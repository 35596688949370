
import { defineView } from '@kmsoft/upf-core'
import { EnumViewConfigTabKey, KViewConfigEventEmits, KViewConfigPropOptions } from './interface'
import KViewConfigViewModel from './KViewConfigViewModel'
import KViewPropertyConfigPanel from '../config/panel/property'
import KViewEventConfig from '../config/panel/event'
import './style/index.less'

/** 视图配置 */
export default defineView({
  name: 'KViewConfig',
  props: KViewConfigPropOptions,
  emits: KViewConfigEventEmits,
  viewModel: KViewConfigViewModel,
  components: { KViewPropertyConfigPanel, KViewEventConfig },
  setup(props, { attrs, emit, expose, slots, vm }) {
    /* tab切换 */
    const onTabChange = (args: string) => {
      vm.activeTabKey.value = args
    }

    /* 配置项内容改变触发的事件 */
    const onElementPropertyChange = (name: string, type: string, value: any) => {
      vm.emit('elementChange', props.element.name, name, type, value)
    }

    /**
     * 脚本修改事件
     * @param configItemName
     * @param value
     */
    const onEventPropertyChange = (configItemName: string, value: any) => {
      vm.emit('elementChange', props.element.name, configItemName, 'event', value)
    }

    return {
      EnumViewConfigTabKey,
      onTabChange,
      onEventPropertyChange,
      onElementPropertyChange
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KDistributionContentObjGridPropOptions, KDistributionContentObjGridEventEmits } from './interface'
import KDistributionContentObjGridViewModel from './KDistributionContentObjGridViewModel'

export default defineView({
  name: 'KDistributionContentObjGrid',
  props: KDistributionContentObjGridPropOptions,
  emits: KDistributionContentObjGridEventEmits,
  viewModel: KDistributionContentObjGridViewModel
})

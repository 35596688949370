
import { defineView } from '@kmsoft/upf-core'
import { ObjectPanelTestPropOptions, ObjectPanelTestEventEmits } from './interface'
import ObjectPanelTestViewModel from './ObjectPanelTestViewModel'

export default defineView({
  name: 'ObjectPanelTest',
  props: ObjectPanelTestPropOptions,
  emits: ObjectPanelTestEventEmits,
  viewModel: ObjectPanelTestViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { App } from 'vue'

import KLayoutDesigner from './designer'
import KDynamicView from './dynamic-view'
import {
  KGridColumnConfig,
  KFilterGridFilterItemDesigner,
  KFilterGridSearchBarDesigner,
  KFilterGridViewDesigner
} from './controls'
import KWrapperControl from './dynamic-view/wrapper'
import KRenderLayout from './render/render-layout'

export * from './designer'
export * from './dynamic-view'
export * from './types'
export * from './config'

const components: Record<string, any> = {
  KDynamicView,
  KWrapperControl,
  KLayoutDesigner,
  KGridColumnConfig,
  KFilterGridFilterItemDesigner,
  KFilterGridSearchBarDesigner,
  KFilterGridViewDesigner,
  KRenderLayout
}

const install = function(app: App) {
  Object.keys(components).forEach(key => {
    app.use(components[key])
  })
}

export default { install: install }

export {
  KFilterGridFilterItemDesigner,
  KFilterGridSearchBarDesigner,
  KFilterGridViewDesigner,
  KDynamicView,
  KWrapperControl,
  KLayoutDesigner,
  KGridColumnConfig,
  KRenderLayout
}

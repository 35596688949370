import { connect, withInstall } from '@kmsoft/upf-core'
import KStructrureGraphView from './KStructrureGraph.vue'
import KStructrureGraphViewModel from './KStructrureGraphViewModel'

const KStructrureGraph = connect(KStructrureGraphView, KStructrureGraphViewModel)

export default withInstall(KStructrureGraph)
export { KStructrureGraph, KStructrureGraphView, KStructrureGraphViewModel }

// 模板生成文件
// export * from './{{folderName}}'

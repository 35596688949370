
import { defineView } from '@kmsoft/upf-core'
import { KBaselinePrimaryObjectPropOptions, KBaselinePrimaryObjectEventEmits } from './interface'
import KBaselinePrimaryObjectViewModel from './KBaselinePrimaryObjectViewModel'

export default defineView({
  name: 'KBaselinePrimaryObject',
  props: KBaselinePrimaryObjectPropOptions,
  emits: KBaselinePrimaryObjectEventEmits,
  viewModel: KBaselinePrimaryObjectViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KChangeRequestRelationshipPropOptions, KChangeRequestRelationshipEventEmits } from './interface'
import KChangeRequestRelationshipViewModel from './KChangeRequestRelationshipViewModel'

const data = [
  {
    id: '#change-issue',
    title: '变更请求-问题报告',
    key: '1'
  },
  {
    id: '#change-order',
    title: '变更请求-变更单',
    key: '2'
  }
] as any

export default defineView({
  name: 'KChangeRequestRelationship',
  props: KChangeRequestRelationshipPropOptions,
  emits: KChangeRequestRelationshipEventEmits,
  viewModel: KChangeRequestRelationshipViewModel,
  setup() {
    return {
      data
    }
  }
})

import { KSchema, ValueChangeEventArgs, VuePropTypes } from '@kmsoft/upf-core'
import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'
import { ControlCollection, KDataElementTree, KDesignerControl } from '../../../../../types'

/** KFilterGridSearchBarConfig 事件 */
export const KFilterGridSearchBarConfigEventEmits = {
  ...BaseViewEventEmits
}

/** KFilterGridSearchBarConfig 事件类型 **/
export type KFilterGridSearchBarConfigEventEmitsType = ViewEmitsTypeExtract<typeof KFilterGridSearchBarConfigEventEmits>

/** KFilterGridSearchBarConfig 参数 **/
export const KFilterGridSearchBarConfigPropOptions = {
  ...BaseViewPropOptions,
  /** 布局方案 */
  schema: VuePropTypes.createType<KSchema>().def({} as KSchema),
  /**数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def({} as KDataElementTree),
  /**控件集合 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def([] as Array<KDesignerControl>)
}

/** KFilterGridSearchBarConfig 参数类型 **/
export type KFilterGridSearchBarConfigPropType = ViewPropsTypeExtract<typeof KFilterGridSearchBarConfigPropOptions>

import { Editor, PropertyValue } from '../../../types'
import { EnumFilterOperatorCode } from '../interface'

/** 操作符 */
export const OPERATORS: Record<PropertyValue['type'], EnumFilterOperatorCode[]> = {
  string: [
    EnumFilterOperatorCode.EQUAL,
    EnumFilterOperatorCode.NOT_EQUAL,
    EnumFilterOperatorCode.LIKE,
    EnumFilterOperatorCode.STARTWITH,
    EnumFilterOperatorCode.ENDWITH
  ],
  number: [
    EnumFilterOperatorCode.EQUAL,
    EnumFilterOperatorCode.NOT_EQUAL,
    EnumFilterOperatorCode.GREATER,
    EnumFilterOperatorCode.GREATER_EQUAL,
    EnumFilterOperatorCode.LESS,
    EnumFilterOperatorCode.LESS_EQUAL
  ],
  time: [
    EnumFilterOperatorCode.GREATER_EQUAL,
    EnumFilterOperatorCode.GREATER,
    EnumFilterOperatorCode.LESS,
    EnumFilterOperatorCode.LESS_EQUAL
  ],
  enum: [EnumFilterOperatorCode.EQUAL, EnumFilterOperatorCode.NOT_EQUAL]
}

export const EDITORS: Record<PropertyValue['type'], Editor> = {
  string: 'text',
  number: 'number',
  time: 'date',
  enum: 'select'
}

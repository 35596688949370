/* eslint-disable no-async-promise-executor */
// import {
//   ObjBusinessParam,
//   objBusinessSrv,
//   projectSrv,
//   ServiceAttachParams,
//   metaObjClsType,
//   EnumObjGridRule
// } from '@kmplm/ecol-data-srv'
// import {
//   commonClientSrv,
//   EnumDialogResult,
//   EnumMainObjPassPropType,
//   KDialog,
//   KObjectCreatePanelLoadDataParams,
//   KObjectCreatePanelSaveEvent,
//   objectClientSrv,
//   ObjectCreateDialogProps,
//   ObjectQueryDialogProps,
//   SuccessCallBack,
//   CreateObjectResult,
//   EnumDialogSize
// } from '@kmplm/ecol-base'

// import * as DataSrv from '@kmplm/ecol-data-srv'
import lodash from 'lodash'
import { PMSCreateObjectResult } from '../components'
import {
  CommonClientSrv,
  EnumClassTemplate,
  EnumObjGridRule,
  FindDialogProps,
  ObjBusinessParam,
  ObjectClientSrv,
  ObjectCreateDialogParams,
  ObjectSelectDialogParam,
  ServiceAttachParams,
  SuccessCallBack
} from '@kmsoft/ebf-common'
import { EnumDialogResult, EnumDialogSize } from '@kmsoft/upf-core'

/**
 * 对象关联（计划/任务）客户端服务
 */
class PlanRefClientSrv {
  /**服务标识 */
  Id = 'Project'
  /**主对象参数 */
  ExtendParam_Plan_Ref = 'PlanRef'
  /**生成服务端扩展参数集。
   * @param params 服务端扩展参数。
   * @returns 服务端扩展参数集。*/
  generateParams(param: ObjBusinessParam): ServiceAttachParams {
    return {
      serviceIdentity: this.Id,
      attachParam: { [this.Id + this.ExtendParam_Plan_Ref]: param }
    }
  }

  /**
   * 打开创建参考对象（计划/任务）对话框
   * @param mainObjParam 主对象对象参数
   */
  onOpenCreatePlanRefDialog = async (
    mainObjParam: ObjBusinessParam,
    callBack?: SuccessCallBack
  ): Promise<PMSCreateObjectResult | undefined> => {
    const dialogProps: ObjectCreateDialogParams = {
      showObjClsCodes: [EnumClassTemplate.DOC]
    }
    const result = await ObjectClientSrv.openCreateDialog(dialogProps)

    if (!result.success) {
      return
    }

    return { objBusiness: [lodash.pick(result.objBusiness, 'modelCode', 'id') as ObjBusinessParam] }
  }

  /**
   * 添加参考对象（计划/任务）
   * @param objClsID
   * @param objID
   * @param showObjClsId 显示的树节点
   * @param callBack
   */
  addPlanRef(mainObjParam: ObjBusinessParam, showObjClsId: string, callBack?: SuccessCallBack): Promise<PMSCreateObjectResult> {
    //1.定义弹出查找对象对话框属性
    //对象类树属性
    return new Promise<PMSCreateObjectResult>((reslove, reject) => {
      const objectClassTreeProps = {
        defaultSelectClsId: showObjClsId,
        // objClassTypes: [
        //   DataSrv.metaObjClsType.DOC,
        //   DataSrv.metaObjClsType.PART,
        //   DataSrv.metaObjClsType.COMMONOBJ,
        //   DataSrv.metaObjClsType.COMMONOBJCUSTOMTABLE
        // ],
        showObjClsIds: showObjClsId ? [showObjClsId] : []
      }
      const objGridRule = { objGridRuleType: EnumObjGridRule.AddDescObj, objGridRuleArgs: { mainObjID: mainObjParam.id } }
      const porps = {
        size: EnumDialogSize.Fat,
        onClosing: async (e): Promise<void> => {
          //3.参数校验
          if (e.dialogResult == EnumDialogResult.Cancel || e.dialogResult == EnumDialogResult.Close) return

          if (e.dialogResult == EnumDialogResult.Apply) {
            e.cancel = true
          }

          const selectedObjParam = (e.viewModel as any).getSelectedObjParams()!
          if (selectedObjParam.length == 0) {
            // CommonClientSrv.alertSelectARow()
            e.cancel = true
            return
          }
          const param = selectedObjParam?.map((m: { modelCode: any; id: any }) => ({ objClsID: m.modelCode, objID: m.id }))

          reslove({ objBusiness: param })
        },
        objectClassManageQueryParam: { ...objectClassTreeProps, ...objGridRule },
        objectSearchQueryParam: { ...objectClassTreeProps, ...objGridRule },
        objectDirectoryManageProps: { ...objGridRule },
        objectSearchProps: { ...objectClassTreeProps, ...objGridRule },
        recentOpenObjectGridProps: { objClsID: showObjClsId, ...objGridRule }
      } as ObjectSelectDialogParam
      //2.弹出查找对象对话框
      ObjectClientSrv.openObjectSelectDialog(porps)
    })
  }

  /**
   * 移除参考对象（计划/任务）关联关系
   */
  removePlanRef(mainObjParam: ObjBusinessParam, objParam: Array<ObjBusinessParam>, callBack: SuccessCallBack) {
    // CommonClientSrv.confirmRemoveObj(async () => {
    //   const result = await ProjectSrv.removePlanRefObjs({
    //     projectObjParam: mainObjParam,
    //     relObjs: objParam
    //   })
    //   if (result) callBack()
    // })
  }

  /**
   * 删除参考对象（计划/任务）
   */
  async deletePlanRef(mainObjParam: ObjBusinessParam, objParams: Array<ObjBusinessParam>, callBack: SuccessCallBack) {
    // 获取选中的对象参数
    // 批量删除对象
    let i = 0
    const rowIdList = objParams.map(o => i++)

    // await ObjectClientSrv.deleteBusinessObjBatch(objParams, rowIdList)
    callBack()
  }
}

export const planRefClientSrv = new PlanRefClientSrv()

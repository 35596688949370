
import { defineView } from '@kmsoft/upf-core'
import { KPartSearchFormPropOptions, KPartSearchFormEventEmits } from './interface'
import KPartSearchFormViewModel from './KPartSearchFormViewModel'

export default defineView({
  name: 'KPartSearchForm',
  props: KPartSearchFormPropOptions,
  emits: KPartSearchFormEventEmits,
  viewModel: KPartSearchFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KPartSearchFormEmitsType, KPartSearchFormPropType } from './interface'
import { KObjectCreatePanelViewModel, KObjectPropertyPanelViewModel } from '@kmsoft/ebf-common'
import { ref, watch } from 'vue'

/** KPartSearchForm */
export default class KPartSearchFormViewModel extends BaseViewModel<KPartSearchFormEmitsType, KPartSearchFormPropType> {
  /** 展开层级 */
  inputLevel = ref(0)
  /** 分支选择 */
  selectBranch = ref('2')
  /** 搜索名称 */
  inputName = ref('')
  /** 搜索编码 */
  inputNumber = ref('')
  /** 通用用布局 */
  refSearchForm = ref<KObjectCreatePanelViewModel>()
  /** 是否选中全部 */
  isSelectAll = ref<Boolean>(true)
  /** 是否重新查询 */
  reFind = ref<Boolean>(false)
  constructor(options: ViewModelOptions<KPartSearchFormPropType>) {
    super(options)

    // 监听查询参数
    watch(
      () => this.inputLevel.value,
      () => {
        this.refershData()
      },
      {
        immediate: true
      }
    )
    watch(
      () => this.selectBranch.value,
      () => {
        this.refershData()
      },
      {
        immediate: true
      }
    )
    watch(
      () => this.inputName.value,
      () => {
        this.refershData()
      },
      {
        immediate: true
      }
    )
    watch(
      () => this.inputNumber.value,
      () => {
        this.refershData()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  /** 校验 */
  async validate() {
    try {
      return true
    } catch (error) {
      return false
    }
  }

  /**
   * 获取表单的值
   * @returns 表单的值
   */
  public getValue() {
    return {
      maxLevel: this.inputLevel.value,
      name: this.inputName.value,
      number: this.inputNumber.value,
      isFindAll: this.selectBranch.value == '2' ? true : false
    }
  }

  /**
   * 清洗数据
   */
  refershData() {
    this.emit('refershData')
    this.reFind.value = true
  }

  /**
   * 勾选全部
   */
  selectAll() {
    this.emit(
      'selectAll',
      {
        maxLevel: this.inputLevel.value,
        name: this.inputName.value,
        number: this.inputNumber.value,
        isFindAll: this.selectBranch.value == '2' ? true : false
      },
      this.reFind.value,
      async () => {
        this.isSelectAll.value = false
        this.reFind.value = false
      }
    )
  }

  /**
   * 取消勾选
   */
  cancelSelectAll() {
    this.emit('cancelSelectAll')
    this.isSelectAll.value = true
  }

  /**
   * 重置层级
   */
  resetLevel() {
    this.inputLevel.value = 0
  }

  /**
   * 找上一处
   */
  findPrevious() {
    this.emit(
      'findPrevious',
      {
        maxLevel: this.inputLevel.value,
        name: this.inputName.value,
        number: this.inputNumber.value,
        isFindAll: this.selectBranch.value == '2' ? true : false
      },
      this.reFind.value
    )
    this.reFind.value = false
  }

  /**
   * 最上
   */
  findTop() {
    this.emit(
      'findTop',
      {
        maxLevel: this.inputLevel.value,
        name: this.inputName.value,
        number: this.inputNumber.value,
        isFindAll: this.selectBranch.value == '2' ? true : false
      },
      this.reFind.value
    )
    this.reFind.value = false
  }

  /**
   * 找下一处
   */
  findNext() {
    this.emit(
      'findNext',
      {
        maxLevel: this.inputLevel.value,
        name: this.inputName.value,
        number: this.inputNumber.value,
        isFindAll: this.selectBranch.value == '2' ? true : false
      },
      this.reFind.value
    )
    this.reFind.value = false
  }

  /**
   * 最下
   */
  findBottom() {
    this.emit(
      'findBottom',
      {
        maxLevel: this.inputLevel.value,
        name: this.inputName.value,
        number: this.inputNumber.value,
        isFindAll: this.selectBranch.value == '2' ? true : false
      },
      this.reFind.value
    )
    this.reFind.value = false
  }

  /**
   * 关闭
   */
  close() {
    this.emit('close')
  }
}

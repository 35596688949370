
import { defineView } from '@kmsoft/upf-core'
import { KIssueReportRelationDocPropOptions, KIssueReportRelationDocEventEmits } from './interface'
import KIssueReportRelationDocViewModel from './KIssueReportRelationDocViewModel'
import { ref } from 'vue'

export default defineView({
  name: 'KIssueReportRelationDoc',
  props: KIssueReportRelationDocPropOptions,
  emits: KIssueReportRelationDocEventEmits,
  viewModel: KIssueReportRelationDocViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

export type ObjectLogicFlowDataLoader = () => Promise<DataType> | DataType

/** 参数 **/
export const KStructrureGraphPropOptions = {
  ...BaseViewPropOptions,
  width: VuePropTypes.number().def(160),
  height: VuePropTypes.number().def(80),
  config: VuePropTypes.createType<Record<string, unknown>>().def({}),
  loadData: VuePropTypes.func<ObjectLogicFlowDataLoader>().def()
}

/** 参数类型 **/
export type KStructrureGraphPropType = ViewPropsTypeExtract<typeof KStructrureGraphPropOptions>

/** 事件 */
export const KStructrureGraphEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KStructrureGraphEmitsType = ViewEmitsTypeExtract<typeof KStructrureGraphEventEmits>

export type DataType = {
  id: number
  children?: Array<DataType>
  [key: string]: unknown
}

export type NodeDataType = {
  id: string | number
  type: string
  x: number
  y: number
  properties: Record<string, unknown>
}

export type EdgesDataType = {
  type: string
  sourceNodeId: string | number
  targetNodeId: string | number
  startPoint?: Record<string, unknown>
  endPoint?: Record<string, unknown>
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KConfigFormItemView from './KConfigFormItem.vue'
import KConfigFormItemViewModel from './KConfigFormItemViewModel'

const KConfigFormItem = connect(KConfigFormItemView, KConfigFormItemViewModel)

export default withInstall(KConfigFormItem)
export { KConfigFormItem, KConfigFormItemView, KConfigFormItemViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KDesignerControlPanel = _resolveComponent("KDesignerControlPanel")!
  const _component_k_tab_pane = _resolveComponent("k-tab-pane")!
  const _component_KDesignerHierarchy = _resolveComponent("KDesignerHierarchy")!
  const _component_KDesignerMetadata = _resolveComponent("KDesignerMetadata")!
  const _component_k_tabs = _resolveComponent("k-tabs")!

  return (_openBlock(), _createBlock(_component_k_tabs, {
    class: "k-designer-toolbox",
    defaultActiveKey: _ctx.$vm.activeKey
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_tab_pane, {
        key: "1",
        tab: "控件"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_KDesignerControlPanel, {
            convertElement: _ctx.convertElement,
            designerMetaControl: _ctx.designerMetaControl
          }, null, 8, ["convertElement", "designerMetaControl"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_tab_pane, {
        key: "2",
        tab: "层级"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_KDesignerHierarchy, {
            schema: _ctx.schema,
            selectedHierarchyTreeNodeKey: _ctx.$vm.selectedHierarchyTreeNodeKey,
            onTreeNodeSelected: _ctx.onHierarchyTreeNodeSelected
          }, null, 8, ["schema", "selectedHierarchyTreeNodeKey", "onTreeNodeSelected"])
        ]),
        _: 1
      }),
      (_ctx.dataSource)
        ? (_openBlock(), _createBlock(_component_k_tab_pane, {
            key: "3",
            tab: "数据元素"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_KDesignerMetadata, {
                dataSource: _ctx.dataSource,
                convertElement: _ctx.convertElement
              }, null, 8, ["dataSource", "convertElement"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["defaultActiveKey"]))
}
import { IAppConfigurer, IMenuPagePathMapping, IocContainer, UPF_IOC_KEYS } from '@kmsoft/upf-core'
import { App } from 'vue'
import { WorkflowMenuPathMapping } from './beans'

import globalControls from './controls'
import KPersonalWorkflowTasks from './pages/personal-workflow-tasks'
import Pages from './pages'
export default {
  configVue: (app: App) => {
    app.use(globalControls)
    app.use(KPersonalWorkflowTasks)
    app.use(Pages)
  },
  configBean: (iocContainer: IocContainer) => {
    iocContainer.registerSingleton<IMenuPagePathMapping>(UPF_IOC_KEYS.MENU_PAGE_PATH_MAPPING, new WorkflowMenuPathMapping())
  }
} as IAppConfigurer

export * from './client-srv'

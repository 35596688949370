import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_object_class_grid, {
      ref: "refDocObjGrid",
      modelCode: "Document",
      modelGroup: "doc",
      pagination: {
        pageIndex: 1,
        pageSize: 50
      },
      loadData: _ctx.$vm.onLoadData,
      onToolStripItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onToolStripItemClicked(event)),
      toolStripItems: _ctx.$vm.toolStripItems,
      onRowDoubleClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$vm.onDoubleClick()))
    }, null, 8, ["loadData", "toolStripItems"])
  ]))
}
import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { KControlAttribute } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'

/* 属性配置项集合 */
const BASE = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE, PROPERTY_CONFIG_ITEM_COLLECTION.DISABLED] as Array<KControlAttribute>

/**控件属性配置项集合 */
const PROPS = [
  LAYOUT_CONFIG_ITEM_COLLECTION.LABEL_POSITION,
  LAYOUT_CONFIG_ITEM_COLLECTION.LABEL_WIDTH,
  LAYOUT_CONFIG_ITEM_COLLECTION.EXPAND,
  LAYOUT_CONFIG_ITEM_COLLECTION.MIN_COL_WIDTH,
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_GAP,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_GAP,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_COUNT
] as Array<KControlAttribute>

/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
] as Array<KControlAttribute>

const EVENT = [
  { name: 'expand', title: '展开', describe: '展开 的说明信息...' },
  { name: 'collapse', title: '收起', describe: '收起 的说明信息...' }
]
export default { BASE, PROPS, LAYOUT, EVENT }

import { AppContext } from '@kmsoft/upf-core'
import { PurviewAdapterBase } from './PurviewAdapterBase'
import { EnumPurviewType } from './types/enums'
import {
  VerifyObjClsPurviewParams,
  VerifyObjectPurviewParams,
  PurviewVerifyResult,
  VerifyFuncPurviewParams,
  VerifyPurviewParams
} from './types'

/** 权限 */
export abstract class PurviewServiceBase {
  constructor() {}

  /**
   * 获取权限适配器
   * @returns 适配器
   */
  abstract getAdapter<T extends PurviewAdapterBase>(): T

  /** 数据适配器 */
  get adapter() {
    const adapter = this.getAdapter()
    if (!adapter) {
      throw new Error('PurviewAdapter 未定义')
    }
    return adapter
  }

  /**
   * 获取用户Id
   * @returns
   */
  get userId() {
    const identity = AppContext.current.getIdentity()
    return identity?.id
  }

  /**
   * 校验权限
   * @param data
   * @param purviewType
   * @param purviewId
   * @returns
   */
  async verifyPurview(purviewType: EnumPurviewType, params: VerifyPurviewParams): Promise<boolean> {
    let purviewResult: PurviewVerifyResult | Promise<PurviewVerifyResult>

    // 判断权限类型
    switch (purviewType) {
      // 对象类权限
      case EnumPurviewType.ObjectClass: {
        /** 检查对象类权限 */ 
        purviewResult = await this.verifyObjClsPurview(params as VerifyObjClsPurviewParams)
        break
      }
      // 操作权限
      case EnumPurviewType.ObjectOperation: {
        /** 查找对象权限 */
        purviewResult = await this.verifyObjectPurview(params as VerifyObjectPurviewParams)
        break
      }
      // 功能权限
      case EnumPurviewType.Func: {
        /** 查找对象权限 */
        purviewResult = await this.verifyFuncPurview(params)
        break
      }
    }

    /** 如果命中规则 */
    if (purviewResult.isMatch) {
      return purviewResult.hasPurview
    }

    return false
  }

  /**
   * 校验对象类权限
   * @param modelCode
   * @param purviewId
   * @returns
   */
  verifyObjClsPurview(params: VerifyObjClsPurviewParams): PurviewVerifyResult {
    return this.adapter.verifyObjClsPurview(params)
  }

  /**
   * 校验对象权限
   * @param params
   * @param purviewId 权限Id
   * @returns
   */
  verifyObjectPurview(params: VerifyObjectPurviewParams): Promise<PurviewVerifyResult> | PurviewVerifyResult {
    return this.adapter.verifyObjectPurview(params)
  }

  /**
   * 校验功能权限
   * @param purviewId 权限Id
   * @returns
   */
  verifyFuncPurview(params: VerifyFuncPurviewParams): PurviewVerifyResult {
    return this.adapter.verifyFuncPurview(params)
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d39ca7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","justify-content":"space-between","align-items":"center"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = { style: {"height":"100%"} }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { style: {"margin-left":"5px"} }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_object_tool_strip, {
          ref: "refToolStrip",
          items: _ctx.$vm.toolStripItems,
          onItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onToolStripItemClicked(event))
        }, null, 8, ["items"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_k_select, {
            class: "searchClass",
            value: _ctx.$vm.selectValue,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.selectValue) = $event)),
            options: _ctx.selectOptions,
            placeholder: _ctx.selectValuePlaceholder
          }, null, 8, ["value", "options", "placeholder"]),
          _createVNode(_component_k_input, {
            class: "searchClass",
            value: _ctx.$vm.inputValue,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.inputValue) = $event)),
            placeholder: "编码/名称"
          }, null, 8, ["value"]),
          _createVNode(_component_k_button, {
            onClick: _ctx.$vm.queryData
          }, {
            default: _withCtx(() => [
              _createTextVNode("查询")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_3, [
        _createVNode(_component_k_data_grid, {
          rowModelType: _ctx.rowModelType,
          class: "personal-create-change-order-grid",
          rowKey: "id",
          name: "personalCreateChangeOrderGrid",
          ref: _ctx.$vm.refDataGrid,
          loadData: _ctx.$vm.loadData,
          pagination: _ctx.pagination
        }, {
          columns: _withCtx(() => [
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "名称",
              name: "name",
              dataPropertyName: "name",
              align: "center",
              width: "150"
            }, {
              default: _withCtx(({ cellValue,row }) => [
                (_ctx.readonly)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: "javascript:void(0)",
                      onClick: () => _ctx.$vm.openObjTab(row)
                    }, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(cellValue), 1)
                    ], 8, _hoisted_4))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(cellValue), 1))
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "编码",
              dataPropertyName: "number",
              align: "center",
              width: "150"
            }, {
              default: _withCtx(({ row }) => [
                _createElementVNode("span", null, [
                  _createElementVNode("a", {
                    href: "javascript:void(0)",
                    onClick: ($event: any) => (_ctx.$vm.openObjTab(row))
                  }, _toDisplayString(row.number), 9, _hoisted_7)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "活动类型",
              dataPropertyName: "activityType",
              align: "center",
              width: "auto"
            }, {
              default: _withCtx(({ row }) => [
                (row.activityType)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.EnumActivityType[row.activityType]), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, "--"))
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "生命周期状态",
              dataPropertyName: "lifecycleStateCode",
              align: "center",
              width: "auto"
            }, {
              default: _withCtx(({ cellValue }) => [
                cellValue
                  ? (_openBlock(), _createBlock(_component_k_tag, {
                      key: 0,
                      color: _ctx.EnumLifecycleStateColor[cellValue]
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.EnumLifecycleState[cellValue]), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_10, "--"))
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "存货处置",
              dataPropertyName: "inventoryDisposition",
              align: "center",
              width: "auto"
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "需要日期",
              dataPropertyName: "needDate",
              align: "center",
              width: "auto"
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "创建时间",
              dataPropertyName: "createTime",
              align: "center",
              width: "auto"
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "更新时间",
              dataPropertyName: "lastUpdateTime",
              align: "center",
              width: "auto"
            })
          ]),
          _: 1
        }, 8, ["rowModelType", "loadData", "pagination"])
      ])
    ]),
    _: 1
  }))
}
import {
  BaseViewModel,
  EnumDialogResult,
  EnumToolStripCompType,
  IQuery,
  KDataGridCellContextMenuEvent,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  KPopupContextMenu,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KPartViewEmitsType, KPartViewPropType } from './interface'
import { ref } from 'vue'
import {
  Api,
  CommonClientSrv,
  EnumRequestCode,
  EnumSupportObjectNumMode,
  EnumToolStripItemKeys,
  ObjectToolStripItem
} from '@kmsoft/ebf-common'
import { KPartViewForm, KPartViewFormViewModel } from './part-view-form'
import { IKPartViewFormDef } from './part-view-form/interface'

/** KPartView */

export default class KPartViewViewModel extends BaseViewModel<KPartViewEmitsType, KPartViewPropType> {
  refDataGridView = ref<KDataGridViewModel>()
  isEdit = ref<boolean>(false)

  constructor(options: ViewModelOptions<KPartViewPropType>) {
    super(options)
  }
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>([
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_INSERT_PART_VIEW,
      title: '插入子视图',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_PART_VIEW,
      title: '删除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single
    }
  ])

  viewDidMount() {}

  beginEdit() {
    this.refDataGridView.value?.beginEdit()
    this.isEdit.value = true
  }

  /**
   * 行Id
   * @returns 行Id
   */
  get rowKey() {
    return this.refDataGridView.value?.getRowKey() || 'id'
  }

  addData(id: any) {
    KDialog.show({
      title: '创建视图',
      size: { width: 500, height: 300 },
      props: {
        formProp: {
          parentId: id
        }
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPartViewForm,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KPartViewFormViewModel
        const recordObject = formViewModel.getFormData()

        const existingName = this.refDataGridView.value?.getRows().some(record => record.name === recordObject.name)
        if (existingName) {
          KNotification.warn({
            message: '系统提示',
            description: '该视图已存在，不能重复添加'
          })
          event.cancel = true
        } else {
          this.create(event, recordObject)
        }
      }
    })
  }

  refresh() {
    this.refDataGridView.value?.refresh()
  }

  /**
   * 右键菜单
   * @param event
   * @returns
   */
  onCellContextMenu(event: KDataGridCellContextMenuEvent) {
    if (this.toolStripItems.value.length <= 0) {
      return
    }
    /** 获取选中的行 */
    const selectedRows = this.refDataGridView.value?.getSelectedRows() as Record<string, any>
    const row = event.row
    if (!selectedRows || selectedRows.length <= 0 || selectedRows.indexOf(row) < 0) {
      this.refDataGridView.value?.clearSelectedRows()
      this.refDataGridView.value?.setSelectedRow(row![this.rowKey], true)
    }

    KPopupContextMenu.show({
      preciseEvent: false,
      items: this.toolStripItems.value,
      onItemClicked: (args: any) => {
        switch (args.name) {
          case EnumToolStripItemKeys.TOOL_STRIP_ITEM_INSERT_PART_VIEW:
            const rows = this.refDataGridView.value?.getSelectedRows()
            this.addData(row![this.rowKey])
            break
          case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_PART_VIEW:
            this.delete(row![this.rowKey])
            break
          default:
            throw new Error('Method not implemented.')
        }
      }
    })
  }

  async save() {
    this.refDataGridView.value?.endEdit()
    await CommonClientSrv.sleep(200)
    this.isEdit.value = false
    const modifyData = this.refDataGridView.value?.getModifiedValue()
    const params = modifyData?.modifiedRows
    if (!params) {
      KNotification.warn({
        message: '系统提示',
        description: '未修改任何数据！'
      })
      this.refDataGridView.value?.beginEdit()
      this.isEdit.value = true
      return
    }

    const allRows = this.refDataGridView.value?.getRows()
    const nameCount: Record<string, number> = {}
    allRows?.forEach(row => {
      const name = row.name
      if (name) {
        nameCount[name] = (nameCount[name] || 0) + 1
      }
    })

    // 找出重复的 name
    const duplicateNames = Object.keys(nameCount).filter(name => nameCount[name] > 1)
    if (duplicateNames.length > 0) {
      KNotification.warn({
        message: '系统提示',
        description: '以下视图【' + duplicateNames + '】，不能重复添加'
      })
      this.refDataGridView.value?.beginEdit()
      this.isEdit.value = true
      return
    }

    const result = await Api.post('part', 'PartView', 'batchUpdate', { data: [params] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '更新成功'
      })
      this.refresh()
    } else {
      KNotification.error({
        title: '操作失败',
        content: result.message || '更新失败',
        details: result.detail
      })
      this.refDataGridView.value?.beginEdit()
      this.isEdit.value = true
    }
  }

  async delete(id: any) {
    const result = await Api.post('part', 'PartView', 'delete', { data: [id] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '删除成功'
      })
      this.refresh()
    } else {
      KNotification.error({
        title: '操作失败',
        content: result.message || '删除失败',
        details: result.detail
      })
    }
  }

  async create(event: KDialogClosingEvent, recordObject: IKPartViewFormDef) {
    const params = {
      id: recordObject.id === '' ? null : recordObject.id,
      name: recordObject.name,
      description: recordObject.description,
      parentPartViewRef:
        recordObject.parentId === ''
          ? null
          : {
              id: recordObject.parentId
            }
    }

    const result = await Api.post('part', 'PartView', 'create', { data: [params] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '新建成功'
      })
      this.refresh()
    } else {
      KNotification.error({
        title: '操作失败',
        content: result.message || '新建失败',
        details: result.detail
      })
      event.cancel = true
    }
  }

  loadData(query: IQuery): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const result = await Api.post('part', 'PartView', 'treeListAll', {})
      if (result && result.code == EnumRequestCode.SUCCESS) {
        setTimeout(() => {
          resolve({
            total: result.data.length,
            rows: result.data
          })
        }, 20)
      } else {
        setTimeout(() => {
          resolve({
            total: 0,
            rows: []
          })
        }, 20)
      }
    })
  }

  getHierarchyPath(row: Record<string, any>) {
    const hierarchy = [] as Array<string>
    this.getHierarchy(row, hierarchy)
    console.log(`开始获取${row.name}的结构`, JSON.stringify(hierarchy))
    return hierarchy
  }
  getHierarchy(row: Record<string, any>, hierarchy: Array<string>) {
    if (row.parentId == 'null' || row.parentId == null) {
      hierarchy.unshift(row.code)
    } else {
      const parentRow = this.refDataGridView.value?.getRow(row.parentId)
      if (parentRow) {
        hierarchy.unshift(row.code)
        this.getHierarchy(parentRow, hierarchy)
      }
    }
  }
}

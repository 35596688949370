import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KWorkflowViewEmitsType, KWorkflowViewPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { result } from 'lodash'
import { KFlowApprovalContentViewModel } from '../../controls/flow-approval-content'
import { KFlowApprovalProcessViewModel } from '../../controls/flow-approval-process'

/** KWorkflowView */
export default class KWorkflowViewViewModel extends BaseViewModel<KWorkflowViewEmitsType, KWorkflowViewPropType> {
  flowDetailData = ref<any>({})
  processDefinitionKey = ref<string>()

  refKFlowApprovalContent = ref<KFlowApprovalContentViewModel>()
  refKFlowApprovalProcess = ref<KFlowApprovalProcessViewModel>()
  /** 工具栏事件否被触发过 **/
  toolStripTrigger = ref<boolean>(false)

  constructor(options: ViewModelOptions<KWorkflowViewPropType>) {
    super(options)
    this.toolStripTrigger.value = false
  }

  viewDidMount() {
    this.getFlowDetailData()
  }

  /** 获取流程详情 **/
  getFlowDetailData() {
    Api.post('official', 'ProcessInstanceService', 'getProcessInstance', {
      data: [this.props.processInstanceId]
    }).then((result: any) => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        const data = result.data || {}
        // 流程节点的执行人添加id，否则列表显示异常
        data.processNodes = data?.processNodes.map((item: any) => {
          item.id = Math.random()
          return item
        })
        // 工具栏事件否被触发过，触发过taskId传空，隐藏工具栏
        let task = data.tasks[0]
        if (data.tasks.length > 1) {
          const newTask = data.tasks.filter((task: any) => task.taskId != null)[0]
          task = newTask ? newTask : data.tasks[0]
        }
        this.flowDetailData.value = {
          ...data,
          taskId: this.toolStripTrigger.value ? '' : task ? (task.taskId ? task.taskId : '') : '',
          currentSteps: data.endTime != null ? '流程已结束' : task ? (task.taskName ? task.taskName : '') : ''
        }
        this.processDefinitionKey.value = result.data.processDefinitionKey
      } else {
        KNotification.error({
          title: '获取失败',
          content: result.message,
          details: result.detail
        })
      }
    })
  }

  /** 流程处理完成，刷新数据 **/
  flowHandlerComplete() {
    this.toolStripTrigger.value = true
    this.refresh()
  }

  refresh() {
    this.getFlowDetailData()
    // 刷新审批内容
    this.refKFlowApprovalContent.value?.getSourceData()
    // 刷新审批过程
    this.refKFlowApprovalProcess.value?.initData()
  }
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KBaselineCompareGridEmitsType, KBaselineCompareGridPropType } from './interface'

/** KBaselineCompareGrid */
export default class KBaselineCompareGridViewModel extends BaseViewModel<
  KBaselineCompareGridEmitsType,
  KBaselineCompareGridPropType
> {
  constructor(options: ViewModelOptions<KBaselineCompareGridPropType>) {
    super(options)
  }

  viewDidMount() {}
}

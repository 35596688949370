import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectRelationsView from './KObjectRelations.vue'
import KObjectRelationsViewModel from './KObjectRelationsViewModel'

const KObjectRelations = connect(KObjectRelationsView, KObjectRelationsViewModel)

export default withInstall(KObjectRelations)
export { KObjectRelations, KObjectRelationsView, KObjectRelationsViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { ref } from 'vue'
import { defineView } from '@kmsoft/upf-core'
import { ObjectSelectorTestPropOptions, ObjectSelectorTestEventEmits } from './interface'
import ObjectSelectorTestViewModel from './ObjectSelectorTestViewModel'
import { KFileUploader, ObjectClientSrv } from '@kmsoft/ebf-common'
import lodash from 'lodash'

export default defineView({
  name: 'ObjectSelectorTest',
  inheritAttrs: false,
  props: ObjectSelectorTestPropOptions,
  emits: ObjectSelectorTestEventEmits,
  viewModel: ObjectSelectorTestViewModel,
  components: {},
  setup(props, { attrs, slots, vm }) {
    const newProps = { ...attrs, ...props }
    const activeKey = ref(['1'])

    const receipientId = ref<number>()
    let selectPage = 1
    let selectTotal = 1000
    const options = ref([
      { label: '雷进宝', value: '1 ' },
      { label: '林雅南', value: '2 ' },
      { label: '小黑', value: '3 ' },
      { label: '洪振霞', value: '4 ' },
      { label: '蔡莉华', value: '5 ' },
      { label: '小蓝', value: '6 ' },
      { label: '李军', value: '7 ' },
      { label: '小橙', value: '8 ' },
      { label: '王军', value: '9 ' },
      { label: '小黄', value: '10' },
      { label: '小棕', value: '11' },
      { label: '瀚文', value: '12' },
      { label: '赵浩歌', value: '13' },
      { label: '陈浩', value: '14' },
      { label: '安鹏飞', value: '15' },
      { label: '刘茂强', value: '16' },
      { label: '邓海阳', value: '17' },
      { label: '陈婉璇', value: '18' },
      { label: '小粉粉', value: '19' },
      { label: '方一强', value: '20' },
      { label: '茹定', value: '21' },
      { label: '王汝民', value: '22' },
      { label: '郑昌梦', value: '23' },
      { label: '须承文', value: '24' }
    ])

    /** 滚动事件 */
    const handlePopupScroll = (e: any) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        console.log('触底了')
        selectPage = selectPage + 1
        if (selectPage <= selectTotal) {
          getTaskList()
        }
      }
    }

    const getTaskList = () => {
      const newArray = []

      for (let index = 0; index < 20; index++) {
        const id = lodash.uniqueId('test_')
        newArray.push({ label: '==' + id, value: id })
      }

      options.value.push(...newArray)
    }

    function onSelectObj() {
      ObjectClientSrv.openObjectSelectDialog({
        onClosing: e => {
          const obj = e.viewInstance?.getSelectedObjParams()
          console.info(obj)
        }
      })
    }

    const onUpload = async () => {
      const result = await KFileUploader.uploadAsync({
        title: '上传测试'
      })
      console.info(result)
    }

    const onLoadData = () => {
      console.info('111')
      return []
    }

    return { activeKey, onSelectObj, onUpload, options, receipientId, handlePopupScroll, onLoadData }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KBaselineMemberReplaceGridPropOptions, KBaselineMemberReplaceGridEventEmits } from './interface'
import KBaselineMemberReplaceGridViewModel from './KBaselineMemberReplaceGridViewModel'
import { EnumLifecycleState, EnumLifecycleStateColor } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KBaselineMemberReplaceGrid',
  props: KBaselineMemberReplaceGridPropOptions,
  emits: KBaselineMemberReplaceGridEventEmits,
  viewModel: KBaselineMemberReplaceGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      EnumLifecycleState,
      EnumLifecycleStateColor
    }
  }
})

import { ObjectToolStripItem, ToolStripHelper } from '@kmsoft/ebf-common'
import { EnumToolStripCompType } from '@kmsoft/upf-core'

export const getToolStrip = () => {
  const commonToolStrip = [...ToolStripHelper.getStructuredDocGeneralObjectOperationToolStripItems()]
  return [...commonToolStrip]
}

export const getStructuredDocToolStrip = (): Array<ObjectToolStripItem> => {
  return [
    {
      name: 'EnumDocToolStripItemKeys.TOOL_STRIP_DOC_RELEASE',
      title: '新建',
      icon: 'release',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: 'contextMenuList',
      title: '修订',
      icon: 'edit',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]
}


import { defineView } from '@kmsoft/upf-core'
import { KChangeStatisticsPropOptions, KChangeStatisticsEventEmits } from './interface'
import KChangeStatisticsViewModel from './KChangeStatisticsViewModel'

export default defineView({
  name: 'KChangeStatistics',
  props: KChangeStatisticsPropOptions,
  emits: KChangeStatisticsEventEmits,
  viewModel: KChangeStatisticsViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

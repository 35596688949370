import { Ref, VNode, ref, watch } from 'vue'
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import {
  EnumObjectQueryMode,
  IObjectSelectorComponent,
  KObjectSelectorEventEmitsType,
  KObjectSelectorPropType
} from './interface'
import { ObjBusinessParam } from '../../client-srv'

/** 对象选择 */
export default class KObjectSelectorViewModel extends BaseViewModel<KObjectSelectorEventEmitsType, KObjectSelectorPropType> {
  /** 查询组件 */
  refObjectQueryComponent = ref<IObjectSelectorComponent>()
  /** 当前选中的查询模式 */
  selectedQueryMode = ref(EnumObjectQueryMode.ObjectDirectoryQuery)
  /** 是否显示通用查询网格 */
  isShowCommonObjectGrid = ref(false)
  /** 是否显示快速搜索 */
  isShowQuickSearch = ref(false)

  constructor(options: ViewModelOptions<KObjectSelectorPropType>) {
    super(options)
    this.selectedQueryMode.value = this.props.objectQueryMode
  }

  /** 加载完成函数 */
  viewDidMount() {}

  //#region 对象信息
  /**
   * 获取当前选择的对象参数
   * @param showSelectMessage
   * @returns
   */
  getSelectedObjParams(): Array<ObjBusinessParam> {
    if (!this.refObjectQueryComponent.value) {
      return []
    }
    return this.refObjectQueryComponent.value.getSelectedObjParams()
  }

  /**
   * 获取选择的行
   * @returns
   */
  getSelectedRows(): Array<Record<string, any>> {
    if (!this.refObjectQueryComponent.value) {
      return []
    }
    return this.refObjectQueryComponent.value.getSelectedRows()
  }
  //#endregion
}


import { defineView } from '@kmsoft/upf-core'
import { KProjectWorkspacePropOptions, KProjectWorkspaceEventEmits } from './interface'
import KProjectWorkspaceViewModel from './KProjectWorkspaceViewModel'
import KFolderManage from '../../folder-manage'

export default defineView({
  name: 'KProjectWorkspace',
  props: KProjectWorkspacePropOptions,
  emits: KProjectWorkspaceEventEmits,
  components: { KFolderManage }, // 这里定义局部组件
  viewModel: KProjectWorkspaceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

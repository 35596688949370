
import { defineView } from '@kmsoft/upf-core'
import { KBaselineCreatePropOptions, KBaselineCreateEventEmits } from './interface'
import KBaselineCreateViewModel from './KBaselineCreateViewModel'
import KBaselineCreateForm from './baseline-create-form'

export default defineView({
  components: { KBaselineCreateForm },
  name: 'KBaselineCreate',
  props: KBaselineCreatePropOptions,
  emits: KBaselineCreateEventEmits,
  viewModel: KBaselineCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { KTreeNode, request } from '@kmsoft/upf-core'
import { IDirectoryTreeNodeData } from '../interface'
import KDirectoryTreeViewModel from '../KDirectoryTreeViewModel'
import { ConfigClientSrv } from '../../../client-srv'

export default class KDirectoryTreeNodeBase<
  TTreeNodeData extends IDirectoryTreeNodeData = IDirectoryTreeNodeData
> extends KTreeNode {
  nodeData: TTreeNodeData

  get dirTreeViewModel() {
    return this.host as KDirectoryTreeViewModel
  }

  get moduleType() {
    return this.nodeData.moduleType
  }

  get type() {
    return this.nodeData.type
  }

  get predefined() {
    return this.nodeData.predefined
  }

  get remark() {
    return this.nodeData.remark
  }

  constructor(nodeData: TTreeNodeData) {
    super()
    this.nodeData = nodeData
    this.id = nodeData.id
    this.name = nodeData.name
    this.leaf = nodeData.leaf == 1 ? true : false
    this.iconType = 'folder'
  }

  /** 设置节点图标类型 */
  setIconType(iconType: string) {
    this.iconType = iconType
    return this
  }

  async populateChildren(populateType: number): Promise<Array<KTreeNode>> {
    const nodes = [] as Array<KTreeNode>
    const baseApiUrl = ConfigClientSrv.getApiBaseUrl
    const url = `${baseApiUrl}/directory/getChildDirectory`
    const result = (await request.post(url, { object: this.id })) as any //directory.getChildDirectory({ object: this.id })

    if (!result.isSuccess) {
      return []
    }

    for (const item of result.data!) {
      if (item.moduleType != this.dirTreeViewModel.moduleType) {
        continue
      }

      if (
        this.dirTreeViewModel.directoryTypes &&
        this.dirTreeViewModel.directoryTypes.length > 0 &&
        (!item.type || this.dirTreeViewModel.directoryTypes?.indexOf(item.type)) == -1
      ) {
        continue
      }

      const childNode = new KDirectoryTreeNodeBase<IDirectoryTreeNodeData>(item)
      nodes.push(childNode)
    }

    return nodes
  }
}

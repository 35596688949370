
import { defineView } from '@kmsoft/upf-core'
import { KPartViewFormEventEmits, KPartViewFormPropOptions } from './interface'
import KPartViewFormViewModel from './KPartViewFormViewModel'
import { readonly } from 'vue'

export default defineView({
  name: 'KPartViewForm',
  props: KPartViewFormPropOptions,
  emits: KPartViewFormEventEmits,
  viewModel: KPartViewFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

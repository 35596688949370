import { MemoryCacheBase } from '@kmsoft/upf-core'

export class SysConfigMemoryCache extends MemoryCacheBase<Array<Record<string, any>>> {
  public static cacheKey = 'SysConfigMemoryCache'

  constructor() {
    super()
    this.cacheKey = SysConfigMemoryCache.cacheKey
  }

  public async reload() {
    this.cacheData = []
  }
}

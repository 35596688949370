import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KFolderFormPropOptions = {
  ...BaseViewPropOptions,
  formProp: VuePropTypes.record().def(),
  /**行为类型（新建/编辑） */
  actionType: VuePropTypes.createType<ActionTypeEnum>().def()
}

/** 参数类型 **/
export type KFolderFormPropType = ViewPropsTypeExtract<typeof KFolderFormPropOptions>

/** 事件 */
export const KFolderFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KFolderFormEmitsType = ViewEmitsTypeExtract<typeof KFolderFormEventEmits>

/**
 * 文件夹表单行为枚举
 */
export enum ActionTypeEnum {
  /**
   * 新建
   */
  NEW = 'new',
  /**
   * 编辑
   */
  EDIT = 'edit',
  /**
   * 只读
   */
  READONLY = 'readonly'
}

export interface IKFolderFormDef {
  /**
   * id
   */
  id: string
  /**
   * 名称
   */
  name: string
  /**
   * 编码
   */
  nameEn: string
  /**
   * 描述
   */
  description: string
  /**
   * 创建者id
   */
  creator: string
  /**
   * 创建时间
   */
  createTime: string
  /**
   * 父id
   */
  parentId: string
}

/**
 * 文件夹prop属性
 */
export interface IKFolderFormPropDef {
  id: string
  modelCode: string
  creatorId: string
  creatorName: string
  createTime: string
  modifierId: string
  modifierName: string
  updateTime: string
  parentId: string
  type: number
  name: string
  allowedClsCodes: string
  ownerId: string
  ownerName: string
  dynamicFolder: number
  inheritParentConfig: number
  orderId: number
  ffLinkId: string
}

import { Component, ComputedOptions, MethodOptions } from 'vue'
import { MetaProperty } from '../../../../index'
import { IFieldEditorResolver } from '../../base'
import KHyperLink from './KHyperLink.vue'
import { KDataGridHyperLinkColumn } from './KDataGridHyperLinkColumn'

export class KHyperLinkRender extends IFieldEditorResolver {
  static getColumn() {
    return new KDataGridHyperLinkColumn()
  }
  static getEditor(field: MetaProperty): string | (() => Component<any, any, any, ComputedOptions, MethodOptions>) | null {
    return () => KHyperLink
  }
}

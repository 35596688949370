import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectClassLayoutDefinitionFormView from './KObjectClassLayoutDefinitionForm.vue'
import KObjectClassLayoutDefinitionFormViewModel from './KObjectClassLayoutDefinitionFormViewModel'

const KObjectClassLayoutDefinitionForm = connect(KObjectClassLayoutDefinitionFormView, KObjectClassLayoutDefinitionFormViewModel)

export default withInstall(KObjectClassLayoutDefinitionForm)
export { KObjectClassLayoutDefinitionForm, KObjectClassLayoutDefinitionFormView, KObjectClassLayoutDefinitionFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewModel,
  EnumAttributeType,
  EnumControlElementType,
  EnumType,
  KControl,
  KLayoutConfig,
  LayoutRenderElement,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KToolStripContainerPropType, KToolStripContainerEventEmitsType, DragElement } from './interface'
import { ref } from 'vue'
import lodash from 'lodash'

/** 导航管理 */
export default class KToolStripContainerViewModel extends BaseViewModel<
  KToolStripContainerEventEmitsType,
  KToolStripContainerPropType
> {
  /** 网格布局区域,方便获取宽度 */
  refGridLayoutArea = ref({} as HTMLElement)
  /** 子元素实例映射  */
  childElementInstanceMap = ref(new Map<string, any>())

  constructor(options: ViewModelOptions<KToolStripContainerPropType>) {
    super(options)
  }

  /** 构建工具栏按钮 */
  createToolStripItem(type: EnumControlElementType) {
    const props: Record<string, any> = {}
    const layout: Record<string, any> = {}
    const base: Record<string, any> = {}

    /** 查找组件定义 */
    const elementConfig = this.props.designerMetaControl.find(a => a.control == type)

    if (!elementConfig) {
      return
    }

    // 循环创建默认值
    for (const item of elementConfig.attrs) {
      if (item.attributeType == EnumAttributeType.BASE) {
        base[item.name] = item.defaultValue
      }
      if (item.attributeType == EnumAttributeType.LAYOUT) {
        layout[item.name] = item.defaultValue
      }
      if (item.attributeType == EnumAttributeType.PROPS) {
        props[item.name] = item.defaultValue
      }
    }

    /** 生成唯一Id */
    const code = type + lodash.uniqueId()

    let result: KControl = {
      ...base,
      title: '',
      name: code,
      control: EnumControlElementType.TOOL_STRIP_BUTTON,
      type: EnumType.CONTROL,
      events: [],
      props: props,
      layout: layout as KLayoutConfig
    }

    switch (type) {
      case EnumControlElementType.TOOL_STRIP_BUTTON: {
        result.title = '按钮'
        result.control = EnumControlElementType.TOOL_STRIP_BUTTON
        break
      }
      case EnumControlElementType.TOOL_STRIP_CHECKBOX: {
        result.title = '复选按钮'
        result.control = EnumControlElementType.TOOL_STRIP_CHECKBOX
        break
      }
      case EnumControlElementType.TOOL_STRIP_DATETIME: {
        result.title = '日期'
        result.control = EnumControlElementType.TOOL_STRIP_DATETIME
        break
      }
      case EnumControlElementType.TOOL_STRIP_INPUT: {
        result.title = '输入框'
        result.control = EnumControlElementType.TOOL_STRIP_INPUT
        break
      }
      case EnumControlElementType.TOOL_STRIP_RADIO: {
        result.title = '单选按钮'
        result.control = EnumControlElementType.TOOL_STRIP_RADIO
        break
      }
      case EnumControlElementType.TOOL_STRIP_SELECT: {
        result.title = '下拉列表'
        result.control = EnumControlElementType.TOOL_STRIP_SELECT
        break
      }
      default:
        break
    }

    return lodash.cloneDeep(result) as KControl
  }

  /**
   * 布局元素选中事件
   * @param element 选中的元素
   */
  onElementSelected(element: LayoutRenderElement) {
    this.emit('elementSelected', element)
  }

  /**
   * 容器中拖入新元素时触发
   * @param dragElement 拖动元素
   */
  onElementDragIn(dragElement: DragElement) {
    let element = dragElement._underlying_vm_
    this.emit('elementDragIn', element.name)
    this.onElementSelected(element)
  }

  onPopoverClick(key: EnumControlElementType) {
    const item = this.createToolStripItem(key)

    if (!item) {
      return
    }

    this.props.elements.push(item)
    this.visible.value = false
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KStructrureGraphPropOptions, KStructrureGraphEventEmits } from './interface'
import KStructrureGraphViewModel from './KStructrureGraphViewModel'

export default defineView({
  name: 'KStructrureGraph',
  props: KStructrureGraphPropOptions,
  emits: KStructrureGraphEventEmits,
  viewModel: KStructrureGraphViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

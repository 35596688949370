import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"padding":"8px 0px","width":"100%","height":"100%"} }
const _hoisted_2 = { class: "layout-content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_class_tree = _resolveComponent("k-object-class-tree")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_KObjectClassLayoutDefinition = _resolveComponent("KObjectClassLayoutDefinition")!
  const _component_k_tab_pane = _resolveComponent("k-tab-pane")!
  const _component_class_property_list = _resolveComponent("class-property-list")!
  const _component_k_tabs = _resolveComponent("k-tabs")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_k_split_pane, {
            size: 15,
            minSize: 15,
            maxSize: 50
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_panel, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_k_object_class_tree, {
                      showAllOrNot: true,
                      onAfterSelect: _cache[0] || (_cache[0] = args => _ctx.$vm.onAfterSelect(args.node))
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_k_split_pane, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_k_tabs, {
                  activeKey: _ctx.$vm.tabActiveKey
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_tab_pane, {
                      key: "1",
                      tab: "属性布局"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_KObjectClassLayoutDefinition, {
                          clsCode: _ctx.$vm.selectedObjClsCode,
                          "cls-id": _ctx.$vm.selectedObjClsId
                        }, null, 8, ["clsCode", "cls-id"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_k_tab_pane, {
                      key: "2",
                      tab: "属性编辑"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_class_property_list, {
                          selectedNode: _ctx.$vm.selectedObjClsCode
                        }, null, 8, ["selectedNode"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["activeKey"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
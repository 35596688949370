import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  KTreeViewEventEmits,
  KTreeViewPropOptions,
  ToolStripItemClickedEventArgs,
  TreeViewMenuEventArgs,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { StructureView } from '../../../client-srv'
import { StructuredDocTreeNode } from './node/StructuredDocTreeNode'

export type StructuredDocQueryRule = {
  type: string
}
/**文档结构 */
export type StructuredDoc = {
  //结构关系Id
  linkId?: string
  //是否有子
  hasChildren?: boolean
  // 子节点
  children?: StructuredDoc[]
  //节点(文档)
  node?: DocNode
}
//文档节点
export type DocNode = ObjBusinessParam & {
  name: string
  code: string
  version: string
  iteration: string
  checkout: boolean
  checkoutPath: string
  orderId: number
  selected?: string
  file: DocInfo
  documentType: string
  folderId?: string
  number?: string
  workingState?: string
}
//文档信息
export type DocInfo = {
  id: string
  md5: string
  originalFileName: string
  size: string
  location: string
  displayLocation: string
  uploadStartTime: string
  uploadEndTime: string
}

/**事件 */
export type StructuredDocTreeEventEmits = ToolStripItemClickedEventArgs & {
  objParams: ObjBusinessParam[]
}

/** 参数 **/
export const KStructuredDocTreePropOptions = {
  ...KTreeViewPropOptions,
  /**对象参数 */
  objParams: VuePropTypes.createType<ObjBusinessParam>().def(),
  /** 视图名称 */
  viewName: VuePropTypes.string().def(''),
  /** 过滤名称 */
  filterName: VuePropTypes.string().def(''),
  /** 是否禁用右键菜单 */
  disabledContextMenu: VuePropTypes.bool().def(false),
  /**展开模式参数 */
  structuredDocViewMode: VuePropTypes.createType<StructureView>().def()
}

/** 参数类型 **/
export type KStructuredDocTreePropType = ViewPropsTypeExtract<typeof KStructuredDocTreePropOptions>

/** 事件 */
export const KStructuredDocTreeEventEmits = {
  ...KTreeViewEventEmits,
  /** 数据更新事件 */
  nodeRefresh: () => true,
  /** 右键菜单点击事件 */
  contextMenuItemClicked: (event: StructuredDocTreeEventEmits) => true,
  /** 菜单弹出之前 */
  beforeContextMenuPopup: (event: TreeViewMenuEventArgs<StructuredDocTreeNode>) => true,
  /** 刷新完成后 */
  refresh: () => true
}

/** 事件类型 **/
export type KStructuredDocTreeEmitsType = ViewEmitsTypeExtract<typeof KStructuredDocTreeEventEmits>

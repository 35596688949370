
import { defineView, exposeComponentEvents, KSelectEventEmits } from '@kmsoft/upf-core'
import { KLifecycleStateEditorPropOptions, KLifecycleStateEditorEventEmits } from './interface'
import KLifecycleStateEditorViewModel from './KLifecycleStateEditorViewModel'
import { EnumLifecycleState, EnumLifecycleStateColor } from '../../../../client-srv'

export default defineView({
  name: 'KLifecycleStateEditor',
  props: KLifecycleStateEditorPropOptions,
  emits: KLifecycleStateEditorEventEmits,
  viewModel: KLifecycleStateEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      if (vm.stateReadonly.value) {
        return (
          <div style="width:100%">
            <div class="k-render-dropdown-text" title="displayValue">
              {vm.stateValue.value ? (
                <k-tag color={EnumLifecycleStateColor[vm.stateValue.value || '']}>{vm.displayValue()}</k-tag>
              ) : (
                '--'
              )}
            </div>
          </div>
        )
      } else {
        return <k-select ref={vm.refSelect.value} {...props} value={vm.stateValue.value} options={EnumLifecycleState._list} />
      }
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KPartDocBomCompareModeFormPropOptions, KPartDocBomCompareModeFormEventEmits } from './interface'
import KPartDocBomCompareModeFormViewModel from './KPartDocBomCompareModeFormViewModel'

export default defineView({
  name: 'KPartDocBomCompareModeForm',
  props: KPartDocBomCompareModeFormPropOptions,
  emits: KPartDocBomCompareModeFormEventEmits,
  viewModel: KPartDocBomCompareModeFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

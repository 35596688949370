import lodash from 'lodash'
import { App, DirectiveBinding, VNode } from 'vue'

export const useGlobalComponentDirective = (app: App) => {
  // 增加钩子函数
  app.directive('kc', {
    beforeMount(el: HTMLElement, binding, vnode) {},
    // 挂载后
    mounted(el: HTMLElement, binding, vnode) {
      addAttribute(el, binding, vnode)
    },
    updated(el: HTMLElement, binding, vnode) {
      addAttribute(el, binding, vnode)
    },
    unmounted() {}
  })

  const addAttribute = (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
    /** 获取组件名称 */
    const componentName = binding.instance?.$.type.name
    /** 获取自定义名称 */
    const customComponentId = binding.instance?.$attrs['v-kc'] as string

    // 增加组件class标记
    el.classList.add('k-c')

    // 如果组件名称正常
    if (componentName) {
      // 添加焦点属性
      el.setAttribute('data-kc-name', lodash.kebabCase(componentName))

      /** 组件Id */
      const componentId = `${lodash.camelCase(componentName.substring(1))}${1}`
      /** 最终使用Id */
      const cid = binding.value || customComponentId || componentId

      // 添加焦点属性
      el.setAttribute('data-kc-id', cid)
    } else {
      console.warn('组件名不存在，请检查组件信息')
    }
  }
}

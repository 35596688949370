
import { defineView } from '@kmsoft/upf-core'
import { KObjectClassGridFindPropOptions, KObjectClassGridFindEventEmits } from './interface'
import { KObjectClassGridFindViewModel } from './KObjectClassGridFindViewModel'
/**
 * 对象查找面板（find）
 */
export default defineView({
  name: 'KObjectClassGridFind',
  emits: KObjectClassGridFindEventEmits,
  props: KObjectClassGridFindPropOptions,
  viewModel: KObjectClassGridFindViewModel,
  setup(props, { emit, vm }) {
    /** 表单规则 */
    const rules: Record<string, Array<any>> = {
      selectedFieldCode: [{ required: true, message: '请选择查找自动', trigger: 'blur' }],
      selectedOperator: [{ required: true, message: '请选择比较类型', trigger: 'blur' }],
      fieldValue: [{ required: true, message: '请输入查找值', trigger: 'blur' }]
    }

    return {
      rules
    }
  }
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KObjectClassTree = _resolveComponent("KObjectClassTree")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_KPropertyGrid = _resolveComponent("KPropertyGrid")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_split_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_k_split_pane, {
          size: 15,
          minSize: "15",
          maxSize: "50"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_KObjectClassTree, {
              ref: "refObjectClassTree",
              onAfterSelect: _cache[0] || (_cache[0] = (node) => _ctx.afterSelect(node)),
              onBeforeSelect: _ctx.$vm.clearSelectRows
            }, null, 8, ["onBeforeSelect"])
          ]),
          _: 1
        }),
        _createVNode(_component_k_split_pane, null, {
          default: _withCtx(() => [
            _createVNode(_component_KPropertyGrid, {
              ref: "refPropertyGrid",
              modelCode: _ctx.$vm.modelCode
            }, null, 8, ["modelCode"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
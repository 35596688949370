/**定义驱动器类型常数，包括 CDRom、Fixed、Network、NoRootDirectory、Ram、Removable 和 Unknown。*/
export enum DriveType {
  /**驱动器类型未知。*/
  Unknown = 0,

  /**驱动器没有根目录。*/
  NoRootDirectory = 1,

  /**驱动器是一个可移动存储设备，如 U 盘。*/
  Removable = 2,

  /**驱动器是一个固定磁盘。*/
  Fixed = 3,

  /**驱动器是一个网络驱动器。*/
  Network = 4,

  /**驱动器是一个光盘设备，如 CD 或 DVD-ROM。*/
  CDRom = 5,

  /**驱动器是一个 RAM 磁盘。*/
  Ram = 6
}

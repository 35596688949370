import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeActivityUpdateCompareGridView from './KChangeActivityUpdateCompareGrid.vue'
import KChangeActivityUpdateCompareGridViewModel from './KChangeActivityUpdateCompareGridViewModel'

const KChangeActivityUpdateCompareGrid = connect(KChangeActivityUpdateCompareGridView, KChangeActivityUpdateCompareGridViewModel)

export default withInstall(KChangeActivityUpdateCompareGrid)
export { KChangeActivityUpdateCompareGrid, KChangeActivityUpdateCompareGridView, KChangeActivityUpdateCompareGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KRequirementChangeCreateFormPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KRequirementChangeCreateFormPropType = ViewPropsTypeExtract<typeof KRequirementChangeCreateFormPropOptions>

/** 事件 */
export const KRequirementChangeCreateFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KRequirementChangeCreateFormEmitsType = ViewEmitsTypeExtract<typeof KRequirementChangeCreateFormEventEmits>

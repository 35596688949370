import lodash from 'lodash'

/** 正则表达式帮助类 */
export class RegexHelper {
  /** 整数正则表达式 */
  public static readonly regexExpInt: RegExp = /^(-)?[0-9]+$/
  /** 正整数正则表达式 */
  public static readonly regexExpPositiveInt: RegExp = /^[1-9][0-9]*$/

  /** 是否包含数字 */
  static isNumber(str: unknown): str is number {
    return lodash.isNumber(str)
  }

  /** 是否包含数字(小数) */
  static isDecimal(str: string): boolean {
    if (!str) return false
    return new RegExp(/^[+-]?\d*[.]?\d*$/).test(str)
  }

  /** 是否包含数字 */
  static hasNumber(str: string): boolean {
    if (!str) return false
    return new RegExp(/[0-9]/).test(str)
  }

  /** 是否包含字母 */
  static hasChar(str: string): boolean {
    if (!str) return false
    return new RegExp(/[a-z]/, 'ig').test(str)
  }

  /** 是否包含小写字母 */
  static hasLowerChar(str: string): boolean {
    if (!str) return false
    return new RegExp(/[a-z]/).test(str)
  }

  /** 是否包含大写字母 */
  static hasUpperChar(str: string): boolean {
    if (!str) return false
    return new RegExp(/[A-Z]/).test(str)
  }

  /** 是否是rtf */
  static isRtf(str: string | undefined): boolean {
    const SZ_RTF_TAG = '{\\rtf'
    if (!str) return false
    return str.length >= SZ_RTF_TAG.length && str.substring(0, SZ_RTF_TAG.length) == SZ_RTF_TAG
  }
}

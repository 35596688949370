import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeRequestEditView from './KChangeRequestEdit.vue'
import KChangeRequestEditViewModel from './KChangeRequestEditViewModel'

const KChangeRequestEdit = connect(KChangeRequestEditView, KChangeRequestEditViewModel)

export default withInstall(KChangeRequestEdit)
export { KChangeRequestEdit, KChangeRequestEditView, KChangeRequestEditViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { ObjBusinessParam, ObjectPanelAttachParams, ObjectPanelDataUpdatedEvent, ObjectPanelTabRetrieveEvent } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartStructPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  /** 附属参数 */
  attachParams: VuePropTypes.createType<ObjectPanelAttachParams>().def({})
}

/** 参数类型 **/
export type KPartStructPropType = ViewPropsTypeExtract<typeof KPartStructPropOptions>

/** 事件 */
export const KPartStructEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 标签页更新事件x
   * @param event
   * @returns
   */
  dataUpdated: (event: ObjectPanelDataUpdatedEvent) => true,
   /**
   * 自定义事件
   * @param event
   * @returns
   */
   retrieve: (event: ObjectPanelTabRetrieveEvent<any, any>) => true,
}

/** 事件类型 **/
export type KPartStructEmitsType = ViewEmitsTypeExtract<typeof KPartStructEventEmits>

/**
 * 零部件单位定义
 */
export interface IQuantityUnit {
  /**
   * id
   */
  id: string
  /**
   * 名称
   */
  name: string
  /**
   * 业务编码
   */
  businessCode: string
  /**
   * 计量单位
   */
  unitSymbol: string
}

import {
  IKTreeNode,
  KTreeNode,
  KTreeViewEventEmitsType,
  KTreeViewPropType,
  VNodeProps,
  defineView,
  exposeComponentEvents
} from '@kmsoft/upf-core'
import KObjectClassTreeViewModel from './KObjectClassTreeViewModel'
import { KObjectClassTreeEventEmits, KObjectClassTreePropOptions } from './interface'
import KObjectClassTreeRootNode from './node/KTreeNodeRootObjClass'

export default defineView({
  name: 'KObjectClassTree',
  props: KObjectClassTreePropOptions,
  emits: KObjectClassTreeEventEmits,
  viewModel: KObjectClassTreeViewModel,
  setup(props, { vm, emit }) {
    const onTreeReady = async (): Promise<void> => {
      //1. 如果设置了仅显示特定对象大类的节点，则默认选择第一个对象大类
      // if (props.showObjClsCodes && props.showObjClsCodes.length > 0) {
      //   const node = vm.refTreeView.value?.getRootNodes() as Array<KTreeNode>
      //   vm.refTreeView.value?.expandByPath(node[0], props.showObjClsCodes[0], true)
      //   return
      // }

      //2. 如果设置了默认选中的节点,则选中指定节点
      if (props.defaultSelectClsCode) {
        await vm.selectNode(props.defaultSelectClsCode)
        return
      }

      //3. 如果设置了仅显示特定对象类模板的节点，则默认选中第一个对象类节点

      //4. 如果设置了隐藏对象大类，也需要选中第一个对象类节点
      const rootNodes = vm.refTreeView.value?.getRootNodes() as Array<KTreeNode>
      if (!rootNodes || rootNodes.length == 0) {
        return
      }

      const node = rootNodes[0]

      while (true) {
        vm.refTreeView.value?.setSelectedNode(node.key)
        break
      }
    }

    /**
     * 加载树数据
     */
    const onLoadTreeData = async () => {
      // 如果不包装根节点
      if (!props.autoWrapRoot) {
        const rootNode = new KObjectClassTreeRootNode()
        rootNode.__getHost = () => vm
        return await rootNode.populateChildren(0)
      } else {
        const rootNode = new KObjectClassTreeRootNode()
        return [rootNode]
      }
    }

    /** 继承事件 */
    const exposedEvents = exposeComponentEvents(vm.refTreeView, Object.keys(KObjectClassTreeEventEmits), emit)

    return () => {
      /** 树参数 */
      const treeViewProps: VNodeProps<KTreeViewPropType, KTreeViewEventEmitsType> = {
        ref: vm.refTreeView,
        ...props,
        class: 'k-object-class-tree',
        enableShortcut: true,
        autoWrapRoot: false,
        defaultExpandAll: true,
        loadData: onLoadTreeData,
        unselectablePredicate: (node: IKTreeNode | null) => node?.id === '__root__',
        onReady: onTreeReady,
        getHost: () => {
          return vm
        },
        ...exposedEvents
      }

      return <k-tree-view {...treeViewProps} />
    }
  }
})

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KPartExcelExportEmitsType, KPartExcelExportPropType } from './interface'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KPartExcelExport */
export default class KPartExcelExportViewModel extends BaseViewModel<KPartExcelExportEmitsType, KPartExcelExportPropType> {
  plainOptions = ref<Record<string, any>[]>([])
  checkValue = ref<string[]>([])
  originValue = ref<Record<string, any>[]>([])
  constructor(options: ViewModelOptions<KPartExcelExportPropType>) {
    super(options)
  }

  viewDidMount() {
    this.getExcelColumns()
  }

  async getExcelColumns() {
    const result = await Api.post('sys', 'ConfigSysQueryService', 'listGridConfigByCode', {
      data: ['SYS_PART_EXPORT_COLUMN_MAPPING']
    })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.originValue.value = result.data
      this.plainOptions.value = result.data.map((item: any) => {
        this.checkValue.value.push(item.property_name)
        return { label: item.column_name, value: item.property_name, disabled: item.property_required == '是' }
      })
    }
  }

  getValue() {
    return this.originValue.value.filter(item => {
      return this.checkValue.value.includes(item.property_name)
    })
  }
}

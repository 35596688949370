
import { defineView } from '@kmsoft/upf-core'
import { KChangeRequestCreatePropOptions, KChangeRequestCreateEventEmits } from './interface'
import KChangeRequestCreateViewModel from './KChangeRequestCreateViewModel'

export default defineView({
  name: 'KChangeRequestCreate',
  props: KChangeRequestCreatePropOptions,
  emits: KChangeRequestCreateEventEmits,
  viewModel: KChangeRequestCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { EnumLabelPosition, KBaseElement, KGridLayoutConfig, defineView } from '@kmsoft/upf-core'
import { KViewRenderElementWrapPropOptions, KViewRenderElementWrapEventEmits } from './interface'
import KViewRenderElementWrapViewModel from './KViewRenderElementWrapViewModel'
import { renderSlot } from 'vue'

export default defineView({
  name: 'KViewRenderElementWrap',
  props: KViewRenderElementWrapPropOptions,
  emits: KViewRenderElementWrapEventEmits,
  viewModel: KViewRenderElementWrapViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /**
     * 创建标签
     * @param labelPosition
     * @param labelStyle
     * @param title
     * @param nullable
     * @returns
     */
    const createLabelDivNode = (labelPosition: string, labelStyle: string, title: string, nullable?: boolean) => {
      let nullableTip = nullable == false ? <span class="k-view-render-element-wrap-required-label">*</span> : null

      switch (labelPosition) {
        case 'hidden':
          return <div class="k-view-render-element-wrap-label" style={labelStyle}></div>
        case 'top':
          return (
            <div class="k-view-render-element-wrap-label" style={labelStyle}>
              <div title={title} class="k-view-render-element-wrap-label-name">
                {nullableTip}
                <span class="k-view-render-element-wrap-label-value">{title}</span>
              </div>
              <div>:</div>
            </div>
          )
        default:
          return (
            <div class="k-view-render-element-wrap-label" style={labelStyle}>
              <div title={title} class="k-view-render-element-wrap-label-name">
                {nullableTip}
                <span class="k-view-render-element-wrap-label-value">{title}</span>
              </div>
              <div>:</div>
            </div>
          )
      }
    }

    /**
     * 创建标题及栏位样式
     * @param element
     * @param elementParent
     * @returns
     */
    const createInputChunkStyle = (element: KBaseElement, elementParent?: KGridLayoutConfig) => {
      /** 优先从当前控件获取labelPosition，若取不到则取父的labelPosition */
      const labelPosition = element.props['labelPosition'] ? element.props['labelPosition'] : elementParent?.labelPosition
      const result = createInputChunkStyleDefault(element, elementParent)

      return { ...result, labelPosition: labelPosition }
    }

    /**
     * 标题居左，默认样式
     * @param el
     * @param parentLayout
     * @returns
     */
    const createInputChunkStyleDefault = (el: KBaseElement, parentLayout?: KGridLayoutConfig) => {
      // 优先从当前控件获取labelPosition，若取不到则取父的labelPosition
      const labelPosition = el.props['labelPosition'] ? el.props['labelPosition'] : parentLayout?.labelPosition
      const labelWidth = parentLayout?.labelWidth
      // 标题样式
      let labelStyle = ''

      switch (labelPosition) {
        case EnumLabelPosition.LEFT:
          labelStyle = `width: ${labelWidth}px`
          break
        case EnumLabelPosition.TOP:
          labelStyle = ``
          break
        case EnumLabelPosition.HIDDEN:
          labelStyle = ``
          break
      }

      // 块的上级样式
      let wholeStyle = `grid-row-start:span ${el.props.rowSpan};`

      if (el.props.entireRow || el.props.fill) {
        wholeStyle += `grid-column:1/-1;`
      } else {
        wholeStyle += `grid-column-start:span ${el.props.colSpan};`
      }

      return { labelStyle, wholeStyle }
    }

    return () => {
      /** 属性 */
      const { labelPosition, labelStyle, wholeStyle } = createInputChunkStyle(props.meta, props.parentLayout)

      return (
        <div class={['k-view-render-element-wrap', `${labelPosition}-label`]} style={wholeStyle}>
          {createLabelDivNode(labelPosition, labelStyle, props.meta.title, props.meta.nullable)}
          <div class="k-view-render-element-wrap-control">{renderSlot(slots, 'default')}</div>
        </div>
      )
    }
  }
})

import {
  BaseViewModel,
  KDataGridViewModel,
  IKTreeNode,
  KToolStripViewModel,
  KTreeViewViewModel,
  KDialog,
  EnumDialogResult,
  EnumDialogSize,
  KDialogClosingEvent
} from '@kmsoft/upf-core'
import { KPageForm, KPageFormViewModel } from './page-form'
import { KPageMOdelingPropType, KPageMOdelingEventEmitsType } from './interface'
import { ref } from 'vue'

/**
 * 页面建模视图模型
 * 使用方式一：ViewModel作为组织者，不操作状态，只负责调度各个子模型的行为
 * 适用场景：子控件都已经有完善的ViewModel对应，通过操作子ViewModel的行为更便捷，例如网格ViewModel，新增一行
 */
export class KPageModelingViewModel extends BaseViewModel<KPageMOdelingEventEmitsType, KPageMOdelingPropType> {
  refObjClsTree = ref<KTreeViewViewModel>()
  refObjClsGrid = ref<KDataGridViewModel>()
  refObjClsToolStrip = ref<KToolStripViewModel>()

  /**
   * 加载完成函数
   */
  viewDidMount() {
    // 设置树组件的数据源
    this.refObjClsTree.value?.setDataSource(this.getTreeData())
    this.refObjClsGrid.value?.setDataSource(this.getGridData())
  }

  // 新建
  addPage() {
    KDialog.show({
      title: '创建页面',
      size: EnumDialogSize.Normal,
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPageForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPageFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return

        const newPageData = formViewModel.getValue()
        this.refObjClsGrid.value?.insertRow(newPageData)
      }
    })
  }

  // 创建新版本命令
  newPageVersion() {}

  // 新增子页面
  newChildPage() {
    KDialog.info({ title: '信息', content: '成功新增' })
  }

  // 删除命令
  deletePage() {
    const rows = this.refObjClsGrid.value?.getSelectedRows()
    if (rows && rows.length > 0) {
      // 这里参数太多，不利于使用，confirm接口的参数应该是明确的
      KDialog.confirm({
        title: '确认删除吗？删除后不可恢复!',
        onOk: async () => {
          // 校验是否可以删除
          const deletingRowIds = rows.map(row => row.id)
          this.refObjClsGrid.value?.removeRow(deletingRowIds)

          KDialog.success({ title: '提示', content: '删除成功' })
        }
      })
    }
  }

  /**
   * 获取网格数据
   * @returns 网格数据
   */
  private getGridData() {
    return [
      { id: '1001', name: '页面测试1', symbol: 'page-01', suffix: 'ecol' },
      { id: '1002', name: '页面测试2', symbol: 'page-01', suffix: 'ecol' },
      { id: '1003', name: '页面测试3', symbol: 'page-01', suffix: 'ecol' },
      { id: '1004', name: '页面测试4', symbol: 'page-01', suffix: 'ecol' },
      { id: '1005', name: '页面测试5', symbol: 'page-01', suffix: 'ecol' }
    ]
  }

  /**
   * 获取树数据
   * @returns 树数据
   */
  private getTreeData(): Array<IKTreeNode> {
    return [
      {
        id: '60001',
        name: '全局模块',
        iconType: 'appstore',
        leaf: true,
        orderId: 1,
        children: [],
        nodeType: 2,
        valid: true,
        predefined: true
      },
      {
        id: '60002',
        name: '零部件管理',
        iconType: 'cluster',
        leaf: true,
        orderId: 1,
        children: [],
        nodeType: 2,
        valid: true,
        predefined: true
      },
      {
        id: '60003',
        name: '文档管理',
        iconType: 'cluster',
        leaf: true,
        orderId: 1,
        children: [],
        nodeType: 2,
        valid: true,
        predefined: true
      }
    ]
  }
}

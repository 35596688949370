
import { defineView } from '@kmsoft/upf-core'
import { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KChangeRequestAffectedViewsGridPropOptions, KChangeRequestAffectedViewsGridEventEmits } from './interface'
import KChangeRequestAffectedViewsGridViewModel from './KChangeRequestAffectedViewsGridViewModel'

export default defineView({
  name: 'KChangeRequestAffectedViewsGrid',
  props: KChangeRequestAffectedViewsGridPropOptions,
  emits: KChangeRequestAffectedViewsGridEventEmits,
  viewModel: KChangeRequestAffectedViewsGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor }
  }
})

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KDocBatchCreateBasePropOptions, KDocBatchCreateBaseEmitsOptions } from '../interface'

/** 参数 **/
export const KDocExtractFilesPropOptions = {
  ...BaseViewPropOptions,
  ...KDocBatchCreateBasePropOptions
}

/** 参数类型 **/
export type KDocExtractFilesPropType = ViewPropsTypeExtract<typeof KDocExtractFilesPropOptions>

/** 事件 */
export const KDocExtractFilesEventEmits = {
  ...BaseViewEventEmits,
  ...KDocBatchCreateBaseEmitsOptions
}

/** 事件类型 **/
export type KDocExtractFilesEmitsType = ViewEmitsTypeExtract<typeof KDocExtractFilesEventEmits>

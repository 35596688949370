
import { defineView } from '@kmsoft/upf-core'
import { KCommonValidationMetricsPropOptions, KCommonValidationMetricsEventEmits } from './interface'
import KCommonValidationMetricsViewModel from './KCommonValidationMetricsViewModel'
import KFolderTree from '../../../../ebf-folder/src/pages/folder-manage/folder-tree'
import KCommonFolderManage from '../../../../ebf-folder/src/pages/common-folder-manage'
import { FolderTypeEnum } from '../../../../ebf-common'

export default defineView({
  name: 'KCommonValidationMetrics',
  props: KCommonValidationMetricsPropOptions,
  emits: KCommonValidationMetricsEventEmits,
  components: { KFolderTree, KCommonFolderManage }, // 这里定义局部组件
  viewModel: KCommonValidationMetricsViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    //文件夹类型
    const folderType = FolderTypeEnum.COMMON_VALIDATION_METRICS_LIBRARY
    //操作栏
    const operatorColumn = [
      { key: 'edit', title: '查看' }
  /*     { key: 'delete', title: '删除' } */
    ]

    return {
      folderType,
      operatorColumn
    }
  }
})

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KCreatorEditorPropOptions = {
  ...BaseViewPropOptions,
  value: VuePropTypes.createType<any>().def(),
  readonly: VuePropTypes.bool().def(false),
  disabled: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KCreatorEditorPropType = ViewPropsTypeExtract<typeof KCreatorEditorPropOptions>

/** 事件 */
export const KCreatorEditorEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KCreatorEditorEmitsType = ViewEmitsTypeExtract<typeof KCreatorEditorEventEmits>

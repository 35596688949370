import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_object_relation_grid = _resolveComponent("k-object-relation-grid")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createBlock(_component_k_split_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_pane, {
        size: 25,
        "min-size": 10,
        "max-size": 40
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_tree_view, {
            ref: _ctx.$vm.refRelationTree,
            "node-key": 'id',
            "auto-wrap-root": true,
            "auto-select-last-node": false,
            "default-expanded-level": 1,
            "unselectable-predicate": node => !node?.leaf,
            onAfterSelect: _cache[0] || (_cache[0] = event => _ctx.$vm.relationTree_AfterSelect(event))
          }, null, 8, ["unselectable-predicate"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_split_pane, null, {
        default: _withCtx(() => [
          _createVNode(_component_k_object_relation_grid, {
            ref: _ctx.$vm.refRelationGrid,
            "obj-cls-code": _ctx.$vm.currentRelation?.destinationClsCode ?? '',
            "obj-param": _ctx.objParam,
            definition: _ctx.$vm.currentRelation
          }, null, 8, ["obj-cls-code", "obj-param", "definition"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
import { connect, withInstall } from '@kmsoft/upf-core'
import KDistributionContentObjGridView from './KDistributionContentObjGrid.vue'
import KDistributionContentObjGridViewModel from './KDistributionContentObjGridViewModel'

const KDistributionContentObjGrid = connect(KDistributionContentObjGridView, KDistributionContentObjGridViewModel)

export default withInstall(KDistributionContentObjGrid)
export { KDistributionContentObjGrid, KDistributionContentObjGridView, KDistributionContentObjGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView } from '@kmsoft/upf-core'
import { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KIssueReportAffectedViewsGridPropOptions, KIssueReportAffectedViewsGridEventEmits } from './interface'
import KIssueReportAffectedViewsGridViewModel from './KIssueReportAffectedViewsGridViewModel'

export default defineView({
  name: 'KIssueReportAffectedViewsGrid',
  props: KIssueReportAffectedViewsGridPropOptions,
  emits: KIssueReportAffectedViewsGridEventEmits,
  viewModel: KIssueReportAffectedViewsGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor }
  }
})

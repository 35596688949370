
import { EnumDataGridRowModelType, IQuery, defineView } from '@kmsoft/upf-core'
import { KTreeGridDemoPropOptions, KTreeGridDemoEventEmits } from './interface'
import KTreeGridDemoViewModel from './KTreeGridDemoViewModel'

export default defineView({
  name: 'KTreeGridDemo',
  props: KTreeGridDemoPropOptions,
  emits: KTreeGridDemoEventEmits,
  viewModel: KTreeGridDemoViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumDataGridRowModelType }
  }
})

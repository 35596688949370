import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  RenderLayoutConfig,
  RenderLayoutElement
} from '@kmsoft/upf-core'

/** 布局类型 */
export enum EnumRenderLayoutType {
  GRID = 'grid',
  FLEX = 'flex',
  BLOCK = 'block'
}

export type RenderLayoutBaseEvent<E = RenderLayoutElement> = {
  item: E
}

/** 拖拽时的节点类型 */
export interface DragElement<E = RenderLayoutElement> {
  _underlying_vm_: E
}

/** 插槽参数 */
export type RenderLayoutItemSlotProps<E extends RenderLayoutElement = RenderLayoutElement> = {
  /** 元素 */
  element: E
  /** 索引 */
  index: number
}

/** 参数 **/
export const KRenderLayoutPropOptions = {
  ...BaseViewPropOptions,
  /** 设计模式 */
  designMode: VuePropTypes.bool().def(false),
  /** 布局类型 */
  layoutType: VuePropTypes.createType<EnumRenderLayoutType>().def(EnumRenderLayoutType.GRID),
  /** 元素集合 */
  elements: VuePropTypes.createType<Array<RenderLayoutElement>>()
    .setRequired()
    .def([]),
  /** 容器配置 */
  containerConfig: VuePropTypes.createType<RenderLayoutConfig>().def(),
  /**
   * 选择的元素
   * @description 元素名称
   */
  selectedElement: VuePropTypes.createType<RenderLayoutElement>().def()
}

/** 参数类型 **/
export type KRenderLayoutPropType = ViewPropsTypeExtract<typeof KRenderLayoutPropOptions>

/** 事件 */
export const KRenderLayoutEventEmits = {
  ...BaseViewEventEmits,
  /** 添加 */
  add: (event: RenderLayoutBaseEvent<any>) => true,
  /**  */
  end: (event: RenderLayoutBaseEvent<any>) => true,
  /** 删除 */
  delete: (element: RenderLayoutElement) => true,
  /** v-model */
  'update:selectedElement': (element: RenderLayoutElement) => true,
  /** v-model */
  'update:elements': (elements: Array<RenderLayoutElement>) => true
}

/** 事件类型 **/
export type KRenderLayoutEmitsType = ViewEmitsTypeExtract<typeof KRenderLayoutEventEmits>

import { BaseViewModel, KFormViewModel, KNotification, utils } from '@kmsoft/upf-core'
import { IConfigDefinition, KConfigFormEventEmitsType, KConfigFormViewModelPropType } from './interface'
import { EnumDataType, EnumEditType, EnumTabType, IValueDesc } from '../../interface'
import { ref } from 'vue'
import { Api } from '@kmsoft/ebf-common'

export default class ConfigFormViewModel extends BaseViewModel<KConfigFormEventEmitsType, KConfigFormViewModelPropType> {
  refConfigForm = ref<KFormViewModel<IConfigDefinition>>()

  dataTypeOptions = ref<Array<IValueDesc>>()
  editTypeOptions = ref<Array<IValueDesc>>()
  /** 是否可编辑 */
  changeAvailable = ref<boolean>(true)
  /** 是否可见勾选框在系统配置模式下可用, 其它模式下不可用 */
  visibleAvailable = ref<boolean>(false)
  /** 是否可用 */
  disabledAvailable = ref<boolean>(false)
  formState = ref<IConfigDefinition>({
    visible: true,
    code: '',
    name: '',
    dataType: '',
    editType: '',
    editParam: '',
    editParamLabel: '',
    remark: ''
  } as IConfigDefinition)

  private row: IConfigDefinition
  private oper: string
  private mode: string

  /**
   * 加载完成函数
   */
  viewDidMount() {
    this.row = this.props.row!
    this.oper = this.props.oper
    this.mode = this.props.mode
    if (this.oper !== 'insert') {
      this.row.visible = this.row.visible === true || this.row.visible === '1'
      this.changeAvailable.value = false
    } else {
      // 新增时默认勾选可见状态
      this.row.visible = true
    }
    // 自定义模式下, 部分属性不可用
    this.visibleAvailable.value = this.mode === EnumTabType.SYS
    this.formState.value = this.row
    console.info(this.formState)
    this.dataTypeOptions.value = this.initDataTypeOptions()
    this.editTypeOptions.value = this.initEditTypeOptions(this.row.dataType)
  }

  /**
   * 获取表单的值
   * @returns 表单的值
   */
  public getValue() {
    return this.formState.value
  }

  public async save(): Promise<any> {
    const formState = this.getValue()
    /** 编码 值默认加上前缀系统配置 */
    let code = this.row.id ? this.row.code : formState?.code
    if (this.mode == EnumTabType.SYS) {
      code = code.startsWith('SYS_') ? code : 'SYS_' + code
    } else if (this.mode == EnumTabType.DEV) {
      code = code.startsWith('DEV_') ? code : 'DEV_' + code
    }
    if (this.row.id) {
      const value = {
        treeId: this.row.treeId,
        id: this.row.id,
        code: code,
        parentCode: this.row.parentCode,
        name: formState?.name,
        type: this.row.type,
        remark: formState?.remark,
        orderId: this.row.orderId,
        dataType: this.row.dataType,
        editType: formState?.editType,
        editParam: formState?.editParam,
        visible: formState?.visible ?? false ? '1' : ('0' as unknown)
      } as IConfigDefinition
      const param = {
        data: [value]
      }
      const modelCode = this.mode === EnumTabType.SYS ? 'ConfigSysService' : 'ConfigDevService'
      const behavior = this.mode === EnumTabType.SYS ? 'updateConfigSysDef' : 'updateConfigDevDef'
      return Api.post('sys', modelCode, behavior, param)
    } else {
      const value = {
        treeId: null as unknown,
        id: null as unknown,
        code: code,
        parentCode: this.row.parentCode,
        name: formState?.name,
        type: this.row.type,
        remark: formState?.remark,
        orderId: this.row.orderId,
        dataType: formState?.dataType,
        editType: formState?.editType,
        editParam: formState?.editParam,
        visible: formState?.visible ?? false ? '1' : ('0' as unknown),
        moduleCode: this.row.moduleCode
      } as IConfigDefinition
      const param = {
        data: [value]
      }
      const modelCode = this.mode === EnumTabType.SYS ? 'ConfigSysService' : 'ConfigDevService'
      const behavior = this.mode === EnumTabType.SYS ? 'insertConfigSysDef' : 'insertConfigDevDef'
      return Api.post('sys', modelCode, behavior, param)
    }
  }

  public async validate() {
    try {
      await this.refConfigForm.value?.validate()
      // 增加配置参数规则校验
      if (!this.validateEditParam(this.formState.value)) {
        return false
      }
      return true
    } catch (error) {
      return false
    }
  }

  /**
   * 初始化[数据类型]下拉选项
   * @returns 下拉选项
   */
  private initDataTypeOptions(): Array<IValueDesc> {
    return [
      {
        label: '单值',
        value: EnumDataType.VALUE
      },
      {
        label: '集合',
        value: EnumDataType.GRID
      },
      {
        label: '自定义',
        value: EnumDataType.CUSTOM
      }
    ]
  }

  /**
   * 改变[数据类型]下拉框事件
   * @param args 事件
   */
  public selectDataType(args: any): void {
    const selected = args.newValue
    const formState = this.formState.value
    if (formState) {
      formState.editType = ''
      formState.editParamLabel = ''
      this.formState.value = formState
    }
    this.editTypeOptions.value = this.initEditTypeOptions(selected)
  }

  /**
   * 初始化[编辑方式]下拉选项
   * @param dataType 数据类型
   * @returns 下拉选项
   */
  private initEditTypeOptions(dataType: string): Array<IValueDesc> {
    if (utils.isEmpty(dataType)) {
      return []
    }
    if (dataType === EnumDataType.VALUE) {
      return [
        {
          label: '文本框',
          value: EnumEditType.TEXT
        },
        {
          label: '多行文本框',
          value: EnumEditType.TEXTAREA
        },
        {
          label: '下拉菜单',
          value: EnumEditType.SELECT
        },
        {
          label: '可编辑下拉菜单',
          value: EnumEditType.SELECT_EDITABLE
        },
        {
          label: '带显示值下拉菜单',
          value: EnumEditType.SELECT_DISPLAY
        },
        {
          label: '开关',
          value: EnumEditType.SWITCH
        },
        {
          label: '单选框',
          value: EnumEditType.RADIO
        }
      ]
    }
    if (dataType === EnumDataType.GRID) {
      return [
        {
          label: '多选框',
          value: EnumEditType.COMBOBOX
        },
        // {
        //   label: '列表',
        //   value: EnumEditType.LIST
        // },
        {
          label: '表格',
          value: EnumEditType.TABLE
        }
      ]
    }
    if (dataType === EnumDataType.CUSTOM) {
      return [
        {
          label: '自定义编辑方式',
          value: EnumEditType.CUSTOM
        },
        {
          label: '对象类',
          value: EnumEditType.OBJ
        }
      ]
    }
    return []
  }

  /**
   * 改变[编辑方式]下拉框事件
   * @param args 事件
   */
  public selectEditType(args: any): void {
    const selected = args.newValue
    let editParamLabel = ''
    this.disabledAvailable.value = false
    switch (selected) {
      case EnumEditType.COMBOBOX:
      case EnumEditType.SELECT:
      case EnumEditType.SELECT_EDITABLE:
      case EnumEditType.RADIO:
        editParamLabel = '格式: {"candidate": ["选项1", "选项2", "选项3"]}'
        break
      case EnumEditType.SELECT_DISPLAY:
        editParamLabel =
          '格式: {"candidate": [{"displayedValue": "选项1显示值","actualValue": "选项1存储值"},{"displayedValue": "选项2显示值","actualValue": "选项2存储值"}]}'
        break
      case EnumEditType.TABLE:
        editParamLabel = '格式: {"columns": [{"name": "列1字段","text": "列1显示名"},{"name": "列2字段","text": "列2显示名"}]}'
        break
      case EnumEditType.TEXT:
      case EnumEditType.TEXTAREA:
      case EnumEditType.SWITCH:
        /** 编辑方式为文本框、开关的配置项，配置参数不可用 */
        this.disabledAvailable.value = true
        break
      default:
        editParamLabel = ''
        break
    }
    const formState = this.formState.value
    if (formState) {
      formState.editParamLabel = editParamLabel
      this.formState.value = formState
    }
  }

  private validateEditParam(formState: IConfigDefinition | undefined): boolean {
    const editParam = formState?.editParam ?? ''
    let validTag = true
    switch (formState?.editType) {
      case EnumEditType.COMBOBOX:
      case EnumEditType.SELECT:
      case EnumEditType.SELECT_EDITABLE:
      case EnumEditType.RADIO:
        validTag = this.isValidJSONFormatForSelect(editParam)
        break
      case EnumEditType.SELECT_DISPLAY:
        validTag = this.isValidJSONFormatForSelectDisplay(editParam)
        break
      case EnumEditType.TABLE:
        validTag = this.isValidJSONFormatForTable(editParam)
        break
      default:
        break
    }
    if (!validTag) {
      KNotification.error({
        title: '提示',
        content: '配置参数不符合规范'
      })
    }
    return validTag
  }

  private isValidJSONFormatForSelect(input: string): boolean {
    try {
      const obj = JSON.parse(input)
      if (typeof obj === 'object' && 'candidate' in obj && Array.isArray(obj.candidate)) {
        for (let i = 0; i < obj.candidate.length; i++) {
          if (typeof obj.candidate[i] !== 'string') {
            return false
          }
        }
        return true
      }
      return false
    } catch (error) {
      return false
    }
  }

  private isValidJSONFormatForSelectDisplay(input: string): boolean {
    try {
      const obj = JSON.parse(input)
      if (typeof obj === 'object' && 'candidate' in obj && Array.isArray(obj.candidate)) {
        for (let i = 0; i < obj.candidate.length; i++) {
          const item = obj.candidate[i]
          if (!(typeof item === 'object' && 'displayedValue' in item && 'actualValue' in item)) {
            return false
          }
          if (typeof item.displayedValue !== 'string' || typeof item.actualValue !== 'string') {
            return false
          }
        }
        return true
      }
      return false
    } catch (error) {
      return false
    }
  }

  private isValidJSONFormatForTable(input: string): boolean {
    try {
      const obj = JSON.parse(input)
      if (typeof obj === 'object' && 'columns' in obj && Array.isArray(obj.columns)) {
        for (let i = 0; i < obj.columns.length; i++) {
          const column = obj.columns[i]
          if (!(typeof column === 'object' && 'name' in column && 'text' in column)) {
            return false
          }
          if (typeof column.name !== 'string' || typeof column.text !== 'string') {
            return false
          }
        }
        return true
      }
      return false
    } catch (error) {
      return false
    }
  }
}

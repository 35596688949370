import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 模块 */
export interface IModuleDefinition {
  id: string
  name: string
}

// prop
export const KModuleListViewModelPropOptions = {
  ...BaseViewPropOptions,
  selectedModuleCodeList: VuePropTypes.createType<Array<string>>().def([])
}

// 页面事件
export const KModuleListEventEmits = {
  ...BaseViewEventEmits
}

// prop type
export type KModuleListViewModelPropType = ViewPropsTypeExtract<typeof KModuleListViewModelPropOptions>

//emit type
export type KModuleListEventEmitsType = ViewEmitsTypeExtract<typeof KModuleListEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeActivityUpdateCompareView from './KChangeActivityUpdateCompare.vue'
import KChangeActivityUpdateCompareViewModel from './KChangeActivityUpdateCompareViewModel'

const KChangeActivityUpdateCompare = connect(KChangeActivityUpdateCompareView, KChangeActivityUpdateCompareViewModel)

export default withInstall(KChangeActivityUpdateCompare)
export { KChangeActivityUpdateCompare, KChangeActivityUpdateCompareView, KChangeActivityUpdateCompareViewModel }

// 模板生成文件
// export * from './{{folderName}}'

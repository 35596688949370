import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KObjectSearchGridPropOptions = {
  ...BaseViewPropOptions,
  params: VuePropTypes.string().def('')
}

/** 参数类型 **/
export type KObjectSearchGridPropType = ViewPropsTypeExtract<typeof KObjectSearchGridPropOptions>

/** 事件 */
export const KObjectSearchGridEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KObjectSearchGridEmitsType = ViewEmitsTypeExtract<typeof KObjectSearchGridEventEmits>

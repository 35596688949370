import { ref, watch } from 'vue'
import { BaseViewModel, ViewModelOptions, IKTreeNode, KTreeViewViewModel, TreeViewSelectEventArgs } from '@kmsoft/upf-core'
import { ObjectRelationClientSrv, ObjectRelationDefination } from '../../client-srv'
import { KObjectRelationsEmitsType, KObjectRelationsPropType } from './interface'
import { KObjectRelationGridViewModel } from '../object-relation-grid'
import { IObjectPanelTab, ObjectPanelTabRetrieveParams } from '../object-panel'

/** KObjectRelations */
export default class KObjectRelationsViewModel extends BaseViewModel<KObjectRelationsEmitsType, KObjectRelationsPropType>
  implements IObjectPanelTab {
  /**关系树。*/
  public refRelationTree = ref<KTreeViewViewModel>()
  /**关系网格。*/
  public refRelationGrid = ref<KObjectRelationGridViewModel>()

  /** 当前选中的关系树定义。*/
  public currentRelation = ref<ObjectRelationDefination>()

  constructor(options: ViewModelOptions<KObjectRelationsPropType>) {
    super(options)

    watch(
      () => this.props.objParam,
      newValue => newValue && this.loadData(),
      { immediate: true, deep: true }
    )
  }

  public retrieve(params: ObjectPanelTabRetrieveParams<string, Record<string, any>>): void {}

  public refresh(): Promise<void> {
    return this.loadData()
  }

  /**加载关系树。*/
  private async loadData() {
    //获取所有关联关系
    const definitions = await ObjectRelationClientSrv.listDefinitions(this.props.objParam.modelCode)

    //创建树节点
    this.refRelationTree.value?.setDataSource(
      ObjectRelationClientSrv.groups.map<IKTreeNode>(group => ({
        id: group.origin,
        name: group.name,
        iconType: 'folder',
        children: definitions
          .filter(_ => (_ as any).group === group.origin)
          .map<IKTreeNode>(_ => ({ ..._, iconType: 'file', leaf: true }))
      }))
    )
  }

  /**关系树节点切换。*/
  public relationTree_AfterSelect(event: TreeViewSelectEventArgs) {
    this.currentRelation.value = (event.node as unknown) as ObjectRelationDefination
  }
}

import { GridCellEditorParams, GridCellRendererParams, KObjectClassGridBaseColumn } from '../../base'
import KClassSelect from '.'
// import { GridCellEditorParams, GridCellRendererParams, KObjectClassGridBaseColumn } from '../..'

/** 人员选择器 */
export class KDataGridClassSelectColumn extends KObjectClassGridBaseColumn {
  name = 'KDataGridClassSelectColumn'
  getCellRenderer(params: GridCellRendererParams) {
    const col = (params.cellParams as any).col
    const newParam = { ...col, ...params }
    return <KClassSelect {...newParam} />
  }

  getCellEditor(params: GridCellEditorParams) {
    const col = (params.cellParams as any).col
    return <KClassSelect {...params} />
  }
}

/** 剪贴板操作类型 */
export enum EnumClipBoarbType {
  /** 拷贝 */
  Copy = 'COPY',
  /** 剪切 */
  Cut = 'CUT'
}

/** 剪贴板数据类型 */
export enum EnumClipBoardDataType {
  /**
   * 剪切类数据类型未知
   **/
  None = 0,

  /**
   * 剪切板类型为文本
   **/
  Text = 1,

  //#region 对象操作
  /**
   * 用于目录的对象列表的剪切、粘贴(string,Array&lt;ObjBusinessParam>;)
   **/
  DirectoryObjList = 20,

  /**
   * 用于对象类的剪切、粘贴(不受目录限制) (Array&lt;ObjBusinessParam>)
   **/
  ObjList = 21,
  //#region

  //#region 全局剪切板 数据对象类型
  /**
   *普通数据对象
   **/
  CommonDataObj = 80,
  //#region

  //#region 零部件节点
  /**
   *零部件节点
   **/
  PartStructNode = 120,
  /** 表格属性 */
  GridCopyData = 200,
  //#region

  /**
   *用户自定义类型
   **/
  UserDefine = 10000
}

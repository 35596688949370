import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../object-tool-strip'
import { EnumToolStripItemKeys } from '../types'

/**
 * 获取流程审批
 * @returns 流程审批
 */
export const getApprovalFlowOperationToolStripItems = (): Array<ObjectToolStripItem> => {
  return baseToolItem
}

/**
 * 基础工具栏
 */
export const baseToolItem = [
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OBJ,
    title: '对象',
    icon: 'DropObjLife',
    visible: true,
    compType: EnumToolStripCompType.CONTEXT_MENU,
    supportedObjNumMode: EnumSupportObjectNumMode.Any,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
        title: '添加',
        icon: 'plus-circle',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+shift+d',
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
        title: '删除',
        icon: 'DeleteObj',
        visible: false,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
        shortcutKey: 'alt+r'
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_GRID_OPERATION,
    title: '列表',
    icon: 'DropGridOp',
    visible: true,
    compType: EnumToolStripCompType.CONTEXT_MENU,
    supportedObjNumMode: EnumSupportObjectNumMode.Any,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
        title: '刷新',
        icon: 'sync',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'f5',
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_FILE_OPERATION,
    title: '文件',
    icon: 'printer',
    visible: false,
    supportedObjClsTempletID: ['Document'],
    compType: EnumToolStripCompType.CONTEXT_MENU,
    supportedObjNumMode: EnumSupportObjectNumMode.Single,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC,
        title: '浏览',
        icon: 'eye',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_REMARK,
        title: '批注',
        icon: 'read',
        visible: false,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
        // shortcutKey: 'alt+s'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK,
        title: '查看批注',
        icon: 'read',
        visible: false,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK_HISTORY,
        title: '查看批注历史版本',
        icon: 'container',
        visible: false,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SET_PRIMARY_OBJ,
    title: '设为主对象',
    icon: 'edit',
    visible: false,
    compType: EnumToolStripCompType.BUTTON,
    supportedObjNumMode: EnumSupportObjectNumMode.Single
  },
] as Array<ObjectToolStripItem>

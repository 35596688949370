import { ref, watch } from 'vue'
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KQuickSearcherEmitsType, KQuickSearcherPropType, QuickSearchForm } from './interface'
import {
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  ObjectHelper,
  QUERY_CONDITION_OPERATOR_COMPARISONS,
  QueryCondition,
  SYS_DEF_PROP
} from '../../../../client-srv'

/** 快速搜索 */
export default class KQuickSearcherViewModel extends BaseViewModel<KQuickSearcherEmitsType, KQuickSearcherPropType> {
  isSingleLine = ref<boolean>(false)
  isShowSearchButton = ref<boolean>(false)
  formLabelColStyle = { style: { width: '6em' } }
  isCaseSensitive = ref<boolean>(false)
  form = ref(this.getDefaultFormValue())
  colNameOptions = ref([
    {
      label: '代号',
      value: SYS_DEF_PROP.SYMBOL
    },
    {
      label: '名称',
      value: SYS_DEF_PROP.NAME
    }
  ])
  /** 操作符选项列表 */
  operatorOptions = ref(
    [EnumQueryConditionOperator.EQUAL, EnumQueryConditionOperator.LIKE, EnumQueryConditionOperator.STARTWITH].map(op => ({
      label: QUERY_CONDITION_OPERATOR_COMPARISONS[op],
      value: op
    }))
  )

  constructor(options: ViewModelOptions<KQuickSearcherPropType>) {
    super(options)
    this.isSingleLine.value = options.props.isSingleLine
    this.isShowSearchButton.value = options.props.isShowSearchButton

    watch(
      () => options.props.condition,
      () => {
        this.clear()
        this.initSearchOption()
      }
    )
  }

  viewDidMount() {
    this.clear()
    this.initSearchOption()
  }

  /**
   * 获取条件
   * @returns
   */
  getCondition() {
    /** 条件 */
    let conditions: Array<QueryCondition> = []

    if (!this.form.value.value) {
      conditions = []
    } else {
      const formValueString = ObjectHelper.objectToString(this.form.value)

      if (formValueString.length !== 0) {
        conditions = this.form.value.colnameList.map(c => {
          return {
            conditionName: c,
            operator: this.form.value.operator,
            conditionValues: [ObjectHelper.objectToString(this.form.value.value)]
          }
        })
      } else {
        conditions = []
      }
    }

    /** 条件 */
    const mainCondition: QueryCondition = { joiner: EnumQueryConditionJoiner.And, conditions: conditions }

    return mainCondition
  }

  clear(): void {
    this.form.value = this.getDefaultFormValue()
  }

  /** 填充缓存数据 */
  initSearchOption() {
    if (!this.props.condition || !this.props.condition.conditions || this.props.condition.conditions.length == 0) {
      return
    }
    this.form.value.colnameList = this.props.condition.conditions.map(_ => _.conditionName!)
    const operator = this.props.condition.conditions[0]
    this.form.value.operator = EnumQueryConditionOperator.LIKE
    this.form.value.value = operator.operator!
  }

  getDefaultFormValue(): QuickSearchForm {
    return {
      colnameList: [SYS_DEF_PROP.SYMBOL, SYS_DEF_PROP.NAME],
      operator: EnumQueryConditionOperator.LIKE,
      value: ''
    }
  }

  onSearch() {
    const filter = this.getCondition()
    this.emit('search', filter)
  }
}

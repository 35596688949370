import { connect, withInstall } from '@kmsoft/upf-core'
import KIssueReportRelationshipView from './KIssueReportRelationship.vue'
import KIssueReportRelationshipViewModel from './KIssueReportRelationshipViewModel'

const KIssueReportRelationship = connect(KIssueReportRelationshipView, KIssueReportRelationshipViewModel)

export default withInstall(KIssueReportRelationship)
export { KIssueReportRelationship, KIssueReportRelationshipView, KIssueReportRelationshipViewModel }

// 模板生成文件
// export * from './{{folderName}}'

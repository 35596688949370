import { connect, withInstall } from '@kmsoft/upf-core'
import KUserSelectorView from './KUserSelector.vue'
import KUserSelectorViewModel from './KUserSelectorViewModel'

const KUserSelector = connect(KUserSelectorView, KUserSelectorViewModel)

export default withInstall(KUserSelector)
export { KUserSelector, KUserSelectorView, KUserSelectorViewModel }
export * from './interface'
export * from './KDataGridUserSelectorColumn'
export * from './KUserSelectorResolver'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KProjectWorkspaceListPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KProjectWorkspaceListPropType = ViewPropsTypeExtract<typeof KProjectWorkspaceListPropOptions>

/** 事件 */
export const KProjectWorkspaceListEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KProjectWorkspaceListEmitsType = ViewEmitsTypeExtract<typeof KProjectWorkspaceListEventEmits>

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartImportPropOptions = {
  ...BaseViewPropOptions,
  folderId: VuePropTypes.string().def('-1')
}

/** 参数类型 **/
export type KPartImportPropType = ViewPropsTypeExtract<typeof KPartImportPropOptions>

/** 事件 */
export const KPartImportEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartImportEmitsType = ViewEmitsTypeExtract<typeof KPartImportEventEmits>

// 页面定义
export interface IPageDefinition {
  id: string
  name: string
  symbol: string
  suffix: string
}
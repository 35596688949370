/** 类型检查 */
export class TypeHelper {
  /**
   * 检查是否是 Class
   * @param obj 被检查对象
   */
  static couldBeClass(obj: unknown): obj is new (...args: any) => any {
    if (typeof obj !== 'function') {
      return false
    }
    if (obj.prototype === undefined) {
      return false
    }
    const str: string = obj.toString()
    if (str.slice(0, 5) === 'class') {
      return true
    }
    if (Object.getOwnPropertyNames(obj.prototype).length >= 0) {
      return true
    }
    if (/^function\s+\(|^function\s+anonymous\(/.test(str)) {
      return false
    }
    if (/\b\(this\b|\bthis[\.\[]\b/.test(str)) {
      return /^function\sdefault_\d+\s*\(/.test(str)
    }
    return false
  }

  /**
   * 判断对象是否是Promise
   * @param val
   * @returns
   */
  static isPromise<T = any>(val: any): val is Promise<T> {
    if (val[Symbol.toStringTag] === 'AsyncFunction') {
      return true
    }
    return false
  }
}

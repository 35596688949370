
import { defineView } from '@kmsoft/upf-core'
import { KEnumsPanelViewModel } from './KEnumsPanelViewModel'
import { KEnumsPanelPropOptions } from './interface'

export default defineView({
  name: 'EnumsPanel', // 组件名称
  components: {}, // 这里定义局部组件
  viewModel: KEnumsPanelViewModel,
  props: KEnumsPanelPropOptions,
  setup() {
    return []
  }
})

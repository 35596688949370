import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"width":"100%"}
}
const _hoisted_2 = {
  class: "k-render-dropdown-text",
  title: "displayValue"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!

  return (_ctx.readonly)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$vm.displayValue || '- -'), 1)
      ]))
    : (_openBlock(), _createBlock(_component_k_select, {
        key: 1,
        ref: "refSelect",
        class: "workspace-select-item",
        value: _ctx.$vm.refView,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.refView) = $event)),
        options: _ctx.$vm.refViewOptions,
        placeholder: "请选择视图",
        defaultActiveFirstOption: true,
        readonly: _ctx.readonly,
        allowClear: false,
        disabled: _ctx.disabled
      }, null, 8, ["value", "options", "readonly", "disabled"]))
}
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { IViewDefinition, KPartCreateViewVersionFormEmitsType, KPartCreateViewVersionFormPropType } from './interface'
import { ref, watch } from 'vue'
import _ from 'lodash'

/** KPartCreateViewVersionForm */
export default class KPartCreateViewVersionFormViewModel extends BaseViewModel<
  KPartCreateViewVersionFormEmitsType,
  KPartCreateViewVersionFormPropType
> {
  /** 视图类型 */
  options = ref<any>()
  /** 视图选择值 */
  originOptions = ref<any>()
  /** 视图值 */
  viewValue = ref<any>()
  constructor(options: ViewModelOptions<KPartCreateViewVersionFormPropType>) {
    super(options)
    this.originOptions.value = _.cloneDeep(options.props.viewProps)
    watch(
      () => options.props.viewProps,
      newVal => {
        this.options.value = newVal.map((x: any) => {
          return {
            label: x.label,
            value: x.value.id
          }
        })
        this.viewValue.value = newVal[0]?.value.id
      },
      { immediate: true }
    )
  }

  /** 视图类型 */
  refView = ref('')
  /** 视图类型选择 */
  viewPropOptions = ref<any>()
  /** 表单响应式数据 */
  formData = ref<IViewDefinition>()

  viewDidMount() {}

  /**
   * 设置数据源
   * @param value 表单的值
   * @returns
   */
  public setValue(value: IViewDefinition) {
    //this.formData.value = value
  }

  /**
   * 获取表单的值
   * @returns 表单的值
   */
  public getValue() {
    const res = this.originOptions.value.find((x: { value: { id: any } }) => x.value.id === this.viewValue.value)
    if (res) {
      return res.value
    }
    return {}
  }
}

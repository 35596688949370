import { BaseViewModel, KDialog, KDialogClosingEvent, VNodeProps, ValueChangeEventArgs, ViewModelOptions } from '@kmsoft/upf-core'
import { KGridColumnConfigPropType, KGridColumnConfigEventEmitsType } from './interface'
import {
  KGridColumnDesigner,
  KGridColumnDesignerEventEmitsType,
  KGridColumnDesignerPropType,
  KGridColumnDesignerViewModel
} from './controls'
import { cloneDeep } from 'lodash'

/** 导航管理 */
export default class KGridColumnConfigViewModel extends BaseViewModel<
  KGridColumnConfigEventEmitsType,
  KGridColumnConfigPropType
> {
  constructor(options: ViewModelOptions<KGridColumnConfigPropType>) {
    super(options)
  }

  openGridDesigner() {
    const _self = this

    const gridColumnConfigProps: VNodeProps<KGridColumnDesignerPropType, KGridColumnDesignerEventEmitsType> = {
      elements: this.props.value,
      designerMetaControl: this.props.designerMetaControl,
      dataSource: this.props.dataSource
    }
    KDialog.show({
      title: '列配置',
      maximizeBox: true,
      size: { width: 1024, height: 608 },
      props: gridColumnConfigProps,
      content: KGridColumnDesigner,
      async onClosing(event: KDialogClosingEvent<KGridColumnDesignerViewModel>) {
        if (event.dialogResult === 'Confirm') {
          const vm = event.viewInstance!
          const result = await vm.validate()
          if (!result) {
            event.cancel = true
            return
          }
          const value = vm.getValue()
          const eventArgs = new ValueChangeEventArgs(value, value)
          _self.emit('change', eventArgs)
        }
      }
    })
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KConfigCustomViewModelPropOptions } from './interface'
import KConfigCustomViewModel from './KConfigCustomViewModel'
import { EnumEditType } from '../../interface'
import KConfigObjectGrid from '../config-object-grid'

export default defineView({
  name: 'ConfigCustom',
  props: KConfigCustomViewModelPropOptions,
  viewModel: KConfigCustomViewModel,
  components: {
    KConfigObjectGrid
  },
  setup() {
    return {
      EnumEditType
    }
  }
})

import { BaseViewModel, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { KPropertyGridEmitsType, KPropertyGridPropType } from './interface'
import { ClassMetaClientSrv } from '../../../client-srv'

/** KPropertyGrid */
export default class KPropertyGridViewModel extends BaseViewModel<KPropertyGridEmitsType, KPropertyGridPropType> {
  /**数据类型候选项 */
  private DataTypeOptions = [
    {
      label: '整数',
      value: 10
    },
    {
      label: '浮点型',
      value: 20
    },
    {
      label: '长整型',
      value: 30
    },
    {
      label: '日期',
      value: 40
    },
    {
      label: '字符',
      value: 50
    },
    {
      label: '图片',
      value: 60
    },
    {
      label: '文本',
      value: 70
    },
    {
      label: '对象',
      value: 80
    },
    {
      label: '分类',
      value: 90
    },
    {
      label: '枚举',
      value: 100
    }
  ]
  //#region 字段，属性定义
  refDataGrid = ref<KDataGridViewModel>()
  /**
   * 数据类型下拉数据
   */
  dataType = ref<Array<any>>(this.DataTypeOptions)
  //#endregion
  constructor(options: ViewModelOptions<KPropertyGridPropType>) {
    super(options)

    watch(
      () => options.props.modelCode,
      () => {
        this.refDataGrid.value?.refresh()
      },
      {
        immediate: true
      }
    )
  }

  get refDataGridView() {
    return this.refDataGrid.value
  }

  viewDidMount() {}

  loadData = async () => {
    if (!this.props.modelCode) return []
    const result = await ClassMetaClientSrv.getClassProperties(this.props.modelCode)
    const newResult = result!.map((x: { id: any; name: any; columnName: any; type: any; length: any }) => {
      return {
        id: x.id,
        propertyName: x.name,
        columnName: x.columnName,
        dataType: x.type,
        length: x.length
      }
    })
    return newResult
  }
  getSelectedRows() {
    this.refDataGrid.value?.getSelectedRows()
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineCompareGridView from './KBaselineCompareGrid.vue'
import KBaselineCompareGridViewModel from './KBaselineCompareGridViewModel'

const KBaselineCompareGrid = connect(KBaselineCompareGridView, KBaselineCompareGridViewModel)

export default withInstall(KBaselineCompareGrid)
export { KBaselineCompareGrid, KBaselineCompareGridView, KBaselineCompareGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

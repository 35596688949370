import { BaseViewModel, KDataGridViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KPartImportErrorMsgEmitsType, KPartImportErrorMsgPropType } from './interface'
import { ref } from 'vue'

/** KPartImportErrorMsg */
export default class KPartImportErrorMsgViewModel extends BaseViewModel<
  KPartImportErrorMsgEmitsType,
  KPartImportErrorMsgPropType
> {
  /**网格 */
  refPartImportErrorMsgGrid = ref<KDataGridViewModel>()

  constructor(options: ViewModelOptions<KPartImportErrorMsgPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 网格数据加载
   */
  loadData() {
    const errorMsg = this.props.errorMsg
    return new Promise((resolve, reject) => {
      if (errorMsg && errorMsg.length != 0) {
        const result = errorMsg.map((item, index) => {
          return {
            id: index,
            ...item
          }
        })
        resolve(result)
      } else {
        reject([])
      }
    })
  }

  /**
   * 刷新网格
   */
  refresh() {
    this.refPartImportErrorMsgGrid.value?.refresh()
  }

  /**
   * 导出excel
   */
  exportAsExcel() {
    const params = {
      columnKeys: this.refPartImportErrorMsgGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id)
    }
    this.refPartImportErrorMsgGrid.value?.exportDataAsExcel(params)
  }

  /**
   * 导出选中
   */
  exportSelectAsExcel() {
    const data = this.refPartImportErrorMsgGrid.value?.getSelectedRows()
    if (data && data.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择要导出的数据'
      })
      return
    }
    const params = {
      onlySelected: true,
      columnKeys: this.refPartImportErrorMsgGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id)
    }
    this.refPartImportErrorMsgGrid.value?.exportDataAsExcel(params)
  }
}

import { ref, watch } from 'vue'
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ObjectSelectorTestEventEmitsType, ObjectSelectorTestPropType } from './interface'

/** ObjectSelectorTest 视图模型 */
export default class ObjectSelectorTestViewModel extends BaseViewModel<
  ObjectSelectorTestEventEmitsType,
  ObjectSelectorTestPropType
> {
  constructor(options: ViewModelOptions<ObjectSelectorTestPropType>) {
    super(options)
  }
  /** 加载完成函数 */
  viewDidMount() {}
}

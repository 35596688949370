import { VNode, createVNode, ref } from 'vue'
import { ConstructorType, defineView, KSkeleton, VNodeProps } from '@kmsoft/upf-core'
import { KDynamicView, KDynamicViewPropType } from '@kmsoft/upf-view-engine'
import KObjectPropertyPanelViewModel from './KObjectPropertyPanelViewModel'
import { KObjectPropertyPanelPropOptions, KObjectPropertyPanelEventEmits } from './interface'
import { EnumLayoutSchemaType } from '../../client-srv'

/** 对象属性面板 */
export default defineView({
  name: 'KObjectPropertyPanel',
  inheritAttrs: false,
  props: KObjectPropertyPanelPropOptions,
  emits: KObjectPropertyPanelEventEmits,
  viewModel: KObjectPropertyPanelViewModel as ConstructorType<KObjectPropertyPanelViewModel>,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      // 如果正在加载 则显示骨架屏
      if (vm.isloading.value) {
        return (
          <div class="skeleton">
            <div class="skeleton-row">
              <k-flex justify="space-between">
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
              </k-flex>
            </div>
            <div class="skeleton-row">
              <k-flex justify="space-between">
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
              </k-flex>
            </div>
            <div class="skeleton-row">
              <k-flex justify="space-between">
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
              </k-flex>
            </div>
            <div class="skeleton-row">
              <k-flex justify="space-between">
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
              </k-flex>
            </div>
            <div class="skeleton-row">
              <k-flex justify="space-between">
                <k-skeleton active />
              </k-flex>
            </div>
          </div>
        )
      }

      /** 参数 */
      const dynamicViewProps: VNodeProps<KDynamicViewPropType> = {
        ref: vm.refDynamicView,
        ref_key: 'refDynamicView',
        name: vm.schema.value?.name,
        schema: vm.schema.value,
        bizCtx: {},
        pageValue: vm.stateValue.value,
        readonly: props.readonly
      }
      let dynamicViewVNode = (null as unknown) as VNode

      if (vm.schema.value) {
        dynamicViewVNode = createVNode(KDynamicView, dynamicViewProps)
      } else {
        dynamicViewVNode = <k-empty description="不存在布局" />
      }

      return <div class="k-object-property-panel">{dynamicViewVNode}</div>
    }
  }
})

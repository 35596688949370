
import { defineView } from '@kmsoft/upf-core'
import { KEditPanelDemoPropOptions, KEditPanelDemoEventEmits } from './interface'
import KEditPanelDemoViewModel from './KEditPanelDemoViewModel'

export default defineView({
  name: 'KEditPanelDemo',
  props: KEditPanelDemoPropOptions,
  emits: KEditPanelDemoEventEmits,
  viewModel: KEditPanelDemoViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartFormView from './KPartForm.vue'
import KPartFormViewModel from './KPartFormViewModel'

const KPartForm = connect(KPartFormView, KPartFormViewModel)

export default withInstall(KPartForm)
export { KPartForm, KPartFormView, KPartFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

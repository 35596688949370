import { defineView } from '@kmsoft/upf-core'
import { KDesignerControlPanelPropOptions } from './interface'
import KDesignerControlPanelViewModel from './KDesignerControlPanelViewModel'
import { KDesignerControl } from '../../../../types'
import { createVNode } from 'vue'
import _ from 'lodash'
import { EnumRenderLayoutType } from '@kmsoft/upf-view-engine'

/** 控件面板 */
export default defineView({
  name: 'KDesignerControlPanel',
  props: KDesignerControlPanelPropOptions,
  viewModel: KDesignerControlPanelViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    const onDragOut = (element: KDesignerControl) => {
      return _.cloneDeep(props.convertElement!(element))
    }

    const __generateCollapsePanel = () => {
      const draggableSlots = {
        item: ({ element }: { element: KDesignerControl }) => {
          return (
            <div class={['k-design-control-item', { 'disable-drag': props.disabledDragElementMap?.has(element.title) }]}>
              <k-icon class="item-icon" type={element.icon} width={24} height={24} />
              <span class="item-title">{element.title}</span>
            </div>
          )
        }
      }

      return vm.toolboxControlGroups.value.map(group => {
        return (
          <k-collapse-panel
            class="k-design-control-group"
            key={group.title}
            header={createVNode('span', null, [<span class="group-title">{group.title}</span>])}
          >
            <k-draggable
              list={group.children}
              itemKey="title"
              group={{ name: `${EnumRenderLayoutType.GRID}-layout-group`, put: false, pull: 'clone' }}
              filter=".disable-drag"
              class="group-content"
              clone={(element: KDesignerControl) => onDragOut(element)}
              v-slots={draggableSlots}
            />
          </k-collapse-panel>
        )
      })
    }

    return () => {
      return (
        <k-collapse class="k-designer-control-panel" size="small" vModel={[vm.activeKeys.value, 'activeKey']} ghost>
          {__generateCollapsePanel()}
        </k-collapse>
      )
    }
  }
})

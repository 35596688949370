import { BaseViewEventEmits, ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import lodash from 'lodash'
import { KObjectClassTreePropOptions } from '../object-class-tree'
import { QueryCondition } from '../../client-srv'

/** 参数 **/
export const KObjectWorkSpaceSearchPropOptions = {
  ...lodash.omit(KObjectClassTreePropOptions, 'defaultSelectClsCode'),
  /** 默认对象编码 */
  modelCode: VuePropTypes.string().def(''),
  /** 启用`包含子类`勾选框 */
  includeChildrenCheck: VuePropTypes.bool().def(false),
  /** 搜索状态, 处于搜索状态将禁用底部按钮 */
  isSearching: VuePropTypes.bool().def(false),
  /** 是否显示搜索 */
  showSearch: VuePropTypes.bool().def(false),
  /** 是否显示取消 */
  showCancel: VuePropTypes.bool().def(false),
  /**
   * 显示的工作区类型
   */
  showWorkspaceType: VuePropTypes.createType<Array<FolderTypeEnum>>().def([]),
  /** 设置要显示的对象id集合,包含子节点 */
  showObjClsCodes: VuePropTypes.array<string>().def([])
}

/** 参数类型 **/
export type KObjectWorkSpaceSearchPropType = ViewPropsTypeExtract<typeof KObjectWorkSpaceSearchPropOptions>

/** 事件 */
export const KObjectWorkSpaceSearchEventEmits = {
  ...BaseViewEventEmits,
  'update:modelCode': (value: string) => true,
  /** 此方法会在点击查询后被调用 */
  search: (condition: QueryCondition): boolean => true,
  /** 点击取消按钮 */
  cancel: () => true
}

/** 事件类型 **/
export type KObjectWorkSpaceSearchEmitsType = ViewEmitsTypeExtract<typeof KObjectWorkSpaceSearchEventEmits>

/** 文件夹类型枚举 */
export enum FolderTypeEnum {
  /**个人工作区 */
  PERSONAL = 'PersonalWorkspace',
  /**公共工作区 */
  PUBLIC = 'PublicWorkspace',
  /**产品工作区 */
  PRODUCT = 'ProductLibrary',
  /**标准件 */
  STANDARD_PARTS = 'StandardPartLibrary',
  /**元器件 */
  ELECTRONIC_COMPONENTS = 'ElectronicComponentsLibrary',
  /** 通用件” */
  COMMON_PARTS = 'CommonPartLibrary'
}

/** 版本类型枚举 */
export enum VersionTypeEnum {
  INIT = '',
  /**最新发布 */
  LATEST = 'latest',
  /**全部 */
  ALL = 'all',
  /**全部发布版本 */
  LATEST_RELEASED = 'latestReleased'
}

/**
 * 枚举转换
 * @param input 文件夹类型
 * @returns 文件夹类型枚举
 */
export function convertToEnum(input: string): FolderTypeEnum {
  const directionMap: { [key: string]: FolderTypeEnum } = {
    PersonalWorkspace: FolderTypeEnum.PERSONAL,
    PublicWorkspace: FolderTypeEnum.PUBLIC,
    ProductLibrary: FolderTypeEnum.PRODUCT,
    StandardPartLibrary: FolderTypeEnum.STANDARD_PARTS,
    ElectronicComponentsLibrary: FolderTypeEnum.ELECTRONIC_COMPONENTS,
    CommonPartLibrary: FolderTypeEnum.COMMON_PARTS
  }

  return directionMap[input]
}

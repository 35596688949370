import {
  BaseViewModel,
  EnumAttributeType,
  EnumGridElementType,
  EnumNodeType,
  EnumType,
  KContainer,
  KControl,
  KGridColumn,
  ViewModelOptions,
  KNotification
} from '@kmsoft/upf-core'
import _ from 'lodash'
import { computed, nextTick, ref } from 'vue'
import { KViewConfigViewModel } from '../../../../../designer'
import { DEFAULT_COLUMN } from '../../config'
import { toKDataGridColumns } from '../../utils'
import lodash from 'lodash'
import { KGridDesignerElement } from '../../types'
import { KDesignerControl, PageDesignerDataSourceProperty, PageDesignerDataSourcePropClass } from '../../../../../types'
import { KGridColumnDesignerEventEmitsType, KGridColumnDesignerPropType } from './interface'
import { generateControlName } from '../../../../../config'

/** 导航管理 */
export default class KGridColumnDesignerViewModel extends BaseViewModel<
  KGridColumnDesignerEventEmitsType,
  KGridColumnDesignerPropType
> {
  //#region 引用
  refDesignerConfig = ref<KViewConfigViewModel>()
  //#endregion

  /** 选中的元素 */
  selectedElement = ref<KGridDesignerElement>()
  /** 选中的控件信息 */
  selectedControl = ref<KDesignerControl>()
  /** 数据元素属性集合 */
  dataSourceElementList = computed(() => {
    return this.__convert2DesignerControl(this.props.dataSource.children, [])
  })
  /** 列类型 */
  metaColumns = computed(() => {
    const base = this.dataSourceElementList.value.map(item => {
      return toKDataGridColumns(item)
    })
    const custom = this.props.designerMetaControl.map(item => {
      return toKDataGridColumns(item)
    })

    const res = DEFAULT_COLUMN.concat(base).concat(custom)

    return res
  })

  constructor(options: ViewModelOptions<KGridColumnDesignerPropType>) {
    super(options)
  }

  viewDidMount(): void {}

  getValue() {
    return this.props.elements
  }

  validate() {
    const element = this.getValue()
    const errInfo = element.find(item => !item.control)
    if (errInfo) {
      KNotification.warn({
        message: '组件类型不能为空',
        description: `${errInfo.title}的组件类型不能为空`
      })
      return false
    }
    return true
  }

  /* 配置项内容改变触发的事件 */
  elementPropertyChange(controlName: string, name: string, type: string, value: any) {
    /** 更新方案中指定元素的配置 */
    nextTick(() => {
      this.__updateSpecificElementConfig(this.props.elements, controlName, name, type, value)
    })
  }

  /**
   * 将元素转为视图区所需格式
   * 工具箱元素被拖出时触发
   * @param dragElement 工具箱中拖出的元素
   */
  __convertElement2Preview(element: KDesignerControl) {
    let dragElement: KDesignerControl = lodash.cloneDeep(element)

    if (dragElement) {
      dragElement = toKDataGridColumns(element)
    }

    const originFiled = dragElement.attrs.find(y => y.name === 'field')?.defaultValue
    const props: Record<string, any> = {}
    const layout: Record<string, any> = {}
    const base: Record<string, any> = {}

    // 1.获取控件信息
    const controlAttrs = this.getLocalControlInfo(originFiled, dragElement.control)

    if (!controlAttrs) {
      return
    }

    const name = this.__getControlKey(dragElement, 'name')
    const title = this.__getControlKey(dragElement, 'title')
    const baseConfig = controlAttrs.attrs.filter(x => x.attributeType === EnumAttributeType.BASE)
    const layoutConfig = controlAttrs.attrs.filter(x => x.attributeType === EnumAttributeType.LAYOUT)
    const propsConfig = controlAttrs.attrs.filter(x => x.attributeType === EnumAttributeType.PROPS)

    baseConfig &&
      baseConfig.map((item: any) => {
        base[item.name] = item.defaultValue
      })
    layoutConfig &&
      layoutConfig.map((item: any) => {
        layout[item.name] = item.defaultValue
      })
    propsConfig &&
      propsConfig.map((item: any) => {
        props[item.name] = item.defaultValue
      })

    if ((dragElement.type as any) === EnumGridElementType.GROUP) {
      const result = {
        ...base,
        title: title,
        name: generateControlName(name),
        control: dragElement.control,
        type: dragElement.type,
        props: props,
        controls: [] as Array<KContainer | KControl>
      }
      return _.cloneDeep(result)
    } else {
      const result = {
        ...base,
        title: title,
        field: name,
        name: generateControlName(dragElement.control),
        control: dragElement.control,
        type: dragElement.type,
        props: props
      }
      return _.cloneDeep(result) as KControl
    }
  }

  /**数据元素中的所有属性拉平放到集合中 */
  __convert2DesignerControl = (
    nodes: Array<PageDesignerDataSourcePropClass | PageDesignerDataSourceProperty>,
    result: Array<KDesignerControl>
  ) => {
    nodes &&
      nodes.forEach(node => {
        if (node.nodeType && node.nodeType === EnumNodeType.PROPERTY) {
          const res = {
            name: node.name,
            title: node.title,
            attrs: (node as PageDesignerDataSourceProperty).attrs,
            events: (node as PageDesignerDataSourceProperty).events!,
            type: EnumType.CONTROL,
            dataType: (node as PageDesignerDataSourceProperty).dataType
          } as KDesignerControl
          result.push(res)
        }
        if (
          (node as PageDesignerDataSourcePropClass).children &&
          (node as PageDesignerDataSourcePropClass).children?.length > 0
        ) {
          this.__convert2DesignerControl((node as PageDesignerDataSourcePropClass).children, result)
        }
      })
    return result
  }

  /**获取当前控件信息 */
  getLocalControlInfo(field: string, control: string) {
    /** 通过字段查找控件 */
    // const controlByFiels = this.__getControlByFiled(field)
    // console.log(controlByFiels, control)
    // if (controlByFiels) {
    //   return controlByFiels
    // }

    const metaControl =
      this.metaColumns.value.find(x => x.control === control) || this.metaColumns.value.find(x => x.control === 'custom')
    if (metaControl) {
      metaControl.control = control
      return metaControl
    }

    return undefined
  }

  /**获取控件的标识
   * @param  element 控件
   */
  __getControlKey = (element: KDesignerControl | PageDesignerDataSourceProperty, key: string) => {
    const controlAttrs = element.attrs.find(x => x.name === key)
    if (controlAttrs && controlAttrs.defaultValue !== '') {
      return controlAttrs.defaultValue
    }
    return (element as any)[key]
  }

  /**通过字段查找控件 */
  __getControlByFiled = (field: string) => {
    let res = undefined
    for (const x of this.dataSourceElementList.value) {
      console.log(this.dataSourceElementList.value)
      const originFiled = x.attrs.find(y => y.name === 'field')?.defaultValue

      if (originFiled === field) {
        res = x
        break
      }
    }
    return res
  }

  /**
   * 更新指定元素的配置（递归）
   * @param elements 元素集合
   * @param elementCode 需要更改配置的元素编码
   * @param configItemName 配置项名称，如 name,title,rowSpan等
   * @param value 配置项最新值
   */
  __updateSpecificElementConfig = (
    elements: Array<KGridDesignerElement>,
    controlName: string,
    name: string,
    type: string,
    value: any
  ) => {
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      if (element.name == controlName) {
        //判断修改属性的类型
        if (type === EnumAttributeType.BASE) {
          /** 执行到这里说明configItemName为name或title */
          ;(element as Record<string, any>)[name] = value
          return
        }
        if (type === EnumAttributeType.PROPS) {
          /** 执行到这里说明configItemName为name或title */
          ;(element as Record<string, any>).props[name] = value
          return
        }
      }
      /** 若当前元素有children,则继续递归 */
      if ((element as any).controls && Reflect.has(element, 'controls')) {
        this.__updateSpecificElementConfig((element as any).controls, controlName, name, type, value)
      }
    }
  }

  /**元素选中事件 */
  onElementSelected(element: KGridDesignerElement | undefined, updateSelf?: boolean) {
    if (element?.name == this.selectedElement.value?.name) {
      return false
    }

    // if (element?.control == 'custom') {
    //   element.field = ''
    // }

    this.selectedElement.value = element
    if (element) {
      /**选中控件的属性 */
      const controlInfo = this.getLocalControlInfo((element! as KGridColumn).field!!, element.control)
      this.selectedControl.value = controlInfo
    }
  }

  /**布局元素改变事件 */
  onElementChange(element: KGridDesignerElement) {
    this.emit('elementChange', element)
  }
}

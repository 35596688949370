import { connect, withInstall } from '@kmsoft/upf-core'
import KVersionGroupObjectSelectFormView from './KVersionGroupObjectSelectForm.vue'
import KVersionGroupObjectSelectFormViewModel from './KVersionGroupObjectSelectFormViewModel'

const KVersionGroupObjectSelectForm = connect(KVersionGroupObjectSelectFormView, KVersionGroupObjectSelectFormViewModel)

export default withInstall(KVersionGroupObjectSelectForm)
export { KVersionGroupObjectSelectForm, KVersionGroupObjectSelectFormView, KVersionGroupObjectSelectFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

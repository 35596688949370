import { Component, ComputedOptions, MethodOptions } from 'vue'
import { IFieldEditorResolver, KObjectClassGridBaseColumn } from '../../base'
import { KDataGridReferencePropertyColumn } from './KDataGridReferencePropertyColumn'
import KReferenceEditor from './KReferenceEditor.vue'
import { EnumDataType, MetaProperty } from '../../../../client-srv'

export class ReferenceEditorResolver extends IFieldEditorResolver {
  static getColumn(field: MetaProperty): KObjectClassGridBaseColumn | null {
    if (field.type === EnumDataType.OBJECT) {
      const refColumnInstance = new KDataGridReferencePropertyColumn()
      return refColumnInstance
    }
    return null
  }

  static getEditor(field: MetaProperty): string | (() => Component<any, any, any, ComputedOptions, MethodOptions>) | null {
    if (field.type === EnumDataType.OBJECT) {
      return () => KReferenceEditor
    }
    return null
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","display":"flex"} }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { style: {"margin-left":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_input_search = _resolveComponent("k-input-search")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_date_time_column = _resolveComponent("k-data-grid-date-time-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_tool_strip, { name: "workspaceToolStrip" }, {
          default: _withCtx(() => [
            _createVNode(_component_k_tool_strip_button_item, {
              name: "refreshBtn",
              icon: "sync",
              title: "刷新",
              onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.refresh())
            })
          ]),
          _: 1
        }),
        _createVNode(_component_k_input_search, {
          value: _ctx.$vm.workspaceName,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.workspaceName) = $event)),
          placeholder: "请输入工作区名称",
          style: {"width":"200px","margin-top":"8px"},
          onSearch: _ctx.$vm.search
        }, null, 8, ["value", "onSearch"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        rowModelType: _ctx.rowModelType,
        class: "product-workspace-grid",
        rowKey: "id",
        name: "workspaceGrid",
        pagination: { pageIndex: 1, pageSize: 50 },
        ref: _ctx.$vm.refWorkspaceGrid,
        loadData: _ctx.$vm.loadData
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_template_column, {
            id: "name",
            name: "name",
            dataPropertyName: "name",
            headerText: "产品库名称",
            align: "center"
          }, {
            default: _withCtx(({ cellValue,row }) => [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                onClick: () => _ctx.$vm.openProductWorkspace(row)
              }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(cellValue), 1)
              ], 8, _hoisted_2)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "description",
            name: "description",
            dataPropertyName: "description",
            headerText: "备注",
            width: "450",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "creator",
            name: "creator",
            dataPropertyName: "creator",
            headerText: "创建者",
            align: "center"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_date_time_column, {
            id: "createTime",
            name: "createTime",
            dataPropertyName: "createTime",
            headerText: "创建时间",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "modifier",
            name: "modifier",
            dataPropertyName: "modifier",
            headerText: "修改人",
            align: "center"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_date_time_column, {
            id: "lastUpdateTime",
            name: "lastUpdateTime",
            dataPropertyName: "lastUpdateTime",
            headerText: "修改时间",
            align: "center"
          })
        ]),
        _: 1
      }, 8, ["rowModelType", "loadData"])
    ]),
    _: 1
  }))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73a6950d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "bom-compare",
  ref: "refBomCompare"
}
const _hoisted_2 = {
  key: 0,
  style: {"color":"blue"}
}
const _hoisted_3 = {
  key: 1,
  style: {"color":"red"}
}
const _hoisted_4 = {
  key: 2,
  style: {"color":"orange"}
}
const _hoisted_5 = {
  key: 3,
  style: {"color":"black"}
}
const _hoisted_6 = {
  key: 0,
  class: "bom-view"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  style: {"color":"blue"}
}
const _hoisted_10 = {
  key: 1,
  style: {"color":"red"}
}
const _hoisted_11 = {
  key: 2,
  style: {"color":"orange"}
}
const _hoisted_12 = {
  key: 3,
  style: {"color":"black"}
}
const _hoisted_13 = {
  key: 0,
  class: "bom-view"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "bom-compare-result" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_object_view_selector = _resolveComponent("k-object-view-selector")!
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_model_browser = _resolveComponent("k-model-browser")!
  const _component_k_empty = _resolveComponent("k-empty")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_split_container = _resolveComponent("k-split-container")!
  const _component_k_tabs = _resolveComponent("k-tabs")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createVNode(_component_k_tool_strip, { name: "bomCompareToolStrip" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_tool_strip_button_item, {
            name: "search",
            title: "搜索",
            onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.search())
          }),
          _createVNode(_component_k_tool_strip_button_item, {
            name: "closeBom",
            title: "关闭",
            onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.close())
          }),
          _createVNode(_component_k_tool_strip_button_item, {
            name: "selectBom",
            title: "同版本组对象",
            onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.selectBom())
          }),
          _createVNode(_component_k_tool_strip_button_item, {
            name: "compareBom",
            title: "比较",
            onClick: _cache[3] || (_cache[3] = () => _ctx.$vm.compareBom()),
            disabled: _ctx.$vm.rightTreeRootNode == null
          }, null, 8, ["disabled"]),
          _createVNode(_component_k_tool_strip_button_item, {
            name: "visualization",
            title: "可视化",
            onClick: _cache[4] || (_cache[4] = () => _ctx.$vm.showView()),
            disabled: _ctx.$vm.rightTreeRootNode == null
          }, null, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$vm.isShowView ? 'bom-tree-all' : 'bom-tree')
        }, [
          _createVNode(_component_k_split_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_split_pane, {
                size: 50,
                minSize: "20",
                maxSize: "80"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_panel, null, {
                    header: _withCtx(() => [
                      _createVNode(_component_k_select, {
                        ref: "select",
                        style: {"width":"120px"},
                        value: _ctx.$vm.leftTreeExpandType,
                        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$vm.leftTreeExpandType) = $event)),
                        options: _ctx.expandTypeOptions,
                        onChange: _cache[6] || (_cache[6] = (args)=>_ctx.$vm.refreshLeftTree(args)),
                        allowClear: false
                      }, null, 8, ["value", "options"]),
                      _createVNode(_component_k_object_view_selector, {
                        style: {"width":"120px","margin-left":"5px"},
                        ref: _ctx.$vm.refLeftViewSelector,
                        value: { name: _ctx.$vm.refLeftView },
                        disabled: _ctx.$vm.leftViewDisabled,
                        onChange: _cache[7] || (_cache[7] = (args)=>_ctx.$vm.refreshLeftTreeAfterView(args))
                      }, null, 8, ["value", "disabled"])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$vm.isShowView ? 'has-view' : 'no-view')
                      }, [
                        _createVNode(_component_k_tree_view, {
                          ref: _ctx.$vm.refLeftFolderTree,
                          nodeKey: "uniqueIdentifier",
                          loadData: _ctx.$vm.loadLeftTreeData,
                          defaultExpandedLevel: 0,
                          autoSelectFirstNodeAfterDefaultExpand: false,
                          onLoaded: _cache[8] || (_cache[8] = () => _ctx.$vm.changeTreeNodeColor()),
                          onAfterSelect: _ctx.$vm.leftAfterSelect
                        }, {
                          title: _withCtx(({node}) => [
                            (node.type == 'leftUnique')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(node.name), 1))
                              : (node.type == 'rightUnique')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(node.name), 1))
                                : (node.type == 'difference')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(node.name), 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(node.name), 1))
                          ]),
                          _: 1
                        }, 8, ["loadData", "onAfterSelect"])
                      ], 2),
                      (_ctx.$vm.isShowView)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            (_ctx.$vm.showLeftBrowser)
                              ? (_openBlock(), _createBlock(_component_k_model_browser, {
                                  key: 0,
                                  ref: _ctx.$vm.refLeftBrowser,
                                  objParam: _ctx.$vm.leftDocObjParam,
                                  environment: "Part",
                                  container: "partBomCompareLeft"
                                }, null, 8, ["objParam"]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                  _createVNode(_component_k_empty, {
                                    description: "未找到可视化文件",
                                    style: {"position":"absolute","top":"50%","transform":"translateY(-50%)"}
                                  })
                                ]))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_k_split_pane, null, {
                default: _withCtx(() => [
                  _createVNode(_component_k_panel, { class: "rightPanel" }, {
                    header: _withCtx(() => [
                      (_ctx.$vm.rightTreeRootNode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_k_select, {
                              ref: "select",
                              style: {"width":"120px"},
                              value: _ctx.$vm.rightTreeExpandType,
                              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.$vm.rightTreeExpandType) = $event)),
                              options: _ctx.expandTypeOptions,
                              onChange: _cache[10] || (_cache[10] = (args)=>_ctx.$vm.refreshRightTree(args)),
                              allowClear: false
                            }, null, 8, ["value", "options"]),
                            _createVNode(_component_k_object_view_selector, {
                              style: {"width":"120px","margin-left":"5px"},
                              ref: _ctx.$vm.refRightViewSelector,
                              value: { name: _ctx.$vm.refRightView },
                              disabled: _ctx.$vm.rightViewDisabled,
                              onChange: _cache[11] || (_cache[11] = (args)=>_ctx.$vm.refreshRightTreeAfterView(args))
                            }, null, 8, ["value", "disabled"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$vm.isShowView ? 'has-view' : 'no-view')
                      }, [
                        _createVNode(_component_k_tree_view, {
                          ref: _ctx.$vm.refRightFolderTree,
                          nodeKey: "uniqueIdentifier",
                          loadData: _ctx.$vm.loadRightTreeData,
                          onLoaded: _cache[12] || (_cache[12] = () => _ctx.$vm.changeTreeNodeColor()),
                          onAfterSelect: _ctx.$vm.rightAfterSelect,
                          defaultExpandedLevel: 0,
                          autoSelectFirstNodeAfterDefaultExpand: false
                        }, {
                          title: _withCtx(({node}) => [
                            (node.type == 'leftUnique')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(node.name), 1))
                              : (node.type == 'rightUnique')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(node.name), 1))
                                : (node.type == 'difference')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(node.name), 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(node.name), 1))
                          ]),
                          _: 1
                        }, 8, ["loadData", "onAfterSelect"])
                      ], 2),
                      (_ctx.$vm.isShowView && _ctx.$vm.showRightView)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            (_ctx.$vm.showRightBrowser)
                              ? (_openBlock(), _createBlock(_component_k_model_browser, {
                                  key: 0,
                                  ref: _ctx.$vm.refRightBrowser,
                                  objParam: _ctx.$vm.rightDocObjParam,
                                  environment: "Part",
                                  container: "partBomCompareRight"
                                }, null, 8, ["objParam"]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  _createVNode(_component_k_empty, {
                                    description: "未找到可视化文件",
                                    style: {"position":"absolute","top":"50%","transform":"translateY(-50%)"}
                                  })
                                ]))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 2),
        _withDirectives(_createElementVNode("div", _hoisted_15, [
          _createVNode(_component_k_tabs, {
            ref: _ctx.$vm.refGlobalTab,
            type: "editable-card"
          }, null, 512)
        ], 512), [
          [_vShow, !_ctx.$vm.isShowView]
        ])
      ], 512)
    ]),
    _: 1
  }))
}
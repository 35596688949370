import { SelectOption, ViewModelOptions, EnumDialogResult } from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { ClassMetaClientSrv, EnumPropertyEditorType, MetaProperty, ObjectClientSrv } from '../../../../client-srv'
import { EnumEditorEnvironment, KBasePropertyEditorViewModel } from '../../base'
import { KReferenceEditorEmitsType, KReferenceEditorPropType } from './interface'

/** 引用属性 */
export default class KReferenceEditorViewModel extends KBasePropertyEditorViewModel<
  KReferenceEditorEmitsType,
  KReferenceEditorPropType
> {
  //#region 字段、属性定义
  /** 是否显示清除按钮 */
  isShowClear = ref(false)

  /** 显示值 */
  displayVal = ref('')

  /** 属性脚本 */
  // eventScript: utils.ReferenceEventScript | undefined
  /**引用属性对象类编码 */
  referObjectClsCode = ref<string>()
  /**引用属性名 */
  referPropName = ref<string>()
  /**引用属性的属性元数据 */
  referenceMeta = ref<Array<MetaProperty>>([])
  refInputGroup = ref<HTMLElement>()

  constructor(options: ViewModelOptions<KReferenceEditorPropType>) {
    super(options)

    watch(
      () => options.props.refClsCode,
      () => {
        this.initObjClassInfo()
      },
      {
        deep: true,
        immediate: true
      }
    )

    watch(
      () => this.stateValue.value,
      newVal => {
        //解析属性元信息
        this.processMeta(newVal)
        this.emit('change', newVal)
      }
    )
  }

  viewDidMount() {
    this.init()
  }

  /**初始化 */
  async init() {
    //网格编辑场景下，需要手动设置控件值
    if (this.props.environment == EnumEditorEnvironment.DATA_GRID) {
      const filed = this.props.field.split('$')[1]
      const value = this.props.value as Record<string, any>
      if (filed && value) {
        this.displayVal.value = value[filed]
        this.stateValue.value = value['nameEn']
      }

      this.setValue(this.props.value)
    }

    //TODO:后续添加脚本事件
    // 属性脚本事件
    // this.eventScript = new utils.ReferenceEventScript()
    // await this.eventScript.init({
    //   methods: {},
    //   fields: [this.property.code],
    //   environment: this.props.environment
    // })
  }

  /**
   * 设置编辑控件的值
   * @param newValue 要设置的值，这个值为显示值
   */
  setValue(newValue: any, setChanged?: boolean) {
    //解析属性元信息
    this.processMeta(newValue, setChanged)
  }

  /**输入框鼠标移入 */
  inputMouseOver() {
    //如果当前可用
    if (!this.disabled.value && !this.props.readonly) {
      this.isShowClear.value = true
    }
  }

  /**输入框鼠标移出 */
  inputMouseOut() {
    this.isShowClear.value = false
  }

  /**清空文本 */
  clear() {
    this.stateValue.value = ''
    this.displayVal.value = ''

    // 循环清空其他列
    this.props.refObjCodes.forEach((f: string) => {
      const name = this.referObjectClsCode.value + '$' + f
      this.props.api.setPropertyValue(name, '', { setChanged: true })
    })
  }

  /**获取对象类和对象类属性信息 */
  private initObjClassInfo() {
    if (this.props.refClsCode) {
      /**引用属性对象类编码 */
      this.referObjectClsCode.value = this.props.refClsCode
      /**引用属性名 */
      const field = this.props.field.split('$')
      this.referPropName.value = field[1]
      //TODO:去掉元数据缓存，由后端提供接口获取
      this.referenceMeta.value = []
    }
  }

  /**解析元数据信息获取实际值和显示值 */
  processMeta(value: any, setChanged?: boolean) {
    // if (this.referenceMeta.value.length <= 0) {
    //   console.log(`未找到对象类${this.referObjectClsCode.value}的属性原信息`)
    //   return
    // } else {
    //TODO:先手动输入
    this.stateValue.value = value || {}
    this.displayVal.value = value
    this.props.api.setPropertyValue(this.referObjectClsCode.value!, this.stateValue.value)
    return
    // }

    // // 1.解析filed获取当前属性的元信息
    // const property = this.referenceMeta.value!.find(x => x.code === this.referPropName.value)!

    // // 2.根据属性元信息获取属性的编辑类型及编辑参数
    // const editType = property.editor ? property.editor.editType : ''
    // const editArgs = JSON.parse(property.editor && property.editor.editArgs ? property.editor.editArgs : '{}')

    // // 3.通过编辑类型设置属性的实际值和显示值
    // switch (editType) {
    //   case EnumPropertyEditorType.SINGLE:
    //   case EnumPropertyEditorType.MULTI:
    //   case EnumPropertyEditorType.DATETIME:
    //   case EnumPropertyEditorType.RICH_TEXT:
    //     {
    //       this.displayVal.value = value
    //       this.stateValue.value = value
    //       this.originalValue.value = value
    //     }
    //     break
    //   case EnumPropertyEditorType.DROPDOWN_LIST:
    //   case EnumPropertyEditorType.DROPDOWN_LIST_EDITABLE:
    //   case EnumPropertyEditorType.DROPDOWN_LIST_WITH_DISPLAY:
    //   case EnumPropertyEditorType.LIST_VIEW_SINGLE_CHECK:
    //     {
    //       const options = editArgs['options'] as Array<SelectOption>
    //       const displayValue = options.find(x => x.value == value)
    //       this.displayVal.value = displayValue ? displayValue.label : ''
    //       this.stateValue.value = value
    //       this.originalValue.value = value
    //     }
    //     break
    //   case EnumPropertyEditorType.CHECKBOX:
    //     {
    //       const displayValue = value == 0 ? '否' : '是'
    //       this.displayVal.value = displayValue
    //       this.stateValue.value = displayValue
    //       this.originalValue.value = displayValue
    //     }
    //     break
    //   default:
    //     this.displayVal.value = value
    //     this.stateValue.value = value
    //     this.originalValue.value = value
    //     break
    // }

    // /**记录旧值 */

    // if (setChanged) {
    //   this.originalValue.value = this.stateValue.value
    // }
  }

  /** 打开对象选择器 */
  openObjectSelector() {
    const objectClassTreeProps = {
      showObjClsCodes: [this.referObjectClsCode.value!],
      defaultSelectClsCode: this.referObjectClsCode.value
    }

    ObjectClientSrv.openObjectSelectDialog({
      title: '选择引用对象',
      showApply: false,
      isMultipleSelection: false,
      objectClassManageQueryParam: { ...objectClassTreeProps },
      objectSearchQueryParam: { ...objectClassTreeProps },
      getContainer: this.refInputGroup.value,
      onClosing: event => {
        if (event.dialogResult == EnumDialogResult.Confirm) {
          const selectedRows = event.viewInstance!.getSelectedRows()
          if (!selectedRows || selectedRows.length <= 0) {
            event.cancel = true
            return
          }
          this.onSelectObj(selectedRows[0])
        }
      }
    })
  }

  /**
   * 对象选择完成后事件
   * @param selectedRow
   */
  private onSelectObj(selectedRow: Record<string, any>) {
    /** 获取显示值 */
    const value = selectedRow[this.referPropName.value!]

    this.processMeta(value)

    if (this.props.api) {
      // 设置实际值
      this.props.api.setPropertyValue(this.referObjectClsCode.value!, selectedRow)
    }

    // 触发事件
    // this.eventScript?.invokeEvent({})
  }
}

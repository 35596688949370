import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectSelectorView from './KObjectSelector'
import KObjectSelectorViewModel from './KObjectSelectorViewModel'

const KObjectSelector = connect(KObjectSelectorView, KObjectSelectorViewModel)

export default withInstall(KObjectSelector)
export { KObjectSelector, KObjectSelectorView, KObjectSelectorViewModel }
export * from './interface'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5897f203"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-start-center mb10" }
const _hoisted_2 = { class: "count plr3" }
const _hoisted_3 = {
  class: "flex-start-start flex-wrap overflowYAuto pt5",
  style: { height: `460px` }
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "text-nowrap plr2 w100p" }
const _hoisted_6 = {
  key: 1,
  class: "flex1 flex-center fs18 h100p"
}
const _hoisted_7 = { class: "flex-center mt10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_tooltip = _resolveComponent("k-tooltip")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_secction = _resolveComponent("secction")!

  return (_openBlock(), _createBlock(_component_secction, { class: "flex-start flex-direction-column flex-between h100p" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(" 文档类型： "),
        _createVNode(_component_k_select, {
          value: _ctx.$vm.documentType,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.documentType) = $event)),
          style: {"width":"300px"},
          options: _ctx.$vm.documentTypeOptions
        }, null, 8, ["value", "options"]),
        _withDirectives(_createElementVNode("span", { class: "ml10" }, "支持文件类型: " + _toDisplayString(_ctx.$vm.accept.local), 513), [
          [_vShow, _ctx.$vm.accept]
        ])
      ]),
      _createElementVNode("section", {
        class: "file-container",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.$vm.openSelectFileDialog && _ctx.$vm.openSelectFileDialog(...args)))
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.fileList.length), 1),
        _createElementVNode("section", _hoisted_3, [
          (_ctx.fileList.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.fileList, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "card flex-center flex-direction-column mlr5 mb5",
                  key: item.id
                }, [
                  _createElementVNode("div", {
                    class: "close flex-center",
                    onClick: _withModifiers(($event: any) => (_ctx.$vm.removeFile(item)), ["stop"])
                  }, "×", 8, _hoisted_4),
                  _createElementVNode("div", null, [
                    _createVNode(_component_k_icon, {
                      type: _ctx.$vm.getIcon(item),
                      width: "60",
                      height: "60",
                      color: "#324669"
                    }, null, 8, ["type"])
                  ]),
                  _createVNode(_component_k_tooltip, { placement: "bottom" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(item?.fileName), 1)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("footer", _hoisted_5, _toDisplayString(item?.fileName), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, " 点击上传文件 "))
        ])
      ]),
      _createElementVNode("footer", _hoisted_7, [
        _createVNode(_component_k_button, {
          type: "primary",
          onClick: _ctx.$vm.extractFiles,
          disabled: !_ctx.fileList.length || !_ctx.$vm.documentType
        }, {
          default: _withCtx(() => [
            _createTextVNode("提取文件")
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ]),
    _: 1
  }))
}
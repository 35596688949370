import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  EnumToolStripCompType
} from '@kmsoft/upf-core'
import {
  EnumSupportObjectNumMode,
  ObjectToolStripItem,
  EnumToolStripItemKeys,
  KObjectClassTreePropOptions,
  QueryCondition,
  EnumPurviewCode
} from '@kmsoft/ebf-common'
import lodash from 'lodash'

/** 参数 **/
export const KObjectWorkspacePropOptions = {
  ...lodash.omit(KObjectClassTreePropOptions, 'defaultSelectClsCode'),
  /** 默认对象编码 */
  modelCode: VuePropTypes.string().def(''),
  /** 启用`包含子类`勾选框 */
  includeChildrenCheck: VuePropTypes.bool().def(false),
  /** 搜索状态, 处于搜索状态将禁用底部按钮 */
  isSearching: VuePropTypes.bool().def(false),
  /** 是否显示搜索 */
  showSearch: VuePropTypes.bool().def(false),
  /** 是否显示取消 */
  showCancel: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KObjectWorkspacePropType = ViewPropsTypeExtract<typeof KObjectWorkspacePropOptions>

/** 事件 */
export const KObjectWorkspaceEventEmits = {
  ...BaseViewEventEmits,
  /** 对象类Id改变事件 */
  'update:modelCode': (value: string) => true,
  /** 此方法会在点击查询后被调用 */
  search: (condition: QueryCondition): boolean => true,
  /** 点击取消按钮 */
  cancel: () => true
}

/** 事件类型 **/
export type KObjectWorkspaceEmitsType = ViewEmitsTypeExtract<typeof KObjectWorkspaceEventEmits>

/**
 * 基础工具栏
 */
export const baseToolItem = [
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OBJ,
    title: '对象',
    icon: 'DropObjLife',
    visible: true,
    compType: EnumToolStripCompType.CONTEXT_MENU,
    supportedObjNumMode: EnumSupportObjectNumMode.Single,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
        title: '创建',
        icon: 'plus',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+n'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
        title: '删除',
        icon: 'DeleteObj',
        visible: false,
        supportedObjClsTempletID: ['Part', 'Document'],
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+r'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS,
        title: '另存',
        supportedObjClsTempletID: ['Part', 'Document'],
        icon: 'ObjSaveAs',
        visible: false,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+s'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE,
        title: '结构管理',
        icon: 'file-protect',
        visible: false,
        supportedObjClsTempletID: ['Part', 'Document'],
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'ctrl+p'
      }
    ]
  },

  {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_GRID_OPERATION,
    title: '列表',
    icon: 'DropGridOp',
    visible: true,
    compType: EnumToolStripCompType.CONTEXT_MENU,
    supportedObjNumMode: EnumSupportObjectNumMode.Single,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
        title: '刷新',
        icon: 'sync',
        visible: true,
        purviewId: EnumPurviewCode.RefreshList,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'f5'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD,
        title: '零部件导入模板下载',
        icon: 'download',
        purviewId: EnumPurviewCode.DownloadPartTemplate,
        supportedObjClsTempletID: ['Part'],
        visible: true,
        compType: EnumToolStripCompType.BUTTON
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_IMPORT,
        title: '零部件导入',
        icon: 'import',
        purviewId: EnumPurviewCode.ImportParts,
        supportedObjClsTempletID: ['Part'],
        visible: true,
        compType: EnumToolStripCompType.BUTTON
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_OBJ_GROUP_OPERATION,
    title: '版本操作',
    icon: 'control',
    visible: false,
    supportedObjClsTempletID: ['Part', 'Document'],
    compType: EnumToolStripCompType.CONTEXT_MENU,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT,
        title: '检出(O)',
        icon: 'DocCheckOut',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+o'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT,
        title: '取消检出',
        icon: 'file-text',
        visible: true,
        compType: EnumToolStripCompType.BUTTON
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN,
        title: '检入(I)',
        icon: 'DocCheckIn',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+i'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE,
        title: '修订',
        icon: 'tag',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_USING_STATISTICS,
    title: '使用统计',
    icon: 'table',
    visible: false,
    purviewId: EnumPurviewCode.StatUsages,
    supportedObjClsTempletID: ['Part'],
    compType: EnumToolStripCompType.BUTTON
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE,
    title: '基线对比',
    icon: 'file-protect',
    visible: false,
    supportedObjClsTempletID: ['ManagedBaseline'],
    compType: EnumToolStripCompType.BUTTON,
    shortcutKey: 'alt+o'
  }
] as Array<ObjectToolStripItem>

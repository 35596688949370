import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KImportItemPropOptions = {
  ...BaseViewPropOptions,
  /** 网格数据 */
  dataSource: VuePropTypes.array<Record<string, any>>().def([]),
  /** 显示的字段信息 */
  fields: VuePropTypes.array<{ title: string; id: string }>().def([]),
  /** 行主键 */
  rowKey: VuePropTypes.string().def('id'),
  /** 是否能多选 */
  isMultipleSelection: VuePropTypes.bool().def(true)
}

/** 参数类型 **/
export type KImportItemPropType = ViewPropsTypeExtract<typeof KImportItemPropOptions>

/** 事件 */
export const KImportItemEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KImportItemEmitsType = ViewEmitsTypeExtract<typeof KImportItemEventEmits>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_form_item = _resolveComponent("k-form-item")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$vm.formList, (formFields) => {
    return (_openBlock(), _createBlock(_component_k_form_item, _mergeProps({
      key: formFields?.props,
      label: formFields?.label,
      name: formFields?.props,
      "label-col": { span: formFields?.lableCol || _ctx.$vm.labelCol }
    }, _ctx.omit(formFields?.compConfig, ['isRequired']), {
      rules: 
      formFields?.compConfig?.isRequired
        ? [{ required: true, message: formFields?.compConfig?.message || 'Please Set Your Message!' }]
        : []
    
    }), {
      default: _withCtx(() => [
        (!_ctx.$vm.isView)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(formFields?.type || 'k-input'), _mergeProps({
              key: 0,
              ref_for: true,
              ref: formFields?.ref,
              name: formFields?.name
            }, formFields?.attrs, {
              value: _ctx.$vm.formValue[formFields?.props],
              "onUpdate:value": ($event: any) => ((_ctx.$vm.formValue[formFields?.props]) = $event),
              checked: _ctx.$vm.formValue[formFields?.props],
              "onUpdate:checked": ($event: any) => ((_ctx.$vm.formValue[formFields?.props]) = $event)
            }), null, 16, ["name", "value", "onUpdate:value", "checked", "onUpdate:checked"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$vm.formValue[formFields?.props + 'show']), 1))
      ]),
      _: 2
    }, 1040, ["label", "name", "label-col", "rules"]))
  }), 128))
}
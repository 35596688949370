import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

export interface ChangeRequestDataInter {
  id: string
  rdmExtensionType: string
}

/** 参数 **/
export const KCustomerNeedsSectionCreatePropOptions = {
  ...BaseViewPropOptions,
  formValue: VuePropTypes.createType<any>().def()
}

/** 参数类型 **/
export type KCustomerNeedsSectionCreatePropType = ViewPropsTypeExtract<typeof KCustomerNeedsSectionCreatePropOptions>

/** 事件 */
export const KCustomerNeedsSectionCreateEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KCustomerNeedsSectionCreateEmitsType = ViewEmitsTypeExtract<typeof KCustomerNeedsSectionCreateEventEmits>

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumClsDataType, EnumClsEditorType } from '../../../enums'

// form
export interface IRefObjEditorFormDefinition {
  //引用对象的编码
  refClsCode: string
  // 引用属性的code
  refObjCodes: Array<string>
  // 引用属性的显示名
  refObjNames: string
  // 引用属性所属分组
  refObjGroup: string
}

// 对象界面定义定义状态
export interface IRefObjEditorCustomPropsViewModelState {
  formState: IRefObjEditorFormDefinition
}

// 对象界面定义定义事件
export const KRefObjEditorCustomPropsEventEmits = {
  ...BaseViewEventEmits
}

// 对象界面定义定义属性选项
export const KRefObjEditorCustomPropOptions = {
  ...BaseViewPropOptions,
  /** 当前属性id */
  propertyId: VuePropTypes.string().def(),
  /** 当前编辑对象类的数据类型 */
  dataType: VuePropTypes.createType<EnumClsDataType>().def(),
  /** 当前编辑对象类的数据的编辑类型 */
  editType: VuePropTypes.createType<EnumClsEditorType>().def(),
  /** 当前编辑类型的自定义参数 */
  editArgs: VuePropTypes.string().def(),
  /** 属性参数，如对象类属性的关联属性信息等 */
  propertyArgs: VuePropTypes.string().def(''),
  /** 是否只读 */
  readonly: VuePropTypes.bool().def(false)
}

// emit type
export type KRefObjEditorCustomPropsEventEmitsType = ViewEmitsTypeExtract<typeof KRefObjEditorCustomPropsEventEmits>

// 对象界面定义定义属性类型
export type KRefObjEditorCustomPropType = ViewPropsTypeExtract<typeof KRefObjEditorCustomPropOptions>

import {
  SimpleViewPropOptions,
  SimpleViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { SelectItem } from '@kmsoft/ebf-common'

export interface KDistributionRecordDataInter {
  id?: string
  rdmExtensionType?: string
  receiver?: string
  status?: string
  countOfCopies?: string
}

/** 参数 **/
export const KDistributionRecordObjGridPropOptions = {
  ...SimpleViewPropOptions
}

/** 参数类型 **/
export type KDistributionRecordObjGridPropType = ViewPropsTypeExtract<typeof KDistributionRecordObjGridPropOptions>

/** 事件 */
export const KDistributionRecordObjGridEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KDistributionRecordObjGridEmitsType = ViewEmitsTypeExtract<typeof KDistributionRecordObjGridEventEmits>

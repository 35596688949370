import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { KControlAttribute, KControlEvent } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'

/* 属性配置项集合 */
const BASE = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE] as Array<KControlAttribute>

/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
] as Array<KControlAttribute>

const PROPS = [
  LAYOUT_CONFIG_ITEM_COLLECTION.MIN_COL_WIDTH,
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_GAP,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_GAP,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_COUNT,
  LAYOUT_CONFIG_ITEM_COLLECTION.LABEL_POSITION,
  LAYOUT_CONFIG_ITEM_COLLECTION.LABEL_WIDTH
]
const EVENT = [
  {
    name: 'mounted',
    title: '加载完成事件',
    describe: '表单挂载完成后触发'
  }
] as Array<KControlEvent>

export default { BASE, PROPS, LAYOUT, EVENT }

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_checkbox_group = _resolveComponent("k-checkbox-group")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_k_checkbox_group, {
      class: "checkbox-style",
      value: _ctx.$vm.checkValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.checkValue) = $event)),
      name: "checkboxgroup",
      options: _ctx.$vm.plainOptions
    }, null, 8, ["value", "options"])
  ]))
}
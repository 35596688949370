import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumDataType, MetaProperty, QueryCondition } from '../../../../client-srv'

//#region 枚举
/** 操作符 */
export enum EnumFilterOperatorCode {
  /** 等于 */
  EQUAL = '=',
  /** 包含 */
  LIKE = 'like',
  /** 开始为 */
  STARTWITH = 'startWith',
  /** 结尾为 */
  ENDWITH = 'endWith',
  /** 介于 */
  IN = 'in',
  /** 不包含 */
  NOT_IN = 'not in',
  /** 小于 */
  LESS = '<',
  /** 大于 */
  GREATER = '>',
  /** 小于等于 */
  LESS_EQUAL = '<=',
  /** 大于等于 */
  GREATER_EQUAL = '>=',
  /** 不等于 */
  NOT_EQUAL = '<>',
  /** 为空 */
  IS_NULL = 'ISNULL',
  /** 非空 */
  NOT_NULL = 'NOTNULL'
}

/** 逻辑运算符 */
export enum EnumFilterLogicalOperation {
  /** 且 */
  And = 'and',
  /** 或 */
  Or = 'or'
}
//#endregion

//#region 常量
/** 操作运算符对照表 */
export const FILTER_OPERATOR_CODE_COMPARISONS: Record<EnumFilterOperatorCode, string> = {
  [EnumFilterOperatorCode.EQUAL]: '等于',
  [EnumFilterOperatorCode.LIKE]: '包含',
  [EnumFilterOperatorCode.STARTWITH]: '开始为',
  [EnumFilterOperatorCode.ENDWITH]: '结尾为',
  [EnumFilterOperatorCode.IN]: '介于',
  [EnumFilterOperatorCode.NOT_IN]: '不包含',
  [EnumFilterOperatorCode.LESS]: '小于',
  [EnumFilterOperatorCode.GREATER]: '大于',
  [EnumFilterOperatorCode.LESS_EQUAL]: '小于等于',
  [EnumFilterOperatorCode.GREATER_EQUAL]: '大于等于',
  [EnumFilterOperatorCode.NOT_EQUAL]: '不等于',
  [EnumFilterOperatorCode.IS_NULL]: '为空',
  [EnumFilterOperatorCode.NOT_NULL]: '非空'
}

/** 逻辑运算符对照表 */
export const FILTER_LOGICAL_OPERATION_COMPARISONS: Record<EnumFilterLogicalOperation, string> = {
  [EnumFilterLogicalOperation.And]: '并且',
  [EnumFilterLogicalOperation.Or]: '或者'
}
//#endregion

//#region 类型定义
/** 高级搜索结果 */
export type RuleResult = {
  /** 表达式 */
  expression: string
  /** 过滤器集合，被标识符所标记 */
  rules: Array<RuleItem>
  /** 是否忽略大小写 */
  isIgnoreCase?: boolean
}

export type RuleItem = {
  /** 唯一Id */
  id: string
  /** 操作符 */
  operator: EnumFilterOperatorCode
  /** 属性Id */
  key: string
  /** 值 */
  value: string | number | undefined
  /** 逻辑操作符 */
  LogicOperator?: EnumFilterLogicalOperation
  /** 是否使用 */
  isUsing: boolean
  /** 数据类型 */
  dataType: EnumDataType
  /**是否可用 */
  disabled?: boolean
  //#endregion
}

/** 参数 **/
export const KRulePropertyPropOptions = {
  ...BaseViewPropOptions,
  /** 对象类的字段列表 */
  fields: VuePropTypes.array<MetaProperty>().def([]),
  /** 显示逻辑操作列 */
  showLogicOperatorColumn: VuePropTypes.bool().def(false),
  /** 启用表达式编辑器 */
  showExpressionEditor: VuePropTypes.bool().def(true),
  /** 条件 */
  condition: VuePropTypes.createType<QueryCondition>().def(),
  /** 是否显示显示子 */
  showIncludeChildren: VuePropTypes.bool().def(false),
  /** 是否包含子项 */
  includeChild: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KRulePropertyPropType = ViewPropsTypeExtract<typeof KRulePropertyPropOptions>

/** 事件 */
export const KRulePropertyEventEmits = {
  ...BaseViewEventEmits,
  /** v-model:condition */
  'update:condition': (value: QueryCondition) => true,
  /** v-model:condition */
  'update:includeChild': (value: boolean) => true
}

/** 事件类型 **/
export type KRulePropertyEmitsType = ViewEmitsTypeExtract<typeof KRulePropertyEventEmits>

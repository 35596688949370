import { connect, withInstall } from '@kmsoft/upf-core'
import KAffectedObjectCreateActivityView from './KAffectedObjectCreateActivity.vue'
import KAffectedObjectCreateActivityViewModel from './KAffectedObjectCreateActivityViewModel'

const KAffectedObjectCreateActivity = connect(KAffectedObjectCreateActivityView, KAffectedObjectCreateActivityViewModel)

export default withInstall(KAffectedObjectCreateActivity)
export { KAffectedObjectCreateActivity, KAffectedObjectCreateActivityView, KAffectedObjectCreateActivityViewModel }

// 模板生成文件
// export * from './{{folderName}}'

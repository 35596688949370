import { BaseViewModel, KDataGridViewModel, KNotification, SelectOption, ViewModelOptions } from '@kmsoft/upf-core'
import { KPartAssociationFormEmitsType, KPartAssociationFormPropType, WorkingStateEnum } from './interface'
import { ref } from 'vue'
import { EnumLifecycleState, ObjectClientSrv } from '@kmsoft/ebf-common'

/** KPartAssociationForm */
export default class KPartAssociationFormViewModel extends BaseViewModel<
  KPartAssociationFormEmitsType,
  KPartAssociationFormPropType
> {
  /**网格 */
  refPartAssociationGrid = ref<KDataGridViewModel>()
  /**生命周期候选值 */
  lifecycleStateOptions = ref<Array<SelectOption>>(EnumLifecycleState._list)
  /**工作状态候选值 */
  workingStateOptions = ref<Array<SelectOption>>(WorkingStateEnum)

  constructor(options: ViewModelOptions<KPartAssociationFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 网格数据加载
   */
  loadData() {
    const partAssociation = this.props.partAssociation
    return new Promise((resolve, reject) => {
      if (partAssociation && partAssociation.length != 0) {
        resolve({
          rows: partAssociation,
          total: partAssociation.length
        })
      } else {
        reject([])
      }
    })
  }

  /**
   * 刷新网格
   */
  refresh() {
    this.refPartAssociationGrid.value?.refresh()
  }

  /**
   * 导出excel
   */
  exportAsExcel() {
    const params = {
      columnKeys: this.refPartAssociationGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id)
    }
    this.refPartAssociationGrid.value?.exportDataAsExcel(params)
  }

  /**
   * 导出选中
   */
  exportSelectAsExcel() {
    const data = this.refPartAssociationGrid.value?.getSelectedRows()
    if (data && data.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择要导出的数据'
      })
      return
    }
    const params = {
      onlySelected: true,
      columnKeys: this.refPartAssociationGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id)
    }
    this.refPartAssociationGrid.value?.exportDataAsExcel(params)
  }

  /**
   * 打开新标签页展示零部件对象
   */
  async openPartObjTab(row: any) {
    const param = {
      id: row?.partId,
      modelCode: row!.partRdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.partRdmExtensionType)
    }
    ObjectClientSrv.openObj(param)
  }

  /**
   * 打开新标签页展示文档对象
   */
  async openDocObjTab(row: any) {
    const param = {
      id: row?.docId,
      modelCode: row!.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.rdmExtensionType)
    }
    ObjectClientSrv.openObj(param)
  }
}

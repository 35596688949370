import { connect, withInstall } from '@kmsoft/upf-core'
import KFlowApprovalProcessView from './KFlowApprovalProcess.vue'
import KFlowApprovalProcessViewModel from './KFlowApprovalProcessViewModel'

const KFlowApprovalProcess = connect(KFlowApprovalProcessView, KFlowApprovalProcessViewModel)

export default withInstall(KFlowApprovalProcess)
export { KFlowApprovalProcess, KFlowApprovalProcessView, KFlowApprovalProcessViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView } from '@kmsoft/upf-core'
import { KPartManagePropOptions, KPartManageEventEmits } from './interface'
import KPartManageViewModel from './KPartManageViewModel'

export default defineView({
  name: 'KPartManage',
  props: KPartManagePropOptions,
  emits: KPartManageEventEmits,
  viewModel: KPartManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})


import { defineComponent, ref } from 'vue'
import { defineView } from '@kmsoft/upf-core'
import { KPartReportPropOptions, KPartReportEventEmits } from './interface'
import KPartReportViewModel from './KPartReportViewModel'
import KPartReportMake from './part-report-make'
import KPartReportBuy from './part-report-buy'

export default defineView({
  name: 'KPartReport',
  props: KPartReportPropOptions,
  emits: KPartReportEventEmits,
  viewModel: KPartReportViewModel,
  components: { KPartReportMake, KPartReportBuy },
  setup(props, { emit, slots, attrs, vm }) {
    return {
      activeKey: ref('1')
    }
  }
})

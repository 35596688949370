import { IKDataGridCellParams, KDataGridColumn } from '@kmsoft/upf-core'
import { KFieldEditorBaseAllProps } from './inherits'
import { VNode } from 'vue'

/**
 * 单元格渲染器参数
 */
export type IKObjectClassGridCellRendererParams = KFieldEditorBaseAllProps & {
  /** 单元格参数 */
  gridParams: IKDataGridCellParams
}

/**
 * 单元格编辑器参数
 */
export type IKObjectClassGridCellEditorParams = KFieldEditorBaseAllProps & {
  /** 单元格参数 */
  gridParams: IKDataGridCellParams
}
export abstract class IKPropertyRender {
  /**
   * 获取非编辑模式下显示模板
   * @param params
   */
  abstract getColumn(): KDataGridColumn
  /**
   * 获取编辑状态下显示模板
   * @param params
   */
  abstract getEditor(params: Record<string, any>): VNode
}

import KReferenceEditor from '.'
import { KObjectClassGridBaseColumn, GridCellEditorParams, GridCellRendererParams } from '../../base'

/** 引用属性列 */
export class KDataGridReferencePropertyColumn extends KObjectClassGridBaseColumn {
  getCellRenderer(params: GridCellRendererParams) {
    const col = (params.cellParams as any).col
    const cellRow = (params.cellParams as any).row
    //获取值  clsCode#property$filed
    const property = col.field.split('#')[1]
    const field = property.split('$')[0]

    const value = cellRow[field]
    return (
      <KReferenceEditor {...params} refClsCode={col.refClsCode} refObjCodes={col.refObjCodes} value={value} field={property} />
    )
  }

  getCellEditor(params: GridCellEditorParams) {
    const col = (params.cellParams as any).col
    const cellRow = (params.cellParams as any).row
    //获取值  clsCode#property$filed
    const property = col.field.split('#')[1]
    const field = property.split('$')[0]

    const value = cellRow[field]
    return (
      <KReferenceEditor {...params} refClsCode={col.refClsCode} refObjCodes={col.refObjCodes} value={value} field={property} />
    )
  }
}

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3ba54f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tree-node-title" }
const _hoisted_2 = {
  key: 0,
  class: "tree-node-unvisible"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "tree-node-invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_menu_item = _resolveComponent("k-menu-item")!
  const _component_k_menu_divider = _resolveComponent("k-menu-divider")!
  const _component_k_menu = _resolveComponent("k-menu")!
  const _component_k_tree_view = _resolveComponent("k-tree-view")!

  return (_openBlock(), _createBlock(_component_k_tree_view, {
    ref: _ctx.$vm.refObjClsTree,
    nodeKey: "id",
    treeSelectedKey: _ctx.$vm.treeSelectedKey,
    defaultExpandedLevel: 0,
    autoSelectLastNode: false,
    autoSelectFirstNodeAfterDefaultExpand: false,
    onAfterSelect: _cache[0] || (_cache[0] = (node) => _ctx.$vm.refreshConfigValuePanel(node.node))
  }, {
    title: _withCtx(({node}) => [
      _createElementVNode("span", _hoisted_1, [
        (node?.visible === '0' || !node?.visible || node?.visible === 'false')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(node?.name), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(node?.name), 1)),
        (node?.valid !== '1' && (node?.type === _ctx.EnumNodeType.SYS_MODULE || node?.type === _ctx.EnumNodeType.SYS_CONFIG))
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, " * "))
          : _createCommentVNode("", true)
      ])
    ]),
    contextmenu: _withCtx(({node}) => [
      _createVNode(_component_k_menu, { style: {"min-width":"150px"} }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$vm.contextMenuMap[node.type], (contextMenu) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: contextMenu.id + node.id
            }, [
              (
              contextMenu.type === node.type &&
                (contextMenu.pasteTag !== '1' || (contextMenu.pasteTag === '1' && _ctx.$vm.showPasteMenu))
            )
                ? (_openBlock(), _createBlock(_component_k_menu_item, {
                    onClick: () => _ctx.$vm[contextMenu.callback](node),
                    key: contextMenu.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_k_icon, {
                        type: contextMenu.icon
                      }, null, 8, ["type"]),
                      _createTextVNode(" " + _toDisplayString(contextMenu.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              (contextMenu.type === _ctx.EnumNodeLevel.DRVIDER)
                ? (_openBlock(), _createBlock(_component_k_menu_divider, { key: 1 }))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["treeSelectedKey"]))
}

import { EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import { KProjectWorkspaceListPropOptions, KProjectWorkspaceListEventEmits } from './interface'
import KProjectWorkspaceListViewModel from './KProjectWorkspaceListViewModel'

export default defineView({
  name: 'KProjectWorkspaceList',
  props: KProjectWorkspaceListPropOptions,
  emits: KProjectWorkspaceListEventEmits,
  viewModel: KProjectWorkspaceListViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})


import { IKTreeNode, TreeViewSelectEventArgs, defineView } from '@kmsoft/upf-core'
import { KRequireTreePropOptions, KRequireTreeEventEmits } from './interface'
import KRequireTreeViewModel from './KRequireTreeViewModel' //
import { isFunction } from 'lodash'

export default defineView({
  name: 'KRequireTree',
  props: KRequireTreePropOptions,
  emits: KRequireTreeEventEmits,
  viewModel: KRequireTreeViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const afterSelect = (event: TreeViewSelectEventArgs<any>) => {
      vm.emit('afterSelect', event)
    }

    const loadTreeData = (node: null | IKTreeNode) => {
      const loadData = props.loadData
      if (loadData && isFunction(loadData)) {
        return loadData(node)
      }
      return Promise.resolve([])
    }
    return {
      afterSelect,
      loadTreeData
    }
  }
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_baseline_create_form = _resolveComponent("k-baseline-create-form")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, _createSlots({
    default: _withCtx(() => [
      _createVNode(_component_k_baseline_create_form, {
        ref: "refBaselineCreateForm",
        showType: "",
        formValue: { classCode: _ctx.$vm.rdmExtensionType },
        partParam: _ctx.partParam,
        collectedParts: _ctx.collectedParts
      }, null, 8, ["formValue", "partParam", "collectedParts"])
    ]),
    _: 2
  }, [
    (_ctx.showAddButton)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createVNode(_component_k_tool_strip, null, {
              default: _withCtx(() => [
                _createVNode(_component_k_tool_strip_button_item, {
                  name: "add",
                  title: "保存",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vm.save(true)))
                })
              ]),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}
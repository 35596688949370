import { connect, withInstall } from '@kmsoft/upf-core'
import KSsoLoginView from './KSSOLogin.vue'
import KSsoLoginViewModel from './KSSOLoginViewModel'

const KSsoLogin = connect(KSsoLoginView, KSsoLoginViewModel)

export default withInstall(KSsoLogin)
export { KSsoLogin, KSsoLoginView, KSsoLoginViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

export interface BaselineCompareGridDiffDataInter {
  id?: string
  path?: string
  compareResult?: string
  sourceBaseline?: string
  targetBaseline?: string
}

/** 参数 **/
export const KBaselineCompareGridDiffPropOptions = {
  ...BaseViewPropOptions,
  sourceTitle: VuePropTypes.string().def(''),
  targetTitle: VuePropTypes.string().def(''),
  dataSource: VuePropTypes.createType<BaselineCompareGridDiffDataInter[]>().def([])
}

/** 参数类型 **/
export type KBaselineCompareGridDiffPropType = ViewPropsTypeExtract<typeof KBaselineCompareGridDiffPropOptions>

/** 事件 */
export const KBaselineCompareGridDiffEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KBaselineCompareGridDiffEmitsType = ViewEmitsTypeExtract<typeof KBaselineCompareGridDiffEventEmits>

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPersonalHomePagePropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KPersonalHomePagePropType = ViewPropsTypeExtract<typeof KPersonalHomePagePropOptions>

/** 事件 */
export const KPersonalHomePageEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPersonalHomePageEmitsType = ViewEmitsTypeExtract<typeof KPersonalHomePageEventEmits>

/** 任务状态枚举 */
export const TaskStatusEnum = {
  IN_PROGRESS: '进行中',
  WARNING: '预警',
  OVERDUE: '逾期',

  NO_START: '未开始',
  ON_DOING: '进行中',
  COMPLETED: '已完成',
  TIME_OUT: '暂停',
  END: '终止'
}

export const ColorEnum = {
  IN_PROGRESS: '#28b660',
  WARNING: '#ffb600',
  OVERDUE: '#ff5d50',
  NO_START: '	#D3D3D3',
  ON_DOING: '#28b660',
  COMPLETED: '#00FFFF',
  TIME_OUT: '#ffb600',
  END: '#008080'
}

export const MessageTypeEnum = {
  APPROVAL_NOTIFICATION: '审批通知',

  AUTHORIZATION_NOTIFICATION: '授权提醒',

  SYSTEM_NOTIFICATION: '系统通知'
}


import { defineView } from '@kmsoft/upf-core'
import { KConfigGridViewModelPropOptions } from './interface'
import KConfigGridViewModel from './KConfigGridViewModel'
import ConfigGridTable from './dev-config-grid-table'
import ConfigGridTableTile from './dev-config-grid-table-tile'
import ConfigGridList from './dev-config-grid-list'
import ConfigGridCombobox from './dev-config-grid-combobox'
import ConfigGridComboboxTile from './dev-config-grid-combobox-tile'
import { EnumOper, EnumTabType, EnumEditType } from '../../interface'

export default defineView({
  components: {
    ConfigGridTable,
    ConfigGridList,
    ConfigGridCombobox,
    ConfigGridTableTile,
    ConfigGridComboboxTile
  },
  name: 'ConfigGrid',
  props: KConfigGridViewModelPropOptions,
  viewModel: KConfigGridViewModel,
  setup() {
    return {
      EnumOper,
      EnumTabType,
      EnumEditType
    }
  }
})

import { ref, watch } from 'vue'
import { BaseViewModel, EnumDialogResult, ViewModelOptions } from '@kmsoft/upf-core'
import { KPartSelectorEmitsType, KPartSelectorPropType } from './interface'
import { KBasePropertyEditorViewModel, ObjectClientSrv } from '@kmsoft/ebf-common'
import SimpleViewModel from '@kmsoft/upf-core/src/mvvm/view-model/SimpleViewModel'

/** KPartSelector */
export default class KPartSelectorViewModel extends SimpleViewModel<KPartSelectorEmitsType, KPartSelectorPropType> {
  //#region 字段、属性定义
  /** 是否显示清除按钮 */
  isShowClear = ref(false)
  /** 显示值 */
  displayVal = ref('')
  /**引用属性对象类编码 */
  referObjectClsCode = ref<string>()
  /**引用属性名 */
  referPropName = ref<string>()

  refInputGroup = ref<HTMLElement>()
  // 选中的对象
  // selectedObject = ref<Record<string, any>>()

  constructor(options: ViewModelOptions<KPartSelectorPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 设置编辑控件的值
   * @param newValue 要设置的值，这个值为显示值
   */
  setValue(newValue: any, setChanged?: boolean) {
    super.setValue(newValue, setChanged)
    this.displayVal.value = this.getDisplayVal()
  }

  getDisplayVal() {
    // 如果有name属性就会有number属性，version和iteration属性,这里只判断一个即可
    if (this.stateValue.value.name) {
      return `${this.stateValue.value.name}【${this.stateValue.value.number}】_${this.stateValue.value.version}.${this.stateValue.value.iteration}`
    } else {
      return ''
    }
  }

  public getValue() {
    return this.stateValue.value
  }

  /** 打开对象选择器 */
  openObjectSelector() {
    const objectClassTreeProps = {
      showObjClsCodes: ['Part']
    }

    ObjectClientSrv.openObjectSelectDialog({
      title: '选择引用对象',
      showApply: false,
      isMultipleSelection: false,
      objectClassManageQueryParam: { ...objectClassTreeProps },
      objectSearchQueryParam: { ...objectClassTreeProps },
      getContainer: this.refInputGroup.value,
      onClosing: event => {
        if (event.dialogResult == EnumDialogResult.Confirm) {
          const selectedRows = event.viewInstance!.getSelectedRows()
          if (!selectedRows || selectedRows.length <= 0) {
            event.cancel = true
            return
          }
          this.setSelectedObject(selectedRows[0])
        }
      }
    })
  }

  public setSelectedObject(selectedObject: Record<string, any>) {
    // 将选中的对象的值设置到控件上
    this.stateValue.value = selectedObject
    this.displayVal.value = this.getDisplayVal()
    this.emit('change', selectedObject)
  }
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KRuleDefineTestEmitsType, KRuleDefineTestPropType } from './interface'

/** KRuleDefineTest */
export default class KRuleDefineTestViewModel extends BaseViewModel<KRuleDefineTestEmitsType, KRuleDefineTestPropType> {
  constructor(options: ViewModelOptions<KRuleDefineTestPropType>) {
    super(options)
  }

  viewDidMount() {}
}

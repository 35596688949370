import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KObjectCreateOptionEmitsType, KObjectCreateOptionPropType } from './interface'

/** KObjectCreateOption */
export default class KObjectCreateOptionViewModel extends BaseViewModel<
  KObjectCreateOptionEmitsType,
  KObjectCreateOptionPropType
> {
  constructor(options: ViewModelOptions<KObjectCreateOptionPropType>) {
    super(options)
  }

  viewDidMount() {}
}

// 上传协议
export enum EnumProtocolType {
  HTTP = 'http',
  THRIFT = 'thrift'
}

// 文件存在的状态
export enum EnumFileExistStatus {
  /** 全新 */
  NEW = '0',
  /** 已经存在 */
  EXISTED = '1',
  /** 部分存在 */
  HALF_EXISTED = '2'
}

// 组件的编辑状态
export enum EnumFileUploadingStatus {
  /** 上传中 */
  UPLOADING = 'uploading',
  /** 上传完成 */
  DONE = 'done',
  /** 上传失败 */
  ERROR = 'error',
  /** 移除、删除 */
  REMOVED = 'removed',
  /** 已复制 */
  COPIED = 'copied'
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  SimpleViewPropOptions,
  SimpleViewEventEmits,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPromoteActivityAfterGridPropOptions = {
  ...SimpleViewPropOptions,
  /** 是否是变更后对象 **/
  isAfterObject: VuePropTypes.bool().def(false),
  /** 过滤状态 **/
  filterLifecycleState: VuePropTypes.string().def('RELEASED')
}

/** 参数类型 **/
export type KPromoteActivityAfterGridPropType = ViewPropsTypeExtract<typeof KPromoteActivityAfterGridPropOptions>

/** 事件 */
export const KPromoteActivityAfterGridEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KPromoteActivityAfterGridEmitsType = ViewEmitsTypeExtract<typeof KPromoteActivityAfterGridEventEmits>

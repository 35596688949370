
import { defineView } from '@kmsoft/upf-core'
import { KChangeActivityCreatePropOptions, KChangeActivityCreateEventEmits } from './interface'
import KChangeActivityCreateViewModel from './KChangeActivityCreateViewModel'

export default defineView({
  name: 'KChangeActivityCreate',
  props: KChangeActivityCreatePropOptions,
  emits: KChangeActivityCreateEventEmits,
  viewModel: KChangeActivityCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

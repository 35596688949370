import { MetaProperty, QueryCondition } from '../../../../client-srv'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

//#region 通用搜索
export type CommonSearchFormItem = {
  property: MetaProperty
  value: string | number | null
}

export type CommonSearchForm = {
  recordForm: Record<string, CommonSearchFormItem>
  lifeStateForm?: CommonSearcherLifeStateForm
}

export type CommonSearcherLifeStateForm = {
  creator: string
  creatorId: string
  createTime: string[]
  lastModifyTime: string[]
  publishTime: string[]
  checkoutTime: string[]
  lifeCycleState: number[]
}
//#endregion

/** 参数 **/
export const KCommonSearcherPropOptions = {
  ...BaseViewPropOptions,
  /** 对象类分组 */
  modelGroup: VuePropTypes.string()
    .setRequired()
    .def(),
  /** 对象类的拓展字段列表 */
  fields: VuePropTypes.createType<MetaProperty[]>().def([]),
  /** 是否启用通用选项表单 */
  enableCommonOptionsForm: VuePropTypes.bool().def(false),
  /** 缓存条件 */
  conditions: VuePropTypes.createType<{ condition: QueryCondition; lifeState: CommonSearcherLifeStateForm }>().def()
}

/** 参数类型 **/
export type KCommonSearcherPropType = ViewPropsTypeExtract<typeof KCommonSearcherPropOptions>

/** 事件 */
export const KCommonSearcherEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KCommonSearcherEmitsType = ViewEmitsTypeExtract<typeof KCommonSearcherEventEmits>

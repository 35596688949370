import {
  BaseViewPropOptions,
  ViewPropsTypeExtract,
  VuePropTypes,
  BaseViewEventEmits,
  ViewEmitsTypeExtract
} from '@kmsoft/upf-core'
import { UpdateConfigValidStatusEventArgs } from '../../sys-config-value/interface'

export interface IConfigGridTableDefinition {
  defId: string
  id: string
  code: string
  name: string
  value: string
  remark: string
  dataType: string
  editType: string
  editParam: string
}

// 页面事件
export const KConfigGridTableTileEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 更新配置项(模块)有效性状态
   * @param validStatus 有效性
   */
  updateConfigValidStatus: (e: UpdateConfigValidStatusEventArgs) => true
}

export const KConfigGridTableTileViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('')
}

export type KConfigGridTableTileViewModelPropType = ViewPropsTypeExtract<typeof KConfigGridTableTileViewModelPropOptions>

export type KConfigGridTableTileEventEmitsType = ViewEmitsTypeExtract<typeof KConfigGridTableTileEventEmits>


import { defineView } from '@kmsoft/upf-core'
import { KPartSaveAsFormPropOptions, KPartSaveAsFormEventEmits } from './interface'
import KPartSaveAsFormViewModel from './KPartSaveAsFormViewModel'

export default defineView({
  name: 'KPartSaveAsForm',
  props: KPartSaveAsFormPropOptions,
  emits: KPartSaveAsFormEventEmits,
  viewModel: KPartSaveAsFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})


import { defineView } from '@kmsoft/upf-core'
import ObjectCreatePanelTestViewModel from './ObjectCreatePanelTestViewModel'
import { ObjectCreatePanelTestEventEmits, ObjectCreatePanelTestPropOptions } from './interface'

export default defineView({
  name: 'ObjectCreatePanelTest',
  props: ObjectCreatePanelTestPropOptions,
  emits: ObjectCreatePanelTestEventEmits,
  viewModel: ObjectCreatePanelTestViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KFolderSelectTestPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KFolderSelectTestPropType = ViewPropsTypeExtract<typeof KFolderSelectTestPropOptions>

/** 事件 */
export const KFolderSelectTestEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KFolderSelectTestEmitsType = ViewEmitsTypeExtract<typeof KFolderSelectTestEventEmits>

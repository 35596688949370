import { connect, withInstall } from '@kmsoft/upf-core'
import KHyperLinkView from './KHyperLink.vue'
import KHyperLinkViewModel from './KHyperLinkViewModel'

const KHyperLink = connect(KHyperLinkView, KHyperLinkViewModel)

export default withInstall(KHyperLink)
export { KHyperLink, KHyperLinkView, KHyperLinkViewModel }
export * from './KHyperLinkRender'
export * from './KDataGridHyperLinkColumn'

// 模板生成文件
// export * from './{{folderName}}'

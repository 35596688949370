import { AgentStaticObject } from '../../AgentStaticObject'
import { AgentInstanceObject } from '../../AgentInstanceObject'

/**表示 XML 文档中的单个节点。*/
export abstract class XmlNode extends AgentInstanceObject {
  protected static type: string = 'System.Xml.XmlDocument,System.Xml.ReaderWriter'

  /**获取包含此节点及其所有子节点的标记。*/
  public async OuterXml(): Promise<string> {
    return this.property('OuterXml', AgentStaticObject.None)
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KRequireTreeView from './KRequireTree.vue'
import KRequireTreeViewModel from './KRequireTreeViewModel'

const KRequireTree = connect(KRequireTreeView, KRequireTreeViewModel)

export default withInstall(KRequireTree)
export { KRequireTree, KRequireTreeView, KRequireTreeViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, SimpleViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import {
  KAutoCompleteSelectorEmitsType,
  KAutoCompleteSelectorLoadDataFnParam,
  KAutoCompleteSelectorOption,
  KAutoCompleteSelectorOptionGroup,
  KAutoCompleteSelectorPropType,
  KSelectPagination
} from './interface'
import { computed, ref, watch } from 'vue'
import _, { isArray } from 'lodash'

/** KAutoCompleteSelector */
export class KAutoCompleteSelectorViewModel extends BaseViewModel<KAutoCompleteSelectorEmitsType, KAutoCompleteSelectorPropType> {
  /** 下拉框引用 */
  refSelect = ref<null | Record<string, any>>(null)
  /** 下拉框父元素 */
  refSelectBox = ref<null | Element>(null)
  /** 候选项集合 */
  localOptions = ref<Array<KAutoCompleteSelectorOption>>([])
  /** 是否可编辑 */
  disabled = ref(true)
  /** 下拉框模式 combobox:单选, multiple:多选  */
  mode = ref('combobox')
  /** 是否展开下拉菜单 */
  isShowOption = ref(false)
  /** 本地选中项 */
  localValue = ref<undefined | string | Array<string>>(undefined)
  /** 页索引 */
  pageIndex = ref(1)
  /** 总页数 */
  pageNumber = ref(2)
  /** 单页数据量 */
  pageSize = ref(10)
  /** 本地分页 */
  localPagination = ref<false | KSelectPagination>(false)
  /** 原始候选项，用于非检索状态时恢复原本的候选 */
  originalOptions = ref<Array<KAutoCompleteSelectorOption>>([])
  /** 搜索值 */
  searchValue = ref('')
  /** 选中的项  */
  selectOption = ref<null | KAutoCompleteSelectorOption | Array<KAutoCompleteSelectorOption>>(null)
  /** 最多显示的tag数 */
  maxTagCount = ref(1)
  /**是否只读 */
  readonly = ref(false)
  /** 是否允许清除 */
  allowClear = computed(() => {
    return this.props.isAllowClear && !!this.localValue.value
  })

  constructor(options: ViewModelOptions<KAutoCompleteSelectorPropType>) {
    super(options)
    this.localPagination.value = _.cloneDeep(options.props.pagination)
    watch(
      () => options.props.disabled,
      newVal => {
        this.disabled.value = newVal
      },
      {
        immediate: true
      }
    )
    watch(
      () => options.props.readonly,
      newVal => {
        this.readonly.value = newVal
      },
      {
        immediate: true
      }
    )

    watch(
      () => options.props.isMultipleSelection,
      newVal => {
        this.mode.value = newVal ? 'multiple' : 'combobox'
      },
      {
        immediate: true
      }
    )

    watch(
      () => options.props.value,
      newVal => {
        if (!newVal || newVal.length == 0) {
          this.localValue.value = options.props.isMultipleSelection ? [] : undefined
          return
        }
        this.localValue.value = newVal
        setTimeout(() => {
          if (options.props.isMultipleSelection && isArray(newVal)) {
            this.selectOption.value = this.localOptions.value.filter(o => (newVal as Array<string>).includes(o.value))
          } else {
            this.selectOption.value = this.localOptions.value.find(o => o.value == (newVal as string)) || null
          }
        }, 50)
      },
      {
        deep: true,
        immediate: true
      }
    )
  }

  viewDidMount() {
    this.loadDataFirstTime()
  }

  /**刷新编辑框渲染数据 */
  refresh() {
    this.loadDataFirstTime()
  }
  /**
   * 获取焦点
   */
  focus() {
    this.refSelect.value?.focus()
  }
  /**
   * 设置选中项
   */
  setValue(selectOption: KAutoCompleteSelectorOption | Array<KAutoCompleteSelectorOption>) {
    if (!selectOption || selectOption.length == 0) {
      this.localValue.value = this.props.isMultipleSelection ? [] : undefined
      this.selectOption.value = selectOption
      return
    }
    if (this.isObject(selectOption)) {
      this.localOptions.value = [selectOption as KAutoCompleteSelectorOption]
      this.localValue.value = (selectOption as KAutoCompleteSelectorOption).value
      this.selectOption.value = selectOption
      return
    }
    if (Array.isArray(selectOption)) {
      this.localOptions.value = selectOption
      this.localValue.value = selectOption.map(option => option.value)
      this.selectOption.value = selectOption
      return
    }
  }
  /**
   * 设置下拉框的options
   * @param options 下拉框的options
   */
  setOptions(options: Array<any>) {
    // 清空当前选择项
    this.setValue([])

    // 设置options
    this.localOptions.value = _.cloneDeep(options)
    this.originalOptions.value = _.cloneDeep(options)
  }

  getValue() {
    return this.selectOption.value
  }

  /**
   * 是否为对象
   */
  isObject(option: any) {
    return Object.prototype.toString.call(option) == '[object Object]'
  }

  /**
   * 初次查询
   */
  loadDataFirstTime() {
    const qry = { searchValue: '' } as KAutoCompleteSelectorLoadDataFnParam

    if (this.isObject(this.localPagination.value)) {
      ;(this.localPagination.value as KSelectPagination).pageIndex = 1
      qry.page = this.localPagination.value as KSelectPagination
    }
    this.props.loadData!(qry)
      .then(res => {
        /** 若开启了分页，记录总页数，方便后续下拉分页查询 */
        if (this.isObject(this.localPagination.value)) {
          this.pageNumber.value = Math.ceil(res.total / (this.localPagination.value as KSelectPagination).pageSize)
        }

        this.localOptions.value = _.cloneDeep(res.options)
        this.originalOptions.value = _.cloneDeep(res.options)
      })
      .finally(() => {
        /** 动态计算多选时可显示的tag数 */
        const singleTagWidth = this.props.maxTagTextLength * 14 + 40
        const count = Math.floor((this.refSelectBox.value?.clientWidth! - 40) / singleTagWidth)
        this.maxTagCount.value = count > 0 ? count : 2
      })
  }

  /**
   * 获取有效的选中项 (去除value中的 "-suggest")
   */
  getValidSelectOptions(
    selectOption: KAutoCompleteSelectorOption | Array<KAutoCompleteSelectorOption>
  ): KAutoCompleteSelectorOption | Array<KAutoCompleteSelectorOption> | null {
    if (this.isObject(selectOption)) {
      const tempOption = _.cloneDeep(selectOption) as KAutoCompleteSelectorOption
      tempOption.value = tempOption.value.replace('-suggest', '')
      return tempOption
    }

    if (Array.isArray(selectOption)) {
      const tempOptions = _.cloneDeep(selectOption) as Array<KAutoCompleteSelectorOption>
      return tempOptions.map(option => ({ value: option.value.replace('-suggest', ''), label: option.label }))
    }
    return null
  }

  /**
   * 重置候选项
   */
  resetOptions = () => {
    this.localOptions.value = _.cloneDeep(this.originalOptions.value)
  }

  /**
   * 滚动条拖动到底部时二次查询
   */
  loadDataByScroll = () => {
    const qry = { searchValue: '' } as KAutoCompleteSelectorLoadDataFnParam
    /** 若开启了分页，则将分页组合成查询条件 */
    if (this.isObject(this.localPagination.value)) {
      qry.page = this.localPagination.value as KSelectPagination
    }
    this.props.loadData!(qry).then(res => {
      /** 未开启建议，直接新增 */
      const tempOptions = this.localOptions.value as Array<KAutoCompleteSelectorOption>
      ;(this.localOptions.value as Array<KAutoCompleteSelectorOption>) = _.sortBy(
        _.unionBy(tempOptions.concat(res.options), 'value'),
        ['value']
      )

      this.originalOptions.value = _.cloneDeep(this.localOptions.value)
    })
  }
  /**
   *获取显示值
   * */
  displayValue() {
    if (this.selectOption.value) {
      if (this.props.isMultipleSelection) return this.selectOption.value.map((x: { label: any }) => x.label).join(',')
      else {
        return (this.selectOption.value as KAutoCompleteSelectorOption).label
      }
    }
  }
  /**
   * 值是否修改
   */
  isModified(): boolean {
    const newValue = this.selectOption.value
    const originalValue = this.originalOptions.value
    return newValue !== originalValue
  }
}

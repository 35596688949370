import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

//#region 类型定义
/** 创建选项条目 */
export type ObjectCreateOptionItem = {
  /** 标签 */
  label: string
  /** 值 */
  value: string
  /** 是否禁用 */
  disabled?: boolean
}

/** 选项值 */
export type OptionValue = EnumSaveOption | string

/** 创建选项 */
export enum EnumSaveOption {
  /** 保留属性 */
  SAVE_PROPERTY = 'SAVE_PROPERTY',
  /** 打开对象 */
  OPEN_OBJ = 'OPEN_OBJ',
  /** 加入工作流 */
  ADD_WORKFLOW = 'ADD_WORKFLOW',
  /** 打开流程对象 */
  OPEN_FLOW_OBJ = 'OPEN_FLOW_OBJ',
  /** 复制关联关系 */
  COPY_RELATIONS = 'COPY_RELATIONS',
  /** 复制描述关系 */
  COPY_DESCRIBE = 'COPY_DESCRIBE'
}

/** 默认选项 */
export const DEFAULT_OPTIONS = [
  {
    label: '保留属性',
    value: EnumSaveOption.SAVE_PROPERTY
  },
  {
    label: '打开对象',
    value: EnumSaveOption.OPEN_OBJ
  },
  {
    label: '加入工作流',
    value: EnumSaveOption.ADD_WORKFLOW
  },
  {
    label: '打开工作流',
    value: EnumSaveOption.OPEN_FLOW_OBJ
  },
  {
    label: '复制关联关系',
    value: EnumSaveOption.COPY_RELATIONS
  },
  {
    label: '复制普通描述对象及描述关系',
    value: EnumSaveOption.COPY_DESCRIBE
  }
]

//#endregion

/** 参数 **/
export const KObjectCreateOptionPropOptions = {
  ...BaseViewPropOptions,
  /**
   * 对象类 Code
   * @description 如果不传入对象类Code 则会显示对象类选择
   */
  modelCode: VuePropTypes.string()
    .setRequired()
    .def(),
  /**
   * 主对象id
   * @description 多版本对象需要传入
   */
  masterId: VuePropTypes.string().def(),
  /**
   * 迭代id
   * @description 多版本对象需要传入
   */
  iteratorId: VuePropTypes.string().def(),
  /**
   * 对象id
   * @description 在元数据基础上
   */
  objId: VuePropTypes.string().def(),
  /** 默认显示的创建选项 */
  showOptions: VuePropTypes.array<OptionValue>().def([EnumSaveOption.SAVE_PROPERTY, EnumSaveOption.OPEN_OBJ]),
  /** 创建选项 */
  options: VuePropTypes.array<ObjectCreateOptionItem>().def([]),
  /** 勾选值 */
  value: VuePropTypes.createType<Record<string, boolean>>().def({})
}

/** 参数类型 **/
export type KObjectCreateOptionPropType = ViewPropsTypeExtract<typeof KObjectCreateOptionPropOptions>

/** 事件 */
export const KObjectCreateOptionEventEmits = {
  ...BaseViewEventEmits,
  /** 更新值 v-model */
  'update:value': (value: Record<string, boolean>) => true
}

/** 事件类型 **/
export type KObjectCreateOptionEmitsType = ViewEmitsTypeExtract<typeof KObjectCreateOptionEventEmits>

import { AgentStaticObject } from '../../AgentStaticObject'
import { AgentInstanceObject } from '../../AgentInstanceObject'

import { FileAttributes } from './FileAttributes'

/**提供文件和目录枚举选项。*/
export class EnumerationOptions extends AgentInstanceObject {
  protected static type: string = 'System.IO.EnumerationOptions,System.Runtime'

  /**创建 {@link EnumerationOptions} 的新实例。
   * @returns 一个 {@link EnumerationOptions} 对象。*/
  public static async create(): Promise<EnumerationOptions> {
    return this.createInstanceAndWrap()
  }

  /**获取默认枚举选项。*/
  public static async Default(): Promise<EnumerationOptions> {
    return this.getOrAddToMap('db633340-a241-4740-8665-726b078b895d', () => EnumerationOptions.create())
  }

  /**获取或设置要跳过的属性。 默认值为 {@link FileAttributes.Hidden} | {@link FileAttributes.System}。*/
  public async AttributesToSkip(value: FileAttributes = AgentStaticObject.None): Promise<FileAttributes> {
    return this.property('AttributesToSkip', value)
  }
}

import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { KBaseEditorEventEmits, KBaseEditorPropOptions } from '../../base'
import { KRuleDefinerEventEmits, KRuleDefinerPropOptions } from '../../../rule-definer'
import lodash from 'lodash'

/** 参数 **/
export const KRuleEditorPropOptions = {
  ...KBaseEditorPropOptions,
  ...lodash.omit(KRuleDefinerPropOptions, 'condition'),
  /** 是否显示为文本 */
  showText: VuePropTypes.bool().def()
}

/** 参数类型 **/
export type KRuleEditorPropType = ViewPropsTypeExtract<typeof KRuleEditorPropOptions>

/** 事件 */
export const KRuleEditorEventEmits = {
  ...KBaseEditorEventEmits,
  ...lodash.omit(KRuleDefinerEventEmits, 'update:condition')
}

/** 事件类型 **/
export type KRuleEditorEmitsType = ViewEmitsTypeExtract<typeof KRuleEditorEventEmits>

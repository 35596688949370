import { connect, withInstall } from '@kmsoft/upf-core'
import KFolderSelectorFormView from './KFolderSelectorForm.vue'
import KFolderSelectorFormViewModel from './KFolderSelectorFormViewModel'

const KFolderSelectorForm = connect(KFolderSelectorFormView, KFolderSelectorFormViewModel)

export default withInstall(KFolderSelectorForm)
export { KFolderSelectorForm, KFolderSelectorFormView, KFolderSelectorFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    name: "vmRefPropertyForm",
    model: _ctx.$vm.formState,
    "label-col": { span: 6 },
    "wrapper-col": { span: 16 }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, { label: "选择显示列" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_k_input, {
              value: _ctx.$vm.formState!.refObjNames,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formState!.refObjNames) = $event)),
              disabled: true,
              readonly: _ctx.readonly
            }, null, 8, ["value", "readonly"]),
            _withDirectives(_createVNode(_component_k_button, {
              onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.toSelectRefObj())
            }, {
              default: _withCtx(() => [
                _createTextVNode(" ... ")
              ]),
              _: 1
            }, 512), [
              [_vShow, !_ctx.readonly]
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
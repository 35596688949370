import { connect, withInstall } from '@kmsoft/upf-core'
import KWorkspaceListView from './KWorkspaceList.vue'
import KWorkspaceListViewModel from './KWorkspaceListViewModel'

const KWorkspaceList = connect(KWorkspaceListView, KWorkspaceListViewModel)

export default withInstall(KWorkspaceList)
export { KWorkspaceList, KWorkspaceListView, KWorkspaceListViewModel }

// 模板生成文件
// export * from './{{folderName}}'

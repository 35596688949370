import { connect, withInstall } from '@kmsoft/upf-core'
import KRequirementChangeCreateFormView from './KRequirementChangeCreateForm.vue'
import KRequirementChangeCreateFormViewModel from './KRequirementChangeCreateFormViewModel'

const KRequirementChangeCreateForm = connect(KRequirementChangeCreateFormView, KRequirementChangeCreateFormViewModel)

export default withInstall(KRequirementChangeCreateForm)
export { KRequirementChangeCreateForm, KRequirementChangeCreateFormView, KRequirementChangeCreateFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

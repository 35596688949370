
import { defineView } from '@kmsoft/upf-core'
import { KObjectDisplayPropOptions, KObjectDisplayEventEmits } from './interface'
import KObjectDisplayViewModel from './KObjectDisplayViewModel'
import './style/index.less'

export default defineView({
  name: 'KObjectDisplay',
  props: KObjectDisplayPropOptions,
  emits: KObjectDisplayEventEmits,
  viewModel: KObjectDisplayViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      if (vm.stateReadonly.value) {
        return (
          <div class="k-render-input-text" title={vm.stateValue.value}>
            {vm.displayTest()}
          </div>
        )
      } else {
        return <k-input {...props} value={vm.inputValue.value} />
      }
    }
  }
})

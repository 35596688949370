import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartAssociationFormPropOptions = {
  ...BaseViewPropOptions,
  partAssociation: VuePropTypes.createType<Array<any>>().def()
}

/** 参数类型 **/
export type KPartAssociationFormPropType = ViewPropsTypeExtract<typeof KPartAssociationFormPropOptions>

/** 事件 */
export const KPartAssociationFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartAssociationFormEmitsType = ViewEmitsTypeExtract<typeof KPartAssociationFormEventEmits>

/**
 * 工作状态枚举
 */
export const WorkingStateEnum = [
  {
    label: '工作中',
    value: 'INWORK'
  },
  {
    label: '已检入',
    value: 'CHECKED_IN'
  },
  {
    label: '已检出',
    value: 'CHECKED_OUT'
  }
]

import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectClassTreeSelectorView from './KObjectClassTreeSelector.vue'
import KObjectClassTreeSelectorViewModel from './KObjectClassTreeSelectorViewModel'

const KObjectClassTreeSelector = connect(KObjectClassTreeSelectorView, KObjectClassTreeSelectorViewModel)

export default withInstall(KObjectClassTreeSelector)
export { KObjectClassTreeSelector, KObjectClassTreeSelectorView, KObjectClassTreeSelectorViewModel }

export * from './interface'

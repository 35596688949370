import { EnumDataType, EnumPropertyEditorType, MetaProperty } from '.'

/** 对象搜索客户端服务 */
export class MetaPropertyClientSrv {
  /** 不支持搜索的列 */
  static readonly NOT_SUPPORT_SEARCH_PROPERTIES: Array<EnumDataType> = [EnumDataType.IMAGE, EnumDataType.OBJECT]
  /** 不支持搜索的列 */
  static readonly NOT_SUPPORT_SIMPLE_SEARCH_PROPERTIES_EDITOR: Array<EnumPropertyEditorType> = [
    EnumPropertyEditorType.OBJECT_DROPDOWN,
    EnumPropertyEditorType.OBJECT_MODAL
  ]

  /**
   * 判断当前数据类型是否支持操作（含普通查询和复杂查询）
   * @param property 属性
   * @returns 是否支持搜索
   */
  public static isSupportSearch(property: MetaProperty): boolean {
    return !this.NOT_SUPPORT_SEARCH_PROPERTIES.includes(property.type)
  }

  /**
   * 是否支持简单查询
   * @param property 属性
   * @returns
   */
  public static isSupportSimpleSearch(property: MetaProperty): boolean {
    /** 是否支持搜索 */
    const isSupportSearch = this.isSupportSearch(property)

    if (!isSupportSearch) {
      return false
    }

    return true
  }
}

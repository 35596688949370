import { connect, withInstall } from '@kmsoft/upf-core'
import KAppendChangeActivityView from './KAppendChangeActivity.vue'
import KAppendChangeActivityViewModel from './KAppendChangeActivityViewModel'

const KAppendChangeActivity = connect(KAppendChangeActivityView, KAppendChangeActivityViewModel)

export default withInstall(KAppendChangeActivity)
export { KAppendChangeActivity, KAppendChangeActivityView, KAppendChangeActivityViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { KUserSelector } from '.'
import { GridCellEditorParams, GridCellRendererParams, KObjectClassGridBaseColumn } from '../../base'
// import { GridCellEditorParams, GridCellRendererParams, KObjectClassGridBaseColumn } from '../..'

/** 人员选择器 */
export class KDataGridUserSelectorColumn extends KObjectClassGridBaseColumn {
  name = 'KDataGridUserSelectorColumn'
  getCellRenderer(params: GridCellRendererParams) {
    const col = (params.cellParams as any).col
    return <KUserSelector {...params} />
  }

  getCellEditor(params: GridCellEditorParams) {
    const col = (params.cellParams as any).col
    return <KUserSelector {...params} />
  }
}

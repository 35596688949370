import { BaseViewModel, IQuery, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KTreeGridDemoEmitsType, KTreeGridDemoPropType } from './interface'
import { ref } from 'vue'

/** KTreeGridDemo */
export default class KTreeGridDemoViewModel extends BaseViewModel<KTreeGridDemoEmitsType, KTreeGridDemoPropType> {
  refDataGridView = ref<KDataGridViewModel>()
  constructor(options: ViewModelOptions<KTreeGridDemoPropType>) {
    super(options)
  }

  viewDidMount() {}

  buildData(total: number) {
    const data = [] as any
    const json = JSON.stringify([
      {
        id: '0001',
        code: 'WO-MMO-001-00001',
        name: 'WO-MMO-001-00001',
        material: '行星组件部装',
        type: 'order',
        hasChildren: true
      },
      {
        id: Date.now().toString() + '10',
        code: 'WO-MMO-001-00001:10',
        name: '磨',
        material: '行星组件部装',
        type: 'workTask',
        hasChildren: false,
        parentId: '0001'
      },
      {
        id: Date.now().toString() + '20',
        code: 'WO-MMO-001-00001:20',
        name: '铣',
        material: '行星组件部装',
        type: 'workTask',
        hasChildren: false,
        parentId: '0001'
      }
    ])
    data.push(...JSON.parse(json))

    return data
  }
  loadData(query: IQuery): Promise<any> {
    return new Promise((resolve, reject) => {
      const data = this.buildData(10)
      setTimeout(() => {
        resolve({
          total: data.length,
          rows: data
        })
      }, 20)
    })
  }
  getHierarchyPath(row: Record<string, any>) {
    const hierarchy = [] as Array<string>
    this.getHierarchy(row, hierarchy)
    console.log(`开始获取${row.name}的结构`, JSON.stringify(hierarchy))
    return hierarchy
  }
  getHierarchy(row: Record<string, any>, hierarchy: Array<string>) {
    if (row.parentId == 'null' || row.parentId == null) {
      hierarchy.unshift(row.code)
    } else {
      const parentRow = this.refDataGridView.value?.getRow(row.parentId)
      if (parentRow) {
        hierarchy.unshift(row.code)
        this.getHierarchy(parentRow, hierarchy)
      }
    }
  }
}

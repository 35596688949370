
import { defineView } from '@kmsoft/upf-core'
import KFolderManage from '../../folder-manage'
import KPublicWorkspaceViewModel from './KPublicWorkspaceViewModel'
import { KPublicWorkspaceEventEmits, KPublicWorkspacePropOptions } from './interface'

export default defineView({
  name: 'KPublicWorkspace',
  props: KPublicWorkspacePropOptions,
  emits: KPublicWorkspaceEventEmits,
  components: { KFolderManage }, // 这里定义局部组件
  viewModel: KPublicWorkspaceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

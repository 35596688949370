import { BaseViewModel, KDialog, KFormViewModel, KNotification, KToolStripViewModel } from '@kmsoft/upf-core'
import {
  IConfigGridComboboxTileDefinition,
  KConfigGridComboboxTileEventEmitsType,
  KConfigGridComboboxTileViewModelPropType
} from './interface'
import { EnumEditType, EnumOper, EnumTabType, IValueDesc } from '../../../interface'
import { isReqSuccess, showError } from '../../../common/common'
import { IConfigValidDefinition, UpdateConfigValidStatusEventArgs } from '../../sys-config-value/interface'
import { ref } from 'vue'
import { Api } from '@kmsoft/ebf-common'

export default class ConfigGridComboboxTileViewModel extends BaseViewModel<
  KConfigGridComboboxTileEventEmitsType,
  KConfigGridComboboxTileViewModelPropType
> {
  /** 下拉菜单可选项 */
  selectEditTypeOptions = ref<Array<IValueDesc>>()
  /** 可编辑 */
  editable = ref<boolean>(false)
  /** 是否加载完成 */
  dataLoaded = ref<boolean>(false)
  /** 页面表单状态 */
  formState = ref<IConfigGridComboboxTileDefinition>({
    code: '',
    name: '',
    values: [] as Array<string>,
    dataType: '',
    editType: '',
    editParam: '',
    remark: ''
  } as IConfigGridComboboxTileDefinition)

  refConfigGridComboboxTileFrom = ref<KFormViewModel<IConfigGridComboboxTileDefinition>>()
  refObjClsToolStrip = ref<KToolStripViewModel>()

  private defId: string
  private treeId: string
  /** 初始值, 用于对比是否发生变更 */
  private sourceValue: Array<string>
  /** 初始对象 */
  private row: IConfigGridComboboxTileDefinition
  private oper: string
  private mode: string

  /**
   * 加载完成函数
   */
  viewDidMount() {
    this.mode = EnumTabType.SYS
  }

  private isEditable(): boolean {
    return this.oper == EnumOper.EDIT
  }

  /**
   * 初始化表单内容
   * @param defId 配置项编码
   * @param treeId 树节点ID
   * @param oper 操作类型
   */
  public init(defId: string, treeId: string, oper: string) {
    this.defId = defId
    this.treeId = treeId
    this.oper = oper
    this.editable.value = this.isEditable()
    this.loadData()
  }

  /**
   * 加载数据
   */
  private loadData(): void {
    const param = {
      data: [
        {
          defId: this.defId as unknown,
          treeId: this.treeId as unknown
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'loadConfigSysValue', param).then(response => {
      this.loadDataInner(response)
    })
  }

  private loadDataInner(response: any) {
    if (!isReqSuccess(response)) {
      showError(response)
      return
    }
    const value = response.data ?? {}
    const formState = {
      defId: value?.defId as unknown,
      id: value?.id as unknown,
      code: value?.code,
      name: value?.name,
      values: [],
      remark: value?.remark,
      dataType: value?.dataType,
      editType: value?.editType,
      editParam: value?.editParam
    } as IConfigGridComboboxTileDefinition
    this.row = formState
    this.initSelect()

    this.loadGridData()
  }

  /**
   * 加载值列表
   */
  private loadGridData(): void {
    const param = {
      data: [
        {
          defId: this.defId as unknown,
          treeId: this.treeId as unknown
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'loadConfigSysGrid', param).then(response => {
      if (!isReqSuccess(response)) {
        showError(response)
        return
      }
      const values = response.data
      const dList = [] as Array<string>
      if (values != null && values.length > 0) {
        for (const value of values) {
          dList.push(value.value1 as string)
        }
      }

      this.row.values = dList
      this.sourceValue = this.row.values
      this.initSelect()
      this.dataLoaded.value = true
      this.formState.value = { ...this.row }
    })
  }

  /**
   * 校验
   */
  public check(): void {
    const param = {
      data: [
        {
          treeId: this.treeId as unknown,
          defId: this.defId as unknown,
          code: this.row.code
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'checkConfigSysValid', param).then(response => {
      if (!isReqSuccess(response)) {
        showError(response)
        return
      }
      if (response.data?.success) {
        KNotification.success({ title: '提示', content: '校验成功' })
      } else {
        KNotification.error({
          title: '提示',
          content: response.data?.message || ''
        })
      }
    })
  }

  /**
   * 保存
   */
  public save(): void {
    const formState = this.formState.value
    const values = formState?.values
    const saveList = [] as Array<Record<string, any>>
    if (values != null && values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        const data = {} as any
        ;(data.code = formState?.code), (data.value1 = values[i])
        data.orderId = i + 1
        data.id = null
        saveList.push(data)
      }
    }
    const param = {
      data: [
        {
          treeId: this.treeId as unknown,
          defId: this.defId as unknown,
          code: formState?.code,
          valueList: saveList
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'updateConfigSysGrid', param).then(response => {
      this.saveInner(response, formState)
    })
  }

  private saveInner(response: any, formState: IConfigGridComboboxTileDefinition | undefined): void {
    if (!isReqSuccess(response)) {
      showError(response)
      return
    }
    // 保存成功后, 刷新初始值
    this.sourceValue = formState?.values ?? []
    KNotification.success({ title: '提示', content: '保存成功' })

    // 发布事件通知
    const validStatus = response.data as IConfigValidDefinition
    validStatus.defId = this.defId
    this.emit('updateConfigValidStatus', new UpdateConfigValidStatusEventArgs(validStatus))
  }

  private valueChanged(): boolean {
    const formState = this.formState.value ?? ({} as any)

    let changed = false
    if (formState.values?.length !== this.sourceValue.length) {
      changed = true
    }
    if (!changed) {
      for (let i = 0; i < formState.values.length; i++) {
        if (formState.values[i] !== this.sourceValue[i]) {
          changed = true
          break
        }
      }
    }
    return changed
  }

  /**
   * 刷新
   */
  public refresh(): void {
    if (this.valueChanged()) {
      KDialog.confirm({
        title: '存在数据修改，是继续保存，还是放弃修改？',
        okText: '继续保存',
        cancelText: '放弃修改',
        onCancel: async () => {
          this.loadGridData()
        },
        onOk: async () => {
          this.save()
        }
      })
    }
  }

  /**
   * 退出编辑
   */
  public editOut(): void {
    this.save()
    this.oper = EnumOper.VIEW
    this.editable.value = this.isEditable()
  }

  public editIn(): void {
    this.oper = EnumOper.EDIT
    this.editable.value = this.isEditable()
  }

  /**
   * 初始化[下拉菜单]选项
   */
  private initSelect(): void {
    if (this.row.editType === EnumEditType.COMBOBOX) {
      const json = JSON.parse(this.row.editParam)
      const values = json?.candidate ?? ([] as Array<string>)

      const options = [] as Array<IValueDesc>

      if (values != null && values.length > 0) {
        for (const value of values) {
          options.push({
            label: value,
            value: value
          })
        }
      }
      this.selectEditTypeOptions.value = options
    }
  }
}

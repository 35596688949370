import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin":"5px 0","display":"flex","justify-content":"space-between"} }
const _hoisted_2 = { class: "grid-operation" }
const _hoisted_3 = { style: {"height":"250px"} }
const _hoisted_4 = { style: {"height":"100%"} }
const _hoisted_5 = { style: {"display":"flex"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_radio_button = _resolveComponent("k-radio-button")!
  const _component_k_radio_group = _resolveComponent("k-radio-group")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    "label-col": { span: 6 },
    "wrapper-col": { span: 16 }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_radio_group, {
          value: _ctx.$vm.formState!.optionType,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formState!.optionType) = $event)),
          "button-style": _ctx.EnumButtonStyle.SOLID,
          disabled: _ctx.readonly,
          onChange: _cache[1] || (_cache[1] = () => _ctx.$vm.toggleShowDisplayColumn())
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_radio_button, { value: "options" }, {
              default: _withCtx(() => [
                _createTextVNode("自定义选项")
              ]),
              _: 1
            }),
            _createVNode(_component_k_radio_button, { value: "enums" }, {
              default: _withCtx(() => [
                _createTextVNode("引用枚举")
              ]),
              _: 1
            }),
            _createVNode(_component_k_radio_button, { value: "dataSourceLink" }, {
              default: _withCtx(() => [
                _createTextVNode("引入数据源")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "button-style", "disabled"]),
        _withDirectives(_createElementVNode("span", _hoisted_2, [
          _withDirectives(_createVNode(_component_k_button, {
            type: "link",
            onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.addOption())
          }, {
            default: _withCtx(() => [
              _createTextVNode("新增")
            ]),
            _: 1
          }, 512), [
            [_vShow, !_ctx.readonly]
          ]),
          _withDirectives(_createVNode(_component_k_button, {
            type: "link",
            onClick: _cache[3] || (_cache[3] = () => _ctx.$vm.removeOption())
          }, {
            default: _withCtx(() => [
              _createTextVNode("删除")
            ]),
            _: 1
          }, 512), [
            [_vShow, !_ctx.readonly]
          ])
        ], 512), [
          [_vShow, _ctx.$vm.formState!.optionType === 'options']
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createVNode(_component_k_data_grid, {
            ref: _ctx.$vm.refCustomOptionGird,
            rowDrag: "",
            dataSource: _ctx.$vm.formState!.options,
            isMultipleSelection: false,
            rowKey: "id",
            disabled: _ctx.readonly,
            onReady: _cache[4] || (_cache[4] = () => _ctx.$vm.toggleShowDisplayColumn())
          }, {
            columns: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$vm.columns, (column) => {
                return (_openBlock(), _createBlock(_component_k_data_grid_text_box_column, {
                  key: column.name,
                  id: column.name,
                  name: `vm-${column.name}`,
                  dataPropertyName: column.name,
                  headerText: column.title,
                  width: "150",
                  align: "center"
                }, null, 8, ["id", "name", "dataPropertyName", "headerText"]))
              }), 128)),
              _createVNode(_component_k_data_grid_template_column, {
                id: "__drag",
                rowDrag: true,
                headerText: "排序",
                width: "70",
                align: "left"
              }, {
                default: _withCtx(() => []),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["dataSource", "disabled"])
        ], 512), [
          [_vShow, _ctx.$vm.formState!.optionType === 'options']
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_k_form_item, { label: "枚举类型：" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_k_input, {
                  value: _ctx.$vm.formState.refEnumName,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$vm.formState.refEnumName) = $event)),
                  disabled: true,
                  readonly: _ctx.readonly
                }, null, 8, ["value", "readonly"]),
                _withDirectives(_createVNode(_component_k_button, {
                  style: {"width":"40px","margin-left":"8px"},
                  onClick: _cache[6] || (_cache[6] = () => _ctx.$vm.toSelectRefEnum())
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" ... ")
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, !_ctx.readonly]
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid, {
            ref: _ctx.$vm.refCustomEnumGird,
            isMultipleSelection: false,
            rowKey: "id",
            style: {"height":"250px"},
            disabled: _ctx.readonly
          }, {
            columns: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$vm.columns, (column) => {
                return (_openBlock(), _createBlock(_component_k_data_grid_text_box_column, {
                  key: column.name,
                  id: column.name,
                  name: `vm-${column.name}`,
                  dataPropertyName: column.name,
                  headerText: column.title,
                  align: "center",
                  width: "150"
                }, null, 8, ["id", "name", "dataPropertyName", "headerText"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["disabled"])
        ], 512), [
          [_vShow, _ctx.$vm.formState.optionType === 'enums']
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_k_textarea, {
            value: _ctx.$vm.formState!.dataSourceLink,
            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.$vm.formState!.dataSourceLink) = $event)),
            rows: 5,
            placeholder: "请输入数据源链接",
            readonly: _ctx.readonly
          }, null, 8, ["value", "readonly"])
        ], 512), [
          [_vShow, _ctx.$vm.formState!.optionType === 'dataSourceLink']
        ])
      ])
    ]),
    _: 1
  }))
}
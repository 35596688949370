import { EnumQueryConditionOperator, QueryCondition } from '../../../../client-srv'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

//#region
export type QuickSearchForm = {
  colnameList: string[]
  operator: EnumQueryConditionOperator
  value: string
}
//#endregion

/** 参数 **/
export const KQuickSearcherPropOptions = {
  ...BaseViewPropOptions,
  /** 是否显示左侧标签 */
  isShowLabel: VuePropTypes.bool().def(true),
  /** 是否单行搜索样式，默认值：false */
  isSingleLine: VuePropTypes.bool().def(false),
  /** 是否显示搜索按钮 */
  isShowSearchButton: VuePropTypes.bool().def(false),
  /** 缓存条件 */
  condition: VuePropTypes.createType<QueryCondition>().def()
}

/** 参数类型 **/
export type KQuickSearcherPropType = ViewPropsTypeExtract<typeof KQuickSearcherPropOptions>

/** 事件 */
export const KQuickSearcherEventEmits = {
  ...BaseViewEventEmits,
  search: (value: QueryCondition) => true
}

/** 事件类型 **/
export type KQuickSearcherEmitsType = ViewEmitsTypeExtract<typeof KQuickSearcherEventEmits>

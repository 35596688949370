
import { defineView } from '@kmsoft/upf-core'
import { KWorkflowTemplatePropOptions, KWorkflowTemplateEventEmits } from './interface'
import KWorkflowTemplateViewModel from './KWorkflowTemplateViewModel'

export default defineView({
  name: 'KWorkflowTemplate',
  props: KWorkflowTemplatePropOptions,
  emits: KWorkflowTemplateEventEmits,
  viewModel: KWorkflowTemplateViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const host = window.location.host
    let parts = host.split('.')
    parts[0] = 'flow-admin'
    // 本地通过localhost访问，没有域名，则默认使用test.kmsaasdev.com
    if (parts.length <= 1) {
      parts[1] = 'dev.kmsaasdev.com'
    }
    let newHost = parts.join('.')
    const url = `${window.location.protocol}//${newHost}/`
    return { url }
  }
})

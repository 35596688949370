import { ObjectToolStripItem } from '@kmsoft/ebf-common'
import { BaseViewModel, EnumToolStripCompType, request, ToolStripItemClickedEventArgs, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KPartDemoEmitsType, KPartDemoPropType } from './interface'
import { Api } from '@kmsoft/ebf-common'

/** KPartDemo */
export default class KPartDemoViewModel extends BaseViewModel<KPartDemoEmitsType, KPartDemoPropType> {
  /** 网格工具栏 */
  gridToolStripItems = ref<Array<ObjectToolStripItem>>([])

  constructor(options: ViewModelOptions<KPartDemoPropType>) {
    super(options)

    //网格工具按钮
    this.gridToolStripItems.value = [
      {
        name: 'customNewObj',
        title: '自定义新建',
        icon: 'play-circle',
        visible: true,
        compType: EnumToolStripCompType.BUTTON
      }
    ]
  }

  viewDidMount() {
    console.log('hello')

    // Api.get()

    // fetch(`https://jsonplaceholder.typicode.com/users`).then(response => response.json())
    //     .then(json => console.log('测试:',json))
    // ;(request.get('https://jsonplaceholder.typicode.com/users', {}) as any).then((data: any) => {
    //   console.log('data:', data)
    // })
    // ;(request.get('/kmsaasApi/users', {}) as any).then((data: any) => {
    //   console.log('data1111>>', data)
    // })

    // (request.get('/aaa/users',{}) as any).then((data:any) =>{
    //   console.log( 'data1111111:', data );
    // })
  }
  async onLoadData() {
    const param = {
      data: [
        {
          characterSet: 'UTF8',
          conditions: [],
          filter: {
            conditionName: 'rdmExtensionType',
            operator: '=',
            conditionValues: ['ZmsClassification'],
            ignoreStr: false
          },
          decrypt: false,
          sorts: [],
          isNeedTotal: false,
          isPresentAll: true,
          publicData: 'INCLUDE_PUBLIC_DATA'
        },
        {
          maxPageSize: 1000,
          curPage: 1,
          pageSize: 20,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    const res = await request.post('/kmsaasApi/request/part/Part/list', param)
    if (res) {
      const data = JSON.parse(res.data)
      return Promise.resolve({
        rows: JSON.parse(res.data),
        total: data.length
      })
    }
  }

  /**
   * 按钮触发
   */
  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onToolStripItemClicked', event.name)
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KModuleSearchViewModelPropOptions } from './interface'
import KModuleSearchViewModel from './KModuleSearchViewModel'

export default defineView({
  name: 'ModuleSearch',
  props: KModuleSearchViewModelPropOptions,
  viewModel: KModuleSearchViewModel,
  setup() {
    return {
      patternOptions: [
        { value: '1', label: '包含' },
        { value: '2', label: '以开始' },
        { value: '3', label: '等于' }
      ],
      branchOptions: [
        { value: '1', label: '当前分支' },
        { value: '2', label: '全树' }
      ]
    }
  }
})

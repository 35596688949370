import { App, Plugin } from 'vue'

import KPartStructureManage from './part-structure-manage'
import KPartStructureTree from './part-structure-tree'

import KPartObjManage from './part-obj-manage'
import KCategoryRender from './category-render'
import KCategorySelector from './category-selector'
import KAttrsCategorySelector from './attrs-category-render'
import KPartUsingStatistics from './part-using-statistics'
import KPartSelector from './part-selector'

export * from './part-structure-manage'
export * from './part-structure-tree'

import KDocEditPanel from './part-edit-panel'

export { KPartStructureManage, KPartStructureTree, KPartObjManage }

const components: Record<string, Plugin> = {
  KPartStructureManage,
  KPartStructureTree,
  KDocEditPanel,
  KPartObjManage,
  KCategoryRender,
  KCategorySelector,
  KAttrsCategorySelector,
  KPartUsingStatistics,
  KPartSelector
}

const install = function(app: App) {
  Object.keys(components).forEach(key => {
    app.use(components[key])
  })
}

export default {
  install: install
}

import {
  BaseViewModel,
  DataGridLoadResult,
  KDataGridRowClickEvent,
  KDataGridViewModel,
  KNotification,
  ViewModelOptions,
  utils
} from '@kmsoft/upf-core'
import { KFlowDetailUpdateExecutorEmitsType, KFlowDetailUpdateExecutorPropType } from './interface'
import { ref, watch } from 'vue'
import lodash from 'lodash'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** KFlowDetailUpdateExecutor */
export default class KFlowDetailUpdateExecutorViewModel extends BaseViewModel<
  KFlowDetailUpdateExecutorEmitsType,
  KFlowDetailUpdateExecutorPropType
> {
  executorGridList = ref<any[]>()
  /** 执行人网格 */
  refExecutorDataGrid = ref<KDataGridViewModel>()
  /** 用户网格 */
  refUserDataGrid = ref<KDataGridViewModel>()

  userList = ref<any[]>([])

  constructor(options: ViewModelOptions<KFlowDetailUpdateExecutorPropType>) {
    super(options)
  }

  viewDidMount() {
    this.initExecutorGridData()
  }
  initExecutorGridData() {
    let executorGridList = [] as Array<Record<string, object>>
    if (this.props.executorGridData) {
      this.props.executorGridData.forEach((processNode: any) => {
        if (!processNode.multiInstance) {
          executorGridList.push({
            ...processNode,
            executorName: processNode.assignVariableValue,
            id: utils.uuid()
          })
        } else {
          processNode.assignVariableValue.forEach((assignVariableValueName: any) => {
            executorGridList.push({
              ...processNode,
              executorName: assignVariableValueName,
              id: utils.uuid()
            })
          })
        }
      })
      this.refExecutorDataGrid.value?.setDataSource(executorGridList)
    }
  }

  /** 校验 */
  async validate() {
    try {
      // TODO
      return true
      //const validResult = await this.refObjectCreatePanel.value?.validate()
      //return validResult
    } catch (error) {
      return false
    }
  }

  async save(): Promise<any> {
    const rows = this.refExecutorDataGrid.value?.getRows()!
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '流程活动不能为空'
      })
      return
    }

    let verifyFlag = true
    // 校验流程活动执行人是否为空
    rows.forEach(row => {
      if (!row.executorName || row.executorName == '') {
        verifyFlag = false
      }
    })
    if (!verifyFlag) {
      KNotification.warn({
        message: '系统提示',
        description: '请保证每个流程活动都有执行人'
      })
      return
    }
    const users = this.refUserDataGrid.value?.getRows()
    const variables = rows.reduce((groups, item) => {
      const assignVariable = item.assignVariable
      const multiInstance = item.multiInstance
      if (multiInstance) {
        if (!groups[assignVariable]) {
          groups[assignVariable] = []
        }
        const user = users?.filter(user => (user.name == item.executorName))!
        groups[assignVariable].push(user[0].id)

        return groups
      } else {
        if (!groups[assignVariable]) {
          groups[assignVariable] = ''
        }
        const user = users?.filter(user => (user.name == item.executorName))!
        groups[assignVariable] = user[0].id
        return groups
      }
    }, {})
    
    return Api.post('official', 'ProcessInstanceService', 'addOrUpdateProcessVariable', {
      data: [
        {
          processInstanceId: this.props.processInstanceId,
          variables: variables
        }
      ]
    })
  }
  /**
   * 刷新用户网格
   */
  refreshUserGrid(event: KDataGridRowClickEvent<any>) {
    this.refUserDataGrid.value?.clearSelectedRows()
    const row = this.refExecutorDataGrid.value!.getSelectedRows()[0]
    const userName = row.executorName
    if (userName) {
      this.refUserDataGrid.value?.setSelectedRow(userName, true)
    }
  }
  /**
   * 移除流程活动用户
   */
  delete(id: string) {
    const deleteRow = this.refExecutorDataGrid.value?.getRow(id)!
    if (deleteRow.due) {
      KNotification.warn({
        message: '系统提示',
        description: '选中的流程活动已执行或正在执行，无法删除执行人'
      })
      return
    }
    const rows = this.refExecutorDataGrid.value?.getRows()
    const commonRows = rows?.filter(row => row.name == deleteRow.name)
    if (commonRows?.length == 1) {
      KNotification.warn({
        message: '移除失败',
        description: '每个流程活动最少需要一个执行人'
      })
      return
    }
    this.refExecutorDataGrid.value?.removeRow(id)
    this.refUserDataGrid.value?.clearSelectedRows()
  }
  /**
   * 添加执行人
   */
  addExecutor() {
    const workflowActivity = this.refExecutorDataGrid.value?.getSelectedRows()
    if (!workflowActivity || workflowActivity.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个流程活动'
      })
      return
    }
    if (workflowActivity[0].due) {
      KNotification.warn({
        message: '系统提示',
        description: '选中的流程活动已执行或正在执行，无法修改执行人'
      })
      return
    }
    if (workflowActivity[0].multiInstance) {
      const users = this.refUserDataGrid.value?.getSelectedRows()
      if (!users || users.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个用户'
        })
        return
      }

      // 1、校验用户是否已经加入选中的流程活动,过滤出未加入的用户
      const executorIds = this.refExecutorDataGrid.value
        ?.getRows()
        .filter(row => row.name == workflowActivity[0].name)
        .map(row => row.executorId)
      const insertUser = users.filter(user => !executorIds?.includes(user.id))
      // 2、构建row
      let newRows = insertUser.map(user => {
        const row = {
          ...workflowActivity[0],
          executorId: user.id,
          executorName: user.name,
          id: utils.uuid()
        }
        return row
      })
      let removeFirstRowFlag = false
      if (!workflowActivity[0].executorId || workflowActivity[0].executorId == '') {
        const updateRow = {
          ...workflowActivity[0],
          executorId: newRows[0].executorId,
          executorName: newRows[0].executorName
        }
        this.refExecutorDataGrid.value?.refreshRow(updateRow)
        removeFirstRowFlag = true
      }
      const index = this.refExecutorDataGrid.value?.getRowIndex(workflowActivity[0].id)

      if (removeFirstRowFlag) {
        newRows = newRows.slice(0)
        newRows.shift()
      }
      this.refExecutorDataGrid.value?.insertRow(newRows, index!)
    } else {
      const users = this.refUserDataGrid.value?.getSelectedRows()
      if (!users || users.length != 1) {
        KNotification.warn({
          message: '系统提示',
          description: '当前流程活动不支持多人操作'
        })
        return
      }
      // 1、校验用户是否已经加入选中的流程活动,过滤出未加入的用户
      const updateRow = {
        ...workflowActivity[0],
        executorId: users[0].id,
        executorName: users[0].name
      }
      this.refExecutorDataGrid.value?.refreshRow(updateRow)
    }
  }

  /**
   * 加载用户网格
   */
  loadDataUser(): Promise<DataGridLoadResult> {
    return new Promise((resolve, reject) => {
      const param = {
        data: []
      }
      Api.post('common', 'UserManage', 'listUserByCurrentTenant', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          const result = res.data.map((data: any) => {
            return {
              id: data.id,
              name: data.name,
              email: data.email
            }
          })
          resolve(result)
        } else {
          resolve([])
        }
      })
    })
  }
}

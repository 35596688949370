import { Component, SlotsType } from 'vue'
import {
  VuePropTypes,
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  KSchema,
  KControl,
  KContainer,
  ComponentResolver,
  KGridColumnResolver
} from '@kmsoft/upf-core'
import { EnumRenderLayoutType } from '../render'

//#region 类型定义
/** 获取组件 Options 回调参数 */
export type DynamicViewGetControlOptionsParams = {
  /** 元数据 */
  meta: KContainer | KControl
}

/** 获取组件 Options 回调结果 */
export type DynamicViewControlOptions = {
  /** 组件 */
  component?: Component
  /** 组件参数 */
  props: Record<string, any>
  /** 插槽 */
  slots: Record<string, any>
}

/** 获取组件 Options 回调 */
export type DynamicViewGetControlOptionsCallback = (
  params: DynamicViewGetControlOptionsParams
) => DynamicViewControlOptions | undefined

/** 带层级的控件类型 */
export interface IControlWithPath {
  currentPath: string
  parentPath: string
  control: KControl | KContainer
}
//#endregion

//#region 组件定义
/** KDynamicView 事件 */
export const KDynamicViewEventEmits = {
  ...BaseViewEventEmits,
  beforeSetDataSource: () => true,
  afterSetDataSource: () => true
}

/** KDynamicView 事件类型 **/
export type KDynamicViewEventEmitsType = ViewEmitsTypeExtract<typeof KDynamicViewEventEmits>

/** KDynamicView 参数 **/
export const KDynamicViewPropOptions = {
  ...BaseViewPropOptions,
  /** 布局方案 */
  schema: VuePropTypes.createType<KSchema>().def(),
  /** 业务上下文 */
  bizCtx: VuePropTypes.createType<Record<string, any>>().def({}),
  /** 页面值 */
  pageValue: VuePropTypes.createType<Record<string, any>>().def({}),
  /**是否只读 */
  readonly: VuePropTypes.bool().def(false),
  /** 获取组件参数 */
  getControlOptions: VuePropTypes.func<DynamicViewGetControlOptionsCallback>().def(),
  /** 布局类型 */
  layoutType: VuePropTypes.createType<EnumRenderLayoutType>().def(EnumRenderLayoutType.GRID)
}

/** KDynamicView 参数类型 **/
export type KDynamicViewPropType = ViewPropsTypeExtract<typeof KDynamicViewPropOptions>
//#endregion

import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectCreateOptionView from './KObjectCreateOption'
import KObjectCreateOptionViewModel from './KObjectCreateOptionViewModel'

const KObjectCreateOption = connect(KObjectCreateOptionView, KObjectCreateOptionViewModel)

export default withInstall(KObjectCreateOption)
export { KObjectCreateOption, KObjectCreateOptionView, KObjectCreateOptionViewModel }
export * from './interface'

import { Api } from '../../client-srv'
import { KContainer, KNotification } from '@kmsoft/upf-core'
import { KObjectPropertyPanelPropType } from './interface'

/**
 * 获取扩展属性schema
 */
export const extendedAttributesSchema = async (id: string, props: KObjectPropertyPanelPropType): Promise<KContainer | null> => {
  const result = await Api.post('common', 'DataModelManagement', 'getAllAttribute', {
    data: [{ entityNameEn: id, holderType: 'MODEL' }]
  })
  // 接口调用失败清除分类属性
  if (!result?.success) {
    KNotification.error({
      title: '操作失败',
      content: result.message || '查询失败',
      details: result.detail
    })
    return Promise.resolve(null)
  }
  result.data = (result?.data || []).filter((arrFil: any) => ['EXTEND'].includes(arrFil?.category))
  if (result.data.length) {
    return getSchema(result, props)
  } else {
    return Promise.resolve(null)
  }
}

async function getSchema(result: any, props: KObjectPropertyPanelPropType): Promise<KContainer> {
  const attrs = result.data || []
  // 使用map创建一个包含Promise的数组
  const promises = (attrs || []).map((item: any) => optionsControl(item, props))

  // 等待所有的Promise解决
  return await Promise.all(promises).then((results: any) => {
    const controls = (results || []).filter((arrFil: any) => ['EXTEND'].includes(arrFil?.category))

    const schema = {
      name: 'extendedAttributes',
      control: 'KSegment',
      visible: true,
      title: '扩展属性',
      disabled: false,
      type: 'container',
      props: {
        labelPosition: 'left',
        labelWidth: 120,
        expand: true,
        minColWidth: 260,
        rowGap: 0,
        colGap: 10,
        colCount: 3
      },
      controls,
      layout: {
        // 4排的 rowSpan = 5， 1排有3个， 向上取整
        rowSpan: Math.ceil((Math.ceil(controls.length / 3) * 5) / 4),
        colSpan: 1,
        entireRow: true,
        fill: false
      },
      events: []
    } as KContainer

    return schema
  })
}

async function optionsControl(item: any, props: KObjectPropertyPanelPropType) {
  const commonArr = {
    visible: 1,
    dataSource: '',
    nullable: 1,
    readonly: props.readonly,
    disabled: props.disabled,
    type: 'control',
    events: [],
    layout: {
      rowSpan: 1,
      colSpan: 1,
      entireRow: false,
      fill: false
    },
    dataType: '50'
  }
  const curControl = isSelect(item) ? 'KSelect' : controlTypeList(item?.type)
  let curOptions = [] as any
  if (curControl === 'KSelect') {
    const res = (await Api.post('common', 'LegalValue', 'getLegalValue', {
      data: [item?.legalValueType]
    })) as any
    if (res?.success) {
      curOptions = res?.data.map((arr: any) => {
        return {
          label: arr?.name,
          ...arr
        }
      })
    }
  }
  return {
    control: curControl,
    props: {
      labelPosition: 'left',
      options: curOptions || [],
      format: 'YYYY-MM-DD HH:mm:ss',
      precision: item?.type === 10 ? 0 : item?.precision,
      extendType: String(item?.type)
    },
    name: item?.code,
    title: item?.displayName,
    field: `extendedAttributes#${item?.code}`,
    category: item?.category,
    ...commonArr
  }
}

// 判断是否为select
function isSelect(arr: any) {
  return arr?.optionalValue === 'LEGAL_VALUE_TYPE' && arr?.legalValueType
}

function controlTypeList(key: any) {
  const result: { [key: string]: string | undefined } = {
    '-1': 'KInput',
    '10': 'KInputNumber',
    '20': 'KInputNumber',
    '40': 'KDateTime',
    '50': 'KInput'
  }
  return result[key] || 'STRING'
}

export const PropertyType = {
  '-1': 'UNKNOWN',
  '10': 'INTEGER',
  '20': 'DECIMAL',
  '30': 'LONG',
  '40': 'DATETIME',
  '50': 'STRING',
  '60': 'PICTURE',
  '70': 'TEXT'
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KCreateFormView from './KCreateForm.vue'
import KCreateFormViewModel from './KCreateFormViewModel'

const KCreateForm = connect(KCreateFormView, KCreateFormViewModel)

export default withInstall(KCreateForm)
export { KCreateForm, KCreateFormView, KCreateFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

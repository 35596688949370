
import { defineView } from '@kmsoft/upf-core'
import { KBaselineComparePropOptions, KBaselineCompareEventEmits } from './interface'
import KBaselineCompareViewModel from './KBaselineCompareViewModel'
import KBaselineCompareGrid from './baseline-compare-grid'
import KBaselineCompareGridDiff from './baseline-compare-grid-diff'
import { reactive, ref, UnwrapRef, computed } from 'vue'
import { SelectItem } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KBaselineCompare',
  components: { KBaselineCompareGrid, KBaselineCompareGridDiff },
  props: KBaselineComparePropOptions,
  emits: KBaselineCompareEventEmits,
  viewModel: KBaselineCompareViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /**  过滤显示方示  **/
    const filterTypeOptions = ref([new SelectItem(1, '显示全部'), new SelectItem(2, '仅显示差异')])

    /** 展示的对象类 **/
    const objClsTypeOptions = computed(() => {
      let allData = [...vm.sourceDataSource.value, ...vm.targetDataSource.value]
      let classNameList = Array.from(new Set([...allData.map(item => item.className)]))

      return classNameList.map(item => new SelectItem(item, item))
    })

    return { objClsTypeOptions, filterTypeOptions }
  }
})

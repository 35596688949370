import { App, Plugin } from 'vue'
export * from './doc-create-panel'
export * from './doc-edit-panel'
export * from './doc-file-list'
export * from './doc-object-create-panel/controls/create-panel-doc-selector'
export * from './doc-object-preview'
export * from './doc-object-property-edit-panel'
export * from './doc-relation-obj'
export * from './doc-structure-manage'
export * from './doc-structure-tree'
export * from './doc-structure-list'
export * from './pdf-browser'
export * from './select-doc'
export * from './toolStrip'
export * from './agent-monitor'
export * from './doc-create-panel/doc-form'
export * from './agent-monitor'
export * from './agent-monitor-applet'
export * from './agent-download'
export * from './doc-batch-create'
export * from './structured-doc-manage'
// export * from './structured-doc-manage/structured-doc-tree'

import KDocStructureManage from './doc-structure-manage'

import KDocFileList from './doc-file-list'
import KDocStructureTree from './doc-structure-tree'

import KDocObjectPreview from './doc-object-preview'

import KDocObjectPropertyEditPanel from './doc-object-property-edit-panel'

import KSelectDoc from './select-doc'

import KPdfBrowser from './pdf-browser'

import KDocCreatePanel from './doc-create-panel'

import KDocEditPanel from './doc-edit-panel'

import KCreatePanelDocSelector from './doc-object-create-panel/controls/create-panel-doc-selector'
import KDocRelation from './doc-relation-obj'
import KDocStructureList from './doc-structure-list'

import KDocFrom from '../controls/doc-create-panel/doc-form'
import KAgentMonitor from '../controls/agent-monitor'
import KAgentMonitorApplet from '../controls/agent-monitor-applet'
import KAgentClientDownload from '../controls/agent-download'
import KDocBatchCreate from './doc-batch-create'
import KDocSelectFiles from './doc-batch-create/doc-select-files'
import KDocExtractFiles from './doc-batch-create/doc-extract-files'
import KDocBatchImport from './doc-batch-create/doc-batch-import'

import KDistributionContentObjGrid from './distribution-content'
import KDistributionRecordObjGrid from './distribution-record'
import KStructuredDocTree from './structured-doc-manage/structured-doc-tree'
import KStructuredDocManage from './structured-doc-manage'

const components: Record<string, Plugin> = {
  KDocFileList,
  KDocObjectPreview,
  KDocObjectPropertyEditPanel,
  KSelectDoc,
  KDocStructureManage,
  KDocStructureTree,
  KStructuredDocManage,
  KStructuredDocTree,
  KPdfBrowser,
  KDocCreatePanel,
  KDocEditPanel,
  KDocRelation,
  KCreatePanelDocSelector,
  KDocStructureList,
  KDocFrom,
  KAgentMonitor,
  KAgentMonitorApplet,
  KAgentClientDownload,
  KDocBatchCreate,
  KDocSelectFiles,
  KDocExtractFiles,
  KDocBatchImport,
  KDistributionContentObjGrid,
  KDistributionRecordObjGrid
}

const install = function(app: App) {
  Object.keys(components).forEach(key => {
    app.use(components[key])
  })
}

export default {
  install: install
}

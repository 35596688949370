import * as Vue from 'vue'
import * as UpfCore from '@kmsoft/upf-core'

/**表示一个 DataGrid 列，该列在其单元格中承载 image 控件。*/
export class KDataGridImageColumn extends UpfCore.KDataGridIconColumn {
  getCellTemplate(
    params: UpfCore.IKDataGridCellParams<UpfCore.KDataGridRowData>
  ): Vue.VNode<Vue.RendererNode, Vue.RendererElement, { [key: string]: any }> {
    return <UpfCore.KImage src={params.cellValue} preview={false}></UpfCore.KImage>
  }
}

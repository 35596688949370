import { IAppConfigurer, IocContainer } from '@kmsoft/upf-core'
import { App } from 'vue'
import { useCache, usePurview } from './client-srv'
import { useEditors } from './controls'
import CommonControl from './controls'
import { useDirectives } from './core'
export { EnumPurviewCode } from './common'

export default {
  configVue: (app: App) => {
    app.use(CommonControl)
    useDirectives(app)
  },
  configBean: (iocContainer: IocContainer) => {
    useCache(iocContainer)
    usePurview(iocContainer)
    useEditors(iocContainer)
  }
} as IAppConfigurer
export * from './client-srv'
export * from './controls'
export * as Agent from './client-agent'

import {
  KGridColumn,
  KGridColumnCompute,
  KGridColumnGroup,
  KViewSchema,
  ValueChangeEventArgs,
  VuePropTypes
} from '@kmsoft/upf-core'
import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'

export const DefaultFormValue = {
  name: '',
  remark: '',
  elements: [] as Array<KGridColumnGroup | KGridColumn | KGridColumnCompute>
} as KViewSchema

/** KFilterGridViewConfigForm 事件 */
export const KFilterGridViewConfigFormEventEmits = {
  ...BaseViewEventEmits,
  /** 值改变前事件 */
  beforeChange: (event: ValueChangeEventArgs) => true,
  /** 值改变后事件 */
  change: (event: ValueChangeEventArgs) => true
}

/** KFilterGridViewConfigForm 事件类型 **/
export type KFilterGridViewConfigFormEventEmitsType = ViewEmitsTypeExtract<typeof KFilterGridViewConfigFormEventEmits>

/** KFilterGridViewConfigForm 参数 **/
export const KFilterGridViewConfigFormPropOptions = {
  ...BaseViewPropOptions,
  formValue: VuePropTypes.createType<KViewSchema>().def(DefaultFormValue)
}

/** KFilterGridViewConfigForm 参数类型 **/
export type KFilterGridViewConfigFormPropType = ViewPropsTypeExtract<typeof KFilterGridViewConfigFormPropOptions>

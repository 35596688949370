import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81cf855a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "change-activity-grid-container" }
const _hoisted_2 = { class: "flex-start" }
const _hoisted_3 = { class: "flex1 change-activity-grid" }
const _hoisted_4 = { class: "flex1 change-activity-grid" }
const _hoisted_5 = { class: "change-activity-grid-diff" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_radio = _resolveComponent("k-radio")!
  const _component_k_radio_group = _resolveComponent("k-radio-group")!
  const _component_k_form = _resolveComponent("k-form")!
  const _component_k_change_activity_update_compare_grid = _resolveComponent("k-change-activity-update-compare-grid")!
  const _component_k_change_activity_update_compare_grid_diff = _resolveComponent("k-change-activity-update-compare-grid-diff")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createVNode(_component_k_form, { layout: "inline" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_form_item, { label: "需要展示的对象类:" }, {
            default: _withCtx(() => [
              _createVNode(_component_k_select, {
                value: _ctx.$vm.searchData.objCls,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.searchData.objCls) = $event)),
                mode: "multiple",
                style: {"width":"300px"},
                options: _ctx.objClsTypeOptions
              }, null, 8, ["value", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_k_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_radio_group, {
                value: _ctx.$vm.searchData.filterType,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.searchData.filterType) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterTypeOptions, (item) => {
                    return (_openBlock(), _createBlock(_component_k_radio, {
                      value: item.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 256))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_k_change_activity_update_compare_grid, {
              title: "改前对象",
              dataSource: _ctx.$vm.dataSourceFilter(_ctx.$vm.sourceDataSource),
              isReference: ""
            }, null, 8, ["dataSource"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_k_change_activity_update_compare_grid, {
              title: "改后对象",
              dataSource: _ctx.$vm.dataSourceFilter(_ctx.$vm.targetDataSource)
            }, null, 8, ["dataSource"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_k_change_activity_update_compare_grid_diff, {
            sourceTitle: "改前对象",
            targetTitle: "改后对象",
            dataSource: _ctx.$vm.gridDiffDataDatasource
          }, null, 8, ["dataSource"])
        ])
      ])
    ]),
    _: 1
  }))
}
import { PageInfo } from './types'
import { ObjBusinessParam } from '../object'
import { Component } from 'vue'
import { PageManager } from '@kmsoft/upf-core'
export class CommonClientSrv {
  /** 系统定义列名 */
  static SYS_DEF_PROP = {
    NAME: 'CNAME',
    SYMBOL: 'CSYMBOL',
    SUFFIX: 'CSUFFIX',
    PRODUCT_TYPE: 'CPRODUCTTYPE',
    PROCESS_ROUTE: 'CPROCESSROUTE',
    MATERIAL_NOM: 'CMATERIALNOM',
    IS_FAMILY: 'CPARTFAMILY',
    SAFE_LEVEL: 'CSAFELEVEL',
    PART_EXTENDPROPERTY: 'EXTENDPROPERTY',
    getPreDefineColName(argName: string): string | undefined {
      switch (argName) {
        case '代号':
          return this.SYMBOL
        case '代号后缀':
          return this.SUFFIX
        case '名称':
          return this.NAME
        case '制造类型':
          return this.PRODUCT_TYPE
        case '工艺路线':
          return this.PROCESS_ROUTE
        case '材料定额':
          return this.MATERIAL_NOM
        case '零部件族':
          return this.IS_FAMILY
        default:
          return undefined
      }
    }
  } as const

  /**
   * 默认分页信息
   * @returns
   */
  static getDefaultPageInfo(): PageInfo {
    return {
      pageSize: 50,
      pageIndex: 1,
      orderBy: ''
    }
  }

  /**
   * 获取当前站点访问根地址
   * @returns
   */
  static getFullHost(): string {
    return document.location.protocol + '//' + document.location.host
  }

  /**
   * 打开页面
   * @param title 匿名标签页的标题
   * @param content Vue组件
   * @param params Vue组件参数
   * @param tabKey tab页签的Key
   * @return tab页签的Key
   */
  static openPage(title: string, content: Component, params: Record<string, any>, tabKey?: string): string {
    if (!tabKey) {
      tabKey = new Date().getTime().toString()
    }

    PageManager.openPage(tabKey, title, content, params)

    return tabKey
  }

  /**
   * 将行转换为业务对象参数
   * @param row
   * @returns
   */
  static getObjBusinessParamByRow(row: Record<string, any>): ObjBusinessParam {
    return {
      modelCode: row.className,
      id: row.id,
      masterId: row.masterId,
      iteratorId: row.iteratorId,
      extensionType: row.rdmExtensionType
    }
  }

  /**
   * 延迟执行后面的操作
   * @param time 延迟时长
   * @returns
   */
  static sleep(time: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(void 0)
      }, time)
    })
  }
  /**
   * 将对象转换为string
   * @param obj 对象
   * @returns
   */
  static objectToString(obj: any): string {
    switch (typeof obj) {
      case 'string':
        return obj
      case 'number':
        return obj.toString()
      case 'boolean':
        return obj.toString()
      case 'undefined':
        return ''
      case 'object':
        return JSON.stringify(obj)
      default:
        return ''
    }
  }

  /** 获取是否是 Windows 环境 */
  static get isWindows() {
    return navigator.userAgent.indexOf('Windows NT ') != -1 || navigator.userAgent.indexOf('Windows ') != -1
  }
}

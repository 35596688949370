
import { defineView } from '@kmsoft/upf-core'
import { KHyperLinkPropOptions, KHyperLinkEventEmits } from './interface'
import KHyperLinkViewModel from './KHyperLinkViewModel'

export default defineView({
  name: 'KHyperLink',
  props: KHyperLinkPropOptions,
  emits: KHyperLinkEventEmits,
  viewModel: KHyperLinkViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

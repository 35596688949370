
import { EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import KPropertySettingsFromViewModel from './KPropertySettingsFromViewModel'
import { KPropertySettingsFromEventEmits, KPropertySettingsFromPropOptions } from './interface'

export default defineView({
  name: 'KPropertySettingsFrom',
  props: KPropertySettingsFromPropOptions,
  emits: KPropertySettingsFromEventEmits,
  viewModel: KPropertySettingsFromViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})

import { Component, ComputedOptions, MethodOptions } from 'vue'
import { KSelectWrapper } from '.'
import { EnumDataType, MetaProperty } from '../../../../client-srv'
import { IFieldEditorResolver, KObjectClassGridBaseColumn } from '../../base'
import { KDataGridClassSelectColumn } from './KDataGridSelectWrapperColumn'

export class ClassSelectResolver extends IFieldEditorResolver {
  static getColumn(field: MetaProperty): KObjectClassGridBaseColumn | null {
    if (field.type !== EnumDataType.OBJECT && field.columnName !== 'rdmExtensionType') {
      const refColumnInstance = new KDataGridClassSelectColumn()
      return refColumnInstance
    }
    return null
  }
  static getEditor(field: MetaProperty): string | (() => Component<any, any, any, ComputedOptions, MethodOptions>) | null {
    if (field.type !== EnumDataType.OBJECT) {
      return () => KSelectWrapper
    }
    return null
  }
}

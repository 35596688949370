import { KNotification, MemoryCacheBase } from '@kmsoft/upf-core'
import lodash from 'lodash'
import { Api, EnumRequestCode } from '../../../../../client-srv'
import { ClassMeta } from '../../../../meta'
/** 对象类缓存 */
export class ClassMetaMemoryCache extends MemoryCacheBase<Array<ClassMeta>> {
  public static cacheKey = 'ClassMetaMemoryCache'
  constructor() {
    super()
    this.cacheKey = ClassMetaMemoryCache.cacheKey
  }

  public async reload() {
    /**通过接口获取对象类元数据定义 */
    const res = await Api.post('common', 'TypeDefinition', 'listAll', { data: [] })
    if (res && res.code == EnumRequestCode.SUCCESS) {
      this.cacheData = res.data! as Array<ClassMeta>
    } else {
      KNotification.error({
        title: '系统提示',
        content: '对象类元数据获取失败',
        details: res.message
      })
      this.cacheData = []
    }
  }

  /** 获取所有数据 */
  public getAll() {
    return lodash.cloneDeep(this.cacheData)
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartDocBomCompareModeFormView from './KPartDocBomCompareModeForm.vue'
import KPartDocBomCompareModeFormViewModel from './KPartDocBomCompareModeFormViewModel'

const KPartDocBomCompareModeForm = connect(KPartDocBomCompareModeFormView, KPartDocBomCompareModeFormViewModel)

export default withInstall(KPartDocBomCompareModeForm)
export { KPartDocBomCompareModeForm, KPartDocBomCompareModeFormView, KPartDocBomCompareModeFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

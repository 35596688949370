import { BaseViewModel, ViewModelOptions, EnumType, KSchema, KNotification } from '@kmsoft/upf-core'
import { KAttrsCategoryRenderEmitsType, KAttrsCategoryRenderPropType } from './interface'
import { ref } from 'vue'
import { Api } from '@kmsoft/ebf-common'
import { get } from 'lodash'
import dayjs from 'dayjs'

/** KAttrsCategoryRender */
export default class KAttrsCategoryRenderViewModel extends BaseViewModel<
  KAttrsCategoryRenderEmitsType,
  KAttrsCategoryRenderPropType
> {
  refDynamic = ref<any | KAttrsCategoryRenderViewModel>(null)
  attrsCategoryControls: Array<Record<string, any>> = []
  attrsCategoryPropsSchema = ref<KSchema>()
  isShowAttrsCategory = ref<boolean>(false)
  attrsPageValue = ref<Record<string, any>>({})
  activeKeys = ref<Array<string>>(['attrs'])
  enumTypeList = ref<Record<string, any>>({})

  /** 初始化画布 */
  initDraw = (id: any, formdata: any = []) => {
    this.refreshClass(id)
    this.setValue(formdata)
  }

  /** 清除 */
  clear = () => {
    this.refreshClass('')
  }

  constructor(options: ViewModelOptions<KAttrsCategoryRenderPropType>) {
    super(options)
  }

  viewDidMount() {
    if (this?.props?.formData?.catetory) {
      this.refreshClass()
    }
  }

  /** 监听分类选择 */
  async refreshClass(id = '') {
    this.isShowAttrsCategory.value = false
    // id为空统一处理
    if (!id) {
      this.attrsCategoryPropsSchema.value = ({} as any) as KSchema
      return
    }
    const result = (await Api.post('common', 'DataModelManagement', 'getAllAttribute', {
      data: [{ entityNameEn: id, holderType: 'MODEL' }]
    })) as any
    // 接口调用失败清除分类属性
    if (!result?.success) {
      KNotification.error({
        title: '操作失败',
        content: result.message || '查询失败',
        details: result.detail
      })
      this.attrsCategoryPropsSchema.value = ({} as any) as KSchema
      return
    }
    result.data = (result?.data || []).filter((arrFil: any) => ['EXTEND'].includes(arrFil?.category))
    if (!get(result, 'data.length', 0)) return
    // 接口调用成功处理
    this.successOption(result)
  }

  // 调用成功处理
  async successOption(result: any) {
    const attrs = get(result, 'data', [])
    // 使用map创建一个包含Promise的数组
    const promises = (attrs || []).map((item: any) => this.optionsControl(item))

    // 等待所有的Promise解决
    Promise.all(promises).then((results: any) => {
      this.attrsCategoryControls = (results || []).filter((arrFil: any) => ['EXTEND'].includes(arrFil?.category))
      this.enumTypeList.value = this.attrsCategoryControls.map((arrMap: any) => {
        return {
          name: arrMap?.name,
          scale: arrMap?.contolTYpe,
          type: this.controlValueList(arrMap?.contolTYpe)
        }
      })
      const schema = {
        name: 'category',
        type: EnumType.PAGE,
        title: '扩展属性',
        control: 'KDynamicView',
        visible: true,
        disabled: false,
        readonly: 0,
        props: {
          minColWidth: 260,
          rowGap: 5,
          colGap: 10,
          colCount: 3,
          labelWidth: 100,
          labelPosition: 'left'
        },
        events: [],
        styles: [],
        controls: this.attrsCategoryControls,
        subPages: []
      }
      this.isShowAttrsCategory.value = true
      this.attrsCategoryPropsSchema.value = (schema as any) as KSchema
    })
  }

  // 判断是否为select
  isSelect(arr: any) {
    return arr?.optionalValue === 'LEGAL_VALUE_TYPE' && arr?.legalValueType
  }

  // contorl组件配置
  async optionsControl(item: any) {
    const commonArr = {
      visible: 1,
      dataSource: '',
      nullable: 1,
      readonly: this.props.readonly,
      disabled: this.props.disabled,
      type: 'control',
      events: [],
      layout: {
        rowSpan: 1,
        colSpan: 1,
        entireRow: false,
        fill: false
      },
      dataType: '50'
    }
    const curControl = this.isSelect(item) ? 'KSelect' : this.controlTypeList(item?.type)
    let curOptions = [] as any
    if (curControl === 'KSelect') {
      const res = (await Api.post('common', 'LegalValue', 'getLegalValue', {
        data: [item?.legalValueType]
      })) as any
      if (res?.success) {
        curOptions = res?.data.map((arr: any) => {
          return {
            label: arr?.name,
            ...arr
          }
        })
      }
    }
    return {
      control: curControl,
      props: {
        labelPosition: 'left',
        readonly: this.props.readonly,
        options: curOptions || [],
        format: 'YYYY-MM-DD HH:mm:ss',
        precision: item?.type === 10 ? 0 : item?.precision
      },
      name: item?.code,
      title: item?.displayName,
      field: item?.code,
      category: item?.category,
      contolTYpe: item?.type,
      ...commonArr
    }
  }

  // 拿数据
  getValue() {
    const result = [] as any
    const oldValue = this.refDynamic?.value?.getValue() || {}
    Object.keys(oldValue).forEach(key => {
      if (oldValue[key]) {
        const currentValue = this.enumTypeList.value.filter((arrFil: any) => {
          return arrFil?.name === key
        })
        if (!currentValue?.length) return
        result.push({
          type: get(currentValue, '[0].type', ''),
          name: key,
          value: String(oldValue[key])
        })
      }
    })
    return result
  }
  //存数据
  setValue(value: any) {
    const result = {} as any
    ;(value || [])
      .filter((arrFil: any) => {
        return arrFil?.type !== 'CATEGORY'
      })
      .map((arrMap: any) => {
        if (arrMap?.type === 'DATETIME') {
          arrMap.value = arrMap?.value ? dayjs(arrMap?.value).format('YYYY-MM-DD HH:mm:ss') : ''
        }
        result[arrMap?.name] = arrMap?.value
      })
    this.attrsPageValue.value = result
  }

  public getModifiedValue() {
    return this.getValue()
  }

  isModified(): boolean {
    return true
  }

  refresh() {
    this.attrsCategoryPropsSchema.value?.controls.map(arr => {
      arr.props.readonly = this.props.readonly
      return arr
    })
  }

  // 根据type查询数据类型
  controlValueList(key: any) {
    const result = {
      '-1': 'UNKNOWN',
      '10': 'INTEGER',
      '20': 'DECIMAL',
      '30': 'LONG',
      '40': 'DATETIME',
      '50': 'STRING',
      '60': 'PICTURE',
      '70': 'TEXT'
    }
    return get(result, `${key}`, 'STRING')
  }

  // 根据type查询控件类型
  controlTypeList(key: any) {
    const result = {
      '-1': 'KInput',
      '10': 'KInputNumber',
      '20': 'KInputNumber',
      '40': 'KDateTime',
      '50': 'KInput'
    }
    return get(result, `${key}`, 'STRING')
  }
}

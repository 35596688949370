import { AppContext, IRequestInterceptor, UPF_IOC_KEYS } from '@kmsoft/upf-core'
import { SysConfig } from './types'

/** 系统配置 */
export class ConfigClientSrv {
  /** WebAPI地址 */
  static get baseApiUrl() {
    const RequestInterceptor = AppContext.current.getIocContainer().getBean<IRequestInterceptor>(UPF_IOC_KEYS.REQUEST_INTERCEPTOR)
    return RequestInterceptor.baseApiUrl
  }

  static get baseFileUrl() {
    return 'http://ebf-file.kmsaas.com'
  }

  /** 获取property */
  static getProperty<T>(key: string): T {
    const environment = AppContext.current.getEnvironment()
    return environment.getProperty(key)
  }
  /**获取Api基地址 */
  static get getApiBaseUrl(): string {
    return ConfigClientSrv.getProperty('server.baseApiUrl')
  }

  /**获取Api基地址 */
  static get getFileApiBaseUrl(): string {
    const host = window.location.host
    const fileServer = 'http://ebf-file'
    const hosts = host.split('.')
    return `${fileServer}.${hosts.length <= 1 || hosts[0] == '192' ? 'dev.kmsaasdev.com' : hosts.slice(1).join('.')}`
  }

  /**
   * 获取所有配置
   * @returns
   */
  static getAllConfigs(): Array<SysConfig> {
    return []
  }

  /**
   * 获取系统配置
   * @param id
   * @returns
   */
  static getConfig<T = Record<string, any>>(id: string): T | undefined {
    return undefined
  }

  /** 默认日期格式 */
  static get DEFAULT_DATE_FORMAT(): string {
    return 'YYYY-MM-DD'
  }

  /** 默认日期时间格式 */
  static get DEFAULT_DATETIME_FORMAT(): string {
    return 'YYYY-MM-DD HH:mm'
  }

  /** 默认日期时分秒格式 */
  static get DEFAULT_FULL_DATETIME_FORMAT(): string {
    return 'YYYY-MM-DD HH:mm:ss'
  }
}

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ToolStripItem,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { IDirectoryTreeNodeData } from '../directory-tree'

/** 脚本分组源数据模型 */
export type ScriptGroupModel = {
  parentId?: string
  name?: string
  type?: number
  isSystem?: boolean
  groupType?: string
  orderId?: number
  script?: string
  id?: string
  remark?: string
}

/** KBizScriptEditor 事件 */
export const KBizScriptEditorEventEmits = {
  ...BaseViewEventEmits,
  'update:value': (value: string) => true
}

/** KBizScriptEditor 事件类型 **/
export type KBizScriptEditorEventEmitsType = ViewEmitsTypeExtract<typeof KBizScriptEditorEventEmits>

/** KBizScriptEditor 参数 **/
export const KBizScriptEditorPropOptions = {
  ...BaseViewPropOptions,
  /** 是否显示工具条 */
  showToolStrip: VuePropTypes.bool().def(false),
  /** 工具栏中的菜单项集合|工具条实现类 */
  toolStripItems: VuePropTypes.createType<Array<ToolStripItem>>().def([]),
  /** 脚本分组 */
  groupTypes: VuePropTypes.array<string>().def(),
  /** 默认脚本 */
  value: VuePropTypes.string().def(),
  /** 脚本上下文 */
  scriptContext: VuePropTypes.func<() => Record<string, any>>().def()
}

/** KBizScriptEditor 参数类型 **/
export type KBizScriptEditorPropType = ViewPropsTypeExtract<typeof KBizScriptEditorPropOptions>

/** 脚本节点数据 */
export interface IScriptNodeData extends IDirectoryTreeNodeData {
  /** 脚本 */
  script: string
  /** 排序号 */
  orderId: number
  /** 脚本名称 */
  name: string
  /** 备注 */
  remark: string
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KEditPanelDemoEmitsType, KEditPanelDemoPropType } from './interface'
import { ObjBusinessParam, ObjectClientSrv } from '@kmsoft/ebf-common'

/** KEditPanelDemo */
export default class KEditPanelDemoViewModel extends BaseViewModel<KEditPanelDemoEmitsType, KEditPanelDemoPropType> {
  constructor(options: ViewModelOptions<KEditPanelDemoPropType>) {
    super(options)
  }

  viewDidMount() {}
  openEditPanel() {
    const params = {
      id: '1',
      modelCode: 'Part',
      group: 'part'
    } as ObjBusinessParam
    ObjectClientSrv.openObj(params)
  }
}

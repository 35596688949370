
import { defineView } from '@kmsoft/upf-core'
import { KBaselineCreateFormPropOptions, KBaselineCreateFormEventEmits } from './interface'
import KBaselineCreateFormViewModel from './KBaselineCreateFormViewModel'

export default defineView({
  name: 'KBaselineCreateForm',
  props: KBaselineCreateFormPropOptions,
  emits: KBaselineCreateFormEventEmits,
  viewModel: KBaselineCreateFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

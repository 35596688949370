import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KSegmentContainerPropType, KSegmentContainerEventEmitsType } from './interface'
import { ref } from 'vue'

/** 导航管理 */
export default class KSegmentContainerViewModel extends BaseViewModel<
  KSegmentContainerEventEmitsType,
  KSegmentContainerPropType
> {
  activeKey = ref('1')
  constructor(options: ViewModelOptions<KSegmentContainerPropType>) {
    super(options)
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { UploadFileInfo } from '../../../client-srv'

/** 参数 **/
export const KFileUploaderProgressPropOptions = {
  ...BaseViewPropOptions,
  fileList: VuePropTypes.createType<Array<UploadFileInfo>>().def([]),
  /** 是否只读 */
  readonly: VuePropTypes.bool().def(false),
  /** 是否禁用 */
  disabled: VuePropTypes.bool().def(false),
  /* 是否可见 */
  visible: VuePropTypes.bool().def(true)
}

/** 参数类型 **/
export type KFileUploaderProgressPropType = ViewPropsTypeExtract<typeof KFileUploaderProgressPropOptions>

/** 事件 */
export const KFileUploaderProgressEventEmits = {
  ...BaseViewEventEmits,
  fileRemove: (fileInfo: UploadFileInfo) => true,
  fileDownload: (fileInfo: UploadFileInfo) => true
}

/** 事件类型 **/
export type KFileUploaderProgressEmitsType = ViewEmitsTypeExtract<typeof KFileUploaderProgressEventEmits>

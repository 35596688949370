import { connect, withInstall } from '@kmsoft/upf-core'
import KBusinessVersionRenderView from './KBusinessVersionRender.vue'
import KBusinessVersionRenderViewModel from './KBusinessVersionRenderViewModel'

const KBusinessVersionRender = connect(KBusinessVersionRenderView, KBusinessVersionRenderViewModel)

export default withInstall(KBusinessVersionRender)
export { KBusinessVersionRender, KBusinessVersionRenderView, KBusinessVersionRenderViewModel }
export * from './KObjectBusinessVersionColumn'

// 模板生成文件
// export * from './{{folderName}}'

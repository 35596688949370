import {
  SimpleViewPropOptions,
  SimpleViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { SelectItem } from '@kmsoft/ebf-common'

/** 参数 **/
export const KIssueReportAffectedViewsGridPropOptions = {
  ...SimpleViewPropOptions,
  /** 是否是设计模式 */
  isDesigner: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KIssueReportAffectedViewsGridPropType = ViewPropsTypeExtract<typeof KIssueReportAffectedViewsGridPropOptions>

/** 事件 */
export const KIssueReportAffectedViewsGridEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KIssueReportAffectedViewsGridEmitsType = ViewEmitsTypeExtract<typeof KIssueReportAffectedViewsGridEventEmits>

import {
  Agent,
  Api,
  EnumRequestCode,
  FileClientSrv,
  KObjectPropertyPanelViewModel,
  KSelectWrapperViewModel
} from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  EnumDialogResult,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  request,
  SimpleViewModel,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { DocProperty } from '../../../client-agent'
import { DocDetailState, KDocFormEmitsType, KDocFormPropType } from './interface'
import { DocEchoGridDataInner, EnumOperateOptions, EnumOperateType } from '../../../controls/doc-echo-list-panel/interface'
import { KDocEchoListPanel, KDocEchoListPanelViewModel } from '../../../controls/doc-echo-list-panel'
import { EnumDocType } from '../../../types/enums'

/** KDocForm */
export default class KDocFormViewModel extends BaseViewModel<KDocFormEmitsType, KDocFormPropType> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  newModelCode = ref<string>()
  //类型选项
  documentTypeOptions = ref<Array<Record<string, any>>>()
  formDataState = ref<DocDetailState>({
    documentType: 'SOLIDWORKS',
    number: '',
    name: '',
    version: 'A',
    lifecycleState: {
      name: '工作中'
    },
    description: '',
    primary: [],
    secondary: []
  })

  modelName = ref('Document')
  groupName = ref('doc')
  modelNumber = ref('DM00384994')

  primaryFile = ref([])
  secondaryFile = ref([])
  private simpleViewModel: SimpleViewModel
  oldDocumentType = ref()
  extendAttrs = ref([] as Record<string, any>[])
  folder = ref<string>()

  constructor(options: ViewModelOptions<KDocFormPropType>) {
    super(options)
    this.formDataState.value = { ...this.formDataState.value, ...options.props.formValue }
    this.folder.value = options.props.formValue['folder']
    watch(
      () => options.props.modelCode,
      () => {
        this.newModelCode.value = options.props.modelCode
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {
    this.newModelCode.value = this.props.modelCode
    this.initDocumentTypeOptions()
  }

  async initDocumentTypeOptions() {
    const result = await Api.post('doc', 'Document', 'getDocType', { data: [] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const data = result.data
      this.documentTypeOptions.value = data
    }
  }

  async customRequest({ file }: { file: File }, attributeName: string) {
    const storageType = '0'
    const applicationId = '0955fb5adeb7489b90b17dc134fb105f'
    const req = new FormData()
    req.append('modelName', this.modelName.value)
    req.append('attributeName', attributeName)
    req.append('modelNumber', this.modelNumber.value)
    req.append('applicationId', applicationId)
    req.append('storageType', storageType)
    req.append('files', file)
    const result = (await request.post('/kmsaasFileApi/upload', req, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })) as any
    if (result && result.result == 'SUCCESS') {
      const data = result.data
      return data[0]
    }
  }

  /**
   * 调用文件上传附件
   * @param 文件
   */
  async uploadSecondaryFile({ file }: { file: File }) {
    const id = await this.customRequest({ file }, 'secondary')
    if (id) {
      this.formDataState.value?.secondary.push({ id: id })
    }
  }

  /**
   * 调用文件上传上传主文件
   * @param param0 文件
   */
  async uploadPrimaryFile({ file }: { file: File }) {
    const id = await this.customRequest({ file }, 'primary')
    if (id) {
      this.formDataState.value?.primary.push({ id: id })
    }
  }

  getValue() {
    let plmDocCreateObject = this.refObjectCreatePanel.value?.getValue() as any
    if (plmDocCreateObject?.extAttrsList?.length) {
      plmDocCreateObject.extAttrs = plmDocCreateObject.extAttrs
        ? plmDocCreateObject.extAttrs.concat(plmDocCreateObject?.extAttrsList)
        : plmDocCreateObject?.extAttrsList
    }
    delete plmDocCreateObject.extAttrsList
    plmDocCreateObject = { ...plmDocCreateObject, rdmExtensionType: plmDocCreateObject.modelCode } as any
    if (this.newModelCode.value && this.newModelCode.value != '') {
      plmDocCreateObject = { ...plmDocCreateObject, rdmExtensionType: this.newModelCode.value } as any
    }
    // 零部件类型

    return plmDocCreateObject
  }

  async save(asTemplate?: boolean) {
    const newDoc = this.getValue()
    if (asTemplate) {
      newDoc!.isTemplate = true
      newDoc!.enabledTemplate = true
    }
    const record = newDoc as Record<string, any>
    const dialog = KDialog.info({ content: '正在创建文档，请稍后...', title: '提示', showOk: false })
    if (record.secondary) {
      const secondary = record.secondary.map((element: string) => {
        return { id: element }
      })
      record.secondary = secondary
    }
    if (!record.docList) {
      record.docList = [
        {
          nodeId: 0,
          isRoot: true,
          name: record.name,
          code: record.number,
          checkoutPath: record.fileName,
          secondary: record.secondary
        }
      ]
    }
    if (await Agent.AgentManager.initialize()) {
      const docList = record.docList as DocProperty[]
      const checkOutDocList = docList.filter(_ => _.checkoutPath)
      const files = []
      for (const item of checkOutDocList) {
        files.push(item.checkoutPath)
      }
      const result = await FileClientSrv.batchUploadFilesByAgent({
        files: files,
        modelName: 'Document',
        attributeName: 'primary'
      })
      for (let i = 0; i < checkOutDocList.length; i++) {
        checkOutDocList[i].primary = [{ id: result.data[i] }]
      }
      for (const item of docList) {
        if (asTemplate) {
          item.isTemplate = true
          item.enabledTemplate = true
        }
      }
      //设置primary
      const rootNode = docList.find(item => item.isRoot)
      if (!rootNode) {
        KNotification.error({
          title: '系统提示',
          content: '主文件不能为空'
        })
      }
      if (!this.validate()) {
        dialog.destroy()
        return null
      }
      record.primary = rootNode?.primary
    } else {
      if (record.fileId) {
        record.primary = [{ id: record.fileId }]
      }
    }

    let folder = this.props.formValue.folder
    if (record.documentType == EnumDocType.BREAKDOWN_ELEMENTS) {
      folder = undefined
    }
    const req = {
      data: [{ ...record, lifecycleState: {}, folder: folder }]
    }
    const result = await Api.post('doc', 'Document', 'create', req)
    dialog.destroy()
    return result
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  async getPreviewDocList(docList: DocProperty[], createPart: boolean): Promise<DocProperty[]> {
    const docEchoList = [] as DocEchoGridDataInner[]
    let index = 0
    for (const docProperty of docList) {
      const docEchoGridDataInner = {
        id: 'doc' + ++index,
        index: index.toString(),
        type: 'doc',
        number: docProperty.code,
        name: docProperty.name,
        folder: docProperty.folder,
        version: 'A',
        iteration: '1',
        operate: docProperty.operate,
        operateOption: docProperty.operateOption,
        docProperty: docProperty
      } as DocEchoGridDataInner
      docEchoList.push(docEchoGridDataInner)
    }
    if (createPart) {
      index = 0
      for (const docProperty of docList) {
        const docEchoGridDataInner = {
          id: 'part' + ++index,
          index: index.toString(),
          type: 'part',
          number: docProperty.part!.number,
          name: docProperty.part!.name,
          folder: docProperty.part!.folder,
          version: 'A',
          iteration: '1',
          operate: docProperty.part!.operate,
          operateOption: docProperty.part!.operateOption,
          nodeId: docProperty.nodeId
        } as DocEchoGridDataInner
        docEchoList.push(docEchoGridDataInner)
      }
    }
    return new Promise((reslove, reject) => {
      KDialog.show({
        title: '预览',
        size: { width: 900, height: 600 },
        props: {
          dataSource: docEchoList,
          operateType: EnumOperateType.Create,
          operateOptions: EnumOperateOptions.CREATE
        },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KDocEchoListPanel,
        onClosing: async (event: KDialogClosingEvent) => {
          const docEchoListPanelViewModel = event.viewModel as KDocEchoListPanelViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          const operateDocList = [] as DocProperty[]
          if (!docEchoListPanelViewModel.validate()) {
            KNotification.warn({
              message: '系统提示',
              description: '请补全数据'
            })
            event.cancel = true
            return
          }
          const operateData = docEchoListPanelViewModel.getOperateData() as DocEchoGridDataInner[]
          const operateDocData = operateData.filter(_ => _.type == 'doc')
          const operatePartData = operateData.filter(_ => _.type == 'part')
          for (const operateDoc of operateDocData) {
            const docProperty = operateDoc.docProperty
            docProperty.code = operateDoc.number
            docProperty.name = operateDoc.name
            docProperty.folder = operateDoc.folder
            docProperty.operate = operateDoc.operate
            if (createPart) {
              const operatePart = operatePartData.find(_ => _.nodeId == docProperty.nodeId)
              if (operatePart) {
                docProperty.part = {
                  number: operatePart.number,
                  name: operatePart.name,
                  folder: operatePart.folder,
                  operate: operatePart.operate
                }
              } else {
                docProperty.part = undefined
              }
            }
            operateDocList.push(docProperty)
          }
          reslove(operateDocList)
        }
      })
    })
  }

  loaded() {
    // 分类组件
    const _this = this
    this.simpleViewModel = this.getByRecursion('KSelectWrapper#documentType', this) as KSelectWrapperViewModel | any
    this.simpleViewModel?.on('select' as any, async (changedEventArgs: any) => {
      if (_this.oldDocumentType.value && changedEventArgs != _this.oldDocumentType.value) {
        //属性置空
        _this.refObjectCreatePanel.value?.setPropertyValue('name', '')
        _this.refObjectCreatePanel.value?.setPropertyValue('number', '')
        _this.refObjectCreatePanel.value?.setPropertyValue('primary', '')
        _this.refObjectCreatePanel.value?.setPropertyValue('secondary', [])
        //清空拓展属性
        const panelProps = _this.refObjectCreatePanel.value?.props as Record<string, any>
        const panelModelCode = panelProps['modelCode']
        const result = await Api.post('common', 'DataModelManagement', 'getAllAttribute', {
          data: [{ entityNameEn: panelModelCode, holderType: 'MODEL' }]
        })
        const data = (result?.data || []).filter((arrFil: any) => ['EXTEND'].includes(arrFil?.category)) as Record<string, any>[]
        _this.extendAttrs.value = data
        console.info('_this.extendAttrs.value', _this.extendAttrs.value)
        if (_this.extendAttrs.value) {
          for (const extendAttr of _this.extendAttrs.value) {
            _this.refObjectCreatePanel.value?.setPropertyValue(`extendedAttributes#${extendAttr?.code}`, '')
          }
        }
      }
      _this.oldDocumentType.value = changedEventArgs
    })
  }
}

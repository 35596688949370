import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { KObjectPanelTabEventEmits, KObjectPanelTabPropOptions } from '../object-panel'

/** 参数 **/
export const KObjectRelationPropOptions = {
  ...KObjectPanelTabPropOptions,
  /**关联编码。*/
  relationCode: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KObjectRelationPropType = ViewPropsTypeExtract<typeof KObjectRelationPropOptions>

/** 事件 */
export const KObjectRelationEventEmits = {
  ...KObjectPanelTabEventEmits
}

/** 事件类型 **/
export type KObjectRelationEmitsType = ViewEmitsTypeExtract<typeof KObjectRelationEventEmits>


import { defineView } from '@kmsoft/upf-core'
import KFolderManage from '../../folder-manage'
import KPersonalWorkspaceViewModel from './KPersonalWorkspaceViewModel'
import { KPersonalWorkspaceEventEmits, KPersonalWorkspacePropOptions } from './interface'

export default defineView({
  name: 'KPersonalWorkspace',
  props: KPersonalWorkspacePropOptions,
  emits: KPersonalWorkspaceEventEmits,
  components: { KFolderManage }, // 这里定义局部组件
  viewModel: KPersonalWorkspaceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

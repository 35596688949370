import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  SimpleViewPropOptions,
  SimpleViewEventEmits
} from '@kmsoft/upf-core'

/** 参数 **/
export const KChangeActivityObjGridPropOptions = {
  ...SimpleViewPropOptions,
  /** 是否是变更后对象 **/
  isAfterObject: VuePropTypes.bool().def(false),
  /** 过滤状态 **/
  filterLifecycleState: VuePropTypes.string().def('RELEASED'),
  /** 是否是设计模式 */
  isDesigner: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KChangeActivityObjGridPropType = ViewPropsTypeExtract<typeof KChangeActivityObjGridPropOptions>

/** 事件 */
export const KChangeActivityObjGridEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KChangeActivityObjGridEmitsType = ViewEmitsTypeExtract<typeof KChangeActivityObjGridEventEmits>

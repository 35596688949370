import { connect, withInstall } from '@kmsoft/upf-core'
import KFolderSelectTestView from './KFolderSelectTest.vue'
import KFolderSelectTestViewModel from './KFolderSelectTestViewModel'

const KFolderSelectTest = connect(KFolderSelectTestView, KFolderSelectTestViewModel)

export default withInstall(KFolderSelectTest)
export { KFolderSelectTest, KFolderSelectTestView, KFolderSelectTestViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KIssueReportRelationPartView from './KIssueReportRelationPart.vue'
import KIssueReportRelationPartViewModel from './KIssueReportRelationPartViewModel'

const KIssueReportRelationPart = connect(KIssueReportRelationPartView, KIssueReportRelationPartViewModel)

export default withInstall(KIssueReportRelationPart)
export { KIssueReportRelationPart, KIssueReportRelationPartView, KIssueReportRelationPartViewModel }

// 模板生成文件
// export * from './{{folderName}}'

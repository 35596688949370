import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { KControlAttribute } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'

/* 属性配置项集合 */
const BASE = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE] as Array<KControlAttribute>

/**控件属性 */
const PROPS = [
  LAYOUT_CONFIG_ITEM_COLLECTION.MIN_COL_WIDTH,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_GAP,
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_GAP,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_COUNT,
  LAYOUT_CONFIG_ITEM_COLLECTION.LABEL_POSITION,
  LAYOUT_CONFIG_ITEM_COLLECTION.LABEL_WIDTH
] as Array<KControlAttribute>
/* 布局配置项集合 */
const LAYOUT = [] as Array<KControlAttribute>

const EVENT = [
  { name: 'mounted', title: '挂载完成', describe: '挂载完成 的说明信息...' },
  { name: 'dataLoaded', title: '数据加载完成', describe: '数据加载完成 的说明信息...' }
]

export default { BASE, PROPS, LAYOUT, EVENT }


import { defineView } from '@kmsoft/upf-core'
import { KFlowHistorySigningPropOptions, KFlowHistorySigningEventEmits } from './interface'
import KFlowHistorySigningViewModel from './KFlowHistorySigningViewModel'
import { ref } from 'vue'

export default defineView({
  name: 'KFlowHistorySigning',
  props: KFlowHistorySigningPropOptions,
  emits: KFlowHistorySigningEventEmits,
  viewModel: KFlowHistorySigningViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const activeKey = ref(['1'])
    return { activeKey }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KDistributionRecordObjGridView from './KDistributionRecordObjGrid.vue'
import KDistributionRecordObjGridViewModel from './KDistributionRecordObjGridViewModel'

const KDistributionRecordObjGrid = connect(KDistributionRecordObjGridView, KDistributionRecordObjGridViewModel)

export default withInstall(KDistributionRecordObjGrid)
export { KDistributionRecordObjGrid, KDistributionRecordObjGridView, KDistributionRecordObjGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

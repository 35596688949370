import { BaseViewModel, DataGridLoadResult, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { IColumnDefinition } from '../../panel/bom-compare-result-panel/interface'
import { KPropertySettingsFromEmitsType, KPropertySettingsFromPropType } from './interface'

/** KPropertySettingsFrom */
export default class KPropertySettingsFromViewModel extends BaseViewModel<
  KPropertySettingsFromEmitsType,
  KPropertySettingsFromPropType
> {
  /**网格组件 */
  refDataGrid = ref<KDataGridViewModel>()
  constructor(options: ViewModelOptions<KPropertySettingsFromPropType>) {
    super(options)
  }

  viewDidMount() {}

  loadData(): Promise<DataGridLoadResult> {
    return new Promise(resolve => {
      resolve([
        {
          code: 'displayUnit',
          name: '单位',
          value: 'UNIT'
        },
        {
          code: 'displayOccurrence',
          name: '位号',
          value: 'OCCURRENCE'
        },
        {
          code: 'displayPhase',
          name: '阶段',
          value: 'PHASE'
        },
        {
          code: 'displayView',
          name: '视图',
          value: 'VIEW'
        },
        {
          code: 'displayAssemblyMode',
          name: '装配模式',
          value: 'ASSEMBLYMODE'
        },
        {
          code: 'displaySource',
          name: '来源',
          value: 'SOURCE'
        }
      ])
    })
  }
  init() {
    if (this.props.selectedRows.length > 0) {
      const codes = this.props.selectedRows.map(row => row.code)
      this.refDataGrid.value?.setSelectedRow(codes, true)
    }
  }

  getSelectRows(): Array<IColumnDefinition> {
    return this.refDataGrid.value?.getSelectedRows() as Array<IColumnDefinition>
  }
  getUnSelectRows(): Array<IColumnDefinition> {
    const selectRows = this.refDataGrid.value?.getSelectedRows() as Array<IColumnDefinition>
    const rows = this.refDataGrid.value?.getRows() as Array<IColumnDefinition>
    return rows.filter(row => {
      return !selectRows.find(item => row.code == item.code)
    })
  }
}

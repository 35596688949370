import { connect, withInstall } from '@kmsoft/upf-core'
import KCommonValidationMetricsView from './KCommonValidationMetrics.vue'
import KCommonValidationMetricsViewModel from './KCommonValidationMetricsViewModel'

const KCommonValidationMetrics = connect(KCommonValidationMetricsView, KCommonValidationMetricsViewModel)

export default withInstall(KCommonValidationMetrics)
export { KCommonValidationMetrics, KCommonValidationMetricsView, KCommonValidationMetricsViewModel }

// 模板生成文件
// export * from './{{folderName}}'

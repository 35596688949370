import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b55baf4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-content-wrapper" }
const _hoisted_2 = { class: "tool-strip-content" }
const _hoisted_3 = { style: {"display":"inline-block"} }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { style: {"margin-left":"5px"} }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_folder_tree = _resolveComponent("k-folder-tree")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_data_grid_date_time_column = _resolveComponent("k-data-grid-date-time-column")!
  const _component_k_divider = _resolveComponent("k-divider")!
  const _component_k_bottom_bar = _resolveComponent("k-bottom-bar")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_split_container = _resolveComponent("k-split-container")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createBlock(_component_k_split_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_pane, {
        size: 15,
        minSize: 15,
        maxSize: 50
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_panel, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_folder_tree, {
                ref: "refFolderTree",
                "folder-id": _ctx.folderId,
                loadData: (node) => _ctx.loadTreeData(node),
                onAfterSelect: _cache[0] || (_cache[0] = (event) => _ctx.$vm.afterSelect(event))
              }, null, 8, ["folder-id", "loadData"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_k_split_pane, null, {
        default: _withCtx(() => [
          _createVNode(_component_k_split_container, { horizontal: true }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _withDirectives((_openBlock(), _createBlock(_component_k_panel, { class: "base-content" }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_k_object_tool_strip, {
                          ref: _ctx.$vm.refToolStrip,
                          items: _ctx.$vm.toolStripItems,
                          onItemClicked: _cache[1] || (_cache[1] = (event)=>_ctx.$vm.onToolStripItemClicked(event))
                        }, null, 8, ["items"])
                      ]),
                      _createVNode(_component_k_select, {
                        ref: "select",
                        style: {"width":"120px"},
                        allowClear: false,
                        value: _ctx.$vm.queryType,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.queryType) = $event)),
                        options: _ctx.queryTypeOptions,
                        onChange: _cache[3] || (_cache[3] = () => _ctx.$vm.refFolderObjGrid.value?.refresh())
                      }, null, 8, ["value", "options"])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_k_data_grid, {
                      rowModelType: _ctx.rowModelType,
                      class: "folder-obj-grid",
                      rowKey: "id",
                      name: "folderObjGrid",
                      ref: _ctx.$vm.refFolderObjGrid,
                      pagination: _ctx.pagination,
                      onRowSelected: _cache[7] || (_cache[7] = (event) => _ctx.$vm.changeToolItem(event)),
                      loadData: _ctx.$vm.loadData
                    }, {
                      columns: _withCtx(() => [
                        _createVNode(_component_k_data_grid_template_column, {
                          id: "name",
                          name: "name",
                          dataPropertyName: "name",
                          headerText: "名称",
                          align: "left"
                        }, {
                          default: _withCtx(({ row }) => [
                            _createVNode(_component_k_icon, {
                              type: _ctx.$vm.getProjectStatus(row),
                              class: _normalizeClass(_ctx.$vm.getIconClass(row)),
                              title: _ctx.$vm.getStatusOption(row)
                            }, null, 8, ["type", "class", "title"]),
                            _createElementVNode("span", {
                              class: "grid-name",
                              onClick: () => _ctx.$vm.openObjTab(row)
                            }, _toDisplayString(row.name), 9, _hoisted_4)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_k_data_grid_template_column, {
                          id: "number",
                          name: "number",
                          dataPropertyName: "number",
                          headerText: "编码",
                          align: "center"
                        }, {
                          default: _withCtx(({ cellValue,row }) => [
                            _createElementVNode("a", {
                              href: "javascript:void(0)",
                              onClick: () => _ctx.$vm.openObjTab(row)
                            }, [
                              _createElementVNode("span", _hoisted_6, _toDisplayString(cellValue), 1)
                            ], 8, _hoisted_5)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_k_data_grid_template_column, {
                          id: "targetExtensionType",
                          name: "targetExtensionType",
                          dataPropertyName: "targetExtensionType",
                          headerText: "实体类型",
                          width: "150",
                          align: "center"
                        }, {
                          default: _withCtx(({ row }) => [
                            _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(row.targetExtensionType) + _ctx.$vm.getView(row)), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_k_data_grid_text_box_column, {
                          id: "businessVersion",
                          name: "businessVersion",
                          dataPropertyName: "businessVersion",
                          headerText: "业务版本",
                          width: "80",
                          align: "center"
                        }),
                        _createVNode(_component_k_data_grid_template_column, {
                          id: "lifecycleStateCode",
                          name: "lifecycleStateCode",
                          dataPropertyName: "lifecycleStateCode",
                          headerText: "生命周期状态",
                          width: "120",
                          align: "center"
                        }, {
                          default: _withCtx(({ cellValue }) => [
                            cellValue
                              ? (_openBlock(), _createBlock(_component_k_tag, {
                                  key: 0,
                                  color: _ctx.EnumLifecycleStateColor[cellValue]
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.EnumLifecycleState[cellValue]), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color"]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_7, "--"))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_k_data_grid_combo_box_column, {
                          id: "workingState",
                          name: "workingState",
                          dataPropertyName: "workingState",
                          headerText: "工作状态",
                          options: _ctx.$vm.workingStateOptions,
                          width: "120",
                          align: "center"
                        }, null, 8, ["options"]),
                        _createVNode(_component_k_data_grid_text_box_column, {
                          id: "description",
                          name: "description",
                          dataPropertyName: "description",
                          headerText: "描述",
                          align: "center"
                        }),
                        _createVNode(_component_k_data_grid_template_column, {
                          id: "creator",
                          name: "creator",
                          dataPropertyName: "creator",
                          headerText: "创建者",
                          align: "center"
                        }, {
                          default: _withCtx(({ cellValue}) => [
                            _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_k_data_grid_date_time_column, {
                          id: "createTime",
                          name: "createTime",
                          dataPropertyName: "createTime",
                          headerText: "创建时间",
                          align: "center"
                        }),
                        _createVNode(_component_k_data_grid_template_column, {
                          id: "modifier",
                          name: "modifier",
                          dataPropertyName: "modifier",
                          headerText: "更新者",
                          align: "center"
                        }, {
                          default: _withCtx(({ cellValue}) => [
                            _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_k_data_grid_date_time_column, {
                          id: "lastUpdateTime",
                          name: "lastUpdateTime",
                          dataPropertyName: "lastUpdateTime",
                          headerText: "最后更新时间",
                          align: "center"
                        }),
                        _createVNode(_component_k_data_grid_template_column, {
                          id: "action",
                          name: "action",
                          fixed: "right",
                          align: "center",
                          headerText: "操作",
                          width: "130"
                        }, {
                          default: _withCtx(({row}) => [
                            _createElementVNode("a", {
                              class: "row-operation-item",
                              onClick: (e) => _ctx.$vm.update(row)
                            }, "编辑", 8, _hoisted_8),
                            _createVNode(_component_k_divider, { type: _ctx.vertical }, null, 8, ["type"]),
                            _createElementVNode("a", {
                              class: "row-operation-item",
                              onClick: (e) => _ctx.$vm.deleteBranch(row)
                            }, "删除此版本", 8, _hoisted_9)
                          ]),
                          _: 1
                        })
                      ]),
                      bottomBar: _withCtx(() => [
                        _createVNode(_component_k_bottom_bar, {
                          enableExcelExport: "",
                          onRefresh: _cache[4] || (_cache[4] = () => _ctx.$vm.refresh()),
                          onExcelSelectExport: _cache[5] || (_cache[5] = () => _ctx.$vm.exportSelectAsExcel()),
                          onExcelExport: _cache[6] || (_cache[6] = () => _ctx.$vm.exportAsExcel())
                        })
                      ]),
                      _: 1
                    }, 8, ["rowModelType", "pagination", "loadData"])
                  ]),
                  _: 1
                })), [
                  [_directive_focus]
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
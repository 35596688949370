import { AgentStaticObject } from '../../AgentStaticObject'

/**对包含文件或目录路径信息的 String 实例执行操作。 这些操作是以跨平台的方式执行的。*/
export class Path extends AgentStaticObject {
  protected static type: string = 'System.IO.Path'

  /**将字符串数组组合成一个路径。
   * @param paths 由路径的各部分构成的数组。
   * @returns 已组合的路径。*/
  public static async Combine(...paths: string[]): Promise<string> {
    return this.invokeStaticMethod('Combine', ...paths)
  }

  /**返回指定路径的目录信息。
   * @param path 文件或目录的路径。
   * @returns path 的目录信息；如果 path 表示根目录或为 null，则为 null。 如果 path 不包含目录信息，则返回 Empty。*/
  public static async GetDirectoryName(path: string): Promise<string> {
    return this.invokeStaticMethod('GetDirectoryName', path)
  }
}

import { ViewPropsTypeExtract, BaseViewPropOptions, BaseViewEventEmits, ViewEmitsTypeExtract } from '@kmsoft/upf-core'

// 页面事件
export const KSysTabEventEmits = {
  ...BaseViewEventEmits
}

// 属性选项
export const KSysTabPropOptions = {
  ...BaseViewPropOptions
}

// 属性类型
export type KSysTabPropType = ViewPropsTypeExtract<typeof KSysTabPropOptions>

// emit type
export type KSysTabEventEmitsType = ViewEmitsTypeExtract<typeof KSysTabEventEmits>

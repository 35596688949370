
import { defineView, KDialog, KDialogClosingEvent } from '@kmsoft/upf-core'
import _ from 'lodash'
import KFilterGridViewConfig from './config-panel'
import { KFilterGridViewConfigViewModel } from './config-panel'
import { KFilterGridViewDesignerEventEmits, KFilterGridViewDesignerPropOptions } from './interface'
import { KFilterGridViewDesignerViewModel } from './KFilterGridViewDesignerViewModel'

/**
 * 筛选网格视图定义器
 */
export default defineView({
  name: 'KFilterGridViewDesigner',
  viewModel: KFilterGridViewDesignerViewModel,
  props: KFilterGridViewDesignerPropOptions,
  emits: KFilterGridViewDesignerEventEmits,
  setup(props, { vm }) {
    /**
     * 打开示图配置弹框
     */
    const openGridDesigner = () => {
      const dataSource = _.cloneDeep(props.dataSource)
      KDialog.show({
        title: '视图配置',
        content: KFilterGridViewConfig,
        props: {
          designerMetaControl: props.designerMetaControl,
          dataSource: dataSource,
          views: _.cloneDeep(vm.stateValue.value)
        },
        size: { width: 1024, height: 608 },
        async onClosing(event: KDialogClosingEvent) {
          if (event.dialogResult === 'Confirm') {
            const viewModel = event.viewModel! as KFilterGridViewConfigViewModel
            const value = viewModel.getValue()
            vm.setValue(value)
          }
        }
      })
    }
    return () => {
      return (
        <div>
          <k-button onClick={openGridDesigner} style="width:100%">
            设置参数
          </k-button>
        </div>
      )
    }
  }
})

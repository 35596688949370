import { createVNode } from 'vue'
import { ComponentHelper, VNodeProps, defineView, exposeComponentEvents } from '@kmsoft/upf-core'
import { KObjectPropertyEditPanelPropOptions, KObjectPropertyEditPanelEventEmits } from './interface'
import KObjectPropertyEditPanelViewModel from './KObjectPropertyEditPanelViewModel'
import {
  KObjectPropertyEditPanelBaseEmitsType,
  KObjectPropertyEditPanelBaseEventEmits,
  KObjectPropertyEditPanelBasePropType
} from './controls'

export default defineView({
  name: 'KObjectPropertyEditPanel',
  props: KObjectPropertyEditPanelPropOptions,
  emits: KObjectPropertyEditPanelEventEmits,
  viewModel: KObjectPropertyEditPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /** 继承事件 */
    const exposedEvents = exposeComponentEvents(
      vm.refObjectPropertyEditPanelBase,
      Object.keys(KObjectPropertyEditPanelBaseEventEmits),
      emit
    )

    return () => {
      if (vm.isLoading.value) {
        return (
          <div class="skeleton">
            <div class="skeleton-row">
              <k-flex justify="space-between">
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
              </k-flex>
            </div>
            <div class="skeleton-row">
              <k-flex justify="space-between">
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
              </k-flex>
            </div>
            <div class="skeleton-row">
              <k-flex justify="space-between">
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
              </k-flex>
            </div>
            <div class="skeleton-row">
              <k-flex justify="space-between">
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
                <k-skeleton-input active />
              </k-flex>
            </div>
            <div class="skeleton-row">
              <k-flex justify="space-between">
                <k-skeleton active />
              </k-flex>
            </div>
          </div>
        )
      }

      /** 面板参数 */
      const panelProps: VNodeProps<KObjectPropertyEditPanelBasePropType, KObjectPropertyEditPanelBaseEmitsType> = {
        ref: vm.refObjectPropertyEditPanelBase,
        ...props,
        ...attrs,
        ...exposedEvents,
        loadData: props.load
      }
      return createVNode(ComponentHelper.renderSomeContent(vm.getPanelName()), panelProps)
    }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KClassPropertySelectorView from './KClassPropertySelector.vue'
import KClassPropertySelectorViewModel from './KClassPropertySelectorViewModel'

const KClassPropertySelector = connect(KClassPropertySelectorView, KClassPropertySelectorViewModel)

export default withInstall(KClassPropertySelector)
export { KClassPropertySelector, KClassPropertySelectorView, KClassPropertySelectorViewModel }
export * from './interface'

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

export interface BaselineCompareGridDataInter {
  rdmVersion?: number
  creator?: string
  rdmDeleteFlag?: number
  baselineMemberId?: string
  modifier?: string
  checkInState?: string
  className?: string
  version?: string
  number?: string
  createTime?: string
  name?: string
  orderIndex?: number
  iteration?: string
  rdmExtensionType?: string
  state?: null
  id?: string
  class?: string
  tenant?: null
  lastUpdateTime?: string
  versionInfo?: string
  diffType?: 'normal' | 'recordAdd' | 'propertyDiff' // 差异类型，正常 、记录新增，属性差异
  propertyDiffInfo?: PropertyDiffInfoInter[]
  [key: string]: any
}

export interface PropertyDiffInfoInter {
  propertyName: string
  text: string
}

/** 参数 **/
export const KBaselineCompareGridPropOptions = {
  ...BaseViewPropOptions,
  /** 是否是参考基线 **/
  isReference: VuePropTypes.bool().def(false),
  /** 标题 **/
  title: VuePropTypes.string().def(),
  /** 数据源 **/
  dataSource: VuePropTypes.createType<BaselineCompareGridDataInter[]>().def([])
}

/** 参数类型 **/
export type KBaselineCompareGridPropType = ViewPropsTypeExtract<typeof KBaselineCompareGridPropOptions>

/** 事件 */
export const KBaselineCompareGridEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KBaselineCompareGridEmitsType = ViewEmitsTypeExtract<typeof KBaselineCompareGridEventEmits>

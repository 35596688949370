
import { defineView } from '@kmsoft/upf-core'
import { KIssueReportEditPropOptions, KIssueReportEditEventEmits } from './interface'
import KIssueReportEditViewModel from './KIssueReportEditViewModel'

export default defineView({
  name: 'KIssueReportEdit',
  props: KIssueReportEditPropOptions,
  emits: KIssueReportEditEventEmits,
  viewModel: KIssueReportEditViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

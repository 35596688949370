import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectAddWorkflowView from './KObjectAddWorkflow.vue'
import KObjectAddWorkflowViewModel from './KObjectAddWorkflowViewModel'

const KObjectAddWorkflow = connect(KObjectAddWorkflowView, KObjectAddWorkflowViewModel)

export default withInstall(KObjectAddWorkflow)
export { KObjectAddWorkflow, KObjectAddWorkflowView, KObjectAddWorkflowViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KPersonalChargeChangeActivityGridView from './KPersonalChargeChangeActivityGrid.vue'
import KPersonalChargeChangeActivityGridViewModel from './KPersonalChargeChangeActivityGridViewModel'

const KPersonalChargeChangeActivityGrid = connect(
  KPersonalChargeChangeActivityGridView,
  KPersonalChargeChangeActivityGridViewModel
)

export default withInstall(KPersonalChargeChangeActivityGrid)
export { KPersonalChargeChangeActivityGrid, KPersonalChargeChangeActivityGridView, KPersonalChargeChangeActivityGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

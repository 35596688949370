import { connect, withInstall } from '@kmsoft/upf-core'
import KDocAnnotationListPanelView from './KDocAnnotationListPanel.vue'
import KDocAnnotationListPanelViewModel from './KDocAnnotationListPanelViewModel'

const KDocAnnotationListPanel = connect(KDocAnnotationListPanelView, KDocAnnotationListPanelViewModel)

export default withInstall(KDocAnnotationListPanel)
export { KDocAnnotationListPanel, KDocAnnotationListPanelView, KDocAnnotationListPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

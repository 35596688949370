import { DriveInfo } from '../../../../io/DriveInfo'
import { KTreeNodeDirectory } from './KTreeNodeDirectory'

/**驱动器。*/
export class KTreeNodeDrive extends KTreeNodeDirectory {
  /**使用指定的驱动器信息初始化。
   * @param drive 用于初始化的驱动器信息。*/
  constructor(drive: DriveInfo) {
    super(drive.Name)

    this.leaf = !drive.IsReady
    this.disabled = !drive.IsReady

    this.needInitializeName = true
    this.needInitializePathTypeName = true
    this.needInitializeShellIcon = true
  }

  protected async initialize(): Promise<void> {
    await super.initialize()
  }
}

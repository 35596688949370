
import { defineView } from '@kmsoft/upf-core'
import { KConfigGridComboboxTileViewModelPropOptions } from './interface'
import { EnumTabType, EnumEditType } from '../../../interface'
import ConfigGridComboboxTileViewModel from './ConfigGridComboboxTileViewModel'

export default defineView({
  name: 'ConfigGridComboboxTile',
  props: KConfigGridComboboxTileViewModelPropOptions,
  viewModel: ConfigGridComboboxTileViewModel,
  setup() {
    return {
      EnumEditType,
      EnumTabType
    }
  }
})

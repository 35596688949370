import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KBasePropertyEditorPropOptions } from '@kmsoft/ebf-common'

/** 参数 **/
export const KChangePromoteActivityPropOptions = {
  ...BaseViewPropOptions,
  ...KBasePropertyEditorPropOptions,
  changeOrderId: VuePropTypes.string().def(),
  formValue: VuePropTypes.createType<any>().def(),
  changeBeforeData: VuePropTypes.createType<any[]>().def(),
  changeAfterData: VuePropTypes.createType<any[]>().def(),
  extAttrs: VuePropTypes.createType<Record<string, any>[]>().def(),
  /** 是否从结构管理bom树上创建 */
  inBomCreate: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KChangePromoteActivityPropType = ViewPropsTypeExtract<typeof KChangePromoteActivityPropOptions>

/** 事件 */
export const KChangePromoteActivityEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangePromoteActivityEmitsType = ViewEmitsTypeExtract<typeof KChangePromoteActivityEventEmits>

import { KDesignerControl } from '../../../types'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  KContainer,
  KControl,
  LayoutRenderElement,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 拖拽时的节点类型 */
export interface DragElement {
  _underlying_vm_: KContainer | KControl
}

/** 事件 **/
export const KToolStripContainerEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 布局元素选中事件
   * @param element 选中元素的布局信息
   */
  elementSelected: (element: LayoutRenderElement) => true,
  /**
   * 元素删除事件
   * @param elementName 元素名
   */
  elementDelete: (element: KContainer | KControl) => true,
  /**
   * 元素拖入事件
   * @param elementName 元素名
   */
  elementDragIn: (elementName: string) => true
}

/** 参数 **/
export const KToolStripContainerPropOptions = {
  ...BaseViewPropOptions,
  /** 元素集合 */
  elements: VuePropTypes.createType<Array<KContainer | KControl>>().def([]),
  /** 选中的元素名称（唯一标识） */
  selectedElement: VuePropTypes.createType<LayoutRenderElement>().def(),
  /** 控件类型定义 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def([])
}

/** 参数类型 **/
export type KToolStripContainerPropType = ViewPropsTypeExtract<typeof KToolStripContainerPropOptions>
/** 事件协议*/
export type KToolStripContainerEventEmitsType = ViewEmitsTypeExtract<typeof KToolStripContainerEventEmits>

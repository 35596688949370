import { ref, watch } from 'vue'
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { QuickSearchBarTestEventEmitsType, QuickSearchBarTestPropType } from './interface'

/** QuickSearchBarTest 视图模型 */
export default class QuickSearchBarTestViewModel extends BaseViewModel<
  QuickSearchBarTestEventEmitsType,
  QuickSearchBarTestPropType
> {
  constructor(options: ViewModelOptions<QuickSearchBarTestPropType>) {
    super(options)
  }
  /** 加载完成函数 */
  viewDidMount() {}
}

import { ref } from 'vue'
import { BaseViewModel, ViewModelOptions, KNotification } from '@kmsoft/upf-core'
import { KIssueReportRelationshipEmitsType, KIssueReportRelationshipPropType } from './interface'
import { Api, EnumRequestCode, KObjectClassGridViewModel, ObjBusinessParam } from '@kmsoft/ebf-common'


/** KIssueReportRelationship */
export default class KIssueReportRelationshipViewModel extends BaseViewModel<KIssueReportRelationshipEmitsType, KIssueReportRelationshipPropType> {

  refChangeRequestGrid = ref<KObjectClassGridViewModel>()
  objParam = ref<ObjBusinessParam>()
  activeKey = ref<Array<string>>(['1'])

  constructor(options: ViewModelOptions<KIssueReportRelationshipPropType>) {
    super(options)
    this.objParam.value = options.props.objParam
  }

  viewDidMount() {}

  titleClick(key: string) {
    if (!this.activeKey.value.includes(key)) {
      this.activeKey.value.push(key)
    }
  }

  loadChangeRequestData() {
    return this.listChangeRequest()
  }

  /**
   * 查询问题报告-变更请求清单
   *
   * @returns
   */
  async listChangeRequest() {
    const reqParam = {
      data: [
        this.objParam.value?.id
      ]
    }
    const result = await Api.post('change', 'ChangeIssue', 'getLinks', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return Promise.resolve({
        rows: result.data?.plmLinkTargets || [],
        total: result.data?.plmLinkTargets?.length
      })
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }
  
}
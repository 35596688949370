import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../object-tool-strip'
import { EnumToolStripItemKeys } from '../types'

/**
 * 获取文件夹工具栏
 * @returns 文件夹工具栏
 */
export const getSearchGridObjectOperationToolStripItems = (): Array<ObjectToolStripItem> => {
  return baseToolItem
}

/**
 * 基础工具栏
 */
export const baseToolItem = [
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OBJ,
    title: '对象',
    icon: 'DropObjLife',
    visible: true,
    compType: EnumToolStripCompType.CONTEXT_MENU,
    supportedObjNumMode: EnumSupportObjectNumMode.Any,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_CAD,
        title: '添加至CAD工作区',
        icon: 'plus-circle',
        supportedObjClsTempletID: ['Part', 'Document'],
        visible: false,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
        shortcutKey: 'alt+f'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS,
        title: '另存',
        supportedObjClsTempletID: ['Part', 'Document'],
        icon: 'ObjSaveAs',
        visible: false,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        shortcutKey: 'alt+s'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH,
        title: '发布',
        supportedObjClsTempletID: ['Part', 'Document', 'OrdinaryDocument'],
        icon: 'publish',
        visible: false,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        shortcutKey: 'alt+p'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE,
        title: '结构管理',
        icon: 'file-protect',
        visible: false,
        supportedObjClsTempletID: ['Part', 'Document'],
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'ctrl+p',
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_OBJ_GROUP_OPERATION,
    title: '版本',
    icon: 'vertical-layout',
    visible: false,
    supportedObjClsTempletID: ['Part', 'Document'],
    compType: EnumToolStripCompType.CONTEXT_MENU,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT,
        title: '检出(O)',
        icon: 'DocCheckOut',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+o',
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT,
        title: '取消检出',
        icon: 'DocCancelCheckOut',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN,
        title: '检入(I)',
        icon: 'DocCheckIn',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+i',
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE,
        title: '修订',
        icon: 'tag',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_GRID_OPERATION,
    title: '列表',
    icon: 'DropGridOp',
    visible: true,
    compType: EnumToolStripCompType.CONTEXT_MENU,
    supportedObjNumMode: EnumSupportObjectNumMode.Any,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
        title: '刷新',
        icon: 'sync',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'f5',
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD,
        title: '零部件导入模板下载',
        icon: 'download',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_FILE_OPERATION,
    title: '文件',
    icon: 'profile',
    visible: false,
    supportedObjClsTempletID: ['Part', 'Document'],
    compType: EnumToolStripCompType.CONTEXT_MENU,
    supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC,
        title: '浏览',
        icon: 'eye',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC,
        title: '下载',
        icon: 'download',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_FILE,
        title: '编辑文件',
        icon: 'edit',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE,
        title: '替换文件',
        icon: 'file-storage',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        compType: EnumToolStripCompType.CONTEXT_MENU,
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        items: [
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_FROM_LOCAL_FILE,
            title: '从本地替换',
            icon: 'DocAppPrint',
            supportedObjClsTempletID: ['Document'],
            visible: true,
            compType: EnumToolStripCompType.CONTEXT_MENU
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_FILE_REPLACE_NEW,
            title: '从模板替换',
            icon: 'DocSourcePrint',
            // supportedObjClsTempletID: ['Document'],
            visible: false,
            compType: EnumToolStripCompType.CONTEXT_MENU
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_QUERY,
            title: '从已有文档替换',
            icon: 'DocSourcePrint',
            // supportedObjClsTempletID: ['Document'],
            visible: false,
            compType: EnumToolStripCompType.CONTEXT_MENU
          }
        ]
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_HISTORY_VERSION,
        title: '文件历史版本',
        icon: 'solution',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        compType: EnumToolStripCompType.BUTTON
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_REMARK,
        title: '批注',
        icon: 'read',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        compType: EnumToolStripCompType.BUTTON
        // shortcutKey: 'alt+s'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK,
        title: '查看批注',
        icon: 'read',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        compType: EnumToolStripCompType.BUTTON
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK_HISTORY,
        title: '查看批注历史版本',
        icon: 'container',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        compType: EnumToolStripCompType.BUTTON
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_GROUP_WORK_FLOW,
    title: '流程',
    icon: 'ViewFlowObj',
    visible: false,
    supportedObjClsTempletID: [
      'Part',
      'Document',
      'ManagedBaseline',
      'ChangeActivity',
      'ChangeIssue',
      'ChangeOrder',
      'ChangeRequest'
    ],
    compType: EnumToolStripCompType.CONTEXT_MENU,
    supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
        title: '加入流程',
        icon: 'AddToFlow',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW,
        title: '查看流程',
        icon: 'ViewFlowObj',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_USING_STATISTICS,
    title: '使用统计',
    icon: 'table',
    visible: false,
    supportedObjClsTempletID: ['Part'],
    compType: EnumToolStripCompType.BUTTON,
    supportedObjNumMode: EnumSupportObjectNumMode.Single
  }
] as Array<ObjectToolStripItem>

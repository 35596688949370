import { connect, withInstall } from '@kmsoft/upf-core'
import KClassUiConfigView from './KClassUiConfig.vue'
import KClassUiConfigViewModel from './KClassUiConfigViewModel'

const KClassUiConfig = connect(KClassUiConfigView, KClassUiConfigViewModel)

export default withInstall(KClassUiConfig)
export { KClassUiConfig, KClassUiConfigView, KClassUiConfigViewModel }

// 模板生成文件
// export * from './{{folderName}}'

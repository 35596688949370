import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = {
  class: "full",
  ref: "refPlanEditPanel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_property_edit_panel = _resolveComponent("k-object-property-edit-panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_object_property_edit_panel, {
        ref: _ctx.$vm.refObjectProperty,
        readonly: true,
        objParam: _ctx.$vm.objParam,
        load: () => _ctx.$vm.loadData(),
        toolStripItems: _ctx.$vm.toolStripItems,
        toolStripOptions: _ctx.$vm.toolStripOptions,
        extendedAttributes: "",
        extendedPosition: 2,
        onToolStripItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onToolStripItemClicked(event)),
        onToolStripItemChange: _cache[1] || (_cache[1] = (event)=>_ctx.$vm.onToolStripItemChange(event)),
        onLoaded: _cache[2] || (_cache[2] = () => _ctx.$vm.loaded())
      }, null, 8, ["objParam", "load", "toolStripItems", "toolStripOptions"])
    ], 512)
  ]))
}
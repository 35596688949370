import { AgentInstanceObject } from '../../AgentInstanceObject'

/**表示字符编码。*/
export class Encoding extends AgentInstanceObject {
  protected static type: string = 'System.Text.Encoding,System.Text.Encoding.Extensions'

  /**获取此 .NET 实现的默认编码。*/
  public static async Default(): Promise<Encoding> {
    return this.getOrAddToMap('65db5294-ec71-4225-965f-bc37f9a675f7', () => this.staticPropertyAndWrap(this, 'Default'))
  }
}

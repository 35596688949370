/**
 * Determines whether the specified callback function returns true for any element of an array.
 * @param array the array
 * @param predicate A function that accepts up to three arguments. The some method calls
 * the predicate function for each element in the array until the predicate returns a value
 * which is coercible to the Boolean value true, or until the end of the array.
 */
export async function someAsync<T>(
  array: T[],
  predicate: (value: T, index: number, array: readonly T[]) => Promise<unknown>
): Promise<boolean> {
  for (let i = 0; i < array.length; i++) if (await predicate(array[i], i, array)) return true
  return false
}

/**
 * Returns the elements of an array that meet the condition specified in a callback function.
 * @param array the array
 * @param predicate A function that accepts up to three arguments. The filter method calls the predicate function one time for each element in the array.
 */
export async function filterAsync<T>(
  array: T[],
  predicate: (value: T, index: number, array: readonly T[]) => Promise<unknown>
): Promise<T[]> {
  const result: T[] = []
  for (let i = 0; i < array.length; i++) if (await predicate(array[i], i, array)) result.push(array[i])
  return result
}

/**
 * Returns the value of the first element in the array where predicate is true, and undefined
 * otherwise.
 * @param array the array
 * @param predicate find calls predicate once for each element of the array, in ascending
 * order, until it finds one where predicate returns true. If such an element is found, find
 * immediately returns that element value. Otherwise, find returns undefined.
 */
export async function findAsync<T>(
  array: T[],
  predicate: (value: T, index: number, array: readonly T[]) => Promise<unknown>
): Promise<T | undefined> {
  for (let i = 0; i < array.length; i++) if (await predicate(array[i], i, array)) return array[i]
}

/**
 * Calls a defined callback function on each element of an array, and returns an array that contains the results.
 * @param array the array
 * @param callbackfn A function that accepts up to three arguments. The map method calls the callbackfn function one time for each element in the array.
 */
export async function mapAsync<T, U>(array: T[], callbackfn: (value: T, index: number, array: T[]) => Promise<U>): Promise<U[]> {
  const result: U[] = []
  for (let i = 0; i < array.length; i++) result.push(await callbackfn(array[i], i, array))
  return result
}

import { AppContext, AppRunner, EnumApplicationRunnerExecutionOpportunity } from '@kmsoft/upf-core'
import { focusEventClientSrv } from '../client-srv'

export default class EbfStartRunner implements AppRunner {
  opportunity(): EnumApplicationRunnerExecutionOpportunity {
    return EnumApplicationRunnerExecutionOpportunity.AFTER_MOUNT
  }
  run(): Promise<void> {
    const App = AppContext.current.getApplication().app
    focusEventClientSrv.init(App)
    return Promise.resolve(void 0)
  }
}

import { FileStream } from './FileStream'

/**包含用于控制其他 {@link FileStream} 对象对同一文件可以具有的访问类型的常数。*/
export enum FileShare {
  /**谢绝共享当前文件。 文件关闭前，打开该文件的任何请求（由此进程或另一进程发出的请求）都将失败。*/
  None = 0,
  /**允许随后打开文件读取。 如果未指定此标志，则文件关闭前，任何打开该文件以进行读取的请求（由此进程或另一进程发出的请求）都将失败。 但是，即使指定了此标志，仍可能需要附加权限才能够访问该文件。*/
  Read = 1,
  /**允许随后打开文件写入。 如果未指定此标志，则文件关闭前，任何打开该文件以进行写入的请求（由此进程或另一进过程发出的请求）都将失败。 但是，即使指定了此标志，仍可能需要附加权限才能够访问该文件。*/
  Write = 2,
  /**允许随后打开文件读取或写入。 如果未指定此标志，则文件关闭前，任何打开该文件以进行读取或写入的请求（由此进程或另一进程发出）都将失败。 但是，即使指定了此标志，仍可能需要附加权限才能够访问该文件。*/
  ReadWrite = Read | Write,
  /**允许随后删除文件。*/
  Delete = 4,
  /**使文件句柄可由子进程继承。 Win32 不直接支持此功能。*/
  Inheritable = 16
}

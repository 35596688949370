import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createVNode(_component_k_tool_strip, { name: "bomCompareResultToolStrip" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_tool_strip_button_item, {
            name: "export",
            title: "导出excel",
            onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.export())
          }),
          _createVNode(_component_k_tool_strip_button_item, {
            name: "refresh",
            title: "刷新",
            onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.refresh())
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        ref: _ctx.$vm.refDataGrid,
        rowKey: "masterIdFullPath",
        loadData: _ctx.$vm.loadData,
        isMultipleSelection: "",
        rowModelType: _ctx.rowModelType,
        selectFirstRow: true,
        columns: _ctx.$vm.columns,
        autoSizeColumnsMode: _ctx.autoSizeColumnsMode,
        onRowSelected: _cache[2] || (_cache[2] = (event)=>_ctx.$vm.setTreeSelect(event))
      }, null, 8, ["loadData", "rowModelType", "columns", "autoSizeColumnsMode"])
    ]),
    _: 1
  }))
}
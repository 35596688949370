import { connect, withInstall } from '@kmsoft/upf-core'
import KDocFileListView from './KDocFileList.vue'
import KDocFileListViewModel from './KDocFileListViewModel'

const KDocFileList = connect(KDocFileListView, KDocFileListViewModel)

export default withInstall(KDocFileList)
export { KDocFileList, KDocFileListView, KDocFileListViewModel }
export * from './interface'

// 模板生成文件
// export * from './{{folderName}}'


import {
  EnumDataGridRowModelType,
  IKTreeNode,
  ToolStripItemClickedEventArgs,
  TreeViewSelectEventArgs,
  defineView
} from '@kmsoft/upf-core'
import { KPersonalWorkflowTasksPropOptions, KPersonalWorkflowTasksEventEmits } from './interface'
import KPersonalWorkflowTasksViewModel from './KPersonalWorkflowTasksViewModel'
import { onActivated, onMounted } from 'vue'
import { clsCodeMap } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KPersonalWorkflowTasks',
  props: KPersonalWorkflowTasksPropOptions,
  emits: KPersonalWorkflowTasksEventEmits,
  viewModel: KPersonalWorkflowTasksViewModel,
  setup() {
    onActivated(() => {
      console.log('onActivated')
    })

    onMounted(() => {
      console.log('onMounted')
    })

    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      pagination: {
        pageIndex: 1,
        pageSize: 50
      },
      clsCodeMap
    }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KIssueReportRelationshipView from './KChangeRequestRelationship.vue'
import KIssueReportRelationshipViewModel from './KChangeRequestRelationshipViewModel'

const KChangeRequestRelationship = connect(KIssueReportRelationshipView, KIssueReportRelationshipViewModel)

export default withInstall(KChangeRequestRelationship)
export { KChangeRequestRelationship, KIssueReportRelationshipView, KIssueReportRelationshipViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView } from '@kmsoft/upf-core'
import { KClassTreeDemoPropOptions, KClassTreeDemoEventEmits } from './interface'
import KClassTreeDemoViewModel from './KClassTreeDemoViewModel'

export default defineView({
  name: 'KClassTreeDemo',
  props: KClassTreeDemoPropOptions,
  emits: KClassTreeDemoEventEmits,
  viewModel: KClassTreeDemoViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

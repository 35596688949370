
import { defineView } from '@kmsoft/upf-core'
import { KObjectSearcherPropOptions, KObjectSearcherEventEmits, EnumObjectSearcherTab } from './interface'
import KObjectSearcherViewModel from './KObjectSearcherViewModel'
import { EnumObjectSearcherMode, EnumSearchRegion } from './interface'
import { computed } from 'vue'
import { KCommonSearcher, KQuickSearcher } from './tabs'

export default defineView({
  name: 'KObjectSearcher',
  props: KObjectSearcherPropOptions,
  emits: KObjectSearcherEventEmits,
  viewModel: KObjectSearcherViewModel,
  components: { KCommonSearcher, KQuickSearcher },
  setup(props, { emit, slots, attrs, vm }) {
    /** 对象类树属性 */
    const objectClassTreeProps = computed(() => {
      return {
        defaultSelectClsCode: props.modelCode,
        classTemplates: props.classTemplates,
        showObjClsCodes: props.showObjClsCodes,
        hideObjClsCodes: props.hideObjClsCodes
      }
    })

    return { EnumObjectSearcherMode, EnumSearchRegion, EnumObjectSearcherTab, objectClassTreeProps }
  }
})

import { KTreeNodeFileSystemInfo } from './KTreeNodeFileSystemInfo'
import { KTreeNodeComputer } from './KTreeNodeComputer'

export class KTreeNodeRoot extends KTreeNodeFileSystemInfo {
  constructor() {
    super('根')
  }

  protected async generateChildren(populateType: number, filter: string): Promise<KTreeNodeFileSystemInfo[]> {
    return [new KTreeNodeComputer()]
  }
}

import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KBaselineCreateFormEmitsType, KBaselineCreateFormPropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api, EnumRequestCode, ObjBusinessBase } from '@kmsoft/ebf-common'

export default class KBaselineCreateFormViewModel extends BaseViewModel<
  KBaselineCreateFormEmitsType,
  KBaselineCreateFormPropType
> {
  rdmExtensionType = ref<any>()

  // 表单响应式数据
  formData = ref<any>({
    number: '',
    name: '',
    lifecycleStateDesc: '',
    primaryObject: {
      id: '',
      clazz: '',
      name: '',
      number: '',
      version: '',
      iteration: ''
    },
    strict: '',
    isLocked: '',
    folder: {
      id: ''
    },
    baselineMemberViewDTOS: []
  })
  // 通用布局
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()

  constructor(options: ViewModelOptions<KBaselineCreateFormPropType>) {
    super(options)
    // 获取外部传值
    // this.formData.value = this.props.formValue
  }

  viewDidMount() {
    // 默认值
    this.formData.value.lifecycleStateDesc = '工作中'

    // 文件夹赋值
    if (this.props.formValue?.folder?.id) {
      this.formData.value.folder.id = this.props.formValue.folder?.id
    }

    // 如果是通过零部件创建基线，需要设置主要对象及表格数据
    if (Object.keys(this.props.partParam).length > 0) {
      this.getPartInfo()
      this.getPrimaryInfo(this.props.partParam.partReference)
    }
  }

  getPrimaryInfo(objParam: { id: string; clazz: string } | undefined){
    Api.post('part', 'Part', 'get', { data: [objParam?.id] }).then(result => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        if (result.data) {
          const findPrimaryObject = result.data
          // 主要对象赋值
          this.formData.value.primaryObject = {
            id: findPrimaryObject.id,
            clazz: findPrimaryObject.rdmExtensionType,
            rdmExtensionType: findPrimaryObject.rdmExtensionType,
            name: findPrimaryObject.name,
            number: findPrimaryObject.number,
            version: findPrimaryObject.version,
            iteration: findPrimaryObject.iteration
          }
          //文件夹赋值
          this.formData.value.folder.id = findPrimaryObject.folder.id
        }
      }
    })
  }

  getPartInfo() {
    // 获取 collectedParts
    const collectedParts = this.props.collectedParts?.map((part: any) => {
      return {
        ...part,
        versionInfo: `${part.version}.${part.iteration}`
      };
    });

    const relatedPartReferences = this.props.collectedParts?.map((part: any) => {
      return {
        id: part.id,
        clazz: part.rdmExtensionType
      };
    });

    this.props.partParam.relatedPartReferences = relatedPartReferences || []

    Api.post('part', 'Part', 'listPartRelatedObjects', { data: [this.props.partParam] }).then(result => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        // 列表赋值
        this.formData.value.baselineMemberViewDTOS = [  
          ...(result.data || []).map((item: any) => {  
            item.versionInfo = `${item.version}.${item.iteration}`;  
            return item;  
          }),  
        ];
      }
    })
  }

  /**
   * 设置数据源
   * @param value 表单的值
   * @returns
   */
  public setValue(value: any) {
    this.formData.value = value
  }
  /**
   * 获取表单的值
   * @returns 表单的值
   */
  public getValue() {
    let createObject = this.refObjectCreatePanel.value?.getValue()

    // 类型,通过文件夹创建取props中的数据，通过part创建取rdmExtensionType中的数据
    const rdmExtensionType = this.props.showType ? this.rdmExtensionType.value : this.props.formValue.classCode
    createObject = { ...createObject, rdmExtensionType, className: rdmExtensionType } as any

    // 处理主要对象
    const primaryObject = {
      id: createObject!.primaryObject?.id ?? '',
      clazz: createObject!.primaryObject?.rdmExtensionType ?? ''
    }

    // 处理表格
    const baselineMemberViewDTOS = (createObject!.baselineMemberViewDTOS || []).reduce((arr: any[], cur: any, index: number) => {
      arr.push({
        branchId: cur.branch?.id ?? null,
        target: {
          id: cur.id ?? '',
          rdmExtensionType: cur.rdmExtensionType ?? ''
        },
        orderIndex: index
      })
      return arr
    }, [])

    createObject!.primaryObject = primaryObject
    createObject!.baselineMemberViewDTOS = baselineMemberViewDTOS
    // 后台不需要传lifecycleState
    Reflect.deleteProperty(createObject as any, 'lifecycleStateDesc')

    return createObject
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  async save() {
    const params = {
      data: [
        {
          ...this.getValue()
          // description: '',
          // lockNode: ''
        }
      ]
    }

    return await Api.post('baseline', 'ManagedBaseline', 'create', params)
  }
}

import { withInstall, connect } from '@kmsoft/upf-core'
import KObjectClassGridView from './KObjectClassGrid'
import { KObjectClassGridViewModel } from './KObjectClassGridViewModel'
import { KObjectClassGridBase } from './controls'

const KObjectClassGrid = connect(KObjectClassGridView, KObjectClassGridViewModel)

export default withInstall(KObjectClassGrid, { KObjectClassGridBase: KObjectClassGridBase })
export { KObjectClassGrid, KObjectClassGridView, KObjectClassGridViewModel }
export * from './interface'
export * from './controls'

import { EnumOperatorType } from '../../client-srv'
import { Doc } from '../../beans/Doc'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KSelectDocPropOptions = {
  ...BaseViewPropOptions,
  /** 文件路径 */
  filePath: VuePropTypes.string().def(),
  /** 文档属性 */
  doc: VuePropTypes.createType<Doc>().def(),
  /**操作类型 */
  type: VuePropTypes.createType<EnumOperatorType>().def()
}

/** 参数类型 **/
export type KSelectDocPropType = ViewPropsTypeExtract<typeof KSelectDocPropOptions>

/** 事件 */
export const KSelectDocEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KSelectDocEmitsType = ViewEmitsTypeExtract<typeof KSelectDocEventEmits>

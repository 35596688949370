import {
  ViewPropsTypeExtract,
  ViewEmitsTypeExtract,
  KDataGridEventEmits,
  KSchema,
  VuePropTypes,
  KDataGridPropOptions,
  EnumChangeFlashType,
  EnumDataGridRowModelType,
  KGridPagination,
  ToolStripItemClickedEventArgs,
  ToolStripItemChangeEventArgs,
  ToolStripItemInputEventArgs,
  KDataGridColumn,
  KDataGridRowData,
  IQuery,
  Page,
  KGridColumn,
  IKDataGridCellParams
} from '@kmsoft/upf-core'
import { KObjectClassGridBaseViewModel } from './KObjectClassGridBaseViewModel'
import { MetaProperty, ObjBusinessParam, IObjectPageResult, QueryCondition } from '../../../client-srv'
import { EnumToolStripEnvironment, ObjectToolStripItem } from '../../object-tool-strip'

//#region 类型定义
/** 查询参数 */
export type ObjectClassGirdBaseQueryParams = Omit<IQuery, 'filters'> & {
  /** 过滤条件 */
  filter?: QueryCondition
}

/** 网格数据 */
export type ObjectClassGirdBaseDataResult = IObjectPageResult | Array<KDataGridRowData>

/** 加载函数 */
export type ObjectClassGirdBaseLoadDataFunction = (
  params: ObjectClassGirdBaseQueryParams
) => Promise<ObjectClassGirdBaseDataResult> | ObjectClassGirdBaseDataResult

/** 自定义编辑器选择器 */
export type ObjectClassGirdBaseColumnResolver = (column: KGridColumn) => KDataGridColumn | undefined

export type ObjectClassGridBaseResolveCustomFilterParams = {
  field: MetaProperty
  methods: KObjectClassGridBaseViewModel
}
//#endregion

/**
 * 对象基础网格事件
 */
export const KObjectClassGridBaseEventEmits = {
  ...KDataGridEventEmits,
  /** 布局改变事件 */
  schemaChange: (schema: KSchema) => true,
  /** 导出列改变事件 */
  exportFieldsChange: (fields: Array<MetaProperty>) => true,
  /** 数据改变事件 【删除，新增，创建】 */
  dataChanged: () => true,
  /**
   * 工具栏点击事件
   * @param event
   * @returns
   */
  toolStripItemClicked: (event: ToolStripItemClickedEventArgs) => true,
  /**
   * 工具条下拉控件选择项改变事件
   * @param event
   * @returns
   */
  toolStripItemChange: (event: ToolStripItemChangeEventArgs) => true,
  /**
   * 输入事件
   * @param event
   * @returns
   */
  toolStripItemInput: (event: ToolStripItemInputEventArgs) => true,
  /**
   * 点击前事件
   * @param event
   * @returns
   */
  itemClickedBefore: (event: ToolStripItemClickedEventArgs) => true,
  /**
   * 调整顺序后时间
   * @returns
   */
  sortRowsAfter: (event: Record<string, any>[]) => true,
  /**操作项点击事件 */
  operationClick: (event: OperationOptionClickEvent) => true
}

/** 对象基础网格事件类型  **/
export type KObjectClassGridBaseEventEmitsType = ViewEmitsTypeExtract<typeof KObjectClassGridBaseEventEmits>

/**操作项 */
export type OperationOption = {
  key: string
  title: string
}
/**操作项事件 */
export type OperationOptionClickEvent = {
  key: string
  params: IKDataGridCellParams
}

/**
 * 对象基础网格属性选项
 */
export const KObjectClassGridBasePropOptions = {
  /** 基础网格定义 */
  ...KDataGridPropOptions,
  /** 布局Id */
  schemaId: VuePropTypes.string().def(),

  /** 主键列名 */
  rowKey: VuePropTypes.string().def('id'),
  /** 默认选中首行 */
  selectFirstRow: VuePropTypes.bool().def(true),
  /** 默认支持分页 */
  pagination: VuePropTypes.createType<false | Page>().def({
    pageIndex: 1,
    pageSize: 100
  }),
  /** 是否包含子对象类 默认值: false */
  includeChildren: VuePropTypes.bool().def(false),
  /** 行业务对象参数获取器 */
  rowObjBussinessParamPredicate: VuePropTypes.func<(row: Record<string, any>) => ObjBusinessParam>().def(),

  //#region  列定义
  /** 原始列（该属性和classId属性互斥） */
  fields: VuePropTypes.array<MetaProperty>().def([]),
  /** 是否显示文件标记列 */
  showDocFileFlagColumn: VuePropTypes.bool().def(false),
  /** 网格列组件工厂 */
  columnResolver: VuePropTypes.func<ObjectClassGirdBaseColumnResolver>().def(),
  //#endregion

  /** 数据来源,默认服务端加载 */
  rowModelType: VuePropTypes.string().def(EnumDataGridRowModelType.SERVER_SIDE),
  /** 开启闪烁 */
  changeFlashMode: VuePropTypes.createType<EnumChangeFlashType>().def(EnumChangeFlashType.NONE),
  /** 过滤器 */
  filter: VuePropTypes.createType<QueryCondition>().def(),
  /** 网格数据加载器 */
  loadData: VuePropTypes.func<ObjectClassGirdBaseLoadDataFunction>().def(),
  /** 是否启用双击对象打开 默认值: true */
  enableDbClickOpenObj: VuePropTypes.bool().def(true),

  //#region 工具栏
  /** 是否显示右键菜单 */
  showContextMenu: VuePropTypes.bool().def(true),
  /** 是否显示网格工具条 */
  showToolStrip: VuePropTypes.bool().def(true),
  /** 是否显示工具条按钮文字 */
  hideToolStripItemText: VuePropTypes.bool().def(false),
  /** 工具条使用环境 */
  toolStripEnvironment: VuePropTypes.createType<EnumToolStripEnvironment>().def(EnumToolStripEnvironment.ObjClassGrid),
  /**操作列 */
  operatorColumn: VuePropTypes.createType<Array<OperationOption>>().def([]),
  /**
   * 是否显示操作列
   */
  showOperatorColumn: VuePropTypes.bool().def(false)
  //#endregion
}

/**
 *  对象基础网格属性类型
 */
export type KObjectClassGridBasePropType = ViewPropsTypeExtract<typeof KObjectClassGridBasePropOptions>

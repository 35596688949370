import { connect, withInstall } from '@kmsoft/upf-core'
import KFlowFormView from './KFlowForm.vue'
import KFlowFormViewModel from './KFlowFormViewModel'

const KFlowForm = connect(KFlowFormView, KFlowFormViewModel)

export default withInstall(KFlowForm)
export { KFlowForm, KFlowFormView, KFlowFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

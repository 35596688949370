import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_button = _resolveComponent("k-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_k_button, {
      onClick: _ctx.$vm.openGridDesigner,
      block: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("设置参数")
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}
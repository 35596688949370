import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../object-tool-strip'
import { EnumToolStripItemKeys } from '../types'

/**
 * 获取文件夹工具栏
 * @returns 文件夹工具栏
 */
export const getObjClassLayoutDefinitionToolStripItems = (): Array<ObjectToolStripItem> => {
  return baseToolItem
}

/**
 * 基础工具栏
 */
export const baseToolItem = [
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
    title: '创建',
    icon: 'NewObj',
    visible: true,
    compType: EnumToolStripCompType.BUTTON,
    shortcutKey: 'alt+n',
    supportedObjNumMode: EnumSupportObjectNumMode.Any
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT,
    title: '编辑',
    icon: 'common_edit_cover',
    visible: false,
    compType: EnumToolStripCompType.BUTTON,
    supportedObjNumMode: EnumSupportObjectNumMode.Single,
    shortcutKey: 'alt+e'
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
    title: '删除',
    icon: 'DeleteObj',
    visible: false,
    compType: EnumToolStripCompType.BUTTON,
    supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
    shortcutKey: 'alt+r'
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
    title: '刷新',
    icon: 'sync',
    visible: true,
    compType: EnumToolStripCompType.BUTTON,
    shortcutKey: 'f5',
    supportedObjNumMode: EnumSupportObjectNumMode.Any
  }
] as Array<ObjectToolStripItem>

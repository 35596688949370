import { Component, ComputedOptions, MethodOptions } from 'vue'
import { IFieldEditorResolver, KObjectClassGridBaseColumn } from '../../base'
import { EnumDataType, EnumPropertyEditorType, MetaProperty } from '../../../../client-srv'
import { KDataGridUserSelectorColumn } from './KDataGridUserSelectorColumn'
import { KUserSelector } from './index'

export class UserEditorResolver extends IFieldEditorResolver {
  static getColumn(field: MetaProperty): KObjectClassGridBaseColumn | null {
    if (field.type === EnumDataType.USER) {
      const refColumnInstance = new KDataGridUserSelectorColumn()
      return refColumnInstance
    }
    return null
  }

  static getEditor(field: MetaProperty): string | (() => Component<any, any, any, ComputedOptions, MethodOptions>) | null {
    if (field.type === EnumDataType.USER) {
      return () => KUserSelector
    }
    return null
  }
}

export const userEditorResolver = new UserEditorResolver()

import { ObjBusinessBase, ObjectLoadDataProvider } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  DateTime
} from '@kmsoft/upf-core'

export interface DocAnnotationGridDataInter {
  id: string
  WorkflowName?: string
  rounds?: string
  version?: string
  taskName?: string
  creator?: string
  creatTime?: DateTime
  fileId?: string
}

/** 参数 **/
export const KDocAnnotationListPanelPropOptions = {
  ...BaseViewPropOptions,
  dataSource: VuePropTypes.createType<DocAnnotationGridDataInter[]>().def([])
}

/** 参数类型 **/
export type KDocAnnotationListPanelPropType = ViewPropsTypeExtract<typeof KDocAnnotationListPanelPropOptions>

/** 事件 */
export const KDocAnnotationListPanelEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDocAnnotationListPanelEmitsType = ViewEmitsTypeExtract<typeof KDocAnnotationListPanelEventEmits>

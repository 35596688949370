import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KRequirementChangeEditPanelPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KRequirementChangeEditPanelPropType = ViewPropsTypeExtract<typeof KRequirementChangeEditPanelPropOptions>

/** 事件 */
export const KRequirementChangeEditPanelEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KRequirementChangeEditPanelEmitsType = ViewEmitsTypeExtract<typeof KRequirementChangeEditPanelEventEmits>

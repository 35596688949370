
import { defineView } from '@kmsoft/upf-core'
import { UserSelectorTestPropOptions, UserSelectorTestEventEmits } from './interface'
import { UserSelectorTestViewModel } from './UserSelectorTestViewModel'

export default defineView({
  name: 'UserSelectorTest',
  props: UserSelectorTestPropOptions,
  emits: UserSelectorTestEventEmits,
  viewModel: UserSelectorTestViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { Api } from '../../client-srv'
import { KContainer, KNotification } from '@kmsoft/upf-core'
import { KObjectPropertyPanelPropType } from './interface'

/**
 * 获取分类属性schema
 */
export const categoryAttributesSchema = async (id: string, props: KObjectPropertyPanelPropType): Promise<KContainer | null> => {
  const result = await Api.post('common', 'ClassificationNode', 'getCategoryNodeInfo', {
    data: [id]
  })

  // 接口调用失败清除分类属性
  if (!result?.success) {
    KNotification.error({
      title: '操作失败',
      content: result.message || '查询失败',
      details: result.detail
    })
    return Promise.resolve(null)
  }

  if (result.data[0].attrs.length) {
    return getSchema(result, props)
  } else {
    return Promise.resolve(null)
  }
}

async function getSchema(result: any, props: KObjectPropertyPanelPropType): Promise<KContainer> {
  const attrs = result.data[0].attrs || {}
  const commonArr = {
    control: 'KInput',
    visible: props.visible,
    dataSource: '',
    nullable: 1,
    readonly: props.readonly,
    disabled: props.disabled,
    type: 'control',
    events: [],
    props: {
      labelPosition: 'left'
    },
    layout: {
      rowSpan: 1,
      colSpan: 1,
      entireRow: false,
      fill: false
    },
    dataType: '50'
  }
  const controls = (attrs || []).map((arr: any) => {
    return {
      //name: arr?.name + arr?.nameEn
      name: `categoryAttributes#${arr?.nameEn}`,
      title: arr?.name,
      field: `categoryAttributes#${arr?.nameEn}`,
      ...commonArr
    }
  })
  const schema = {
    name: 'categoryAttributes',
    control: 'KSegment',
    visible: true,
    title: '分类属性',
    disabled: false,
    type: 'container',
    props: {
      labelPosition: 'left',
      labelWidth: 100,
      expand: true,
      minColWidth: 260,
      rowGap: 0,
      colGap: 10,
      colCount: 3
    },
    controls,
    layout: {
      // 4排的 rowSpan = 5， 1排有3个， 向上取整
      rowSpan: Math.ceil((Math.ceil(controls.length / 3) * 5) / 4),
      colSpan: 1,
      entireRow: true,
      fill: false
    },
    events: []
  } as KContainer

  return Promise.resolve(schema)
}

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ToolStripItemClickedEventArgs,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  BaseViewModel
} from '@kmsoft/upf-core'
import { ConcreteComponent } from 'vue'
import { ObjBusinessParam } from '../../client-srv'
import { ObjectCreatePanelLoadDataCallback } from '../object-create-panel'

//#region 枚举
export enum EnumObjectPanelTabParamType {
  /** 零部件展开方式 取值：EnumPartNodeChildExpandMode */
  ExpandMode = 'ExpandMode',
  /** 根零部件对象参数 */
  RootPartObjParam = 'RootPartObjParam',
  /** 选中的节点 */
  SelectedNode = 'SelectedNode',
  /** 业务对象 */
  ObjBusiness = 'ObjBusiness',
  /** 组件环境 */
  ComponentEnviroment = 'ComponentEnviroment',
  /**BomLink信息 */
  BomLinkParam = 'bomLinkParam',
  /** 根节点 */
  RootNode = 'RootNode',
  QueryRule = 'QueryRule'
}

export enum EnumObjTabPageId {
  DocProperty = '文档属性',
  Property = '属性',
  BomViewStructure = 'BOM结构',
  ObjRelation = '关联',
  DescRelation = '描述',
  StructureList = '结构列表',
  PartBomViews = 'BOM视图',
  Version = '版本',
  DocHistory = '签审历史',
  TaskPlanEdit = '项目计划',
  ProjectDirectory = '项目目录',
  BrowseDesc = '文档',
  DocInnerBrowse = '浏览',
  StructureManage = '结构管理',
  /** 产品模型 */
  ProductBrowser = 'Web_UCProductBrowser',
  /** 零部件模型 */
  PartBrowser = 'Web_UCPartBrowser'
}
//#endregion

//#region 事件
/** 对象面板标签页事件基类 */
export interface ObjectPanelTabEvent {
  /** 标签页配置 */
  tabConfig: ObjectPanelTabConfig
  /** 标签页Id */
  tabId: string
}
export interface ObjectPanelTabChangeEvent extends ObjectPanelTabEvent {}
/** 对象面板标签页数据更新事件 */
export interface ObjectPanelTabDataUpdatedEvent {}
/** 对象面板标签页更新事件 */
export interface ObjectPanelDataUpdatedEvent extends ObjectPanelTabEvent {}
/** 对象面板挂载事件 */
export interface ObjectPanelTabMountedEvent extends ObjectPanelTabEvent {}
/** 对象面板标签页准备就绪事件 */
export interface ObjectPanelTabReadyEvent extends ObjectPanelTabEvent {}
/** 对象面板Tab页自定义事件参数 */
export interface ObjectPanelTabRetrieveEvent<TCommand = string, TData = Record<string, any>> {
  /** 命令 */
  command: TCommand
  /** 数据 */
  data: TData
}
/**
 * 对象面板自定义事件
 */
export interface ObjectPanelRetrieveEvent<TCommand = string, TData = Record<string, any>> extends ObjectPanelTabEvent {
  /** 命令 */
  command: TCommand
  /** 数据 */
  data: TData
}
//#endregion

//#region 类型
/** 标签页保存结果 */
export type ObjectPanelTabSaveChangesResult = {
  cancel: boolean
}

/** 标签页保存结果 */
export type ObjectPanelSaveChangesResult = {
  cancel: boolean
}

/** 对象面板Tab页自定义命令参数 */
export type ObjectPanelTabRetrieveParams<TCommand = string, TData = Record<string, any>> = {
  /** 命令 */
  command: TCommand
  /** 数据 */
  data: TData
}

/** 面板刷新参数 */
export type ObjectPanelTabRefreshParams = {
  /** 对象Id */
  objID: string
  /** 对象类Id */
  objClsID?: string
}

/** 面板附属参数 */
export type ObjectPanelAttachParams = {
  [key in EnumObjectPanelTabParamType]?: any
}

/** 对象面板事件类型 */
export enum ObjectPanelEventType {
  /** 属性面板保存 */
  PROPERTY_PANEL_SAVE = 1
}

/** 对象面板标签页配置 */
export type ObjectPanelTabConfig = {
  /** 组件引用 */
  ref: IObjectPanelTab | undefined
  /** 标签页顺序 */
  index: number
  /** 标签页Id */
  tabId: string
  /** 标签页标题 */
  title: string
  /** 标签页类型 */
  //tabType: EnumObjTabType
  /** 是否默认激活 */
  isDefaultActive: boolean
  /** 是否可见 */
  visible: boolean
  /** 组件 */
  component: string | ConcreteComponent
  /** 参数 【如果类型为方法，那么每次更新标签页时会重新请求参数】 */
  params: Record<string, any> | (() => Record<string, any>)
}

/** 面板配置加载完成事件 */
export type ObjectPanelTabConfigSetParams = {
  /** 标签页配置 */
  tabConfigs: Array<ObjectPanelTabConfig>
}

/** 对象面板自定义命令参数 */
export type ObjectPanelRetrieveParams<TData = Record<string, any>> = {
  /** 标签页Id */
  tabId: string
  /** 命令 */
  command: string
  /** 数据 */
  data: TData
}

/** 对象面板Tab页继承类 */
export interface IObjectPanelTab {
  /** 加载数据 */
  retrieve(params: ObjectPanelTabRetrieveParams): void
  /** 刷新标签页 */
  refresh(): Promise<void>
  /** 获取是否更改 */
  isDataChanged?(): Promise<boolean>
  /** 保存 */
  saveChanges?(): Promise<ObjectPanelTabSaveChangesResult>
}
//#endregion

//#region 标签页定义

/** 标签页通用参数 */
export const KObjectPanelTabPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  /** 是否顶层打开对象面板 */
  isHighestOpen: VuePropTypes.bool().def(true),
  /** 禁止操作 */
  suppressOperation: VuePropTypes.bool().def(false),
  /** 禁止对象工具栏条目 */
  suppressObjectLife: VuePropTypes.bool().def(false),
  /** 标签页配置设置前 */
  tabConfigSetBefore: VuePropTypes.func<(event: ObjectPanelTabConfigSetParams) => void>().def(),
  /** 附属参数 */
  attachParams: VuePropTypes.createType<ObjectPanelAttachParams>().def({}),
  /**工具栏项点击事件 */
  toolStripItemClick: VuePropTypes.func<(event: ToolStripItemClickedEventArgs) => void>().def(),
  /**数据加载函数 */
  loadData: VuePropTypes.func<ObjectCreatePanelLoadDataCallback>().def(),
  container: VuePropTypes.createType<HTMLElement | BaseViewModel>().def()
  /** 服务附属参数 */
  //serviceAttachParams: VuePropTypes.createType<ServiceAttachParams>().def()
}

/** 参数类型 **/
export type KObjectPanelTabPropType = ViewPropsTypeExtract<typeof KObjectPanelTabPropOptions>

/** 组件事件定义 */
export const KObjectPanelTabEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 标签页更新事件
   * @param event
   * @returns
   */
  dataUpdated: (event: ObjectPanelTabDataUpdatedEvent) => true,
  /**
   * 自定义事件
   * @param event
   * @returns
   */
  retrieve: (event: ObjectPanelTabRetrieveEvent<any, any>) => true,
  /**
   * 准备就绪事件
   * @returns
   */
  ready: () => true
}

/** 事件类型 **/
export type KObjectPanelTabEmitsType = ViewEmitsTypeExtract<typeof KObjectPanelTabEventEmits>

//#endregion

//#region 组件定义

/** 参数 **/
export const KObjectPanelPropOptions = {
  ...BaseViewPropOptions,
  ...KObjectPanelTabPropOptions,
  /** 禁止的标签页(编码集合) */
  suppressedTabs: VuePropTypes.array<string>().def([])
}

/** 参数类型 **/
export type KObjectPanelPropType = ViewPropsTypeExtract<typeof KObjectPanelPropOptions>

/** 事件 */
export const KObjectPanelEventEmits = {
  ...BaseViewEventEmits,
  /** 面板准备就绪 */
  ready: () => true,
  /**
   * 工具栏点击事件
   * @param event
   * @returns
   */
  itemClicked: (event: ToolStripItemClickedEventArgs) => true,
  /**
   * 标签页更新事件x
   * @param event
   * @returns
   */
  dataUpdated: (event: ObjectPanelDataUpdatedEvent) => true,
  /**
   * 标签页挂载完成事件
   * @returns
   */
  tabMounted: (event: ObjectPanelTabMountedEvent) => true,
  /**
   * 标签页准备就绪事件
   * @returns
   */
  tabReady: (event: ObjectPanelTabReadyEvent) => true,
  /**
   * 对象参数改变事件
   * @returns
   */
  'update:objParam': (objParam: ObjBusinessParam) => true,
  /**
   * 标签页执行自定义操作事件
   * @param objParam
   * @returns
   */
  retrieve: (event: ObjectPanelRetrieveEvent<any, any>) => true,
  /**
   * tab 页切换事件
   * @param event
   * @returns
   */
  tabChange: (event: ObjectPanelTabChangeEvent) => true
}

/** 事件类型 **/
export type KObjectPanelEmitsType = ViewEmitsTypeExtract<typeof KObjectPanelEventEmits>
//#endregion

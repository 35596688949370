import { connect, withInstall } from '@kmsoft/upf-core'
import KDocRelationObjView from './KDocRelationObj.vue'
import KDocRelationObjViewModel from './KDocRelationObjViewModel'

const KDocRelationObj = connect(KDocRelationObjView, KDocRelationObjViewModel)

export default withInstall(KDocRelationObj)
export { KDocRelationObj, KDocRelationObjView, KDocRelationObjViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView } from '@kmsoft/upf-core'
import { KDocTemplateEventEmits, KDocTemplatePropOptions } from './interface'
import KDocTemplateViewModel from './KDocTemplateViewModel'

export default defineView({
  name: 'KDocTemplate',
  props: KDocTemplatePropOptions,
  emits: KDocTemplateEventEmits,
  viewModel: KDocTemplateViewModel,
  setup() {
    return {}
  }
})

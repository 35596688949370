import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KGridDesignerPreviewPropType, KGridDesignerPreviewEventEmitsType } from './interface'
import { ref } from 'vue'
import { DragElement, KGridDesignerElement } from '../../types'
import _ from 'lodash'

/** 导航管理 */
export default class KGridDesignerPreviewViewModel extends BaseViewModel<
  KGridDesignerPreviewEventEmitsType,
  KGridDesignerPreviewPropType
> {
  refComputeModal = ref<any>()

  constructor(options: ViewModelOptions<KGridDesignerPreviewPropType>) {
    super(options)
  }

  /**
   * 布局元素选中事件
   * @param element 选中的元素
   */
  elementSelected(element: KGridDesignerElement) {
    this.emit('elementSelected', element)
  }

  /**
   * 容器中拖入新元素时触发
   * @param dragElement 拖动元素
   */
  elementDragIn(dragElement: DragElement) {
    const element = dragElement._underlying_vm_
    this.emit('elementDragIn', element.title)
    this.emit('elementChange', this.props.elements)
    this.elementSelected(element)
  }

  /**容器中元素拖动完成时触发 */
  onElementDragEnd(dragElement: DragElement) {
    this.emit('elementChange', this.props.elements)
  }
}

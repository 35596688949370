import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    model: _ctx.$vm.formState,
    "label-col": { span: 6 },
    "wrapper-col": { span: 16 }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, {
        name: "componentFullName",
        label: "组件全名"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_textarea, {
            rows: "4",
            value: _ctx.$vm.formState!.componentFullName,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formState!.componentFullName) = $event)),
            readonly: _ctx.readonly
          }, null, 8, ["value", "readonly"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
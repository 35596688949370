// import { Project } from '@kmplm/ecol-api'
// import { IObjectPageResult } from '../base/object'
// import { DEFAULT_PAGE_RESULT } from '../common'

class ProjectSrv {
  // /**
  //  * 获取立项文件列表
  //  * @param request
  //  * @returns
  //  */
  // async getProposalObjsGridData(request: Project.GetProposalObjsGridDataRequest): Promise<IObjectPageResult> {
  //   const data = (await Project.getProposalObjsGridData(request)).data
  //   return (data ?? DEFAULT_PAGE_RESULT) as IObjectPageResult
  // }
  // /**
  //  * 添加项目立项文档
  //  * @param request
  //  * @returns
  //  */
  // async addProposalObjs(request: Project.AddRefObjsRequest): Promise<Project.AddRefObjsResponse['data']> {
  //   return (await Project.addProposalObjs(request)).data
  // }
  // /**
  //  * 移除立项文档
  //  * @param request
  //  * @returns
  //  */
  // async removeProposalObjs(request: Project.RemoveProposalObjsRequest): Promise<Project.RemoveProposalObjsResponse['data']> {
  //   return (await Project.removeProposalObjs(request)).data
  // }
  // /**
  //  * 获取参考对象列表(项目)
  //  * @param request
  //  * @returns
  //  */
  // async getRefObjsGridData(request: Project.GetRefObjsGridDataRequest): Promise<IObjectPageResult> {
  //   const data = (await Project.getRefObjsGridData(request)).data
  //   return (data ?? DEFAULT_PAGE_RESULT) as IObjectPageResult
  // }
  // /**
  //  * 添加参考对象(项目)
  //  * @param request
  //  * @returns
  //  */
  // async addRefObjs(request: Project.AddRefObjsRequest): Promise<Project.AddRefObjsResponse['data']> {
  //   return (await Project.addRefObjs(request)).data
  // }
  // /**
  //  * 移除参考对象(项目)
  //  * @param request
  //  * @returns
  //  */
  // async removeRefObjs(request: Project.RemoveRefObjsRequest): Promise<Project.RemoveRefObjsResponse['data']> {
  //   return (await Project.removeRefObjs(request)).data
  // }
  // /**
  //  * 获取参考对象列表(计划/任务)
  //  * @param request
  //  * @returns
  //  */
  // async getPlanRefObjsGridData(request: Project.GetPlanRefObjsGridDataRequest): Promise<IObjectPageResult> {
  //   const data = (await Project.getPlanRefObjsGridData(request)).data
  //   return (data ?? DEFAULT_PAGE_RESULT) as IObjectPageResult
  // }
  // /**
  //  * 添加参考对象(计划/任务)
  //  * @param request
  //  * @returns
  //  */
  // async addPlanRefObjs(request: Project.AddPlanRefObjsRequest): Promise<Project.AddPlanRefObjsResponse['data']> {
  //   return (await Project.addPlanRefObjs(request)).data
  // }
  // /**
  //  * 移除参考对象(计划/任务)
  //  * @param request
  //  * @returns
  //  */
  // async removePlanRefObjs(request: Project.RemovePlanRefObjsRequest): Promise<Project.RemovePlanRefObjsResponse['data']> {
  //   return (await Project.removePlanRefObjs(request)).data
  // }
  // /**
  //  * 获取交付物列表
  //  * @param request
  //  * @returns
  //  */
  // async getDeliverableObjsGridData(
  //   request: Project.GetDeliverableObjsGridDataRequest
  // ): Promise<Project.GetDeliverableObjsGridDataResponse['data']> {
  //   return (await Project.getDeliverableObjsGridData(request)).data
  // }
  // /**
  //  * 添加交付物
  //  * @param request
  //  * @returns
  //  */
  // async addDeliverableObjs(request: Project.AddDeliverableObjsRequest): Promise<Project.AddDeliverableObjsResponse['data']> {
  //   return (await Project.addDeliverableObjs(request)).data
  // }
  // /**
  //  * 移除交付物
  //  * @param request
  //  * @returns
  //  */
  // async removeDeliverableObjs(
  //   request: Project.RemoveDeliverableObjsRequest
  // ): Promise<Project.RemoveDeliverableObjsResponse['data']> {
  //   return (await Project.removeDeliverableObjs(request)).data
  // }
  // /**
  //  * 判断交付物对象是否已经存在
  //  * @param request
  //  * @returns
  //  */
  // async isDeliverableObjExist(
  //   request: Project.IsDeliverableObjExistRequest
  // ): Promise<Project.IsDeliverableObjExistResponse['data']> {
  //   return (await Project.isDeliverableObjExist(request)).data
  // }
  // /**
  //  * 根据配置项获取流程参数
  //  * @param request
  //  * @returns
  //  */
  // async getProjectConfig(request: Project.GetProjectConfigRequest): Promise<Project.GetProjectConfigResponse['data']> {
  //   return (await Project.getProjectConfig(request)).data
  // }
  // /**
  //  * 项目计划提交流程后
  //  * @param request
  //  * @returns
  //  */
  // async flowStepObjCommitAfter(
  //   request: Project.FlowStepObjCommitAfterRequest
  // ): Promise<Project.FlowStepObjCommitAfterResponse['data']> {
  //   return (await Project.flowStepObjCommitAfter(request)).data
  // }
}

export const projectSrv = new ProjectSrv()

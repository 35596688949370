import { VNode } from 'vue'
import { EnumDataGridChangedRowState, IKDataGridCellParams, KDataGridColumn, KDataGridViewModel } from '@kmsoft/upf-core'
import { EnumLayoutSchemaType, MetaProperty } from '../../../../client-srv'
import {
  EditorModifiedValue,
  EditorSetPropertyValueParams,
  EnumEditorEnvironment,
  GridCellEditorParams,
  GridCellRendererParams,
  IKEditorAPI
} from './types'

/** 网格基础列 */
export abstract class KObjectClassGridBaseColumn extends KDataGridColumn {
  constructor() {
    super()
  }
  /** 列定义 */
  property: MetaProperty
  /** 布局类型 */
  schemaType: EnumLayoutSchemaType
  /** 组件引用 */
  refComponent: () => KDataGridViewModel
  /** 字段 */
  code: string
  /** 字段 */
  dataSource: string
  /**
   * 获取单元格参数
   * @param params
   * @returns
   */
  private getCellTemplateParams(params: IKDataGridCellParams): GridCellRendererParams {
    return {
      cellParams: params,
      disabled: false,
      readonly: true,
      property: this.property,
      field: this.code,
      dataSource: this.dataSource,
      environment: EnumEditorEnvironment.DATA_GRID,
      value: params.cellValue || undefined,
      api: this.getApi(params)
    }
  }

  /**
   * 获取编辑参数
   * @param params
   * @returns
   */
  private getEditorParams(params: IKDataGridCellParams): GridCellEditorParams {
    return {
      cellParams: params,
      disabled: params.col.readonly,
      readonly: params.col.readonly,
      property: this.property,
      field: this.code,
      dataSource: this.dataSource,
      environment: EnumEditorEnvironment.DATA_GRID,
      value: params.cellValue || undefined,
      api: this.getApi(params)
    }
  }

  /**
   * 获取单元格显示内容
   * @param params
   * @returns
   */
  getCellTemplate(params: IKDataGridCellParams) {
    const rendererParams = this.getCellTemplateParams(params)
    return this.getCellRenderer(rendererParams)
  }

  /**
   * 获取单元格编辑器
   * @param params
   * @returns
   */
  getCellEditingTemplate(params: IKDataGridCellParams) {
    const editorParams = this.getEditorParams(params)
    return this.getCellEditor(editorParams)
  }

  /**
   * 获取编辑器API
   * @param params
   * @returns
   */
  private getApi(cellParams: IKDataGridCellParams): IKEditorAPI {
    const rowKey = this.refComponent().getRowKey()
    /** 行Id */
    const rowId = cellParams.row[rowKey]

    /**
     * 获取值
     * @param key 属性名
     * @returns
     */
    const getPropertyValue = (key: string) => {
      const row = this.refComponent().getRow(rowId)
      return row ? row[key] : undefined
    }

    /**
     * 设置值
     * @param key 属性名
     * @param value 值
     * @param setChanged
     */
    const setPropertyValue = (key: string, value: string, params?: EditorSetPropertyValueParams) => {
      const refComponent = (cellParams.getHost() as any).$vm as KDataGridViewModel
      refComponent.refreshRow({ [refComponent.getRowKey()]: rowId, [key]: value }, params?.setChanged)
      refComponent.endEdit()
    }

    /**
     * 获取当前行数据
     * @returns
     */
    const getValue = () => {
      return cellParams.row
    }

    // /** 获取修改记录 */
    // const getModifiedValue = async () => {
    //   /** 修改记录 */
    //   const modifiedValue: EditorModifiedValue = {
    //     properties: {},
    //     tableProperties: [],
    //     valueChanged: false
    //   }

    //   /** 获取修改记录 */
    //   const changes = this.refComponent().getChanges()

    //   /** 获取修改行记录 */
    //   const changedRow = changes.filter(a => a.state == EnumDataGridChangedRowState.MODIFIED).find(a => a.key == rowId)

    //   // 如果修改了行
    //   if (changedRow) {
    //     modifiedValue.properties = changedRow.record
    //   }

    //   // 设置是否更改标记
    //   modifiedValue.valueChanged = Object.keys(modifiedValue.properties).length > 0

    //   return modifiedValue
    // }
    const getCurrentPanelViewModel = () => {
      return this
    }
    const getCurrentPanelProps = () => {
      return this.refComponent().props
    }
    return {
      getPropertyValue,
      setPropertyValue,
      getValue,
      getCurrentPanelViewModel,
      getCurrentPanelProps
      // getObjBussiness: getObjBussiness,
      // getObjBussinessParam: getObjBussinessParam,
      // getModifiedValue: getModifiedValue
      // getHost: this.refComponent,
      // getData: () => params.row
    }
  }

  /**
   * 获取单元格渲染组件
   * @param params
   */
  abstract getCellRenderer(params: GridCellRendererParams): VNode

  /**
   * 获取编辑控件
   * @param params
   */
  abstract getCellEditor(params: GridCellRendererParams): VNode | null
}

/** 默认列 */
export default class KDataGridDefaultColumn extends KObjectClassGridBaseColumn {
  readonly: boolean = true
  getCellRenderer(params: GridCellRendererParams) {
    return <span>{params.cellParams.cellValue || ''}</span>
  }

  getCellEditor(params: GridCellRendererParams) {
    return <span></span>
  }
}

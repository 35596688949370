import { KDataElementTree, KDesignerControl } from '../../../../../types'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KGridDesignerElement } from '../../types'

/** 事件 **/
export const KGridColumnDesignerEventEmits = {
  ...BaseViewEventEmits,
  /**元素选中事件 */
  elementSelected: (element: KGridDesignerElement) => true,
  /**元素删除事件 */
  elementDelete: (element: KGridDesignerElement) => true,
  /**
   * 元素拖入事件
   * @param elementName 元素名
   */
  elementDragIn: (elementName: string) => true,
  /**元素change事件 */
  elementChange: (element: KGridDesignerElement) => true
}

/** 参数类型 **/
export type KGridColumnDesignerPropType = ViewPropsTypeExtract<typeof KGridColumnDesignerPropOptions>

/** 参数 **/
export const KGridColumnDesignerPropOptions = {
  ...BaseViewPropOptions,
  /** 布局 */
  elements: VuePropTypes.array<KGridDesignerElement>()
    .setRequired()
    .def([]),
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def([]),
  /**数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def({} as KDataElementTree)
}

/** 事件协议*/
export type KGridColumnDesignerEventEmitsType = ViewEmitsTypeExtract<typeof KGridColumnDesignerEventEmits>

import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../../object-tool-strip'
import { EnumToolStripItemKeys } from '../../types'
import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumClassTemplate } from '../../../../client-srv'

export const getFlowDetailToolStripItems = (): Array<ObjectToolStripItem> => {
  return [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_SUBMIT,
      title: '提交',
      icon: 'common_up_cover',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'alt+o'
    },
    // {
    //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_START,
    //   title: '启动',
    //   icon: 'file-protect',
    //   visible: true,
    //   compType: EnumToolStripCompType.BUTTON,
    //   shortcutKey: 'alt+o'
    // },
    // {
    //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_DELEGATE,
    //   title: '委托',
    //   icon: 'file-protect',
    //   visible: true,
    //   compType: EnumToolStripCompType.BUTTON,
    //   shortcutKey: 'alt+o'
    // },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_PAUSE,
      title: '暂停',
      icon: 'pause',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_RESTART,
      title: '重启',
      icon: 'common_refresh_cover',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_STOP,
      title: '终止',
      icon: 'stop',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_CHANGE_EXECUTOR,
      title: '修改执行人',
      icon: 'file-text',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]
}

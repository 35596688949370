import { EnumDialogResult, KDataGridRowData, KDialog, ToolStripItemClickedEventArgs, ViewModelOptions } from '@kmsoft/upf-core'
import { KObjectRelationGridEmitsType, KObjectRelationGridPropType } from './interface'
import { KObjectClassGridViewModel, ObjectClassGirdBaseQueryParams } from '../object-class-grid'
import { EnumToolStripItemKeys } from '../toolstrip'
import {
  EnumObjCopyMode,
  IObjectPageResult,
  ObjBusinessParam,
  ObjectClientSrv,
  ObjectRelationClientSrv,
  ObjectRelationDefination
} from '../../client-srv/object'
import { watch } from 'vue'

/** KObjectRelationGrid */
export default class KObjectRelationGridViewModel<
  E extends KObjectRelationGridEmitsType = KObjectRelationGridEmitsType,
  P extends KObjectRelationGridPropType = KObjectRelationGridPropType
> extends KObjectClassGridViewModel<E, P> {
  classAttr: string = 'k-object-relation-grid'

  private modelCode: string
  constructor(options: ViewModelOptions<P>) {
    super(options)

    this.modelCode = options.props.modelCode
    watch(
      () => this.props.definition,
      async () => {
        if (this.modelCode !== this.props.modelCode) this.modelCode = this.props.modelCode
        else return this.refresh()
      }
    )
  }

  public async onLoadData(params: ObjectClassGirdBaseQueryParams): Promise<IObjectPageResult | Array<KDataGridRowData>> {
    return ObjectRelationClientSrv.listLinkedObjects(this?.props?.objParam, this?.props?.definition)
  }

  private async addLink(businessParamGenerator?: (arg0: ObjectRelationDefination) => Promise<ObjBusinessParam[] | undefined>) {
    return ObjectRelationClientSrv.addLink(this.props.objParam, this.props.definition, businessParamGenerator, () =>
      this.refresh()
    )
  }

  private async discardLink(operate: string) {
    const selectedRows = this.getSelectedRows()
    if (!selectedRows || selectedRows.length === 0) return

    KDialog.confirm({
      title: `确认${operate}`,
      content: `确认${operate}所选对象关系？`,
      onOk: async () => ObjectRelationClientSrv.deleteLinks(selectedRows, operate === '删除', () => this.refresh())
    })
  }

  public async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        return this.addLink(async definition => {
          const createResult = {} as any
          return createResult.dialogResult === EnumDialogResult.Confirm && createResult.objBusiness
            ? [createResult.objBusiness]
            : undefined
        })
      case EnumToolStripItemKeys.TOOL_STRIP_ADD_RELATION_OBJ:
        return this.addLink(
          async definition =>
            new Promise((resolve, reject) =>
              ObjectClientSrv.openObjectSelectDialog({
                showApply: false,
                objectClassManageQueryParam: {
                  showObjClsCodes: [definition.destinationClsCode]
                },
                objectSearchQueryParam: {
                  showObjClsCodes: [definition.destinationClsCode]
                },
                onClosing: async event => {
                  if (!event.viewInstance || event.dialogResult !== EnumDialogResult.Confirm) return

                  const objParams = event.viewInstance.getSelectedObjParams()
                  if (objParams.length < 1) event.cancel = true
                  else resolve(objParams)
                }
              })
            )
        )
      case EnumToolStripItemKeys.TOOL_STRIP_REMOVE_RELATION_OBJ:
        return this.discardLink('移除')
      case EnumToolStripItemKeys.TOOL_STRIP_DELETE_RELATION_OBJ:
        return this.discardLink('删除')
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT: {
        this.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
        this.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD, true)
        // this.isGridDisabled.value = false
        break
      }
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE: {
        this.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
        this.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD, false)
        // this.isGridDisabled.value = true
        break
      }
      default:
        break
    }
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KReferenceEditorPropOptions, KReferenceEditorEventEmits } from './interface'
import KReferenceEditorViewModel from './KReferenceEditorViewModel'

export default defineView({
  name: 'KReferenceEditor',
  props: KReferenceEditorPropOptions,
  emits: KReferenceEditorEventEmits,
  viewModel: KReferenceEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

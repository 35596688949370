import { connect, withInstall } from '@kmsoft/upf-core'
import KProjectWorkspaceView from './KProjectWorkspace.vue'
import KProjectWorkspaceViewModel from './KProjectWorkspaceViewModel'

const KProjectWorkspace = connect(KProjectWorkspaceView, KProjectWorkspaceViewModel)

export default withInstall(KProjectWorkspace)
export { KProjectWorkspace, KProjectWorkspaceView, KProjectWorkspaceViewModel }

// 模板生成文件
// export * from './{{folderName}}'

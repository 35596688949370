import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { getCandidates } from '../utils'
import { EnumAttributeType, EnumDataType, KControlAttribute, KControlEvent } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'

/* 组件的基本属性 */
const comConfig = [
  {
    name: 'readonly',
    title: '是否只读',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'type',
    title: '日期,时间,日期区间',
    control: 'KSelect',
    options: getCandidates(['date', 'time', 'range']),
    defaultValue: 'date',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'picker',
    title: '日期周期',
    control: 'KSelect',
    options: getCandidates(['date', 'week', 'month', 'quarter', 'year']),
    defaultValue: 'date',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'size',
    title: '输入框大小',
    control: 'KSelect',
    options: getCandidates(['small', 'middle', 'large']),
    defaultValue: 'middle',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'allowClear',
    title: '可清空',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'bordered',
    title: '是否有边框',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'format',
    title: '日期格式',
    control: 'KSelect',
    options: getCandidates(['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss']),
    defaultValue: 'YYYY-MM-DD HH:mm:ss',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  }
]

/* 基础配置项集合 */
const BASE = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE, PROPERTY_CONFIG_ITEM_COLLECTION.DISABLED] as Array<KControlAttribute>

/**控件属性配置集合 */
const PROPS = comConfig

/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
] as Array<KControlAttribute>

const EVENT: Array<KControlEvent> = [{ name: 'change', title: '值改变事件', describe: '值改变事件 的说明信息...' }]

export default { BASE, PROPS, LAYOUT, EVENT }


import { EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import { KPartImportErrorMsgPropOptions, KPartImportErrorMsgEventEmits } from './interface'
import KPartImportErrorMsgViewModel from './KPartImportErrorMsgViewModel'

export default defineView({
  name: 'KPartImportErrorMsg',
  props: KPartImportErrorMsgPropOptions,
  emits: KPartImportErrorMsgEventEmits,
  viewModel: KPartImportErrorMsgViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KFlowApprovalContentView from './KFlowApprovalContent.vue'
import KFlowApprovalContentViewModel from './KFlowApprovalContentViewModel'

const KFlowApprovalContent = connect(KFlowApprovalContentView, KFlowApprovalContentViewModel)

export default withInstall(KFlowApprovalContent)
export { KFlowApprovalContent, KFlowApprovalContentView, KFlowApprovalContentViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { TreeViewSelectEventArgs, defineView } from '@kmsoft/upf-core'
import KCodeGeneratorViewModel from './KCodeGeneratorViewModel'
import { KCodeGeneratorEventEmits, KCodeGeneratorPropOptions } from './interface'

export default defineView({
  name: 'KCodeGenerator',
  props: KCodeGeneratorPropOptions,
  emits: KCodeGeneratorEventEmits,
  viewModel: KCodeGeneratorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { IValueDesc } from '../../../interface'

// 配置项定义
export interface IConfigGridComboboxTileDefinition {
  defId: string
  id: string
  code: string
  name: string
  values: Array<string>
  remark: string
  dataType: string
  editType: string
  editParam: string
  value1?: string
}

// 页面表单状态
export interface IConfigGridComboboxTileViewModelState {
  formState: IConfigGridComboboxTileDefinition
  editable: boolean
  dataLoaded: boolean
  selectEditTypeOptions: Array<IValueDesc>
  mode: string
}

// 页面事件
export const KConfigGridComboboxTileEventEmits = {
  ...BaseViewEventEmits
}

export const KConfigGridComboboxTileViewModelPropOptions = {
  ...BaseViewPropOptions,
  defId: VuePropTypes.string().def(''),
  treeId: VuePropTypes.string().def(''),
  oper: VuePropTypes.string().def(''),
  mode: VuePropTypes.string().def('')
}

export type KConfigGridComboboxTileViewModelPropType = ViewPropsTypeExtract<typeof KConfigGridComboboxTileViewModelPropOptions>

export type KConfigGridComboboxTileEventEmitsType = ViewEmitsTypeExtract<typeof KConfigGridComboboxTileEventEmits>

import { IWidgets } from '@kmsoft/upf-core'
import { VNode, RendererNode, RendererElement, createVNode } from 'vue'
import { KAgentMonitorApplet } from '@kmsoft/ebf-doc'

export default class AgentMonitor implements IWidgets {
  location = 'header'
  render(): VNode<RendererNode, RendererElement, { [key: string]: any }> {
    return createVNode(KAgentMonitorApplet)
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeActivityObjGridView from './KChangeActivityObjGrid.vue'
import KChangeActivityObjGridViewModel from './KChangeActivityObjGridViewModel'

const KChangeActivityObjGrid = connect(KChangeActivityObjGridView, KChangeActivityObjGridViewModel)

export default withInstall(KChangeActivityObjGrid)
export { KChangeActivityObjGrid, KChangeActivityObjGridView, KChangeActivityObjGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

// 模块定义
export interface IModuleDefinition {
  defId: string
  id: string
  code: string
  parentCode: string
  moduleCode: string
  name: string
  type: string
  remark: string
  orderId: number
  visible: boolean | string
}

// 页面事件
export const KModuleFormEventEmits = {
  ...BaseViewEventEmits
}

// 属性选项
export const KModuleFormPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def(''),
  /**
   * 操作(insert: 新增, update: 修改, view: 查看)
   */
  oper: VuePropTypes.string()
    .setRequired()
    .def(),
  row: VuePropTypes.createType<IModuleDefinition>().def()
}

// 属性类型
export type KModuleFormPropType = ViewPropsTypeExtract<typeof KModuleFormPropOptions>

export type KModuleFormEventEmitsType = ViewEmitsTypeExtract<typeof KModuleFormEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KProductNeedsCreateView from './KProductNeedsCreate.vue'
import KProductNeedsCreateViewModel from './KProductNeedsCreateViewModel'

const KProductNeedsCreate = connect(KProductNeedsCreateView, KProductNeedsCreateViewModel)

export default withInstall(KProductNeedsCreate)
export { KProductNeedsCreate, KProductNeedsCreateView, KProductNeedsCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

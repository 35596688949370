import { connect, withInstall } from '@kmsoft/upf-core'
import KMetricsEditPanelView from './KMetricsEditPanel.vue'
import KMetricsEditPanelViewModel from './KMetricsEditPanelViewModel'

const KMetricsEditPanel = connect(KMetricsEditPanelView, KMetricsEditPanelViewModel)

export default withInstall(KMetricsEditPanel)
export { KMetricsEditPanel, KMetricsEditPanelView, KMetricsEditPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView } from '@kmsoft/upf-core'
import { KChangeActivityRelationshipPropOptions, KChangeActivityRelationshipEventEmits } from './interface'
import KChangeActivityRelationshipViewModel from './KChangeActivityRelationshipViewModel'
const data = [
  {
    id: '#change-order',
    title: '变更活动-变更单',
    key: '1'
  }
] as any
export default defineView({
  name: 'KChangeActivityRelationship',
  props: KChangeActivityRelationshipPropOptions,
  emits: KChangeActivityRelationshipEventEmits,
  viewModel: KChangeActivityRelationshipViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      data
    }
  }
})

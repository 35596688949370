
import { defineView } from '@kmsoft/upf-core'
import { KScriptEditorPropOptions, KScriptEditorEventEmits } from './interface'
import KScriptEditorViewModel from './KScriptEditorViewModel'

export default defineView({
  name: 'KScriptEditor',
  props: KScriptEditorPropOptions,
  emits: KScriptEditorEventEmits,
  viewModel: KScriptEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

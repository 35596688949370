import { connect, withInstall } from '@kmsoft/upf-core'
import KPlanValidationMetricsGridView from './KPlanValidationMetricsGrid.vue'
import KPlanValidationMetricsGridViewModel from './KPlanValidationMetricsGridViewModel'

const KPlanValidationMetricsGrid = connect(KPlanValidationMetricsGridView, KPlanValidationMetricsGridViewModel)

export default withInstall(KPlanValidationMetricsGrid)
export { KPlanValidationMetricsGrid, KPlanValidationMetricsGridView, KPlanValidationMetricsGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPdfBrowserPropOptions = {
  ...BaseViewPropOptions,
  urls: VuePropTypes.createType<string[]>()
    .setRequired()
    .def([])
}

/** 参数类型 **/
export type KPdfBrowserPropType = ViewPropsTypeExtract<typeof KPdfBrowserPropOptions>

/** 事件 */
export const KPdfBrowserEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPdfBrowserEmitsType = ViewEmitsTypeExtract<typeof KPdfBrowserEventEmits>

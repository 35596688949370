import { AppContext, IIdentity } from '@kmsoft/upf-core'

/** 登录服务 */
export class LoginClientSrv {
  /**
   * 获取用户信息
   * @param throwEx
   */
  static getUserIdentity(): IIdentity | undefined {
    const curentIdentity = AppContext.current.getIdentity()
    return curentIdentity
  }
}

/** 外部程序枚举 */
export enum EnumExApp {
  /** 0 = 集成平台（用于处理ID_APP_PDM_START消息）*/
  集成平台 = 0,

  /** 1 = PDM;天翼客户端插件（PDM和天翼客户端插件不能同时出现）*/
  PDM = 1,

  /** 2 = CAD2D;*/
  CAD2D = 2,

  /** 3 = CAD3D;*/
  CAD3D = 3,

  /** 4 = CAPP（早期DNC也用4，20121127后DNC改为235）*/
  KMCAPP = 4,

  /** 5 = AutoCad;*/
  AUTOCAD = 5,

  /** 6 = office;*/
  Office = 6,

  /** 7 = ug; */
  UG = 7,

  /** 8 = proe;*/
  PROE = 8,

  /** 9 = 正泰质量系统;*/
  正泰质量系统 = 9,

  /** 10 = 工作流;*/
  工作流 = 10,

  /** 11 = pdm3.x;*/
  pdm3x = 11,

  /** 12 = java服务器（SHIP10项目与PDM集成）;*/
  java服务器 = 12,

  /** 13 = 电装系统*/
  电装系统 = 13,

  /** 14 = 工艺资源管理器（GyShow.Exe）*/
  工艺资源管理器 = 14,

  /** 15 = 邮件侦测器客户端*/
  邮件侦测器客户端 = 15,

  /** 16 = 邮件侦测器服务端*/
  邮件侦测器服务端 = 16,

  /** 17 = Solidedge（以前为9，与正泰质量系统冲突）（pdm邮件及时提示系统服务端的选项窗口？）*/
  SOLIDEDGE = 17,

  /** 18 = Solidworks（以前为13，与电装系统冲突）*/
  SOLIDWORKS = 18,

  /** 19 = Protel*/
  Protel = 19,

  /** 20 = Eplan*/
  EPLAN = 20,

  /** 22	= AutoCAD自动填表模块？*/
  AutoCAD自动填表模块 = 22,

  /** 23	= 资源管理器中check in、check out 文件的插件？*/
  资源管理器中文件的插件 = 23,

  /** 24	= 文件驱动中加密文件的插件  dpEncrypt.exe  供pdm用？*/
  文件驱动中加密文件的插件 = 24,

  /** 25 = BOM（备用）（AutoCAD属性编辑acadext？）*/
  BOM备用 = 25,

  /** 26 = 尧创版CAD（20070510）*/
  尧创版CAD = 26,

  /** 29 = inventor */
  INVENTOR = 29,

  /** 30 = catia*/
  CATIA = 30,

  /** 36 = word*/
  word = 36,

  /** excel = 37,*/
  excel = 37,

  /** 38 = project(20110225)*/
  project = 38,

  /** 40 = 界面自动化填写工具（20070511）*/
  界面自动化填写工具 = 40,

  /** 41 = pdmshell.exe/PDM启动程序 */
  pdmshell = 41,

  /** 42 = es_ddb.exe /签字程序，用于为protel签字*/
  es_ddb = 42,

  /** 45 = dpl_RobotServer	(20090603) */
  dpl_RobotServer = 45,

  /** 46 = KMMES             (20110126)*/
  KMMES = 46,

  /** 47 = KM 3DCAPP_A       (20130826)*/
  KM3DCAPP_A = 47,

  /** 48 = KM 3DCAPP_M       (20130826)*/
  KM3DCAPP_M = 48,

  /** 50 = 输入设备管理工具 (20110429)*/
  输入设备管理工具 = 50,

  /** 51 = CAXA集成插件 （20130321）*/
  CAXA集成插件 = 51,

  /** 52 = 中望三维Cad*/
  ZW3D = 52,

  /** 100 = PDM服务端*/
  PDM服务端 = 100,

  /** 111 = PDM第二进程*/
  PDM第二进程 = 111,

  /** 112	= 和天翼服务端集成的Exe（20081127）*/
  和天翼服务端集成的Exe = 112,

  /** 113	= 编码服务端（20140726）*/
  编码服务端 = 113,

  /** 114 = PB提供获取数据窗口语法服务pdm_pbserver.exe（20141106）*/
  PB提供获取数据窗口语法服务pdm_pbserver = 114,

  /** 115 远程签署客户端  （20141203）*/
  远程签署客户端 = 115,

  /** 浩辰Cad*/
  HAOCHENCAD = 116,

  /** 中望二维Cad*/
  ZW2D = 117,

  /**
   * Web服务集成CS应用（20120613）
   * 222	= 当GXG转换PDF接口kmGxkToPdf(...)所调用的进程未注册集成平台，用此号注册。【供IIS调用的w3wc.exe进程使用】
   */
  当GXG转换PDF接口kmGxkToPdf = 222,

  /** 223	= 收到Web服务来的消息后调用CS功能 (即：dpWebProxy_BSToCS（...）)【无锡压缩，载体目前为kmBSToCS.exe进程】*/
  收到Web服务来的消息后调用CS功能 = 223,

  /** 234 = 金舟ERP*/
  金舟ERP = 234,

  /** 235 = DNC （早期DNC用4，与CAPP冲突，20121127改）*/
  DNC = 235,

  /** 501 = 新平台集成服务 （20120213）*/
  新平台集成服务 = 501
}

import {
  BaseViewModel,
  DataGridLoadResult,
  EnumDialogResult,
  KDataGridRowSelectedEvent,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ToolStripItemClickedEventArgs,
  ViewModelOptions,
  PageManager
} from '@kmsoft/upf-core'
import { KClassUiConfigfrom } from '../../../../../../ebf-config'
import { ref, watch } from 'vue'
import KObjectClassLayoutDefinitionViewModelFrom, {
  KObjectClassLayoutDefinitionFormViewModel
} from './form/object-class-layout-definition-form'
import { IObjectClassLayout, KObjectClassLayoutDefinitionEmitsType, KObjectClassLayoutDefinitionPropType } from './interface'
import { KObjectToolStripViewModel, ObjectToolStripItem } from '../../../../controls/object-tool-strip'
import { Api, EnumRequestCode } from '../../../../client-srv'
import { ToolStripHelper, EnumToolStripItemKeys } from '../../../../controls/toolstrip'

/** KObjectClassLayoutDefinition */
export default class KObjectClassLayoutDefinitionViewModel extends BaseViewModel<
  KObjectClassLayoutDefinitionEmitsType,
  KObjectClassLayoutDefinitionPropType
> {
  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>(ToolStripHelper.getObjClassLayoutDefinitionToolStripItems())
  /** 对象类布局网格 */
  refObjectClassLayoutGrid = ref<KDataGridViewModel>()

  constructor(options: ViewModelOptions<KObjectClassLayoutDefinitionPropType>) {
    super(options)
    watch(
      () => options.props.clsId,
      () => {
        this.refObjectClassLayoutGrid.value?.clearSelectedRows()
        this.refObjectClassLayoutGrid.value?.refresh()
        this.initToolStrip()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {
    this.initToolStrip()
  }

  /**
   * 工具栏点击事件
   * @param event 工具栏点击事件
   */
  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.createLayoutDefinition()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT: {
        const rows = this.refObjectClassLayoutGrid.value?.getSelectedRows()
        if (rows && rows.length != 0) {
          this.editObjectClassLayout(rows[0] as IObjectClassLayout)
        }
        break
      }
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE: {
        const rows = this.refObjectClassLayoutGrid.value?.getSelectedRows()!
        if (rows.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '请至少选择一条数据'
          })
          return
        }
        const rowIds = rows.map(row => row.id)
        this.batchDeleteLayoutDefinition(rowIds)
        break
      }
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refObjectClassLayoutGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /**
   * 改变工具栏
   */
  changeToolItem(event: KDataGridRowSelectedEvent<any>) {
    if (this.props.environment == 'show') {
      return
    }
    const selectRows = this.refObjectClassLayoutGrid.value?.getSelectedRows()
    if (!selectRows || selectRows.length == 0) {
      this.initToolStrip()
      return
    }
    const param = { environment: 100, selectedDatas: selectRows }
    this.refToolStrip.value?.updateState(param)
  }

  /**
   * 初始化工具栏
   */
  initToolStrip() {
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE, false)
  }

  /**
   * 创建布局定义
   */
  createLayoutDefinition() {
    KDialog.show({
      title: '创建布局',
      size: { width: 718, height: 460 },
      props: { clsId: this.props.clsId, operationType: 'create' },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KObjectClassLayoutDefinitionViewModelFrom,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KObjectClassLayoutDefinitionFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formValue = formViewModel.getValue()
        if (
          formValue.name == null ||
          formValue.name === '' ||
          formValue.code == null ||
          formValue.code === '' ||
          formValue.type == null ||
          formValue.type === ''
        ) {
          KNotification.warn({
            message: '系统提示',
            description: '名称、编码、类型不能为空'
          })
          event.cancel = true
          return
        }
        // 布局的编号唯一
        const filterRows = this.refObjectClassLayoutGrid.value?.getRows().filter(row => {
          return row.code === formValue.code
        })
        if (filterRows && filterRows?.length > 0) {
          KNotification.warn({
            message: '系统提示',
            description: '对象类已存在该编号，对象类中不允许存在相同编号的布局'
          })
          event.cancel = true
          return
        }
        // 添加布局
        const params = {
          data: [
            {
              code: formValue.code,
              name: formValue.name,
              type: formValue.type,
              remark: formValue.remark,
              sourceLayoutId: formValue.sourceLayoutId,
              belongClsCode: this.props.clsCode,
              belongClsId: this.props.clsId
            }
          ]
        }
        const res = await Api.post('official', 'ClsLayoutDefService', 'create', params)
        if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
          const queryParams = {
            data: [res.data]
          }
          const result = await Api.post('official', 'ClsLayoutDefService', 'fetchDetail', queryParams)
          if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
            // this.refObjectClassLayoutGrid.value?.insertRow(result.data, 0)
            this.refObjectClassLayoutGrid.value?.refresh()
          } else {
            KNotification.error({
              title: '系统错误',
              content: result.message ? result.message : '未查询到新创建的数据'
            })
            return
          }
        } else {
          KNotification.error({
            title: '系统错误',
            content: res.message ? res.message : '对象类布局创建失败'
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 批量删除
   */
  batchDeleteLayoutDefinition(rowIds: any) {
    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: async () => {
        // 批量删除
        const queryParams = {
          data: [rowIds]
        }
        const result = await Api.post('official', 'ClsLayoutDefService', 'batchRemove', queryParams)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          // this.refObjectClassLayoutGrid.value?.removeRow(rowIds)
          this.refObjectClassLayoutGrid.value?.clearSelectedRows()
          this.refObjectClassLayoutGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '系统错误',
            content: result.message ? result.message : '未查询到新创建的数据'
          })
          return
        }
      }
    })
  }

  /**
   * 加载对象类布局定义
   */
  loadObjectClassLayoutDefinition(): Promise<DataGridLoadResult> {
    return new Promise(resolve => {
      if (!this.props.clsId) {
        resolve([])
      }
      const param = {
        data: [this.props.clsCode]
      }
      Api.post('official', 'ClsLayoutDefService', 'listLayoutDefByClsCode', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          resolve(res.data)
        } else {
          resolve([])
        }
      })
    })
  }

  /**
   * 打开布局设计器
   * @param row 选中行数据
   */
  openLayoutDesign(row: IObjectClassLayout) {
    PageManager.openPage(row.id!, row.name, KClassUiConfigfrom, {
      belongClsCode: row.belongClsCode,
      classCode: row.clsCode,
      layoutCode: row.code,
      layoutName: row.name,
      layoutType: row.type,
      layoutId: row.id
    })
  }

  /**
   * 编辑对象类布局
   * @param row 选中行数据
   */
  editObjectClassLayout(row: IObjectClassLayout) {
    KDialog.show({
      title: '编辑布局',
      size: { width: 718, height: 460 },
      props: { objectClassLayoutFormData: row, operationType: 'update' },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KObjectClassLayoutDefinitionViewModelFrom,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KObjectClassLayoutDefinitionFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formValue = formViewModel.getValue()
        if (
          formValue.name == null ||
          formValue.name === '' ||
          formValue.code == null ||
          formValue.code === '' ||
          formValue.type == null ||
          formValue.type === ''
        ) {
          KNotification.warn({
            message: '系统提示',
            description: '名称、编码、类型不能为空'
          })
          event.cancel = true
          return
        }

        // 编辑布局
        const params = {
          data: [
            {
              id: formValue.id,
              code: formValue.code,
              name: formValue.name,
              type: formValue.type,
              remark: formValue.remark,
              schema: row.schema,
              belongClsId: this.props.clsId
            }
          ]
        }
        const res = await Api.post('official', 'ClsLayoutDefService', 'update', params)
        if (res && res.code == EnumRequestCode.SUCCESS) {
          const queryParams = {
            data: [formValue.id]
          }
          const result = await Api.post('official', 'ClsLayoutDefService', 'fetchDetail', queryParams)
          if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
            this.refObjectClassLayoutGrid.value?.refreshRow(result.data)
          } else {
            KNotification.error({
              title: '系统错误',
              content: result.message ? result.message : '未查询到修改后的数据'
            })
            return
          }
        } else {
          KNotification.error({
            title: '系统错误',
            content: res.message ? res.message : '修改对象类布局失败'
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 查看布局详情
   * @param row 选中行数据
   */
  detail(row: IObjectClassLayout) {
    KDialog.show({
      title: '查看布局',
      size: { width: 718, height: 460 },
      props: { objectClassLayoutFormData: row, operationType: 'detail' },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KObjectClassLayoutDefinitionViewModelFrom
    })
  }

  /**
   * 单个删除
   * @param row 选中行数据
   */
  singleDelete(row: IObjectClassLayout) {
    this.batchDeleteLayoutDefinition([row.id])
  }

  /**
   * 获取选中的行数据
   * @returns 选中的行数据
   */
  getSelectRows() {
    return this.refObjectClassLayoutGrid.value?.getSelectedRows()
  }
}

import { EnumDialogResult, ViewModelOptions, request } from '@kmsoft/upf-core'
import { KFileEditorEmitsType, KFileEditorPropType } from './interface'
import { KBaseEditorViewModel } from '../../base'
import { computed, ref, watch } from 'vue'
import { KFileUploader } from '../../../file-uploader'
import { Api, EnumFileUploadingStatus, FileClientSrv, FileInfo, UploadFileInfo } from '../../../../client-srv'
import { isArray } from 'lodash'
import { fileSuffixIconMapping } from '../../../../controls/object-file-upload/interface'

/** 文件编辑器 */
export default class KFileEditorViewModel extends KBaseEditorViewModel<KFileEditorEmitsType, KFileEditorPropType, string> {
  /** 是否正在加载 */
  isUploading = ref<boolean>()
  /** 是否正在下载 */
  isDownloading = ref<boolean>()
  /** 文件信息 */
  fileInfo = ref<FileInfo>()
  /** 显示值 */
  displayValue = computed(() => {
    return this.fileInfo.value?.originalFileName || this.fileInfo.value?.name || '无文件'
  })

  getIcon = computed(() => {
    return () => {
      if (this.fileInfo.value) {
        const fileSuffix = this.fileInfo.value.name?.split('.')[1]
        const findType = Object.keys(fileSuffixIconMapping).find(key =>
          fileSuffixIconMapping[key].fileSuffixList.includes(fileSuffix as string)
        )
        if (findType) {
          return fileSuffixIconMapping[findType].icon
        } else {
          return fileSuffixIconMapping.other.icon
        }
      }
    }
  })

  constructor(options: ViewModelOptions<KFileEditorPropType>) {
    super(options)

    watch(
      () => options.props.value,
      () => {
        this.onUpdateFileInfo()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  /**
   * 文件ID 更新
   * @returns
   */
  async onUpdateFileInfo() {
    if (!this.stateValue.value) {
      return
    }
    const fileInfos = await FileClientSrv.getFilesDetail([this.stateValue.value])
    if (fileInfos.length <= 0) {
      return
    }
    this.fileInfo.value = fileInfos[0]
  }

  /** 清除 */
  onClear() {
    this.stateValue.value = undefined
    this.fileInfo.value = undefined
  }

  /**
   * 上传
   * @description
   */
  async onClickUpload() {
    this.isUploading.value = true
    /** 上传结果 */
    const fileResult = await KFileUploader.uploadAsync({
      title: '上传附件',
      maxCount: 1,
      multiple: false,
      accept: this.props.accept
    })

    // 如果不是取消
    if (fileResult.dialogResult != EnumDialogResult.Cancel) {
      /** 过滤文件 */
      const files = fileResult.fileList.filter(
        a => a.status == EnumFileUploadingStatus.DONE || a.status == EnumFileUploadingStatus.COPIED
      )

      // 如果有文件
      if (files.length > 0) {
        const file = fileResult.fileList[0]
        this.fileInfo.value = file
        this.stateValue.value = file.id
      } else {
        this.clear()
      }
    }

    this.isUploading.value = false
  }

  /**
   * 下载
   * @description
   */
  async onClickDownLoad() {
    if (!this.fileInfo.value) {
      return
    }
    this.isDownloading.value = true
    await FileClientSrv.downloadFile({
      id: this.fileInfo.value.id!,
      location: this.fileInfo.value.location,
      startIndex: 0,
      endIndex: this.fileInfo.value.totalChunkCount! - 1,
      fileName: this.fileInfo.value.originalFileName!
    })
    this.isDownloading.value = false
  }

  download() {
    if (!this.fileInfo.value) {
      return
    }
    // @ts-ignore
    request
      .post(
        Api.uploadProxyUrl + '/download',
        {
          fileIds: this.fileInfo.value.id,
          modelName: 'Document'
        },
        {
          responseType: 'blob'
        }
      )
      .then((data: any) => {
        const url = window.URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', this.fileInfo.value?.name as string) //指定下载后的文件名，防跳转
        document.body.appendChild(link)
        link.click()
      })
  }
  setValue(value: any) {
    if (value && isArray(value)) {
      this.fileInfo.value = value[0]
    }
  }
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDescriptionsEventEmitsType } from '@kmsoft/upf-core/src/web-controls/descriptions'
import { KDesignerToolboxPropType } from './interface'
import { computed, ref, watch } from 'vue'
import { KDesignerControl, KDesignerToolBox } from '../../../types/metadata'
import _ from 'lodash'

/** 导航管理 */
export default class KDesignerToolboxViewModel extends BaseViewModel<KDescriptionsEventEmitsType, KDesignerToolboxPropType> {
  activeKey = ref('1')
  selectedHierarchyTreeNodeKey = ref(this.props.selectElementCode!)

  constructor(options: ViewModelOptions<KDesignerToolboxPropType>) {
    super(options)
    watch(
      () => this.props.selectElementCode,
      () => {
        // 监听设计区选中的元素，并同步选中层级树节点
        this.selectedHierarchyTreeNodeKey.value = this.props.selectElementCode!
      }
    )
  }

  viewDidMount(): void {}
}

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { IValueDesc } from '../../interface'

// 配置项定义
export interface IConfigDefinition {
  treeId: string
  id: string
  code: string
  parentCode: string
  name: string
  type: string
  remark: string
  orderId: number
  dataType: string
  editType: string
  editParam: string
  editParamLabel: string
  visible: boolean | string
  moduleCode: string
  clsCode?: string
}

// 页面表单状态
export interface IConfigFormViewModelState {
  formState: IConfigDefinition
  changeAvailable: boolean
  visibleAvailable: boolean
  /** 数据类型 */
  dataTypeOptions: Array<IValueDesc>
  /** 编辑方式 */
  editTypeOptions: Array<IValueDesc>
}

// 页面事件
export const KConfigFormEventEmits = {
  ...BaseViewEventEmits
}

export const KConfigFormViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def(''),
  /**
   * 操作(insert: 新增, update: 修改, view: 查看)
   */
  oper: VuePropTypes.string()
    .setRequired()
    .def(),
  row: VuePropTypes.createType<IConfigDefinition>().def()
}

export type KConfigFormViewModelPropType = ViewPropsTypeExtract<typeof KConfigFormViewModelPropOptions>

export type KConfigFormEventEmitsType = ViewEmitsTypeExtract<typeof KConfigFormEventEmits>

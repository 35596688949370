import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createBlock(_component_k_data_grid, {
    ref: "refDataGrid",
    rowKey: "code",
    loadData: _ctx.$vm.loadData,
    isMultipleSelection: "",
    rowModelType: _ctx.rowModelType,
    onFirstDataRendered: _ctx.$vm.init
  }, {
    columns: _withCtx(() => [
      _createVNode(_component_k_data_grid_text_box_column, {
        key: "code",
        id: "code",
        name: "code",
        dataPropertyName: "code",
        headerText: "编码",
        align: "center"
      }),
      _createVNode(_component_k_data_grid_text_box_column, {
        key: "name",
        id: "name",
        name: "name",
        dataPropertyName: "name",
        headerText: "名称",
        align: "center"
      })
    ]),
    _: 1
  }, 8, ["loadData", "rowModelType", "onFirstDataRendered"]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"400px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_data_grid, {
      ref: "refDataGridView",
      treeData: true,
      isLazyLoadTreeData: false,
      loadData: _ctx.$vm.loadData,
      rowModelType: 'serverSide'
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_k_data_grid_template_column, {
          id: "code",
          headerText: "编码",
          sortable: true,
          dataPropertyName: "code",
          align: "center",
          width: "auto"
        }, {
          default: _withCtx(({cellValue}) => [
            _createElementVNode("span", null, _toDisplayString(cellValue), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "name",
          headerText: "名称",
          sortable: true,
          dataPropertyName: "name",
          align: "center",
          width: "auto"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "material",
          headerText: "物料",
          sortable: true,
          dataPropertyName: "material",
          align: "center",
          width: "auto",
          readonly: true
        })
      ]),
      _: 1
    }, 8, ["loadData"])
  ]))
}
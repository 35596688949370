import { GridCellEditorParams, GridCellRendererParams, KObjectClassGridBaseColumn } from '../../base'
import KCreatorEditor from '.'

/** 对象显示器 */
export class KDataGridCreatorTextColumn extends KObjectClassGridBaseColumn {
  name = 'KDataGridCreatorTextColumn'
  getCellRenderer(params: GridCellRendererParams) {
    const col = (params.cellParams as any).col
    const newParam = { ...col, ...params }
    return <KCreatorEditor {...newParam} />
  }

  getCellEditor(params: GridCellEditorParams) {
    const col = (params.cellParams as any).col
    const newParam = { ...col, ...params }
    return <KCreatorEditor {...newParam} />
  }
}

import { EnumToolStripCompType } from '@kmsoft/upf-core'
import {
  toolStripItemAdd,
  toolStripItemCancelCollection,
  toolStripItemCollection,
  toolStripItemDelete,
  toolStripItemEdit,
  toolStripItemNew,
  toolStripItemRefresh,
  toolStripItemRemove,
  toolStripItemRemoveReceivedDataShare,
  toolStripItemRemoveShare,
  toolStripItemSave,
  toolStripItemShare
} from './base'
import { EnumToolStripItemKeys } from './types'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../object-tool-strip'

//新建
export const toolStripItemCreateFolderObj: ObjectToolStripItem = {
  ...toolStripItemNew,
  visible: true
}

//添加
export const toolStripItemAddFolderObj: ObjectToolStripItem = {
  ...toolStripItemAdd,
  name: EnumToolStripItemKeys.TOOL_STRIP_ADD_RELATION_OBJ,
  visible: true
}
//共享
export const toolStripItemShareFolderObj: ObjectToolStripItem = {
  ...toolStripItemShare,
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SHARE,
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  visible: true
}
/**移除共享 */
export const toolStripItemRemoveShareFolderObj: ObjectToolStripItem = {
  ...toolStripItemRemoveShare,
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE_SHARE,
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
  visible: true
}
/**移除收到的共享 */
export const toolStripItemRemoveReceivedDataShareFolderObj: ObjectToolStripItem = {
  ...toolStripItemRemoveReceivedDataShare,
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE_RECEIVED_SHARE,
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
  visible: true
}
//收藏
export const toolStripItemCollectionFolderObj: ObjectToolStripItem = {
  ...toolStripItemCollection,
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECTION,
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
  visible: true
}
//取消收藏
export const toolStripItemCancelCollectionFolderObj: ObjectToolStripItem = {
  ...toolStripItemCancelCollection,
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CANCEL_COLLECTION,
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
  visible: true
}

//编辑
// export const toolStripItemEditObjRelation: ObjectToolStripItem = {
//   ...toolStripItemEdit,
//   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT,
//   visible: true
// }

//保存
// export const toolStripItemSaveObjRelation: ObjectToolStripItem = {
//   ...toolStripItemSave,
//   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE,
//   visible: false
// }

//移除
export const toolStripItemRemoveFolderObj: ObjectToolStripItem = {
  ...toolStripItemRemove,
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
  visible: true
}
//刷新
export const toolStripItemRefreshFolderObj: ObjectToolStripItem = {
  ...toolStripItemRefresh,
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
  visible: true
}

//删除
// export const toolStripItemDeleteObjRelation: ObjectToolStripItem = {
//   ...toolStripItemDelete,
//   name: EnumToolStripItemKeys.TOOL_STRIP_DELETE_RELATION_OBJ,
//   supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
//   visible: true
// }

//关联列表按钮
export const getFolderToolStrip = (): Array<ObjectToolStripItem> => [
  toolStripItemCreateFolderObj,
  toolStripItemAddFolderObj,
  toolStripItemShareFolderObj,
  toolStripItemCollectionFolderObj,
  toolStripItemRemoveFolderObj,
  toolStripItemRefreshFolderObj,
  toolStripItemRemoveShareFolderObj,
  toolStripItemRemoveReceivedDataShareFolderObj
  // toolStripItemDeleteObjRelation,
  // toolStripItemEditObjRelation
  // toolStripItemSaveObjRelation
]

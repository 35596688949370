import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KTaskEvaluatePropOptions = {
  ...BaseViewPropOptions,
  /** 流程业务id */
  businessId:VuePropTypes.string().def()
}

/** 参数类型 **/
export type KTaskEvaluatePropType = ViewPropsTypeExtract<typeof KTaskEvaluatePropOptions>

/** 事件 */
export const KTaskEvaluateEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KTaskEvaluateEmitsType = ViewEmitsTypeExtract<typeof KTaskEvaluateEventEmits>

import { BaseViewModel, KDataGridViewModel, KDialog, KNotification, KToolStripViewModel, utils } from '@kmsoft/upf-core'
import {
  IConfigGridTableDefinition,
  KConfigGridTableTileEventEmitsType,
  KConfigGridTableTileViewModelPropType
} from './interface'
import { EnumOper, EnumTabType, IColumnDefinition } from '../../../interface'
import { isReqSuccess, showError } from '../../../common/common'
import { IConfigValidDefinition, UpdateConfigValidStatusEventArgs } from '../../sys-config-value/interface'
import { ref } from 'vue'
import { Api } from '@kmsoft/ebf-common'

export default class KConfigGridTableTileViewModel extends BaseViewModel<
  KConfigGridTableTileEventEmitsType,
  KConfigGridTableTileViewModelPropType
> {
  refObjClsToolStrip = ref<KToolStripViewModel>()
  refObjClsGrid = ref<KDataGridViewModel>()

  // 页面表单状态
  formState = ref<IConfigGridTableDefinition>()
  /** 列定义 */
  columns = ref<Array<IColumnDefinition>>([])
  /** 上移是否可用 */
  upEnabled = ref<boolean>(true)
  /** 下移是否可用 */
  downEnabled = ref<boolean>(true)
  /** 是否可编辑 */
  editable = ref<boolean>(true)
  /** 数据是否加载完成 */
  dataLoaded = ref<boolean>(false)
  mode: string

  /** 配置项编码 */
  private defId: string
  /** 树节点ID */
  private treeId: string
  /** 配置项编码 */
  private code: string
  /** 编辑参数 */
  private editParam: string
  /** 初始对象列表 */
  private rows: Array<Record<string, any>>
  /** 选项卡,  系统配置 | 自定义配置*/
  private oper: string

  /**
   * 加载完成函数
   */
  viewDidMount() {
    // 初始化时没有选择行, 禁止上下移动
    this.upEnabled.value = false
    this.downEnabled.value = false
    this.mode = EnumTabType.SYS
  }

  private isEditable(): boolean {
    return this.oper === EnumOper.EDIT
  }

  /**
   * 初始化表单内容
   * @param defId 配置项编码
   * @param treeId 树节点ID
   * @param code 配置项参数
   * @param editParam 配置参数
   * @param oper 操作类型
   */
  public init(defId: string, treeId: string, code: string, editParam: string, oper: string) {
    this.defId = defId
    this.treeId = treeId
    this.code = code
    this.editParam = editParam
    this.oper = oper
    this.editable.value = this.isEditable()

    // 1、根据配置参数初始化表格列头
    if (this.editParam) {
      const json = JSON.parse(this.editParam)
      const values = json.columns as Array<Record<string, any>>
      const columns = [] as Array<IColumnDefinition>
      if (values != null && values.length > 0) {
        for (let i = 0; i < values.length; i++) {
          const column = {
            code: 'value' + (i + 1),
            name: values[i].text
          }
          columns.push(column)
        }
      }
      this.columns.value = columns
    }

    this.loadData()
  }

  /**
   * 加载数据
   */
  private loadData(): void {
    const param = {
      data: [
        {
          defId: this.defId as unknown,
          treeId: this.treeId as unknown
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'loadConfigSysValue', param).then(response => {
      this.loadDataInner(response)
    })
  }

  private loadDataInner(response: any): void {
    if (!isReqSuccess(response)) {
      showError(response)
      return
    }
    const value = response.data ?? {}

    const formState = {
      defId: value?.defId as unknown,
      id: value?.id as unknown,
      code: value?.code,
      name: value?.name,
      value: value?.value,
      remark: value?.remark,
      dataType: value?.dataType,
      editType: value?.editType,
      editParam: value?.editParam
    } as IConfigGridTableDefinition
    this.formState.value = formState
    this.loadGridData()
  }

  /**
   * 加载数据
   */
  private loadGridData(): void {
    const param = {
      data: [
        {
          defId: this.defId as unknown,
          treeId: this.treeId as unknown
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'loadConfigSysGrid', param).then(response => {
      this.loadGridDataInner(response)
    })
  }

  private loadGridDataInner(response: any): void {
    if (!isReqSuccess(response)) {
      showError(response)
      return
    }
    const values = response.data
    const dList = [] as Array<Record<string, any>>
    if (values != null && values.length > 0) {
      for (const value of values) {
        const row = {
          defId: value.defId as unknown,
          id: utils.uuid(32),
          code: value.code,
          remark: value.remark,
          orderId: value.orderId,
          value1: value.value1,
          value2: value.value2,
          value3: value.value3,
          value4: value.value4,
          value5: value.value5,
          value6: value.value6,
          value7: value.value7,
          value8: value.value8,
          value9: value.value9,
          value10: value.value10,
          value11: value.value11,
          value12: value.value12,
          value13: value.value13,
          value14: value.value14,
          value15: value.value15,
          value16: value.value16,
          value17: value.value17,
          value18: value.value18,
          value19: value.value19,
          value20: value.value20
        }
        dList.push(row)
      }
    }
    this.rows = dList
    this.refObjClsGrid.value?.setValue(JSON.parse(JSON.stringify(this.rows)))
    this.dataLoaded.value = true
    if (this.rows.length > 0) {
      // 开启表格编辑
      if (this.isEditable()) {
        this.refObjClsGrid.value?.beginEdit()
      }
    }
  }

  /**
   * 新建
   */
  public doInsert(): void {
    // 开启表格编辑
    this.refObjClsGrid.value?.beginEdit()
    const row = {
      id: utils.uuid(32)
    } as any
    // 有多少字段, 占用多少列
    for (let i = 0; i < this.columns.value.length; i++) {
      row['value' + (i + 1)] = ''
    }
    this.refObjClsGrid.value?.insertRow(row)
  }

  /**
   * 删除
   */
  public doDelete(): void {
    this.refObjClsGrid.value?.removeSelectedRows()
    this.upEnabled.value = false
    this.downEnabled.value = false
  }

  /**
   * 行选择(取消)事件
   * @param selectedEvent 选择事件
   */
  public selectRow(selectedEvent: any): void {
    if (selectedEvent.event !== null) {
      this.upEnabled.value = selectedEvent.rowIndex !== 0
      const rows = this.refObjClsGrid.value?.getRows()!
      this.downEnabled.value = selectedEvent.rowIndex !== rows?.length - 1
    }
  }

  /**
   * 上移
   */
  public doUp(): void {
    const selectedRows = this.refObjClsGrid.value?.getSelectedNodes()
    if (selectedRows == null || selectedRows.length !== 1) {
      KNotification.info({
        message: '必须且只能勾选一行进行移动'
      })
      return
    }
    const currentRow = selectedRows[0]
    if (currentRow.firstChild) {
      KNotification.info({
        message: '当前在第一行，无法上移'
      })
      return
    }
    this.refObjClsGrid.value?.endEdit()
    const currentId = currentRow.id
    const currentIndex = currentRow.rowIndex
    if (currentId === undefined || currentIndex === null) {
      return
    }

    const currentData = currentRow.data
    const newData = {} as any
    for (const key in currentData) {
      newData[key] = currentData[key]
    }

    const allRows = this.refObjClsGrid.value?.getRows()!
    allRows.splice(currentIndex, 1)
    allRows.splice(currentIndex - 1, 0, newData)
    this.refObjClsGrid.value?.setValue(allRows)
    this.upEnabled.value = currentIndex - 1 !== 0
    this.downEnabled.value = true

    this.refObjClsGrid.value?.beginEdit()
  }

  /**
   * 下移
   */
  public doDown(): void {
    const selectedRows = this.refObjClsGrid.value?.getSelectedNodes()
    if (selectedRows == null || selectedRows.length !== 1) {
      KNotification.info({
        message: '必须且只能勾选一行进行移动'
      })
      return
    }
    const currentRow = selectedRows[0]
    if (currentRow.lastChild) {
      KNotification.info({
        message: '当前在最后一行，无法下移'
      })
      return
    }
    this.refObjClsGrid.value?.endEdit()
    const currentId = currentRow.id
    const currentIndex = currentRow.rowIndex
    if (currentId === undefined || currentIndex === null) {
      return
    }

    const currentData = currentRow.data
    const newData = {} as any
    for (const key in currentData) {
      newData[key] = currentData[key]
    }

    const allRows = this.refObjClsGrid.value?.getRows()!
    allRows.splice(currentIndex, 1)
    allRows.splice(currentIndex + 1, 0, newData)
    this.refObjClsGrid.value?.setValue(allRows)
    this.downEnabled.value = currentIndex + 1 !== allRows.length - 1
    this.upEnabled.value = true
    this.refObjClsGrid.value?.beginEdit()
  }

  /**
   * 校验
   */
  public check(): void {
    const param = {
      data: [
        {
          treeId: this.treeId as unknown,
          defId: this.defId as unknown,
          code: this.code
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'checkConfigSysValid', param).then(response => {
      this.checkInner(response)
    })
  }

  private checkInner(response: any): void {
    if (!isReqSuccess(response)) {
      showError(response)
      return
    }
    if (response.data?.success) {
      KNotification.success({ title: '提示', content: '校验成功' })
    } else {
      KNotification.error({
        title: '提示',
        content: response.data?.message || ''
      })
    }
  }

  /**
   * 保存
   */
  public save(): void {
    this.refObjClsGrid.value?.endEdit()
    const values = this.refObjClsGrid.value?.getValue()
    const saveList = []
    if (values != null && values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        const data = {} as any
        for (const key in values[i]) {
          data[key] = values[i][key]
        }
        data.orderId = i + 1
        data.id = null
        saveList.push(data)
      }
    }
    const param = {
      data: [
        {
          treeId: this.treeId as unknown,
          defId: this.defId as unknown,
          code: this.code,
          valueList: saveList
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'updateConfigSysGrid', param).then(response => {
      this.saveInner(response)
    })
  }

  private saveInner(response: any): void {
    this.refObjClsGrid.value?.beginEdit()
    if (!isReqSuccess(response)) {
      showError(response)
      return
    }
    // 更新初始值
    this.rows = JSON.parse(JSON.stringify(this.refObjClsGrid.value?.getValue()))
    KNotification.success({ title: '提示', content: '保存成功' })

    // 发布事件通知
    const validStatus = response.data as IConfigValidDefinition
    validStatus.defId = this.defId
    this.emit('updateConfigValidStatus', new UpdateConfigValidStatusEventArgs(validStatus))
  }

  private valueChanged(): boolean {
    const currentRows = this.refObjClsGrid.value?.getRows()!
    let changed = false
    if (currentRows.length !== this.rows.length) {
      changed = true
    }
    if (!changed) {
      for (let i = 0; i < currentRows.length; i++) {
        for (const key in currentRows[i]) {
          if (currentRows[i][key] !== this.rows[i][key]) {
            changed = true
            break
          }
        }
      }
    }
    return changed
  }

  /**
   * 刷新
   */
  public refresh(): void {
    this.refObjClsGrid.value?.endEdit()
    if (this.valueChanged()) {
      KDialog.confirm({
        title: '存在数据修改，是继续保存，还是放弃修改？',
        okText: '继续保存',
        cancelText: '放弃修改',
        onCancel: async () => {
          this.loadData()
        },
        onOk: async () => {
          this.save()
        }
      })
    }

    this.refObjClsGrid.value?.beginEdit()
  }

  /**
   * 完成编辑
   */
  public editOut(): void {
    this.save()
    this.refObjClsGrid.value?.endEdit(true)
    this.editable.value = false
  }

  /**
   * 编辑
   */
  public editIn(): void {
    this.refObjClsGrid.value?.beginEdit()
    this.editable.value = true
  }
}


import { defineView, KDialog, KDialogClosingEvent } from '@kmsoft/upf-core'
import _ from 'lodash'
import KFilterGridSearchBarConfig from './config-panel'
import { KFilterGridSearchBarConfigViewModel } from './config-panel'
import { KFilterGridSearchBarDesignerEventEmits, KFilterGridSearchBarDesignerPropOptions } from './interface'
import { KFilterGridSearchBarDesignerViewModel } from './KFilterGridSearchBarDesignerViewModel'

/**
 * 筛选网格搜索栏定义器
 */
export default defineView({
  name: 'KFilterGridSearchBarDesigner',
  viewModel: KFilterGridSearchBarDesignerViewModel,
  props: KFilterGridSearchBarDesignerPropOptions,
  emits: KFilterGridSearchBarDesignerEventEmits,
  setup(props, { vm }) {
    const openGridDesigner = () => {
      KDialog.show({
        title: '搜索栏配置',
        content: KFilterGridSearchBarConfig,
        props: {
          designerMetaControl: props.designerMetaControl,
          dataSource: props.dataSource,
          schema: _.cloneDeep(vm.stateValue.value)
        },
        size: { width: 1024, height: 608 },
        async onClosing(event: KDialogClosingEvent) {
          if (event.dialogResult === 'Confirm') {
            const viewModel = event.viewModel as KFilterGridSearchBarConfigViewModel
            const value = viewModel.getValue()
            vm.setValue(value)
          }
        }
      })
    }
    return () => {
      return (
        <div>
          <k-button onClick={openGridDesigner} style="width:100%">
            设置参数
          </k-button>
        </div>
      )
    }
  }
})


import { defineView, ToolStripItemClickedEventArgs, ToolStripItemChangeEventArgs } from '@kmsoft/upf-core'
import { KMetricsEditPanelPropOptions, KMetricsEditPanelEventEmits } from './interface'
import KMetricsEditPanelViewModel from './KMetricsEditPanelViewModel'

export default defineView({
  name: 'KMetricsEditPanel',
  props: KMetricsEditPanelPropOptions,
  emits: KMetricsEditPanelEventEmits,
  viewModel: KMetricsEditPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import {
  BaseViewModel,
  EnumDialogResult,
  IKTreeNode,
  KDataGridRowData,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  KTabsViewModel,
  KTreeViewViewModel,
  TreeViewExpandEventArgs,
  utils,
  ValueChangeEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KPartDocBomCompareEmitsType, KPartDocBomComparePropType } from './interface'
import { ref, watch } from 'vue'
import { KBomCompareResultPanel, KBomCompareResultPanelViewModel } from '../part-bom-compare/panel/bom-compare-result-panel'
import { IRootTreeNodeParam } from '../part-bom-compare/panel/bom-compare-result-panel/interface'
import { Api, EnumClassTemplate, EnumRequestCode, ObjectClientSrv } from '@kmsoft/ebf-common'
import {
  KVersionGroupObjectSelectForm,
  KVersionGroupObjectSelectFormViewModel
} from '../part-bom-compare/form/version-group-object-select-form'
import { KPartBomCompareModeForm, KPartBomCompareModeFormViewModel } from '../part-bom-compare/form/part-bom-compare-mode-form'
import { KBaselineGrid } from '../../../../../ebf-baseline/src'
import { KBaselineGridViewModel } from '../../../../../ebf-baseline/src/pages/baseline-grid'
import { KDocBomCompareResultPanel, KDocBomCompareResultPanelViewModel } from './panel/doc-bom-compare-result-panel'
import { KPartDocBomCompareModeForm } from './part-doc-bom-compare-mode-form'
import { KObjectViewSelectorViewModel } from '@kmsoft/ebf-common/src/controls/object-view-selector'

/** KPartDocBomCompare */
export default class KPartDocBomCompareViewModel extends BaseViewModel<KPartDocBomCompareEmitsType, KPartDocBomComparePropType> {
  /**左侧树组件 */
  refLeftFolderTree = ref<KTreeViewViewModel>()
  /**左侧树根节点Id */
  leftTreeRootNode = ref<any>()
  /**右侧树组件 */
  refRightFolderTree = ref<KTreeViewViewModel>()
  /**右侧树根节点Id */
  rightTreeRootNode = ref<any>()
  /**左侧树展开类型 */
  leftTreeExpandType = ref<string>('LATEST_WITHOUT_WORKING_COPY')
  /**比较结果网格 */
  refCompareDataGrid = ref<KBomCompareResultPanelViewModel>()
  /**比较结果选中Id */
  compareResultSelectedId = ref<string>()
  /**差异件是否展开 */
  expandDifferentialParts = ref<boolean>(false)
  /** 基线Id */
  baselineId = ref<string>()

  refGlobalTab = ref<KTabsViewModel>()
  refBomCompare = ref<HTMLElement>()
  /**
   * 左树根节点对象
   */
  leftTreeRootNodeParam = ref<IRootTreeNodeParam>()
  /**
   * 右树根节点对象
   */
  rightTreeRootNodeParam = ref<IRootTreeNodeParam>()
  /**
   * 比较结果
   */
  refCompareResult = ref<Array<KDataGridRowData>>([])
  leftViewDisabled = ref<boolean>(false)

  actualLeftTreeRootNode = ref<any>()

  /**
   * 比较规则是否应用到根
   */
  applyToRoot = ref<boolean>(false)
  /** 左侧树视图选择器 */
  refLeftViewSelector = ref<KObjectViewSelectorViewModel>()
  /** 左侧树视图 */
  refLeftView = ref<string>()

  constructor(options: ViewModelOptions<KPartDocBomComparePropType>) {
    super(options)
    if (
      options.props.leftPart &&
      !(Object.keys(options.props.leftPart).length === 0 && options.props.leftPart.constructor === Object)
    ) {
      const leftPart = options.props.leftPart!
      this.leftTreeRootNode.value = {
        id: leftPart.id,
        modelCode: leftPart.className,
        extensionType: leftPart.extensionType
      }
      this.actualLeftTreeRootNode.value = this.leftTreeRootNode.value
      this.refLeftView.value = leftPart.view
    }
    if (
      options.props.rightPart &&
      !(Object.keys(options.props.rightPart).length === 0 && options.props.rightPart.constructor === Object)
    ) {
      const rightPart = options.props.rightPart!
      this.rightTreeRootNode.value = { id: rightPart.id, modelCode: rightPart.className, extensionType: rightPart.extensionType }
    }
    this.applyToRoot.value = options.props.applyToRoot
    watch(
      () => this.leftTreeRootNode.value,
      () => {
        this.refLeftFolderTree.value?.refresh()
      },
      {
        immediate: true
      }
    ),
      watch(
        () => this.rightTreeRootNode.value,
        () => {
          this.refLeftFolderTree.value?.refresh()
        },
        {
          immediate: true
        }
      ),
      watch(
        () => this.refCompareResult.value,
        () => {
          this.refLeftFolderTree.value?.refresh()
          this.refRightFolderTree.value?.refresh()
        },
        {
          immediate: true
        }
      )
  }

  viewDidMount() {}
  /**
   * 左侧树加载数据
   */
  loadLeftTreeData(): Promise<Array<IKTreeNode>> {
    return new Promise(resolve => {
      if (!this.leftTreeRootNode.value || this.leftTreeRootNode.value.id == '') {
        resolve([])
        return
      }
      const param = {
        data: [
          {
            root: {
              id: this.leftTreeRootNode.value.id,
              clazz: this.leftTreeRootNode.value.extensionType
            },
            queryRule: {
              type: this.leftTreeExpandType.value
            },
            applyToRoot: this.applyToRoot.value,
            view: this.refLeftView.value
          }
        ]
      } as any
      // 如果展开类型是基线则参数加上基线信息
      if (this.leftTreeExpandType.value == 'BASELINE') {
        param.data[0].queryRule.baseline = {
          id: this.baselineId.value,
          clazz: 'ManagedBaseline'
        }
      }
      Api.post('part', 'Part', 'expandForTree', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
          if (res.data.length > 0) {
            const rootNode = res.data.filter((data: any) => !data.pid)
            this.actualLeftTreeRootNode.value = rootNode[0].userObject.part
            if (rootNode) {
              const treeArray = this.buildTree(res.data, rootNode.id)
              resolve(treeArray)
              return
            }
          }
        } else {
          KNotification.error({
            title: '系统错误',
            content: res.message
          })
        }
        resolve([])
      })
    })
  }
  /**
   * 视图选择后刷新左侧树
   */
  refreshLeftTreeAfterView(args: ValueChangeEventArgs<any>) {
    if (args.newValue == null) {
      return
    }
    if (!this.leftTreeRootNode.value) {
      return
    }
    this.refLeftView.value = args.newValue
    this.refLeftFolderTree.value?.refresh()
  }
  /**
   * 右侧树加载数据
   */
  loadRightTreeData(): Promise<Array<IKTreeNode>> {
    return new Promise(resolve => {
      if (!this.rightTreeRootNode.value || this.rightTreeRootNode.value!.id == '') {
        resolve([])
        return
      }
      const param = {
        data: [
          {
            root: {
              id: this.rightTreeRootNode.value.id,
              clazz: this.rightTreeRootNode.value.extensionType
            }
          }
        ]
      } as any

      Api.post('part', 'Part', 'expandByDoc', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
          if (res.data.length > 0) {
            const rootNode = res.data.filter((data: any) => data.userObject.id == this.rightTreeRootNode.value.id)
            if (rootNode) {
              const treeArray = this.buildTree(res.data, rootNode.id)
              resolve(treeArray)
              return
            }
          }
        } else {
          KNotification.error({
            title: '系统错误',
            content: res.message
          })
        }
        resolve([])
      })
    })
  }

  /**
   * 构建树
   */
  buildTree(array: Array<IKTreeNode>, pid: string): Array<IKTreeNode> {
    const result = [] as Array<IKTreeNode>
    array.forEach(item => {
      if (item.pid == pid) {
        item.children = this.buildTree(array, item.id)
        result.push(item)
      }
    })
    return result
  }

  /**
   * 关闭右侧bom树
   */
  close() {
    this.rightTreeRootNode.value = null
    this.compareResultSelectedId.value = ''
    this.refCompareResult.value = []
    this.refLeftFolderTree.value?.refresh()
  }

  /**
   * 比较bom树
   */
  compareBom() {
    KDialog.show({
      title: '比较',
      content: KPartDocBomCompareModeForm,
      props: {
        partId: this.rightTreeRootNode.value!.id
      },
      size: { width: 300, height: 200 },
      getContainer: this.refBomCompare.value,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewInstance as KPartBomCompareModeFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const selectRow = formViewModel.getFormValue()
        this.expandDifferentialParts.value = selectRow?.expandDifferentialParts ? selectRow?.expandDifferentialParts : false

        this.leftTreeRootNodeParam.value = {
          root: { id: this.leftTreeRootNode.value.id, modelCode: this.leftTreeRootNode.value.extensionType },
          queryRule: {
            type: this.leftTreeExpandType.value,
            baseline:
              this.leftTreeExpandType.value == 'BASELINE'
                ? { id: this.baselineId.value!, modelCode: 'ManagedBaseline' }
                : undefined
          },
          applyToRoot: this.applyToRoot.value,
          view: this.refLeftView.value
        }
        this.rightTreeRootNodeParam.value = {
          root: { id: this.leftTreeRootNode.value.id, modelCode: this.leftTreeRootNode.value.extensionType }
        }

        const activeMenuKey = this.refGlobalTab.value?.getActiveKey()
        if (selectRow?.openNewTabShowResult || !activeMenuKey || activeMenuKey == '') {
          this.openPage()
          return
        }
        const result = this.refGlobalTab.value?.existsTab(activeMenuKey)
        if (result) {
          const vm = this.getByRecursion(activeMenuKey) as KDocBomCompareResultPanelViewModel
          vm.reload(
            this.leftTreeRootNodeParam.value,
            this.rightTreeRootNodeParam.value,
            this.expandDifferentialParts.value,
            this.applyToRoot.value
          )
        } else {
          this.openPage()
          return
        }
      }
    })
  }

  /**
   *
   * @param rows 获取比较结果
   */
  getCompareResult(rows: Array<KDataGridRowData>) {
    this.refCompareResult.value = rows
  }

  changeTreeNodeColor() {
    const rows = this.refCompareResult.value
    if (rows && rows.length > 0 && this.rightTreeRootNode.value) {
      // 改变节点颜色
      rows.forEach(row => {
        switch (row.desc) {
          case '左树独有':
            {
              // 根据路径获取节点信息
              const leftNode = this.refLeftFolderTree.value?.getNode(row.masterIdFullPath)
              // 设置节点类型
              if (leftNode) {
                leftNode.type = 'leftUnique'
              }
            }
            break
          case '右树独有':
            {
              // 根据路径获取节点信息
              const rightNode = this.refRightFolderTree.value?.getNode(row.masterIdFullPath)
              // 设置节点类型
              if (rightNode) {
                rightNode.type = 'rightUnique'
              }
            }
            break
          default:
            break
        }
      })
    }
  }

  /**
   * 设置右侧树选中
   */
  setRightNodeSelected(event: TreeViewExpandEventArgs<any>) {
    if (!this.rightTreeRootNode.value || this.rightTreeRootNode.value.id == '') {
      return
    }
    this.compareResultSelectedId.value = event.node.uniqueIdentifier
  }

  /**
   * 右侧树选中事件
   */
  setLeftNodeSelected(event: TreeViewExpandEventArgs<any>) {
    if (!this.refLeftFolderTree.value || this.leftTreeRootNode.value.id == '') {
      return
    }
    this.compareResultSelectedId.value = event.node.uniqueIdentifier
  }

  /**
   * 设置树选中
   */
  setSelectTreeNode(rows: Array<KDataGridRowData>) {
    if (rows.length > 0) {
      const leftNode = this.refLeftFolderTree.value?.getNode(rows[0].masterIdFullPath)
      const rightNode = this.refRightFolderTree.value?.getNode(rows[0].masterIdFullPath)
      /**清除选中 */

      if (leftNode) {
        this.refLeftFolderTree.value?.expandTo(leftNode.uniqueIdentifier)
        this.refLeftFolderTree.value?.setSelectedNode(leftNode.uniqueIdentifier)
      } else {
        this.refLeftFolderTree.value?.clearSelected()
      }
      if (rightNode) {
        this.refRightFolderTree.value?.expandTo(rightNode.uniqueIdentifier)
        this.refRightFolderTree.value?.setSelectedNode(rightNode.uniqueIdentifier)
      } else {
        this.refRightFolderTree.value?.clearSelected()
      }
    }
  }
  /**
   * 刷新左侧树
   */
  refreshLeftTree(args: ValueChangeEventArgs<any>) {
    if (args.newValue == null) {
      return
    }
    this.leftViewDisabled.value = args.newValue == 'BASELINE'
    // 如果选择基线，则打开基线选择弹框
    if (args.newValue == 'BASELINE') {
      //todo 打开基线选择弹框
      KDialog.show({
        title: '选择基线',
        size: { width: 800, height: 600 },
        props: {
          primaryObjectId: this.leftTreeRootNode.value.id
        },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KBaselineGrid,
        getContainer: this.refBomCompare.value,
        onClosing: async (event: KDialogClosingEvent) => {
          const formViewModel = event.viewModel as KBaselineGridViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          const selectRow = formViewModel.getSelectRow()
          if (!selectRow) {
            KNotification.warning({
              title: '系统提示',
              content: '请选择一个基线'
            })
            event.cancel = true
            return
          }
          this.baselineId.value = selectRow.id
          this.refLeftFolderTree.value?.refresh()
        }
      })
      return
    }
    this.refLeftFolderTree.value?.refresh()
  }
  /**
   * 刷新右侧树
   */
  refreshRightTree(args: ValueChangeEventArgs<any>) {
    if (args.newValue == null) {
      return
    }
    // 如果选择基线，则打开基线选择弹框
    if (args.newValue == 'BASELINE') {
      //todo 打开基线选择弹框
      KDialog.show({
        title: '选择基线',
        size: { width: 800, height: 600 },
        props: {
          primaryObjectId: this.leftTreeRootNode.value.id
        },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KBaselineGrid,
        getContainer: this.refBomCompare.value,
        onClosing: async (event: KDialogClosingEvent) => {
          const formViewModel = event.viewModel as KBaselineGridViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          const selectRow = formViewModel.getSelectRow()
          if (!selectRow) {
            KNotification.warning({
              title: '系统提示',
              content: '请选择一个基线'
            })
            event.cancel = true
            return
          }
          this.refRightFolderTree.value?.refresh()
        }
      })
      return
    }
    this.refRightFolderTree.value?.refresh()
  }

  openPage() {
    if (!this.refGlobalTab.value) return
    const key = utils.uuid()

    if (!this.refGlobalTab.value?.existsTab(key)) {
      const expandDifferentialParts = this.expandDifferentialParts.value ? '差异件展开' : '差异件不展开'
      const title = expandDifferentialParts
      this.refGlobalTab.value?.addTab(key, title, KDocBomCompareResultPanel, {
        ref: 'refBomResult',
        name: key,
        source: this.leftTreeRootNodeParam.value,
        target: this.rightTreeRootNodeParam.value,
        expandDifferentialParts: this.expandDifferentialParts.value,
        applyToRoot: this.applyToRoot.value,
        onLoadReady: (rows: Array<KDataGridRowData>) => this.getCompareResult(rows)
      })
    }
    this.refGlobalTab.value?.active(key)
  }
}

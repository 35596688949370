import { connect, withInstall } from '@kmsoft/upf-core'
import KPartReportMakeView from './KPartReportMake.vue'
import KPartReportMakeViewModel from './KPartReportMakeViewModel'

const KPartReportMake = connect(KPartReportMakeView, KPartReportMakeViewModel)

export default withInstall(KPartReportMake)
export { KPartReportMake, KPartReportMakeView, KPartReportMakeViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_property_edit_panel = _resolveComponent("k-object-property-edit-panel")!

  return (_openBlock(), _createBlock(_component_k_object_property_edit_panel, {
    ref: _ctx.$vm.refObjectProperty,
    readonly: true,
    objParam: _ctx.$vm.docParam,
    toolStripItems: _ctx.$vm.toolStripItems,
    toolStripOptions: _ctx.$vm.toolStripOptions,
    load: () => _ctx.$vm.onLoadData(),
    extendedAttributes: "",
    extendedPosition: 2,
    onToolStripItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onToolStripItemClicked(event)),
    onToolStripItemChange: _cache[1] || (_cache[1] = (event)=>_ctx.$vm.onToolStripItemChange(event))
  }, null, 8, ["objParam", "toolStripItems", "toolStripOptions", "load"]))
}
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  KBaseElement,
  KGridLayoutConfig
} from '@kmsoft/upf-core'

/** 参数 **/
export const KViewRenderElementWrapPropOptions = {
  ...BaseViewPropOptions,
  meta: VuePropTypes.createType<KBaseElement>()
    .setRequired()
    .def(),
  parentLayout: VuePropTypes.createType<KGridLayoutConfig>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KViewRenderElementWrapPropType = ViewPropsTypeExtract<typeof KViewRenderElementWrapPropOptions>

/** 事件 */
export const KViewRenderElementWrapEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KViewRenderElementWrapEmitsType = ViewEmitsTypeExtract<typeof KViewRenderElementWrapEventEmits>

import { computed, getCurrentInstance, nextTick, Ref, ref, watch } from 'vue'
import {
  BaseViewModel,
  ViewModelOptions,
  ToolStripItemClickedEventArgs,
  EnumToolStripCompType,
  KDataGridViewModel,
  KNotification,
  KDialog,
  SimpleViewModel,
  EnumDialogResult,
  KInputViewModel,
  KDialogClosingEvent
} from '@kmsoft/upf-core'
import { KBaselineMemberGridEmitsType, KBaselineMemberGridPropType } from './interface'
import {
  EnumToolStripItemKeys,
  Api,
  EnumLifecycleState,
  ObjectClientSrv,
  KObjectSelectorViewModel,
  KObjectClassGridViewModel,
  EnumWorkingState
} from '@kmsoft/ebf-common'
import { KBaselinePrimaryObjectViewModel } from '../baseline-primary-object'
import { KBaselineMemberReplaceGridViewModel, KBaselineMemberReplaceGrid } from './baseline-member-replace-grid'
import lodash from 'lodash'

/** KBaselineMemberGrid */
export default class KBaselineMemberGridViewModel extends SimpleViewModel<
  KBaselineMemberGridEmitsType,
  KBaselineMemberGridPropType
> {
  // 对象类网格
  refObjGrid = ref<KObjectClassGridViewModel>()
  modified = ref<boolean>(false)
  toolItem = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
      title: '添加',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'minus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SET_PRIMARY_OBJ,
      title: '设为主对象',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REPLACE_MEMBER,
      title: '替换成员',
      icon: 'control',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  gridData = ref<any[]>([])

  constructor(options: ViewModelOptions<KBaselineMemberGridPropType>) {
    super(options)

    watch(
      () => this.props.readonly,
      newValue => {
        this.refObjGrid.value?.setToolStripDisabled(newValue as boolean)
      }
    )
  }

  viewDidMount() {}

  /**
   * 基线成员工具栏按钮点击事件
   * @param event
   */
  toolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.addBaselineMember()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.removeBaselineMember()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SET_PRIMARY_OBJ:
        this.setPrimaryObj()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REPLACE_MEMBER:
        this.replaceMember()
        break
      default:
        break
    }
  }

  /**
   * 刷新基线成员
   */
  public refresh() {
    this.refObjGrid.value?.refresh()
  }

  /**
   * 添加基线成员
   */
  private addBaselineMember() {
    // 打开对象查找选择框，但仅限于文档跟零部件
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Part', 'Document']
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Part', 'Document']
      },
      getContainer: this.refObjGrid.value?.getContainer(),
      onClosing: async event => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        // 获取当前弹出组件实例的ViewModel
        const selectorViewModel = event.viewModel as KObjectSelectorViewModel
        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows()

        let hasWorkingCopy = false
        selectedRows.forEach(item => {
          if (item?.workingCopy) {
            KNotification.warn({
              message: '系统提示',
              description: '选择数据行中存在工作副本数据，无法添加！'
            })
            hasWorkingCopy = true
            event.cancel = true
            return
          }
        })
        if (hasWorkingCopy) {
          return // 在外部函数中终止逻辑
        }

        this.addGridData(selectedRows)
      }
    })
  }

  addGridData(gridData: any[]) {
    const repeatData: any[] = []
    gridData.forEach(item => {
      if (this.gridData.value?.some(row => row?.master?.id === item?.master?.id)) {
        repeatData.push(item)
      }
    })

    if (repeatData.length) {
      const rowNames = repeatData.map(item => this.getRowName(item)).join(',')
      KDialog.confirm({
        title: '系统提示',
        content: `【${rowNames}】存在重复数据，是覆盖原有数据？`,
        onOk: () => {
          this.setGridData(gridData, repeatData, true)
        },
        onCancel: () => {
          this.setGridData(gridData, repeatData, false)
        }
      })
    } else {
      this.setGridData(gridData, repeatData, false)
    }
  }

  /**
   *
   * @param gridData 添加数据
   * @param repeatData 重复数据
   * @param replace 是否替换原有数据
   */
  setGridData(gridData: any[], repeatData: any[], replace: boolean = false) {
    let addDataList = []
    if (replace) {
      // 去掉原始数据中重复数据
      this.gridData.value = this.gridData.value.filter(item => !repeatData.some(row => row?.master?.id === item?.master?.id))
      addDataList =
        gridData.map((item: any) => {
          // 版本
          item.versionInfo = `${item.version}.${item.iteration}`
          // 状态
          item.state = item.lifecycleStateCode ? EnumLifecycleState[item.lifecycleStateCode] : '--'
          // 检入标记
          item.checkInState = item.workingState ? EnumWorkingState[item.workingState as keyof typeof EnumWorkingState] : '--'
          return item
        }) || []
    } else {
      // 去掉添加数据中重复数据
      addDataList =
        gridData
          .filter(item => !repeatData.some(row => row?.master?.id === item?.master?.id))
          .map((item: any) => this.propertyConvert(item)) || []
    }
    this.modified.value = true
    this.gridData.value.push(...addDataList)
    this.refresh()
  }

  propertyConvert(item: any) {
    // 版本
    item.versionInfo = `${item.version}.${item.iteration}`
    // 状态
    item.state = item.lifecycleStateCode ? EnumLifecycleState[item.lifecycleStateCode] : '--'
    // 检入标记
    item.checkInState = item.workingState ? EnumWorkingState[item.workingState as keyof typeof EnumWorkingState] : '--'
    return item
  }

  getRowName(row: any) {
    return `${row.name}【${row.number}】_${row.version}.${row.iteration}`
  }

  /**
   * 移除基线成员
   */
  removeBaselineMember() {
    const selectedRows = this.refObjGrid.value?.getSelectedRows() || []
    if (selectedRows.length > 0) {
      this.modified.value = true
      this.gridData.value = this.gridData.value.filter(item => !selectedRows.some(row => row.id === item.id))
      this.clearSelectedRows()
      this.refresh()
    }
  }

  clearSelectedRows() {
    // @ts-ignore  this.refObjGrid.value?.refDataGrid返回为KDataGridViewModel,
    this.refObjGrid.value?.refDataGrid.clearSelectedRows()
  }

  loadData(qry: any) {
    return this.listBaselineMembers(qry)
  }

  /**
   * 查询零部件列表
   *
   * @returns
   */
  async listBaselineMembers(qry: any) {
    // 先不要分页
    return { total: this.gridData.value?.length, rows: this.gridData.value }
  }

  isModified(): boolean {
    // 编辑时默认认为修改
    return this.modified.value
  }

  getModifiedValue() {
    return this.getValue()
  }

  public setValue(newValue: any, setChanged?: boolean) {
    // 赋值的数据已经过滤过，不需要再过滤
    this.gridData.value = lodash.cloneDeep(newValue || [])
    this.refresh()
  }

  public getValue() {
    return this.gridData.value
  }

  /**
   * 设为主要对象
   */
  public setPrimaryObj() {
    const selectedRows = this.refObjGrid.value?.getSelectedRows() || []
    if (selectedRows.length > 0) {
      if (selectedRows.length > 1) {
        KNotification.info({
          message: '请勾选一条数据操作！'
        })
      } else {
        const row = selectedRows[0]
        KDialog.confirm({
          title: `确认要将【${row.name}】设为主要对象么？`,
          okText: '确认',
          cancelText: '取消',
          onCancel: async () => {},
          onOk: async () => {
            const KBaselinePrimaryObjectViewModel = this.getByRecursion(
              'KBaselinePrimaryObject',
              this.props.api.getCurrentPanelViewModel() as BaseViewModel
            ) as KBaselinePrimaryObjectViewModel

            KBaselinePrimaryObjectViewModel.setSelectedObject(row)

            this.refresh()
          }
        })
      }
    }
  }

  replaceMember() {
    const selectedRows = this.refObjGrid.value?.getSelectedRows() || []
    if (selectedRows.length > 0) {
      if (selectedRows.length > 1) {
        KNotification.info({
          message: '请勾选一条数据操作！'
        })
      } else {
        const row = selectedRows[0]
        KDialog.show({
          title: '替换成员',
          size: { width: 800, height: 600 },
          props: {
            id: row?.master?.id,
            className: row?.className
          },
          showApply: false,
          maximizeBox: false,
          minimizeBox: false,
          content: KBaselineMemberReplaceGrid,
          onClosing: async (event: KDialogClosingEvent) => {
            if (event.dialogResult == EnumDialogResult.Cancel) return
            if (event.dialogResult == EnumDialogResult.Close) return

            const formViewModel = event.viewModel as KBaselineMemberReplaceGridViewModel
            const replaceSelectedRows = formViewModel.getSelectedRows()
            const replaceRow = replaceSelectedRows.length == 0 ? null : replaceSelectedRows[0]

            if (replaceRow) {
              const findIndex = this.gridData.value.findIndex(item => item.id === row.id)
              if (findIndex >= 0) {
                this.modified.value = true
                this.gridData.value.splice(findIndex, 1, replaceRow)
                this.clearSelectedRows()
                this.refresh()
              }
            }
          }
        })
      }
    }
  }
}

/**指定控制绑定以及通过反射执行成员和类型搜索的方式的标记。*/
export enum BindingFlags {
  /**指定未定义任何绑定标志。*/
  Default = 0,
  /**指定在绑定时不应考虑成员名称的大小写。*/
  IgnoreCase = 1,
  /**指定只应考虑在所提供类型的层次结构级别上声明的成员。 不考虑继承的成员。*/
  DeclaredOnly = 2,
  /**指定实例成员要包括在搜索中。*/
  Instance = 4,
  /**指定静态成员要包括在搜索中。*/
  Static = 8,
  /**指定公共成员要包括在搜索中。*/
  Public = 16,
  /**指定非公共成员要包括在搜索中。*/
  NonPublic = 32,
  /**指定应返回层次结构往上的公共成员和受保护静态成员。 不返回继承类中的私有静态成员。 静态成员包括字段、方法、事件和属性。 不支持嵌套类型。*/
  FlattenHierarchy = 64,
  /**指定要调用的方法。 这不必是构造函数或类型初始值设定项。*/
  InvokeMethod = 256,
  /**指定反射应创建指定类型的实例。 调用与给定参数匹配的构造函数。 忽略提供的成员名称。 如果未指定查找的类型，则应用“（实例 | 公共）”。 不能调用类型初始值设定项。*/
  CreateInstance = 512,
  /**指定应返回指定字段的值。*/
  GetField = 1024,
  /**指定应设置指定字段的值。*/
  SetField = 2048,
  /**指定应返回指定属性的值。*/
  GetProperty = 4096,
  /**指定应设置指定属性的值。 对于 COM 属性，指定此绑定标志等效于指定 {@link PutDispProperty} 和 {@link PutRefDispProperty}。*/
  SetProperty = 8192,
  /**指定应调用 COM 对象上的 PROPPUT 成员。 PROPPUT 指定使用值的属性设置函数。 如果属性同时具有 PROPPUT 和 PROPPUTREF 并且你需要区分调用哪一个，请使用 {@link PutDispProperty}。*/
  PutDispProperty = 16384,
  /**指定应调用 COM 对象上的 PROPPUTREF 成员。 PROPPUTREF 指定使用引用而不是值的属性设置函数。 如果属性同时具有 PROPPUT 和 PROPPUTREF 并且你需要区分调用哪一个，请使用 {@link PutRefDispProperty}。*/
  PutRefDispProperty = 32768,
  /**指定提供的参数的类型必须与对应形参的类型完全匹配。 如果调用方提供非 null Binder 对象，则反射会引发异常，因为这意味着调用方在提供将选取适当方法的 BindToXXX 实现。 当自定义绑定器可实现此标志的语义时，默认绑定器会忽略此标志。*/
  ExactBinding = 65536,
  /**未实现。*/
  SuppressChangeType = 131072,
  /**返回其参数计数与提供的参数数量匹配的成员集。 此绑定标志用于参数具有默认值的方法和使用变量参数 (varargs) 的方法。*/
  OptionalParamBinding = 262144,
  /**在 COM 互操作中用于指定可以忽略成员的返回值。*/
  IgnoreReturn = 16777216,
  DoNotWrapExceptions = 33554432
}

import { BaseViewModel, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KAppendChangeActivityEmitsType, KAppendChangeActivityPropType } from './interface'

import { ref } from 'vue'

/** KAppendChangeActivity */
export default class KAppendChangeActivityViewModel extends BaseViewModel<
  KAppendChangeActivityEmitsType,
  KAppendChangeActivityPropType
> {
  refDataGrid = ref<KDataGridViewModel>()
  constructor(options: ViewModelOptions<KAppendChangeActivityPropType>) {
    super(options)
  }

  viewDidMount() {}

  getSelectedRows() {
    return this.refDataGrid.value?.getSelectedRows() || []
  }
}


import { defineView } from '@kmsoft/upf-core'
import { EnumLifecycleState, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KChangeTracingPropOptions, KChangeTracingEventEmits } from './interface'
import KChangeTracingViewModel from './KChangeTracingViewModel'

export default defineView({
  name: 'KChangeTracing',
  props: KChangeTracingPropOptions,
  emits: KChangeTracingEventEmits,
  viewModel: KChangeTracingViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumLifecycleState, EnumLifecycleStateColor }
  }
})

import {
  BaseViewModel,
  EnumDialogResult,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KBaselineCompareEmitsType, KBaselineComparePropType } from './interface'
import { computed, nextTick, ref } from 'vue'
import { Api, EnumRequestCode, EnumLifecycleState, clsCodeMap } from '@kmsoft/ebf-common'
import { BaselineCompareGridDataInter, PropertyDiffInfoInter } from './baseline-compare-grid/interface'
import { BaselineCompareGridDiffDataInter } from './baseline-compare-grid-diff/interface'
import { KBaselineCompareGridDialog, KBaselineCompareGridDialogViewModel } from '../baseline-compare/baseline-compare-grid-dialog'
import lodash, { result } from 'lodash'
import { KBaselineCompareGridDiffViewModel } from './baseline-compare-grid-diff'

/** KBaselineCompare */
export default class KBaselineCompareViewModel extends BaseViewModel<KBaselineCompareEmitsType, KBaselineComparePropType> {
  refCompareGridDiff = ref<KBaselineCompareGridDiffViewModel>()

  searchData = ref<{ objCls: string[]; filterType: number }>({
    objCls: [],
    filterType: 1
  })
  /** 是否显示参考网格 **/
  showSourceGrid = ref<Boolean>(true)
  sourceTitle = ref<string>('')
  sourceDataSource = ref<BaselineCompareGridDataInter[]>([])
  targetTitle = ref<string>('')
  targetDataSource = ref<BaselineCompareGridDataInter[]>([])

  /** 差异表格实际数据 **/
  gridDiffDataDatasource = computed<BaselineCompareGridDiffDataInter[]>(() => {
    const sourceDataSource = this.dataSourceFilter.value(this.sourceDataSource.value)
    const targetDataSource = this.dataSourceFilter.value(this.targetDataSource.value)

    const result: BaselineCompareGridDiffDataInter[] = []

    // 对比基线比参考基线少数据
    const map1 = new Map()
    sourceDataSource.forEach(sourceItem => {
      const sourceUnionKey = sourceItem?.master?.id

      map1.set(sourceUnionKey, sourceItem)

      // 参考基线有，对比基线没有的数据，比较结果为减少
      if (sourceItem.diffType == 'recordAdd') {
        result.push({
          id: lodash.uniqueId(),
          path: `${sourceItem.number}：${sourceItem.name}`,
          compareResult: '减少',
          sourceBaseline: `${sourceItem.number}：${sourceItem.name}`,
          targetBaseline: ``
        })
      }
    })

    // 对比基线比参考基线多数据
    targetDataSource.forEach(targetItem => {
      if (targetItem.diffType == 'recordAdd') {
        result.push({
          id: lodash.uniqueId(),
          path: `${targetItem.number}：${targetItem.name}`,
          compareResult: '增加',
          sourceBaseline: ``,
          targetBaseline: `${targetItem.number}：${targetItem.name}`
        })
      }
    })

    // 对比基线和参考基线差异数据
    targetDataSource.forEach(targetItem => {
      if (targetItem.diffType == 'propertyDiff') {
        const targetUnionKey = targetItem?.master?.id
        const sourceItem = map1.get(targetUnionKey)

        const propertyDiffInfo = targetItem.propertyDiffInfo || []
        const sourceBaseline = propertyDiffInfo.reduce((str: string, cur: PropertyDiffInfoInter) => {
          str += `${cur.text}：${sourceItem[cur.propertyName]}；`
          return str
        }, '')

        const targetBaseline = propertyDiffInfo.reduce((str: string, cur: PropertyDiffInfoInter) => {
          str += `${cur.text}：${targetItem[cur.propertyName]}； `
          return str
        }, '')

        result.push({
          id: lodash.uniqueId(),
          path: `${targetItem.number}：${targetItem.name}`,
          compareResult: '属性差异',
          sourceBaseline,
          targetBaseline
        })
      }
    })

    return result
  })

  /** source表格实际数据 **/
  dataSourceFilter = computed(() => {
    return (dataSource: BaselineCompareGridDataInter[] = []) => {
      // 1、过滤对象类筛选
      let result = []
      result =
        dataSource.filter((item: BaselineCompareGridDataInter) => this.searchData.value.objCls.includes(item.className!)) || []

      // 2、过滤差异
      result = result.filter((item: BaselineCompareGridDataInter) =>
        this.searchData.value.filterType == 2 ? item.diffType != 'normal' : true
      )

      return result
    }
  })

  constructor(options: ViewModelOptions<KBaselineComparePropType>) {
    super(options)
  }

  viewDidMount() {
    this.getSourceGridData()
  }

  /** 获取参考基线数据 **/
  getSourceGridData() {
    const ids = [this.props.sourceId]
    if (this.props.targetId) ids.push(this.props.targetId)
    const dataList = ids.map(id => {
      return Api.post('baseline', 'ManagedBaseline', 'get', { data: [id] })
    })
    Promise.all(dataList).then((result: any) => {
      if (result[0] && result[0].code == EnumRequestCode.SUCCESS) {
        this.sourceTitle.value = `${result[0].data.name}`
        const sourceDataSource =
          (result[0].data.plmBaselineMemberTargetViewDTOList || []).map((item: any) => {
            item.versionInfo = `${item.version}.${item.iteration}`
            item.state = EnumLifecycleState[item.lifecycleStateCode]
            item.classAndView = clsCodeMap.get(item.className!) + this.getViewName(item)
            return item
          }) || []
        this.sourceDataSource.value = sourceDataSource
      }
      if (result[1] && result[1].code == EnumRequestCode.SUCCESS) {
        this.targetTitle.value = `${result[1].data.name}`
        const targetDataSource =
          (result[1].data.plmBaselineMemberTargetViewDTOList || []).map((item: any) => {
            item.versionInfo = `${item.version}.${item.iteration}`
            item.state = EnumLifecycleState[item.lifecycleStateCode]
            item.classAndView = clsCodeMap.get(item.className!) + this.getViewName(item)
            return item
          }) || []
        this.targetDataSource.value = targetDataSource
      }
      this.checkObjCls()
      this.props.targetId && this.addDiffInfo()
    })
  }

  /** 添加对比基线 **/
  openTargetBaselineDialog() {
    KDialog.show({
      title: '添加对比基线',
      size: { width: 800, height: 600 },
      props: {},
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KBaselineCompareGridDialog,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KBaselineCompareGridDialogViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const selectedRows = formViewModel.getSelectedRows()
        const row = selectedRows.length == 0 ? null : selectedRows[0]

        if (row) {
          this.getTargetGridData(row)
        }
      }
    })
  }

  /** 获取目标网格并进行 **/
  getTargetGridData(row: any) {
    Api.post('baseline', 'ManagedBaseline', 'get', { data: [row.id] }).then(result => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        this.targetTitle.value = `${result.data.name}`
        const targetDataSource =
          (result.data.plmBaselineMemberTargetViewDTOList || []).map((item: any) => {
            item.versionInfo = `${item.version}.${item.iteration}`
            item.state = EnumLifecycleState[item.lifecycleStateCode]
            item.classAndView = clsCodeMap.get(item.className!) + this.getViewName(item)
            return item
          }) || []
        this.targetDataSource.value = targetDataSource
        this.checkObjCls()
        // 添加差异信息
        this.addDiffInfo()
        // 网格有bug,数据变更后，会不触发getRowClass重新渲染样式，数据变更后，先删掉网络，等数据更新完后再显示
        this.showSourceGrid.value = false
        nextTick(() => {
          this.showSourceGrid.value = true
        })
      }
    })
  }

  /** 选中对象类 **/
  checkObjCls() {
    const allData = [...this.sourceDataSource.value, ...this.targetDataSource.value]
    //默认全部选中
    this.searchData.value.objCls = [...new Set([...allData.map(item => item.className)])] as string[]
  }

  addDiffInfo() {
    //差异对比
    // 1、标记参考基线多的数据
    const map1 = new Map()
    this.sourceDataSource.value.forEach((item: BaselineCompareGridDataInter) => {
      const sourceUnionKey = item?.master?.id
      map1.set(sourceUnionKey, item)
    })

    const map2 = new Map()
    this.targetDataSource.value.forEach((item: BaselineCompareGridDataInter) => {
      const targetUnionKey = item?.master?.id
      map2.set(targetUnionKey, item)
    })

    map1.forEach((item: BaselineCompareGridDataInter, key: string) => {
      if (map2.has(key)) {
        item.diffType = 'normal'
      } else {
        item.diffType = 'recordAdd'
      }
    })

    // 2、标记对比基线多的数据
    map2.forEach((item: BaselineCompareGridDataInter, key: string) => {
      if (map1.has(key)) {
        item.diffType = 'normal'
      } else {
        item.diffType = 'recordAdd'
      }
    })

    // 3、找到对比基线的属性差异, 参考基线和对比基线数据都记录属性差异，参考基线不显示属性差异，对比基线显示属性差异
    map2.forEach((targetItem: BaselineCompareGridDataInter, key: string) => {
      // 检查正常记录中，key相同，属性不同的数据
      if (targetItem.diffType == 'normal') {
        const sourceItem = map1.get(key)

        //差异信息
        const propertyDiffInfo: PropertyDiffInfoInter[] = []
        if (sourceItem.versionInfo != targetItem.versionInfo) {
          propertyDiffInfo.push({ propertyName: 'versionInfo', text: '版本号' })
        }
        if (sourceItem.state != targetItem.state) {
          propertyDiffInfo.push({ propertyName: 'state', text: '生命周期状态' })
        }
        if (sourceItem.classAndView != targetItem.classAndView) {
          propertyDiffInfo.push({ propertyName: 'classAndView', text: '实体类型' })
        }

        if (propertyDiffInfo.length) {
          targetItem.diffType = 'propertyDiff'
          targetItem.propertyDiffInfo = propertyDiffInfo

          sourceItem.diffType = 'propertyDiff'
          sourceItem.propertyDiffInfo = propertyDiffInfo
        }
      }
    })
  }

  getViewName(item: Record<string, any>) {
    if (item.partView && item.partView.description) {
      return `(${item.partView.description})`
    } else {
      return ''
    }
  }

  /** 导出差异 **/
  exportDiff() {
    //@ts-ignore
    this.refCompareGridDiff.value?.refDiffGrid?.exportDataAsExcel({
      fileName: '基线对比差异',
      sheetName: '基线对比差异',
      columnKeys: ['path', 'compareResult', 'sourceBaseline', 'targetBaseline']
    })
  }
}

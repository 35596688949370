import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  id: "login",
  class: "login-wrapper",
  style: {"background-image":"url('./img/background_img.svg')","background-repeat":"no-repeat","background-size":"cover","height":"100%"}
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "login-area" }
const _hoisted_4 = { class: "main-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_spin = _resolveComponent("k-spin")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_k_spin, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.loginComponent)))
            ])
          ])
        ])
      ]))
}
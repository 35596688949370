
import { EnumBaseControl, KContainer, KControl, defineView } from '@kmsoft/upf-core'
import { Component, createCommentVNode, createVNode, resolveComponent } from 'vue'
import { renderBaseControl, renderToolStrip } from '../utils'
import { KWrapperControlEventEmits, KWrapperControlPropOptions } from './interface'
import { KWrapperControlViewModel } from './KWrapperControlViewModel'
import { DynamicViewControlOptions } from '../interface'

export default defineView({
  inheritAttrs: false,
  name: 'KWrapperControl',
  viewModel: KWrapperControlViewModel,
  props: KWrapperControlPropOptions,
  emits: KWrapperControlEventEmits,
  setup(props, { attrs, slots, vm, emit }) {
    /**
     * 渲染元素
     * @returns 渲染内容
     */
    const renderElement = () => {
      const control = props.meta.control.split('_')[0]

      // 如果不可见
      if (!props.meta.visible) {
        return createCommentVNode(control)
      }

      /** 自定义选项 */
      const customOptions: DynamicViewControlOptions = props.getControlOptions?.({ meta: props.meta }) || {
        props: {},
        slots: {}
      }
      /** 自定义节点 */
      const component = customOptions.component || (resolveComponent(control) as Component)
      /** 新选项 */
      const newOptions: DynamicViewControlOptions = {
        ...customOptions,
        component: component
      }

      // 如果是基本控件
      if (
        Object.values(EnumBaseControl).includes((control as unknown) as EnumBaseControl) ||
        (props.meta.dataType && props.meta.dataType !== '')
      ) {
        return renderBaseControl(props.meta as KControl, props.readonly, props.parentLayout, newOptions)
      }

      //#region 生成节点
      let vNode

      if (control == 'KToolStrip') {
        vNode = renderToolStrip(props.meta as KContainer, newOptions)
      } else {
        vNode = createVNode(
          component,
          {
            ref_key: 'ref' + props.meta.name,
            class: 'dynamic-view-runtime',
            name: props.meta.name,
            title: props.meta.title,
            controls: (props.meta as KContainer).controls || [],
            meta: props.meta,
            parentLayout: props.parentLayout,
            visible: props.meta.visible,
            disabled: props.meta.disabled,
            readonly: props.readonly,
            field: (props.meta as KControl).field || '',
            ...props.meta.props,
            ...newOptions.props
          },
          newOptions.slots
        )
      }
      //#endregion

      return vNode
    }

    return () => {
      return renderElement()
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KObjectManagePropOptions, KObjectManageEventEmits } from './interface'
import KObjectManageViewModel from './KObjectManageViewModel'

export default defineView({
  name: 'KObjectManage',
  props: KObjectManagePropOptions,
  emits: KObjectManageEventEmits,
  viewModel: KObjectManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

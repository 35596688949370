import { BaseViewModel, IKTreeNode, KTreeViewViewModel, TreeViewSelectEventArgs, ViewModelOptions } from '@kmsoft/upf-core'
import { KWorkflowTemplateSelectorEmitsType, KWorkflowTemplateSelectorPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { WorkflowClientSrv } from '../../../client-srv'
import { objParam } from '../interface'

/** KWorkflowTemplateSelector */
export default class KWorkflowTemplateSelectorViewModel extends BaseViewModel<
  KWorkflowTemplateSelectorEmitsType,
  KWorkflowTemplateSelectorPropType
> {
  /**工作区模板树 */
  refWorkflowTemplateTree = ref<KTreeViewViewModel>()
  /**活动key */
  activeKey = ref<Array<string>>(['1'])
  /**图片地址 */
  flowImgUrl = ref<string>()
  objParams = ref<Array<objParam>>([])

  constructor(options: ViewModelOptions<KWorkflowTemplateSelectorPropType>) {
    super(options)
    this.objParams.value = options.props.objParams
  }

  viewDidMount() {}

  /**
   * 刷新工作流数据
   */
  async refreshWorkflow(event: TreeViewSelectEventArgs<any>) {
    WorkflowClientSrv.getWorkflowTemplatePicture(event.node.id).then((res: string) => {
      this.flowImgUrl.value = res
    })
  }
  /**
   * 加载工作流模板
   */
  loadWorkflowTemplateData(): Promise<Array<IKTreeNode>> {
    const filterObjs = this.objParams.value.map(obj => {
      return {
        objClsCode: obj.objClsCode,
        lifecycleStateCode: obj.status
      }
    })
    return new Promise((resolve, reject) => {
      const param = {
        data: [
          {
            key: '',
            name: '',
            category: '',
            currentPage: 1,
            pageSize: 1000,
            filterObjs: filterObjs
          }
        ]
      }
      Api.post('official', 'ProcessInstanceService', 'listProcessDefine', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS && res.data.dataList) {
          const result = res.data.dataList.map((data: any) => {
            return {
              ...data,
              templateKey: data.key,
              leaf: true,
              children: []
            } as IKTreeNode
          })
          resolve(result)
        } else {
          resolve([])
        }
      })
    })
  }
  /**
   * 获取工作流模板Id
   * @returns 工作流模板Id
   */
  getWorkTemplate() {
    return this.refWorkflowTemplateTree.value?.getSelectedNode()
  }
}

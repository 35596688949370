import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KBaselineEditPropOptions = {
  ...BaseViewPropOptions,
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def({
      id: '642090557597818880',
      modelCode: 'ManagedBaseline',
      modelGroup: 'baseline'
    })
}

/** 参数类型 **/
export type KBaselineEditPropType = ViewPropsTypeExtract<typeof KBaselineEditPropOptions>

/** 事件 */
export const KBaselineEditEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KBaselineEditEmitsType = ViewEmitsTypeExtract<typeof KBaselineEditEventEmits>

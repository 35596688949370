import { EnumAttributeType, EnumDataType, EnumLabelPosition, KControlAttribute, KControlEvent } from '@kmsoft/upf-core'
import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { getCandidates } from '../utils'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'

/* 组件的基本属性 */
const comConfig = [
  {
    name: 'labelPosition',
    title: '标签位置',
    control: 'KSelect',
    attributeType: EnumAttributeType.PROPS,
    options: [
      { displayValue: '居左', actualValue: EnumLabelPosition.LEFT },
      { displayValue: '居上', actualValue: EnumLabelPosition.TOP },
      { displayValue: '不显示', actualValue: EnumLabelPosition.HIDDEN }
    ],
    defaultValue: 'hidden',
    dataType: EnumDataType.STRING
  },
  {
    name: 'autoIndex',
    title: '自动生成序号',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'rowKey',
    title: '行关键字',
    control: 'KInput',
    defaultValue: 'id',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'isMultipleSelection',
    title: '是否可多选',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'rowDrag',
    title: '是否可拖拽',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'rowDragMutiRow',
    title: '是否可多行拖拽',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'pagination',
    title: '是否开启分页',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'showStripe',
    title: '是否开启奇偶行',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'stripeBackgroundColor',
    title: '偶行背景色',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'columns',
    title: '列配置',
    control: 'KGridColumnConfig',
    defaultValue: [],
    dataType: EnumDataType.ARRAY,
    attributeType: EnumAttributeType.PROPS
  }
] as Array<KControlAttribute>

/* 属性配置项集合 */
const BASE = [
  PROPERTY_CONFIG_ITEM_COLLECTION.TITLE,
  PROPERTY_CONFIG_ITEM_COLLECTION.READONLY,
  {
    name: 'dataSource',
    title: '数据源',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.BASE
  },
  {
    name: 'field',
    title: '字段',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.BASE
  }
] as Array<KControlAttribute>
/**控件属性 */
const PROPS = comConfig
/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
] as Array<KControlAttribute>

const EVENT: Array<KControlEvent> = [
  { name: 'viewDidMount', title: '数据加载完成', describe: '数据加载完成 的说明信息...' },
  { name: 'selectionChanged', title: '行选中', describe: '行选中 的说明信息...' },
  { name: 'firstDataRendered', title: '首次数据渲染完成后', describe: '' },
  { name: 'rowDataUpdated', title: '行更新完成', describe: '' },
  { name: 'paginationChanged', title: '分页改变', describe: '' },
  { name: 'rowClick', title: '行点击', describe: '' },
  { name: 'rowDoubleClick', title: '行双击', describe: '' },
  { name: 'rowSelected', title: '当行选中或取消选中', describe: '' },
  { name: 'rowChanged', title: '行切换事件', describe: '' },
  { name: 'rowValueChanged', title: '结束编辑后', describe: '' },
  { name: 'rowDragEnter', title: '行拖动开始', describe: '' },
  { name: 'rowDragEnd', title: '行拖动结束', describe: '' },
  { name: 'cellValueChanged', title: '单元格值改变', describe: '' },
  { name: 'cellClick', title: '单元格单击', describe: '' },
  { name: 'cellDblClick', title: '单元格双击', describe: '' },
  { name: 'cellFocused', title: '单元格获取焦点', describe: '' },
  { name: 'cellKeyDown', title: '单元格键盘按钮按下', describe: '' },
  { name: 'cellKeyPress', title: '单元格键盘按钮按下', describe: '' },
  { name: 'cellMouseOver', title: '鼠标移入单元格', describe: '' },
  { name: 'cellMouseDown', title: '鼠标点击单元格', describe: '' },
  { name: 'cellMouseOut', title: '鼠标移出单元格', describe: '' },
  { name: 'cellContextMenu', title: '单元格右键事件', describe: '' },
  { name: 'cellEditingStarted', title: '单元开始编辑', describe: '' },
  { name: 'cellEditingStopped', title: '单元格结束编辑', describe: '' },
  { name: 'rowGroupOpened', title: '分组展开收起', describe: '' }
]

export default { BASE, PROPS, LAYOUT, EVENT }

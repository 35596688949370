import { BaseViewEventEmits, BaseViewPropOptions, utils, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'

/** 参数 **/
export const KLoginPagePropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KLoginPagePropType = ViewPropsTypeExtract<typeof KLoginPagePropOptions>

/** 事件 */
export const KLoginPageEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KLoginPageEmitsType = ViewEmitsTypeExtract<typeof KLoginPageEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KPersonalCreateChangeOrderGridView from './KPersonalCreateChangeOrderGrid.vue'
import KPersonalCreateChangeOrderGridViewModel from './KPersonalCreateChangeOrderGridViewModel'

const KPersonalCreateChangeOrderGrid = connect(KPersonalCreateChangeOrderGridView, KPersonalCreateChangeOrderGridViewModel)

export default withInstall(KPersonalCreateChangeOrderGrid)
export { KPersonalCreateChangeOrderGrid, KPersonalCreateChangeOrderGridView, KPersonalCreateChangeOrderGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

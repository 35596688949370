import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeActivityEditView from './KChangeActivityEdit.vue'
import KChangeActivityEditViewModel from './KChangeActivityEditViewModel'

const KChangeActivityEdit = connect(KChangeActivityEditView, KChangeActivityEditViewModel)

export default withInstall(KChangeActivityEdit)
export { KChangeActivityEdit, KChangeActivityEditView, KChangeActivityEditViewModel }

// 模板生成文件
// export * from './{{folderName}}'

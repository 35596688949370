import { connect, withInstall } from '@kmsoft/upf-core'
import KDocEditPanelView from './KDocEditPanel.vue'
import KDocEditPanelViewModel from './KDocEditPanelViewModel'

const KDocEditPanel = connect(KDocEditPanelView, KDocEditPanelViewModel)

export default withInstall(KDocEditPanel)
export { KDocEditPanel, KDocEditPanelView, KDocEditPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

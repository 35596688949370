import { connect, withInstall } from '@kmsoft/upf-core'
import KDocEchoListPanelView from './KDocEchoListPanel.vue'
import KDocEchoListPanelViewModel from './KDocEchoListPanelViewModel'

const KDocEchoListPanel = connect(KDocEchoListPanelView, KDocEchoListPanelViewModel)

export default withInstall(KDocEchoListPanel)
export { KDocEchoListPanel, KDocEchoListPanelView, KDocEchoListPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KPromoteActivityAfterGridView from './KPromoteActivityAfterGrid.vue'
import KPromoteActivityAfterGridViewModel from './KPromoteActivityAfterGridViewModel'

const KPromoteActivityAfterGrid = connect(KPromoteActivityAfterGridView, KPromoteActivityAfterGridViewModel)

export default withInstall(KPromoteActivityAfterGrid)
export { KPromoteActivityAfterGrid, KPromoteActivityAfterGridView, KPromoteActivityAfterGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

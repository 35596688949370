import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "file-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input_group_label = _resolveComponent("k-input-group-label")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_input_group = _resolveComponent("k-input-group")!

  return (_openBlock(), _createBlock(_component_k_input_group, {
    class: "k-script-editor",
    compact: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_input_group_label, {
        class: "file-label",
        value: _ctx.$vm.stateValue,
        disabled: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$vm.stateValue), 1)
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_k_button, {
        class: "action-button",
        disabled: _ctx.$vm.disabled,
        onClick: _ctx.$vm.onEdit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_icon, { type: "file-text" })
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ]),
    _: 1
  }))
}
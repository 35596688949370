import {
  BaseViewModel,
  EnumDialogResult,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  SimpleViewModel,
  ViewModelOptions,
  AppContext
} from '@kmsoft/upf-core'
import { KPromoteActivityBeforeGridEmitsType, KPromoteActivityBeforeGridPropType } from './interface'
import { ref, watch } from 'vue'
import {
  Api,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  KObjectSelectorViewModel,
  ObjectClientSrv,
  EnumWorkingState,
  EnumLifecycleState,
  EBF_IOC_KEY,
  EnumPhaseState
} from '@kmsoft/ebf-common'
import lodash from 'lodash'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import {
  KIssueReportRelationDoc,
  KIssueReportRelationDocViewModel
} from '../issue-report-affected-views/issue-report-relation-doc'

/** KChangeActivityObjGrid */
export default class KChangeActivityObjGridViewModel extends SimpleViewModel<
  KPromoteActivityBeforeGridEmitsType,
  KPromoteActivityBeforeGridPropType
> {
  gridData = ref<any[]>([])
  refDataGrid = ref<KDataGridViewModel>()
  modified = ref<boolean>(false)
  constructor(options: ViewModelOptions<KPromoteActivityBeforeGridPropType>) {
    super(options)
  }

  viewDidMount() {}

  public setValue(newValue: any, setChanged?: boolean) {
    this.gridData.value = lodash.cloneDeep(newValue)
  }

  getCurrentPhase() {
    const refObjectCreatePanel = this.props.api.getCurrentPanelViewModel() as KObjectPropertyPanelViewModel
    const data = refObjectCreatePanel.getValue()
    return data.extAttrs[0].value
  }

  /** 添加 | 收集 **/
  openObject(handle: string) {
    const currentPhase = this.getCurrentPhase()
    if (!currentPhase) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择当前阶段'
      })
      return
    }
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      isMultipleSelection: true,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Part'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: [
            {
              conditionName: 'lifecycleState.internalName',
              operator: EnumQueryConditionOperator.EQUAL,
              conditionValues: [this.props.filterLifecycleState]
            },
            {
              conditionName: 'phase',
              operator: EnumQueryConditionOperator.EQUAL,
              conditionValues: [currentPhase]
            },
            ...(handle == 'add'
              ? [
                  {
                    conditionName: 'latest',
                    operator: EnumQueryConditionOperator.EQUAL,
                    conditionValues: ['true']
                  }
                ]
              : [])
          ]
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Part']
      },
      getContainer: this.refDataGrid.value?.getContainer(),
      onClosing: async event => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        // 获取当前弹出组件实例的ViewModel
        const selectorViewModel = event.viewModel as KObjectSelectorViewModel
        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows() || []
        if (handle == 'add') {
          if (selectedRows.length > 0) {
            const hasRepeat = this.checkRepeat(selectedRows)
            if (hasRepeat) {
              event.cancel = true
              return
            }
            this.addGridData(selectedRows)
          }
        } else {
          if (selectedRows.length > 1) {
            event.cancel = true
            KNotification.warn({
              message: '系统提示',
              description: '只能选择一条零部件'
            })
            return
          }
          const param = {
            data: [
              {
                id: selectedRows[0].id,
                clazz: selectedRows[0].rdmExtensionType
              },
              currentPhase
            ]
          }
          const result = await Api.post('part', 'Part', 'collectPart', param)
          if (result && result.code == EnumRequestCode.SUCCESS) {
            const hasRepeat = this.checkRepeat(result.data)
            if (hasRepeat) {
              event.cancel = true
              return
            }
            if (result.data.length == 0) {
              KNotification.warn({
                message: '系统提示',
                description: `未收集到已发布且阶段为${EnumPhaseState[currentPhase]}的数据`
              })
              return
            }
            this.addGridData(result.data)
          }
        }
      }
    })
  }

  /** 相关文档 **/
  relatedDocument() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    if (rows && rows.length !== 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    KDialog.show({
      title: '相关文档',
      size: { width: 1000, height: 600 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      getContainer: this.refDataGrid.value?.getContainer(),
      content: KIssueReportRelationDoc,
      props: {
        objParam: {
          id: rows[0].id,
          rdmExtensionType: rows[0].rdmExtensionType,
          modelCode: 'ChangeIssue',
          modelGroup: 'change'
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const selectorViewModel = event.viewModel as KIssueReportRelationDocViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows()

        if (selectedRows.length > 0) {
          const hasRepeat = this.checkRepeat(selectedRows)
          if (hasRepeat) {
            event.cancel = true
            return
          }
          this.addGridData(selectedRows)
        }

        //刷新重新渲染
        this.refresh()
      }
    })
  }

  // 根据masterId检查是否有重复数据
  checkRepeat(selectData: Record<string, any>[]) {
    let hasRepeat = false
    let hasNoReleased = false
    const selectMasterId = selectData.map(item => {
      if (item.lifecycleStateCode !== 'RELEASED') hasNoReleased = true
      if (!hasRepeat) {
        hasRepeat = this.gridData.value.some(data => data.targetMasterId == item.master.id || data?.master?.id == item.master.id)
      }
      return item.master.id
    })
    if (selectMasterId.length != new Set(selectMasterId).size) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中存在重复数据'
      })
      return true
    }
    if (hasRepeat) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中存在已添加的数据'
      })
      return true
    }
    if (hasNoReleased) {
      KNotification.warn({
        message: '系统提示',
        description: '只能添加已发布的数据'
      })
      return true
    }
    return false
  }

  addGridData(gridData: any[]) {
    this.modified.value = true
    const addDataList = this.gridDataFilter(gridData)
    this.gridData.value.push(...addDataList)
    this.refresh()
  }

  gridDataFilter(gridData: any[]) {
    // 去重
    const addDataList =
      gridData.map((item: any) => {
        // 版本
        item.versionInfo = `${item.version}.${item.iteration}`
        // 状态
        item.state = item.lifecycleStateCode ? EnumLifecycleState[item.lifecycleStateCode] : '--'
        // 检入标记
        item.checkInState = item.workingState ? EnumWorkingState[item.workingState as keyof typeof EnumWorkingState] : '--'
        return item
      }) || []

    return addDataList
  }

  /** 移除 **/
  removeData() {
    const selectedRows = this.refDataGrid.value?.getSelectedRows() || []
    if (selectedRows.length > 0) {
      this.modified.value = true
      this.gridData.value = this.gridData.value.filter(item => !selectedRows.some(row => row.id === item.id))
    }
  }
  /** 刷新 **/
  refresh() {}

  isModified(): boolean {
    // 编辑时默认认为修改
    return this.modified.value
  }

  getModifiedValue() {
    return this.getValue()
  }

  public getValue() {
    return this.gridData.value || []
  }

  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: any) {
    const param = {
      id: row?.targetId || row.id,
      modelCode: row!.targetExtensionType || row.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.targetExtensionType || row.className)
    }
    ObjectClientSrv.openObj(param)
  }

  getView(row: any) {
    if (row.partView?.description) {
      return '(' + row.partView?.description + ')'
    }
    return ''
  }
}

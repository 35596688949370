import { renderSlot } from 'vue'
import { EnumType, KContainer, defineView } from '@kmsoft/upf-core'
import { KViewRenderElementEventEmits, KViewRenderElementPropOptions } from './interface'
import KViewRenderElementViewModel from './KViewRenderElementViewModel'
import { EnumRenderLayoutType } from '../render-layout'

export default defineView({
  inheritAttrs: false,
  name: 'KViewRenderElement',
  props: KViewRenderElementPropOptions,
  emits: KViewRenderElementEventEmits,
  viewModel: KViewRenderElementViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    /**
     * 构建元素样式
     * @param element 拖入元素
     */
    const buildGridLayoutElementStyle = () => {
      if (!props.meta) {
        return {}
      }

      const style: Record<string, any> = {}

      // 如果是外侧主页面
      if (props.meta.type === EnumType.PAGE) {
        style['grid-column'] = '1/-1'
        style['grid-row-start'] = `span ${props.gridRows}`
      } else {
        const meta = props.meta as KContainer
        const layout = meta.layout

        // 如果占满整行或者填充
        if (layout.entireRow || layout.fill) {
          style['grid-column'] = '1/-1'
        } else {
          style['grid-column-start'] = `span ${layout.colSpan}`
        }

        // 如果填充剩余空间
        if (layout.fill) {
          // style['grid-template-rows'] = 'repeat(auto-fill,200px)'
        }

        style['grid-row-start'] = `span ${layout.rowSpan}`
      }

      const outerStyle: any = attrs.style || {}

      return { ...style, ...outerStyle }
    }

    const renderMoreAction = () => {
      const popoverSlots = {
        default: () => {
          return (
            <span class="render-element-action">
              <k-icon type="more"></k-icon>
            </span>
          )
        },
        content: () => {
          return (
            <>
              <span
                class="render-element-action top"
                key="top"
                onClick={(e: Event) => {
                  e.stopPropagation()
                  vm.onTop()
                }}
              >
                <k-icon type="to-top"></k-icon>
              </span>
              <span
                class="render-element-action bottom"
                key="bottom"
                onClick={(e: Event) => {
                  e.stopPropagation()
                  vm.onBottom()
                }}
              >
                <k-icon type="vertical-align-bottom"></k-icon>
              </span>
              <span
                class="render-element-action up"
                key="up"
                onClick={(e: Event) => {
                  e.stopPropagation()
                  vm.onUp()
                }}
              >
                <k-icon type="arrow-up"></k-icon>
              </span>
              <span
                class="render-element-action down"
                key="down"
                onClick={(e: Event) => {
                  e.stopPropagation()
                  vm.onDown()
                }}
              >
                <k-icon type="arrow-down"></k-icon>
              </span>
            </>
          )
        }
      }

      return <k-popover placement="right" v-slots={popoverSlots} />
    }

    /**
     * 渲染设计操作
     * @returns
     */
    const renderDesignAction = () => {
      if (!props.designMode) {
        return
      }

      if (props.meta?.type === EnumType.PAGE) {
        return
      }

      return (
        <div class="render-element-actions">
          <span class="render-element-action delete" onClick={vm.onDelete}>
            <k-icon type="delete"></k-icon>
          </span>
          {renderMoreAction()}
        </div>
      )
    }

    /** 移动图标 */
    const renderMover = () => {
      return (
        <div class="render-element-mover render-element-mover">
          <k-icon type="drag" />
        </div>
      )
    }

    return () => {
      let style: Record<string, any> = {}

      if (props.layoutType == EnumRenderLayoutType.GRID) {
        style = buildGridLayoutElementStyle()
      }

      /** 容器属性 */
      const containerProps = {
        ...attrs,
        class: [
          'k-view-render-element',
          `${props.layoutType}-layout-element`,
          { 'design-element': props.designMode },
          { 'render-element-mover': props.designMode },
          { selected: props.selected },
          attrs.class
        ],
        style: [style, `${props.layoutType == EnumRenderLayoutType.FLEX ? 'width:100%' : ''}`],
        'data-name': props.meta.name,
        onClick: vm.onClick
      }

      return (
        <div {...containerProps}>
          {renderDesignAction()}
          {renderSlot(slots, 'default')}
        </div>
      )
    }
  }
})

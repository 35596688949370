import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { IObjectClassLayout } from '../../interface'

/** 参数 **/
export const KObjectClassLayoutDefinitionFormPropOptions = {
  ...BaseViewPropOptions,
  /** 对象类id */
  clsId:VuePropTypes.string().def(),
  /** 对象类布局表单数据 */
  objectClassLayoutFormData:VuePropTypes.createType<IObjectClassLayout>().def(),
  /** 操作类型 */
  operationType:VuePropTypes.createType<'create' | 'update' | 'detail'>().def('create')
}

/** 参数类型 **/
export type KObjectClassLayoutDefinitionFormPropType = ViewPropsTypeExtract<typeof KObjectClassLayoutDefinitionFormPropOptions>

/** 事件 */
export const KObjectClassLayoutDefinitionFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KObjectClassLayoutDefinitionFormEmitsType = ViewEmitsTypeExtract<typeof KObjectClassLayoutDefinitionFormEventEmits>

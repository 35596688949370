import { connect, withInstall } from '@kmsoft/upf-core'
import KPersonalCreateChangeActivityGridView from './KPersonalCreateChangeActivityGrid.vue'
import KPersonalCreateChangeActivityGridViewModel from './KPersonalCreateChangeActivityGridViewModel'

const KPersonalCreateChangeActivityGrid = connect(
  KPersonalCreateChangeActivityGridView,
  KPersonalCreateChangeActivityGridViewModel
)

export default withInstall(KPersonalCreateChangeActivityGrid)
export { KPersonalCreateChangeActivityGrid, KPersonalCreateChangeActivityGridView, KPersonalCreateChangeActivityGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KRequireWorkspaceView from './KRequireWorkspace.vue'
import KRequireWorkspaceViewModel from './KRequireWorkspaceViewModel'

const KRequireWorkspace = connect(KRequireWorkspaceView, KRequireWorkspaceViewModel)

export default withInstall(KRequireWorkspace)
export { KRequireWorkspace, KRequireWorkspaceView, KRequireWorkspaceViewModel }

// 模板生成文件
// export * from './{{folderName}}'

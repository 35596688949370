import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"width":"100%","display":"flex"} }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { style: {"margin-left":"5px"} }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_input_search = _resolveComponent("k-input-search")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_date_time_column = _resolveComponent("k-data-grid-date-time-column")!
  const _component_k_divider = _resolveComponent("k-divider")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _directive_permission = _resolveDirective("permission")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_tool_strip, { name: "workspaceToolStrip" }, {
          default: _withCtx(() => [
            (_ctx.UserClientSrv.verifyPurview(_ctx.EnumPurviewCode.CreateProductLibrary))
              ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                  key: 0,
                  type: "primary",
                  name: "createBtn",
                  ref: "refCreateBtn",
                  icon: "plus",
                  title: "新建",
                  onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.create())
                }, null, 512))
              : _createCommentVNode("", true),
            _createVNode(_component_k_tool_strip_button_item, {
              name: "refreshBtn",
              icon: "sync",
              title: "刷新",
              onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.refresh())
            })
          ]),
          _: 1
        }),
        _createVNode(_component_k_input_search, {
          value: _ctx.$vm.workspaceName,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.workspaceName) = $event)),
          placeholder: "请输入工作区名称",
          style: {"width":"200px","margin-top":"8px"},
          onSearch: _ctx.$vm.search
        }, null, 8, ["value", "onSearch"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        rowModelType: _ctx.rowModelType,
        class: "product-workspace-grid",
        rowKey: "id",
        name: "workspaceGrid",
        pagination: { pageIndex: 1, pageSize: 50 },
        ref: _ctx.$vm.refWorkspaceGrid,
        loadData: _ctx.$vm.loadData
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_template_column, {
            id: "name",
            name: "name",
            dataPropertyName: "name",
            headerText: "产品库名称",
            align: "center"
          }, {
            default: _withCtx(({ cellValue,row }) => [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                onClick: () => _ctx.$vm.openProductWorkspace(row)
              }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(cellValue), 1)
              ], 8, _hoisted_2)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "description",
            name: "description",
            dataPropertyName: "description",
            headerText: "备注",
            width: "450",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "creator",
            name: "creator",
            dataPropertyName: "creator",
            headerText: "创建者",
            align: "center",
            width: "150"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_date_time_column, {
            id: "createTime",
            name: "createTime",
            dataPropertyName: "createTime",
            headerText: "创建时间",
            width: "200",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "modifier",
            name: "modifier",
            dataPropertyName: "modifier",
            headerText: "修改人",
            width: "150",
            align: "center"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_date_time_column, {
            id: "lastUpdateTime",
            name: "lastUpdateTime",
            dataPropertyName: "lastUpdateTime",
            headerText: "修改时间",
            width: "300",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "action",
            name: "action",
            fixed: "right",
            headerText: "操作",
            width: "200",
            align: "center"
          }, {
            default: _withCtx(({row}) => [
              _withDirectives((_openBlock(), _createElementBlock("a", {
                class: "row-operation-item",
                onClick: (e) => _ctx.$vm.update(row)
              }, [
                _createTextVNode(" 编辑 ")
              ], 8, _hoisted_4)), [
                [_directive_permission, _ctx.EnumPurviewCode.ModifyProductLibrary]
              ]),
              (
                _ctx.UserClientSrv.verifyPurview(_ctx.EnumPurviewCode.ModifyProductLibrary) &&
                  _ctx.UserClientSrv.verifyPurview(_ctx.EnumPurviewCode.DeleteProductLibrary)
              )
                ? (_openBlock(), _createBlock(_component_k_divider, {
                    key: 0,
                    type: _ctx.vertical
                  }, null, 8, ["type"]))
                : _createCommentVNode("", true),
              _withDirectives((_openBlock(), _createElementBlock("a", {
                class: "row-operation-item",
                onClick: (e) => _ctx.$vm.delete(row.id)
              }, [
                _createTextVNode(" 删除 ")
              ], 8, _hoisted_5)), [
                [_directive_permission, _ctx.EnumPurviewCode.DeleteProductLibrary]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["rowModelType", "loadData"])
    ]),
    _: 1
  }))
}
import {
  ComponentHelper,
  KToolStripViewModel,
  ToolStripItem,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KObjectToolStripEventEmitsType, KObjectToolStripPropType } from './interface'
import { ObjectToolStripItem, ObjectToolStripSetItemVisibleParams, ToolStripFilterParams } from './types'
import { localStorageCache } from '../../index'
import { ToolStripFilter } from './utils'

/** 对象工具栏 */
export default class KObjectToolstripViewModel extends KToolStripViewModel<
  KObjectToolStripEventEmitsType,
  KObjectToolStripPropType
> {
  /** 布局Id */
  configSchemaId: string = ''
  /** class 属性 */
  classAttr: string = 'k-object-tool-strip'
  /** 权限列表 */
  limitList: string[] = []

  constructor(options: ViewModelOptions<KObjectToolStripPropType>) {
    super(options)
    this.initItems(options.props.items)
    this.configSchemaId = ComponentHelper.getComponentPath()
  }

  /** 加载完成函数 */
  viewDidMount() {}

  private filterItem(toolStripItem: any) {
    toolStripItem.forEach((item: any) => {
      if (item.purviewId) {
        item.visible = this.limitList.includes(item.purviewId)
      }
      if (item.items && item.items.length > 0) {
        this.filterItem(item.items)
      }
    })
  }

  /**
   * 按钮类型控件的点击事件
   * @param eventArgs
   * @overload 子类重写
   */
  public dispatchItemClickedEvent(eventArgs: ToolStripItemClickedEventArgs) {
    super.dispatchItemClickedEvent(eventArgs)

    // const menu = refToolStrip.value?.findItem(event.id)
    // if (menu && config) {
    //   const menuConfig = getConfig(menu.id, config.configs)
    //   const context = props.scriptContext ? props.scriptContext() : {}
    //   if (menuConfig && menuConfig.enableScript && menuConfig.clickScript) {
    //     ScriptClientSrv.execute(EnumScriptBusiness.EventScript, menuConfig.clickScript, { ...context })
    //   }
    // }
  }

  /**
   * 工具栏自身点击回调
   * @overload 子类重写
   */
  public onContainerClick(e: MouseEvent) {
    super.onContainerClick(e)

    // 按下指定按键才能打开
    if (e.shiftKey && e.ctrlKey) {
      // 打开工具栏配置界面
      this.openToolStripManage()
    }
  }

  /** 初始化自定义配置 */
  initItems(newItems: Array<ToolStripItem>) {
    const limitCache: string | undefined = localStorageCache.getCache('system_limits')
    if (limitCache) {
      this.limitList = JSON.parse(limitCache)
        .filter((item: any) => item.purviewType == 2)
        .map((item: any) => item.purviewId)
    }
    this.filterItem(newItems)
    // /** 从缓存查找配置 */
    // config = toolStripConfigSrv.getToolStripConfigByPath(configSchemaId)
    // /** 查找更多按钮 */
    // let moreItem = items.value?.find(a => a.id == toolStripItemGroupMore.id)
    // // 如果没有更多按钮
    // if (!moreItem) {
    //   moreItem = lodash.cloneDeep(toolStripItemGroupMore)
    //   newItems.push(moreItem)
    // }
    // if (config) {
    //   // 应用配置到工具栏上
    //   applyConfigsToItems(newItems, config.configs, false, true)
    //   // 更新工具栏条目对照表
    //   // 在零部件树上的点击事件是工具栏初始化时挂载到工具栏条目数组上的，后期插入会导致没有挂载上点击事件
    //   // 所以这里需要重新挂载点击事件
    //   // refToolStrip.value?.updateItemsMap()
    // }
  }

  /**
   * 更新状态
   * @param params
   */
  async updateState(params: ToolStripFilterParams) {
    /** 直接从工具栏获取对照表，提高性能 */
    const itemsMap = this.getMenuItemMap()

    if (!itemsMap || itemsMap.size <= 0) {
      return
    }

    this.isLoading.value = true

    // 过滤工具栏
    await ToolStripFilter.filterToolStripItems(
      itemsMap,
      {
        ...params,
        schemaId: this.configSchemaId
      },
      this.limitList
    )

    this.isLoading.value = false
  }

  /**
   * 能否修改工具栏显示隐藏配置
   * @param item
   * @returns
   */
  private cannotModifyProperties(item: ObjectToolStripItem) {
    return (
      item.isValidatedEnvironment == false ||
      item.isValidatedObjClsTemplet == false ||
      item.isValidatedObjNumMode == false ||
      item.hasPurview == false
    )
  }

  /**
   * 设置工具栏条目状态
   * @param id
   * @param isVisible
   * @param params
   * @returns
   */
  setItemVisible(id: string, isVisible: boolean, params: ObjectToolStripSetItemVisibleParams = { ignoreBaseConfig: false }) {
    // 如果忽略配置
    if (params.ignoreBaseConfig) {
      this.setItemVisible(id, isVisible)
      return
    }

    // zhouw 新增 如果用户没有按钮权限，则强制按钮不可见
    const toolStipItem = this.findItem(id) as ObjectToolStripItem

    if (!toolStipItem) {
      return
    }

    const cannotModify = this.cannotModifyProperties(toolStipItem)

    if (cannotModify) {
      return
    }

    super.setItemVisible(id, isVisible)
  }
  /**
   * 设置工具栏是否启用
   * @param id
   * @param isVisible
   * @param params
   * @returns
   */
  setItemDisabled(id: string, isVisible: boolean, params: ObjectToolStripSetItemVisibleParams = { ignoreBaseConfig: false }) {
    // 如果忽略配置
    if (params.ignoreBaseConfig) {
      this.setItemDisabled(id, isVisible)
      return
    }

    // zhouw 新增 如果用户没有按钮权限，则强制按钮不可见
    const toolStipItem = this.findItem(id) as ObjectToolStripItem

    if (!toolStipItem) {
      return
    }

    const cannotModify = this.cannotModifyProperties(toolStipItem)

    if (cannotModify) {
      return
    }

    super.setItemDisabled(id, isVisible)
  }

  /**
   * 打开创建对象对话框
   * @param config 打开配置
   */
  openToolStripManage(): void {
    // //显示弹窗
    // KDialog.show({
    //   title: '工具条按钮配置',
    //   size: EnumDialogSize.Fat,
    //   movable: true,
    //   maximizeBox: true,
    //   content: () => KToolStripManage,
    //   props: { toolStripItems: props.items, schemaId: configSchemaId, scriptContext: props.scriptContext },
    //   onClosing: async (event: KDialogClosingEvent<IKToolStripManage>) => {
    //     if (event.dialogResult === EnumDialogResult.Cancel || event.dialogResult === EnumDialogResult.Close) {
    //       return
    //     }
    //     await event.refComponent?.save()
    //   }
    // })
  }
}

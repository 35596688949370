import { connect, withInstall } from '@kmsoft/upf-core'
import KProjectWorkspaceListView from './KProjectWorkspaceList.vue'
import KProjectWorkspaceListViewModel from './KProjectWorkspaceListViewModel'

const KProjectWorkspaceList = connect(KProjectWorkspaceListView, KProjectWorkspaceListViewModel)

export default withInstall(KProjectWorkspaceList)
export { KProjectWorkspaceList, KProjectWorkspaceListView, KProjectWorkspaceListViewModel }

// 模板生成文件
// export * from './{{folderName}}'

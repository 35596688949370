import {
  BaseViewModel,
  EnumDialogResult,
  KDataGridRowDoubleClickEvent,
  KDataGridRowSelectedEvent,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  KTreeSelectNode,
  request,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import {
  Api,
  ClassMetaClientSrv,
  clsCodeVersionMap,
  EnumObjectSearcherTab,
  EnumRequestCode,
  EnumToolStripItemKeys,
  FolderTypeEnum,
  KFolderSelector,
  KFolderSelectorViewModel,
  KObjectClassGridViewModel,
  KObjectDynamicCreatePanel,
  KRuleDefinerViewModel,
  MetaProperty,
  ObjectClientSrv,
  ObjectToolStripItem,
  QueryCondition
} from '@kmsoft/ebf-common'
import { baseToolItem, KObjectWorkspaceEmitsType, KObjectWorkspacePropType } from './interface'
import { computed, ref, watch } from 'vue'
import {
  CommonSearcherLifeStateForm,
  KCommonSearcherViewModel,
  KQuickSearcherViewModel
} from '@kmsoft/ebf-common/src/controls/object-searcher/tabs'
import { FolderClientSrv } from '../../../../ebf-folder/src/client-srv'
import { KPartForm, KPartFormViewModel } from '../../../../ebf-part/src/pages/part-manage/part-form'
import { KPartImport } from '../../../../ebf-part/src/pages/part-import'
import { KObjectWorkSpaceSearchViewModel } from '@kmsoft/ebf-common/src/controls/object-work-space-search'

/** KObjectWorkspace */
export default class KObjectWorkspaceViewModel extends BaseViewModel<KObjectWorkspaceEmitsType, KObjectWorkspacePropType> {
  /** 搜索面板 */
  refIKObjectWorkspaceSearch = ref<KObjectWorkSpaceSearchViewModel>()
  /** 高级查询引用 */
  refKRuleDefine = ref<KRuleDefinerViewModel>()
  /** 通用查询引用 */
  refKCommonSearcher = ref<KCommonSearcherViewModel>()
  /** 快速查询引用 */
  refKQuickSearcher = ref<KQuickSearcherViewModel>()
  /** 网格引用 */
  refObjectClassGrid = ref<KObjectClassGridViewModel>()

  /** 固定对象类Id */
  freezeClassCode = computed(() => this.props.showObjClsCodes.length == 1)
  /** 类编码 */
  modelCode = ref<string>()
  /** 类分组 */
  modelGroup = ref<string | undefined>()
  /** 类名 */
  className = ref<string | undefined>()
  /** 加载状态 */
  isLoading = ref<boolean>(false)
  /** 对象属性 */
  classTemplate = ref<string>()
  /** 是否显示通用查询页签 */
  isAllowCommonSearch = ref(false)
  /** 是否显示快速查询页 */
  isAllowQuickSearch = ref(false)
  /** 拓展对象属性列表 */
  fields = ref<Array<MetaProperty>>([])
  /** 标签页 */
  tabKey = ref<EnumObjectSearcherTab>(EnumObjectSearcherTab.COMMON_SEARCH)
  /** 包含子类 */
  isIncludeChildren = ref<boolean>(false)
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>(baseToolItem)

  /** 通用搜索配置 */
  commonCondition = ref<{ condition: QueryCondition; lifeState: CommonSearcherLifeStateForm }>()
  /** 高级搜索配置 */
  advancedCondition = ref<QueryCondition>()
  /** 快速搜索配置 */
  quickSearchCondition = ref<QueryCondition>()
  /**选中文件夹id */
  folderId = ref<string>('')
  /** 文档对象下拉树选择 */
  targetClsObjectTreeData = ref<Array<KTreeSelectNode>>([])
  /** 对象类树属性 */
  objectClassTreeProps = computed(() => {
    return {
      objClassTypes: this.props.classTemplates,
      showObjClsIds: this.props.showObjClsCodes,
      hideObjClsIds: this.props.hideObjClsCodes
    }
  })
  /** 过滤参数 */
  filter = ref<QueryCondition>()
  /** 选中的对象类型 */
  selectObjType = ref<string | undefined>()

  constructor(options: ViewModelOptions<KObjectWorkspacePropType>) {
    super(options)
    watch(
      () => this.modelCode.value,
      (newValue, old) => {
        this.selectObjType.value = newValue
        this.filter.value = undefined
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  //#region 事件
  /**
   * 搜索
   * @param condition
   */
  onSearch(condition: QueryCondition) {
    condition.conditions = condition.conditions?.filter(condition => {
      if (condition.conditionName == 'folder.id') {
        this.folderId.value = condition.conditionValues![0]
        if (clsCodeVersionMap.get(this.modelCode.value!)) {
          condition.conditionName = 'branch.folder.id'
        }
      }
      return condition.conditionName !== 'workingCopy'
    })

    this.filter.value = condition
  }

  /** 取消搜索 */
  onCancel() {
    this.filter.value = undefined
  }

  /**
   * 双击打开编辑面板
   * @param event 行双击事件
   */
  async onRowDoubleClick(event: KDataGridRowDoubleClickEvent) {
    const param = {
      id: event.row!.id,
      modelCode: event.row!.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(event.row!.className)
    }
    ObjectClientSrv.openObj(param)
  }

  //#endregion

  changeToolItem(event: KDataGridRowSelectedEvent<any>) {
    const selectRows = this.refObjectClassGrid.value?.getSelectedRows()
    if (!selectRows || selectRows.length == 0) {
      this.toolStripItems.value = [...baseToolItem]
      return
    }
    const param = { modelCode: selectRows[0].rdmExtensionType, environment: 100, selectedDatas: selectRows }
    this.refObjectClassGrid.value?.updateToolStripState(param)
  }

  /**
   * 工具栏点击事件
   */
  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const rows = this.refObjectClassGrid.value!.getSelectedRows()
    if (rows && rows.length > 0) {
      rows.forEach(item => {
        item.targetId = item.id
        item.targetClass = item.className
        item.targetExtensionType = item.rdmExtensionType
        item.targetFolderId = item.folder?.id
      })
    }
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.create()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE:
        this.delete()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CONVERT:
        this.batchConvertUniversalparts()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD:
        this.downloadTemplate()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_IMPORT:
        this.partImport()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE:
        FolderClientSrv.revise(rows!).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS:
        FolderClientSrv.saveAs(rows!).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE:
        FolderClientSrv.structManage(rows!)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT:
        FolderClientSrv.checkOut(rows!).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT:
        FolderClientSrv.unCheckOut(rows!).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN:
        FolderClientSrv.checkIn(rows!).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_USING_STATISTICS:
        FolderClientSrv.usingStatistics(rows!)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE:
        FolderClientSrv.baselineCompare(rows!)
        break
      default:
        break
    }
  }

  /**
   * 获取零部件
   */
  async getPart(id: string) {
    const result = (await Api.post('part', 'Part', 'get', { data: [id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件失败',
        details: result.detail
      })
      return
    }
  }

  /**
   * 创建对象
   */
  create() {
    if (!this.folderId.value) {
      KNotification.warning({
        title: '存储位置未选择',
        content: '请先选择工作区下的存储位置'
      })
      return
    }
    KDialog.show({
      title: '创建对象',
      content: KObjectDynamicCreatePanel,
      props: {
        folderId: this.folderId.value,
        defaultSelectClsCode: this.selectObjType.value,
        showObjClsSelector: false
      },
      size: { width: 1200, height: 600 },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewInstance as any
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }
        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refObjectClassGrid.value?.refresh()
          KNotification.success('对象创建成功')
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '创建对象失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 删除
   *
   * @returns
   */
  delete() {
    const rows = this.refObjectClassGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: async () => {
        let result: Record<string, any> | undefined
        if (this.selectObjType.value == 'Part') {
          const rowIds = rows!.map(row => row.id)
          result = await Api.post('part', 'Part', 'batchDelete', { data: [rowIds] })
        } else if (this.selectObjType.value == 'Document') {
          const idObjs = rows?.map(item => {
            return item.id
          })
          result = await Api.post('doc', 'Document', 'batchDelete', { data: [idObjs] })
        }
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '删除成功'
          })

          // 刷新网格
          this.refObjectClassGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '删除失败',
            content: result?.message || '删除失败',
            details: result?.detail
          })
          return
        }
      }
    })
  }

  /**
   * 编辑
   */
  editObj() {
    if (this.selectObjType.value == 'Part') {
      this.updatePart()
    }
  }

  /**
   * 编辑零部件
   */
  updatePart() {
    const rows = this.refObjectClassGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    // 获取对象id
    const rowIds = rows!.map(row => row.master.id)
    // 获取对象
    const partData = this.getPart(rowIds[0])

    KDialog.show({
      title: '编辑零部件',
      size: { width: 800, height: 600 },
      props: { formValue: partData },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPartForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPartFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }

        const formData = formViewModel.getValue()
        const result = await Api.post('part', 'Part', 'create', { data: [formData] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          await this.refObjectClassGrid.value?.refresh()

          KNotification.success({
            title: '系统提示',
            content: '更改成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '更改失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 批量转通用件
   */
  async batchConvertUniversalparts() {
    const rows = this.refObjectClassGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    // 获取对象id
    const rowIds = rows!.map(row => row.branch.id)

    const objList = rows!.map(row => {
      return {
        branchId: row.branch.id,
        folderId: row.branch.folder.id,
        className: row.branch.rdmExtensionType,
        clazz: row.branch.className
      }
    })

    KDialog.show({
      title: '批量转通用件',
      size: { width: 500, height: 200 },
      props: {
        showWorkspaceType: [FolderTypeEnum.COMMON_PARTS]
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KFolderSelector,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KFolderSelectorViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const folder = formViewModel.getValue() as any

        const result = await Api.post('folder', 'Folder', 'copyFolderObjLink', { data: [folder.id, objList] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          await this.refObjectClassGrid.value?.refresh()
          KNotification.success({
            title: '系统提示',
            content: '添加成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '添加失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 刷新对象
   */
  refresh() {
    this.refObjectClassGrid.value?.refresh()
  }

  /**
   * 零部件导入
   */
  partImport() {
    if (!this.folderId.value) {
      KNotification.warning({
        title: '存储位置未选择',
        content: '请先选择工作区下的存储位置'
      })
      return
    }
    const folderId = this.folderId.value
    KDialog.show({
      title: '零部件导入',
      size: { width: 500, height: 300 },
      props: { folderId: folderId },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPartImport,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        this.refObjectClassGrid.value?.refresh()
      }
    })
  }

  /**
   * 下载Excel模板
   */
  async downloadTemplate() {
    // 获取模板配置
    const fetchByCode = await Api.post('sys', 'ConfigSysQueryService', 'fetchByCode', {
      data: ['SYS_PART_IMPORT_TEMPLATE']
    })
    if (!(fetchByCode && fetchByCode.success)) {
      KNotification.warn({
        message: '系统提示',
        description: '无法获取零部件导入模板配置'
      })
      return
    }
    // 下载模板
    const param = {
      modelName: 'ZmsAllPropertyTest',
      fileIds: fetchByCode.data
    }
    const result = await request.post('/kmsaasFileApi/download', param, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    })
    if (result?.status == EnumRequestCode.SUCCESS && result.data) {
      const bolb = new Blob([result.data as any], { type: 'application/octet-stream,charset=UTF-8' })
      const url = URL.createObjectURL(bolb)
      const link = document.createElement('a')
      link.href = url
      link.download = '零部件导入模板.xlsx'
      link.click()
    } else {
      KNotification.error({
        title: '操作失败',
        content: '下载零部件导入模板失败'
      })
    }
  }
}

import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectClassManageView from './KObjectClassManage'
import KObjectClassManageViewModel from './KObjectClassManageViewModel'

const KObjectClassManage = connect(KObjectClassManageView, KObjectClassManageViewModel)

export default withInstall(KObjectClassManage)
export { KObjectClassManage, KObjectClassManageView, KObjectClassManageViewModel }
export * from './interface'


import { defineView } from '@kmsoft/upf-core'
import { KQuickSearcherPropOptions, KQuickSearcherEventEmits } from './interface'
import KQuickSearcherViewModel from './KQuickSearcherViewModel'

export default defineView({
  name: 'KQuickSearcher',
  props: KQuickSearcherPropOptions,
  emits: KQuickSearcherEventEmits,
  viewModel: KQuickSearcherViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

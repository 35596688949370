import { BaseViewModel, KDataGridRowDoubleClickEvent, KObject, KTreeSelectNode, ViewModelOptions } from '@kmsoft/upf-core'
import { KObjectWorkspaceEmitsType, KObjectWorkspacePropType } from './interface'
import { computed, ref, watch } from 'vue'
import { KRuleDefinerViewModel } from '../rule-definer'
import { CommonSearcherLifeStateForm, KCommonSearcherViewModel, KQuickSearcherViewModel } from '../object-searcher/tabs'
import {
  ClassMetaClientSrv,
  MetaProperty,
  ObjBusinessParam,
  ObjectClientSrv,
  QueryCondition,
  clsCodeVersionMap
} from '../../client-srv'
import { EnumObjectSearcherTab } from '../object-searcher'
import { KObjectWorkSpaceSearchViewModel } from '../object-work-space-search'
import { KObjectClassGridViewModel } from '../object-class-grid'
import { KObjectViewSelectorViewModel } from '../object-view-selector'

/** KObjectWorkspace */
export default class KObjectWorkspaceViewModel extends BaseViewModel<KObjectWorkspaceEmitsType, KObjectWorkspacePropType> {
  /** 搜索面板 */
  refIKObjectWorkspaceSearch = ref<KObjectWorkSpaceSearchViewModel>()
  /** 高级查询引用 */
  refKRuleDefine = ref<KRuleDefinerViewModel>()
  /** 通用查询引用 */
  refKCommonSearcher = ref<KCommonSearcherViewModel>()
  /** 快速查询引用 */
  refKQuickSearcher = ref<KQuickSearcherViewModel>()

  /** 固定对象类Id */
  freezeClassCode = computed(() => this.props.showObjClsCodes.length == 1)
  /** 类编码 */
  modelCode = ref<string>()
  /** 类分组 */
  modelGroup = ref<string | undefined>()
  /** 类名 */
  className = ref<string | undefined>()
  /** 加载状态 */
  isLoading = ref<boolean>(false)
  /** 对象属性 */
  classTemplate = ref<string>()
  /** 是否显示通用查询页签 */
  isAllowCommonSearch = ref(false)
  /** 是否显示快速查询页 */
  isAllowQuickSearch = ref(false)
  /** 拓展对象属性列表 */
  fields = ref<Array<MetaProperty>>([])
  /** 标签页 */
  tabKey = ref<EnumObjectSearcherTab>(EnumObjectSearcherTab.COMMON_SEARCH)
  /** 包含子类 */
  isIncludeChildren = ref<boolean>(false)

  /** 对象网格实例 */
  refObjectQueryComponent = ref<KObjectClassGridViewModel>()

  /** 通用搜索配置 */
  commonCondition = ref<{ condition: QueryCondition; lifeState: CommonSearcherLifeStateForm }>()
  /** 高级搜索配置 */
  advancedCondition = ref<QueryCondition>()
  /** 快速搜索配置 */
  quickSearchCondition = ref<QueryCondition>()
  /**选中文件夹id */
  selectDocId = ref<string>('')
  /** 文档对象下拉树选择 */
  targetClsObjectTreeData = ref<Array<KTreeSelectNode>>([])
  /** 对象类树属性 */
  objectClassTreeProps = computed(() => {
    return {
      objClassTypes: this.props.classTemplates,
      showObjClsIds: this.props.showObjClsCodes,
      hideObjClsIds: this.props.hideObjClsCodes
    }
  })
  /** 过滤参数 */
  filter = ref<QueryCondition>()
  constructor(options: ViewModelOptions<KObjectWorkspacePropType>) {
    super(options)
    watch(
      () => this.modelCode.value,
      () => {
        this.filter.value = undefined
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  //#region 事件
  /**
   * 搜索
   * @param condition
   */
  onSearch(condition: QueryCondition) {
    condition.conditions?.forEach(condition => {
      if (condition.conditionName == 'folder.id' && clsCodeVersionMap.get(this.modelCode.value!)) {
        condition.conditionName = 'branch.folder.id'
      }
    })
    this.filter.value = condition
    // TODO  ????
  }

  /** 取消搜索 */
  onCancel() {
    this.filter.value = undefined
  }

  /**
   * 双击打开编辑面板
   * @param event 行双击事件
   */
  async onRowDoubleClick(event: KDataGridRowDoubleClickEvent) {
    const param = {
      id: event.row!.id,
      modelCode: event.row!.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(event.row!.className)
    }
    ObjectClientSrv.openObj(param)
  }

  //#region
  /**
   * 获取选中的业务对象参数
   * @returns
   */
  getSelectedObjParams(): ObjBusinessParam[] {
    if (!this.refObjectQueryComponent.value) {
      return []
    }
    return this.refObjectQueryComponent.value.getSelectedObjParams()
  }

  /**
   * 获取选中行
   * @returns
   */
  getSelectedRows(): Record<string, any>[] {
    if (!this.refObjectQueryComponent.value) {
      return []
    }

    /** 获取选中行 */
    const rows = this.refObjectQueryComponent.value.getSelectedRows()

    // 如果没选中行
    if (!rows) {
      return []
    }

    return rows
  }
  //#endregion
}

import {
  BaseViewModel,
  KContainer,
  KControl,
  KGridLayoutConfig,
  KRootLayout,
  KSchema,
  LayoutRenderElement,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KPreviewEventEmitsType, KPreviewPropType } from './interface'
import { ref, watch } from 'vue'

/** 设计器区ViewModel */
export default class KPreviewViewModel extends BaseViewModel<KPreviewEventEmitsType, KPreviewPropType> {
  /** 网格布局区域,方便获取宽度 */
  refPreviewContainer = ref<HTMLElement>()

  /** 子元素实例映射  */
  childElementInstanceMap = ref(new Map<string, any>())

  /** 下拉框是否显示 */
  dropdownVisible = ref(false)
  /** 下拉按钮选中项 */
  dropSelect = ref('')
  /** 工具栏新增弹框 */
  showDialog = ref(false)
  /** 工具箱选中项 */
  toolStripSelectItem = ref('' as any)

  constructor(options: ViewModelOptions<KPreviewPropType>) {
    super(options)
    watch(
      () => this.props.schema,
      (newValue, oldValue) => {
        if (newValue.controls.length == 0) {
          this.clearElementSelected()
        }
      },
      { deep: true }
    )
  }

  /**
   * 布局元素选中事件
   * @param element 选中的元素
   */
  onElementSelected(element: LayoutRenderElement | undefined, isDelete?: boolean) {
    if (!element && !isDelete) {
      return
    }
    this.emit('elementSelected', element)
  }

  /**
   * 清除选中
   */
  clearElementSelected() {
    this.emit('elementSelected', null, true)
  }

  /**获取当前控件父元素的布局配置 */
  getParentElementLayoutConfig(
    code: string,
    elements: Array<KContainer | KControl | KSchema | KRootLayout>
  ): KGridLayoutConfig | undefined {
    // 循环元素
    for (let i = 0; i < elements.length; i++) {
      /** 当前元素 */
      const container = elements[i] as KContainer

      // 如果是容器
      if (container.controls && container.controls.length > 0) {
        // 查找对应元素
        const findLayout = container.controls.find(x => x.name === code)
        // 返回容器布局
        if (findLayout !== undefined) {
          const layoutConfig = container.props as KGridLayoutConfig
          return { ...layoutConfig }
        }
      } else {
        continue
      }

      // 若当前元素有children,则继续递归
      if (container.controls && Reflect.has(container, 'controls')) {
        const result = this.getParentElementLayoutConfig(code, container.controls)
        if (result) {
          return result
        }
      }
    }
  }
}

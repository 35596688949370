
import { defineView } from '@kmsoft/upf-core'
import { KDistributionRecordCreatePropOptions, KDistributionRecordCreateEventEmits } from './interface'
import KDistributionRecordCreateViewModel from './KDistributionRecordCreateViewModel'

export default defineView({
  name: 'KDistributionRecordCreate',
  props: KDistributionRecordCreatePropOptions,
  emits: KDistributionRecordCreateEventEmits,
  viewModel: KDistributionRecordCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

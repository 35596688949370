
import { defineView } from '@kmsoft/upf-core'
import { clsCodeMap } from '@kmsoft/ebf-common'
import { KAppendChangeActivityPropOptions, KAppendChangeActivityEventEmits } from './interface'
import KAppendChangeActivityViewModel from './KAppendChangeActivityViewModel'

export default defineView({
  name: 'KAppendChangeActivity',
  props: KAppendChangeActivityPropOptions,
  emits: KAppendChangeActivityEventEmits,
  viewModel: KAppendChangeActivityViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { clsCodeMap }
  }
})

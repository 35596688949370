import { ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'
import { KObjectPanelTabEventEmits, KObjectPanelTabPropOptions } from '../object-panel'

/** 参数 **/
export const KObjectRelationsPropOptions = {
  ...KObjectPanelTabPropOptions
}

/** 参数类型 **/
export type KObjectRelationsPropType = ViewPropsTypeExtract<typeof KObjectRelationsPropOptions>

/** 事件 */
export const KObjectRelationsEventEmits = {
  ...KObjectPanelTabEventEmits
}

/** 事件类型 **/
export type KObjectRelationsEmitsType = ViewEmitsTypeExtract<typeof KObjectRelationsEventEmits>

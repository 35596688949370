import { ObjBusinessParam, ObjectPanelAttachParams, ObjectPanelTabRetrieveEvent } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/**
 * 工作状态
 * @description 工作状态
 */
export enum EnumWorkState {
  /**检入 */
  CHECKED_IN = 'CHECKED_IN',
  /** 检出 */
  CHECKED_OUT = 'CHECKED_OUT',
  /** 工作副本 */
  INWORK = 'INWORK'
}

/** 参数 **/
export const KPartEditPanelPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  /** 附属参数 */
  attachParams: VuePropTypes.createType<ObjectPanelAttachParams>().def({}),
  container: VuePropTypes.createType<HTMLElement>().def()
}

/** 参数类型 **/
export type KPartEditPanelPropType = ViewPropsTypeExtract<typeof KPartEditPanelPropOptions>

/** 事件 */
export const KPartEditPanelEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 自定义事件
   * @param event
   * @returns
   */
  retrieve: (event: ObjectPanelTabRetrieveEvent<any, any>) => true
}

/** 事件类型 **/
export type KPartEditPanelEmitsType = ViewEmitsTypeExtract<typeof KPartEditPanelEventEmits>

import { EnumToolStripCompType, ToolStripItem, KContainer, KControl, KGridLayoutConfig } from '@kmsoft/upf-core'
import { createVNode, resolveComponent } from 'vue'
import { DynamicViewControlOptions } from '../interface'
import { KViewRenderElementWrap } from '../../render'

/**
 * 渲染基础控件
 * @param el 元数据
 * @param readonly
 * @param parentLayout
 * @param options
 * @returns
 */
export function renderBaseControl(
  meta: KControl,
  readonly: boolean,
  parentLayout: KGridLayoutConfig,
  options: DynamicViewControlOptions
) {
  /** 节点 */
  const vNode = createVNode(options.component!, {
    ref_key: 'ref' + meta.name,
    name: meta.name,
    title: meta.title,
    meta: meta,
    parentLayout: parentLayout,
    visible: meta.visible,
    disabled: meta.disabled,
    readonly: readonly,
    field: meta.field || '',
    ...meta.props
  })

  return (
    <KViewRenderElementWrap meta={meta} parentLayout={parentLayout}>
      {vNode}
    </KViewRenderElementWrap>
  )
}

/**
 *  渲染工具栏
 * @param el 工具栏元素
 * @param options
 * @param ref 实例
 */
export const renderToolStrip = (el: KContainer, options: DynamicViewControlOptions, ref?: any) => {
  /** 组织工具栏条目 */
  const items = buildToolStripItems(el.controls as Array<KControl>)

  return createVNode(
    resolveComponent(el.control),
    {
      name: el.name,
      style: { width: '100%' },
      ref: ref,
      visible: el.visible,
      disabled: el.disabled,
      readonly: el.readonly,
      items,
      ...el.props,
      ...options.props
    },
    options.slots
  )
}

const buildToolStripItems = (controls: Array<KControl>): Array<ToolStripItem> => {
  return controls.map(c => {
    let item = {
      name: c.name,
      title: c.title,
      visible: c.visible,
      disabled: c.disabled,
      ...c.props
    }
    switch (c.control) {
      case 'KToolStripButtonItem':
        return {
          ...item,
          compType: EnumToolStripCompType.BUTTON
        } as ToolStripItem
      case 'KToolStripInputItem':
        return {
          ...item,
          compType: EnumToolStripCompType.INPUT
        } as ToolStripItem
      case 'KToolStripCheckboxItem':
        return {
          ...item,
          compType: EnumToolStripCompType.CHECKBOX
        } as ToolStripItem
      case 'KToolStripSelectItem':
        return {
          ...item,
          compType: EnumToolStripCompType.SELECT
        } as ToolStripItem
      case 'KToolStripDateItem':
        return {
          ...item,
          compType: EnumToolStripCompType.DATE
        } as ToolStripItem
      case 'KToolStripDropdownItem':
        return {
          ...item,
          compType: EnumToolStripCompType.DROPDOWN
        } as ToolStripItem
      case 'KToolStripRadioItem':
        return {
          ...item,
          compType: EnumToolStripCompType.RADIO
        } as ToolStripItem
      case 'KToolStripContextMenuItem':
        return {
          ...item,
          compType: EnumToolStripCompType.CONTEXT_MENU
        } as ToolStripItem
      default:
        return {
          ...item,
          compType: EnumToolStripCompType.BUTTON
        } as ToolStripItem
    }
  })
}

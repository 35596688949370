import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KConfigFormItemPropOptions = {
  ...BaseViewPropOptions,
  formValue: VuePropTypes.record().def({}),
  formList: VuePropTypes.array().def([]),
  labelCol: VuePropTypes.number().def(4),
  isView: VuePropTypes.bool().def(false),
  colSpan: VuePropTypes.number().def(1)
}

/** 参数类型 **/
export type KConfigFormItemPropType = ViewPropsTypeExtract<typeof KConfigFormItemPropOptions>

/** 事件 */
export const KConfigFormItemEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KConfigFormItemEmitsType = ViewEmitsTypeExtract<typeof KConfigFormItemEventEmits>

export type compConfig = {
  isRequired: boolean
  message: string
}
export type attrs = {
  disabled: boolean
}
export type formFields = {
  ref: any
  type: string
  label: string
  props: string
  name: any
  lableCol: number
  compConfig: Record<string, compConfig>
  attrs: Record<string, attrs>
}

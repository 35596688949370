import { connect, withInstall } from '@kmsoft/upf-core'
import KImageEditorView from './KImageEditor.vue'
import KImageEditorViewModel from './KImageEditorViewModel'

const KImageEditor = connect(KImageEditorView, KImageEditorViewModel)

export default withInstall(KImageEditor)
export { KImageEditor, KImageEditorView, KImageEditorViewModel }

// 模板生成文件
// export * from './{{folderName}}'

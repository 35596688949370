
import { defineView } from '@kmsoft/upf-core'
import { KDistributionEditPropOptions, KDistributionEditEventEmits } from './interface'
import KDistributionEditViewModel from './KDistributionEditViewModel'
import { readonly } from 'vue'

export default defineView({
  name: 'KDistributionEdit',
  props: KDistributionEditPropOptions,
  emits: KDistributionEditEventEmits,
  viewModel: KDistributionEditViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KGridDesignerToolBoxPropType, KGridDesignerToolBoxEventEmitsType } from './interface'
import { computed, ref, watch } from 'vue'
import { KDesignerControl, KDesignerToolBox } from '../../../../../types'

/** 导航管理 */
export default class KGridDesignerToolBoxViewModel extends BaseViewModel<
  KGridDesignerToolBoxEventEmitsType,
  KGridDesignerToolBoxPropType
> {
  activeKey = ref('1')

  constructor(options: ViewModelOptions<KGridDesignerToolBoxPropType>) {
    super(options)
  }

  viewDidMount(): void {}
}

export const PLM_IOC_KEY = {
  EVENT_HOOKS: Symbol('EVENT_HOOKS')
}

/** Saas全局事件名称枚举 */
export const EnumSaasEventNames = {
  /** 代理状态发生变化 */
  AGENT_STATUS_CHANGED: 'agent:statusChange',
  /** 未读消息发生变化 */
  UNREAD_MESSAGE_COUNTE_CHANGED: 'message:messageChange',
  /** 剪切板数据发生变化 */
  CLIPBOARD_DATA_CHANGE: 'clipboard:dataChange',
  /** 导航菜单数据发生变化 */
  NAVIGATION_MENU_CHANGE: 'NavigationMenuChange'
}

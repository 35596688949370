import { EnumFilterOperator } from '@kmsoft/upf-core'
import { KDataGridColumn } from '@kmsoft/upf-core'
import { VNode, createVNode, resolveComponent } from 'vue'
export abstract class IKPropertyRender {
  /**
   * 获取非编辑模式下显示模板
   * @param params
   */
  abstract getColumn(): KDataGridColumn
  /**
   * 获取编辑状态下显示模板
   * @param params
   */
  abstract getEditor(params: Record<string, any>): VNode

  /**
   * 获取操作符
   */
  abstract getOperator(): EnumFilterOperator
}

export function applyToConstructor(renderName: string, argArray: any[]) {
  const renderComponent = createVNode(resolveComponent(renderName))
  const clazz = renderComponent.type as { new (): IKPropertyRender }
  const args = [null].concat(argArray)
  if (clazz.prototype && clazz.prototype.constructor) {
    const constructor = clazz.prototype.constructor
    const factoryFunction = constructor.bind.apply(constructor, args as any)
    return new factoryFunction()
  } else {
    return null
  }
}

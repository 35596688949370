import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartViewFormPropOptions = {
  ...BaseViewPropOptions,
  formProp: VuePropTypes.record().def()
}

/** 参数类型 **/
export type KPartViewFormPropType = ViewPropsTypeExtract<typeof KPartViewFormPropOptions>

/** 事件 */
export const KPartViewFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartViewFormEmitsType = ViewEmitsTypeExtract<typeof KPartViewFormEventEmits>

export interface IKPartViewFormDef {
  /**
   * id
   */
  id: string
  /**
   * 名称
   */
  name: string

  /**
   * 描述
   */
  description: string

  /**
   * 父id
   */
  parentId: string
}

/**
 * prop属性
 */
export interface IKPartViewFormPropDef {
  parentId: string
}

import {
  BaseViewModel,
  IKTreeNode,
  KNotification,
  KTreeViewViewModel,
  TreeViewSelectEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { nextTick, ref, watch } from 'vue'
import { convertToEnum } from '../..'
import { Api, EnumRequestCode } from '../../../../client-srv'
import { KFolderSelectorFormEmitsType, KFolderSelectorFormPropType } from './interface'

/** KKFolderSelectorForm */
export default class KFolderSelectorFormViewModel extends BaseViewModel<
  KFolderSelectorFormEmitsType,
  KFolderSelectorFormPropType
> {
  /**树组件 */
  refFolderTree = ref<KTreeViewViewModel>()
  /**工作区Id */
  workspaceId = ref<string>('')
  /** 工作区对象下拉树选择 */
  workspaceTreeData = ref<any[]>([])
  /** 文件夹Id */
  folderId = ref<string>('')

  constructor(options: ViewModelOptions<KFolderSelectorFormPropType>) {
    super(options)
    watch(
      () => this.workspaceId.value,
      () => {
        this.folderId.value = ''
        this.refFolderTree.value?.refresh()
      }
    )
  }

  async viewDidMount() {
    await this.loadOptions()
    if (this.props.folderId) {
      await this.getWorkspace(this.props.folderId)
    }
    this.folderId.value = this.props.folderId
  }
  /**
   * 工作区候选值加载
   */
  async loadOptions() {
    const param = {
      data: []
    }
    const result = await Api.post('folder', 'Folder', 'listAllWorkspace', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const data = result.data
        .filter((data: Record<string, object>) => {
          if (this.props.showWorkspaceType && this.props.showWorkspaceType.length > 0) {
            if (this.props.showWorkspaceType.includes(convertToEnum(data.type.toString()))) {
              return true
            } else {
              return false
            }
          }
          return true
        })
        .map((data: Record<string, object>) => {
          const node = {
            id: data.id,
            pId: data.parentId,
            title: data.name,
            isLeaf: data.leafFlag,
            value: data?.id
          }
          return node
        })
      this.workspaceTreeData.value = data
    }
  }
  /**
   * 加载文件夹树
   */
  loadTreeData(): Promise<Array<IKTreeNode>> {
    return new Promise((resolve, reject) => {
      if (this.workspaceId.value == '' || this.workspaceId.value == null) {
        resolve([])
        return
      }
      const param = {
        data: [this.workspaceId.value]
      }
      Api.post('folder', 'Folder', 'listChildFolder', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          resolve(res.data)
        } else {
          resolve([])
        }
      })
    })
  }
  /**
   * 设置选中树节点
   */
  setSelectNode() {}

  /**
   * 获取工作区
   * @param folderId 文件夹Id
   */
  async getWorkspace(folderId: string) {
    if (folderId == '' || folderId == null) {
      return
    }
    const param = {
      data: [folderId]
    }
    const result = await Api.post('folder', 'Folder', 'getWorkspaceByFolderId', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.workspaceId.value = result.data.id
    } else {
      KNotification.warn({
        message: '系统提示',
        description: result.message
      })
      return
    }
  }

  afterSelect(event: TreeViewSelectEventArgs<any>) {
    this.folderId.value = event.node.id
  }

  getFolderId() {
    return this.folderId.value
  }
  loaded() {
    if (this.props.folderId) {
      nextTick(() => {
        this.refFolderTree.value?.expandTo(this.props.folderId)
        this.refFolderTree.value?.setSelectedNode(this.props.folderId)
      })
    }
  }
}

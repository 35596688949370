import { KTreeNode, request } from '@kmsoft/upf-core'
import { IDirectoryTreeNodeData } from '../interface'
import KDirectoryTreeViewModel from '../KDirectoryTreeViewModel'
import KDirectoryTreeNodeBase from './KDirectoryTreeNodeBase'
import { ConfigClientSrv } from '../../../client-srv'
import { object } from 'vue-types'

export default class KRootDirectoryTreeNode extends KTreeNode {
  get dirTreeViewModel() {
    return this.host as KDirectoryTreeViewModel
  }
  constructor() {
    super()
    this.id = '__root__'
    this.name = '全局'
    this.leaf = false
    this.iconType = 'home'
  }

  async populateChildren(populateType: number): Promise<KTreeNode[]> {
    const nodes = new Array<KTreeNode>()
    const baseApiUrl = ConfigClientSrv.baseApiUrl
    const url = `${baseApiUrl}/directory/getChildDirectory`
    const result = (await request.post(url, { object: 0 })) as any

    if (!result.isSuccess) {
      return []
    }

    const showRootDirectoryCodes = this.dirTreeViewModel.showRootDirectoryCodes

    for (const item of result.data!) {
      if (item.moduleType != this.dirTreeViewModel.moduleType) {
        continue
      }

      if (
        this.dirTreeViewModel.directoryTypes &&
        this.dirTreeViewModel.directoryTypes.length > 0 &&
        (!item.type || this.dirTreeViewModel.directoryTypes?.indexOf(item.type)) == -1
      ) {
        continue
      }

      if (showRootDirectoryCodes && showRootDirectoryCodes.length > 0) {
        if (!item.code || showRootDirectoryCodes.indexOf(item.code) == -1) {
          continue
        }
      }

      const node = new KDirectoryTreeNodeBase<IDirectoryTreeNodeData>(item)

      if (node) {
        nodes.push(node)
      }
    }

    return nodes
  }
}

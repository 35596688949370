import {
  KTreeViewEventEmits,
  KTreeViewEventEmitsType,
  KTreeViewPropType,
  VNodeProps,
  defineView,
  exposeComponentEvents
} from '@kmsoft/upf-core'
import { KPartStructureTreePropOptions, KPartStructureTreeEventEmits } from './interface'
import KPartStructureTreeViewModel from './KPartStructureTreeViewModel'
import { KObjectToolStripEventEmitsType, KObjectToolStripPropType, RuntimeHelper } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KPartStructureTree',
  props: KPartStructureTreePropOptions,
  emits: KPartStructureTreeEventEmits,
  viewModel: KPartStructureTreeViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const exposedEvents = exposeComponentEvents(vm.refTreeView!, Object.keys(KTreeViewEventEmits), emit)

    return () => {
      /** 工具栏参数 */
      const toolStripProps: VNodeProps<KObjectToolStripPropType, KObjectToolStripEventEmitsType> = {
        ref: vm.refToolStrip,
        hideText: true,
        enableShortcut: true,
        onItemClicked: vm.onToolStripClicked
        // scriptContext: onGetContext
      }

      /** 树参数 */
      const treeViewProps: VNodeProps<KTreeViewPropType, KTreeViewEventEmitsType> = {
        ...props,
        ...exposedEvents,
        ref: vm.refTreeView,
        checkStrictly: !vm.notCheckStrictly.value,
        autoSelectLastNode: false,
        defaultExpandedLevel: 0,
        autoSelectFirstNodeAfterDefaultExpand: false,
        contextMenuList: props.disabledContextMenu ? undefined : vm.toolStripItems.value,
        isMultipleSelection: vm.isMultipleSelection.value,
        enableShortcut: true,
        destroyMenuOnHide: !RuntimeHelper.isAutomatedTesting,
        loadData: vm.onLoadData,
        onReady: vm.onReady,
        getHost: () => {
          return vm.refTreeView.value!
        },
        onAfterExpand: vm.onAfterExpand,
        onBeforeMenuPopup: vm.onBeforeMenuPopup,
        onAfterSelect: vm.onAfterSelect,
        unselectablePredicate: vm.unselectablePredicate
      }

      return (
        <div class="k-part-structure-tree" onClick={vm.onContainerClick}>
          <div style="display: none;">
            <k-object-tool-strip {...toolStripProps} v-model={[vm.toolStripItems.value, 'items']}></k-object-tool-strip>
          </div>
          <div class="container" ref="refContainer" v-focus>
            <k-tree-view {...treeViewProps}></k-tree-view>
            <div class="options" v-show={vm.isMultipleSelection.value}>
              <k-checkbox v-model={[vm.notCheckStrictly.value, 'checked']}>级联</k-checkbox>
            </div>
          </div>
        </div>
      )
    }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KWorkflowTemplateSelectorView from './KWorkflowTemplateSelector.vue'
import KWorkflowTemplateSelectorViewModel from './KWorkflowTemplateSelectorViewModel'

const KWorkflowTemplateSelector = connect(KWorkflowTemplateSelectorView, KWorkflowTemplateSelectorViewModel)

export default withInstall(KWorkflowTemplateSelector)
export { KWorkflowTemplateSelector, KWorkflowTemplateSelectorView, KWorkflowTemplateSelectorViewModel }

// 模板生成文件
// export * from './{{folderName}}'

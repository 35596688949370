import { GlobalException, SelectOption } from '@kmsoft/upf-core'
import {
  ClassMetaClientSrv,
  EnumDataType,
  EnumPropertyEditorType,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  MetaProperty,
  ObjectHelper,
  QUERY_CONDITION_JOINER_COMPARISONS,
  QUERY_CONDITION_OPERATOR_COMPARISONS,
  QueryCondition
} from '../../../../../client-srv'
import {
  EnumFilterLogicalOperation,
  EnumFilterOperatorCode,
  FILTER_OPERATOR_CODE_COMPARISONS,
  RuleItem,
  RuleResult
} from '../interface'
import { Ast, expressionToAst } from './bep'
import { ISearchFilterTree, SearchFilterTreeLeaf } from './filterTree'

export class PropertyConverter {
  /** 支持日期比较操作符 */
  private static DateTimeOperator = new Set([EnumFilterOperatorCode.GREATER, EnumFilterOperatorCode.LESS])
  /** 支持大小比较操作符 */
  private static OrderOperator = new Set([
    EnumFilterOperatorCode.EQUAL,
    EnumFilterOperatorCode.NOT_EQUAL,
    EnumFilterOperatorCode.GREATER,
    EnumFilterOperatorCode.LESS,
    EnumFilterOperatorCode.GREATER_EQUAL,
    EnumFilterOperatorCode.LESS_EQUAL
  ])
  /** 字符串比较操作符 */
  private static FinitySerialableOperator = new Set([
    EnumFilterOperatorCode.LIKE,
    EnumFilterOperatorCode.STARTWITH,
    EnumFilterOperatorCode.ENDWITH,
    EnumFilterOperatorCode.EQUAL,
    EnumFilterOperatorCode.NOT_EQUAL
    // EnumFilterOperatorCode.NOT_INCLUDE
    // EnumFilterOperatorCode.NOT_ENDWITH,
    // EnumFilterOperatorCode.NOT_STARTWITH,
  ])
  public static NULL_IDENTIFIER = '<%NULL%>'
  public static CURRENT_USER_IDENTIFIER = '<%CURRENT_USER%>'

  /**
   * 判断一个字段取值是否数字
   * @param field
   * @returns
   */
  public static isNumberField(field: MetaProperty): boolean {
    return field.type === EnumDataType.INT || field.type === EnumDataType.FLOAT
  }

  /**
   * 通过属性获得可选项
   * @param field 传入属性
   * @param optional 拓展参数
   * @returns 选项
   */
  public static getOptionsByField(
    field: MetaProperty,
    optional?: { allowNull?: boolean; allowCurrentUser?: boolean }
  ): Array<SelectOption> {
    if (!field) {
      return []
    }

    let options: SelectOption[] = []

    if (field.editor && field.editor.editArgs) {
      try {
        const args = JSON.parse(field.editor.editArgs) as { options: Array<SelectOption> }
        if ('options' in args) {
          options = args.options
        }
      } catch (error) {
        console.error('field.editor.editArgs 非合法json!')
      }
    }

    if (optional?.allowNull && field.nullable) {
      options.push({ label: 'NULL', value: this.NULL_IDENTIFIER })
    }

    if (optional?.allowCurrentUser) {
      options.push({ label: '当前用户', value: this.CURRENT_USER_IDENTIFIER })
    }

    return options
  }

  /**
   * 根据字段获取可用操作符选项
   * @param field 字段
   * @returns 可用操作符选项
   */
  public static getOperatorByField(field: MetaProperty): Array<SelectOption> {
    let operatorSet = new Set<EnumFilterOperatorCode>([])
    const options: SelectOption[] = []

    if (this.isDateTimeField(field)) {
      operatorSet = new Set([...this.DateTimeOperator])
    } else if (this.isTextField(field) || this.isEnumerableField(field)) {
      operatorSet = new Set([...this.FinitySerialableOperator])
    } else if (this.isNumberField(field)) {
      operatorSet = new Set([...this.OrderOperator])
    }

    // 过滤有限取值类型
    for (const operator of operatorSet) {
      options.push({
        label: FILTER_OPERATOR_CODE_COMPARISONS[operator],
        value: operator
      })
    }

    return options
  }

  /**
   * 判断一个字段是否日期
   */
  public static isDateTimeField(field: MetaProperty): boolean {
    return field.editor.editType == EnumPropertyEditorType.DATETIME
  }

  /**
   * 判断一个字段是否是有限序列（如数组，字符串）
   */
  public static isTextField(field: MetaProperty): boolean {
    const dataType = field.type
    if (dataType === EnumDataType.CHAR || dataType === EnumDataType.STRING) {
      return true
    } else {
      return false
    }
  }

  /**
   * 判断一个字段取值是否是可枚举的
   * @param field
   * @returns
   */
  public static isEnumerableField(field: MetaProperty): boolean {
    const editType = field.editor.editType
    if (
      editType === EnumPropertyEditorType.DROPDOWN_LIST_WITH_DISPLAY ||
      editType === EnumPropertyEditorType.DROPDOWN_LIST_EDITABLE ||
      editType === EnumPropertyEditorType.DROPDOWN_LIST ||
      editType === EnumPropertyEditorType.LIST_VIEW ||
      editType === EnumPropertyEditorType.LIST_VIEW_SINGLE_CHECK
    ) {
      return true
    } else {
      return false
    }
  }

  //#region 规则转换
  /**
   * 将 Condition 转换为界面显示的 Rule
   * @param condition
   */
  public static convertConditionToRule(condition: QueryCondition): RuleResult {
    if (!condition) {
      return { expression: '', rules: [] }
    }

    const queue = new Array<RuleItem>()

    this.getFilters(condition, queue)

    let expression = this.generateExpression(condition, queue)

    const leftCount = (expression.match(/\(/g) || []).length
    const rightCount = (expression.match(/\)/g) || []).length

    if (leftCount === rightCount && leftCount === 1 && expression.startsWith('(') && expression.endsWith(')')) {
      expression = expression.substring(1, expression.length - 1)
    }

    return {
      expression: expression,
      rules: queue
    }
  }

  private static findIndex(filters: Array<RuleItem>, obj: QueryCondition): string {
    for (let i = 0; i < filters.length; i++) {
      if (
        filters[i].operator.toString === obj.operator?.toString &&
        filters[i].key === obj.conditionName &&
        filters[i].value === obj.conditionValues?.join()
      ) {
        return (i + 1).toString()
      }
    }
    return '-1'
  }

  private static generateExpression(parse: QueryCondition, filters: Array<RuleItem>): string {
    if (!parse || Object.keys(parse).length === 0) {
      return ''
    }
    if (parse.joiner) {
      const queue = []
      if (parse.conditions) {
        for (let i = 0; i < parse.conditions.length; i++) {
          queue.push(this.generateExpression(parse.conditions[i], filters))
        }
        if (parse.joiner === EnumQueryConditionJoiner.And) {
          return queue.join(' and ')
        } else {
          return '(' + queue.join(' or ') + ')'
        }
      } else {
        return ''
      }
    } else {
      return this.findIndex(filters, parse)
    }
  }

  private static getFilters(parse: QueryCondition, queue: Array<RuleItem>): void {
    if (!parse.conditions || parse.conditions.length === 0) {
      queue.push({
        operator: ((parse.operator as unknown) as EnumFilterOperatorCode) ?? '',
        key: parse.conditionName ?? '',
        value: parse.conditionValues?.join(),
        id: '',
        isUsing: true,
        dataType: EnumDataType.STRING
      })
      return
    }
    for (let i = 0; i < parse.conditions.length; i++) {
      const condition = parse.conditions[i]
      if (condition.operator) {
        if (
          !queue.find(
            o =>
              o.operator.toString === condition.operator?.toString &&
              o.key === condition.conditionName &&
              o.value === condition.conditionValues?.join()
          )
        ) {
          queue.push({
            operator: ((condition.operator as unknown) as EnumFilterOperatorCode) ?? '',
            key: condition.conditionName ?? '',
            value: condition.conditionValues?.join(),
            id: '',
            isUsing: true,
            dataType: EnumDataType.STRING
          })
        } else {
          this.getFilters(condition, queue)
        }
      }
    }
  }

  /**
   * 将 Rule 转换 为 Condition
   * @param rule
   * @returns
   */
  public static convertRuleToCondition(rule: RuleResult): QueryCondition {
    const expression = rule.expression
    const rules = rule.rules
    const filters = new Array<QueryCondition>()
    for (let i = 0; i < rules.length; i++) {
      const node = rules[i]
      let value = node.value ?? ''
      value = typeof value === 'string' ? value : value.toString()
      filters.push({
        conditionName: node.key,
        operator: (node.operator as unknown) as EnumQueryConditionOperator,
        conditionValues: [value]
      })
    }
    const stack = new Array()
    const split = expression.split(' ')
    // 数字正则
    const numberPattern = /^\d+(\.\d+)?$/
    // and或者or
    const andOrPattern = /^(and|or)$/i
    for (let i = 0; i < split.length; i++) {
      let s = split[i]
      // 数字、and或者or字符串
      if (numberPattern.test(s) || andOrPattern.test(s)) {
        stack.push(s)
      }
      // 数字括号组合 (number或者number)
      else {
        // 左括号
        if (s.includes('(')) {
          while (s.includes('(')) {
            stack.push(s.substring(0, 1))
            s = s.substring(1, s.length)
          }
          stack.push(s)
        }
        // 右括号
        else {
          while (s.includes(')')) {
            const num = s.substring(0, s.indexOf(')'))
            const queue = [num]
            while (stack.length > 0 && stack[stack.length - 1] !== '(') {
              queue.unshift(stack.pop())
            }
            stack.pop()
            filters.push(this.joinExpression(queue.join(' '), filters))
            s = filters.length + s.substring(s.indexOf(')') + 1, s.length)
          }
          stack.push(filters.length)
        }
      }
    }
    return this.joinExpression(stack.join(' '), filters)
  }

  /**
   * 转换简单表达式
   * @param expression 表达式
   * @param filters 筛选条件
   * @returns
   */
  private static joinExpression(expression: string, filters: Array<QueryCondition>): QueryCondition {
    expression = expression.toLowerCase()
    if (expression.length === 0) {
      return {}
    }
    if (expression.includes(EnumFilterLogicalOperation.Or)) {
      const split = expression.split(EnumFilterLogicalOperation.Or)
      const list = new Array<QueryCondition>()
      split.forEach(s => {
        list.push(this.joinExpression(s.trim(), filters))
      })
      return {
        joiner: EnumQueryConditionJoiner.Or,
        conditions: list
      }
    } else if (expression.includes(EnumFilterLogicalOperation.And)) {
      const list = new Array<QueryCondition>()
      const split = expression.split(EnumFilterLogicalOperation.And)
      split.forEach(s => {
        list.push(this.joinExpression(s.trim(), filters))
      })
      return {
        joiner: EnumQueryConditionJoiner.And,
        conditions: list
      }
    } else {
      return filters[parseInt(expression) - 1]
    }
  }

  /**
   * 将条件转换问文本
   * @param parse
   * @param modelCode 对应的类编码，如果不传conditionName拼接的是英文
   * @returns
   */
  public static convertConditionToString(parse: QueryCondition, properties?: Array<MetaProperty>) {
    // 如果条件不存在，则返回空字符串
    if (!parse) {
      return ''
    }

    const alias = properties ? properties.filter(item => item.code == parse.conditionName)[0]?.name : parse.conditionName

    // 如果顶层是基本条件
    if (parse.conditionName && parse.operator && parse.conditionValues) {
      return `${alias} ${QUERY_CONDITION_OPERATOR_COMPARISONS[parse.operator]} ${parse.conditionValues.join('-')}`
    }

    // 如果顶层是复合条件
    if (parse.joiner && parse.conditions && parse.conditions.length > 0) {
      /** 子条件 */
      const subConditionsString: string = parse.conditions
        .map(subCondition => {
          return this.convertConditionToString(subCondition, properties)
        })
        .join(` ${QUERY_CONDITION_JOINER_COMPARISONS[parse.joiner]} `)

      return `${subConditionsString}`
    }
    return ''
  }
  /**
   * 将过滤结果解析
   * @param asf
   * @returns
   */
  public static advancedSearchFilterToSearchFilterTree(asf: RuleResult): ISearchFilterTree {
    /** 过滤表达式 */
    const expr = asf.expression!
    /** 将表达式解析成列表 */
    const parseResult = expressionToAst(expr)
    if (parseResult.ok) {
      return this.astToSft(parseResult.val, asf.rules)
    } else {
      throw GlobalException.getSuggestionException('无法解析表达式：' + parseResult.val)
    }
  }

  private static astToSft(ast: Ast, filters: Array<RuleItem>): ISearchFilterTree {
    if (ast.type === 'Ident') {
      const filter = filters[Number.parseInt(ast.val) - 1]
      return new SearchFilterTreeLeaf(filter.key, filter.operator, ObjectHelper.objectToString(filter.value), filter.dataType)
    } else if (ast.type === 'Bracketed') {
      return this.astToSft(ast.node, filters)
    } else {
      const lhs = this.astToSft(ast.left, filters)
      const rhs = this.astToSft(ast.right, filters)
      if (ast.op === 'and') {
        return lhs.and(rhs)
      } else {
        return lhs.or(rhs)
      }
    }
  }
  //#endregion
}

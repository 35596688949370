import { Guid } from '@kmsoft/upf-core'
import { RequestHelper } from './RequestHelper'

export class ObjectHelper {
  /**
   * 排除一个对象上的属性
   * @param obj 对象
   * @param fields 属性列表
   * @returns
   */
  static omit<T extends object, K extends keyof T>(obj: T, fields: K[]): Omit<T, K> {
    const shallowCopy = Object.assign({}, obj)
    for (let i = 0; i < fields.length; i += 1) {
      const key = fields[i]
      delete shallowCopy[key]
    }
    return shallowCopy
  }

  /** 获取时间戳 */
  static getTimeStamp(): string {
    return Date.now().toString()
  }

  /**
   * 获取枚举值
   * @param entries 枚举定义键值对，Object.entries(TEnum)
   * @param key 枚举键名（string）
   * @param defaultValue 如果查找失败，返回的默认值
   * @returns
   */
  static getEnumValueByKey<TEnum = any>(enumValue: any, key: string | null, defaultValue: any): TEnum {
    if (key == null || key == undefined) {
      return defaultValue
    }

    const entries = Object.entries(enumValue) as Array<Array<any>>

    for (const iterator of entries) {
      if (iterator[0].toString() == key) {
        return iterator[1]
      }
    }

    return defaultValue
  }

  /**
   * 获取枚举值
   * @param entries 枚举定义键值对，Object.entries(TEnum)
   * @param value 枚举值（string|number）
   * @param defaultValue 如果查找失败，返回的默认值
   * @returns
   */
  static getEnumKeyByValue<TEnum>(enumValue: any, value: string | number | null, defaultValue: any): TEnum {
    if (value == null || value == undefined) {
      return defaultValue
    }

    const entries = Object.entries(enumValue) as Array<Array<any>>

    for (const iterator of entries) {
      if (iterator[0].toString() == value.toString()) {
        return iterator[1]
      }
    }

    return defaultValue
  }

  /**
   * 获取GUID
   * @returns
   */
  static getServerGuid(): string {
    return RequestHelper.post<string>('api/Common/getGuid', {})
  }

  /**
   * 生成GUID
   * @returns GUID
   */
  static getGuid() {
    return Guid.newGuidString()
  }

  /**
   * 将对象转换为string
   * @param obj 对象
   * @returns
   */
  static objectToString(obj: any): string {
    switch (typeof obj) {
      case 'string':
        return obj
      case 'number':
        return obj.toString()
      case 'boolean':
        return obj.toString()
      case 'undefined':
        return ''
      case 'object':
        return JSON.stringify(obj)
      default:
        return ''
    }
  }

  /** 判断是否非空 */
  static isNotNullOrEmpty(value: any) {
    if (value === null) {
      return false
    }
    if (value === undefined) {
      return false
    }
    if (Array.isArray(value)) {
      return (value as any[]).length > 0
    }
    return true
  }

  static regInt: RegExp = new RegExp(/^\d+$/)
  static isIntString(value: string) {
    return value != null && value != undefined && this.regInt.test(value)
  }

  /**
   * 获取属性代理
   * @param methods 执行方法
   */
  static getObjectProxy(methods: (key: string) => Record<string, any> | undefined) {
    const object = {}
    return new Proxy(object, {
      get(target, key: string) {
        return methods(key)
      },
      has(target, key: string) {
        return true
      }
    })
  }

  /** 文件转Base4 */
  static fileToBase64(blob: File): Promise<string | undefined> {
    return new Promise<string | undefined>(reslove => {
      const reader = new FileReader()
      reader.onloadend = e => {
        if (!e.target) {
          reslove(undefined)
          return
        }
        reslove(e.target.result as string)
      }
      reader.readAsDataURL(blob)
    })
  }

  /** 解析XML */
  // static parseXML<TData = any>(str: string): TData {
  //   const obj = FastXmlParser.parse(str)
  //   return obj
  // }

  /**
   * 对象转XML
   * @param obj
   * @returns
   */
  // static stringifyXML(obj: any): string {
  //   const options: FastXmlParser.J2xOptionsOptional = {}
  //   const parser = new FastXmlParser.j2xParser(options)
  //   return parser.parse(obj)
  // }

  static isFunction = (val: unknown): val is Function => typeof val === 'function'
  static isString = (val: unknown): val is string => typeof val === 'string'
  static isSymbol = (val: unknown): val is symbol => typeof val === 'symbol'
  static isObject = (val: unknown): val is Record<any, any> => val !== null && typeof val === 'object'
  static isPromise = <T = any>(val: unknown): val is Promise<T> => {
    return ObjectHelper.isObject(val) && ObjectHelper.isFunction(val.then) && ObjectHelper.isFunction(val.catch)
  }

  static insertString(fullValue: string, startIndex: number, value: string, removeLength = 0): string {
    return fullValue.substring(0, startIndex) + value + fullValue.substring(startIndex + removeLength, fullValue.length)
  }

  static isNullOrEmpty(value: string | undefined | null) {
    return value == '' || value == null
  }

  static repeat(value: string, n: number): string {
    if (n <= 0) return ''
    if (n == 1) return value
    return Array(Math.trunc(n) + 1).join(value)
  }

  static padLeft(fullValue: string, totalWidth: number, paddingChar = ' '): string {
    const diff = totalWidth - fullValue.length
    if (diff > 0) {
      if (diff == 1) return paddingChar + fullValue
      return this.repeat(paddingChar, diff) + fullValue
    }
    return fullValue
  }
}

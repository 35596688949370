
import {
  EnumDataGridAutoSizeColumnsMode,
  EnumDataGridRowModelType,
  KDataGridRowSelectedEvent,
  defineView
} from '@kmsoft/upf-core'
import { KDocBomCompareResultPanelPropOptions, KDocBomCompareResultPanelEventEmits } from './interface'
import KDocBomCompareResultPanelViewModel from './KDocBomCompareResultPanelViewModel'

export default defineView({
  name: 'KDocBomCompareResultPanel',
  props: KDocBomCompareResultPanelPropOptions,
  emits: KDocBomCompareResultPanelEventEmits,
  viewModel: KDocBomCompareResultPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autoSizeColumnsMode: EnumDataGridAutoSizeColumnsMode.ALL_CELLS
    }
  }
})

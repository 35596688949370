import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_relation_grid = _resolveComponent("k-object-relation-grid")!

  return (_openBlock(), _createBlock(_component_k_object_relation_grid, {
    ref: _ctx.$vm.refRelationGrid,
    "obj-cls-code": _ctx.$vm.currentRelation?.destinationClsCode ?? '',
    "obj-param": _ctx.objParam,
    definition: _ctx.$vm.currentRelation
  }, null, 8, ["obj-cls-code", "obj-param", "definition"]))
}
import { UPF_IOC_KEYS, IAppConfigurer, IocContainer, IMenuPagePathMapping } from '@kmsoft/upf-core'
import { RequirementMenuPathMapping } from './beans'
import { App } from 'vue'
import globalControls from './controls'
import KCustomerNeedsCreate from './pages/customer-needs/customer-needs-create'
import KProductNeedsCreate from './pages/product-needs/product-needs-create'
import KMetricsEditPanel from './pages/common-validation-metrics/metrics-edit-panel'
import KCustomerNeedsEditPanel from './controls/customer-needs-edit-panel'
import KProductNeedsEditPanel from './controls/product-needs-edit-panel'
import KCustomerNeedsSectionEditPanel from './controls/customer-needs-section-edit-panel'
import KProductNeedsSectionEditPanel from './controls/product-needs-section-edit-panel'
import KValidationMetricsManage from './pages/validation-metrics-manage'
import KRequirementChangeCreateForm from './pages/requirement-change/requirement-change-create-form'
import KRequirementChangeEditPanel from './pages/requirement-change/requirement-change-edit-panel'
import KPlanEditPanel from './pages/verification-plan/plan-edit-panel'
import KPlanValidationMetricsGrid from './pages/verification-plan/plan-validation-metrics-grid'
// import { useExtension } from './extension'

export default {
  configVue: (app: App) => {
    app.use(globalControls)
    app.use(KCustomerNeedsCreate)
    app.use(KProductNeedsCreate)
    app.use(KCustomerNeedsEditPanel)
    app.use(KProductNeedsEditPanel)
    app.use(KCustomerNeedsSectionEditPanel)
    app.use(KProductNeedsSectionEditPanel)
    app.use(KMetricsEditPanel)
    app.use(KValidationMetricsManage)
    app.use(KRequirementChangeCreateForm)
    app.use(KRequirementChangeEditPanel)
    app.use(KPlanEditPanel)
    app.use(KPlanValidationMetricsGrid)
  },
  configBean: (iocContainer: IocContainer) => {
    iocContainer.registerSingleton<IMenuPagePathMapping>(UPF_IOC_KEYS.MENU_PAGE_PATH_MAPPING, new RequirementMenuPathMapping())
    // useExtension(iocContainer)
  }
} as IAppConfigurer

export { KCustomerNeedsCreate, KMetricsEditPanel }
export * from './client-srv'

import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../../propsConfigItemCollection'
import { KControlAttribute, KControlEvent } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../../layoutConfigItemCollection'

/* 属性配置项集合 */
const BASE: Array<KControlAttribute> = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE]
/**控件属性 */
const PROPS: Array<KControlAttribute> = []
/* 布局配置项集合 */
const LAYOUT: Array<KControlAttribute> = [
  LAYOUT_CONFIG_ITEM_COLLECTION.WIDTH,
  LAYOUT_CONFIG_ITEM_COLLECTION.ALIGN,
  LAYOUT_CONFIG_ITEM_COLLECTION.FROZEN,
  // LAYOUT_CONFIG_ITEM_COLLECTION.ROW_GROUP,
  // LAYOUT_CONFIG_ITEM_COLLECTION.ENABLE_ROW_GROUP,
  LAYOUT_CONFIG_ITEM_COLLECTION.HIDE
]

const EVENT: Array<KControlEvent> = []

export default { BASE, PROPS, LAYOUT, EVENT }

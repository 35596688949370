import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KChangeTracingPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KChangeTracingPropType = ViewPropsTypeExtract<typeof KChangeTracingPropOptions>

/** 事件 */
export const KChangeTracingEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangeTracingEmitsType = ViewEmitsTypeExtract<typeof KChangeTracingEventEmits>

import { BaseViewModel, ViewModelOptions, KNotification, KDataGridViewModel, MemoryCacheFactory } from '@kmsoft/upf-core'
import { IPartRouteItemDefinition, KPartUsingStatisticsEmitsType, KPartUsingStatisticsPropType } from './interface'
import { ref, watch, Ref } from 'vue'
import {
  Api,
  ClassMetaClientSrv,
  CommonClientSrv,
  EnumRequestCode,
  KObjectClassGridViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  PartViewMemoryCache
} from '@kmsoft/ebf-common'
import { KPartStructureManage } from '../part-structure-manage'

export default class KPartUsingStatisticsViewModel extends BaseViewModel<
  KPartUsingStatisticsEmitsType,
  KPartUsingStatisticsPropType
> {
  classCode = ref<string>()
  selectLevel = ref<number>(1)
  selectView = ref<number>(1)
  selectListView = ref(true)
  objParam = ref<ObjBusinessParam>()
  refObjGrid = ref<KObjectClassGridViewModel>()
  refLogicflow: Ref<any> = ref()
  flowData: Record<string, any>
  loadListView = ref<Array<IPartRouteItemDefinition>>([])
  statisticValue = ref<number>(0)
  //iconView = ref<Array<any>>([])
  config = {
    stopZoomGraph: false,
    stopScrollGraph: true
  }

  constructor(options: ViewModelOptions<KPartUsingStatisticsPropType>) {
    super(options)
    this.objParam.value = options.props.objParam
    watch(
      () => options.props.objParam,
      () => {
        this.objParam.value = options.props.objParam
        this.refObjGrid.value?.refresh()
      },
      {
        immediate: true
      }
    ),
      watch(
        () => this.selectLevel.value,
        () => {
          this.refObjGrid.value?.refresh()
        },
        {
          immediate: true
        }
      )
  }

  async loadIconData(): Promise<any> {
    const data = [] as any
    const result = (await this.expandForGrid()) as any
    // 刷新图标视图
    const iconView = result!.map(
      (resultData: { userObject: any; hasChildren: any; pid: any; name: any; number: any; id: any }) => {
        return {
          id: resultData.id,
          hasChildren: resultData.hasChildren,
          parentId: resultData.pid,
          name: resultData.name,
          number: resultData.userObject.bomLink.sourcePart.master.number
        }
      }
    ) as any
    if (iconView && iconView.length > 0) {
      for (let index = 0; index < iconView.length; index++) {
        data.push(iconView[index])
      }
    }
    return data
  }

  async loadData() {
    return new Promise((resolve, reject) => {
      this.listPart().then(res => {
        resolve(res)
      })
    })
  }

  /**
   * 引用对象列表
   */
  async listPart() {
    return new Promise((resolve, reject) => {
      this.expandForGrid().then((result: any) => {
        if (this.selectLevel.value == 1) {
          // 单层引用
          // 刷新列表视图
          result = result.filter((val: { pid: any }) => val.pid)
          this.loadListView.value = result!.map((resultData: { id: any; displayPath: any }) => {
            return {
              id: resultData.id,
              displayPath: resultData.displayPath
            }
          })
        } else {
          // 多层引用,只返回叶子节点
          result = result.filter((val: { hasChildren: any; pid: any }) => !val.hasChildren && val.pid)
          this.loadListView.value = result!.map((resultData: { id: any; displayPath: any }) => {
            return {
              id: resultData.id,
              displayPath: resultData.displayPath
            }
          })
        }
        this.statisticValue.value = result.length
        resolve(result)
      })
    })
  }

  /**
   * 网格展开
   */
  async expandForGrid() {
    const id = this.objParam.value?.id as string
    // 获取对象
    const part = (await this.getPart(id)) as any
    const maxLevel = this.selectLevel.value == 1 ? 1 : 99
    const param = {
      data: [
        {
          root: {
            id: id,
            clazz: part.rdmExtensionType
          },
          expandType: 'REVERSE_EXPANSION',
          queryRule: { type: 'LATEST_WITHOUT_WORKING_COPY' },
          view: part.partView.name,
          maxLevel: maxLevel
        }
      ]
    }
    const result = await Api.post('part', 'Part', 'expandForGrid', param)
    return new Promise((resolve, reject) => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        this.flowData = this.buildTree(result.data)
        this.refLogicflow.value?.refresh()
        resolve(result.data)
      } else {
        KNotification.error({
          title: '获取失败',
          content: result.message,
          details: result.detail
        })
        reject(void 0)
      }
    })
  }

  /**
   * 结构管理
   */
  async structureManage() {
    const rows = this.refObjGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    if (rows && rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '每次只能选择一条数据'
      })
      return
    }
    // 获取对象id
    const rowIds = rows!.map(row => row.userObject.bomLink.sourcePart.id)
    // 获取对象
    const partData = (await this.getPart(rowIds[0])) as any
    const title = '结构管理:' + ObjectClientSrv.getObjBusinessDesc(partData)
    CommonClientSrv.openPage(
      title,
      KPartStructureManage,
      {
        objParam: { modelCode: partData.rdmExtensionType, modelGroup: 'part', id: partData.id },
        partViewName: partData.partView.name
      },
      partData.id
    )
  }

  /**
   * 获取零部件
   */
  async getPart(id: string) {
    const result = (await Api.post('part', 'Part', 'get', { data: [id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件失败',
        details: result.detail
      })
      return
    }
  }

  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: any) {
    const param = {
      id: row?.userObject.bomLink.sourcePart.id,
      modelCode: row!.userObject.bomLink.sourcePart.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.userObject.bomLink.sourcePart.className!)
    }
    ObjectClientSrv.openObj(param)
  }

  /**
   * 修改对象
   */
  async update(row: any) {
    const param = {
      id: row?.userObject.bomLink.sourcePart.id,
      modelCode: row!.userObject.bomLink.sourcePart.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.userObject.bomLink.sourcePart.className)
    }
    ObjectClientSrv.openObj(param)
  }

  flowLoadData(): Record<string, any> {
    return this.flowData
  }

  buildTree(items: Record<string, any>[]): Record<string, any> {
    // 创建一个映射，用于快速查找元素
    const itemMap: Record<string, any> = {}
    items.forEach(item => {
      itemMap[item.id] = {
        id: item.id,
        name: item.name,
        number: item.userObject.bomLink.sourcePart.master.number,
        pid: item.pid,
        children: []
      }
    })

    // 遍历所有元素，根据pid将元素添加到其父元素的child数组中
    items.forEach(item => {
      if (item.pid === null) {
        // pid为null的元素是根元素
        return
      }
      if (itemMap[item.pid]) {
        // 确保父元素存在
        itemMap[item.pid].children.push(itemMap[item.id])
      }
    })

    // 找出根元素（pid为null的元素）
    return Object.values(itemMap).find(item => item.pid === null)
  }

  getView(row: any) {
    const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
    const partView = cacheInstance.getAll()
    if (row.partView) {
      const view = partView.filter(item => item.id == row.partView.id)[0].description
      return '(' + view + ')'
    }
    return ''
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeRequestAffectedViewsGridView from './KChangeRequestAffectedViewsGrid.vue'
import KChangeRequestAffectedViewsGridViewModel from './KChangeRequestAffectedViewsGridViewModel'

const KChangeRequestAffectedViewsGrid = connect(KChangeRequestAffectedViewsGridView, KChangeRequestAffectedViewsGridViewModel)

export default withInstall(KChangeRequestAffectedViewsGrid)
export { KChangeRequestAffectedViewsGrid, KChangeRequestAffectedViewsGridView, KChangeRequestAffectedViewsGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KPartReportBuyPropOptions = {
  ...BaseViewPropOptions,
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KPartReportBuyPropType = ViewPropsTypeExtract<typeof KPartReportBuyPropOptions>

/** 事件 */
export const KPartReportBuyEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartReportBuyEmitsType = ViewEmitsTypeExtract<typeof KPartReportBuyEventEmits>

import { AppContext, IocContainer } from '@kmsoft/upf-core'
import { PurviewServiceConstructor } from './base'
import { PLM_IOC_KEYS } from '../../beans'

export * from './base'
export * from './PurviewSrv'

/**
 * 注册 权限服务
 * @param iocContainer
 * @param serviceConstructor
 */
export const usePurviewService = (iocContainer: IocContainer, serviceConstructor: PurviewServiceConstructor) => {
  // 注册缓存管理器实例
  iocContainer.registerSingleton(PLM_IOC_KEYS.PURVIEW_SERVICE, serviceConstructor)
}

/**
 * 获取权限服务
 * @returns
 */
export const getPurviewService = () => {
  const purviewService = AppContext.current.getIocContainer().getBean<PurviewServiceConstructor>(PLM_IOC_KEYS.PURVIEW_SERVICE)
  if (!purviewService) {
    throw new Error('权限服务不存在')
  }
  return purviewService
}

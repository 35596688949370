import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KCustomerNeedsCreatePropOptions = {
  ...BaseViewPropOptions,
  formValue: VuePropTypes.createType<any>().def()
}

/** 参数类型 **/
export type KCustomerNeedsCreatePropType = ViewPropsTypeExtract<typeof KCustomerNeedsCreatePropOptions>

/** 事件 */
export const KCustomerNeedsCreateEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KCustomerNeedsCreateEmitsType = ViewEmitsTypeExtract<typeof KCustomerNeedsCreateEventEmits>

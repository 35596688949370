import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineCreateFormView from './KBaselineCreateForm.vue'
import KBaselineCreateFormViewModel from './KBaselineCreateFormViewModel'

const KBaselineCreateForm = connect(KBaselineCreateFormView, KBaselineCreateFormViewModel)

export default withInstall(KBaselineCreateForm)
export { KBaselineCreateForm, KBaselineCreateFormView, KBaselineCreateFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

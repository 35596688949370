import {
  DialogFunc,
  EnumDialogResult,
  EnumDialogSize,
  IPageResult,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  utils,
  VNodeProps
} from '@kmsoft/upf-core'
import { resolveComponent } from 'vue'
import {
  FindDialogProps,
  KObjectClassGridFilterPropType,
  KObjectClassGridFilterViewModel,
  KObjectDynamicCreateDialogParams,
  KObjectDynamicCreatePanel,
  KObjectPanelPropType,
  KObjectSelector,
  KObjectSelectorEventEmitsType,
  KObjectSelectorPropType,
  ObjectClassGridFilterResult,
  ObjectSelectDialogParam
} from '../../controls'

import KObjectClassGridFilter from '../../controls/object-class-grid-filter'
import KObjectClassGridFind from '../../controls/object-class-grid-find'
import { Api, CommonClientSrv, EnumQueryConditionOperator, EnumRequestCode } from '../base'
import { ClassMetaClientSrv } from '../meta'
import { CreateObjectResult, EnumObjCopyMode, ObjBusinessBase, ObjBusinessParam, SaveObjBusinessParams } from './types'
import { clsCodeMap, ObjectCreateDynamicPanelParams, ObjectGroupMapping } from './types/types'

/** 对象相关操作客户端服务类 */
export class ObjectClientSrv {
  //#region 业务对象 API服务
  /**
   * 获取业务对象
   * @param objParam
   */
  static async getObjBusiness(objParam: ObjBusinessParam): Promise<ObjBusinessBase | undefined> {
    const param = { data: [objParam.id] }
    //获取当前对象类的设计态编码
    const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(objParam.modelCode)
    const result = await Api.post(objParam.modelGroup!, designerCode!, 'get', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data as ObjBusinessBase
    }
    return undefined
  }

  /**
   * 保存业务对象
   * @param params
   * @returns
   */
  static async saveBusinessObj(params: SaveObjBusinessParams) {
    /** 保存结果 */
    const newParam = { data: [params] }
    const result = await Api.post(params.group!, params.modelCode!, 'save', newParam)

    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data as ObjBusinessBase
    }

    return undefined
  }

  /**
   * 删除对象
   * @param param
   * @returns
   */
  static async deleteBusiness(
    param: Array<ObjBusinessParam>,
    group: string,
    modelCode: string
  ): Promise<Array<ObjBusinessParam>> {
    const newParam = { data: param }
    const result = await Api.post(group, modelCode, 'delete', newParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data as Array<ObjBusinessParam>
    }
    return []
  }

  /**
   * 插入对象
   * @param params
   */
  static async createBusiness(params: SaveObjBusinessParams) {
    const newParam = { data: [params] }
    const result = await Api.post(params.group!, params.modelCode!, 'create', newParam)

    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data as ObjBusinessBase
    }

    return undefined
  }

  /**
   * 文档插入对象（不提取属性）
   * @param params
   */
  static async insertDocBusiness(params: SaveObjBusinessParams, fileProperty?: any, isProperty: boolean = false) {
    let result
    const newParam = { data: [params] }
    if (isProperty && fileProperty) {
      result = await Api.post(params.group!, params.modelCode!, 'insertDocBusiness', newParam) //DocApi.insertDocStruct({ object: fileProperty as any })
    } else {
      result = await Api.post(params.group!, params.modelCode!, 'insertDocBusiness', newParam) //DocApi.insertDataObject({ object: params as any })
    }

    if (result && result.isSuccess) {
      return (result.data as unknown) as ObjBusinessBase
    }

    return undefined
  }

  /**
   * 对象查询接口
   * @param query
   * @returns
   */
  static async listObjects(query: any): Promise<IPageResult> {
    let queryFilter = utils.deepClone(query)
    if (!query.filter) {
      queryFilter = {
        filter: {
          conditions: [
            {
              conditionName: 'rdmExtensionType',
              operator: EnumQueryConditionOperator.EQUAL,
              conditionValues: [queryFilter.filterModelCode]
            }
          ],
          joiner: 'and'
        }
      }
    }

    const request: any = {
      data: [
        {
          characterSet: 'UTF8',
          filter: queryFilter.filter,
          sorts: query.sorts,
          sort: 'DESC',
          orderBy: 'lastUpdateTime',
          isNeedTotal: true
        },
        {
          maxPageSize: 1000,
          curPage: query.page.pageIndex,
          pageSize: query.page.pageSize,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }

    const result = await Api.post(query.modelGroup, query.modelCode, 'page', request)

    if (result && result.code == EnumRequestCode.SUCCESS) {
      const data = result.data
      return {
        rows: data.data,
        total: data.page.totalRows
      }
    }

    return {
      total: 0,
      rows: []
    }
  }

  //#region 带界面函数
  /**
   * 打开对象
   * @param objParam 对象参数
   */
  static async openObj(objParam: ObjBusinessParam, tabKey?: string) {
    // 如果参数不正确
    if (!objParam || !objParam.id || !objParam.modelCode) {
      return
    }

    /** 标签页标识 */
    const newTabKey = tabKey ? tabKey : `${objParam.id}#${objParam.modelCode}`

    /** 对象结果 */
    //TODO:待实现
    const objResult = (await this.getObjBusiness(objParam)) as Record<string, any>

    if (!objResult) {
      KNotification.error({ title: '对象打开失败', content: '当前对象不存在' })
      return
    }
    const partView = objResult.partView ? `(${objResult.partView?.description})` : ''
    /** 标题:通用打开对象标签页标题为 编码+名称 */
    const title = clsCodeMap.get(objResult.rdmExtensionType) + partView + ':' + this.getObjBusinessDesc(objResult)
    /** 面板参数 */
    let panelProps: VNodeProps<KObjectPanelPropType> = { objParam: { ...objParam, branchId: objResult?.branch?.id } }
    /**
     * 元器件特殊处理,若当前为元器件时,显示电子设计文档标签页
     */
    const classCode = objParam.modelCode
    if (classCode === 'Electroniccomponents') {
      const tabConfigBefore = (tabs: any) => {
        const res = tabs.tabConfigs.find((x: any) => x.tabId === 'lbj-dzsjwd')
        if (res) res.visible = true
      }
      panelProps = { ...panelProps, tabConfigSetBefore: tabConfigBefore }
    } else if (objResult && objResult.documentType == 'STRUCTURE') {
      const tabConfigBefore = (tabs: any) => {
        const res = tabs.tabConfigs.find((x: any) => x.tabId === 'structured-doc-manage')
        if (res) res.visible = true
      }
      panelProps = { ...panelProps, tabConfigSetBefore: tabConfigBefore }
    } else if (objResult && (objResult.documentType == 'SymbolLib' || objResult.documentType == 'WrapperLib')) {
      const tabConfigBefore = (tabs: any) => {
        const res = tabs.tabConfigs.find((x: any) => x.tabId === 'lbj-dzsjwd')
        if (res) res.visible = true
      }
      panelProps = { ...panelProps, tabConfigSetBefore: tabConfigBefore }
    }

    // 打开页面
    CommonClientSrv.openPage(title, () => resolveComponent('KObjectPanel'), panelProps, newTabKey)
  }

  /**
   * 打开动态创建对象弹窗
   * @param params 参数
   */
  static openObjectDynamicCreateDialog(params: KObjectDynamicCreateDialogParams) {
    return new Promise((resolve, reject) => {
      KDialog.show({
        title: '创建文档对象',
        content: KObjectDynamicCreatePanel,
        props: {
          clsOptions: params.clsOptions,
          folderId: params.folderId,
          showObjClsCodes: params.showObjClsCodes,
          defaultSelectClsCode: params.defaultSelectClsCode,
          showObjClsSelector: params.showObjClsSelector
        },
        size: { width: 1000, height: 760 },
        onClosing: async (event: KDialogClosingEvent) => {
          const formViewModel = event.viewInstance as any
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          const validateResult = await formViewModel.validate()
          if (!validateResult) {
            event.cancel = true
            return
          }
          const result = await formViewModel.save()
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success('对象创建成功')
            // 创建成功后，将成功的数据返回出去
            resolve(result)
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message || '创建对象失败',
              details: result.detail
            })
            event.cancel = true
            return
          }
        }
      })
    })
  }

  /**
   * 打开创建对象对话框
   * @param params 参数
   */
  static openCreateDialog(params: ObjectCreateDynamicPanelParams) {
    const _this = this
    // 创建 Promise 对象，以支持同步编码
    return new Promise<CreateObjectResult>((resolve, reject) => {
      // 显示弹窗
      KDialog.show({
        title: '创建对象',
        content: KObjectDynamicCreatePanel,
        props: params,
        size: { width: 1200, height: document.documentElement.clientHeight - 100 },
        onClosing: async (event: KDialogClosingEvent) => {
          // 如果点击取消或者关闭
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
            resolve({ success: false, dialogResult: event.dialogResult })
            return
          }
          // 如果传入了保存前事件
          if (params.onClosingBefore) {
            // 执行事件
            params.onClosingBefore({ event: event })
            // 如果取消关闭
            if (event.cancel == true) {
              return
            }
          }
          const formViewModel = event.viewInstance as any
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          const validateResult = await formViewModel.validate()
          if (!validateResult) {
            event.cancel = true
            return
          }
          const result = await formViewModel.save()
          // 点击应用
          if (event.dialogResult == EnumDialogResult.Apply) {
            event.cancel = true
            params.onApply?.(result)
          }
          // 点击确认
          if (event.dialogResult == EnumDialogResult.Confirm) {
            params.onConfirm?.(result)
          }
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success('对象创建成功')
            resolve({ success: true, dialogResult: event.dialogResult!, objBusiness: result.data })
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message || '创建对象失败',
              details: result.detail
            })
            event.cancel = true
            return resolve({ success: false, dialogResult: event.dialogResult!, objBusiness: {} as ObjBusinessParam })
          }
        },
        onClosed: params.onClosed
      })
    })
  }

  /**
   * 根据创建模式获取标题
   * @param objCopyMode 对象创建模式
   * @returns 创建面板标题
   */
  static getTitleFromEnum(objCopyMode: EnumObjCopyMode): string {
    let title = ''
    switch (objCopyMode) {
      case EnumObjCopyMode.NewObj:
        title = '创建对象'
        break
      case EnumObjCopyMode.NewMajorVersion:
        title = '创建大版本'
        break
      case EnumObjCopyMode.NewVersion:
        title = '创建小版本'
        break
      case EnumObjCopyMode.SaveAs:
        title = '另存为'
        break
      default:
        title = '创建对象'
        break
    }
    return title
  }

  /**
   * 通过对象类获取对应的创建面板
   * @param modelCode
   * @returns
   */
  static async getObjectCreateForm(group: string) {
    switch (group) {
      case 'Doc':
        return 'KDocObjectCreateForm'
      case 'WFTaskContentTemplate':
        return 'KTaskObjectCreatePanel'
      default:
        return 'KCommonObjectCreateForm'
    }
  }

  /**
   * 打开对象选中对话框
   * @param params 参数
   */
  static openObjectSelectDialog(params: ObjectSelectDialogParam): void {
    const objectSelectorProps: VNodeProps<KObjectSelectorPropType, KObjectSelectorEventEmitsType> = {
      isMultipleSelection: params.isMultipleSelection,
      supportedQueryMode: params.supportedQueryMode,
      objectQueryMode: params.objectQueryMode,
      objectClassManageQueryParam: params.objectClassManageQueryParam,
      objectSearchQueryParam: params.objectSearchQueryParam
    }
    /** 显示弹框 */
    const dialog = KDialog.show({
      title: params.title || '选择对象',
      size: { width: 1300, height: 800 },
      showApply: params.showApply ?? true,
      showCancel: true,
      resizable: true,
      movable: true,
      maximizeBox: params.maximizeBox ?? true,
      minimizeBox: params.minimizeBox ?? true,
      closable: params.closable,
      icon: 'profile',
      rootClassName: params.rootClassName,
      state: params.state,
      content: KObjectSelector,
      getContainer: params.getContainer,
      props: objectSelectorProps,
      onClosing: params.onClosing,
      onClosed: params.onClosed
    })
  }

  /**
   * 打开过滤窗口
   * @param config
   * @returns
   */
  static async openFilterDialog(config: {
    title?: string
    props: KObjectClassGridFilterPropType
  }): Promise<ObjectClassGridFilterResult | undefined> {
    return await new Promise<ObjectClassGridFilterResult | undefined>((resolve, reject) =>
      KDialog.show({
        title: config.title || '过滤',
        size: EnumDialogSize.Large,
        showFooter: true,
        floatFooter: true,
        movable: true,
        content: KObjectClassGridFilter,
        props: config.props,
        onClosing(args: KDialogClosingEvent<KObjectClassGridFilterViewModel>) {
          if (args.dialogResult == EnumDialogResult.Confirm) {
            /** 获取过滤 */
            const result = args.viewInstance?.getFilterResult()

            if (result == undefined) {
              args.cancel = true
              return
            }

            resolve(result)
          } else {
            resolve(undefined)
          }
        }
      })
    )
  }

  /**
   * 打开对象类查找对话框
   * @param config 打开配置
   */
  static openObjectClassGridFindDialog(config: FindDialogProps): ReturnType<DialogFunc> {
    const { onFindNext, onFindPrev, fields } = config
    return KDialog.show({
      title: config?.title || '查找',
      size: { width: 420, height: -1 },
      showFooter: false,
      mask: false,
      movable: true,
      getContainer: config.getContainer,
      content: KObjectClassGridFind,
      props: { onFindNext, onFindPrev, fields },
      onClosed: config.onClosed
    })
  }

  /**通过编码获取模型分组 */
  static async getModelGroupByCode(code: string) {
    //获取设计态编码
    const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(code)
    for (const [system, subsystems] of ObjectGroupMapping) {
      if (subsystems.includes(designerCode!)) {
        return system
      }
    }
    return undefined
  }

  /**
   * 获取业务对象描述信息
   * @param obj 业务对象
   */
  static getObjBusinessDesc(obj: Record<string, any>): string {
    if (!obj) return 'Unknown'
    let result = obj.number + ':' + obj.name || ''
    if (obj.partViewEnum || obj.workingState) {
      const workingState =
        obj.workingState === 'INWORK'
          ? '工作中'
          : obj.workingState === 'CHECKED_IN'
          ? '已检入'
          : obj.workingState === 'CHECKED_OUT'
          ? '已检出'
          : '未知'
      const partViewEnum = obj.partViewEnum == 'DESIGN' ? '设计' : '制造'
      if (!obj.partViewEnum) {
        result += ':' + '[' + workingState + ']'
      } else if (!obj.workingState) {
        result += ':' + '[' + partViewEnum + ']'
      } else {
        result += ':' + '[' + workingState + '_' + (partViewEnum || '') + ']'
      }
    }
    if (obj.version) {
      result += ':' + obj.version
    }
    return result
  }
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDesignerControlPanelEventEmitsType, KDesignerControlPanelPropType } from './interface'
import { computed, ref, watch } from 'vue'
import _ from 'lodash'
import { KDesignerControl, KDesignerToolBox } from '../../../../types'

/** 导航管理 */
export default class KDesignerControlPanelViewModel extends BaseViewModel<
  KDesignerControlPanelEventEmitsType,
  KDesignerControlPanelPropType
> {
  activeKeys = ref([] as Array<string>)
  /** 工具栏条目分组 */
  toolboxControlGroups = computed(() => {
    return this.groupByType(this.props.designerMetaControl)
  })

  constructor(options: ViewModelOptions<KDesignerControlPanelPropType>) {
    super(options)
  }

  /**
   * 工具箱元数据转换
   * @param data 工具箱集合
   */
  groupByType(data: Array<KDesignerControl>): Array<KDesignerToolBox> {
    const groupedData: Record<string, KDesignerToolBox> = {}
    let list: Array<KDesignerControl> = data
    if (this.props.columnConfig) {
      list = data.filter(item => item.controlType == '容器列')
    }

    for (const item of list) {
      // 如果不可见
      if (item.visible == false) {
        continue
      }

      if (!item.controlType) {
        continue
      }

      // 如果分组没有创建
      if (!groupedData[item.controlType]) {
        groupedData[item.controlType] = { title: item.controlType, children: [] }
      }

      // 将组件加入分组
      groupedData[item.controlType].children.push(item)
    }

    const result: Array<KDesignerToolBox> = Object.values(groupedData)

    return result
  }
}

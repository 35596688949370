import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeOrderAffectedObjectGridView from './KChangeOrderAffectedObjectGrid.vue'
import KChangeOrderAffectedObjectGridViewModel from './KChangeOrderAffectedObjectGridViewModel'

const KChangeOrderAffectedObjectGrid = connect(KChangeOrderAffectedObjectGridView, KChangeOrderAffectedObjectGridViewModel)

export default withInstall(KChangeOrderAffectedObjectGrid)
export { KChangeOrderAffectedObjectGrid, KChangeOrderAffectedObjectGridView, KChangeOrderAffectedObjectGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

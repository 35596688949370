import { Component, ComputedOptions, MethodOptions } from 'vue'
import { KCreatorEditor } from '.'
import { MetaProperty } from '../../../../client-srv'
import { IFieldEditorResolver } from '../../base'
import { KDataGridCreatorTextColumn } from './KCreatorTextColumn'

export class KCreatorEditorResolver extends IFieldEditorResolver {
  static getColumn(field: MetaProperty) {
    if (field.columnName == 'creator') {
      const refColumnInstance = new KDataGridCreatorTextColumn()
      return refColumnInstance
    }
    return null
  }
  static getEditor(field: MetaProperty): string | (() => Component<any, any, any, ComputedOptions, MethodOptions>) | null {
    if (field.code == 'creator') {
      return () => KCreatorEditor
    }
    return null
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_quick_search_bar = _resolveComponent("k-quick-search-bar")!
  const _component_k_collapse_panel = _resolveComponent("k-collapse-panel")!
  const _component_k_collapse = _resolveComponent("k-collapse")!

  return (_openBlock(), _createBlock(_component_k_collapse, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
    ghost: "",
    class: "page"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_collapse_panel, {
        key: "1",
        header: "默认（多行表单模式）"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_quick_search_bar, {
            ref: _ctx.refSearch1,
            onSearch: _ctx.onSearch
          }, null, 8, ["onSearch"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_collapse_panel, {
        key: "2",
        header: "单行显示"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_quick_search_bar, {
            isSingleLine: true,
            ref: _ctx.refSearch2,
            onSearch: _ctx.onSearch
          }, null, 8, ["onSearch"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_collapse_panel, {
        key: "3",
        header: "单行显示&隐藏字段标签"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_quick_search_bar, {
            isSingleLine: true,
            isShowLabel: false,
            ref: _ctx.refSearch3,
            onSearch: _ctx.onSearch
          }, null, 8, ["onSearch"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_collapse_panel, {
        key: "4",
        header: "单行显示&显示搜索按钮"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_quick_search_bar, {
            isSingleLine: true,
            isShowSearchButton: true,
            ref: _ctx.refSearch4,
            onSearch: _ctx.onSearch
          }, null, 8, ["onSearch"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["activeKey"]))
}
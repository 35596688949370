import { connect, withInstall } from '@kmsoft/upf-core'
import KPartSelectorView from './KPartSelector.vue'
import KPartSelectorViewModel from './KPartSelectorViewModel'

const KPartSelector = connect(KPartSelectorView, KPartSelectorViewModel)

export default withInstall(KPartSelector)
export { KPartSelector, KPartSelectorView, KPartSelectorViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView } from '@kmsoft/upf-core'
import { KPartCreateViewVersionFormPropOptions, KPartCreateViewVersionFormEventEmits } from './interface'
import KPartCreateViewVersionFormViewModel from './KPartCreateViewVersionFormViewModel'

export default defineView({
  name: 'KPartCreateViewVersionForm',
  props: KPartCreateViewVersionFormPropOptions,
  emits: KPartCreateViewVersionFormEventEmits,
  viewModel: KPartCreateViewVersionFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KProductNeedsSectionEditPanelView from './KProductNeedsSectionEditPanel.vue'
import KProductNeedsSectionEditPanelViewModel from './KProductNeedsSectionEditPanelViewModel'

const KProductNeedsSectionEditPanel = connect(KProductNeedsSectionEditPanelView, KProductNeedsSectionEditPanelViewModel)

export default withInstall(KProductNeedsSectionEditPanel)
export { KProductNeedsSectionEditPanel, KProductNeedsSectionEditPanelView, KProductNeedsSectionEditPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeKanbanView from './KChangeKanban.vue'
import KChangeKanbanViewModel from './KChangeKanbanViewModel'

const KChangeKanban = connect(KChangeKanbanView, KChangeKanbanViewModel)

export default withInstall(KChangeKanban)
export { KChangeKanban, KChangeKanbanView, KChangeKanbanViewModel }

// 模板生成文件
// export * from './{{folderName}}'

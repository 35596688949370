import { BaseViewModel, KSchema, ValueChangeEventArgs, ViewModelOptions } from '@kmsoft/upf-core'
import { KFilterGridSearchBarDesignerEventEmitsType, KFilterGridSearchBarDesignerPropType } from './interface'
import { ref } from 'vue'

/** KFilterGridSearchBarDesigner 视图模型 */
export class KFilterGridSearchBarDesignerViewModel extends BaseViewModel<
  KFilterGridSearchBarDesignerEventEmitsType,
  KFilterGridSearchBarDesignerPropType
> {
  stateValue = ref<Array<KSchema>>([])
  originalValue = ref<Array<KSchema>>([])

  constructor(options: ViewModelOptions<KFilterGridSearchBarDesignerPropType>) {
    super(options)
  }
  /** 加载完成函数 */
  viewDidMount() {}

  /**
   * 设置模型托管的值
   * 根据 setChanged 判断是否更新originalValue的值
   * @param value 值
   */
  public setValue(value: any, setChanged?: boolean) {
    const oldValue = this.getValue(),
      eventArgs = new ValueChangeEventArgs(oldValue, value)
    if (setChanged) {
      this.originalValue.value = value
    }
    this.emit('beforeChange', eventArgs)
    this.stateValue.value = value
    this.emit('change', eventArgs)
  }

  /**
   * 获取模型托管的值
   */
  public getValue() {
    return this.stateValue.value
  }
}


// 实例
/**
 * @v-model:formValue =========》表单双向绑定的值
 * @ref =======================》ref
 * @groupList =================》带下拉的表单
 * @formList ==================》不带下拉的表单
 * @labelCol ==================》label占据的宽度
 * @getValue ==================》表单调用getValue方式时提供的修改值方法，可以不传，默认值为当前表单值
 * 。。。
  <k-config-form
    v-model:formValue="$vm.configFormValue"
    :ref="$vm.refConfigForm"
    :groupList="$vm.groupList"
    :labelCol="6"
    :getValue="$vm.configFormGetValue"
  ></k-config-form>
 */
import { defineView } from '@kmsoft/upf-core'
import { KConfigFormPropOptions, KConfigFormEventEmits } from './interface'
import KConfigFormViewModel from './KConfigFormViewModel'
import { omit } from 'lodash'
import ConfigFormItem from './components/form-item'

const __default__ = defineView({
  name: 'KConfigForm',
  props: KConfigFormPropOptions,
  emits: KConfigFormEventEmits,
  viewModel: KConfigFormViewModel,
  components: { ConfigFormItem },
  setup(props, { emit, slots, attrs, vm }) {
    const formItemWidth = 'calc(' + 100 / props?.colSpan + '% - 5px)'
    return {
      formItemWidth,
      omit
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1910cdab": (_ctx.formItemWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__

import { KTreeSelectNode, defineView } from '@kmsoft/upf-core'
import { KObjectWorkSpaceSearchPropOptions, KObjectWorkSpaceSearchEventEmits } from './interface'
import KObjectWorkSpaceSearchViewModel from './KObjectWorkSpaceSearchViewModel'
import { EnumObjectSearcherMode, EnumObjectSearcherTab, EnumSearchRegion } from '../object-searcher/interface'
import { computed } from 'vue'
import { KCommonSearcher, KQuickSearcher } from '../object-searcher/tabs'
import { KObjectViewSelector } from '../object-view-selector'

export default defineView({
  name: 'KObjectWorkSpaceSearch',
  props: KObjectWorkSpaceSearchPropOptions,
  emits: KObjectWorkSpaceSearchEventEmits,
  components: { KCommonSearcher, KQuickSearcher, KObjectViewSelector },
  viewModel: KObjectWorkSpaceSearchViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /** 对象类树属性 */
    const objectClassTreeProps = computed(() => {
      return {
        defaultSelectClsCode: props.modelCode,
        showObjClsCodes: props.showObjClsCodes,
        hideObjClsCodes: props.hideObjClsCodes
      }
    })
    return { EnumObjectSearcherMode, EnumSearchRegion, EnumObjectSearcherTab, objectClassTreeProps }
  }
})

import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { EnumAttributeType, EnumDataType, KControlAttribute } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'
import { getCandidates } from '../utils'

/* 组件的基本属性 */
const comConfig = [
  {
    name: 'options',
    title: '候选项配置',
    control: 'custom',
    defaultValue: [],
    dataType: EnumDataType.ARRAY,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'hideText',
    title: '隐藏文字',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  }
]

/* 基础属性配置项集合 */
const BASE = [
  PROPERTY_CONFIG_ITEM_COLLECTION.TITLE,
  PROPERTY_CONFIG_ITEM_COLLECTION.DISABLED,
  PROPERTY_CONFIG_ITEM_COLLECTION.READONLY
] as Array<KControlAttribute>

/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
] as Array<KControlAttribute>

/**控件属性集合 */
const PROPS = comConfig

const EVENT = [{ name: 'change', title: '值改变事件', describe: '值改变事件 的说明信息...' }]

export default { BASE, LAYOUT, PROPS, EVENT }

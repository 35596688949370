import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartCreateViewVersionFormPropOptions = {
  ...BaseViewPropOptions,
  // 视图列表
  viewProps: VuePropTypes.createType<any>().def()
}

/** 参数类型 **/
export type KPartCreateViewVersionFormPropType = ViewPropsTypeExtract<typeof KPartCreateViewVersionFormPropOptions>

/** 事件 */
export const KPartCreateViewVersionFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartCreateViewVersionFormEmitsType = ViewEmitsTypeExtract<typeof KPartCreateViewVersionFormEventEmits>

// 页面定义
export interface IViewDefinition {
  // id
  id: string
  // 类型
  clazz: string
  // 描述
  description: string
  // 名称
  name: string
}

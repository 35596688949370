
import { defineView } from '@kmsoft/upf-core'
import { KChangeRequestEditPropOptions, KChangeRequestEditEventEmits } from './interface'
import KChangeRequestEditViewModel from './KChangeRequestEditViewModel'

export default defineView({
  name: 'KChangeRequestEdit',
  props: KChangeRequestEditPropOptions,
  emits: KChangeRequestEditEventEmits,
  viewModel: KChangeRequestEditViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

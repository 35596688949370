import { ref } from 'vue'
import { BaseViewModel, IKForm, SimpleViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KCustomPropFormEventEmitsType, KCustomPropFormPropType } from './interface'

export class KCustomPropsFormViewModel extends BaseViewModel<KCustomPropFormEventEmitsType, KCustomPropFormPropType> {
  /** form state */
  formState = ref<IKForm<any>>()
  /** refName */
  refName = ref()
  // 不同编辑器对应的自定义表单对象, 简单的处理成基类SimpleViewModel
  vmCustomPropsFormForEditorType = ref<SimpleViewModel>()

  constructor(options: ViewModelOptions<KCustomPropFormPropType>) {
    super(options)
  }

  vueDidMount() {}

  // 设置表单的值
  setValue(formState: any) {
    if (this.refName.value) {
      this.refName.value!.setValue({ ...formState })
    }
  }

  // 获取表单的值
  getValue() {
    return this.refName.value!.getValue()
  }
}

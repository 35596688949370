import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumClsDataType, EnumClsEditorType } from '../../../enums'

/** 参数 **/
export const KFileUploadPropOptions = {
  ...BaseViewPropOptions,
  /** 当前编辑类型的自定义参数 */
  editArgs: VuePropTypes.string().def(),
  /** 当前属性id */
  propertyId: VuePropTypes.string().def(),
  /** 当前编辑对象类的数据类型 */
  dataType: VuePropTypes.createType<EnumClsDataType>().def(),
  /** 当前编辑对象类的数据的编辑类型 */
  editType: VuePropTypes.createType<EnumClsEditorType>().def(),
  /** 是否只读 */
  readonly: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KFileUploadPropType = ViewPropsTypeExtract<typeof KFileUploadPropOptions>

/** 事件 */
export const KFileUploadEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KFileUploadEmitsType = ViewEmitsTypeExtract<typeof KFileUploadEventEmits>

/**文件上传参数类型 */
export interface IKFileFormDefinition {
  modelName: string
  attributeName: string
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDocObjectPropertyEditPanelEmitsType, KDocObjectPropertyEditPanelPropType } from './interface'
import { ref } from 'vue'
import { KObjectPropertyEditPanelBaseViewModel } from '@kmsoft/ebf-common'

/** KDocObjectPropertyEditPanel */
export default class KDocObjectPropertyEditPanelViewModel extends BaseViewModel<
  KDocObjectPropertyEditPanelEmitsType,
  KDocObjectPropertyEditPanelPropType
> {
  /** 属性面板引用 */
  refObjectPropertyEditPanelBase = ref<KObjectPropertyEditPanelBaseViewModel>()

  constructor(options: ViewModelOptions<KDocObjectPropertyEditPanelPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 保存
   * @param onlySave 是否仅保存
   * @returns
   */
  async save(onlySave: boolean = false) {
    // return this.refObjectPropertyEditPanelBase.value?.save(onlySave)
  }

  /**
   * 刷新
   */
  async refresh() {
    return this.refObjectPropertyEditPanelBase.value?.refresh()
  }
}

import { AppContext } from '@kmsoft/upf-core'
import { isObject } from 'lodash'

/** 本地存储 */
class LocalStorageCache {
  /**
   * 获取缓存
   * @param key
   */
  getCache<T>(key: string, defaultValue?: T): T | undefined {
    const id = AppContext.current.getIdentity()?.id
    const userId = id
    const cacheData = this.getGlobalCache<Array<LocalCacheConfig<T>>>(key)
    if (!cacheData) {
      return defaultValue
    }
    const userCache = cacheData.find(a => a.userId == userId)
    if (!userCache) {
      return defaultValue
    }
    return userCache.value || defaultValue
  }

  /**
   * 设置缓存（根据用户区分）
   * @param key 缓存键
   * @param value 缓存值
   * @returns
   */
  setCache(key: string, value: any) {
    const id = AppContext.current.getIdentity()?.id
    const userId = id!
    const cacheData = this.getGlobalCache(key)
    let newCache: Array<LocalCacheConfig<any>> = []

    if (cacheData) {
      const cacheDataArray = cacheData as Array<LocalCacheConfig<any>>
      const userCache = cacheDataArray.find(a => a.userId == userId)
      if (userCache) {
        userCache.value = value
      } else {
        cacheDataArray.push({
          userId: userId,
          value: value
        })
      }
      newCache = cacheDataArray
    } else {
      newCache = [
        {
          userId: userId,
          value: value
        }
      ]
    }
    return localStorage.setItem(key, this.convertToJSON(newCache))
  }

  /**
   * 获取全局缓存
   * @param key 缓存键
   * @returns
   */
  getGlobalCache<T>(key: string): T | undefined {
    const storageValue = localStorage.getItem(key)
    if (!storageValue) {
      return undefined
    }
    try {
      return JSON.parse(storageValue)
    } catch (error) {
      return storageValue as any
    }
  }

  /**
   * 设置全局缓存，不区分用户
   * @param key
   * @param value
   */
  setGlobalCache(key: string, value: any) {
    return localStorage.setItem(key, this.convertToJSON(value))
  }

  /**
   * 转换为Json字符串
   * @param value
   * @returns
   */
  convertToJSON(value: any) {
    let storageValue: string = ''
    if (isObject(value)) {
      storageValue = JSON.stringify(value)
    } else {
      storageValue = value
    }
    return storageValue
  }
}

type LocalCacheConfig<T> = {
  userId: string
  value: T
}

export const localStorageCache = new LocalStorageCache()


import { defineView } from '@kmsoft/upf-core'
import { KChangeOrderEditPropOptions, KChangeOrderEditEventEmits } from './interface'
import KChangeOrderEditViewModel from './KChangeOrderEditViewModel'

export default defineView({
  name: 'KChangeOrderEdit',
  props: KChangeOrderEditPropOptions,
  emits: KChangeOrderEditEventEmits,
  viewModel: KChangeOrderEditViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

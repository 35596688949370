import { UPF_IOC_KEYS, IAppConfigurer, IocContainer, IMenuPagePathMapping } from '@kmsoft/upf-core'
import { PartMenuPathMapping } from './beans'
import { App } from 'vue'
import Control from './controls'
import { useExtension } from './extension'
import KPartManage from './pages/part-manage'
import KPartForm from './pages/part-manage/part-form'
import KPartImport from './pages/part-import'
import KPartStruct from './pages/part-manage/part-struct'
import KPartBomCompare from './pages/part-manage/part-bom-compare'
import KPartView from './pages/part-view'

export default {
  configVue: (app: App) => {
    app.use(Control)
    app.use(KPartForm)
    app.use(KPartManage)
    app.use(KPartImport)
    app.use(KPartStruct)
    app.use(KPartBomCompare)
    app.use(KPartView)
  },
  configBean: (iocContainer: IocContainer) => {
    iocContainer.registerSingleton<IMenuPagePathMapping>(UPF_IOC_KEYS.MENU_PAGE_PATH_MAPPING, new PartMenuPathMapping())
    // useExtension(iocContainer)
  }
} as IAppConfigurer
export { KPartForm, KPartManage, KPartImport, KPartBomCompare }

export * from './client-srv'

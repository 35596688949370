import { KViewSchemaWithId, VuePropTypes } from '@kmsoft/upf-core'
import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'
import { KDataElementTree, KDesignerControl } from '../../../../../types'

/** KFilterGridViewConfig 事件 */
export const KFilterGridViewConfigEventEmits = {
  ...BaseViewEventEmits
}

/** KFilterGridViewConfig 事件类型 **/
export type KFilterGridViewConfigEventEmitsType = ViewEmitsTypeExtract<typeof KFilterGridViewConfigEventEmits>

/** KFilterGridViewConfig 参数 **/
export const KFilterGridViewConfigPropOptions = {
  ...BaseViewPropOptions,
  views: VuePropTypes.array<KViewSchemaWithId>().def([]),
  /**字段 */
  metadataGroups: VuePropTypes.array<Record<string, any>>().def(),
  /**数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def({} as KDataElementTree),
  /**控件集合 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def([] as Array<KDesignerControl>)
}

/** KFilterGridViewConfig 参数类型 **/
export type KFilterGridViewConfigPropType = ViewPropsTypeExtract<typeof KFilterGridViewConfigPropOptions>

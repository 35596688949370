import { StructData } from './types'

/**
 * 通过路径获取节点Id
 * @param data
 * @param path 路径 {cid}_{index?},{cid}_{index?}
 * @returns
 */
export const getNodeIdByPath = (dataArray: Array<StructData>, pathArray: Array<string>) => {
  //队列
  const queue: Array<StructData> = []
  //路径索引
  let pathIndex = 0

  //初始化队列
  queue.push(...dataArray)

  while (queue.length) {
    //路径节点Id
    const idItem = pathArray[pathIndex]

    //删除第一个循环项
    const curNode = queue.shift()!

    // 位置是否匹配
    let matchIndex = true
    let cid = idItem

    // 如果路径包含下划线 则判断当前位置和路径位置是否匹配
    if (idItem && idItem.includes('_')) {
      const pathAttr = idItem.split('_')
      // matchIndex = curNode.index == pathAttr[1]
      cid = pathAttr[0]
    }

    if (curNode.cid == cid && matchIndex) {
      //如果符合条件
      //如果执行到最后一个队列
      if (pathIndex == pathArray.length - 1) {
        return curNode
      }

      //增加路径索引
      pathIndex++

      //如果有子项，加入循环队列
      if (curNode && curNode.children) {
        queue.unshift(...curNode.children)
      }
    }
  }
  return null
}

/** 根据节点Id获取路径 */
export const getPathByNodeId = (dataArray: Array<StructData>, nodeId: number, currentPath: Array<string> = []): Array<string> => {
  for (const node of dataArray) {
    const pathNodeId = node.cid == 'root' ? node.cid : `${node.cid}_${node.index}`
    if (node.xmlNodeId === nodeId) {
      return [...currentPath, pathNodeId]
    }

    if (node.children && node.children.length > 0) {
      const path = getPathByNodeId(node.children, nodeId, [...currentPath, pathNodeId])
      if (path.length > 0) {
        return path
      }
    }
  }

  return []
}

export const getChildNodeIds = (node: StructData) => {
  /** 节点Id */
  const nodeIds = [node.xmlNodeId]
  /** 子节点 */
  const childNodes = [...(node.children || [])]

  while (childNodes.length > 0) {
    /** 移除第一个节点 */
    const curNode = childNodes.shift()

    if (curNode) {
      nodeIds.push(curNode.xmlNodeId)

      if (curNode.children) {
        childNodes.push(...curNode.children)
      }
    }
  }

  return nodeIds
}

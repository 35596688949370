import { VuePropTypes, ViewPropsTypeExtract, BaseViewPropOptions } from '@kmsoft/upf-core'
import { FileAttributes } from '../../../io/FileAttributes'

export const KFileDialogPropOptions = {
  ...BaseViewPropOptions,
  resultType: VuePropTypes.createType<FileAttributes>().def(FileAttributes.Normal),
  allowEdit: VuePropTypes.bool().def(false),
  multiselect: VuePropTypes.bool().def(false),
  filter: VuePropTypes.string().def('所有文件 (*.*)|*.*')
}
export type KFileDialogPropType = ViewPropsTypeExtract<typeof KFileDialogPropOptions>

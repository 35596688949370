import {
  BaseViewModel,
  EnumDialogResult,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KDocExtractFilesEmitsType, KDocExtractFilesPropType } from './interface'
import { computed, nextTick, ref } from 'vue'
import { Progress, FileDataInter } from '../doc-select-files/interface'
import { AddFilePropertyReadOption } from '../../doc-object-create-panel/interface'
import { KDocFileProperty, KDocFilePropertyViewModel } from '../../doc-object-create-panel/controls/doc-file-property'
import { Agent, ConfigClientSrv, FileClientSrv, LoginClientSrv } from '@kmsoft/ebf-common'
import { DocClientSrv } from '../../../client-srv'
import * as AgentDdb from '../../../client-agent'

/** KDocExtractFiles */
export default class KDocExtractFilesViewModel extends BaseViewModel<KDocExtractFilesEmitsType, KDocExtractFilesPropType> {
  refDataGrid = ref<KDataGridViewModel>()
  /** 选中的行 **/
  selectedRows = ref<Array<FileDataInter>>([])
  /** 代理程序上传时的参数 **/
  filePropertyOption = ref<AddFilePropertyReadOption>()
  /** 是否禁用导入 **/
  disabledImport = computed(() => {
    // 没有选中行，选中行缺失名称编码，或通过代理程序上传的文件，没有处理完，不能导出跳到下一步(完成或失败都代表处理完)
    return (
      this.selectedRows.value.length <= 0 ||
      this.selectedRows.value.some(row => row.name == '' || row.number == '') ||
      this.props.fileList.some(row => !['complete', 'fail'].includes(row.extractProgress.id))
    )
  })

  disabledTryAgain = computed(() => {
    // 有处理中，或全部都上传成功的，禁用重试
    return (
      this.props.fileList.some(row => ['processing'].includes(row.extractProgress.id)) ||
      this.props.fileList.filter(row => row.extractProgress.id == 'complete').length == this.props.fileList.length
    )
  })

  constructor(options: ViewModelOptions<KDocExtractFilesPropType>) {
    super(options)
  }

  viewDidMount() {
    this.init()
  }

  async init() {
    if (this.props.agentOpened) {
      this.filePropertyOption.value = await this.selectDocFileProperty()
    }
    this.extractFiles()
  }
  selectionChanged(data: any) {
    this.selectedRows.value = data.selectedRows || ([] as FileDataInter[])

    const selectedTempIds = this.selectedRows.value.map(row => row.tempId)
    this.props.fileList.forEach(fileData => {
      if (selectedTempIds.includes(fileData.tempId)) {
        fileData.selected = true
      } else {
        fileData.selected = false
      }
    })
  }

  /** 提取文件 **/
  async extractFiles() {
    const files = this.props.fileList.filter(item => ['waiting', 'fail'].includes(item.extractProgress.id))
    if (this.props.agentOpened) {
      this.proxyExtractFiles(files)
    } else {
      this.localUploadFiles(files)
    }
  }
  /** 本地上传文件 **/
  async localUploadFiles(files: FileDataInter[]) {
    // 获取本地上传文件
    const fileList = files.filter(item => !item.proxyUpload)
    // 上传loading
    files.forEach(item => (item.extractProgress = Progress.waiting))
    for (let i = 0; i < fileList.length; i++) {
      const fileData = fileList[i]
      fileData.extractProgress = Progress.processing
      await DocClientSrv.uploadPrimaryFile(fileData.file as File)
        .then(fileId => {
          if (!fileId) {
            fileData.extractProgress = Progress.fail
          } else {
            fileData.extractProgress = Progress.complete
            fileData.primary = [{ id: fileId }]
          }
        })
        .catch(_ => (fileData.extractProgress = Progress.fail))
    }
  }

  /** 代理程序抽取文件 **/
  async proxyExtractFiles(files: FileDataInter[]) {
    const options = this.filePropertyOption.value
    // 代理上传文件
    const fileList = files.filter(item => item.proxyUpload)
    // 上传loading
    files.forEach(item => (item.extractProgress = Progress.waiting))

    for (let i = 0; i < fileList.length; i++) {
      const fileData = fileList[i]
      if (options && options.isProperties == true) {
        await this.getFileProperty(fileData, options)
      } else {
        fileData.docList = []
        fileData.docStructList = []
      }
    }
  }

  /**选择文件引用属性 */
  async selectDocFileProperty(): Promise<AddFilePropertyReadOption | undefined> {
    return new Promise(resolve => {
      KDialog.show({
        title: '信息提取选项',
        movable: true,
        size: { width: 360, height: 200 },
        showApply: false,
        content: KDocFileProperty,
        onClosing: async (event: KDialogClosingEvent<KDocFilePropertyViewModel>) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return undefined
          const viewModel = event.viewModel as KDocFilePropertyViewModel

          const values = viewModel.getValue()
          this.emit('update:filePropertyOption', values)
          return resolve(values)
        }
      })
    })
  }

  /** 提取文件属性 **/
  async getFileProperty(fileData: FileDataInter, options: AddFilePropertyReadOption) {
    const argIntegrationConfig = await DocClientSrv.getIntegrationConfig(this.props.documentType as string)
    if (argIntegrationConfig && argIntegrationConfig.data) {
      fileData.extractProgress = Progress.processing
      const fileProperty = await AgentDdb.DdbClientSrv.GetFileProperty(
        [fileData.filePath as string],
        [],
        options.isCascade!,
        [],
        [],
        argIntegrationConfig.data,
        LoginClientSrv.getUserIdentity()?.token!,
        {
          downloadUrl: FileClientSrv.getApiUrl('upload'),
          checkMd5Url: '',
          copyUrl: '',
          uploadUrl: FileClientSrv.getApiUrl('upload')
        },
        {
          location: '',
          chunkSize: ''
        }
      )

      if (fileProperty && fileProperty.docList && fileProperty.docList.length > 0) {
        fileData.extractProgress = Progress.complete
        // //找到根节点
        const root = fileProperty.docList.find(a => a.isRoot == true)

        fileData.number = root?.code
        fileData.name = root?.name
        fileData.docList = fileProperty.docList
        fileData.docStructList = fileProperty.docStructList
      } else {
        fileData.extractProgress = Progress.fail
      }
    }
  }

  /** 移除 **/
  removeData() {
    const selectedRows = this.refDataGrid.value?.getSelectedRows() || []
    selectedRows.forEach(item => {
      this.emit('removeFile', item)
    })
  }

  /** 重试 **/
  tryAgain() {
    this.extractFiles()
  }
}

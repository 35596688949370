import {
  BaseViewPropOptions,
  ViewPropsTypeExtract,
  VuePropTypes,
  BaseViewEventEmits,
  ViewEmitsTypeExtract
} from '@kmsoft/upf-core'
import { IConfigValueDefinition, UpdateConfigValidStatusEventArgs } from '../sys-config-value/interface'
import { IValueDesc } from '../../interface'

// 页面表单状态
export interface IConfigValueTileViewModelState {
  formState: IConfigValueDefinition
  editable: boolean
  dataLoaded: boolean
  selectEditTypeOptions: Array<IValueDesc>
  selectEditEditTypeOptions: Array<IValueDesc>
  selectDisplayEditTypeOptions: Array<IValueDesc>
  radioEditTypeOptions: Array<IValueDesc>
  mode: string
}

// 页面事件
export const KConfigValueTileEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 更新配置项(模块)有效性状态
   * @param validStatus 有效性
   */
  updateConfigValidStatus: (e: UpdateConfigValidStatusEventArgs) => true
}

export const KConfigValueTileViewModelPropOptions = {
  ...BaseViewPropOptions,
  defId: VuePropTypes.string().def(''),
  treeId: VuePropTypes.string().def(''),
  oper: VuePropTypes.string().def(''),
  mode: VuePropTypes.string().def('')
}

export type KConfigValueTileViewModelPropType = ViewPropsTypeExtract<typeof KConfigValueTileViewModelPropOptions>

export type KConfigValueTileEventEmitsType = ViewEmitsTypeExtract<typeof KConfigValueTileEventEmits>

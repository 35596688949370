import './style/index.css'
import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectWorkspaceView from './KObjectWorkspace.vue'
import KObjectWorkspaceViewModel from './KObjectWorkspaceViewModel'

const KObjectWorkspace = connect(KObjectWorkspaceView, KObjectWorkspaceViewModel)

export default withInstall(KObjectWorkspace)
export { KObjectWorkspace, KObjectWorkspaceView, KObjectWorkspaceViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { EnumAttributeType, EnumDataType } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'
import { KControlAttribute } from '@kmsoft/upf-core'

export const SORT_CANDIDATE = [
  { displayValue: '左排列', actualValue: 'left' },
  { displayValue: '右排列', actualValue: 'right' }
]

export const comConfig = [
  {
    name: 'sorted',
    title: '排列方式',
    control: 'KSelect',
    options: SORT_CANDIDATE,
    defaultValue: 'right',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  }
]

/* 属性配置项集合 */
const BASE = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE] as Array<KControlAttribute>

/**控件属性 */
const PROPS = comConfig
/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
] as Array<KControlAttribute>

const EVENT = [{ name: 'itemClick', title: '点击', describe: '点击 的说明信息...' }]

export default { BASE, PROPS, LAYOUT, EVENT }

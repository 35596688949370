import { connect, withInstall } from '@kmsoft/upf-core'
import KDocCreatePanelView from './KDocCreatePanel.vue'
import KDocCreatePanelViewModel from './KDocCreatePanelViewModel'

const KDocCreatePanel = connect(KDocCreatePanelView, KDocCreatePanelViewModel)

export default withInstall(KDocCreatePanel)
export { KDocCreatePanel, KDocCreatePanelView, KDocCreatePanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, SelectOption, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KPartBomCompareModeFormEmitsType, KPartBomCompareModeFormPropType } from './interface'

/** KPartBomCompareModeForm */
export default class KPartBomCompareModeFormViewModel extends BaseViewModel<
  KPartBomCompareModeFormEmitsType,
  KPartBomCompareModeFormPropType
> {
  /**
   * 比较方式
   */
  comparisonMode = ref<string>('SINGLE')
  /**
   * 比较方式候选值
   */
  options = ref<Array<SelectOption>>([
    { label: '单层比较', value: 'SINGLE' },
    { label: '多层比较', value: 'MULTI' },
    { label: '汇总比较', value: 'AGGREGATE_LIST' }
  ])
  /**
   * 差异件是否展开
   */
  expandDifferentialParts = ref<boolean>(false)
  /**
   * 忽略分支节点
   */
  ignoringBranchNodes = ref<boolean>(false)
  /**
   * 是否在新标签页显示比较结果
   */
  openNewTabShowResult = ref<boolean>(false)
  constructor(options: ViewModelOptions<KPartBomCompareModeFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 获取表单值
   */
  getFormValue() {
    switch (this.comparisonMode.value) {
      case 'SINGLE':
        return {
          comparisonModeName:"单层比较",
          comparisonMode: this.comparisonMode.value,
          openNewTabShowResult: this.openNewTabShowResult.value
        }
      case 'MULTI':
        return {
          comparisonModeName:"多层比较",
          comparisonMode: this.comparisonMode.value,
          expandDifferentialParts: this.expandDifferentialParts.value,
          openNewTabShowResult: this.openNewTabShowResult.value
        }
      case 'AGGREGATE_LIST':
        return {
          comparisonModeName:"汇总比较",
          comparisonMode: this.comparisonMode.value,
          ignoringBranchNodes: this.ignoringBranchNodes.value,
          openNewTabShowResult: this.openNewTabShowResult.value
        }
      default:
        break
    }
  }
}

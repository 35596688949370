import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  RenderLayoutElement,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumRenderLayoutType } from '../render-layout'

/** 参数 **/
export const KViewRenderElementPropOptions = {
  ...BaseViewPropOptions,
  /** 元数据 */
  meta: VuePropTypes.createType<RenderLayoutElement>()
    .setRequired()
    .def(),
  /** 网格行数 */
  gridRows: VuePropTypes.number().def(2),
  /** 布局类型 */
  layoutType: VuePropTypes.createType<EnumRenderLayoutType>().def(EnumRenderLayoutType.GRID),
  /** 设计模式 */
  designMode: VuePropTypes.bool().def(false),
  /** 已选择 */
  selected: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KViewRenderElementPropType = ViewPropsTypeExtract<typeof KViewRenderElementPropOptions>

/** 事件 **/
export const KViewRenderElementEventEmits = {
  ...BaseViewEventEmits,
  /** 删除 */
  delete: (element: RenderLayoutElement) => true,
  /** 选择事件 */
  select: (selected: boolean) => true,
  top: () => true,
  bottom: () => true,
  up: () => true,
  down: () => true
}

/** 事件协议*/
export type KViewRenderElementEventEmitsType = ViewEmitsTypeExtract<typeof KViewRenderElementEventEmits>

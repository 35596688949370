import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineGridView from './KBaselineGrid.vue'
import KBaselineGridViewModel from './KBaselineGridViewModel'

const KBaselineGrid = connect(KBaselineGridView, KBaselineGridViewModel)

export default withInstall(KBaselineGrid)
export { KBaselineGrid, KBaselineGridView, KBaselineGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "config-grid-container" }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = { style: {"flex":"1"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_tool_strip, {
        ref: _ctx.$vm.refObjClsToolStrip,
        items: _ctx.toolStripItems,
        onItemClicked: _ctx.toolStripItemClick
      }, null, 8, ["items", "onItemClicked"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_k_object_class_grid, {
        ref: _ctx.$vm.refObjClsGrid,
        clsCode: _ctx.$vm.clsCode,
        disabled: _ctx.$vm.disabled,
        onRowDoubleClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vm.openEditPanel()))
      }, null, 8, ["clsCode", "disabled"])
    ])
  ]))
}
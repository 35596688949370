import { defineView, VNodeProps, exposeComponentEvents } from '@kmsoft/upf-core'
import { KObjectVersionGridPropOptions, KObjectVersionGridEventEmits } from './interface'
import KObjectVersionGridViewModel from './KObjectVersionGridViewModel'
import {
  EnumObjClassManageToolStripOptions,
  KObjectClassGridEventEmits,
  KObjectClassGridEventEmitsType,
  KObjectClassGridPropType
} from '../object-class-grid'

export default defineView({
  name: 'KObjectVersionGrid',
  props: KObjectVersionGridPropOptions,
  emits: KObjectVersionGridEventEmits,
  viewModel: KObjectVersionGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    // 暴露事件
    const exposedEvents = exposeComponentEvents(vm.refObjectClassGrid, Object.keys(KObjectClassGridEventEmits), emit)

    return () => {
      const objProps: VNodeProps<KObjectClassGridPropType, KObjectClassGridEventEmitsType> = {
        ref: vm.refObjectClassGrid,
        ...props,
        ...exposedEvents,
        toolStripOptions:
          EnumObjClassManageToolStripOptions.ShowObjLifeTime |
          EnumObjClassManageToolStripOptions.ShowOperation |
          EnumObjClassManageToolStripOptions.ShowGridOperation,
        modelCode: props.objParam.modelCode,
        isMultipleSelection: false,
        pagination: false,
        loadData: vm.onLoadData
      }
      return <k-object-class-grid {...objProps}></k-object-class-grid>
    }
  }
})

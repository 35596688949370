
import { defineView } from '@kmsoft/upf-core'
import { KChangePromoteActivityEditPropOptions, KChangePromoteActivityEditEventEmits } from './interface'
import KChangePromoteActivityEditViewModel from './KChangePromoteActivityEditViewModel'

export default defineView({
  name: 'KChangePromoteActivityEdit',
  props: KChangePromoteActivityEditPropOptions,
  emits: KChangePromoteActivityEditEventEmits,
  viewModel: KChangePromoteActivityEditViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import * as UpfCore from '@kmsoft/upf-core'

import { AgentManager } from './AgentManager'
import { AgentInstanceObject } from './AgentInstanceObject'
import { BindingFlags } from './system/reflection/BindingFlags'
import { AgentEventHandler } from './kmsoft/agent/AgentEventHandler'

/**代理端静态对象。*/
export abstract class AgentStaticObject {
  //#region 静态成员
  /**无对象。*/
  public static readonly None: any = Symbol()

  /**类型。*/
  protected static type: string = 'KMSoft.Agent.AgentStaticObject'

  /**将指定的字符串（它将二进制数据编码为 Base64 数字）转换为等效的 8 位无符号整数数组。
   * @param s 要转换的字符串。
   * @returns 与 {@link s} 等效的 8 位无符号整数数组。*/
  protected static async fromBase64String(s: string): Promise<Blob> {
    const str = atob(s)
    let len = str.length
    const arr = new Uint8Array(len)
    while (len--) arr[len] = str.charCodeAt(len)
    return new Blob([arr])
  }

  /**将 8 位无符号整数的数组转换为其用 Base64 数字编码的等效字符串表示形式。
   * @param inArray 8 位无符号整数数组。
   * @returns inArray 的内容的字符串表示形式，以 Base64 表示。*/
  protected static async toBase64String(inArray: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onabort = event => reject(event.target?.error ?? '操作已取消')
      reader.onerror = event => reject(event.target?.error ?? '操作失败')
      reader.onload = event => {
        const result = event.target?.result as string
        if (result) {
          const pos = result.indexOf(',')
          if (pos > -1) resolve(result.substring(pos + 1))
        }
        return resolve(result)
      }
      reader.readAsDataURL(inArray)
    })
  }

  /**从对象列表中获取对象，若对象不存在，则创建并加入列表。
   * @param id 对象在列表中的 Id。
   * @param generator 对象创建方法。
   * @returns 对象。*/
  protected static async getOrAddToMap<T extends AgentInstanceObject>(id: string, generator: () => Promise<T> | T): Promise<T> {
    let obj = AgentManager.objectMaps.get(id) as T
    if (!obj) AgentManager.objectMaps.set(id, (obj = await generator()))
    return obj
  }
  //#endregion

  //#region 构造函数
  /**缺省构造函数。*/
  protected static constructorDisabled: boolean = true
  protected static constructorLocked: boolean = true
  constructor() {
    if ((this.constructor as typeof AgentStaticObject).constructorDisabled)
      throw UpfCore.GlobalException.getSuggestionException(`无法创建静态类的新实例。`)
    if ((this.constructor as typeof AgentStaticObject).constructorLocked)
      throw UpfCore.GlobalException.getSuggestionException(`请使用 ${AgentStaticObject.createProtected.name} 创建新实例。`)
  }
  /**创建新实例。
   * @param id 实例 Id。
   * @returns 新实例。*/
  protected static createProtected<T extends AgentInstanceObject>(id: string): T {
    throw UpfCore.GlobalException.getSuggestionException(`无法创建静态类的新实例。`)
  }
  //#endregion

  //#region 对象操作
  /**调用指定静态方法。
   * @param name 方法名称。
   * @param parameters 方法参数。
   * @returns 方法返回值。*/
  protected static async invokeStaticMethod<T>(name: string, ...parameters: any[]): Promise<T> {
    return AgentManager.invokeStaticMember<T>(this.type, name, BindingFlags.InvokeMethod, parameters)
  }

  /**调用指定静态方法并包装。
   * @param name 方法名称。
   * @param parameters 方法参数。
   * @returns 方法返回值。*/
  protected static async invokeStaticMethodAndWrap<T extends AgentInstanceObject>(
    type: typeof AgentInstanceObject,
    name: string,
    ...parameters: any[]
  ): Promise<T> {
    return ((type as unknown) as typeof AgentStaticObject).createProtected<T>(
      await AgentManager.invokeStaticMemberAndWrap(this.type, name, BindingFlags.InvokeMethod, parameters)
    )
  }

  /**获取或设置指定静态属性值。
   * @param name 属性名称。
   * @param value 属性值。
   * @returns 属性值。*/
  protected static async staticProperty<T>(name: string, value: T = AgentStaticObject.None): Promise<T> {
    if (value === AgentStaticObject.None) return AgentManager.invokeStaticMember<T>(this.type, name, BindingFlags.GetProperty, [])
    await AgentManager.invokeStaticMember(this.type, name, BindingFlags.SetProperty, [value])
    return value
  }

  /**获取指定静态属性值并包装。
   * @param name 属性名称。
   * @param value 属性值。
   * @returns 属性值。*/
  protected static async staticPropertyAndWrap<T extends AgentInstanceObject>(
    type: typeof AgentInstanceObject,
    name: string,
    value: T = AgentStaticObject.None
  ): Promise<T> {
    if (value === AgentStaticObject.None)
      return ((type as unknown) as typeof AgentStaticObject).createProtected<T>(
        await AgentManager.invokeStaticMemberAndWrap(this.type, name, BindingFlags.GetProperty, [])
      )
    await AgentManager.invokeStaticMember(this.type, name, BindingFlags.SetProperty, [value])
    return value
  }

  /**获取或设置指定静态字段值。
   * @param name 字段名称。
   * @param value 字段值。
   * @returns 字段值。*/
  protected static async staticField<T>(name: string, value: T = AgentStaticObject.None): Promise<T> {
    if (value === AgentStaticObject.None) return AgentManager.invokeStaticMember<T>(this.type, name, BindingFlags.GetField, [])
    await AgentManager.invokeStaticMember(this.type, name, BindingFlags.SetField, [value])
    return value
  }

  /**获取指定字段值并包装。
   * @param name 字段名称。
   * @param value 字段值。
   * @returns 字段值。*/
  protected static async staticFieldAndWrap<T extends AgentInstanceObject>(
    type: typeof AgentInstanceObject,
    name: string,
    value: T = AgentStaticObject.None
  ): Promise<T> {
    if (value === AgentStaticObject.None)
      return ((type as unknown) as typeof AgentStaticObject).createProtected<T>(
        await AgentManager.invokeStaticMemberAndWrap(this.type, name, BindingFlags.GetField, [])
      )
    await AgentManager.invokeStaticMember(this.type, name, BindingFlags.SetField, [value])
    return value
  }
  //#endregion

  //#region 对象事件
  /**附加静态事件处理程序。
   * @param name 事件名称。
   * @param handler 事件处理程序。
   * @returns 事件处理程序 Id。*/
  protected static async addStaticEventHandler(name: string, handler: AgentEventHandler): Promise<string> {
    return AgentManager.addStaticEventHandler(this.type, name, handler)
  }

  /**移除事件处理程序。
   * @param name 事件名称。
   * @param handlerId 事件处理程序 Id。
   * @returns */
  protected static async removeStaticEventHandler(name: string, handlerId: string): Promise<void> {
    return AgentManager.removeStaticEventHandler(this.type, name, handlerId)
  }
  //#endregion
}

import { escape } from 'lodash'

/** Cookie服务类 */
class CookieHelper {
  /** 读取Cookie
   * @param key cookie键名
   */
  get(key: string): string | undefined {
    if (document.cookie) {
      const arr = document.cookie.split('; ')
      for (let i = 0; i < arr.length; i++) {
        const arr2 = arr[i].split('=')
        if (arr2[0] === key) return arr2[1]
      }
    }
  }

  /**
   * 设置Cookie
   * @param key cookie键名
   * @param value cookie值
   * @param day 过期时间（天）
   */

  set(key: string, value: string, day: number) {
    const expireDate: any = new Date()
    expireDate.setDate(expireDate.getDate() + day)
    document.cookie = escape(key) + '=' + escape(value) + ';expires=' + expireDate.toGMTString()
  }
}

export const cookieHelper = new CookieHelper()

import { connect, withInstall } from '@kmsoft/upf-core'
import KDistributionRecordCreateView from './KDistributionRecordCreate.vue'
import KDistributionRecordCreateViewModel from './KDistributionRecordCreateViewModel'

const KDistributionRecordCreate = connect(KDistributionRecordCreateView, KDistributionRecordCreateViewModel)

export default withInstall(KDistributionRecordCreate)
export { KDistributionRecordCreate, KDistributionRecordCreateView, KDistributionRecordCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

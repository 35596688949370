import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { IColumnDefinition } from '../../panel/bom-compare-result-panel/interface'

/** 参数 **/
export const KPropertySettingsFromPropOptions = {
  ...BaseViewPropOptions,
  selectedRows: VuePropTypes.createType<Array<IColumnDefinition>>().def([])
}

/** 参数类型 **/
export type KPropertySettingsFromPropType = ViewPropsTypeExtract<typeof KPropertySettingsFromPropOptions>

/** 事件 */
export const KPropertySettingsFromEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPropertySettingsFromEmitsType = ViewEmitsTypeExtract<typeof KPropertySettingsFromEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import ObjectCreatePanelTestView from './ObjectCreatePanelTest.vue'
import ObjectCreatePanelTestViewModel from './ObjectCreatePanelTestViewModel'

const ObjectCreatePanelTest = connect(ObjectCreatePanelTestView, ObjectCreatePanelTestViewModel)

export default withInstall(ObjectCreatePanelTest)
export { ObjectCreatePanelTest, ObjectCreatePanelTestView, ObjectCreatePanelTestViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView } from '@kmsoft/upf-core'
import { KCustomerNeedsCreatePropOptions, KCustomerNeedsCreateEventEmits } from './interface'
import KCustomerNeedsCreateViewModel from './KCustomerNeedsCreateViewModel'

export default defineView({
  name: 'KCustomerNeedsCreate',
  props: KCustomerNeedsCreatePropOptions,
  emits: KCustomerNeedsCreateEventEmits,
  viewModel: KCustomerNeedsCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { BaseViewModel, SelectOption } from '@kmsoft/upf-core'
import { KEnumsPanelPropType, IEnumsPanelDefinition, KEnumsPanelPropsEventEmitsType, ITreeSelectData } from './interface'
// import { enumDef } from '../../../../../../../../../api'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '../../../../../../../../client-srv'

export class KEnumsPanelViewModel extends BaseViewModel<KEnumsPanelPropsEventEmitsType, KEnumsPanelPropType> {
  formState = ref<IEnumsPanelDefinition>({ refEnumId: '', refEnumCode: '', refEnumName: '' })
  /** 枚举下拉树选择 */
  enumTreeData = ref<Array<SelectOption>>([])

  viewDidMount() {
    this.loadEnumTreeData()
  }

  treeSelect(value: string, node: any) {
    // 将数据进行保存
    this.formState.value = {
      refEnumId: value,
      refEnumCode: '',
      refEnumName: node.title
    }
  }

  /**
   * 加载枚举下拉树
   */
  async loadEnumTreeData() {
    const res = await Api.post('official', 'EnumService', 'listAll', { data: [] })
    if (res.code === EnumRequestCode.SUCCESS) {
      const enumList = res.data.map((item: { name: any; code: any }) => {
        return {
          label: item.name,
          value: item.code
        }
      })
      this.enumTreeData.value.push(...enumList)
    }
  }

  // 设置表单的值
  setValue(formState: IEnumsPanelDefinition) {
    this.formState.value = formState
  }

  // 获取表单的值
  getValue(): IEnumsPanelDefinition {
    this.enumTreeData.value
      .filter(data => this.formState.value.refEnumId == data.value)
      .forEach(data => {
        this.formState.value.refEnumName = data.label
      })
    return this.formState.value
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_object_class_grid, {
      ref: "refObjGrid",
      modelCode: "ChangeRequest",
      modelGroup: "change",
      pagination: {
        pageIndex: 1,
        pageSize: 20
      },
      onRowDoubleClick: _ctx.$vm.onDoubleClick,
      onToolStripItemClicked: _ctx.$vm.toolStripItemClicked,
      toolStripItems: _ctx.$vm.toolItem,
      loadData: _ctx.$vm.loadData
    }, null, 8, ["onRowDoubleClick", "onToolStripItemClicked", "toolStripItems", "loadData"])
  ]))
}

import { defineView } from '@kmsoft/upf-core'
import { clsCodeMap, EnumLifecycleState, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KBaselineCompareGridPropOptions, KBaselineCompareGridEventEmits, BaselineCompareGridDataInter } from './interface'
import KBaselineCompareGridViewModel from './KBaselineCompareGridViewModel'

export default defineView({
  name: 'KBaselineCompareGrid',
  props: KBaselineCompareGridPropOptions,
  emits: KBaselineCompareGridEventEmits,
  viewModel: KBaselineCompareGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const getRowClass = ({ row }: { row: BaselineCompareGridDataInter }) => {
      let customClass = ''
      if (row.diffType == 'recordAdd') {
        customClass = 'record-add'
      }

      return {
        customClass
      }
    }

    const getCellClass = ({ row, column }: { row: BaselineCompareGridDataInter; column: any }) => {
      let customClass = ''
      // 参考基线不显示属性差异标色
      if (
        !props.isReference &&
        row.diffType == 'propertyDiff' &&
        row.propertyDiffInfo!.map(item => item.propertyName).includes(column.dataPropertyName)
      ) {
        customClass = 'property-diff'
      }
      return { customClass }
    }
    return {
      clsCodeMap,
      EnumLifecycleState,
      EnumLifecycleStateColor,
      getRowClass,
      getCellClass
    }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KWorkflowSigningHistoryView from './KWorkflowSigningHistory.vue'
import KWorkflowSigningHistoryViewModel from './KWorkflowSigningHistoryViewModel'

const KWorkflowSigningHistory = connect(KWorkflowSigningHistoryView, KWorkflowSigningHistoryViewModel)

export default withInstall(KWorkflowSigningHistory)
export { KWorkflowSigningHistory, KWorkflowSigningHistoryView, KWorkflowSigningHistoryViewModel }

// 模板生成文件
// export * from './{{folderName}}'

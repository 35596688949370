import { connect, withInstall } from '@kmsoft/upf-core'
import KIssueReportAffectedViewsGridView from './KIssueReportAffectedViewsGrid.vue'
import KIssueReportAffectedViewsGridViewModel from './KIssueReportAffectedViewsGridViewModel'

const KIssueReportAffectedViewsGrid = connect(KIssueReportAffectedViewsGridView, KIssueReportAffectedViewsGridViewModel)

export default withInstall(KIssueReportAffectedViewsGrid)
export { KIssueReportAffectedViewsGrid, KIssueReportAffectedViewsGridView, KIssueReportAffectedViewsGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

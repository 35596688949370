import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_data_grid, {
      ref: "refDataGrid",
      rowModelType: "serverSide",
      loadData: _ctx.$vm.loadData,
      pagination: { pageIndex: 1, pageSize: 50 },
      onPaginationChanged: _ctx.$vm.paginationChanged,
      isMultipleSelection: false
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "ID",
          dataPropertyName: "id",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "编码",
          dataPropertyName: "number",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "名称",
          dataPropertyName: "name",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          headerText: "状态",
          dataPropertyName: "lifecycleStateCode",
          align: "center",
          width: "150"
        }, {
          default: _withCtx(({ cellValue }) => [
            cellValue
              ? (_openBlock(), _createBlock(_component_k_tag, {
                  key: 0,
                  color: _ctx.EnumLifecycleStateColor[cellValue]
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.EnumLifecycleState[cellValue]), 1)
                  ]),
                  _: 2
                }, 1032, ["color"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_2, "--"))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["loadData", "onPaginationChanged"])
  ]))
}
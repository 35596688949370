import { ObjectToolStripItem } from '@kmsoft/ebf-common'
import { EnumPartToolStripItemKeys } from '../types'
import { EnumToolStripCompType } from '@kmsoft/upf-core'

export const openPartObjToolStripItem: ObjectToolStripItem = {
  name: EnumPartToolStripItemKeys.TOOL_STRIP_OPEN_PART_OBJ,
  title: '打开对象',
  icon: 'OpenObj',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const showSwapToolStripItem: ObjectToolStripItem = {
  name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_SHOW_SWAP,
  title: '显示替换件',
  icon: 'folder-open',
  visible: true,
  compType: EnumToolStripCompType.CHECKBOX
}

export const toolStripItemReplaceVersion: ObjectToolStripItem = {
  name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_MENU_CHANGE_VERSION,
  title: '版本替换',
  icon: 'Part_Change',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KCreatorEditorView from './KCreatorEditor.vue'
import KCreatorEditorViewModel from './KCreatorEditorViewModel'

const KCreatorEditor = connect(KCreatorEditorView, KCreatorEditorViewModel)

export default withInstall(KCreatorEditor)
export { KCreatorEditor, KCreatorEditorView, KCreatorEditorViewModel }
export * from './interface'
export * from './KCreateEditorResolver'
export * from './KCreatorTextColumn'

// 模板生成文件
// export * from './{{folderName}}'

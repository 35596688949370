import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KAppendChangeActivityPropOptions = {
  ...BaseViewPropOptions,
  dataSource: VuePropTypes.array<any[]>().def([])
}

/** 参数类型 **/
export type KAppendChangeActivityPropType = ViewPropsTypeExtract<typeof KAppendChangeActivityPropOptions>

/** 事件 */
export const KAppendChangeActivityEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KAppendChangeActivityEmitsType = ViewEmitsTypeExtract<typeof KAppendChangeActivityEventEmits>

import { Api, CommonClientSrv, EnumRequestCode, LoginClientSrv, ObjectGroupMapping } from '@kmsoft/ebf-common'
import { EnumDialogResult, KDialog, KDialogClosingEvent, KNotification } from '@kmsoft/upf-core'
import { KTaskEvaluate, KTaskEvaluateViewModel } from '../../controls/task-evaluate'
import { KObjectAddWorkflow, KObjectAddWorkflowViewModel } from '../../pages/object-add-workflow'
import { KWorkflowView } from '../../pages/workflow-view'

export class WorkflowClientSrv {
  /**
   * 加入流程，注意：流程名称根据下面参数自动生成，如果没有则生成的流程名称不包含该字段
   * @param objId 对象Id
   * @param objClsCode 对象类
   * @param name 对象名称
   * @param number 对象编码
   * @param version 对象版本
   */
  static async addWorkflow(objId: string, objClsCode: string, status: string, name?: string, number?: string, version?: string) {
    return this.addObjectsToWorkflow([
      { objID: objId, objClsCode: objClsCode, name: name, status: status, number: number, version: version }
    ])
  }

  /**
   * 加入流程，注意：流程名称根据下面参数自动生成，如果没有则生成的流程名称不包含该字段
   * @param objId 对象Id
   * @param objClsCode 对象类
   * @param name 对象名称
   * @param number 对象编码
   * @param version 对象版本
   */
  static async addWorkflowoOnlyCheckWf(
    objId: string,
    objClsCode: string,
    status: string,
    name?: string,
    number?: string,
    version?: string
  ) {
    return this.addObjectsToWorkflowCheckWf([
      { objID: objId, objClsCode: objClsCode, name: name, status: status, number: number, version: version }
    ])
  }

  /**
   * 将一批对象加入流程
   * @param objParams 对象列表
   */
  static async addObjectsToWorkflow(
    objParams: Array<{ objID: string; objClsCode: string; status: string; name?: string; number?: string; version?: string }>
  ) {
    return new Promise((resolve, reject) => {
      const param = objParams.map(obj => {
        return {
          objId: obj.objID,
          objClsCode: obj.objClsCode,
          name: obj.name!,
          number: obj.number!
        }
      })
      this.checkWorkflowAndChangeManaged(param).then(res => {
        if (res) {
          KDialog.show({
            title: '加入流程',
            content: KObjectAddWorkflow,
            props: {
              objParams: objParams
            },
            size: { width: 1100, height: 820 },
            onClosing: async (event: KDialogClosingEvent) => {
              if (event.dialogResult == EnumDialogResult.Cancel) return
              if (event.dialogResult == EnumDialogResult.Close) return
              const vm = event.viewModel as KObjectAddWorkflowViewModel
              const verifyResult = await vm.verify()
              if (!verifyResult) {
                KNotification.warn({
                  message: '系统提示',
                  description: '请检查流程活动执行人是否为空或表单数据未填写'
                })
                event.cancel = true
                return
              }
              const value = vm.getValue()
              const wfWorkObjList = objParams.map(objParam => {
                return { workObjId: objParam.objID, workObjClsCode: objParam.objClsCode }
              })
              const param = {
                data: [
                  {
                    code: value.code,
                    name: value.name,
                    templateId: value.templateId,
                    describe: value.describe,
                    wfWorkObjList
                  }
                ]
              }
              const result = await Api.post('official', 'ProcessContentService', 'create', param)
              if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
                const params = {
                  data: [
                    {
                      key: value.templateKey,
                      id: value.templateId,
                      name: value.name,
                      userId: LoginClientSrv.getUserIdentity()?.id,
                      businessId: result.data,
                      variables: value.variables
                    }
                  ]
                }
                const res = await Api.post('official', 'ProcessInstanceService', 'startProcessByKey', params)
                if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
                  KNotification.success({
                    title: '系统提示',
                    content: '加入流程成功'
                  })
                  resolve(void 0)
                  return
                } else {
                  KNotification.error({
                    title: '启动流程失败',
                    content: res.message!,
                    details: result.detail
                  })
                  event.cancel = true
                  return
                }
              } else {
                KNotification.error({
                  title: '创建流程失败',
                  content: result.message!,
                  details: result.detail
                })
                event.cancel = true
                return
              }
            }
          })
        }
      })
    })
  }

  /**
   * 将一批对象加入流程校验是否已加入流程
   * @param objParams 对象列表
   */
  static async addObjectsToWorkflowCheckWf(
    objParams: Array<{ objID: string; objClsCode: string; status: string; name?: string; number?: string; version?: string }>
  ) {
    return new Promise((resolve, reject) => {
      const param = objParams.map(obj => {
        return {
          objId: obj.objID,
          objClsCode: obj.objClsCode
        }
      })
      this.checkWorkflow(param).then(res => {
        if (res) {
          KDialog.show({
            title: '加入流程',
            content: KObjectAddWorkflow,
            props: {
              objParam: {
                name: objParams[0].name,
                number: objParams[0].number,
                version: objParams[0].version
              }
            },
            size: { width: 1100, height: 820 },
            onClosing: async (event: KDialogClosingEvent) => {
              if (event.dialogResult == EnumDialogResult.Cancel) return
              if (event.dialogResult == EnumDialogResult.Close) return
              const vm = event.viewModel as KObjectAddWorkflowViewModel
              const verifyResult = await vm.verify()
              if (!verifyResult) {
                KNotification.warn({
                  message: '系统提示',
                  description: '请检查流程活动执行人是否为空或表单数据未填写'
                })
                event.cancel = true
                return
              }
              const value = vm.getValue()
              const wfWorkObjList = objParams.map(objParam => {
                return { workObjId: objParam.objID, workObjClsCode: objParam.objClsCode }
              })
              const param = {
                data: [
                  {
                    code: value.code,
                    name: value.name,
                    templateId: value.templateId,
                    describe: value.describe,
                    wfWorkObjList
                  }
                ]
              }
              const result = await Api.post('official', 'ProcessContentService', 'create', param)
              if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
                const params = {
                  data: [
                    {
                      key: value.templateKey,
                      id: value.templateId,
                      name: value.name,
                      userId: LoginClientSrv.getUserIdentity()?.id,
                      businessId: result.data,
                      variables: value.variables
                    }
                  ]
                }
                const res = await Api.post('official', 'ProcessInstanceService', 'startProcessByKey', params)
                if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
                  KNotification.success({
                    title: '系统提示',
                    content: '加入流程成功'
                  })
                  resolve(void 0)
                  return
                } else {
                  KNotification.error({
                    title: '启动流程失败',
                    content: res.message!,
                    details: result.detail
                  })
                  event.cancel = true
                  return
                }
              } else {
                KNotification.error({
                  title: '创建流程失败',
                  content: result.message!,
                  details: result.detail
                })
                event.cancel = true
                return
              }
            }
          })
        }
      })
    })
  }

  /**
   * 获取流程模板流程图
   * @param workflowTemplateId 流程模板id
   * @returns 流程图
   */
  static async getWorkflowTemplatePicture(workflowTemplateId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const host = window.location.host
      let parts = host.split('.')
      parts[0] = 'flow-admin'
      // 本地通过localhost访问，没有域名，则默认使用test.kmsaasdev.com
      if (parts.length <= 1) {
        parts[1] = 'dev.kmsaas.com'
      }
      let newHost = parts.join('.')
      const url = `${window.location.protocol}//${newHost}/bpmn-viewer/index.html?mode=definition&id=` + workflowTemplateId
      resolve(url)
    })
  }

  /**
   * 查看流程
   * @param objId
   * @param objClsCode
   * @returns
   */
  static async viewWorkflow(objId: string, objClsCode: string) {
    const signingHistoryPara = {
      workObjId: objId,
      workObjClsCode: objClsCode
    }
    Api.post('official', 'ProcessContentService', 'listHistoryProcess', { data: [signingHistoryPara] }).then(result => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        if (result.data.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '未找到流程'
          })
          return
        }
        CommonClientSrv.openPage(
          '流程详情：' + result.data[0].processName,
          KWorkflowView,
          {
            taskId: '',
            processInstanceId: result.data[0].processInstanceId
          },
          result.data[0].processInstanceId
        )
      } else {
        KNotification.error({
          title: '获取失败',
          content: result.message,
          details: result.detail
        })
      }
    })
  }

  /**
   * 校验对象是否加入流程或变更活动
   * @param objId 对象id
   * @param objClsCode 对象类
   */
  static async checkWorkflowAndChangeManaged(
    objParams: Array<{ objId: string; objClsCode: string; name: string; number: string }>
  ): Promise<boolean> {
    // 校验对象是否加入流程
    if (objParams.length == 0) {
      return false
    }
    //校验当前对象是否加入流程或变更活动
    const param = objParams.map(obj => {
      return {
        id: obj.objId,
        clazz: obj.objClsCode,
        name: obj.name,
        number: obj.number
      }
    })
    const res = await Api.post('change', 'ChangeActivity', 'checkByCaAndWf', { data: [param] })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      if (res.data.success) {
        return true
      } else {
        KNotification.warn({
          message: '系统提示',
          description: res.data.msg
        })
        return false
      }
    } else {
      KNotification.warn({
        message: '系统提示',
        description: res.message
      })
      return false
    }
  }

  /**
   * 校验对象是否加入流程
   * @param objId 对象id
   * @param objClsCode 对象类
   */
  static async checkWorkflow(objParams: Array<{ objId: string; objClsCode: string }>): Promise<boolean> {
    // 校验对象是否加入流程
    if (objParams.length == 0) {
      return false
    }
    //校验当前对象是否加入流程
    const param = objParams.map(obj => {
      return {
        workObjId: obj.objId,
        workObjClsCode: obj.objClsCode
      }
    })
    const res = await Api.post('official', 'ProcessContentService', 'checkWorkflow', { data: [param] })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      if (res.data.success) {
        return true
      } else {
        KNotification.warn({
          message: '系统提示',
          description: res.data.message
        })
        return false
      }
    } else {
      KNotification.warn({
        message: '系统提示',
        description: res.message
      })
      return false
    }
  }

  /**
   * 任务评价
   * @param processInstanceId 流程实例id
   */
  static async taskEvaluate(processInstanceId: string, businessId: string) {
    const checkParam = {
      data: [processInstanceId]
    }
    const checkResult = await Api.post('official', 'ProcessContentService', 'checkWorkflowForEvaluate', checkParam)
    if (checkResult && checkResult.code == EnumRequestCode.SUCCESS) {
      if (checkResult.data.success) {
        //弹出任务评价窗口
        KDialog.show({
          title: '任务评价',
          props: {
            businessId: businessId
          },
          content: KTaskEvaluate,
          size: { width: 677, height: 280 },
          showCancel: false,
          closable: false,
          onClosing: async (event: KDialogClosingEvent) => {
            if (event.dialogResult == EnumDialogResult.Cancel) return
            if (event.dialogResult == EnumDialogResult.Close) return
            const vm = event.viewModel as KTaskEvaluateViewModel
            const formValue = vm.getValue()!
            if (formValue.workObjInfo.length == 0) {
              return
            }
            // 调用任务评价接口
            const param = {
              planId: formValue.workObjInfo[0].id,
              evaluationLevel: formValue.evaluate,
              evaluationDetail: formValue.describe
            }
            const res = await Api.post('official', 'ITaskService', 'saveTaskEvaluation', { data: [param] })
            if (res && res.code == EnumRequestCode.SUCCESS) {
              KNotification.success({
                title: '系统提示',
                content: '任务评价成功'
              })
            } else {
              KNotification.warn({
                message: '系统提示',
                description: res.message
              })
            }
          }
        })
      }
      return
    } else {
      KNotification.error({
        title: '系统提示',
        content: checkResult.message!
      })
      return
    }
  }
}

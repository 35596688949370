import { EnumRequestCode, IClsOption, KObjectDynamicCreatePanel } from '@kmsoft/ebf-common'
import { EnumDialogResult, KDataGridRowData, KDialog, KDialogClosingEvent, KNotification } from '@kmsoft/upf-core'

export class RequirementChangeClientSrv {
  static create(row: KDataGridRowData, folderId: string) {
    const clsOptions = [] as Array<IClsOption>
    KDialog.show({
      title: '创建对象',
      content: KObjectDynamicCreatePanel,
      props: {
        clsOptions: clsOptions,
        folderId: folderId,
        showObjClsCodes: ['RequirementChangeRequest'],
        defaultSelectClsCode: 'RequirementChangeRequest',
        showObjClsSelector: false
      },
      size: { width: 900, height: document.documentElement.clientHeight - 300 },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewInstance as any
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }
        const result = await formViewModel.save(row, folderId)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success('对象创建成功')
          return true
        } else {
          if (result) {
            KNotification.error({
              title: '操作失败',
              content: result.message || '创建对象失败',
              details: result.detail
            })
          }
          event.cancel = true
          return false
        }
      }
    })
  }
}


import { EnumDataGridRowModelType, IQuery, defineView } from '@kmsoft/upf-core'
import { KPartViewPropOptions, KPartViewEventEmits } from './interface'
import KPartViewViewModel from './KPartViewViewModel'

export default defineView({
  name: 'KPartView',
  props: KPartViewPropOptions,
  emits: KPartViewEventEmits,
  viewModel: KPartViewViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumDataGridRowModelType }
  }
})

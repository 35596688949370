import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KFolderSelectTestEmitsType, KFolderSelectTestPropType } from './interface'
import { ref } from 'vue'

/** KFolderSelectTest */
export default class KFolderSelectTestViewModel extends BaseViewModel<KFolderSelectTestEmitsType, KFolderSelectTestPropType> {
  formDataState = ref<Record<string, object>>()
  //635513245569916928
  folderId = ref<string>('')
  constructor(options: ViewModelOptions<KFolderSelectTestPropType>) {
    super(options)
  }

  viewDidMount() {}
  changeFolderId() {
    alert('newFolderId:' + this.folderId.value)
  }
}

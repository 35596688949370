import { IMenuPagePathMapping } from '@kmsoft/upf-core'
import KWorkflowTemplate from '../pages/workflow-template'
import KPersonalWorkflowTasks from '../pages/personal-workflow-tasks'
import KWorkflowView from '../pages/workflow-view'
import KWorkflowSigningHistory from '../pages/workflow-signing-history'

export default class ModuleMenuExpose implements IMenuPagePathMapping {
  getMenus() {
    return [
      { target: 'workflow-template', component: KWorkflowTemplate },
      { target: 'personal-workflow-tasks', component: KPersonalWorkflowTasks },
      { target: 'workflow-view', component: KWorkflowView },
      { target: 'workflow-signing-history', component: KWorkflowSigningHistory }
    ]
  }
}

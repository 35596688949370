import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineCompareGridDialogView from './KBaselineCompareGridDialog.vue'
import KBaselineCompareGridDialogViewModel from './KBaselineCompareGridDialogViewModel'

const KBaselineCompareGridDialog = connect(KBaselineCompareGridDialogView, KBaselineCompareGridDialogViewModel)

export default withInstall(KBaselineCompareGridDialog)
export { KBaselineCompareGridDialog, KBaselineCompareGridDialogView, KBaselineCompareGridDialogViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectDisplayView from './KObjectDisplay.vue'
import KObjectDisplayViewModel from './KObjectDisplayViewModel'

const KObjectDisplay = connect(KObjectDisplayView, KObjectDisplayViewModel)

export default withInstall(KObjectDisplay)
export { KObjectDisplay, KObjectDisplayView, KObjectDisplayViewModel }
export * from './KObjectDisplayResolver'
export * from './KObjectDisplayColumn'

// 模板生成文件
// export * from './{{folderName}}'

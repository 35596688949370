import { BaseViewModel, KDataGridViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KDocAnnotationListPanelEmitsType, KDocAnnotationListPanelPropType } from './interface'
import { Api, EnumRequestCode, KObjectClassGridViewModel } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KKDocAnnotationListPanel */
export default class KDocAnnotationListPanelViewModel extends BaseViewModel<
  KDocAnnotationListPanelEmitsType,
  KDocAnnotationListPanelPropType
> {
  refDataGrid = ref<KDataGridViewModel>()
  constructor(options: ViewModelOptions<KDocAnnotationListPanelPropType>) {
    super(options)
  }

  viewDidMount() {}

  getSelectedRow() {
    const rows = this.refDataGrid.value?.getSelectedRows()
    if (!rows || rows.length < 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return null
    }
    return rows[0]
  }

  getSelectedRows() {
    const rows = this.refDataGrid.value?.getSelectedRows()
    if (!rows || rows.length < 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return null
    }
    return rows
  }

  // async loadData() {
  //   return Promise.resolve(this.props.data)
  // let reqData = { workflowId: this.props.workflowId, documentId: this.props.documentId } as any
  // if (!this.props.taskId) {
  //   const result = await Api.post('doc', 'Document', 'listDocAnnotation', { data: [reqData] })
  //   if (result && result.code == EnumRequestCode.SUCCESS) {
  //     return Promise.resolve(result.data)
  //   }
  //   KNotification.error({
  //     title: '系统错误',
  //     content: result.message
  //   })
  //   return
  // } else {
  //   reqData = { ...reqData, taskId: this.props.taskId }
  //   const result = await Api.post('doc', 'Document', 'listDocHistoryAnnotation', { data: [reqData] })
  //   if (result && result.code == EnumRequestCode.SUCCESS) {
  //     return Promise.resolve(result.data)
  //   }
  //   KNotification.error({
  //     title: '系统错误',
  //     content: result.message
  //   })
  //   return
  // }
  // }
}

import { BaseViewModel, KDataGridViewModel, KViewSchema, KViewSchemaWithId, ViewModelOptions } from '@kmsoft/upf-core'
import { KFilterGridViewConfigEventEmitsType, KFilterGridViewConfigPropType } from './interface'
import { ref, watch } from 'vue'
import { cloneDeep } from 'lodash'

/** KFilterGridViewConfig 视图模型 */
export class KFilterGridViewConfigViewModel extends BaseViewModel<
  KFilterGridViewConfigEventEmitsType,
  KFilterGridViewConfigPropType
> {
  /** 网格引用 */
  refGrid = ref<KDataGridViewModel | null>(null)
  /** 视图集合 */
  views = ref<Array<KViewSchemaWithId>>([])
  constructor(options: ViewModelOptions<KFilterGridViewConfigPropType>) {
    super(options)

    watch(
      () => options.props.views,
      newVal => {
        this.views.value = cloneDeep(newVal!)
      },
      {
        immediate: true,
        deep: true
      }
    )
  }
  /**
   * 加载完成函数
   */
  viewDidMount() {}

  /**
   * 校验
   */
  validate() {
    return new Promise((resolve, reject) => {
      resolve(true)
    })
  }

  /**
   * 获取视图信息
   */
  getValue() {
    return this.convert2KViewSchema()
  }

  /**
   * 转换KViewsSchema
   */
  private convert2KViewSchema(): Array<KViewSchema> {
    const getRows = this.refGrid.value?.getRows()
    return getRows!.map(item => {
      return { name: item.name, remark: item.remark, elements: item.elements }
    })
  }
}

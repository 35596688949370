import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  KDialogClosingEvent,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { CreateObjBusinessParams, EnumObjCopyMode, ObjBusinessBase, ObjBusinessParam, ObjBusinessResult } from '../../client-srv'
import KObjectCreatePanelViewModel from './KObjectCreatePanelViewModel'
import { OptionValue } from './controls'

//#region 类型定义
/** 创建面板加载参数 */
export type ObjectCreateLoadDataParams = {
  /** 创建对象类型 */
  objCopyMode: EnumObjCopyMode
  /** 对象类Id */
  modelCode: string
  /**对象类分组 */
  group: string
  /** 对象Id（仅在在另存为，创建大版本，创建小版本场景下才会使用该属性） */
  objId?: string
  /**主对象id */
  masterId?: string
  /**迭代id */
  iteratorId?: string
}

/** 对象面板保存结果 */
export type ObjectCreateFormSaveResult = ObjBusinessParam & {
  /** 业务对象 */
  objBusiness?: ObjBusinessBase
  /** 取消保存 */
  cancel: boolean
  /** 是否成功 */
  success: boolean
  /** 错误消息 */
  errMsg: string
  /** 加入流程后流程对象参数 */
  flowObjParam?: ObjBusinessParam
}

/**
 * 业务对象获取器类型
 * @params: 参数
 */
export type ObjectCreatePanelLoadDataCallback = (params: ObjectCreateLoadDataParams) => Promise<ObjBusinessResult>

/** 保存回调 */
export type ObjectCreatePanelSaveCallback = (params: CreateObjBusinessParams) => Promise<ObjectCreateFormSaveResult>

/** 新建对象保存后 */
export type ObjectCreateedEvent = (
  /** 业务对象 */
  objBusiness: ObjBusinessBase
) => Promise<void>

/** 附属参数 */
export type ObjectCreatePanelAttachParams = {}
//#endregion

//#region 窗口属性

/** 对象创建前事件 */
export type ObjectCreateDialogSaveBeforeEvent = {
  /** 事件 */
  event: KDialogClosingEvent<KObjectCreatePanelViewModel>
}

/**
 * 对象面板保存结果
 */
export type ObjectCreatePanelSaveEvent = ObjectCreateFormSaveResult & {
  /** 保存选项 */
  options: Array<OptionValue>
}

/** 窗口参数 */
export type ObjectCreateDialogParams = Partial<KObjectCreatePanelPropType> & {
  /** 标题 */
  title?: string
  /** 是否显示应用按钮 */
  showApply?: boolean
  /**
   * 是否强制显示对象类选择下拉控件，非必选属性
   * @description 通过零部件创建EPBOM场景，虽然提供了默认对象类，但是还是可以切换EPBOOM对象类
   */
  forceShowObjClsSelector?: boolean
  /** 关闭前事件 */
  onClosingBefore?: (event: ObjectCreateDialogSaveBeforeEvent) => void
  /** 确认回调 */
  onConfirm?: (result: ObjectCreateFormSaveResult) => void
  /** 应用回调 */
  onApply?: (result: ObjectCreateFormSaveResult) => void
  /** 关闭回调 */
  onClosed?: () => void
}
//#endregion

/** 参数 **/
export const KObjectCreatePanelPropOptions = {
  ...BaseViewPropOptions,
  /** 对象类Id */
  modelCode: VuePropTypes.string().def(),
  /**对象类分组 */
  modelGroup: VuePropTypes.string().def(),
  //#region 对象类树
  /**
   * 是否强制像是对象类选择下拉控件
   * @default false
   * @description Exp: 通过零部件创建EPBOM场景，虽然提供了默认对象类，但是还是可以切换EPBOOM对象类
   */
  forceShowObjClsSelector: VuePropTypes.bool().def(false),
  /** 对象类类型 */
  classTemplates: VuePropTypes.array<string>().def([]),
  /** 设置要显示的对象id集合 */
  showObjClsCodes: VuePropTypes.array<string>().def([]),
  /** 设置要隐藏的对象类id 可追加列表 使得多个环境变量可共同干预 */
  hideObjClsCodes: VuePropTypes.array<string>().def([]),
  //#endregion

  //#region 创建大小版本
  /** 对象Id（仅在在另存为，创建大版本，创建小版本场景下才会使用该属性） */
  objId: VuePropTypes.string().def(),
  /**  */
  masterId: VuePropTypes.string().def(),
  /** 迭代Id */
  iteratorId: VuePropTypes.string().def(),
  //#endregion

  //#region 创建面板
  /** 创建对象类型 */
  objCopyMode: VuePropTypes.createType<EnumObjCopyMode>()
    .setRequired()
    .def(),
  /** 对象面板业务对象获取器 */
  loadData: VuePropTypes.func<ObjectCreatePanelLoadDataCallback>().def(),
  /** 自定义保存回调 */
  onSave: VuePropTypes.func<ObjectCreatePanelSaveCallback>().def(),
  /** 新建对象保存后 */
  onSaved: VuePropTypes.func<ObjectCreateedEvent>().def(),
  /** 创建附属参数 */
  attachParams: VuePropTypes.createType<ObjectCreatePanelAttachParams>().def(),
  /** 是否展示全部 */
  showAllOrNot: VuePropTypes.bool().def(false)
  //#endregion
}

/** 参数类型 **/
export type KObjectCreatePanelPropType = ViewPropsTypeExtract<typeof KObjectCreatePanelPropOptions>

/** 事件 */
export const KObjectCreatePanelEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KObjectCreatePanelEmitsType = ViewEmitsTypeExtract<typeof KObjectCreatePanelEventEmits>

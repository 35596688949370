import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KChangeRankingPropOptions = {
  ...BaseViewPropOptions,
  dataSource: VuePropTypes.createType<Record<string, string>[]>().def([])
}

/** 参数类型 **/
export type KChangeRankingPropType = ViewPropsTypeExtract<typeof KChangeRankingPropOptions>

/** 事件 */
export const KChangeRankingEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangeRankingEmitsType = ViewEmitsTypeExtract<typeof KChangeRankingEventEmits>

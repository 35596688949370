import { IColumnDefinition } from '../../../interface'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

export interface IConfigGridTableDefinition {
  defId: string
  id: string
  code: string
  name: string
  value: string
  remark: string
  dataType: string
  editType: string
  editParam: string
}

// 页面事件
export const KConfigGridTableTileEventEmits = {
  ...BaseViewEventEmits
}

export const KConfigGridTableTileViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('')
}

export type KConfigGridTableTileViewModelPropType = ViewPropsTypeExtract<typeof KConfigGridTableTileViewModelPropOptions>

export type KConfigGridTableTileEventEmitsType = ViewEmitsTypeExtract<typeof KConfigGridTableTileEventEmits>

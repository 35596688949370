import lodash from 'lodash'
import { ref, watch, computed } from 'vue'
import { defineView } from '@kmsoft/upf-core'
import {
  KObjectCreateOptionPropOptions,
  KObjectCreateOptionEventEmits,
  ObjectCreateOptionItem,
  EnumSaveOption,
  DEFAULT_OPTIONS
} from './interface'
import KObjectCreateOptionViewModel from './KObjectCreateOptionViewModel'
import { ClassMetaClientSrv, MetaClass } from '../../../../client-srv'

export default defineView({
  name: 'KObjectCreateOption',
  props: KObjectCreateOptionPropOptions,
  emits: KObjectCreateOptionEventEmits,
  viewModel: KObjectCreateOptionViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /** 选择的条目 */
    const checkboxValue = ref<Record<string, boolean>>({})
    /** 元数据定义 */
    const metaClass = ref<MetaClass>()

    watch(
      () => props.modelCode,
      async () => {
        //TODO:移除元数据缓存
        metaClass.value = await ClassMetaClientSrv.getClassByClsCode(props.modelCode)
      },
      { immediate: true }
    )

    /** 单选组列表 */
    const options = computed(() => {
      const newOptions: Array<ObjectCreateOptionItem> = []

      // 增加默认选项
      newOptions.push(...DEFAULT_OPTIONS.filter(a => props.showOptions.find(b => a.value == b)))

      if (metaClass.value && metaClass.value?.feature.supportWorkFlow) {
        newOptions.push(DEFAULT_OPTIONS.find(a => a.value == EnumSaveOption.ADD_WORKFLOW)!)
        newOptions.push(DEFAULT_OPTIONS.find(a => a.value == EnumSaveOption.OPEN_FLOW_OBJ)!)
      }

      newOptions.push(...props.options)

      return newOptions
    })

    watch(checkboxValue, (newValue, oldValue) => {
      //#region 打开工作流勾选时  打开对象不应该勾选
      const isOpenFlowObj = checkboxValue.value[EnumSaveOption.OPEN_FLOW_OBJ]
      if (isOpenFlowObj) {
        checkboxValue.value[EnumSaveOption.OPEN_OBJ] = false
      }
      //#endregion

      //#region 打开对象时 打开流程不应该勾选
      const isOpenObj = checkboxValue.value[EnumSaveOption.OPEN_OBJ]
      if (isOpenObj) {
        checkboxValue.value[EnumSaveOption.OPEN_FLOW_OBJ] = false
      }
      //#endregion

      // v-model 更新
      emit('update:value', checkboxValue.value)
    })

    return () => {
      /** 单选框参数 */
      const checkBoxGroupProps = {
        options: options.value,
        value: options.value
          .filter(a => checkboxValue.value[a.value] == true)
          .map(a => {
            return a.value
          }),
        'onUpdate:value': (value: Array<string>) => {
          const newValue: Record<string, boolean> = {}
          for (const option of options.value) {
            newValue[option.value] = value.includes(option.value)
          }
          checkboxValue.value = newValue
        }
      }

      return (
        <div class="k-object-create-form-option">
          <k-checkbox-group {...checkBoxGroupProps} />
        </div>
      )
    }
  }
})

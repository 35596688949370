import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_checkbox = _resolveComponent("k-checkbox")!
  const _component_k_radio_group = _resolveComponent("k-radio-group")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    ref: _ctx.$vm.refConfigForm,
    "label-col": { span: 5 },
    "wrapper-col": { span: 16 },
    model: _ctx.$vm.formState
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, {
        label: "编码",
        name: "code",
        required: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formState.code,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formState.code) = $event)),
            maxlength: 40
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, {
        label: "名称",
        name: "name",
        required: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formState.name,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formState.name) = $event)),
            maxlength: 40
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, {
        label: "备注",
        name: "remark",
        required: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_textarea, {
            value: _ctx.$vm.formState.remark,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.formState.remark) = $event)),
            maxlength: 170,
            rows: 3
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, {
        label: "模式",
        name: "pattern",
        required: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_select, {
            value: _ctx.$vm.formState.pattern,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.formState.pattern) = $event)),
            options: _ctx.patternOptions
          }, null, 8, ["value", "options"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, { label: "范围" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_checkbox, {
            checked: _ctx.$vm.formState.folder,
            "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.$vm.formState.folder) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode("目录")
            ]),
            _: 1
          }, 8, ["checked"]),
          _createVNode(_component_k_checkbox, {
            checked: _ctx.$vm.formState.config,
            "onUpdate:checked": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$vm.formState.config) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode("配置项")
            ]),
            _: 1
          }, 8, ["checked"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, {
        label: "分支",
        name: "branch",
        required: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_radio_group, {
            value: _ctx.$vm.formState.branch,
            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.$vm.formState.branch) = $event)),
            options: _ctx.branchOptions
          }, null, 8, ["value", "options"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
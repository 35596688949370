import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  EnumDialogResult,
  KDialogClosingEvent,
  KDialogResult,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VNodeProps,
  VuePropTypes
} from '@kmsoft/upf-core'
import { UploadFileInfo } from '../../client-srv'
import KFileUploaderViewModel from './KFileUploaderViewModel'

//#region 类型定义
/** 上传窗口参数 */
export type FileUploaderAsyncDialogParams = VNodeProps<KFileUploaderPropType, KFileUploaderEmitsType> & {
  /** 标题 */
  title?: string
}

/** 上传窗口参数 */
export type FileUploaderDialogParams = VNodeProps<KFileUploaderPropType, KFileUploaderEmitsType> & {
  /** 标题 */
  title?: string
  /** 窗口关闭事件 */
  onClosing: (event: KDialogClosingEvent<KFileUploaderViewModel>) => void
}

/** 上传窗口结果 */
export type FileUploaderDialogResult = KDialogResult

/** 上传结果 */
export type FileUploaderResult = {
  /** 执行关闭的按钮Id */
  dialogResult: EnumDialogResult | string
  /** 文件列表 */
  fileList: Array<UploadFileInfo>
}
//#endregion

/** 参数 **/
export const KFileUploaderPropOptions = {
  ...BaseViewPropOptions,
  /** 初始化显示的文件列表 */
  fileList: VuePropTypes.createType<Array<UploadFileInfo>>().def([]),
  /** 拖拽还是普通的 */
  mode: VuePropTypes.createType<'default' | 'dragger'>().def('default'),
  /** 接受的文件类型，后缀 */
  accept: VuePropTypes.array().def(),
  /** 是否同时上传多个 */
  multiple: VuePropTypes.bool().def(true),
  /** 最大上传数量 */
  maxCount: VuePropTypes.number().def(Infinity),
  /** 是否只读 */
  readonly: VuePropTypes.bool().def(false),
  /** 是否可用 */
  disabled: VuePropTypes.bool().def(false),
  /** 是否显示进度条 */
  showUploadList: VuePropTypes.bool().def(true),
  /** 是否显示上传按钮 */
  showUploadButton: VuePropTypes.bool().def(false),
  /** 填充控件 */
  fillSpace: VuePropTypes.bool().def(false),
  /**模型编码 */
  modelCode: VuePropTypes.string().def(''),
  /**文件属性编码 */
  attributeName: VuePropTypes.string().def('')
}

/** 参数类型 **/
export type KFileUploaderPropType = ViewPropsTypeExtract<typeof KFileUploaderPropOptions>

/** 事件 */
export const KFileUploaderEventEmits = {
  ...BaseViewEventEmits,
  /** 分片上传完成 */
  chunkUploaded: (fileInfo: UploadFileInfo) => true,
  /** 上传完成 */
  uploaded: (fileInfo: UploadFileInfo) => true,
  /** 上传失败 */
  uploadFail: (fileInfo: UploadFileInfo) => true,
  /** 移除文件 */
  remove: (fileInfo: UploadFileInfo) => true,
  /** 文件列表改变事件 */
  change: (fileList: Array<UploadFileInfo>) => true,
  /** v-model */
  'update:fileList': (fileList: Array<UploadFileInfo>) => true
}

/** 事件类型 **/
export type KFileUploaderEmitsType = ViewEmitsTypeExtract<typeof KFileUploaderEventEmits>

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KCommonValidationMetricsPropOptions = {
  ...BaseViewPropOptions,
  folderId: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KCommonValidationMetricsPropType = ViewPropsTypeExtract<typeof KCommonValidationMetricsPropOptions>

/** 事件 */
export const KCommonValidationMetricsEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KCommonValidationMetricsEmitsType = ViewEmitsTypeExtract<typeof KCommonValidationMetricsEventEmits>

import { Component } from 'vue'
import { ObjBusinessParam } from '../../'
import { NavigationMenuGroup } from '@kmsoft/upf-core'

/** 对象面板排序配置 */
export type ObjectPanelTabSortConfig = {
  tabId: string
  index: number
}
export enum EnumObjectAttachParam {
  /// <summary>
  /// 【系统标准接口】
  /// 创建描述和关联对象时的主对象。ObjBusinessParam类型。
  /// </summary>
  MainObjParam = 1,
  /// <summary>
  /// 【系统标准接口】
  /// 同名传递的业务对象。类型为 ObjBusinessBase。
  /// </summary>
  SameNameFillBusiness = 20,
  /// <summary>
  /// 【系统标准接口】
  /// 扩展参数的格式标识。当用一个类实现多个创建功能的应用，而入参又不同时，可以用此区分。
  /// 由使用方定义和解释。
  /// 建议为字符型，格式：插件名_功能名。
  /// </summary>
  ExtendParamType = 1000,

  /// <summary>
  /// 【系统标准接口】
  /// 扩展参数1
  /// </summary>
  ExtendParam1 = 1001,

  /// <summary>
  /// 【系统标准接口】
  /// 扩展参数2
  /// </summary>
  ExtendParam2 = 1002,

  /// <summary>
  /// 【系统标准接口】
  /// 扩展参数3
  /// </summary>
  ExtendParam3 = 1003,

  /// <summary>
  /// 【系统标准接口】
  /// 扩展参数3
  /// </summary>
  ExtendParam4 = 1004,

  /// <summary>
  /// 【系统标准接口】
  /// 扩展参数5
  /// </summary>
  ExtendParam5 = 1005
}
/** 服务附属参数 */
export type ServiceAttachParams = {
  serviceIdentity: string
  attachParam: {
    [key in EnumObjectAttachParam | string]?: any
  }
}

/** 事件参数基类型 */
export type EventFunctionParams = {
  cancel?: boolean
  cancelInfo?: string
  failed?: boolean
}

export class EventHandlerSrv<E extends EventFunctionParams> {
  protected eventArgs: E
  constructor(eventArgs: E) {
    this.eventArgs = eventArgs
  }

  protected fail(failInfo: string): true {
    this.eventArgs.cancel = true
    this.eventArgs.cancelInfo = failInfo
    this.eventArgs.failed = true
    return true
  }

  protected cancel(cancelInfo?: string): true {
    this.eventArgs.cancel = true
    this.eventArgs.cancelInfo = cancelInfo
    this.eventArgs.failed = false
    return true
  }

  protected continue() {
    this.eventArgs.cancel = false
    return false
  }
}

/**
 * 菜单加载前处理参数
 */
export type NavigationMenuInitBeforeEvent = {
  menuGroups: Array<NavigationMenuGroup>
} & EventFunctionParams

export type DeleteObjEvent = {
  objParams: ObjBusinessParam[]
} & EventFunctionParams

export type OpenObjEvent = {
  objParam: ObjBusinessParam
  title: string | Record<string, any>
  isPurview: boolean
  serviceAttachParams: ServiceAttachParams
} & EventFunctionParams

export type OpenObjQueryEvent = {
  showObjClsIds: string[]
  hideObjClsIds: string[]
} & EventFunctionParams

export type ChangeObjClsShowEvent = {
  objParamList?: ObjBusinessParam[]
  objClsID: string
  objectClassIdList: {
    show: string[]
    hide: string[]
  }
} & EventFunctionParams

export type ObjectPanelTabsInitEvent = {
  sortConfig: Array<ObjectPanelTabSortConfig>
} & EventFunctionParams

export type GetDocCreatePanelEvent = {
  objClsID: string
  panelComponents: string | (() => Component)
} & EventFunctionParams

/**对象类树界面显示参数（对象创建对话框、对象查询对话框） */
export type objClassTreeShowArgs = {
  /**零部件创建或查询界面中，对象类树中要隐藏的对象类Id列表 */
  hideObjClsIds?: Array<string>
  /**零部件创建或查找界面中，对象类树中仅显示的对象类Id列表 */
  showObjClsIds?: Array<string>
  /**零部件创建或查找界面中，对象类树默认选中的对象类Id */
  defaultSelectedObjClsId?: string
  /**对象类模板列表 */
  objClsTypes?: Array<string>
  /** 对象查询对象框的类型列表 */
  objQueryType?: Array<number>
  /**对象类模板列表 */
  objClassTypes: Array<string>
}

export enum EventEnumPartChangeType {
  /**
   * 更改零部件(新建)
   */
  changePartByCreate,

  /**
   * 更改零部件(列表)
   */
  changePartByList,

  /**
   * 转换为最新版本
   */
  changeLastVersion,

  /**
   * 转换为最新发布版本
   */
  changeLastPublishVersion,

  /**
   * 更改零部件(版本列表)
   */
  changeVersionList
}


import { ref } from 'vue'
import { defineView } from '@kmsoft/upf-core'
import { QuickSearchBarTestPropOptions, QuickSearchBarTestEventEmits } from './interface'
import QuickSearchBarTestViewModel from './QuickSearchBarTestViewModel'
import { KQuickSearchBarViewModel } from '@kmsoft/ebf-common/src/controls'

export default defineView({
  name: 'QuickSearchBarTest',
  inheritAttrs: false,
  props: QuickSearchBarTestPropOptions,
  emits: QuickSearchBarTestEventEmits,
  viewModel: QuickSearchBarTestViewModel,
  setup() {
    const refSearch1 = ref<KQuickSearchBarViewModel>()
    const refSearch2 = ref<KQuickSearchBarViewModel>()
    const refSearch3 = ref<KQuickSearchBarViewModel>()
    const refSearch4 = ref<KQuickSearchBarViewModel>()
    const activeKey = ref(['1'])
    const onSearch = (filter: any) => {
      console.log(filter)
    }
    return { activeKey, refSearch1, refSearch2, refSearch3, refSearch4, onSearch }
  }
})

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

export interface FlowApprovalContentInter {
  lifecycleState?: string
  code?: string
  name?: string
  id?: string
  class?: string
  version?: string
  clsCode?: string
  lifecycleIsChanged:boolean
}

/** 参数 **/
export const KFlowApprovalContentPropOptions = {
  ...BaseViewPropOptions,
  /** 显示工具栏 */
  showToolStrip:VuePropTypes.bool().def(true),
  /** 业务id **/
  businessId: VuePropTypes.string().def(),
  /** 流程定义key */
  processDefinitionKey:VuePropTypes.string().def()
}

/** 参数类型 **/
export type KFlowApprovalContentPropType = ViewPropsTypeExtract<typeof KFlowApprovalContentPropOptions>

/** 事件 */
export const KFlowApprovalContentEventEmits = {
  ...BaseViewEventEmits,
  /** 是否打开面板 **/
  open: (isOpen: boolean) => true
}

/** 事件类型 **/
export type KFlowApprovalContentEmitsType = ViewEmitsTypeExtract<typeof KFlowApprovalContentEventEmits>

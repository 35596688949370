
import { defineView } from '@kmsoft/upf-core'
import { KTaskEvaluatePropOptions, KTaskEvaluateEventEmits } from './interface'
import KTaskEvaluateViewModel from './KTaskEvaluateViewModel'

export default defineView({
  name: 'KTaskEvaluate',
  props: KTaskEvaluatePropOptions,
  emits: KTaskEvaluateEventEmits,
  viewModel: KTaskEvaluateViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

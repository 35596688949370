import { connect, withInstall } from '@kmsoft/upf-core'
import KPropertyGridView from './KPropertyGrid.vue'
import KPropertyGridViewModel from './KPropertyGridViewModel'

const KPropertyGrid = connect(KPropertyGridView, KPropertyGridViewModel)

export default withInstall(KPropertyGrid)
export { KPropertyGrid, KPropertyGridView, KPropertyGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

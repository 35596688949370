import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c2dda3f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "k-filter-grid-filter-item-config" }
const _hoisted_2 = { class: "k-filter-grid-filter-item-config-grid" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_tool_strip, null, {
      default: _withCtx(() => [
        _createVNode(_component_k_tool_strip_button_item, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addFilter())),
          title: "新增"
        }),
        _createVNode(_component_k_tool_strip_button_item, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteFilter())),
          title: "删除"
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_data_grid, { ref: "refGrid" }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "name",
            headerText: "名称",
            dataPropertyName: "name",
            align: "left"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "condition",
            headerText: "条件",
            width: "480",
            align: "left",
            dataPropertyName: "condition"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "operation",
            headerText: "操作"
          }, {
            default: _withCtx(({ row }) => [
              _createElementVNode("a", {
                class: "operation-button",
                onClick: ($event: any) => (_ctx.editFilter(row.id))
              }, "编辑", 8, _hoisted_3)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ])
  ]))
}
import { BaseViewModel, KControlAttribute, ViewModelOptions, utils } from '@kmsoft/upf-core'
import { KViewPropertyConfigPanelEventEmitsTypes, KViewPropertyConfigPanelPropType } from './interface'
import { BASE_CONTROL_ATTRIBUTE } from '../../../../../config'
import { ref, watch } from 'vue'
import lodash from 'lodash'

/** 导航管理 */
export default class KViewPropertyConfigPanelViewModel extends BaseViewModel<
  KViewPropertyConfigPanelEventEmitsTypes,
  KViewPropertyConfigPanelPropType
> {
  activeKeys = ref(['common', 'base', 'layout', 'prop'] as Array<string>)

  localData = ref({} as Record<string, any>)
  /** 基础属性 */
  baseConfig: Array<KControlAttribute> = lodash.cloneDeep(BASE_CONTROL_ATTRIBUTE)
  /**选中控件值的集合 */
  attrsValueItem = ref(this.props.attributeValueItem)
  controlType = ref(this.props.control.control)

  constructor(options: ViewModelOptions<KViewPropertyConfigPanelPropType>) {
    super(options)
  }
}

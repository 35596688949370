
import { defineView } from '@kmsoft/upf-core'
import { KChangeActivityUpdateCompareGridDiffPropOptions, KChangeActivityUpdateCompareGridDiffEventEmits } from './interface'
import KChangeActivityUpdateCompareGridDiffViewModel from './KChangeActivityUpdateCompareGridDiffViewModel'

export default defineView({
  name: 'KChangeActivityUpdateCompareGridDiff',
  props: KChangeActivityUpdateCompareGridDiffPropOptions,
  emits: KChangeActivityUpdateCompareGridDiffEventEmits,
  viewModel: KChangeActivityUpdateCompareGridDiffViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import {
  BaseViewModel,
  EnumDialogResult,
  IQuery,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KIcon,
  KModal,
  KNotification,
  PageManager,
  ViewModelOptions,
  utils
} from '@kmsoft/upf-core'
import { createVNode, ref } from 'vue'
import { IWorkspace, KWorkspaceListEmitsType, KWorkspaceListPropType } from './interface'

import { Api, EnumRequestCode, QueryCondition, EnumPurviewCode } from '@kmsoft/ebf-common'
import { KProductWorkspace } from '..'
import { KProductWorkspaceForm, KProductWorkspaceFormViewModel } from '../form/product-workspace-form'
import { UserClientSrv } from '../../../../client-srv'

/** KWorkspaceList */
export default class KWorkspaceListViewModel extends BaseViewModel<KWorkspaceListEmitsType, KWorkspaceListPropType> {
  /**工作区列表网格 */
  refWorkspaceGrid = ref<KDataGridViewModel>()
  /**工作区名称 */
  workspaceName = ref<string>()

  constructor(options: ViewModelOptions<KWorkspaceListPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 初始化工作区网格
   */
  async loadData(qry: IQuery) {
    const param = {
      data: [
        {
          filter: {},
          isNeedTotal: true,
          sort: 'DESC',
          orderBy: 'createTime'
        },
        {
          maxPageSize: 1000,
          curPage: qry.page?.pageIndex,
          pageSize: 50,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    if (this.workspaceName.value && this.workspaceName.value != '') {
      param.data[0].filter = {
        conditions: [
          {
            conditionName: 'name',
            operator: 'like',
            conditionValues: [this.workspaceName.value]
          }
        ],
        joiner: 'and',
        ignoreStr: true
      } as QueryCondition
    }

    const result = await Api.post('folder', 'Folder', 'listProductFolder', param)
    if (result && result.code == EnumRequestCode.SUCCESS && result.data.data) {
      return {
        rows: result.data.data,
        total: result.data.page.totalRows
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 刷新网格数据
   */
  refresh() {
    this.refWorkspaceGrid.value?.refresh()
  }
  /**
   * 创建产品工作区
   */
  create() {
    KDialog.show({
      title: '创建产品库',
      size: { width: 480, height: 360 },
      content: KProductWorkspaceForm,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
          return
        }
        /** 当前组件 */
        const formViewModel = event.viewModel as KProductWorkspaceFormViewModel

        /** 获取表单值 */
        const formValue = formViewModel.getFormValue()
        const param = {
          data: [
            {
              ...formValue
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'createProductWorkspace', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refWorkspaceGrid.value?.insertRow(result.data!)
        } else {
          KNotification.warn({
            message: '创建产品库异常',
            description: result.message
          })
          event.cancel = true
        }
      }
    })
  }
  openProductWorkspace(row: IWorkspace) {
    if (UserClientSrv.verifyPurview(EnumPurviewCode.OpenProductLibrary)) {
      PageManager.openPage(utils.uuid(), `产品库（` + row.name + `)`, KProductWorkspace, {
        workspaceId: row.id,
        workspaceName: row.name
      })
    } else {
      KNotification.warn({
        message: '权限不足',
        description: '您没有打开产品库的权限'
      })
    }
    return
  }

  /**
   * 更新产品产品库
   */
  update(row: any) {
    KDialog.show({
      title: '编辑产品库',
      size: { width: 480, height: 360 },
      props: { workspace: row },
      content: KProductWorkspaceForm,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
          return
        }
        /** 当前组件 */
        const formViewModel = event.viewModel as KProductWorkspaceFormViewModel

        /** 获取表单值 */
        const formValue = formViewModel.getFormValue()
        const param = {
          data: [
            {
              ...formValue
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'updateProductWorkspace', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refWorkspaceGrid.value?.refreshRow(result.data!)
        } else {
          KNotification.warn({
            message: '修改产品库异常',
            description: result.message
          })
          event.cancel = true
        }
      }
    })
  }

  delete(id: string) {
    KModal.delete({
      title: '产品库删除提示',
      icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
      content: '您正在进行删除操作，请确认是否删除',
      onOk: () => {
        const param = {
          data: [[id]]
        }
        Api.post('folder', 'Folder', 'batchDeleteProductWorkspace', param).then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS) {
            this.refWorkspaceGrid.value?.removeRow(id)
            KNotification.success({
              title: '系统提示',
              content: '产品库删除成功'
            })
          } else {
            KNotification.warn({
              message: '删除失败',
              description: res.message
            })
            return
          }
        })
      }
    })
  }

  search() {
    this.refresh()
  }
}

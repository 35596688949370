import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KAgentDownloadEmitsType, KAgentDownloadPropType } from './interface'

/** KAgentDownload */
export default class KAgentDownloadViewModel extends BaseViewModel<KAgentDownloadEmitsType, KAgentDownloadPropType> {
  constructor(options: ViewModelOptions<KAgentDownloadPropType>) {
    super(options)
  }

  viewDidMount() {}
}

import { VuePropTypes, defineExtendView } from '@kmsoft/upf-core'
import { KObjectRelationGridPropOptions, KObjectRelationGridEventEmits } from './interface'
import KObjectClassGrid, { EnumObjClassManageToolStripOptions } from '../object-class-grid'
import { ToolStripHelper } from '../toolstrip'
import { ObjectToolStripItem } from '../object-tool-strip'

KObjectRelationGridPropOptions.toolStripOptions = VuePropTypes.createType<EnumObjClassManageToolStripOptions>().def(
  EnumObjClassManageToolStripOptions.None
)
KObjectRelationGridPropOptions.toolStripItems = VuePropTypes.createType<ObjectToolStripItem[]>().def(
  ToolStripHelper.getRelationToolStrip()
)

export default defineExtendView({
  name: 'KObjectRelationGrid',
  props: KObjectRelationGridPropOptions,
  emits: KObjectRelationGridEventEmits,
  parent: KObjectClassGrid
})


import { defineView } from '@kmsoft/upf-core'
import { KChangeRankingPropOptions, KChangeRankingEventEmits } from './interface'
import KChangeRankingViewModel from './KChangeRankingViewModel'

export default defineView({
  name: 'KChangeRanking',
  props: KChangeRankingPropOptions,
  emits: KChangeRankingEventEmits,
  viewModel: KChangeRankingViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

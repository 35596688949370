import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KGridDesignerElement } from '../../types'

/** KGridDesignerPreview 事件 **/
export const KGridDesignerPreviewEventEmits = {
  ...BaseViewEventEmits,
  /**元素选中事件 */
  elementSelected: (elements: KGridDesignerElement) => true,
  /**元素删除事件 */
  elementDelete: (elements: KGridDesignerElement) => true,
  /**
   * 元素拖入事件
   * @param elementName 元素名
   */
  elementDragIn: (elementName: string) => true,
  /**元素change事件 */
  elementChange: (elements: KGridDesignerElement) => true
}

/** KGridDesignerPreview 参数类型 **/
export type KGridDesignerPreviewPropType = ViewPropsTypeExtract<typeof KGridDesignerPreviewPropOptions>

/** KGridDesignerPreview 参数 **/
export const KGridDesignerPreviewPropOptions = {
  ...BaseViewPropOptions,
  /**布局元素 */
  elements: VuePropTypes.array<KGridDesignerElement>()
    .setRequired()
    .def([]),
  /** 选中的元素 */
  selectedElement: VuePropTypes.createType<KGridDesignerElement | undefined>().def(),
  /**是否为分组容器 */
  isGroupElement: VuePropTypes.bool().def(false)
}

/** KGridDesignerPreview 事件协议*/
export type KGridDesignerPreviewEventEmitsType = ViewEmitsTypeExtract<typeof KGridDesignerPreviewEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KCommonFolderManageView from './KCommonFolderManage.vue'
import KCommonFolderManageViewModel from './KCommonFolderManageViewModel'

const KCommonFolderManage = connect(KCommonFolderManageView, KCommonFolderManageViewModel)

export default withInstall(KCommonFolderManage)
export { KCommonFolderManage, KCommonFolderManageView, KCommonFolderManageViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { ObjBusinessParam, ObjectPanelAttachParams } from '@kmsoft/ebf-common'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KDocRelationObjPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  activeKey: VuePropTypes.createType<Array<string>>().def([]),
  tabId: VuePropTypes.string().def(''),
  /** 附属参数 */
  attachParams: VuePropTypes.createType<ObjectPanelAttachParams>().def({})
}

/** 参数类型 **/
export type KDocRelationObjPropType = ViewPropsTypeExtract<typeof KDocRelationObjPropOptions>

/** 事件 */
export const KDocRelationObjEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDocRelationObjEmitsType = ViewEmitsTypeExtract<typeof KDocRelationObjEventEmits>
export interface IDocRelationDefinition {
  // title
  title: string
  key: string
}

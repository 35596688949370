import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectFileUploadView from './KObjectFileUpload.vue'
import KObjectFileUploadViewModel from './KObjectFileUploadViewModel'

const KObjectFileUpload = connect(KObjectFileUploadView, KObjectFileUploadViewModel)

export default withInstall(KObjectFileUpload)
export { KObjectFileUpload, KObjectFileUploadView, KObjectFileUploadViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView, EnumButtonStyle } from '@kmsoft/upf-core'
import { KSelectEditorFormViewModel } from './KSelectEditorFormViewModel'
import { KSelectEditorCustomPropOptions } from './interface'

export default defineView({
  name: 'SelectEditorForm', // 组件名称
  viewModel: KSelectEditorFormViewModel,
  props: KSelectEditorCustomPropOptions,
  setup(props, ctx) {
    return {
      /** 自定义options方式的网格columns */
      columns: [
        { name: 'label', title: '显示值' },
        { name: 'value', title: '取值' }
      ],
      EnumButtonStyle
    }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectSearchGridView from './KObjectSearchGrid.vue'
import KObjectSearchGridViewModel from './KObjectSearchGridViewModel'

const KObjectSearchGrid = connect(KObjectSearchGridView, KObjectSearchGridViewModel)

export default withInstall(KObjectSearchGrid)
export { KObjectSearchGrid, KObjectSearchGridView, KObjectSearchGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KPersonalCreateChangeRequestGridView from './KPersonalCreateChangeRequestGrid.vue'
import KPersonalCreateChangeRequestGridViewModel from './KPersonalCreateChangeRequestGridViewModel'

const KPersonalCreateChangeRequestGrid = connect(KPersonalCreateChangeRequestGridView, KPersonalCreateChangeRequestGridViewModel)

export default withInstall(KPersonalCreateChangeRequestGrid)
export { KPersonalCreateChangeRequestGrid, KPersonalCreateChangeRequestGridView, KPersonalCreateChangeRequestGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

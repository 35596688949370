import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    name: "PageForm",
    "label-col": { span: 8 },
    "wrapper-col": { span: 16 },
    autocomplete: "off"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, {
        label: "名称",
        name: "name"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formState.name,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formState.name) = $event))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, {
        label: "代号",
        name: "symbol"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formState.symbol,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formState.symbol) = $event))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, {
        label: "代号后缀",
        name: "suffix"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formState.suffix,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.formState.suffix) = $event))
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
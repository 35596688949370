/** 生命周期属性 */
export const LIFECYCLE_PROP = {
  CREATOR: 'creator',
  CREATOR_NAME: 'creatorname',
  CREATE_TIME: 'createtime',
  LAST_MODIFY_TIME: 'clastmodifytime',
  MODIFY_TIME: 'modifytime',
  MODIFIER: 'modifyer',
  MODIFIER_NAME: 'modifyername',
  PUBLISH_TIME: 'publishtime',
  CHECKOUT_TIME: 'checkouttime',
  STATE: 'state'
}

/** 系统定义列名 */
export const SYS_DEF_PROP = {
  NAME: 'name',
  SYMBOL: 'symbol',
  SUFFIX: 'suffix',
  PRODUCT_TYPE: 'productType',
  PROCESS_ROUTE: 'processRoute',
  MATERIAL_NOM: 'materialnom',
  IS_FAMILY: 'partFamily',
  SAFE_LEVEL: 'safeLevel',
  PART_EXTENDPROPERTY: 'extendProperty'
}

import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'

/** 参数 **/
export const KWorkspaceListPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KWorkspaceListPropType = ViewPropsTypeExtract<typeof KWorkspaceListPropOptions>

/** 事件 */
export const KWorkspaceListEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KWorkspaceListEmitsType = ViewEmitsTypeExtract<typeof KWorkspaceListEventEmits>

export interface IWorkspace {
  /** id */
  id: string
  name: string
  remark: string
  /** 工作区类型 */
  type: number
  createId: string
  createTime: string
  /** 所有者Id */
  ownerId: string
  /** 所有者名称 */
  ownerName: string
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KTreeGridDemoPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KTreeGridDemoPropType = ViewPropsTypeExtract<typeof KTreeGridDemoPropOptions>

/** 事件 */
export const KTreeGridDemoEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KTreeGridDemoEmitsType = ViewEmitsTypeExtract<typeof KTreeGridDemoEventEmits>

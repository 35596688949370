
import { defineComponent, ref } from 'vue'
import { theme } from 'ant-design-vue'
import { VuePropTypes, AppContext, NavigationMenuGroup } from '@kmsoft/upf-core'
import KNavigationBar from './KNavigationBar'

export default defineComponent({
  name: 'KExtendLayoutSidebar',
  components: {
    KNavigationBar
  },
  props: {
    theme: VuePropTypes.string().def('dark'),
    menus: VuePropTypes.array().def(),
    menuActiveKey: VuePropTypes.string().def(),
    isMenusVisible: VuePropTypes.bool().def(true)
  },
  setup(props) {
    let { token } = theme.useToken()
    const environment = AppContext.current.getEnvironment()

    return {
      appName: environment.getAppName(),
      collapseBtnColor: '#ffffff',
      menusRef: ref<NavigationMenuGroup[]>(props.menus as NavigationMenuGroup[]),
      globalStyleObj: ref<any>(token)
    }
  },
  data() {
    return {
      collapsed: false
    }
  },
  methods: {
    onCollapse() {
      this.collapsed = true
    },
    onExpand() {
      this.collapsed = false
    }
  }
})

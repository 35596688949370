import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KChangeStatisticsEmitsType, KChangeStatisticsPropType } from './interface'
import { computed, ref, watch } from 'vue'
import * as echarts from 'echarts'
import ResizeObserver from 'resize-observer-polyfill'

/** KChangeStatistics */
export default class KChangeStatisticsViewModel extends BaseViewModel<KChangeStatisticsEmitsType, KChangeStatisticsPropType> {
  selectValue = ref<string>(this.props.selectValue)
  refChart = ref()

  constructor(options: ViewModelOptions<KChangeStatisticsPropType>) {
    super(options)
    watch(
      () => this.props.yData,
      () => {
        this.chart.setOption(this.option.value)
      }
    )
  }

  chart: echarts.ECharts

  option = computed(() => {
    return {
      tooltip: {
        trigger: 'axis',
        formatter: '{b}：{c}'
      },
      grid: {
        bottom: 20
      },
      xAxis: {
        data: this.props.xData
      },
      yAxis: {},
      series: [
        {
          type: this.props.echartsType,
          data: this.props.yData,
          barMaxWidth: 20,
          ...this.props.echartsOption
        }
      ]
    }
  })

  viewDidMount() {
    this.init()
  }

  init() {
    this.chart = echarts.init(this.refChart.value)
    this.chart.setOption(this.option.value)
    const resizeOb = new ResizeObserver(() => this.chart.resize())
    resizeOb.observe(this.refChart.value)
  }
}

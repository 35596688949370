import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0095e64b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%","height":"100%","padding":"5px","display":"flex","flex-direction":"column"} }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = { style: {"width":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_config_value_tile = _resolveComponent("config-value-tile")!
  const _component_config_grid = _resolveComponent("config-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$vm.configList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.code,
        class: "config-item"
      }, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_config_value_tile, {
            ref_for: true,
            ref: "refSysConfigValueTileView",
            defId: item.defId,
            treeId: item.id,
            code: item.code,
            editType: item.editType,
            editParam: item.editParam,
            oper: "view",
            mode: _ctx.mode,
            onUpdateConfigValidStatus: _cache[0] || (_cache[0] = (validStatus) => _ctx.$vm.updateConfigValidStatus(validStatus))
          }, null, 8, ["defId", "treeId", "code", "editType", "editParam", "mode"])
        ], 512), [
          [_vShow, item.dataType === _ctx.EnumDataType.VALUE]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createVNode(_component_config_grid, {
            ref_for: true,
            ref: "refSysConfigGridTileView",
            defId: item.defId,
            treeId: item.id,
            code: item.code,
            editType: item.editType,
            editParam: item.editParam,
            oper: "view",
            mode: _ctx.mode,
            onUpdateConfigValidStatus: _cache[1] || (_cache[1] = (validStatus) => _ctx.$vm.updateConfigValidStatus(validStatus))
          }, null, 8, ["defId", "treeId", "code", "editType", "editParam", "mode"])
        ], 512), [
          [_vShow, item.dataType === _ctx.EnumDataType.GRID]
        ])
      ]))
    }), 128))
  ]))
}
import { BaseViewModel } from '@kmsoft/upf-core'
import { KSysTabEventEmitsType, KSysTabPropType } from './interface'
import KSysConfigTreeViewModel from '../sys-config-tree/KSysConfigTreeViewModel'
import KConfigValueViewModel from '../sys-config-value/KConfigValueViewModel'
import { EnumNodeType, EnumConfigTabType, EnumTabType, EnumDataType } from '../../interface'
import { UpdateConfigValidStatusEventArgs } from '../sys-config-value/interface'
import KConfigGridViewModel from '../sys-config-grid/KConfigGridViewModel'
import KConfigCustomViewModel from '../../common/config-custom/KConfigCustomViewModel'
import KConfigTileViewModel from '../sys-config-tile/KConfigTileViewModel'
import { RefreshConfigValuePanelEventArgs } from '../sys-config-tree/interface'
import { ref } from 'vue'

export default class KSysTabViewModel extends BaseViewModel<KSysTabEventEmitsType, KSysTabPropType> {
  // 系统配置树
  refSysConfigTreeView = ref<KSysConfigTreeViewModel>()
  // 配置项单值维护
  refSysConfigValueView = ref<KConfigValueViewModel>()
  // 配置项集合维护
  refSysConfigGridView = ref<KConfigGridViewModel>()
  // 配置项自定义维护
  refSysConfigCustomView = ref<KConfigCustomViewModel>()
  // 配置项平铺查看
  refSysConfigTileView = ref<KConfigTileViewModel>()

  /** 明细显示标记(1表示单值配置项, 2表示集合配置项) */
  detailTag = ref<string>('0')
  tagValue = ref<boolean>(false)
  tagGrid = ref<boolean>(false)
  tagCustom = ref<boolean>(false)
  tagTile = ref<boolean>(false)
  tagObj = ref<boolean>(false)
  mode = ref<string>('')

  /**
   * 加载完成函数
   */
  viewDidMount() {
    this.mode.value = EnumTabType.SYS
  }

  /**
   * 树节点点击事件
   * @param args 参数(树节点对象)
   */
  public refreshConfigValuePanel(args: RefreshConfigValuePanelEventArgs) {
    const node = args.node
    // 配置项
    if (node.type === EnumNodeType.SYS_CONFIG) {
      // 单值
      if (node.dataType === EnumDataType.VALUE) {
        this.detailTag.value = EnumConfigTabType.VALUE
        this.tagValue.value = true
        this.tagGrid.value = false
        this.tagCustom.value = false
        this.tagTile.value = false

        this.refSysConfigValueView.value!.init(node.defId, node.id)
      }
      // 集合
      if (node.dataType === EnumDataType.GRID) {
        this.detailTag.value = EnumConfigTabType.GRID
        this.tagValue.value = false
        this.tagGrid.value = true
        this.tagCustom.value = false
        this.tagTile.value = false

        this.refSysConfigGridView.value!.init(node.defId, node.id, node.code, node.editType, node.editParam, 'edit')
      }
      // 自定义
      if (node.dataType === EnumDataType.CUSTOM) {
        this.detailTag.value = EnumConfigTabType.CUSTOM
        this.tagValue.value = false
        this.tagGrid.value = false
        this.tagCustom.value = true
        this.tagTile.value = false

        this.refSysConfigCustomView.value!.init(node.defId, node.id, node.code, node.editType, node.editParam)
      }
    } else if (node.type === EnumNodeType.SYS_MODULE || node.type === EnumNodeType.SYS_FOLDER) {
      // 模块/目录
      this.detailTag.value = EnumConfigTabType.TILE
      this.tagValue.value = false
      this.tagGrid.value = false
      this.tagCustom.value = false
      this.tagTile.value = true

      this.refSysConfigTileView.value!.init(node.id, node.code)
    } else {
      // 其它(如: 根节点)
      this.detailTag.value = ''
      this.tagValue.value = false
      this.tagGrid.value = false
      this.tagCustom.value = false
      this.tagTile.value = false
    }
  }

  /**
   * 更新配置项(模块)有效性状态
   * @param validStatus 有效性状态
   */
  public updateConfigValidStatus(validStatus: UpdateConfigValidStatusEventArgs): void {
    this.refSysConfigTreeView.value!.updateConfigValidStatus(validStatus.validStatus)
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KObjectRelationPropOptions, KObjectRelationEventEmits } from './interface'
import KObjectRelationViewModel from './KObjectRelationViewModel'
import { KObjectRelationGrid } from '../object-relation-grid'

export default defineView({
  name: 'KObjectRelation',
  props: KObjectRelationPropOptions,
  emits: KObjectRelationEventEmits,
  viewModel: KObjectRelationViewModel,
  components: { KObjectRelationGrid }
})

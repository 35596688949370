import {
  BaseViewModel,
  TreeViewCheckedEventArgs,
  TreeViewSelectEventArgs,
  ViewModelOptions,
  EnumTreeViewNodeRefreshType
} from '@kmsoft/upf-core'
import { KDocStructureManageEmitsType, KDocStructureManagePropType, StructureFilterOption } from './interface'
import { computed, ref, watch } from 'vue'
import { DocClientSrv, DocFilterResult, EnumFilterType, EnumPartNodeChildExpandMode, StructureView } from '../../client-srv'
import {
  EnumObjTabPageId,
  ObjectPanelDataUpdatedEvent,
  KObjectPanelViewModel,
  ObjBusinessParam,
  TREE_VIEW_ROOT_NODE_KEY,
  ObjectClientSrv,
  ClassMetaClientSrv
} from '@kmsoft/ebf-common'
import { DocStructureTreeNode } from '../doc-structure-tree/node/DocStructureTreeNode'
import { KDocStructureTreeViewModel } from '../doc-structure-tree'

/** KDocStructureManage */
export default class KDocStructureManageViewModel extends BaseViewModel<
  KDocStructureManageEmitsType,
  KDocStructureManagePropType
> {
  /** 文档结构树引用 */
  refDocStructureTree = ref<KDocStructureTreeViewModel>()
  /** 对象面板 */
  refObjectPanel = ref<KObjectPanelViewModel>()

  /** 过滤条件 */
  structureFilterOptions = ref<Array<StructureFilterOption>>([])
  /** 过滤名称 */
  structureFilterName = ref<string>()
  /** 文档过滤规则 */
  filterResult: DocFilterResult = {
    searchCondition: '',
    filterType: EnumFilterType.None,
    results: []
  }
  /** 视图名称 */
  structureViewName = ref<string>('')
  /** 视图模式 */
  docStructureViewMode = computed(() => {
    return this.structureViews.value.find(a => a.viewName == this.structureViewName.value)
  })
  /** 视图列表 */
  structureViews = ref<Array<StructureView>>([])
  /** 视图下拉列表 */
  structureViewOptions = computed(() => {
    return this.structureViews.value.map(a => {
      return { label: a.viewName, value: a.viewName }
    })
  })

  /** 节点展开模式 */
  nodeChildExpandMode = ref<EnumPartNodeChildExpandMode>()

  //#region 控制属性
  /** 是否正在变更选择项 */
  private selectionChanging = false
  /** 是否允许选择 */
  private allowSelectionChange = false
  //#endregion

  //#region 对象参数
  /** 结构树根对象参数 */
  rootObjParam = ref<ObjBusinessParam>()
  /** 当前选择的对象 */
  selectObjParam = ref<ObjBusinessParam>()
  /** 选中的树节点 */
  selectedNode = ref<DocStructureTreeNode>()
  //#endregion

  //#region 对外组件API
  /** 文档结构树 */
  get docStructureTree() {
    return this.refDocStructureTree.value
  }

  /** 对象面板 */
  get objectPanel() {
    return this.refObjectPanel.value
  }
  //#endregion

  //#region 界面控制
  /** 是否初始化完成 */
  isInitialized = ref<boolean>(false)
  //#endregion
  constructor(options: ViewModelOptions<KDocStructureManagePropType>) {
    super(options)
    this.rootObjParam.value = options.props.objParam
    this.selectObjParam.value = options.props.objParam
    // 监听对象参数
    watch(
      () => options.props.objParam,
      async (newValue, oldValue) => {
        this.rootObjParam.value = newValue
        if (oldValue) {
          await this.refresh()
        } else {
          await this.init()
        }
      },
      {
        immediate: true
      }
    )

    // // 节点展开模式
    // watch(this.nodeChildExpandMode, newValue => {
    //   this.emit('update:nodeChildExpandMode', newValue!)
    // })
    // 监听版本规则选择
    watch(
      this.structureViewName,
      async newValue => {
        this.onStructureViewChange(newValue)
      },
      {
        immediate: true
      }
    )
    // 监听过滤条件
    watch(this.structureFilterName, async newValue => {
      // 获取过滤结果
      await this.getFilterResult()

      // 刷新树
      await this.refDocStructureTree.value?.treeView?.refresh()

      // 选中默认节点
      this.selectRootNode()
    })
  }

  viewDidMount() {}

  /**
   * 初始化
   */
  async init() {
    if (!this.props.objParam) {
      return
    }

    // 加载完成
    this.isInitialized.value = false

    // 加载过滤器
    await this.initFilter()

    // 加载完成
    this.isInitialized.value = true
  }
  /**
   * 初始化过滤器
   */
  async initFilter() {
    // API
    this.structureViews.value = [{ viewName: '最新版本' }]

    /** 默认视图 */
    let defaultViewName = ''
    if (this.props.viewName) {
      defaultViewName = this.props.viewName
    } else {
      const expandMode = EnumPartNodeChildExpandMode.LatestVersion
      defaultViewName = DocClientSrv.getPartViewModeViewName(expandMode)
    }

    // API
    this.structureFilterOptions.value = [{ label: '??', value: '' }]

    this.structureViewName.value = defaultViewName
  }
  /**
   * 重载
   */
  async refresh() {
    await this.init()
  }
  /** 选中根节点 */
  selectRootNode() {
    this.refDocStructureTree.value?.treeView?.setSelectedNode(TREE_VIEW_ROOT_NODE_KEY)
  }
  /** 节点选中后事件 */
  onTreeAfterCheck(event: TreeViewCheckedEventArgs) {
    this.emit('afterTreeCheck', event)
  }

  /** 节点刷新 */
  onNodeRefresh() {
    this.refObjectPanel.value?.refresh()
  }
  /**
   * 获取过滤结果
   * @returns
   */
  async getFilterResult() {
    // 如果没有选中过滤条件
    if (!this.structureFilterName.value) {
      this.filterResult.filterType = EnumFilterType.None
      return
    }

    // 获取根节点
    const rootNode = this.refDocStructureTree.value?.treeView?.getRootNodes() as Array<DocStructureTreeNode>

    // const parentId = rootNode[0].partId
    // const parentClsId = rootNode[0].partObjClsId

    // filterResult = await DataSrv.partStructureSrv.getFilterResult({
    //   objClsID: parentClsId,
    //   objID: parentId,
    //   filterName: structureFilterName.value,
    //   viewName: structureViewName.value
    // })
  }
  /**
   * 节点选择前事件
   * @param event
   */
  async onBeforeNodeSelect(event: TreeViewSelectEventArgs) {
    if (!event.node) {
      return
    }

    // 取消选择
    event.cancel = !this.allowSelectionChange

    // 如果正在选择
    if (this.selectionChanging) {
      return
    }

    this.selectionChanging = true

    try {
      /** 保存更改 */
      const saveResult = await this.refObjectPanel.value?.saveChanges()

      // 如果取消
      if (saveResult && saveResult.cancel) {
        return
      }

      this.allowSelectionChange = true

      try {
        this.refDocStructureTree.value?.treeView?.setSelectedNode(event.node.key!)
      } finally {
        this.allowSelectionChange = false
      }
    } finally {
      this.selectionChanging = false
    }
  }
  /**
   * 版本规则改变
   * @param newValue
   * @returns
   */
  async onStructureViewChange(newValue: string) {
    if (!this.docStructureViewMode.value) {
      return
    }

    // 获取展开模式
    this.nodeChildExpandMode.value = DocClientSrv.getPartNodeChildExpandMode(this.docStructureViewMode.value.viewName)

    // 如果是Bom视图
    if (this.docStructureViewMode.value.baselineContents) {
      if (this.docStructureViewMode.value.baselineContents!.length <= 0) {
        return
      }
      this.rootObjParam.value = this.docStructureViewMode.value.baselineContents.find(
        _ => _.masterId == this.props.objParam.masterId
      )!
      this.selectObjParam.value = {
        modelCode: this.selectObjParam.value!.modelCode,
        id: this.docStructureViewMode.value.baselineContents[0].id,
        modelGroup: this.selectObjParam.value!.modelGroup
      }
    } else {
      this.rootObjParam.value = this.props.objParam
      this.selectObjParam.value = this.props.objParam
    }

    // 获取过滤结果
    await this.getFilterResult()

    // 刷新树
    await this.refDocStructureTree.value?.treeView?.refresh(TREE_VIEW_ROOT_NODE_KEY, true)

    // 选中默认节点
    this.selectRootNode()

    this.emit('update:viewName', newValue)
  }

  /** 树加载完成以后 */
  async onTreeReady() {
    // 选择根节点
    this.selectRootNode()

    // 使用灵活队列的方式显示数据
    setTimeout(() => {
      // 不使用树默认展开功能，频繁加载大量数据会导致控件卡顿
      const rootNodes = this.refDocStructureTree.value?.treeView?.getRootNodes()
      if (rootNodes) {
        this.refDocStructureTree.value?.treeView?.expandLevel(rootNodes, 0)
      }
    }, 0)

    // 抛出树就绪事件
    this.emit('treeReady', this.refDocStructureTree.value!)
  }

  /** 节点选择事件 */
  async onAfterSelect(event: TreeViewSelectEventArgs<DocStructureTreeNode>) {
    const node = event.node

    // 保存选中的树节点
    this.selectedNode.value = node
    //获取设计态编码
    node.parentDocObjParam.modelGroup = await ObjectClientSrv.getModelGroupByCode(node.parentDocObjParam.modelCode)

    this.selectObjParam.value = {
      modelCode: node.docObjParam.modelCode,
      id: node.docObjParam.id,
      modelGroup: node.docObjParam.modelGroup
    }

    this.emit('afterTreeSelect', event)
  }
  /**
   * 对象面板更新事件
   * @param event
   */
  async onObjectPanelDataUpdated(event: ObjectPanelDataUpdatedEvent) {
    if (event.tabId == EnumObjTabPageId.StructureList || event.tabId == EnumObjTabPageId.Property) {
      /** 获取选中的节点 */
      const selectedNode = this.refDocStructureTree.value?.treeView?.getSelectedNode() as DocStructureTreeNode
      /** 刷新选择的节点 */
      if (selectedNode) {
        await this.refDocStructureTree.value?.refreshNode(
          selectedNode,
          event.tabId == EnumObjTabPageId.StructureList
            ? EnumTreeViewNodeRefreshType.NODE_CHILD
            : EnumTreeViewNodeRefreshType.SELF
        )
      }
    }
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KPropertySettingsFromView from './KPropertySettingsFrom.vue'
import KPropertySettingsFromViewModel from './KPropertySettingsFromViewModel'

const KPropertySettingsFrom = connect(KPropertySettingsFromView, KPropertySettingsFromViewModel)

export default withInstall(KPropertySettingsFrom)
export { KPropertySettingsFrom, KPropertySettingsFromView, KPropertySettingsFromViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { BaseViewModel, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { AddFilePropertyReadOption } from '../../interface'
import { KDocTemplateEmitsType, KDocTemplatePropType } from './interface'

/** KDocFileProperty */
export default class KDocTemplateViewModel extends BaseViewModel<KDocTemplateEmitsType, KDocTemplatePropType> {
  model = ref<AddFilePropertyReadOption>({
    isProperties: false,
    isStruct: false,
    isCascade: false
  })

  /** 网格控件 */
  refDataGrid = ref<KDataGridViewModel>()
  /** 分页 */
  pagination = { pageIndex: 1, pageSize: 100 }

  constructor(options: ViewModelOptions<KDocTemplatePropType>) {
    super(options)
  }

  /**
   * 刷新关系定义网格
   */
  refreshClassRelationGrid() {
    this.refDataGrid.value?.refresh()
    this.refDataGrid.value?.clearSelectedRows()
  }

  viewDidMount() {}

  async loadData() {
    const result = await Api.post('sys', 'ConfigSysQueryService', 'listGridConfigByCode', {
      data: ['SYS_DOC_TEMPLATE_STORE']
    })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const filteredData = result.data.filter((item: { documentType: string }) => item.documentType == this.props.documentType)
      return {
        rows: filteredData,
        total: filteredData.length
      }
    }
  }

  getSelectedRows() {
    return this.refDataGrid.value?.getSelectedRows() || []
  }
}

/** URL帮助方法 */
export class UrlHelper {
  /**
   * 获取URL参数
   * @param variable
   * @returns
   */
  static getQueryVariable(variable: string): string | undefined {
    let query = ''

    // 如果是标准query
    if (window.location.search) {
      query = window.location.search.substring(1)
    } else if (window.location.hash) {
      query = window.location.hash.split('?')[1]
    } else {
      query = window.location.href.split('?')[1]
    }

    if (!query) {
      return undefined
    }

    /** 拆分参数 */
    const vars = query.split('&')
    for (const queryItem of vars) {
      const pair = queryItem.split('=')
      if (pair[0] == variable) {
        return pair[1]
      }
    }
    return undefined
  }

  /**
   * 获取当前地址
   * @param template
   * @returns
   */
  static getCurrentUrl(template: string) {
    const protocol = window.location.protocol.replace(':', '') // http
    const host = window.location.host // www.xxx.com
    const host_name = window.location.hostname // www
    const port = window.location.port // 80
    const path_name = window.location.pathname // /path/path/page

    const paramsArray: Record<string, any> = {
      PROTOCOL: protocol,
      HOST: host,
      HOST_NAME: host_name,
      PORT: port,
      PATH_NAME: path_name
    }

    let newUrl = template

    for (const key in paramsArray) {
      newUrl = newUrl.replaceAll(`{${key}}`, paramsArray[key])
    }

    return newUrl
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KCodeGeneratorView from './KCodeGenerator.vue'
import KCodeGeneratorViewModel from './KCodeGeneratorViewModel'

const KCodeGenerator = connect(KCodeGeneratorView, KCodeGeneratorViewModel)

export default withInstall(KCodeGenerator)
export { KCodeGenerator, KCodeGeneratorView, KCodeGeneratorViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%","padding":"5px","display":"flex","flex-direction":"column"} }
const _hoisted_2 = { style: {"height":"100%"} }
const _hoisted_3 = { style: {"height":"100%"} }
const _hoisted_4 = { style: {"height":"100%"} }
const _hoisted_5 = { style: {"height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sys_config_tree = _resolveComponent("sys-config-tree")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_config_value = _resolveComponent("config-value")!
  const _component_config_grid = _resolveComponent("config-grid")!
  const _component_config_custom = _resolveComponent("config-custom")!
  const _component_config_tile = _resolveComponent("config-tile")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createBlock(_component_k_split_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_pane, {
        size: 15,
        minSize: 10,
        maxSize: 30
      }, {
        default: _withCtx(() => [
          _createVNode(_component_sys_config_tree, {
            ref: _ctx.$vm.refSysConfigTreeView,
            type: "1",
            onRefreshConfigValuePanel: _cache[0] || (_cache[0] = (node) => _ctx.$vm.refreshConfigValuePanel(node))
          }, null, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_k_split_pane, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createElementVNode("div", _hoisted_2, [
              _createVNode(_component_config_value, {
                ref: _ctx.$vm.refSysConfigValueView,
                mode: _ctx.$vm.mode,
                onUpdateConfigValidStatus: _cache[1] || (_cache[1] = (validStatus) => _ctx.$vm.updateConfigValidStatus(validStatus))
              }, null, 8, ["mode"])
            ], 512), [
              [_vShow, _ctx.$vm.tagValue]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_3, [
              _createVNode(_component_config_grid, {
                ref: _ctx.$vm.refSysConfigGridView,
                mode: _ctx.$vm.mode,
                onUpdateConfigValidStatus: _cache[2] || (_cache[2] = (validStatus) => _ctx.$vm.updateConfigValidStatus(validStatus))
              }, null, 8, ["mode"])
            ], 512), [
              [_vShow, _ctx.$vm.tagGrid]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createVNode(_component_config_custom, {
                ref: _ctx.$vm.refSysConfigCustomView,
                mode: _ctx.$vm.mode
              }, null, 8, ["mode"])
            ], 512), [
              [_vShow, _ctx.$vm.tagCustom]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_5, [
              _createVNode(_component_config_tile, {
                ref: _ctx.$vm.refSysConfigTileView,
                mode: _ctx.$vm.mode,
                onUpdateConfigValidStatus: _cache[3] || (_cache[3] = (validStatus) => _ctx.$vm.updateConfigValidStatus(validStatus))
              }, null, 8, ["mode"])
            ], 512), [
              [_vShow, _ctx.$vm.tagTile]
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
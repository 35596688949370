
import { defineView } from '@kmsoft/upf-core'
import { KConfigValueViewModelPropOptions } from './interface'
import { EnumTabType, EnumEditType } from '../../interface'
import KConfigValueViewModel from './KConfigValueViewModel'

export default defineView({
  name: 'ConfigValue',
  props: KConfigValueViewModelPropOptions,
  viewModel: KConfigValueViewModel,
  setup() {
    return {
      EnumEditType,
      EnumTabType
    }
  }
})

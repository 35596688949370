import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { KBaseEditorEventEmits, KBasePropertyEditorPropOptions } from '../editors/base'

/** 参数 **/
export const KCodeGeneratorPropOptions = {
  ...KBasePropertyEditorPropOptions,
  /** 占位内容 */
  placeholder: VuePropTypes.string().def('请选择编码规则'),
  /** 生成编码所需参数 */
  params: VuePropTypes.createType<CodeGenerateParams>().def()
}

/** 参数类型 **/
export type KCodeGeneratorPropType = ViewPropsTypeExtract<typeof KCodeGeneratorPropOptions>

/** 事件 */
export const KCodeGeneratorEventEmits = {
  ...KBaseEditorEventEmits
}

/** 事件类型 **/
export type KCodeGeneratorEmitsType = ViewEmitsTypeExtract<typeof KCodeGeneratorEventEmits>

export class CodeGenerateParams {
  /**
   * 版本号
   */
  version?: string
  /**
   * 迭代版本
   */
  iteration?: string
}

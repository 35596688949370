import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KConfigFormPropOptions = {
  ...BaseViewPropOptions,
  formValue: VuePropTypes.record().def({}),
  formList: VuePropTypes.array().def([]),
  labelCol: VuePropTypes.number().def(4),
  isView: VuePropTypes.bool().def(false),
  groupList: VuePropTypes.array().def([]),
  isExpand: VuePropTypes.bool().def(true),
  getValue: VuePropTypes.func().def((value: any) => value),
  colSpan: VuePropTypes.number().def(1)
}

/** 参数类型 **/
export type KConfigFormPropType = ViewPropsTypeExtract<typeof KConfigFormPropOptions>

/** 事件 */
export const KConfigFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KConfigFormEmitsType = ViewEmitsTypeExtract<typeof KConfigFormEventEmits>

export type compConfig = {
  isRequired: boolean
  message: string
}
export type attrs = {
  disabled: boolean
}
export type formFields = {
  ref: any
  type: string
  label: string
  props: string
  name: any
  lableCol: number
  compConfig: Record<string, compConfig>
  attrs: Record<string, attrs>
}

export type groupList = {
  label: string
  props: string
  style?: string
  children: Array<formFields>
}

import { BaseViewModel, IQuery, KDataGridViewModel, PageManager, ViewModelOptions, utils } from '@kmsoft/upf-core'
import { KProjectWorkspaceListEmitsType, KProjectWorkspaceListPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode, QueryCondition } from '@kmsoft/ebf-common'
import { IWorkspace } from '../../product-workspace/workspace-list/interface'
import KProjectWorkspace from '..'

/** KProjectWorkspaceList */
export default class KProjectWorkspaceListViewModel extends BaseViewModel<
  KProjectWorkspaceListEmitsType,
  KProjectWorkspaceListPropType
> {
  /**工作区列表网格 */
  refWorkspaceGrid = ref<KDataGridViewModel>()
  /**工作区名称 */
  workspaceName = ref<string>()
  constructor(options: ViewModelOptions<KProjectWorkspaceListPropType>) {
    super(options)
  }

  viewDidMount() {}
  /**
   * 初始化工作区网格
   */
  async loadData(qry:IQuery) {
    const param = {
      data: [
        {
          filter: {},
          isNeedTotal: true,
          sort: 'DESC',
          orderBy: 'createTime'
        },
        {
          maxPageSize: 1000,
          curPage: qry.page?.pageIndex,
          pageSize: 50,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    if (this.workspaceName.value && this.workspaceName.value != '') {
      param.data[0].filter = {
        conditions:[
          {
            conditionName:'name',
            operator:'like',
            conditionValues:[this.workspaceName.value]
          }
        ],
        joiner:"and",
        ignoreStr:true
      } as QueryCondition
    }

    const result = await Api.post('folder', 'Folder', 'listProjectWorkspace', param)
    if (result && result.code == EnumRequestCode.SUCCESS && result.data.data) {
      return {
        rows: result.data.data,
        total: result.data.page.totalRows
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  refresh(){
    this.refWorkspaceGrid.value?.refresh()
  }
  openProjectWorkspace(row: IWorkspace) {
    PageManager.openPage(utils.uuid(), `项目工作区（` + row.name + `)`, KProjectWorkspace, {
      workspaceId: row.id,
      workspaceName: row.name
    })
    return
  }

  search() {
    this.refresh()
  }
}

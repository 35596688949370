import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "refSelectBox",
  class: "k-auto-complete-selector"
}
const _hoisted_2 = {
  key: 1,
  class: "k-render-input-text",
  title: "{vm.stateValue.value}"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.$vm.readonly)
      ? (_openBlock(), _createBlock(_component_k_select, {
          key: 0,
          ref: "refSelect",
          value: _ctx.$vm.localValue,
          showSearch: "",
          optionLabelProp: "label",
          allowClear: _ctx.$vm.allowClear,
          placeholder: _ctx.placeholder,
          autofocus: _ctx.autofocus,
          maxTagTextLength: _ctx.$vm.maxTagTextLength,
          disabled: _ctx.$vm.disabled,
          filterOption: _ctx.searchControl,
          options: _ctx.$vm.localOptions,
          mode: _ctx.$vm.mode,
          open: _ctx.$vm.isShowOption,
          showArrow: _ctx.isShowArrow,
          onSelect: _ctx.onSelect,
          onFocus: _ctx.onFocus,
          onBlur: _ctx.onBlur,
          onSearch: _ctx.onSearch,
          maxTagCount: _ctx.$vm.maxTagCount,
          onChange: _ctx.onChange,
          onPopupScroll: _ctx.onPopupScroll,
          notFoundContent: _ctx.notFoundContent()
        }, {
          option: _withCtx((option) => [
            _renderSlot(_ctx.$slots, "option", _normalizeProps(_guardReactiveProps(option)), () => [
              _createElementVNode("span", null, _toDisplayString(option.label), 1)
            ])
          ]),
          _: 3
        }, 8, ["value", "allowClear", "placeholder", "autofocus", "maxTagTextLength", "disabled", "filterOption", "options", "mode", "open", "showArrow", "onSelect", "onFocus", "onBlur", "onSearch", "maxTagCount", "onChange", "onPopupScroll", "notFoundContent"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$vm.displayValue()), 1))
  ], 512))
}

import { defineAsyncComponent, defineComponent } from 'vue'
import { KFilterGridSearchBarConfigEventEmits, KFilterGridSearchBarConfigPropOptions } from './interface'
import { defineView } from '@kmsoft/upf-core'
import { KFilterGridSearchBarConfigViewModel } from './KFilterGridSearchBarConfigViewModel'
/**
 * 网格搜索栏配置面板
 */
export default defineView({
  name: 'KFilterGridSearchBarConfig',
  viewModel: KFilterGridSearchBarConfigViewModel,
  mixins: [],
  inheritAttrs: false,
  props: KFilterGridSearchBarConfigPropOptions,
  emits: KFilterGridSearchBarConfigEventEmits,
  components: {
    LayoutDesigner: defineAsyncComponent(() => import('../../../../../designer'))
  },
  setup(props, { vm }) {
    return () => {
      return (
        <layout-designer
          ref={vm.refLayoutDesign}
          schema={props.schema}
          dataSource={props.dataSource}
          designerMetaControl={props.designerMetaControl}
        />
      )
    }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartDocBomCompareView from './KPartDocBomCompare.vue'
import KPartDocBomCompareViewModel from './KPartDocBomCompareViewModel'

const KPartDocBomCompare = connect(KPartDocBomCompareView, KPartDocBomCompareViewModel)

export default withInstall(KPartDocBomCompare)
export { KPartDocBomCompare, KPartDocBomCompareView, KPartDocBomCompareViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  Api,
  EnumQueryConditionOperator,
  EnumRequestCode,
  EnumToolStripItemKeys,
  IClsOption,
  KObjectDynamicCreatePanel,
  KObjectToolStripViewModel,
  ObjectClientSrv,
  ObjectToolStripItem,
  ToolStripHelper,
  EnumLifecycleState,
  processCellCallback,
  PartViewMemoryCache
} from '@kmsoft/ebf-common'
import KConfigForm, { KConfigFormViewModel } from '@kmsoft/ebf-common/src/controls/config-form'
import {
  BaseViewModel,
  EnumDialogResult,
  IKTreeNode,
  IQuery,
  KDataGridRowSelectedEvent,
  KDataGridTemplateColumn,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KIcon,
  KModal,
  KNotification,
  MemoryCacheFactory,
  SelectOption,
  ToolStripItemClickedEventArgs,
  TreeViewSelectEventArgs,
  ViewModelOptions,
  request,
  utils
} from '@kmsoft/upf-core'
import { createVNode, ref, render, watch } from 'vue'
import { KPartImport } from '../../../../ebf-part/src/pages/part-import'
import { FolderClientSrv } from '../../client-srv'
import { CopyOrCutEnum } from './folder-tree/interface'
import { KFolderManageEmitsType, KFolderManagePropType, WorkingStateDesc, WorkingStateEnum } from './interface'
import { DocManageClientSrv } from '../../../../ebf-doc/src/client-srv/DocManageClientSrv'
import { instanceOf } from 'vue-types'
import { KFolderTreeViewModel } from './folder-tree'
import _ from 'lodash'

/** KFolderManage */
export default class KFolderManageViewModel extends BaseViewModel<KFolderManageEmitsType, KFolderManagePropType> {
  /** 文件夹列表网格 */
  refFolderObjGrid = ref<KDataGridViewModel>()
  /** 选中的树节点 */
  selectNode = ref<IKTreeNode>()
  refFolderTree = ref<KFolderTreeViewModel>()

  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>(ToolStripHelper.getFolderGeneralObjectOperationToolStripItems())
  /**复制还是粘贴中 */
  inCopyOrCut: CopyOrCutEnum = CopyOrCutEnum.NOTHING
  /** 零部件单行展示按钮 */
  partSingleVisible = ref(true)
  /**最新版本/最新发布版本条件 */
  queryType = ref<String>('LATEST')

  /**列表查询参数 */
  searchParam = ref<Record<string, any>>({
    queryType: 'ALL'
  })
  searchParamUpdateFlag = ref<boolean>(false)

  /**生命周期候选值 */
  lifecycleStateOptions = ref<Array<SelectOption>>(EnumLifecycleState._list)
  /**工作状态候选值 */
  workingStateOptions = ref<Array<SelectOption>>(WorkingStateEnum)

  constructor(options: ViewModelOptions<KFolderManagePropType>) {
    super(options)
    watch(
      () => this.selectNode.value,
      () => {
        this.refreshList()
      }
    ),
      watch(
        () => this.queryType.value,
        () => {
          if (this.searchParamUpdateFlag.value) {
            this.searchParam.value = { ...this.searchParam.value, queryType: this.queryType.value }
            this.searchParamUpdateFlag.value = false
          } else {
            this.searchParam.value = { queryType: this.queryType.value }
            this.refFolderObjGrid.value?.refresh()
          }
        }
      )
  }

  // 树节点会首次加载会选中两次，增加防抖
  refreshList = _.debounce(() => {
    this.refFolderObjGrid.value?.refresh()
  }, 300)

  viewDidMount() {
    this.initToolStrip()
  }

  /**
   * 初始化工具栏
   */
  initToolStrip() {
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_CAD, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_OBSOLETE, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COPY, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CUT, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_OBJ_GROUP_OPERATION, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CONVERT, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_GROUP_FILE_OPERATION, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHANGE_MANAGED, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_GROUP_WORK_FLOW, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_USING_STATISTICS, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUIREMENT_CHANGE, false)
  }
  /**
   * 加载网格数据
   */
  async loadData(query: IQuery) {
    if (this.selectNode.value == null) {
      return {
        rows: [],
        total: 0
      }
    }
    const param = {
      data: [
        {
          folderId: this.selectNode.value?.id,
          pageVO: {
            maxPageSize: 1000,
            curPage: query.page?.pageIndex,
            pageSize: query.page?.pageSize,
            totalRows: 0,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0
          },
          ...this.searchParam.value
        }
      ]
    }
    const result = await Api.post('folder', 'Folder', 'listFolderLink', param)
    if (result && result.code == EnumRequestCode.SUCCESS && result.data && result.data.data) {
      return {
        rows: result.data.data,
        total: result.data.page.totalRows
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }
  /**
   * 改变工具栏
   */
  async changeToolItem(event: KDataGridRowSelectedEvent<any>) {
    const selectRows = this.refFolderObjGrid.value?.getSelectedRows()
    if (!selectRows || selectRows.length == 0) {
      this.initToolStrip()
      return
    }
    const newModelCode = selectRows[selectRows.length - 1].targetClass
    const param = { modelCode: newModelCode, environment: 100, selectedDatas: selectRows }

    await this.refToolStrip.value?.updateState(param)
    if (newModelCode == 'ProductNeeds' && selectRows[selectRows.length - 1].lifecycleStateCode == 'RELEASED') {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUIREMENT_CHANGE, true)
    } else {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUIREMENT_CHANGE, false)
    }
  }
  /**
   * 集合比较
   */
  compareArray(arr1: Array<string>, arr2: Array<string>) {
    if (arr1.length !== arr2.length) {
      return false
    }
    const sortedArr1 = arr1.slice().sort()
    const sortedArr2 = arr2.slice().sort()
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false
      }
    }
    return true
  }
  /**
   * 工具栏点击事件
   */
  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const rows = this.refFolderObjGrid.value!.getSelectedRows()
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.create()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BATCH_CREATE_DOCUMENTS:
        this.batchCreateDocuments()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.add()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_CAD:
        FolderClientSrv.addToCADWorkspace(rows, this.refFolderTree.value?.getContainer())
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS:
        FolderClientSrv.saveAs(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE:
        FolderClientSrv.batchDelete(this.selectNode.value, rows).then(res => {
          if (res) {
            this.refFolderObjGrid.value?.removeSelectedRows()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH:
        FolderClientSrv.batchDelete(this.selectNode.value, rows, true).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH:
        FolderClientSrv.publish(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_OBSOLETE:
        FolderClientSrv.batchObsolete(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_COPY:
        this.copy()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CUT:
        this.cut()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_PASTE:
        this.paste()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE:
        FolderClientSrv.structManage(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT:
        FolderClientSrv.checkOut(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT:
        FolderClientSrv.unCheckOut(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN:
        FolderClientSrv.checkIn(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE:
        FolderClientSrv.revise(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_FIND:
        this.search()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD:
        this.downloadTemplate()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_IMPORT:
        this.partImport()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC:
        FolderClientSrv.browse(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC:
        FolderClientSrv.downloadDoc(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_FILE:
        FolderClientSrv.editFile(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_FROM_LOCAL_FILE:
        FolderClientSrv.fileReplaceFromLocalFile(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_FILE_REPLACE_NEW:
        FolderClientSrv.fileReplaceNew(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_QUERY:
        FolderClientSrv.fileReplaceQuery(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_HISTORY_VERSION:
        FolderClientSrv.historyVersion(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_REMARK:
        FolderClientSrv.remark(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK:
        FolderClientSrv.viewRemark(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK_HISTORY:
        FolderClientSrv.viewRemarkHistory(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ISSUE:
        FolderClientSrv.createChangeIssue(rows, this.refFolderTree.value?.getContainer(), this.selectNode.value).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_REQUEST:
        FolderClientSrv.createChangeRequest(rows, this.refFolderTree.value?.getContainer(), this.selectNode.value).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ORDER:
        FolderClientSrv.createChangeOrder(rows, this.refFolderTree.value?.getContainer(), this.selectNode.value).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_CREATE:
        FolderClientSrv.afterUpdateCreate(rows, this.refFolderTree.value?.getContainer()).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_ADD_ACTIVITY:
        FolderClientSrv.afterUpdateAddActivity(rows, this.refFolderTree.value?.getContainer()).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_CREATE:
        FolderClientSrv.beforeUpdateCreate(rows, this.refFolderTree.value?.getContainer()).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_ADD_ACTIVITY:
        FolderClientSrv.beforeUpdateAddActivity(rows, this.refFolderTree.value?.getContainer()).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CONVERT:
        FolderClientSrv.convert(rows).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW:
        FolderClientSrv.addToWorkflow(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW:
        FolderClientSrv.viewWorkFlow(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_USING_STATISTICS:
        FolderClientSrv.usingStatistics(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE:
        FolderClientSrv.baselineCompare(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUIREMENT_CHANGE:
        FolderClientSrv.createRequirementChange(rows, this.selectNode.value?.id!)
        break
      default:
        break
    }
  }

  /**
   * 零部件导入
   */
  partImport() {
    const folderId = this.selectNode.value?.id
    KDialog.show({
      title: '零部件导入',
      size: { width: 500, height: 300 },
      props: { folderId: folderId },
      showApply: false,
      showCancel: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPartImport,
      onClosing: async (event: KDialogClosingEvent) => {
        this.refFolderObjGrid.value?.refresh()
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
      }
    })
  }

  /**
   * 下载Excel模板
   */
  async downloadTemplate() {
    // 获取模板配置
    const fetchByCode = await Api.post('sys', 'ConfigSysQueryService', 'fetchByCode', {
      data: ['SYS_PART_IMPORT_TEMPLATE']
    })
    if (!(fetchByCode && fetchByCode.success)) {
      KNotification.warn({
        message: '系统提示',
        description: '无法获取零部件导入模板配置'
      })
      return
    }
    // 下载模板
    const param = {
      modelName: 'ZmsAllPropertyTest',
      fileIds: fetchByCode.data
    }
    const result = await request.post('/kmsaasFileApi/download', param, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    })
    if (result?.status == EnumRequestCode.SUCCESS && result.data) {
      const bolb = new Blob([result.data as any], { type: 'application/octet-stream,charset=UTF-8' })
      const url = URL.createObjectURL(bolb)
      const link = document.createElement('a')
      link.href = url
      link.download = '零部件导入模板.xlsx'
      link.click()
    } else {
      KNotification.error({
        title: '操作失败',
        content: '下载零部件导入模板失败'
      })
    }
  }
  /**
   * 树选中后事件
   * @param event 树选中后事件
   */
  afterSelect(event: TreeViewSelectEventArgs<any>) {
    this.selectNode.value = event.node
    this.refFolderObjGrid.value?.clearSelectedRows()
    this.searchParam.value = { queryType: 'LATEST', lifecycleState: ['InWork', 'UnderReview', 'Released'] }
  }

  /**
   * 创建对象
   */
  create() {
    const clsOptions = [] as Array<IClsOption>
    const node = this.selectNode.value
    let defaultSelectClsCode = ''
    let showObjClsCodes = [] as Array<string>
    if (node && node.rootNodeType) {
      showObjClsCodes = ['Part']
      defaultSelectClsCode = 'Part'
    }
    KDialog.show({
      title: '创建对象',
      content: KObjectDynamicCreatePanel,
      props: {
        clsOptions: clsOptions,
        folderId: this.selectNode.value?.id,
        showObjClsCodes: showObjClsCodes,
        defaultSelectClsCode: defaultSelectClsCode
      },
      size: { width: 1200, height: document.documentElement.clientHeight - 100 },
      getContainer: this.refFolderTree.value?.getContainer(),
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewInstance as any
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }
        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refFolderObjGrid.value?.refresh()
          KNotification.success('对象创建成功')
        } else {
          if (result) {
            KNotification.error({
              title: '操作失败',
              content: result.message || '创建对象失败',
              details: result.detail
            })
          }
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 批量创建文档
   */
  batchCreateDocuments() {
    DocManageClientSrv.batchCreateDocuments(this.selectNode.value?.id, () => {
      this.refFolderObjGrid.value?.refresh()
    })
  }
  /**
   * 添加对象
   */
  add() {
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      isMultipleSelection: true,
      size: { width: 1300, height: 850 },
      objectClassManageQueryParam: { showObjClsCodes: ['Part', 'Document'] },
      objectSearchQueryParam: { showObjClsCodes: ['Part', 'Document'] },
      getContainer: this.refFolderTree.value?.getContainer(),
      onClosing: async (event: KDialogClosingEvent) => {
        if (!event.viewInstance) {
          return
        }

        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }

        const objParams = event.viewInstance.getSelectedObjParams()

        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const modelGroup = await ObjectClientSrv.getModelGroupByCode(objParams[0]!.modelCode)
        const objIds = objParams.map((obj: { id: any }) => obj.id)
        // 获取对象信息
        const query = {
          modelCode: objParams[0]!.modelCode,
          modelGroup: modelGroup,
          filter: {
            conditions: [
              {
                conditionName: 'rdmExtensionType',
                operator: EnumQueryConditionOperator.EQUAL,
                conditionValues: [objParams[0]!.extensionType]
              },
              {
                conditionName: 'id',
                operator: EnumQueryConditionOperator.IN,
                conditionValues: objIds
              }
            ],
            joiner: 'and'
          },
          page: {
            pageIndex: 1,
            pageSize: 1000
          }
        }
        const objs = await ObjectClientSrv.listObjects(query)
        if (objs.rows.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '未查询到对象信息'
          })
          event.cancel = true
          return
        }
        // 根据对象中是否有branch对象判断是否是版本对象，若为版本对象则取branch.id
        const objList = objs.rows.map(obj => {
          if (obj.branch) {
            return {
              id: obj.branch.id,
              clazz: obj.branch.rdmExtensionType,
              className: obj.branch.rdmExtensionType
            }
          } else {
            return {
              id: obj.id,
              clazz: obj.rdmExtensionType,
              className: obj.rdmExtensionType
            }
          }
        })
        const params = {
          data: [
            {
              folderId: this.selectNode.value?.id,
              objList: objList
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'batchCreateFolderObjLink', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '对象添加成功'
          })
          this.refresh()
          return
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message!
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 批量删除对象
   */
  batchDelete() {
    KModal.delete({
      title: '删除提示',
      icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
      content: '您正在进行删除操作，请确认是否删除',
      onOk: async () => {
        const rows = this.refFolderObjGrid.value!.getSelectedRows() as any
        if (!rows || rows.length == 0) return
        const deleteRows = rows.map((row: any) => {
          return {
            folderedLinkId: row.id,
            folderId: row.targetFolderId,
            objId: row.targetId,
            className: row.targetClass
          }
        })
        const param = {
          data: [this.selectNode.value?.id, [...deleteRows]]
        }
        const result = await Api.post('folder', 'Folder', 'deleteFolderObjLink', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success('对象移除成功')

          // 移除选中的行
          this.refFolderObjGrid.value?.removeSelectedRows()
        } else {
          KNotification.error({
            title: '对象移除失败',
            content: result.message!
          })
        }
      }
    })
  }
  /**
   * 复制对象
   */
  copy() {
    const rows = this.refFolderObjGrid.value!.getSelectedRows() as any
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    this.inCopyOrCut = CopyOrCutEnum.IN_COPY
    this.setCache('copyRows', rows)
    this.setCache('sourceFolderId', this.selectNode.value?.id)
  }
  /**
   * 剪切对象
   */
  cut() {
    const rows = this.refFolderObjGrid.value!.getSelectedRows() as any
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    this.inCopyOrCut = CopyOrCutEnum.IN_CUT
    this.setCache('copyRows', rows)
    this.setCache('sourceFolderId', this.selectNode.value?.id)
  }
  /**
   * 粘贴对象
   */
  paste() {
    const rows = this.getCache('copyRows')
    const rowsData = rows.map((row: any) => {
      return {
        oldFolderedLinkId: row.id,
        oldFolderId: row.targetFolderId,
        objId: row.targetBranchId ? row.targetBranchId : row.targetId,
        className: row.targetClass
      }
    })
    const fromNodeId = this.getCache('sourceFolderId') as string
    if (!rows) {
      KNotification.warn({
        message: '系统提示',
        description: '请先复制或剪切一个对象'
      })
      return
    }
    const currentNodeId = this.selectNode.value?.id
    if (fromNodeId == currentNodeId) {
      KNotification.warn({
        message: '系统提示',
        description: '请勿粘贴到相同目录'
      })
      return
    }
    const param = {
      data: [currentNodeId, rowsData]
    }
    //如果是剪切操作，需要删除fromNode的数据
    if (this.inCopyOrCut == CopyOrCutEnum.IN_CUT) {
      Api.post('folder', 'Folder', 'cutFolderObjLink', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          this.refFolderObjGrid.value?.refresh()
          KNotification.success('剪切成功')
        } else {
          KNotification.warn({
            message: '剪切失败',
            description: res.message
          })
          return
        }
      })
    } else if (this.inCopyOrCut == CopyOrCutEnum.IN_COPY) {
      Api.post('folder', 'Folder', 'copyFolderObjLink', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          this.refFolderObjGrid.value?.refresh()
          KNotification.success('粘贴成功')
        } else {
          KNotification.warn({
            message: '复制失败',
            description: res.message
          })
          return
        }
      })
    }
    this.clearCache('sourceFolderId')
    this.clearCache('copyRows')
    this.inCopyOrCut = CopyOrCutEnum.NOTHING
  }
  /**
   * 刷新对象
   */
  refresh() {
    this.searchParam.value = { queryType: 'LATEST' }
    this.queryType.value = 'LATEST'
    this.refFolderObjGrid.value?.refresh()
  }
  /**
   * 修改对象
   */
  async update(row: any) {
    const param = {
      id: row?.targetId,
      modelCode: row!.targetClass,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.targetClass)
    }
    ObjectClientSrv.openObj(param)
  }
  /**
   * 删除对象
   * @param id 对象id
   */
  async deleteBranch(row: any) {
    FolderClientSrv.batchDelete(this.selectNode.value, [row], true).then(res => {
      if (res) {
        this.refresh()
      }
    })
  }
  /**
   * 搜索对象
   */
  search() {
    KDialog.show({
      title: '搜索',
      content: KConfigForm,
      props: {
        formList: [
          {
            label: '名称',
            props: 'name',
            name: 'commonSearchName',
            attrs: {
              placeholder: '请输入名称'
            }
          },
          {
            label: '编码',
            props: 'number',
            name: 'commonSearchId',
            attrs: {
              placeholder: '请输入编码'
            }
          },
          {
            label: '创建时间',
            props: 'createTime',
            type: 'k-date-time',
            name: 'commonSearchCreateTime',
            attrs: {
              type: 'range'
            }
          },
          {
            label: '修改时间',
            props: 'lastUpdateTime',
            type: 'k-date-time',
            name: 'commonSearchLastUpdateTime',
            attrs: {
              type: 'range'
            }
          },
          {
            label: '版本',
            props: 'queryType',
            type: 'k-select',
            name: 'commonSearchPhase',
            attrs: {
              options: [
                {
                  label: '全部版本',
                  value: 'ALL'
                },
                {
                  label: '最新版本',
                  value: 'LATEST'
                },
                {
                  label: '最新发布版本',
                  value: 'LATEST_RELEASED'
                }
              ]
            }
          },
          {
            label: '生命周期状态',
            props: 'lifecycleState',
            type: 'k-select',
            name: 'commonSearchPhase',
            attrs: {
              placeholder: '请选择生命周期状态',
              mode: 'multiple',
              options: [
                {
                  label: '工作中',
                  value: 'InWork'
                },
                {
                  label: '审核中',
                  value: 'UnderReview'
                },
                {
                  label: '已发布',
                  value: 'Released'
                },
                {
                  label: '已废弃',
                  value: 'OBSOLETED'
                }
              ]
            }
          }
        ],
        labelCol: 6,
        getValue: (result: any) => {
          return result
        },
        formValue: { queryType: 'ALL', lifecycleState: ['InWork', 'UnderReview', 'Released'] }
      },
      size: { width: 800, height: 500 },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KConfigFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const value = formViewModel.formValue.value
        this.searchParam.value = value
        this.queryType.value = this.searchParam.value.queryType
        this.searchParamUpdateFlag.value = true
        this.refFolderObjGrid.value?.refresh()
      }
    })
  }

  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: any) {
    const param = {
      id: row?.targetId,
      modelCode: row!.targetExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.targetExtensionType)
    }
    ObjectClientSrv.openObj(param)
  }
  exportSelectAsExcel() {
    const data = this.refFolderObjGrid.value?.getSelectedRows()
    if (data && data.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择要导出的数据'
      })
      return
    }
    const params = {
      onlySelected: true,
      columnKeys: this.refFolderObjGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id),
      processCellCallback: (params: any) => {
        const self = this
        return processCellCallback(params, self.refFolderObjGrid.value)
      }
    }
    this.refFolderObjGrid.value?.exportDataAsExcel(params)
  }
  exportAsExcel() {
    const params = {
      columnKeys: this.refFolderObjGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id),
      processCellCallback: (params: any) => {
        const self = this
        return processCellCallback(params, self.refFolderObjGrid.value)
      }
    }
    this.refFolderObjGrid.value?.exportDataAsExcel(params)
  }

  getProjectStatus(row: any) {
    if (row.workingState === 'CHECKED_IN') {
      return 'lock-in'
    } else if (row.workingState === 'INWORK' || row.workingState === 'CHECKED_OUT') {
      return 'lock-out'
    }
  }

  getIconClass(row: any) {
    if (row.workingState === 'INWORK') {
      return 'unlock-self'
    } else if (row.workingState === 'CHECKED_IN') {
      return 'lock-in'
    }
    return 'unlock-out'
  }

  getStatusOption(row: any) {
    return WorkingStateDesc.find(it => it.value === row.workingState)?.label
  }

  getView(row: any) {
    const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
    const partView = cacheInstance.getAll()
    if (row.targetViewId) {
      const view = partView.filter(item => item.id == row.targetViewId)[0].description
      return '(' + view + ')'
    }
    return ''
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KLoginPageView from './KLoginPage.vue'
import KLoginPageViewModel from './KLoginPageViewModel'

const KLoginPage = connect(KLoginPageView, KLoginPageViewModel)

export default withInstall(KLoginPage)
export { KLoginPage, KLoginPageView, KLoginPageViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView } from '@kmsoft/upf-core'
import { KObjSelectPanelViewModel } from './KObjSelectPanelViewModel'
import { KObjSelectPanelPropOptions } from './interface'

export default defineView({
  name: 'ObjSelectPanel', // 组件名称
  components: {}, // 这里定义局部组件
  viewModel: KObjSelectPanelViewModel,
  props: KObjSelectPanelPropOptions,
  setup() {
    return {
      columns: [
        { width: 'auto', name: 'belongClsName', title: '对象类名称' },
        { width: 'auto', name: 'code', title: '属性编码' },
        { width: 'auto', name: 'name', title: '属性名称' },
        { width: 'auto', name: 'displayName', title: '属性显示名称' }
      ]
    }
  }
})

import { ContextItemsLoader } from '../../../../../controls'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** KViewEventEditPanel 事件 **/
export const KViewEventEditPanelEventEmits = {
  ...BaseViewEventEmits
}

/** KViewEventEditPanel 参数 **/
export const KViewEventEditPanelPropOptions = {
  ...BaseViewPropOptions,
  /**控件标题 */
  title: VuePropTypes.string().def(),
  /** 上下文加载回调 */
  loadContext: VuePropTypes.func<ContextItemsLoader>().def(),
  /** 脚本 */
  script: VuePropTypes.string().def()
}

/** KViewEventEditPanel 参数类型 **/
export type KViewEventEditPanelPropType = ViewPropsTypeExtract<typeof KViewEventEditPanelPropOptions>

/** KViewEventEditPanel 事件协议*/
export type KViewEventEditPanelEventEmitType = ViewEmitsTypeExtract<typeof KViewEventEditPanelEventEmits>

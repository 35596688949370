import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KTempUserSelectorPropOptions = {
  ...BaseViewPropOptions,
  userId: VuePropTypes.string().def('')
}

/** 参数类型 **/
export type KTempUserSelectorPropType = ViewPropsTypeExtract<typeof KTempUserSelectorPropOptions>

/** 事件 */
export const KTempUserSelectorEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KTempUserSelectorEmitsType = ViewEmitsTypeExtract<typeof KTempUserSelectorEventEmits>

export interface IKUserSelectorFormDef {
  /**
   * 用户id
   */
  userId: string
}

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  EnumDataType,
  EnumType,
  IKTreeNode,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KDataElementTree, KDesignerControl, PageDesignerDataSourceProperty } from '../../../types/metadata'

/** KDesignerToolbox 状态 **/
export interface IKDesignerToolboxState {}

/** KDesignerToolbox 事件 **/
export const KDesignerToolboxEventEmits = {
  ...BaseViewEventEmits,
  hierarchyTreeNodeSelected: (node: IKTreeNode) => true
}

/** KDesignerToolbox 参数 **/
export const KDesignerToolboxPropOptions = {
  ...BaseViewPropOptions,
  /** 是否显示基础组件 */
  showBaseMetaControl: VuePropTypes.bool().def(true),
  convertElement: VuePropTypes.func<(element: KDesignerControl | PageDesignerDataSourceProperty) => any>().def(),
  /** 不可拖拽的元素  */
  disabledDragElementMap: VuePropTypes.createType<Map<string, string>>().def(),
  /**选中的元素 */
  selectElement: VuePropTypes.createType<string>().def(),
  /** 布局方案 */
  schema: VuePropTypes.createType<any>().def(),
  /** 设计区中选中的元素编码（唯一标识） */
  selectElementCode: VuePropTypes.createType<string>().def(),
  /**工具箱控件元数据 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>()
    .setRequired()
    .def(),
  /**数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def()
}

/** KDesignerToolbox 参数类型 **/
export type KDesignerToolboxPropType = ViewPropsTypeExtract<typeof KDesignerToolboxPropOptions>
/**KDesignerToolbox 事件协议 */
export type KDesignerToolboxEventEmitsType = ViewEmitsTypeExtract<typeof KDesignerToolboxEventEmits>

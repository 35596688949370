
import { defineView } from '@kmsoft/upf-core'
import  KPartImportViewModel  from './KPartImportViewModel'
import { KPartImportPropOptions, KPartImportEventEmits} from "./interface";

export default defineView({
  name: 'KPartImport',
  props:KPartImportPropOptions,
  emits:KPartImportEventEmits,
  viewModel: KPartImportViewModel
})


import { defineView } from '@kmsoft/upf-core'
import { KPartObjManageEventEmits, KPartObjManagePropOptions } from './interface'
import KPartObjManageViewModel from './KPartObjManageViewModel'
import { KModelBrowser } from '@kmsoft/km-vue'

export default defineView({
  name: 'KPartObjManage',
  props: KPartObjManagePropOptions,
  emits: KPartObjManageEventEmits,
  viewModel: KPartObjManageViewModel,
  components: { KModelBrowser },
  setup() {
    return {}
  }
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KDesignerControlPanel = _resolveComponent("KDesignerControlPanel")!
  const _component_k_tab_pane = _resolveComponent("k-tab-pane")!
  const _component_KDesignerMetadata = _resolveComponent("KDesignerMetadata")!
  const _component_k_tabs = _resolveComponent("k-tabs")!

  return (_openBlock(), _createBlock(_component_k_tabs, {
    defaultActiveKey: _ctx.$vm.activeKey
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_tab_pane, {
        key: "1",
        tab: "控件"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_KDesignerControlPanel, {
            columnConfig: true,
            convertElement: _ctx.convertElement,
            "designer-meta-control": _ctx.metaColumns
          }, null, 8, ["convertElement", "designer-meta-control"])
        ]),
        _: 1
      }),
      (_ctx.dataSource)
        ? (_openBlock(), _createBlock(_component_k_tab_pane, {
            key: "2",
            tab: "数据元素"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_KDesignerMetadata, {
                convertElement: _ctx.convertElement,
                dataSource: _ctx.dataSource
              }, null, 8, ["convertElement", "dataSource"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["defaultActiveKey"]))
}

import { defineView } from '@kmsoft/upf-core'
import { KConfigFormViewModelPropOptions } from './interface'
import KConfigFormViewModel from './KConfigFormViewModel'
import { EnumEditType } from '../../interface'

export default defineView({
  name: 'ConfigForm',
  props: KConfigFormViewModelPropOptions,
  viewModel: KConfigFormViewModel,
  setup() {
    return {
      EnumEditType,
      rules: {
        code: { required: true, message: '编码不能为空', trigger: 'blur' },
        name: { required: true, message: '名称不能为空', trigger: 'blur' },
        dataType: { required: true, message: '数据类型不能为空', trigger: 'blur' },
        editType: { required: true, message: '编辑方式不能为空', trigger: 'blur' }
      }
    }
  }
})

import { AgentInstanceObject } from '../../../AgentInstanceObject'

/**指定图像的文件格式。 不可继承。*/
export class ImageFormat extends AgentInstanceObject {
  protected static type: string = 'System.Drawing.Imaging.ImageFormat,System.Drawing.Common'

  /**获取位图 (BMP) 图像格式。*/
  public static async Bmp(): Promise<ImageFormat> {
    return this.getOrAddToMap('70f36173-2f5a-4bfc-b518-dab08b622ca7', () => this.staticPropertyAndWrap(ImageFormat, 'Bmp'))
  }

  /**获取增强型图元文件 (WMF) 图像格式。*/
  public static async Emf(): Promise<ImageFormat> {
    return this.getOrAddToMap('e6fe6e13-bdae-41d2-9585-091655ae25b4', () => this.staticPropertyAndWrap(ImageFormat, 'Emf'))
  }

  /**获取可交换图像文件 (Exif) 格式。*/
  public static async Exif(): Promise<ImageFormat> {
    return this.getOrAddToMap('719234d6-14c1-42c7-b474-5cb08fb64df9', () => this.staticPropertyAndWrap(ImageFormat, 'Exif'))
  }

  /**获取图形交换格式 (GIF) 图像格式。*/
  public static async Gif(): Promise<ImageFormat> {
    return this.getOrAddToMap('85f4e6bf-2091-472e-a980-0b7872fd725e', () => this.staticPropertyAndWrap(ImageFormat, 'Gif'))
  }

  /**获取 Windows 图标图像格式。*/
  public static async Icon(): Promise<ImageFormat> {
    return this.getOrAddToMap('1634713f-7239-45ef-93e0-ca21d3a555da', () => this.staticPropertyAndWrap(ImageFormat, 'Icon'))
  }

  /**获取联合图像专家组 (JPEG) 图像格式。*/
  public static async Jpeg(): Promise<ImageFormat> {
    return this.getOrAddToMap('c0f9bb4d-7256-48cf-92ce-6ef17c5dc2a5', () => this.staticPropertyAndWrap(ImageFormat, 'Jpeg'))
  }

  /**获取内存中的位图的格式。*/
  public static async MemoryBmp(): Promise<ImageFormat> {
    return this.getOrAddToMap('1b739aa6-c3a5-4b03-814e-dc853faf2a94', () => this.staticPropertyAndWrap(ImageFormat, 'MemoryBmp'))
  }

  /**获取 W3C 可移植网络图形 (PNG) 图像格式。*/
  public static async Png(): Promise<ImageFormat> {
    return this.getOrAddToMap('b5d04330-128f-4dcf-98fe-98fe9c31c395', () => this.staticPropertyAndWrap(ImageFormat, 'Png'))
  }

  /**获取标记图像文件格式 (TIFF) 图像格式。*/
  public static async Tiff(): Promise<ImageFormat> {
    return this.getOrAddToMap('bc93c654-50e6-42be-beae-bf5a69b51653', () => this.staticPropertyAndWrap(ImageFormat, 'Tiff'))
  }

  /**获取 Windows 图元文件 (WMF) 图像格式。*/
  public static async Wmf(): Promise<ImageFormat> {
    return this.getOrAddToMap('0c5588cf-7d91-4b17-9169-08cc4aedd79b', () => this.staticPropertyAndWrap(ImageFormat, 'Wmf'))
  }
}

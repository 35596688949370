/** 分页信息 */
export type PageInfo = {
  /**
   * 分页大小
   */
  pageSize: number
  /**
   * 当前页
   */
  pageIndex: number
  /**
   * 排序
   */
  orderBy: string
}

/** 系统配置 */
export type SysConfig<T = any> = {
  /** 编码 */
  code: string
  value: T
}

/** 上传结果 */
export type UploadFileResult = {
  copyFileId: string
  fileId: string
  fileName: string
  localFilePath: string
  state: number
}

/**
 * 单值或列表
 */
export type ValueOrArray<T> = T | Array<T>

/**
 * 下拉列表选项
 */
export type SelectOption<T extends ValueOrArray<string> | ValueOrArray<number>> = {
  label?: string
  value: T
}

export const EBF_IOC_KEY = {
  SET_NODE_HIGH_LIGHT: Symbol('SET_NODE_HIGH_LIGHT'),
  AGENT_LINK: Symbol('AGENT_LINK'),
  SET_SEARCH_PARAMS: Symbol('SET_SEARCH_PARAMS'),
  SSO_LOGIN: Symbol('SSO_LOGIN'),
  CLOSE_DIALOG: Symbol('CLOSE_DIALOG'),
  AGENT_LOGIN: Symbol('AGENT_LOGIN')
}

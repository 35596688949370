import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { KObjectClassTreePropOptions } from '../../../object-class-tree/interface'
import { KBaseEditorEventEmits, KBaseEditorPropOptions } from '../../base'

/** 参数 **/
export const KObjectClassTreeSelectorPropOptions = {
  ...KBaseEditorPropOptions,
  /** 选择类型 */
  type: VuePropTypes.createType<'class' | 'all'>().def('all'),
  /** 是否显示上次选择的对象类 */
  showDefaultSelect: VuePropTypes.bool().def(true),
  /** 是否显示清除按钮 */
  showClear: VuePropTypes.bool().def(false),
  /** 是否显示前缀 */
  showPrefix: VuePropTypes.bool().def(false),
  /** 占位内容 */
  placeholder: VuePropTypes.string().def('对象类选择'),
  /** 值 */
  value: VuePropTypes.someType<Array<string> | string>([Array, String]).def(),
  /** 默认展开下拉 */
  defaultOpen: VuePropTypes.bool().def(false),
  /**对象类树基础属性 */
  ...KObjectClassTreePropOptions
}

/** 参数类型 **/
export type KObjectClassTreeSelectorPropType = ViewPropsTypeExtract<typeof KObjectClassTreeSelectorPropOptions>

/** 事件 */
export const KObjectClassTreeSelectorEventEmits = {
  ...KBaseEditorEventEmits,
  /** 事件名: v-model:value */
  'update:value': (value: string) => true,
  /** 对象类名称 */
  'update:objClsName': (value: string) => true
}

/** 事件类型 **/
export type KObjectClassTreeSelectorEmitsType = ViewEmitsTypeExtract<typeof KObjectClassTreeSelectorEventEmits>

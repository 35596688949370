export enum EnumPartNodeChildExpandMode {
  /**
   * 【系统标准接口】
   * 默认的物理视图
   */
  Default = 0,

  /**
   * 【系统标准接口】
   * 发布时间
   */
  PublishTime = 1,

  /**
   * 【系统标准接口】
   * 最新发布版本
   */
  LatestPublishVersion = 2,

  /**
   * 【系统标准接口】
   * 最新版本
   */
  LatestVersion = 3,

  /**
   * 【系统标准接口】
   * 配置规则
   */
  OtherRules = 4,
  /**
   * 【系统标准接口】
   * 基线
   */
  Baseline = 5,
  /**
   * 【系统标准接口】
   * 其他基线
   */
  OtherBaseline = 6,

  /**
   * 【系统标准接口】
   * 时间有效性
   */
  DatedEffectivity = 7
}

export enum EnumFilterType {
  /**
   * 无条件，原始物理视图
   */
  None,

  /**
   * 从上至下的过滤，SearchCondition有效
   */
  SearchConditon,

  /**
   * 子保留则父保留的情况，StructResult有效
   */
  FilterByChildCondition,

  /**
   * 最新版本，预定义规则
   */
  LatestedVersion,

  /**
   * 最新发布版本，预定义规则
   */
  LatestedPublishedVersion
}

export enum EnumPartViewModeConstDef {
  /**
   * 【系统标准接口】
   * 物理视图
   */
  StructTabView = '物理视图',

  /**
   * 【系统标准接口】
   * 最新版本
   */
  LatestView = '最新版本',

  /**
   * 【系统标准接口】
   * 最新发布版本
   */
  LatestPublishedView = '最新发布版本',
  /**
   * 【系统标准接口】
   * 基线
   */
  Baseline = '基线',
  /**
   * 【系统标准接口】
   * 其他基线
   */
  OtherBaseline = '更多基线'
}


import { EnumDataGridRowModelType, IKTreeNode, TreeViewSelectEventArgs, defineView } from '@kmsoft/upf-core'
import { EnumLifecycleStateSimple, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KPersonalElectronicDistributionPropOptions, KPersonalElectronicDistributionEventEmits } from './interface'
import KPersonalElectronicDistributionViewModel from './KPersonalElectronicDistributionViewModel'

export default defineView({
  name: 'KPersonalElectronicDistribution',
  props: KPersonalElectronicDistributionPropOptions,
  emits: KPersonalElectronicDistributionEventEmits,
  viewModel: KPersonalElectronicDistributionViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      EnumLifecycleStateSimple,
      EnumLifecycleStateColor,
      pagination: { pageIndex: 1, pageSize: 100 },
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})

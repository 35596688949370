
import { IKTreeNode, TreeViewSelectEventArgs, defineView } from '@kmsoft/upf-core'
import { KIssueReportRelationPartPropOptions, KIssueReportRelationPartEventEmits } from './interface'
import KIssueReportRelationPartViewModel from './KIssueReportRelationPartViewModel'
import { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KIssueReportRelationPart',
  props: KIssueReportRelationPartPropOptions,
  emits: KIssueReportRelationPartEventEmits,
  viewModel: KIssueReportRelationPartViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor }
  }
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_checkbox = _resolveComponent("k-checkbox")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    ref: _ctx.$vm.refModuleForm,
    "label-col": { span: 5 },
    "wrapper-col": { span: 16 },
    rules: _ctx.rules,
    model: _ctx.$vm.formState
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, {
        label: "编码",
        name: "code",
        required: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formState.code,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formState.code) = $event)),
            maxlength: 40,
            disabled: !_ctx.$vm.changeAvailable
          }, null, 8, ["value", "disabled"])
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(_component_k_checkbox, {
        checked: _ctx.$vm.formState.visible,
        "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formState.visible) = $event)),
        style: {"margin-left":"140px","margin-bottom":"10px"}
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 可见 ")
        ]),
        _: 1
      }, 8, ["checked"]), [
        [_vShow, _ctx.$vm.visibleAvailable]
      ]),
      _createVNode(_component_k_form_item, {
        label: "名称",
        name: "name",
        required: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formState.name,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.formState.name) = $event)),
            maxlength: 40
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, {
        label: "备注",
        name: "remark",
        required: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_textarea, {
            value: _ctx.$vm.formState.remark,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.formState.remark) = $event)),
            maxlength: 170,
            rows: 3
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["rules", "model"]))
}
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KChangeActivityRelationshipPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KChangeActivityRelationshipPropType = ViewPropsTypeExtract<typeof KChangeActivityRelationshipPropOptions>

/** 事件 */
export const KChangeActivityRelationshipEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangeActivityRelationshipEmitsType = ViewEmitsTypeExtract<typeof KChangeActivityRelationshipEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KTaskEvaluateView from './KTaskEvaluate.vue'
import KTaskEvaluateViewModel from './KTaskEvaluateViewModel'

const KTaskEvaluate = connect(KTaskEvaluateView, KTaskEvaluateViewModel)

export default withInstall(KTaskEvaluate)
export { KTaskEvaluate, KTaskEvaluateView, KTaskEvaluateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

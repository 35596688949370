
import { defineView } from '@kmsoft/upf-core'
import { KFilePropertyEditorPropOptions, KFilePropertyEditorEventEmits } from './interface'
import KFilePropertyEditorViewModel from './KFilePropertyEditorViewModel'

/** 文件属性上传组件 */
export default defineView({
  name: 'KFilePropertyEditor',
  props: KFilePropertyEditorPropOptions,
  emits: KFilePropertyEditorEventEmits,
  viewModel: KFilePropertyEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

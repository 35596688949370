import lodash from 'lodash'
import { eventEmitter, KNotification, EventArgs } from '@kmsoft/upf-core'
import { EnumClipBoardDataType, EnumClipBoarbType } from './types'
import { ObjectHelper } from '../ObjectHelper'
import { EnumSaasEventNames, PLM_IOC_KEY } from '../../system'

export class ClipBoard {
  /** 剪贴板类型 */
  private static clipBoardType: EnumClipBoarbType | undefined
  /** 剪贴板数据 */
  private static clipBoardData: ClipboardObjectData<any> | undefined
  /** 已注册的数据类型 */
  private static maxDataType: number = 0
  /** 注册数据类型 */
  private static regeistedDataType: Map<string, number> = (() => {
    const result: Map<string, number> = new Map<string, number>()
    const entries = Object.entries(EnumClipBoardDataType) as Array<Array<any>>

    for (const iterator of entries) {
      const key = iterator[0]
      const value = iterator[1]
      if (!ObjectHelper.isIntString(value)) continue

      const dataType = parseInt(value)
      result.set(key, dataType)
      ClipBoard.maxDataType = Math.max(ClipBoard.maxDataType, dataType)
    }
    return result
  })()
  /** 注册反向对照 */
  private static regeistedDataTypeReversed: Map<number, string> = (() => {
    const result: Map<number, string> = new Map<number, string>()
    for (const key of ClipBoard.regeistedDataType.keys()) {
      result.set(ClipBoard.regeistedDataType.get(key)!, key)
    }
    return result
  })()

  /**
   * 注册一种剪切板数据类型
   * @param dataTypeName 数据类型名称
   * @returns 数据类型（Int）
   */
  static registerClipBoardDataType(dataTypeName: string): number {
    if (dataTypeName == null || dataTypeName == undefined) return -1
    if (this.regeistedDataType.has(dataTypeName)) return -1

    this.maxDataType += 1
    this.regeistedDataType.set(dataTypeName, this.maxDataType)
    this.regeistedDataTypeReversed.set(this.maxDataType, dataTypeName)

    return this.maxDataType
  }

  /**
   * 设置剪贴板
   */
  private static set<T>(data: ClipboardObjectData<T>, clipBoardType: EnumClipBoarbType, showNotification = true) {
    if (!this.regeistedDataTypeReversed.has(data.dataType)) {
      console.info(`剪贴板 ${data.dataType} 数据类型不存在`)
      return
    }

    this.clipBoardType = clipBoardType

    // 深克隆数据
    let cloned: ClipboardObjectData<T> | undefined
    const dataInner = data.dataObject
    if (data.clone && dataInner) {
      data.dataObject = undefined
      cloned = lodash.cloneDeep(data)
      cloned.dataObject = data.clone(dataInner)
    } else {
      cloned = lodash.cloneDeep(data)
    }

    this.clipBoardData = cloned

    if (showNotification) {
      KNotification.success({
        title: '操作完成',
        content: clipBoardType == EnumClipBoarbType.Copy ? '复制成功' : '剪切成功'
      })
    }

    const event = new EventArgs()
    eventEmitter.emit(PLM_IOC_KEY.EVENT_HOOKS, EnumSaasEventNames.CLIPBOARD_DATA_CHANGE, event)
  }

  /**
   * 清空剪贴板
   */
  static clear() {
    this.clipBoardData = undefined
    this.clipBoardType = undefined
    const event = new EventArgs()
    eventEmitter.emit(PLM_IOC_KEY.EVENT_HOOKS, EnumSaasEventNames.CLIPBOARD_DATA_CHANGE, event)
  }

  /**
   * 是否存在数据
   * @param type 类型
   * @returns
   */
  static contains(argType: EnumClipBoardDataType | number | Array<EnumClipBoardDataType | number>) {
    if (this.clipBoardData === undefined || this.clipBoardType == undefined) return false

    if (lodash.isArray(argType)) {
      return argType.indexOf(this.clipBoardData.dataType) >= 0
    } else {
      return this.clipBoardData.dataType == argType
    }
  }

  /**
   * 复制到剪贴板
   * @param argData
   */
  static copy<T>(argData: ClipboardObjectData<T>, showNotification = true) {
    this.set(argData, EnumClipBoarbType.Copy, showNotification)
  }

  /**
   * 剪切到剪贴板
   * @param argData
   */
  static cut<T>(argData: ClipboardObjectData<T>, showNotification = true) {
    this.set(argData, EnumClipBoarbType.Cut, showNotification)
  }

  /**
   * 获取剪贴板数据
   */
  static getData<T>(
    argType: EnumClipBoardDataType | number | Array<EnumClipBoardDataType | number>
  ): ClipboardObjectData<T> | undefined {
    if (this.clipBoardData === undefined || this.clipBoardData.dataObject == undefined || this.clipBoardType == undefined) return

    if (lodash.isArray(argType)) {
      if (argType.indexOf(this.clipBoardData.dataType) >= 0) {
        return this.clipBoardData
      }
    } else if (this.clipBoardData.dataType == argType) {
      return this.clipBoardData
    }
  }

  /** 获取复制或剪贴类型 */
  static getType(): EnumClipBoarbType | undefined {
    return this.clipBoardType
  }

  /**
   * 复制到系统剪贴板
   * @param value
   */
  static copyToSystemClipBoard(value: string) {
    if (!value) return

    const textArea = document.createElement('textarea')
    textArea.value = value
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
  }
}

/** 剪贴板数据 */
export class ClipboardObjectData<T> {
  /** 环境信息 */
  enviromentInfo?: DataObjClipEnviroment
  /** 数据类型 */
  dataType: EnumClipBoardDataType | number
  /** 剪贴板数据 */
  dataObject?: T
  /** 父节点id */
  parentId?: string
  /** 父节点 */
  parentNode?: any

  /** 克隆数据方法 */
  clone?: (x: T) => T = undefined

  /** 粘贴完成后回调 */
  pasteCallback?: () => Promise<void> | void

  /**
   * 剪贴板数据
   * @param argDataObj 剪贴板数据
   * @param argDataType 剪贴数据类型
   * @param pasteCallback 粘贴完成后回调
   */
  constructor(
    argDataObj: T,
    argId: string,
    argParentNode: any,
    argDataType: EnumClipBoardDataType | number,
    pasteCallback?: () => Promise<void> | void
  ) {
    this.dataObject = argDataObj
    this.dataType = argDataType
    this.parentId = argId
    this.parentNode = argParentNode
    this.pasteCallback = pasteCallback
  }

  /** 完成复制 */
  public async complete() {
    if (this.pasteCallback) {
      await this.pasteCallback()
    }
    /** 剪切后粘贴，需要清空剪切板 */
    if (this.isCut) {
      ClipBoard.clear()
    }
  }

  /** 剪贴板类型 */
  get clipBoardType(): EnumClipBoarbType | undefined {
    return ClipBoard.getType()
  }

  /** 是否是剪切 */
  get isCut(): boolean {
    return ClipBoard.getType() == EnumClipBoarbType.Cut
  }
}

/** 数据剪切环境 */
export type DataObjClipEnviroment = {
  /** 环境标识 */
  flag: string
  /** 环境数据 */
  detail: Record<string, any>
}

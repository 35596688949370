import { connect, withInstall } from '@kmsoft/upf-core'
import KPartObjManageView from './KPartObjManage.vue'
import KPartObjManageViewModel from './KPartObjManageViewModel'

const KPartObjManage = connect(KPartObjManageView, KPartObjManageViewModel)

export default withInstall(KPartObjManage)
export { KPartObjManage, KPartObjManageView, KPartObjManageViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartReportBuyView from './KPartReportBuy.vue'
import KPartReportBuyViewModel from './KPartReportBuyViewModel'

const KPartReportBuy = connect(KPartReportBuyView, KPartReportBuyViewModel)

export default withInstall(KPartReportBuy)
export { KPartReportBuy, KPartReportBuyView, KPartReportBuyViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, IKTreeNode, KTreeViewViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDesignerHierarchyEventEmitsType, KDesignerHierarchyPropType } from './interface'
import { nextTick, ref, watch } from 'vue'

/** 导航管理 */
export default class KDesignerHierarchyViewModel extends BaseViewModel<
  KDesignerHierarchyEventEmitsType,
  KDesignerHierarchyPropType
> {
  /** 树数据 */
  treeDataSource = ref<Array<IKTreeNode>>([])
  refTreeView = ref<KTreeViewViewModel>()
  constructor(options: ViewModelOptions<KDesignerHierarchyPropType>) {
    super(options)
    watch(
      () => this.props.schema,
      (newSchema: any) => {
        this.__setTreeData(newSchema)
      },
      { deep: true }
    )
    watch(
      () => this.props.selectedHierarchyTreeNodeKey,
      (name?: string) => {
        this.__setSelectedNode(name)
      }
    )
  }
  viewDidMount(): void {
    this.__setTreeData(this.props.schema)
    this.__setSelectedNode(this.props.selectedHierarchyTreeNodeKey)
  }
  /**
   * 层级树赋值
   * @param schema 布局方案
   */
  __setTreeData = (schema: any) => {
    if (schema) {
      const treeNode = this.__transformTreeNode(schema)
      this.treeDataSource.value = treeNode ? [treeNode] : ([] as any)
      this.refTreeView.value?.setDataSource(this.treeDataSource.value)
    } else {
      this.treeDataSource.value = [] as any
    }
  }
  /**
   * 设置树节点选中
   * @param code
   */
  __setSelectedNode = (name?: string) => {
    nextTick(() => {
      if (name && this.props.schema) {
        this.refTreeView.value?.setSelectedNode(name)
      } else {
        this.refTreeView.value?.clearSelected()
      }
    })
  }
  /**
   * 将布局方案转换为树节点
   * @param schema 布局方案
   * @returns
   */
  __transformTreeNode = (schema: any): Record<string, any> => {
    let elements = schema.controls
    return {
      id: schema.name,
      name: schema.title,
      children: elements && elements.length > 0 ? elements.map(this.__transformTreeNode) : []
    }
  }
}

import './style/index.css'
import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectSearchView from './KObjectSearch'
import KObjectSearchViewModel from './KObjectSearchViewModel'

const KObjectSearch = connect(KObjectSearchView, KObjectSearchViewModel)

export default withInstall(KObjectSearch)
export { KObjectSearch, KObjectSearchView, KObjectSearchViewModel }
export * from './interface'


import { IFrameEventManager, ObjBusinessParam, UrlHelper, configSrv } from '@kmsoft/ebf-common'
import { AppContext, KDialog, defineView } from '@kmsoft/upf-core'
import _ from 'lodash'
import { computed } from 'vue'
import {
  deliverableClientSrv,
  iframeMsgClientSrv,
  manaReviewClientSrv,
  planRefClientSrv,
  projectProposalDocClientSrv,
  projectRefClientSrv
} from '../../client-srv'
import { EnumPMSMessageType } from '../enums'
import { PMSMessage } from '../types'
import KProjectPageWrapperViewModel from './KProjectPageWrapperViewModel'
import { KProjectPageWrapperEventEmits, KProjectPageWrapperPropOptions } from './interface'
import * as Utils from './utils'
import { WorkflowClientSrv } from '@kmsoft/ebf-workflow'

export default defineView({
  name: 'KProjectPageWrapper',
  props: KProjectPageWrapperPropOptions,
  emits: KProjectPageWrapperEventEmits,
  viewModel: KProjectPageWrapperViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const getToken = () => {
      //TODO:先使用临时token,后续sso接口提供后统一修改
      return AppContext.current.getIdentity()?.token
    }
    const iframeSrc = computed(() => {
      let urlPreifx = ''

      if (props.isAbsUrl === false) {
        const host = window.location.host
        let pmsServer = configSrv.pmsServer()
        const hosts = host.split('.')
        pmsServer = `${pmsServer}.${hosts.length <= 1 ? 'dev.kmsaasdev.com' : hosts.slice(1).join('.')}` //pmsServer + hosts.length < 1 ? '' : hosts.slice(1).join('.')
        urlPreifx = `${pmsServer}/#/${props.name}`
      } else {
        urlPreifx = `${props.name}`
      }

      const urlParams = {
        token: getToken(),
        //通过菜单页面打开时，props.windowid有值，但是通过新标签页打开的场景下，props.windowid没有值
        windowid: props.windowId ?? new Date().getTime(),
        host: UrlHelper.getCurrentUrl('{PROTOCOL}://{HOST}/index.html'),
        ...props.objectInfo
      }
      const symbol = urlPreifx.indexOf('?') >= 0 ? '&' : '?'
      return `${urlPreifx}${symbol}${Utils.objectToUrlParams(urlParams)}`
    })
    /** 注册 iframe 消息监听 */
    const registEvent = () => {
      //防止事件重复注册
      if ((window as any).length == 0) {
        iFrameEventManager.addEventListener<PMSMessage>(
          'PMS',
          _.throttle(async data => {
            ;(window as any).listener = 'PMS'
            eventListener(data)
          }, 3000)
        )

        const eventListener = async (data: any) => {
          // if (data.windowid != props.windowId) return
          data.props = _.cloneDeep(iframeMsgClientSrv.convertParamsToString(data.props))
          let showObjClsId: string = ''
          if (data.type == EnumPMSMessageType.OpenUrl) {
            console.info('打开页面', data)
            if (data.url == undefined || data.url == null || data.name == undefined || data.name == null) {
              console.info('打开页面传入参数不合法', data)
              KDialog.error({ content: '传入参数不合法，请检查 ！' })
              return
            }
            console.log('message-data', data)

            iframeMsgClientSrv.openTab(data.name, data.url)
          }

          if (data.type == EnumPMSMessageType.PMS_OPEN_OBJECT) {
            let msgData = data.props.objParams.map((_: { objClsID: any; objID: any; versionGroupID: any }) => ({
              modelCode: _.objClsID,
              id: _.objID,
              versionGroupID: _.versionGroupID
            }))
            onCheck('打开对象', msgData)
            iframeMsgClientSrv.openObjTab(msgData[0])
          }

          // 返回消息参数
          let outObjBusinessParam: ObjBusinessParam[] = []

          // 项目立项文档 新建
          if (data.type == EnumPMSMessageType.PMS_CREATE_DOC) {
            let msgData = data.props.objParams.map(
              (_: { objClsID: any; objID: any; versionGroupID: any; folderId: string }) =>
                ({
                  modelCode: _.objClsID,
                  id: _.objID,
                  versionGroupID: _.versionGroupID,
                  folderId: _.folderId
                } as ObjBusinessParam)
            )
            onCheck('新建项目立项文档', msgData)
            let result = await projectProposalDocClientSrv.onOpenCreateProjectProposalDocDialog(msgData[0], msgData[0].folderId)
            if (result && result.objBusiness) {
              onPMSCommonResult(EnumPMSMessageType.PMS_CREATE_DOC, data.windowid, result.objBusiness, true, data.props.objParams)
            }
          }
          // 项目立项文档 添加
          if (data.type == EnumPMSMessageType.PMS_ADD_DOC) {
            let msgData = data.props.objParams.map((_: { objClsID: any; objID: any; versionGroupID: any }) => ({
              modelCode: _.objClsID,
              id: _.objID,
              versionGroupID: _.versionGroupID
            }))
            onCheck('添加项目立项文档', msgData)

            let result = await projectProposalDocClientSrv.addProjectProposalDoc(msgData[0], '')
            if (result && result.objBusiness) {
              onPMSCommonResult(EnumPMSMessageType.PMS_ADD_DOC, data.windowid, result.objBusiness, true, data.props.objParams)
            }
          }

          // 项目参考对象 新建
          if (data.type == EnumPMSMessageType.PMS_CREATE_PROJECT_REF) {
            let msgData = data.props.objParams.map((_: { objClsID: any; objID: any; versionGroupID: any }) => ({
              modelCode: _.objClsID,
              id: _.objID,
              versionGroupID: _.versionGroupID
            }))
            onCheck('新建项目参考对象', msgData)

            let result = await projectRefClientSrv.onOpenCreateProjectRefDialog(msgData[0])
            if (result && result.objBusiness) {
              onPMSCommonResult(
                EnumPMSMessageType.PMS_CREATE_PROJECT_REF,
                data.windowid,
                result.objBusiness,
                true,
                data.props.objParams
              )
            }
          }
          // 项目参考对象 添加
          if (data.type == EnumPMSMessageType.PMS_ADD_PROJECT_REF) {
            let msgData = data.props.objParams.map((_: { objClsID: any; objID: any; versionGroupID: any }) => ({
              modelCode: _.objClsID,
              id: _.objID,
              versionGroupID: _.versionGroupID
            }))

            onCheck('添加项目参考对象', msgData)
            console.log('添加项目参考对象', msgData)

            let result = await projectRefClientSrv.addProjectRef(msgData[0], '')
            if (result && result.objBusiness) {
              onPMSCommonResult(
                EnumPMSMessageType.PMS_ADD_PROJECT_REF,
                data.windowid,
                result.objBusiness,
                true,
                data.props.objParams
              )
            }
          }

          // 计划参考对象 新建
          if (data.type == EnumPMSMessageType.PMS_CREATE_PLAN_REF) {
            let msgData = data.props.objParams.map((_: { objClsID: any; objID: any; versionGroupID: any }) => ({
              modelCode: _.objClsID,
              id: _.objID,
              versionGroupID: _.versionGroupID
            }))
            onCheck('新建计划参考对象', msgData)
            let result = await planRefClientSrv.onOpenCreatePlanRefDialog(msgData[0])
            if (result && result.objBusiness) {
              onPMSCommonResult(
                EnumPMSMessageType.PMS_CREATE_PLAN_REF,
                data.windowid,
                result.objBusiness,
                true,
                data.props.objParams
              )
            }
          }
          // 计划参考对象 添加
          if (data.type == EnumPMSMessageType.PMS_ADD_PLAN_REF) {
            let msgData = data.props.objParams.map((_: { objClsID: any; objID: any; versionGroupID: any }) => ({
              objClsID: _.objClsID,
              id: _.objID,
              versionGroupID: _.versionGroupID
            }))
            onCheck('添加计划参考对象', msgData)
            let result = await planRefClientSrv.addPlanRef(msgData[0], '')
            if (result && result.objBusiness) {
              onPMSCommonResult(
                EnumPMSMessageType.PMS_ADD_PLAN_REF,
                data.windowid,
                result.objBusiness,
                true,
                data.props.objParams
              )
            }
          }

          // 任务参考对象 添加
          if (data.type == EnumPMSMessageType.PMS_ADD_TASK_REF) {
            let msgData = data.props.objParams.map((_: { objClsID: any; objID: any; versionGroupID: any }) => ({
              modelCode: _.objClsID,
              id: _.objID,
              versionGroupID: _.versionGroupID
            }))
            onCheck('添加任务参考对象', msgData)
            let result = await planRefClientSrv.addPlanRef(msgData[0], '')
            if (result && result.objBusiness) {
              onPMSCommonResult(
                EnumPMSMessageType.PMS_ADD_TASK_REF,
                data.windowid,
                result.objBusiness,
                true,
                data.props.objParams
              )
            }
          }

          // 任务交付物 新建
          if (data.type == EnumPMSMessageType.PMS_CREATE_TASK_DELIVERABLE) {
            let msgData = data.props.objParams.map(
              (_: { objClsID: any; objID: any; versionGroupID: any; tempObjClassID: any; folderId: string }) => ({
                modelCode: _.objClsID,
                id: _.objID,
                versionGroupID: _.versionGroupID,
                tempObjClassID: _.tempObjClassID,
                folderId: _.folderId
              })
            )
            onCheck('新建任务交付物', msgData)

            showObjClsId = msgData[0].tempObjClassID ? msgData[0].tempObjClassID.toString() : ''
            const folderId = msgData[0].folderId ? msgData[0].folderId.toString() : ''
            let result = await deliverableClientSrv.onOpenCreateDeliverableDialog(msgData[0], folderId, showObjClsId)

            if (result && result.objBusiness) {
              onPMSCommonResult(
                EnumPMSMessageType.PMS_CREATE_TASK_DELIVERABLE,
                data.windowid,
                result.objBusiness,
                true,
                data.props.objParams
              )
            }
          }
          // 任务交付物 添加
          if (data.type == EnumPMSMessageType.PMS_ADD_TASK_DELIVERABLE) {
            let msgData = data.props.objParams.map(
              (_: { objClsID: any; objID: any; versionGroupID: any; tempObjClassID: any }) => ({
                modelCode: _.objClsID,
                id: _.objID,
                versionGroupID: _.versionGroupID,
                tempObjClassID: _.tempObjClassID
              })
            )
            onCheck('添加任务交付物', msgData)

            showObjClsId = msgData[0].tempObjClassID ? msgData[0].tempObjClassID.toString() : ''
            let result = await deliverableClientSrv.addDeliverable(msgData[0], showObjClsId)

            if (result && result.objBusiness) {
              onPMSCommonResult(
                EnumPMSMessageType.PMS_ADD_TASK_DELIVERABLE,
                data.windowid,
                result.objBusiness,
                true,
                data.props.objParams
              )
            }
          }
          // 证明资料 新建
          if (data.type == EnumPMSMessageType.PMS_CREATE_MANA_REVIEW_DOC) {
            let msgData = data.props.objParams.map(
              (_: { objClsID: any; objID: any; versionGroupID: any; tempObjClassID: any; folderId: string }) => ({
                modelCode: _.objClsID,
                id: _.objID,
                versionGroupID: _.versionGroupID,
                tempObjClassID: _.tempObjClassID,
                folderId: _.folderId
              })
            )
            onCheck('新建证明资料', msgData)

            showObjClsId = msgData[0].tempObjClassID ? msgData[0].tempObjClassID.toString() : ''
            const folderId = msgData[0].folderId ? msgData[0].folderId.toString() : ''
            let result = await manaReviewClientSrv.onOpenCreateManaReviewDialog(msgData[0], folderId, showObjClsId)

            if (result && result.objBusiness) {
              onPMSCommonResult(
                EnumPMSMessageType.PMS_CREATE_MANA_REVIEW_DOC,
                data.windowid,
                result.objBusiness,
                true,
                data.props.objParams
              )
            }
          }
          // 证明资料 添加
          if (data.type == EnumPMSMessageType.PMS_ADD_MANA_REVIEW_DOC) {
            let msgData = data.props.objParams.map(
              (_: { objClsID: any; objID: any; versionGroupID: any; tempObjClassID: any }) => ({
                modelCode: _.objClsID,
                id: _.objID,
                versionGroupID: _.versionGroupID,
                tempObjClassID: _.tempObjClassID
              })
            )
            onCheck('添加证明资料', msgData)

            showObjClsId = msgData[0].tempObjClassID ? msgData[0].tempObjClassID.toString() : ''
            let result = await manaReviewClientSrv.addManaReview(msgData[0], showObjClsId)

            if (result && result.objBusiness) {
              onPMSCommonResult(
                EnumPMSMessageType.PMS_ADD_MANA_REVIEW_DOC,
                data.windowid,
                result.objBusiness,
                true,
                data.props.objParams
              )
            }
          }

          // 加入到现有流程
          if (data.type == EnumPMSMessageType.AddToExistFlow) {
            let msgData = data.props.objParams.map((_: { objClsID: any; objID: any; versionGroupID: any }) => ({
              id: _.objID,
              objClsCode: _.objClsID,
              objID: _.objID
              // versionGroupID: _.versionGroupID  // 这里去掉版本组
            }))

            onCheck('加入到现有流程', msgData, false)

            // 打开流程模板选择界面，请加入工作流
            WorkflowClientSrv.addObjectsToWorkflowCheckWf(msgData).then(() => {
              onPMSCommonResult(EnumPMSMessageType.AddToExistFlow, data.windowid, data.props.objParams, true)
            })

            // const flowConfig = await projectSrv.getProjectConfig({ configItemId: data.configId })
            // if (!flowConfig) return
            // if (flowConfig) {
            //   if (!flowConfig.flowObjParam || flowConfig.flowObjParam.length == 0) {
            //     KDialog.error({ content: '请检查配置项：' + flowConfig.configName + ' ！' })
            //     return
            //   }

            //   const result = await clientSrvFactory.flowClientSrv.addToWorkFlow({
            //     objBusinessParams: msgData,
            //     flowObjParam: {
            //       objClsID: flowConfig.flowObjParam[0].objClsID!,
            //       objID: flowConfig.flowObjParam[0].objID!
            //     }
            //   })

            //   if (result) {
            //     onPMSCommonResult(EnumPMSMessageType.AddToExistFlow, data.windowid, data.props.objParams, true)
            //   }
            // }
          }
        }

        /** 返回消息结果 */
        const onPMSCommonResult = (
          type: EnumPMSMessageType,
          windowid: string,
          objParams: Array<ObjBusinessParam | { objClsID: string; objID: string }>,
          success: boolean,
          PMSParams?: any
        ) => {
          console.info(type, '发送消息', objParams, PMSParams)
          iFrameEventManager.broadcastEvent('PMS', {
            type: type,
            windowid: windowid,
            props: {
              success: success,
              objParams: objParams,
              PMSParams: PMSParams
            }
          })
        }

        /** 参数校验 */
        const onCheck = (type: string, objParams: Array<ObjBusinessParam>, single: boolean = true) => {
          console.log('check-objParams', objParams)
          if (objParams == undefined || objParams == null) {
            console.info(type, '传入参数不合法', objParams)
            KDialog.error({ content: '传入参数不合法，请检查 ！' })
            return
          }

          if (single && objParams.length > 1) {
            console.info(type, '传入参数不合法', objParams)
            KDialog.error({ content: '传入参数不合法，请检查 ！' })
            return
          }

          let checkResult = objParams.some(a => a.id == undefined || a.id == null || a.id == undefined || a.id == null)
          if (checkResult) {
            console.info(type, '传入参数不合法', objParams)
            KDialog.error({ content: '传入参数不合法，请检查 ！' })
            return
          }
          console.info(type, '传入参数', objParams)
        }
      }
    }
    const iFrameEventManager = IFrameEventManager.register({
      appId: 'SAAS',
      get targetWindow() {
        return vm.refFrame.value?.contentWindow!
      },
      options: {
        extraData() {
          return { windowid: props.windowId }
        }
      }
    })
    registEvent()
    return {
      iframeSrc,
      registEvent
    }
  }
})

import {
  SimpleViewPropOptions,
  SimpleViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { SelectItem } from '@kmsoft/ebf-common'

/** 参数 **/
export const KChangeRequestAffectedViewsGridPropOptions = {
  ...SimpleViewPropOptions,
  /** 是否是设计模式 */
  isDesigner: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KChangeRequestAffectedViewsGridPropType = ViewPropsTypeExtract<typeof KChangeRequestAffectedViewsGridPropOptions>

/** 事件 */
export const KChangeRequestAffectedViewsGridEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KChangeRequestAffectedViewsGridEmitsType = ViewEmitsTypeExtract<typeof KChangeRequestAffectedViewsGridEventEmits>

import { EnumElementConfigItemName, IKDesignerConfigItem } from '../../../types'
import { BOOLEAN_CANDIDATE, DEFAULT_LABEL_POSITION_CANDIDATE } from '../../../config'
import { KAttributeValueItem } from '../../../types'
import {
  EnumEditType,
  EnumLabelPosition,
  KGridColumn,
  KGridColumnGroup,
  KGridDesignerMetadataGroup,
  KGridSchema
} from '@kmsoft/upf-core'

/**
 * 布局配置项名称映射
 */
export const CONFIG_ITEM_LABEL_NAME_MAP = {
  name: '名称',
  title: '标题',
  labelWidth: '标签宽度',
  minColWidth: '最小列宽',
  rowGap: '行间距',
  colGap: '列间距',
  rowSpan: '行跨度',
  colSpan: '列跨度',
  labelPosition: '标签位置',
  entireRow: '占整行',
  fill: '占满空间',
  colCount: '列数', // 容器中的属性
  columns: '列定义' // 表格中的属性
} as Record<string, string>

export interface IKSingleFormGridConfigModalParam {
  tableName: string
  metadataGroups: Array<KGridDesignerMetadataGroup>
  schema: KGridSchema
}

export const TABLE_LABEL_POSITION_CANDIDATE = [
  { displayValue: '居上', actualValue: EnumLabelPosition.TOP },
  { displayValue: '不显示', actualValue: EnumLabelPosition.HIDDEN }
]
/**
 * 配置项显示顺序
 */
export const CONFIG_ITEM_DISPLAY_ORDER = {
  name: 0,
  title: 1,
  rowSpan: 2,
  colSpan: 3,
  labelWidth: 4,
  labelPosition: 5,
  entireRow: 6,
  fill: 7,
  minColWidth: 8,
  rowGap: 9,
  colGap: 10,
  colCount: 11,
  columns: 12
} as Record<string, any>

/** 元素通用配置项  */
export interface ElementCommonConfigItem {
  /** 名称 */
  name: string
  /** 标签 */
  title: string
  /** 行跨度 */
  rowSpan: number
  /** 列跨度 */
  colSpan: number
  /** 是否占用整行 */
  entireRow: boolean
  /** 标签宽度 */
  labelWidth: number
  /** 标签位置 */
  labelPosition: EnumLabelPosition
}

/** 输入元素的配置项 */
export interface InputElementConfigItem extends ElementCommonConfigItem {
  /** 是否占满整个布局区 */
  fill: boolean
}

/** 表格类型元素的配置项 */
export interface TableElementConfigItem extends ElementCommonConfigItem {
  /** 是否占满整个布局区 */
  fill: boolean
  /** 列配置 */
  columns: Array<KGridColumnGroup | KGridColumn>
}

/** 页段配置项 */
export interface SegmentElementConfigItem extends ElementCommonConfigItem {
  /** 最小列宽度 */
  minColWidth: number
  /** 行间距 */
  rowGap: number
  /** 列间距 */
  colGap: number
  /** 列数 */
  colCount: number
}
/** 配置项 */
export const CONFIG_ITEM_COLLECTION = {
  NAME: { name: EnumElementConfigItemName.NAME, title: '名称', editType: EnumEditType.INPUT },
  TITLE: { name: EnumElementConfigItemName.TITLE, title: '标题', editType: EnumEditType.INPUT },
  ROW_SPAN: { name: EnumElementConfigItemName.ROW_SPAN, title: '行跨度', editType: EnumEditType.INPUT_NUMBER },
  COL_SPAN: { name: EnumElementConfigItemName.COL_SPAN, title: '列跨度', editType: EnumEditType.INPUT_NUMBER },
  ENTIRE_ROW: {
    name: EnumElementConfigItemName.ENTIRE_ROW,
    title: '占整行',
    editType: EnumEditType.DROPDOWN,
    editArgument: { candidates: [...BOOLEAN_CANDIDATE] }
  },
  LABEL_WIDTH: { name: EnumElementConfigItemName.LABEL_WIDTH, title: '标签宽度', editType: EnumEditType.INPUT_NUMBER },
  LABEL_POSITION: {
    name: EnumElementConfigItemName.LABEL_POSITION,
    title: '标签位置',
    editType: EnumEditType.DROPDOWN,
    editArgument: {
      candidates: [...DEFAULT_LABEL_POSITION_CANDIDATE]
    }
  },
  FILL: {
    name: EnumElementConfigItemName.FILL,
    title: '占满空间',
    editType: EnumEditType.DROPDOWN,
    editArgument: { candidates: [...BOOLEAN_CANDIDATE] }
  },
  MIN_COL_WIDTH: { name: EnumElementConfigItemName.MIN_COL_WIDTH, title: '最小列宽', editType: EnumEditType.INPUT_NUMBER },
  ROW_GAP: { name: EnumElementConfigItemName.ROW_GAP, title: '行间距', editType: EnumEditType.INPUT_NUMBER },
  COL_GAP: { name: EnumElementConfigItemName.COL_GAP, title: '列间距', editType: EnumEditType.INPUT_NUMBER },
  COL_COUNT: { name: EnumElementConfigItemName.COL_COUNT, title: '列数', editType: EnumEditType.INPUT_NUMBER },
  COLUMNS: { name: 'columns', title: '列编辑', editType: EnumEditType.CUSTOM }
}

/**
 * 输入型元素的配置项集合
 */
export const InputElementConfigItems = [
  CONFIG_ITEM_COLLECTION.NAME,
  CONFIG_ITEM_COLLECTION.TITLE,
  CONFIG_ITEM_COLLECTION.ROW_SPAN,
  CONFIG_ITEM_COLLECTION.COL_SPAN,
  CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  CONFIG_ITEM_COLLECTION.LABEL_WIDTH,
  CONFIG_ITEM_COLLECTION.LABEL_POSITION,
  CONFIG_ITEM_COLLECTION.FILL
] as Array<IKDesignerConfigItem>

/**
 * 表格元素的配置项集合
 */
export const TableElementConfigItems = [
  CONFIG_ITEM_COLLECTION.NAME,
  CONFIG_ITEM_COLLECTION.TITLE,
  CONFIG_ITEM_COLLECTION.ROW_SPAN,
  CONFIG_ITEM_COLLECTION.COL_SPAN,
  CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  CONFIG_ITEM_COLLECTION.LABEL_WIDTH,
  CONFIG_ITEM_COLLECTION.LABEL_POSITION,
  CONFIG_ITEM_COLLECTION.FILL,
  CONFIG_ITEM_COLLECTION.COLUMNS
] as Array<IKDesignerConfigItem>

/**
 * 页段元素配置项集合
 */
export const SegmentElementConfigItems = [
  CONFIG_ITEM_COLLECTION.NAME,
  CONFIG_ITEM_COLLECTION.TITLE,
  CONFIG_ITEM_COLLECTION.ROW_SPAN,
  CONFIG_ITEM_COLLECTION.COL_SPAN,
  CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  CONFIG_ITEM_COLLECTION.LABEL_WIDTH,
  CONFIG_ITEM_COLLECTION.LABEL_POSITION,
  CONFIG_ITEM_COLLECTION.MIN_COL_WIDTH,
  CONFIG_ITEM_COLLECTION.ROW_GAP,
  CONFIG_ITEM_COLLECTION.COL_GAP,
  CONFIG_ITEM_COLLECTION.COL_COUNT
] as Array<IKDesignerConfigItem>

/**
 * 全局配置项
 */
export const GlobalConfigItems = [
  CONFIG_ITEM_COLLECTION.LABEL_WIDTH,
  CONFIG_ITEM_COLLECTION.LABEL_POSITION,
  CONFIG_ITEM_COLLECTION.MIN_COL_WIDTH,
  CONFIG_ITEM_COLLECTION.ROW_GAP,
  CONFIG_ITEM_COLLECTION.COL_GAP,
  CONFIG_ITEM_COLLECTION.COL_COUNT
] as Array<IKDesignerConfigItem>

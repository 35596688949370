import { connect, withInstall } from '@kmsoft/upf-core'
import KPartReportView from './KPartReport.vue'
import KPartReportViewModel from './KPartReportViewModel'

const KPartReport = connect(KPartReportView, KPartReportViewModel)

export default withInstall(KPartReport)
export { KPartReport, KPartReportView, KPartReportViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KPdfBrowserView from './KPdfBrowser.vue'
import KPdfBrowserViewModel from './KPdfBrowserViewModel'

const KPdfBrowser = connect(KPdfBrowserView, KPdfBrowserViewModel)

export default withInstall(KPdfBrowser)
export { KPdfBrowser, KPdfBrowserView, KPdfBrowserViewModel }

// 模板生成文件
// export * from './{{folderName}}'

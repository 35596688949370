import { BaseViewModel, KDataGridViewModel, KNotification, ViewModelOptions, utils } from '@kmsoft/upf-core'
import { KFlowApprovalProcessEmitsType, KFlowApprovalProcessPropType } from './interface'
import { ref, watch } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** KFlowApprovalProcess */
export default class KFlowApprovalProcessViewModel extends BaseViewModel<
  KFlowApprovalProcessEmitsType,
  KFlowApprovalProcessPropType
> {
  flowImgUrl = ref<string>()

  executorGridList = ref<Array<Record<string, any>>>()

  approvalOpinionList = ref<Array<any>>([])

  constructor(options: ViewModelOptions<KFlowApprovalProcessPropType>) {
    super(options)

    watch(
      () => options.props.processInstanceId,
      newVal => {
        if (newVal) {
          this.initData()
        }
      },
      {
        immediate: true,
        deep: true
      }
    )
  }

  viewDidMount() {}

  initData() {
    this.getFlowImg()
    this.getApprovalOpinion()
    this.initExecutorGridData()
  }

  initExecutorGridData() {
    const executorGridList = [] as Array<Record<string, object>>
    if (this.props.executorGridData) {
      this.props.executorGridData.forEach((processNode: any) => {
        if (!processNode.multiInstance) {
          executorGridList.push({
            ...processNode,
            executorName: processNode.assignVariableValue,
            id: utils.uuid()
          })
        } else {
          processNode.assignVariableValue.forEach((assignVariableValueName: any) => {
            executorGridList.push({
              ...processNode,
              executorName: assignVariableValueName,
              id: utils.uuid()
            })
          })
        }
      })
      this.executorGridList.value = executorGridList
      // this.refExecutorDataGrid.value?.setDataSource(executorGridList)
    }
  }

  /** 获取流程图片 **/
  getFlowImg() {
    const host = window.location.host
    const parts = host.split('.')
    parts[0] = 'flow-admin'
    // 本地通过localhost访问，没有域名，则默认使用test.kmsaasdev.com
    if (parts.length <= 1) {
      parts[1] = 'dev.kmsaas.com'
    }
    const newHost = parts.join('.')
    const url = `${window.location.protocol}//${newHost}/bpmn-viewer/index.html?id=` + this.props.processInstanceId
    this.flowImgUrl.value = url
  }

  /** 获审批意见 **/
  getApprovalOpinion() {
    if (this.props.processInstanceId) {
      Api.post('official', 'ProcessContentService', 'listSuggestion', {
        data: [this.props.processInstanceId]
      }).then((result: any) => {
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.approvalOpinionList.value = result.data || []
        } else {
          KNotification.error({
            title: '获取失败',
            content: result.message,
            details: result.detail
          })
        }
      })
    }
  }
}

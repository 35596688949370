import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21284eda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%","height":"100%","padding":"5px","display":"flex","flex-direction":"column"} }
const _hoisted_2 = { class: "title-wrapper" }
const _hoisted_3 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$vm.formState?.name), 1),
      _createVNode(_component_k_tool_strip, {
        ref: _ctx.$vm.refObjClsToolStrip
      }, {
        default: _withCtx(() => [
          (_ctx.$vm.editable && _ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 0,
                name: "btnInsert",
                title: "新建",
                onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.doInsert())
              }))
            : _createCommentVNode("", true),
          (_ctx.$vm.editable && _ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 1,
                name: "btnDelete",
                title: "删除",
                onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.doDelete())
              }))
            : _createCommentVNode("", true),
          (_ctx.$vm.editable && _ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 2,
                name: "btnUp",
                title: "上移",
                onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.doUp()),
                disabled: !_ctx.$vm.upEnabled
              }, null, 8, ["disabled"]))
            : _createCommentVNode("", true),
          (_ctx.$vm.editable && _ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 3,
                name: "btnDown",
                title: "下移",
                onClick: _cache[3] || (_cache[3] = () => _ctx.$vm.doDown()),
                disabled: !_ctx.$vm.downEnabled
              }, null, 8, ["disabled"]))
            : _createCommentVNode("", true),
          (_ctx.$vm.editable && _ctx.$vm.dataLoaded && _ctx.mode === _ctx.EnumTabType.SYS)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 4,
                name: "btnCheck",
                title: "校验",
                onClick: _cache[4] || (_cache[4] = () => _ctx.$vm.check())
              }))
            : _createCommentVNode("", true),
          (_ctx.$vm.editable && _ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 5,
                name: "btnSave",
                title: "保存",
                onClick: _cache[5] || (_cache[5] = () => _ctx.$vm.save())
              }))
            : _createCommentVNode("", true),
          (_ctx.$vm.editable && _ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 6,
                name: "btnRefresh",
                title: "刷新",
                onClick: _cache[6] || (_cache[6] = () => _ctx.$vm.refresh())
              }))
            : _createCommentVNode("", true),
          (_ctx.$vm.editable && _ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 7,
                name: "btnEditOut",
                title: "完成编辑",
                onClick: _cache[7] || (_cache[7] = () => _ctx.$vm.editOut())
              }))
            : _createCommentVNode("", true),
          (!_ctx.$vm.editable && _ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 8,
                name: "btnEditIn",
                title: "编辑",
                onClick: _cache[8] || (_cache[8] = () => _ctx.$vm.editIn())
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512)
    ]),
    _createVNode(_component_k_data_grid, {
      ref: _ctx.$vm.refObjClsGrid,
      autoIndex: false,
      pagination: false,
      rowKey: "id",
      isMultipleSelection: false,
      rowModeType: "clientSide",
      onRowSelected: _cache[9] || (_cache[9] = (selectedEvent) => _ctx.$vm.selectRow(selectedEvent))
    }, {
      columns: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$vm.columns, (column) => {
          return (_openBlock(), _createBlock(_component_k_data_grid_text_box_column, {
            key: column.code,
            id: column.code,
            name: column.code,
            dataPropertyName: column.code,
            headerText: column.name,
            width: "120px",
            align: "center"
          }, null, 8, ["id", "name", "dataPropertyName", "headerText"]))
        }), 128))
      ]),
      _: 1
    }, 512)
  ]))
}
export enum EnumQueryConditionOperator {
  /** 等于 */
  EQUAL = '=',
  /** 包含 */
  LIKE = 'like',
  /** 开始为 */
  STARTWITH = 'startWith',
  /** 结尾为 */
  ENDWITH = 'endWith',
  /** 介于 */
  IN = 'in',
  /** 不包含 */
  NOT_IN = 'not in',
  /** 小于 */
  LESS = '<',
  /** 大于 */
  GREATER = '>',
  /** 小于等于 */
  LESS_EQUAL = '<=',
  /** 大于等于 */
  GREATER_EQUAL = '>=',
  /** 不等于 */
  NOT_EQUAL = '<>',
  /** 为空 */
  IS_NULL = 'ISNULL',
  /** 非空 */
  NOT_NULL = 'NOTNULL'
}

/** 逻辑运算符 */
export enum EnumQueryConditionJoiner {
  /** 且 */
  And = 'and',
  /** 或 */
  Or = 'or'
}

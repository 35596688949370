import {
  VuePropTypes,
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract
} from '@kmsoft/upf-core'

/** QuickSearchBarTest 参数 **/
export const QuickSearchBarTestPropOptions = {
  ...BaseViewPropOptions
}

/** QuickSearchBarTest 参数类型 **/
export type QuickSearchBarTestPropType = ViewPropsTypeExtract<typeof QuickSearchBarTestPropOptions>

/** QuickSearchBarTest 事件 */
export const QuickSearchBarTestEventEmits = {
  ...BaseViewEventEmits
}

/** QuickSearchBarTest 事件类型 **/
export type QuickSearchBarTestEventEmitsType = ViewEmitsTypeExtract<typeof QuickSearchBarTestEventEmits>

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KProjectPageWrapperEmitsType, KProjectPageWrapperPropType } from './interface'
import { ref } from 'vue'

/** KProjectPageWrapper */
export default class KProjectPageWrapperViewModel extends BaseViewModel<
  KProjectPageWrapperEmitsType,
  KProjectPageWrapperPropType
> {
  constructor(options: ViewModelOptions<KProjectPageWrapperPropType>) {
    super(options)
  }

  viewDidMount() {}
  refFrame = ref<HTMLIFrameElement>()
}

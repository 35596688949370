import {
  BaseViewModel,
  DataGridLoadResult,
  EnumDialogResult,
  IKTreeNode,
  IQuery,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  KTreeViewViewModel,
  SelectOption,
  TreeViewSelectEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import {
  DistributionStatusEnum,
  KPersonalElectronicDistributionEmitsType,
  KPersonalElectronicDistributionPropType
} from './interface'
import {
  Api,
  EnumRequestCode,
  ObjectClientSrv,
  ObjectToolStripItem,
  EnumLifecycleState,
  ClassMetaClientSrv
} from '@kmsoft/ebf-common'
import { ref, watch, nextTick } from 'vue'
import { WorkflowClientSrv } from '../../../../ebf-workflow/src/client-srv'
import { KDistributionCreate, KDistributionCreateViewModel } from '../../pages/distribution/distribution-create'

/** KpersonalElectronicDistribution */
export default class KPersonalElectronicDistributionViewModel extends BaseViewModel<
  KPersonalElectronicDistributionEmitsType,
  KPersonalElectronicDistributionPropType
> {
  /**树组件定义 */
  refTree = ref<KTreeViewViewModel>()
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>()
  /**流程网格组件 */
  refDistributionGrid = ref<KDataGridViewModel>()
  /** 选中的节点 */
  selectNode = ref<IKTreeNode>()
  /** 查询条件 */
  queryCriteria = ref<string>('')
  /** 是否显示已读 */
  showRead = ref<boolean>(true)
  /** 是否显示勾选框 */
  showBox = ref<boolean>(true)
  /** 是否显示勾选框 */
  showReceiveStatus = ref<boolean>(true)
  /** 发放单状态候选值 */
  distributionStatusOptions = ref<Array<SelectOption>>(DistributionStatusEnum)
  /**生命周期候选值 */
  lifecycleStateOptions = ref<Array<SelectOption>>(EnumLifecycleState._list)

  constructor(options: ViewModelOptions<KPersonalElectronicDistributionPropType>) {
    super(options)
    watch(
      () => this.selectNode.value,
      () => {
        if (this.selectNode.value?.type === '1') {
          nextTick(() => {
            this.showBox.value = true
            this.refDistributionGrid.value?.hideColumn('status', false)
            this.refDistributionGrid.value?.hideColumn('receivingTime', false)
            this.refDistributionGrid.value?.hideColumn('withdrawn', true)
          })
        } else if (this.selectNode.value?.type === '2') {
          nextTick(() => {
            this.showBox.value = false
            this.refDistributionGrid.value?.hideColumn('status', true)
            this.refDistributionGrid.value?.hideColumn('receivingTime', true)
            this.refDistributionGrid.value?.hideColumn('withdrawn', false)
          })
        }
        this.refDistributionGrid.value?.refresh()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  /**
   * 加载树
   */
  loadTreeData(): Promise<Array<IKTreeNode>> {
    return new Promise((resolve, reject) => {
      resolve([
        {
          id: 'root',
          name: '我的发放单',
          leaf: false,
          iconType: 'Node_MyProjectTask',
          children: [
            {
              id: '1',
              name: '我的接收',
              leaf: true,
              type: '1',
              iconType: 'PurviewDefine',
              children: []
            },
            {
              id: '2',
              name: '我的发放',
              leaf: true,
              type: '2',
              iconType: 'MSProjectInput',
              children: []
            }
          ] as Array<IKTreeNode>
        }
      ])
    })
  }

  /**
   * 刷新网格
   */
  setSelectNode(event: TreeViewSelectEventArgs<any>) {
    this.selectNode.value = event.node
  }

  /**
   * 网格数据加载
   */
  loadData(query: IQuery): Promise<DataGridLoadResult> {
    const showRead = this.showRead.value
    const key = this.queryCriteria.value
    const pageVO = {
      maxPageSize: 100,
      curPage: query.page?.pageIndex,
      pageSize: query.page?.pageSize,
      totalRows: 0,
      totalPages: 0,
      startIndex: 0,
      endIndex: 0
    }
    return new Promise((resolve, reject) => {
      if (!this.selectNode.value) {
        reject([])
        return
      }
      switch (this.selectNode.value.type) {
        case '1': {
          const param = {
            data: [
              {
                showRead: showRead,
                key: key
              },
              pageVO
            ]
          }
          Api.post('doc', 'Distribution', 'pageMyReceiveDistribution', param).then(res => {
            if (res && res.code == EnumRequestCode.SUCCESS) {
              resolve({
                rows: res.data.data,
                total: res.data.page.totalRows
              })
            } else {
              resolve([])
            }
          })
          break
        }
        case '2': {
          const param = {
            data: [
              {
                key: key
              },
              pageVO
            ]
          }
          Api.post('doc', 'Distribution', 'pageMyInitiatedDistribution', param).then(res => {
            if (res && res.code == EnumRequestCode.SUCCESS) {
              resolve({
                rows: res.data.data,
                total: res.data.page.totalRows
              })
            } else {
              resolve([])
            }
          })
          break
        }
        default:
          resolve([])
          break
      }
    })
  }

  /**
   * 切换是否显示已读
   */
  changShowReadValue() {
    this.refDistributionGrid.value?.refresh()
  }

  /**
   * 打开电子发放单
   * @param row 选中行
   */
  async openDistribution(row: any) {
    const param = {
      id: row?.id,
      modelCode: row!.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.className!)
    }
    ObjectClientSrv.openObj(param)
    // 打开后已读
    if (this.selectNode.value?.type == '1' && row.status != 'READ') {
      return new Promise((resolve, reject) => {
        const param = {
          data: [row.id]
        }
        Api.post('doc', 'Distribution', 'read', param).then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS) {
            this.refDistributionGrid.value?.refresh()
            resolve([])
          } else {
            KNotification.error({
              title: '系统提示',
              content: res.message
            })
            reject([])
          }
        })
      })
    }
  }

  /**
   * 搜索发放单
   */
  async search() {
    this.refDistributionGrid.value?.refresh()
  }

  /**
   * 创建发放单
   */
  create() {
    KDialog.show({
      title: '新建发放单',
      size: { width: 1000, height: 800 },
      props: { classTreeSelectDisplay: true },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KDistributionCreate,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KDistributionCreateViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }
        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refDistributionGrid.value?.refresh()
          KNotification.success({
            title: '系统提示',
            content: '新建成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '新建失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 删除发放单
   */
  delete() {
    const rows = this.refDistributionGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: () => {
        // 获取主对象id集合
        const rowIds = rows!.map(row => row.id)
        Api.post('doc', 'Distribution', 'batchDelete', { data: [rowIds] }).then(res => {
          if (res.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '删除成功'
            })
            // 刷新网格
            this.refDistributionGrid.value?.refresh()
          } else {
            KNotification.error({
              title: '操作失败',
              content: res.message || '删除失败',
              details: res.detail
            })
            return
          }
        })
      }
    })
  }

  /**
   * 撤回发放单
   */
  withdraw() {
    const rows = this.refDistributionGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认撤回吗？撤回后接收人不可见!',
      onOk: () => {
        // 获取主对象id集合
        const rowIds = rows!.map(row => row.id)
        Api.post('doc', 'Distribution', 'batchWithdrawn', { data: [rowIds] }).then(res => {
          if (res.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '撤回成功'
            })
            // 刷新网格
            this.refDistributionGrid.value?.refresh()
          } else {
            KNotification.error({
              title: '操作失败',
              content: res.message || '撤回失败',
              details: res.detail
            })
            return
          }
        })
      }
    })
  }

  /**
   * 加入流程
   */
  async addWorkflow() {
    const rows = this.refDistributionGrid.value?.getSelectedRows() as any
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    const params = rows.map((row: any) => {
      return {
        objID: row.id,
        objClsCode: 'Distribution',
        name: row.name,
        number: row.number,
        status: row.lifecycleStateCode
      }
    }) as Array<any>
    WorkflowClientSrv.addObjectsToWorkflow(params)
  }
}

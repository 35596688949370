import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

// 页面事件
export const KConfigValueTileEventEmits = {
  ...BaseViewEventEmits
}

export const KConfigValueTileViewModelPropOptions = {
  ...BaseViewPropOptions,
  defId: VuePropTypes.string().def(''),
  treeId: VuePropTypes.string().def(''),
  oper: VuePropTypes.string().def(''),
  mode: VuePropTypes.string().def('')
}

export type KConfigValueTileViewModelPropType = ViewPropsTypeExtract<typeof KConfigValueTileViewModelPropOptions>

export type KConfigValueTileEventEmitsType = ViewEmitsTypeExtract<typeof KConfigValueTileEventEmits>

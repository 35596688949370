import { BaseViewModel, ViewModelOptions, SelectOption, MemoryCacheFactory } from '@kmsoft/upf-core'
import { KObjectViewSelectorEmitsType, KObjectViewSelectorPropType, ViewData } from './interface'
import { Api, EnumRequestCode, PartViewMemoryCache } from '../../client-srv'
import { ref, watch } from 'vue'
import { isObject } from 'lodash'
import { KSelectViewModel } from '@kmsoft/upf-core/src'

/** KObjectViewSelector */
export default class KObjectViewSelectorViewModel extends BaseViewModel<
  KObjectViewSelectorEmitsType,
  KObjectViewSelectorPropType
> {
  /**视图 */
  refView = ref<string>()
  originValue = ref<string>()
  /**视图候选项 */
  refViewOptions: Array<SelectOption>
  /**视图详细数据 */
  viewDataList = ref<Array<ViewData>>()
  displayValue = ref<string>()
  refSelect = ref<KSelectViewModel>()

  constructor(options: ViewModelOptions<KObjectViewSelectorPropType>) {
    super(options)
    this.initPartViewOptions()
    watch(
      () => options.props.value,
      (newVal: Record<string, any>) => {
        if (newVal) {
          this.refView.value = newVal.name
        }
      },
      { immediate: true }
    )
  }

  viewDidMount() {}

  /**
   * 初始化视图候选项
   */
  async initPartViewOptions() {
    //获取视图
    const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
    const PartView = cacheInstance.getAll()
    const viewOptions: Array<SelectOption> = []
    this.viewDataList.value = PartView as Array<ViewData>
    PartView.forEach(view => {
      viewOptions.push({ label: view.description, value: view.name })
    })
    this.refViewOptions = viewOptions
  }

  getValue() {
    return this.viewDataList.value?.filter(elem => elem.name == this.refView.value)[0]
  }
  setValue(value: ViewData, setChange?: boolean) {
    if (value && isObject(value)) {
      this.refView.value = value.name
      this.displayValue.value = value.description
    }
    if (setChange) {
      this.refSelect.value?.setValue(value.name, setChange)
    }
  }
  isModified() {
    return this.refSelect.value!.isModified()
  }
  getModifiedValue() {
    return this.getValue()
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KWorkflowViewView from './KWorkflowView.vue'
import KWorkflowViewViewModel from './KWorkflowViewViewModel'

const KWorkflowView = connect(KWorkflowViewView, KWorkflowViewViewModel)

export default withInstall(KWorkflowView)
export { KWorkflowView, KWorkflowViewView, KWorkflowViewViewModel }

// 模板生成文件
// export * from './{{folderName}}'

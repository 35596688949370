import { UPF_IOC_KEYS, IAppConfigurer, IocContainer, IMenuPagePathMapping } from '@kmsoft/upf-core'
import { DocMenuPathMapping } from './beans'
import { App } from 'vue'
import Control from './controls'
import { DocToolCommon } from './client-srv'
import { DocToolPdfBrowser } from './client-srv'
import { useExtension } from './extension'
import KDistributionCreate from "./pages/distribution/distribution-create";
import KDistributionEdit from "./pages/distribution/distribution-edit";

export default {
  configVue: (app: App) => {
    app.use(Control)
    app.use(KDistributionCreate)
    app.use(KDistributionEdit)
  },
  configBean: (iocContainer: IocContainer) => {
    iocContainer.registerSingleton<IMenuPagePathMapping>(UPF_IOC_KEYS.MENU_PAGE_PATH_MAPPING, new DocMenuPathMapping())
    DocToolCommon.register(iocContainer)
    DocToolPdfBrowser.register(iocContainer)
    //useExtension(iocContainer)
  }
} as IAppConfigurer
export * as AgentDdb from './client-agent'
export * from './controls'
import { IMenuPagePathMapping } from '@kmsoft/upf-core'
import KBaselineCreate from '../pages/baseline-create'
import KBaselineEdit from '../pages/baseline-edit'
import KBaselineCompare from '../pages/baseline-compare'

export default class ModuleMenuExpose implements IMenuPagePathMapping {
  getMenus() {
    return [
      { target: 'baseline-create', component: KBaselineCreate },
      { target: 'baseline-edit', component: KBaselineEdit },
      { target: 'baseline-compare', component: KBaselineCompare }
    ]
  }
}

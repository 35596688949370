import { connect, withInstall } from '@kmsoft/upf-core'
import KIssueReportRelationDocView from './KIssueReportRelationDoc.vue'
import KIssueReportRelationDocViewModel from './KIssueReportRelationDocViewModel'

const KIssueReportRelationDoc = connect(KIssueReportRelationDocView, KIssueReportRelationDocViewModel)

export default withInstall(KIssueReportRelationDoc)
export { KIssueReportRelationDoc, KIssueReportRelationDocView, KIssueReportRelationDocViewModel }

// 模板生成文件
// export * from './{{folderName}}'

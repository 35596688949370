import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeRequestView from './KChangeRequest.vue'
import KChangeRequestViewModel from './KChangeRequestViewModel'

const KChangeRequest = connect(KChangeRequestView, KChangeRequestViewModel)

export default withInstall(KChangeRequest)
export { KChangeRequest, KChangeRequestView, KChangeRequestViewModel }

// 模板生成文件
// export * from './{{folderName}}'

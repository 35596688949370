
import { defineView, exposeComponentEvents, KInputEventEmits } from '@kmsoft/upf-core'
import { KCreatorEditorPropOptions, KCreatorEditorEventEmits } from './interface'
import KCreatorEditorViewModel from './KCreatorEditorViewModel'

export default defineView({
  name: 'KCreatorEditor',
  props: KCreatorEditorPropOptions,
  emits: KCreatorEditorEventEmits,
  viewModel: KCreatorEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      if (vm.stateReadonly.value) {
        return (
          <div style="width:100%">
            <div class="k-render-dropdown-text" title="displayValue">
              {vm.displayValue()}
            </div>
          </div>
        )
      } else {
        return <k-input ref={vm.refInput.value} {...props} value={vm.stateValue.value?.split(' ')[0]} />
      }
    }
  }
})

import { BaseViewModel, ValueChangeEventArgs, ViewModelOptions } from '@kmsoft/upf-core'
import { KFilterGridFilterItemDesignerEventEmitsType, KFilterGridFilterItemDesignerPropType } from './interface'
import { KFilterWithId } from './interface'
import { ref } from 'vue'

/** KFilterGridFilterItemDesigner 视图模型 */
export class KFilterGridFilterItemDesignerViewModel extends BaseViewModel<
  KFilterGridFilterItemDesignerEventEmitsType,
  KFilterGridFilterItemDesignerPropType
> {
  stateValue = ref<Array<KFilterWithId>>([])
  originalValue = ref<Array<KFilterWithId>>([])
  constructor(options: ViewModelOptions<KFilterGridFilterItemDesignerPropType>) {
    super(options)
  }
  /** 加载完成函数 */
  viewDidMount() {}

  /**
   * 设置模型托管的值
   * 根据 setChanged 判断是否更新originalValue的值
   * @param value 值
   */
  public setValue(value: any, setChanged?: boolean) {
    const oldValue = this.getValue(),
      eventArgs = new ValueChangeEventArgs(oldValue, value)
    if (setChanged) {
      this.originalValue.value = value
    }
    this.emit('beforeChange', eventArgs)
    this.stateValue.value = value
    this.emit('change', eventArgs)
  }

  /**
   * 获取模型托管的值
   */
  public getValue() {
    return this.stateValue.value
  }
}

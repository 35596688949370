import { enumFactory } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  SelectOption
} from '@kmsoft/upf-core'
import { readonly } from 'vue'

export type DocEchoGridDataInner = Required<any> & IDocEchoGridDataInner

export interface IDocEchoGridDataInner {
  id: string
  index: string
  type: string
  number: string
  name: string
  folder: {
    id: string
  }
  version: string
  iteration: string
  operate?: string
  operateOption?: string
}

/** 参数 **/
export const KDocEchoListPanelPropOptions = {
  ...BaseViewPropOptions,
  dataSource: VuePropTypes.createType<DocEchoGridDataInner[]>()
    .setRequired()
    .def([]),
  operateType: VuePropTypes.createType<EnumOperateType>().def(),
  operateOptions: VuePropTypes.createType<Array<SelectOption>>().def(),
  isEditNumber: VuePropTypes.createType<boolean>().def(false)
}

/** 参数类型 **/
export type KDocEchoListPanelPropType = ViewPropsTypeExtract<typeof KDocEchoListPanelPropOptions>

/** 事件 */
export const KDocEchoListPanelEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDocEchoListPanelEmitsType = ViewEmitsTypeExtract<typeof KDocEchoListPanelEventEmits>

/** 操作类型 */
export enum EnumOperateType {
  /** 修订 */
  Revise = 1,
  /** 另存 */
  SaveAs = 2,
  /** 创建 */
  Create = 3,
  /** 检入 */
  CheckIn = 4,
  /** 创建或检入前预览 */
  Priview = 5
}

export const EnumOperateOptions = {
  REVISE: [
    {
      label: '修订',
      value: 'Revise'
    },
    {
      label: '忽略',
      value: 'Ignore'
    }
  ],
  SAVEAS: [
    {
      label: '另存',
      value: 'Saveas'
    },
    {
      label: '忽略',
      value: 'Ignore'
    }
  ],
  CREATE: [
    {
      label: '创建',
      value: 'Add'
    },
    {
      label: '更新',
      value: 'Update'
    },
    {
      label: '检入',
      value: 'Checkin'
    },
    {
      label: '修订',
      value: 'Revise'
    },
    {
      label: '引用',
      value: 'Refer'
    },
    {
      label: '忽略',
      value: 'Ignore'
    }
  ],
  CHECKIN: [
    {
      label: '创建',
      value: 'Add'
    },
    {
      label: '更新',
      value: 'Update'
    },
    {
      label: '检入',
      value: 'Checkin'
    },
    {
      label: '修订',
      value: 'Revise'
    },
    {
      label: '引用',
      value: 'Refer'
    },
    {
      label: '忽略',
      value: 'Ignore'
    }
  ]
}

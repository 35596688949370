import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!

  return (_openBlock(), _createBlock(_component_k_object_class_grid, {
    ref: _ctx.$vm.refHistoryDataGrid,
    modelCode: "Part",
    modelGroup: "part",
    loadData: _ctx.$vm.loadHistoryData
  }, null, 8, ["loadData"]))
}
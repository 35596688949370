
import { defineView } from '@kmsoft/upf-core'
import { KChangeOrderAffectedObjectGridPropOptions, KChangeOrderAffectedObjectGridEventEmits } from './interface'
import KChangeOrderAffectedObjectGridViewModel from './KChangeOrderAffectedObjectGridViewModel'
import { clsCodeMap, EnumLifecycleState, EnumLifecycleStateColor } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KChangeOrderAffectedObjectGrid',
  props: KChangeOrderAffectedObjectGridPropOptions,
  emits: KChangeOrderAffectedObjectGridEventEmits,
  viewModel: KChangeOrderAffectedObjectGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { clsCodeMap, EnumLifecycleState, EnumLifecycleStateColor }
  }
})

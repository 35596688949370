import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectCreatePanelFormBaseView from './KObjectCreatePanelFormBase'
import KObjectCreatePanelFormBaseViewModel from './KObjectCreatePanelFormBaseViewModel'

const KObjectCreatePanelFormBase = connect(KObjectCreatePanelFormBaseView, KObjectCreatePanelFormBaseViewModel)

export default withInstall(KObjectCreatePanelFormBase)
export { KObjectCreatePanelFormBase, KObjectCreatePanelFormBaseView, KObjectCreatePanelFormBaseViewModel }
export * from './interface'

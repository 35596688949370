
import { defineView } from '@kmsoft/upf-core'
import { KChangeKanbanPropOptions, KChangeKanbanEventEmits } from './interface'
import KChangeKanbanViewModel from './KChangeKanbanViewModel'
import KChangeStatistics from './change-statistics'
import KChangeRanking from './change-ranking'

export default defineView({
  name: 'KChangeKanban',
  components: { KChangeStatistics, KChangeRanking },
  props: KChangeKanbanPropOptions,
  emits: KChangeKanbanEventEmits,
  viewModel: KChangeKanbanViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

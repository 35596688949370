import { connect, withInstall } from '@kmsoft/upf-core'
import KDocBatchImportView from './KDocBatchImport.vue'
import KDocBatchImportViewModel from './KDocBatchImportViewModel'

const KDocBatchImport = connect(KDocBatchImportView, KDocBatchImportViewModel)

export default withInstall(KDocBatchImport)
export { KDocBatchImport, KDocBatchImportView, KDocBatchImportViewModel }

// 模板生成文件
// export * from './{{folderName}}'

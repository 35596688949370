import * as Vue from 'vue'
import * as UpfCore from '@kmsoft/upf-core'

import { KTreeNodeFileSystemInfo } from './tree-node/KTreeNodeFileSystemInfo'
import { KTreeNodeRoot } from './tree-node/KTreeNodeRoot'
import { KFileDialogPropType } from './interface'
import { FileAttributes } from '../../../io/FileAttributes'

export default class KFileDialogViewModel extends UpfCore.BaseViewModel<UpfCore.BaseViewEventEmitsType, KFileDialogPropType> {
  public refTreeView = Vue.ref<UpfCore.KTreeViewViewModel>()
  public refBreadcrumb = Vue.ref<UpfCore.KBreadcrumbViewModel>()
  public breadcrumbRoute = Vue.ref<UpfCore.BreadcrumbRoutes[]>([])
  public refDataGrid = Vue.ref<UpfCore.KDataGridViewModel>()
  public refFileNames = Vue.ref<UpfCore.KToolStripInputItemViewModel>()
  public refFileType = Vue.ref<UpfCore.KToolStripSelectItemViewModel>()

  public async refTreeView_LoadData(node: UpfCore.IKTreeNode | null) {
    return node ? [] : await new KTreeNodeRoot().populateChildren(0)
  }

  public refTreeView_AfterNodeInsert(event: UpfCore.TreeViewInsertEventArgs): boolean {
    if (this.refTreeView.value && !this.refTreeView.value.getSelectedNode()) {
      const root = this.refTreeView.value.getRootNodes()[0]
      if (root && root.key) this.refTreeView.value?.setSelectedNode(root.key)
    }
    return true
  }

  public refTreeView_AfterSelect(event: UpfCore.TreeViewSelectEventArgs<KTreeNodeFileSystemInfo>) {
    //刷新导航
    let node = event.node
    const routes: UpfCore.BreadcrumbRoutes[] = []
    do routes.push({ path: node.key, breadcrumbName: node.name })
    while ((node = node.parent as KTreeNodeFileSystemInfo))
    this.breadcrumbRoute.value = routes.reverse()

    //刷新网格
    this.retrieveDetail(event.node, this.refFileType.value?.getValue() as string | string[])
  }

  public refBreadcrumb_Click(event: MouseEvent): boolean {
    event.preventDefault()
    if (event.target instanceof HTMLAnchorElement && event.target.hash) {
      const path = event.target.hash
        .split('/')
        .slice(-1)
        .map(decodeURI)[0]
      this.refTreeView.value?.setSelectedNode(path)
    }
    return true
  }

  public refDataGrid_CellDoubleClicked(event: UpfCore.KDataGridCellDoubleClickEvent<KTreeNodeFileSystemInfo>) {
    if (event.row && !event.row.disabled && event.row.pathType != FileAttributes.Normal) this.selectSubNode(event.row.key)
  }

  public refDataGrid_SelectionChanged(event: UpfCore.KDataGridSelectionChangedEvent<KTreeNodeFileSystemInfo>) {
    const rows = event.selectedRows.filter(_ => _.pathType === this.props.resultType)
    const quote = rows.length > 1 ? '"' : ''
    return this.refFileNames.value?.setValue(rows.map(_ => `${quote}${_.name}${quote}`).join(' '), true)
  }

  public refFileType_Change(value: UpfCore.ValueChangeEventArgs /*<string | string[]>*/) {
    //刷新网格
    this.retrieveDetail(this.refTreeView.value?.getSelectedNode() as KTreeNodeFileSystemInfo, value.newValue)
  }

  private retrieveDetail(node: KTreeNodeFileSystemInfo | undefined, filter: string | string[]): void {
    node
      ?.populateChildren(this.props.resultType, UpfCore.utils.isArray(filter) ? filter.join(';') : filter)
      .then(nodes => this.refDataGrid.value?.setDataSource(nodes))
  }

  private async selectSubNode(key: string): Promise<void> {
    if (!this.refTreeView.value) return

    const currentKey = this.refTreeView.value.getSelectedNode()?.key
    if (currentKey) await this.refTreeView.value.expand(currentKey)

    this.refTreeView.value.setSelectedNode(key)
  }

  public get filtersVisible(): boolean {
    return this.props.resultType === FileAttributes.Normal
  }

  public get filters(): UpfCore.SelectOption[] {
    const result: UpfCore.SelectOption[] = []
    const patterns = this.props.filter.split('|')
    for (let i = 0; i < patterns.length - 1; i += 2) result.push({ label: patterns[i], value: patterns[i + 1] })
    return result
  }

  public collect(): { cancel: boolean; fileNames: string[]; fileName: string } {
    const result: ReturnType<KFileDialogViewModel['collect']> = { cancel: true, fileNames: [], fileName: '' }
    if (!this.refDataGrid.value) return result

    const selectedRows = this.refDataGrid.value.getSelectedRows<KTreeNodeFileSystemInfo>()
    let checkFileNames = false
    if (selectedRows.length == 0) {
      if (this.props.allowEdit) {
        const fileName = this.refFileNames.value?.getValue()
        result.fileNames = fileName ? [fileName] : []
        checkFileNames = this.props.resultType === FileAttributes.Normal
      }
    } else result.fileNames = selectedRows.filter(_ => _.pathType === this.props.resultType).map(_ => _.id)

    if (checkFileNames) {
      const rows = this.refDataGrid.value.getRows<KTreeNodeFileSystemInfo>()
      const directory = rows.find(_ => _.pathType === FileAttributes.Directory && result.fileNames.includes(_.name))
      if (directory) this.selectSubNode(directory.key)
      return result
    }

    if (result.fileNames.length > 0) {
      result.fileName = result.fileNames[0]
      result.cancel = false
      return result
    }

    const directory = selectedRows.find(_ => _.pathType === FileAttributes.Directory)
    if (directory) this.selectSubNode(directory.key)
    return result
  }
}

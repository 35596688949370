import { Component, ref, watch } from 'vue'
import { BaseViewModel, ViewModelOptions, KTabsViewModel, AppContext } from '@kmsoft/upf-core'

import { KExtendLayoutEventEmitsType, KExtendLayoutPropType } from './interface'

/**
 * 基础布局视图模型
 */
export default class KExtendLayoutViewModel extends BaseViewModel<KExtendLayoutEventEmitsType, KExtendLayoutPropType> {
  refGlobalTab = ref<KTabsViewModel>()
  showTabs = ref<boolean>(false)
  activeMenuKey = ref<string>()
  vueRouter = AppContext.current.getApplication().router.vueRouter
  refSideBar = ref<any>()
  refHeader = ref<any>()
  collapsed = ref<boolean>(false)
  multiTab = ref<boolean>(false)

  get currentRouter() {
    return this.vueRouter.currentRoute
  }

  constructor(options: ViewModelOptions<KExtendLayoutPropType>) {
    super(options)
    this.activeMenuKey.value = this.props.defaultMenuKey

    watch(
      () => this.props.defaultMenuKey,
      newValue => {
        if (newValue) {
          this.showTabs.value = true
          return
        }
        this.showTabs.value = false
      }
    )

    watch(this.activeMenuKey, newValue => {
      if (newValue) {
        this.showTabs.value = true
        return
      }
      this.showTabs.value = false
    })

    watch(
      this.currentRouter,
      newValue => {
        const newMenuKey = newValue.params.defaultMenuKey as string
        this.activeMenuKey.value = newMenuKey
      },
      {
        deep: true
      }
    )
  }

  /** 加载完成函数 */
  viewDidMount() {}

  openPage(key: string, title: string, content: Component | null, params: Record<string, any>) {
    if (!this.refGlobalTab.value) return

    if (!this.refGlobalTab.value?.existsTab(key)) {
      this.refGlobalTab.value?.addTab(key, title, content, params)
    }
    this.refGlobalTab.value?.active(key)
    this.activeMenuKey.value = key
    this.showTabs.value = true
  }

  closeAllPages() {
    this.refGlobalTab.value?.removeAll()
  }

  closePage(key: string) {
    this.refGlobalTab.value?.remove(key)
  }

  toggle() {
    this.collapsed.value = !this.collapsed.value
  }

  // tab change
  onTabChange(key: string) {
    // 设置激活的菜单项
    this.activeMenuKey.value = key
    let shouldChangeRoute = true

    if (this.currentRouter.value) {
      const params = this.currentRouter.value.params
      if (params.defaultMenuKey === key) shouldChangeRoute = false
    }

    // 同步设置路由地址
    shouldChangeRoute && this.vueRouter.push({ name: 'index', params: { defaultMenuKey: key } })
  }

  onAllTabClosed() {
    this.activeMenuKey.value = ''
    // 同步设置路由地址
    this.vueRouter.push({ name: 'index' })
  }
}

import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KPartStructureTreeView from './KPartStructureTree'
import KPartStructureTreeViewModel from './KPartStructureTreeViewModel'

const KPartStructureTree = connect(KPartStructureTreeView, KPartStructureTreeViewModel)

export default withInstall(KPartStructureTree)
export { KPartStructureTree, KPartStructureTreeView, KPartStructureTreeViewModel }
export * from './nodes'
export * from './interface'

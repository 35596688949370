
import { defineView, ToolStripItemClickedEventArgs } from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { KFlowApprovalContentEventEmits, KFlowApprovalContentPropOptions } from './interface'
import KFlowApprovalContentViewModel from './KFlowApprovalContentViewModel'
import { clsCodeMap, EnumLifecycleState, EnumLifecycleStateColor } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KFlowApprovalContent',
  props: KFlowApprovalContentPropOptions,
  emits: KFlowApprovalContentEventEmits,
  viewModel: KFlowApprovalContentViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const activeKey = ref(['1'])

    watch(activeKey, newValue => {
      vm.emit('open', !!newValue.length)
    })

    return { activeKey, clsCodeMap, EnumLifecycleState, EnumLifecycleStateColor }
  }
})

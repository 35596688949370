
import { defineView } from '@kmsoft/upf-core'
import { KObjectSearchGridPropOptions, KObjectSearchGridEventEmits } from './interface'
import KObjectSearchGridViewModel from './KObjectSearchGridViewModel'

export default defineView({
  name: 'KObjectSearchGrid',
  props: KObjectSearchGridPropOptions,
  emits: KObjectSearchGridEventEmits,
  viewModel: KObjectSearchGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

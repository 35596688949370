import { connect, withInstall } from '@kmsoft/upf-core'
import KReferenceEditorView from './KReferenceEditor.vue'
import KReferenceEditorViewModel from './KReferenceEditorViewModel'

const KReferenceEditor = connect(KReferenceEditorView, KReferenceEditorViewModel)

export default withInstall(KReferenceEditor)
export { KReferenceEditor, KReferenceEditorView, KReferenceEditorViewModel }
export * from './interface'
export * from './KDataGridReferencePropertyColumn'
export * from './ReferenceEditorResolver'

import { AgentStaticObject } from '../../AgentStaticObject'
import { AgentInstanceObject } from '../../AgentInstanceObject'
import { AgentEventHandler } from '../../kmsoft/agent/AgentEventHandler'

import { ProcessStartInfo } from './ProcessStartInfo'

/**提供对本地和远程进程的访问权限并使你能够启动和停止本地系统进程。*/
export class Process extends AgentInstanceObject {
  protected static type: string = 'System.Diagnostics.Process,System.Diagnostics.Process'

  /**创建 {@link Process} 的新实例。
   * @returns 一个 {@link Process} 对象。*/
  public static async create(): Promise<Process> {
    return this.createInstanceAndWrap()
  }

  /**获取或设置在进程终止时是否应引发 {@link Exited} 事件。*/
  public async EnableRaisingEvents(value: boolean = AgentStaticObject.None): Promise<boolean> {
    return this.property('EnableRaisingEvents', value)
  }

  /**获取要传递给 Process.Start() 方法的属性。*/
  public async StartInfo(value: ProcessStartInfo = AgentStaticObject.None): Promise<ProcessStartInfo> {
    return this.propertyAndWrap(ProcessStartInfo, 'StartInfo', value)
  }

  private Event_Exited: string
  /**在进程退出时发生。*/
  public async Exited(value?: AgentEventHandler): Promise<void> {
    this.Event_Exited = await this.eventHandler('Exited', value ?? this.Event_Exited)
  }

  /**启动(或重用)此 {@link Process} 组件的 {@link StartInfo} 属性指定的进程资源，并将其与该组件关联。
   * @returns 如果启动了进程资源，则为 true；如果没有启动新的进程资源(例如，如果重用了现有进程)，则为 false。*/
  public async Start(): Promise<boolean> {
    return this.invokeMethod('Start')
  }

  /**通过指定文档或应用程序文件的名称来启动进程资源，并将资源与新的 {@link Process} 组件关联。
   * @param fileName 要在进程中运行的文档或应用程序文件的名称。
   * @returns
   * 与进程资源关联的新 {@link Process}，如果未启动进程资源，则为 null。
   * 请注意，伴随同一进程中已运行的实例而启动的新进程将独立于其他进程。
   * 此外，启动可能返回一个 HasExited 属性已设置为 true 的非 null 进程。
   * 在这种情况下，启动的进程可能已激活现有实例自身，然后退出。*/
  public static Start(fileName: string): Promise<Process> {
    return this.invokeStaticMethodAndWrap(Process, 'Start', fileName)
  }
}

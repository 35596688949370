import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

export interface ExecutorGridDataInter {
  assignVariable: string
  assignVariableValue: string
  name: string
  class: string
}

/** 参数 **/
export const KFlowApprovalProcessPropOptions = {
  ...BaseViewPropOptions,
  /** 流程实例id **/
  processInstanceId: VuePropTypes.string().def(),
  executorGridData: VuePropTypes.array<ExecutorGridDataInter[]>().def([])
}

/** 参数类型 **/
export type KFlowApprovalProcessPropType = ViewPropsTypeExtract<typeof KFlowApprovalProcessPropOptions>

/** 事件 */
export const KFlowApprovalProcessEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KFlowApprovalProcessEmitsType = ViewEmitsTypeExtract<typeof KFlowApprovalProcessEventEmits>

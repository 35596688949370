
import {
  KNotification,
  EnumFilterOperator,
  KDialog,
  EnumDialogResult,
  KDialogClosingEvent,
  KCondition,
  KFilter,
  defineView
} from '@kmsoft/upf-core'
import _ from 'lodash'
import { KFilterGridFilterItemConfigEventEmits, KFilterGridFilterItemConfigPropOptions } from './interface'
import KFilterGirdFilterItemConfigForm, { KFilterGirdFilterItemConfigFormViewModel } from './form'
import { KFilterGridFilterItemConfigViewModel } from './KFilterGridFilterItemConfigViewModel'
import { KFilterWithId } from '../interface'

export default defineView({
  inheritAttrs: false,
  name: 'KFilterGridFilterItemConfig',
  viewModel: KFilterGridFilterItemConfigViewModel,
  emits: KFilterGridFilterItemConfigEventEmits,
  props: KFilterGridFilterItemConfigPropOptions,
  setup(props, { vm }) {
    /**
     * 删除筛选项
     */
    const deleteFilter = () => {
      /**获取所有选中行列 */
      let getRow = vm.refGrid.value?.getSelectedRows().map((x: Record<string, any>) => x.id) as Array<string>
      if (getRow) {
        getRow.forEach(item => {
          let index = vm.filters.value.findIndex(res => res.id === item)
          if (index !== -1) {
            vm.filters.value.splice(index, 1)
          }
        })
      }
    }

    /**
     * 编辑筛选项
     */
    const editFilter = (id: string) => {
      let filter = vm.filters.value.find(index => index.id === id) as KFilter
      const baseProps = props.dataSource.children.find(c => c.title == '基础属性')
      const columns = baseProps?.children || []
      KDialog.show({
        title: '编辑筛选项',
        props: {
          columns,
          filter
        },
        size: { width: 1000, height: 500 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KFilterGirdFilterItemConfigForm,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
          const formViewModel = event.viewModel as KFilterGirdFilterItemConfigFormViewModel
          const result = await formViewModel.validate()
          if (!result) {
            event.cancel = true
            return
          }
          const formValue = formViewModel.getValue() as KFilterWithId
          editOK(formValue)
        }
      })
    }

    /**
     * 添加筛选项
     */
    const addFilter = () => {
      const baseProps = props.dataSource.children.find(c => c.title == '基础属性')
      const columns = baseProps?.children || []
      KDialog.show({
        title: '新建筛选项',
        props: {
          columns
        },
        size: { width: 1000, height: 500 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KFilterGirdFilterItemConfigForm,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
          const formViewModel = event.viewModel as KFilterGirdFilterItemConfigFormViewModel
          const result = await formViewModel.validate()
          if (!result) {
            event.cancel = true
            return
          }
          const formValue = formViewModel.getValue() as KFilterWithId
          createOK(formValue)
        }
      })
    }

    /**
     * 新增筛选器
     */
    const createOK = (model: KFilterWithId) => {
      /**验证是否存在相同名称 */
      if (__validate2Name(model)) {
        KNotification.warning({
          title: '提示',
          content: '已存在相同名称'
        })
        return false
      }
      let id = Date.now().toString()
      model.id = id
      model.conditions = __convert2ConditionValue(model.conditions)
      vm.filters.value.push(model)
    }

    /**
     * 修改筛选器
     */
    const editOK = (model: KFilterWithId) => {
      /**验证是否存在相同名称 */
      if (__validate2Name(model)) {
        KNotification.warning({
          title: '提示',
          content: '已存在相同名称'
        })
        return false
      }
      model.conditions = __convert2ConditionValue(model.conditions)
      vm.filters.value = vm.filters.value.map(item => (item.id === model.id ? model : item))
    }

    /**
     * 转换筛选条件
     */
    const __convert2ConditionValue = (condition: Array<KCondition>): Array<KCondition> => {
      return condition.map(item => {
        if (item.value.length === 0) {
          if (item.operator === EnumFilterOperator.BETWEEN) {
            for (let i = 0; i < 2; i++) {
              item.value.push('')
            }
          } else {
            item.value.push('')
          }
        }
        return item
      })
    }

    /**
     * 校验是否存在相同名称
     */
    const __validate2Name = (model: KFilterWithId) => {
      const gridDataSource = vm.refGrid.value?.getRows() as Array<Record<string, any>>
      let result = gridDataSource?.filter(item => item.id !== model.id).find(x => x.name === model.name)
      if (result) return true
    }

    return {
      addFilter,
      deleteFilter,
      editFilter
    }
  }
})

import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { VNode, ref } from 'vue'
import {
  CreateObjBusinessParams,
  EnumObjCopyMode,
  FileClientSrv,
  ObjBusinessBase,
  ObjBusinessResult,
  ObjectClientSrv
} from '../../../../client-srv'
import { KObjectPropertyPanelViewModel } from '../../../object-property-panel'
import { ObjectCreateFormSaveResult, ObjectCreateLoadDataParams } from '../../interface'
import { EnumSaveOption, OptionValue } from '../option'
import { KObjectCreatePanelFormBaseEmitsType, KObjectCreatePanelFormBasePropType } from './interface'

/** 导航管理 */
export default class KObjectCreatePanelFormBaseViewModel<
  E extends KObjectCreatePanelFormBaseEmitsType = KObjectCreatePanelFormBaseEmitsType,
  P extends KObjectCreatePanelFormBasePropType = KObjectCreatePanelFormBasePropType
> extends BaseViewModel<KObjectCreatePanelFormBaseEmitsType, KObjectCreatePanelFormBasePropType> {
  /** 属性面板 */
  refObjectPropertyPanel = ref<KObjectPropertyPanelViewModel>()
  /** 是否正在加载 */
  isLoading = ref<boolean>(false)
  /** 创建选项 */
  createOptionValue = ref<Record<OptionValue, boolean>>({})

  constructor(options: ViewModelOptions<KObjectCreatePanelFormBasePropType>) {
    super(options)
  }

  viewDidMount() {}

  /** 获取头部内容 */
  getHeaderContent(): VNode | void {}

  /** 获取底部内容 */
  getFooterContent(): VNode | void {}

  /** 获取修改的记录 */
  getModifiedValue() {
    return this.refObjectPropertyPanel.value?.getModifiedValue()
  }

  /** 刷新 */
  refresh() {
    return this.refObjectPropertyPanel.value?.refresh()
  }

  /** 获取面板值 */
  getValue() {
    return this.refObjectPropertyPanel.value?.getValue()
  }

  /**
   * 设置值
   * @param value
   * @returns
   */
  setValue(value: ObjBusinessResult) {
    return this.refObjectPropertyPanel.value?.setValue(value)
  }

  /**
   * 校验表单
   * @returns
   */
  validate() {
    /** 验证表单 */
    return this.refObjectPropertyPanel.value?.validateFormValue()
  }

  /**  */
  async save() {
    this.isLoading.value = true

    /** 保存选项 */
    const option = this.getOption()
    /** 表单修改的数据 */
    const modifiedValue = this.getModifiedValue()
    /** 完整表单数据 */
    const value = this.getValue()! as ObjBusinessBase
    /** 保存参数 */
    const saveObjParams = {
      ...value!,
      modelCode: this.props.modelCode,
      group: this.props.modelGroup,
      id: value.id
    } as CreateObjBusinessParams
    /** 验证表单 */
    const validateResult = this.validate()
    // const validateResult = this.refObjectPropertyPanel.value?.validateFormValue()

    // 如果验证失败
    if (!validateResult) {
      this.isLoading.value = false
      return {
        id: '',
        modelCode: '',
        group: this.props.modelGroup,
        objBusiness: undefined,
        flowObjParam: undefined,
        cancel: false,
        success: false,
        errMsg: ''
      }
    }

    /** 保存结果 */
    const result = await this.saveBusinessObj(saveObjParams)
    result.objBusiness = {
      ...value,
      modelCode: result.objBusiness?.modelCode!,
      id: result.objBusiness?.id!,
      group: this.props.modelGroup
    }

    this.isLoading.value = false

    if (!result.success) {
      KNotification.error({
        title: '系统提示',
        content: '对象创建保存失败',
        details: result.errMsg ? result.errMsg : '对象创建保存失败'
      })
      return
    }

    // 打开对象
    if (option[EnumSaveOption.OPEN_OBJ] && result.objBusiness) {
      // 打开对象
      ObjectClientSrv.openObj(result.objBusiness)
    }

    // 打开流程
    if (option[EnumSaveOption.OPEN_FLOW_OBJ] && result.flowObjParam) {
      // 打开对象
      ObjectClientSrv.openObj(result.flowObjParam)
    }

    // 保留属性
    if (option[EnumSaveOption.SAVE_PROPERTY]) {
      // 刷新
      this.refObjectPropertyPanel.value?.refresh()
    }

    return result
  }

  /**
   * 获取业务对象
   * @returns
   */
  private async getBusinessObj(params: ObjectCreateLoadDataParams): Promise<ObjBusinessResult | undefined> {
    if (this.props.loadData) {
      return this.props.loadData(params)
    } else {
      return Promise.resolve({} as ObjBusinessResult) //ObjectClientSrv.createObjBusiness(this.props.group, params.modelCode, params.objCopyMode, params.objId)
    }
  }

  /**
   * 保存业务对象
   * @param params
   */
  protected async saveBusinessObj(params: CreateObjBusinessParams): Promise<ObjectCreateFormSaveResult> {
    if (this.props.onSave) {
      return this.props.onSave(params)
    }
    // 另存复制文件
    if (params.fileId && params.objCopyMode == EnumObjCopyMode.SaveAs) {
      const value = await FileClientSrv.copyFile({ fileId: params.fileId, fileLocation: params.location })
      Object.assign(params, {
        fileId: value.data.id,
        fileName: value.data.name,
        md5: value.data.md5,
        size: value.data.size,
        location: value.data.location,
        locationDisplay: value.data.displayLocation
      })
    }

    /** 保存结果 */
    const saveResult = await ObjectClientSrv.createBusiness(params)

    if (saveResult) {
      return {
        id: saveResult.id,
        modelCode: saveResult.modelCode,
        modelGroup: this.props.modelGroup,
        objBusiness: saveResult,
        flowObjParam: undefined,
        cancel: false,
        success: true,
        errMsg: ''
      }
    }

    return {
      id: '',
      modelCode: '',
      modelGroup: this.props.modelGroup,
      objBusiness: undefined,
      flowObjParam: undefined,
      cancel: false,
      success: false,
      errMsg: ''
    }
  }

  /**
   * 获取保存选项
   * @returns
   */
  getOption() {
    return this.createOptionValue.value
  }

  /**
   * 加载表单数据
   * @description
   */
  onLoadData = async (): Promise<ObjBusinessResult | undefined> => {
    // 设置为加载状态
    this.isLoading.value = true

    /** 创建面板加载参数 */
    const params: ObjectCreateLoadDataParams = {
      modelCode: this.props.modelCode,
      objCopyMode: this.props.objCopyMode,
      objId: this.props.objId,
      group: this.props.modelGroup,
      masterId: this.props.masterId,
      iteratorId: this.props.iteratorId
    }

    const result = (this.getBusinessObj(params) as unknown) as ObjBusinessBase

    // 设置为加载完成状态
    this.isLoading.value = false

    // 返回加载数据
    return result
  }
}

import { IMenuPagePathMapping } from '@kmsoft/upf-core'
import FolderManage from '../pages/folder-manage'
import PersonalWorkspace from '../pages/workspace/personal-workspace'
import KWorkspaceList from '../pages/workspace/product-workspace/workspace-list'
import PublicWorkspace from '../pages/workspace/public-workspace'

export default class ModuleMenuExpose implements IMenuPagePathMapping {
  getMenus() {
    return [
      { target: 'folder-manage', component: FolderManage },
      { target: 'personal-workspace', component: PersonalWorkspace },
      { target: 'prduct-workspace-list', component: KWorkspaceList },
      { target: 'public-workspace', component: PublicWorkspace }
    ]
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KDistributionRecordObjGridPropOptions, KDistributionRecordObjGridEventEmits } from './interface'
import KDistributionRecordObjGridViewModel from './KDistributionRecordObjGridViewModel'

export default defineView({
  name: 'KDistributionRecordObjGrid',
  props: KDistributionRecordObjGridPropOptions,
  emits: KDistributionRecordObjGridEventEmits,
  viewModel: KDistributionRecordObjGridViewModel
})

import {
  DataGridLoadResult,
  EnumDialogResult,
  EnumToolStripCompType,
  IQuery,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  SimpleViewModel,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KPersonalCreateChangeActivityGridEmitsType, KPersonalCreateChangeActivityGridPropType } from './interface'
import { ref } from 'vue'
import {
  ObjectClientSrv,
  LoginClientSrv,
  Api,
  EnumRequestCode,
  EnumQueryConditionOperator,
  EnumToolStripItemKeys,
  EnumSupportObjectNumMode
} from '@kmsoft/ebf-common'
import lodash, { debounce } from 'lodash'
import { KChangeOrderEdit, KChangeOrderEditViewModel } from '../../../../pages/change-order/change-order-edit'
import { WorkflowClientSrv } from '../../../../../../ebf-workflow/src'

/** KPersonalCreateChangeActivityGrid */
export default class KPersonalCreateChangeActivityGridViewModel extends SimpleViewModel<
  KPersonalCreateChangeActivityGridEmitsType,
  KPersonalCreateChangeActivityGridPropType
> {
  gridData = ref<any[]>([])
  refDataGrid = ref<KDataGridViewModel>()
  inputValue = ref<string>('')
  selectValue = ref<string>('')
  toolStripItems = ref<any>([
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
      title: '加入流程',
      icon: 'AddToFlow',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
    }
  ])
  constructor(options: ViewModelOptions<KPersonalCreateChangeActivityGridPropType>) {
    super(options)
  }

  viewDidMount() {}

  public setValue(newValue: any, setChanged?: boolean) {
    this.gridData.value = lodash.cloneDeep(newValue)
  }

  queryData = debounce(() => {
    this.refDataGrid.value?.clearSelectedRows()
    this.refDataGrid.value?.refresh()
  }, 300)

  /**
   * 网格数据加载
   */
  loadData(query: IQuery): Promise<DataGridLoadResult> {
    const user = LoginClientSrv.getUserIdentity()
    const userId = user?.name
    const finalConditons = []
    finalConditons.push({
      conditionName: 'creator',
      operator: EnumQueryConditionOperator.EQUAL,
      conditionValues: [userId]
    })
    finalConditons.push({
      conditionName: 'projectMasterId',
      operator: EnumQueryConditionOperator.IS_NULL
    })

    if (this.inputValue.value !== '') {
      const inputValueCondition = {
        conditions: [
          {
            conditionName: 'name',
            operator: EnumQueryConditionOperator.LIKE,
            conditionValues: [this.inputValue.value]
          },
          {
            conditionName: 'number',
            operator: EnumQueryConditionOperator.LIKE,
            conditionValues: [this.inputValue.value]
          }
        ],
        joiner: 'or'
      }
      finalConditons.push(inputValueCondition)
    }

    if (this.selectValue.value) {
      finalConditons.push({
        conditionName: 'lifecycleState.internalName',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: [this.selectValue.value]
      })
    }

    const params = {
      data: [
        {
          sort: 'DESC',
          orderBy: 'lastUpdateTime',
          filter: {
            conditions: finalConditons,
            joiner: 'and'
          },
          isNeedTotal: true
        },
        {
          currentPage: query.page?.pageIndex,
          pageSize: query.page?.pageSize
        }
      ]
    }
    return new Promise((resolve, reject) => {
      Api.post('change', 'ChangeActivity', 'page', params).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS && res.data.data) {
          const data = {
            rows: res.data.data,
            total: res.data.page.totalRows
          }
          resolve(data)
        } else {
          resolve({
            rows: [],
            total: 0
          })
        }
      })
    })
  }

  showEdit(id: any) {
    KDialog.show({
      title: '编辑变更单',
      size: { width: 1000, height: 600 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KChangeOrderEdit,
      props: {
        objParam: {
          id: id,
          modelCode: 'ChangeOrder',
          modelGroup: 'change'
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KChangeOrderEditViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // if (await formViewModel.save()) {
        //   this.refObjGrid?.value?.refresh()
        // } else {
        //   event.cancel = true
        // }
      }
    })
  }

  /** 加入流程 **/
  addToWorkflow() {
    const rows = this.refDataGrid.value!.getSelectedRows()
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一个对象'
      })
      return
    }
    const partParams = rows.map(row => {
      return {
        objID: row.id,
        objClsCode: row.className,
        name: row.name,
        number: row.number,
        status: row.lifecycleStateCode
      }
    })
    WorkflowClientSrv.addObjectsToWorkflow(partParams)
  }

  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW:
        this.addToWorkflow()
        break
    }
  }

  public getValue() {
    return this.gridData.value || []
  }

  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: any) {
    const param = {
      id: row?.targetId || row.id,
      modelCode: row!.targetExtensionType || row.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.targetExtensionType || row.className)
    }
    ObjectClientSrv.openObj(param)
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KBaselineCompareGridDiffPropOptions, KBaselineCompareGridDiffEventEmits } from './interface'
import KBaselineCompareGridDiffViewModel from './KBaselineCompareGridDiffViewModel'

export default defineView({
  name: 'KBaselineCompareGridDiff',
  props: KBaselineCompareGridDiffPropOptions,
  emits: KBaselineCompareGridDiffEventEmits,
  viewModel: KBaselineCompareGridDiffViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

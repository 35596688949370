
import { defineView, KGridLayoutConfig, SimpleElementItem, VNodeProps } from '@kmsoft/upf-core'
import { createCommentVNode, createVNode } from 'vue'
import { KDynamicViewViewModel } from './KDynamicViewViewModel'
import KWrapperControl, { KWrapperControlEventEmitsType, KWrapperControlPropType } from './wrapper'
import { KDynamicViewEventEmits, KDynamicViewPropOptions } from './interface'
import { KRenderLayout, KRenderLayoutEmitsType, KRenderLayoutPropType, RenderLayoutItemSlotProps } from '../render'

/** 动态渲染器 */
export default defineView({
  inheritAttrs: false,
  name: 'KDynamicView',
  viewModel: KDynamicViewViewModel,
  props: KDynamicViewPropOptions,
  emits: KDynamicViewEventEmits,
  watch: {},
  setup(props, { attrs, slots, emit, vm }) {
    return () => {
      if (!vm.schema.value) {
        return createCommentVNode('KDynamicView')
      }

      /** 布局 */
      const schema = vm.schema.value

      /** 网格布局参数 */
      const renderLayoutProps: VNodeProps<KRenderLayoutPropType, KRenderLayoutEmitsType> = {
        ref_key: `ref_DynamicViewGridLayout_${schema.name}`,
        designMode: false,
        elements: schema.controls,
        containerConfig: schema.props as KGridLayoutConfig,
        layoutType: props.layoutType
      }

      /** 渲染器插槽 */
      const renderLayoutSlots = {
        item: (slotProps: RenderLayoutItemSlotProps) => {
          /** 当前元素 */
          const element = slotProps.element
          /** 参数 */
          const wrapperControlProps: VNodeProps<KWrapperControlPropType, KWrapperControlEventEmitsType> = {
            ref_key: `refWrapperControl_${element.name}`,
            meta: element as SimpleElementItem,
            parentLayout: schema.props as KGridLayoutConfig,
            instance: this,
            readonly: props.readonly,
            getControlOptions: props.getControlOptions
          }

          /** 创建节点 */
          const vNode = createVNode(KWrapperControl, wrapperControlProps)

          return vNode
        }
      }

      return createVNode(KRenderLayout, renderLayoutProps, renderLayoutSlots)
    }
  }
})

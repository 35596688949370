import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_data_grid, {
      dataSource: _ctx.dataSource,
      ref: "refDataGrid",
      isMultipleSelection: false
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "编码",
          dataPropertyName: "number",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "名称",
          dataPropertyName: "name",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          headerText: "实体类型",
          dataPropertyName: "className",
          align: "center",
          width: "150"
        }, {
          default: _withCtx(({cellValue}) => [
            _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "创建时间",
          dataPropertyName: "createTime",
          align: "center",
          width: "100"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          headerText: "创建者",
          dataPropertyName: "creator",
          align: "center",
          width: "100"
        }, {
          default: _withCtx(({ cellValue}) => [
            _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["dataSource"])
  ]))
}
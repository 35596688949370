
import { EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import { KPartCollectFormPropOptions, KPartCollectFormEventEmits } from './interface'
import KPartCollectFormViewModel from './KPartCollectFormViewModel'

export default defineView({
  name: 'KPartCollectForm',
  props: KPartCollectFormPropOptions,
  emits: KPartCollectFormEventEmits,
  viewModel: KPartCollectFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      pagination: { pageIndex: 1, pageSize: 100 },
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KDocExtractFilesPropOptions, KDocExtractFilesEventEmits } from './interface'
import KDocExtractFilesViewModel from './KDocExtractFilesViewModel'
import { BaselineCompareGridDataInter } from '../../../../../ebf-baseline/src/pages/baseline-compare/baseline-compare-grid/interface'

export default defineView({
  name: 'KDocExtractFiles',
  props: KDocExtractFilesPropOptions,
  emits: KDocExtractFilesEventEmits,
  viewModel: KDocExtractFilesViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const getCellClass = ({ row, column }: { row: BaselineCompareGridDataInter; column: any }) => {
      let customClass = ''
      // 参考基线不显示属性差异标色
      if (['number', 'name'].includes(column.dataPropertyName)) {
        customClass = 'custom-cell'
      }
      return { customClass }
    }
    return { emit, getCellClass }
  }
})

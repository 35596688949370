import { ref, watch } from 'vue'
import {
  BaseViewModel,
  EnumDialogResult,
  IKDataGrid,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  ViewModelOptions,
  utils
} from '@kmsoft/upf-core'
import {
  KSelectEditorCustomPropType,
  KSelectEditorCustomPropsEventEmitsType,
  ISelectEditorFormDefinition,
  showDisplayValueColumnEditorTypeList
} from './interface'
import EnumsPanel, { KEnumsPanelViewModel } from './enums-panel'
import { Api, EnumRequestCode } from '../../../../../../../client-srv'
// import { enumDef } from '../../../../../../../../api'

export class KSelectEditorFormViewModel extends BaseViewModel<
  KSelectEditorCustomPropsEventEmitsType,
  KSelectEditorCustomPropType
> {
  formState = ref<ISelectEditorFormDefinition>({ optionType: 'options' })

  /** ref grid */
  refCustomOptionGird = ref<KDataGridViewModel>()

  /** ref 枚举类型显示网格 */
  refCustomEnumGird = ref<KDataGridViewModel>()

  /** 自定义options方式的网格columns */
  columns = ref<any>([
    { name: 'label', title: '显示值' },
    { name: 'value', title: '取值' }
  ])

  constructor(options: ViewModelOptions<KSelectEditorCustomPropType>) {
    super(options)

    watch(
      () => options.props.propertyId,
      () => {
        // 判断editArgs是否为空
        const data = options.props.editArgs ? JSON.parse(options.props.editArgs) : { optionType: 'options' }
        this.formState.value = data
      },
      { immediate: true }
    )

    watch(
      () => options.props.editType,
      () => {
        this.toggleShowDisplayColumn()
      }
    )

    watch(
      () => this.formState.value.refEnumId,
      async newVal => {
        if (!newVal) {
          this.refCustomEnumGird.value?.setDataSource([])
          return
        }
        const data = [this.formState.value.refEnumId]
        // 根据当前选择的枚举id，获取枚举类型的options，展示到枚举类grid网格上
        const enumDetailResult = await Api.post('official', 'EnumService', 'getByCode', { data: data }) //enumDef.fetchEnumDetail({ object: { enumId: newVal } })

        if (enumDetailResult.code === EnumRequestCode.SUCCESS) {
          const data = enumDetailResult.data?.enumValueList?.map((item: { id: any; displayValue: any; actualValue: any }) => {
            return {
              id: item.id,
              label: item.displayValue,
              value: item.actualValue
            }
          })!

          this.refCustomEnumGird.value?.setDataSource(data)
        }
      },
      { immediate: true }
    )
  }

  viewDidMount() {
    this.refCustomOptionGird.value?.beginEdit()
  }

  // 设置表单的值
  setValue(formState: ISelectEditorFormDefinition) {
    this.formState.value = formState
    this.toggleShowDisplayColumn()
  }

  // 获取表单的值
  getValue() {
    this.refCustomOptionGird.value?.endEdit()

    // 2. 自定义下拉项
    const rows = this.refCustomOptionGird.value?.getRows()!
    const editType = this.props.editType!
    this.formState.value!.options = rows.map(item => {
      const label = showDisplayValueColumnEditorTypeList.includes(editType) ? item.label : item.value
      return { id: item.id, label, value: item.value }
    })

    return this.formState.value
  }

  /** 引入枚举类型 */
  toSelectRefEnum() {
    // 显示对象属性选择modal
    KDialog.show({
      title: '选择枚举类型',
      size: { width: 718, height: 420 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: EnumsPanel,
      onClosing: async (event: KDialogClosingEvent) => {
        const vm = event.viewModel as KEnumsPanelViewModel

        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const { refEnumId, refEnumCode, refEnumName } = vm.getValue()
        this.formState.value = {
          ...this.getValue()!,
          refEnumId,
          refEnumCode,
          refEnumName
        }
      }
    })
  }

  /** 新增下拉选项 */
  addOption() {
    const newRow = {
      id: utils.uuid(10),
      label: '',
      value: ''
    }
    // 插入新行
    this.refCustomOptionGird.value?.insertRow(newRow)
  }

  /**
   * 移除下拉选项
   */
  removeOption() {
    // 移除选中的行
    this.refCustomOptionGird.value?.removeSelectedRows()
  }

  /**
   * 列 显示/隐藏
   * 网格ready时，editorType切换时
   */
  toggleShowDisplayColumn() {
    const editType = this.props.editType!
    if (showDisplayValueColumnEditorTypeList.includes(editType)) {
      this.refCustomOptionGird.value?.hideColumn('label', false)
    } else {
      this.refCustomOptionGird.value?.hideColumn('label', true)
    }
  }
}

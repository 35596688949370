import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KSelectEventEmits, KSelectPropOptions } from '@kmsoft/upf-core/src/web-controls/select/interface'

/**
 * 枚举类型
 */
export enum EnumOptionsType {
  DATA_SOURCE_LINK = 'dataSourceLink',
  OPTIONS = 'options',
  Enum = 'enums'
}

/** 参数 **/
export const KSelectWrapperPropOptions = {
  ...KSelectPropOptions,
  /**候选项类型 */
  optionType: VuePropTypes.createType<EnumOptionsType>().def(EnumOptionsType.OPTIONS),
  /**枚举名称 */
  refEnumName: VuePropTypes.string().def(),
  /**枚举名称 */
  refEnumCode: VuePropTypes.string().def(),
  /**枚举ID */
  refEnumId: VuePropTypes.string().def(),
  /**数据源链接 */
  dataSourceLink: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KSelectWrapperPropType = ViewPropsTypeExtract<typeof KSelectWrapperPropOptions>

/** 事件 */
export const KSelectWrapperEventEmits = {
  ...KSelectEventEmits
}

/** 事件类型 **/
export type KSelectWrapperEmitsType = ViewEmitsTypeExtract<typeof KSelectWrapperEventEmits>

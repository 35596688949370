import { Agent, FileClientSrv } from '@kmsoft/ebf-common'
import { DocTool } from './DocTool'
import { Doc } from '../beans/Doc'
import { DocClientSrv } from './DocClientSrv'
import { EnumDocToolType, EnumOperatorType } from './enums'
import { KNotification } from '@kmsoft/upf-core'

export class DocToolCommon extends DocTool {
  static ClassName = 'DocToolCommon'
  protected static typeSupported: EnumDocToolType = EnumDocToolType.Browser | EnumDocToolType.Editor | EnumDocToolType.Downloader
  public async execute(doc: Doc): Promise<void> {
    switch (this.type) {
      case EnumDocToolType.Browser:
        return this.browse(doc)
      case EnumDocToolType.Downloader:
        return this.download(doc)
      case EnumDocToolType.Editor:
        return this.edit(doc)
      case EnumDocToolType.Markup:
      case EnumDocToolType.Printer:
      case EnumDocToolType.Reference:
      default:
        break
    }
  }

  private async browse(doc: Doc): Promise<void> {
    if (!doc) return

    const docs = await DocClientSrv.selectDocs(doc, EnumOperatorType.Browser)
    if (!docs) return

    // 代理打开
    if (await Agent.AgentManager.initialize()) {
      //下载
      await DocClientSrv.downloadDocsByAgent(docs.downLoadList, docs.options.selectedFilePath)
      if (doc.fileName) {
        const process = await Agent.Process.create()
        const startinfo = await process.StartInfo()
        await startinfo.UseShellExecute(true)
        await startinfo.FileName(await Agent.Path.Combine(docs.options.selectedFilePath, doc.fileName))

        process.Start()
        // process.Exited(this.openAlert)
      }
    } else {
      //下载
      DocClientSrv.downloads(docs.downLoadList)
    }
  }

  private async edit(doc: Doc): Promise<void> {
    if (!doc) return

    const docs = await DocClientSrv.selectDocs(doc, EnumOperatorType.Editor)
    if (!docs) return

    //下载
    DocClientSrv.downloads(docs.downLoadList)

    //打开
    if (await Agent.AgentManager.initialize()) {
      if (doc.localFilePath) await Agent.Process.Start(doc.localFilePath)
    }
  }

  private async download(doc: Doc): Promise<void> {
    if (!doc) return

    const docs = await DocClientSrv.selectDocs(doc, EnumOperatorType.Download)
    if (!docs) return

    if (await Agent.AgentManager.initialize()) {
      // 检查同名文件
      for (const doc of docs.downLoadList) {
        if (doc.fileId && doc.fileName) {
          const fullPath = await Agent.Path.Combine(docs.options.selectedFilePath, doc.fileName)
          const isExists = await Agent.File.Exists(fullPath)
          if (isExists) {
            KNotification.error({
              title: '下载失败',
              content: `文件[${doc.fileName}]已存在`
            })
            return
          }
        }
      }
      // 下载操作
      await DocClientSrv.downloadDocsByAgent(docs.downLoadList, docs.options.selectedFilePath)
      KNotification.success('下载成功')
    }
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KBasePropertyEditorPropOptions, KBasePropertyEditorEventEmits } from './interface'
import KBaseEditorViewModel from './KBasePropertyEditorViewModel'

export default defineView({
  name: 'KBaseEditor',
  props: KBasePropertyEditorPropOptions,
  emits: KBasePropertyEditorEventEmits,
  viewModel: KBaseEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

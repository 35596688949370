import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeActivityCreateView from './KChangeActivityCreate.vue'
import KChangeActivityCreateViewModel from './KChangeActivityCreateViewModel'

const KChangeActivityCreate = connect(KChangeActivityCreateView, KChangeActivityCreateViewModel)

export default withInstall(KChangeActivityCreate)
export { KChangeActivityCreate, KChangeActivityCreateView, KChangeActivityCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { AgentStaticObject } from '../../../AgentStaticObject'

import { ImageFormat } from '../../../system/drawing/imaging/ImageFormat'
import { SpecialFolder } from '../../../system/Environment.SpecialFolder'

import Lodash from 'lodash'

/**Windows API*/
export class NativeMethods extends AgentStaticObject {
  protected static type: string = 'KMSoft.Base.WindowsAPIExtensions.NativeMethods,KMSoft.Base.WindowsAPIExtensions'

  /**指定类名和标题文本的最大长度。*/
  public static readonly MAX_PATH = 260
  /**指定字符串缓存的最大长度。*/
  public static readonly MAX_BUFFER = 32767

  /**Retrieve the display name for the file.
   * @param pszPath A pointer to a null-terminated string of maximum length {@link MAX_PATH} that contains the path and file name.
   * @param argActual Indicates that the function should attempt to access the file specified by {@link pszPath}.
   * @returns The name as it appears in Windows Explorer.*/
  public static async GetFileDisplayName(pszPath: string, argActual: boolean): Promise<string>
  /**Retrieve the display name for the file.
   * @param pszPath A pointer to a null-terminated string of maximum length {@link MAX_PATH} that contains the path and file name.
   * @param argActual Indicates that the function should attempt to access the file specified by {@link pszPath}.
   * @returns The name as it appears in Windows Explorer.*/
  public static async GetFileDisplayName(pszPath: SpecialFolder): Promise<string>
  /**Retrieve the display name for the file.
   * @param pszPath A pointer to a null-terminated string of maximum length {@link MAX_PATH} that contains the path and file name.
   * @param argActual Indicates that the function should attempt to access the file specified by {@link pszPath}.
   * @returns The name as it appears in Windows Explorer.*/
  public static async GetFileDisplayName(pszPath: string | SpecialFolder, argActual: boolean = false): Promise<string> {
    return Lodash.isString(pszPath)
      ? this.invokeStaticMethod('GetFileDisplayName', pszPath, argActual)
      : this.invokeStaticMethod('GetFileDisplayName', pszPath)
  }

  /**Retrieve the string that describes the file's type.
   * @param pszPath A pointer to a null-terminated string of maximum length {@link MAX_PATH} that contains the path and file name.
   * @param argActual Indicates that the function should attempt to access the file specified by {@link pszPath}.
   * @returns String that describes the file's type.*/
  public static async GetFileTypeName(pszPath: string, argActual: boolean): Promise<string>
  /**Retrieve the string that describes the file's type.
   * @param pszPath A pointer to a null-terminated string of maximum length {@link MAX_PATH} that contains the path and file name.
   * @param argActual Indicates that the function should attempt to access the file specified by {@link pszPath}.
   * @returns String that describes the file's type.*/
  public static async GetFileTypeName(pszPath: SpecialFolder): Promise<string>
  /**Retrieve the string that describes the file's type.
   * @param pszPath A pointer to a null-terminated string of maximum length {@link MAX_PATH} that contains the path and file name.
   * @param argActual Indicates that the function should attempt to access the file specified by {@link pszPath}.
   * @returns String that describes the file's type.*/
  public static async GetFileTypeName(pszPath: string | SpecialFolder, argActual: boolean = false): Promise<string> {
    return Lodash.isString(pszPath)
      ? this.invokeStaticMethod('GetFileTypeName', pszPath, argActual)
      : this.invokeStaticMethod('GetFileTypeName', pszPath)
  }

  /**Retrieve the image that represents the file's type.
   * @param pszPath A pointer to a null-terminated string of maximum length {@link MAX_PATH} that contains the path and file name.
   * @param argActual Indicates that the function should attempt to access the file specified by {@link pszPath}.
   * @param argSmall Retrieve the file's small icon.
   * @param argFormat 图片的格式。
   * @returns Image that represents the file's type.*/
  public static async GetFileTypeBitmapBytes(
    pszPath: string,
    argActual: boolean,
    argSmall: boolean,
    argFormat?: ImageFormat
  ): Promise<string>
  /**Retrieve the image that represents the file's type.
   * @param pszPath A pointer to a null-terminated string of maximum length {@link MAX_PATH} that contains the path and file name.
   * @param argActual Unused.
   * @param argSmall Retrieve the file's small icon.
   * @param argFormat 图片的格式。
   * @returns Image that represents the file's type.*/
  public static async GetFileTypeBitmapBytes(
    pszPath: SpecialFolder,
    argActual: boolean,
    argSmall: boolean,
    argFormat?: ImageFormat
  ): Promise<string>
  public static async GetFileTypeBitmapBytes(
    pszPath: string | SpecialFolder,
    argActual: boolean = false,
    argSmall: boolean = false,
    argFormat: ImageFormat
  ): Promise<string> {
    return Lodash.isString(pszPath)
      ? this.invokeStaticMethod('GetFileTypeBitmapBytes', pszPath, argActual, argSmall, argFormat ?? (await ImageFormat.Bmp()))
      : this.invokeStaticMethod('GetFileTypeBitmapBytes', pszPath, argSmall, argFormat ?? (await ImageFormat.Bmp()))
  }
}

<template>
  <span class="layout-switcher action" @click="onClick"><k-icon :type="layout" /></span>
</template>

<script lang=" ts ">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
export default defineComponent({
  name: 'KExtendLayoutSwitcher',
  computed: {
    ...mapState({
      layout: state => `${state.layout}-layout`
    })
  },
  methods: {
    ...mapActions(['switchLayout']),
    onClick() {
      this.switchLayout()
    }
  }
})
</script>

<style lang="less">
.layout-switcher {
  top: 2px;
  // font-size: 14px;
  svg.svg-icon {
    width: 1.2em !important;
    height: 1.2em !important;
    line-height: 1.2em !important;
  }
}
</style>

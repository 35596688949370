import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectRelationView from './KObjectRelation.vue'
import KObjectRelationViewModel from './KObjectRelationViewModel'

const KObjectRelation = connect(KObjectRelationView, KObjectRelationViewModel)

export default withInstall(KObjectRelation)
export { KObjectRelation, KObjectRelationView, KObjectRelationViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KDocTemplatePropOptions = {
  ...BaseViewPropOptions,
  documentType: VuePropTypes.string().def('')
}

/** 参数类型 **/
export type KDocTemplatePropType = ViewPropsTypeExtract<typeof KDocTemplatePropOptions>

/** 事件 */
export const KDocTemplateEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDocTemplateEmitsType = ViewEmitsTypeExtract<typeof KDocTemplateEventEmits>

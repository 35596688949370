
import lodash from 'lodash'
import { computed, defineComponent, getCurrentInstance, ref } from 'vue'
import {
  AppContext,
  IIdentity,
  IIdentityStorage,
  request,
  UPF_IOC_KEYS,
  VuePropTypes,
  defineView,
  eventEmitter,
  EventArgs
} from '@kmsoft/upf-core'
import { EnumLoginState } from './enums'
import { EBF_IOC_KEY, Api, EnumRequestCode, sysSrv } from '@kmsoft/ebf-common'
import KSsoLoginViewModel from './KSSOLoginViewModel'

/** 单点登录表单框 */
export default defineView({
  inheritAttrs: false,
  viewModel: KSsoLoginViewModel,
  props: {},
  setup(props, { vm }) {
    const loginState = ref<EnumLoginState>()
    const loginMsg = ref<string>('正在登录中......')

    /** 初始化 */
    const init = async () => {
      loginState.value = EnumLoginState.LoggingIn
      const setIntervalId = ref<any>()

      // 获取当前页面的 URL
      const currentUrl = window.location.href

      // 创建一个 URL 对象
      const url = new URL(currentUrl)

      // 使用 URLSearchParams 对象获取参数
      const params = new URLSearchParams(url.search)

      // 获取 'code' 参数
      const code = params.get('code')

      // 如果code不存在则跳转到http://110.41.125.166/basic/mgmt/api/v1/oauth2/authorize?response_type=code&client_id=BFAchY9inzXTCIUcIr70tdIYFCEEf2HR89AwGNAbYbEkYKlX7MpUOXR_2vB9y-yO&redirect_uri=https://plm.kmsoft.com&state=11
      if (!code) {
        const url = vm.getProperty('url.loginAuthorize') as string
        window.open(url)
      }

      //根据code获取token
      // 定义要发送的数据
      const postData = {
        grantType: vm.getProperty('url.grantType'),
        clientId: vm.getProperty('url.clientId'),
        clientSecret: vm.getProperty('url.clientSecret'),
        code: code,
        redirectUri: vm.getProperty('url.redirectUri')
      }
      const res = await Api.post('common', 'SSOService', 'getUserInformation', { data: [postData] })
      if (res && res.code == EnumRequestCode.SUCCESS) {
        const identity = {
          id: res.data.userId,
          name: res.data.userName,
          code: res.data.userName,
          token: res.data.token,
          exprisTime: Number(res.data.tokenExpireTime) * 1000
        } as IIdentity
        //存储登录凭证
        AppContext.current
          .getIocContainer()
          .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
          .set(identity)
        //存储refreshToken
        if (sysSrv.isAgentLogin()) {
          eventEmitter.emit(EBF_IOC_KEY.AGENT_LOGIN, 'agentAfterLoad', (identity.token as unknown) as EventArgs)
        } else {
          // 跳转主页
          localStorage.setItem('refreshToken', res.data.refreshToken)
          loginMsg.value = '登录成功,正在进入KMSAASPLM......'
          setTimeout(() => {
            AppContext.current.getApplication().router.vueRouter.replace('./homePage')
          }, 2000)
        }
        //开启定时任务，周期为两个小时
        setIntervalId.value = setInterval(() => {
          refreshToken()
        }, 1 * 60 * 60 * 1000 + 55 * 60 * 1000)

        //两小时后开始第一次执行
        setTimeout(() => {
          refreshToken()
        }, 1 * 60 * 60 * 1000 + 55 * 60 * 1000)
      } else {
        const url = vm.getProperty('url.loginAuthorize') as string
        window.location.replace(url)
      }
    }

    /**定时任务 */
    const refreshToken = async () => {
      //获取refreshToken
      const refreshToken = localStorage.getItem('refreshToken')
      const params = {
        grantType: 'refresh_token',
        clientId: vm.getProperty('url.clientId'),
        clientSecret: vm.getProperty('url.clientSecret'),
        refreshToken: refreshToken
      }
      const res = await Api.post('common', 'SSOService', 'refreshToken', { data: [params] })
      if (res && res.code == EnumRequestCode.SUCCESS) {
        const identity = {
          id: res.data.userId,
          name: res.data.userName,
          code: res.data.userName,
          token: res.data.token,
          exprisTime: Number(res.data.tokenExpireTime) * 1000
        } as IIdentity
        //先清理再重新存储
        AppContext.current
          .getIocContainer()
          .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
          .clear()
        //存储登录凭证
        AppContext.current
          .getIocContainer()
          .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
          .set(identity)
        //存储refreshToken
        localStorage.setItem('refreshToken', res.data.refreshToken)
      } else {
        const url = vm.getProperty('url.loginAuthorize') as string
        window.location.replace(url)
      }
    }

    init()

    return {
      EnumLoginState,
      loginState,
      loginMsg
    }
  }
})

import { AppContext, KDataGridColumn } from '@kmsoft/upf-core'
import lodash from 'lodash'
import { Component, resolveComponent } from 'vue'
import * as Editors from '../../controls/editors'
import { IOC_COMPONENT_KEYS_BASE } from '../../ioc-conponents'
import { MetaProperty } from '../meta'
import { KObjectClassGridBaseColumn } from '../../controls/editors'

/** 编辑组件工场 */
export class EditorFactory {
  /**
   * 获取编辑器
   * @param field
   * @returns
   */
  static getEditor(field: MetaProperty): Component | undefined {
    /** 获取所有编辑器 */
    const customEditors = this.getEditors()

    // 循环所有编辑器
    for (const iterator of customEditors) {
      const editor = iterator.getEditor(field)
      if (editor) {
        const component = lodash.isString(editor) ? resolveComponent(editor) : editor()
        return component as Component
      }
    }
  }

  /**
   * 获取列
   * @param field
   * @returns
   */
  static getColumn(control: string): KDataGridColumn | undefined {
    /** 获取所有编辑器 */
    const customEditors = this.getEditors()
    // 循环所有自定义列
    for (const iterator of customEditors) {
      const editor = (iterator as any).name && (iterator as any).name == control //iterator.getEditor(field)
      if (editor) {
        return (iterator as unknown) as KDataGridColumn
      }
    }
  }

  /** 获取所有编辑器 */
  private static getEditors() {
    /** 获取所有编辑器 */
    const customEditors = AppContext.current
      .getIocContainer()
      .getBeans<Editors.IFieldEditorResolver>(IOC_COMPONENT_KEYS_BASE.FIELD_EDITOR_RESOLVER)
    return customEditors
  }
}

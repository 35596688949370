import { connect, withInstall } from '@kmsoft/upf-core'
import KTempUserSelectorView from './KTempUserSelector.vue'
import KTempUserSelectorViewModel from './KTempUserSelectorViewModel'

const KTempUserSelector = connect(KTempUserSelectorView, KTempUserSelectorViewModel)

export default withInstall(KTempUserSelector)
export * from './interface'
export { KTempUserSelector, KTempUserSelectorView, KTempUserSelectorViewModel }
// 模板生成文件
// export * from './{{folderName}}'

import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { KBasePropertyEditorEventEmits, KBasePropertyEditorPropOptions } from '../../base'

/** 参数 **/
export const KSelectFillEditorPropOptions = {
  ...KBasePropertyEditorPropOptions
}

/** 参数类型 **/
export type KSelectFillEditorPropType = ViewPropsTypeExtract<typeof KSelectFillEditorPropOptions>

/** 事件 */
export const KSelectFillEditorEventEmits = {
  ...KBasePropertyEditorEventEmits
}

/** 事件类型 **/
export type KSelectFillEditorEmitsType = ViewEmitsTypeExtract<typeof KSelectFillEditorEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KIssueReportEditView from './KIssueReportEdit.vue'
import KIssueReportEditViewModel from './KIssueReportEditViewModel'

const KIssueReportEdit = connect(KIssueReportEditView, KIssueReportEditViewModel)

export default withInstall(KIssueReportEdit)
export { KIssueReportEdit, KIssueReportEditView, KIssueReportEditViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, KDataGridCellEditableParams, KDataGridViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KPartMaterialBomFormEmitsType, KPartMaterialBomFormPropType } from './interface'
import { Api, CommonClientSrv, EnumRequestCode } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KPartMaterialBomForm */
export default class KPartMaterialBomFormViewModel extends BaseViewModel<
  KPartMaterialBomFormEmitsType,
  KPartMaterialBomFormPropType
> {
  refMaterialGrid = ref<KDataGridViewModel>()

  constructor(options: ViewModelOptions<KPartMaterialBomFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  async loadData() {
    const selectedNode = this.props.selectedNode!
    const param = {
      data: [
        {
          root: {
            id: selectedNode.id,
            clazz: selectedNode.modelCode
          },
          expandType: 'FORWARD_EXPANSION',
          queryRule: { type: 'LATEST' },
          view: selectedNode.partView,
          maxLevel: 99
        }
      ]
    }
    const result = await Api.post('part', 'Part', 'listBomLink', param)
    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      setTimeout(() => {
        this.refMaterialGrid.value?.beginEdit()
      }, 200)
      return result.data
    } else {
      return {
        rows: []
      }
    }
  }

  /**
   * 网格展开
   */
  async expandForGrid() {
    const selectedNode = this.props.selectedNode!
    const param = {
      data: [
        {
          root: {
            id: selectedNode.id,
            clazz: selectedNode.modelCode
          },
          expandType: 'FORWARD_EXPANSION',
          queryRule: { type: 'LATEST' },
          view: 'Design',
          maxLevel: 99
        }
      ]
    }
    const result = await Api.post('part', 'Part', 'expandForTree', param)
    return new Promise((resolve, reject) => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        resolve(result.data)
      } else {
        KNotification.error({
          title: '获取失败',
          content: result.message,
          details: result.detail
        })
        reject(void 0)
      }
    })
  }

  /**
   * 保存
   */
  async batchUpdateBom() {
    this.refMaterialGrid.value?.endEdit(true)

    return new Promise<any>((resolve, reject) => {
      /* const changedRows = this.refMaterialGrid.value?.getChangedRows()!
      const hasModifiedRows = Reflect.has(changedRows, 'modifiedRows')
      if (!hasModifiedRows) {
        resolve(false)
      } else { */
      setTimeout(async () => {
        const changedRows = this.refMaterialGrid.value?.getChangedRows()!
        const modifiedRows = changedRows?.modifiedRows
        if (!modifiedRows || modifiedRows.length == 0) {
          return resolve(false)
        }
        const param = modifiedRows!.map(row => {
          const originalRow = this.refMaterialGrid.value?.getRow(row.findnumberPath)!
          const bomlinkId = originalRow.bomlinkId
          const workingState = originalRow.workingState
          const checkOutUserName = originalRow.checkOutUserName
          const props = Object.assign({}, row)
          delete props.findnumberPath
          return {
            id: originalRow.id,
            bomlinkId: bomlinkId,
            workingState: workingState,
            checkOutUserName: checkOutUserName,
            props: props
          }
        })
        const result = await Api.post('part', 'Part', 'updatePartAndChildMaterialProp', { data: [param] })
        resolve(result)
      }, 400)
      // }
    })
  }

  /**
   * 撤销
   */
  async cancel() {
    this.refMaterialGrid.value?.undoCellEditing()
  }

  /**
   * 设置网格单元格编辑状态
   * @param params
   * @returns
   */
  getCellEditable(params: KDataGridCellEditableParams) {
    const row = params.row
    const column = params.column.dataPropertyName
    if (row && row['findnumberPath'] == '1' && ['quantity', 'occurrence'].includes(column)) {
      return false
    }
    return undefined
  }

  beginEdit() {
    this.refMaterialGrid.value?.beginEdit()
  }

  endEdit() {
    this.refMaterialGrid.value?.endEdit(true)
  }
}

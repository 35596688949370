import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex1" }
const _hoisted_2 = { class: "flex-center" }
const _hoisted_3 = { class: "flex-center mt10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_secction = _resolveComponent("secction")!

  return (_openBlock(), _createBlock(_component_secction, { class: "flex-start flex-direction-column flex-between h100p" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_data_grid, {
          ref: "refDataGrid",
          dataSource: _ctx.$vm.importFileList,
          rowKey: "tempId",
          "is-multiple-selection": false
        }, {
          columns: _withCtx(() => [
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "编码",
              dataPropertyName: "number",
              align: "center",
              width: "150"
            }, {
              default: _withCtx(({ row }) => [
                _createTextVNode(_toDisplayString(row.number), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "文档名称",
              dataPropertyName: "name",
              align: "center",
              width: "150"
            }, {
              default: _withCtx(({ row }) => [
                _createTextVNode(_toDisplayString(row.name), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "文件名",
              dataPropertyName: "fileName",
              align: "center",
              width: "150"
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "入库状态",
              dataPropertyName: "importStateProgress",
              align: "center",
              width: "200"
            }, {
              default: _withCtx(({ row }) => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["mr5", row.importStateProgress.icon == 'loading' ? 'icon-loading' : ''])
                  }, [
                    _createVNode(_component_k_icon, {
                      type: row.importStateProgress.icon
                    }, null, 8, ["type"])
                  ], 2),
                  _createElementVNode("div", null, _toDisplayString(row.importStateProgress.stateName), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "说明",
              dataPropertyName: "importDesc",
              align: "center",
              width: "250"
            }, {
              default: _withCtx(({ row }) => [
                _createTextVNode(_toDisplayString(row.importDesc), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["dataSource"])
      ]),
      _createElementVNode("footer", _hoisted_3, [
        _createVNode(_component_k_button, {
          onClick: _cache[0] || (_cache[0] = () => _ctx.emit('previousStep'))
        }, {
          default: _withCtx(() => [
            _createTextVNode("返回上一步")
          ]),
          _: 1
        }),
        (!_ctx.$vm.allSaved)
          ? (_openBlock(), _createBlock(_component_k_button, {
              key: 0,
              type: "primary",
              onClick: _ctx.$vm.save,
              disabled: _ctx.$vm.saveLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$vm.haveImport ? '重新入库' : '批量入库'), 1)
              ]),
              _: 1
            }, 8, ["onClick", "disabled"]))
          : (_openBlock(), _createBlock(_component_k_button, {
              key: 1,
              type: "primary",
              onClick: _cache[1] || (_cache[1] = () => _ctx.emit('closeDialog'))
            }, {
              default: _withCtx(() => [
                _createTextVNode("关闭")
              ]),
              _: 1
            }))
      ])
    ]),
    _: 1
  }))
}
import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { KObjectClassGridEventEmits, KObjectClassGridPropOptions } from '../object-class-grid'
import { ObjBusinessParam, ObjectRelationDefination } from '../../client-srv'

/** 参数 **/
export const KObjectRelationGridPropOptions = {
  ...KObjectClassGridPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  /**关系定义。*/
  definition: VuePropTypes.createType<ObjectRelationDefination>().def()
}

/** 参数类型 **/
export type KObjectRelationGridPropType = ViewPropsTypeExtract<typeof KObjectRelationGridPropOptions>

/** 事件 */
export const KObjectRelationGridEventEmits = {
  ...KObjectClassGridEventEmits
}

/** 事件类型 **/
export type KObjectRelationGridEmitsType = ViewEmitsTypeExtract<typeof KObjectRelationGridEventEmits>

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KViewRenderElementPropType, KViewRenderElementEventEmitsType } from './interface'

/** 导航管理 */
export default class KDesignerElementViewModel extends BaseViewModel<
  KViewRenderElementEventEmitsType,
  KViewRenderElementPropType
> {
  constructor(options: ViewModelOptions<KViewRenderElementPropType>) {
    super(options)
  }

  onDelete(event: MouseEvent) {
    event.stopPropagation()
    this.emit('delete', this.props.meta)
  }

  /**
   * 点击事件
   * @param e 事件
   */
  onClick(e: Event) {
    e.stopPropagation()
    this.emit('select', true)
  }

  onTop() {
    this.emit('top')
  }

  onBottom() {
    this.emit('bottom')
  }

  onUp() {
    this.emit('up')
  }

  onDown() {
    this.emit('down')
  }
}

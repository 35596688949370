
import { defineView } from '@kmsoft/upf-core'
import { KAffectedObjectCreateActivityPropOptions, KAffectedObjectCreateActivityEventEmits } from './interface'
import KAffectedObjectCreateActivityViewModel from './KAffectedObjectCreateActivityViewModel'

export default defineView({
  name: 'KAffectedObjectCreateActivity',
  props: KAffectedObjectCreateActivityPropOptions,
  emits: KAffectedObjectCreateActivityEventEmits,
  viewModel: KAffectedObjectCreateActivityViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"300px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_user_selector = _resolveComponent("k-user-selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_button, {
      onClick: _ctx.$vm.setValue
    }, {
      default: _withCtx(() => [
        _createTextVNode("设值")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_k_button, {
      onClick: _ctx.$vm.getValue
    }, {
      default: _withCtx(() => [
        _createTextVNode("取值")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_k_button, {
      onClick: _ctx.$vm.setDisabled
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$vm.disabled ? '启用' : '禁用'), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_k_user_selector, {
      isMultipleSelection: false,
      ref: _ctx.$vm.refUserSelector,
      value: _ctx.$vm.selectedValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.selectedValue) = $event)),
      disabled: _ctx.$vm.disabled,
      onChange: _ctx.$vm.change
    }, null, 8, ["value", "disabled", "onChange"])
  ]))
}
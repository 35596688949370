
import { defineView } from '@kmsoft/upf-core'
import { KClassUiDefinePropOptions, KClassUiDefineEventEmits } from './interface'
import KClassUiDefineViewModel from './KClassUiDefineViewModel'
import ClassPropertyList from './tab/property-list'
import KObjectClassLayoutDefinition from './tab/object-class-layout-definition'
import { ref } from 'vue'

export default defineView({
  name: 'KClassUiDefine',
  props: KClassUiDefinePropOptions,
  emits: KClassUiDefineEventEmits,
  viewModel: KClassUiDefineViewModel,
  components: { ClassPropertyList, KObjectClassLayoutDefinition },
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

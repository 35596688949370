import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KSchemaExportPropType, KSchemaExportEventEmitsType } from './interface'
import { ref } from 'vue'

/** 导航管理 */
export default class KSchemaExportViewModel extends BaseViewModel<KSchemaExportEventEmitsType, KSchemaExportPropType> {
  localSchema = ref(this.props.schema)
  constructor(options: ViewModelOptions<KSchemaExportPropType>) {
    super(options)
  }
  getValue() {
    return this.localSchema.value
  }
}

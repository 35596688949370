import { KBasePropertyEditorEventEmits, KBasePropertyEditorPropOptions } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KBaselinePrimaryObjectPropOptions = {
  ...KBasePropertyEditorPropOptions,
  /** 是否只读模式 */
  /** 是否可用 */
  disabled: VuePropTypes.bool()
    .setRequired()
    .def(false),
  /** 是否只读模式 */
  readonly: VuePropTypes.bool()
    .setRequired()
    .def(false),
  /** 值 （TODO:这里可以改为一个完整的对象）*/
  value: VuePropTypes.createType().def()
}

/** 参数类型 **/
export type KBaselinePrimaryObjectPropType = ViewPropsTypeExtract<typeof KBaselinePrimaryObjectPropOptions>

/** 事件 */
export const KBaselinePrimaryObjectEventEmits = {
  ...KBasePropertyEditorEventEmits /** 值改变事件 */,
  change: (value: any) => true
}

/** 事件类型 **/
export type KBaselinePrimaryObjectEmitsType = ViewEmitsTypeExtract<typeof KBaselinePrimaryObjectEventEmits>

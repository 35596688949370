import { connect, withInstall } from '@kmsoft/upf-core'
import KFolderTreeView from './KFolderTree.vue'
import KFolderTreeViewModel from './KFolderTreeViewModel'

const KFolderTree = connect(KFolderTreeView, KFolderTreeViewModel)

export default withInstall(KFolderTree)
export { KFolderTree, KFolderTreeView, KFolderTreeViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KRulePropertyView from './KRuleProperty.vue'
import KRulePropertyViewModel from './KRulePropertyViewModel'

const KRuleProperty = connect(KRulePropertyView, KRulePropertyViewModel)

export default withInstall(KRuleProperty)
export { KRuleProperty, KRulePropertyView, KRulePropertyViewModel }
export * from './tools'
export * from './interface'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { GridDataInter } from './change-activity-update-compare-grid/interface'

/** 参数 **/
export const KChangeActivityUpdateComparePropOptions = {
  ...BaseViewPropOptions,
  sourceData: VuePropTypes.createType<GridDataInter[]>().def([]),
  targetData: VuePropTypes.createType<GridDataInter[]>().def([])
}

/** 参数类型 **/
export type KChangeActivityUpdateComparePropType = ViewPropsTypeExtract<typeof KChangeActivityUpdateComparePropOptions>

/** 事件 */
export const KChangeActivityUpdateCompareEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangeActivityUpdateCompareEmitsType = ViewEmitsTypeExtract<typeof KChangeActivityUpdateCompareEventEmits>

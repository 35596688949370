import { BaseViewModel, KDataGridViewModel, ViewModelOptions, SelectOption } from '@kmsoft/upf-core'
import { KBaselineMemberReplaceGridEmitsType, KBaselineMemberReplaceGridPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode, EnumLifecycleState } from '@kmsoft/ebf-common'

/** KBaselineMemberReplaceGrid */
export default class KBaselineMemberReplaceGridViewModel extends BaseViewModel<
  KBaselineMemberReplaceGridEmitsType,
  KBaselineMemberReplaceGridPropType
> {
  refDataGrid = ref<KDataGridViewModel>()
  /** 生命周期枚举 */
  lifecycleStateOptions = ref<Array<SelectOption>>(EnumLifecycleState._list)
  constructor(options: ViewModelOptions<KBaselineMemberReplaceGridPropType>) {
    super(options)
  }

  viewDidMount() {}

  async loadData() {
    const params = {
      data: [
        {
          id: this.props.id,
          className: this.props.className
        }
      ]
    }
    const result = (await Api.post('baseline', 'ManagedBaseline', 'listVersionObjectByTarget', params)) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      return (result.data || []).map((item: any) => {
        item.versionInfo = `${item.version}.${item.iteration}`
        return item
      })
    } else {
      return []
    }
  }

  getSelectedRows() {
    return this.refDataGrid.value?.getSelectedRows() || []
  }
}

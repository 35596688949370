import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  KDialogClosingEvent
} from '@kmsoft/upf-core'
import { OptionValue } from '../object-create-panel/controls'
import KObjectDynamicCreatePanelViewModel from './KObjectDynamicCreatePanelViewModel'

/** 参数 **/
export const KObjectDynamicCreatePanelPropOptions = {
  ...BaseViewPropOptions,
  /**
   * 对象类候选值
   */
  clsOptions: VuePropTypes.createType<Array<IClsOption>>().def([]),
  folderId: VuePropTypes.string().def(),
  /**默认选中节点的code */
  defaultSelectClsCode: VuePropTypes.string().def(),
  /** 设置要显示的对象id集合,包含子节点 */
  showObjClsCodes: VuePropTypes.array<string>().def([]),
  /** 是否显示对象类选择器 */
  showObjClsSelector: VuePropTypes.bool().def(true)
}

/** 参数类型 **/
export type KObjectDynamicCreatePanelPropType = ViewPropsTypeExtract<typeof KObjectDynamicCreatePanelPropOptions>

// /** 对象创建前事件 */
// export type ObjectCreateDialogSaveBeforeEvent = {
//   /** 事件 */
//   event: KDialogClosingEvent<KObjectDynamicCreatePanelViewModel>
// }

/**
 * 对象面板保存结果
 */
// export type ObjectCreatePanelSaveEvent = {
//   /** 保存选项 */
//   options: Array<OptionValue>
// }

/** 窗口参数 */
export type KObjectDynamicCreateDialogParams = Partial<KObjectDynamicCreatePanelPropType> & {
  /** 标题 */
  title?: string
  /** 是否显示应用按钮 */
  showApply?: boolean
  /**
   * 是否强制显示对象类选择下拉控件，非必选属性
   * @description 通过零部件创建EPBOM场景，虽然提供了默认对象类，但是还是可以切换EPBOOM对象类
   */
  forceShowObjClsSelector?: boolean
  // /** 关闭前事件 */
  // onClosingBefore?: (event: ObjectCreateDialogSaveBeforeEvent) => void
  // /** 确认回调 */
  // onConfirm?: (result: ObjectCreateFormSaveResult) => void
  // /** 应用回调 */
  // onApply?: (result: ObjectCreateFormSaveResult) => void
  // /** 关闭回调 */
  // onClosed?: () => void
}

/** 事件 */
export const KObjectDynamicCreatePanelEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KObjectDynamicCreatePanelEmitsType = ViewEmitsTypeExtract<typeof KObjectDynamicCreatePanelEventEmits>

/**
 * 对象类候选值
 */
export interface IClsOption {
  /**
   * 对象类编码
   */
  clsCode: string
  /**
   * 对象类名称
   */
  clsName: string
  /**
   * 页面
   */
  page: string
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

export interface PartParamInter {
  partReference: {
    id: string
    clazz: string
  }
  includeDocDescribe: boolean
  includeDocReference: boolean
  onlyPublish: boolean
  relatedPartReferences: Array<{
    id: string
    clazz: string
  }>
}

/** 参数 **/
export const KBaselineCreateFormPropOptions = {
  ...BaseViewPropOptions,
  /** 是否显示类型，通过文件夹创建不显示，通过part创建需要显示 **/
  showType: VuePropTypes.bool().def(false),
  formValue: VuePropTypes.createType<any>().def(),
  /** part创建基线时查询参数 **/
  partParam: VuePropTypes.createType<Partial<PartParamInter>>().def({}),
  collectedParts: VuePropTypes.createType<Array<any>>().def()
}

/** 参数类型 **/
export type KBaselineCreateFormPropType = ViewPropsTypeExtract<typeof KBaselineCreateFormPropOptions>

/** 事件 */
export const KBaselineCreateFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KBaselineCreateFormEmitsType = ViewEmitsTypeExtract<typeof KBaselineCreateFormEventEmits>

import { App, Plugin } from 'vue'
import KWorkflowSigningHistory from './workflow-signing-history'
import KWorkflowTemplate from './workflow-template'
import KWorkflowView from './workflow-view'

const components: Record<string, Plugin> = {
  KWorkflowTemplate,
  KWorkflowView,
  KWorkflowSigningHistory
}

const install = function(app: App) {
  Object.keys(components).forEach(key => {
    app.use(components[key])
  })
}

export default {
  install: install
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartViewView from './KPartView.vue'
import KPartViewViewModel from './KPartViewViewModel'

const KPartView = connect(KPartViewView, KPartViewViewModel)

export default withInstall(KPartView)
export { KPartView, KPartViewView, KPartViewViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, SimpleViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KBaseEditorEmitsType, KBaseEditorPropType } from './interface'

/** KBaseEditor */
export default class KBaseEditorViewModel<
  E extends KBaseEditorEmitsType = KBaseEditorEmitsType,
  P extends KBaseEditorPropType = KBaseEditorPropType,
  V = string
> extends SimpleViewModel<E, P, V> {
  constructor(options: ViewModelOptions<P>) {
    super(options)
  }

  viewDidMount() {}
}

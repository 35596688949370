
import { defineView } from '@kmsoft/upf-core'
import { KDocFileListPropOptions, KDocFileListEventEmits } from './interface'
import KDocFileListViewModel from './KDocFileListViewModel'

export default defineView({
  name: 'KDocFileList',
  props: KDocFileListPropOptions,
  emits: KDocFileListEventEmits,
  viewModel: KDocFileListViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { BaseViewModel, KDataGridModelUpdatedEvent, ViewModelOptions } from '@kmsoft/upf-core'
import { KIssueReportRelationDocEmitsType, KIssueReportRelationDocPropType } from './interface'
import { Api, EnumRequestCode, KObjectClassGridViewModel, EnumWorkingState, EnumLifecycleState } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KIssueReportRelationDoc */
export default class KIssueReportRelationDocViewModel extends BaseViewModel<
  KIssueReportRelationDocEmitsType,
  KIssueReportRelationDocPropType
> {
  refObjGrid = ref<KObjectClassGridViewModel>()

  /**
   * 外部选择的零部件对象ID和类型
   */
  partId = ref<string>('')
  rdmExtensionType = ref<string>()

  /** 网格数据 */
  gridData = ref<any[]>([])

  constructor(options: ViewModelOptions<KIssueReportRelationDocPropType>) {
    super(options)
    this.partId.value = this.props.objParam.id
    this.rdmExtensionType.value = this.props.objParam.rdmExtensionType
  }

  // [key: string]: any; // 添加索引签名

  viewDidMount() {}

  async loadData(qry: any) {
    if (this.rdmExtensionType.value == 'Part') {
      return this.loadPartRefDocData(qry)
    } else {
      return this.loadDocumentRefDocData(qry)
    }
  }

  async loadDocumentRefDocData(qry: any) {
    const param = {
      data: [this.partId.value]
    }

    const result = await Api.post('doc', 'Document', 'listRelatedLatest', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.gridData.value = result.data || []
      this.gridDataFilter()
      return this.gridData.value
    } else {
      return []
    }
  }

  async loadPartRefDocData(qry: any) {
    const param = {
      group: 'part',
      behavior: 'listDocumentByPart',
      data: [
        {
          id: this.partId.value,
          clazz: this.rdmExtensionType.value
        }
      ],
      clsCode: 'Part'
    }

    const result = await Api.post('part', 'Part', 'listDocumentByPart', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.gridData.value = result.data || []
      this.gridDataFilter()
      return this.gridData.value
    } else {
      return []
    }
  }

  gridDataFilter() {
    // 去重
    const gridDataMap = this.gridData.value.reduce((acc, current) => {
      // 版本
      current.versionInfo = `${current.version}.${current.iteration}`
      // 状态
      current.state = current.lifecycleStateCode ? EnumLifecycleState[current.lifecycleStateCode] : '--'
      // 检入标记
      current.checkInState = current.workingState ? EnumWorkingState[current.workingState as keyof typeof EnumWorkingState] : '--'

      acc[current.id] = current
      return acc
    }, {})

    this.gridData.value = Object.values(gridDataMap)
  }

  modelUpdated(event: KDataGridModelUpdatedEvent) {
    if (event.keepRenderedRows && event.api.totalRows > 0) {
      const xList = event.api.getRows() || []
      if (xList != null && xList.length > 0) {
        xList.forEach((item: any) => {
          event.api.setSelectedRow(item.id, true)
        })
      }
    }
  }

  getSelectedRows() {
    return this.refObjGrid.value?.getSelectedRows() || []
  }
}

import {
  IKTreeNode,
  BaseViewPropOptions,
  ViewPropsTypeExtract,
  EventArgs,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  TreeViewSelectEventArgs
} from '@kmsoft/upf-core'

/**刷新配置值面板事件对外参数 */
export class RefreshConfigValuePanelEventArgs extends EventArgs {
  // 节点对象
  node: IKTreeNode
  constructor(node: IKTreeNode) {
    super()
    this.node = node
  }
}

// 页面事件
export const KConfigTreeEventEmits = {
  ...BaseViewEventEmits,
  refreshConfigValuePanel: (e: RefreshConfigValuePanelEventArgs) => true
}

// 属性选项
export const KConfigTreePropOptions = {
  ...BaseViewPropOptions
}

// 属性类型
export type KConfigTreePropType = ViewPropsTypeExtract<typeof KConfigTreePropOptions>

// emit type
export type KConfigTreeEventEmitsType = ViewEmitsTypeExtract<typeof KConfigTreeEventEmits>


import { defineView } from '@kmsoft/upf-core'
import { EnumTabType } from '../../../interface'
import { KConfigGridTableViewModelPropOptions } from './interface'
import KConfigGridTableViewModel from './KConfigGridTableViewModel'

export default defineView({
  name: 'ConfigGridTable',
  props: KConfigGridTableViewModelPropOptions,
  viewModel: KConfigGridTableViewModel,
  setup() {
    return {
      EnumTabType
    }
  }
})

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KRuleDefinerEmitsType, KRuleDefinerPropType, RuleDefinition } from './interface'
import { ref, watch } from 'vue'
import { KRulePropertyViewModel } from './controls'
import { ClassMetaClientSrv, EnumDataType, MetaProperty, QueryCondition } from '../../client-srv'
import { isArray } from 'lodash'

/** 规则定义器 */
export default class KRuleDefinerViewModel extends BaseViewModel<KRuleDefinerEmitsType, KRuleDefinerPropType> {
  /** 规则属性 */
  refRuleProperty = ref<KRulePropertyViewModel>()
  /** 对象类Id */
  modelCode = ref<string>('')
  /** 对象类名称 */
  objClsName = ref<string>('')
  /** 列定义 */
  fields = ref<Array<MetaProperty>>()
  /** 是否包含子项 */
  includeChild = ref<boolean>(false)
  /** 正在加载 */
  isLoading = ref<boolean>(true)
  /** 条件内容 */
  condition = ref<QueryCondition>()

  constructor(options: ViewModelOptions<KRuleDefinerPropType>) {
    super(options)
    this.modelCode.value = options.props.modelCode
    this.fields.value = options.props.fields
    this.condition.value = options.props.condition

    this.init(true, false, [])

    watch(
      () => [options.props.modelCode, options.props.fields],
      (newValue, oldValue) => {
        this.init(true, options.props.isMerge, options.props.clsAttrs || [])
      },
      {
        immediate: true
      }
    )

    watch(
      () => options.props?.clsAttrs,
      (newVal: any) => {
        this.init(true, true, newVal || [])
      },
      {
        immediate: true
      }
    )
    watch(
      () => options.props.includeChild,
      newVal => {
        this.includeChild.value = newVal
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  /**
   * 初始化
   * @returns
   */
  private async init(clear: boolean = false, isMerge: boolean = false, extAttrs: Array<Record<string, any>>) {
    this.isLoading.value = true
    // 如果根据对象类Code,如果isMerge为true则合并
    if (isMerge) {
      let propertyList: any = []
      if (this.modelCode.value) {
        propertyList = await this.initPropertiesByClsCode(this.modelCode.value)
      }
      this.fields.value = propertyList.concat(extAttrs || ([] as any))
    } else if (this.modelCode.value) {
      const propertyList = await this.initPropertiesByClsCode(this.modelCode.value)
      this.fields.value = propertyList as any
    } else if (this.props.fields) {
      // 如果是外部传入
      this.fields.value = this.props.fields
    } else {
      this.fields.value = []
    }

    if (clear) {
      this.clear()
    }

    this.isLoading.value = false
  }

  /**
   * 获得属性列表
   */
  private async initPropertiesByClsCode(argObjClsCode: string) {
    /** 属性列表 */
    const propertyList: Array<MetaProperty> = []

    // 对象类不为空
    if (argObjClsCode) {
      /** 通过对象类Code 获取 对象类定义 */
      const properties = await ClassMetaClientSrv.getClassProperties(argObjClsCode)!
      propertyList.push(...properties!)
    }

    /** 过滤掉图片列、对象列等 */
    const noDisplayFieldDataTypes = [
      EnumDataType.IMAGE,
      EnumDataType.OBJECT,
      EnumDataType.TYPE,
      EnumDataType.ENUM,
      EnumDataType.USER
    ]

    return propertyList
    // .filter(a => !noDisplayFieldDataTypes.includes(a.type))
    // .map((arrMap: any) => {
    //   return {
    //     columnName: arrMap?.columnName,
    //     ...omit(arrMap, ['columnName'])
    //   }
    // })
  }

  /** 清空规则 */
  public clear() {
    // 清空条件
    this.condition.value = undefined
    this.refRuleProperty.value?.clear()
  }

  /**
   * 校验
   * @param showMsg 是否显示校验信息
   * @returns
   */
  public validate(showMsg: boolean = true) {
    return this.refRuleProperty.value?.validate(showMsg)
  }

  /**
   * 获取对象类 Code
   * @returns
   */
  public getObjClsCode(): string {
    return this.modelCode.value
  }

  /**
   * 获取条件
   * @returns
   */
  public getCondition(): QueryCondition | undefined {
    // 目前joiner逻辑还没有处理
    const joiner = this.refRuleProperty.value?.getJoiner()!
    return {
      conditions: (joiner?.rules || []).map((arr: any) => {
        return {
          conditionName: arr?.key,
          operator: arr?.operator,
          conditionValues: isArray(arr.value) ? arr?.value : [arr?.value]
        }
      }) as any,
      joiner: 'and' as any
    }
  }

  /**
   * 获取定义
   * @returns
   */
  public getDefinition(): RuleDefinition | undefined {
    if (!this.condition.value) {
      return {
        condition: {},
        modelCode: this.modelCode.value,
        includeChild: this.includeChild.value,
        fields: this.fields.value!
      }
    }
    /** 定义 */
    const definition: RuleDefinition = {
      condition: this.condition.value,
      modelCode: this.modelCode.value,
      includeChild: this.includeChild.value,
      fields: this.fields.value!
    }
    return definition
  }

  //#region 事件
  /**
   * 对象类 Code 更新事件
   * @param modelCode
   */
  onUpdateObjClsCode(value: string) {
    this.modelCode.value = value
    this.init(true, false, [])
    this.emit('update:modelCode', value)
  }

  /**
   * 条件数据更新事件
   * @param condition
   */
  onUpdateCondition(condition: QueryCondition) {
    this.condition.value = condition
    this.emit('update:condition', condition)
  }

  /**
   * 包含子类数据更新事件
   * @param condition
   */
  onUpdateIncludeChild(value: boolean) {
    this.includeChild.value = value
    this.emit('update:includeChild', value)
  }
  //#endregion
}

import { defineView } from '@kmsoft/upf-core'
import { KSegmentContainerEventEmits, KSegmentContainerPropOptions } from './interface'
import KSegmentContainerViewModel from './KSegmentContainerViewModel'
import { renderSlot } from 'vue'

export default defineView({
  name: 'KSegmentContainer',
  props: KSegmentContainerPropOptions,
  emits: KSegmentContainerEventEmits,
  viewModel: KSegmentContainerViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    return () => {
      return (
        <k-collapse class="k-segment-container" vModel={[vm.activeKey.value, 'activeKey']} expandIconPosition="right">
          <k-collapse-panel size="small" collapsible="disabled" showArrow={false} key={vm.activeKey.value} header={props.title}>
            {renderSlot(slots, 'default')}
          </k-collapse-panel>
        </k-collapse>
      )
    }
  }
})

import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { EnumAttributeType, EnumDataType, KControlAttribute } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'
import { getCandidates } from '../utils'

/* 组件的基本属性 */
const comConfig = [
  {
    name: 'readonly',
    title: '是否只读',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'size',
    title: '选择框大小',
    control: 'KSelect',
    options: getCandidates(['small', 'middle', 'large']),
    defaultValue: 'middle',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'mode',
    title: '多选,单选,tags(可自定义)',
    control: 'KSelect',
    options: getCandidates(['multiple', 'combobox', 'tags']),
    defaultValue: 'multiple',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'maxTagCount',
    title: '最多显示tag数',
    control: 'KInputNumber',
    defaultValue: 99999,
    dataType: EnumDataType.INT,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'maxTagTextLength',
    title: 'tag最大文本长度',
    control: 'KInputNumber',
    defaultValue: 99999,
    dataType: EnumDataType.INT,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'allowClear',
    title: '可清空',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'defaultOpen',
    title: '是否默认展开下拉菜单',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'filterOption',
    title: '根据输入项进行筛选',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'showSearch',
    title: '是否显示搜索按钮',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'showSearch',
    title: '是否显示搜索按钮',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'required',
    title: '是否必填',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'options',
    title: '候选项配置',
    control: 'custom',
    defaultValue: '',
    dataType: EnumDataType.ARRAY,
    attributeType: EnumAttributeType.PROPS
  }
]
/* 属性配置项集合 */
const BASE = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE, PROPERTY_CONFIG_ITEM_COLLECTION.DISABLED] as Array<KControlAttribute>
/**控件属性配置项集合 */
const PROPS = comConfig
/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_GAP,
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_GAP,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_COUNT,
  LAYOUT_CONFIG_ITEM_COLLECTION.LABEL_POSITION,
  LAYOUT_CONFIG_ITEM_COLLECTION.LABEL_WIDTH
] as Array<KControlAttribute>

const EVENT = [{ name: 'change', title: '值改变事件', describe: '值改变事件 的说明信息...' }]

export default { BASE, PROPS, LAYOUT, EVENT }

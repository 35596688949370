
import { defineView } from '@kmsoft/upf-core'
import KConfigManageViewModel from './KConfigManageViewModel'
import SysTab from './sys/sys-tab'
import DevTab from './dev/dev-tab'
import { EnumTabType } from './interface'

// 导出组件
export default defineView({
  components: { SysTab, DevTab },
  name: 'ConfigManage',
  viewModel: KConfigManageViewModel,
  props: {},
  setup(props) {
    return {
      EnumTabType
    }
  }
})

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumWebSocketStatus } from './enum'

/** 参数 **/
export const KAgentMonitorPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KAgentMonitorPropType = ViewPropsTypeExtract<typeof KAgentMonitorPropOptions>

/** 事件 */
export const KAgentMonitorEventEmits = {
  ...BaseViewEventEmits,
  statusChange: (status: EnumWebSocketStatus) => true
}

/** 事件类型 **/
export type KAgentMonitorEmitsType = ViewEmitsTypeExtract<typeof KAgentMonitorEventEmits>

import {
  BaseViewPropOptions,
  ViewPropsTypeExtract,
  VuePropTypes,
  BaseViewEventEmits,
  ViewEmitsTypeExtract
} from '@kmsoft/upf-core'
import { UpdateConfigValidStatusEventArgs } from '../../sys-config-value/interface'

// 配置项定义
export interface IConfigGridComboboxTileDefinition {
  defId: string
  id: string
  code: string
  name: string
  values: Array<string>
  remark: string
  dataType: string
  editType: string
  editParam: string
}

// 页面事件
export const KConfigGridComboboxTileEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 更新配置项(模块)有效性状态
   * @param validStatus 有效性
   */
  updateConfigValidStatus: (e: UpdateConfigValidStatusEventArgs) => true
}

export const KConfigGridComboboxTileViewModelPropOptions = {
  ...BaseViewPropOptions,
  defId: VuePropTypes.string().def(''),
  treeId: VuePropTypes.string().def(''),
  oper: VuePropTypes.string().def(''),
  mode: VuePropTypes.string().def('')
}

export type KConfigGridComboboxTileViewModelPropType = ViewPropsTypeExtract<typeof KConfigGridComboboxTileViewModelPropOptions>

export type KConfigGridComboboxTileEventEmitsType = ViewEmitsTypeExtract<typeof KConfigGridComboboxTileEventEmits>

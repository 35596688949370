import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KChangeKanbanEmitsType, KChangeKanbanPropType } from './interface'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { computed, ref } from 'vue'
import moment from 'moment'

/** KChangeKanban */
export default class KChangeKanbanViewModel extends BaseViewModel<KChangeKanbanEmitsType, KChangeKanbanPropType> {
  constructor(options: ViewModelOptions<KChangeKanbanPropType>) {
    super(options)
  }
  dateData = [
    { value: 'QUARTER', label: '季' },
    { value: 'MONTH', label: '月' },
    { value: 'WEEK', label: '周' },
    { value: 'DAY', label: '日' }
  ]

  requestTypeGroup: Record<string, string> = {
    COMPONENT_CHANGE: '零部件变更',
    DESIGN_CHANGE: '设计变更',
    DOCUMENTATION_CORRECTION: '资料修正',
    OTHER: '其他'
  }

  startDate: string = moment(new Date())
    .subtract(6, 'months')
    .format('YYYY-MM-DD')
  endDate: string = moment().format('YYYY-MM-DD')

  selectValue = ref('ChangeActivity')
  selectActivityValue = ref('All')
  dateValue = ref('MONTH')

  isMoreSelect = ref(false)
  searchMoreData = ref<string[]>([])

  // 变更表单纵轴数据
  changeFormYData = ref<string | number[]>([])

  // 变更活动改后对象统计纵轴数据
  changeActivityYData = ref<string | number[]>([])

  // 变更请求类型统计X,Y轴数据
  changeRequestXData = ref<string[]>([])
  changeRequestYData = ref<string | number[]>([])

  changeExecutionData = ref<Record<string, unknown>[]>([])

  allData = ref<Record<string, []>>({})

  // 请求页面数据
  async loadData(timeType: string) {
    const result = (await Api.post('change', 'ChangeCommon', 'dashboard', {
      data: [
        {
          itemType: 'ALL',
          timeType,
          start: this.startDate,
          end: this.endDate
        }
      ]
    })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.allData.value = result.data
      this.handleChangeFormData()
      this.handleChangeActivityData()
      this.handleRequestData()
      this.handleExecutionData()
    } else {
      return null
    }
  }

  // 变更表单统计
  handleChangeFormData() {
    const itemsStatisticData = this.allData.value.changeItemsStatistic.filter(
      (item: Record<string, unknown>) => item.group == this.selectValue.value
    )
    this.changeFormYData.value = this.newXAxisData.value.map(item => {
      const data = itemsStatisticData.find((value: Record<string, unknown>) => value.key == item)
      return data ? data['count'] : 0
    })
  }

  // 获取变更活动改后对象统计数据
  handleChangeActivityData() {
    const map: Record<string, number> = {}
    if (this.selectActivityValue.value == 'All') {
      this.allData.value.changeAfterObjectsStatistic.forEach((item: any) => {
        map[item.key] = map[item.key] !== undefined ? Number(map[item.key]) + Number(item.count) : Number(item.count)
      })
      this.changeActivityYData.value = this.newXAxisData.value.map(item => {
        const data = map[item] ? map[item] : 0
        return data
      })
    } else {
      const afterObjectsStatisticData = this.allData.value.changeAfterObjectsStatistic.filter(
        (item: Record<string, unknown>) => item.group == this.selectActivityValue.value
      )
      this.changeActivityYData.value = this.newXAxisData.value.map(item => {
        const data = afterObjectsStatisticData.find((value: Record<string, unknown>) => value.key == item)
        return data ? data['count'] : 0
      })
    }
  }

  // 获取变更请求类型统计
  handleRequestData() {
    const x: string[] = []
    const y: string | number[] = []
    this.allData.value.changeTypesStatistic.forEach(item => {
      x.push(this.requestTypeGroup[item['key']])
      y.push(item['count'])
    })
    this.changeRequestXData.value = x
    this.changeRequestYData.value = y
  }

  handleExecutionData() {
    this.changeExecutionData.value = this.allData.value.changeExecutionStatistic.map((item, index) => {
      return {
        id: index,
        name: item['key'],
        changObjectNum: item['count']
      }
    })
  }

  // 改变筛选时间
  changeDate(value: string) {
    switch (value) {
      case 'DAY':
        this.startDate = moment(new Date())
          .subtract(7, 'days')
          .format('YYYY-MM-DD')
        break
      case 'WEEK':
        this.startDate = moment(new Date())
          .subtract(3, 'weeks')
          .format('YYYY-MM-DD')
        break
      case 'MONTH':
        this.startDate = moment(new Date())
          .subtract(6, 'months')
          .format('YYYY-MM-DD')
        break
      case 'QUARTER':
        this.startDate = moment(new Date())
          .subtract(4, 'quarters')
          .format('YYYY-MM-DD')
        break
    }
    this.isMoreSelect.value = false
    this.searchMoreData.value = []
    this.loadData(value)
  }

  // 变更表单及变更活动改后对象统计X轴数据
  xAxisData = computed(() => {
    const data = []
    switch (this.dateValue.value) {
      case 'DAY':
        for (let i = 6; i >= 0; i--) {
          data.push(
            moment()
              .subtract(i, 'days')
              .format('YYYY-MM-DD')
          )
        }
        break
      case 'WEEK':
        for (let i = 3; i >= 0; i--) {
          data.push(
            moment()
              .subtract(i, 'weeks')
              .weekday(1)
              .format('YYYY-MM-DD')
          )
        }
        break
      case 'MONTH':
        for (let i = 5; i >= 0; i--) {
          data.push(
            moment()
              .subtract(i, 'months')
              .format('YYYY-M')
          )
        }
        break
      case 'QUARTER':
        for (let i = 3; i >= 0; i--) {
          data.push(
            moment()
              .subtract(i, 'quarters')
              .startOf('quarter')
              .format('YYYY-[Q]Q')
          )
        }
        break
    }
    return data
  })

  newXAxisData = computed(() => {
    if (this.searchMoreData.value.length > 0) {
      const data = this.getDatesInRange(this.searchMoreData.value[0], this.searchMoreData.value[1])
      return data
    } else {
      return this.xAxisData.value
    }
  })

  changeForm(value: Record<string, any>) {
    this.selectValue.value = value.newValue
    this.handleChangeFormData()
  }

  changeActivity(value: Record<string, any>) {
    this.selectActivityValue.value = value.newValue
    this.handleChangeActivityData()
  }

  moreClick() {
    this.isMoreSelect.value = true
  }

  customDateChange(value: any) {
    if (value.newValue) {
      this.searchMoreData.value = [value.newValue[0], value.newValue[1]]
      this.startDate = value.newValue[0]
      this.endDate = value.newValue[1]
      this.loadData('DAY')
    }
  }

  // 更多筛选获取所有日期
  getDatesInRange(startDate: string, endDate: string) {
    const dateArray = []
    const currentDate = moment(startDate)
    while (currentDate.isSameOrBefore(endDate)) {
      dateArray.push(currentDate.clone().format('YYYY-MM-DD'))
      currentDate.add(1, 'days')
    }
    return dateArray
  }

  viewDidMount() {
    // 设置季度日期格式
    moment.locale('en', {
      Q: function(date: any) {
        // 返回季度数字
        return Math.ceil(date.month() / 3 + 1)
      }
    })
    this.loadData('MONTH')
  }

  /** 变更表单 **/
  changeFormOptions = [
    {
      value: 'ChangeIssue',
      label: '变更问题报告'
    },
    {
      value: 'ChangeRequest',
      label: '变更请求'
    },
    {
      value: 'ChangeOrder',
      label: '变更单'
    },
    {
      value: 'ChangeActivity',
      label: '变更活动'
    }
  ]

  /** 变更活动 **/
  changeActivityOptions = [
    {
      value: 'All',
      label: '全部'
    },
    {
      value: 'DocumentBranch',
      label: '文档'
    },
    {
      value: 'PartBranch',
      label: '零部件'
    }
  ]

  /** 变更对象 **/
  echartsOption = {
    itemStyle: {
      color: {
        type: 'linear',
        x: 0, //右
        y: 0, //下
        x2: 0, //左
        y2: 1, //上
        colorStops: [
          {
            offset: 0,
            color: '#005BEA' // 0% 处的颜色
          },
          {
            offset: 1,
            color: '#00C6FB' // 100% 处的颜色
          }
        ]
      }
    }
  }
}


import { defineView, utils } from '@kmsoft/upf-core'
import { KClassPropertyFormViewModel } from './KClassPropertyFormViewModel'
import { KClassPropertyFormPropOptions, displayPatternOptions } from './interface'
import CustomPropsForm from './custom-prop-form'
import { EnumClsDataType, allDataTypeConfig } from '../enums'
import { computed } from 'vue'

export default defineView({
  name: 'ClassPropertyForm', // 组件名称
  components: { CustomPropsForm }, // 这里定义局部组件
  viewModel: KClassPropertyFormViewModel,
  props: KClassPropertyFormPropOptions,
  setup(props, { vm }) {
    const showDataLength = computed(() => {
      const showDateTypeList = [EnumClsDataType.CHARACTER]
      return showDateTypeList.includes(vm.formState.value.dataType!)
    })

    const showDisplayPattern = computed(() => {
      const showDateTypeList = [EnumClsDataType.FLOAT]
      return showDateTypeList.includes(vm.formState.value.dataType!)
    })

    const showPropertyScale = computed(() => {
      const showDateTypeList = [EnumClsDataType.FLOAT]
      const dateTypeFlag = showDateTypeList.includes(vm.formState.value.dataType!)

      // 其他判断条件
      const flag2 = !utils.isEmpty(vm.formState.value.displayPattern) && vm.formState.value.displayPattern !== 0
      return dateTypeFlag && flag2
    })

    const showSample = computed(() => {
      // 日期类型不显示
      const hideDateTypeList = [EnumClsDataType.DATE]
      const hideFlag = !hideDateTypeList.includes(vm.formState.value?.dataType!)
      return hideFlag
    })

    // 这里定义非响应式数据以及子组件的引用
    return {
      allDataTypeConfig,
      displayPatternOptions,
      showDataLength,
      showDisplayPattern,
      showPropertyScale,
      showSample
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KPartFormPropOptions, KPartFormEventEmits } from './interface'
import KPartFormViewModel from './KPartFormViewModel'

export default defineView({
  name: 'KPartForm',
  props: KPartFormPropOptions,
  emits: KPartFormEventEmits,
  viewModel: KPartFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

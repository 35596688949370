import { IColumnDefinition } from '../../../interface'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

// 页面事件
export const KConfigGridTableEventEmits = {
  ...BaseViewEventEmits
}

export const KConfigGridTableViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('')
}

export type KConfigGridTableViewModelPropType = ViewPropsTypeExtract<typeof KConfigGridTableViewModelPropOptions>

export type KConfigGridTableEventEmitsType = ViewEmitsTypeExtract<typeof KConfigGridTableEventEmits>

import {
  BaseViewModel,
  DataGridLoadResult,
  KDataGridColumn,
  KDataGridRowSelectedEvent,
  KDataGridTextBoxColumn,
  KDataGridViewModel,
  KNotification,
  ViewModelOptions
} from '@kmsoft/upf-core'
import {
  KDocBomCompareResultPanelEmitsType,
  KDocBomCompareResultPanelPropType,
  IColumnDefinition,
  initColumns,
  IRootTreeNodeParam
} from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** KDocBomCompareResultPanel */
export default class KDocBomCompareResultPanelViewModel extends BaseViewModel<
  KDocBomCompareResultPanelEmitsType,
  KDocBomCompareResultPanelPropType
> {
  /**
   * 网格组件
   */
  refDataGrid = ref<KDataGridViewModel>()

  /** 列定义 */
  columns = ref<Array<KDataGridColumn>>([])
  /**隐藏的列 */
  hideColumns = ref<Array<IColumnDefinition>>([])
  /**显示的列 */
  showColumns = ref<Array<IColumnDefinition>>([])
  /**差异件是否展开 */
  expandDifferentialParts = ref<boolean | undefined>(false)
  /**源对象 */
  source = ref<IRootTreeNodeParam>()
  /** 目标对象 */
  target = ref<IRootTreeNodeParam>()
  /**
   * 比较规则是否应用到根
   */
  applyToRoot = ref<boolean>()

  constructor(options: ViewModelOptions<KDocBomCompareResultPanelPropType>) {
    super(options)
    this.source.value = options.props.source
    this.target.value = options.props.target
    this.expandDifferentialParts.value = options.props.expandDifferentialParts
    this.applyToRoot.value = options.props.applyToRoot
    this.initColumn()
  }

  viewDidMount() {}

  loadData(): Promise<DataGridLoadResult> {
    return new Promise(resolve => {
      // todo 根据sourceId、targetId、comparisonMode、expandDifferentialParts、ignoringBranchNodes调用后端获取比较结果
      const param = {
        data: [
          {
            treeExpandList: [
              {
                root: {
                  id: this.source.value?.root.id,
                  clazz: this.source.value?.root.modelCode
                },
                queryRule: this.source.value?.queryRule,
                applyToRoot: this.source.value?.applyToRoot
              },
              {
                root: {
                  id: this.target.value?.root.id,
                  clazz: this.target.value?.root.modelCode
                }
              }
            ],
            compareSub: this.expandDifferentialParts.value
          }
        ]
      }
      Api.post('part', 'Part', 'compareBomWithDoc', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
          const result = [] as Array<any>
          res.data.forEach((item: any) => {
            if (item.userObject.diff) {
              result.push(item.userObject.diff)
            }
          })
          this.emit('loadReady', result)
          resolve(result)
        } else {
          KNotification.error({
            title: '操作失败',
            content: res.message,
            details: res.detail
          })
          resolve([])
        }
      })
    })
  }

  /**
   * 导出excel
   */
  export() {
    const hideColumnsCode = this.hideColumns.value.map(column => column.code)
    this.refDataGrid.value?.exportDataAsExcel({
      fileName: 'bom与文档比较结果',
      sheetName: 'bom与文档比较结果',
      columnKeys: this.columns.value.filter(column => !hideColumnsCode.includes(column.id)).map(column => column.id),
      processCellCallback: (args: any) => args.value
    })
  }

  /**
   * 设置选中行
   */
  setSelectRow(key: string) {
    this.refDataGrid.value?.setSelectedRow(key, true)
  }

  /**
   * 设置树选中
   */
  setTreeSelect(event: KDataGridRowSelectedEvent<any>) {
    const rows = this.refDataGrid.value?.getSelectedRows()
    this.emit('selectRowsChange', rows)
  }
  /**
   * 清除网格所有数据
   */
  clearGrid() {
    this.refDataGrid.value?.clear()
  }

  /**
   * 初始化网格
   */
  initColumn() {
    // 获取所有属性
    initColumns.forEach(column => {
      const dataColumn = new KDataGridTextBoxColumn()
      dataColumn.id = column.code
      dataColumn.headerAlign = 'center'
      dataColumn.headerText = column.name
      dataColumn.dataPropertyName = column.code
      dataColumn.width = 'auto'
      dataColumn.hide = false
      this.columns.value.push(dataColumn)
    })
  }
  /**
   * 刷新网格
   */
  refresh() {
    this.refDataGrid.value?.refresh()
  }
  /**
   * 根据条件重新加载网格
   */
  reload(source: IRootTreeNodeParam, target: IRootTreeNodeParam, expandDifferentialParts: boolean, applyToRoot: boolean) {
    this.source.value = source
    this.target.value = target
    this.expandDifferentialParts.value = expandDifferentialParts
    this.applyToRoot.value = applyToRoot
    this.refDataGrid.value?.refresh()
  }
  /**
   * 获取所有行数据
   */
  getRows() {
    return this.refDataGrid.value?.getRows()
  }
}

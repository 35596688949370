
import { defineView } from '@kmsoft/upf-core'
import { KGridColumnConfigPropOptions } from './interface'
import KGridColumnConfigViewModel from './KGridColumnConfigViewModel'

export default defineView({
  name: 'KGridColumnConfig',
  props: KGridColumnConfigPropOptions,
  viewModel: KGridColumnConfigViewModel,
  setup() {
    return {}
  }
})

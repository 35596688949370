import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KIssueReportPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KIssueReportPropType = ViewPropsTypeExtract<typeof KIssueReportPropOptions>

/** 事件 */
export const KIssueReportEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KIssueReportEmitsType = ViewEmitsTypeExtract<typeof KIssueReportEventEmits>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"500px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_json_pretty = _resolveComponent("k-json-pretty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_json_pretty, {
      style: {"height":"500px"},
      data: _ctx.$vm.localSchema
    }, null, 8, ["data"])
  ]))
}
import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { EnumAttributeType, EnumDataType, KControlAttribute } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'
import { getCandidates } from '../utils'

const comProps = [
  {
    name: 'enableShortcut',
    title: '是否启用快捷方式',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'hideText',
    title: '是否隐藏文字',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  }
  // {
  //   type: 'component',
  //   name: 'items',
  //   title: '菜单项集合',
  //   editType: EnumEditType.INPUT,
  //   disabled: true
  // }
]

/* 属性配置项集合 */
const BASE = [
  PROPERTY_CONFIG_ITEM_COLLECTION.TITLE,
  PROPERTY_CONFIG_ITEM_COLLECTION.DISABLED
  // ...comProps
] as Array<KControlAttribute>
/**控件属性配置项集合 */
const PROPS = comProps
/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
] as Array<KControlAttribute>

const EVENT = [
  { name: 'click', title: '点击', describe: '点击 的说明信息...' },
  { name: 'itemClick', title: '集合点击', describe: '点击 的说明信息...' }
]
export default { BASE, PROPS, LAYOUT, EVENT }

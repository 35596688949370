import { Plugin, App } from 'vue'
import * as editors from './editors'
import { IIocContainer, KDataGridColumn, KDataGridColumnGroup } from '@kmsoft/upf-core'
import { IOC_COMPONENT_KEYS_BASE } from '../../ioc-conponents'
import { KObjectClassGridBaseColumn } from './base'
export * from './editors'
export * from './base'
export * from './utils'

export const useEditors = (iocContainer: IIocContainer) => {
  /** 编辑器 */
  const allEditors = editors as Record<string, any>
  for (const key in allEditors) {
    const editor = allEditors[key]
    const result = editor.prototype && 'getCellRenderer' in editor.prototype && 'getCellEditor' in editor.prototype //editor.prototype[''] && editor.prototype['getCellEditor']
    if (editor.getColumn && editor.getEditor) {
      iocContainer.registerSingleton(IOC_COMPONENT_KEYS_BASE.FIELD_EDITOR_RESOLVER, editor)
    }
    if (result) {
      const column = applyToConstructor(editor, [{ name: editor.name }])
      iocContainer.registerSingleton(IOC_COMPONENT_KEYS_BASE.FIELD_EDITOR_RESOLVER, column)
    }
  }
}

/**
 * 调用指定对象类的构造函数方法
 */
export const applyToConstructor = (clazz: { new (): KDataGridColumn } | { new (): KDataGridColumnGroup }, argArray: any[]) => {
  const args = [null].concat(argArray)
  if (clazz.prototype && clazz.prototype.constructor) {
    const constructor = clazz.prototype.constructor
    // eslint-disable-next-line prefer-spread
    const factoryFunction = constructor.bind.apply(constructor, args as any)
    return new factoryFunction()
  } else {
    return null
  }
}

const install = function(app: App) {
  const allEditors = editors as Record<string, any>
  Object.keys(editors).forEach(key => {
    const obj = allEditors[key]
    if ('install' in obj) {
      app.use(obj)
    }
  })
}

export default {
  install: install
}


import { defineView } from '@kmsoft/upf-core'
import { EnumLifecycleState, EnumPhaseState, clsCodeMap, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KPromoteActivityBeforeGridPropOptions, KPromoteActivityBeforeGridEventEmits } from './interface'
import KPromoteActivityBeforeGridViewModel from './KPromoteActivityBeforeGridViewModel'
import { readonly } from 'vue'

export default defineView({
  name: 'KPromoteActivityBeforeGrid',
  props: KPromoteActivityBeforeGridPropOptions,
  emits: KPromoteActivityBeforeGridEventEmits,
  viewModel: KPromoteActivityBeforeGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumLifecycleState, EnumPhaseState, clsCodeMap, EnumLifecycleStateColor }
  }
})

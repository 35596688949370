import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KLoginPageEmitsType, KLoginPagePropType } from './interface'

/** KLoginPage */
export default class KLoginPageViewModel extends BaseViewModel<KLoginPageEmitsType, KLoginPagePropType> {
  constructor(options: ViewModelOptions<KLoginPagePropType>) {
    super(options)
  }

  viewDidMount() {}
}

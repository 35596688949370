import {
  PurviewAdapterBase,
  PurviewVerifyResult,
  VerifyFuncPurviewParams,
  VerifyObjClsPurviewParams,
  VerifyObjectPurviewParams
} from '../../common'

/** 权限数据适配器 */
export class PurviewAdapter extends PurviewAdapterBase {
  verifyObjClsPurview(params: VerifyObjClsPurviewParams): PurviewVerifyResult {
    return { isMatch: true, hasPurview: false }
  }

  verifyObjectPurview(params: VerifyObjectPurviewParams): Promise<PurviewVerifyResult> | PurviewVerifyResult {
    // 子类重写提供数据
    return { isMatch: false, hasPurview: false }
  }

  verifyFuncPurview(params: VerifyFuncPurviewParams): PurviewVerifyResult {
    // 子类重写提供数据
    return { isMatch: false, hasPurview: false }
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  EnumType,
  VuePropTypes
} from '@kmsoft/upf-core'
import {
  DEFAULT_CURRENCY_EVENTS,
  DEFAULT_CURRENCY_LAYOUT_PROPS,
  DEFAULT_CURRENCY_PROPS,
  EnumClsEditorType,
  EnumControl
} from './type'
import { ControlCollection, KDesignerControl } from '@kmsoft/ebf-view-engine'
import ElementConfigCollection from './element-config'
import _ from 'lodash'

/**对象类定义 */
export interface IKClassDefinition {
  // id: string
  /**名称 */
  className: string
  /**编码 */
  classCode: string
  /**属性集合 */
  properties: Array<IKMetaPropertyDefinition>
  /**关系属性 */
  relationProperty?: Array<IKClassDefinition>
  /**附属表格 */
  attachedTable?: Array<IKClassDefinition>
  /**关联对象附属表格 */
  relClassAttachTables?: Array<IKClassDefinition>
  /**关联对象的属性 */
  relationClassProps?: Array<IKClassDefinition>
  /**关系列表 */
  relatedList?: Array<IKClassDefinition>
}

/**属性元数据信息 */
export interface IKMetaPropertyDefinition {
  /**id */
  id: string
  propertyId: string
  /**编码 */
  propCode: string
  /** 列编码 */
  column: string
  /**名称 */
  propName: string
  /**对象类id */
  classId: string
  /**对象类名称 */
  className: string
  dataTypeName: string
  /**数据长度 */
  dataLength: number
  /**列宽 */
  columnWidth: number
  /**编辑参数 */
  editArgs: string
  /**是否可变 */
  changeable: number
  /**预定义 */
  predefined: number
  /**数据类型 */
  dataType: EnumClsDataType
  /**显示名 */
  displayName: string
  /**默认值 */
  defaultValue: string
  /**编辑方式 */
  editType: number
  /**是否只读 ;0->否,1->是*/
  readonly: number
  /**是否可为空 */
  nullable: number
  /**是否可见 */
  visible: number
  /** 属性分类 */
  propSource: string

  /**引用属性编辑参数 */
  propertyArgs?: string
}

/** 数据类型 */
export enum EnumClsDataType {
  /** 整数 */
  INTEGER = 10,
  /** 浮点型 */
  FLOAT = 20,
  /** 长整型 */
  LONG_INTEGER = 30,
  /** 日期 */
  DATE = 40,
  /** 字符 */
  CHARACTER = 50,
  /** 图片 */
  IMG = 60,
  /** 大文本 */
  TEXT = 70,
  /** 对象/引用属性 */
  OBJ = 80,
  /** 分类 */
  CLASSIFY = 90,
  /** 枚举 */
  ENUMS = 100,
  /**人员 */
  USER = 110,
  /**网格 */
  GRID = 999
}

/** 转换时需特殊处理字段 */
export enum EnumSpecialFiled {
  NAME = 'name',
  TITLE = 'title',
  CONTROL = 'control',
  DATASOURCE = 'dataSource',
  FIELD = 'field',
  DISABLED = 'disabled',
  REQUIRED = 'required'
}

/**数据元素节点类型 */
export enum EnumNodeType {
  /**分类 */
  CLASSIFICATION = 0,
  /**属性 */
  PROPERTY = 1
}

/**对象类属性类型 */
export enum EnumPropertyType {
  /**属性 */
  PROPERTIES = 'properties',
  /**附属表格 */
  ATTACHED_TABLES = 'attachedTables',
  /**关联对象附属表格 */
  ATTACHED_TABLES_REF = 'attachedTablesRef',
  /**关系列表 */
  RELATION_LIST = 'relationList'
}

/** 参数 **/
export const KClassUiConfigPropOptions = {
  ...BaseViewPropOptions,
  layoutName: VuePropTypes.string().def(),
  layoutCode: VuePropTypes.string().def(),
  classCode: VuePropTypes.string().def(),
  belongClsCode: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KClassUiConfigPropType = ViewPropsTypeExtract<typeof KClassUiConfigPropOptions>

/** 事件 */
export const KClassUiConfigEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KClassUiConfigEmitsType = ViewEmitsTypeExtract<typeof KClassUiConfigEventEmits>

/**
 * 对象类树类型
 */
export const classTreeType = new Map([
  ['attachedTables', '附属表格'],
  ['relClassAttachTables', '关联对象附属表格'],
  ['relationClassProps', '关联对象的属性'],
  ['relatedList', '关系列表'],
  ['base', '基础属性'],
  ['extend', '扩展属性']
])

/**编辑类型与默认控件映射*/
export const editTypeMap = new Map<number, string>([
  [EnumClsEditorType.SINGLE_LINE_EDITOR, EnumControl.INPUT],
  [EnumClsEditorType.MULTI_LINE_EDITOR, EnumControl.TEXT_AREA],
  [EnumClsEditorType.DATE_EDITOR, EnumControl.DATE_TIME],
  [EnumClsEditorType.SELECT_EDITOR, EnumControl.SELECT],
  [EnumClsEditorType.SELECT_EDITABLE_EDITOR, EnumControl.SELECT],
  [EnumClsEditorType.SELECT_VALUED_EDITOR, EnumControl.SELECT],
  [EnumClsEditorType.CHECKBOX_EDITOR, EnumControl.CHECKBOX],
  [EnumClsEditorType.CUSTOM_EDITOR, EnumControl.CUSTOM],
  [EnumClsEditorType.OBJ_SELECTOR, EnumControl.OBJECT_SELECT],
  [EnumClsEditorType.DIALOG_OBJ_SELECTOR, EnumControl.GRID_OBJ_SELECT],
  [EnumClsEditorType.USER_EDITOR, EnumControl.USER_SELECT],
  [EnumClsEditorType.FILED_EDITOR, EnumControl.FOLDER_SELECTOR],
  [EnumClsEditorType.LIFECYCLE_EDITOR, EnumControl.LIFECYCLE_EDITOR],
  [EnumClsEditorType.FILED_UPLOAD_EDITOR, EnumControl.FILED_UPLOAD_EDITOR],
  [EnumClsEditorType.VIEW_SELECTOR, EnumControl.VIEW_SELECTOR],
  [EnumClsEditorType.CATEGORY_SELECTOR, EnumControl.CATEGORY_SELECTOR],
  [EnumClsEditorType.FILED_UPLOAD_AGENT_EDITOR, EnumControl.FILED_UPLOAD_AGENT_EDITOR]
])

/**
 * 获取控件配置
 * @param control 控件名称
 */
export const __getControlInfo = (control: EnumControl | string) => {
  //1.先获取组件配置项
  try {
    //1.先获取组件配置项
    const res = _.cloneDeep(ElementConfigCollection.get(control))
    //2.自定义控件直接返回配置项与事件
    if (control == EnumControl.CUSTOM_CONTROL_GRID) {
      return {
        attrs: res.PROPS,
        events: res.EVENT
      }
    }
    //3.获取通用配置项和事件
    const currencyProps = [...DEFAULT_CURRENCY_PROPS, ...DEFAULT_CURRENCY_LAYOUT_PROPS]
    const currencyEvents = [...DEFAULT_CURRENCY_EVENTS]
    res.PROPS = [...currencyProps, ...res.PROPS]
    res.EVENT = [...currencyEvents, ...res.EVENT]
    return {
      attrs: res.PROPS,
      events: res.EVENT
    }
  } catch (error) {
    //2.获取通用配置项和事件
    const currencyProps = [...DEFAULT_CURRENCY_PROPS, ...DEFAULT_CURRENCY_LAYOUT_PROPS]
    const currencyEvents = [...DEFAULT_CURRENCY_EVENTS]
    return {
      attrs: currencyProps,
      events: currencyEvents
    }
  }
}

/**数据类型对应的编辑方式 */
export const dataTypeConfig = [
  {
    dataType: EnumClsDataType.CHARACTER,
    options: [
      {
        name: EnumControl.INPUT, //,
        title: '单行编辑框',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.INPUT).attrs,
        events: __getControlInfo(EnumControl.INPUT).events,
        control: EnumControl.INPUT,
        dataType: EnumClsDataType.CHARACTER
      },
      {
        name: EnumControl.TEXT_AREA, //,
        title: '多行编辑框',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.TEXT_AREA).attrs,
        events: __getControlInfo(EnumControl.TEXT_AREA).events,
        control: EnumControl.TEXT_AREA,
        dataType: EnumClsDataType.CHARACTER
      },
      {
        name: EnumControl.CLASS_SELECT,
        title: '下拉列表框',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.CLASS_SELECT).attrs,
        events: __getControlInfo(EnumControl.CLASS_SELECT).events,
        control: EnumControl.CLASS_SELECT,
        dataType: EnumClsDataType.CHARACTER
      },
      {
        name: EnumControl.RADIO_GROUP,
        title: '单选列表视图',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.RADIO_GROUP).attrs,
        events: __getControlInfo(EnumControl.RADIO_GROUP).events,
        control: EnumControl.RADIO_GROUP,
        dataType: EnumClsDataType.CHARACTER
      },
      {
        name: EnumControl.CUSTOM,
        title: '自定义组件',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.CUSTOM).attrs,
        events: __getControlInfo(EnumControl.CUSTOM).events,
        control: EnumControl.CUSTOM,
        dataType: EnumClsDataType.CHARACTER
      }
    ] as Array<KDesignerControl>
  },
  {
    dataType: EnumClsDataType.LONG_INTEGER,
    options: [
      {
        name: EnumControl.INPUT,
        title: '单行编辑框',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.INPUT).attrs,
        events: __getControlInfo(EnumControl.INPUT).events,
        control: EnumControl.INPUT,
        dataType: EnumClsDataType.LONG_INTEGER
      },
      {
        name: EnumControl.CLASS_SELECT,
        title: '下拉列表框',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.CLASS_SELECT).attrs,
        events: __getControlInfo(EnumControl.CLASS_SELECT).events,
        control: EnumControl.CLASS_SELECT,
        dataType: EnumClsDataType.LONG_INTEGER
      },
      {
        name: EnumControl.RADIO_GROUP,
        title: '单选列表视图',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.RADIO_GROUP).attrs,
        events: __getControlInfo(EnumControl.RADIO_GROUP).events,
        control: EnumControl.RADIO_GROUP,
        dataType: EnumClsDataType.LONG_INTEGER
      },
      {
        name: EnumControl.RADIO_GROUP,
        title: '单选列表视图',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.RADIO_GROUP).attrs,
        events: __getControlInfo(EnumControl.RADIO_GROUP).events,
        control: EnumControl.RADIO_GROUP,
        dataType: EnumClsDataType.LONG_INTEGER
      },
      {
        name: EnumControl.CUSTOM,
        title: '自定义组件',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.CUSTOM).attrs,
        events: __getControlInfo(EnumControl.CUSTOM).events,
        control: EnumControl.CUSTOM,
        dataType: EnumClsDataType.LONG_INTEGER
      }
    ] as Array<KDesignerControl>
  },
  {
    dataType: EnumClsDataType.INTEGER,
    options: [
      {
        name: EnumControl.INPUT,
        title: '单行编辑框',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.INPUT).attrs,
        events: __getControlInfo(EnumControl.INPUT).events,
        control: EnumControl.INPUT,
        dataType: EnumClsDataType.INTEGER
      },
      {
        name: EnumControl.CLASS_SELECT,
        title: '下拉列表框',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.CLASS_SELECT).attrs,
        events: __getControlInfo(EnumControl.CLASS_SELECT).events,
        control: EnumControl.CLASS_SELECT,
        dataType: EnumClsDataType.INTEGER
      },
      {
        name: EnumControl.RADIO_GROUP,
        title: '单选列表视图',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.RADIO_GROUP).attrs,
        events: __getControlInfo(EnumControl.RADIO_GROUP).events,
        control: EnumControl.RADIO_GROUP,
        dataType: EnumClsDataType.INTEGER
      },
      {
        name: EnumControl.CUSTOM,
        title: '自定义组件',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.CUSTOM).attrs,
        events: __getControlInfo(EnumControl.CUSTOM).events,
        control: EnumControl.CUSTOM,
        dataType: EnumClsDataType.INTEGER
      },
      {
        name: EnumControl.CHECKBOX,
        title: '复选框',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.CHECKBOX).attrs,
        events: __getControlInfo(EnumControl.CHECKBOX).events,
        control: EnumControl.CHECKBOX,
        dataType: EnumClsDataType.INTEGER
      }
    ] as Array<KDesignerControl>
  },
  {
    dataType: EnumClsDataType.TEXT,
    options: [
      {
        name: EnumControl.TEXT_AREA,
        title: '多行编辑框',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.TEXT_AREA).attrs,
        events: __getControlInfo(EnumControl.TEXT_AREA).attrs,
        control: EnumControl.TEXT_AREA,
        dataType: EnumClsDataType.TEXT
      }
    ] as Array<KDesignerControl>
  },
  {
    dataType: EnumClsDataType.DATE,
    options: [
      {
        name: EnumControl.DATE_TIME,
        title: '日期编辑器',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.DATE_TIME).attrs,
        events: __getControlInfo(EnumControl.DATE_TIME).events,
        control: EnumControl.DATE_TIME,
        dataType: EnumClsDataType.DATE
      }
    ]
  },
  {
    dataType: EnumClsDataType.GRID,
    options: [
      {
        name: EnumControl.GRID,
        title: '网格',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.GRID).attrs,
        events: __getControlInfo(EnumControl.GRID).events,
        control: EnumControl.GRID,
        dataType: EnumClsDataType.GRID
      },
      {
        name: EnumControl.FILTER_GRID,
        title: '筛选网格',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.FILTER_GRID).attrs,
        events: __getControlInfo(EnumControl.FILTER_GRID).events,
        control: EnumControl.FILTER_GRID,
        dataType: EnumClsDataType.GRID
      }
    ]
  },
  {
    dataType: EnumClsDataType.FLOAT,
    options: [
      {
        name: EnumControl.INPUT,
        title: '单行编辑框',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.INPUT).attrs,
        events: __getControlInfo(EnumControl.INPUT).events,
        control: EnumControl.INPUT,
        dataType: EnumClsDataType.FLOAT
      },
      {
        name: EnumControl.RADIO_GROUP,
        title: '单选列表视图',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.RADIO_GROUP).attrs,
        events: __getControlInfo(EnumControl.RADIO_GROUP).events,
        control: EnumControl.RADIO_GROUP,
        dataType: EnumClsDataType.FLOAT
      },
      {
        name: EnumControl.CLASS_SELECT,
        title: '下拉列表框',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.CLASS_SELECT).attrs,
        events: __getControlInfo(EnumControl.CLASS_SELECT).events,
        control: EnumControl.CLASS_SELECT,
        dataType: EnumClsDataType.FLOAT
      }
    ]
  },
  {
    dataType: EnumClsDataType.OBJ,
    options: [
      {
        name: EnumControl.OBJECT_SELECT,
        title: '对象选择器',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.OBJECT_SELECT).attrs,
        events: __getControlInfo(EnumControl.OBJECT_SELECT).events,
        control: EnumControl.OBJECT_SELECT,
        dataType: EnumClsDataType.OBJ
      },
      {
        name: EnumControl.GRID_OBJ_SELECT,
        title: '弹窗对象选择器',
        type: EnumType.CONTROL,
        attrs: __getControlInfo(EnumControl.GRID_OBJ_SELECT).attrs,
        events: __getControlInfo(EnumControl.GRID_OBJ_SELECT).events,
        control: EnumControl.GRID_OBJ_SELECT,
        dataType: EnumClsDataType.OBJ
      }
    ]
  }
] as Array<ControlCollection>

/** 自定义控件 */
export const customControls = [
  {
    name: 'KChangeOrderAffectedObjectGrid',
    title: '受影响对象',
    icon: 'table',
    type: EnumType.CONTROL,
    attrs: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).attrs,
    events: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).events,
    control: 'KChangeOrderAffectedObjectGrid',
    controlType: '变更单',
    dataType: ''
  },
  {
    name: 'KChangeOrderActivityPlanGrid',
    title: '变更活动计划',
    icon: 'table',
    type: EnumType.CONTROL,
    attrs: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).attrs,
    events: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).events,
    control: 'KChangeOrderActivityPlanGrid',
    controlType: '变更单',
    dataType: ''
  },
  {
    name: 'KChangeActivityObjGrid',
    title: '改前/改后对象',
    icon: 'table',
    type: EnumType.CONTROL,
    attrs: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).attrs,
    events: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).events,
    control: 'KChangeActivityObjGrid',
    controlType: '变更活动',
    dataType: ''
  },
  {
    name: 'KChangeRequestAffectedViewsGrid',
    title: '受影响对象',
    icon: 'table',
    type: EnumType.CONTROL,
    attrs: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).attrs,
    events: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).events,
    control: 'KChangeRequestAffectedViewsGrid',
    controlType: '变更请求',
    dataType: ''
  },
  {
    name: 'KIssueReportAffectedViewsGrid',
    title: '受影响对象',
    icon: 'table',
    type: EnumType.CONTROL,
    attrs: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).attrs,
    events: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).events,
    control: 'KIssueReportAffectedViewsGrid',
    controlType: '变更问题报告',
    dataType: ''
  },
  {
    name: 'KPromoteActivityBeforeGrid',
    title: '改前对象',
    icon: 'table',
    type: EnumType.CONTROL,
    attrs: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).attrs,
    events: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).events,
    control: 'KPromoteActivityBeforeGrid',
    controlType: '转阶段活动',
    dataType: ''
  },
  {
    name: 'KPromoteActivityAfterGrid',
    title: '改后对象',
    icon: 'table',
    type: EnumType.CONTROL,
    attrs: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).attrs,
    events: __getControlInfo(EnumControl.CUSTOM_CONTROL_GRID).events,
    control: 'KPromoteActivityAfterGrid',
    controlType: '转阶段活动',
    dataType: ''
  }
] as Array<KDesignerControl>

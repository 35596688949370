import { ViewModelOptions } from '@kmsoft/upf-core'
import { KBasePropertyEditorEmitsType, KBasePropertyEditorPropType } from './interface'
import { IKEditorAPI } from './types'
import { MetaProperty } from '../../../../client-srv'
import { KBaseEditorViewModel } from '../form'

/** KBaseEditor */
export default class KBasePropertyEditorViewModel<
  E extends KBasePropertyEditorEmitsType = KBasePropertyEditorEmitsType,
  P extends KBasePropertyEditorPropType = KBasePropertyEditorPropType,
  V = string
> extends KBaseEditorViewModel<E, P, V> {
  constructor(options: ViewModelOptions<P>) {
    super(options)
  }

  /** API */
  get api(): IKEditorAPI {
    return this.props.api
  }

  /** 属性定义 */
  get property(): MetaProperty {
    return this.props.property
  }

  viewDidMount() {}
}

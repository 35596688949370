import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartViewPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KPartViewPropType = ViewPropsTypeExtract<typeof KPartViewPropOptions>

/** 事件 */
export const KPartViewEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartViewEmitsType = ViewEmitsTypeExtract<typeof KPartViewEventEmits>

// 页面定义
export interface IPageDefinition {
  id: string
  name: string
  symbol: string
  suffix: string
}

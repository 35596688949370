import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { style: {"margin-left":"5px"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"margin-left":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_bottom_bar = _resolveComponent("k-bottom-bar")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        rowModelType: _ctx.rowModelType,
        class: "part-collect-grid",
        rowKey: "id",
        name: "partCollectGrid",
        pagination: _ctx.pagination,
        ref: _ctx.$vm.refPartAssociationGrid,
        loadData: _ctx.$vm.loadData
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_template_column, {
            id: "partNumber",
            name: "partNumber",
            dataPropertyName: "partNumber",
            headerText: "零部件编码",
            align: "left",
            width: "150"
          }, {
            default: _withCtx(({ cellValue,row }) => [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                onClick: () => _ctx.$vm.openPartObjTab(row)
              }, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(cellValue), 1)
              ], 8, _hoisted_1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "partName",
            name: "partName",
            dataPropertyName: "partName",
            headerText: "零部件名称",
            align: "left",
            width: "150"
          }),
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "partLifecycleStateCode",
            name: "partLifecycleStateCode",
            dataPropertyName: "partLifecycleStateCode",
            headerText: "零部件生命周期状态",
            options: _ctx.$vm.lifecycleStateOptions,
            width: "150"
          }, null, 8, ["options"]),
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "partWorkingState",
            name: "partWorkingState",
            dataPropertyName: "partWorkingState",
            headerText: "零部件工作状态",
            options: _ctx.$vm.workingStateOptions,
            width: "150"
          }, null, 8, ["options"]),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "type",
            name: "type",
            dataPropertyName: "type",
            headerText: "关系类型",
            align: "left",
            width: "150"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "number",
            name: "number",
            dataPropertyName: "number",
            headerText: "文档编码",
            align: "left",
            width: "150"
          }, {
            default: _withCtx(({ cellValue,row }) => [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                onClick: () => _ctx.$vm.openDocObjTab(row)
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(cellValue), 1)
              ], 8, _hoisted_3)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "name",
            name: "name",
            dataPropertyName: "name",
            headerText: "文档名称",
            align: "left",
            width: "150"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "businessVersion",
            name: "businessVersion",
            dataPropertyName: "businessVersion",
            headerText: "业务版本",
            align: "left",
            width: "150"
          }),
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "lifecycleStateCode",
            name: "lifecycleStateCode",
            dataPropertyName: "lifecycleStateCode",
            headerText: "文档生命周期状态",
            options: _ctx.$vm.lifecycleStateOptions,
            width: "150"
          }, null, 8, ["options"]),
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "workingState",
            name: "workingState",
            dataPropertyName: "workingState",
            headerText: "文档工作状态",
            options: _ctx.$vm.workingStateOptions,
            width: "150"
          }, null, 8, ["options"])
        ]),
        bottomBar: _withCtx(() => [
          _createVNode(_component_k_bottom_bar, {
            enableExcelExport: "",
            onRefresh: _cache[0] || (_cache[0] = () => _ctx.$vm.refresh()),
            onExcelSelectExport: _cache[1] || (_cache[1] = () => _ctx.$vm.exportSelectAsExcel()),
            onExcelExport: _cache[2] || (_cache[2] = () => _ctx.$vm.exportAsExcel())
          })
        ]),
        _: 1
      }, 8, ["rowModelType", "pagination", "loadData"])
    ]),
    _: 1
  }))
}

import { defineView } from '@kmsoft/upf-core'
import KFolderManage from '../../folder-manage'
import KProductWorkspaceViewModel from './KProductWorkspaceViewModel'
import { KProductWorkspaceEventEmits, KProductWorkspacePropOptions } from './interface'

export default defineView({
  name: 'Kproduct-workspace',
  props: KProductWorkspacePropOptions,
  emits: KProductWorkspaceEventEmits,
  viewModel: KProductWorkspaceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  },
  components: { KFolderManage }
})

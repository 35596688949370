import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_button = _resolveComponent("k-button")!

  return (_openBlock(), _createBlock(_component_k_button, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vm.openEditPanel()))
  }, {
    default: _withCtx(() => [
      _createTextVNode("打开编辑面板")
    ]),
    _: 1
  }))
}
import { connect, withInstall } from '@kmsoft/upf-core'
import KFlowHistorySigningView from './KFlowHistorySigning.vue'
import KFlowHistorySigningViewModel from './KFlowHistorySigningViewModel'

const KFlowHistorySigning = connect(KFlowHistorySigningView, KFlowHistorySigningViewModel)

export default withInstall(KFlowHistorySigning)
export { KFlowHistorySigning, KFlowHistorySigningView, KFlowHistorySigningViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { getCandidates } from '../utils'
import { EnumAttributeType, EnumDataType, KControlAttribute, KControlEvent } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'

/* 组件的基本属性 */
const comConfig = [
  {
    name: 'icon',
    title: '图标',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'type',
    title: '类型',
    control: 'KSelect',
    options: getCandidates(['checkbox', 'button']),
    defaultValue: 'checkbox',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'checkedText',
    title: '选中后文字',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  }
]

/* 基础配置项集合 */
const BASE = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE, PROPERTY_CONFIG_ITEM_COLLECTION.DISABLED] as Array<KControlAttribute>

/**控件属性配置集合 */
const PROPS = comConfig

/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
] as Array<KControlAttribute>

const EVENT: Array<KControlEvent> = [{ name: 'change', title: '值改变事件', describe: '值改变事件 的说明信息...' }]

export default { BASE, PROPS, LAYOUT, EVENT }

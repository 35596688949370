
import { IKTreeNode, TreeViewSelectEventArgs, defineView } from '@kmsoft/upf-core'
import { KIssueReportPartStruPropOptions, KIssueReportPartStruEventEmits } from './interface'
import KIssueReportPartStruViewModel from './KIssueReportPartStruViewModel'
import { ref } from 'vue'

export default defineView({
  name: 'KIssueReportPartStru',
  props: KIssueReportPartStruPropOptions,
  emits: KIssueReportPartStruEventEmits,
  viewModel: KIssueReportPartStruViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

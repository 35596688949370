import { ObjectCreatePanelLoadDataCallback } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartFormPropOptions = {
  ...BaseViewPropOptions,
  modelCode: VuePropTypes.createType<string>().def('Part'),
  formValue: VuePropTypes.createType<any>().def(),
  classTreeSelectDisplay: VuePropTypes.createType<boolean>().def(false)
}

/** 参数类型 **/
export type KPartFormPropType = ViewPropsTypeExtract<typeof KPartFormPropOptions>

/** 事件 */
export const KPartFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartFormEmitsType = ViewEmitsTypeExtract<typeof KPartFormEventEmits>

/** 零部件来源 */
export enum EnumSource {
  /**
   * 自制
   */
  Make = '自制',
  /**
   * 采购
   */
  Buy = '采购'
}

/** 默认单位 */
export enum EnumDefaultUnitEnum {
  EA = '每个',
  AS_NEEDED = '按需',
  KG = '千克',
  M = '米',
  L = '升',

  EA2 = '台',
  EA3 = '套',
  EA4 = '件',
  EA5 = '只',
  EA6 = '张',
  EA7 = '包',
  EA8 = '本',
  EA9 = '块',
  EA10 = '片',
  EA11 = '条'
}

/** 装配模式 */
export enum EnumAssemblyMode {
  Separable = '可分离',
  Inseparable = '不可分离',
  Component = '组件'
}

/** 阶段 */
export enum EnumPhase {
  D = 'D',
  T = 'T',
  S = 'S'
}

/** 视图 */
export enum EnumPartView {
  DESIGN = '设计',
  MANUFACTURE = '制造'
}

/** 生命周期状态 */
export enum EnumLifecycleState {
  IN_WORK = '工作中',
  UNDER_REVIEW = '审核中',
  RELEASED = '已发布',
  OBSOLETED = '已废弃'
}

/** 引用对象 */
export interface IObjectReferenceDefinition {
  id: string
  clazz: string
}

// 页面定义
export type IPartDefinition = {
  // id
  id: string
  // 类型
  rdmExtensionType: string
  // 编码
  number: string
  // 名称
  name: string
  // 大版本
  version: string
  // 装配模式
  assemblyMode: EnumAssemblyMode
  // 零部件视图
  partViewEnum: EnumPartView
  // 阶段
  phase: EnumPhase
  // 默认单位
  defaultUnitEnum: EnumDefaultUnitEnum
  // 来源
  source: EnumSource
  // 生命周期状态
  lifecycleStateEnum: EnumLifecycleState
  // 主对象
  master: IObjectReferenceDefinition
  // 分支对象
  branch: IObjectReferenceDefinition
  // 文件夹
  folder: IObjectReferenceDefinition
} & Record<string, any>

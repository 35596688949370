
import { ToolStripItemChangeEventArgs, ToolStripItemClickedEventArgs, defineView } from '@kmsoft/upf-core'
import { KProductNeedsEditPanelPropOptions, KProductNeedsEditPanelEventEmits } from './interface'
import KProductNeedsEditPanelViewModel from './KProductNeedsEditPanelViewModel'

export default defineView({
  name: 'KProductNeedsEditPanel',
  props: KProductNeedsEditPanelPropOptions,
  emits: KProductNeedsEditPanelEventEmits,
  viewModel: KProductNeedsEditPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

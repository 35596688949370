import { connect, withInstall } from '@kmsoft/upf-core'
import KCustomerNeedsSectionEditPanelView from './KCustomerNeedsSectionEditPanel.vue'
import KCustomerNeedsSectionEditPanelViewModel from './KCustomerNeedsSectionEditPanelViewModel'

const KCustomerNeedsSectionEditPanel = connect(KCustomerNeedsSectionEditPanelView, KCustomerNeedsSectionEditPanelViewModel)

export default withInstall(KCustomerNeedsSectionEditPanel)
export { KCustomerNeedsSectionEditPanel, KCustomerNeedsSectionEditPanelView, KCustomerNeedsSectionEditPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  KDataGridRowData
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartMaterialBomFormPropOptions = {
  ...BaseViewPropOptions,
  selectedNode: VuePropTypes.createType<ObjBusinessParam>().def()
}

/** 参数类型 **/
export type KPartMaterialBomFormPropType = ViewPropsTypeExtract<typeof KPartMaterialBomFormPropOptions>

/** 事件 */
export const KPartMaterialBomFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartMaterialBomFormEmitsType = ViewEmitsTypeExtract<typeof KPartMaterialBomFormEventEmits>


import { defineView } from '@kmsoft/upf-core'
import { KPdfBrowserPropOptions, KPdfBrowserEventEmits } from './interface'
import KPdfBrowserViewModel from './KPdfBrowserViewModel'

export default defineView({
  name: 'KPdfBrowser',
  props: KPdfBrowserPropOptions,
  emits: KPdfBrowserEventEmits,
  viewModel: KPdfBrowserViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

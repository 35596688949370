import { BaseViewModel, KFormViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { IKUserSelectorFormDef, KTempUserSelectorEmitsType, KTempUserSelectorPropType } from './interface'

/**
 * 临时人员选择（人员选择面板接入后需要删除）
 *
 * */
export default class KTempUserSelectorViewModel extends BaseViewModel<KTempUserSelectorEmitsType, KTempUserSelectorPropType> {
  /**表单 */
  refFolderForm = ref<KFormViewModel>()

  //表单绑定数据
  formDataState = ref<IKUserSelectorFormDef>({
    userId: this.props.userId
  })
  constructor(options: ViewModelOptions<KTempUserSelectorPropType>) {
    super(options)
  }

  viewDidMount() {
    console.log('KTempUserSelectorViewModel', this.props.userId)
  }

  getSelectedUserId() {
    return this.formDataState.value.userId
  }
}

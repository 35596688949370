import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KIssueReportPartStruPropOptions = {
  ...BaseViewPropOptions,
  objParam: VuePropTypes.createType<Record<string, string>>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KIssueReportPartStruPropType = ViewPropsTypeExtract<typeof KIssueReportPartStruPropOptions>

/** 事件 */
export const KIssueReportPartStruEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KIssueReportPartStruEmitsType = ViewEmitsTypeExtract<typeof KIssueReportPartStruEventEmits>
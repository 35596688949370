import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'

export interface ITreeSelectData {
  key: string
  title: string
  value: string
  disabled: boolean
  children: Array<ITreeSelectData>
}

// form
export interface IEnumsPanelDefinition {
  /** 枚举类型id */
  refEnumId: string
  /** 枚举类型code */
  refEnumCode?: string
  /** 枚举类型名称 */
  refEnumName: string
}

// 对象界面定义定义状态
export interface IEnumsPanelPropsViewModelState {
  formState: IEnumsPanelDefinition
  /** 枚举下拉树选择 */
  enumTreeData: Array<Record<string, any>>
}

// 对象界面定义定义事件
export const KEnumsPanelPropsEventEmits = {
  ...BaseViewEventEmits
}

// 对象界面定义定义属性选项
export const KEnumsPanelPropOptions = {
  ...BaseViewPropOptions
}

// emit type
export type KEnumsPanelPropsEventEmitsType = ViewEmitsTypeExtract<typeof KEnumsPanelPropsEventEmits>

// 对象界面定义定义属性类型
export type KEnumsPanelPropType = ViewPropsTypeExtract<typeof KEnumsPanelPropOptions>

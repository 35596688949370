import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectCreatePanelView from './KObjectCreatePanel'
import KObjectCreatePanelViewModel from './KObjectCreatePanelViewModel'
import * as controls from './controls'

const coms: Record<string, any> = {}
for (const key in controls) {
  const component = Reflect.get(controls, key)
  if (Reflect.has(component, 'install')) {
    coms[key] = component
  }
}

const KObjectCreatePanel = connect(KObjectCreatePanelView, KObjectCreatePanelViewModel)

export default withInstall(KObjectCreatePanel, coms)
export { KObjectCreatePanel, KObjectCreatePanelView, KObjectCreatePanelViewModel }
export * from './controls'
export * from './interface'

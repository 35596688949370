import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { EnumEditorEnvironment, IKEditorAPI } from './types'
import { MetaProperty } from '../../../../client-srv'
import { KBaseEditorEventEmits, KBaseEditorPropOptions } from '../form'

/** 参数 **/
export const KBasePropertyEditorPropOptions = {
  ...KBaseEditorPropOptions,
  /**
   * 编辑器API
   * @description 编辑器对外操作 API
   */
  api: VuePropTypes.createType<IKEditorAPI>()
    .setRequired()
    .def(),
  /**
   * 属性定义
   * @description 当前编辑器对应属性
   */
  property: VuePropTypes.createType<MetaProperty>()
    .setRequired()
    .def(),
  /** 当前环境 */
  environment: VuePropTypes.createType<EnumEditorEnvironment>()
    .setRequired()
    .def(),
  /** 数据源 */
  dataSource: VuePropTypes.string()
    .setRequired()
    .def(),
  /** 字段 */
  field: VuePropTypes.string()
    .setRequired()
    .def(),
  /**可为空 */
  required: VuePropTypes.bool()
    .setRequired()
    .def(true)
}

/** 参数类型 **/
export type KBasePropertyEditorPropType = ViewPropsTypeExtract<typeof KBasePropertyEditorPropOptions>

/** 事件 */
export const KBasePropertyEditorEventEmits = {
  ...KBaseEditorEventEmits
}

/** 事件类型 **/
export type KBasePropertyEditorEmitsType = ViewEmitsTypeExtract<typeof KBasePropertyEditorEventEmits>

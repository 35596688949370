import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KDocCreatePanelEmitsType, KDocCreatePanelPropType } from './interface'
import { KDocFormViewModel } from './doc-form'

/** KDocCreatePanel */
export default class KDocCreatePanelViewModel extends BaseViewModel<KDocCreatePanelEmitsType, KDocCreatePanelPropType> {
  tabActiveKey = ref<string>('1')
  refDocPanel = ref<KDocFormViewModel>()
  constructor(options: ViewModelOptions<KDocCreatePanelPropType>) {
    super(options)
  }

  viewDidMount() {}

  getFormData() {
    return this.refDocPanel.value?.getValue()
  }
  validFormData() {
    return this.refDocPanel.value?.validate()
  }
}

import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumToolStripItemKeys } from '../types'
import { ObjectToolStripItem } from '../../object-tool-strip'
import { EnumVersionShowType } from '../../../client-srv/object'
import { EnumClassTemplate } from '../../../client-srv'

export const toolStripItemFilter: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILTER,
  title: '过滤',
  icon: 'funnel-plot',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+t'
}

export const toolStripItemCancelFilter: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NO_FILTER,
  title: '取消过滤',
  icon: 'stop',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemFind: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FIND,
  title: '查找...',
  icon: 'search',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+f'
}

export const toolStripItemGlobalSearch: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITME_GLOBAL_SEARCH,
  title: '全局查询',
  icon: 'file-search',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemShowSubClass: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SHOW_SUBCLASS,
  title: '显示子类',
  icon: 'edit',
  visible: true,
  compType: EnumToolStripCompType.CHECKBOX
}

export const toolStripItemVersionType: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_VERSION_SHOW_TYPE,
  title: '展示版本',
  visible: true,
  compType: EnumToolStripCompType.SELECT,
  allowClear: false,
  options: [
    {
      value: EnumVersionShowType.LatestVersion,
      label: '最新版本'
    },
    {
      value: EnumVersionShowType.AllVersion,
      label: '所有版本'
    },
    {
      value: EnumVersionShowType.LatestPublishVersion,
      label: '最新发布版本'
    },
    {
      value: EnumVersionShowType.AllPublished,
      label: '所有发布版本'
    }
  ],
  value: EnumVersionShowType.LatestVersion,
  unSupportedObjClsTempletID: [EnumClassTemplate.BASELINE, EnumClassTemplate.ORG, EnumClassTemplate.USER]
}

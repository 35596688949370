
import { defineComponent } from 'vue'
import { VuePropTypes } from '@kmsoft/upf-core'
import KProjectPageWrapper from '../project-page-wrapper'

/**
 * ProjectIframe
 */
export default defineComponent({
  name: 'ProjectIframe', //组件名称必须指定，否则在获取组件路径时，当前组件路径节点名会有问题
  inheritAttrs: false,
  /**
   * 组件属性定义
   */
  props: {
    name: VuePropTypes.string().def()
  },
  components: {
    KProjectPageWrapper
  },
  /**
   * 组件事件定义
   */
  emits: {},
  setup(props, { attrs, emit, expose, slots }) {
    //#region  私有方法
    //#endregion

    //#region 字段，属性定义
    let windowid = new Date().getTime().toString()
    //#endregion

    //#region  公共方法
    //#endregion

    //#region  事件委托方法
    //#endregion

    //#region 属性监听逻辑
    //#endregion

    //#region 生命周期函数
    //#endregion

    //#region 继承相关
    //#endregion

    //#region setup返回值
    return { windowid }
    //#endregion
  }
})


import { EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import { KPlanValidationMetricsGridPropOptions, KPlanValidationMetricsGridEventEmits } from './interface'
import KPlanValidationMetricsGridViewModel from './KPlanValidationMetricsGridViewModel'
import { clsCodeMap } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KPlanValidationMetricsGrid',
  props: KPlanValidationMetricsGridPropOptions,
  emits: KPlanValidationMetricsGridEventEmits,
  viewModel: KPlanValidationMetricsGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      clsCodeMap,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})

import { defineExtendView } from '@kmsoft/upf-core'
import { KCommonObjectCreateFormPropOptions, KCommonObjectCreateFormEventEmits } from './interface'
import { KObjectCreatePanelFormBase } from '../base'

export default defineExtendView({
  name: 'KCommonObjectCreateForm',
  props: KCommonObjectCreateFormPropOptions,
  emits: KCommonObjectCreateFormEventEmits,
  parent: KObjectCreatePanelFormBase
})

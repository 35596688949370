
import { CSSProperties, provide } from 'vue'
import { KExtendLayoutHeader, KExtendLayoutSidebar } from '../controls'
import KExtendLayoutViewModel from './KExtendLayoutViewModel'
import { KExtendLayoutEventEmits, KExtendLayoutPropOptions } from './interface'
import { defineView } from '@kmsoft/upf-core'

/** 基础布局组件 */
export default defineView({
  name: 'KExtendLayout',
  viewModel: KExtendLayoutViewModel,
  components: {
    KExtendLayoutSidebar,
    KExtendLayoutHeader
  },
  props: KExtendLayoutPropOptions,
  emits: KExtendLayoutEventEmits,
  setup(props, { vm }) {
    const $PageManager = { openPage: vm.openPage, closePage: vm.closePage, closeAllPages: vm.closeAllPages }
    provide('$PageManager', $PageManager)
    return {}
  }
})

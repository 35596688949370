import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KFlowDetailPropOptions = {
  ...BaseViewPropOptions,
  dataForm: VuePropTypes.createType<any>().def({})
}

/** 参数类型 **/
export type KFlowDetailPropType = ViewPropsTypeExtract<typeof KFlowDetailPropOptions>

/** 事件 */
export const KFlowDetailEventEmits = {
  ...BaseViewEventEmits,
  /** 是否打开面板 **/
  open: (isOpen: boolean) => true,
  submitComplete: () => true,
  updateExecutorComplete: () => true
}

/** 事件类型 **/
export type KFlowDetailEmitsType = ViewEmitsTypeExtract<typeof KFlowDetailEventEmits>

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-center flex-direction-column h100p w250" }
const _hoisted_2 = { class: "flex1 flex-start-center pl100 w100p" }
const _hoisted_3 = { class: "flex1 flex-start-center pl100 w100p" }
const _hoisted_4 = { class: "flex1 flex-start-center pl100 w100p" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_checkbox = _resolveComponent("k-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_checkbox, {
        checked: _ctx.$vm.includeDocDescribe,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.includeDocDescribe) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode("收集描述文档")
        ]),
        _: 1
      }, 8, ["checked"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_k_checkbox, {
        checked: _ctx.$vm.includeDocReference,
        "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.includeDocReference) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode("收集参考文档")
        ]),
        _: 1
      }, 8, ["checked"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_k_checkbox, {
        checked: _ctx.$vm.onlyPublish,
        "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.onlyPublish) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode("仅收集发布的对象")
        ]),
        _: 1
      }, 8, ["checked"])
    ])
  ]))
}
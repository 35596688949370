
import { defineView } from '@kmsoft/upf-core'
import { KUserSelectorPropOptions, KUserSelectorEventEmits } from './interface'
import KUserSelectorViewModel from './KUserSelectorViewModel'
import KAutoCompleteSelector from '../../auto-complete-selector'
import { computed } from 'vue'
import { SelectItem } from '../../../../client-srv'

/**
 * 用户选择器
 */
export default defineView({
  name: 'KUserSelector',
  props: KUserSelectorPropOptions,
  emits: KUserSelectorEventEmits,
  components: { KAutoCompleteSelector },
  viewModel: KUserSelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const userOptions = computed(() => {
      return (
        vm.userList.value.map(user => {
          return new SelectItem(user.name, user.name?.split(' ')[0])
        }) || []
      )
    })

    return { userOptions }
  }
})

import { UrlHelper } from '@kmsoft/ebf-common'
import { AppRunner, EnumApplicationRunnerExecutionOpportunity } from '@kmsoft/upf-core'
import { iframeMsgClientSrv } from '../client-srv'

export default class ProjectManageRunner implements AppRunner {
  opportunity(): EnumApplicationRunnerExecutionOpportunity {
    return EnumApplicationRunnerExecutionOpportunity.AFTER_MOUNT
  }
  run(): Promise<void> {
    const windowid = UrlHelper.getQueryVariable('windowid')
    iframeMsgClientSrv.setWindowId(windowid || '')
    return Promise.resolve()
  }
}

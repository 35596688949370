import {
  BaseViewModel,
  IKTreeNode,
  ToolStripItemClickedEventArgs,
  TreeViewSelectEventArgs,
  ViewModelOptions,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KRequireManageEmitsType, KRequireManagePropType } from './interface'
import { ref, nextTick } from 'vue'
import {
  EnumObjectPanelTabParamType,
  EnumObjTabPageId,
  KObjectPanelViewModel,
  ObjBusinessParam,
  ObjectPanelAttachParams,
  ObjectPanelDataUpdatedEvent,
  ObjectPanelTabConfigSetParams
} from '@kmsoft/ebf-common'

export default class KRequireManageViewModel extends BaseViewModel<KRequireManageEmitsType, KRequireManagePropType> {
  /** 选中的树节点 */
  selectNode = ref<any>()
  /** 对象面板 */
  refObjectPanel = ref<KObjectPanelViewModel>()
  /** 禁止的标签页(编码集合) */
  suppressedTabs = ref<Array<string>>([])
  /** 对象参数 */
  objParam = ref<any>()
  /** 是否顶层打开对象面板 */
  isHighestOpen = ref<boolean>(false)
  /** 禁止操作 */
  suppressOperation = ref<boolean>(false)
  /** 禁止对象工具栏条目 */
  suppressObjectLife = ref<boolean>(false)
  /** 附属参数 */
  attachParams = ref<any>({})
  constructor(options: ViewModelOptions<KRequireManagePropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 树选中后事件
   * @param event 树选中后事件
   */
  afterSelect(event: TreeViewSelectEventArgs<any>) {
    nextTick(async () => {
      const { modelCode, id } = event.node
      this.objParam.value = {
        id,
        modelCode,
        modelGroup: 'requirement'
      }
      this.attachParams.value = {
        [EnumObjectPanelTabParamType.RootPartObjParam]: this.objParam.value,
        [EnumObjectPanelTabParamType.ComponentEnviroment]: modelCode
      }

      this.selectNode.value = event.node
    })
  }

  /** 点击详情 */
  async loadData() {}

  /**
   * 对象面板更新事件
   * @param event
   */
  async onObjectPanelDataUpdated(event: ObjectPanelDataUpdatedEvent) {
    if (event.tabId == EnumObjTabPageId.StructureList || event.tabId == EnumObjTabPageId.Property) {
      // /** 获取选中的节点 */
      // const selectedNode = this.refPartStructureTree.value?.treeView?.getSelectedNode() as PartStructureTreeNode
      // /** 刷新选择的节点 */
      // if (selectedNode) {
      //   await this.refPartStructureTree.value?.refreshNode(
      //     selectedNode,
      //     event.tabId == EnumObjTabPageId.StructureList
      //       ? EnumTreeViewNodeRefreshType.NODE_CHILD
      //       : EnumTreeViewNodeRefreshType.SELF
      //   )
      // }
    }
  }
}

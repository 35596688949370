import { connect, withInstall } from '@kmsoft/upf-core'
import KDocFormView from './KDocForm.vue'
import KDocFormViewModel from './KDocFormViewModel'

const KDocForm = connect(KDocFormView, KDocFormViewModel)

export default withInstall(KDocForm)
export { KDocForm, KDocFormView, KDocFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

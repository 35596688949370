/** 权限类型 */
export enum EnumPurviewType {
  /** 对象类 */
  ObjectClass = 1,
  /** 对象 */
  ObjectOperation = 2,
  /** 功能权限 */
  Func = 3
}
/** 权限码 */
export enum EnumPurviewCode {
  /** 产品工作区-全部产品库 */
  AllProductLibraries = '1010201',
  /** 产品工作区-新建产品库 */
  CreateProductLibrary = '1010202',
  /** 产品工作区-编辑产品库 */
  ModifyProductLibrary = '1010203',
  /** 产品工作区-删除产品库 */
  DeleteProductLibrary = '1010204',
  /** 产品工作区-打开产品库 */
  OpenProductLibrary = '1010205',

  /** 流程建模-流程模板定义 */
  DefineWorkflowTemplage = '1010601',
  /** 流程建模-新增 */
  CreateWorkflowTemplate = '1010602',
  /** 流程建模-刷新 */
  RefreshWorkflowTemplate = '1010603',
  /** 流程建模-预览 */
  PreviewWorkflowTemplate = '1010604',
  /** 流程建模-发布 */
  PublishWorkflowTemplate = '1010605',
  /** 流程建模-停用 */
  DisableWorkflowTemplate = '1010606',
  /** 流程建模-修改 */
  ModifyWorkflowTemplate = '1010607',
  /** 流程建模-删除 */
  DeleteWorkflowTemplate = '1010608',

  /** 查看（本目录） */
  View = '1010801',
  /** 查看对象列表 */
  ViewList = '1010901',
  /** 修改目录属性 */
  ModifyFolder = '1011001',
  /** 创建子目录 */
  CreateFolder = '1011101',
  /** 添加/删除对象链接 */
  MergeObjectLinks = '1011201',
  /** 删除目录 */
  DeleteFolder = '1011301',
  /** 删除子目录 */
  DeleteChildFolders = '1011401',

  /** 批量创建文档 */
  BatchCreateDocuments = '1011501',
  /** 添加 */
  Add = '1011601',
  /** 添加至CAD工作区 */
  AddToEmpWorkspace = '1011701',
  /** 复制 */
  Copy = '1011801',
  /** 剪切 */
  Cut = '1011901',
  /** 粘贴 */
  Paste = '1012001',
  /** 列表刷新 */
  RefreshList = '1012101',
  /** 列表搜索 */
  Search = '1012201',
  /** 零部件导入模板下载 */
  DownloadPartTemplate = '1012301',
  /** 零部件导入 */
  ImportParts = '1012401',
  /** 批量转通用件 */
  BatchConvertToCommonParts = '1012501',
  /** 批量维护物料 */
  BatchManagedParts = '1012601',
  /** 批量维护BOM属性 */
  BatchModifyBom = '1012701',
  /** 使用统计 */
  StatUsages = '1012801',
  /** 创建基线 */
  CreateBaseline = '1012901',
  /** 创建转阶段活动 */
  CreateTransitionStageActivities = '1013001',
  /** BOM报表 */
  BomReport = '1013101',

  /** 标签页-签审历史 */
  ReviewHistory = '1013201',
  /** 标签页-结构列表 */
  UsageList = '1013202',
  /** 标签页-描述关系 */
  DescribeRelations = '1013203',
  /** 标签页-参考关系 */
  ReferenceRelations = '1013204',
  /** 标签页-描述文档 */
  DescribeDocuments = '1013205',
  /** 标签页-参考文档 */
  ReferenceDocuments = '1013206',
  /** 标签页-附属文档 */
  AttachedDocuments = '1013207',
  /** 标签页-历史版本 */
  VersionHistory = '1013208',
  /** 标签页-变更记录 */
  ChangeHistory = '1013209',
  /** 标签页-所属基线 */
  BalongToBaseline = '1013210',
  /** 标签页-全局替代件 */
  GlobalSubstitution = '1013211',
  /** 标签页-局部替代件 */
  PartialSubstitution = '1013212',
  /** 标签页-使用统计 */
  StatUsageRelations = '1013213',
  /** 标签页-可视化 */
  Visualization = '1013214',
  /** 标签页-关系 */
  Relations = '1013215'
}

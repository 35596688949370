import { withInstall, connect } from '@kmsoft/upf-core'
import KFilterGridFilterItemDesignerView from './KFilterGridFilterItemDesigner.vue'
import { KFilterGridFilterItemDesignerViewModel } from './KFilterGridFilterItemDesignerViewModel'

const KFilterGridFilterItemDesigner = withInstall(
  connect(KFilterGridFilterItemDesignerView, KFilterGridFilterItemDesignerViewModel)
)

export { KFilterGridFilterItemDesigner, KFilterGridFilterItemDesignerViewModel }
export * from './interface'

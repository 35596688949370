
import { defineView } from '@kmsoft/upf-core'
import { KPartSelectorPropOptions, KPartSelectorEventEmits } from './interface'
import KPartSelectorViewModel from './KPartSelectorViewModel'

export default defineView({
  name: 'KPartSelector',
  props: KPartSelectorPropOptions,
  emits: KPartSelectorEventEmits,
  viewModel: KPartSelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

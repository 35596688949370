import { BaseViewModel, IKTreeNode, KTreeViewViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDesignerMetadataPropType, KDesignerMetadataEventEmitsType } from './interface'
import { ref, watch } from 'vue'

/** 导航管理 */
export default class KDesignerMetadataViewModel extends BaseViewModel<
  KDesignerMetadataEventEmitsType,
  KDesignerMetadataPropType
> {
  refDesignerConfig = ref<KTreeViewViewModel>()
  activeKeys = ref([] as Array<string>)
  localElementGroups = ref<Array<IKTreeNode>>()

  constructor(options: ViewModelOptions<KDesignerMetadataPropType>) {
    super(options)
    watch(
      () => this.props.dataSource,
      newValue => {
        this.localElementGroups.value = [newValue]
      },
      { immediate: true }
    )
  }

  viewDidMount(): void {}
}


import { defineView } from '@kmsoft/upf-core'
import { KFlowDetailPropOptions, KFlowDetailEventEmits } from './interface'
import KFlowDetailViewModel from './KFlowDetailViewModel'
import { ref, watch } from 'vue'

export default defineView({
  name: 'KFlowDetail',
  props: KFlowDetailPropOptions,
  emits: KFlowDetailEventEmits,
  viewModel: KFlowDetailViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const activeKey = ref(['1'])

    watch(activeKey, newValue => {
      vm.emit('open', !!newValue.length)
    })

    return { activeKey }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KViewEventConfigEventEmits, KViewEventConfigPropOptions } from './interface'
import KViewEventConfigViewModel from './KViewEventConfigViewModel'

export default defineView({
  name: 'KViewEventConfig',
  props: KViewEventConfigPropOptions,
  emits: KViewEventConfigEventEmits,
  viewModel: KViewEventConfigViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    const computedShowBadge = (eventName: string) => {
      const scriptInfo = vm.currentEventValueItem?.value.find(x => x.name === eventName)
      if (scriptInfo && scriptInfo.code !== '') return true
      return false
    }
    return {
      computedShowBadge
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KConfigTreePropOptions } from './interface'
import { EnumNodeLevel, EnumNodeType } from '../../interface'
import KSysConfigTreeViewModel from './KSysConfigTreeViewModel'

export default defineView({
  name: 'SysConfigTree',
  props: KConfigTreePropOptions,
  viewModel: KSysConfigTreeViewModel,
  setup() {
    return {
      EnumNodeLevel,
      EnumNodeType
    }
  }
})

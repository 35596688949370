import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { IFileEditFormDefinition, KFileEditEmitsType, KFileEditPropType } from './interface'
import { ref, watch } from 'vue'

/** KFileEdit */
export default class KFileEditViewModel extends BaseViewModel<KFileEditEmitsType, KFileEditPropType> {
  formState = ref<IFileEditFormDefinition>()

  constructor(options: ViewModelOptions<KFileEditPropType>) {
    super(options)
    watch(
      () => () => options.props.propertyId,
      () => {
        // 判断editArgs是否为空
        const data = options.props.editArgs ? JSON.parse(options.props.editArgs) : {}
        // 这里modelCode为选中节点的code
        data.modelCode = options.props.selectedNode.code
        this.formState.value = data
      },
      { immediate: true }
    )
  }

  setValue(formState: IFileEditFormDefinition) {
    this.formState.value = formState
  }

  getValue() {
    return this.formState.value
  }
}

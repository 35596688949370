import { EnumDialogResult, KDialog, KDialogClosingEvent, connect, withInstall } from '@kmsoft/upf-core'
import lodash from 'lodash'
import KFileUploaderView from './KFileUploader'
import KFileUploaderViewModel from './KFileUploaderViewModel'
import {
  FileUploaderAsyncDialogParams,
  FileUploaderDialogParams,
  FileUploaderDialogResult,
  FileUploaderResult
} from './interface'
import './style/style.less'

export * from './interface'
export { KFileUploader, KFileUploaderView, KFileUploaderViewModel }

/**
 * 异步上传方法
 * @param params
 * @returns
 */
const uploadAsync = (params: FileUploaderAsyncDialogParams): Promise<FileUploaderResult> => {
  return new Promise<FileUploaderResult>((resolve, reject) => {
    KDialog.show({
      title: params.title || '上传文件',
      closable: false,
      props: {
        showUploadButton: true,
        fillSpace: true,
        ...lodash.omit(params, 'title'),
        modelCode: params.modelCode,
        attributeName: params.attributeName
      },
      content: KFileUploader,
      onClosing(event: KDialogClosingEvent<KFileUploaderViewModel>) {
        // 如果点击取消
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
          resolve({ dialogResult: event.dialogResult, fileList: [] })
          return
        }

        /** 文件列表 */
        const fileList = event.viewInstance!.getFileList()

        resolve({ dialogResult: event.dialogResult!, fileList: fileList })
      }
    })
  })
}

/**
 * 异步上传方法
 * @param params
 * @returns
 */
const upload = (params: FileUploaderDialogParams): FileUploaderDialogResult => {
  return KDialog.show({
    title: params.title || '上传文件',
    closable: false,
    props: { showUploadButton: true, fillSpace: true, ...lodash.omit(params, 'title') },
    content: KFileUploader,
    onClosing: params.onClosing
  })
}

/** 类型定义 */
type KFileUploaderType = typeof KFileUploaderView & { uploadAsync: typeof uploadAsync; upload: typeof upload }

const KFileUploader = connect(KFileUploaderView, KFileUploaderViewModel) as KFileUploaderType

// 挂载方法
KFileUploader.uploadAsync = uploadAsync
KFileUploader.upload = upload

export default withInstall(KFileUploader)

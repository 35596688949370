import {
  VuePropTypes,
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract
} from '@kmsoft/upf-core'
import { EnumQueryConditionOperator, QueryCondition } from '../../client-srv'
import { RuleResult } from '../rule-definer'

/** 快速搜索表单数据类型 */
export type QuickSearchForm = {
  /** 列名集合 */
  colnameList: string[]
  /** 操作符 */
  operator: EnumQueryConditionOperator
  /** 查询关键值 */
  value: string
}

export class AdvancedSearchFilterHelper {
  static isEmpty(filter: RuleResult): boolean {
    if (filter.rules && filter.rules.length > 0) {
      for (const filterItem of filter.rules) {
        if (filterItem.value !== '') {
          return false
        }
      }
    }
    return true
  }

  static get empty(): RuleResult {
    return {
      expression: '',
      rules: []
    }
  }
}

/** KQuickSearchBar 参数 **/
export const KQuickSearchBarPropOptions = {
  ...BaseViewPropOptions,
  /**
   * 是否显示左侧标签
   */
  isShowLabel: VuePropTypes.bool().def(true),
  /**
   * 是否单行搜索样式，默认值：false
   */
  isSingleLine: VuePropTypes.bool().def(false),
  /**
   * 是否显示搜索按钮
   */
  isShowSearchButton: VuePropTypes.bool().def(false),
  /**
   * 搜索条件默认值
   */
  searchFilter: VuePropTypes.createType<QueryCondition>().def()
}

/** KQuickSearchBar 参数类型 **/
export type KQuickSearchBarPropType = ViewPropsTypeExtract<typeof KQuickSearchBarPropOptions>

/** KQuickSearchBar 事件 */
export const KQuickSearchBarEventEmits = {
  ...BaseViewEventEmits,
  /** 搜索事件 */
  search: (condition: QueryCondition) => true
}

/** KQuickSearchBar 事件类型 **/
export type KQuickSearchBarEventEmitsType = ViewEmitsTypeExtract<typeof KQuickSearchBarEventEmits>

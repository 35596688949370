import { IKTreeNode, KNotification, utils } from '@kmsoft/upf-core'
import { EnumRequestCode } from '@kmsoft/ebf-common'

/**
 * 判断是否是租户模式
 * @returns true表示是租户模式
 */
export function isTenantMode(): boolean {
  return false
}

/**
 * 请求结果状态转换
 * @param response 请求结果
 * @returns true表示成功
 */
export function isReqSuccess(response: any): boolean {
  return response && response.code == EnumRequestCode.SUCCESS
}

export function showError(response: any) {
  KNotification.error({
    title: '提示',
    content: response.message
  })
}

/**
 * 将父子结构一维数组转换成children[]形式
 * @param arr 源数据集合
 * @returns 目标数据集合(默认只有一个对象)
 */
export function toTree(arr: Array<IKTreeNode>): Array<IKTreeNode> {
  const tree = [] as Array<IKTreeNode>
  const codeMap = new Map()
  const map = new Map()

  arr.forEach((node: any) => {
    codeMap.set(node.code, { ...node })
    map.set(node.code + '_' + node.parentCode, { ...node, children: [] })
  })

  map.forEach((node, _, map) => {
    const parentNode = codeMap.get(node.parentCode)
    const parentCode = node.parentCode + '_' + parentNode?.parentCode
    const parent = map.get(parentCode)

    if (parent) {
      parent.children.push(node)
      parent.leaf = false
    } else {
      tree.push(node)
    }
  })

  return tree
}

/**
 * 判断两个变量值是否相等(null等特殊情况判定为相等)
 * @param a 变量
 * @param b 变量
 * @returns true表示相等
 */
export function equals(a: string | undefined, b: string | undefined) {
  if (utils.isEmpty(a) && utils.isEmpty(b)) {
    return true
  }
  return a === b
}

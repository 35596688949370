import { connect, withInstall } from '@kmsoft/upf-core'
import KActivityPlanDetailGridView from './KActivityPlanDetailGrid.vue'
import KActivityPlanDetailGridViewModel from './KActivityPlanDetailGridViewModel'

const KActivityPlanDetailGrid = connect(KActivityPlanDetailGridView, KActivityPlanDetailGridViewModel)

export default withInstall(KActivityPlanDetailGrid)
export { KActivityPlanDetailGrid, KActivityPlanDetailGridView, KActivityPlanDetailGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

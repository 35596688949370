import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KCreateFormPropOptions = {
  ...BaseViewPropOptions,
  /** 选中的文件夹id */
  folderId: VuePropTypes.createType<string>().def(),
  /** 是否通用指标 */
  isGeneral: VuePropTypes.createType<boolean>().def(false)
}

/** 参数类型 **/
export type KCreateFormPropType = ViewPropsTypeExtract<typeof KCreateFormPropOptions>

/** 事件 */
export const KCreateFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KCreateFormEmitsType = ViewEmitsTypeExtract<typeof KCreateFormEventEmits>

/** 工具栏支持的选中行数量 */
export enum EnumSupportObjectNumMode {
  /** 任意对象数量 [总是显示] */
  Any = 0,
  /** 单个对象 [选择1个对象时显示] */
  Single = 1,
  /** 多个对象，默认 [选择大于1个对象时显示] */
  Multiple = 2,
  /** 单个、或者多个对象[选择大于0个对象时显示] */
  Single_Multiple = 3
}

/** 工具栏类型 */
export enum EnumObjectToolStripItemType {
  /** 预定义 */
  Predefine = 1,
  /** 用户定义 */
  UserDefined = 2
}

/** 工具条使用环境 */
export enum EnumToolStripEnvironment {
  /** 普通对象网格 */
  DataGrid = 100,
  /** 对象类网格 */
  ObjClassGrid = 101,
  /** 关联对象网格 */
  ObjRelationGrid = 102,
  /** 描述对象网格 */
  ObjDescGrid = 103,
  /** 零部件结构管理 */
  PartStructureManager = 200,
  /** 对象面板 */
  ObjPanel = 201
}


import { defineView, KCkeditor, VNodeProps } from '@kmsoft/upf-core'
import { KStructuredDocManageEventEmits, KStructuredDocManagePropOptions } from './interface'
import KStructuredDocManageViewModel from './KStructuredDocManageViewModel'
import { KStructuredDocTreeEmitsType, KStructuredDocTreePropType } from './structured-doc-tree'

export default defineView({
  name: 'KStructuredDocManage',
  props: KStructuredDocManagePropOptions,
  emits: KStructuredDocManageEventEmits,
  viewModel: KStructuredDocManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      /**渲染文档结构树 */
      const renderTree = () => {
        const structuredDocPanelSlots = {
          header: () => {
            const popoverSlots = {
              default: () => {
                const title = vm.structureFilterName.value ? ',' + vm.structureFilterName.value : ''
                return (
                  <k-button type="text" size="small">
                    <k-icon type="filter" />
                    过滤：{vm.structureViewName.value}
                    {title}
                    <k-icon type="down" />
                  </k-button>
                )
              },
              content: () => {
                return (
                  <k-space direction="vertical" style="width:300px">
                    <k-form>
                      <k-form-item label="版本规则">
                        <k-select
                          placeholder="版本规则"
                          options={vm.structureViewOptions.value}
                          disabled={props.disabledView}
                          v-model={[vm.structureViewName.value, 'value']}
                        ></k-select>
                      </k-form-item>
                      {/* <k-form-item label="过滤条件">
                        <k-select
                          placeholder="过滤条件"
                          options={vm.structureFilterOptions.value}
                          allowClear={true}
                          disabled={props.disabledFilter}
                          v-model={[vm.structureFilterName.value, 'value']}
                        ></k-select>
                      </k-form-item> */}
                    </k-form>
                  </k-space>
                )
              }
            }

            return (
              <div style="display:flex; align-items: center">
                <k-popover trigger="click" placement="bottomLeft" v-slots={popoverSlots} />
                <div>
                  <k-button type="primary" onClick={() => vm.previewWord()}>
                    预览
                  </k-button>
                  <k-button type="text" onClick={() => vm.exportToWord()}>
                    导出
                  </k-button>
                  <k-button type="text" onClick={() => vm.importFromWord()}>
                    导入
                  </k-button>
                </div>
              </div>
            )
          },
          default: () => {
            const structuredDocViewMode = vm.structureViews.value.find(f => f.viewName == vm.structureViewName.value)

            if (!vm.isInitialized.value) {
              return <k-spin />
            }

            const treeProps: VNodeProps<KStructuredDocTreePropType, KStructuredDocTreeEmitsType> = {
              ref: vm.refStructuredDocTree,
              objParams: vm.rootObjParam.value,
              viewName: vm.structureViewName.value,
              filterName: vm.structureFilterName.value,
              isMultipleSelection: props.isMultipleSelection,
              checkStrictly: props.checkStrictly,
              disabledContextMenu: props.disabledContextMenu,
              structuredDocViewMode: structuredDocViewMode,
              onBeforeSelect: vm.onBeforeNodeSelect,
              onAfterSelect: vm.onAfterSelect,
              onReady: vm.onTreeReady,
              onAfterCheck: vm.onTreeAfterCheck,
              onNodeRefresh: vm.onNodeRefresh
            }

            return <k-structured-doc-tree {...treeProps} />
          }
        }

        return (
          <k-split-pane>
            <k-panel hideHeader={props.suppressView} v-slots={structuredDocPanelSlots} />
          </k-split-pane>
        )
      }
      /** 对象面板 */
      const renderObjectPanel = () => {
        const structDocEditor = {
          header: () => {
            return (
              <k-object-tool-strip
                ref="refToolStrip"
                items={vm.toolStripItems}
                onItemClicked={(event: any) => vm.onToolStripItemClicked(event)}
              />
            )
          },
          default: () => {
            return (
              <div class="full">
                <span class="content">章节内容:</span>
                <KCkeditor ref="refCkEditor" value={vm.editorText.value} class="doc-editor" disabled={true} />
              </div>
            )
          }
        }
        return (
          <k-split-pane collapse={!props.showObjectPanel} size={80} class="docToolStrip">
            <k-panel v-slots={structDocEditor} />
          </k-split-pane>
        )
      }
      return (
        <k-split-container class="k-structured-doc-manage">
          <k-split-pane size={20}>
            <k-split-container horizontal={true}>{renderTree()}</k-split-container>
          </k-split-pane>
          {renderObjectPanel()}
        </k-split-container>
      )
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KConfigGridComboboxViewModelPropOptions } from './interface'
import { EnumTabType, EnumEditType } from '../../../interface'
import KConfigGridComboboxViewModel from './KConfigGridComboboxViewModel'

export default defineView({
  name: 'ConfigGridCombobox',
  props: KConfigGridComboboxViewModelPropOptions,
  viewModel: KConfigGridComboboxViewModel,
  setup() {
    return {
      EnumEditType,
      EnumTabType
    }
  }
})

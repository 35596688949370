import { connect, withInstall } from '@kmsoft/upf-core'
import KCategoryRenderView from './KCategoryRender.vue'
import KCategoryRenderViewModel from './KCategoryRenderViewModel'

const KCategoryRender = connect(KCategoryRenderView, KCategoryRenderViewModel)

export default withInstall(KCategoryRender)
export { KCategoryRender, KCategoryRenderView, KCategoryRenderViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { Stream } from './Stream'

/**创建一个流，其后备存储为内存。*/
export class MemoryStream extends Stream {
  protected static type: string = 'System.IO.MemoryStream,System.Runtime'

  /**使用初始化为零的可扩展容量初始化 {@link MemoryStream} 类的新实例。
   * @returns 一个 {@link MemoryStream} 对象。*/
  public static async create(): Promise<MemoryStream> {
    return this.createInstanceAndWrap()
  }

  /**将流内容写入字节数组，而与 {@link Position} 属性无关。
   * @returns 新的字节数组。*/
  public async ToArray(): Promise<string> {
    return this.invokeMethod('ToArray')
  }
}

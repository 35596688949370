import { connect, withInstall } from '@kmsoft/upf-core'
import KScriptEditorView from './KScriptEditor.vue'
import KScriptEditorViewModel from './KScriptEditorViewModel'

const KScriptEditor = connect(KScriptEditorView, KScriptEditorViewModel)

export default withInstall(KScriptEditor)
export { KScriptEditor, KScriptEditorView, KScriptEditorViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KAgentMonitorAppletPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KAgentMonitorAppletPropType = ViewPropsTypeExtract<typeof KAgentMonitorAppletPropOptions>

/** 事件 */
export const KAgentMonitorAppletEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KAgentMonitorAppletEmitsType = ViewEmitsTypeExtract<typeof KAgentMonitorAppletEventEmits>

import { EnumNodeType, defineView, utils } from '@kmsoft/upf-core'
import { KDesignerMetadataEventEmits, KDesignerMetadataPropOptions } from './interface'
import KDesignerMetadataViewModel from './KDesignerMetadataViewModel'
import { KDataElementTree, PageDesignerDataSourceProperty } from '../../../../types'
import { createVNode } from 'vue'
import { EnumRenderLayoutType } from '@kmsoft/upf-view-engine'

/** 控件元数据 */
export default defineView({
  name: 'KDesignerMetadata',
  props: KDesignerMetadataPropOptions,
  emits: KDesignerMetadataEventEmits,
  viewModel: KDesignerMetadataViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    const onDragOut = (element: PageDesignerDataSourceProperty) => {
      return utils.deepClone(props.convertElement!(element))
    }

    return () => {
      return (
        <k-tree-view
          ref={vm.refDesignerConfig}
          nodeKey="id"
          autoWrapRoot={false}
          defaultExpandedLevel={0}
          dataSource={vm.localElementGroups.value}
          v-slots={{
            title: (node: KDataElementTree) => {
              const data = [node.node]
              return (
                <k-draggable
                  list={data}
                  itemKey="name"
                  group={{ name: `${EnumRenderLayoutType.GRID}-layout-group`, put: false, pull: 'clone' }}
                  filter=".disable-drag"
                  class="k-designer-metadata"
                  clone={(element: PageDesignerDataSourceProperty) => onDragOut(element)}
                  v-slots={{
                    item: ({ element }: { element: PageDesignerDataSourceProperty }) => {
                      return createVNode(
                        'div',
                        {
                          class: `${element.nodeType !== EnumNodeType.PROPERTY ? ' disable-drag' : ''}`
                        },
                        [
                          <span class="k-designer-toolbox-item-title" style={'cursor:pointer'}>
                            {element.title}
                          </span>
                        ]
                      )
                    }
                  }}
                />
              )
            }
          }}
        ></k-tree-view>
      )
    }
  }
})

import {
  VuePropTypes,
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract
} from '@kmsoft/upf-core'

/** ObjectSelectorTest 参数 **/
export const ObjectSelectorTestPropOptions = {
  ...BaseViewPropOptions
}

/** ObjectSelectorTest 参数类型 **/
export type ObjectSelectorTestPropType = ViewPropsTypeExtract<typeof ObjectSelectorTestPropOptions>

/** ObjectSelectorTest 事件 */
export const ObjectSelectorTestEventEmits = {
  ...BaseViewEventEmits
}

/** ObjectSelectorTest 事件类型 **/
export type ObjectSelectorTestEventEmitsType = ViewEmitsTypeExtract<typeof ObjectSelectorTestEventEmits>

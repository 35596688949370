import * as objectOperationToolStrip from './objectOperationToolStrip'
import * as gridOperationToolStrip from './gridOperationToolStrip'
// import * as layoutSchemaToolStrip from './layoutSchemaToolStrip'
import * as objectLifeToolStrip from './objectLifeToolStrip'
import * as objectRelationToolStrip from './objectRelationToolStrip'
import * as objectFolderToolStrip from './objectFolderToolStrip'

import * as doc from './doc'
import * as distribution from './doc/distribution'
import * as part from './part'
import * as partStructure from './part/part-structure'
import * as baseline from './baseline'
import * as issueReport from './change/issue-report'
import * as changeActivity from './change/change-activity'
import * as changePromoteActivity from './change/change-promote-activity'
import * as changeRequest from './change/change-request'
import * as changeOrder from './change/change-order'
import * as workflowTasks from './workflow'
import * as flowDetail from './workflow/flowDetail'
import * as folder from './folder'
import * as search from './search'
import * as ApprovalFlow from './workflow/process'
import * as objectClassLayoutDefinition from './object-class-layout-definition'
import * as customerNeeds from './requirement/customer-needs'
import * as productNeeds from './requirement/product-needs'
import { getChangeOrderEditToolStripItems } from './change/change-order'

export * from './base'
export * from './types/enums'

export namespace ToolStripHelper {
  //基础
  export const getObjectOperationToolStrip = objectOperationToolStrip.getObjectOperationToolStrip
  export const getDocGeneralObjectOperationToolStripItems = doc.getDocGeneralObjectOperationToolStripItems
  export const getStructuredDocGeneralObjectOperationToolStripItems = doc.getStructuredDocGeneralObjectOperationToolStripItems
  export const getPartGeneralObjectOperationToolStripItems = part.getPartGeneralObjectOperationToolStripItems
  export const getPartStructureOperationToolStripItems = partStructure.getPartStructureOperationToolStripItems
  export const getGridOperationToolStrip = gridOperationToolStrip.getGridOperationToolStrip
  // export const getLayoutSchemaToolStrip = layoutSchemaToolStrip.getLayoutSchemaToolStrip
  export const getObjectLifeToolStrip = objectLifeToolStrip.getObjectLifeToolStrip
  export const getRelationToolStrip = objectRelationToolStrip.getRelationToolStrip
  export const getFolderToolStrip = objectFolderToolStrip.getFolderToolStrip
  // export const getVersionToolStrip = version.getVersionToolStrip
  export const getIssueReportToolEditStripItems = issueReport.getIssueReportEditToolStripItems
  export const getBaselineEditToolStripItems = baseline.getBaselineEditToolStripItems
  export const getWorkFlowTaskToolStripItems = workflowTasks.getWorkFlowTaskToolStrip
  export const getFlowDetailToolStripItems = flowDetail.getFlowDetailToolStripItems
  export const getChangeActivityEditToolStripItems = changeActivity.getChangeActivityEditToolStripItems
  export const getChangePromoteActivityEditToolStripItems = changePromoteActivity.getChangePromoteActivityEditToolStripItems
  export const getChangeRequestToolEditStripItems = changeRequest.getChangeRequestEditToolStripItems
  export const getChangeOrderToolEditStripItems = changeOrder.getChangeOrderEditToolStripItems
  export const getFolderGeneralObjectOperationToolStripItems = folder.getFolderGeneralObjectOperationToolStripItems
  export const getApprovalFlowToolStripItems = ApprovalFlow.getApprovalFlowOperationToolStripItems
  export const getDistributionEditToolStripItems = distribution.getDistributionEditToolStripItems
  export const getSearchGridObjectOperationToolStripItems = search.getSearchGridObjectOperationToolStripItems
  // 对象类布局定义工具栏
  export const getObjClassLayoutDefinitionToolStripItems = objectClassLayoutDefinition.getObjClassLayoutDefinitionToolStripItems
  // 需求管理
  export const getCustomerNeedsOperationToolStripItems = customerNeeds.getCustomerNeedsOperationToolStripItems
  export const getProductNeedsOperationToolStripItems = productNeeds.getProductNeedsOperationToolStripItems
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KWrapperControlEventEmitsType, KWrapperControlPropType } from './interface'

/** 视图模型 */
export class KWrapperControlViewModel extends BaseViewModel<KWrapperControlEventEmitsType, KWrapperControlPropType> {
  constructor(options: ViewModelOptions<KWrapperControlPropType>) {
    super(options)
  }
  /**
   * 加载完成函数
   */
  viewDidMount() {}
}

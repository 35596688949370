// 移动位置
import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../object-tool-strip'
import { EnumToolStripItemKeys } from '../types'

export const toolStripItemRowSort: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ROW_SORT,
  icon: 'edit',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  title: '调整顺序'
}

export const moveUpToolStrip: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE_UP,
  icon: 'arrow-up',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  title: '上移'
}

export const moveDownToolStrip: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE_DOWN,
  icon: 'arrow-down',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  title: '下移'
}

export const moveToTopToolStrip: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE_TO_TOP,
  icon: 'vertical-align-top',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  title: '移至最上'
}

export const moveToBottomToolStrip: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE_TO_BOTTOM,
  icon: 'vertical-align-bottom',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  title: '移至最下'
}

export const reindexToolStrip: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REINDEX,
  icon: 'reload',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  title: '重新生成位置号'
}

export const sortByIndexToolStrip: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT_BY_INDEX,
  icon: 'ordered-list',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  title: '按位置号排列'
}

export const toolStripMoveDown: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE_DOWN,
  icon: 'arrow-down',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  title: '下移',
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
}

export const toolStripMoveToTop: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE_TO_TOP,
  icon: 'vertical-align-top',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  title: '最上',
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
}

export const toolStripMoveToBottom: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE_TO_BOTTOM,
  icon: 'vertical-align-bottom',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  title: '最下',
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
}

export const toolStripMoveUp: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE_UP,
  icon: 'arrow-up',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  title: '上移',
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
}

import { AgentStaticObject } from '../../AgentStaticObject'

import { FileSystemInfo } from './FileSystemInfo'
import { FileStream } from './FileStream'

/**提供用于创建、复制、删除、移动和打开文件的属性和实例方法，并且帮助创建 {@link FileStream} 对象。 此类不能被继承。*/
export class FileInfo extends FileSystemInfo {
  protected static type: string = 'System.IO.FileInfo,System.Runtime'

  /**初始化作为文件路径的包装的 {@link FileInfo} 类的新实例。
   * @param fileName 新文件的完全限定名或相对文件名。 路径不要以目录分隔符结尾。
   * @returns 一个 {@link FileInfo} 对象。*/
  public static async create(fileName: string): Promise<FileInfo> {
    return this.createInstanceAndWrap(fileName)
  }

  /**获取当前文件的大小（以字节为单位）。*/
  public async Length(): Promise<string> {
    return (await this.property('Length', AgentStaticObject.None))?.toString()
  }
}

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/**
 * 实时搜索选择器
 */
export interface IKAutoCompleteSelector {
  /** 设置选中项 */
  setValue(option: KAutoCompleteSelectorOption | Array<KAutoCompleteSelectorOption>): void
  /** 获取选中项 */
  getValue(): KAutoCompleteSelectorOption | Array<KAutoCompleteSelectorOption>
  /** 设置下拉框的options*/
  setOptions(options: Array<any>): void
  /** 获取焦点  */
  focus(): void
  /** 刷新编辑框渲染数据*/
  refresh(): void
}

/**
 * 实时搜索选择器候选项类型
 */
export interface KAutoCompleteSelectorOption extends Record<string, any> {
  /** 显示值 */
  label: string
  /** 实际值 */
  value: string
}

/**
 * 建议项开关
 */
export type OptionSuggestion =
  | false
  | {
      /** 分类名 */
      category: string
      /** 场景 */
      situation?: string
    }

/** 实时搜索选择器数据加载函数 */
export type KAutoCompleteSelectorLoadDataFn = (
  param: KAutoCompleteSelectorLoadDataFnParam
) => Promise<KAutoCompleteSelectorLoadDataFnResponse>

/** 实时搜索选择器数据加载函数入参 */
export interface KAutoCompleteSelectorLoadDataFnParam {
  searchValue: string
  page?: {
    pageIndex: number
    pageSize: number
  }
}

export interface KAutoCompleteSelectorLoadDataFnResponse {
  /** 总数*/
  total: number
  /** 候选项集合 */
  options: Array<KAutoCompleteSelectorOption>
}

/**
 * 下拉菜单分页配置
 */
export type KSelectPagination = {
  pageIndex: number
  pageSize: number
}

/**
 * 加载函数的触发方式
 */
export enum EnumLoadDataTriggerMode {
  /** 第一次获取焦点 */
  FIRST_FOCUS,
  /** 滚动条到底部 */
  SCROLL_BOTTOM,
  /** 输入检索值 */
  SEARCH_CHANGE
}

/**
 * 分组模式的候选项类型
 */
export interface KAutoCompleteSelectorOptionGroup {
  label: string
  options: Array<KAutoCompleteSelectorOption>
}

/** 参数 **/
export const KAutoCompleteSelectorPropOptions = {
  ...BaseViewPropOptions,
  /** 候选项加载函数 */
  loadData: VuePropTypes.func<KAutoCompleteSelectorLoadDataFn>().def(),
  /** 选中项（v-model） */
  value: VuePropTypes.createType<string | Array<string>>().def(),
  /** 是否多选 */
  isMultipleSelection: VuePropTypes.bool().def(false),
  /** 分页配置，false表示不分页 */
  pagination: VuePropTypes.createType<false | KSelectPagination>().def(false),
  /** 是否禁用状态 */
  disabled: VuePropTypes.bool().def(false),
  /** 是否显示下拉箭头  */
  isShowArrow: VuePropTypes.bool().def(false),
  /** 是否开启建议项 */
  suggestion: VuePropTypes.createType<OptionSuggestion>().def(false),
  /** 多选模式时,最大显示的 tag 文本长度 */
  maxTagTextLength: VuePropTypes.number().def(3),
  /** 是否在获取焦点时显示下拉菜单 */
  isShowOptionOnFocus: VuePropTypes.bool().def(true),
  /** 是否一次性加载 (该值为true，则只会调用一次loadData，检索时不再调用)*/
  isOneTimeLoad: VuePropTypes.bool().def(false),
  /** 是否允许清除 */
  isAllowClear: VuePropTypes.bool().def(true),
  /** 占位符 */
  placeholder: VuePropTypes.string().def(''),
  /** 是否自动获取焦点 */
  autofocus: VuePropTypes.bool().def(false),
  /** 是否禁用状态 */
  readonly: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KAutoCompleteSelectorPropType = ViewPropsTypeExtract<typeof KAutoCompleteSelectorPropOptions>

/** 事件 */
export const KAutoCompleteSelectorEventEmits = {
  ...BaseViewEventEmits,
  /** 焦点事件 */
  focus: () => true,
  /** 搜索事件 */
  search: (value: string) => true,
  /** 选中项改变事件（双向绑定） */
  'update:value': (newVal: string | Array<string>) => true,
  /** 改变事件（选中和取消选中时触发）  */
  change: (selectOptions: null | KAutoCompleteSelectorOption | Array<KAutoCompleteSelectorOption>) => true,
  /** 选中事件 */
  select: (value: string, option: KAutoCompleteSelectorOption) => true,
  /** 焦点离开事件 */
  blur: () => true
}

/** 事件类型 **/
export type KAutoCompleteSelectorEmitsType = ViewEmitsTypeExtract<typeof KAutoCompleteSelectorEventEmits>

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KIssueReportCreatePropOptions = {
  ...BaseViewPropOptions,
  /** 是否显示添加按钮 **/
  showAddButton: VuePropTypes.bool().def(false),
  /** 是否显示扩展操作(继续创建下一个、打开对象) */
  showExtButton: VuePropTypes.bool().def(false),
  formValue: VuePropTypes.createType<any>().def()
}

/** 参数类型 **/
export type KIssueReportCreatePropType = ViewPropsTypeExtract<typeof KIssueReportCreatePropOptions>

/** 事件 */
export const KIssueReportCreateEventEmits = {
  ...BaseViewEventEmits,
  cancel: () => true,
  confirm: (params: Record<string, any>) => true
}

/** 事件类型 **/
export type KIssueReportCreateEmitsType = ViewEmitsTypeExtract<typeof KIssueReportCreateEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KPersonalChangeItemsView from './KPersonalChangeItems.vue'
import KPersonalChangeItemsViewModel from './KPersonalChangeItemsViewModel'

const KPersonalChangeItems = connect(KPersonalChangeItemsView, KPersonalChangeItemsViewModel)

export default withInstall(KPersonalChangeItems)
export { KPersonalChangeItems, KPersonalChangeItemsView, KPersonalChangeItemsViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartImportView from './KPartImport.vue'
import KPartImportViewModel from './KPartImportViewModel'

const KPartImport = connect(KPartImportView, KPartImportViewModel)

export default withInstall(KPartImport)
export { KPartImport, KPartImportView, KPartImportViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { LAYOUT_ROW_HEIGHT } from '../../config'
import { KContainer, KControl, KGridLayoutConfig, RenderLayoutElement } from '@kmsoft/upf-core'

export const getLayoutStyle = (layoutConfig: KGridLayoutConfig, gridRows: number) => {
  const style: Record<string, any> = {
    'column-gap': `${layoutConfig.colGap}px`,
    'grid-auto-rows': `${LAYOUT_ROW_HEIGHT}px`,
    'row-gap': `${layoutConfig.rowGap}px`,
    'grid-template-rows': `repeat(${gridRows},${LAYOUT_ROW_HEIGHT}px)`,
    ...gridTemplateColumns(layoutConfig.colCount, layoutConfig.minColWidth)
  }

  return style
}

const gridTemplateColumns = (colCount: number, minColWidth: number) => {
  return {
    'grid-template-columns': `repeat(${colCount > 0 ? colCount : 'auto-fill'},minmax(${colCount > 0 ? 0 : minColWidth}px,1fr))`
  }
}

/**
 *
 * @param elements
 * @param totalRows
 */
export const getFillConfig = (elements: Array<RenderLayoutElement>, totalRows: number) => {
  /** 如果全部为占满整行的情况下，启用占满剩余空间属性 */
  const enableFill = elements.every(a => 'layout' in a && a.layout && (a.layout.fill || a.layout.entireRow))

  const elementStyle: Record<string, any> = {}

  if (enableFill) {
    const curElements = elements as Array<KContainer | KControl>
    /** 全部需要占满空间的控件 */
    const fillElements = curElements.filter(a => a.layout.fill)
    /** 需要占满空间控件的数量 */
    const fillCounts = fillElements.length
    /** 计算行 */
    let usedRows = 0

    // 累加行数
    for (const iterator of curElements) {
      if (iterator.layout.fill) {
        usedRows += 0
      } else {
        usedRows += iterator.layout.rowSpan
      }
    }

    /** 剩余行 */
    const remainingRows = totalRows - usedRows
    /** 每一行跨行数量 */
    const perRowSpan = Math.floor(remainingRows / fillCounts)
    /** 计算最后一行剩余的跨行数量 */
    const lastRowSpan = remainingRows - perRowSpan * fillCounts

    for (let index = 0; index < fillElements.length; index++) {
      const element = fillElements[index]

      if (index != fillElements.length - 1) {
        elementStyle[element.name] = { 'grid-row-start': `span ${perRowSpan}` }
      } else {
        elementStyle[element.name] = { 'grid-row-start': `span ${perRowSpan + lastRowSpan}` }
      }
    }
  }

  return elementStyle
}

/** 获取布局行行数 */
export const getLayoutRowsConfig = (containerHeight: number, rowGap: number) => {
  /** 容器高度 */
  const finalContainerHeight = containerHeight + rowGap
  /** 行高 */
  const finalRowHeight = LAYOUT_ROW_HEIGHT + rowGap
  /** 计算总行数 */
  const totalRows = Math.floor(finalContainerHeight / finalRowHeight)

  return {
    totalRows: totalRows,
    totalRowHeight: finalRowHeight * totalRows
  }
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_result = _resolveComponent("k-result")!

  return (_openBlock(), _createBlock(_component_k_result, { class: "sso-login" }, {
    icon: _withCtx(() => [
      (_ctx.loginState == _ctx.EnumLoginState.LoggingIn)
        ? (_openBlock(), _createBlock(_component_k_icon, {
            key: 0,
            class: "loading-icon",
            type: "loading"
          }))
        : (_ctx.loginState == _ctx.EnumLoginState.Success)
          ? (_openBlock(), _createBlock(_component_k_icon, {
              key: 1,
              type: "success-circle-green"
            }))
          : (_ctx.loginState == _ctx.EnumLoginState.Error)
            ? (_openBlock(), _createBlock(_component_k_icon, {
                key: 2,
                type: "info-circle-orange"
              }))
            : _createCommentVNode("", true)
    ]),
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.loginMsg), 1)
    ]),
    _: 1
  }))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p10" }
const _hoisted_2 = { class: "p10 h550 overflowYAuto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_steps = _resolveComponent("k-steps")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_steps, {
          current: _ctx.$vm.currentStep,
          items: _ctx.$vm.steps
        }, null, 8, ["current", "items"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$vm.componentName), {
            agentOpened: _ctx.$vm.agentOpened,
            fileList: _ctx.$vm.fileList,
            folderId: _ctx.folderId,
            documentType: _ctx.$vm.documentType,
            "onUpdate:documentType": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.documentType) = $event)),
            filePropertyOption: _ctx.$vm.filePropertyOption,
            "onUpdate:filePropertyOption": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.filePropertyOption) = $event)),
            onAddFile: _ctx.$vm.addFile,
            onRemoveFile: _ctx.$vm.removeFile,
            onNextStep: _ctx.$vm.nextStep,
            onPreviousStep: _ctx.$vm.previousStep,
            onCloseDialog: _ctx.$vm.closeDialog
          }, null, 40, ["agentOpened", "fileList", "folderId", "documentType", "filePropertyOption", "onAddFile", "onRemoveFile", "onNextStep", "onPreviousStep", "onCloseDialog"]))
        ], 1024))
      ])
    ]),
    _: 1
  }))
}
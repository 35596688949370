
import { ToolStripItemClickedEventArgs, defineView } from '@kmsoft/upf-core'
import { KConfigObjectGridPropOptions, KConfigObjectGridEventEmits, EnumToolStripItem, EnumTabType } from './interface'
import KConfigObjectGridViewModel from './KConfigObjectGridViewModel'
import { toolStripItems, EnumToolStripItemKeys } from './interface'

export default defineView({
  name: 'KConfigObjectGrid',
  props: KConfigObjectGridPropOptions,
  emits: KConfigObjectGridEventEmits,
  viewModel: KConfigObjectGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /**工具栏项事件 */
    const toolStripItemClick = (event: ToolStripItemClickedEventArgs) => {
      switch (event.name) {
        case EnumToolStripItemKeys.ADD:
          {
            vm.addObjClass()
          }
          break
        case EnumToolStripItemKeys.DELETE:
          {
            vm.deleteObjClass()
          }
          break
        case EnumToolStripItemKeys.EDIT:
          {
            vm.openEditPanel()
          }
          break
        case EnumToolStripItemKeys.REFRESH:
          {
            vm.refresh()
          }
          break
        default:
          break
      }
    }
    return {
      EnumToolStripItem,
      EnumTabType,
      toolStripItems,
      toolStripItemClick
    }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KLifecycleStateEditorView from './KLifecycleStateEditor.vue'
import KLifecycleStateEditorViewModel from './KLifecycleStateEditorViewModel'

const KLifecycleStateEditor = connect(KLifecycleStateEditorView, KLifecycleStateEditorViewModel)

export default withInstall(KLifecycleStateEditor)
export { KLifecycleStateEditor, KLifecycleStateEditorView, KLifecycleStateEditorViewModel }
export * from './interface'
export * from './KObjectLifecycleStateColumn'

// 模板生成文件
// export * from './{{folderName}}'

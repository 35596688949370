import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectClassLayoutDefinitionView from './KObjectClassLayoutDefinition.vue'
import KObjectClassLayoutDefinitionViewModel from './KObjectClassLayoutDefinitionViewModel'

const KObjectClassLayoutDefinition = connect(KObjectClassLayoutDefinitionView, KObjectClassLayoutDefinitionViewModel)

export default withInstall(KObjectClassLayoutDefinition)
export { KObjectClassLayoutDefinition, KObjectClassLayoutDefinitionView, KObjectClassLayoutDefinitionViewModel }

// 模板生成文件
// export * from './{{folderName}}'

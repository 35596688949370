import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import {
  KBaseEditorEventEmits,
  KBaseEditorPropOptions,
  KBasePropertyEditorEventEmits,
  KBasePropertyEditorPropOptions
} from '../../base'

//#region
export type FilePropertyInfo = {
  /** 文件Id */
  fileId: string
  /** 文件名 */
  fileName: string
  /** 大小 */
  size: number
  /** MD5 */
  md5: string
  /** 位置 */
  location: string
  /** 显示位置 */
  displayLocation: string
}
//#endregion

/** 参数 **/
export const KFilePropertyEditorPropOptions = {
  ...KBasePropertyEditorPropOptions,
  /** 是否允许清除 */
  allowClear: VuePropTypes.bool().def(),
  /** 是否显示上传按钮 */
  showUploadButton: VuePropTypes.bool().def(true),
  /** 是否显示下载按钮 */
  showDownloadButton: VuePropTypes.bool().def(true)
}

/** 参数类型 **/
export type KFilePropertyEditorPropType = ViewPropsTypeExtract<typeof KFilePropertyEditorPropOptions>

/** 事件 */
export const KFilePropertyEditorEventEmits = {
  ...KBasePropertyEditorEventEmits
}

/** 事件类型 **/
export type KFilePropertyEditorEmitsType = ViewEmitsTypeExtract<typeof KFilePropertyEditorEventEmits>

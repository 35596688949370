import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ObjectPanelTestEmitsType, ObjectPanelTestPropType } from './interface'

/** ObjectPanelTest */
export default class ObjectPanelTestViewModel extends BaseViewModel<ObjectPanelTestEmitsType, ObjectPanelTestPropType> {
  constructor(options: ViewModelOptions<ObjectPanelTestPropType>) {
    super(options)
  }

  viewDidMount() {}
}


import { defineView } from '@kmsoft/upf-core'
import { KSingleLineEditorFormViewModel } from './KSingleLineEditorFormViewModel'
import { KSingleLineEditorCustomPropOptions } from './interface'
/**
 *
 */
export default defineView({
  name: 'SingleLineEditorForm', // 组件名称
  components: {}, // 这里定义局部组件
  viewModel: KSingleLineEditorFormViewModel,
  props: KSingleLineEditorCustomPropOptions,
  setup(props, ctx) {
    return {}
  }
})

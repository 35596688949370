import { EnumAttributeType, EnumControlElementType, EnumDataType, utils } from '@kmsoft/upf-core'
import { DEFAULT_CURRENCY_LAYOUT_PROPS, DEFAULT_CURRENCY_PROPS, DEFAULT_LABEL_POSITION_CANDIDATE } from '../../type'

const dateFormatOptions = [
  { displayValue: 'HH时mm分ss秒', actualValue: 'HH时mm分ss秒' },
  { displayValue: 'HH:mm:ss', actualValue: 'HH:mm:ss' },
  { displayValue: 'YYYY年MM月DD日', actualValue: 'YYYY年MM月DD日' },
  { displayValue: 'YYYY/MM/DD', actualValue: 'YYYY/MM/DD' },
  { displayValue: 'YYYY-MM-DD', actualValue: 'YYYY-MM-DD' },
  { displayValue: 'YY年MM月DD日', actualValue: 'YY年MM月DD日' },
  { displayValue: 'YY/MM/DD', actualValue: 'YY/MM/DD' },
  { displayValue: 'YY-MM-DD', actualValue: 'YY-MM-DD' },
  { displayValue: 'YYYY年MM月DD日HH时mm分ss秒', actualValue: 'YYYY年MM月DD日HH时mm分ss秒' },
  { displayValue: 'YYYY/MM/DD HH:mm:ss', actualValue: 'YYYY/MM/DD HH:mm:ss' },
  { displayValue: 'YYYY-MM-DD HH:mm:ss', actualValue: 'YYYY-MM-DD HH:mm:ss' },
  { displayValue: 'YY/MM/DD HH:mm:ss', actualValue: 'YY/MM/DD HH:mm:ss' },
  { displayValue: 'YY-MM-DD HH:mm:ss', actualValue: 'YY-MM-DD HH:mm:ss' }
]
/* 组件的基本属性 */
const comConfig = [
  {
    name: 'format',
    title: '显示格式',
    control: 'KSelect',
    options: dateFormatOptions,
    defaultValue: 'YY-MM-DD',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  }
]

/**控件属性配置项集合 */
const PROPS = [] as Array<any>

const EVENT = [
  { name: 'beforeValueChange', title: '值改变前', describe: '值改变前 的说明信息...' },
  { name: 'valueChanged', title: '值改变后', describe: '值改变后 的说明信息...' }
]

export default { PROPS, EVENT }

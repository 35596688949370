import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", {
    ref: "refFrame",
    src: _ctx.iframeSrc,
    style: {"width":"100%","height":"100%","border":"none","display":"block"}
  }, null, 8, _hoisted_1))
}
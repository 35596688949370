import { connect, withInstall } from '@kmsoft/upf-core'
import KPartEditPanelView from './KPartEditPanel.vue'
import KPartEditPanelViewModel from './KPartEditPanelViewModel'

const KPartEditPanel = connect(KPartEditPanelView, KPartEditPanelViewModel)

export default withInstall(KPartEditPanel)
export { KPartEditPanel, KPartEditPanelView, KPartEditPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { createVNode } from 'vue'
import {
  EnumControlElementType,
  EnumLayoutFlexDirection,
  KFlexLayoutConfig,
  KGridColumn,
  KGridColumnCompute,
  KGridColumnGroup,
  VNodeProps,
  defineView
} from '@kmsoft/upf-core'
import { DragElement, KGridDesignerElement } from '../../types'
import KGridDesignerPreviewViewModel from './KGridDesignerPreviewViewModel'
import {
  KGridDesignerPreviewEventEmits,
  KGridDesignerPreviewEventEmitsType,
  KGridDesignerPreviewPropOptions,
  KGridDesignerPreviewPropType
} from './interface'
import {
  EnumRenderLayoutType,
  KRenderLayout,
  KRenderLayoutEmitsType,
  KRenderLayoutPropType,
  RenderLayoutBaseEvent,
  RenderLayoutItemSlotProps
} from '@kmsoft/upf-view-engine'

export default defineView({
  name: 'KGridDesignerPreview',
  props: KGridDesignerPreviewPropOptions,
  emits: KGridDesignerPreviewEventEmits,
  viewModel: KGridDesignerPreviewViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    /**
     * 根据类型渲染指定元素
     * @param element 元素
     */
    const __renderElement = (element: KGridDesignerElement, index: number) => {
      //布局元素为分组时渲染分组容器
      if (element.control === EnumControlElementType.GROUP_COLUMN) {
        return _renderGroupContainer(element)
      }

      //如果布局元素为计算列时
      if (element.control === EnumControlElementType.EXPRESSION) {
        return _renderComputeColumn(element)
      }

      return (
        <div class="column-info">
          <span class="column-name">{element.title}</span>
        </div>
      )
    }

    /**渲染分组容器*/
    const _renderGroupContainer = (container: KGridDesignerElement) => {
      return (
        <div class="column-group-info">
          <div class="column-group-title">{container.title}</div>
          {__renderOwnComponent(container as KGridColumnGroup)}
        </div>
      )
    }

    /**渲染计算列 */
    const _renderComputeColumn = (element: KGridDesignerElement) => {
      let computeElements = element as KGridColumnCompute
      return (
        <div class="column-info compute">
          <span class="column-name">
            {computeElements.title}
            <span class="column-type">
              <k-icon type="calculator" />
            </span>
          </span>
        </div>
      )
    }

    /**
     * 渲染自身组件
     * @param container 元素
     */
    const __renderOwnComponent = (container: KGridColumnGroup) => {
      const previewProps: VNodeProps<KGridDesignerPreviewPropType, KGridDesignerPreviewEventEmitsType> = {
        selectedElement: props.selectedElement,
        isGroupElement: true,
        elements: container.controls,
        onElementSelected: (element: KGridDesignerElement) => vm.elementSelected(element),
        onElementDelete: (element: KGridDesignerElement) => vm.emit('elementDelete', element),
        onElementDragIn: (elementName: string) => vm.emit('elementDragIn', elementName),
        onElementChange: () => {
          vm.emit('elementChange', props.elements)
        }
      }

      return <k-grid-designer-preview {...previewProps} />
    }

    return () => {
      if (!props.elements) {
        return
      }

      const draggableSlots = {
        item: ({ element, index }: RenderLayoutItemSlotProps<KGridColumn | KGridColumnGroup>) => {
          return __renderElement(element, index)
        }
      }

      /** 容器配置 */
      const containerConfig: KFlexLayoutConfig = {
        direction: EnumLayoutFlexDirection.COLUMN
      }
      /** 渲染器参数 */
      const renderLayoutProps: VNodeProps<KRenderLayoutPropType, KRenderLayoutEmitsType> = {
        class: ['grid-column-designer', { 'main-layout': !props.isGroupElement }, { 'group-element': props.isGroupElement }],
        designMode: true,
        elements: props.elements,
        layoutType: EnumRenderLayoutType.FLEX,
        containerConfig: containerConfig,
        selectedElement: props.selectedElement,
        onAdd: ({ item }: RenderLayoutBaseEvent<DragElement>) => vm.elementDragIn(item),
        onEnd: ({ item }: RenderLayoutBaseEvent<DragElement>) => vm.onElementDragEnd(item),
        'onUpdate:selectedElement': element => vm.elementSelected(element as KGridDesignerElement)
      }

      return createVNode(KRenderLayout, renderLayoutProps, draggableSlots)
    }
  }
})

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

// form
export interface IObjSelectPanelDefinition {
  openType: number | string
}

// 对象界面定义定义状态
export interface IObjSelectPanelPropsViewModelState {}

// 对象界面定义定义事件
export const KObjSelectPanelPropsEventEmits = {
  ...BaseViewEventEmits
}

// 对象界面定义定义属性选项
export const KObjSelectPanelPropOptions = {
  ...BaseViewPropOptions,
  /** 属性参数，如对象类属性的关联属性信息等 */
  propertyArgs: VuePropTypes.string().def()
}

// emit type
export type KObjSelectPanelPropsEventEmitsType = ViewEmitsTypeExtract<typeof KObjSelectPanelPropsEventEmits>

// 对象界面定义定义属性类型
export type KObjSelectPanelPropType = ViewPropsTypeExtract<typeof KObjSelectPanelPropOptions>

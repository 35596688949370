import { BaseViewModel, ViewModelOptions, KInputViewModel } from '@kmsoft/upf-core'
import { KCreatorEditorEmitsType, KCreatorEditorPropType } from './interface'
import { ref, watch } from 'vue'

/** KCreatorEditor */
export default class KCreatorEditorViewModel extends BaseViewModel<KCreatorEditorEmitsType, KCreatorEditorPropType> {
  /**当前值 */
  stateValue = ref<string>('')
  /**原始值 */
  originalValue = ref<string>()
  refInput = ref<KInputViewModel>()
  stateReadonly = ref<boolean>(true)
  constructor(options: ViewModelOptions<KCreatorEditorPropType>) {
    super(options)
    watch(
      () => options.props.readonly,
      newValue => {
        this.stateReadonly.value = newValue
      }
    )
  }

  viewDidMount() {}
  getValue() {
    return this.stateValue.value
  }
  setValue(value: any, setChanged?: boolean) {
    let newValue = ''
    if (Array.isArray(value)) {
      const data = value.map(item => item?.split(' ')[0])
      newValue = data?.join(',')
    } else {
      newValue = value
    }
    this.stateValue.value = newValue
    if (setChanged) {
      this.originalValue.value = newValue
    }
  }
  getModifiedValue() {
    return this.stateValue.value
  }
  isModified() {
    return this.stateValue.value != this.originalValue.value
  }
  displayValue() {
    let value = this.props.value
    if (Array.isArray(value)) {
      value = value.map(item => item?.split(' ')[0])
    }
    return this.stateValue.value ? this.stateValue.value.split(' ')[0] : value ? value.split(' ')[0] : '--'
  }
}

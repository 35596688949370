import { MetaProperty, QueryCondition } from '../../client-srv'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  ShowDialogFunctionProps,
  VNodeProps,
  KDialogClosingEvent
} from '@kmsoft/upf-core'
import KRuleDefinerViewModel from './KRuleDefinerViewModel'

//#region 类型定义
export type RuleDefinition = {
  /** 条件 */
  condition: QueryCondition
  /**
   * 对象类 Code
   * @description 当传入属性时，对象类Code为空
   */
  modelCode: string
  /** 包含子类 */
  includeChild: boolean
  /** 列定义 */
  fields: Array<MetaProperty>
}

/** 规则定义窗口参数 */
export type KRuleDefinerDialogParams = Pick<ShowDialogFunctionProps, 'title'> & {
  props: VNodeProps<KRuleDefinerPropType, KRuleDefinerEmitsType>
  onClosing?: (args: KDialogClosingEvent<KRuleDefinerViewModel>) => void
}
//#endregion

/** 参数 **/
export const KRuleDefinerPropOptions = {
  ...BaseViewPropOptions,
  /** 对象类Id */
  modelCode: VuePropTypes.string().def(''),
  /** 对象类的字段列表 */
  fields: VuePropTypes.createType<MetaProperty[]>().def(),
  /** 条件 */
  condition: VuePropTypes.createType<QueryCondition>().def(),
  /** 对象类树是否可见 */
  showObjClsTree: VuePropTypes.bool().def(false),
  /** 对象类树是否可用 */
  disabledObjClsTree: VuePropTypes.bool().def(true),
  /** 是否显示包含子类 */
  showIncludeChildren: VuePropTypes.bool().def(false),
  /** 是否包含子类 */
  includeChild: VuePropTypes.bool().def(false),
  /** 设置要显示的对象id集合 */
  showObjClsCodes: VuePropTypes.array<string>().def([]),
  /** 是否合并fields */
  isMerge: VuePropTypes.bool().def(false),
  /** 合并的数组 */
  clsAttrs: VuePropTypes.array().def([])
}

/** 参数类型 **/
export type KRuleDefinerPropType = ViewPropsTypeExtract<typeof KRuleDefinerPropOptions>

/** 事件 */
export const KRuleDefinerEventEmits = {
  ...BaseViewEventEmits,
  /** v-model:condition */
  'update:condition': (value: QueryCondition) => true,
  /** v-model:includeChild */
  'update:includeChild': (value: boolean) => true,
  /** v-model:includeChild */
  'update:modelCode': (value: string) => true
}

/** 事件类型 **/
export type KRuleDefinerEmitsType = ViewEmitsTypeExtract<typeof KRuleDefinerEventEmits>

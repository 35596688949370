
import { defineView } from '@kmsoft/upf-core'
import { KPlanCreateFormPropOptions, KPlanCreateFormEventEmits } from './interface'
import KPlanCreateFormViewModel from './KPlanCreateFormViewModel'

export default defineView({
  name: 'KPlanCreateForm',
  props: KPlanCreateFormPropOptions,
  emits: KPlanCreateFormEventEmits,
  viewModel: KPlanCreateFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

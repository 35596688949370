import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KProjectPageWrapperPropOptions = {
  ...BaseViewPropOptions,
  /** 页面地址 */
  name: VuePropTypes.string()
    .setRequired()
    .def(),
  /** 是否绝对路径 */
  isAbsUrl: VuePropTypes.bool().def(false),
  /** 页面Id */
  windowId: VuePropTypes.string()
    .setRequired()
    .def(),
  objectInfo: {} as any
}

/** 参数类型 **/
export type KProjectPageWrapperPropType = ViewPropsTypeExtract<typeof KProjectPageWrapperPropOptions>

/** 事件 */
export const KProjectPageWrapperEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KProjectPageWrapperEmitsType = ViewEmitsTypeExtract<typeof KProjectPageWrapperEventEmits>

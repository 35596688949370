
import { ComponentHelper, defineView, EnumDialogSize, KDialog } from '@kmsoft/upf-core'
import { KAgentMonitorAppletPropOptions, KAgentMonitorAppletEventEmits } from './interface'
import KAgentMonitorAppletViewModel from './KAgentMonitorAppletViewModel'
import { computed, createVNode, ref, TransitionProps } from 'vue'
import { EnumSaasEventNames, EnumWebSocketStatus, WebSocketStatusEvent } from '@kmsoft/ebf-common'
import { KAgentMonitor } from '../agent-monitor'
import { navigatorClientSrv, onEventEmit } from '../../client-srv'

export default defineView({
  name: 'KAgentMonitorApplet',
  props: KAgentMonitorAppletPropOptions,
  emits: KAgentMonitorAppletEventEmits,
  viewModel: KAgentMonitorAppletViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    //#region  私有方法
    const showIcon = ref(true) //ref(navigatorClientSrv.isWindows)
    /** 连接状态 */
    const agentStatus = computed(() => {
      switch (vm.status.value) {
        case EnumWebSocketStatus.Connected:
          return '连接正常'
        case EnumWebSocketStatus.Connecting:
          return '连接中'
        case EnumWebSocketStatus.Disconnected:
          return '未连接'
        default:
          return '未连接'
      }
    })
    const linkStatus = computed(() => {
      switch (vm.status.value) {
        case EnumWebSocketStatus.Connected:
          return 'success'
        case EnumWebSocketStatus.Connecting:
          return 'warning'
        case EnumWebSocketStatus.Disconnected:
          return 'error'
        default:
          return 'error'
      }
    })

    /** 过渡动画参数 */
    const transitionProps: TransitionProps = {
      appear: false,
      css: false,
      ...(ComponentHelper.scaleXMotion('k-transition-motion-scale-x', false) as any)
    }
    //#endregion

    //#region  事件委托方法
    const openProxyMonitor = (): void => {
      KDialog.show({
        title: '代理程序连接工具',
        size: EnumDialogSize.Small,
        showConfirm: false,
        showCancel: false,
        movable: true,
        mask: false,
        content: () => {
          return createVNode(KAgentMonitor)
        }
      })
    }

    //#endregion

    return {
      showIcon,
      agentStatus,
      linkStatus,
      EnumWebSocketStatus,
      transitionProps,
      openProxyMonitor
    }
  }
})

import { IMenuPagePathMapping } from '@kmsoft/upf-core'
import KIssueReport from '../pages/issue-report'
import KChangeIssueCreate from '../pages/issue-report/issue-report-create'
import { KIssueReportViewModel } from '../pages/issue-report'
import KChangeActivityCreate from '../pages/change-activity/change-activity-create'
import KChangeActivityEdit from '../pages/change-activity/change-activity-edit'
import KChangeKanban from '../pages/change-kanban'
import KChangeTracing from '../pages/change-tracing'
import KChangeRequest from '../pages/change-request'
import KKChangeRequestCreate from '../pages/change-request/change-request-create'
import { KChangeRequestViewModel } from '../pages/change-request'
import KChangeOrderCreate from '../pages/change-order/change-order-create'
import KChangeOrderEdit from '../pages/change-order/change-order-edit'
import { KPersonalChangeItems } from '../pages/personal-change-items'

export default class ModuleMenuExpose implements IMenuPagePathMapping {
  getMenus() {
    return [
      { target: 'issue-report-create', component: KChangeIssueCreate },
      {
        target: 'issue-report-create-popup',
        component: KChangeIssueCreate,
        callback: function(params: any) {
          KIssueReportViewModel.prototype.showCreate(1200, 800, params)
        }
      },
      { target: 'issue-report', component: KIssueReport },
      { target: 'change-activity-create', component: KChangeActivityCreate },
      { target: 'change-activity-edit', component: KChangeActivityEdit },
      { target: 'change-kanban', component: KChangeKanban },
      { target: 'change-tracing', component: KChangeTracing },
      { target: 'change-request-create', component: KKChangeRequestCreate },
      {
        target: 'change-request-create-popup',
        component: KKChangeRequestCreate,
        callback: function(params: any) {
          KChangeRequestViewModel.prototype.showCreate(1200, 800, params)
        }
      },
      { target: 'change-request', component: KChangeRequest },
      { target: 'change-order-create', component: KChangeOrderCreate },
      { target: 'change-order-edit', component: KChangeOrderEdit },
      { target: 'personal-change-items', component: KPersonalChangeItems },
    ]
  }
}

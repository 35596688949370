import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ce77d0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "k-common-searcher" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_property_editor = _resolveComponent("k-object-property-editor")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_input_group = _resolveComponent("k-input-group")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_form = _resolveComponent("k-form")!
  const _component_k_collapse_panel = _resolveComponent("k-collapse-panel")!
  const _component_k_user_selector = _resolveComponent("k-user-selector")!
  const _component_k_date_time = _resolveComponent("k-date-time")!
  const _component_k_checkbox_group = _resolveComponent("k-checkbox-group")!
  const _component_k_collapse = _resolveComponent("k-collapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_collapse, {
      activeKey: _ctx.$vm.activeKey,
      "onUpdate:activeKey": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.$vm.activeKey) = $event)),
      ghost: "",
      class: "page"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_k_collapse_panel, {
          key: "1",
          header: "基本属性"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_form, {
              class: "search-filter",
              "label-col": { span: 8 }
            }, {
              default: _withCtx(() => [
                _createVNode(_TransitionGroup, {
                  name: "k-motion-list",
                  appear: "",
                  class: "aaaaa"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$vm.searchProperties, (f, idx) => {
                      return (_openBlock(), _createBlock(_component_k_form_item, {
                        name: f.columnName,
                        label: f.name,
                        key: idx
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_k_input_group, { compact: "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_k_object_property_editor, {
                                value: _ctx.$vm.form.recordForm[f.columnName].value,
                                "onUpdate:value": ($event: any) => ((_ctx.$vm.form.recordForm[f.columnName].value) = $event),
                                field: f
                              }, null, 8, ["value", "onUpdate:value", "field"]),
                              _createVNode(_component_k_button, {
                                onClick: () => _ctx.$vm.removeField(f)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_k_icon, { type: "minus-circle" })
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["name", "label"]))
                    }), 128)),
                    _createVNode(_component_k_form_item, { key: "-1" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_k_input_group, { compact: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_k_select, {
                              options: _ctx.$vm.unusedOptions,
                              value: _ctx.$vm.newProperty,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.newProperty) = $event)),
                              placeholder: "添加过滤字段"
                            }, null, 8, ["options", "value"]),
                            _createVNode(_component_k_button, {
                              onClick: _ctx.$vm.addField,
                              disabled: !_ctx.$vm.newProperty
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_k_icon, { type: "plus-circle" })
                              ]),
                              _: 1
                            }, 8, ["onClick", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_k_collapse_panel, {
          key: "2",
          header: "通用属性"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_form, { "label-col": { span: 8 } }, {
              default: _withCtx(() => [
                _createVNode(_component_k_form_item, { label: "创建者" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_user_selector, {
                      value: _ctx.$vm.form.lifeStateForm!.creator,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.form.lifeStateForm!.creator) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_form_item, { label: "创建时间" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_date_time, {
                      type: "range",
                      value: _ctx.$vm.form.lifeStateForm!.createTime,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.form.lifeStateForm!.createTime) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_form_item, { label: "修改时间" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_date_time, {
                      type: "range",
                      value: _ctx.$vm.form.lifeStateForm!.lastModifyTime,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.form.lifeStateForm!.lastModifyTime) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_form_item, { label: "发布时间" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_date_time, {
                      type: "range",
                      value: _ctx.$vm.form.lifeStateForm!.publishTime,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.$vm.form.lifeStateForm!.publishTime) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                (_ctx.$vm.isShowCheckoutTime)
                  ? (_openBlock(), _createBlock(_component_k_form_item, {
                      key: 0,
                      label: "检出时间"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_k_date_time, {
                          type: "range",
                          value: _ctx.$vm.form.lifeStateForm!.checkoutTime,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$vm.form.lifeStateForm!.checkoutTime) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_k_form_item, { label: "生命周期状态" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_checkbox_group, {
                      value: _ctx.$vm.form.lifeStateForm!.lifeCycleState,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.$vm.form.lifeStateForm!.lifeCycleState) = $event)),
                      options: _ctx.$vm.lifeStateOptions
                    }, null, 8, ["value", "options"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.enableCommonOptionsForm]
        ])
      ]),
      _: 1
    }, 8, ["activeKey"])
  ]))
}
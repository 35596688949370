import {
  SimpleViewPropOptions,
  SimpleViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KCategoryRenderPropOptions = {
  ...SimpleViewPropOptions,
  formData: VuePropTypes.record().def(),
  readonly: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KCategoryRenderPropType = ViewPropsTypeExtract<typeof KCategoryRenderPropOptions>

/** 事件 */
export const KCategoryRenderEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KCategoryRenderEmitsType = ViewEmitsTypeExtract<typeof KCategoryRenderEventEmits>

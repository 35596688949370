import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumClsDataType, EnumClsEditorType } from '../../../enums'
import { IClassTreeNode } from '../../../../../tree/interface'

// form
export interface IFileEditFormDefinition {
  modelCode: string
}

/** 参数 **/
export const KFileEditPropOptions = {
  ...BaseViewPropOptions,
  /** 当前编辑类型的自定义参数 */
  editArgs: VuePropTypes.string().def(),
  /** 当前属性id */
  propertyId: VuePropTypes.string().def(),
  /** 当前编辑对象类的数据类型 */
  dataType: VuePropTypes.createType<EnumClsDataType>().def(),
  /** 当前编辑对象类的数据的编辑类型 */
  editType: VuePropTypes.createType<EnumClsEditorType>().def(),
  /** 是否只读 */
  readonly: VuePropTypes.bool().def(false),
  /** 选中节点 */
  selectedNode: VuePropTypes.createType<IClassTreeNode>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KFileEditPropType = ViewPropsTypeExtract<typeof KFileEditPropOptions>

/** 事件 */
export const KFileEditEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KFileEditEmitsType = ViewEmitsTypeExtract<typeof KFileEditEventEmits>

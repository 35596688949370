import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_part_report_make = _resolveComponent("k-part-report-make")!
  const _component_k_tab_pane = _resolveComponent("k-tab-pane")!
  const _component_k_part_report_buy = _resolveComponent("k-part-report-buy")!
  const _component_k_tabs = _resolveComponent("k-tabs")!

  return (_openBlock(), _createBlock(_component_k_tabs, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_tab_pane, {
        key: "1",
        tab: "自制机加件材料定额表"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_part_report_make, {
            objParam: _ctx.$vm.objParam
          }, null, 8, ["objParam"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_tab_pane, {
        key: "3",
        tab: "外购件表"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_part_report_buy, {
            objParam: _ctx.$vm.objParam
          }, null, 8, ["objParam"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["activeKey"]))
}
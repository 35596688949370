import {
  Api,
  CommonClientSrv,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  KObjectPanelPropType,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import { AgentManager, AgentRequestModel } from '@kmsoft/ebf-common/src/client-agent'
import { AppContext, EnumDialogResult, KDialog, KDialogClosingEvent, VNodeProps } from '@kmsoft/upf-core'
import { resolveComponent } from 'vue'
import * as Handlers from '.'
import { KPersonalWorkspace, KPersonalWorkspaceViewModel } from '../../../../../../../ebf-folder'
import { DocClientSrv } from '../../../../../client-srv'
import { KDocEditPanel, KDocStructureManage } from '../../../../../controls'
import { integrateConfig } from '../config/integrateConfig'
import { DpMsgClientSrv } from '../DpMsgClientSrv'
import { DpMsgXml } from '../DpMsgXml'
import { EnumExApp } from '../EnumExApp'
import { Application, IOTypeEnum } from '../Parameter'
export class DpMsgHandler {
  /**消息处理注册 */
  public static async register() {
    /**根据配置注册消息处理事件 */
    const integrateAppList = integrateConfig.getAppApi()
    for (const integrateApp of integrateAppList) {
      for (const integrateMethod of integrateApp.methods) {
        if (integrateMethod.handler == this.name) {
          //AgentManager.modelHandlerMaps.set('5.ID_APP_PDM_START', dpMsgHandler.login)
          AgentManager.modelHandlerMaps.set(
            `${integrateApp.id}.${integrateMethod.id}`,
            (DpMsgHandler as any)[integrateMethod.name]
          )
        } else {
          AgentManager.modelHandlerMaps.set(
            `${integrateApp.id}.${integrateMethod.id}`,
            (Handlers as any)[integrateMethod.handler][integrateMethod.name]
          )
        }
      }
      //AgentManager.modelHandlerMaps.set(`${integrateApp.id}.ID_APP_PDM_START`, Handlers['autoCADHandler']['login'])
    }
  }
}

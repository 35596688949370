
import { defineView, ValueChangeEventArgs } from '@kmsoft/upf-core'
import { KPartDocBomComparePropOptions, KPartDocBomCompareEventEmits } from './interface'
import KPartDocBomCompareViewModel from './KPartDocBomCompareViewModel'

export default defineView({
  name: 'KPartDocBomCompare',
  props: KPartDocBomComparePropOptions,
  emits: KPartDocBomCompareEventEmits,
  viewModel: KPartDocBomCompareViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      expandTypeOptions: [
        {
          label: '最新版本',
          value: 'LATEST_WITHOUT_WORKING_COPY'
        },
        {
          label: '最新发布版本',
          value: 'LATEST_RELEASED'
        },
        {
          label: '基线',
          value: 'BASELINE'
        }
      ]
    }
  }
})

import './style/style.less'
import { connect } from '@kmsoft/upf-core'
import KLayoutDesignerView from './KLayoutDesigner.vue'
import KLayoutDesignerViewModel from './KLayoutDesignerViewModel'
import { App } from 'vue'
import { KViewConfig, KViewConfigViewModel, KDesignerMetadata, KDesignerControlPanel } from './controls'

const KLayoutDesigner = connect(KLayoutDesignerView, KLayoutDesignerViewModel)

KLayoutDesigner.install = function(app: App) {
  if (app.config.globalProperties.__k_layout_designer__) return
  app.config.globalProperties.__k_layout_designer__ = true
  app.component('KLayoutDesigner', KLayoutDesigner)
}

export default KLayoutDesigner as typeof KLayoutDesigner & Plugin
export * from './interface'
export * from './common'
export { KLayoutDesigner, KLayoutDesignerViewModel, KViewConfig, KViewConfigViewModel, KDesignerMetadata, KDesignerControlPanel }


import { KDataGridRowDoubleClickEvent, defineView } from '@kmsoft/upf-core'
import { KBaselineGridPropOptions, KBaselineGridEventEmits } from './interface'
import KBaselineGridViewModel from './KBaselineGridViewModel'

export default defineView({
  name: 'KBaselineGrid',
  props: KBaselineGridPropOptions,
  emits: KBaselineGridEventEmits,
  viewModel: KBaselineGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

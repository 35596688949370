
import { defineView } from '@kmsoft/upf-core'
import { KPartExcelExportPropOptions, KPartExcelExportEventEmits } from './interface'
import KPartExcelExportViewModel from './KPartExcelExportViewModel'

export default defineView({
  name: 'KPartExcelExport',
  props: KPartExcelExportPropOptions,
  emits: KPartExcelExportEventEmits,
  viewModel: KPartExcelExportViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

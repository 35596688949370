import { connect, withInstall } from '@kmsoft/upf-core'
import KSelectDocView from './KSelectDoc.vue'
import KSelectDocViewModel from './KSelectDocViewModel'

const KSelectDoc = connect(KSelectDocView, KSelectDocViewModel)

export default withInstall(KSelectDoc)
export { KSelectDoc, KSelectDocView, KSelectDocViewModel }
export * from './interface'

// 模板生成文件
// export * from './{{folderName}}'

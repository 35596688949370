import { BaseViewModel, EnumDialogResult, ViewModelOptions } from '@kmsoft/upf-core'
import { FilePropertyInfo, KFilePropertyEditorEmitsType, KFilePropertyEditorPropType } from './interface'
import { KBaseEditorViewModel, KBasePropertyEditorViewModel } from '../../base'
import { computed, ref, watch } from 'vue'
import { KFileUploader } from '../../../file-uploader'
import { EnumFileUploadingStatus, FileClientSrv, FileInfo } from '../../../../client-srv'

/** 文件属性上传组件 */
export default class KFilePropertyEditorViewModel extends KBasePropertyEditorViewModel<
  KFilePropertyEditorEmitsType,
  KFilePropertyEditorPropType,
  string
> {
  /** 是否正在加载 */
  isUploading = ref<boolean>()
  /** 是否正在下载 */
  isDownloading = ref<boolean>()
  /** 文件信息 */
  fileInfo = ref<FileInfo>()
  /** 显示值 */
  displayValue = computed(() => {
    return this.fileInfo.value?.originalFileName || '无文件'
  })

  constructor(options: ViewModelOptions<KFilePropertyEditorPropType>) {
    super(options)

    watch(
      () => options.props.value,
      () => {
        this.onUpdateFileInfo()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  /**
   * 文件ID 更新
   * @returns
   */
  async onUpdateFileInfo() {
    if (!this.stateValue.value) {
      return
    }
    /** 通过 Id 获取文件信息 */
    const fileInfos = await FileClientSrv.getFilesDetail([this.stateValue.value])
    if (fileInfos.length <= 0) {
      return
    }
    this.fileInfo.value = fileInfos[0]
  }

  /** 清除 */
  onClear() {
    this.stateValue.value = undefined
    this.fileInfo.value = undefined
  }

  /**
   * 上传
   * @description
   */
  async onClickUpload() {
    this.isUploading.value = true
    /** 上传结果 */
    const fileResult = await KFileUploader.uploadAsync({ title: '上传附件', maxCount: 1, multiple: false })

    // 如果不是取消
    if (fileResult.dialogResult != EnumDialogResult.Cancel) {
      /** 过滤文件 */
      const files = fileResult.fileList.filter(
        a => a.status == EnumFileUploadingStatus.DONE || a.status == EnumFileUploadingStatus.COPIED
      )

      // 如果有文件
      if (files.length > 0) {
        const file = fileResult.fileList[0]
        this.fileInfo.value = file
        this.stateValue.value = file.id
        this.api.setPropertyValue('fileName', file.name)
        this.api.setPropertyValue('size', file.size)
        this.api.setPropertyValue('md5', file.md5)
        this.api.setPropertyValue('location', file.location)
        this.api.setPropertyValue('displayLocation', file.displayLocation)
      } else {
        this.clear()
      }
    }

    this.isUploading.value = false
  }

  /**
   * 下载
   * @description
   */
  async onClickDownLoad() {
    if (!this.fileInfo.value) {
      return
    }
    this.isDownloading.value = true
    await FileClientSrv.downloadFile({
      id: this.fileInfo.value.id!,
      location: this.fileInfo.value.location,
      startIndex: 0,
      endIndex: this.fileInfo.value.totalChunkCount! - 1,
      fileName: this.fileInfo.value.originalFileName!
    })
    this.isDownloading.value = false
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineCreateView from './KBaselineCreate.vue'
import KBaselineCreateViewModel from './KBaselineCreateViewModel'

const KBaselineCreate = connect(KBaselineCreateView, KBaselineCreateViewModel)

export default withInstall(KBaselineCreate)
export { KBaselineCreate, KBaselineCreateView, KBaselineCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_breadcrumb = _resolveComponent("k-breadcrumb")!
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_data_grid_image_column = _resolveComponent("k-data-grid-image-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_split_container = _resolveComponent("k-split-container")!
  const _component_k_tool_strip_input_item = _resolveComponent("k-tool-strip-input-item")!
  const _component_k_tool_strip_select_item = _resolveComponent("k-tool-strip-select-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createVNode(_component_k_breadcrumb, {
        ref: _ctx.$vm.refBreadcrumb,
        separator: '>',
        routes: _ctx.$vm.breadcrumbRoute,
        onClick: _cache[0] || (_cache[0] = event => _ctx.$vm.refBreadcrumb_Click(event))
      }, null, 8, ["routes"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_k_tool_strip, null, {
        default: _withCtx(() => [
          _createVNode(_component_k_tool_strip_input_item, {
            ref: _ctx.$vm.refFileNames,
            title: '文件名：',
            width: 600,
            enable: false
          }, null, 512),
          _createVNode(_component_k_tool_strip_select_item, {
            ref: _ctx.$vm.refFileType,
            "allow-clear": false,
            visible: _ctx.$vm.filtersVisible,
            options: _ctx.$vm.filters,
            value: _ctx.$vm.filters[0]?.value,
            onChange: _cache[5] || (_cache[5] = value => _ctx.$vm.refFileType_Change(value as any))
          }, null, 8, ["visible", "options", "value"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_split_container, { "enable-collapse": false }, {
        default: _withCtx(() => [
          _createVNode(_component_k_split_pane, { size: 30 }, {
            default: _withCtx(() => [
              _createVNode(_component_k_tree_view, {
                ref: _ctx.$vm.refTreeView,
                "auto-select-last-node": false,
                "default-expanded-level": 0,
                "load-data": node => _ctx.$vm.refTreeView_LoadData(node),
                onAfterNodeInsert: _cache[1] || (_cache[1] = event => _ctx.$vm.refTreeView_AfterNodeInsert(event)),
                onAfterSelect: _cache[2] || (_cache[2] = event => _ctx.$vm.refTreeView_AfterSelect(event))
              }, null, 8, ["load-data"])
            ]),
            _: 1
          }),
          _createVNode(_component_k_split_pane, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_data_grid, {
                ref: _ctx.$vm.refDataGrid,
                unCheckablePredicate: row => (row as KTreeNodeFileSystemInfo).disabled,
                "is-multiple-selection": _ctx.multiselect,
                onCellDblClick: _cache[3] || (_cache[3] = event => _ctx.$vm.refDataGrid_CellDoubleClicked(event)),
                onSelectionChanged: _cache[4] || (_cache[4] = event => _ctx.$vm.refDataGrid_SelectionChanged(event))
              }, {
                columns: _withCtx(() => [
                  _createVNode(_component_k_data_grid_image_column, {
                    headerText: '',
                    dataPropertyName: 'shellIcon',
                    width: 40
                  }),
                  _createVNode(_component_k_data_grid_text_box_column, {
                    headerText: '名称',
                    dataPropertyName: 'name'
                  }),
                  _createVNode(_component_k_data_grid_text_box_column, {
                    headerText: '路径',
                    dataPropertyName: 'path'
                  }),
                  _createVNode(_component_k_data_grid_text_box_column, {
                    headerText: '类型',
                    dataPropertyName: 'pathTypeName'
                  })
                ]),
                _: 1
              }, 8, ["unCheckablePredicate", "is-multiple-selection"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}

import { defineView } from '@kmsoft/upf-core'
import { KFolderSelectTestPropOptions, KFolderSelectTestEventEmits } from './interface'
import KFolderSelectTestViewModel from './KFolderSelectTestViewModel'
import { FolderTypeEnum } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KFolderSelectTest',
  props: KFolderSelectTestPropOptions,
  emits: KFolderSelectTestEventEmits,
  viewModel: KFolderSelectTestViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      showWorkspaceType: [FolderTypeEnum.STANDARD_PARTS, FolderTypeEnum.COMMON_PARTS]
    }
  }
})

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartCollectFormPropOptions = {
  ...BaseViewPropOptions,
  parts: VuePropTypes.createType<Array<any>>().def()
}

/** 参数类型 **/
export type KPartCollectFormPropType = ViewPropsTypeExtract<typeof KPartCollectFormPropOptions>

/** 事件 */
export const KPartCollectFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartCollectFormEmitsType = ViewEmitsTypeExtract<typeof KPartCollectFormEventEmits>

/**
 * 工作状态枚举
 */
export const WorkingStateEnum = [
  {
    label: '工作中',
    value: 'INWORK'
  },
  {
    label: '已检入',
    value: 'CHECKED_IN'
  },
  {
    label: '已检出',
    value: 'CHECKED_OUT'
  }
]

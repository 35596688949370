import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_doc_form = _resolveComponent("k-doc-form")!
  const _component_k_tab_pane = _resolveComponent("k-tab-pane")!
  const _component_k_tabs = _resolveComponent("k-tabs")!

  return (_openBlock(), _createBlock(_component_k_tabs, {
    activeKey: _ctx.$vm.tabActiveKey,
    ref: "refFolderTabs"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_tab_pane, {
        key: "1",
        tab: "详情"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_doc_form, { ref: "refDocPanel" }, null, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_k_tab_pane, {
        key: "2",
        tab: "安全"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 开发中 ")
        ]),
        _: 1
      }),
      _createVNode(_component_k_tab_pane, {
        key: "3",
        tab: "流程"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 开发中 ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["activeKey"]))
}
import { AgentInstanceObject } from '../../AgentInstanceObject'

import { ImageFormat } from './imaging/ImageFormat'
import { Stream } from '../io/Stream'

/**为源自 {@link Bitmap} 和 {@link Metafile} 的类提供功能的抽象基类。*/
export abstract class Image extends AgentInstanceObject {
  protected static type: string = 'System.Drawing.Image,System.Drawing.Common'

  /**将此图像以指定的格式保存到指定的流中。
   * @param stream 将在其中保存图像的 {@link Stream}。
   * @param format {@link ImageFormat}，指定保存的图像的格式。
   * @returns */
  public async Save(stream: Stream, format: ImageFormat): Promise<void> {
    return this.invokeMethod('Save', stream, format)
  }
}

import {
  BaseViewModel,
  EnumItemChangeCancelType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KDistributionEditEmitsType, KDistributionEditPropType } from './interface'
import { ref } from 'vue'
import {
  Api,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPropertyEditPanelViewModel,
  ObjBusinessParam,
  ObjectToolStripItem,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import { WorkflowClientSrv } from '../../../../../ebf-workflow/src/client-srv'

/** KDistributionEdit */
export default class KDistributionEditViewModel extends BaseViewModel<KDistributionEditEmitsType, KDistributionEditPropType> {
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  taskParam = ref<ObjBusinessParam>()
  originFormData = ref<any>()
  toolStripItems = ref<ObjectToolStripItem[]>(ToolStripHelper.getDistributionEditToolStripItems())

  constructor(options: ViewModelOptions<KDistributionEditPropType>) {
    super(options)
    this.taskParam.value = options.props.objParam
  }

  viewDidMount() {}

  async loadData() {
    const result = (await Api.post('doc', 'Distribution', 'get', { data: [this.props.objParam.id] })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      const data = result.data

      if (data.lifecycleStateCode === 'RELEASED' || data.lifecycleStateCode === 'UNDERREVIEW') {
        this.disabledEdit()
      }

      this.originFormData.value = data
      return data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message,
        details: result.detail
      })
      return
    }
  }

  /** 禁用编辑**/
  disabledEdit() {
    this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
    this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
  }

  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const buttonName = event.name as EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE
    const method = this[buttonName]
    if (typeof method === 'function') {
      method.call(this, event)
    }
  }

  /** 保存 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE](event: ToolStripItemClickedEventArgs) {
    this.save()
    // this.refObjectProperty.value?.setReadOnly(true)
  }

  /** 刷新 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH](event: ToolStripItemClickedEventArgs) {
    this.refObjectProperty.value?.refresh()
  }

  /** 加入流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.addWorkflow(
      this.taskParam.value!.id,
      'Distribution',
      this.originFormData.value.lifecycleStateCode == 'RELEASED' ? 'Released' : '',
      this.originFormData.value.name,
      this.originFormData.value.number
    )
  }

  /** 查看流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.viewWorkflow(this.taskParam.value!.id, 'Distribution')
  }

  /** 修改状态 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_UPDATE_STATE](event: ToolStripItemClickedEventArgs) {
    KNotification.info({
      message: '功能正在开发中，敬请期待'
    })
  }

  onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    if (event.name == EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY) {
      if (event.value) {
        this.refObjectProperty.value?.setReadOnly(false)
      } else {
        this.save()
        this.refObjectProperty.value?.setReadOnly(true)
        event.itemCancelType = EnumItemChangeCancelType.CancelAll
      }
    }
  }

  async save() {
    const modifiedValue = this.refObjectProperty.value?.getModifiedValue() as any
    const value = this.refObjectProperty.value?.getValue() as any
    //如果有修改对
    if (Object.keys(modifiedValue).length) {
      // 附件
      if (Object.keys(modifiedValue).includes('secondary')) {
        modifiedValue.secondaryIds = [...modifiedValue.secondary]
        Reflect.deleteProperty(modifiedValue, 'secondary')
      }
      if (Object.keys(value).includes('plmDistributionContentTargetViewDTOS')) {
        modifiedValue!.distributionContentLinkViews = (value!.plmDistributionContentTargetViewDTOS as any[]).map(item => {
          return {
            target: {
              id: item.id,
              rdmExtensionType: item.rdmExtensionType
            }
          }
        })

        Reflect.deleteProperty(modifiedValue, 'plmDistributionContentTargetViewDTOS')
      }

      if (Object.keys(value).includes('distributionRecordViews')) {
        const reg = /^[1-9]\d*$/
        const verifyPriority = value!.distributionRecordViews.some(
          (item: any) => item.countOfCopies && !reg.test(item.countOfCopies)
        )
        if (verifyPriority) {
          KNotification.warn({
            message: '保存失败',
            description: '发放份数只能填写大于0的整数'
          })
          return
        }
        modifiedValue!.distributionRecordViews = (value!.distributionRecordViews as any[]).map(item => {
          return {
            countOfCopies: item.countOfCopies,
            receiver: item.receiver,
            receivingTime: item.receivingTime,
            status: item.status
          }
        })
      }

      const params = {
        data: [
          {
            id: this.props.objParam.id,
            ...modifiedValue
          }
        ]
      }

      const result = await Api.post('doc', 'Distribution', 'update', params)
      if (result && result.code == EnumRequestCode.SUCCESS) {
        this.refObjectProperty.value?.refresh()
        KNotification.success('操作成功！')
      } else {
        KNotification.error({
          title: '系统错误',
          content: result.message
        })
      }
    } else {
      KNotification.warn({
        message: '系统提示',
        description: '没有修改数据'
      })
    }
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartManagePropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KPartManagePropType = ViewPropsTypeExtract<typeof KPartManagePropOptions>

/** 事件 */
export const KPartManageEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartManageEmitsType = ViewEmitsTypeExtract<typeof KPartManageEventEmits>

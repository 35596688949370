import { BaseViewModel, EnumType, KSchema, ViewModelOptions, KNotification, SimpleViewModel } from '@kmsoft/upf-core'
import { KCategoryRenderEmitsType, KCategoryRenderPropType } from './interface'
import { ref, watch } from 'vue'
import { get } from 'lodash'
import { Api } from '@kmsoft/ebf-common'
import { KDynamicViewViewModel } from '@kmsoft/upf-view-engine'

/** KCategoryRender */
export default class KCategoryRenderViewModel extends SimpleViewModel<KCategoryRenderEmitsType, KCategoryRenderPropType> {
  refCategory = ref<any>(null)
  refDynamic = ref<any | KDynamicViewViewModel>(null)
  categoryControls: Array<Record<string, any>> = []
  categoryPropsSchema = ref<KSchema>()
  isShowCategory = ref<boolean>(false)
  pageValue = ref<Record<string, any>>({})
  activeKeys = ref<Array<string>>(['class'])
  readonly = ref<any>(false)

  /** 初始化画布 */
  initDraw = (id: any) => {
    this.refreshClass(id)
  }

  /** 清除 */
  clear = () => {
    this.refreshClass('')
  }

  constructor(options: ViewModelOptions<KCategoryRenderPropType>) {
    super(options)
    this.readonly.value = options?.props?.readonly
  }

  viewDidMount() {
    if (this?.props?.formData?.catetory) {
      this.refreshClass()
    }
  }

  /** 监听分类选择 */
  async refreshClass(id = '') {
    this.isShowCategory.value = false
    // id为空统一处理
    if (!id || (Array.isArray(id) && id.length === 0)) {
      this.categoryPropsSchema.value = ({} as any) as KSchema
      return
    }
    const result = (await Api.post('common', 'ClassificationNode', 'getCategoryNodeInfo', {
      data: [id]
    })) as any
    // 接口调用失败清除分类属性
    if (!result?.success) {
      KNotification.error({
        title: '操作失败',
        content: result.message || '查询失败',
        details: result.detail
      })
      this.categoryPropsSchema.value = ({} as any) as KSchema
      return
    }
    if (!get(result.data, '[0].attrs.length', 0)) return
    // 接口调用成功处理
    this.successOption(result)
  }

  // 调用成功处理
  async successOption(result: any) {
    const { attrs } = get(result, 'data[0]', {})
    const commonArr = {
      control: 'KInput',
      visible: this.props.visible,
      dataSource: '',
      nullable: 1,
      readonly: this.props.readonly,
      disabled: this.props.disabled,
      type: 'control',
      events: [],
      props: {
        labelPosition: 'left',
        readonly: this.readonly.value
      },
      layout: {
        rowSpan: 1,
        colSpan: 1,
        entireRow: false,
        fill: false
      },
      dataType: '50'
    }
    this.categoryControls = (attrs || []).map((arr: any) => {
      return {
        name: arr?.name + arr?.nameEn,
        title: arr?.name,
        field: arr?.nameEn,
        ...commonArr
      }
    })
    const schema = {
      name: 'category',
      type: EnumType.PAGE,
      title: '分类属性',
      control: 'KDynamicView',
      visible: true,
      disabled: false,
      readonly: 0,
      props: {
        minColWidth: 260,
        rowGap: 5,
        colGap: 10,
        colCount: 3,
        labelWidth: 100,
        labelPosition: 'left'
      },
      events: [],
      styles: [],
      controls: this.categoryControls,
      subPages: []
    }
    this.isShowCategory.value = true
    this.categoryPropsSchema.value = (schema as any) as KSchema
  }

  // 拿数据
  getValue() {
    const oldValue = this.refDynamic?.value?.getValue()
    return [{ catAttr: oldValue }]
  }
  //存数据
  setValue(value: any) {
    const values = get(value, '[0].catAttr')
    this.pageValue = values
  }

  public getModifiedValue() {
    return this.getValue()
  }

  isModified(): boolean {
    return true
  }

  refresh() {
    this.categoryPropsSchema.value?.controls.map(arr => {
      arr.props.readonly = this.readonly.value
      return arr
    })
  }
}

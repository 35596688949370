import { BaseViewModel, KFormViewModel, SelectOption, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { IProductWorkspaceBaseProp, KProductWorkspaceFormEmitsType, KProductWorkspaceFormPropType } from './interface'

/** KProductWorkspaceForm */
export default class KProductWorkspaceFormViewModel extends BaseViewModel<
  KProductWorkspaceFormEmitsType,
  KProductWorkspaceFormPropType
> {
  /** 所有者列表 */
  ownerSelectOptions = {}
  /** 基本信息表单 */
  refBasePropForm = ref<KFormViewModel<IProductWorkspaceBaseProp>>()
  /** 基本信息 */
  formData = ref<IProductWorkspaceBaseProp>({
    name: '',
    description: ''
  })

  ownerOptions = ref<Array<SelectOption>>()
  /** 校验规则 */
  rules = {
    name: [{ required: true, message: '产品库名称不能为空', trigger: 'blur' }]
  }

  constructor(options: ViewModelOptions<KProductWorkspaceFormPropType>) {
    super(options)
    if (this.props.workspace) {
      this.formData.value = this.props.workspace
    }
  }

  viewDidMount() {}

  /**
   * 获取表单值
   * @returns
   */
  getFormValue() {
    const value = this.refBasePropForm.value?.formState

    /** 参数 */
    const param = {
      ...value
    }
    return param
  }
}

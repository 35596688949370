
import { EnumDataGridRowModelType, EnumDataGridRowSpanType, KDataGridRowClickEvent, defineView } from '@kmsoft/upf-core'
import { KFlowDetailUpdateExecutorPropOptions, KFlowDetailUpdateExecutorEventEmits } from './interface'
import KFlowDetailUpdateExecutorViewModel from './KFlowDetailUpdateExecutorViewModel'
import { computed, ref } from 'vue'
import { SelectItem } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KFlowDetailUpdateExecutor',
  props: KFlowDetailUpdateExecutorPropOptions,
  emits: KFlowDetailUpdateExecutorEventEmits,
  viewModel: KFlowDetailUpdateExecutorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const userOptions = computed(() => {
      return (
        vm.userList.value.map(user => {
          return new SelectItem(user.name, user.name)
        }) || []
      )
    })

    return {
      userOptions,
      executorRowModelType: EnumDataGridRowModelType.CLIENT_SIDE,
      userRowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      rowSpanMode: EnumDataGridRowSpanType.SAME_ROW
    }
  }
})

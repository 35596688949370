
import { defineView } from '@kmsoft/upf-core'
import { KChangeOrderCreatePropOptions, KChangeOrderCreateEventEmits } from './interface'
import KChangeOrderCreateViewModel from './KChangeOrderCreateViewModel'

export default defineView({
  name: 'KChangeOrderCreate',
  props: KChangeOrderCreatePropOptions,
  emits: KChangeOrderCreateEventEmits,
  viewModel: KChangeOrderCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})


import { defineView } from '@kmsoft/upf-core'
import { ActionTypeEnum, KFolderFormEventEmits, KFolderFormPropOptions } from './interface'
import KFolderFormViewModel from './KFolderFormViewModel'
import { readonly } from 'vue'

export default defineView({
  name: 'KFolderForm',
  props: KFolderFormPropOptions,
  emits: KFolderFormEventEmits,
  viewModel: KFolderFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      visible: props.actionType == ActionTypeEnum.EDIT,
      disabled: props.actionType == ActionTypeEnum.READONLY
    }
  }
})

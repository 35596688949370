import { KDataElementTree, PageDesignerDataSourceProperty } from '../../../../types/metadata'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** KDesignerMetadata 状态 **/
export interface IKDesignerMetadataState {}

/** KDesignerMetadata 事件 **/
export const KDesignerMetadataEventEmits = {
  ...BaseViewEventEmits
}

/** KDesignerMetadata 参数 **/
export const KDesignerMetadataPropOptions = {
  ...BaseViewPropOptions,
  convertElement: VuePropTypes.func<(element: PageDesignerDataSourceProperty) => any>().def(),
  /** 不可拖拽的元素  */
  disabledDragElementMap: VuePropTypes.createType<Map<string, string>>().def(),
  /** 数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>()
    .setRequired()
    .def()
}

/** KDesignerMetadata 参数类型 **/
export type KDesignerMetadataPropType = ViewPropsTypeExtract<typeof KDesignerMetadataPropOptions>
/** KDesignerMetadata 事件协议 */
export type KDesignerMetadataEventEmitsType = ViewEmitsTypeExtract<typeof KDesignerMetadataEventEmits>

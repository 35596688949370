import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-835f9220"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hyper-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: "javascript:void(0)",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vm.openObjTab()))
    }, _toDisplayString(_ctx.$vm.stateValue), 1)
  ]))
}
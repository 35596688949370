import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e3c5a05"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "node-title" }
const _hoisted_2 = { class: "node-name" }
const _hoisted_3 = {
  key: 0,
  class: "node-action"
}
const _hoisted_4 = {
  key: 1,
  class: "script-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_spin = _resolveComponent("k-spin")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_popover = _resolveComponent("k-popover")!
  const _component_k_directory_tree = _resolveComponent("k-directory-tree")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_monaco_editor = _resolveComponent("k-monaco-editor")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createBlock(_component_k_split_container, { class: "k-biz-script-editor" }, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_pane, {
        size: 15,
        minSize: 15,
        maxSize: 50
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_directory_tree, {
            ref: _ctx.$vm.refModuleTree,
            class: "script-tree",
            nodeKey: 'id',
            defaultExpandedLevel: 0,
            moduleType: _ctx.$vm.moduleType,
            loadDirObj: true,
            showRootDirectoryCodes: _ctx.groupTypes,
            unselectablePredicate: (node)=>node.leaf !== true,
            autoSelectLastNode: false,
            onAfterSelect: _cache[0] || (_cache[0] = (event) => _ctx.$vm.onTreeNodeAfterSelect(event))
          }, {
            title: _withCtx(({node}) => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(node.name), 1),
                (node.leaf)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_k_popover, {
                        title: "脚本信息",
                        trigger: "click",
                        placement: "rightTop"
                      }, {
                        content: _withCtx(() => [
                          (_ctx.$vm.isLoadingScriptContent)
                            ? (_openBlock(), _createBlock(_component_k_spin, {
                                key: 0,
                                style: {"height":"200px"}
                              }))
                            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createElementVNode("p", null, _toDisplayString(node.name), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.$vm.currentScript), 1)
                              ]))
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_k_button, {
                            size: "small",
                            shape: "circle"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_k_icon, { type: "more" })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["moduleType", "showRootDirectoryCodes", "unselectablePredicate"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_split_pane, null, {
        default: _withCtx(() => [
          _createVNode(_component_k_monaco_editor, {
            ref: _ctx.$vm.refScriptEditor,
            value: _ctx.$vm.value,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.value) = $event)),
            showLangSelector: false,
            showThemeSelector: false,
            showToolStrip: _ctx.showToolStrip,
            toolStripItems: _ctx.toolStripItems
          }, null, 8, ["value", "showToolStrip", "toolStripItems"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
import {
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  SimpleViewPropOptions,
  SimpleViewEventEmits,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KBaseEditorPropOptions = {
  ...SimpleViewPropOptions
}

/** 参数类型 **/
export type KBaseEditorPropType = ViewPropsTypeExtract<typeof KBaseEditorPropOptions>

/** 事件 */
export const KBaseEditorEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KBaseEditorEmitsType = ViewEmitsTypeExtract<typeof KBaseEditorEventEmits>

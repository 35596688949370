import { BaseViewModel, KSchema, ValueChangeEventArgs, ViewModelOptions } from '@kmsoft/upf-core'
import { KFilterGridSearchBarConfigEventEmitsType, KFilterGridSearchBarConfigPropType } from './interface'
import { ref } from 'vue'

/** KFilterGridSearchBarConfig 视图模型 */
export class KFilterGridSearchBarConfigViewModel extends BaseViewModel<
  KFilterGridSearchBarConfigEventEmitsType,
  KFilterGridSearchBarConfigPropType
> {
  /** 设计器引用 */
  refLayoutDesign = ref<Nullable<any>>()

  constructor(options: ViewModelOptions<KFilterGridSearchBarConfigPropType>) {
    super(options)
  }

  /**
   * 加载完成函数
   */
  viewDidMount() {}

  /**
   * 获取搜索栏布局方案
   */
  getValue() {
    return this.refLayoutDesign.value?.getSchema()
  }
  /**
   * 校验
   * @returns
   */
  validate() {
    return new Promise(resolve => {
      resolve(true)
    })
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KVersionGroupObjectSelectFormPropOptions = {
  ...BaseViewPropOptions,
  /**
   * 零部件Id
   */
  partId: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KVersionGroupObjectSelectFormPropType = ViewPropsTypeExtract<typeof KVersionGroupObjectSelectFormPropOptions>

/** 事件 */
export const KVersionGroupObjectSelectFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KVersionGroupObjectSelectFormEmitsType = ViewEmitsTypeExtract<typeof KVersionGroupObjectSelectFormEventEmits>

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!

  return (_openBlock(), _createBlock(_component_k_object_class_grid, {
    objClsCode: "Part",
    objClsGroup: "part",
    onToolStripItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onToolStripItemClicked(event)),
    toolStripItems: _ctx.$vm.gridToolStripItems
  }, null, 8, ["toolStripItems"]))
}
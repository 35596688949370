
import { defineView } from '@kmsoft/upf-core'
import { KBusinessVersionRenderPropOptions, KBusinessVersionRenderEventEmits } from './interface'
import KBusinessVersionRenderViewModel from './KBusinessVersionRenderViewModel'

export default defineView({
  name: 'KBusinessVersionRender',
  props: KBusinessVersionRenderPropOptions,
  emits: KBusinessVersionRenderEventEmits,
  viewModel: KBusinessVersionRenderViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      return (
        <div class="k-render-input-text" title={vm.stateValue.value}>
          {vm.stateValue.value}
        </div>
      )
    }
  }
})

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KBottomBarEmitsType, KBottomBarPropType } from './interface'
import { ref } from 'vue'

/** KBottomBar */
export default class KBottomBarViewModel extends BaseViewModel<KBottomBarEmitsType, KBottomBarPropType> {
  constructor(options: ViewModelOptions<KBottomBarPropType>) {
    super(options)
  }
  isMaxPageSize = ref<Boolean>(false)
  /**
   * 分页大小切换事件
   */
  onPageSizeChange() {
    this.isMaxPageSize.value = !this.isMaxPageSize.value
    this.emit('pageSizeChange', this.isMaxPageSize.value)
  }
  /** 刷新事件 */
  onRefresh() {
    this.emit('refresh')
  }
  onMenuClick(event: any) {
    if (event.key == 'exportSelect') {
      this.onSelectExport()
    } else {
      this.onExcelExport()
    }
  }
  onSelectExport() {
    this.emit('excelSelectExport')
  }
  onExcelExport() {
    this.emit('excelExport')
  }

  viewDidMount() {}
}

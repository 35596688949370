
import { defineView } from '@kmsoft/upf-core'
import { KIssueReportRelationshipPropOptions, KIssueReportRelationshipEventEmits } from './interface'
import KIssueReportRelationshipViewModel from './KIssueReportRelationshipViewModel'

const data = [
  {
    id: '#change-request',
    title: '问题报告-变更请求',
    key: '1'
  }
] as any

export default defineView({
  name: 'KIssueReportRelationship',
  props: KIssueReportRelationshipPropOptions,
  emits: KIssueReportRelationshipEventEmits,
  viewModel: KIssueReportRelationshipViewModel,
  setup() {
    return {
      data
    }
  }
})

import { KPersonalElectronicDistribution } from '../controls/personal-electronic-distribution'
import KPageModeling from '../pages/mvvm-test'
import { IMenuPagePathMapping } from '@kmsoft/upf-core'
export default class DocMenuPagePathMapping implements IMenuPagePathMapping {
  getMenus() {
    return [
      { target: 'object-panel-test-kmvue', component: KPageModeling },
      { target: 'personal-electronic-distribution', component: KPersonalElectronicDistribution }
    ]
  }
}

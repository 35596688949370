import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

// 页面表单状态
export interface IConfigCustomViewModelState {
  dyComponent: any
}

// 页面事件
export const KConfigCustomEventEmits = {
  ...BaseViewEventEmits
}

export const KConfigCustomViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('')
}

export type KConfigCustomViewModelPropType = ViewPropsTypeExtract<typeof KConfigCustomViewModelPropOptions>

export type KConfigCustomEventEmitsType = ViewEmitsTypeExtract<typeof KConfigCustomEventEmits>

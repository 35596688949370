import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cdc6f5d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_tooltip = _resolveComponent("k-tooltip")!
  const _component_k_input_group_label = _resolveComponent("k-input-group-label")!
  const _component_k_input_group = _resolveComponent("k-input-group")!
  const _component_k_image = _resolveComponent("k-image")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_k_input_group, {
      class: "k-image-editor",
      compact: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_k_input_group_label, {
          class: "file-label",
          value: _ctx.$vm.displayValue,
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$vm.displayValue), 1),
            (_ctx.allowClear)
              ? (_openBlock(), _createBlock(_component_k_tooltip, {
                  key: 0,
                  title: "清除"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_button, {
                      size: "small",
                      type: "text",
                      onClick: _ctx.$vm.onClear
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_k_icon, { type: "clear" })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["value"]),
        (_ctx.showDownloadButton && _ctx.$vm.stateValue)
          ? (_openBlock(), _createBlock(_component_k_button, {
              key: 0,
              class: "action-button",
              loading: _ctx.$vm.isDownloading,
              disabled: _ctx.$vm.disabled,
              onClick: _ctx.$vm.onClickPreview
            }, {
              default: _withCtx(() => [
                _createVNode(_component_k_icon, { type: "eye" })
              ]),
              _: 1
            }, 8, ["loading", "disabled", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.showUploadButton)
          ? (_openBlock(), _createBlock(_component_k_button, {
              key: 1,
              class: "action-button",
              loading: _ctx.$vm.isUploading,
              disabled: _ctx.$vm.disabled,
              onClick: _ctx.$vm.onClickUpload
            }, {
              default: _withCtx(() => [
                _createVNode(_component_k_icon, { type: "file-text" })
              ]),
              _: 1
            }, 8, ["loading", "disabled", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_k_image, {
      width: 200,
      style: { display: 'none' },
      preview: {
      visible: _ctx.$vm.imageVisible,
      onVisibleChange: _ctx.$vm.setImageVisible
    },
      src: _ctx.$vm.imageSrc
    }, null, 8, ["preview", "src"])
  ], 64))
}
import { MemoryCacheBase, MemoryCacheBaseConstructor, MemoryCacheRepositoryBase } from '@kmsoft/upf-core'
import { TypeHelper } from '../../common'
import * as caches from './caches'

/** 内存缓存 */
export class MemoryCacheRepository extends MemoryCacheRepositoryBase {
  getCaches(): MemoryCacheBaseConstructor[] {
    /** 缓存列表 */
    const cacheList: Array<MemoryCacheBaseConstructor> = []
    /** 缓存类列表 */
    const cacheRecord = caches as Record<string, typeof MemoryCacheBase>
    // 循环缓存列表
    for (const key in cacheRecord) {
      const obj = cacheRecord[key]
      if (!TypeHelper.couldBeClass(obj)) {
        continue
      }
      cacheList.push(obj)
    }
    return cacheList
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KCustomerNeedsSectionCreateView from './KCustomerNeedsSectionCreate.vue'
import KCustomerNeedsSectionCreateViewModel from './KCustomerNeedsSectionCreateViewModel'

const KCustomerNeedsSectionCreate = connect(KCustomerNeedsSectionCreateView, KCustomerNeedsSectionCreateViewModel)

export default withInstall(KCustomerNeedsSectionCreate)
export { KCustomerNeedsSectionCreate, KCustomerNeedsSectionCreateView, KCustomerNeedsSectionCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

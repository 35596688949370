
import { defineView } from '@kmsoft/upf-core'
import { EnumLifecycleState, EnumPhaseState, clsCodeMap, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KChangeActivityObjGridPropOptions, KChangeActivityObjGridEventEmits } from './interface'
import KChangeActivityObjGridViewModel from './KChangeActivityObjGridViewModel'
import { readonly } from 'vue'

export default defineView({
  name: 'KChangeActivityObjGrid',
  props: KChangeActivityObjGridPropOptions,
  emits: KChangeActivityObjGridEventEmits,
  viewModel: KChangeActivityObjGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumLifecycleState, EnumPhaseState, clsCodeMap, EnumLifecycleStateColor }
  }
})

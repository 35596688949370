import { ObjBusinessBase, ObjBusinessParam, ObjectLoadDataProvider, ObjectPanelAttachParams } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartObjManagePropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  formValue: VuePropTypes.createType<IPartDefinition>().def(),
  /** 面板值加载 */
  loadData: VuePropTypes.func<ObjectLoadDataProvider>()
    .setRequired()
    .def(() => Promise.resolve({} as ObjBusinessBase)),
  /** 附属参数 */
  attachParams: VuePropTypes.createType<ObjectPanelAttachParams>().def({}),
  tabId: VuePropTypes.string().def('lbj-mswd'),
  container: VuePropTypes.createType<HTMLElement>().def()
}

/** 参数类型 **/
export type KPartObjManagePropType = ViewPropsTypeExtract<typeof KPartObjManagePropOptions>

/** 事件 */
export const KPartObjManageEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartObjManageEmitsType = ViewEmitsTypeExtract<typeof KPartObjManageEventEmits>

/** 零部件来源 */
export enum EnumPartSource {
  /**
   * 自制
   */
  Make = '自制',
  /**
   * 采购
   */
  Buy = '采购'
}

/** 零部件默认单位 */
export enum EnumPartDefaultUnit {
  EA = '每个',
  AS_NEEDED = '按需',
  KG = '千克',
  M = '米',
  L = '升',

  EA2 = '台',
  EA3 = '套',
  EA4 = '件',
  EA5 = '只',
  EA6 = '张',
  EA7 = '包',
  EA8 = '本',
  EA9 = '块',
  EA10 = '片',
  EA11 = '条'
}

/** 引用对象 */
export interface IObjectReferenceDefinition {
  id: string
  clazz: string
}

/** 零部件扩展类型 */
export interface IPartTypeDefinition {
  id: string
  // 显示名
  name: string
  // 对象类名
  nameEn: string
}

// 页面定义
export interface IPartDefinition {
  // id
  id: string
  // 类型
  rdmExtensionType: string
  // 主对象
  master: IObjectReferenceDefinition
  // 分支对象
  branch: IObjectReferenceDefinition
  // 编码
  number: string
  // 名称
  name: string
  // 默认单位
  defaultUnit: string
  // 来源
  source: EnumPartSource
  // 零部件视图
  partView: string
  // 文件夹
  folder: IObjectReferenceDefinition
}

// 左侧列表定义
export interface IPartDataItemDefinition {
  id: string
  title: string
  key: string
}

// 查询规则
export enum IPartQueryRuleType {
  LATEST = '最新迭代',
  LATEST_RELEASED = '最新发布'
}

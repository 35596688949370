import { connect, withInstall } from '@kmsoft/upf-core'
import KFolderManageView from './KFolderManage.vue'
import KFolderManageViewModel from './KFolderManageViewModel'

const KFolderManage = connect(KFolderManageView, KFolderManageViewModel)

export default withInstall(KFolderManage)
export { KFolderManage, KFolderManageView, KFolderManageViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { MetaProperty } from '../../../../client-srv'
import { Component } from 'vue'
import { KObjectClassGridBaseColumn } from './KBaseColumn'

export class IFieldEditorResolver {
  /**
   * 根据属性定义获取编辑器组件
   * @param field 属性定义
   * @returns
   */
  getEditor(field: MetaProperty): string | (() => Component) | null {
    return null
  }

  /**
   * 根据属性定义获取网格列类型
   * @param field
   */
  getColumn(field: MetaProperty): KObjectClassGridBaseColumn | null {
    return null
  }

  /**
   * 获取自定义表格属性
   * @param field
   */
  getTableEditor(field: MetaProperty): string | (() => Component) | null {
    return null
  }

  // /**
  //  * 获取过滤器
  //  * @param field
  //  * @returns
  //  */
  // getFilter(field: MetaProperty): string | (() => Component) | null {
  //   return () => KBaseFilterElement
  // }
}

import { ViewModelOptions, EnumDialogResult } from '@kmsoft/upf-core'
import { KImageEditorEmitsType, KImageEditorPropType } from './interface'
import { KBaseEditorViewModel } from '../../base'
import { computed, ref, watch } from 'vue'
import { KFileUploader } from '../../../file-uploader'
import { EnumFileUploadingStatus, FileClientSrv, FileInfo, UploadFileInfo } from '../../../../client-srv'

/** KImageEditor */
export default class KImageEditorViewModel extends KBaseEditorViewModel<KImageEditorEmitsType, KImageEditorPropType, string> {
  /** 是否正在加载 */
  isUploading = ref<boolean>()
  /** 是否正在下载 */
  isDownloading = ref<boolean>()
  /** 文件信息 */
  fileInfo = ref<FileInfo>()
  /** 图片 */
  imageSrc = ref<string>()
  /** 显示值 */
  displayValue = computed(() => {
    return this.fileInfo.value?.originalFileName || '无文件'
  })
  imageVisible = ref<boolean>(false)

  constructor(options: ViewModelOptions<KImageEditorPropType>) {
    super(options)

    watch(
      () => options.props.value,
      () => {
        this.onUpdateFileInfo()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  /**
   * 设置图片是否可见
   * @param value
   */
  setImageVisible(value: boolean): void {
    this.imageVisible.value = value
  }

  /**
   * 文件ID 更新
   * @returns
   */
  async onUpdateFileInfo() {
    if (!this.stateValue.value) {
      return
    }
    const fileInfos = await FileClientSrv.getFilesDetail([this.stateValue.value])
    if (fileInfos.length <= 0) {
      return
    }
    this.fileInfo.value = fileInfos[0]
  }

  /** 清除 */
  onClear() {
    this.stateValue.value = undefined
    this.fileInfo.value = undefined
  }

  /**
   * 上传
   * @description
   */
  async onClickUpload() {
    this.isUploading.value = true
    /** 上传结果 */
    const fileResult = await KFileUploader.uploadAsync({
      title: '上传附件',
      maxCount: 1,
      multiple: false,
      accept: this.props.accept
    })

    // 如果不是取消
    if (fileResult.dialogResult != EnumDialogResult.Cancel) {
      /** 过滤文件 */
      const files = fileResult.fileList.filter(
        a => a.status == EnumFileUploadingStatus.DONE || a.status == EnumFileUploadingStatus.COPIED
      )

      // 如果有文件
      if (files.length > 0) {
        const file = fileResult.fileList[0]
        this.fileInfo.value = file
        this.stateValue.value = file.id
      } else {
        this.clear()
      }
    }

    this.isUploading.value = false
  }

  /**
   * 下载
   * @description
   */
  async onClickPreview() {
    if (!this.fileInfo.value) {
      return
    }
    this.isDownloading.value = true
    const fileResult = await FileClientSrv.getFileObjectUrl({
      id: this.fileInfo.value.id!,
      location: this.fileInfo.value.location,
      startIndex: 0,
      endIndex: this.fileInfo.value.totalChunkCount! - 1,
      fileName: ''
    })
    this.imageSrc.value = fileResult
    this.imageVisible.value = true
    this.isDownloading.value = false
  }
}

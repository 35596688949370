import { ref, watch } from 'vue'
import {
  BaseViewModel,
  KNotification,
  IKDataGrid,
  ViewModelOptions,
  KDialog,
  KDialogClosingEvent,
  EnumDialogResult,
  KDataGridViewModel
} from '@kmsoft/upf-core'
import { KClassPropertyListEventEmitsType, KClassPropertyListPropType } from './interface'
import KClassPropertyForm, { KClassPropertyFormViewModel } from './class-property-form'
import { EnumClsDataType, allDataTypeConfig, allEditorTypeConfig } from './enums'
import { IClsPropsFormDefine } from './class-property-form/interface'
import { KImportItem, KImportItemViewModel } from './import-item'
import { Api, EnumRequestCode } from '../../../../../../ebf-common'

export class KClassPropertyListViewModel extends BaseViewModel<KClassPropertyListEventEmitsType, KClassPropertyListPropType> {
  /** 对象类属性网格 */
  refClassPropertyGrid = ref<KDataGridViewModel>()
  /** 所有数据类型的下拉选项 */
  dataTypeOptions = ref<Record<string, any>>(Object.values(allDataTypeConfig))
  /** 所有编辑类型的下拉选项 */
  editTypeOptions = ref<Record<string, any>>(Object.values(allEditorTypeConfig))
  /**属性类型 */
  propertyTypeOptions = ref<Record<string, any>>([
    { label: '基础属性', value: 'BASE' },
    { label: '扩展属性', value: 'EXTEND' }
  ])
  /** 当前被选中的对象类 */
  private selectedCode: string

  constructor(options: ViewModelOptions<KClassPropertyListPropType>) {
    super(options)

    watch(
      () => options.props.selectedNode,
      newVal => {
        newVal && this.refresh(newVal)
      },
      { immediate: true }
    )
  }

  /** 加载完成函数 */
  viewDidMount() {}

  /**
   * 右侧refresh， 点击左侧树、初始化时
   * @param node 左侧树当前选择的node
   */
  async refresh(val: any) {
    this.selectedCode = val
    // 获取数据
    const res: any = await Api.post('official', 'ClsLayoutPropService', 'listPropByClsCode', { data: [[this.selectedCode]] })
    if (res?.code != EnumRequestCode.SUCCESS) {
      return KNotification.error({
        title: '系统提示',
        content: '属性集查询错误'
      })
    }
    const data = res.data[val]
    this.setGridData(data || [])
  }

  /** 导入属性 */
  async importProperty() {
    // 查询全部属性
    const result = await Api.post('official', 'ClsPropService', 'listByClsCode', { data: [[this.props.selectedNode]] })
    if (result?.code != EnumRequestCode.SUCCESS) {
      return KNotification.error({
        title: '系统提示',
        content: '引入属性失败'
      })
    }

    const data = result.data?.map((item: any) => {
      item.dataType = allDataTypeConfig[item.dataType as EnumClsDataType]?.label as any
      return item
    })

    KDialog.show({
      title: '导入属性',
      size: { width: 900, height: 600 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KImportItem,
      props: {
        dataSource: data,
        fields: [
          { title: '属性名称', id: 'name' },
          { title: '属性编码', id: 'code' },
          { title: '数据类型', id: 'dataType' },
          { title: '属性类型', id: 'propSource' }
        ]
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KImportItemViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const data = formViewModel.getValue()!

        // 引入属性
        const result = await Api.post('official', 'ClsLayoutPropService', 'batchInductProp', {
          data: [data.map(item => item.id)]
        })
        if (result?.code != EnumRequestCode.SUCCESS) {
          event.cancel = true
          KNotification.error({
            title: '导入失败',
            content: result.message!
          })
          return
        }
        // 刷新网格数据
        this.refresh(this.selectedCode)
      }
    })
  }

  /** 网格数据保存 */
  async updateProp(row: Record<string, any>) {
    // 获取表单的详情数据
    const result = await Api.post('official', 'ClsLayoutPropService', 'fetchPropDetail', { data: [row.id] })
    let detailData: IClsPropsFormDefine = {}

    if (result?.code != EnumRequestCode.SUCCESS) {
      return KNotification.error({
        title: result.message,
        content: result.detail!
      })
    }

    detailData = result?.data! || []
    // 当前数据类型对应的配置选项
    const currentDataTypeConfig = allDataTypeConfig[row.dataType as EnumClsDataType]

    // 数据类型对应的默认的编辑类型
    if (!detailData.editType && currentDataTypeConfig) detailData.editType = currentDataTypeConfig.defaultEditorType

    KDialog.show({
      title: '更新属性信息',
      size: { width: 900, height: 600 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KClassPropertyForm,
      props: {
        dataType: row.dataType,
        formState: { ...detailData },
        /**文件访问 */
        fileAccess: this.props.fileAccess,
        propertyCode: row.propertyCode,
        selectedNode: this.props.selectedNode
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const vm = event.viewModel as KClassPropertyFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const data = vm.getValue() as any

        // 这里对空列表进行筛选
        const args = JSON.parse(data.editArgs)
        if (args.options) {
          args.options = args.options.filter((it: any) => it.label != '')
          data.editArgs = JSON.stringify(args)
        }
        const saveRes = await Api.post('official', 'ClsLayoutPropService', 'saveProp', { data: [{ ...data }] })
        // 保存到后台
        if (saveRes?.code != EnumRequestCode.SUCCESS) {
          KNotification.error({
            title: '保存失败',
            content: saveRes.data!
          })
          event.cancel = true
        }

        KNotification.success({
          title: '保存成功',
          content: saveRes.data!
        })
        // 更新网格数据
        this.refresh(this.selectedCode)
      }
    })
  }

  /** 网格数据查看 */
  async detail(row: Record<string, any>) {
    // 获取表单的详情数据
    const result = await Api.post('official', 'ClsLayoutPropService', 'fetchPropDetail', { data: [row.id] })
    let detailData: IClsPropsFormDefine = {}
    if (result?.code != EnumRequestCode.SUCCESS) {
      return KNotification.error({
        title: result.message,
        content: result.detail!
      })
    }
    detailData = result?.data! || []
    // 当前数据类型对应的配置选项
    const currentDataTypeConfig = allDataTypeConfig[row.dataType as EnumClsDataType]
    // 数据类型对应的默认的编辑类型
    if (!detailData.editType && currentDataTypeConfig) detailData.editType = currentDataTypeConfig.defaultEditorType
    KDialog.show({
      title: '查看布局属性信息',
      size: { width: 900, height: 600 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KClassPropertyForm,
      props: {
        dataType: row.dataType,
        formState: { ...detailData },
        operationType: 'detail'
      }
    })
  }

  /**
   * 移除属性
   */
  async removeProp(row: Record<string, any>) {
    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: async () => {
        const result = await Api.post('official', 'ClsLayoutPropService', 'batchDetachProp', { data: [[row.id]] })
        if (result?.code != EnumRequestCode.SUCCESS) {
          KNotification.error({
            title: result.message,
            content: result.detail!
          })
          return
        }
        // 刷新网格数据
        this.refClassPropertyGrid.value?.removeRow(row.id)
      }
    })
  }

  /**
   * 获取网格数据
   * @returns
   */
  getGridData(): Array<Record<string, any>> {
    return this.refClassPropertyGrid.value!.getRows()
  }

  /**
   * 设置网格数据
   * @param data
   */
  setGridData(data: Array<Record<string, any>>) {
    this.refClassPropertyGrid.value!.setDataSource(data)
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KRequirementChangeEditPanelView from './KRequirementChangeEditPanel.vue'
import KRequirementChangeEditPanelViewModel from './KRequirementChangeEditPanelViewModel'

const KRequirementChangeEditPanel = connect(KRequirementChangeEditPanelView, KRequirementChangeEditPanelViewModel)

export default withInstall(KRequirementChangeEditPanel)
export { KRequirementChangeEditPanel, KRequirementChangeEditPanelView, KRequirementChangeEditPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { GridCellEditorParams, GridCellRendererParams, KObjectClassGridBaseColumn } from '../../base'
import KLifecycleStateEditor from '.'
import { EnumLifecycleState } from '../../../../client-srv'
import { IKDataGridCellParams } from '@kmsoft/upf-core'
import { VNode, RendererNode, RendererElement, createTextVNode } from 'vue'

/** 对象显示器 */
export class KDataGridLifecycleStateColumn extends KObjectClassGridBaseColumn {
  name = 'KDataGridLifecycleStateColumn'
  options = EnumLifecycleState._list
  getCellRenderer(params: GridCellRendererParams) {
    const col = (params.cellParams as any).col
    const newParam = { ...col, ...params }
    return <KLifecycleStateEditor {...newParam} />
  }

  getCellEditor(params: GridCellEditorParams) {
    const col = (params.cellParams as any).col
    const newParam = { ...col, ...params }
    return <KLifecycleStateEditor {...newParam} />
  }
}

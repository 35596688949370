import { ref } from 'vue'
import { BaseViewEventEmitsType, BaseViewModel, BaseViewPropType, KTabsViewModel } from '@kmsoft/upf-core'
import KSysTabViewModel from './sys/sys-tab/KSysTabViewModel'
import KDevTabViewModel from './dev/dev-tab/KDevTabViewModel'

export default class KConfigManageViewModel extends BaseViewModel<BaseViewEventEmitsType, BaseViewPropType> {
  // 选项卡
  refObjClsTab = ref<KTabsViewModel>()
  // 系统配置选项卡
  refSysTabView = ref<KSysTabViewModel>()
  // 自定义配置选项卡
  refDevTabView = ref<KDevTabViewModel>()

  /**
   * 加载完成函数
   */
  viewDidMount() {}
}

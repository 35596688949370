import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

export interface GridDataInter {
  rdmVersion?: number
  creator?: string
  rdmDeleteFlag?: number
  modifier?: string
  checkInState?: string
  className?: string
  version?: string
  number?: string
  linkId?: string
  createTime?: string
  name?: string
  iteration?: string
  rdmExtensionType?: string
  state?: any
  id?: string
  class?: string
  tenant?: any
  lastUpdateTime?: string
  versionInfo?: string
  diffType?: 'normal' | 'recordAdd' | 'propertyDiff' // 差异类型，正常 、记录新增，属性差异
  propertyDiffInfo?: PropertyDiffInfoInter[]
  [key: string]: any
}

export interface PropertyDiffInfoInter {
  propertyName: string
  text: string
}

/** 参数 **/
export const KChangeActivityUpdateCompareGridPropOptions = {
  ...BaseViewPropOptions,
  /** 是否是参考值 **/
  isReference: VuePropTypes.bool().def(false),
  /** 标题 **/
  title: VuePropTypes.string().def(),
  /** 数据源 **/
  dataSource: VuePropTypes.createType<GridDataInter[]>().def([])
}

/** 参数类型 **/
export type KChangeActivityUpdateCompareGridPropType = ViewPropsTypeExtract<typeof KChangeActivityUpdateCompareGridPropOptions>

/** 事件 */
export const KChangeActivityUpdateCompareGridEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangeActivityUpdateCompareGridEmitsType = ViewEmitsTypeExtract<typeof KChangeActivityUpdateCompareGridEventEmits>

import {
  BaseViewPropOptions,
  ViewPropsTypeExtract,
  VuePropTypes,
  EventArgs,
  BaseViewEventEmits,
  ViewEmitsTypeExtract
} from '@kmsoft/upf-core'
import { IValueDesc } from '../../interface'

// 配置项定义
export interface IConfigValueDefinition {
  defId: string
  id: string
  code: string
  name: string
  value: string
  values: Array<string>
  value_switch: boolean
  remark: string
  dataType: string
  editType: string
  editParam: string
}

/**
 * 配置项有效性定义
 */
export interface IConfigValidDefinition {
  defId: string
  valid: string
  moduleValidList: Array<Record<string, any>>
}

/**更新配置项(模块)有效性状态事件对外参数 */
export class UpdateConfigValidStatusEventArgs extends EventArgs {
  // 有效状态
  validStatus: IConfigValidDefinition
  constructor(validStatus: IConfigValidDefinition) {
    super()
    this.validStatus = validStatus
  }
}

// 页面事件
export const KConfigValueEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 更新配置项(模块)有效性状态
   * @param validStatus 有效性
   */
  updateConfigValidStatus: (e: UpdateConfigValidStatusEventArgs) => true
}

export const KConfigValueViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('')
}

export type KConfigValueViewModelPropType = ViewPropsTypeExtract<typeof KConfigValueViewModelPropOptions>

export type KConfigValueEventEmitsType = ViewEmitsTypeExtract<typeof KConfigValueEventEmits>

import {
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  KToolStripPropOptions,
  KToolStripEventEmits,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KObjectToolStripPropOptions = {
  ...KToolStripPropOptions,
  enableShortcut: VuePropTypes.bool().def(true)
}

/** 参数类型 **/
export type KObjectToolStripPropType = ViewPropsTypeExtract<typeof KObjectToolStripPropOptions>

/** 事件 */
export const KObjectToolStripEventEmits = {
  ...KToolStripEventEmits
}

/** 事件类型 */
export type KObjectToolStripEventEmitsType = ViewEmitsTypeExtract<typeof KObjectToolStripEventEmits>

import lodash from 'lodash'
import { ref, Ref, watch } from 'vue'
import { ObjectToolStripItem } from '..'

type ToolStripConfigParams = {
  /** 附加工具栏数组 */
  extraItems?: {
    /** 附加工具栏数组 */
    items: Ref<Array<ObjectToolStripItem> | undefined>
    /** 插入到最前面 */
    appendFront?: boolean
  }[]
}

/** 工具栏处理类 */
class ToolStripConfigHandler {
  /** 配置列表 */
  public items = ref<Array<ObjectToolStripItem>>([])

  /** 原始配置 */
  private originData: Array<ObjectToolStripItem>

  /** 参数 */
  private params?: ToolStripConfigParams

  /**
   * 构造
   * @param items 工具栏数组
   * @param params 更新配置
   */
  constructor(items: Array<ObjectToolStripItem>, params?: ToolStripConfigParams) {
    /** items 初始化 */
    this.items.value = []
    /** 保存参数 */
    this.params = params

    // 如果传入了附加参数
    if (params?.extraItems) {
      params?.extraItems.forEach(element => {
        /** 监听子组件增加的菜单项 */
        watch(element.items, this.onUpdateToolStrip.bind(this))
      })
    }

    /** 更新工具栏状态 */
    this.updateItems(items, params)
  }

  /**
   * 更新工具栏配置
   * @param items 工具栏数组
   * @param params 更新配置
   */
  async updateItems(items: Array<ObjectToolStripItem>, params?: ToolStripConfigParams) {
    const cloneData = lodash.cloneDeep(items)
    /** 克隆原始数据 */
    this.originData = cloneData
    /** 新工具栏数组 */
    let newItems = cloneData

    // 附加条目
    if (params?.extraItems) {
      params?.extraItems.forEach(element => {
        /** 如果插入到前面 */
        const appendFront = element.appendFront || false

        if (element.items.value) {
          /** 如果是组合到最前面 */
          if (appendFront) {
            newItems = [...lodash.cloneDeep(element.items.value), ...newItems]
          } else {
            newItems = [...newItems, ...lodash.cloneDeep(element.items.value)]
          }
        }
      })
    }

    // 工具栏数组
    this.items.value = newItems
  }

  /** 附加工具栏更新回调 */
  private onUpdateToolStrip(newValue: Array<ObjectToolStripItem> | undefined, oldValue: Array<ObjectToolStripItem> | undefined) {
    this.updateItems(this.originData, this.params)
  }
}

/** 定义工具栏配置 */
export const defineToolStripData = (
  items: Array<ObjectToolStripItem>,
  params?: ToolStripConfigParams
): Ref<Array<ObjectToolStripItem>> => {
  const handler = new ToolStripConfigHandler(lodash.cloneDeep(items), params)
  return handler.items as Ref<Array<ObjectToolStripItem>>
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KConfigFormItemEmitsType, KConfigFormItemPropType, formFields } from './interface'
import { ref, watch } from 'vue'

/** KConfigFormItem */
export default class KConfigFormItemViewModel extends BaseViewModel<KConfigFormItemEmitsType, KConfigFormItemPropType> {
  // 表单绑定modelvalue值
  formValue = ref<Record<string, any>>({})
  // 表单列配置
  formList = ref<Array<formFields>>([]) // 是否为查看态
  isView = ref<Boolean>(true)
  // 表单label宽度
  labelCol = ref<Number>(4)
  // 表单多少列排列
  colSpan = ref<Number>(1)

  constructor(options: ViewModelOptions<KConfigFormItemPropType>) {
    super(options)
    this.labelCol.value = options.props.labelCol
    this.isView.value = options.props.isView
    this.colSpan.value = options.props.colSpan

    // 监听值
    watch(
      () => this.props.formValue,
      newVal => {
        this.formValue.value = newVal
      },
      {
        immediate: true
      }
    )

    // 监听列配置
    watch(
      () => this.props.formList,
      (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
          this.formList.value = newVal
        }
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}
}

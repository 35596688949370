
import { IKTreeNode, TreeViewSelectEventArgs, defineView } from '@kmsoft/upf-core'
import { KFolderTreePropOptions, KFolderTreeEventEmits } from './interface'
import KFolderTreeViewModel from './KFolderTreeViewModel'
import { isFunction } from 'lodash'

export default defineView({
  name: 'KFolderTree',
  props: KFolderTreePropOptions,
  emits: KFolderTreeEventEmits,
  viewModel: KFolderTreeViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const afterSelect = (event: TreeViewSelectEventArgs<any>) => {
      vm.emit('afterSelect', event)
    }
    const loadTreeData = (node: null | IKTreeNode) => {
      const loadData = props.loadData
      if (loadData && isFunction(loadData)) {
        return loadData(node)
      }
      return Promise.resolve([])
    }
    return {
      afterSelect,
      loadTreeData
    }
  }
})

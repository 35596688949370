import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

// 页面表单状态
export interface IConfigTileViewModelState {
  configList: Array<Record<string, any>>
  mode: string
}

// 页面事件
export const KConfigTileEventEmits = {
  ...BaseViewEventEmits
}

export const KConfigTileViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('')
}

export type KConfigTileViewModelPropType = ViewPropsTypeExtract<typeof KConfigTileViewModelPropOptions>

export type KConfigTileEventEmitsType = ViewEmitsTypeExtract<typeof KConfigTileEventEmits>


import { defineView } from '@kmsoft/upf-core'
import { KObjectRelationsPropOptions, KObjectRelationsEventEmits } from './interface'
import KObjectRelationsViewModel from './KObjectRelationsViewModel'
import { KObjectRelationGrid } from '../object-relation-grid'

export default defineView({
  name: 'KObjectRelations',
  props: KObjectRelationsPropOptions,
  emits: KObjectRelationsEventEmits,
  viewModel: KObjectRelationsViewModel,
  components: { KObjectRelationGrid }
})

import { KObjectPropertyEditPanelBaseEventEmits, KObjectPropertyEditPanelBasePropOptions } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KDocObjectPropertyEditPanelPropOptions = {
  ...KObjectPropertyEditPanelBasePropOptions
}

/** 参数类型 **/
export type KDocObjectPropertyEditPanelPropType = ViewPropsTypeExtract<typeof KDocObjectPropertyEditPanelPropOptions>

/** 事件 */
export const KDocObjectPropertyEditPanelEventEmits = {
  ...KObjectPropertyEditPanelBaseEventEmits
}

/** 事件类型 **/
export type KDocObjectPropertyEditPanelEmitsType = ViewEmitsTypeExtract<typeof KDocObjectPropertyEditPanelEventEmits>

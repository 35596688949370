
import { defineView } from '@kmsoft/upf-core'
import { KRefObjEditorFormViewModel } from './KRefObjEditorFormViewModel'
import { KRefObjEditorCustomPropOptions } from './interface'

export default defineView({
  name: 'RefObjEditorForm', // 组件名称
  components: {}, // 这里定义局部组件
  viewModel: KRefObjEditorFormViewModel,
  props: KRefObjEditorCustomPropOptions,
  setup(props, ctx) {
    return {}
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeOrderRelationshipView from './KChangeOrderRelationship.vue'
import KChangeOrderRelationshipViewModel from './KChangeOrderRelationshipViewModel'

const KChangeOrderRelationship = connect(KChangeOrderRelationshipView, KChangeOrderRelationshipViewModel)

export default withInstall(KChangeOrderRelationship)
export { KChangeOrderRelationship, KChangeOrderRelationshipView, KChangeOrderRelationshipViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartBomCompareModeFormView from './KPartBomCompareModeForm.vue'
import KPartBomCompareModeFormViewModel from './KPartBomCompareModeFormViewModel'

const KPartBomCompareModeForm = connect(KPartBomCompareModeFormView, KPartBomCompareModeFormViewModel)

export default withInstall(KPartBomCompareModeForm)
export { KPartBomCompareModeForm, KPartBomCompareModeFormView, KPartBomCompareModeFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView, ToolStripItemChangeEventArgs, ToolStripItemClickedEventArgs } from '@kmsoft/upf-core'
import { KDocEditPanelEventEmits, KDocEditPanelPropOptions } from './interface'
import KDocEditPanelViewModel from './KDocEditPanelViewModel'

export default defineView({
  name: 'KDocEditPanel',
  props: KDocEditPanelPropOptions,
  emits: KDocEditPanelEventEmits,
  viewModel: KDocEditPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

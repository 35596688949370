import { XmlNode } from './XmlNode'

/**表示 XML 文档。 可使用此类在文档中加载、验证、编辑、添加和放置 XML。*/
export class XmlDocument extends XmlNode {
  protected static type: string = 'System.Xml.XmlDocument,System.Xml.ReaderWriter'

  /**初始化 {@link XmlDocument} 类的新实例。*/
  public static async create(): Promise<XmlDocument> {
    return this.createInstanceAndWrap()
  }

  /**从指定的 URL 加载 XML 文档。
   * @param filename 含要加载的 XML 文档的文件的 URL。 URL 既可以是本地文件，也可以是 HTTP URL（Web 地址）。*/
  public async Load(filename: string): Promise<void> {
    return this.invokeMethod('Load', filename)
  }

  /**将 XML 文档保存到指定的文件。 如果存在指定文件，则此方法会覆盖它。
   * @param filename 要将文档保存到其中的文件的位置。*/
  public async Save(filename: string): Promise<void> {
    return this.invokeMethod('Save', filename)
  }
}

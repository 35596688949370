
import { defineView } from '@kmsoft/upf-core'
import { KLoginPagePropOptions, KLoginPageEventEmits } from './interface'
import KLoginPageViewModel from './KLoginPageViewModel'
import { createVNode, ref } from 'vue'
import KLogin from '../login'
import KSSOLogin from '../sso-login'
import { sysSrv } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KLoginPage',
  props: KLoginPagePropOptions,
  emits: KLoginPageEventEmits,
  viewModel: KLoginPageViewModel,
  components: { KSSOLogin, KLogin },
  setup(props, { emit, slots, attrs, vm }) {
    let isLoading = ref(false)
    let remind = ref<string>()
    let sysTitle = ref<string>()
    let sysSubTitle = ref<string>()
    let loginComponent = ref<any>()
    const init = () => {
      // 设置为加载
      isLoading.value = true
      // 标题
      sysTitle.value = 'test'
      // 判断是否是单点登录
      if (sysSrv.isSSOLogin()) {
        loginComponent.value = createVNode(KSSOLogin)
      } else {
        loginComponent.value = createVNode(KLogin)
      }

      isLoading.value = false
    }
    init()

    return { isLoading, remind, sysTitle, sysSubTitle, loginComponent }
  }
})

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!

  return (_openBlock(), _createBlock(_component_k_object_class_grid, {
    ref: _ctx.$vm.refObjGrid,
    modelCode: "ManagedBaseline",
    modelGroup: "baseline",
    schemaType: "grid",
    onToolStripItemClicked: _ctx.$vm.toolStripItemClicked,
    toolStripItems: _ctx.$vm.toolItem,
    loadData: _ctx.$vm.loadData,
    onRowDoubleClick: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onDoubleClick(event))
  }, null, 8, ["onToolStripItemClicked", "toolStripItems", "loadData"]))
}
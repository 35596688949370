
import { defineView } from '@kmsoft/upf-core'
import KFolderManage from '../../folder-manage'
import { KCADWorkspacePropOptions, KCADWorkspaceEventEmits } from './interface'
import KCADWorkspaceViewModel from './KCADWorkspaceViewModel'

export default defineView({
  name: 'KCADWorkspace',
  props: KCADWorkspacePropOptions,
  emits: KCADWorkspaceEventEmits,
  viewModel: KCADWorkspaceViewModel,
  components: { KFolderManage }, // 这里定义局部组件
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KConfigTreePropOptions } from './interface'
import { EnumNodeLevel } from '../../interface'
import KDevConfigTreeViewModel from './KDevConfigTreeViewModel'

export default defineView({
  name: 'DevConfigTree',
  props: KConfigTreePropOptions,
  viewModel: KDevConfigTreeViewModel,
  setup() {
    return {
      EnumNodeLevel
    }
  }
})

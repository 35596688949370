import { IMenuPagePathMapping } from '@kmsoft/upf-core'
import KConfigManage from '../pages/config-manage'

export default class ModuleMenuExpose implements IMenuPagePathMapping {
  getMenus() {
    return [
      { target: 'config-manage', component: KConfigManage }
    ]
  }
}

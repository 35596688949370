import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeStatisticsView from './KChangeStatistics.vue'
import KChangeStatisticsViewModel from './KChangeStatisticsViewModel'

const KChangeStatistics = connect(KChangeStatisticsView, KChangeStatisticsViewModel)

export default withInstall(KChangeStatistics)
export { KChangeStatistics, KChangeStatisticsView, KChangeStatisticsViewModel }

// 模板生成文件
// export * from './{{folderName}}'

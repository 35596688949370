import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KChangeActivityCreateEmitsType, KChangeActivityCreatePropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api, EnumWorkingState } from '@kmsoft/ebf-common'
import lodash from 'lodash'

/** KChangeActivityCreate */
export default class KChangeActivityCreateViewModel extends BaseViewModel<
  KChangeActivityCreateEmitsType,
  KChangeActivityCreatePropType
> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  formData = ref<any>({
    number: '',
    name: '',
    activityType: '',
    type: 'DESIGN_CHANGE',
    businessDecisionCategory: 'DESIGN_MATTERS',
    priority: 'LOW',
    complexity: 'LOW',
    inventoryDisposition: '',
    description: '',
    supplyingOrganizations: '',
    needDate: null,
    secondaryIds: null,
    folder: {
      id: ''
    },
    changeBeforeLinkViews: [],
    changeAfterLinkViews: []
  })

  constructor(options: ViewModelOptions<KChangeActivityCreatePropType>) {
    super(options)
  }

  viewDidMount() {
    // 文件夹赋值
    if (this.props.formValue?.folder?.id) {
      this.formData.value.folder.id = this.props.formValue.folder?.id
    }

    //变更前赋值
    if (this.props.changeBeforeData) {
      this.formData.value.changeBeforeLinkViews = lodash.cloneDeep(this.props.changeBeforeData)
    }

    //变更后赋值
    if (this.props.changeAfterData) {
      this.formData.value.changeAfterLinkViews = lodash.cloneDeep(this.props.changeAfterData)
    }
  }

  getValue() {
    const createObject = lodash.cloneDeep(this.refObjectCreatePanel.value?.getValue())

    return createObject
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  async save() {
    const createObject = this.getValue()
    //变更前对象
    createObject!.changeBeforeLinkViews = (createObject!.changeBeforeLinkViews as any[]).map(item => {
      return {
        masterId: item.targetMasterId || item?.master?.id,
        branchId: item.targetBranchId || item?.branch?.id,
        target: {
          id: item.targetId || item.id,
          className: item.targetClass || item.className,
          rdmExtensionType: item.targetExtensionType || item.rdmExtensionType
        }
      }
    })
    //变更后对象
    createObject!.changeAfterLinkViews = (createObject!.changeAfterLinkViews as any[]).map(item => {
      return {
        masterId: item.targetMasterId || item?.master?.id,
        target: {
          id: item.targetBranchId || item?.branch?.id,
          rdmExtensionType: item.targetBranchType || item?.branch?.rdmExtensionType
        }
      }
    })

    // 如果是从变更单创建而来，添加关联关系
    if (this.props.changeOrderId) {
      createObject!.changeOrderViewDTO = {
        id: this.props.changeOrderId
      }
    }

    const params = {
      data: [
        {
          ...createObject
        }
      ]
    }

    return await Api.post('change', 'ChangeActivity', 'create', params)
  }
}

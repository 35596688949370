
import { defineView } from '@kmsoft/upf-core'
import { KRequirementChangeCreateFormPropOptions, KRequirementChangeCreateFormEventEmits } from './interface'
import KRequirementChangeCreateFormViewModel from './KRequirementChangeCreateFormViewModel'

export default defineView({
  name: 'KRequirementChangeCreateForm',
  props: KRequirementChangeCreateFormPropOptions,
  emits: KRequirementChangeCreateFormEventEmits,
  viewModel: KRequirementChangeCreateFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KConfigObjectGrid from './KConfigObjectGrid.vue'
import KConfigObjectGridViewModel from './KConfigObjectGridViewModel'

const KObjectGrid = connect(KConfigObjectGrid, KConfigObjectGridViewModel)

export default withInstall(KObjectGrid)
export { KObjectGrid, KConfigObjectGrid, KConfigObjectGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { EnumAttributeType, EnumDataType } from '@kmsoft/upf-core'

/**控件属性集合 */
const PROPS = [
  {
    name: 'modelName',
    title: '对象类编码',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'attributeName',
    title: '属性名称',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  }
] as Array<any>

const EVENT = [
  { name: 'beforeValueChange', title: '值改变前', describe: '值改变前 的说明信息...' },
  { name: 'change', title: '值改变后', describe: '值改变后 的说明信息...' }
]

export default { PROPS, EVENT }

import { connect, withInstall } from '@kmsoft/upf-core'
import KProductWorkspaceFormView from './KProductWorkspaceForm.vue'
import KProductWorkspaceFormViewModel from './KProductWorkspaceFormViewModel'

const KProductWorkspaceForm = connect(KProductWorkspaceFormView, KProductWorkspaceFormViewModel)

export default withInstall(KProductWorkspaceForm)
export { KProductWorkspaceForm, KProductWorkspaceFormView, KProductWorkspaceFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KAgentDownloadView from './KAgentDownload.vue'
import KAgentDownloadViewModel from './KAgentDownloadViewModel'

const KAgentDownload = connect(KAgentDownloadView, KAgentDownloadViewModel)

export default withInstall(KAgentDownload)
export { KAgentDownload, KAgentDownloadView, KAgentDownloadViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { IFolder } from '../../folder-manage/folder-tree/interface'
import { KProjectWorkspaceEmitsType, KProjectWorkspacePropType, ProjectRootTreeNode } from './interface'

/** KProjectWorkspace */
export default class KProjectWorkspaceViewModel extends BaseViewModel<KProjectWorkspaceEmitsType, KProjectWorkspacePropType> {
  constructor(options: ViewModelOptions<KProjectWorkspacePropType>) {
    super(options)
  }

  viewDidMount() {}

  async loadTreeData() {
    const param = {
      data: [this.props.workspaceId]
    }
    const res = await Api.post('folder', 'Folder', 'getFolderById', param)
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const node = {
        id: res.data?.id,
        name: res.data?.name,
        leafFlag: res.data.leafFlag,
        nameEn: res.data.nameEn,
        type: res.data.type
      } as IFolder
      return [new ProjectRootTreeNode(node)]
    } else {
      KNotification.error({
        title: '获取项目工作区数据异常',
        content: res.detail!
      })
      return Promise.resolve([])
    }
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KCreatePanelDocSelectorPropOptions, KCreatePanelDocSelectorEventEmits } from './interface'
import KCreatePanelDocSelectorViewModel from './KCreatePanelDocSelectorViewModel'
import { EnumAddMainFileType } from '../../interface'

export default defineView({
  name: 'KCreatePanelDocSelector',
  props: KCreatePanelDocSelectorPropOptions,
  emits: KCreatePanelDocSelectorEventEmits,
  viewModel: KCreatePanelDocSelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumAddMainFileType }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KGridDesignerToolBoxEventEmits, KGridDesignerToolBoxPropOptions } from './interface'
import KGridDesignerToolBoxViewModel from './KGridDesignerToolBoxViewModel'
import { KDesignerControlPanel, KDesignerMetadata } from '../../../../../designer'

export default defineView({
  name: 'KGridDesignerToolBox',
  props: KGridDesignerToolBoxPropOptions,
  emits: KGridDesignerToolBoxEventEmits,
  viewModel: KGridDesignerToolBoxViewModel,
  components: { KDesignerControlPanel, KDesignerMetadata },
  setup(props, { attrs, emit, expose, slots, vm }) {
    return {}
  }
})

import KDynamicViewView from './KDynamicView.vue'
import { KDynamicViewViewModel } from './KDynamicViewViewModel'
import { connect, withInstall } from '@kmsoft/upf-core'

const KDynamicView = connect(KDynamicViewView, KDynamicViewViewModel)

export default withInstall(KDynamicView)
export { KDynamicView, KDynamicViewView, KDynamicViewViewModel }
export * from './utils/render'
export * from './interface'

import { Api, EnumRequestCode, FolderTypeEnum } from '@kmsoft/ebf-common'
import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { IFolder } from '../../folder-manage/folder-tree/interface'
import { CommonPartWorkspace, KCommonPartWorkspaceEmitsType, KCommonPartWorkspacePropType } from './interface'

/** KCommonPartWorkspace */
export default class KCommonPartWorkspaceViewModel extends BaseViewModel<
  KCommonPartWorkspaceEmitsType,
  KCommonPartWorkspacePropType
> {
  constructor(options: ViewModelOptions<KCommonPartWorkspacePropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 加载树节点
   */
  async loadTreeData() {
    const param = {
      data: [FolderTypeEnum.COMMON_PARTS]
    }
    const res = await Api.post('folder', 'Folder', 'getFolderByType', param)
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const node = {
        id: res.data?.id,
        name: res.data?.name,
        leafFlag: res.data.leafFlag,
        nameEn: res.data.nameEn,
        type: res.data.type
      } as IFolder
      return [new CommonPartWorkspace(node)]
    } else {
      KNotification.error({
        title: '获取公共工作区数据异常',
        content: res.message!
      })
      return Promise.resolve([])
    }
  }
}

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { FolderTypeEnum } from '../..'

/** 参数 **/
export const KFolderSelectorFormPropOptions = {
  ...BaseViewPropOptions,
  /**
   * 文件夹Id
   */
  folderId: VuePropTypes.string().def(''),
  /**
   * 显示的工作区类型
   */
  showWorkspaceType: VuePropTypes.createType<Array<FolderTypeEnum>>().def([])
}

/** 参数类型 **/
export type KFolderSelectorFormPropType = ViewPropsTypeExtract<typeof KFolderSelectorFormPropOptions>

/** 事件 */
export const KFolderSelectorFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KFolderSelectorFormEmitsType = ViewEmitsTypeExtract<typeof KFolderSelectorFormEventEmits>

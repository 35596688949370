import { EmitHandler, eventEmitter } from '@kmsoft/upf-core'
import { ComponentInternalInstance, getCurrentInstance, onMounted, onUnmounted } from 'vue'

/**
 * 监听全局事件
 * @param eventName 事件名称
 * @param Handler 事件回调
 * @returns
 */
export const onEventEmit = (eventName: string, Handler: EmitHandler) => {
  /** 获取当前实例 */
  const instance = getCurrentInstance()

  if (!instance) {
    console.warn(
      `onEventEmit is called when there is no active component instance to be associated with. Lifecycle injection APIs can only be used during execution of setup(). If you are using async setup(), make sure to register lifecycle hooks before the first await statement.`
    )
    return
  }

  // 监听事件
  onMounted(() => {
    eventEmitter.on(eventName, Handler)
  })

  // 移除时取消监听
  onUnmounted(() => {
    eventEmitter.off(eventName, Handler)
  })
}

const getGlobalTab = (instance: ComponentInternalInstance): ComponentInternalInstance | undefined => {
  if (instance.attrs.class == 'global-tabs-pane') {
    return instance
  }

  if (instance.parent) {
    return getGlobalTab(instance.parent)
  }

  return undefined
}

import { Api, EnumRequestCode, FolderTypeEnum, EnumPurviewCode } from '@kmsoft/ebf-common'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  EnumToolStripCompType,
  KNotification,
  KTreeNode,
  ToolStripButtonItem,
  ToolStripItem,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { UserClientSrv } from '../../../client-srv'
import { EnumToolStripItemKeys, FolderTreeNode, IFolder } from '../../folder-manage/folder-tree/interface'

/** 参数 **/
export const KProjectWorkspacePropOptions = {
  ...BaseViewPropOptions,
  /** 工作区Id */
  workspaceId: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KProjectWorkspacePropType = ViewPropsTypeExtract<typeof KProjectWorkspacePropOptions>

/** 事件 */
export const KProjectWorkspaceEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KProjectWorkspaceEmitsType = ViewEmitsTypeExtract<typeof KProjectWorkspaceEventEmits>

/**
 * 文件夹目录树根节点定义
 */
export class ProjectRootTreeNode extends KTreeNode {
  /**
   * 图标
   */
  iconType: string

  /**
   * 是否是单节点
   */

  isSingleRoot: boolean
  /**
   * 编码
   */
  nameEn: string
  /**
   * 类型
   */
  type: string

  constructor(parentNode?: IFolder) {
    if (parentNode) {
      super()
      this.id = parentNode.id
      this.name = parentNode.name
      this.leaf = parentNode.leafFlag
      this.iconType = 'home'
      this.isSingleRoot = true
      this.nameEn = parentNode.nameEn
      this.type = parentNode.type
    } else {
      super()
      this.id = '__root__'
      this.name = '全局'
      this.leaf = false
      this.iconType = 'home'
    }
  }

  async populateChildren(populateType: number): Promise<Array<KTreeNode>> {
    const newChild = [] as Array<KTreeNode>
    const param = {
      data: [
        this.id,
        {
          rdmPageVO: {
            maxPageSize: 1000,
            curPage: 1,
            pageSize: 1000,
            totalRows: 0,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0
          }
        }
      ]
    }
    const result = await Api.post('folder', 'Folder', 'listChildFolder', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      for (const item of result.data!) {
        switch (item.type) {
          case FolderTypeEnum.PROJECT_INITIATION:
          case FolderTypeEnum.PROJECT_REFERENCE:
          case FolderTypeEnum.PROJECT_DELIVERABLE: {
            const workspaceChildNode = new ProjectPredefinedFolder(item as any)
            newChild.push(workspaceChildNode)
            break
          }
          default: {
            const workspaceChildNode = new FolderTreeNode(item as any)
            newChild.push(workspaceChildNode)
            break
          }
        }
      }
    } else {
      KNotification.error({
        title: '获取子节点失败',
        content: result.message!,
        details: result.detail!
      })
    }
    return newChild
  }
  /**
   * 获取右键菜单
   * @returns 菜单列表
   */
  getContextMenus(): Array<ToolStripItem> {
    const workSpaceRootToolStripItem = [
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.NEW,
        /** 文字 */
        title: '新建',
        /** 是否可见  */
        visible: true,
        purviewId: EnumPurviewCode.CreateFolder,
        icon: 'folder-add',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.REFRESH,
        /** 文字 */
        title: '刷新',
        /** 是否可见  */
        visible: true,
        icon: 'loading-3-quarters',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem
    ] as Array<ToolStripItem & { purviewId?: string }>
    return workSpaceRootToolStripItem.filter(item => !item.purviewId || UserClientSrv.verifyPurview(item.purviewId))
  }
}

export class ProjectPredefinedFolder extends KTreeNode {
  /**
   * 编码
   */
  nameEn: string
  /**
   * 图标
   */
  iconType: string
  /**
   * 备注
   */
  description: string
  /**
   * 创建人id
   */
  creator: string

  /***
   * 创建时间
   */
  createTime: string

  /**
   * 类型
   */
  type: string

  /**
   * 父id
   */
  parentId: string

  constructor(data: IFolder) {
    super()
    this.id = data.id
    this.nameEn = data.nameEn
    this.name = data.name
    this.leaf = data.leafFlag
    this.iconType = 'folder'
    this.description = data.description
    this.createTime = data.createTime
    this.creator = data.creator
    this.type = data.type
    this.parentId = data.parentId
  }
  async populateChildren(populateType: number): Promise<Array<KTreeNode>> {
    const newChild = [] as Array<KTreeNode>
    if (this.leaf) {
      return newChild
    }
    const param = {
      data: [
        this.id,
        {
          rdmPageVO: {
            maxPageSize: 1000,
            curPage: 1,
            pageSize: 1000,
            totalRows: 0,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0
          }
        }
      ]
    }
    const result = await Api.post('folder', 'Folder', 'listChildFolder', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      for (const item of result.data!) {
        item.rootNodeType = this.type
        const childNode = new FolderTreeNode(item as any)
        newChild.push(childNode)
      }
    }
    return newChild
  }
  /**
   * 获取右键菜单
   * @returns 菜单列表
   */
  getContextMenus(): Array<ToolStripItem> {
    const workSpaceRootToolStripItem = [
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.NEW,
        /** 文字 */
        title: '新建',
        /** 是否可见  */
        visible: true,
        purviewId: EnumPurviewCode.CreateFolder,
        icon: 'folder-add',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.EDIT,
        /** 文字 */
        title: '属性',
        /** 是否可见  */
        visible: true,
        purviewId: EnumPurviewCode.ModifyFolder,
        icon: 'compute-prop',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.REFRESH,
        /** 文字 */
        title: '刷新',
        /** 是否可见  */
        visible: true,
        icon: 'loading-3-quarters',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem
    ] as Array<ToolStripItem & { purviewId?: string }>
    return workSpaceRootToolStripItem.filter(item => !item.purviewId || UserClientSrv.verifyPurview(item.purviewId))
  }
}

import { EnumToolStripCompType } from '@kmsoft/upf-core'
import {
  toolStripItemAdd,
  toolStripItemDelete,
  toolStripItemEdit,
  toolStripItemNew,
  toolStripItemRemove,
  toolStripItemSave
} from './base'
import { EnumToolStripItemKeys } from './types'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../object-tool-strip'

//新建
export const toolStripItemCreateObjRelation: ObjectToolStripItem = {
  ...toolStripItemNew,
  visible: true
}

//添加
export const toolStripItemAddObjRelation: ObjectToolStripItem = {
  ...toolStripItemAdd,
  name: EnumToolStripItemKeys.TOOL_STRIP_ADD_RELATION_OBJ,
  visible: true
}

//编辑
export const toolStripItemEditObjRelation: ObjectToolStripItem = {
  ...toolStripItemEdit,
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT,
  visible: true
}

//保存
export const toolStripItemSaveObjRelation: ObjectToolStripItem = {
  ...toolStripItemSave,
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE,
  visible: false
}

//移除
export const toolStripItemRemoveObjRelation: ObjectToolStripItem = {
  ...toolStripItemRemove,
  name: EnumToolStripItemKeys.TOOL_STRIP_REMOVE_RELATION_OBJ,
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
  visible: true
}

//删除
export const toolStripItemDeleteObjRelation: ObjectToolStripItem = {
  ...toolStripItemDelete,
  name: EnumToolStripItemKeys.TOOL_STRIP_DELETE_RELATION_OBJ,
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
  visible: true
}

//更换
export const toolStripItemReplaceObjRelation: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIPC_CHANGE_RELATION_OBJ,
  title: '更换',
  icon: 'swap',
  disabled: false,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  visible: true
}

//转换为指定关联
export const toolStripItemChangeRelationObjToAppoint: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_CHANGE_RELATION_OBJ_TO_APPOINT,
  title: '转换为指定关联',
  icon: 'swap',
  disabled: false,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
  visible: true
}

//设为主描述关系
export const toolStripItemSetMainDescObj: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_SET_MAIN_DESC_OBJ,
  title: '设为主描述关系',
  icon: 'swap',
  disabled: false,
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjNumMode: EnumSupportObjectNumMode.Single
}

//关联列表按钮
export const getRelationToolStrip = (): Array<ObjectToolStripItem> => [
  toolStripItemCreateObjRelation,
  toolStripItemAddObjRelation,
  toolStripItemRemoveObjRelation,
  toolStripItemDeleteObjRelation,
  toolStripItemEditObjRelation,
  toolStripItemSaveObjRelation
  // toolStripItemReplaceObjRelation,
  // toolStripItemChangeRelationObjToAppoint
]

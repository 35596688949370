import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

export interface GridDiffDataInter {
  id?: string
  path?: string
  compareResult?: string
  sourceData?: string
  targetData?: string
}

/** 参数 **/
export const KChangeActivityUpdateCompareGridDiffPropOptions = {
  ...BaseViewPropOptions,
  sourceTitle: VuePropTypes.string().def(''),
  targetTitle: VuePropTypes.string().def(''),
  dataSource: VuePropTypes.createType<GridDiffDataInter[]>().def([])
}

/** 参数类型 **/
export type KChangeActivityUpdateCompareGridDiffPropType = ViewPropsTypeExtract<
  typeof KChangeActivityUpdateCompareGridDiffPropOptions
>

/** 事件 */
export const KChangeActivityUpdateCompareGridDiffEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangeActivityUpdateCompareGridDiffEmitsType = ViewEmitsTypeExtract<
  typeof KChangeActivityUpdateCompareGridDiffEventEmits
>

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KObjectClassGridEventEmits, KObjectClassGridPropOptions } from '../object-class-grid'
import { KObjectPanelTabEventEmits, KObjectPanelTabPropOptions } from '../object-panel'

/** 参数 **/
export const KObjectVersionGridPropOptions = {
  ...KObjectClassGridPropOptions,
  ...KObjectPanelTabPropOptions,
  /** 是否包含自身 */
  includedSelf: VuePropTypes.bool().def(true)
}

/** 参数类型 **/
export type KObjectVersionGridPropType = ViewPropsTypeExtract<typeof KObjectVersionGridPropOptions>

/** 事件 */
export const KObjectVersionGridEventEmits = {
  ...KObjectClassGridEventEmits,
  ...KObjectPanelTabEventEmits
}

/** 事件类型 **/
export type KObjectVersionGridEmitsType = ViewEmitsTypeExtract<typeof KObjectVersionGridEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KProjectPageWrapperView from './KProjectPageWrapper.vue'
import KProjectPageWrapperViewModel from './KProjectPageWrapperViewModel'

const KProjectPageWrapper = connect(KProjectPageWrapperView, KProjectPageWrapperViewModel)

export default withInstall(KProjectPageWrapper)
export { KProjectPageWrapper, KProjectPageWrapperView, KProjectPageWrapperViewModel }

// 模板生成文件
// export * from './{{folderName}}'

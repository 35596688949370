import { AppContext, IocContainer } from '@kmsoft/upf-core'
import { Doc } from '../beans/Doc'
import { EnumDocToolType } from './enums'

export abstract class DocTool {
  static ClassName = 'DocToll'
  protected static typeSupported: EnumDocToolType

  protected type: EnumDocToolType

  constructor(type: EnumDocToolType) {
    this.type = type
  }
  private static getKey(type: EnumDocToolType, name: string): Symbol {
    return Symbol.for(type.toString() + ':' + name)
  }

  public static register(iocContainer: IocContainer) {
    let type = this.typeSupported
    let flag = 1
    while (type > 0) {
      if (type % 2 > 0) {
        const localFlag = flag
        iocContainer.register(this.getKey(flag, this.ClassName), () => new (this as any)(localFlag))
      }
      type = type >> 1
      flag = flag << 1
    }
  }

  public static getDocTool<T extends DocTool>(type: EnumDocToolType, name?: string): T | undefined {
    return AppContext.current.getIocContainer().getBean<T>(this.getKey(type, name ?? 'DocToolCommon'))
  }

  public abstract execute(doc: Doc): Promise<void>
}


import { defineView } from '@kmsoft/upf-core'
import KFolderManage from '../../folder-manage'
import KElectronicComponentsWorkspaceViewModel from './KElectronicComponentsWorkspaceViewModel'
import { KElectronicComponentsWorkspaceEventEmits, KElectronicComponentsWorkspacePropOptions } from './interface'

export default defineView({
  name: 'KElectronicComponentsWorkspace',
  props: KElectronicComponentsWorkspacePropOptions,
  emits: KElectronicComponentsWorkspaceEventEmits,
  components: { KFolderManage }, // 这里定义局部组件
  viewModel: KElectronicComponentsWorkspaceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

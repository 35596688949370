import { connect, withInstall } from '@kmsoft/upf-core'
import KDocStructureListView from './KDocStructureList.vue'
import KDocStructureListViewModel from './KDocStructureListViewModel'

const KDocStructureList = connect(KDocStructureListView, KDocStructureListViewModel)

export default withInstall(KDocStructureList)
export { KDocStructureList, KDocStructureListView, KDocStructureListViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { AgentInstanceObject, AgentStaticObject } from '@kmsoft/ebf-common/src/client-agent'
import { EnumExApp } from './EnumExApp'

export class DpMsgClientSrv extends AgentInstanceObject {
  protected static type: string = 'KMSoft.Components.Dp.DpMsgClientSrv,KMSoft.Components.Dp'

  /**
   * 请求代理向Cad程序发送消息
   */
  public static async Send(argMsgDestApp: EnumExApp, argMsgId: string, argMsgParas: string, mode: number): Promise<boolean> {
    return this.invokeStaticMethod('Send', argMsgDestApp, argMsgId, argMsgParas, mode)
  }

  /**
   * 设置token
   */
  public static async Token(token: string = AgentStaticObject.None): Promise<string> {
    return this.staticProperty('Token', token)
  }
}

import { AgentStaticObject } from '../../AgentStaticObject'

import { Encoding } from '../text/Encoding'

/**提供用于创建、复制、删除、移动和打开单一文件的静态方法，并协助创建 FileStream 对象。*/
export class File extends AgentStaticObject {
  protected static type: string = 'System.IO.File,System.Runtime'

  /**确定指定的文件是否存在。
   * @param path 要检查的文件。
   * @returns
   * 如果调用方具有要求的权限并且 true 包含现有文件的名称，则为 {@link path}；否则为 false。
   * 如果 {@link path} 为 null（一个无效路径或零长度字符串）,则此方法也将返回 false。
   * 如果调用方不具有读取指定文件所需的足够权限，则不引发异常并且该方法返回 false，这与 {@link path} 是否存在无关。*/
  public static async Exists(path?: string): Promise<boolean> {
    return this.invokeStaticMethod('Exists', path)
  }

  /**打开一个文本文件，读取文件中的所有文本，然后关闭此文件。
   * @param path 要打开以进行读取的文件。
   * @returns 包含文件中所有文本的字符串。*/
  public static async ReadAllText(path: string): Promise<string>
  /**打开一个文件，使用指定的编码读取文件中的所有文本，然后关闭此文件。
   * @param path 要打开以进行读取的文件。
   * @param encoding 应用到文件内容的编码。
   * @returns 包含文件中所有文本的字符串。*/
  public static async ReadAllText(path: string, encoding?: Encoding): Promise<string>
  public static async ReadAllText(path: string, encoding?: Encoding): Promise<string> {
    return encoding ? this.invokeStaticMethod('ReadAllText', path, encoding) : this.invokeStaticMethod('ReadAllText', path)
  }

  /**创建一个新文件，向其中写入指定的字符串，然后关闭文件。 如果目标文件已存在，则会将其截断并覆盖。
   * @param path 要写入的文件。
   * @param contents 要写入文件的字符串。
   * @returns */
  public static async WriteAllText(path: string, contents: string): Promise<void>
  /**创建一个新文件，使用指定编码向其中写入指定的字符串，然后关闭文件。 如果目标文件已存在，则会将其截断并覆盖。
   * @param path 要写入的文件。
   * @param contents 要写入文件的字符串。
   * @param encoding 应用于字符串的编码。
   * @returns */
  public static async WriteAllText(path: string, contents: string, encoding?: Encoding): Promise<void>
  public static async WriteAllText(path: string, contents: string, encoding?: Encoding): Promise<void> {
    return encoding
      ? this.invokeStaticMethod('WriteAllText', path, contents, encoding)
      : this.invokeStaticMethod('WriteAllText', path, contents)
  }

  /**打开一个二进制文件，将文件的内容读入一个字节数组，然后关闭该文件。
   * @param path 要打开以进行读取的文件。
   * @returns 包含文件内容的字节数组。*/
  public static async ReadAllBytes(path: string): Promise<Blob> {
    return this.fromBase64String(await this.invokeStaticMethod('ReadAllBytes', path))
  }

  /**创建一个新文件，在其中写入指定的字节数组，然后关闭该文件。 如果目标文件已存在，则会将其截断并覆盖。
   * @param path 要写入的文件。
   * @param bytes 要写入文件的字节。
   * @returns */
  public static async WriteAllBytes(path: string, bytes: Blob): Promise<void> {
    return this.invokeStaticMethod('WriteAllBytes', path, await this.toBase64String(bytes))
  }
}

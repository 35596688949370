import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KAgentMonitorEmitsType, KAgentMonitorPropType } from './interface'
import { computed, ref } from 'vue'

/** KAgentMonitor */
export default class KAgentMonitorViewModel extends BaseViewModel<KAgentMonitorEmitsType, KAgentMonitorPropType> {
  //#region  私有方法
  checkInterval = ref(10)
  webSocketId = ref('')
  agentStatus = ref('')
  constructor(options: ViewModelOptions<KAgentMonitorPropType>) {
    super(options)
  }

  viewDidMount() {}
}

import { IKTreeNode } from '@kmsoft/upf-core'
import { BizContextItem } from '../../../../../types'

/**
 * 将脚本信息转换为节点信息
 * @param item 脚本信息
 * @returns 节点
 */
export const convertContextItemToNode = (item: BizContextItem): IKTreeNode => {
  return {
    id: item.name,
    name: item.name,
    contextItem: item
  }
}

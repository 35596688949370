import { KDataElementTree, KDesignerControl } from '../../../../../types'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  IKTreeNode,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** KGridDesignerToolBox 事件 **/
export const KGridDesignerToolBoxEventEmits = {
  ...BaseViewEventEmits,
  hierarchyTreeNodeSelected: (node: IKTreeNode) => true
}

/** KGridDesignerToolBox 参数 **/
export const KGridDesignerToolBoxPropOptions = {
  ...BaseViewPropOptions,
  /** 转换元素格式 */
  convertElement: VuePropTypes.func<(element: KDesignerControl) => any>().def(),
  /** 列类型 */
  metaColumns: VuePropTypes.createType<Array<KDesignerControl>>().def([]),
  /**数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def({} as KDataElementTree)
}

/** KGridDesignerToolBox 参数类型 **/
export type KGridDesignerToolBoxPropType = ViewPropsTypeExtract<typeof KGridDesignerToolBoxPropOptions>

/** KGridDesignerToolBox 事件协议*/

export type KGridDesignerToolBoxEventEmitsType = ViewEmitsTypeExtract<typeof KGridDesignerToolBoxEventEmits>

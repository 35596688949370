import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDocObjectPreviewEmitsType, KDocObjectPreviewPropType } from './interface'
import { ref } from 'vue'

/** KDocObjectPreview */
export default class KDocObjectPreviewViewModel extends BaseViewModel<KDocObjectPreviewEmitsType, KDocObjectPreviewPropType> {
  fileRecord = ref<Record<string, any>>()
  fileIconUrl = ref<string>()

  constructor(options: ViewModelOptions<KDocObjectPreviewPropType>) {
    super(options)
  }

  viewDidMount() {}

  onClickFile() {}
}

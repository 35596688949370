import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineEditView from './KBaselineEdit.vue'
import KBaselineEditViewModel from './KBaselineEditViewModel'

const KBaselineEdit = connect(KBaselineEditView, KBaselineEditViewModel)

export default withInstall(KBaselineEdit)
export { KBaselineEdit, KBaselineEditView, KBaselineEditViewModel }

// 模板生成文件
// export * from './{{folderName}}'

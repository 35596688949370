import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KPartDocBomCompareModeFormEmitsType, KPartDocBomCompareModeFormPropType } from './interface'
import { ref } from 'vue'

/** KPartDocBomCompareModeForm */
export default class KPartDocBomCompareModeFormViewModel extends BaseViewModel<
  KPartDocBomCompareModeFormEmitsType,
  KPartDocBomCompareModeFormPropType
> {
  /**
   * 差异件是否展开
   */
  expandDifferentialParts = ref<boolean>(false)
  /**
   * 忽略分支节点
   */
  ignoringBranchNodes = ref<boolean>(false)
  /**
   * 是否在新标签页显示比较结果
   */
  openNewTabShowResult = ref<boolean>(false)

  constructor(options: ViewModelOptions<KPartDocBomCompareModeFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 获取表单值
   */
  getFormValue() {
    return {
      comparisonModeName: '多层比较',
      expandDifferentialParts: this.expandDifferentialParts.value,
      openNewTabShowResult: this.openNewTabShowResult.value
    }
  }
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { KObjectCreatePanelEmitsType, KObjectCreatePanelPropType } from './interface'
import { KObjectCreatePanelFormBaseViewModel } from './controls'
import { EnumObjCopyMode, ObjectClientSrv } from '../../client-srv'

/** 导航管理 */
export default class KObjectCreatePanelViewModel extends BaseViewModel<KObjectCreatePanelEmitsType, KObjectCreatePanelPropType> {
  /** 表单引用 */
  refForm = ref<KObjectCreatePanelFormBaseViewModel>()
  /** 对象类Id */
  modelCode = ref<string>('')
  /** 对象类组 */
  modelGroup = ref<string>('')

  /** 对象Id */
  objId = ref<string>('')
  /** 创建对象类型 */
  objCopyMode = ref<EnumObjCopyMode>(EnumObjCopyMode.NewObj)
  /** 表单组件 */
  formComponent = ref<string>()
  /** 是否正在加载 */
  isLoading = ref<boolean>()

  constructor(options: ViewModelOptions<KObjectCreatePanelPropType>) {
    super(options)
    this.modelCode.value = options.props.modelCode || ''
    this.modelGroup.value = options.props.modelGroup || ''
    this.objCopyMode.value = options.props.objCopyMode
    this.objId.value = options.props.objId || ''

    watch(
      this.modelCode,
      () => {
        // 初始化
        this.init()
      },
      { immediate: true }
    )
  }

  viewDidMount() {}

  async init() {
    this.isLoading.value = true

    const formComponent = await ObjectClientSrv.getObjectCreateForm(this.modelCode.value)
    this.formComponent.value = formComponent

    this.isLoading.value = false
  }

  /** 刷新 */
  async refresh() {
    return this.refForm.value?.refresh()
  }

  /** 保存 */
  async save() {
    return this.refForm.value?.save()
  }

  /**
   * 获取保存选项
   * @returns
   */
  getOption() {
    return this.refForm.value?.getOption()
  }
  getValue() {
    return this.refForm.value?.getValue()
  }

  /**
   * 
   * @returns 获取修改值
   */
  getModifiedValue() {
    return this.refForm.value?.getModifiedValue()
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KChangeActivityEditPropOptions, KChangeActivityEditEventEmits } from './interface'
import KChangeActivityEditViewModel from './KChangeActivityEditViewModel'
import { readonly } from 'vue'

export default defineView({
  name: 'KChangeActivityEdit',
  props: KChangeActivityEditPropOptions,
  emits: KChangeActivityEditEventEmits,
  viewModel: KChangeActivityEditViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { KAttributeValueItem } from '../../../../../types'
import { ControlCollection, KDataElementTree, KDesignerControl } from '../../../../../types/metadata'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  EnumDataType,
  EnumType,
  LayoutRenderElement,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** KViewPropertyConfigPanel 事件 **/
export const KViewPropertyConfigPanelEventEmits = {
  ...BaseViewEventEmits,
  elementPropertyChange: (configItemName: string, type: string, value: any) => true
}

/** KViewPropertyConfigPanel 参数 **/
export const KViewPropertyConfigPanelPropOptions = {
  ...BaseViewPropOptions,
  /** 控件信息 */
  control: VuePropTypes.createType<KDesignerControl>()
    .setRequired()
    .def(),
  /** 当前元素 */
  element: VuePropTypes.createType<LayoutRenderElement>()
    .setRequired()
    .def(),
  /** 控件元数据 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def([]),
  /** 控件属性的值集合 */
  attributeValueItem: VuePropTypes.createType<KAttributeValueItem>().def({} as KAttributeValueItem),
  /** 数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def()
}

/** KViewPropertyConfigPanel 参数类型 **/
export type KViewPropertyConfigPanelPropType = ViewPropsTypeExtract<typeof KViewPropertyConfigPanelPropOptions>

/** KViewPropertyConfigPanel 事件协议*/
export type KViewPropertyConfigPanelEventEmitsTypes = ViewEmitsTypeExtract<typeof KViewPropertyConfigPanelEventEmits>

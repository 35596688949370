import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%","padding":"5px","display":"flex","flex-direction":"column"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_config_object_grid = _resolveComponent("k-config-object-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$vm.dyComponent && _ctx.$vm.editType === _ctx.EnumEditType.CUSTOM)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$vm.dyComponent), { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$vm.editType === _ctx.EnumEditType.OBJ)
      ? (_openBlock(), _createBlock(_component_k_config_object_grid, {
          key: 1,
          ref: "refConfigObjectGrid"
        }, null, 512))
      : _createCommentVNode("", true)
  ]))
}
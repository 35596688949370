import { connect, withInstall } from '@kmsoft/upf-core'
import KBottomBarView from './KBottomBar.vue'
import KBottomBarViewModel from './KBottomBarViewModel'

const KBottomBar = connect(KBottomBarView, KBottomBarViewModel)

export default withInstall(KBottomBar)
export { KBottomBar, KBottomBarView, KBottomBarViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KDocBatchCreateBasePropOptions, KDocBatchCreateBaseEmitsOptions } from '../interface'

/** 参数 **/
export const KDocBatchImportPropOptions = {
  ...BaseViewPropOptions,
  ...KDocBatchCreateBasePropOptions
}

/** 参数类型 **/
export type KDocBatchImportPropType = ViewPropsTypeExtract<typeof KDocBatchImportPropOptions>

/** 事件 */
export const KDocBatchImportEventEmits = {
  ...BaseViewEventEmits,
  ...KDocBatchCreateBaseEmitsOptions
}

/** 事件类型 **/
export type KDocBatchImportEmitsType = ViewEmitsTypeExtract<typeof KDocBatchImportEventEmits>

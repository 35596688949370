/** 编辑器环境 */
export enum EnumEditorEnvironment {
  /** 网格 */
  DATA_GRID = 1,
  /** 属性面板 */
  PROPERTY_PANEL = 2,
  /** 过滤器 */
  FILTER = 3
}

/** 文件类别 */
export enum EnumFileCategory {
  /** 文件属性 */
  FileProperty = 1,
  /** 文档主文件 */
  DocMain = 2,
  /** 文档附件 */
  DocAttach = 3,
  /**临时文件 */
  TempFile = 4
}


import { EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import { KDocAnnotationListPanelPropOptions, KDocAnnotationListPanelEventEmits } from './interface'
import KDocAnnotationListPanelViewModel from './KDocAnnotationListPanelViewModel'

export default defineView({
  name: 'KDocAnnotationListPanel',
  props: KDocAnnotationListPanelPropOptions,
  emits: KDocAnnotationListPanelEventEmits,
  viewModel: KDocAnnotationListPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import lodash from 'lodash'
// import { MetaRegionSrv } from './region'
// import { PluginAssembly } from './plugin'
// import { GlobalConfiguration } from './type'
import { RequestHelper } from '../common'
import { AppContext } from '@kmsoft/upf-core'

/** 配置服务类 */
class ConfigSrv {
  /** WebAPI地址 */
  baseApiUrl: string = (window as any).kmsoft.config.mock_baseApiUrl ?? (window as any).kmsoft.config.baseApiUrl
  // /** 模型浏览系统服务地址 */
  // hoopsServer: string = (window as any).kmsoft.config.hoopsServer
  /** 项目管理系统服务地址 */
  pmsServer(): string {
    return AppContext.current.getEnvironment().getProperty('server.pmsServer')
  }
  // /**统一登录认证服务地址Url */
  // ssoLoginPageUrl: string = (window as any).kmsoft.config.ssoLoginPageUrl
  // /** 低代码服务器地址 */
  // lowcodeServer: string = (window as any).kmsoft.config.lowcodeServer
  // private __pluginAssemblies: Array<PluginAssembly>
  // private __globalConfiguration: GlobalConfiguration

  // /**
  //  * 初始化配置
  //  */
  // initConfig() {
  //   const globalConfiguration = this.getGlobalConfiguration()
  //   const plugins = this.getEnabledPlugins()
  //   return { globalConfiguration: globalConfiguration, plugins: plugins }
  // }

  // /** 获取已启用的插件 */
  // getEnabledPlugins(): Array<PluginAssembly> {
  //   if (lodash.isArray(this.__pluginAssemblies)) {
  //     return this.__pluginAssemblies
  //   }

  //   /** 获取默认选中域 */
  //   const regionId = MetaRegionSrv.getDefaultSelectedRegion()
  //   /** 请求参数 */
  //   const reqParams = { regionId: regionId }
  //   /** 获取插件列表 */
  //   const result = RequestHelper.post<Array<PluginAssembly>>('api/Config/GetAssemble', reqParams)

  //   configSrv.__pluginAssemblies = result

  //   return this.__pluginAssemblies
  // }

  // /** 获取已启用的插件 */
  // getGlobalConfiguration(): GlobalConfiguration {
  //   if (this.__globalConfiguration) return this.__globalConfiguration

  //   let xhr = new XMLHttpRequest()
  //   xhr.open('post', this.baseApiUrl + (this.baseApiUrl.endsWith('/') ? '' : '/') + 'api/Common/GetGlobalConfiguration', false)
  //   xhr.onload = e => {
  //     configSrv.__globalConfiguration = JSON.parse((e.currentTarget as any).responseText!).data
  //   }
  //   xhr.send()

  //   return this.__globalConfiguration
  // }

  // /** 是否启用特定插件的特定功能 */
  // isPluginEnabled(assembleName: string): boolean {
  //   if (!assembleName) return false
  //   const pluginAssemblies = this.getEnabledPlugins()
  //   //插件名称比较忽略大小写
  //   const assembly = pluginAssemblies.find(p => p.assembleName && p.assembleName.toUpperCase() === assembleName.toUpperCase())
  //   if (assembly) {
  //     return true
  //   }
  //   return false
  // }
}

export const configSrv = new ConfigSrv()

import {
  BaseViewModel,
  EnumDialogResult,
  EnumDialogSize,
  KLayoutElementEvent,
  KDialogClosingEvent,
  ViewModelOptions,
  utils
} from '@kmsoft/upf-core'
import { KViewEventConfigPropType, KViewEventConfigEventEmitsType } from './interface'
import { ref, watch } from 'vue'
import { KDialog } from '@kmsoft/upf-core'
import { BizContextItem } from '../../../../../types'
import KViewEventEditPanel from './view-event-edit-panel'
import KViewEventEditPanelViewModel from './view-event-edit-panel/KViewEventEditPanelViewModel'

/** 导航管理 */
export default class KViewEventConfigViewModel extends BaseViewModel<KViewEventConfigEventEmitsType, KViewEventConfigPropType> {
  /** 脚本编辑引用 */
  refViewEventEditPanel = ref<any>()
  /** 当前编辑的事件 */
  currentEditEvent = ref<string>()
  /**事件脚本 */
  currentScript = ref<string>('')
  /**事件值集合 */
  currentEventValueItem = ref<Array<KLayoutElementEvent>>(this.props.eventValueItem!)
  /**显示标记 */
  showTag = ref(false)
  constructor(options: ViewModelOptions<KViewEventConfigPropType>) {
    super(options)

    watch(
      () => options.props.eventValueItem,
      (newVal: any) => {
        this.currentEventValueItem.value = newVal
      }
    )
  }

  /**
   * 编辑事件脚本
   * @param eventName 事件名称
   */
  editScript = (eventName: string) => {
    const _self = this
    const scriptInfo = this.currentEventValueItem?.value.find(x => x.name === eventName)
    if (scriptInfo && scriptInfo.code !== '') {
      this.currentScript.value = scriptInfo.code
    } else {
      this.currentScript.value = this.__buildDefaultScript(eventName)
    }
    this.currentEditEvent.value = eventName
    /**打开脚本编辑器 */
    KDialog.show({
      title: '脚本编辑',
      size: { width: 900, height: 552 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KViewEventEditPanel,
      props: { script: this.currentScript.value, loadContext: this.__analysisBizContext },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult === EnumDialogResult.Cancel) return
        const vm = event.viewModel as KViewEventEditPanelViewModel
        const value = vm.getValue()
        if (!value || !_self.currentEditEvent.value) {
          return
        }
        _self.emit('elementPropertyChange', _self.currentEditEvent.value, value)
      }
    })
  }
  /**解析业务上下文 */
  __analysisBizContext = () => {
    const bizContext = this.props.bizContext
    if (utils.isFunction(bizContext)) {
      const context = bizContext()
      return context.contextItemInfos
    }
    return [] as Array<BizContextItem>
  }
  /**
   * 构建默认脚本
   * @param eventName 事件名称
   */
  __buildDefaultScript = (eventName: string) => {
    const script = `viewModel.on('${eventName}', function (args) {

})`
    return script
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KnavigationStyleTestPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KnavigationStyleTestPropType = ViewPropsTypeExtract<typeof KnavigationStyleTestPropOptions>

/** 事件 */
export const KnavigationStyleTestEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KnavigationStyleTestEmitsType = ViewEmitsTypeExtract<typeof KnavigationStyleTestEventEmits>

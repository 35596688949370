import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KIssueReportEditPropOptions = {
  ...BaseViewPropOptions,
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KIssueReportEditPropType = ViewPropsTypeExtract<typeof KIssueReportEditPropOptions>

/** 事件 */
export const KIssueReportEditEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KIssueReportEditEmitsType = ViewEmitsTypeExtract<typeof KIssueReportEditEventEmits>

import { ObjBusinessParam } from '@kmsoft/ebf-common'
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KDocFileListEmitsType, KDocFileListPropType } from './interface'

/** KDocFileList */
export default class KDocFileListViewModel extends BaseViewModel<KDocFileListEmitsType, KDocFileListPropType> {
  constructor(options: ViewModelOptions<KDocFileListPropType>) {
    super(options)
  }
  docParams = ref<ObjBusinessParam>()
  modelCode = ref<string>('DocAttachFile')

  viewDidMount() {}

  // //#region  私有方法
  // async loadData() {
  //   if (!this.docParams.value) return

  //   // const queryModel: ObjectClassGridQueryParams = {
  //   //   modelCode: this.modelCode.value,
  //   //   filters: [
  //   //     {
  //   //       value: this.docParams.value.id,
  //   //       operator: '=',
  //   //       key: 'mainDocId'
  //   //     },
  //   //     {
  //   //       value: this.docParams.value.modelCode,
  //   //       operator: '=',
  //   //       key: 'mainDocClsCode'
  //   //     }
  //   //   ],
  //   //   page: {
  //   //     pageIndex: 1,
  //   //     pageSize: 100000
  //   //   }
  //   // }

  //   const queryModel = {
  //     filter: {
  //       joiner: EnumQueryConditionJoiner.And,
  //       conditions: [
  //         {
  //           conditionName: 'mainDocId',
  //           operator: EnumQueryConditionOperator.EQUAL,
  //           conditionValues: [this.docParams.value.id]
  //         },
  //         {
  //           conditionValues: [this.docParams.value.modelCode],
  //           operator: EnumQueryConditionOperator.EQUAL,
  //           conditionName: 'mainDocClsCode'
  //         }
  //       ]
  //     },

  //     page: {
  //       pageIndex: 1,
  //       pageSize: 100000
  //     }
  //   }

  //   const result = await ObjectClientSrv.listObjects(queryModel)
  //   if (!result || !result.rows || result.rows.length < 1) return
  //   const query: objectManagement.ListObjectsRequest = {
  //     object: {
  //       modelCode: this.modelCode.value,
  //       filter: {
  //         conditionValues: result.rows.map(a => a.childId),
  //         operator: 'in',
  //         conditionName: 'id'
  //       }
  //     }
  //   }
  //   const resultList = await objectManagement.listObjects(query)

  //   // if (resultList && resultList.isSuccess) {
  //   //   return {
  //   //     rows: resultList.data!.rows,
  //   //     total: resultList.data!.totalRows || 0
  //   //   }
  //   // } else {
  //   //   return {
  //   //     total: 0,
  //   //     rows: []
  //   //   }
  //   // }
  // }
}

import { EnumDataType } from '../../../../../client-srv'
import { EnumFilterLogicalOperation, EnumFilterOperatorCode, RuleResult } from '../interface'

export interface ISearchFilterTree {
  readonly isLeaf: boolean
  /**
   * 合并条件
   * @param rhs
   */
  and(rhs: ISearchFilterTree): ISearchFilterTree
  /**
   * 或
   * @param rhs
   */
  or(rhs: ISearchFilterTree): ISearchFilterTree
  /**
   * 转过滤条件
   * @param counter
   */
  toAdvancedFilter(counter: { index: number }): RuleResult
  /**
   * 通过数据数据过滤
   * @param record
   * @param isIgnoreCase
   */
  test(record: Record<string, any>, isIgnoreCase?: boolean): boolean
}

export class SearchFilterTreeLeaf implements ISearchFilterTree {
  readonly isLeaf = true
  /** 操作符 */
  operator: string
  /** 值 */
  value: string
  /** 键 */
  key: string
  /**是否区分大小写 */
  unignoreCase: boolean
  /** 数据类型 */
  type: EnumDataType

  /**
   *
   * @param key
   * @param operator
   * @param value
   * @param type
   * @param unignoreCase 是否区分大小写
   */
  constructor(key: string, operator: EnumFilterOperatorCode, value: string, type: EnumDataType, unignoreCase: boolean = false) {
    this.key = key
    this.operator = operator
    this.value = value
    this.type = type || EnumDataType.STRING
    this.unignoreCase = unignoreCase
  }

  toAdvancedFilter(counter: { index: number } = { index: 1 }): RuleResult {
    const filter = {
      expression: counter.index.toString(),
      rules: [
        {
          key: this.key,
          value: this.value,
          operator: this.operator
        }
      ]
    } as RuleResult
    counter.index += 1
    return filter
  }

  and(rhs: ISearchFilterTree): SearchFilterTreeCon {
    return new SearchFilterTreeCon(EnumFilterLogicalOperation.And, [this, rhs])
  }

  or(rhs: ISearchFilterTree): SearchFilterTreeCon {
    return new SearchFilterTreeCon(EnumFilterLogicalOperation.Or, [this, rhs])
  }

  /**
   * 验证
   * @param record
   * @param isUnignoreCase
   * @returns
   */
  test(record: Record<string, any>, isUnignoreCase?: boolean): boolean {
    if (isUnignoreCase === undefined) isUnignoreCase = this.unignoreCase
    let value = record[this.key]
    const type = this.type

    if (this.value === '<%NULL%>') {
      return value === null
    }

    if (typeof value === 'string' && (type === EnumDataType.CHAR || type === EnumDataType.STRING)) {
      let thisValue = this.value
      if (!isUnignoreCase) {
        value = value.toUpperCase()
        thisValue = thisValue.toUpperCase()
      }
      switch (this.operator) {
        case EnumFilterOperatorCode.STARTWITH:
          return value.startsWith(thisValue)
        case EnumFilterOperatorCode.ENDWITH:
          return value.endsWith(thisValue)

        case EnumFilterOperatorCode.LIKE:
          return value.includes(thisValue)
        case EnumFilterOperatorCode.EQUAL:
          return thisValue === value
        case EnumFilterOperatorCode.NOT_EQUAL:
          return thisValue !== value
        // case EnumFilterOperatorCode.NOT_STARTWITH:
        //   return !value.startsWith(thisValue)
        // case EnumFilterOperatorCode.NOT_ENDWITH:
        //   return !value.endsWith(thisValue)
        // case EnumFilterOperatorCode.NOT_INCLUDE:
        //   return !value.includes(thisValue)
        default:
          return false
      }
    }

    if (typeof value === 'string' && type === EnumDataType.DATE) {
      const datetime = new Date(value)
      const filterDatetime = new Date(this.value)
      switch (this.operator) {
        case EnumFilterOperatorCode.GREATER:
          return filterDatetime < datetime
        case EnumFilterOperatorCode.LESS:
          return filterDatetime > datetime
        default:
          return false
      }
    }

    if (typeof value === 'number' && type === EnumDataType.INT) {
      const filterValue = Number.parseInt(this.value)
      switch (this.operator) {
        case EnumFilterOperatorCode.GREATER:
          return value > filterValue
        case EnumFilterOperatorCode.LESS:
          return value < filterValue
        case EnumFilterOperatorCode.EQUAL:
          return value === filterValue
        case EnumFilterOperatorCode.NOT_EQUAL:
          return value !== filterValue
        case EnumFilterOperatorCode.GREATER_EQUAL:
          return value >= filterValue
        case EnumFilterOperatorCode.LESS_EQUAL:
          return value <= filterValue
        default:
          return false
      }
    }

    if (typeof value === 'number' && type === EnumDataType.FLOAT) {
      const filterValue = Number.parseFloat(this.value)
      switch (this.operator) {
        case EnumFilterOperatorCode.GREATER:
          return value > filterValue
        case EnumFilterOperatorCode.LESS:
          return value < filterValue
        case EnumFilterOperatorCode.EQUAL:
          return value === filterValue
        case EnumFilterOperatorCode.NOT_EQUAL:
          return value !== filterValue
        case EnumFilterOperatorCode.GREATER_EQUAL:
          return value >= filterValue
        case EnumFilterOperatorCode.LESS_EQUAL:
          return value <= filterValue
        default:
          return false
      }
    }
    return false
  }

  isEqual(rhs: SearchFilterTreeLeaf): boolean {
    return (
      this.key === rhs.key && this.operator === rhs.operator && this.value === rhs.value && this.unignoreCase == rhs.unignoreCase
    )
  }
}

class SearchFilterTreeCon implements ISearchFilterTree {
  readonly isLeaf = false
  /** 操作符 */
  boolOp: EnumFilterLogicalOperation
  /** 子 */
  children: ISearchFilterTree[] = []

  constructor(boolOp: EnumFilterLogicalOperation, children: ISearchFilterTree[] = []) {
    this.boolOp = boolOp
    this.children = children
  }

  toAdvancedFilter(counter: { index: number } = { index: 1 }): RuleResult {
    const op = ` ${this.boolOp} `
    const exprssionList: string[] = []
    const rules = []

    for (const child of this.children) {
      const af = child.toAdvancedFilter(counter)
      exprssionList.push(af.expression!)
      rules.push(...af.rules)
    }

    return {
      expression: `(${exprssionList.join(op)})`,
      rules
    }
  }

  and(rhs: ISearchFilterTree): ISearchFilterTree {
    const isOpAnd = this.boolOp == EnumFilterLogicalOperation.And
    if (rhs instanceof SearchFilterTreeCon && rhs.boolOp == EnumFilterLogicalOperation.And) {
      if (isOpAnd) {
        return new SearchFilterTreeCon(EnumFilterLogicalOperation.And, [...this.children, ...rhs.children])
      } else {
        return new SearchFilterTreeCon(EnumFilterLogicalOperation.And, [this, ...rhs.children])
      }
    } else {
      if (isOpAnd) {
        return new SearchFilterTreeCon(EnumFilterLogicalOperation.And, [...this.children, rhs])
      } else {
        return new SearchFilterTreeCon(EnumFilterLogicalOperation.And, [this, rhs])
      }
    }
  }

  or(rhs: ISearchFilterTree): ISearchFilterTree {
    const isOpOr = this.boolOp == EnumFilterLogicalOperation.Or
    if (rhs instanceof SearchFilterTreeCon && rhs.boolOp == EnumFilterLogicalOperation.Or) {
      if (isOpOr) {
        return new SearchFilterTreeCon(EnumFilterLogicalOperation.Or, [...this.children, ...rhs.children])
      } else {
        return new SearchFilterTreeCon(EnumFilterLogicalOperation.Or, [this, ...rhs.children])
      }
    } else {
      if (isOpOr) {
        return new SearchFilterTreeCon(EnumFilterLogicalOperation.Or, [...this.children, rhs])
      } else {
        return new SearchFilterTreeCon(EnumFilterLogicalOperation.Or, [this, rhs])
      }
    }
  }

  test(record: Record<string, any>): boolean {
    if (this.boolOp === EnumFilterLogicalOperation.And) {
      for (const child of this.children) {
        const currentResult = child.test(record)
        if (currentResult) {
          continue
        } else {
          return false
        }
      }
      return true
    } else {
      for (const child of this.children) {
        const currentResult = child.test(record)
        if (currentResult) {
          return true
        } else {
          continue
        }
      }
      return false
    }
  }
}

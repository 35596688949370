import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KBaselineCreateEmitsType, KBaselineCreatePropType } from './interface'
import { ref } from 'vue'
import KBaselineCreateFormViewModel from './baseline-create-form/KBaselineCreateFormViewModel'
import { EnumRequestCode, ObjectClientSrv } from '@kmsoft/ebf-common'

/** KBaselineCreate */
export default class KBaselineCreateViewModel extends BaseViewModel<KBaselineCreateEmitsType, KBaselineCreatePropType> {
  refBaselineCreateForm = ref<KBaselineCreateFormViewModel>()

  constructor(options: ViewModelOptions<KBaselineCreatePropType>) {
    super(options)
  }

  viewDidMount() {}

  async validate() {
    return this.refBaselineCreateForm.value?.validate()
  }

  async save(isHandle: boolean = false) {
    if (isHandle) {
      let result = await this.refBaselineCreateForm.value!.save()
      if (result && result.code == EnumRequestCode.SUCCESS) {
        KNotification.success({
          title: '系统提示',
          content: '保存成功'
        })
      } else {
        KNotification.error({
          title: '保存失败',
          content: result.message!
        })
      }
    } else {
      return this.refBaselineCreateForm.value!.save()
    }
  }

  /*  edit() {
    const param = {
      id: '640232012401086464',
      modelCode: 'ManagedBaseline',
      modelGroup: 'baseline'
    }
    ObjectClientSrv.openObj(param)
  }*/
}

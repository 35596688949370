import {
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  SimpleViewEventEmits,
  BaseViewPropOptions,
  VuePropTypes,
  BaseViewEventEmits,
  SimpleViewPropOptions
} from '@kmsoft/upf-core'
import { readonly } from 'vue'

/** 参数 **/
export const KUserSelectorPropOptions = {
  ...SimpleViewPropOptions,
  /** 选中项（v-model） */
  value: VuePropTypes.createType<string | Array<string>>().def(''),
  /** 是否禁用状态 */
  disabled: VuePropTypes.bool().def(false),
  /** 是否禁用状态 */
  readonly: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KUserSelectorPropType = ViewPropsTypeExtract<typeof KUserSelectorPropOptions>

/** 事件 */
export const KUserSelectorEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KUserSelectorEmitsType = ViewEmitsTypeExtract<typeof KUserSelectorEventEmits>

import { ObjectToolStripItem } from '@kmsoft/ebf-common'
import { EnumToolStripCompType } from '@kmsoft/upf-core'

export const folderObjOperationToolStripItems: Array<ObjectToolStripItem> = [
  {
    name: 'importObject',
    title: '引入对象',
    icon: 'plus-circle',
    visible: true,
    compType: EnumToolStripCompType.BUTTON
  },
  {
    name: 'removeObjects',
    title: '移除对象',
    icon: 'minus-circle',
    visible: true,
    compType: EnumToolStripCompType.BUTTON
  }
]

import { BaseViewModel, KFormViewModel, KNotification, SelectOption, ViewModelOptions } from '@kmsoft/upf-core'
import { KTaskEvaluateEmitsType, KTaskEvaluatePropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** KTaskEvaluate */
export default class KTaskEvaluateViewModel extends BaseViewModel<KTaskEvaluateEmitsType, KTaskEvaluatePropType> {
  /**表单viewModel */
  refForm = ref<KFormViewModel>()
  /**表单数据 */
  formData = ref<Record<string, any>>({
    /**
     * 评价
     */
    evaluate: 'GOOD',
    /**
     * 说明
     */
    describe: '',
    /**
     * 审批内容
     */
    workObjInfo:[]
  })
  /** 单选框可选项 */
  radioOptions = ref<Array<SelectOption>>([
    {
      label: '优秀',
      value: 'EXCELLENT'
    },
    {
      label: '良好',
      value: 'GOOD'
    },
    {
      label: '一般',
      value: 'COMMON'
    },
    {
      label: '差',
      value: 'POOR'
    }
  ])
  constructor(options: ViewModelOptions<KTaskEvaluatePropType>) {
    super(options)
  }

  viewDidMount() {
    this.listWorkObjInfo()
  }

  listWorkObjInfo(){
    if (this.props.businessId) {
      Api.post('official', 'ProcessContentService', 'listWorkObjInfo', {
        data: [this.props.businessId]
      }).then((result: any) => {
        if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
          this.formData.value.workObjInfo = result.data
        } else {
          KNotification.error({
            title: '获取失败',
            content: result.message,
            details: result.detail
          })
        }
      })
    }
  }

  getValue() {
    return this.formData.value
  }
}

import lodash from 'lodash'
import { ref, watch } from 'vue'
import { BaseViewModel, KTabsViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import {
  EnumObjectPanelTabParamType,
  KObjectPanelEmitsType,
  KObjectPanelPropType,
  ObjectPanelDataUpdatedEvent,
  ObjectPanelRetrieveEvent,
  ObjectPanelRetrieveParams,
  ObjectPanelSaveChangesResult,
  ObjectPanelTabConfig,
  ObjectPanelTabDataUpdatedEvent,
  ObjectPanelTabMountedEvent,
  ObjectPanelTabReadyEvent,
  ObjectPanelTabRetrieveEvent
} from './interface'
import { localStorageCache } from '../../index'
import { ClassMetaClientSrv, LayoutClassTab, LayoutClientSrv, ObjBusinessParam } from '../../client-srv'

/** 对象面板 */
export default class KObjectPanelViewModel extends BaseViewModel<KObjectPanelEmitsType, KObjectPanelPropType> {
  /** 标签页引用 */
  refTabs = ref<KTabsViewModel>()
  /** 标签页配置 */
  tabConfigs = ref<Array<ObjectPanelTabConfig>>([])
  /** 激活的标签页 */
  activeKey = ref('')
  /** 是否在加载 */
  isLoading = ref(false)
  /** 主对象参数：如果是虚拟对象（如：零部件结构对象），该属性才有值 */
  mainObjParam = ref<ObjBusinessParam>()
  /** 是否有权限 默认有权限 */
  hasPreview = ref<boolean>(true)
  /** 对象是否存在 */
  isObjExisted = ref(true)
  /** 对象类标签页 */
  remoteTabs = ref<LayoutClassTab[]>([])

  constructor(options: ViewModelOptions<KObjectPanelPropType>) {
    super(options)

    watch(
      () => this.props.attachParams[EnumObjectPanelTabParamType.BomLinkParam],
      () => {
        this.initTabs(this.props.objParam)
      }
    )

    watch(
      () => this.props.objParam,
      (newValue, oldValue) => {
        this.init(newValue, oldValue)
      },
      {
        immediate: true
      }
    )
  }

  /**
   * 初始化数据
   * @param newValue 新对象参数
   * @param oldValue 就对象参数
   * @returns
   */
  async init(newValue?: ObjBusinessParam, oldValue?: ObjBusinessParam) {
    if (!newValue) {
      return
    }

    this.mainObjParam.value = newValue

    //#region 判断对象是否存在，如果不存在，则提示，并提示消息，并禁用当前控件
    // 根据对象类编码+对象类Id获取业务对象
    // const objBusiness = await ObjectClientSrv.getObjBusiness(this.mainObjParam.value)

    // this.isObjExisted.value = objBusiness != undefined

    // if (!this.isObjExisted.value) {
    //   return
    // }
    //#endregion

    /** 获取当前对象有没有浏览权限 [获取主对象权限] */
    //TODO: 根据对象业务参数获取对象浏览权限
    const purview = { hasPurview: true } //await purviewSrv.judgeObjectPurview(this.mainObjParam.value, EnumObjectPurviewType.Browse)
    // 如果没有权限则不显示内容
    this.hasPreview.value = purview.hasPurview

    /** 对象类是否更改 */
    const isObjcClsChange = newValue?.modelCode != oldValue?.modelCode

    // 对象类是否更改
    if (isObjcClsChange) {
      await this.initTabs(this.mainObjParam.value)
    }

    // 触发 ready 事件
    this.emit('ready')
    // 对象类参数更新事件
    this.emit('update:objParam', this.props.objParam)
  }

  /**
   * 加载标签页
   * @param objParam
   */
  async initTabs(objParam: ObjBusinessParam) {
    // 加载标记
    this.isLoading.value = true
    // 获取当前权限
    const limitCache: string | undefined = localStorageCache.getCache('system_limits')
    let limitList: string[] = []
    if (limitCache) {
      limitList = JSON.parse(limitCache)
        .filter((item: any) => item.purviewType == 2)
        .map((item: any) => item.purviewId)
    }

    /** 不显示的标签页 */
    const ignoreTabCodes: Array<string> = []
    /** 获取对象类标签页 */
    if (this.remoteTabs.value.length == 0) {
      //获取当前对象设计态code
      const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(objParam.modelCode)
      if (objParam.modelCode == 'PromoteActivity') {
        // 转阶段活动用自己的标签页
        this.remoteTabs.value = await LayoutClientSrv.getTabsById(objParam.modelGroup!, objParam.modelCode!)
      } else {
        this.remoteTabs.value = await LayoutClientSrv.getTabsById(objParam.modelGroup!, designerCode!)
      }
    }
    /** 判断是否显示局部替代件标签页 */
    if (!this.props.attachParams[EnumObjectPanelTabParamType.BomLinkParam]) {
      ignoreTabCodes.push('jbtdj')
    }
    /** 根据 面板id 权限码 过滤面板 */
    const tabDefines = this.remoteTabs.value.filter(
      a =>
        [...ignoreTabCodes, ...this.props.suppressedTabs].indexOf(a.tabCode) < 0 &&
        ((a.purviewId && limitList.includes(a.purviewId)) || !a.purviewId)
    )

    // 将 Tab 页定义转换为配置
    let tabs = tabDefines
      .map(t => {
        return {
          ref: undefined,
          index: t.orderId,
          tabId: t.tabCode,
          title: t.tabName,
          visible: t.visible == undefined ? true : t.visible,
          params: {},
          component: t.tabControl
        } as ObjectPanelTabConfig
      })
      .filter(t => t.component != undefined)

    // 排序
    tabs = lodash.sortBy(tabs, tab => tab.index)

    // 发出标签页设置前事件
    this.props.tabConfigSetBefore?.({ tabConfigs: tabs })

    // 设置标签页配置
    this.tabConfigs.value = tabs

    // 取消加载标记
    this.isLoading.value = false

    // 如果没有打开的标签页 或者 之前打开的标签页不存在
    if (!this.activeKey.value || !tabs.find(a => a.tabId == this.activeKey.value)) {
      /** 查找默认激活标签页 */
      const defaultActiveTab = tabs.find(a => a.isDefaultActive == true)

      // 如果找到了默认激活标签页配置
      if (defaultActiveTab) {
        this.activeKey.value = defaultActiveTab.tabId
      } else if (tabs.length > 0) {
        // 如果没有默认Tab页 或者 Tab页 不存在
        // 设置默认显示标签页为第一个
        this.activeKey.value = tabs ? tabs[0].tabId : ''
      }

      // 发出标签页切换事件
      this.onTabChange(this.activeKey.value)
    }
  }

  //#region 公共方法
  /** 刷新 */
  async refresh() {
    for (const iterator of this.tabConfigs.value) {
      // 子标签页引用
      const refTab = iterator.ref
      if (!refTab?.refresh) {
        console.warn(`${iterator.tabId}不存在 refresh 方法`)
        continue
      }
      // 刷新子标签页
      await refTab?.refresh()
    }
  }

  /**
   * 添加页面
   * @param tabId
   * @param title
   * @param component
   * @param params
   */
  addPage(tabId: string, title: string, component: any, params: any) {
    /** 先移除已存在的标签页 */
    lodash.remove(this.tabConfigs.value, page => page.tabId == tabId)
    const lastConfig = lodash.maxBy(this.tabConfigs.value, config => config.index)
    /** 标签页配置 */
    const config = {
      /** 组件引用 */
      ref: undefined,
      index: lastConfig ? lastConfig.index + 1 : 1,
      tabId: tabId,
      title: title,
      component: component,
      //tabType: EnumObjTabType.Extend,
      params: params,
      visible: true
    } as ObjectPanelTabConfig
    this.tabConfigs.value.push(config)
  }

  /**
   * 添加页面
   * @param index
   * @param tabId
   * @param title
   * @param component
   * @param params
   */
  insertPage(index: number, tabId: string, title: string, component: any, params: any) {
    /** 先移除已存在的标签页 */
    lodash.remove(this.tabConfigs.value, page => page.tabId == tabId)
    /** 标签页配置 */
    const config = {
      /** 组件引用 */
      ref: undefined,
      index: index,
      tabId: tabId,
      title: title,
      component: component,
      // tabType: EnumObjTabType.Extend,
      params: params,
      visible: true
    } as ObjectPanelTabConfig
    this.tabConfigs.value.splice(index, 0, config)
  }

  /**
   * 移除页面
   * @param tabId
   */
  removePage(tabId: string) {
    lodash.remove(this.tabConfigs.value, t => t.tabId == tabId)

    // 如果移除后对应的标签页不存在，则定位到第一个标签页
    if (!this.tabConfigs.value.some(a => a.tabId == this.activeKey.value)) {
      this.activeKey.value = this.tabConfigs.value[0].tabId
    }
  }

  /**
   * 获取页面配置
   * @param tabId
   * @returns
   */
  getPageConfig(tabId: string) {
    return this.tabConfigs.value.find(t => t.tabId == tabId)
  }

  /**
   * 获取当前标签页
   * @returns
   */
  getCurrentTabConfig() {
    return this.getPageConfig(this.activeKey.value)
  }

  /**
   * 设置标签页是否可见
   * @param tabId 标签页Id
   * @param visible 是否可见
   */
  setPageVisible(tabId: string, visible: boolean) {
    const tabConfig = this.getPageConfig(tabId)
    if (tabConfig) {
      tabConfig.visible = visible
      // 如果隐藏当前的标签页，则定位到第一个标签页
      if (tabId == this.activeKey.value && visible == false) {
        this.activeKey.value = this.tabConfigs.value[0].tabId
      }
    }
  }

  /** 获取标签页配置 */
  getTabConfigs() {
    return this.tabConfigs.value
  }

  /** 当前主业务对象参数 */
  getObjParam(): ObjBusinessParam {
    return this.mainObjParam.value!
  }

  /**
   * 刷新标签页
   * @param params
   * @returns
   */
  async retrieve(params: ObjectPanelRetrieveParams) {
    /** 获取标签页配置 */
    const tab = this.tabConfigs.value.find(a => a.tabId == params.tabId)
    if (!tab) {
      console.warn('标签页不存在')
      return
    }
    // 子标签页引用
    const refTab = tab.ref
    // 刷新子标签页
    await refTab?.retrieve?.({
      command: params.command,
      data: params.data
    })
  }

  /**
   * 设置当前选中标签页
   * @param tabId 标签页id
   */
  activePage(tabId: string) {
    const tabConfig = this.getPageConfig(tabId)
    if (tabConfig) {
      this.refTabs.value?.active(tabId)
    }
  }

  /**
   * 保存更改
   * @returns 保存结果
   */
  async saveChanges(): Promise<ObjectPanelSaveChangesResult> {
    // 循环所有标签页
    for (const tabConfig of this.tabConfigs.value) {
      // 如果没有实现 saveChanges
      if (!tabConfig.ref || !tabConfig.ref.saveChanges || !tabConfig.ref.isDataChanged) {
        continue
      }

      /** 获取数据是否更改 */
      const changed = await tabConfig.ref.isDataChanged()

      // 如果没有更改 继续获取其他标签页的状态
      if (changed !== true) {
        continue
      }

      // 激活标签页
      this.activePage(tabConfig.tabId)

      /** 保存更改 */
      const result = await tabConfig.ref.saveChanges()

      // 如果取消
      if (result.cancel) {
        return { cancel: true }
      }
    }

    return { cancel: false }
  }

  //#endregion

  /** 模型更新事件 */
  onDataUpdated(event: ObjectPanelTabDataUpdatedEvent, config: ObjectPanelTabConfig) {
    /** 组合事件参数 */
    const panelEvent = {
      ...event,
      tabId: config.tabId,
      tabConfig: config
    } as ObjectPanelDataUpdatedEvent
    // 发布事件
    this.emit('dataUpdated', panelEvent)
  }

  /** 模型更新事件 */
  onRetrieve(event: ObjectPanelTabRetrieveEvent, config: ObjectPanelTabConfig) {
    /** 组合事件参数 */
    const panelEvent = {
      ...event,
      tabId: config.tabId,
      tabConfig: config
    } as ObjectPanelRetrieveEvent
    // 发布事件
    this.emit('retrieve', panelEvent)
  }

  /** 切换Tab事件 */
  onTabChange(tabId: string) {
    this.activeKey.value = tabId
    /** 根据 tabId 查找配置 */
    const tabConfig = this.tabConfigs.value.find(a => a.tabId == tabId)
    this.emit('tabChange', { tabId: tabId, tabConfig: tabConfig! })
  }

  /**
   * 标签页挂载完成
   * @param config
   */
  onTabMounted(config: ObjectPanelTabConfig) {
    /** 组合事件参数 */
    const panelEvent: ObjectPanelTabMountedEvent = {
      tabId: config.tabId,
      tabConfig: config
    }
    // 发布事件
    this.emit('tabMounted', panelEvent)
  }

  /**
   * 标签页准备就绪事件
   * @param config
   */
  onTabReady(config: ObjectPanelTabConfig) {
    /** 组合事件参数 */
    const panelEvent: ObjectPanelTabReadyEvent = {
      tabId: config.tabId,
      tabConfig: config
    }
    // 发布事件
    this.emit('tabReady', panelEvent)
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KBaselineMemberGridPropOptions, KBaselineMemberGridEventEmits } from './interface'
import KBaselineMemberGridViewModel from './KBaselineMemberGridViewModel'

export default defineView({
  name: 'KBaselineMemberGrid',
  props: KBaselineMemberGridPropOptions,
  emits: KBaselineMemberGridEventEmits,
  viewModel: KBaselineMemberGridViewModel
})

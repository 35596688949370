import {
  BaseViewEventEmits,
  BaseViewEventEmitsType,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { IValueDesc } from '../../interface'

// 配置项定义
export interface IConfigValueDefinition {
  defId: string
  id: string
  code: string
  name: string
  value: string
  values: Array<string>
  value_switch: boolean
  remark: string
  dataType: string
  editType: string
  editParam: string
}

// 页面表单状态
export interface IConfigValueViewModelState {
  formState: IConfigValueDefinition
  selectEditTypeOptions: Array<IValueDesc>
  selectEditEditTypeOptions: Array<IValueDesc>
  selectDisplayEditTypeOptions: Array<IValueDesc>
  radioEditTypeOptions: Array<IValueDesc>
  mode: string
}

// 页面事件
export const KConfigValueEventEmits = {
  ...BaseViewEventEmits
}

export const KConfigValueViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('')
}

export type KConfigValueViewModelPropType = ViewPropsTypeExtract<typeof KConfigValueViewModelPropOptions>

export type KConfigValueEventEmitsType = ViewEmitsTypeExtract<typeof KConfigValueEventEmits>


import { defineView } from '@kmsoft/upf-core'
import { KCategoryRenderPropOptions, KCategoryRenderEventEmits } from './interface'
import KCategoryRenderViewModel from './KCategoryRenderViewModel'
import { omit } from 'lodash'

export default defineView({
  name: 'KCategoryRender',
  props: KCategoryRenderPropOptions,
  emits: KCategoryRenderEventEmits,
  viewModel: KCategoryRenderViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      omit
    }
  }
})

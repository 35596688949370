import { connect, withInstall } from '@kmsoft/upf-core'
import KDocBatchCreateView from './KDocBatchCreate.vue'
import KDocBatchCreateViewModel from './KDocBatchCreateViewModel'

const KDocBatchCreate = connect(KDocBatchCreateView, KDocBatchCreateViewModel)

export default withInstall(KDocBatchCreate)
export { KDocBatchCreate, KDocBatchCreateView, KDocBatchCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { FileAccess } from './FileAccess'
import { FileIOPermissionAccess } from '../security/permissions/FileIOPermissionAccess'

/**指定操作系统打开文件的方式。*/
export enum FileMode {
  /**指定操作系统应创建新文件。 这需要 {@link FileIOPermissionAccess.Write} 权限。 如果文件已存在，则将引发 {@link IOException} 异常。*/
  CreateNew = 1,
  /**指定操作系统应创建新文件。 如果此文件已存在，则会将其覆盖。 这需要 {@link FileIOPermissionAccess.Write} 权限。 {@link Create} 等效于这样的请求：如果文件不存在，则使用 {@link CreateNew}；否则使用 {@link Truncate}。 如果该文件已存在但为隐藏文件，则将引发 {@link UnauthorizedAccessException} 异常。*/
  Create = 2,
  /**指定操作系统应打开现有文件。 打开文件的能力取决于 {@link FileAccess} 枚举所指定的值。 如果文件不存在，引发一个 {@link FileNotFoundException} 异常。*/
  Open = 3,
  /**指定操作系统应打开文件（如果文件存在）；否则，应创建新文件。 如果用 {@link FileAccess.Read} 打开文件，则需要 {@link FileIOPermissionAccess.Read} 权限。 如果文件访问为 {@link FileAccess.Write}，则需要 {@link FileIOPermissionAccess.Write} 权限。 如果用 {@link FileAccess.ReadWrite} 打开文件，则同时需要 {@link FileIOPermissionAccess.Read} 和 {@link FileIOPermissionAccess.Write} 权限。*/
  OpenOrCreate = 4,
  /**指定操作系统应打开现有文件。 该文件被打开时，将被截断为零字节大小。 这需要 {@link FileIOPermissionAccess.Write} 权限。 尝试从使用 {@link FileMode.Truncate} 打开的文件中进行读取将导致 {@link ArgumentException} 异常。*/
  Truncate = 5,
  /**若存在文件，则打开该文件并查找到文件尾，或者创建一个新文件。 这需要 {@link FileIOPermissionAccess.Append} 权限。 {@link FileMode.Append} 只能与 {@link FileAccess.Write} 一起使用。 试图查找文件尾之前的位置时会引发 {@link IOException} 异常，并且任何试图读取的操作都会失败并引发 {@link NotSupportedException} 异常。*/
  Append = 6
}

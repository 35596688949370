import NProgress from 'nprogress'
import { IRouteHandlerInterceptor, AppBuilder, AppContext } from '@kmsoft/upf-core'
export default class RouteHandlerInterceptor implements IRouteHandlerInterceptor {
  beforeEachRoute(to: any, from: any, next: any): void {
    NProgress.start()
    if (to.name === 'login') {
      next(), NProgress.done()
      return
    }
    // TODO：这里可以从环境配置中读取认证方式，当需要认证时，才是下面这段逻辑
    if (AppContext.current.isAuthenticated()) {
      next(), NProgress.done()
      return
    } else {
      next({ name: 'login', query: { redirect: to.fullPath } }), NProgress.done()
    }
  }
  beforeResolve(to: any, from: any, next: any): void {
    next()
  }
  afterEachRoute(to: any, from: any): void {
    NProgress.done()
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartSearchFormView from './KPartSearchForm.vue'
import KPartSearchFormViewModel from './KPartSearchFormViewModel'

const KPartSearchForm = connect(KPartSearchFormView, KPartSearchFormViewModel)

export default withInstall(KPartSearchForm)
export { KPartSearchForm, KPartSearchFormView, KPartSearchFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import {
  KObjectCreatePanelFormBaseEventEmits,
  KObjectCreatePanelFormBasePropOptions,
  CreateObjBusinessParams,
  ObjBusinessBase,
  SaveObjBusinessParams
} from '@kmsoft/ebf-common'
import { AgentDdb } from '../..'

//#region
/** 文件类型 */
export enum EnumAddMainFileType {
  /** 空文档 */
  Empty,
  /** 引用本地文件 */
  LocalFile,
  /** 引用模板 */
  Template,
  /** 引用其他文档对象 */
  DocObj,
  /** CAPP新建文件 */
  Capp
}

/** 选中文件属性 */
export type SelectDocItem = {
  /** 类型 */
  type?: EnumAddMainFileType
  /** 文件名称 */
  fileName?: string
  /** 文件Id */
  fileId?: string
  /**文件路径 */
  filePath?: string
  /**选择的文档对象Id */
  selectDocObjId?: string
  /**选择的文档对象类Id */
  selectDocObjClsId?: string
  /**模板Id */
  templateId?: string
  md5?: string
  size?: string
  location?: string
  displayLocation?: string
}

/**引入文件-信息提取选项 */
export type AddFilePropertyReadOption = {
  /**
   * 是否提取对象属性
   */
  isProperties?: boolean
  /**
   * 是否提取零部件结构
   */
  isStruct?: boolean
  /**
   * 是否级联提取零部件结构
   */
  isCascade?: boolean
}

export type DocCreateObjBusinessParams = {
  params: SaveObjBusinessParams
  fileProperty?: AgentDdb.FileProperty
  isProperty: boolean
}

/** 保存回调 */
export type ObjectDcoCreatePanelSaveCallback = (params: CreateObjBusinessParams) => Promise<ObjBusinessBase>

//#endregion

/** 参数 **/
export const KDocObjectCreateFormPropOptions = {
  ...KObjectCreatePanelFormBasePropOptions
}

/** 参数类型 **/
export type KDocObjectCreateFormPropType = ViewPropsTypeExtract<typeof KDocObjectCreateFormPropOptions>

/** 事件 */
export const KDocObjectCreateFormEventEmits = {
  ...KObjectCreatePanelFormBaseEventEmits
}

/** 事件类型 **/
export type KDocObjectCreateFormEmitsType = ViewEmitsTypeExtract<typeof KDocObjectCreateFormEventEmits>

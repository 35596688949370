import { ObjBusinessParam } from '../../../../client-srv'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KHyperLinkPropOptions = {
  ...BaseViewPropOptions,
  objParam: VuePropTypes.createType<ObjBusinessParam>().def(),
  value: VuePropTypes.createType<any>().def()
}

/** 参数类型 **/
export type KHyperLinkPropType = ViewPropsTypeExtract<typeof KHyperLinkPropOptions>

/** 事件 */
export const KHyperLinkEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KHyperLinkEmitsType = ViewEmitsTypeExtract<typeof KHyperLinkEventEmits>

import { FileAttributes } from '../../../../io/FileAttributes'
import { KTreeNodeFileSystemInfo } from './KTreeNodeFileSystemInfo'

/**文件。*/
export class KTreeNodeFile extends KTreeNodeFileSystemInfo {
  constructor(path: string) {
    super(path)

    this.pathType = FileAttributes.Normal
    this.leaf = true

    this.needInitializePathTypeName = true
    this.needInitializeShellIcon = true
  }

  protected async generateChildren(populateType: number, filter: string): Promise<KTreeNodeFileSystemInfo[]> {
    return []
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KAttrsCategoryRenderPropOptions, KAttrsCategoryRenderEventEmits } from './interface'
import KAttrsCategoryRenderViewModel from './KAttrsCategoryRenderViewModel'

export default defineView({
  name: 'KAttrsCategoryRender',
  props: KAttrsCategoryRenderPropOptions,
  emits: KAttrsCategoryRenderEventEmits,
  viewModel: KAttrsCategoryRenderViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

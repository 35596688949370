import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { getCandidates } from '../utils'
import { EnumAttributeType, EnumDataType, KControlAttribute, KControlEvent } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'

/* 组件的基本属性 */
const comConfig = [
  {
    name: 'type',
    title: '按钮类型',
    control: 'KSelect',
    options: getCandidates(['primary', 'danger', 'link', 'dashed', 'ghost', 'text', 'default']),
    defaultValue: 'default',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'loading',
    title: 'loading状态',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'icon',
    title: '图标',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'size',
    title: '大小型号',
    control: 'KSelect',
    options: getCandidates(['small', 'middle', 'large']),
    defaultValue: 'middle',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'shape',
    title: '按钮形状',
    control: 'KSelect',
    options: getCandidates(['circle', 'round', 'default']),
    defaultValue: 'default',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'hideText',
    title: '隐藏文字',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  }
]

/* 基础配置项集合 */
const BASE = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE, PROPERTY_CONFIG_ITEM_COLLECTION.DISABLED] as Array<KControlAttribute>

/**控件属性配置集合 */
const PROPS = comConfig

/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
] as Array<KControlAttribute>

const EVENT: Array<KControlEvent> = [{ name: 'click', title: '点击', describe: '点击 的说明信息...' }]

export default { BASE, PROPS, LAYOUT, EVENT }

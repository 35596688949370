import { connect, withInstall } from '@kmsoft/upf-core'
import KValidationMetricsManageView from './KValidationMetricsManage.vue'
import KValidationMetricsManageViewModel from './KValidationMetricsManageViewModel'

const KValidationMetricsManage = connect(KValidationMetricsManageView, KValidationMetricsManageViewModel)

export default withInstall(KValidationMetricsManage)
export { KValidationMetricsManage, KValidationMetricsManageView, KValidationMetricsManageViewModel }

// 模板生成文件
// export * from './{{folderName}}'

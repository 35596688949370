import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewPropsTypeExtract,
  VuePropTypes,
  ViewEmitsTypeExtract
} from '@kmsoft/upf-core'
import { UpdateConfigValidStatusEventArgs } from '../sys-config-value/interface'

// 页面事件
export const KConfigTileEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 更新配置项(模块)有效性状态
   * @param validStatus 有效性
   */
  updateConfigValidStatus: (e: UpdateConfigValidStatusEventArgs) => true
}

export const KConfigTileViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('')
}

export type KConfigTileViewModelPropType = ViewPropsTypeExtract<typeof KConfigTileViewModelPropOptions>

export type KConfigTileEventEmitsType = ViewEmitsTypeExtract<typeof KConfigTileEventEmits>

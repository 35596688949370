import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPlanEditPanelPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KPlanEditPanelPropType = ViewPropsTypeExtract<typeof KPlanEditPanelPropOptions>

/** 事件 */
export const KPlanEditPanelEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPlanEditPanelEmitsType = ViewEmitsTypeExtract<typeof KPlanEditPanelEventEmits>

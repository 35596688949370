import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    name: "vmRefPropertyForm",
    model: _ctx.$vm.formState,
    "label-col": { span: 6 },
    "wrapper-col": { span: 16 }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, { label: "对象类编码" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_k_input, {
              value: _ctx.$vm.formState!.modelName,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formState!.modelName) = $event)),
              readonly: _ctx.readonly
            }, null, 8, ["value", "readonly"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, { label: "属性名称" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formState!.attributeName,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formState!.attributeName) = $event)),
            readonly: _ctx.readonly
          }, null, 8, ["value", "readonly"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselinePrimaryObjectView from './KBaselinePrimaryObject.vue'
import KBaselinePrimaryObjectViewModel from './KBaselinePrimaryObjectViewModel'

const KBaselinePrimaryObject = connect(KBaselinePrimaryObjectView, KBaselinePrimaryObjectViewModel)

export default withInstall(KBaselinePrimaryObject)
export { KBaselinePrimaryObject, KBaselinePrimaryObjectView, KBaselinePrimaryObjectViewModel }

// 模板生成文件
// export * from './{{folderName}}'

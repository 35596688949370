import { BaseViewPropOptions, ViewPropsTypeExtract, BaseViewEventEmits, ViewEmitsTypeExtract } from '@kmsoft/upf-core'

// 属性定义
export const KPageFormPropOptions = { ...BaseViewPropOptions }
export type KPageFormPropType = ViewPropsTypeExtract<typeof KPageFormPropOptions>

// 事件定义
export const KPageFormEventEmits = {
  ...BaseViewEventEmits
}
export type KPageFormEventEmitsType = ViewEmitsTypeExtract<typeof KPageFormEventEmits>

// 页面定义
export interface IPageDefinition {
  id: string
  name: string
  symbol: string
  suffix: string
}

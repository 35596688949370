import { BaseViewModel, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KBaselineCompareGridDiffEmitsType, KBaselineCompareGridDiffPropType } from './interface'
import { ref } from 'vue'

/** KBaselineCompareGridDiff */
export default class KBaselineCompareGridDiffViewModel extends BaseViewModel<
  KBaselineCompareGridDiffEmitsType,
  KBaselineCompareGridDiffPropType
> {
  refDiffGrid = ref<KDataGridViewModel>()
  constructor(options: ViewModelOptions<KBaselineCompareGridDiffPropType>) {
    super(options)
  }

  viewDidMount() {}
}

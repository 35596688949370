import { connect, withInstall } from '@kmsoft/upf-core'
import KBasePropertyEditorView from './KBasePropertyEditor.vue'
import KBasePropertyEditorViewModel from './KBasePropertyEditorViewModel'

const KBasePropertyEditor = connect(KBasePropertyEditorView, KBasePropertyEditorViewModel)

export default withInstall(KBasePropertyEditor)
export { KBasePropertyEditor, KBasePropertyEditorView, KBasePropertyEditorViewModel }
export * from './interface'
export * from './types'
export * from './KBaseColumn'
export * from './IFieldEditorResolver'

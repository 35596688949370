
import { defineView } from '@kmsoft/upf-core'
import { KDesignerHierarchyEventEmits, KDesignerHierarchyPropOptions } from './interface'
import KDesignerHierarchyViewModel from './KDesignerHierarchyViewModel'
import './style/index.less'

/** 控件层级 */
export default defineView({
  name: 'KDesignerHierarchy',
  props: KDesignerHierarchyPropOptions,
  emits: KDesignerHierarchyEventEmits,
  viewModel: KDesignerHierarchyViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    /**
     * 树节点选中事件
     * @param node 选中的节点
     */
    const onTreeNodeSelected = (args: Record<string, any>) => {
      emit('treeNodeSelected', args.node)
    }

    return () => {
      return (
        <k-tree-view
          ref={vm.refTreeView.value}
          nodeKey="id"
          autoWrapRoot={false}
          autoSelectFirstNodeAfterDefaultExpand={false}
          defaultExpandedLevel={100}
          dataSource={vm.treeDataSource.value}
          onAfterSelect={onTreeNodeSelected}
        ></k-tree-view>
      )
    }
  }
})

import {
  BaseViewModel,
  EnumToolStripCompType,
  IKTreeNode,
  KNotification,
  KToolStripSelectItemViewModel,
  KTreeViewViewModel,
  SelectOption,
  SelectOptionValue,
  ToolStripItemClickedEventArgs,
  TreeViewSelectEventArgs,
  ValueChangeEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KIssueReportPartStruEmitsType, KIssueReportPartStruPropType } from './interface'
import { Api, EnumRequestCode, EnumToolStripItemKeys, EnumVersionShowType, KObjectClassGridViewModel } from '@kmsoft/ebf-common'
import { nextTick, ref } from 'vue'
import treeSelect from '@kmsoft/upf-core/src/web-controls/tree-select'

/** KIssueReportPartStru */
export default class KIssueReportPartStruViewModel extends BaseViewModel<
  KIssueReportPartStruEmitsType,
  KIssueReportPartStruPropType
> {
  /**
   * 外部选择的零部件对象ID和类型
   */
  partId = ref<string>('')
  rdmExtensionType = ref<string>('')

  /** 树组件 */
  refPartTree = ref<KTreeViewViewModel>()

  constructor(options: ViewModelOptions<KIssueReportPartStruPropType>) {
    super(options)
    this.partId.value = this.props.objParam.id
    this.rdmExtensionType.value = this.props.objParam.rdmExtensionType
  }

  // [key: string]: any; // 添加索引签名

  viewDidMount() {
    this.loadTreeData()
  }

  /**
   * 加载零部件向父递归查询树
   */
  async loadTreeData() {
    let data = [] as any
    const result = (await this.expandForGrid()) as any
    // 刷新图标视图
    const iconView = result!.map(
      (resultData: { userObject: any; hasChildren: any; pid: any; name: any; number: any; id: any }) => {
        return {
          id: resultData.id,
          hasChildren: resultData.hasChildren,
          parentId: resultData.pid,
          name: resultData.name
        }
      }
    ) as any
    if (iconView && iconView.length > 0) {
      for (let index = 0; index < iconView.length; index++) {
        data.push(iconView[index])
      }
    }
    this.fillIcon(data)
    data = this.toTree(data)
    this.refPartTree.value?.setValue(data)
  }

  fillIcon(arr: Array<IKTreeNode>) {
    arr.forEach((node: any) => {
      node.iconType = node.hasChildren ? 'setting' : 'layout-item'
    })
  }

  /**
   * 将父子结构一维数组转换成children[]形式
   * @param arr 源数据集合
   * @returns 目标数据集合(默认只有一个对象)
   */
  toTree(arr: Array<IKTreeNode>): Array<IKTreeNode> {
    const tree = [] as Array<IKTreeNode>
    const codeMap = new Map()
    const map = new Map()

    arr.forEach((node: any) => {
      codeMap.set(node.id, { ...node })
      map.set(node.id + '_' + node.parentId, { ...node, children: [] })
    })

    map.forEach((node, _, map) => {
      const parentNode = codeMap.get(node.parentId)
      const parentId = node.parentId + '_' + parentNode?.parentId
      const parent = map.get(parentId)

      if (parent) {
        parent.children.push(node)
        parent.leaf = false
      } else {
        tree.push(node)
      }
    })

    return tree
  }

  /**
   * 网格展开
   */
  async expandForGrid() {
    const param = {
      data: [
        {
          root: {
            id: this.partId.value,
            clazz: this.rdmExtensionType.value
          },
          expandType: 'FORWARD_EXPANSION',
          queryRule: { type: 'LATEST_WITHOUT_WORKING_COPY' },
          view: 'Design',
          maxLevel: 99
        }
      ]
    }
    const result = await Api.post('part', 'Part', 'expandForTree', param)
    return new Promise((resolve, reject) => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        resolve(result.data)
      } else {
        KNotification.error({
          title: '获取失败',
          content: result.message,
          details: result.detail
        })
        reject(void 0)
      }
    })
  }
}


import {
  defineView,
  EnumDataGridRowModelType,
  EnumDividerType,
  IKTreeNode,
  KDataGridRowSelectedEvent,
  KGridPagination,
  MemoryCacheFactory,
  ToolStripItemClickedEventArgs,
  TreeViewSelectEventArgs
} from '@kmsoft/upf-core'
import { isFunction } from 'lodash'
import { KFolderTree } from './folder-tree'
import { KFolderManageEventEmits, KFolderManagePropOptions } from './interface'
import KFolderManageViewModel from './KFolderManageViewModel'
import { clsCodeMap, EnumLifecycleState, EnumLifecycleStateColor } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KFolderManage',
  props: KFolderManagePropOptions,
  emits: KFolderManageEventEmits,
  viewModel: KFolderManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const loadTreeData = (node: null | IKTreeNode) => {
      const loadData = props.loadData
      if (loadData && isFunction(loadData)) {
        return loadData(node)
      }
      return Promise.resolve([])
    }

    const lifecycleState = (lifecycleState: string) => {
      if (lifecycleState) {
        return JSON.parse(lifecycleState).description
      }
    }

    return {
      EnumLifecycleState,
      EnumLifecycleStateColor,
      lifecycleState,
      loadTreeData,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      vertical: EnumDividerType.VERTICAL,
      pagination: { pageIndex: 1, pageSize: 100 } as KGridPagination,
      clsCodeMap,
      queryTypeOptions: [
        {
          label: '最新版本',
          value: 'LATEST'
        },
        {
          label: '最新发布版本',
          value: 'LATEST_RELEASED'
        },
        {
          label: '全部版本',
          value: 'ALL'
        }
      ]
    }
  },
  components: { KFolderTree }
})


import { EnumSizeType, VNodeProps, defineView } from '@kmsoft/upf-core'
import { KObjectWorkspacePropOptions, KObjectWorkspaceEventEmits, KObjectWorkspaceEmitsType } from './interface'
import KObjectWorkspaceViewModel from './KObjectWorkspaceViewModel'
import { EnumObjectSearcherMode, EnumObjectSearcherTab, EnumSearchRegion } from '../object-searcher/interface'
import { computed, createVNode } from 'vue'
import { KObjectWorkSpaceSearchPropType } from '../object-work-space-search/interface'
import {
  EnumObjClassManageToolStripOptions,
  KObjectClassGrid,
  KObjectClassGridEventEmitsType,
  KObjectClassGridPropType
} from '../object-class-grid'
import { KObjectWorkSpaceSearch } from '../object-work-space-search'
import { ClassMetaClientSrv, ObjectClientSrv } from '../../client-srv'

export default defineView({
  name: 'KObjectWorkspace',
  props: KObjectWorkspacePropOptions,
  emits: KObjectWorkspaceEventEmits,
  viewModel: KObjectWorkspaceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      /** 渲染搜索组件 */
      const renderSearcher = () => {
        const searcherProps: VNodeProps<KObjectWorkSpaceSearchPropType, KObjectWorkspaceEmitsType> = {
          ref: vm.refIKObjectWorkspaceSearch,
          ...vm.objectClassTreeProps.value,
          autoSelectFirstNode: false,
          includeChildrenCheck: true,
          showSearch: true,
          showCancel: true,
          showObjClsCodes: props.showObjClsCodes,
          hideObjClsCodes: props.hideObjClsCodes,
          onSearch: vm.onSearch,
          onCancel: vm.onCancel,
          'onUpdate:modelCode': async (value: string) => {
            vm.modelCode.value = value
            vm.modelGroup.value = await ObjectClientSrv.getModelGroupByCode(value)
          }
        }
        return createVNode(KObjectWorkSpaceSearch, searcherProps)
      }

      /** 渲染网格 */
      const renderGrid = () => {
        if (!vm.modelCode.value || !vm.filter.value) {
          return <k-empty description="请选择对象类或查询条件" />
        }

        const objectClassGridProps: VNodeProps<KObjectClassGridPropType, KObjectClassGridEventEmitsType> = {
          ref: vm.refObjectQueryComponent,
          isMultipleSelection: props.isMultipleSelection,
          modelCode: vm.modelCode.value,
          modelGroup: vm.modelGroup.value,
          filter: vm.filter.value,
          showToolStrip: false,
          onRowDoubleClick: vm.onRowDoubleClick,
          pagination: { pageIndex: 1, pageSize: 50 },
          toolStripOptions:
            EnumObjClassManageToolStripOptions.ShowObjLifeTime |
            EnumObjClassManageToolStripOptions.ShowOperation |
            EnumObjClassManageToolStripOptions.ShowGridOperation,
          class: 'class-content'
        }

        return createVNode(KObjectClassGrid, objectClassGridProps)
      }

      return (
        <k-split-container sizeUnit={EnumSizeType.Pixel}>
          <k-split-pane size="420" minSize="420">
            {renderSearcher()}
          </k-split-pane>
          <k-split-pane>{renderGrid()}</k-split-pane>
        </k-split-container>
      )
    }
  }
})


import { EnumSizeType, defineView, LayoutRenderElement } from '@kmsoft/upf-core'
import KGridColumnDesignerViewModel from './KGridColumnDesignerViewModel'
import { KGridDesignerPreview } from '../preview'
import { KGridDesignerToolBox } from '../toolbox'
import { KViewConfig } from '../../../../../designer'
import { KGridColumnDesignerEventEmits, KGridColumnDesignerPropOptions } from './interface'

/** 设计器 */
export default defineView({
  inheritAttrs: false,
  name: 'KGridColumnDesigner',
  props: KGridColumnDesignerPropOptions,
  emits: KGridColumnDesignerEventEmits,
  viewModel: KGridColumnDesignerViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    return () => {
      const renderConfig = () => {
        if (!vm.selectedElement.value) {
          return
        }
        return (
          <KViewConfig
            ref={vm.refDesignerConfig}
            element={vm.selectedElement.value}
            control={vm.selectedControl.value}
            onElementChange={vm.elementPropertyChange}
          />
        )
      }

      const renderToolBox = () => {
        if (!vm.metaColumns.value) {
          return
        }
        return (
          <KGridDesignerToolBox
            convertElement={vm.__convertElement2Preview}
            metaColumns={vm.metaColumns.value}
            dataSource={props.dataSource}
          />
        )
      }

      return (
        <k-split-container class="k-layout-designer default-theme k-grid-column-designer" sizeUnit={EnumSizeType.Pixel}>
          <k-split-pane size={250}>{renderToolBox()}</k-split-pane>
          <k-split-pane class="k-layout-designer-page-container">
            <div class="k-layout-designer-page-view">
              <div class="k-layout-designer-page">
                <KGridDesignerPreview
                  elements={props.elements}
                  selectedElement={vm.selectedElement.value}
                  onElementChange={vm.onElementChange}
                  onElementSelected={vm.onElementSelected}
                />
              </div>
            </div>
          </k-split-pane>
          <k-split-pane size={300}>{renderConfig()}</k-split-pane>
        </k-split-container>
      )
    }
  }
})

import { BaseViewEventEmits, ViewEmitsTypeExtract, ViewPropsTypeExtract, VueNode, VuePropTypes } from '@kmsoft/upf-core'
import { KObjectClassTreePropOptions } from '../object-class-tree'
import { QueryCondition } from '../../client-srv'
import { Ref } from 'vue'
import KObjectSearcherViewModel from './KObjectSearcherViewModel'
import lodash from 'lodash'

//#region 枚举
/** 对象搜索模式 */
export enum EnumObjectSearcherMode {
  /** 网格 */
  GRID = 'GRID',
  PARTTREE_FIND = 'PARTTREE_FIND',
  PARTTREE_FIND_RELATIVE = 'PARTTREE_FIND_RELATIVE'
}

/** 当前标签页 */
export enum EnumObjectSearcherTab {
  COMMON_SEARCH = 'COMMON_SEARCH',
  ADVANCED_SEARCH = 'ADVANCED_SEARCH',
  QUICK_SEARCH = 'QUICK_SEARCH'
}

/** 搜索范围 */
export enum EnumSearchRegion {
  BRANCH_WISE = 'BRANCH_WISE',
  TREE_WISE = 'TREE_WISE'
}
//#endregion

//#region
export type ParttreeSearchForm = {
  condition: QueryCondition
  treewise: boolean
  objClsID?: string
  findDepth?: number
  isIncludeRelationObject?: boolean
  isIncludeDescriptionObject?: boolean
}

/** 对象搜索框 */
export type IKObjectSearcherDialog = {
  /** 关闭窗口 */
  close: () => void
  /** 是否打开 */
  isOpen: boolean
  /** 组件引用 */
  refComponent?: KObjectSearcherViewModel
}

/** 全局搜索对话框属性 */
export type GlobalSearchDialogProps = {
  /** 标题 */
  title?: string
  /** 控件宿主 */
  getContainer?: string | (() => VueNode | HTMLElement) | VueNode | HTMLElement
  /** 组件参数 */
  props: {
    /** 默认选择的对象类 */
    modelCode?: Ref<string | undefined>
    /** 对象类Id */
    showObjClsCodes?: Ref<Array<string> | undefined>
    /** 对象类类型 */
    objClassTypes?: Ref<Array<string> | undefined>
    /** 路径 */
    componentPath?: string
    /** 在点击搜索时调用 */
    onSearch?: (condition: QueryCondition) => void
    /** 取消 */
    onCancel?: () => void
  }
}

//#endregion

/** 参数 **/
export const KObjectSearcherPropOptions = {
  ...lodash.omit(KObjectClassTreePropOptions, 'defaultSelectClsCode'),
  /** 启用`包含子类`勾选框 */
  modelCode: VuePropTypes.string().def(''),
  /** 启用`包含子类`勾选框 */
  includeChildrenCheck: VuePropTypes.bool().def(false),
  /** 搜索状态, 处于搜索状态将禁用底部按钮 */
  isSearching: VuePropTypes.bool().def(false),
  /** 路径 */
  componentPath: VuePropTypes.string().def(''),
  /** 是否显示搜索 */
  showSearch: VuePropTypes.bool().def(false),
  /** 是否显示取消 */
  showCancel: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KObjectSearcherPropType = ViewPropsTypeExtract<typeof KObjectSearcherPropOptions>

/** 事件 */
export const KObjectSearcherEventEmits = {
  ...BaseViewEventEmits,
  /** 对象类Id改变事件 */
  'update:modelCode': (value: string) => true,
  /** 此方法会在点击查询后被调用 */
  search: (condition: QueryCondition): boolean => true,
  /** 点击取消按钮 */
  cancel: () => true
}

/** 事件类型 **/
export type KObjectSearcherEmitsType = ViewEmitsTypeExtract<typeof KObjectSearcherEventEmits>

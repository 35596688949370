import { EnumDataType, EnumQueryConditionOperator, MetaProperty } from '../../client-srv'
import { BaseViewModel, IKForm, ViewModelOptions, SelectOption } from '@kmsoft/upf-core'
import { KObjectClassGridFindEventEmitsType, KObjectClassGridFindPropType, ObjectClassGridFindForm } from './interface'
import { ref, watch } from 'vue'
import { EnumFilterOperatorCode, PropertyConverter, SearchFilterTreeLeaf } from '../rule-definer'

/**
 * 对象网格查找器
 */
export class KObjectClassGridFindViewModel extends BaseViewModel<
  KObjectClassGridFindEventEmitsType,
  KObjectClassGridFindPropType
> {
  /** 表单引用 */
  refForm = ref<IKForm<Record<string, any>>>()
  /** 表单配置 */
  formModel = ref({
    selectedFieldCode: '',
    selectedOperator: EnumFilterOperatorCode.STARTWITH,
    fieldValue: undefined,
    /**是否区分大小写 */
    unignoreCase: false
  })
  /** 数据类型 */
  dataType = ref<EnumDataType>(EnumDataType.STRING)
  /** 旧的条件 */
  oldCondition = ref<SearchFilterTreeLeaf | undefined>(undefined)
  /** 操作符选项 */
  operatorOptions = ref<Array<SelectOption>>([])
  /** 当前选择的列 */
  selectedField = ref<MetaProperty>()
  /** 字段选项 */
  fieldsOptions = ref<Array<SelectOption>>([])

  constructor(options: ViewModelOptions<KObjectClassGridFindPropType>) {
    super(options)

    watch(
      () => options.props.fields,
      newVal => {
        this.fieldsOptions.value = newVal.map(f => ({ value: f.code, label: f.name }))
      },
      {
        immediate: true,
        deep: true
      }
    )

    watch(
      this.fieldsOptions,
      newValue => {
        if (!newValue || newValue.length == 0) return
        const selectedFieldCode = newValue[0].value
        if (selectedFieldCode) {
          this.formModel.value.selectedFieldCode = selectedFieldCode as string
          this.selectedField.value = options.props.fields.find(f => f.code === selectedFieldCode)
        }
      },
      {
        immediate: true,
        deep: true
      }
    )

    watch(
      this.selectedField,
      newValue => {
        this.operatorOptions.value = []

        if (newValue) {
          this.loadOperatorOptions(newValue!)
          const fieldColumnCode = newValue?.code || ''
          this.formModel.value.selectedFieldCode = fieldColumnCode
          this.formModel.value.selectedOperator = EnumFilterOperatorCode.EQUAL
          this.formModel.value.fieldValue = undefined
          if (PropertyConverter.isDateTimeField(newValue)) {
            this.formModel.value.selectedOperator = EnumFilterOperatorCode.GREATER
          } else if (PropertyConverter.isEnumerableField(newValue)) {
            this.formModel.value.selectedOperator = EnumFilterOperatorCode.EQUAL
            const options = PropertyConverter.getOptionsByField(newValue, {
              allowNull: true,
              allowCurrentUser: false
            })
            if (options.length > 0) this.formModel.value.fieldValue = options[0].value as any
          } else if (PropertyConverter.isTextField(newValue)) {
            this.formModel.value.selectedOperator = EnumFilterOperatorCode.LIKE
          } else if (PropertyConverter.isNumberField(newValue)) {
            this.formModel.value.selectedOperator = EnumFilterOperatorCode.EQUAL
          }
          this.dataType.value = newValue.type!
          return
        }
      },
      {
        immediate: true,
        deep: true
      }
    )
  }

  viewDidMount() {
    const field = this.selectedField.value
    if (field) {
      this.loadOperatorOptions(field)
      this.dataType.value = field.type!
    }
  }

  /**
   * 获取条件
   */
  getCondition = async (): Promise<SearchFilterTreeLeaf | undefined> => {
    try {
      await this.refForm.value?.validate()
      const key = this.formModel.value.selectedFieldCode!
      const operator = this.formModel.value.selectedOperator
      let value: any = this.formModel.value.fieldValue!
      if (this.selectedField.value && PropertyConverter.isNumberField(this.selectedField.value)) {
        value = parseFloat(value)
      }
      return new SearchFilterTreeLeaf(key, operator, value, this.dataType.value, this.formModel.value.unignoreCase)
    } catch {
      return undefined
    }
  }

  /**
   * 执行查找
   * @param direction
   */
  find = async (direction: 'next' | 'prev'): Promise<void> => {
    const currentCondition = await this.getCondition()
    if (!currentCondition) return
    let isUpdated
    if (this.oldCondition.value === currentCondition) {
      isUpdated = false
    } else if (currentCondition && this.oldCondition.value && currentCondition.isEqual(this.oldCondition.value)) {
      isUpdated = false
    } else {
      isUpdated = true
    }
    if (currentCondition) {
      const form: ObjectClassGridFindForm = {
        condition: currentCondition,
        dataType: this.dataType.value!
      }
      if (direction === 'next') {
        this.emit('findNext', form, isUpdated)
      } else {
        this.emit('findPrev', form, isUpdated)
      }
    }
    this.oldCondition.value = currentCondition
  }

  /**
   * 加载比较符选项
   * @param field 属性字段
   */
  private loadOperatorOptions = (field: MetaProperty) => {
    this.operatorOptions.value = PropertyConverter.getOperatorByField(field)
  }
}

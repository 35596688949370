
import { defineView, ToolStripItemChangeEventArgs, ToolStripItemClickedEventArgs } from '@kmsoft/upf-core'
import { KPartEditPanelEventEmits, KPartEditPanelPropOptions } from './interface'
import KPartEditPanelViewModel from './KPartEditPanelViewModel'

export default defineView({
  name: 'KPartEditPanel',
  props: KPartEditPanelPropOptions,
  emits: KPartEditPanelEventEmits,
  viewModel: KPartEditPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

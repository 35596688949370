import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartDemoPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KPartDemoPropType = ViewPropsTypeExtract<typeof KPartDemoPropOptions>

/** 事件 */
export const KPartDemoEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartDemoEmitsType = ViewEmitsTypeExtract<typeof KPartDemoEventEmits>

import { BaseViewModel, KDataGridRowDoubleClickEvent, ViewModelOptions, utils } from '@kmsoft/upf-core'
import { KBaselineGridEmitsType, KBaselineGridPropType } from './interface'
import { ref } from 'vue'
import { Api, CommonClientSrv, EnumRequestCode, KObjectClassGridViewModel, ObjectToolStripItem } from '@kmsoft/ebf-common'
import { KBaselineEdit } from '../baseline-edit'

/** KBaselineGrid */
export default class KBaselineGridViewModel extends BaseViewModel<KBaselineGridEmitsType, KBaselineGridPropType> {
  refObjGrid = ref<KObjectClassGridViewModel>()
  /**
   * 工具栏定义
   */
  toolItem = ref<Array<ObjectToolStripItem>>()

  constructor(options: ViewModelOptions<KBaselineGridPropType>) {
    super(options)
  }

  viewDidMount() {}

  toolStripItemClicked() {}
  /**
   * 网格加载
   */
  async loadData(qry: any) {
    if (!this.props.primaryObjectId) {
      return {
        rows: [],
        total: 0
      }
    }
    const param = {
      data: [this.props.primaryObjectId!]
    }
    const result = await Api.post('baseline', 'ManagedBaseline', 'listPartBaseline', param)
    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      return {
        rows: result.data,
        total: result.data.length
      }
    }
  }
  onDoubleClick(event: KDataGridRowDoubleClickEvent<any>) {
    CommonClientSrv.openPage(
      '对象浏览',
      KBaselineEdit,
      {
        objParam: {
          id: event.row.id,
          modelCode: 'ManagedBaseline',
          modelGroup: 'baseline'
        }
      },
      utils.uuid()
    )
  }

  getSelectRow() {
    return this.refObjGrid.value?.getSelectedRow()
  }
}

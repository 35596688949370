import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KStructrureGraphEmitsType, KStructrureGraphPropType, DataType, NodeDataType, EdgesDataType } from './interface'
import { ref, getCurrentInstance, Ref, ComponentInternalInstance } from 'vue'
import LogicFlow from '@logicflow/core'
import '@logicflow/core/dist/style/index.css'
import RectNode from './RectNode'

/** KChangeFlow */
export default class KChangeFlowViewModel extends BaseViewModel<KStructrureGraphEmitsType, KStructrureGraphPropType> {
  constructor(options: ViewModelOptions<KStructrureGraphPropType>) {
    super(options)
  }

  lf: Ref<any> = ref()
  flowWidth: number = this.props.width
  flowHeight: number = this.props.height
  refLogicFlow: Ref<any> = ref()
  instance: ComponentInternalInstance | null = getCurrentInstance()

  transformTree = (
    tree: DataType,
    x: number = this.flowWidth / 2 + 50,
    y: number = this.flowHeight / 2 + 50,
    parentId?: number,
    nodes: Array<NodeDataType> = [],
    edges: Array<EdgesDataType> = [],
    isTree?: boolean,
    oldNums?: Record<string, number>
  ) => {
    if (!tree) {
      return {}
    }
    // 储存当前节点的坐标，在出现当前节点的子节点有多个的时候使用
    const oldNum = { x, y }
    let childNumber = 1
    let oldChildNumber = 0
    const { id, children, ...nodeData } = tree
    // 处理当前节点
    const currentNode = {
      id: tree.id,
      type: 'rect-node',
      x,
      y,
      properties: nodeData
    }
    nodes.push(currentNode)
    if (parentId) {
      edges.push({
        type: 'polyline',
        sourceNodeId: parentId,
        targetNodeId: tree.id,
        // 可选的起始点
        ...(isTree && oldNums
          ? {
              startPoint: {
                id: `${parentId}-${tree.id}`,
                x: oldNums.x,
                y: oldNums.y + this.flowHeight / 2
              },
              endPoint: {
                id: `${parentId}-${tree.id}`,
                x: x - this.flowWidth / 2,
                y: y
              }
            }
          : {})
      })
    }
    // 递归处理子节点
    if (tree.children && tree.children.length > 0) {
      const currentX = x + (this.flowWidth * 3) / 2 // 每个子节点向右移动节点宽度的二分之三
      let currentY = y // 同一层级的子节点y坐标相同

      if (tree.children.length == 1) {
        const obj = this.transformTree(tree.children[0], currentX, currentY, currentNode.id, nodes, edges)
        childNumber = obj.childNumber!
      } else {
        tree.children.forEach((child, index) => {
          currentY += (this.flowHeight + 20) * (childNumber - oldChildNumber) // 有多个子节点时子节点向下移动
          const obj = this.transformTree(child, currentX, currentY, currentNode.id, nodes, edges, true, oldNum)
          oldChildNumber = childNumber
          childNumber = childNumber + obj.childNumber!
        })
      }
    }
    return { nodes, edges, childNumber }
  }

  async init() {
    const res = await this.onLoadData()
    const { childNumber, ...nodeData } = this.transformTree(res!)
    this.lf.value = new LogicFlow({
      container: this.refLogicFlow.value,
      isSilentMode: true,
      adjustNodePosition: false,
      stopZoomGraph: true,
      stopScrollGraph: false,
      background: { backgroundColor: '#f3f5f7' },
      ...this.props.config
    })
    RectNode.view.defaultSlot = this.instance?.slots.flowNode
    RectNode.model.width = this.flowWidth
    RectNode.model.height = this.flowHeight
    this.lf.value.register(RectNode)
    if (res) {
      this.lf.value.render(nodeData)
    }
  }

  async onLoadData() {
    if (this.props.loadData) {
      return await this.props.loadData()
    } else {
      return null
    }
  }

  async refresh() {
    const res = await this.onLoadData()
    if (res) {
      RectNode.view.defaultSlot = this.instance?.slots.flowNode
      RectNode.model.width = this.flowWidth
      RectNode.model.height = this.flowHeight
      this.lf.value.register(RectNode)
      const { childNumber, ...nodeData } = this.transformTree(res)
      this.lf.value.render(nodeData)
    }
  }

  viewDidMount() {
    this.init()
  }
}

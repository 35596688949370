
import { EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import KVersionGroupObjectSelectFormViewModel from './KVersionGroupObjectSelectFormViewModel'
import { KVersionGroupObjectSelectFormEventEmits, KVersionGroupObjectSelectFormPropOptions } from './interface'

export default defineView({
  name: 'KVersionGroupObjectSelectForm',
  props: KVersionGroupObjectSelectFormPropOptions,
  emits: KVersionGroupObjectSelectFormEventEmits,
  viewModel: KVersionGroupObjectSelectFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  KDialogClosingEvent,
  ShowDialogFunctionProps,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VueNode,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessBase, ObjBusinessParam } from '../../client-srv'
import { ObjectClassManageQueryParam } from '../object-class-manage'
import { ObjectSearchQueryParam } from '../object-search'
import { PropType } from 'vue-types/dist/types'

/** Dialog 参数 */
export type ObjectSelectDialogParam = Pick<
  ShowDialogFunctionProps,
  | 'title'
  | 'size'
  | 'showApply'
  | 'onClosed'
  | 'state'
  | 'getContainer'
  | 'rootClassName'
  | 'maximizeBox'
  | 'minimizeBox'
  | 'closable'
> &
  Partial<KObjectSelectorPropType> & {
    onClosing: (event: KDialogClosingEvent<IObjectSelectorComponent>) => void
  }

/** 对象查询组件接口 */
export interface IObjectSelectorComponent {
  /** 获取当前选中行所代表的业务对象参数集合 */
  getSelectedObjParams: () => Array<ObjBusinessParam>
  /** 获取当前选中行所代表的业务对象集合 */
  getSelectedObjBussiness: () => Array<ObjBusinessBase>
  /** 获取当前选中行集合 */
  getSelectedRows: () => Array<Record<string, any>>
}

/** 对象查询方式 */
export enum EnumObjectQueryMode {
  /** 对象类型查询 */
  ObjectClassManageQuery = 1,
  /** 对象目录查询 */
  ObjectDirectoryQuery = 2,
  /** 高级搜索 */
  AdvanceSearch = 4,
  /** 最近打开对象 */
  RecentOpenObj = 8,
  /** 所有 */
  All = 1023
}

export const ObjectQueryModeDescriptions = {
  [EnumObjectQueryMode.ObjectClassManageQuery]: '对象类型',
  [EnumObjectQueryMode.ObjectDirectoryQuery]: '高级查询'
  // [EnumObjectQueryMode.AdvanceSearch]: '高级查询'
  // [EnumObjectQueryMode.RecentOpenObj]: '最近打开对象'
  //  [EnumObjectQueryMode.MyFlowTask]: '我的工作流任务',
  //  [EnumObjectQueryMode.ObjSearchList]: '搜索列表',
  //  [EnumObjectQueryMode.RegionShareToMe]: '共享给我的数据'
}

/** KObjectSelector 参数 **/
export const KObjectSelectorPropOptions = {
  ...BaseViewPropOptions,
  /** 是否允许多选 */
  isMultipleSelection: VuePropTypes.bool().def(false),
  /** 支持的查询模式 */
  supportedQueryMode: VuePropTypes.createType<EnumObjectQueryMode>().def(EnumObjectQueryMode.All),
  /** 默认查询方式 */
  objectQueryMode: VuePropTypes.createType<EnumObjectQueryMode>().def(EnumObjectQueryMode.ObjectDirectoryQuery),
  /** 对象分类查询参数 */
  objectClassManageQueryParam: VuePropTypes.createType<ObjectClassManageQueryParam>().def(),
  /** 高级搜索组件属性 */
  objectSearchQueryParam: VuePropTypes.createType<ObjectSearchQueryParam>().def()
}

/** KObjectSelector 参数类型 **/
export type KObjectSelectorPropType = ViewPropsTypeExtract<typeof KObjectSelectorPropOptions>

/** KObjectSelector 事件 */
export const KObjectSelectorEventEmits = {
  ...BaseViewEventEmits
}

/** KObjectSelector 事件类型 **/
export type KObjectSelectorEventEmitsType = ViewEmitsTypeExtract<typeof KObjectSelectorEventEmits>

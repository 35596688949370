import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  ToolStripItemClickedEventArgs,
  TreeViewMenuEventArgs,
  KTreeViewPropOptions,
  KTreeViewEventEmits
} from '@kmsoft/upf-core'
import { PartStructureTreeNode } from './nodes'
import { StructureView } from '../../client-srv'
import { EnumPartToolStripItemKeys } from '../toolstrip'

//#region 事件
export type PartStructureQueryRule = {
  type: string
}

export type PartStructureTreeMenuItemClickedEvent = ToolStripItemClickedEventArgs & {
  objParams: ObjBusinessParam[]
}
//#endregion

/** 参数 **/
export const KPartStructureTreePropOptions = {
  ...KTreeViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam & { folderId?: string }>().def(),
  /** 视图名称（筛选器：最新、最新有效、基线、时间有效性） */
  viewName: VuePropTypes.string().def(''),
  /** 视图名称（视图：设计、制造） */
  partViewName: VuePropTypes.string().def(''),
  /** 过滤名称 */
  filterName: VuePropTypes.string().def(''),
  /** 是否禁用右键菜单 */
  disabledContextMenu: VuePropTypes.bool().def(false),
  /**展开模式参数 */
  partStructureViewMode: VuePropTypes.createType<StructureView>().def(),
  /** 找上一处 */
  findPrevious: VuePropTypes.func().def((value: any) => value),
  /** 找下一处 */
  findNext: VuePropTypes.func().def((value: any) => value),
  /** 清除查询节点 */
  clearQueryNode: VuePropTypes.func().def(() => true),
  /** 选中全部 */
  selectAll: VuePropTypes.func().def((value: any) => value),
  /** 取消选中全部 */
  cancelSelectAll: VuePropTypes.func().def(() => true),
  /** 清洗数据 */
  refershSearchData: VuePropTypes.func().def(() => true),
  /** 最上 */
  findTop: VuePropTypes.func().def((value: any) => value),
  /** 最下 */
  findBottom: VuePropTypes.func().def((value: any) => value),
  /** 时间有效性 */
  datedValue: VuePropTypes.string().def('')
}

/** 参数类型 **/
export type KPartStructureTreePropType = ViewPropsTypeExtract<typeof KPartStructureTreePropOptions>

/** 事件 */
export const KPartStructureTreeEventEmits = {
  ...KTreeViewEventEmits,
  /** 数据更新事件 */
  nodeRefresh: () => true,
  /** 右键菜单点击事件 */
  contextMenuItemClicked: (event: PartStructureTreeMenuItemClickedEvent) => true,
  /** 菜单弹出之前 */
  beforeContextMenuPopup: (event: TreeViewMenuEventArgs<PartStructureTreeNode>) => true,
  /** 刷新完成后 */
  refresh: () => true,
  /**工具栏点击事件 */
  toolStripClicked: (event: EnumPartToolStripItemKeys) => true
}

/** 事件类型 **/
export type KPartStructureTreeEmitsType = ViewEmitsTypeExtract<typeof KPartStructureTreeEventEmits>

import { AppContext, BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KSsoLoginEmitsType, KSsoLoginPropType } from './interface'

/** KSsoLogin */
export default class KSsoLoginViewModel extends BaseViewModel<KSsoLoginEmitsType, KSsoLoginPropType> {
  constructor(options: ViewModelOptions<KSsoLoginPropType>) {
    super(options)
  }

  viewDidMount() {}

  getProperty(key: string) {
    return AppContext.current.getEnvironment().getProperty(key)
  }
}

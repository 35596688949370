
import {
  EnumDataGridRowModelType,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  defineView
} from '@kmsoft/upf-core'
import { KPlanEditPanelPropOptions, KPlanEditPanelEventEmits } from './interface'
import KPlanEditPanelViewModel from './KPlanEditPanelViewModel'
import { clsCodeMap } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KPlanEditPanel',
  props: KPlanEditPanelPropOptions,
  emits: KPlanEditPanelEventEmits,
  viewModel: KPlanEditPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      clsCodeMap,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})

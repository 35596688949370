
import { defineView } from '@kmsoft/upf-core'
import { KRulePropertyPropOptions, KRulePropertyEventEmits } from './interface'
import KRulePropertyViewModel from './KRulePropertyViewModel'

export default defineView({
  name: 'KRuleProperty',
  props: KRulePropertyPropOptions,
  emits: KRulePropertyEventEmits,
  viewModel: KRulePropertyViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

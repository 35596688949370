import {
  BaseViewModel,
  EnumItemChangeCancelType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KChangeActivityEditEmitsType, KChangeActivityEditPropType } from './interface'
import { ref } from 'vue'
import {
  Api,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPropertyEditPanelViewModel,
  ObjBusinessParam,
  ObjectToolStripItem,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import KChangeActivityUpdateCompare from '../change-activity-update-compare'
import { WorkflowClientSrv } from '../../../../../ebf-workflow/src/client-srv'

/** KChangeActivityEdit */
export default class KChangeActivityEditViewModel extends BaseViewModel<
  KChangeActivityEditEmitsType,
  KChangeActivityEditPropType
> {
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  refContainer = ref<HTMLElement>()
  taskParam = ref<ObjBusinessParam>()
  originFormData = ref<any>()
  toolStripItems = ref<ObjectToolStripItem[]>(ToolStripHelper.getChangeActivityEditToolStripItems())

  constructor(options: ViewModelOptions<KChangeActivityEditPropType>) {
    super(options)
    this.taskParam.value = options.props.objParam
  }

  viewDidMount() {}

  async loadData() {
    const result = (await Api.post('change', 'ChangeActivity', 'get', { data: [this.props.objParam.id] })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      const data = result.data

      if (data.lifecycleStateCode === 'RELEASED' || data.lifecycleStateCode === 'UNDERREVIEW') {
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW, true)
        this.disabledEdit()
      }
      if (data.needDate) {
        data.needDate = data.needDate.slice(0, 10)
      }
      this.originFormData.value = data
      return data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message,
        details: result.detail
      })
      return
    }
  }

  /** 禁用编辑**/
  disabledEdit() {
    this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
    this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
  }

  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const buttonName = event.name as EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE
    const method = this[buttonName]
    if (typeof method === 'function') {
      method.call(this, event)
    }
  }

  /** 保存 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE](event: ToolStripItemClickedEventArgs) {
    this.save()
    // this.refObjectProperty.value?.setReadOnly(true)
  }

  /** 刷新 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH](event: ToolStripItemClickedEventArgs) {
    this.refObjectProperty.value?.refresh()
  }

  /** 变更差异 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHANGE_ACTIVITY_UPDATE_DIFF]() {
    KDialog.show({
      title: '变更差异',
      size: {
        width: document.documentElement.clientWidth - 100,
        height: document.documentElement.clientHeight - 100
      },
      props: {
        sourceData: this.originFormData.value.plmChangeBeforeTargetViews,
        targetData: this.originFormData.value.plmChangeAfterTargetViews
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      showConfirm: false,
      getContainer: this.refContainer.value,
      content: KChangeActivityUpdateCompare,
      onClosing: async (event: KDialogClosingEvent) => {
        // if (event.dialogResult == EnumDialogResult.Cancel) return
        // if (event.dialogResult == EnumDialogResult.Close) return
        // if (await formViewModel.save()) {
        //   this.refObjGrid.value?.refresh()
        // } else {
        //   event.cancel = true
        // }
      }
    })
  }

  /** 加入流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.addWorkflow(
      this.taskParam.value!.id,
      'ChangeActivity',
      this.originFormData.value.lifecycleStateCode == 'RELEASED' ? 'Released' : '',
      this.originFormData.value.name,
      this.originFormData.value.number
    )
  }

  /** 查看流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.viewWorkflow(this.taskParam.value!.id, 'ChangeActivity')
  }

  /** 修改状态 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_UPDATE_STATE](event: ToolStripItemClickedEventArgs) {
    KNotification.info({
      message: '功能正在开发中，敬请期待'
    })
  }

  async onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    if (event.name == EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY) {
      if (event.value) {
        this.refObjectProperty.value?.setReadOnly(false)
      } else {
        if (await this.save(false)) {
          this.refObjectProperty.value?.setReadOnly(true)
          event.itemCancelType = EnumItemChangeCancelType.CancelAll
        }
      }
    }
  }
  async validate() {
    return this.refObjectProperty.value?.validate()
  }
  async save(isSave: boolean = true) {
    if (await this.validate()) {
      const modifiedValue = this.refObjectProperty.value?.getModifiedValue() as any
      const value = this.refObjectProperty.value?.getValue() as any
      //如果有修改对
      if (Object.keys(modifiedValue).length) {
        // 附件
        if (Object.keys(modifiedValue).includes('secondary')) {
          modifiedValue.secondaryIds = [...modifiedValue.secondary]
          Reflect.deleteProperty(modifiedValue, 'secondary')
        }

        if (Object.keys(value).includes('plmChangeBeforeTargetViews')) {
          modifiedValue!.changeBeforeLinkViews = (value!.plmChangeBeforeTargetViews as any[]).map(item => {
            return {
              masterId: item?.master?.id,
              branchId: item?.branch?.id,
              target: {
                id: item.id,
                className: item.className,
                rdmExtensionType: item.rdmExtensionType
              }
            }
          })

          Reflect.deleteProperty(modifiedValue, 'plmChangeBeforeTargetViews')
        }

        if (Object.keys(value).includes('plmChangeAfterTargetViews')) {
          modifiedValue!.changeAfterLinkViews = (value!.plmChangeAfterTargetViews as any[]).map(item => {
            return {
              masterId: item?.master?.id,
              target: {
                id: item?.branch?.id,
                rdmExtensionType: item?.branch?.rdmExtensionType
              }
            }
          })

          Reflect.deleteProperty(modifiedValue, 'plmChangeAfterTargetViews')
        }

        const params = {
          data: [
            {
              id: this.props.objParam.id,
              ...modifiedValue
            }
          ]
        }

        const result = await Api.post('change', 'ChangeActivity', 'update', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refObjectProperty.value?.refresh()
          KNotification.success('操作成功！')
          return true
        } else {
          KNotification.error({
            title: '系统错误',
            content: result.message
          })
          return false
        }
      } else {
        if (isSave) {
          KNotification.warn({
            message: '系统提示',
            description: '没有修改数据'
          })
        }

        return true
      }
    } else {
      return false
    }
  }
}

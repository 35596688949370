import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_property_edit_panel = _resolveComponent("k-object-property-edit-panel")!

  return (_openBlock(), _createBlock(_component_k_object_property_edit_panel, {
    ref: _ctx.$vm.refObjectProperty,
    readonly: true,
    objParam: _ctx.$vm.taskParam,
    load: _ctx.$vm.loadData,
    toolStripItems: _ctx.$vm.toolStripItems,
    onToolStripItemClicked: _ctx.$vm.onToolStripItemClicked,
    onToolStripItemChange: _ctx.$vm.onToolStripItemChange
  }, null, 8, ["objParam", "load", "toolStripItems", "onToolStripItemClicked", "onToolStripItemChange"]))
}
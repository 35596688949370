import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"padding-bottom":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    model: _ctx.$vm.formState,
    "label-col": { span: 6 },
    "wrapper-col": { span: 16 }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, {
        name: "format",
        label: "显示格式"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_select, {
            value: _ctx.$vm.formState!.format,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formState!.format) = $event)),
            options: _ctx.dateFormatOptions,
            readonly: _ctx.readonly,
            onChange: _ctx.onDateFormatChange
          }, null, 8, ["value", "options", "readonly", "onChange"]),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.dateDescribe), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, {
        name: "sample",
        label: "示例"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formState!.sample,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formState!.sample) = $event)),
            disabled: true,
            readonly: _ctx.readonly
          }, null, 8, ["value", "readonly"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
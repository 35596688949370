
import { defineView } from '@kmsoft/upf-core'
import { KDocCreatePanelEventEmits, KDocCreatePanelPropOptions } from './interface'
import KDocCreatePanelViewModel from './KDocCreatePanelViewModel'
import KDocForm from './doc-form'
export default defineView({
  name: 'KDocCreatePanel',
  props: KDocCreatePanelPropOptions,
  emits: KDocCreatePanelEventEmits,
  viewModel: KDocCreatePanelViewModel,
  components: { KDocForm },
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

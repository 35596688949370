import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KWorkflowSigningHistoryEmitsType, KWorkflowSigningHistoryPropType } from './interface'
import { ref } from 'vue'
import { Api, ClassMetaClientSrv, EnumRequestCode, ObjectClientSrv } from '@kmsoft/ebf-common'
import { watch } from 'vue'

/** KWorkflowSigningHistory */
export default class KWorkflowSigningHistoryViewModel extends BaseViewModel<
  KWorkflowSigningHistoryEmitsType,
  KWorkflowSigningHistoryPropType
> {
  signingHistoryList = ref<any>([])
  flowDetailData = ref<any>({})
  loadingData = ref<boolean>(false)
  constructor(options: ViewModelOptions<KWorkflowSigningHistoryPropType>) {
    super(options)
    watch(
      () => this.props.objParam,
      newVal => {
        this.getSigningHistoryData()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {
    // this.getSigningHistoryData()
  }

  async getSigningHistoryData() {
    // 临时处理 如果传入的是Document或者Part，并且this.props.objParam?.branchId为空，则调用接口获取数据后再查询
    const group = await ObjectClientSrv.getModelGroupByCode(this.props.objParam.modelCode)
    //const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(this.props.objParam.modelCode)
    let designerCode =
      this.props.objParam.modelCode == 'PromoteActivity'
        ? this.props.objParam.modelCode
        : await ClassMetaClientSrv.getDesignerEntityCode(this.props.objParam.modelCode)

    if ((designerCode == 'Document' || designerCode == 'Part') && !this.props.objParam?.branchId) {
      const result = await Api.post(group!, designerCode, 'get', { data: [this.props.objParam.id] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        this.props.objParam.branchId = result.data.branch.id
      } else {
        KNotification.error({
          title: '获取失败',
          content: result.message,
          details: result.detail
        })
        return
      }
    }
    const workObjId =
      designerCode == 'Document' || designerCode == 'Part' ? this.props.objParam?.branchId : this.props.objParam?.id

    designerCode =
      designerCode == 'Part'
        ? this.props.objParam.modelCode
        : await ClassMetaClientSrv.getDesignerEntityCode(this.props.objParam.modelCode)
    const signingHistoryPara = {
      workObjId: workObjId,
      workObjClsCode: designerCode
    }
    Api.post('official', 'ProcessContentService', 'listHistoryProcess', { data: [signingHistoryPara] }).then(result => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        const data =
          (result.data || []).map((item: any) => {
            item.id = item.processInstanceId
            return item
          }) || []

        this.signingHistoryList.value = data

        if (this.signingHistoryList.value.length) {
          this.rowChanged(this.signingHistoryList.value[0])
        }
      } else {
        KNotification.error({
          title: '获取失败',
          content: result.message,
          details: result.detail
        })
      }
    })
  }

  rowChanged(row: any) {
    this.getFlowDetailData(row.processInstanceId)
  }

  /** 获取流程详情 **/
  getFlowDetailData(processInstanceId: string) {
    this.loadingData.value = true
    Api.post('official', 'ProcessInstanceService', 'getProcessInstance', {
      data: [processInstanceId]
    })
      .then((result: any) => {
        this.loadingData.value = false
        if (result && result.code == EnumRequestCode.SUCCESS) {
          const data = result.data || {}
          // 流程节点的执行人添加id，否则列表显示异常
          data.processNodes = data?.processNodes.map((item: any) => {
            item.id = Math.random()
            return item
          })
          this.flowDetailData.value = {
            ...result.data,
            taskId: '',
            currentSteps: data.endTime != null ? '流程已结束' : data.tasks[0]?.taskName
          }
        } else {
          KNotification.error({
            title: '获取失败',
            content: result.message,
            details: result.detail
          })
        }
      })
      .catch(_ => (this.loadingData.value = false))
  }
}

// import { KPackage } from '@kmplm/ecol-base'

// export * from './components'
// export * from './client-srv'

// import { createVNode } from 'vue'
// import * as Components from './components'
// import { controlEntry } from './ControlEntry'
// //启动项
// import { startup } from './Startup'

// //默认导出当前包对象
// const projectManagePackage: KPackage = {
//   controlEntry: controlEntry,
//   configService: iocContainer => startup.configService(iocContainer),
//   components: Components,
//   runners: [startup],
//   slots: []
// }
// export default projectManagePackage

import { UPF_IOC_KEYS, IAppConfigurer, IocContainer, IMenuPagePathMapping, IMenuProvider } from '@kmsoft/upf-core'
import { App } from 'vue'
import { ProjectManageMenuMapping, ProjectManageRunner } from './beans'

export default {
  configVue: (app: App) => {},
  configBean: (iocContainer: IocContainer) => {
    iocContainer.registerSingleton<IMenuPagePathMapping>(UPF_IOC_KEYS.MENU_PAGE_PATH_MAPPING, new ProjectManageMenuMapping())
    iocContainer.registerSingleton(UPF_IOC_KEYS.APP_RUNNER, new ProjectManageRunner())
  }
} as IAppConfigurer

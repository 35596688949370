import { createVNode, VNode } from 'vue'
import { KDataGridColumn, KDataGridTextBoxColumn } from '@kmsoft/upf-core'
import * as ClientSrv from '../../../../client-srv'
import { ObjectClassGridBaseResolveCustomFilterParams } from '../interface'

/**
 * 构建自定义过滤器
 * @param params 参数
 */
export const resolveCustomFilter = (params: ObjectClassGridBaseResolveCustomFilterParams): VNode | KDataGridColumn => {
  /** 获取过滤器 */
  // const filter = ClientSrv.EditorFactory.getFilter(params.field)

  // if (!filter) {
  //   return <p>过滤器未定义</p>
  // }

  // /** 过滤器控件属性 */
  // const commonEditorInstanceValues: editors.KFieldFilterEditorBaseAllProps = {
  //   field: params.field,
  //   editParam: params.field.editParam || {},
  //   environment: editors.EnumEditorEnvironment.Filter,
  //   value: undefined,
  //   host: params.methods as any
  // }

  // return createVNode(filter, commonEditorInstanceValues)
  return createVNode('')
}


import { EnumDataGridRowModelType, EnumDividerType, defineView } from '@kmsoft/upf-core'
import { KWorkspaceListPropOptions, KWorkspaceListEventEmits } from './interface'
import { UserClientSrv } from '../../../../client-srv'
import KWorkspaceListViewModel from './KWorkspaceListViewModel'
import { EnumPurviewCode } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KWorkspaceList',
  props: KWorkspaceListPropOptions,
  emits: KWorkspaceListEventEmits,
  viewModel: KWorkspaceListViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      UserClientSrv,
      EnumPurviewCode,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      vertical: EnumDividerType.VERTICAL
    }
  }
})

import { BaseViewModel, IKTreeNode, ViewModelOptions } from '@kmsoft/upf-core'
import { KViewEventEditPanelPropType, KViewEventEditPanelEventEmitType } from './interface'
import { ref } from 'vue'
import { commonScript, exampleScript, otherScript } from '../consts'
import { convertContextItemToNode } from '../utils'
import { KMonacoEditorViewModel } from '@kmsoft/upf-core'

/** 导航管理 */
export default class KViewEventEditPanelViewModel extends BaseViewModel<
  KViewEventEditPanelEventEmitType,
  KViewEventEditPanelPropType
> {
  /** 代码编辑器引用 */
  refMonacoEditor = ref<KMonacoEditorViewModel>()
  /** 脚本 */
  scriptValue = ref(this.props.script)
  constructor(options: ViewModelOptions<KViewEventEditPanelPropType>) {
    super(options)
  }
  getValue = () => {
    return this.scriptValue.value
  }
  /**
   * 获取脚本列表
   * @returns 脚本节点
   */
  onLoadData = () => {
    /** 加载上下文脚本列表 */
    const contextScript = this.props.loadContext ? this.props.loadContext() : []
    /** 上下文节点 */
    const contextNode: IKTreeNode = {
      id: 'context-group',
      iconType: 'database',
      name: '业务上下文',
      children: contextScript.map(a => convertContextItemToNode(a))
    }
    /** 通用节点 */
    const commonNode: IKTreeNode = {
      id: 'common-group',
      iconType: 'api',
      name: '通用脚本',
      children: commonScript.map(a => convertContextItemToNode(a))
    }
    /** 样例节点 */
    const exampleNode: IKTreeNode = {
      id: 'example-group',
      iconType: 'alert',
      name: '样例脚本',
      children: exampleScript.map(a => convertContextItemToNode(a))
    }
    /** 其他节点 */
    const otherNode: IKTreeNode = {
      id: 'other-group',
      iconType: 'script',
      name: '其他',
      children: otherScript.map(a => convertContextItemToNode(a))
    }
    return [contextNode, commonNode, exampleNode, otherNode]
  }
}

import { AgentManager } from './AgentManager'
import { AgentStaticObject } from './AgentStaticObject'

import { BindingFlags } from './system/reflection/BindingFlags'
import { AgentEventHandler } from './kmsoft/agent/AgentEventHandler'

/**代理端对象。*/
export class AgentInstanceObject extends AgentStaticObject {
  protected static type: string = 'KMSoft.Agent.AgentInstanceObject'

  //#region 构造函数
  protected static constructorDisabled: boolean = false
  /**缺省构造函数。*/
  constructor(id: string) {
    super()
    this.id = id
    AgentInstanceObject.registry.register(this, this.id, this)
  }
  /**创建新实例。
   * @param id 实例 Id。
   * @returns 新实例。*/
  protected static createProtected<T extends AgentInstanceObject>(id: string): T {
    this.constructorLocked = false
    try {
      return new this(id) as T
    } finally {
      this.constructorLocked = true
    }
  }

  //析构函数
  private static registry = new FinalizationRegistry(heldValue => AgentManager.disposeInstance(heldValue as string))
  //#endregion

  //#region 属性
  /**Id。*/
  private id: string

  /**返回对象的字符串形式。*/
  public toJSON(): any {
    return { __type: AgentInstanceObject.type, __object: { id: this.id } }
  }
  //#endregion

  //#region 对象操作
  /**创建对象。
   * @param parameters 参数。
   * @returns 对象。*/
  protected static async createInstance<T extends AgentInstanceObject>(...parameters: any[]): Promise<T> {
    return AgentManager.createInstance(this.type, parameters)
  }

  /**创建对象并包装。
   * @param parameters 参数。
   * @returns 对象。*/
  protected static async createInstanceAndWrap<T extends AgentInstanceObject>(...parameters: any[]): Promise<T> {
    return this.createProtected<T>(await AgentManager.createInstanceAndWrap(this.type, parameters))
  }

  /**销毁对象。
   * @returns 若成功销毁对象，为 true，否则，为 false。*/
  protected async disposeInstance(): Promise<boolean> {
    const result = await AgentManager.disposeInstance(this.id)
    AgentInstanceObject.registry.unregister(this)
    return result
  }

  /**调用指定方法。
   * @param name 方法名称。
   * @param parameters 方法参数。
   * @returns 方法返回值。*/
  protected async invokeMethod<T>(name: string, ...parameters: any[]): Promise<T> {
    return AgentManager.invokeMember<T>(this.id, name, BindingFlags.InvokeMethod, parameters)
  }

  /**调用指定方法并包装。
   * @param name 方法名称。
   * @param parameters 方法参数。
   * @returns 方法返回值。*/
  protected async invokeMethodAndWrap<T extends AgentInstanceObject>(
    type: typeof AgentInstanceObject,
    name: string,
    ...parameters: any[]
  ): Promise<T> {
    return type.createProtected<T>(await AgentManager.invokeMemberAndWrap(this.id, name, BindingFlags.InvokeMethod, parameters))
  }

  /**获取或设置指定属性值。
   * @param name 属性名称。
   * @param value 属性值。
   * @returns 属性值。*/
  protected async property<T>(name: string, value: T = AgentStaticObject.None): Promise<T> {
    if (value === AgentStaticObject.None) return AgentManager.invokeMember<T>(this.id, name, BindingFlags.GetProperty, [])
    await AgentManager.invokeMember(this.id, name, BindingFlags.SetProperty, [value])
    return value
  }

  /**获取指定属性值并包装。
   * @param name 属性名称。
   * @param value 属性值。
   * @returns 属性值。*/
  protected async propertyAndWrap<T extends AgentInstanceObject>(
    type: typeof AgentInstanceObject,
    name: string,
    value: T = AgentStaticObject.None
  ): Promise<T> {
    if (value === AgentStaticObject.None)
      return type.createProtected<T>(await AgentManager.invokeMemberAndWrap(this.id, name, BindingFlags.GetProperty, []))
    await AgentManager.invokeMember(this.id, name, BindingFlags.SetProperty, [value])
    return value
  }

  /**获取或设置指定字段值。
   * @param name 字段名称。
   * @param value 字段值。
   * @returns 字段值。*/
  protected async field<T>(name: string, value: T = AgentStaticObject.None): Promise<T> {
    if (value === AgentStaticObject.None) return AgentManager.invokeMember<T>(this.id, name, BindingFlags.GetField, [])
    await AgentManager.invokeMember<T>(this.id, name, BindingFlags.SetField, [value])
    return value
  }

  /**获取指定字段值并包装。
   * @param name 字段名称。
   * @param value 字段值。
   * @returns 字段值。*/
  protected async fieldAndWrap<T extends AgentInstanceObject>(
    type: typeof AgentInstanceObject,
    name: string,
    value: T = AgentStaticObject.None
  ): Promise<T> {
    if (value === AgentStaticObject.None)
      return type.createProtected<T>(await AgentManager.invokeMemberAndWrap(this.id, name, BindingFlags.GetField, []))
    await AgentManager.invokeMember<T>(this.id, name, BindingFlags.SetField, [value])
    return value
  }
  //#endregion

  //#region 对象事件
  /**附加或移除事件处理程序。
   * @param name 事件名称。
   * @param handler 事件处理程序。
   * @returns 事件处理程序 Id。*/
  protected async eventHandler(name: string, handler: AgentEventHandler | string): Promise<string> {
    if (typeof handler !== 'string') return AgentManager.addEventHandler(this.id, name, handler)
    await AgentManager.removeEventHandler(this.id, name, handler)
    return undefined!
  }
  //#endregion
}

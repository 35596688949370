import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KDistributionCreatePropOptions = {
  ...BaseViewPropOptions,
  formValue: VuePropTypes.createType<any>().def(),
  changeBeforeData: VuePropTypes.createType<any[]>().def(),
  changeAfterData: VuePropTypes.createType<any[]>().def()
}

/** 参数类型 **/
export type KDistributionCreatePropType = ViewPropsTypeExtract<typeof KDistributionCreatePropOptions>

/** 事件 */
export const KDistributionCreateEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDistributionCreateEmitsType = ViewEmitsTypeExtract<typeof KDistributionCreateEventEmits>


import { defineView } from '@kmsoft/upf-core'
import { KProductNeedsCreatePropOptions, KProductNeedsCreateEventEmits } from './interface'
import KProductNeedsCreateViewModel from './KProductNeedsCreateViewModel'

export default defineView({
  name: 'KProductNeedsCreate',
  props: KProductNeedsCreatePropOptions,
  emits: KProductNeedsCreateEventEmits,
  viewModel: KProductNeedsCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

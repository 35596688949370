
import { ConstructorType, defineView } from '@kmsoft/upf-core'
import { KAutoCompleteSelectorViewModel } from './KAutoCompleteSelectorViewModel'
import {
  KAutoCompleteSelectorEventEmits,
  KAutoCompleteSelectorLoadDataFnParam,
  KAutoCompleteSelectorOption,
  KAutoCompleteSelectorPropOptions,
  KSelectPagination
} from './interface'
/**
 * 实时搜索选择器，包含如下功能：
 * 1. 单选、多选
 * 2. 快速检索
 * 3. 分页或一次性加载
 */

/**
 *  实时搜索选择器
 */
export default defineView({
  name: 'KAutoCompleteSelector',
  viewModel: KAutoCompleteSelectorViewModel as ConstructorType<KAutoCompleteSelectorViewModel>,
  components: {},
  emits: KAutoCompleteSelectorEventEmits,
  props: KAutoCompleteSelectorPropOptions,
  setup(props, { vm }) {
    /**
     * 下拉选中事件
     */
    const onSelect = (value: string, option: KAutoCompleteSelectorOption) => {
      let tempOption = vm.getValidSelectOptions(option) as KAutoCompleteSelectorOption
      /** 单选模式下，选中后关闭下拉框 */
      if (!props.isMultipleSelection) {
        // vm.isShowOption.value = false
        // vm.refSelect.value?.blur()
      }

      vm.emit('select', value, tempOption)
    }
    /**
     * 获取焦点后的回调
     */
    const onFocus = () => {
      if (props.isShowOptionOnFocus) {
        vm.isShowOption.value = true
      }
      vm.emit('focus')
    }
    /**
     * 焦点离开后的回调
     */
    const onBlur = () => {
      vm.isShowOption.value = false
      vm.resetOptions()
      vm.emit('blur')
    }
    /**
     * 搜索事件（防抖 300ms）
     */
    const onSearch = (value: string) => {
      vm.searchValue.value = value
      vm.isShowOption.value = true
      vm.emit('search', value)
      /** 输入检索值且非一次性加载时重新查询候选项 */
      if (value && !props.isOneTimeLoad) {
        __loadDataBySearch(value)
        return
      }
      /** 清空检索值时恢复原始候选项 */
      if (!value) {
        if (props.isShowOptionOnFocus || props.isOneTimeLoad) {
          vm.resetOptions()
          return
        }
        vm.isShowOption.value = false
      }
    }
    /**
     * 选中改变事件(选中和取消选中触发)
     * @param value 实际值（属性id）
     * @param option 选中的选项
     */
    const onChange = (
      args: { newValue: string | Array<string> },
      option: KAutoCompleteSelectorOption | Array<KAutoCompleteSelectorOption>
    ) => {
      let selectOption = null as null | KAutoCompleteSelectorOption | Array<KAutoCompleteSelectorOption>
      vm.localValue.value = args.newValue
      if (props.isMultipleSelection) {
        vm.selectOption.value = vm.localOptions.value.filter(o => (args.newValue as Array<string>).includes(o.value))
      } else {
        vm.selectOption.value = vm.localOptions.value.find(o => o.value == (args.newValue as string)) || null
      }
      vm.emit('change', selectOption)
      vm.emit('update:value', args.newValue)
    }
    /**
     * 下拉菜单滚动条移动事件
     */
    const onPopupScroll = (e: Record<string, any>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        /** 滚动条到底部时，递增页索引并再次查询 */
        if (!props.isOneTimeLoad && vm.isObject(vm.localPagination.value) && !vm.searchValue.value) {
          let tempPagination = vm.localPagination.value as KSelectPagination
          if (tempPagination.pageIndex < vm.pageNumber.value) {
            tempPagination.pageIndex++
            vm.loadDataByScroll()
          }
        }
      }
    }
    /**
     * 根据输入项进行本地筛选
     * @param searchValue 搜索条件
     * @param option 候选项
     */
    const searchControl = (searchValue: string, option: KAutoCompleteSelectorOption): boolean => {
      /** 候选项非一次性加载时,通过检索会重新触发loadData，因此无需在这里过滤*/
      if (!props.isOneTimeLoad) return true
      return false
    }

    /**
     * 通过检索值查询
     */
    const __loadDataBySearch = (searchValue: string) => {
      let qry = {
        searchValue
      } as KAutoCompleteSelectorLoadDataFnParam
      if (vm.isObject(vm.localPagination.value)) {
        qry.page = { pageIndex: 1, pageSize: (vm.localPagination.value as KSelectPagination).pageSize }
      }
      props.loadData!(qry).then(res => {
        vm.localOptions.value = res.options
      })
    }

    /**
     * 未找到符合条件的候选项时出现的提示信息
     */
    const notFoundContent = () => {
      return `无匹配数据`
    }

    return {
      onSelect,
      onFocus,
      onBlur,
      onSearch,
      onChange,
      onPopupScroll,
      searchControl,
      notFoundContent
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KBaselineCompareGridDialogPropOptions, KBaselineCompareGridDialogEventEmits } from './interface'
import KBaselineCompareGridDialogViewModel from './KBaselineCompareGridDialogViewModel'
import { EnumLifecycleState, EnumLifecycleStateColor } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KBaselineCompareGridDialog',
  props: KBaselineCompareGridDialogPropOptions,
  emits: KBaselineCompareGridDialogEventEmits,
  viewModel: KBaselineCompareGridDialogViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumLifecycleState, EnumLifecycleStateColor }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KImportItemView from './KImportItem.vue'
import KImportItemViewModel from './KImportItemViewModel'

const KImportItem = connect(KImportItemView, KImportItemViewModel)

export default withInstall(KImportItem)
export { KImportItem, KImportItemView, KImportItemViewModel }

// 模板生成文件
// export * from './{{folderName}}'

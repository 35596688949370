import { connect, withInstall } from '@kmsoft/upf-core'
import KCategorySelectorView from './KCategorySelector.vue'
import KCategorySelectorViewModel from './categorySelectorViewModel'

const KCategorySelector = connect(KCategorySelectorView, KCategorySelectorViewModel)

export default withInstall(KCategorySelector)
export { KCategorySelector, KCategorySelectorView, KCategorySelectorViewModel }

// 模板生成文件
// export * from './{{folderName}}'

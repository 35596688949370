import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KFlowFormPropOptions = {
  ...BaseViewPropOptions,
  /**类型 */
  type: VuePropTypes.createType<'submit' | 'end'>().def('submit'),
  taskId: VuePropTypes.string().def('00e07ad1-2a01-11ef-8ac3-46ba9817b75e')
}

/** 参数类型 **/
export type KFlowFormPropType = ViewPropsTypeExtract<typeof KFlowFormPropOptions>

/** 事件 */
export const KFlowFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KFlowFormEmitsType = ViewEmitsTypeExtract<typeof KFlowFormEventEmits>

/**
 * 评审建议
 */
export enum ReviewCommentsEnum {
  AGREE = '1',
  REJECT = '2'
}
export interface IValueDesc {
  label: string
  value: string
}

export interface WfNode {
  /**
   * 节点key
   */
  key: string

  /**
   * 节点名称
   */
  name: string

  /**
   * 连线名称
   */
  flowName: string

  /**
   * 条件表达式
   */
  conditionExpression: string

  /**
   * 是否为回退
   */
  rollback: boolean
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KPersonalElectronicDistributionView from './KPersonalElectronicDistribution.vue'
import KPersonalElectronicDistributionViewModel from './KPersonalElectronicDistributionViewModel'

const KPersonalElectronicDistribution = connect(KPersonalElectronicDistributionView, KPersonalElectronicDistributionViewModel)

export default withInstall(KPersonalElectronicDistribution)
export { KPersonalElectronicDistribution, KPersonalElectronicDistributionView, KPersonalElectronicDistributionViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KCustomerNeedsCreateView from './KCustomerNeedsCreate.vue'
import KCustomerNeedsCreateViewModel from './KCustomerNeedsCreateViewModel'

const KCustomerNeedsCreate = connect(KCustomerNeedsCreateView, KCustomerNeedsCreateViewModel)

export default withInstall(KCustomerNeedsCreate)
export { KCustomerNeedsCreate, KCustomerNeedsCreateView, KCustomerNeedsCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  KTreeViewPropOptions,
  KTreeViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  BaseViewPropOptions,
  TreeViewLoadDataFunc
} from '@kmsoft/upf-core'
import { KRequireWorkspacePropOptions } from '../interface'

/** 参数 **/
export const KRequireManagePropOptions = {
  ...KTreeViewPropOptions,
  ...BaseViewPropOptions,
  ...KRequireWorkspacePropOptions,
  folderId: VuePropTypes.string().def(),
  /** 展开节点时加载子节点的数据库请求方法，返回promise **/
  loadData: VuePropTypes.func<TreeViewLoadDataFunc>().def()
}

/** 参数类型 **/
export type KRequireManagePropType = ViewPropsTypeExtract<typeof KRequireManagePropOptions>

/** 事件 */
export const KRequireManageEventEmits = {
  ...KTreeViewEventEmits
}

/** 事件类型 **/
export type KRequireManageEmitsType = ViewEmitsTypeExtract<typeof KRequireManageEventEmits>

import { BaseViewModel, IKDataGrid, KDataGridSelectionChangedEvent, KNotification } from '@kmsoft/upf-core'
import { KObjSelectPanelPropType, KObjSelectPanelPropsEventEmitsType } from './interface'
// import { classProperty } from '../../../../../../../../../api'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '../../../../../../../../client-srv'
import { EnumClsDataType } from '../../../../enums'

export class KObjSelectPanelViewModel extends BaseViewModel<KObjSelectPanelPropsEventEmitsType, KObjSelectPanelPropType> {
  refRefPropertyGrid = ref<IKDataGrid>()

  viewDidMount() {
    // init grid data
    this.initGridData()
  }

  /**
   * 初始化网格数据
   */
  async initGridData() {
    // const propertyArgs = JSON.parse(this.props.propertyArgs!)
    // classProperty.listByClassId({ object: propertyArgs.id }).then(res => {
    //   if (res?.state === 'success') {
    //     // 移除本身就是对象类的属性
    //     const data = res.data?.filter(item => item.dataType !== EnumClsDataType.OBJ) || []
    //     this.setValue(data)
    //   }
    // })
    const propertyArgs = JSON.parse(this.props.propertyArgs!)
    const code = propertyArgs && propertyArgs.refClsCode
    // 查询全部属性
    const result = await Api.post('official', 'ClsPropService', 'listByClsCode', { data: [[code]] })
    if (result?.code === EnumRequestCode.SUCCESS) {
      // 移除本身就是对象类的属性
      const data = result.data?.filter((item: { dataType: EnumClsDataType }) => item.dataType !== EnumClsDataType.OBJ) || []
      this.setValue(data)
    } else {
      return KNotification.error({
        title: '系统提示',
        content: '引入属性失败'
      })
    }
  }

  /**
   * 行切换
   * @param row
   */
  rowSelectionChanged(data: KDataGridSelectionChangedEvent) {
    // 当前点击行
    const selectedRow = data.selectedRows[0]

    // 设置选中行，为当前点击行
    this.refRefPropertyGrid.value!.setSelectedRow(selectedRow.id, true)
  }

  // 获取表单的值
  getValue() {
    return this.refRefPropertyGrid.value!.getSelectedRows()
  }

  // 设置网格数据
  setValue(data: Array<Record<string, any>>) {
    this.refRefPropertyGrid.value!.setDataSource(data)
  }
}

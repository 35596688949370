import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_require_tree = _resolveComponent("k-require-tree")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_object_panel = _resolveComponent("k-object-panel")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_k_split_pane, {
            size: 15,
            minSize: 15,
            maxSize: 50
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_panel, null, {
                default: _withCtx(() => [
                  _createVNode(_component_k_require_tree, {
                    ref: "refFolderTree",
                    objParam: _ctx.$vm.selectNode,
                    loadData: (node) => _ctx.loadTreeData(node),
                    onAfterSelect: _cache[0] || (_cache[0] = (event) => _ctx.$vm.afterSelect(event))
                  }, null, 8, ["objParam", "loadData"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_k_split_pane, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_object_panel, {
                ref: _ctx.$vm.refObjectPanel,
                objParam: _ctx.$vm.objParam,
                suppressedTabs: _ctx.$vm.suppressedTabs,
                isHighestOpen: _ctx.$vm.isHighestOpen,
                onDataUpdated: _ctx.$vm.onObjectPanelDataUpdated,
                attachParams: _ctx.$vm.attachParams
              }, null, 8, ["objParam", "suppressedTabs", "isHighestOpen", "onDataUpdated", "attachParams"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
import { BaseViewModel, KFormViewModel, ViewModelOptions, KNotification } from '@kmsoft/upf-core'
import { KFlowFormEmitsType, KFlowFormPropType, IValueDesc, WfNode } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** KFlowForm */
export default class KFlowFormViewModel extends BaseViewModel<KFlowFormEmitsType, KFlowFormPropType> {
  /**表单viewModel */
  refForm = ref<KFormViewModel>()
  /**表单数据 */
  formData = ref<Record<string, any>>({
    /**
     * 说明
     */
    describe: '',
    /**
     * 节点流向
     */
    radioValue: '',
    /**
     * 是否驳回
     */
    rollback: false
  })

  /**节点数据 */
  wfNodeData = ref<Array<WfNode>>()

  /** 单选框可选项 */
  radioOptions = ref<Array<IValueDesc>>([])
  constructor(options: ViewModelOptions<KFlowFormPropType>) {
    super(options)
  }

  viewDidMount() {
    if (this.props.type == 'submit') {
      this.initRadio()
    }
  }
  /**
   * 获取表单内容
   */
  getValue() {
    if (this.props.type == 'submit') {
      const foundNode = this.wfNodeData.value!.find(node => node.key === this.formData.value.radioValue)
      this.formData.value.rollback = foundNode?.rollback
      return this.formData.value
    }
    if (this.props.type == 'end') {
      return { describe: this.formData.value.describe }
    }
  }

  async validate() {
    try {
      await this.refForm.value?.validate()
      return true
    } catch (error) {
      return false
    }
  }
  /**
   * 初始化[单选框]
   */
  private initRadio(): void {
    const param = {
      data: [this.props.taskId]
    }
    Api.post('official', 'ProcessInstanceService', 'getNextNodes', param).then(res => {
      if (res && res.code == EnumRequestCode.SUCCESS) {
        this.wfNodeData.value = res.data as Array<WfNode>

        const options = [] as Array<IValueDesc>

        if (this.wfNodeData.value != null && this.wfNodeData.value.length > 0) {
          for (const value of this.wfNodeData.value) {
            options.push({
              label: value.flowName ? value.flowName : value.name,
              value: value.key
            })
          }
          /**去掉驳回选项（驳回无法加监听器）
           options.push({
            label: '驳回',
            value: '2'
          }) */
          this.formData.value.radioValue = options[0].value
        }
        this.radioOptions.value = options
        return
      } else {
        KNotification.error({
          title: '系统提示',
          content: res.message!
        })
        return
      }
    })
  }
}

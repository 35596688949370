import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-877ba696"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_object_property_panel = _resolveComponent("k-object-property-panel")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_checkbox = _resolveComponent("k-checkbox")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_dialog_content = _resolveComponent("k-dialog-content")!

  return (_openBlock(), _createBlock(_component_k_dialog_content, {
    class: "k-issue-report-creater",
    dense: ""
  }, _createSlots({
    default: _withCtx(() => [
      _createVNode(_component_k_panel, null, _createSlots({
        default: _withCtx(() => [
          _createVNode(_component_k_object_property_panel, {
            ref: "refObjectCreatePanel",
            modelCode: "ChangeIssue",
            modelGroup: "change",
            schemaType: "new",
            loadData: () => _ctx.$vm.formData
          }, null, 8, ["loadData"])
        ]),
        _: 2
      }, [
        (_ctx.showAddButton)
          ? {
              name: "header",
              fn: _withCtx(() => [
                _createVNode(_component_k_tool_strip, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_tool_strip_button_item, {
                      name: "add",
                      title: "保存",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vm.save())),
                      type: "primary"
                    })
                  ]),
                  _: 1
                })
              ]),
              key: "0"
            }
          : undefined
      ]), 1024)
    ]),
    _: 2
  }, [
    (!_ctx.showAddButton && _ctx.showExtButton)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_k_checkbox, {
                class: "createNext",
                checked: _ctx.$vm.createNext,
                "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.createNext) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("继续创建下一个")
                ]),
                _: 1
              }, 8, ["checked"]),
              _createVNode(_component_k_checkbox, {
                class: "openObj",
                checked: _ctx.$vm.openObj,
                "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.openObj) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("打开对象")
                ]),
                _: 1
              }, 8, ["checked"]),
              _createVNode(_component_k_button, {
                disabled: _ctx.$vm.isConfirming,
                loading: _ctx.$vm.isConfirming,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$vm.onCancel()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("取消")
                ]),
                _: 1
              }, 8, ["disabled", "loading"]),
              _createVNode(_component_k_button, {
                type: "primary",
                disabled: _ctx.$vm.isConfirming,
                loading: _ctx.$vm.isConfirming,
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$vm.onConfirm()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("确认")
                ]),
                _: 1
              }, 8, ["disabled", "loading"])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}
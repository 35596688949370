import { connect, withInstall } from '@kmsoft/upf-core'
import KRenderLayoutView from './KRenderLayout'
import KRenderLayoutViewModel from './KRenderLayoutViewModel'
import './style/index.less'

const KRenderLayout = connect(KRenderLayoutView, KRenderLayoutViewModel)

export default withInstall(KRenderLayout)
export { KRenderLayout, KRenderLayoutView, KRenderLayoutViewModel }
export * from './interface'

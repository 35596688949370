import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-start h100p" }
const _hoisted_2 = { class: "flex1" }
const _hoisted_3 = { class: "flex1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_flow_detail = _resolveComponent("k-flow-detail")!
  const _component_k_flow_approval_content = _resolveComponent("k-flow-approval-content")!
  const _component_k_flow_approval_process = _resolveComponent("k-flow-approval-process")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("div", {
            style: _normalizeStyle({ height: _ctx.showFlowDetailPanel ? '270px' : '32px' })
          }, [
            _createVNode(_component_k_flow_detail, {
              onOpen: _cache[0] || (_cache[0] = isOpen => (_ctx.showFlowDetailPanel = isOpen)),
              onSubmitComplete: _ctx.$vm.flowHandlerComplete,
              onUpdateExecutorComplete: _ctx.$vm.refresh,
              dataForm: _ctx.$vm.flowDetailData
            }, null, 8, ["onSubmitComplete", "onUpdateExecutorComplete", "dataForm"])
          ], 4),
          _createElementVNode("div", {
            style: _normalizeStyle({ height: `calc(100% - ${_ctx.showFlowDetailPanel ? '270px' : '32px'})` })
          }, [
            _createVNode(_component_k_flow_approval_content, {
              businessId: _ctx.$vm.flowDetailData?.businessId,
              showToolStrip: _ctx.$vm.flowDetailData.currentSteps != '流程已结束',
              processDefinitionKey: _ctx.$vm.processDefinitionKey,
              ref: "refKFlowApprovalContent"
            }, null, 8, ["businessId", "showToolStrip", "processDefinitionKey"])
          ], 4)
        ]),
        _createElementVNode("section", _hoisted_3, [
          _createVNode(_component_k_flow_approval_process, {
            ref: "refKFlowApprovalProcess",
            processInstanceId: _ctx.$vm.flowDetailData?.processInstanceId,
            executorGridData: _ctx.$vm.flowDetailData?.processNodes
          }, null, 8, ["processInstanceId", "executorGridData"])
        ])
      ])
    ]),
    _: 1
  }))
}
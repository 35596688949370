import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../../object-tool-strip'
import { EnumToolStripItemKeys } from '../../types'
import { EnumClassTemplate } from '../../../../client-srv'

export const toolStripItemStructManage: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE,
  title: '结构管理',
  icon: 'file-protect',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+p',
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.CUSTOMER_NEEDS]
}

export const toolStripItemCreateProductNeeds: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PRODUCT_NEEDS,
  title: '创建产品需求',
  icon: 'file-protect',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+p',
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.CUSTOMER_NEEDS],
  disabled: true
}

export const toolStripMoreOperation: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_MORE,
  title: '更多操作',
  icon: 'more',
  visible: true,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  items: [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
      title: '加入流程',
      icon: 'AddToFlow',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW,
      title: '查看流程',
      icon: 'ViewFlowObj',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]
}

export const getCustomerNeedsOperationToolStripItems = (): Array<ObjectToolStripItem> => {
  return [toolStripItemStructManage, toolStripItemCreateProductNeeds, toolStripMoreOperation]
}

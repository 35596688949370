import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** KSchemaExport 状态 **/
export interface IKSchemaExportState {}

/** KSchemaExport 事件 **/
export const KSchemaExportEventEmits = {
  ...BaseViewEventEmits
}

/** KSchemaExport 参数 **/
export const KSchemaExportPropOptions = {
  ...BaseViewPropOptions,
  schema: VuePropTypes.createType<Record<string, any>>().def({})
}

/** KSchemaExport 参数类型 **/
export type KSchemaExportPropType = ViewPropsTypeExtract<typeof KSchemaExportPropOptions>

/** KSchemaExport 事件协议*/
export type KSchemaExportEventEmitsType = ViewEmitsTypeExtract<typeof KSchemaExportEventEmits>

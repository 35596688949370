/** IOC组件键名 */
export const IOC_COMPONENT_KEYS_BASE = {
  CLIENT_SRV_FACTORY: Symbol('CLIENT_SRV_FACTORY'),
  /** 插件插件干预 */
  PLUGIN_COMPONENT_INTERVENE: Symbol('PLUGIN_COMPONENT_INTERVENE'),
  /** 字段编辑器返回 */
  FIELD_EDITOR_RESOLVER: Symbol('FIELD_EDITOR_RESOLVER'),
  /** 编辑器返回 */
  FILE_APP_RESOLVER: Symbol('BROWSER_RESOLVER'),
  /** 脚本上下文提供器 */
  SCRIPT_CONTEXT_RESOLVER: Symbol('SCRIPT_CONTEXT_RESOLVER'),
  /**菜单注入 */
  NAVIGATOR_MENU_PROVIDER: Symbol('NAVIGATOR_MENU_PROVIDER')
}


import { defineView } from '@kmsoft/upf-core'
import { KCreateFormPropOptions, KCreateFormEventEmits } from './interface'
import KCreateFormViewModel from './KCreateFormViewModel'

export default defineView({
  name: 'KCreateForm',
  props: KCreateFormPropOptions,
  emits: KCreateFormEventEmits,
  viewModel: KCreateFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

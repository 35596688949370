import { BaseViewModel, EnumDialogResult, KDialog, KDialogClosingEvent, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KObjectClassLayoutDefinition, KObjectClassLayoutDefinitionViewModel } from '../..'
import { IObjectClassLayout } from '../../interface'
import { KObjectClassLayoutDefinitionFormEmitsType, KObjectClassLayoutDefinitionFormPropType } from './interface'

/** KObjectClassLayoutDefinitionForm */
export default class KObjectClassLayoutDefinitionFormViewModel extends BaseViewModel<
  KObjectClassLayoutDefinitionFormEmitsType,
  KObjectClassLayoutDefinitionFormPropType
> {
  formData = ref({
    id: '',
    name: '',
    code: '',
    type: '',
    sourceLayoutName: '',
    sourceLayoutId: null,
    remark: ''
  }) as any

  constructor(options: ViewModelOptions<KObjectClassLayoutDefinitionFormPropType>) {
    super(options)
    if (options.props.objectClassLayoutFormData) {
      this.formData.value = {
        sourceLayoutName: '',
        sourceLayoutId: null,
        remark: '',
        ...options.props.objectClassLayoutFormData
      }
    }
  }

  viewDidMount() {}
  /**
   * 获取复制的布局
   */
  getSourceLayout() {
    KDialog.show({
      title: '布局选择',
      size: { width: 1100, height: 680 },
      props: { clsId: this.props.clsId, environment: 'show' },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KObjectClassLayoutDefinition,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KObjectClassLayoutDefinitionViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const selectRows = (await formViewModel.getSelectRows()) as Array<IObjectClassLayout>
        if (!selectRows || selectRows.length != 1) {
          KNotification.warn({
            message: '系统提示',
            description: '请选择一个布局进行复制'
          })
          event.cancel = true
          return
        }
        this.formData.value.sourceLayoutId = selectRows[0].id!
        this.formData.value.sourceLayoutName = selectRows[0].name!
      }
    })
  }

  getValue() {
    return this.formData.value
  }
}

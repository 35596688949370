import { BizContextItem } from '../../../../../types'

/** 通用脚本 */
export const commonScript: Array<BizContextItem> = [
  {
    name: '打开标签页',
    code: '$.PageManager.openPage("<填入key>","<填入标题>","<填入全局组件名称或组件声明>",{})',
    sample: '$.PageManager.openPage("1234","页面定义","PageModeling",{propA:""})',
    remark: '通过标签页管理器打开指定的页面，要求传入页面标题，组件以及组件参数'
  },
  {
    name: '弹出窗口',
    code: '$.KDialog.show()',
    sample:
      '$.KDialog.show({\n' +
      '      title: "创建页面",\n' +
      '      size: EnumDialogSize.Normal,\n' +
      '      showApply: false,\n' +
      '      maximizeBox: false,\n' +
      '      minimizeBox: false,\n' +
      '      component: PageForm,\n' +
      '      onClosing: async (event: KDialogClosingEvent) => {\n' +
      '        const formViewModel = event.refComponent.$vm as PageFormViewModel\n' +
      '        if (event.dialogResult == EnumDialogResult.Cancel) return\n' +
      '\n' +
      '        const newPageData = formViewModel.getValue()\n' +
      '        this.objClsGrid.insertRow(newPageData)\n' +
      '      }\n' +
      '    })',
    remark: '弹出指定的组件'
  },
  {
    name: '弹出子页面',
    code:
      '$.showChildPageDialog({\n' +
      '      pageName: "页面名称",\n' +
      '      pageTitle: "页面标题",\n' +
      '      size: "normal",\n' +
      '      maximizeBox: false,\n' +
      '      minimizeBox: false,\n' +
      '      onClosing: (event) => {\n' +
      '      }\n' +
      '    })',
    sample:
      '$.showChildPageDialog({\n' +
      '      pageName: "页面名称",\n' +
      '      pageTitle: "页面标题",\n' +
      '      size: "normal",\n' +
      '      maximizeBox: false,\n' +
      '      minimizeBox: false,\n' +
      '      onClosing: (event) => {\n' +
      '      }\n' +
      '    })',
    remark:
      '一级页面中的脚本可以直接通过子页面名称以弹出窗口的形式打开子页面，子页面脚本共享父页面的上下文，父页面也可以额外传入参数'
  },
  {
    name: '服务器方法调用',
    code: '$.invokeServerApi("程序集;类名","方法名称",{/**这里传入参数**/})',
    sample: '$.invokeServerApi("程序集;类名","方法名称",{/**这里传入参数**/})',
    remark: '提供二次开发人员便捷的调用后台服务器中接口的能力'
  },
  {
    name: 'http请求',
    code: '$.request.get()',
    sample: '$.request.get()',
    remark: 'http Get请求'
  }
]

/** 实用样例 */
export const exampleScript: Array<BizContextItem> = [
  {
    name: '根据路径查找子ViewModel',
    code: 'pageViewModel.getByPath("a.b.c")',
    sample: 'pageViewModel.getByPath("a.b.c")',
    remark: '从渲染器根ViewModel根据路径查找子ViewModel'
  },
  {
    name: '递归查找子ViewModel',
    code: 'pageViewModel.getByRecursion("子控件名称")',
    sample: 'pageViewModel.getByRecursion("子控件名称")',
    remark: '从渲染器根ViewModel递归查找子ViewModel'
  },
  {
    name: '设置控件可见',
    code: 'viewModel.setVisible(true)',
    sample: 'viewModel.setVisible(true)',
    remark: '将viewModel对应的控件设置为可见'
  },
  {
    name: '设置控件不可用',
    code: 'viewModel.setEnable(false)',
    sample: 'viewModel.setEnable(true)',
    remark: '将viewModel对应的控件设置为不可用'
  },
  {
    name: '设置控件只读模式',
    code: 'viewModel.setReadonly(true)',
    sample: 'viewModel.setReadonly(true)',
    remark: '将viewModel对应的控件设置为只读模式，当然前提是该控件具备只读模式的显示'
  }
]

/** 其他 */
export const otherScript: Array<BizContextItem> = [
  {
    name: '调试',
    code: 'debugger',
    sample: 'debugger',
    remark: '关键字：调试\r\n说明：XXXXX'
  },
  {
    name: '打印',
    code: 'console.log()',
    sample: 'console.log(`test`)',
    remark: 'console.log(`test`)'
  }
]

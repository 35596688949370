import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KChangeActivityUpdateCompareGridDiffEmitsType, KChangeActivityUpdateCompareGridDiffPropType } from './interface'

/** KChangeActivityUpdateCompareGridDiff */
export default class KChangeActivityUpdateCompareGridDiffViewModel extends BaseViewModel<KChangeActivityUpdateCompareGridDiffEmitsType, KChangeActivityUpdateCompareGridDiffPropType> {
  constructor(options: ViewModelOptions<KChangeActivityUpdateCompareGridDiffPropType>) {
    super(options)
  }

  viewDidMount() {}
}

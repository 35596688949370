import { BaseViewModel, KDataGridRowDoubleClickEvent, ViewModelOptions } from '@kmsoft/upf-core'
import { KObjectSearchEmitsType, KObjectSearchPropType } from './interface'
import { computed, ref, watch } from 'vue'
import { KObjectSearcherViewModel } from '../object-searcher'
import { ClassMetaClientSrv, ObjBusinessBase, ObjBusinessParam, ObjectClientSrv, QueryCondition } from '../../client-srv'
import { KObjectClassGridViewModel } from '../object-class-grid'

/** KObjectSearch */
export default class KObjectSearchViewModel extends BaseViewModel<KObjectSearchEmitsType, KObjectSearchPropType> {
  /** 搜索面板 */
  refIKObjectSearcher = ref<KObjectSearcherViewModel>()
  /** 对象类网格 */
  refObjectClassGrid = ref<KObjectClassGridViewModel>()

  /** 过滤参数 */
  filter = ref<QueryCondition>()

  /** 对象类树属性 */
  objectClassTreeProps = computed(() => {
    return {
      defaultSelectClsId: this.props.defaultSelectClsCode,
      objClassTypes: this.props.classTemplates,
      showObjClsIds: this.props.showObjClsCodes,
      hideObjClsIds: this.props.hideObjClsCodes
    }
  })
  /** 对象类Code */
  modelCode = ref<string>()
  /** 对象类分组 */
  modelGroup = ref<string | undefined>()

  constructor(options: ViewModelOptions<KObjectSearchPropType>) {
    super(options)
    this.modelCode.value = options.props.defaultSelectClsCode
    watch(
      () => this.modelCode.value,
      () => {
        this.filter.value = undefined
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  //#region
  /**
   * 获取选中的业务对象参数
   * @returns
   */
  getSelectedObjParams(): ObjBusinessParam[] {
    if (!this.refObjectClassGrid.value) {
      return []
    }
    return this.refObjectClassGrid.value.getSelectedObjParams()
  }

  /**
   * 获取选中的业务对象
   * @returns
   */
  getSelectedObjBussiness(): ObjBusinessBase[] {
    if (!this.refObjectClassGrid.value) {
      return []
    }
    return this.refObjectClassGrid.value.getSelectedObjBussiness()
  }

  /**
   * 获取选中行
   * @returns
   */
  getSelectedRows(): Record<string, any>[] {
    if (!this.refObjectClassGrid.value) {
      return []
    }

    /** 获取选中行 */
    const rows = this.refObjectClassGrid.value.getSelectedRows()

    // 如果没选中行
    if (!rows) {
      return []
    }

    return rows
  }

  //#region 事件
  /**
   * 搜索
   * @param condition
   */
  onSearch(condition: QueryCondition) {
    this.filter.value = condition
  }

  /** 取消搜索 */
  onCancel() {
    this.filter.value = undefined
  }

  /**
   * 双击打开编辑面板
   * @param event 行双击事件
   */
  async onRowDoubleClick(event: KDataGridRowDoubleClickEvent) {
    const param = {
      id: event.row!.id,
      modelCode: event.row!.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(event.row!.className)
    }
    ObjectClientSrv.openObj(param)
  }
  //#endregion
}

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  style: {"width":"100%","display":"flex"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_button = _resolveComponent("k-button")!

  return (_ctx.$vm.readonly)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$vm.folderPath), 1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_k_input, {
          class: "input-box",
          value: _ctx.$vm.folderPath,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.folderPath) = $event)),
          disabled: true
        }, null, 8, ["value"]),
        _createVNode(_component_k_button, {
          class: "k-reference-editor-selector",
          onClick: _ctx.$vm.openFolderSelector,
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_icon, { type: "select-arrow" })
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ]))
}
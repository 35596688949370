import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KAffectedObjectCreateActivityEmitsType, KAffectedObjectCreateActivityPropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api } from '@kmsoft/ebf-common'
import { KChangeOrderActivityPlanGridDataInter } from '../../change-order-activity-plan-grid/interface'

/** KAffectedObjectCreateActivity */
export default class KAffectedObjectCreateActivityViewModel extends BaseViewModel<
  KAffectedObjectCreateActivityEmitsType,
  KAffectedObjectCreateActivityPropType
> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  formData = ref<KChangeOrderActivityPlanGridDataInter>({
    number: '',
    name: '',
    activityType: '',
    inventoryDisposition: '',
    description: '',
    supplyingOrganizations: '',
    needDate: null
  })
  constructor(options: ViewModelOptions<KAffectedObjectCreateActivityPropType>) {
    super(options)
  }

  viewDidMount() {}
  getValue(): KChangeOrderActivityPlanGridDataInter {
    const createObject = this.refObjectCreatePanel.value?.getValue()
    return createObject as KChangeOrderActivityPlanGridDataInter
  }
}

export class EncryptHelper {
  /** 偏移值 */
  private static _offset: number = 12

  /**
   * 对对象进行编码
   */
  static encode(obj: Record<string, any>): string {
    if (!obj) return ''
    const tempStr = JSON.stringify(obj)
    let result = ''
    for (let i = 0; i < tempStr.length; i++) {
      const charCode = tempStr.charCodeAt(i) + this._offset
      result += String.fromCharCode(charCode)
    }
    return result
  }

  /** 解码对象 */
  static decode<T extends Record<string, any>>(decodeStr: string): T {
    if (decodeStr && decodeStr.length > 0) {
      let result = ''
      for (let i = 0; i < decodeStr.length; i++) {
        const charCode = decodeStr.charCodeAt(i) - this._offset
        result += String.fromCharCode(charCode)
      }
      try {
        return JSON.parse(result)
      } catch (ex) {
        console.error('Decode string failed: ' + decodeStr)
      }
    }
    return {} as any
  }
}

import { Api, EnumPurviewCode, EnumRequestCode } from '@kmsoft/ebf-common'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  EnumToolStripCompType,
  KTreeNode,
  ToolStripButtonItem,
  ToolStripItem,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract
} from '@kmsoft/upf-core'
import { UserClientSrv } from '../../../client-srv'
import { EnumToolStripItemKeys, FolderTreeNode, IFolder } from '../../folder-manage/folder-tree/interface'

/** 参数 **/
export const KElectronicComponentsWorkspacePropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KElectronicComponentsWorkspacePropType = ViewPropsTypeExtract<typeof KElectronicComponentsWorkspacePropOptions>

/** 事件 */
export const KElectronicComponentsWorkspaceEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KElectronicComponentsWorkspaceEmitsType = ViewEmitsTypeExtract<typeof KElectronicComponentsWorkspaceEventEmits>

/**
 * 元器件目录
 */
export class ElectronicComponentsPartWorkspace extends KTreeNode {
  /**
   * 编码
   */
  nameEn: string
  /**
   * 图标
   */
  iconType: string
  /**
   * 备注
   */
  description: string
  /**
   * 创建人id
   */
  creator: string

  /***
   * 创建时间
   */
  createTime: string

  /**
   * 类型
   */
  type: string

  /**
   * 父id
   */
  parentId: string
  /**
   * 根节点类型
   */
  rootNodeType?: string

  constructor(data: IFolder) {
    super()
    this.id = data.id
    this.nameEn = data.nameEn
    this.name = data.name
    this.leaf = data.leafFlag
    this.iconType = 'folder'
    this.description = data.description
    this.createTime = data.createTime
    this.creator = data.creator
    this.type = data.type
    this.parentId = data.parentId
    this.rootNodeType = data.type
  }

  async populateChildren(populateType: number): Promise<Array<KTreeNode>> {
    const newChild = [] as Array<KTreeNode>
    if (this.leaf) {
      return newChild
    }
    const param = {
      data: [
        this.id,
        {
          rdmPageVO: {
            maxPageSize: 1000,
            curPage: 1,
            pageSize: 1000,
            totalRows: 0,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0
          }
        }
      ]
    }
    const result = await Api.post('folder', 'Folder', 'listChildFolder', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      for (const item of result.data!) {
        item.rootNodeType = this.type
        const childNode = new FolderTreeNode(item as any)
        newChild.push(childNode)
      }
    }
    return newChild
  }

  /**
   * 获取右键菜单
   * @returns 菜单列表
   */
  getContextMenus(): Array<ToolStripItem> {
    const workSpaceRootToolStripItem = [
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.NEW,
        /** 文字 */
        title: '新建',
        /** 是否可见  */
        visible: true,
        purviewId: EnumPurviewCode.CreateFolder,
        icon: 'folder-add',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.EDIT,
        /** 文字 */
        title: '属性',
        /** 是否可见  */
        visible: true,
        purviewId: EnumPurviewCode.ModifyFolder,
        icon: 'compute-prop',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.REFRESH,
        /** 文字 */
        title: '刷新',
        /** 是否可见  */
        visible: true,
        icon: 'loading-3-quarters',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem
    ] as Array<ToolStripItem & { purviewId?: string }>
    return workSpaceRootToolStripItem.filter(item => !item.purviewId || UserClientSrv.verifyPurview(item.purviewId))
  }
}

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumClsDataType, EnumClsEditorType } from '../../../enums'

// 以下几种编辑类型需要增加显示值列
export const showDisplayValueColumnEditorTypeList = [
  EnumClsEditorType.SELECT_VALUED_EDITOR,
  EnumClsEditorType.LIST_EDITOR,
  EnumClsEditorType.LIST_SINGLE_EDITOR
]

// form
export interface ISelectEditorFormDefinition {
  optionType: 'options' | 'enums' | 'dataSourceLink'
  options?: Array<{ id: string; label: string; value: string }>
  /** 引用枚举对象名称 */
  refEnumName?: string
  /** 引用枚举类型对象code */
  refEnumCode?: string
  /** 引用枚举类型对象id */
  refEnumId?: string
  dataSourceLink?: string
}

// 对象界面定义定义事件
export const KSelectEditorCustomPropsEventEmits = {
  ...BaseViewEventEmits
}

// 对象界面定义定义属性选项
export const KSelectEditorCustomPropOptions = {
  ...BaseViewPropOptions,
  /** 当前编辑类型的自定义参数 */
  editArgs: VuePropTypes.string().def(),
  /** 当前属性id */
  propertyId: VuePropTypes.string().def(),
  /** 当前编辑对象类的数据类型 */
  dataType: VuePropTypes.createType<EnumClsDataType>().def(),
  /** 当前编辑对象类的数据的编辑类型 */
  editType: VuePropTypes.createType<EnumClsEditorType>().def(),
  /** 是否只读 */
  readonly: VuePropTypes.bool().def(false)
}

// emit type
export type KSelectEditorCustomPropsEventEmitsType = ViewEmitsTypeExtract<typeof KSelectEditorCustomPropsEventEmits>

// 对象界面定义定义属性类型
export type KSelectEditorCustomPropType = ViewPropsTypeExtract<typeof KSelectEditorCustomPropOptions>

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KClassPropertySelectorEmitsType, KClassPropertySelectorPropType } from './interface'
import { KPropertyGridViewModel } from './property-grid'

/** KSelectProperty */
export default class KSelectPropertyViewModel extends BaseViewModel<
  KClassPropertySelectorEmitsType,
  KClassPropertySelectorPropType
> {
  modelCode = ref<string>()
  refPropertyGrid = ref<KPropertyGridViewModel>()
  constructor(options: ViewModelOptions<KClassPropertySelectorPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**设置对象类编码*/
  setObjClsCode(value: string) {
    this.modelCode.value = value
  }
  /**清空网格选中行 */
  clearSelectRows() {
    this.refPropertyGrid.value?.refDataGridView?.clearSelectedRows()
  }
  /**获取网格选中行 */
  getSelectedRows() {
    return this.refPropertyGrid.value?.refDataGridView?.getSelectedRows()
  }
}

import {
  Agent,
  Api,
  CommonClientSrv,
  EnumRequestCode,
  EnumToolStripItemKeys,
  FileClientSrv,
  KObjectClassGridViewModel,
  KObjectPanelPropType,
  ObjectToolStripItem,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import { DocProperty } from '@kmsoft/ebf-doc/src/client-agent'
import {
  BaseViewModel,
  EnumDialogResult,
  EnumToolStripCompType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ToolStripItemClickedEventArgs,
  ViewModelOptions,
  VNodeProps
} from '@kmsoft/upf-core'
import { ref, resolveComponent } from 'vue'
import { KDocCreatePanel, KDocCreatePanelViewModel } from '../../../../ebf-doc/src/controls'
import KDocStructureManage from '../../../../ebf-doc/src/controls/doc-structure-manage'
import { KDocDemoEmitsType, KDocDemoPropType } from './interface'

/** KDocDemo */
export default class KDocDemoViewModel extends BaseViewModel<KDocDemoEmitsType, KDocDemoPropType> {
  /** 网格工具栏 */
  refDocObjGrid = ref<KObjectClassGridViewModel>()

  toolStripItems = ref<Array<ObjectToolStripItem>>([
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OBJ,
      title: '对象',
      icon: 'DropObjLife',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      items: [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWOBJ,
          title: '创建',
          icon: 'NewObj',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'alt+n'
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
          title: '删除',
          icon: 'DeleteObj',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'delete'
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS,
          title: '另存',
          icon: 'ObjSaveAs',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'alt+s'
        },
        {
          name: 'edit',
          title: '编辑',
          icon: 'stop',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION,
      title: '操作',
      icon: 'DropObjOp',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      items: [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT,
          title: '检出(O)',
          icon: 'DocCheckOut',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT,
          title: '取消检出',
          icon: 'file-text',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN,
          title: '检入(I)',
          icon: 'DocCheckIn',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'alt+i'
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE,
          title: '结构管理',
          icon: 'file-protect',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_GRID_OPERATION,
      title: '列表',
      icon: 'DropGridOp',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      items: [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
          title: '刷新',
          icon: 'sync',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'f5'
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EXPORT_EXCEL,
          title: '导出Excel',
          icon: 'table',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    }
  ])

  constructor(options: ViewModelOptions<KDocDemoPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 按钮触发
   */
  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onToolStripItemClicked', event.name)
    const eventName = event.name
    switch (eventName) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWOBJ:
        this.doCreate()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE:
        this.doDelete()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refDocObjGrid.value?.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE:
        this.structManage()
        break
      default:
        break
    }
  }

  /**
   * 获取文档
   */
  async getDoc(id: string) {
    const result = (await Api.post('doc', 'Document', 'get', { data: [id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取文档失败',
        details: result.detail
      })
      return
    }
  }

  /**
   * 执行删除
   */
  async doDelete() {
    const rows = this.refDocObjGrid.value?.getSelectedRows()
    console.log('doDelete', rows)
    const idObjs = rows?.map(item => {
      return item.id
    })
    const result = await Api.post('doc', 'Document', 'batchDelete', { data: [idObjs] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '删除成功'
      })
      //TODO:自行实现删除接口,成功后再从网格移除行
      this.refDocObjGrid.value?.removeSelectRow()
    } else {
      KNotification.error({
        title: '删除失败',
        content: result.message!
      })
      return
    }
  }

  /**
   * 执行新建
   */
  async doCreate() {
    KDialog.show({
      title: '新建文档',
      size: { width: 718, height: 498 },
      props: {
        // parentId: node.id,
        // folderId: '',
        // isMultipleSelection: true
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KDocCreatePanel,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KDocCreatePanelViewModel
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        //1.校验表单数据
        const valid = formViewModel.validFormData()
        if (!valid) {
          event.cancel = true
          return
        }
        //获取表单的数据
        const newDoc = formViewModel.getFormData()
        const record = newDoc as Record<string, any>
        const docList = record.docList as DocProperty[]
        for (const item of docList) {
          const uploadFileInfo = await Agent.FileInfo.create(item.checkoutPath!)
          const fileLength = await uploadFileInfo.Length()
          const result = await FileClientSrv.uploadFileByAgent({
            filename: item.checkoutPath!,
            md5: '',
            size: fileLength,
            total: '1',
            shardIndex: '0',
            location: '1',
            requestId: Math.random().toString(),
            chunkSize: fileLength,
            modelName: 'Document',
            attributeName: 'primary'
          })
          item.primary = [{ id: result.data[0] }]
        }

        //设置primary
        const rootNode = docList.find(item => item.isRoot)
        if (!rootNode) {
          KNotification.error({
            title: '系统提示',
            content: '主文件不能为空'
          })
        }
        record.primary = rootNode?.primary
        const req = {
          data: [newDoc]
        }
        const result = await Api.post('doc', 'Document', 'create', req)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '添加文档成功'
          })
          this.refDocObjGrid.value?.refresh()
        } else {
          KNotification.error({
            title: '新建失败',
            content: result.message!
          })
          event.cancel = true
          return
        }
      }
    })
  }

  async onLoadData(qry: any) {
    const param = {
      data: [
        {
          sort: 'DESC',
          orderBy: 'lastUpdateTime',
          characterSet: 'UTF8',
          conditions: [],
          filter: {},
          decrypt: false,
          sorts: [],
          isNeedTotal: true,
          isPresentAll: true,
          publicData: 'INCLUDE_PUBLIC_DATA'
        },
        {
          maxPageSize: 1000,
          curPage: qry.page.pageIndex,
          pageSize: 50,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    const res = await Api.post('doc', 'Document', 'page', param)
    if (res) {
      return Promise.resolve({
        rows: res.data.data,
        total: res.data.page.totalRows
      })
    }
  }

  onDoubleClick() {
    const selectedRow = this.refDocObjGrid.value?.getSelectedRow()
    const param = { id: selectedRow?.id, modelCode: 'Document', modelGroup: 'doc', masterId: selectedRow?.master.id }
    const name = selectedRow?.name
    const number = selectedRow?.number
    //const partViewEnum = selectedRow?.partViewEnum === 'DESIGN' ? '设计' : '制造'
    const version = selectedRow?.version + '.' + selectedRow?.iteration
    const workingState =
      selectedRow?.workingState === 'INWORK' ? '工作中' : 'CHECKED_IN' ? '已检入' : 'CHECKED_OUT' ? '已检出' : '未知'

    /** 标签页标识 */
    const tabKey = `${param.id}#${param.modelCode}`
    /** 标题 */
    const title = `${number}` + ':' + `${name}` + ':' + `${version}` + ':' + `${workingState}`
    /** 面板参数 */
    const panelProps: VNodeProps<KObjectPanelPropType> = { objParam: param }

    // 打开页面
    CommonClientSrv.openPage(title, () => resolveComponent('KObjectPanel'), panelProps, tabKey)
  }

  /**
   * 结构管理
   */
  async structManage() {
    const rows = this.refDocObjGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    // 获取对象id
    const rowIds = rows!.map(row => row.id)
    // 获取对象
    const docData = (await this.getDoc(rowIds[0])) as any
    const title = '结构管理:' + ObjectClientSrv.getObjBusinessDesc(docData)
    CommonClientSrv.openPage(
      title,
      KDocStructureManage,
      { objParam: { modelCode: docData.rdmExtensionType, modelGroup: 'doc', id: docData.id } },
      '测试'
    )
  }
}

import {
  KContainer,
  KControl,
  KGridColumn,
  KGridColumnCompute,
  KGridColumnGroup,
  KViewSchema,
  VNodeProps,
  KDataGridViewModel,
  KDataGridTemplateColumn,
  MemoryCacheFactory,
  utils
} from '@kmsoft/upf-core'
import { clsCodeMap, EnumLifecycleState, PartViewMemoryCache } from '../../client-srv'
import { render } from 'vue'
import { EnumEditorEnvironment, IKEditorAPI, KBasePropertyEditorPropType } from './base'

/**
 * 主对象 Code
 * @description 主对象用当前属性标识，其他用对象类 Code 标识
 */
const MAIN_CLASS_CODE = '_'

/** 表单布局处理方法 */
export type ProcessFormSchemaParams = {
  /** 主对象对象类定义 */
  // mainMetaClass: MetaClass
  /** 编辑器 API */
  api: (control: any) => IKEditorAPI
  /** 环境 */
  environment: EnumEditorEnvironment
}

/** 网格布局处理方法 */
export type ProcessGridSchemaParams = {
  /** 主对象对象类定义 */
  // mainMetaClass: MetaClass
  /** 环境 */
  environment: EnumEditorEnvironment
}

/**
 * 处理布局
 * @param controls 布局
 * @param params 参数
 */
export const processSchema = (controls: Array<KContainer | KControl>, params: ProcessFormSchemaParams) => {
  for (const control of controls) {
    if ('controls' in control) {
      processSchema(control.controls, params)
      continue
    }
    if (control.control === 'KDataGrid') {
      processGridSchema(control.props['columns'], params as any)
      continue
    }
    /** 通过名称拆分 对象类#字段名称#唯一标识 */
    const nameAttrs = control.name.split('#')
    /** 对象标识 */
    const clasIdentity = nameAttrs[0]

    /** 属性定义 */
    // let metaProperty: MetaProperty
    // let referenceClsCode: string = ''

    // 如果不是主对象
    // if (!(clasIdentity == MAIN_CLASS_CODE)) {
    //   // 1.通过名称拆分获取引用属性对象类编码   属性编码$字段名称#唯一标识
    //   const propertyName = control.name.split('#')[1].split('$')! //control.field?.split('$')!
    //   // 2.获取引用属性元信息
    //   metaProperty = params.mainMetaClass.properties.find(a => a.code.trim() == propertyName[0])!

    //   referenceClsCode = propertyName[0]
    // } else {
    //   metaProperty = params.mainMetaClass.properties.find(a => a.code.toUpperCase() == control.field!.toUpperCase())!
    // }

    // const { readonly, visible, nullable } = metaProperty
    // const newParam = {
    //   nullable,
    //   visible,
    //   disabled: readonly
    // }
    // Object.assign(control, newParam)
    //获取属性编辑控制
    // if (metaProperty && metaProperty.editor) {
    //   const { nullable } = metaProperty.editor
    //   control.nullable = nullable
    // }

    /** 编辑器属性 */
    const editorProps: VNodeProps<KBasePropertyEditorPropType> = {
      api: params.api(control),
      environment: params.environment,
      // property: metaProperty,
      // dataSource: control.dataSource,
      // referenceClsCode: referenceClsCode,
      field: control.field
      // ...parseEditorArgs(
      //   metaProperty && metaProperty.editor && metaProperty.editor.editArgs ? metaProperty.editor.editArgs : '{}'
      // )
    }

    Object.assign(control.props, editorProps)
  }
}

/**
 * 处理筛选网格布局方案
 * @param controls 布局
 * @param params 参数
 */
export const processFilterGridSchema = (control: KControl, params: ProcessGridSchemaParams) => {
  const views = control.props.views as Array<KViewSchema>
  views.forEach(view => {
    view.elements.forEach(column => {
      processGridColumn(column, params)
    })
  })
}

/**
 * 处理网格布局方案
 * @param controls 布局
 * @param params 参数
 */
export const processGridSchema = (
  columns: Array<KGridColumnGroup | KGridColumn | KGridColumnCompute>,
  params: ProcessGridSchemaParams
) => {
  columns.forEach(column => {
    processGridColumn(column, params)
  })
}

/**
 * 处理网格列
 * @param column 网格列
 * @param params
 */
const processGridColumn = (column: KGridColumnGroup | KGridColumn | KGridColumnCompute, params: ProcessGridSchemaParams) => {
  /** 通过名称拆分 对象类#字段名称#唯一标识 */
  const nameAttrs = column.name.split('#')
  /** 对象标识 */
  const clasIdentity = nameAttrs[0]

  // /** 属性定义 */
  // let metaProperty: MetaProperty

  // // 如果不是主对象
  // if (!(clasIdentity == MAIN_CLASS_CODE)) {
  //   // 1.通过名称拆分获取引用属性对象类编码   属性编码$字段名称#唯一标识
  //   const propertyName = column.field?.split('$')!
  //   // 2.获取引用属性元信息
  //   metaProperty = params.mainMetaClass.properties.find(a => a.code == propertyName[0])!
  // } else {
  //   metaProperty = params.mainMetaClass.properties.find(a => a.code == column.field)!
  // }

  /** 编辑器属性 */
  const editorProps: VNodeProps<KBasePropertyEditorPropType> = {
    environment: params.environment,
    // property: metaProperty,
    // dataSource: column.dataSource,
    field: column.field
    // ...parseEditorArgs(metaProperty && metaProperty.editor && metaProperty.editor.editArgs ? metaProperty.editor.editArgs : '{}')
  }

  Object.assign(column.props, editorProps)
}

/**
 * 解析编辑参数
 * @param params
 * @returns
 */
const parseEditorArgs = (params: string): Record<string, any> => {
  try {
    const result = JSON.parse(params)
    if (typeof result == 'object') {
      return result
    }
  } finally {
    // 解析有可能出错
  }
  return {}
}

/** 获取零部件视图 */
const getView = (row: any) => {
  const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
  const partView = cacheInstance.getAll()
  if (row.targetViewId) {
    const view = partView.filter(item => item.id == row.targetViewId)[0].description
    return '(' + view + ')'
  }
  return ''
}

/** 导出Excel网格处理方法 */
export function processCellCallback(params: Record<string, any>, gridViewModel: KDataGridViewModel | undefined) {
  const excelCellContainer = document.createElement('div')
  if (typeof params.value === 'boolean') {
    return params.value ? '是' : '否'
  } else if (params.column.colId == 'targetExtensionType') {
    const view = getView(params.node.data)
    return params.value ? clsCodeMap.get(params.value) + view : ''
  } else if (params.column.colId == 'lifecycleStateCode') {
    return params.value ? EnumLifecycleState[params.value] : ''
  } else if (params.column.colId == 'creator' || params.column.colId == 'modifier') {
    return params.value ? params.value.split(' ')[0] : ''
  } else {
    const declaringColumn = gridViewModel?.getColumnDefs().find(column => column.id === params.column.getColId()),
      cellParam = {
        cellValue: params.value,
        col: declaringColumn,
        row: params.node?.data,
        rowIndex: params.node?.rowIndex,
        getHost: () => self
      }
    if (utils.isFunction(declaringColumn?.getCellTemplate) && !(declaringColumn instanceof KDataGridTemplateColumn)) {
      // 将 VNode 渲染为 DOM
      render(declaringColumn?.getCellTemplate!(cellParam as any) || null, excelCellContainer)
      const displayValue = (excelCellContainer as HTMLElement)?.innerText
      if (displayValue !== null && displayValue !== undefined) return displayValue
    }
    return params.value
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KnavigationStyleTestView from './KnavigationStyleTest.vue'
import KnavigationStyleTestViewModel from './KnavigationStyleTestViewModel'

const KnavigationStyleTest = connect(KnavigationStyleTestView, KnavigationStyleTestViewModel)

export default withInstall(KnavigationStyleTest)
export { KnavigationStyleTest, KnavigationStyleTestView, KnavigationStyleTestViewModel }

// 模板生成文件
// export * from './{{folderName}}'

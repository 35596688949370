import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumClsDataType, EnumClsEditorType } from '../../../enums'

// form
export interface ICustomEditorFormDefinition {
  dataLength: string
  componentFullName: string
}

// 对象界面定义定义事件
export const KCustomEditorCustomPropsEventEmits = {
  ...BaseViewEventEmits
}

// 对象界面定义定义属性选项
export const KCustomEditorCustomPropOptions = {
  ...BaseViewPropOptions,
  /** 当前编辑类型的自定义参数 */
  editArgs: VuePropTypes.string().def(),
  /** 当前属性id */
  propertyId: VuePropTypes.string().def(),
  /** 当前编辑对象类的数据类型 */
  dataType: VuePropTypes.createType<EnumClsDataType>().def(),
  /** 当前编辑对象类的数据的编辑类型 */
  editType: VuePropTypes.createType<EnumClsEditorType>().def(),
  /** 是否只读 */
  readonly: VuePropTypes.bool().def(false)
}

// emit type
export type KCustomEditorCustomPropsEventEmitsType = ViewEmitsTypeExtract<typeof KCustomEditorCustomPropsEventEmits>

// 对象界面定义定义属性类型
export type KCustomEditorCustomPropType = ViewPropsTypeExtract<typeof KCustomEditorCustomPropOptions>

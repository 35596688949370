import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KRuleDefineTestPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KRuleDefineTestPropType = ViewPropsTypeExtract<typeof KRuleDefineTestPropOptions>

/** 事件 */
export const KRuleDefineTestEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KRuleDefineTestEmitsType = ViewEmitsTypeExtract<typeof KRuleDefineTestEventEmits>

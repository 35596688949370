
import { defineView } from '@kmsoft/upf-core'
import { KObjectClassLayoutDefinitionFormPropOptions, KObjectClassLayoutDefinitionFormEventEmits } from './interface'
import KObjectClassLayoutDefinitionFormViewModel from './KObjectClassLayoutDefinitionFormViewModel'

export default defineView({
  name: 'KObjectClassLayoutDefinitionForm',
  props: KObjectClassLayoutDefinitionFormPropOptions,
  emits: KObjectClassLayoutDefinitionFormEventEmits,
  viewModel: KObjectClassLayoutDefinitionFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      /**布局类型 */
      typeOptions: [
        { label: '新建', value: 'new' },
        { label: '编辑', value: 'edit' },
        { label: '网格', value: 'grid' },
        { label: '列表', value: 'list' }
      ],
      /** 布局样式相关：表单布局、标签列布局、控件布局 */
      formStyle: {
        /** 设置表单布局，默认为horizontal*/
        layout: 'horizontal',
        /** 设置标签列布局，同Col组件，*/
        labelCol: { span: 3, offset: 5 },
        /** 设置控件列布局，同Col组件，*/
        wrapperCol: { span: 12, offset: 0 }
      }
    }
  }
})

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KSelectFillEditorEmitsType, KSelectFillEditorPropType } from './interface'
import { KBasePropertyEditorViewModel } from '../../base'

/** 选择填写 */
export default class KSelectFillEditorViewModel extends KBasePropertyEditorViewModel<
  KSelectFillEditorEmitsType,
  KSelectFillEditorPropType
> {
  constructor(options: ViewModelOptions<KSelectFillEditorPropType>) {
    super(options)
  }

  viewDidMount() {}
}

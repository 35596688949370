import { ILocale } from './types'

/** 简体中文 */
class Locale implements ILocale {
  readonly MSG_LOADING: string = '加载中...'
  readonly MSG_PLS_SELECT_A_ROW: string = '请选择一行记录'
  readonly MSG_PLS_SELECT_A_TREE_NODE: string = '请选择一个树节点'
  readonly MSG_PLS_SELECT_A_OBJ_CLASS: string = '请选择一个对象类'
  readonly MSG_PLS_SELECT_A_OBJ_CLASS_NODE: string = '请选择一个对象类节点'
  readonly MSG_OBJ_DELETED: string = '对象不存在，或者对象已被删除！'

  readonly MSG_DUPLICATED_ROW: string = '不能选择重复的对象'

  readonly MSG_NO_SEARCH_CONDITION: string = '查询条件不能为空'
  readonly MSG_NO_SEARCH_RESULT: string = '查询结果为空'
  readonly MSG_SEARCH_REACH_LAST: string = '已经是最后一个结果'
  readonly MSG_SEARCH_REACH_FIRST: string = '已经是第一个结果'

  //#region 何处使用
  readonly MSG_NOWHERE_USE: string = '当前零部件未被装配件引用'
  //#endregion

  //#region 权限
  readonly OBJECT_NO_PURVIEW: string = '该对象暂无权限，可能未授予该权限或已授权的权限已经过期'
  //#endregion
}

export default new Locale()


import { defineView } from '@kmsoft/upf-core'
import { KPartCreateBaselinePropOptions, KPartCreateBaselineEventEmits } from './interface'
import KPartCreateBaselineViewModel from './KPartCreateBaselineViewModel'

export default defineView({
  name: 'KPartCreateBaseline',
  props: KPartCreateBaselinePropOptions,
  emits: KPartCreateBaselineEventEmits,
  viewModel: KPartCreateBaselineViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../../propsConfigItemCollection'
import { KControlAttribute, KControlEvent } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../../layoutConfigItemCollection'

/* 属性配置项集合 */
const BASE = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE] as Array<KControlAttribute>

/**控件属性 */
const PROPS = [] as Array<KControlAttribute>
/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.WIDTH,
  LAYOUT_CONFIG_ITEM_COLLECTION.ALIGN,
  LAYOUT_CONFIG_ITEM_COLLECTION.FROZEN,
  // LAYOUT_CONFIG_ITEM_COLLECTION.ROW_GROUP,
  // LAYOUT_CONFIG_ITEM_COLLECTION.ENABLE_ROW_GROUP,
  LAYOUT_CONFIG_ITEM_COLLECTION.HIDE
] as Array<KControlAttribute>

const EVENT = [] as Array<KControlEvent>

export default { BASE, PROPS, LAYOUT, EVENT }

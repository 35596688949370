
import { defineComponent } from 'vue'
import KAccountMenu from './KAccountMenu.vue'
import { VuePropTypes, eventEmitter, KNotification } from '@kmsoft/upf-core'
import { CommonClientSrv, EBF_IOC_KEY } from '@kmsoft/ebf-common'
import { KObjectSearchGrid } from '../../../pages/object-search-grid'
import { debounce } from 'lodash'

export default defineComponent({
  name: 'KExtendLayoutHeader',
  components: {
    KAccountMenu
  },
  props: {
    appName: VuePropTypes.string().def(),
    theme: { type: String, required: false, default: 'dark' }
  },
  data() {
    return {
      visible: true,
      oldScrollTop: 0,
      fixHeader: true,
      title: ''
    }
  },
  watch: {
    $route: {
      handler(to) {
        this.title = to.meta.title
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    searchObject: debounce(function(this: any, val: any) {
      if (val.newValue) {
        eventEmitter.emit(EBF_IOC_KEY.SET_SEARCH_PARAMS, 'changeParams', { data: val.newValue } as any)
        this.openWorkflowPage(val)
      } else {
        KNotification.warn({
          message: '系统提示',
          description: '请输入查询条件'
        })
      }
    }, 500),
    /**
     * 打开搜索结果页面
     * @param row 选中行
     */
    openWorkflowPage(row: any) {
      CommonClientSrv.openPage(
        '快速搜索',
        KObjectSearchGrid,
        {
          params: row.newValue
        },
        'search'
      )
    }
  }
})

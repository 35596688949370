import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-775dc0f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border1 h100p" }
const _hoisted_2 = { style: {"height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, { class: "panel-content" }, {
    header: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createVNode(_component_k_tool_strip, null, {
          default: _withCtx(() => [
            _createVNode(_component_k_tool_strip_button_item, {
              title: "添加",
              icon: "plus",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vm.addData('')))
            }),
            _createVNode(_component_k_tool_strip_button_item, {
              title: "刷新",
              icon: "sync",
              onClick: _ctx.$vm.refresh
            }, null, 8, ["onClick"]),
            (!_ctx.$vm.isEdit)
              ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                  key: 0,
                  title: "编辑",
                  icon: "save",
                  onClick: _ctx.$vm.beginEdit
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.$vm.isEdit)
              ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                  key: 1,
                  title: "保存",
                  icon: "save",
                  onClick: _ctx.$vm.save
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_2, [
        _createVNode(_component_k_data_grid, {
          ref: "refDataGridView",
          rowKey: "id",
          isMultipleSelection: false,
          suppressMenu: false,
          onCellContextMenu: _ctx.$vm.onCellContextMenu,
          treeData: true,
          isLazyLoadTreeData: false,
          loadData: _ctx.$vm.loadData,
          rowModelType: 'serverSide',
          autoIndex: false
        }, {
          columns: _withCtx(() => [
            _createVNode(_component_k_data_grid_text_box_column, {
              id: "name",
              headerText: "类型",
              sortable: true,
              dataPropertyName: "name",
              align: "left",
              width: "auto"
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              id: "description",
              headerText: "名称",
              sortable: true,
              dataPropertyName: "description",
              align: "left",
              width: "auto"
            })
          ]),
          _: 1
        }, 8, ["onCellContextMenu", "loadData"])
      ])
    ]),
    _: 1
  }))
}
import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { KBasePropertyEditorEventEmits, KBasePropertyEditorPropOptions } from '../../base'

/** 参数 **/
export const KReferenceEditorPropOptions = {
  ...KBasePropertyEditorPropOptions,
  /** 是否可用 */
  disabled: VuePropTypes.bool()
    .setRequired()
    .def(false),
  /** 是否只读模式 */
  readonly: VuePropTypes.bool()
    .setRequired()
    .def(false),
  /** 值 */
  value: VuePropTypes.createType<string | Record<string, any>>().def(),
  /** 当前属性引用的列*/
  refObjCodes: VuePropTypes.createType<Array<string>>()
    .setRequired()
    .def(),
  /**当前引用属性的对象类编码 */
  refClsCode: VuePropTypes.string().def('')
}

/** 参数类型 **/
export type KReferenceEditorPropType = ViewPropsTypeExtract<typeof KReferenceEditorPropOptions>

/** 事件 */
export const KReferenceEditorEventEmits = {
  ...KBasePropertyEditorEventEmits,
  /** 值改变事件 */
  change: (value: any) => true
}

/** 事件类型 **/
export type KReferenceEditorEmitsType = ViewEmitsTypeExtract<typeof KReferenceEditorEventEmits>

import { EnumAttributeType, EnumDataType } from '@kmsoft/upf-core'
import { DEFAULT_CURRENCY_LAYOUT_PROPS, DEFAULT_CURRENCY_PROPS } from '../../type'
/* 组件的基本属性 */
const comConfig = [
  {
    name: 'editArgs',
    title: '候选项配置',
    control: 'SelectOptionsConfig',
    defaultValue: '',
    dataType: EnumDataType.ARRAY,
    attributeType: EnumAttributeType.PROPS
  }
]

/**控件属性配置项集合 */
const PROPS = [] as Array<any>

const EVENT = [
  { name: 'beforeValueChange', title: '值改变前', describe: '值改变前 的说明信息...' },
  { name: 'valueChanged', title: '值改变后', describe: '值改变后 的说明信息...' }
]

export default { PROPS, EVENT }

import { BaseViewModel, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KVersionGroupObjectSelectFormEmitsType, KVersionGroupObjectSelectFormPropType } from './interface'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** KVersionGroupObjectSelectForm */
export default class KVersionGroupObjectSelectFormViewModel extends BaseViewModel<
  KVersionGroupObjectSelectFormEmitsType,
  KVersionGroupObjectSelectFormPropType
> {
  /**网格组件 */
  refHistoryDataGrid = ref<KDataGridViewModel>()
  constructor(options: ViewModelOptions<KVersionGroupObjectSelectFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 历史版本
   */
  async loadHistoryData() {
    const id = this.props.partId
    const param = {
      data: [id]
    }

    const result = await Api.post('part', 'Part', 'listPartHistory', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return {
        rows: result.data,
        total: result.data.length
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 获取选中的行数据
   * @returns 选中的行数据
   */
  getSelectRow() {
    return this.refHistoryDataGrid.value?.getSelectedRows()
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KAgentMonitorView from './KAgentMonitor.vue'
import KAgentMonitorViewModel from './KAgentMonitorViewModel'

const KAgentMonitor = connect(KAgentMonitorView, KAgentMonitorViewModel)

export default withInstall(KAgentMonitor)
export { KAgentMonitor, KAgentMonitorView, KAgentMonitorViewModel }

// 模板生成文件
// export * from './{{folderName}}'

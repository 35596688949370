import { EnumAttributeType, EnumBaseControl, EnumDataType, EnumEditType } from '@kmsoft/upf-core'
import { BOOLEAN_CANDIDATE, DEFAULT_LABEL_ALIGN_CANDIDATE, DEFAULT_LABEL_POSITION_CANDIDATE } from './interface'
import { KControlAttribute } from '@kmsoft/upf-core'

/** 布局项名称枚举 */
export enum EnumLayoutConfigItemName {
  /** 标题位置 */
  TITLE_POSITION = 'titlePosition',
  /** 标签宽度 */
  LABEL_WIDTH = 'labelWidth',
  /** 行跨度 */
  ROW_SPAN = 'rowSpan',
  /** 列跨度 */
  COL_SPAN = 'colSpan',
  /** 占整行 */
  ENTIRE_ROW = 'entireRow',
  /** 最小列宽 */
  MIN_COL_WIDTH = 'minColWidth',
  /** 行间距 */
  COL_GAP = 'colGap',
  /** 列间距 */
  ROW_GAP = 'rowGap',
  /** 列数 */
  COL_COUNT = 'colCount',
  /** 默认展开 */
  EXPAND = 'expand',
  /** 是否占满空间  */
  FILL = 'fill',
  /** 位置 */
  LABEL_POSITION = 'labelPosition',
  /** 宽度 */
  WIDTH = 'width',
  /** 对齐方式 */
  ALIGN = 'align',
  /** 冻结 */
  FROZEN = 'frozen',
  /** 是否开启分组 */
  ROW_GROUP = 'rowGroup',
  /** 开启分组，可拖拽 */
  ENABLE_ROW_GROUP = 'enableRowGroup',
  /** 列是否隐藏 */
  HIDE = 'hide'
}

/* 所有属性 */
export const LAYOUT_CONFIG_ITEM_COLLECTION = {
  LABEL_POSITION: {
    name: EnumLayoutConfigItemName.LABEL_POSITION,
    title: '标签位置',
    control: 'KSelect',
    attributeType: EnumAttributeType.PROPS,
    options: DEFAULT_LABEL_POSITION_CANDIDATE,
    defaultValue: 'left',
    dataType: EnumDataType.STRING
  } as KControlAttribute,
  LABEL_WIDTH: {
    name: EnumLayoutConfigItemName.LABEL_WIDTH,
    title: '标签宽度',
    control: EnumBaseControl.NUMBER,
    attributeType: EnumAttributeType.PROPS,
    defaultValue: 50,
    dataType: EnumDataType.INT
  },
  ROW_SPAN: {
    name: EnumLayoutConfigItemName.ROW_SPAN,
    title: '行跨度',
    control: EnumBaseControl.NUMBER,
    attributeType: EnumAttributeType.LAYOUT,
    defaultValue: 1,
    dataType: EnumDataType.INT
  },
  COL_SPAN: {
    name: EnumLayoutConfigItemName.COL_SPAN,
    title: '列跨度',
    control: EnumBaseControl.NUMBER,
    attributeType: EnumAttributeType.LAYOUT,
    defaultValue: 1,
    dataType: EnumDataType.INT
  },
  ENTIRE_ROW: {
    name: EnumLayoutConfigItemName.ENTIRE_ROW,
    title: '占整行',
    control: 'KSelect',
    attributeType: EnumAttributeType.LAYOUT,
    options: BOOLEAN_CANDIDATE,
    defaultValue: false,
    dataType: EnumDataType.BOOL
  },
  MIN_COL_WIDTH: {
    name: EnumLayoutConfigItemName.MIN_COL_WIDTH,
    title: '最小列宽',
    control: EnumBaseControl.NUMBER,
    attributeType: EnumAttributeType.PROPS,
    defaultValue: 260,
    dataType: EnumDataType.INT
  },
  COL_GAP: {
    name: EnumLayoutConfigItemName.COL_GAP,
    title: '列间距',
    control: EnumBaseControl.NUMBER,
    attributeType: EnumAttributeType.PROPS,
    defaultValue: 10,
    dataType: EnumDataType.INT
  },
  ROW_GAP: {
    name: EnumLayoutConfigItemName.ROW_GAP,
    title: '行间距',
    control: EnumBaseControl.NUMBER,
    attributeType: EnumAttributeType.PROPS,
    defaultValue: 10,
    dataType: EnumDataType.INT
  },
  COL_COUNT: {
    name: EnumLayoutConfigItemName.COL_COUNT,
    title: '列数',
    control: EnumBaseControl.NUMBER,
    attributeType: EnumAttributeType.PROPS,
    defaultValue: 3,
    dataType: EnumDataType.INT
  },
  EXPAND: {
    name: EnumLayoutConfigItemName.EXPAND,
    title: '默认展开',
    editType: EnumEditType.DROPDOWN,
    control: 'KSelect',
    attributeType: EnumAttributeType.PROPS,
    options: BOOLEAN_CANDIDATE,
    defaultValue: true,
    dataType: EnumDataType.BOOL
  },
  FILL: {
    name: EnumLayoutConfigItemName.FILL,
    title: '占满空间',
    control: 'KSelect',
    attributeType: EnumAttributeType.LAYOUT,
    options: BOOLEAN_CANDIDATE,
    defaultValue: false,
    dataType: EnumDataType.BOOL
  },
  WIDTH: {
    name: EnumLayoutConfigItemName.WIDTH,
    title: '宽度',
    control: EnumBaseControl.NUMBER,
    attributeType: EnumAttributeType.LAYOUT,
    defaultValue: 100,
    dataType: EnumDataType.INT
  },
  ALIGN: {
    name: EnumLayoutConfigItemName.ALIGN,
    title: '对齐方式',
    control: 'KSelect',
    attributeType: EnumAttributeType.LAYOUT,
    options: DEFAULT_LABEL_ALIGN_CANDIDATE,
    defaultValue: 'left',
    dataType: EnumDataType.STRING
  },
  FROZEN: {
    name: EnumLayoutConfigItemName.FROZEN,
    title: '冻结',
    control: 'KSelect',
    attributeType: EnumAttributeType.LAYOUT,
    options: BOOLEAN_CANDIDATE,
    defaultValue: false,
    dataType: EnumDataType.BOOL
  },
  HIDE: {
    name: EnumLayoutConfigItemName.HIDE,
    title: '列是否隐藏',
    control: 'KSelect',
    attributeType: EnumAttributeType.LAYOUT,
    options: BOOLEAN_CANDIDATE,
    defaultValue: false,
    dataType: EnumDataType.BOOL
  }
}

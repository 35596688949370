import { connect, withInstall } from '@kmsoft/upf-core'
import KTreeGridDemoView from './KTreeGridDemo.vue'
import KTreeGridDemoViewModel from './KTreeGridDemoViewModel'

const KTreeGridDemo = connect(KTreeGridDemoView, KTreeGridDemoViewModel)

export default withInstall(KTreeGridDemo)
export { KTreeGridDemo, KTreeGridDemoView, KTreeGridDemoViewModel }

// 模板生成文件
// export * from './{{folderName}}'

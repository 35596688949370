import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineMemberReplaceGridView from './KBaselineMemberReplaceGrid.vue'
import KBaselineMemberReplaceGridViewModel from './KBaselineMemberReplaceGridViewModel'

const KBaselineMemberReplaceGrid = connect(KBaselineMemberReplaceGridView, KBaselineMemberReplaceGridViewModel)

export default withInstall(KBaselineMemberReplaceGrid)
export { KBaselineMemberReplaceGrid, KBaselineMemberReplaceGridView, KBaselineMemberReplaceGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, KDialog, KNotification, SimpleViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KActivityPlanDetailGridEmitsType, KActivityPlanDetailGridPropType } from './interface'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** KActivityPlanDetailGrid */
export default class KActivityPlanDetailGridViewModel extends SimpleViewModel<
  KActivityPlanDetailGridEmitsType,
  KActivityPlanDetailGridPropType
> {
  constructor(options: ViewModelOptions<KActivityPlanDetailGridPropType>) {
    super(options)
  }

  viewDidMount() {}

  removeAffectedObject(row: any) {
    KDialog.confirm({
      content: '确认要删除该关联对象么？',
      onOk: () => {
        this.emit('removeAffectedObject', row)
      },
      onCancel: () => {}
    })
  }
}

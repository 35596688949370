import { request, RequestConfig } from '@kmsoft/upf-core'

export class Api {
  // 上传文件代理
  static uploadProxyUrl = '/kmsaasFileApi'
  static baseApiUrl = '/kmsaasApi/request'

  /**
   * post 请求
   * @param group 分组
   * @param modelCode 对象类编码
   * @param behavior 行为
   * @param data 业务数据
   * @returns
   */
  static async post(group: string, modelCode: string, behavior: string, data: any, config?: RequestConfig) {
    const url = Api.baseApiUrl + '/' + group + '/' + modelCode + '/' + behavior
    return (await request.post(url, data, config)) as any
  }

  static async get(model: string, modelCode: string, behavior: string, data: any, config?: RequestConfig) {
    const url = Api.baseApiUrl + '/' + model + '/' + modelCode + '/' + behavior
    return (await request.get(url, data, config)) as any
  }
}

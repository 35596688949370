import { connect, withInstall } from '@kmsoft/upf-core'
import KSelectWrapperView from './KSelectWrapper.vue'
import KSelectWrapperViewModel from './KSelectWrapperViewModel'

const KSelectWrapper = connect(KSelectWrapperView, KSelectWrapperViewModel)

export default withInstall(KSelectWrapper)
export { KSelectWrapper, KSelectWrapperView, KSelectWrapperViewModel }
export * from './KSelectWrapperResolver'
export * from './KDataGridSelectWrapperColumn'

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  KControlAttribute,
  KControlEvent,
  KRootLayout,
  LayoutContainerElement,
  LayoutRenderElement,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KDesignerControl } from '../../../types/metadata'

export interface ElementInfo {
  element: LayoutRenderElement
  /**属性 */
  attrs: Array<KControlAttribute>
  /**事件 */
  events: Array<KControlEvent>
}

/** KPreview 事件 **/
export const KPreviewEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 布局元素选中事件
   * @param element 选中元素的布局信息
   */
  elementSelected: (element: LayoutRenderElement | undefined) => true,
  /**
   * 元素删除事件
   * @param elementName 元素名
   */
  elementDelete: (element: LayoutRenderElement) => true,
  /**
   * 元素拖入事件
   * @param elementName 元素名
   */
  elementDragIn: (elementName: string) => true
}

/** KPreview 参数 **/
export const KPreviewPropOptions = {
  ...BaseViewPropOptions,
  /** 布局 */
  schema: VuePropTypes.createType<LayoutContainerElement>()
    .setRequired()
    .def(),
  /** 根布局 */
  rootSchema: VuePropTypes.createType<KRootLayout>()
    .setRequired()
    .def(),
  /** 选中的元素 */
  selectedElement: VuePropTypes.createType<LayoutRenderElement | undefined>().def(),
  /** 控件类型定义 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def()
}

export const KDefaultColumnsType = [
  'KDataGridTextBoxColumn',
  'KDataGridTextAreaColumn',
  'KDataGridCheckBoxColumn',
  'KDataGridComboBoxColumn',
  'KDataGridDateTimeColumn',
  'KDataGridNumberColumn'
]

/** KPreview 参数类型 */
export type KPreviewPropType = ViewPropsTypeExtract<typeof KPreviewPropOptions>

/** KPreview 事件协议 */
export type KPreviewEventEmitsType = ViewEmitsTypeExtract<typeof KPreviewEventEmits>

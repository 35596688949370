import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartCreatePromotePropOptions = {
  ...BaseViewPropOptions,
  phase: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KPartCreatePromotePropType = ViewPropsTypeExtract<typeof KPartCreatePromotePropOptions>

/** 事件 */
export const KPartCreatePromoteEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartCreatePromoteEmitsType = ViewEmitsTypeExtract<typeof KPartCreatePromoteEventEmits>

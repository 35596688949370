import { connect, withInstall } from '@kmsoft/upf-core'
import KPersonalHomePageView from './KPersonalHomePage.vue'
import KPersonalHomePageViewModel from './KPersonalHomePageViewModel'

const KPersonalHomePage = connect(KPersonalHomePageView, KPersonalHomePageViewModel)

export default withInstall(KPersonalHomePage)
export { KPersonalHomePage, KPersonalHomePageView, KPersonalHomePageViewModel }

export * from './interface'

// 模板生成文件
// export * from './{{folderName}}'

import { ISearchFilterTree, SearchFilterTreeLeaf } from './filterTree'
import { GlobalException } from '@kmsoft/upf-core'
import { ObjectHelper } from '../../../../../client-srv'
import { RuleItem, RuleResult } from '../interface'

/** 基础操作符字段 */
type TokenBase = { from: number; to: number }
/** 相等 */
type IdentToken = TokenBase & { type: 'ident'; val: string }
/** 左括号 */
type LeftBracketToken = TokenBase & { type: 'lb' }
/** 右括号 */
type RightBracketToken = TokenBase & { type: 'rb' }
/** 且或 */
type AndOrToken = TokenBase & { type: 'op'; val: 'and' | 'or' }
/** 空格 */
type SeparatedToken = TokenBase & { type: 'sep' }
/** 操作符 */
type Token = IdentToken | LeftBracketToken | RightBracketToken | AndOrToken | SeparatedToken

type BracketPair = Record<number, number>

export type Ast =
  | {
      type: 'Con'
      op: 'and' | 'or'
      left: Ast
      right: Ast
    }
  | {
      type: 'Ident'
      val: string
    }
  | {
      type: 'Bracketed'
      node: Ast
    }

export type ParseError = string

/** 解析结果 */
export type Result<T, E> =
  | {
      ok: true
      val: T
    }
  | {
      ok: false
      val: E
    }

function tokenFormat(token: Token) {
  return `<${token.type}>@[${token.from},${token.to}]`
}

/** */
function err<T, E>(e: E): Result<T, E> {
  return {
    ok: false,
    val: e
  }
}

/** */
function ok<T, E>(x: T): Result<T, E> {
  return {
    ok: true,
    val: x
  }
}

function map<T, U, E>(r: Result<T, E>, f: (x: T) => U): Result<U, E> {
  if (r.ok) {
    return {
      ok: true,
      val: f(r.val)
    }
  } else {
    return r as Result<U, E>
  }
}

function bracket(ast: Ast): Ast {
  return {
    type: 'Bracketed',
    node: ast
  }
}

/**
 * 匹配括号
 * @param tokens
 * @returns
 */
function pair(tokens: Array<Token>): Result<BracketPair, ParseError> {
  const lbStack: Array<number> = []
  const pairs: BracketPair = {}
  for (let index = 0; index < tokens.length; index += 1) {
    const token = tokens[index]
    if (token.type === 'lb') {
      lbStack.push(index)
    } else if (token.type === 'rb') {
      const leftIdx = lbStack.pop()
      if (leftIdx === undefined) {
        return err(`多余的右括号${tokenFormat(token)}`)
      }
      pairs[leftIdx] = index
    } else {
      continue
    }
  }
  if (lbStack.length !== 0) {
    return err(`缺少相应右括号的左括号${tokenFormat(tokens[lbStack[0]])}`)
  }
  return ok(pairs)
}

function lexizor(tokens: Array<Token>, from: number, to: number, bracketPair: BracketPair): Result<Ast, ParseError> {
  let left: Ast

  const first = tokens[from]
  let second_idx = from + 1

  if (first.type === 'lb') {
    const leftResult = map(lexizor(tokens, from + 1, bracketPair[from], bracketPair), bracket)
    if (leftResult.ok) {
      left = leftResult.val
      second_idx = bracketPair[from] + 1
    } else {
      return leftResult
    }
  } else if (first.type === 'ident') {
    left = {
      type: 'Ident',
      val: first.val
    }
  } else {
    return err(`应当为左括号或者id${tokenFormat(first)}`)
  }

  if (second_idx === to) {
    return ok(left)
  }

  const second = tokens[second_idx]

  if (second.type === 'op') {
    const node = lexizor(tokens, second_idx + 1, to, bracketPair)
    return map(node, n => ({ type: 'Con', op: second.val, left, right: n }))
  } else {
    return err(`应当为运算符${tokenFormat(second)}`)
  }
}

//
//    <&>           <|>
//    / \           / \
//   1  <|>  ->   <&>  3
//      / \       / \
//     2   3     1   2
function transpose(ast: Ast): Ast {
  if (ast.type === 'Con' && ast.op === 'and') {
    const right = ast.right
    if (right.type === 'Con' && right.op === 'or') {
      ast.right = right.left
      return {
        ...right,
        left: ast,
        right: right.right
      }
    }
  }
  return ast
}

function transposeAll(ast: Ast): Ast {
  switch (ast.type) {
    case 'Bracketed':
      ast.node = transposeAll(ast.node)
      return ast
    case 'Con':
      ast.left = transposeAll(ast.left)
      ast.right = transposeAll(ast.right)
      return transpose(ast)
    default:
      return ast
  }
}

/** 表达式匹配 */
const regExpRepo: Array<{ re: RegExp | string; asToken: (from: number, res: RegExpMatchArray) => Token }> = [
  {
    re: /^and/, // 且
    asToken: (from, res) => ({
      type: 'op',
      val: 'and',
      from,
      to: from + 3
    })
  },
  {
    re: /^or/, // 或
    asToken: (from, res) => ({
      type: 'op',
      val: 'or',
      from,
      to: from + 2
    })
  },
  {
    re: /^\(/, // 左括号
    asToken: (from, res) => ({
      type: 'lb',
      from,
      to: from + 1
    })
  },
  {
    re: /^\)/, // 右括号
    asToken: (from, res) => ({
      type: 'rb',
      from,
      to: from + 1
    })
  },
  {
    re: new RegExp(/^\d+/), // 匹配数字
    asToken: (from, res) => ({
      type: 'ident',
      val: res[0],
      from,
      to: from + res[0].length
    })
  },
  {
    re: new RegExp(/^\s+/), // 匹配空格
    asToken: (from, res) => ({
      type: 'sep',
      from,
      to: from + res[0].length
    })
  }
]

/**
 * 分词器
 * @param expression 表达式
 * @description 将表达式转操作列表
 * @returns
 */
function tokenizor(expression: string): Result<Array<Token>, ParseError> {
  let code = expression
  let idx = 0
  const tokens: Array<Token> = []

  outer: while (code.length !== 0) {
    for (const rule of regExpRepo) {
      const regExp: RegExp = new RegExp(rule.re, 'i')
      const matchResult = code.match(regExp)
      if (matchResult !== null) {
        const token = rule.asToken(idx, matchResult)
        idx += matchResult[0].length
        code = expression.slice(idx)
        if (token.type !== 'sep') {
          tokens.push(token)
        }
        continue outer
      }
    }
    return err(`cant parse token at ${expression.length - code.length}`)
  }

  return ok(tokens)
}

export function expressionToAst(expression: string): Result<Ast, ParseError> {
  const tokens = tokenizor(expression)
  if (tokens.ok) {
    const bracketPair = pair(tokens.val)
    if (bracketPair.ok) {
      return map(lexizor(tokens.val, 0, tokens.val.length, bracketPair.val), transposeAll)
      // return lexizor(tokens.val, 0, tokens.val.length, bracketPair.val)
    } else {
      return bracketPair
    }
  } else {
    return tokens
  }
}

export function identSet(ast: Ast): Set<string> {
  if (ast.type === 'Ident') {
    return new Set([ast.val])
  } else if (ast.type === 'Bracketed') {
    return identSet(ast.node)
  } else {
    const left = identSet(ast.left)
    const right = identSet(ast.right)
    for (const li of left) {
      right.add(li)
    }
    return right
  }
}

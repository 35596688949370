import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_rule_definer = _resolveComponent("k-rule-definer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_k_rule_definer, {
      disabledObjClsTree: false,
      showObjClsTree: true,
      ruleId: "1768156810814033920",
      condition: _ctx.condition,
      "onUpdate:condition": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.condition) = $event))
    }, null, 8, ["condition"]),
    _createElementVNode("div", null, _toDisplayString(JSON.stringify(_ctx.condition)), 1)
  ], 64))
}
import { ActualParameter, IOTypeEnum } from './Parameter'

export class DpMsgXml {
  public static createActualParameter(
    FormalParameterID: string,
    IOType: string,
    DataType: string,
    IsArray: string,
    Value: string
  ): ActualParameter {
    const result: ActualParameter = {
      FormalParameterID: FormalParameterID,
      IOType: IOType,
      DataType: DataType,
      IsArray: IsArray,
      Value: Value
    }

    return result
  }

  /**
   *创建返回xml消息内容
   * @param xmlStr 收到的消息内容
   * @param result 方法执行结果，没有返回结果的给空字符串
   * @param outParameters Out类型的参数
   */
  public static createXmlResult(
    xmlStr: string,
    result: string | number | boolean,
    outParameters: Record<string, string | number | boolean>
  ): string {
    const xml = new DOMParser()
    const doc = xml.parseFromString(xmlStr, 'text/xml')

    /**ReturnParameter赋值*/
    if (result !== '') {
      const returnParameterValueNode = doc.querySelector('Application ReturnParameter Value')
      if (returnParameterValueNode) {
        returnParameterValueNode.innerHTML = String(result ?? '')
      }
    }

    /**Out类型的参数 */
    const actualParameterNodeList = doc.querySelectorAll('Application ActualParameters ActualParameter')
    for (const parameter in outParameters) {
      for (const actualParameterNode of actualParameterNodeList) {
        const parameterName = actualParameterNode.querySelector('FormalParameterID')?.innerHTML?.trim() ?? ''
        if (parameterName !== parameter) continue

        const ioType =
          actualParameterNode
            .querySelector('IOType')
            ?.innerHTML?.trim()
            ?.toLowerCase() ?? ''
        if (ioType != 'out') continue

        const valueNode = actualParameterNode.querySelector('Value')
        if (!valueNode) continue
        valueNode.innerHTML = String(outParameters[parameter] ?? '')
      }
    }

    const s = new XMLSerializer()
    return s.serializeToString(doc)
  }

  /**
   * 解析插件返回的xml
   * @param text xml 字符串
   * @returns 包装成对象的xml参数（包含入和出）
   */
  public static parseXml(text: string) {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(text, 'application/xml')

    // 提取有值的 ActualParameter
    const actualParameters: ActualParameter[] = []
    const parameters = xmlDoc.getElementsByTagName('ActualParameter')

    for (let i = 0; i < parameters.length; i++) {
      const formalParameterIDElement = parameters[i].getElementsByTagName('FormalParameterID')[0]
      const ioTypeElement = parameters[i].getElementsByTagName('IOType')[0]
      const dataTypeElement = parameters[i].getElementsByTagName('DataType')[0]
      const isArrayElement = parameters[i].getElementsByTagName('IsArray')[0]
      const valueElement = parameters[i].getElementsByTagName('Value')[0]

      const formalParameterID = formalParameterIDElement?.textContent || ''
      const ioType = ioTypeElement?.textContent || ''
      const dataType = dataTypeElement?.textContent || ''
      const isArray = isArrayElement?.textContent || ''
      const value = valueElement?.textContent || ''

      if (value) {
        actualParameters.push({
          FormalParameterID: formalParameterID,
          IOType: ioType,
          DataType: dataType,
          IsArray: isArray,
          Value: value
        })
      }
    }
    return actualParameters
  }

  /**
   *  过滤数据返回参数的value
   * @param actualParameters 参数数组对象
   * @param parameterId  参数标识
   * @param ioType 入参、出参
   * @returns  对应参数的value
   */
  public static getValueFromParamId(actualParameters: ActualParameter[], parameterId: string, ioType: IOTypeEnum) {
    if (actualParameters) {
      for (const item of actualParameters) {
        if (item.IOType.toUpperCase() == ioType.toUpperCase() && item.FormalParameterID == parameterId) {
          return item.Value
        }
      }
    }
  }

  /**
   * 对象转xml
   * @param obj 对象
   * @param rootElement 根节点
   * @returns xml字符串
   */
  public static objectToXML(obj: any, rootElement?: string): string {
    let xml = ''

    if (rootElement) {
      xml += `<${rootElement}>`
    }

    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const value = obj[key]
        if (Array.isArray(value)) {
          value.forEach(item => {
            xml += DpMsgXml.objectToXML(item, key)
          })
        } else if (typeof value === 'object') {
          xml += DpMsgXml.objectToXML(value, key)
        } else {
          xml += `<${key}>${value}</${key}>`
        }
      }
    }

    if (rootElement) {
      xml += `</${rootElement}>`
    }

    return xml
  }
}

import { BaseViewModel, KFormViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { IKPartViewFormDef, KPartViewFormEmitsType, KPartViewFormPropType } from './interface'
/** KPartViewForm */
export default class KPartViewFormViewModel extends BaseViewModel<KPartViewFormEmitsType, KPartViewFormPropType> {
  /**表单 */
  refPartViewForm = ref<KFormViewModel>()

  //允许的对象类选项
  allowedObjectClassesOptions = ref<Array<Record<string, any>>>([])
  //表单绑定数据
  formDataState = ref<IKPartViewFormDef>({
    id: '',
    name: '',
    description: '',
    parentId: ''
  })

  constructor(options: ViewModelOptions<KPartViewFormPropType>) {
    super(options)
  }

  viewDidMount() {
    this.loadData()
  }

  /**
   * 获取表单数据
   * @returns 表单数据
   */
  getFormData(): IKPartViewFormDef {
    return this.refPartViewForm.value?.getValues() as IKPartViewFormDef
  }

  loadData() {
    const formData = this.formDataState.value
    const formProp = this.props.formProp
    if (formProp) {
      formData.parentId = formProp.parentId!
    }
  }

  /**
   * getNormalData
   */
  public getNormalData() {
    return this.formDataState.value
  }
}

import { IColumnDefinition } from '../../../interface'
import {
  BaseViewPropOptions,
  ViewPropsTypeExtract,
  VuePropTypes,
  BaseViewEventEmits,
  ViewEmitsTypeExtract
} from '@kmsoft/upf-core'
import { UpdateConfigValidStatusEventArgs } from '../../sys-config-value/interface'

// 页面表单状态
export interface IConfigGridTableViewModelState {
  columns: Array<IColumnDefinition>
  upEnabled: boolean
  downEnabled: boolean
  dataLoaded: boolean
  mode: string
}

// 页面事件
export const KConfigGridTableEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 更新配置项(模块)有效性状态
   * @param validStatus 有效性
   */
  updateConfigValidStatus: (e: UpdateConfigValidStatusEventArgs) => true
}

export const KConfigGridTableViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('')
}

export type KConfigGridTableViewModelPropType = ViewPropsTypeExtract<typeof KConfigGridTableViewModelPropOptions>

export type KConfigGridTableEventEmitsType = ViewEmitsTypeExtract<typeof KConfigGridTableEventEmits>

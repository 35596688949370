
import { defineView } from '@kmsoft/upf-core'
import { KSysTabPropOptions } from './interface'
import { EnumConfigTabType, EnumTabType } from '../../interface'
import KSysTabViewModel from './KSysTabViewModel'
import SysConfigTree from '../sys-config-tree'
import ConfigValue from '../sys-config-value'
import ConfigGrid from '../sys-config-grid'
import ConfigCustom from '../../common/config-custom'
import ConfigTile from '../sys-config-tile'

export default defineView({
  components: { SysConfigTree, ConfigValue, ConfigGrid, ConfigCustom, ConfigTile },
  name: 'SysTab',
  props: KSysTabPropOptions,
  viewModel: KSysTabViewModel,
  setup() {
    return {
      EnumConfigTabType,
      EnumTabType
    }
  }
})

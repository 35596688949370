import { connect, withInstall } from '@kmsoft/upf-core'
import KCADWorkspaceView from './KCADWorkspace.vue'
import KCADWorkspaceViewModel from './KCADWorkspaceViewModel'

const KCADWorkspace = connect(KCADWorkspaceView, KCADWorkspaceViewModel)

export default withInstall(KCADWorkspace)
export { KCADWorkspace, KCADWorkspaceView, KCADWorkspaceViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import * as UpfCore from '@kmsoft/upf-core'

import KFileDialogViewModel from './KFileDialogViewModel'
import { KFileDialogPropOptions } from './interface'
import { KDataGridImageColumn } from './data-grid-column/KDataGridImageColumn'
import { KTreeNodeFileSystemInfo } from './tree-node/KTreeNodeFileSystemInfo'

// 导出组件
export default UpfCore.defineView({
  name: 'KFileDialog',
  viewModel: KFileDialogViewModel,
  components: { KDataGridImageColumn },
  props: KFileDialogPropOptions
})

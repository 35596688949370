import { BaseViewModel, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KChangeActivityUpdateCompareGridEmitsType, KChangeActivityUpdateCompareGridPropType } from './interface'
import { ref } from 'vue'
import { ClassMetaClientSrv, ObjectClientSrv } from '@kmsoft/ebf-common'

/** KChangeActivityUpdateCompareGrid */
export default class KChangeActivityUpdateCompareGridViewModel extends BaseViewModel<
  KChangeActivityUpdateCompareGridEmitsType,
  KChangeActivityUpdateCompareGridPropType
> {
  refDataGrid = ref<KDataGridViewModel>()
  constructor(options: ViewModelOptions<KChangeActivityUpdateCompareGridPropType>) {
    super(options)
  }

  viewDidMount() {}
  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: Record<string, any>) {
    const param = {
      id: row.id!,
      modelCode: row.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row.className)
    }
    ObjectClientSrv.openObj(param)
  }
}

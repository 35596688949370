import { GridCellEditorParams, GridCellRendererParams, KObjectClassGridBaseColumn } from '../../base'
import KBusinessVersionRender from '.'

/** 对象显示器 */
export class KDataGridBusinessVersionColumn extends KObjectClassGridBaseColumn {
  name = 'KDataGridBusinessVersionColumn'
  getCellRenderer(params: GridCellRendererParams) {
    const col = (params.cellParams as any).col
    const row = (params.cellParams as any).row
    let value = '--'
    //版本列统一显示
    if (row['version'] && row['iteration']) {
      value = row['version'] + '.' + row['iteration']
    }
    const newParam = { ...col, ...params, value: value }

    return <KBusinessVersionRender {...newParam} />
  }

  getCellEditor(params: GridCellEditorParams) {
    const col = (params.cellParams as any).col
    const row = (params.cellParams as any).row
    let value = ''
    //版本列统一显示
    if (row['version'] && row['iteration']) {
      value = row['version'] + '.' + row['iteration']
    }
    const newParam = { ...col, ...params, value: value }

    return <KBusinessVersionRender {...newParam} />
  }
}

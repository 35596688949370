import {
  KDataGridViewModel,
  VueNode,
  KDialog,
  IKTreeNode,
  KTreeViewViewModel,
  TreeViewMenuEventArgs,
  ToolStripItemClickedEventArgs
} from '@kmsoft/upf-core'
import { Ref } from 'vue'
import { FnFormAction, messages } from '..'
import { ObjectClientSrv } from '../../client-srv'
import { MetaProperty } from '../meta'
import { KObjectClassGridFindDialog, ObjectClassGridFindForm, SearchFilterTreeLeaf } from '../../controls'

export class ComponentClientSrv {
  /** 查找窗口 */
  static openGridFindDialog(
    refFilterGridRender: Ref<KDataGridViewModel | undefined>,
    fields: Ref<Array<MetaProperty>>
  ): KObjectClassGridFindDialog {
    /** 初始化开始索引位置 */
    const init = () => {
      isStartFind = false
      localFindCount = 0
      startFindIndex = refFilterGridRender.value?.getSelectedIndex() ?? 0
      localFindIndex = startFindIndex
    }

    /** 获取行主键列名 */
    const rowKey = refFilterGridRender.value?.getRowKey()!
    /** 本地查询条件 */
    let localFindCondition: SearchFilterTreeLeaf | undefined = undefined
    /** 是否开始查找 */
    let isStartFind = false
    /** 本地查询个数 */
    let localFindCount: number = 0
    /** 开始查找索引 */
    let startFindIndex: number
    /** 本地查询索引 */
    let localFindIndex: number
    /** 上次查询方向 */
    let lastDirection: string | undefined
    init()

    /** 查找下一个 */
    const getNextFindIndex = (direction: 'prev' | 'next', totalRowCount: number): void => {
      if (direction === 'prev') {
        if (localFindIndex == 0) {
          localFindIndex = totalRowCount - 1
        } else {
          localFindIndex--
        }
      } else {
        if (localFindIndex >= totalRowCount - 1) {
          localFindIndex = 0
        } else {
          localFindIndex++
        }
      }
    }

    /**
     * 查找
     * @returns 返回是否查找完毕
     */
    const localFind = (direction: 'prev' | 'next'): boolean => {
      if (!refFilterGridRender.value) return true
      /** 获取所有行 */
      const rows = refFilterGridRender.value.getRows()
      if (!rows || rows.length == 0) return true

      if (lastDirection != direction) {
        localFindCount = 0
        startFindIndex = refFilterGridRender.value.getSelectedIndex()!
      }

      while (true) {
        getNextFindIndex(direction, rows.length)
        //if (localFindIndex == startFindIndex) return true

        isStartFind = true
        try {
          const row = rows[localFindIndex]
          if (localFindCondition?.test(row)) {
            localFindCount += 1
            const key = row[rowKey]
            refFilterGridRender.value?.clearSelectedRows()
            refFilterGridRender.value?.setSelectedRow(key, true)
            refFilterGridRender.value?.setTreeExpand(key, true)
            refFilterGridRender.value?.scrollToRow(key)
            if (localFindIndex == startFindIndex) return true
            return false
          }

          if (localFindIndex == startFindIndex) return true
        } finally {
          lastDirection = direction
        }
      }
    }
    // 查找回调
    const onFind = (direction: 'prev' | 'next'): FnFormAction<ObjectClassGridFindForm> => {
      return (form, update) => {
        const { condition } = form
        if (update) {
          localFindCondition = condition
          init()
        }
        const findFinished = localFind(direction)
        if (findFinished) {
          if (localFindCount == 0) {
            KDialog.info({
              content: messages.MSG_NO_SEARCH_RESULT
            })
          } else {
            if (direction === 'prev') {
              KDialog.info({
                content: messages.MSG_SEARCH_REACH_FIRST
              })
            } else {
              KDialog.info({
                content: '查询完毕，共找到[' + localFindCount + ']条记录。'
              })
            }
          }
          // 查询到最后一条结果，清空选中行数据
          // refFilterGridRender.value?.clearSelectedRows()
          init()
        }
      }
    }

    const dialogResult = ObjectClientSrv.openObjectClassGridFindDialog({
      fields: fields,
      title: '查找',
      onFindNext: onFind('next'),
      onFindPrev: onFind('prev'),
      getContainer: () => refFilterGridRender.value?.getContainer() as VueNode
    })

    // 返回数据代理
    return Object.freeze({
      close: () => dialogResult.destroy(),
      get refComponent() {
        return dialogResult.getComponent()
      }
    })
  }

  /**
   * 获取操作的节点列表
   * @param event
   * @param refTreeView
   * @returns
   */
  static getOperationTreeNodes<T extends IKTreeNode>(
    refTreeView: KTreeViewViewModel | undefined,
    event?: TreeViewMenuEventArgs | ToolStripItemClickedEventArgs
  ): { isMultiSelect: boolean; selectedNodes: Array<T> } {
    if (!refTreeView) {
      return { isMultiSelect: false, selectedNodes: [] }
    }

    /** 选择的节点 */
    const checkedNodes = refTreeView.getCheckedNodes() as Array<T>
    /** 是否是多选 */
    let isMultiSelect = false
    /** 选择的节点 */
    let selectedNode: IKTreeNode
    /** 最终选择的节点 */
    let selectedNodes: Array<IKTreeNode> = []

    // 如果没有传入节点参数，为快捷键事件
    if (event?.attachParams && event.attachParams.node) {
      selectedNode = refTreeView?.getNode(event.attachParams?.node.key)!
    } else {
      selectedNode = refTreeView?.getSelectedNode()!
    }

    // 如果选择了多个节点
    if (checkedNodes.length > 0) {
      selectedNodes = [...checkedNodes]
      isMultiSelect = true
    }

    // 如果当前操作的节点不在多选节点中
    if (!checkedNodes?.find(a => a.key == selectedNode.key)) {
      selectedNodes = [selectedNode]
      isMultiSelect = false
    }

    return {
      isMultiSelect: isMultiSelect,
      selectedNodes: selectedNodes as Array<T>
    }
  }
}

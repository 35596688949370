import { connect, withInstall } from '@kmsoft/upf-core'
import ObjectPanelTestView from './ObjectPanelTest.vue'
import ObjectPanelTestViewModel from './ObjectPanelTestViewModel'

const ObjectPanelTest = connect(ObjectPanelTestView, ObjectPanelTestViewModel)

export default withInstall(ObjectPanelTest)
export { ObjectPanelTest, ObjectPanelTestView, ObjectPanelTestViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34ba0b95"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"100%","width":"100%","padding":"10px 0px","box-sizing":"border-box"} }
const _hoisted_2 = { style: {"height":"35%"} }
const _hoisted_3 = { style: {"width":"100%","display":"flex"} }
const _hoisted_4 = { style: {"height":"65%"} }
const _hoisted_5 = ["src"]
const _hoisted_6 = { style: {"display":"flex","width":"100%","height":"100%"} }
const _hoisted_7 = { style: {"width":"45%"} }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { style: {"width":"10%","height":"100%","display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_10 = { style: {"width":"45%","height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_col = _resolveComponent("k-col")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_row = _resolveComponent("k-row")!
  const _component_k_form = _resolveComponent("k-form")!
  const _component_k_tab_pane = _resolveComponent("k-tab-pane")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_tabs = _resolveComponent("k-tabs")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_k_form, {
            ref: _ctx.$vm.refForm,
            model: _ctx.$vm.formData,
            rules: _ctx.rules,
            "label-col": { span: 4 },
            "wrapper-col": { span: 18 },
            class: "form-style"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_row, { span: "24" }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_col, { span: "24" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_k_form_item, {
                        label: "流程模板",
                        name: "templateId"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_k_input, {
                              class: "input-box",
                              value: _ctx.$vm.workflowTemplateName,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.workflowTemplateName) = $event)),
                              disabled: true
                            }, null, 8, ["value"]),
                            _createVNode(_component_k_button, {
                              class: "k-reference-editor-selector",
                              onClick: _ctx.$vm.openWorkflowTemplateSelector
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_k_icon, { type: "select-arrow" })
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_k_col, { span: "24" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_k_form_item, {
                        label: "编码",
                        name: "code"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_k_input, {
                            class: "input-box",
                            value: _ctx.$vm.formData!.code,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formData!.code) = $event)),
                            disabled: true
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_k_col, { span: "24" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_k_form_item, {
                        label: "名称",
                        name: "name"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_k_input, {
                            class: "input-box",
                            value: _ctx.$vm.formData!.name,
                            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.formData!.name) = $event))
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_k_col, { span: "24" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_k_form_item, {
                        label: "描述",
                        name: "describe"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_k_textarea, {
                            value: _ctx.$vm.formData!.describe,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.formData!.describe) = $event)),
                            maxlength: 170,
                            rows: 3
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createVNode(_component_k_tabs, {
            activeKey: _ctx.$vm.activeKey,
            "onUpdate:activeKey": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$vm.activeKey) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_tab_pane, {
                key: "1",
                tab: "流程图"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("iframe", {
                    src: _ctx.$vm.imageSrc,
                    style: {"width":"100%","height":"100%","border":"none","display":"block"}
                  }, null, 8, _hoisted_5)
                ]),
                _: 1
              }),
              _createVNode(_component_k_tab_pane, {
                key: "2",
                tab: "执行人"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_k_data_grid, {
                        ref: _ctx.$vm.refExecutorDataGrid,
                        rowKey: "id",
                        isMultipleSelection: false,
                        selectFirstRow: true,
                        loadData: _ctx.$vm.loadDataExecutor,
                        rowModelType: _ctx.rowModelType,
                        onRowClick: _cache[4] || (_cache[4] = (event)=>_ctx.$vm.refreshUserGrid(event))
                      }, {
                        columns: _withCtx(() => [
                          _createVNode(_component_k_data_grid_text_box_column, {
                            id: "name",
                            name: "name",
                            dataPropertyName: "name",
                            headerText: "流程活动",
                            rowSpanMode: _ctx.rowSpanMode,
                            align: "center",
                            width: "180"
                          }, null, 8, ["rowSpanMode"]),
                          _createVNode(_component_k_data_grid_template_column, {
                            id: "executorName",
                            name: "executorName",
                            dataPropertyName: "executorName",
                            headerText: "执行人名称",
                            align: "center",
                            width: "189"
                          }, {
                            default: _withCtx(({ cellValue}) => [
                              _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_k_data_grid_template_column, {
                            id: "action",
                            name: "action",
                            fixed: "right",
                            align: "left",
                            headerText: "操作",
                            width: "80"
                          }, {
                            default: _withCtx(({row}) => [
                              _createElementVNode("a", {
                                class: "row-operation-item",
                                onClick: (e) => _ctx.$vm.delete(row.id)
                              }, "移除", 8, _hoisted_8)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["loadData", "rowModelType"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_k_button, {
                        type: "primary",
                        onClick: _ctx.$vm.addExecutor
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_k_icon, { type: "backward" })
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_k_data_grid, {
                        ref: _ctx.$vm.refUserDataGrid,
                        rowKey: "id",
                        isMultipleSelection: true,
                        loadData: _ctx.$vm.loadDataUser,
                        rowModelType: _ctx.rowModelType
                      }, {
                        columns: _withCtx(() => [
                          _createVNode(_component_k_data_grid_template_column, {
                            id: "name",
                            name: "name",
                            dataPropertyName: "name",
                            headerText: "姓名",
                            align: "center",
                            width: "190"
                          }, {
                            default: _withCtx(({ cellValue}) => [
                              _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_k_data_grid_text_box_column, {
                            id: "email",
                            name: "email",
                            dataPropertyName: "email",
                            headerText: "邮箱",
                            align: "center",
                            width: "211"
                          })
                        ]),
                        _: 1
                      }, 8, ["loadData", "rowModelType"])
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["activeKey"]), [
            [_vShow, _ctx.$vm.formData.templateId != '']
          ])
        ])
      ])
    ]),
    _: 1
  }))
}

import { defineView } from '@kmsoft/upf-core'
import { KChangeOrderRelationshipPropOptions, KChangeOrderRelationshipEventEmits } from './interface'
import KChangeOrderRelationshipViewModel from './KChangeOrderRelationshipViewModel'
const data = [
  {
    id: '#change-request',
    title: '变更单-变更请求',
    key: '1'
  },
  {
    id: '#change-activity',
    title: '变更单-变更活动',
    key: '2'
  }
] as any

export default defineView({
  name: 'KChangeOrderRelationship',
  props: KChangeOrderRelationshipPropOptions,
  emits: KChangeOrderRelationshipEventEmits,
  viewModel: KChangeOrderRelationshipViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      data
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import KFolderSelectorViewModel from './KFolderSelectorViewModel'
import { KFolderSelectorEventEmits, KFolderSelectorPropOptions } from './interface'

export default defineView({
  name: 'KFolderSelector',
  props: KFolderSelectorPropOptions,
  emits: KFolderSelectorEventEmits,
  viewModel: KFolderSelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})


import { defineView } from '@kmsoft/upf-core'
import { ObjectGridTestPropOptions, ObjectGridTestEventEmits } from './interface'
import ObjectGridTestViewModel from './ObjectGridTestViewModel'

export default defineView({
  name: 'ObjectGridTest',
  props: ObjectGridTestPropOptions,
  emits: ObjectGridTestEventEmits,
  viewModel: ObjectGridTestViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KPromoteActivityBeforeGridView from './KPromoteActivityBeforeGrid.vue'
import KPromoteActivityBeforeGridViewModel from './KPromoteActivityBeforeGridViewModel'

const KPromoteActivityBeforeGrid = connect(KPromoteActivityBeforeGridView, KPromoteActivityBeforeGridViewModel)

export default withInstall(KPromoteActivityBeforeGrid)
export { KPromoteActivityBeforeGrid, KPromoteActivityBeforeGridView, KPromoteActivityBeforeGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

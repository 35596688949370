import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KRuleEditorView from './KRuleEditor'
import KRuleEditorViewModel from './KRuleEditorViewModel'

const KRuleEditor = connect(KRuleEditorView, KRuleEditorViewModel)

export default withInstall(KRuleEditor)
export { KRuleEditor, KRuleEditorView, KRuleEditorViewModel }
export * from './interface'

import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../object-tool-strip'
import { EnumToolStripItemKeys } from '../types'
import { EnumClassTemplate } from '../../../client-srv'

/** 刷新 */
export const toolStripItemRefresh: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
  title: '刷新',
  icon: 'sync',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'f5'
}

export const toolStripItemOpen: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_OPEN_OBJ,
  title: '打开',
  icon: 'select',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+enter',
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Browse
}

export const toolStripItemViewMainObj: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_MAIN_OBJ,
  title: '查看主对象...',
  icon: 'eye',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // propertiesPredicate: (params: ToolStripFilterParams) => {
  //   if (!params.getObjectPurviewData) {
  //     return {}
  //   }

  //   /** 查询权限数据 */
  //   const purviewData = params.selectedDatas.map(a => params.getObjectPurviewData!(a))

  //   return {
  //     visible: purviewData.every(
  //       a =>
  //         a.flowStatus &&
  //         (a.flowStatus.descType == DescObjRelationType.Normal || a.flowStatus.descType == DescObjRelationType.Subordinate)
  //     )
  //   }
  // }
}

export const toolStripItemMainDescRel: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_MAIN_DESC_REL,
  title: '主描述关系...',
  icon: 'OpenObj',
  visible: true,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ],
  items: [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_OPEN_MAIN_DESC_OBJ,
      title: '打开',
      icon: 'OpenObj',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_MAIN_DESC_DOC,
      title: '浏览',
      icon: 'DocBrowse',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single
    }
  ]
}

export const toolStripItemUnFreeze: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_FREEZE,
  title: '解冻',
  icon: 'UnFreezenObj',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,

  enabledObjNumMode: EnumSupportObjectNumMode.Single_Multiple
}

export const toolStripItemSort: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT,
  title: '排序',
  icon: 'sort-descending',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+s'
}

export const toolStripItemNew: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
  title: '新建...',
  icon: 'plus',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemAdd: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
  title: '添加',
  icon: 'plus-circle',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+a'
}
export const toolStripItemShare: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SHARE,
  title: '共享',
  icon: 'share-alt',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}
export const toolStripItemCollection: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_COLLECTION,
  title: '收藏',
  icon: 'big-class-model',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemRemoveShare: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE_SHARE,
  title: '移除共享',
  icon: 'delete',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemRemoveReceivedDataShare: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE_RECEIVED_SHARE,
  title: '移除收到的共享',
  icon: 'delete',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemCancelCollection: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CANCEL_COLLECTION,
  title: '取消收藏',
  icon: 'clear',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemInsert: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_INSERT,
  title: '插入',
  icon: 'plus-circle',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+a'
}

export const toolStripItemDelete: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
  title: '删除',
  icon: 'DeleteObj',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'alt+r'
  // supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
  // unSupportedEnvironment: [EnumToolStripEnvironment.PartStructureManager]
}

export const toolStripItemRemove: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
  title: '移除',
  icon: 'close-circle',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+d',
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
}

export const toolStripItemEdit: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT,
  title: '编辑',
  icon: 'edit',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+e'
}

export const toolStripItemStartEdit: ObjectToolStripItem = {
  ...toolStripItemEdit,
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY,
  title: '编辑属性',
  compType: EnumToolStripCompType.CHECKBOX,
  type: 'button',
  checkedTitle: '完成编辑',
  enabledObjNumMode: EnumSupportObjectNumMode.Single_Multiple
}

export const toolStripItemSave: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE,
  icon: 'save',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+s',
  title: '保存'
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Edit
}

export const toolStripItemExportExcel: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EXPORT_EXCEL,
  title: '导出Excel',
  icon: 'table',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemReport: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REPORT,
  title: '报表',
  icon: 'table',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemCopy: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_COPY,
  title: '复制',
  icon: 'copy',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+c',
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
  // unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER],
  // unSupportedEnvironment: [EnumToolStripEnvironment.PartStructureManager]
}

export const toolStripItemCut: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CUT,
  title: '剪切',
  icon: 'scissor',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+x',
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
  // unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
}

export const toolStripItemPaste: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_PASTE,
  title: '粘贴',
  icon: 'paste',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+v'
  //zhouw 修改 2023.08.03，对象类网格默认是不支持粘贴操作的，对于如关联对象列表/描述对象列表，需要手动调用api来显示粘贴按钮
  // unSupportedEnvironment: [EnumToolStripEnvironment.PartStructureManager, EnumToolStripEnvironment.ObjClassGrid]
}

export const toolStripReName: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_RENAME,
  title: '重命名',
  icon: 'edit',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}


import { EBF_IOC_KEY, webSocketClientSrv } from '@kmsoft/ebf-common'
import { ComponentHelper, defineView, eventEmitter } from '@kmsoft/upf-core'
import { TransitionProps, computed, ref, watch } from 'vue'
import { EnumWebSocketStatus } from './enum'
import { KAgentMonitorEventEmits, KAgentMonitorPropOptions } from './interface'
import KAgentMonitorViewModel from './KAgentMonitorViewModel'

export default defineView({
  name: 'KAgentMonitor',
  props: KAgentMonitorPropOptions,
  emits: KAgentMonitorEventEmits,
  viewModel: KAgentMonitorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    //#region  私有方法
    let checkInterval = ref(10)
    let webSocketId = ref('')
    //最小重试时间
    let __checkAgentMinInterval = 5
    //最大重连次数
    let __connectWebSocketRetryTimes = 3
    let count = 0
    let setIntervalId: string | number | NodeJS.Timeout | null | undefined = null
    /** 状态 */
    const status = ref<EnumWebSocketStatus>(EnumWebSocketStatus.Disconnected)
    /**记录状态 */
    const originalStatus = ref<EnumWebSocketStatus>(EnumWebSocketStatus.Disconnected)
    /** 是否显示设置 */
    const showSettings = ref(false)
    /** 过渡动画参数 */
    const transitionProps: TransitionProps = {
      appear: false,
      css: false,
      ...(ComponentHelper.scaleYMotion('k-transition-motion-scale-y', false) as any)
    }

    //#region  事件委托方法
    const connectAgent = async (): Promise<void> => {
      if (status.value != EnumWebSocketStatus.Disconnected) return

      status.value = EnumWebSocketStatus.Connecting
      const result = (await webSocketClientSrv.connect(3)) ? EnumWebSocketStatus.Connected : EnumWebSocketStatus.Disconnected
      status.value = result
      originalStatus.value = result
      //若重连次数达到上限,则重新打开定时器
      if (count == __connectWebSocketRetryTimes) {
        setIntervalId = setInterval(updateAgentStatus, Number(checkInterval.value) * 1000)
        //重置重连次数
        count = 0
      }
      //执行全局事件
      eventEmitter.emit(EBF_IOC_KEY.AGENT_LINK, 'updateAgentStatus', status.value as any)
    }
    // 更新代理状态函数
    const updateAgentStatus = async (): Promise<void> => {
      const isConnected = await webSocketClientSrv.isConnected()
      status.value = isConnected ? EnumWebSocketStatus.Connected : EnumWebSocketStatus.Disconnected

      if (!isConnected) {
        count += 1
      } else {
        count = 0
      }

      eventEmitter.emit(EBF_IOC_KEY.AGENT_LINK, 'updateAgentStatus', status.value as any)

      // 如果达到最大重连次数，清除定时器
      if (count == __connectWebSocketRetryTimes) {
        clearInterval(setIntervalId!)
      }
    }

    /** 显示设置 */
    const onShowSettings = () => {
      showSettings.value = !showSettings.value
    }
    /** 连接状态 */
    const agentStatus = computed(() => {
      switch (status.value) {
        case EnumWebSocketStatus.Connected:
          return '连接正常'
        case EnumWebSocketStatus.Connecting:
          return '连接中'
        case EnumWebSocketStatus.Disconnected:
          return '未连接'
        default:
          return '未连接'
      }
    })
    const linkStatus = computed(() => {
      switch (status.value) {
        case EnumWebSocketStatus.Connected:
          return 'success'
        case EnumWebSocketStatus.Connecting:
          return 'warning'
        case EnumWebSocketStatus.Disconnected:
          return 'error'
        default:
          return 'error'
      }
    })

    //#region 属性监听逻辑
    watch(checkInterval, newVal => {
      if (newVal < __checkAgentMinInterval) {
        checkInterval.value = __checkAgentMinInterval
      }
      clearInterval(setIntervalId!)
      setIntervalId = setInterval(updateAgentStatus, Number(checkInterval.value) * 1000)
    })
    //开启定时器
    setIntervalId = setInterval(updateAgentStatus, Number(checkInterval.value) * 1000)
    return {
      agentStatus,
      linkStatus,
      status,
      showSettings,
      EnumWebSocketStatus,
      checkInterval,
      transitionProps,
      webSocketId,
      connectAgent,
      onShowSettings
    }
  }
})

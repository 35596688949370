import {
  KObjectPanelTabEventEmits,
  KObjectPanelTabPropOptions,
  ObjBusinessParam,
  ObjectPanelRetrieveEvent,
  ObjectPanelTabChangeEvent
} from '@kmsoft/ebf-common'
import {
  TreeViewCheckedEventArgs,
  TreeViewSelectEventArgs,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumPartNodeChildExpandMode } from '../../client-srv'
import { KStructuredDocTreeViewModel } from './structured-doc-tree'
import { StructuredDocTreeNode } from './structured-doc-tree/node/StructuredDocTreeNode'

// export type StructureViewOption = {
//   /** 标签 */
//   label: string
//   /** 值 */
//   value: string
// }

// export type StructureFilterOption = {
//   /** 标签 */
//   label: string
//   /** 值 */
//   value: string
// }
/** 参数 **/
export const KStructuredDocManagePropOptions = {
  ...KObjectPanelTabPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  /** 是否停用对象面板 */
  suppressObjectPanel: VuePropTypes.bool().def(false),
  /** 是否默认显示对象面板 */
  showObjectPanel: VuePropTypes.bool().def(true),
  /** 是否支持多选 */
  isMultipleSelection: VuePropTypes.bool().def(false),
  /** 选中严格受控，父子节点选中状态不再关联 */
  checkStrictly: VuePropTypes.bool().def(false),
  /** 节点展开模式 */
  nodeChildExpandMode: VuePropTypes.createType<EnumPartNodeChildExpandMode>().def(),
  /** 停用过滤视图 */
  suppressView: VuePropTypes.bool().def(false),
  /** 禁用视图 */
  disabledView: VuePropTypes.bool().def(false),
  /** 禁用过滤 */
  disabledFilter: VuePropTypes.bool().def(false),
  /** 是否禁用右键菜单 */
  disabledContextMenu: VuePropTypes.bool().def(false),
  /** 禁止的标签页 */
  suppressedTabs: VuePropTypes.array<string>().def([]),
  /** 默认视图 */
  viewName: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KStructuredDocManagePropType = ViewPropsTypeExtract<typeof KStructuredDocManagePropOptions>

/** 事件 */
export const KStructuredDocManageEventEmits = {
  ...KObjectPanelTabEventEmits,
  /** 切换视图事件 */
  'update:viewName': (value: string) => true,
  /** 版本规则 */
  'update:nodeChildExpandMode': (value: EnumPartNodeChildExpandMode) => true,
  /** 节点选中后事件 */
  afterTreeCheck: (event: TreeViewCheckedEventArgs) => true,
  /** 初始化完成后提交此事件，传递显示的文档树，用于要对树进行初始化配置的场景 */
  treeReady: (tree: KStructuredDocTreeViewModel) => true,
  /** 对象面板标签页更改事件 */
  objectPanelTabChange: (event: ObjectPanelTabChangeEvent) => true,
  /** 对象面板自定义指令 */
  objectPanelRetrieve: (event: ObjectPanelRetrieveEvent<any>) => true,
  /** 树选择后事件 */
  afterTreeSelect: (event: TreeViewSelectEventArgs<StructuredDocTreeNode>) => true
}

/** 事件类型 **/
export type KStructuredDocManageEmitsType = ViewEmitsTypeExtract<typeof KStructuredDocManageEventEmits>

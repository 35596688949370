import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KDocObjectPropertyEditPanelView from './KDocObjectPropertyEditPanel'
import KDocObjectPropertyEditPanelViewModel from './KDocObjectPropertyEditPanelViewModel'

const KDocObjectPropertyEditPanel = connect(KDocObjectPropertyEditPanelView, KDocObjectPropertyEditPanelViewModel)

export default withInstall(KDocObjectPropertyEditPanel)
export { KDocObjectPropertyEditPanel, KDocObjectPropertyEditPanelView, KDocObjectPropertyEditPanelViewModel }
export * from './interface'

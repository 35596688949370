import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  IKTreeNode,
  KSchema,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** KDesignerHierarchy 状态 **/
export interface IKDesignerHierarchyState {}

/** KDesignerHierarchy 事件 **/
export const KDesignerHierarchyEventEmits = {
  ...BaseViewEventEmits,
  treeNodeSelected: (node: IKTreeNode) => true
}

/** KDesignerHierarchy 参数 **/
export const KDesignerHierarchyPropOptions = {
  ...BaseViewPropOptions,
  /** 布局方案 */
  schema: VuePropTypes.createType<KSchema>().def(),
  /** 选中的层级树节点 */
  selectedHierarchyTreeNodeKey: VuePropTypes.createType<string>().def()
}

/** KDesignerHierarchy 参数类型 **/
export type KDesignerHierarchyPropType = ViewPropsTypeExtract<typeof KDesignerHierarchyPropOptions>

/** KDesignerHierarchy事件协议 */
export type KDesignerHierarchyEventEmitsType = ViewEmitsTypeExtract<typeof KDesignerHierarchyEventEmits>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c1e80f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { class: "full part-validation-metrics-grid" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"margin-left":"5px"} }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_data_grid_check_box_column = _resolveComponent("k-data-grid-check-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_object_tool_strip, {
        ref: _ctx.$vm.refMetricsToolStrip,
        items: _ctx.$vm.metricsToolStripItems,
        onItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onMetricsToolStripItemClicked(event))
      }, null, 8, ["items"]),
      _createVNode(_component_k_data_grid, {
        class: "validation-metrics-grid",
        style: {"height":"270px"},
        rowKey: "id",
        name: "refValidationMetricsGrid",
        ref: _ctx.$vm.refValidationMetricsGrid,
        pagination: false,
        rowModelType: _ctx.rowModelType,
        loadData: _ctx.$vm.loadMetricsData
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_template_column, {
            id: "number",
            name: "number",
            dataPropertyName: "validationMetrics",
            headerText: "编码",
            align: "left"
          }, {
            default: _withCtx(({ cellValue,row }) => [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                onClick: () => _ctx.$vm.openMetricsTab(row)
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(cellValue.number), 1)
              ], 8, _hoisted_3)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "title",
            name: "title",
            dataPropertyName: "validationMetrics.title",
            headerText: "标题",
            readonly: false,
            align: "left"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "rdmExtensionType",
            name: "rdmExtensionType",
            dataPropertyName: "validationMetrics",
            headerText: "实体类型",
            align: "left"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue.rdmExtensionType)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "type",
            name: "type",
            dataPropertyName: "validationMetrics.type",
            headerText: "指标类型",
            readonly: false,
            options: _ctx.$vm.validationMetricsTypeOptions,
            align: "left"
          }, null, 8, ["options"]),
          _createVNode(_component_k_data_grid_check_box_column, {
            id: "isGeneral",
            name: "isGeneral",
            dataPropertyName: "validationMetrics.isGeneral",
            headerText: "通用指标",
            align: "left"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "executor",
            name: "executor",
            dataPropertyName: "validationMetrics.executor",
            headerText: "执行者",
            readonly: false,
            align: "left"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "description",
            name: "description",
            dataPropertyName: "validationMetrics.description",
            headerText: "描述",
            readonly: false,
            align: "left"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "creator",
            name: "creator",
            dataPropertyName: "validationMetrics",
            headerText: "指标创建者",
            align: "left"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.creator.split(' ')[0]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "createTime",
            name: "createTime",
            dataPropertyName: "validationMetrics",
            headerText: "指标创建时间",
            align: "left"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.createTime), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "action",
            name: "action",
            fixed: "right",
            align: "left",
            headerText: "操作",
            width: "auto"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("a", {
                class: "row-operation-item",
                onClick: (e) => _ctx.$vm.openMetricsTab(row)
              }, "查看", 8, _hoisted_5)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["rowModelType", "loadData"])
    ])
  ]))
}
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  SimpleViewPropOptions,
  SimpleViewEventEmits
} from '@kmsoft/upf-core'
import { KBasePropertyEditorPropOptions } from '@kmsoft/ebf-common'

/** 参数 **/
export const KChangeOrderAffectedObjectGridPropOptions = {
  ...SimpleViewPropOptions,
  ...KBasePropertyEditorPropOptions,
  /** 是否是设计模式 */
  isDesigner: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KChangeOrderAffectedObjectGridPropType = ViewPropsTypeExtract<typeof KChangeOrderAffectedObjectGridPropOptions>

/** 事件 */
export const KChangeOrderAffectedObjectGridEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KChangeOrderAffectedObjectGridEmitsType = ViewEmitsTypeExtract<typeof KChangeOrderAffectedObjectGridEventEmits>

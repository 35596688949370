import { BaseViewModel, KDataGridViewModel } from '@kmsoft/upf-core'
import { IModuleDefinition, KModuleListEventEmitsType, KModuleListViewModelPropType } from './interface'
import { isReqSuccess, showError } from '../../common/common'
import { ref } from 'vue'
import { Api } from '@kmsoft/ebf-common'

export default class KModuleListViewModel extends BaseViewModel<KModuleListEventEmitsType, KModuleListViewModelPropType> {
  refObjClsGrid = ref<KDataGridViewModel>()

  private selectedModuleCodeList: Array<string>

  /**
   * 加载完成函数
   */
  viewDidMount() {
    this.selectedModuleCodeList = this.props.selectedModuleCodeList
    this.loadData()
  }

  /**
   * 加载数据
   */
  private loadData(): void {
    const param = {
      data: []
    }
    Api.post('sys', 'ConfigSysService', 'listModules', param).then((response: any) => {
      if (!isReqSuccess(response)) {
        showError(response)
        return
      }
      const dList = response.data as Array<IModuleDefinition>
      const datasource: Array<IModuleDefinition> = []
      dList?.forEach((item: any) => {
        if (!this.selectedModuleCodeList.includes(item.code)) {
          datasource.push({
            id: item.code,
            name: item.name
          })
        }
      })
      this.refObjClsGrid.value!.setDataSource(datasource)
    })
  }

  public getSelectedModuleList(): Array<IModuleDefinition> {
    return this.refObjClsGrid.value!.getSelectedRows()
  }
}

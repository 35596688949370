
import { defineView } from '@kmsoft/upf-core'
import { KFileUploaderProgressPropOptions, KFileUploaderProgressEventEmits } from './interface'
import KFileUploaderProgressViewModel from './KFileUploaderProgressViewModel'
import { EnumFileUploadingStatus, UploadFileInfo } from '../../../client-srv'

export default defineView({
  name: 'KFileUploaderProgress',
  props: KFileUploaderProgressPropOptions,
  emits: KFileUploaderProgressEventEmits,
  viewModel: KFileUploaderProgressViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    //移除
    const handleRemove = (fileInfo: UploadFileInfo) => {
      emit('fileRemove', fileInfo)
    }

    // 下载
    const handleDownloadFile = (fileInfo: UploadFileInfo) => {
      emit('fileDownload', fileInfo)
    }

    return {
      handleRemove,
      handleDownloadFile,
      EnumFileUploadingStatus
    }
  }
})

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { objParam } from '../interface'

/** 参数 **/
export const KWorkflowTemplateSelectorPropOptions = {
  ...BaseViewPropOptions,
  /**对象参数 */
  objParams: VuePropTypes.createType<Array<objParam>>().def([])
}

/** 参数类型 **/
export type KWorkflowTemplateSelectorPropType = ViewPropsTypeExtract<typeof KWorkflowTemplateSelectorPropOptions>

/** 事件 */
export const KWorkflowTemplateSelectorEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KWorkflowTemplateSelectorEmitsType = ViewEmitsTypeExtract<typeof KWorkflowTemplateSelectorEventEmits>

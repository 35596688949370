
import { defineView } from '@kmsoft/upf-core'
import { KDocObjectPreviewPropOptions, KDocObjectPreviewEventEmits } from './interface'
import KDocObjectPreviewViewModel from './KDocObjectPreviewViewModel'

export default defineView({
  name: 'KDocObjectPreview',
  props: KDocObjectPreviewPropOptions,
  emits: KDocObjectPreviewEventEmits,
  viewModel: KDocObjectPreviewViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import './style/style.less'
import { EnumDialogSize, KDialog, connect, withInstall } from '@kmsoft/upf-core'
import KRuleDefinerView from './KRuleDefiner'
import KRuleDefinerViewModel from './KRuleDefinerViewModel'
import { KRuleDefinerDialogParams } from './interface'

/** 类型定义 */
type KFileUploaderType = typeof KRuleDefinerView & { show: typeof show }

/** 规则定义 */
const KRuleDefiner = connect(KRuleDefinerView, KRuleDefinerViewModel) as KFileUploaderType

/**
 * 显示脚本窗口
 * @param props
 * @returns
 */
export const show = (props: KRuleDefinerDialogParams) => {
  return KDialog.show({
    props: props.props,
    title: props.title,
    closable: false,
    size: EnumDialogSize.Large,
    content: KRuleDefiner,
    onClosing: props.onClosing
  })
}

// 挂载函数
KRuleDefiner.show = show

export default withInstall(KRuleDefiner)
export { KRuleDefiner, KRuleDefinerView, KRuleDefinerViewModel }
export * from './interface'
export * from './controls'

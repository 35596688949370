import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ValueChangeEventArgs,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

import { KDataElementTree } from '../../../../../types'
import { KFilterEditorResolver, KFilterOperationResolver, KFilterWithId } from '../interface'

/** KFilterGridFilterItemConfig 事件 */
export const KFilterGridFilterItemConfigEventEmits = {
  ...BaseViewEventEmits
}

/** KFilterGridFilterItemConfig 事件类型 **/
export type KFilterGridFilterItemConfigEventEmitsType = ViewEmitsTypeExtract<typeof KFilterGridFilterItemConfigEventEmits>

/** KFilterGridFilterItemConfig 参数 **/
export const KFilterGridFilterItemConfigPropOptions = {
  ...BaseViewPropOptions,
  /** 筛选条件 */
  filters: VuePropTypes.array<KFilterWithId>().def([]),
  /**操作符自定义 */
  operationResolver: VuePropTypes.func<KFilterOperationResolver>().def(),
  /**编辑方式工厂 */
  filterEditorResolver: VuePropTypes.func<KFilterEditorResolver>().def(),
  /**数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def({} as KDataElementTree)
}

/** KFilterGridFilterItemConfig 参数类型 **/
export type KFilterGridFilterItemConfigPropType = ViewPropsTypeExtract<typeof KFilterGridFilterItemConfigPropOptions>

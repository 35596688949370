
import { defineView, KDialog, KDialogClosingEvent, ValueChangeEventArgs } from '@kmsoft/upf-core'
import _ from 'lodash'
import KFilterGridFilterItemConfig from './config-panel'
import { KFilterGridFilterItemConfigViewModel } from './config-panel'
import { KFilterGridFilterItemDesignerEventEmits, KFilterGridFilterItemDesignerPropOptions } from './interface'
import { KFilterGridFilterItemDesignerViewModel } from './KFilterGridFilterItemDesignerViewModel'

/**
 * 筛选网格筛选项定义器
 */
export default defineView({
  name: 'KFilterGridFilterItemDesigner',
  viewModel: KFilterGridFilterItemDesignerViewModel,
  props: KFilterGridFilterItemDesignerPropOptions,
  emits: KFilterGridFilterItemDesignerEventEmits,
  setup(props, { vm }) {
    const openGridDesigner = () => {
      KDialog.show({
        title: '筛选项配置',
        content: KFilterGridFilterItemConfig,
        props: {
          designerMetaControl: props.designerMetaControl,
          dataSource: props.dataSource,
          filters: _.cloneDeep(vm.stateValue)
        },
        size: { width: 1024, height: 608 },
        async onClosing(event: KDialogClosingEvent) {
          if (event.dialogResult === 'Confirm') {
            const viewModel = event.viewModel as KFilterGridFilterItemConfigViewModel
            const value = viewModel.getValue()
            vm.setValue(value)
          }
        }
      })
    }
    return () => {
      return (
        <div>
          <k-button onClick={openGridDesigner} style="width:100%">
            设置参数
          </k-button>
        </div>
      )
    }
  }
})

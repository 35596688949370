import { connect, withInstall } from '@kmsoft/upf-core'
import KIssueReportCreateView from './KIssueReportCreate.vue'
import KIssueReportCreateViewModel from './KIssueReportCreateViewModel'

const KIssueReportCreate = connect(KIssueReportCreateView, KIssueReportCreateViewModel)

export default withInstall(KIssueReportCreate)
export { KIssueReportCreate, KIssueReportCreateView, KIssueReportCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { KDataElementTree, KDesignerControl } from '../../../types'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ValueChangeEventArgs,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KGridDesignerElement } from './types'

/** 事件 **/
export const KGridColumnConfigEventEmits = {
  ...BaseViewEventEmits,
  change: (event: ValueChangeEventArgs) => true
}

/** 参数 **/
export const KGridColumnConfigPropOptions = {
  ...BaseViewPropOptions,
  /**控件元数据 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def([]),
  /**数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def({} as KDataElementTree),
  /** 值 */
  value: VuePropTypes.array<KGridDesignerElement>().def([])
}

/** 参数类型 **/
export type KGridColumnConfigPropType = ViewPropsTypeExtract<typeof KGridColumnConfigPropOptions>
/** 事件协议*/
export type KGridColumnConfigEventEmitsType = ViewEmitsTypeExtract<typeof KGridColumnConfigEventEmits>

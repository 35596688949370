import { AgentStaticObject } from '../../AgentStaticObject'

import { Dictionary_String_Array, Dictionary_String_String } from '../../system/collections/generic/Dictionary'
import { HttpClient } from '../../system/net/http/HttpClient'

/**提供用于将数据发送到由 URI 标识的资源及从这样的资源接收数据的常用方法。*/
export class HttpClientBase extends HttpClient {
  protected static type: string = 'KMSoft.Agent.HttpClientBase'

  /**初始化 {@link HttpClientBase} 类的新实例。*/
  public static async create(): Promise<HttpClientBase> {
    return this.createInstanceAndWrap()
  }

  /**获取或设置发送请求时使用的 Internet 资源的统一资源标识符 (URI) 的基址。*/
  public async BaseAddressString(value: string = AgentStaticObject.None): Promise<string> {
    return this.property('BaseAddressString', value)
  }

  /**添加指定的标头及其值到 HttpHeaders 集合中。
   * @param name  要添加到集合中的标头。
   * @param value 标头的内容。*/
  public async SetHeader(name: string, value: string | undefined): Promise<void> {
    return this.invokeMethod('SetHeader', name, value)
  }

  /**将具有指定 URI 的资源下载到本地文件。
   * @param address 从中下载数据的 URI。
   * @param fileName 要接收数据的本地文件的名称。*/
  public async DownloadFile(address: string, fileName: string) {
    return this.invokeMethod('DownloadFile', address, fileName)
  }

  /**将具有指定 URI 的资源下载到本地文件。(Post)
   * @param address 从中下载数据的 URI。
   * @param param 请求参数
   * @param fileName 要接收数据的本地文件的名称。*/
  public async DownloadFileByPost(address: string, param: string, fileName: string) {
    return this.invokeMethod('DownloadFileByPost', address, param, fileName)
  }

  /**将指定的本地文件和数据上载到具有指定 URI 的资源。
   * @param address 接收文件和数据的资源的 URI。
   * @param fileNames 要发送到资源的文件。
   * @param datas 要发送到资源的数据。
   * @returns 一个字符串，它包含来自资源的响应的正文。*/
  public async UploadMultipartFormData(
    address: string,
    fileNames: Record<string, string>,
    datas: Record<string, string>
  ): Promise<any> {
    return this.invokeMethod(
      'UploadMultipartFormData',
      address,
      Dictionary_String_String.createLocal(fileNames),
      Dictionary_String_String.createLocal(datas)
    )
  }

  /**将指定的本地文件和数据批量上载到具有指定 URI 的资源。
   * @param address 接收文件和数据的资源的 URI。
   * @param fileNames 要发送到资源的文件。
   * @param datas 要发送到资源的数据。
   * @returns 一个字符串，它包含来自资源的响应的正文。*/
  public async BatchUploadMultipartFormData(
    address: string,
    files: Record<string, Array<string>>,
    datas: Record<string, string>
  ): Promise<any> {
    return this.invokeMethod(
      'BatchUploadMultipartFormData',
      address,
      Dictionary_String_Array.createLocal(files),
      Dictionary_String_String.createLocal(datas)
    )
  }
}

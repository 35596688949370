import { DialogResult } from './DialogResult'

/**指定用于在屏幕上显示对话框的基类。*/
export abstract class CommonDialog {
  /**获取创建控件时所需要的创建参数。*/
  protected get CreateParams(): Record<string, any> {
    return {}
  }

  /**在派生类中被重写时，将通用对话框的属性重置为默认值。*/
  public abstract Reset(): void

  /**运行通用对话框。
   * @returns 如果用户在对话框中单击“确定”，则为 {@link DialogResult.OK}；否则为 {@link DialogResult.Cancel}。*/
  public abstract ShowDialog(): Promise<DialogResult>
}

import { IMenuPagePathMapping } from '@kmsoft/upf-core'
import KPageModeling from '../pages/mvvm-test'
import KPartCreateBaseline from '../controls/part-create-baseline'
export default class ModuleMenuExpose implements IMenuPagePathMapping {
  getMenus() {
    return [
      { target: 'mvvm-test', component: KPageModeling },
      { target: 'catetory-test', component: KPageModeling },
      { target: 'part-create-baseline', component: KPartCreateBaseline }
    ]
  }
}

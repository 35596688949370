import * as Vue from 'vue'
import * as UpfCore from '@kmsoft/upf-core'

import { NativeMethods } from '../../../../../kmsoft/base/windows-api-extensions/NativeMethods'
import { FileAttributes } from '../../../../io/FileAttributes'
import { ImageFormat } from '../../../../drawing/imaging/ImageFormat'

/**文件系统树节点。*/
export abstract class KTreeNodeFileSystemInfo extends UpfCore.KTreeNode implements Record<string, any> {
  /**使用指定的路径初始化。
   * @param path 用于初始化的路径。*/
  constructor(path: string) {
    super()

    this.key = path
    this.id = path
    this.name = path.replace(/[\\/]?$/, '').match(/[^\\/]*$/)?.[0] ?? ''
  }

  /**指示是否初始化。*/
  protected needInitialize = true

  /**指示是否初始化 {@link name}。*/
  protected needInitializeName = false

  /**指示是否初始化 {@link pathTypeName}。*/
  protected needInitializePathTypeName = false

  /**指示是否初始化 {@link shellIcon}。*/
  protected needInitializeShellIcon = false

  /**初始化。*/
  protected async initialize(): Promise<void> {
    if (this.needInitialize) {
      if (this.needInitializeName) this.name = await this.initializeName()
      if (this.needInitializePathTypeName) this.pathTypeName = await this.initializePathTypeName()
      if (this.needInitializeShellIcon) this.shellIcon = await this.initializeShellIcon()
    }
  }

  /**获取 {@link name} 的初始值。*/
  protected async initializeName(): Promise<string> {
    return NativeMethods.GetFileDisplayName(this.path, true)
  }

  /**获取 {@link pathTypeName} 的初始值。*/
  protected async initializePathTypeName(): Promise<string> {
    return NativeMethods.GetFileTypeName(this.path, true)
  }

  /**获取 {@link pathTypeName} 的初始值。*/
  protected async initializeShellIcon(): Promise<string> {
    let image = await NativeMethods.GetFileTypeBitmapBytes(this.path, true, true, await ImageFormat.Png())
    if (image) image = 'data:image/png;base64,' + image
    return image
  }

  /**路径。*/
  public get path(): string {
    return this.id
  }

  /**路径类型。*/
  public pathType: FileAttributes

  /**路径类型名称。*/
  public pathTypeName: string

  /**外壳图标。*/
  public shellIcon: string

  public getIconFromType(): Vue.VNode | undefined {
    if (this.shellIcon) return Vue.createVNode(UpfCore.KImage, { src: this.shellIcon, preview: false })
  }

  public async populateChildren(populateType: number, filter: string = '*'): Promise<KTreeNodeFileSystemInfo[]> {
    const nodes = await this.generateChildren(populateType, filter)
    for (const node of nodes) await node.initialize()
    return nodes
  }

  /**{@inheritDoc KTreeNodeFileSystemInfo.populateChildren}*/
  protected abstract generateChildren(populateType: number, filter: string): Promise<KTreeNodeFileSystemInfo[]>
}

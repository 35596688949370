import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, EnumToolStripEnvironment, ObjectToolStripItem } from '../object-tool-strip'
import { toolStripItemDelete } from './base'
import { EnumToolStripItemKeys } from './types/enums'
import { EnumClassTemplate } from '../../client-srv'

export const getObjectLifeToolStrip = (): ObjectToolStripItem => {
  /** 版本工具栏 */
  let versionToolStripItems: Array<ObjectToolStripItem> = []
  /** 版本规则 */
  // const isCreateNewVersion = metaSrvConfigSrv.getIsCreateNewVersion()
  // 根据配置显示
  // if (isCreateNewVersion) {
  //   versionToolStripItems = [
  //     {
  //       name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWVERISON,
  //       text: '创建新版本',
  //       icon: 'NewVersion',
  //       visible: true,
  //       compType: EnumToolStripCompType.BUTTON,
  //       title: '',
  //       supportedObjNumMode: EnumSupportObjectNumMode.Single
  //       // unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER],
  //       // purviewType: EnumPurviewType.ObjectOperation,
  //       // purviewId: EnumObjectPurviewType.CreateNewVersion
  //     }
  //   ]
  // } else {
  versionToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWVERISON,
      icon: 'tag',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      title: '创建大版本',
      supportedObjNumMode: EnumSupportObjectNumMode.Single,
      unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
      // purviewType: EnumPurviewType.ObjectOperation,
      // purviewId: EnumObjectPurviewType.CreateNewVersion
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWVERISON_OF_MINOR,
      icon: 'tag',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      title: '创建小版本',
      supportedObjNumMode: EnumSupportObjectNumMode.Single,
      unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
      // purviewType: EnumPurviewType.ObjectOperation,
      // purviewId: EnumObjectPurviewType.CreateNewVersion
    }
  ]
  // }

  return {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OBJ,
    title: '对象',
    icon: 'DropObjLife',
    visible: true,
    compType: EnumToolStripCompType.CONTEXT_MENU,
    unSupportedObjClsTempletID: [EnumClassTemplate.ORG, EnumClassTemplate.USER],
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWOBJ,
        title: '创建',
        icon: 'NewObj',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+n',
        unSupportedEnvironment: [EnumToolStripEnvironment.PartStructureManager]
        // purviewType: EnumPurviewType.ObjectClass,
        // purviewId: EnumObjectClassPurviewType.Create
      },
      ...versionToolStripItems,
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS,
        title: '另存',
        icon: 'ObjSaveAs',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+s',
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
        // purviewType: EnumPurviewType.ObjectOperation,
        // purviewId: EnumObjectPurviewType.SaveAs
      },
      {
        ...toolStripItemDelete
        //   // purviewType: EnumPurviewType.ObjectOperation,
        //   // purviewId: EnumObjectPurviewType.Delete
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH,
        title: '发布',
        icon: 'Publish',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+p',
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
        unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
        // purviewType: EnumPurviewType.ObjectOperation,
        // purviewId: EnumObjectPurviewType.Publish
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FROZEN,
        title: '冻结',
        icon: 'thunderbolt',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+f',
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
        // purviewType: EnumPurviewType.ObjectOperation,
        // purviewId: EnumObjectPurviewType.Freezen
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_OWNER,
        title: '修改所有者',
        icon: 'solution',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
        unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
        // purviewType: EnumPurviewType.ObjectOperation,
        // purviewId: EnumObjectPurviewType.EditOwnerPurview
      }
    ]
  }
}

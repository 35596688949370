import { EnumQueryConditionJoiner, EnumQueryConditionOperator } from './enums'

/** 操作运算符对照表 */
export const QUERY_CONDITION_OPERATOR_COMPARISONS: Record<EnumQueryConditionOperator, string> = {
  [EnumQueryConditionOperator.EQUAL]: '等于',
  [EnumQueryConditionOperator.LIKE]: '包含',
  [EnumQueryConditionOperator.STARTWITH]: '开始为',
  [EnumQueryConditionOperator.ENDWITH]: '结尾为',
  [EnumQueryConditionOperator.IN]: '介于',
  [EnumQueryConditionOperator.NOT_IN]: '不包含',
  [EnumQueryConditionOperator.LESS]: '小于',
  [EnumQueryConditionOperator.GREATER]: '大于',
  [EnumQueryConditionOperator.LESS_EQUAL]: '小于等于',
  [EnumQueryConditionOperator.GREATER_EQUAL]: '大于等于',
  [EnumQueryConditionOperator.NOT_EQUAL]: '不等于',
  [EnumQueryConditionOperator.IS_NULL]: '为空',
  [EnumQueryConditionOperator.NOT_NULL]: '非空'
}

/** 逻辑运算符对照表 */
export const QUERY_CONDITION_JOINER_COMPARISONS: Record<EnumQueryConditionJoiner, string> = {
  [EnumQueryConditionJoiner.And]: '并且',
  [EnumQueryConditionJoiner.Or]: '或者'
}

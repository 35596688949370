
import { defineView } from '@kmsoft/upf-core'
import { KnavigationStyleTestPropOptions, KnavigationStyleTestEventEmits } from './interface'
import KnavigationStyleTestViewModel from './KnavigationStyleTestViewModel'

export default defineView({
  name: 'KnavigationStyleTest',
  props: KnavigationStyleTestPropOptions,
  emits: KnavigationStyleTestEventEmits,
  viewModel: KnavigationStyleTestViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

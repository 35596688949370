import { BaseViewModel, ViewModelOptions, RenderLayoutElement } from '@kmsoft/upf-core'
import { EnumRenderLayoutType, KRenderLayoutEmitsType, KRenderLayoutPropType } from './interface'
import { computed, ref } from 'vue'
import { getLayoutRowsConfig } from './utils'
import lodash from 'lodash'

/** KRenderLayout */
export default class KRenderLayoutViewModel extends BaseViewModel<KRenderLayoutEmitsType, KRenderLayoutPropType> {
  /** 网格布局区域,方便获取宽度 */
  refPreviewContainer = ref<HTMLElement>()
  /** 行数量 */
  gridRows = ref<number>(2)

  constructor(options: ViewModelOptions<KRenderLayoutPropType>) {
    super(options)
  }

  viewDidMount() {
    this.gridRows.value = this.calcGridRows()
  }

  calcGridRows() {
    if (!this.refPreviewContainer.value) {
      return 2
    }
    // 如果是网格布局
    if (this.props.layoutType != EnumRenderLayoutType.GRID) {
      return 0
    }
    const { rowGap } = this.props.containerConfig!
    /** 容器 */
    const containerElement = this.refPreviewContainer.value
    return getLayoutRowsConfig(containerElement.clientHeight, rowGap).totalRows
  }

  onElementSelected(element: RenderLayoutElement) {
    this.emit('update:selectedElement', element)
  }

  onElementDelete(element: RenderLayoutElement) {
    lodash.remove(this.props.elements, a => a.name == element.name)
    this.emit('delete', element)
  }

  /**布局元素移动到顶部事件 */
  elementToTop(element: RenderLayoutElement) {
    const index = this.props.elements.findIndex(x => x.name === element.name)
    this.props.elements.splice(index, 1)
    this.props.elements.unshift(element)
    this.emit('update:elements', this.props.elements)
  }

  /**布局元素上移事件 */
  elementUp(index: number) {
    const newArr = this.__swapItems(this.props.elements, index, index - 1)
    this.props.elements = newArr
    this.emit('update:elements', this.props.elements)
  }

  /**布局元素下移事件 */
  elementDown(index: number) {
    const newArr = this.__swapItems(this.props.elements, index, index + 1)
    this.props.elements = newArr
    this.emit('update:elements', this.props.elements)
  }

  /**布局元素移动到底部事件 */
  elementToBottom(elements: RenderLayoutElement) {
    const index = this.props.elements.findIndex(x => x.name === elements.name)
    this.props.elements.splice(index, 1)
    this.props.elements.push(elements)
    this.emit('update:elements', this.props.elements)
  }

  /**布局元素重排 */
  __swapItems(arr: RenderLayoutElement[] | undefined, index1: number, index2: number): Array<RenderLayoutElement> {
    if (index2 >= arr!.length || index2 < 0) {
      return arr as Array<RenderLayoutElement>
    }
    arr![index1] = arr!.splice(index2, 1, arr![index1])[0]
    return arr as Array<RenderLayoutElement>
  }
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KPartCreateBaselineEmitsType, KPartCreateBaselinePropType } from './interface'
import { ref } from 'vue'

/** KPartCreateBaseline */
export default class KPartCreateBaselineViewModel extends BaseViewModel<
  KPartCreateBaselineEmitsType,
  KPartCreateBaselinePropType
> {
  includeDocDescribe = ref<boolean>(false)
  includeDocReference = ref<boolean>(false)
  onlyPublish = ref<boolean>(false)

  constructor(options: ViewModelOptions<KPartCreateBaselinePropType>) {
    super(options)
  }

  viewDidMount() {}

  getValue() {
    return {
      includeDocDescribe: this.includeDocDescribe.value,
      includeDocReference: this.includeDocReference.value,
      onlyPublish: this.onlyPublish.value
    }
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_dynamic_view = _resolveComponent("k-dynamic-view")!
  const _component_k_collapse_panel = _resolveComponent("k-collapse-panel")!
  const _component_k_collapse = _resolveComponent("k-collapse")!

  return (_ctx.$vm.isShowCategory)
    ? (_openBlock(), _createBlock(_component_k_collapse, {
        key: 0,
        size: "small",
        class: "k-segment",
        ghost: true,
        activeKey: _ctx.$vm.activeKeys,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.activeKeys) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_collapse_panel, {
            key: "class",
            header: "分类属性"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_dynamic_view, {
                ref: "refDynamic",
                schema: _ctx.$vm.categoryPropsSchema,
                pageValue: _ctx.$vm.pageValue
              }, null, 8, ["schema", "pageValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["activeKey"]))
    : _createCommentVNode("", true)
}
export const enumFactory = function(obj: any) {
  //添加_enum属性
  addEnum(obj)
  //添加_list属性
  addList(obj)

  return Object.freeze(obj)
}

function addEnum(obj: any) {
  Object.defineProperty(obj, '_enum', {
    writable: false,
    configurable: false,
    enumerable: false,
    value: ((): Record<keyof typeof obj, keyof typeof obj> => {
      const enumObject = Object.keys(obj).reduce((enumObj, key) => {
        enumObj[key] = key
        return enumObj
      }, {} as Record<keyof typeof obj, keyof typeof obj>)

      return enumObject
    })()
  })
}

function addList(obj: any) {
  Object.defineProperty(obj, '_list', {
    writable: false,
    configurable: false,
    enumerable: false,
    value: (() => {
      const list = Object.keys(obj).map(key => {
        return new SelectItem(String(key), obj[key])
      })

      return list
    })()
  })
}

export class SelectItem {
  constructor(value: any, label: any) {
    this.value = value
    this.label = label
  }

  label = ''
  value = ''
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineMemberGridView from './KBaselineMemberGrid.vue'
import KBaselineMemberGridViewModel from './KBaselineMemberGridViewModel'

const KBaselineMemberGrid = connect(KBaselineMemberGridView, KBaselineMemberGridViewModel)

export default withInstall(KBaselineMemberGrid)
export { KBaselineMemberGrid, KBaselineMemberGridView, KBaselineMemberGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView } from '@kmsoft/upf-core'
import { KIssueReportPropOptions, KIssueReportEventEmits } from './interface'
import KIssueReportViewModel from './KIssueReportViewModel'
import { ref } from 'vue'

export default defineView({
  name: 'KIssueReport',
  props: KIssueReportPropOptions,
  emits: KIssueReportEventEmits,
  viewModel: KIssueReportViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { defineView } from '@kmsoft/upf-core'
import { KRuleEditorPropOptions, KRuleEditorEventEmits } from './interface'
import KRuleEditorViewModel from './KRuleEditorViewModel'

export default defineView({
  name: 'KRuleEditor',
  props: KRuleEditorPropOptions,
  emits: KRuleEditorEventEmits,
  viewModel: KRuleEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      /** 渲染操作按钮 */
      const renderActionButton = () => {
        if (vm.disabled.value || vm.readonly.value) {
          return
        }

        return (
          <k-button disabled={vm.disabled.value} onClick={vm.onEditRule}>
            <k-icon type="highlight" />
          </k-button>
        )
      }

      /** 渲染输入框 */
      const renderInput = () => {
        if (props.showText) {
          return <div class="rule-text">{vm.displayValue.value}</div>
        }

        return <k-input value={vm.displayValue.value} disabled={true} />
      }

      return (
        <k-input-group class="k-rule-editor" compact>
          {renderInput()}
          {renderActionButton()}
        </k-input-group>
      )
    }
  }
})

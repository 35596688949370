import { connect, withInstall } from '@kmsoft/upf-core'
import KPartCollectFormView from './KPartCollectForm.vue'
import KPartCollectFormViewModel from './KPartCollectFormViewModel'

const KPartCollectForm = connect(KPartCollectFormView, KPartCollectFormViewModel)

export default withInstall(KPartCollectForm)
export { KPartCollectForm, KPartCollectFormView, KPartCollectFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_rule_define = _resolveComponent("k-rule-define")!
  const _component_k_checkbox = _resolveComponent("k-checkbox")!
  const _component_k_dialog_content = _resolveComponent("k-dialog-content")!

  return (_openBlock(), _createBlock(_component_k_dialog_content, null, {
    footer: _withCtx(() => [
      _createVNode(_component_k_checkbox, {
        checked: _ctx.$vm.isIgnoreCase,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.isIgnoreCase) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode("忽略大小写")
        ]),
        _: 1
      }, 8, ["checked"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_rule_define, {
        ref: _ctx.$vm.refSearcher,
        fields: _ctx.fields,
        condition: _ctx.filter,
        "logic-operator-column": true,
        "enable-expression-editor": false
      }, null, 8, ["fields", "condition"])
    ]),
    _: 1
  }))
}
import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KDocObjectPreviewView from './KDocObjectPreview.vue'
import KDocObjectPreviewViewModel from './KDocObjectPreviewViewModel'

const KDocObjectPreview = connect(KDocObjectPreviewView, KDocObjectPreviewViewModel)

export default withInstall(KDocObjectPreview)
export { KDocObjectPreview, KDocObjectPreviewView, KDocObjectPreviewViewModel }
export * from './interface'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

export interface ChangeRequestDataInter {
  id: string
  rdmExtensionType: string
}

/** 参数 **/
export const KChangeOrderCreatePropOptions = {
  ...BaseViewPropOptions,
  /** 变更请求单 **/
  changeRequestData: VuePropTypes.createType<ChangeRequestDataInter>().def(),
  /** 受影响对象 **/
  affectedObjData: VuePropTypes.array().def(),
  formValue: VuePropTypes.createType<any>().def()
}

/** 参数类型 **/
export type KChangeOrderCreatePropType = ViewPropsTypeExtract<typeof KChangeOrderCreatePropOptions>

/** 事件 */
export const KChangeOrderCreateEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangeOrderCreateEmitsType = ViewEmitsTypeExtract<typeof KChangeOrderCreateEventEmits>


import { defineView } from '@kmsoft/upf-core'
import KRuleDefineTestViewModel from './KRuleDefineTestViewModel'
import { KRuleDefineTestEventEmits, KRuleDefineTestPropOptions } from './interface'
import { QueryCondition } from '@kmsoft/ebf-common'
import { ref } from 'vue'

export default defineView({
  name: 'KRuleDefineTest',
  props: KRuleDefineTestPropOptions,
  emits: KRuleDefineTestEventEmits,
  viewModel: KRuleDefineTestViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const condition = ref<QueryCondition>()
    return { condition }
  }
})

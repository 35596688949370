import {
  BaseViewModel,
  EnumDialogResult,
  EnumItemChangeCancelType,
  EnumItemClickedCancelType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  PageManager,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  utils,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KCustomerNeedsEditPanelEmitsType, KCustomerNeedsEditPanelPropType } from './interface'
import { ref, watch } from 'vue'
import {
  Api,
  CommonClientSrv,
  EnumObjClassManageToolStripOptions,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPropertyEditPanelViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  ObjectToolStripItem,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import { WorkflowClientSrv } from '../../../../ebf-workflow'
import lodash from 'lodash'
import { KProductNeedsCreate, KProductNeedsCreateViewModel } from '../../../src/pages/product-needs/product-needs-create'
import KRequireWorkSpace from '../../../../ebf-requirement/src/require-workspace'

/** KCustomerNeedsEditPanel */
export default class KCustomerNeedsEditPanelViewModel extends BaseViewModel<
  KCustomerNeedsEditPanelEmitsType,
  KCustomerNeedsEditPanelPropType
> {
  /** 属性面板工具栏 */
  toolStripOptions = ref<EnumObjClassManageToolStripOptions>(EnumObjClassManageToolStripOptions.ShowEdit)
  toolStripItems = ref<Array<ObjectToolStripItem>>(ToolStripHelper.getCustomerNeedsOperationToolStripItems())
  /** 属性 */
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  /** 对象参数 */
  objParam = ref<ObjBusinessParam>()
  categorySelectorViewModel = ref<any>(null)
  originFormData = ref<any>()
  refCustomerNeeds = ref<HTMLElement>()

  constructor(options: ViewModelOptions<KCustomerNeedsEditPanelPropType>) {
    super(options)
    this.objParam.value = options.props.objParam
    watch(
      () => this.props.objParam,
      newValue => {
        if (newValue && newValue.id) {
          this.objParam.value = newValue
          this.loadData()
        }
      }
    )
  }

  viewDidMount() {}

  /** 加载数据 */
  async loadData() {
    const result = (await Api.post('requirement', 'CustomerNeeds', 'get', { data: [this.props.objParam.id] })) as any

    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      const item = result.data
      if (item.lifecycleStateCode === 'RELEASED') {
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PRODUCT_NEEDS, false)
      }
      this.originFormData.value = result.data
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取原始需求失败',
        details: result.detail
      })
      return
    }
  }

  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    if (!this.refObjectProperty.value?.props.objParam.id) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    console.log('onToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.update()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refObjectProperty.value?.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW:
        this.addWorkflow()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW:
        this.viewWorkflow()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE:
        this.structManage()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PRODUCT_NEEDS:
        this.createProductNeeds()
        break
      default:
        break
    }
  }

  /**
   *  创建产品需求
   */
  async createProductNeeds() {
    const data = lodash.cloneDeep(this.originFormData.value)
    const props: any = {
      customerNeeds: data
    }

    KDialog.show({
      title: '创建产品需求',
      size: { width: 1000, height: 800 },
      props,
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      getContainer: this.refCustomerNeeds.value,
      content: KProductNeedsCreate,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KProductNeedsCreateViewModel
        const validateResult = await formViewModel.validate()

        if (!validateResult) {
          event.cancel = true
          return
        }

        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '新建成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '新建失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  async onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY:
        if (event.value) {
          this.refObjectProperty.value?.setReadOnly(false)
        } else {
          this.doSave()
          event.itemCancelType = EnumItemChangeCancelType.CancelAll
          this.refObjectProperty.value?.setReadOnly(true)
        }
        break
      default:
        break
    }
  }

  /** 编辑保存 */
  async doSave() {
    const value = this.refObjectProperty.value?.getModifiedValue()! as any
    if (Object.keys(value).length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '没有修改数据'
      })
      return
    }
    if (value.secondary) {
      const secondary = value.secondary.map((element: string) => {
        return { id: element }
      })
      value.secondary = secondary
    }
    const reqParam = { ...value, id: this.objParam.value?.id }
    console.log('doSave', reqParam)
    const result = await Api.post('requirement', 'CustomerNeeds', 'update', { data: [reqParam] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success('操作成功！')
      return
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }

  /** 更新 */
  async update() {
    if (await this.validate()) {
      const value = this.refObjectProperty.value?.getModifiedValue()! as any
      if (Object.keys(value).length) {
        const objParam = this.refObjectProperty.value?.props.objParam
        const reqParam = { ...value, id: objParam?.id }

        if (reqParam.attachment) {
          reqParam.attachment = reqParam.attachment.map((element: string) => {
            return { id: element }
          })
        }

        const result = await Api.post('requirement', 'CustomerNeeds', 'update', { data: [reqParam] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refObjectProperty.value?.refresh()
          KNotification.success('操作成功！')
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message,
            details: result.detail
          })
        }
      } else {
        KNotification.warn({
          message: '系统提示',
          description: '没有修改数据'
        })
      }
    }
  }

  /** 加入流程 */
  async addWorkflow() {
    const result = (await Api.post('requirement', 'CustomerNeeds', 'get', { data: [this.props.objParam.id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      WorkflowClientSrv.addWorkflow(
        result.data.id,
        result.data.rdmExtensionType,
        result.data.lifecycleStateCode,
        result.data.title,
        result.data.number,
        result.data.version
      )
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取原始需求失败',
        details: result.detail
      })
      return
    }
  }

  /** 查看流程 */
  async viewWorkflow() {
    const result = (await Api.post('requirement', 'CustomerNeeds', 'get', { data: [this.props.objParam.id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      WorkflowClientSrv.viewWorkflow(result.data.id, result.data.rdmExtensionType)
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取原始需求失败',
        details: result.detail
      })
      return
    }
  }

  /** 结构管理 */
  structManage() {
    CommonClientSrv.openPage(`结构管理: `, KRequireWorkSpace, {
      name: 'RequireManage',
      isAbsUrl: false,
      windowId: new Date().getTime(),
      objParam: {
        name: this.originFormData.value.name,
        ...this.props.objParam
      }
    })
  }

  /** 校验 */
  async validate() {
    try {
      return this.refObjectProperty.value?.validate()
    } catch (error) {
      console.log('验证错误', error)
      return false
    }
  }

  /**面板加载完成 */
  loaded() {}
}

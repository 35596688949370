
import { ToolStripItemChangeEventArgs, ToolStripItemClickedEventArgs, defineView } from '@kmsoft/upf-core'
import { KCustomerNeedsEditPanelPropOptions, KCustomerNeedsEditPanelEventEmits } from './interface'
import KCustomerNeedsEditPanelViewModel from './KCustomerNeedsEditPanelViewModel'

export default defineView({
  name: 'KCustomerNeedsEditPanel',
  props: KCustomerNeedsEditPanelPropOptions,
  emits: KCustomerNeedsEditPanelEventEmits,
  viewModel: KCustomerNeedsEditPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

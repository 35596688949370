import { IMenuPagePathMapping, MenuPagePath } from '@kmsoft/upf-core'
import KClassTreeDemo from '../pages/class-tree-demo'
import KDocDemo from '../pages/doc-demo'
import KEditPanelDemo from '../pages/edit-panel-demo'
import ObjectCreatePanelTest from '../pages/object-create-panel-test'
import KObjectCreatePanelTest from '../pages/object-create-panel-test'
import ObjectGridTest from '../pages/object-grid-test'
import ObjectPanelTest from '../pages/object-panel-test'
import ObjectSelectorTest from '../pages/object-selector-test'
import KPartDemo from '../pages/part-demo'
import QuickSearchBarTest from '../pages/quick-search-bar-test'
import RuleDefineTest from '../pages/rule-define-test'
import UserSelectorTest from '../pages/user-selector-test'
import KObjectClassManage from '../pages/object-manage'
import KNavigation from '../pages/navigation-style-test'
import { KPartImport, KPartManage } from '@kmsoft/ebf-part'
import { KConfigManage } from '@kmsoft/ebf-config'
import {
  KCADWorkspace,
  KClassUiDefine,
  KCommonPartManage,
  KElectronicComponentsWorkspace,
  KMyManagedProductionWorkspace,
  KPersonalHomePage,
  KPersonalWorkspace,
  KProjectWorkspace,
  KProjectWorkspaceList,
  KPublicWorkspace,
  KStandardWorkspace,
  KWorkspaceList
} from '../../../ebf-folder'
import KTreeDataGrid from '../pages/tree-grid-demo'
import KFolderSelectTest from '../pages/folder-select-test'

import { KObjectWorkspace } from '../controls/object-workspace'
import { KObjectFileUpload } from '../../../ebf-common/src/controls/object-file-upload'
import { KIssueReportRelationPart } from '@kmsoft/ebf-change-manage'
import { KPartImportErrorMsg } from '@kmsoft/ebf-part/src/pages/part-import/part-import-error-msg'
import { KCommonValidationMetrics } from '../../../ebf-requirement/src/pages/common-validation-metrics'
import { KCustomerNeedsCreate } from '@kmsoft/ebf-requirement'

export default class MenuPagePathMapping implements IMenuPagePathMapping {
  getMenus() {
    return [
      { target: 'object-panel-test', component: ObjectPanelTest },
      { target: 'object-create-panel-test', component: ObjectCreatePanelTest },
      { target: 'class-tree-demo', component: KClassTreeDemo },
      { target: 'object-grid-test', component: ObjectGridTest },
      { target: 'quick-search-bar-test', component: QuickSearchBarTest },
      { target: 'rule-define-test', component: RuleDefineTest },
      { target: 'object-selector-test', component: ObjectSelectorTest },
      { target: 'user-selector-test', component: UserSelectorTest },
      { target: 'part-demo', component: KPartDemo },
      { target: 'edit-panel-demo', component: KEditPanelDemo },
      { target: 'object-class-manage', component: KObjectClassManage },
      { target: 'part-manage', component: KPartManage },
      { target: 'part-import', component: KPartImport },
      { target: 'part-import-error-msg', component: KPartImportErrorMsg },
      { target: 'product-space-list', component: KWorkspaceList },
      { target: 'public-workspace', component: KPublicWorkspace },
      { target: 'personal-workspace', component: KPersonalWorkspace },
      { target: 'standard-workspace', component: KStandardWorkspace },
      { target: 'electronic-components-workspace', component: KElectronicComponentsWorkspace },
      { target: 'common-part-workspace', component: KCommonPartManage },
      { target: 'project-workspace-list', component: KProjectWorkspaceList },
      { target: 'cad-workspace-list', component: KCADWorkspace },
      { target: 'doc-demo', component: KDocDemo },
      { target: 'config-manage', component: KConfigManage },
      { target: 'object-create-panel-test', component: KObjectCreatePanelTest },
      { target: 'tree-grid-demo', component: KTreeDataGrid },
      { target: 'folder-selector-test', component: KFolderSelectTest },
      { target: 'file-upload', component: KObjectFileUpload },
      { target: 'object-workspace', component: KObjectWorkspace },
      { target: 'my-managed-production-workspace', component: KMyManagedProductionWorkspace },
      { target: 'navigation-style-test', component: KNavigation },
      { target: 'project-workspace', component: KProjectWorkspace },
      { target: 'personal-homepage', component: KPersonalHomePage },
      { target: 'issue-report-relation-part', component: KIssueReportRelationPart },
      { target: 'class-ui-define', component: KClassUiDefine },
      { target: 'issue-report-relation-part', component: KIssueReportRelationPart },
      { target: 'issue-report-relation-part', component: KIssueReportRelationPart },
      { target: 'common-validation-metrics', component: KCommonValidationMetrics }
    ] as Array<MenuPagePath>
  }
}

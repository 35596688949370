export enum EnumKmVueFileType {
  Office = 1,
  PDF = 2,
  Picture = 3,
  Model = 4,
  Part = 5,
  Txt = 6,
  Video = 7
}

export enum EnumMenuType {
  All = 0,
  Tree = 1,
  Pan = 2,
  FullView = 3,
  VisableOrHiden = 5,
  View = 5,
  Slices = 6,
  Grab = 7,
  Measure = 8,
  Mark = 9,
  QuickShot = 10,
  Explode = 11,
  Setting = 12,
  Nav = 13,
  Check = 14,
  Compare = 15
}


import {
  EnumDataGridAutoSizeColumnsMode,
  EnumDataGridRowModelType,
  KDataGridRowSelectedEvent,
  defineView
} from '@kmsoft/upf-core'
import KBomCompareResultPanelViewModel from './KBomCompareResultPanelViewModel'
import { KBomCompareResultPanelEventEmits, KBomCompareResultPanelPropOptions } from './interface'

export default defineView({
  name: 'KBomCompareResultPanel',
  props: KBomCompareResultPanelPropOptions,
  emits: KBomCompareResultPanelEventEmits,
  viewModel: KBomCompareResultPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autoSizeColumnsMode: EnumDataGridAutoSizeColumnsMode.ALL_CELLS
    }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KPersonalWorkflowTasksView from './KPersonalWorkflowTasks.vue'
import KPersonalWorkflowTasksViewModel from './KPersonalWorkflowTasksViewModel'

const KPersonalWorkflowTasks = connect(KPersonalWorkflowTasksView, KPersonalWorkflowTasksViewModel)

export default withInstall(KPersonalWorkflowTasks)
export { KPersonalWorkflowTasks, KPersonalWorkflowTasksView, KPersonalWorkflowTasksViewModel }

// 模板生成文件
// export * from './{{folderName}}'

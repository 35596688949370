import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'

/** 参数 **/
export const KClassPropertySelectorPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KClassPropertySelectorPropType = ViewPropsTypeExtract<typeof KClassPropertySelectorPropOptions>

/** 事件 */
export const KClassPropertySelectorEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KClassPropertySelectorEmitsType = ViewEmitsTypeExtract<typeof KClassPropertySelectorEventEmits>

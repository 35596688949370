import { AgentStaticObject } from '../../AgentStaticObject'
import { AgentInstanceObject } from '../../AgentInstanceObject'

/**提供字节序列的一般视图。 这是一个抽象类。*/
export abstract class Stream extends AgentInstanceObject {
  protected static type: string = 'System.IO.Stream,System.Runtime'

  /**当在派生类中重写时，获取或设置当前流中的位置。*/
  public Position(value: number = AgentStaticObject.None): Promise<number> {
    return this.property('Position', value)
  }
}

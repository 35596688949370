export enum EnumObjCopyMode {
  /**
   * 0 新建对象
   */
  NewObj = 0,
  /**
   * 1 创建小版本
   */
  NewVersion = 1,
  /**
   * 2 创建大版本
   */
  NewMajorVersion = 2,
  /**
   * 3 另存
   */
  SaveAs = 3
}

/**
 * 【系统标准接口】
 * 对象分类列表中的显示版本的方式定义
 */
export enum EnumVersionShowType {
  /**
   * 【系统标准接口】
   * 获取最新版本
   */
  LatestVersion = 0,

  /**
   * 【系统标准接口】
   * 获取所有版本
   */
  AllVersion = 1,

  /**
   * 【系统标准接口】
   * 获取最新发布版本
   */
  LatestPublishVersion = 2,

  /**
   * 【系统标准接口】
   * 获取所有发布版本
   */
  AllPublished = 3
}

/**
 * 对象分类管理规则
 */
export enum EnumObjGridRule {
  /**
   * 其他
   * */
  Others = 'Others',
  /**
   * 我管理的流程
   * */
  MyManageFlow = 'MyManageFlow',
  /**
   * 批次任务添加工作对象
   * */
  BatchTaskAddDataObjs = 'BatchTaskAddDataObjs',
  /**
   * 单层引用选择同版本子件规则
   * */
  ChangePartOnSingleUseRule = 'ChangePartOnSingleUseRule',
  /**
   * 排除自己的关联对象规则
   * */
  AddRelationObjRule = 'AddRelationObjRule',
  /**
   * 未被其他对象描述的文档对象
   * */
  AddDescObj = 'AddDescObj',

  /**
   * 引用现有文档
   */
  QuoteDocObj = 'QuoteDocObj',

  /**
   * 工作流过滤
   */
  FlowFilter = 'FlowFilter',

  /**
   * 游离文档处理
   */
  FreeDocManagementRule = 'FreeDocManagementRule',

  /**
   * 基线管理
   */
  BaselineManageRule = 'BaselineManage',
  /**
   * 已发布
   */
  PublishedObj = 'PublishedObj',
  /** 版本列表 */
  ChangePartVersionRule = 'ChangePartVersion'
}

export enum EnumObjLifeState {
  /**
   * 0-创建
   */
  Created = 0,

  /**
   * 100-活动
   */
  Actived = 100,

  /**
   * 199-预发布
   */
  PrePublished = 199,

  /**
   * 150-稳定
   */
  Stable = 150, //PNW47：生命周期状态改进

  /**
   * 200-发布
   */
  Published = 200,

  /**
   * 300-冻结
   */
  Freezen = 300
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangePromoteActivityView from './KChangePromoteActivity.vue'
import KChangePromoteActivityViewModel from './KChangePromoteActivityViewModel'

const KChangePromoteActivity = connect(KChangePromoteActivityView, KChangePromoteActivityViewModel)

export default withInstall(KChangePromoteActivity)
export { KChangePromoteActivity, KChangePromoteActivityView, KChangePromoteActivityViewModel }

// 模板生成文件
// export * from './{{folderName}}'

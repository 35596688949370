import { connect, withInstall } from '@kmsoft/upf-core'
import KPartDemoView from './KPartDemo.vue'
import KPartDemoViewModel from './KPartDemoViewModel'

const KPartDemo = connect(KPartDemoView, KPartDemoViewModel)

export default withInstall(KPartDemo)
export { KPartDemo, KPartDemoView, KPartDemoViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { EnumAlignType, EnumControlElementType, EnumLabelPosition } from '@kmsoft/upf-core'

/** 默认标签位置候选值 */
export const DEFAULT_LABEL_POSITION_CANDIDATE = [
  { displayValue: '居左', actualValue: EnumLabelPosition.LEFT },
  { displayValue: '居上', actualValue: EnumLabelPosition.TOP },
  { displayValue: '不显示', actualValue: EnumLabelPosition.HIDDEN }
]

export const DEFAULT_LABEL_ALIGN_CANDIDATE = [
  { displayValue: '居左', actualValue: EnumAlignType.LEFT },
  { displayValue: '居中', actualValue: EnumAlignType.CENTER },
  { displayValue: '居右', actualValue: EnumAlignType.RIGHT }
]

/** 布尔类型的下拉候选值 */
export const BOOLEAN_CANDIDATE = [
  { displayValue: '是', actualValue: true },
  { displayValue: '否', actualValue: false }
]
/** 组件类型候选值 */
export const CONTROL_TYPE_CANDIDATE = [
  { displayValue: '文本框', actualValue: EnumControlElementType.INPUT },
  { displayValue: '多文本', actualValue: EnumControlElementType.TEXT_AREA },
  { displayValue: '日期框', actualValue: EnumControlElementType.DATE_TIME },
  { displayValue: '数字框', actualValue: EnumControlElementType.NUMBER },
  { displayValue: '复选框', actualValue: EnumControlElementType.CHECKBOX },
  { displayValue: '下拉框', actualValue: EnumControlElementType.COMBOBOX },
  { displayValue: '按钮', actualValue: EnumControlElementType.BUTTON },
  { displayValue: '下拉按钮', actualValue: EnumControlElementType.DROPDOWN_BUTTON }
]

/** 候选值属性 */
export type KDesignerControlOptions = {
  displayValue: string
  actualValue: any
}

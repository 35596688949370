import { ref } from 'vue'
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KClassUiDefineEmitsType, KClassUiDefinePropType } from './interface'

/** KClassUiDefine */
export default class KClassUiDefineViewModel extends BaseViewModel<KClassUiDefineEmitsType, KClassUiDefinePropType> {
  selectedObjClsId = ref()
  selectedObjClsCode = ref()
  selectedNode = ref<any>()
  tabActiveKey = ref('1')
  /**支持文件访问 */
  fileAccess = ref(false)
  constructor(options: ViewModelOptions<KClassUiDefinePropType>) {
    super(options)
  }

  /** 加载完成函数 */
  viewDidMount() {}
  /**
   * 选择节点以后
   * @param node
   */
  onAfterSelect(node: any) {
    console.log('对象属性', node?.objClassMetaProperty)
    this.selectedObjClsId.value = node.objClassMetaProperty.id as any
    this.selectedObjClsCode.value = node.objClassMetaProperty.code as any

    this.selectedNode.value = node.objClassMetaProperty.code as any
  }
}

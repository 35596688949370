import { connect, withInstall } from '@kmsoft/upf-core'
import KFlowDetailView from './KFlowDetail.vue'
import KFlowDetailViewModel from './KFlowDetailViewModel'

const KFlowDetail = connect(KFlowDetailView, KFlowDetailViewModel)

export default withInstall(KFlowDetail)
export { KFlowDetail, KFlowDetailView, KFlowDetailViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KObjectViewSelectorPropOptions = {
  ...BaseViewPropOptions,
  value: VuePropTypes.createType<Record<string, any>>().def(),
  readonly: VuePropTypes.bool().def(),
  disabled: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KObjectViewSelectorPropType = ViewPropsTypeExtract<typeof KObjectViewSelectorPropOptions>

/** 事件 */
export const KObjectViewSelectorEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KObjectViewSelectorEmitsType = ViewEmitsTypeExtract<typeof KObjectViewSelectorEventEmits>

/** 视图对象 **/
export type ViewData = {
  /**视图id */
  id: string
  /**视图名称 */
  name: string
  /**排序id */
  sortId: string
  /**视图描述 */
  description: string
  /**拓展类型 */
  rdmExtensionType: string
  /**类型 */
  className: string
}

/** 文档类型 */
export enum EnumDocType {
  /**
   * UG数模
   */
  UG = 'UG_SOLIDEDGE',
  /**
   * SW数模
   */
  SW = 'SOLIDWORKS',
  /**
   * autoCAD
   */
  CAD = 'AUTOCAD_2D',
  /**
   * office
   */
  OFFICE = 'OFFICE',
  /**
   * WPS
   */
  WPS = 'WPS',

  /**
   * word
   */
  WORD = 'WORD',

  /**
   * excel
   */
  EXCEL = 'EXCEL',

  /**
   * 结构化文档
   */
  STRUCTURE = 'STRUCTURE',

  /**
   * 结构化文档分解要素
   */
  BREAKDOWN_ELEMENTS = 'BREAKDOWN_ELEMENTS',

  /**
   * CADENCE原理图
   */
  CADENCE = 'CADENCE',

  /**
   * CADENCE_PCB图
   */
  CADENCE_PCB = 'CADENCE_PCB',

  /**
   * 其他
   */
  OTHER = 'OTHER'
}

/** 文档类型 */
export enum EnumDocDependencyType {
  /**
   * 签字文档
   */
  SIGNATURE = 'SIGNATURE',
  /**
   * 打印文档
   */
  PRINT = 'PRINT',
  /**
   * 浏览文档
   */
  BROWSE = 'Browse',

  /**
   * SOLIDWORKS工程图
   */
  SOLIDWORKS_2D = 'SOLIDWORKS_2D',

  /**
   * Gerber文件
   */
  CADENCE_GERBER = 'GERBER',

  /**
   * 坐标文件
   */
  CADENCE_COORDINATE = 'COORDINATE',

  /**
   * ODB++文件
   */
  CADENCE_ODB_PLUS = 'ODB_PLUS',

  /**
   * 网表文件
   */
  CADENCE_NET_LIST = 'NET_LIST'
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b579f596"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "event-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "element-event-items"
}
const _hoisted_4 = { class: "event-base-info" }
const _hoisted_5 = { class: "element-event-name" }
const _hoisted_6 = { class: "element-event-operation" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_badge = _resolveComponent("k-badge")!
  const _component_k_button = _resolveComponent("k-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.controlEvents && _ctx.controlEvents.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.controlEvents, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "element-event-item",
                    key: item.name
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_k_badge, {
                        dot: _ctx.computedShowBadge(item.name)
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_5, _toDisplayString(item.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["dot"])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_k_button, {
                        class: "element-event-operation-item",
                        onClick: () => _ctx.$vm.editScript(item.name)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("点击设值")
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, " 当前元素无事件添加项 "))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, " 当前未选中元素 "))
  ]))
}
/** 配置项名称枚举 */
export enum EnumElementConfigItemName {
  /** 元素名称（唯一标识） */
  NAME = 'name',
  /** 元素标题 */
  TITLE = 'title',
  /** 标签宽度 */
  LABEL_WIDTH = 'labelWidth',
  /** 最小列宽度 */
  MIN_COL_WIDTH = 'minColWidth',
  /** 标签位置 */
  LABEL_POSITION = 'labelPosition',
  /** 行间距 */
  ROW_GAP = 'rowGap',
  /** 列间距 */
  COL_GAP = 'colGap',
  /** 表格列配置 */
  TABLE_COLUMNS = 'columns',
  /** 列跨度 */
  COL_SPAN = 'colSpan',
  /** 行跨度 */
  ROW_SPAN = 'rowSpan',
  /** 是否占整行 */
  ENTIRE_ROW = 'entireRow',
  /** 是否占满空间  */
  FILL = 'fill',
  /** 容器列数 */
  COL_COUNT = 'colCount'
}

/**扩展点数据源类型 */
export enum EnumExtensionPointDataSourceType {
  /**手动输入 */
  MannualInput = 0,
  /**网络获取 */
  Network = 1
}
/**扩展节点类型 */
export enum EnumExtensionPointNodeType {
  /**目录 */
  Directory = 0,
  /**扩展点 */
  ExtensionPoint = 1,
  /**属性 */
  Property = 2
}

/** 扩展点属性类型 */
export enum EnumExtensionPointPropType {
  /** 简单数据 */
  SimpleData = 0,
  /** 复杂对象 */
  ComplexObject = 1,
  /** 回调函数 */
  Function = 2
}

/**页面设计数据源类型 */
export enum EnumPageDesignDataSourceType {
  /**手动输入 */
  MannualInput = 0,
  /**网络获取 */
  Network = 1
}

import {
  AppContext,
  EnumErrorLevel,
  GlobalException,
  Guid,
  KDialog,
  KNotification,
  UPF_IOC_KEYS,
  eventEmitter
} from '@kmsoft/upf-core'
import lodash from 'lodash'
import { Agent } from '../../index'
import { localStorageCache } from '../cache'
import { EnumSaasEventNames } from '../system'
import { AgentReqModelBase } from './types'

/** WebSocket配置 */
type WebSocketConfig = {
  webApiUrl: string
  port: number
  sessionId: string
}

/**
 * 状态
 */
export enum EnumWebSocketStatus {
  Disconnected = 1,
  Connecting = 2,
  Connected = 3,
  Error = 3
}

/** 状态事件 */
export type WebSocketStatusEvent = {
  /** 状态 */
  status: EnumWebSocketStatus
  /** 状态消息 */
  statusMsg: string
}

/**
 * WebSocket服务类
 */
class WebSocketClientSrv {
  private __webSocketConfigKey: string = 'WEB_SOCKET_CONFIG_KEY'
  private __basePort: number = 5310
  private __port: number = this.__basePort
  private __sessionId = Guid.newGuidString()
  private __webSocketId: string | null = null
  private __webSocket: WebSocket | null = null
  private __isWebApiOK: boolean = false
  /** 是否正在连接 */
  private __status: EnumWebSocketStatus = EnumWebSocketStatus.Disconnected
  /** 连接本地代理程序失败重试次数：10 */
  private __connectWebSocketRetryTimes = 10
  /** 本地代理程序检查最跳频率（秒）：10 */
  private __checkAgentInterval = 10
  /** 本地代理程序检查最小心跳频率（秒）：5 */
  private __checkAgentMinInterval = 5
  /** 等待本地代理程序启动时间（秒）：5（5秒后再进行WebSocket连接） */
  private __agentExeStartWaitTime = 5
  private __timeout?: NodeJS.Timeout
  /** 重试次数 */
  private __retryTimes = 0
  //#region  公共方法
  /**
   * 更新本地代理
   */
  updateAndRun(): void {
    if (this.__webSocketId) {
      return
    }
    this.__connect(3)
  }
  /**
   * 更新本地代理
   */
  update(): void {
    if (this.__webSocketId) {
      return
    }

    this.__startupProxyExe(1)
    KNotification.info({ message: '本地代理程序更新中，请稍后...' })
  }
  /**
   * 连接WebSocket
   * @param mode：1=>更新，2=>运行，3=>更新+运行
   */
  async connect(mode: number = 2): Promise<boolean> {
    const self = this
    if (mode == 2) {
      return new Promise(r => {
        KDialog.confirm({
          content: '请确认本地代理程序更新到最新版本？',
          onOk: () => self.__connect(mode).then(b => r(b)),
          onCancel: () => r(false)
        })
      })
    } else {
      return await self.__connect(mode)
    }
  }
  /**
   * WebSocket是否连接成功
   */
  async isConnected(): Promise<boolean> {
    return await Agent.AgentManager.initialize() //this.__webSocketId != null || this.__webSocketId != ''
  }
  /**
   * 当前状态
   */
  get status() {
    return this.__status
  }
  /**
   * 获取WebSocketId
   */
  getWebSocketId(): string | null {
    return this.__webSocketId
  }

  /**
   * 关闭WebSocket
   */
  close(): void {
    this.__webSocket?.close()
    this.__webSocket = null
    this.__webSocketId = null
    this.__isWebApiOK = false
    this.__status = EnumWebSocketStatus.Disconnected
    this.emitStatus()
  }

  /**
   * 判断指定请求是否为代理响应
   * @param resp 请求配置
   * @returns
   */
  isAgentResponse(resp: Record<string, any>): boolean {
    return resp && Reflect.has(resp, 'webSocketRequestIdentity')
  }

  /**
   * 获取WebApi基础地址，由于是本地代理，所以没必要使用HTTPS，直接写死返回http协议地址
   * @returns WebApi基础地址
   */
  getAgentApiBaseUrl(): string {
    return 'http://localhost:5093'
    // return 'http://localhost:' + this.__port.toString()
  }
  /**
   * 获取WebSocket唯一标识
   * @returns WebSocket唯一标识
   */
  getAgentReqModelBase(): AgentReqModelBase {
    if (!this.__webSocketId) {
      throw GlobalException.getException('请确认本地代理程序运行正常！', { level: EnumErrorLevel.GeneralError, module: 'Agent' })
    }
    return {
      webSocketIdentity: this.__webSocketId!,
      webSocketRequestIdentity: 'REQ_' + new Date().getTime().toString()
    }
  }
  /**
   * @returns 获取WebSocket连接URL地址
   */
  getWebSocketUrl(webSocketIDentity?: string): string {
    const scheme = document.location.protocol == 'https:' ? 'wss' : 'ws'
    if (webSocketIDentity) return scheme + '://localhost:' + this.__port + '/ws?id=' + webSocketIDentity
    else return scheme + '://localhost:' + this.__port + '/ws'
  }
  /**
   * 设置代理检查频率
   * @param interval 代理检查频率（单位：秒）
   */
  setAgentCheckInterval(interval: number): void {
    if (interval < this.__checkAgentMinInterval) interval = this.__checkAgentMinInterval
    this.__checkAgentInterval = interval
  }
  //#endregion

  //#region  私有辅助方法
  /**
   * 连接WebSocket
   * @param mode：1=>更新，2=>运行，3=>更新+运行
   * @returns 是否连接成功
   */
  private async __connect(mode: number = 2): Promise<boolean> {
    this.__retryTimes = 0
    do {
      // 设置状态为连接中
      this.__status = EnumWebSocketStatus.Connecting

      // 如果是第一次启动
      if (this.__retryTimes == 0) {
        this.emitStatus('代理程序启动中')
      } else {
        this.emitStatus(`正在重试启动代理程序[${this.__retryTimes}/${this.__connectWebSocketRetryTimes}]`)
      }

      this.__retryTimes++
      const baseUrl = AppContext.current.getEnvironment().getBaseApiUrl() as string
      this.__port = await this.__getPort(baseUrl)

      // 启动代理程序
      await this.__startupProxyExe(mode)

      const result = await this.__connectWebSocket()
      if (result) {
        this.emitStatus('代理程序连接中')
        return true
      }

      // 如果超过重试次数
      if (this.__retryTimes > this.__connectWebSocketRetryTimes) {
        this.__status = EnumWebSocketStatus.Error
        return false
      }
    } while (true)
  }

  /**
   * 获取代理程序启动参数
   * @returns
   */
  private __getProxyExeArguments(mode: number = 3, enableDebug: boolean = false): string {
    const baseUrl = AppContext.current.getEnvironment().getBaseApiUrl() as string
    const agentApiUrl = '--urls=' + this.getAgentApiBaseUrl()
    const encodeUrl = encodeURIComponent(agentApiUrl)
    return `HostArguments=${encodeUrl}`
  }

  /**
   * 启动代理程序
   * @param mode：1=>更新，2=>运行，3=>更新+运行
   */
  private __startupProxyExe(mode: number = 3): Promise<void> {
    //清除老的iframe
    const id = 'ifm_km_proxy'
    const oldIfm = document.getElementById(id)
    oldIfm && document.body.removeChild(oldIfm)
    oldIfm && oldIfm.remove()
    //创建新的iframe
    const elemIF = document.createElement('iframe')
    elemIF.id = id
    elemIF.style.display = 'none'
    elemIF.src = 'kmsaas://proxy?' + this.__getProxyExeArguments(mode)
    document.body.appendChild(elemIF)
    return new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, this.__agentExeStartWaitTime * 1000)
    })
  }

  private async __connectWebSocket(): Promise<boolean> {
    return Agent.AgentManager.initialize()
  }

  private async __getPort(webApiUrl: string): Promise<number> {
    let port: number
    let isNewPort = false
    const allConfigs = localStorageCache.getCache<Array<WebSocketConfig>>(this.__webSocketConfigKey) ?? []
    let existedConfig: WebSocketConfig | undefined

    try {
      if (allConfigs.length == 0) {
        port = this.__basePort + (new Date().getTime() % 50000)
        isNewPort = true
        return port
      }

      existedConfig = allConfigs.find(c => c.webApiUrl == webApiUrl)
      if (existedConfig) {
        port = existedConfig.port
        return port
      }

      const maxConfig = lodash.maxBy(allConfigs, c => c.port)!
      port = maxConfig.port + 1
      isNewPort = true
      return port
    } finally {
      if (isNewPort) {
        allConfigs.push({
          webApiUrl: webApiUrl,
          port: port!,
          sessionId: this.__sessionId
        })
        localStorageCache.setCache(this.__webSocketConfigKey, allConfigs)
      } else if (existedConfig) {
        //更新端口号
        existedConfig.sessionId = this.__sessionId
        localStorageCache.setCache(this.__webSocketConfigKey, allConfigs)
      }
    }
  }

  /**
   * 发出状态事件
   * @param msg
   */
  private emitStatus(msg: string = '') {
    eventEmitter.emit(UPF_IOC_KEYS.APP_WIDGET, EnumSaasEventNames.AGENT_STATUS_CHANGED, {
      status: this.__status,
      statusMsg: msg
    } as any)
  }
  //#endregion
}

export const webSocketClientSrv = new WebSocketClientSrv()

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KAttrsCategoryRenderPropOptions = {
  ...BaseViewPropOptions,
  formData: VuePropTypes.record().def(),
  readonly: VuePropTypes.bool().def(false),
  disabled:VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KAttrsCategoryRenderPropType = ViewPropsTypeExtract<typeof KAttrsCategoryRenderPropOptions>

/** 事件 */
export const KAttrsCategoryRenderEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KAttrsCategoryRenderEmitsType = ViewEmitsTypeExtract<typeof KAttrsCategoryRenderEventEmits>

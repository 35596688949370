
import { ToolStripItemChangeEventArgs, ToolStripItemClickedEventArgs, defineView } from '@kmsoft/upf-core'
import { KProductNeedsSectionEditPanelPropOptions, KProductNeedsSectionEditPanelEventEmits } from './interface'
import KProductNeedsSectionEditPanelViewModel from './KProductNeedsSectionEditPanelViewModel'

export default defineView({
  name: 'KProductNeedsSectionEditPanel',
  props: KProductNeedsSectionEditPanelPropOptions,
  emits: KProductNeedsSectionEditPanelEventEmits,
  viewModel: KProductNeedsSectionEditPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

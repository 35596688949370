import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

import { DefaultFilterValue, KFilterEditorResolver, KFilterOperationResolver, KFilterWithId } from '../../interface'
import { KDesignerControl } from '../../../../../../types'

/** KFilterGirdFilterItemConfigForm 事件 */
export const KFilterGirdFilterItemConfigFormEventEmits = {
  ...BaseViewEventEmits
}

/** KFilterGirdFilterItemConfigForm 事件类型 **/
export type KFilterGirdFilterItemConfigFormEventEmitsType = ViewEmitsTypeExtract<typeof KFilterGirdFilterItemConfigFormEventEmits>

/** KFilterGirdFilterItemConfigForm 参数 **/
export const KFilterGirdFilterItemConfigFormPropOptions = {
  ...BaseViewPropOptions,
  /**列 */
  columns: VuePropTypes.createType<Array<KDesignerControl>>().def([]),
  /**操作符自定义 */
  operationResolver: VuePropTypes.func<KFilterOperationResolver>().def(),
  /**编辑方式工厂 */
  filterEditorResolver: VuePropTypes.func<KFilterEditorResolver>().def(),
  /** 筛选条件 */
  filter: VuePropTypes.createType<KFilterWithId>().def(DefaultFilterValue as KFilterWithId)
}

/** KFilterGirdFilterItemConfigForm 参数类型 **/
export type KFilterGirdFilterItemConfigFormPropType = ViewPropsTypeExtract<typeof KFilterGirdFilterItemConfigFormPropOptions>

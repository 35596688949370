import { App, Plugin } from 'vue'
import KChangeActivityCreate from './change-activity/change-activity-create'
import KChangeActivityEdit from './change-activity/change-activity-edit'
import KIssueReportCreate from './issue-report/issue-report-create'
import KChangeOrderCreate from './change-order/change-order-create'
import KChangeOrderEdit from './change-order/change-order-edit'
import KChangeOrderRelationship from './change-order/change-order-relationship'
import KChangeActivityRelationship from './change-activity/change-activity-relationship'
import KChangePromoteActivity from './change-promote-activity/change-promote-activity'
import KChangePromoteActivityEdit from './change-promote-activity/change-promote-activity-edit'

const components: Record<string, Plugin> = {
  KChangeActivityCreate,
  KChangeActivityEdit,
  KIssueReportCreate,
  KChangeOrderCreate,
  KChangeOrderEdit,
  KChangeOrderRelationship,
  KChangeActivityRelationship,
  KChangePromoteActivity,
  KChangePromoteActivityEdit
}

const install = function(app: App) {
  Object.keys(components).forEach(key => {
    app.use(components[key])
  })
}

export default {
  install: install
}

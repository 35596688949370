import { Component, ComputedOptions, MethodOptions } from 'vue'
import { KObjectDisplay } from '.'
import { MetaProperty } from '../../../../client-srv'
import { IFieldEditorResolver } from '../../base'
import { KDataGridObjectDisplayColumn } from './KObjectDisplayColumn'

export class ObjectDisplayResolver extends IFieldEditorResolver {
  static getColumn(field: MetaProperty) {
    if (field.columnName == 'rdmExtensionType') {
      const refColumnInstance = new KDataGridObjectDisplayColumn()
      return refColumnInstance
    }
    return null
  }
  static getEditor(field: MetaProperty): string | (() => Component<any, any, any, ComputedOptions, MethodOptions>) | null {
    if (field.code == 'rdmExtensionType') {
      return () => KObjectDisplay
    }
    return null
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineCompareGridDiffView from './KBaselineCompareGridDiff.vue'
import KBaselineCompareGridDiffViewModel from './KBaselineCompareGridDiffViewModel'

const KBaselineCompareGridDiff = connect(KBaselineCompareGridDiffView, KBaselineCompareGridDiffViewModel)

export default withInstall(KBaselineCompareGridDiff)
export { KBaselineCompareGridDiff, KBaselineCompareGridDiffView, KBaselineCompareGridDiffViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { style: {"margin-left":"5px"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_divider = _resolveComponent("k-divider")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _withDirectives(_createVNode(_component_k_object_tool_strip, {
        ref: _ctx.$vm.refToolStrip,
        items: _ctx.$vm.toolStripItems,
        onItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onToolStripItemClicked(event))
      }, null, 8, ["items"]), [
        [_vShow, _ctx.environment === 'detail']
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        name: "objectClassLayoutGrid",
        ref: _ctx.$vm.refObjectClassLayoutGrid,
        disabled: true,
        autoIndex: true,
        loadData: () => _ctx.$vm.loadObjectClassLayoutDefinition(),
        isMultipleSelection: true,
        onRowSelected: _cache[1] || (_cache[1] = (event) => _ctx.$vm.changeToolItem(event)),
        rowModelType: _ctx.rowModelType
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            headerText: "布局编号",
            dataPropertyName: "code",
            align: "center",
            width: _ctx.environment === 'detail' ? '175' : 'auto'
          }, null, 8, ["width"]),
          _createVNode(_component_k_data_grid_template_column, {
            headerText: "布局名称",
            dataPropertyName: "name",
            align: "center",
            width: _ctx.environment === 'detail' ? '240' : 'auto'
          }, {
            default: _withCtx(({ cellValue,row }) => [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                onClick: () => _ctx.$vm.openLayoutDesign(row)
              }, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(cellValue), 1)
              ], 8, _hoisted_1)
            ]),
            _: 1
          }, 8, ["width"]),
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "type",
            name: "type",
            dataPropertyName: "type",
            headerText: "布局类型",
            options: _ctx.objectClassLayoutTypeOptions,
            align: "center",
            width: _ctx.environment === 'detail' ? '240' : 'auto'
          }, null, 8, ["options", "width"]),
          _createVNode(_component_k_data_grid_text_box_column, {
            headerText: "描述",
            dataPropertyName: "remark",
            align: "center",
            width: _ctx.environment === 'detail' ? '300' : 'auto'
          }, null, 8, ["width"]),
          _createVNode(_component_k_data_grid_text_box_column, {
            headerText: "创建时间",
            dataPropertyName: "createTime",
            align: "center",
            width: _ctx.environment === 'detail' ? '210' : 'auto'
          }, null, 8, ["width"]),
          (_ctx.environment === 'detail')
            ? (_openBlock(), _createBlock(_component_k_data_grid_template_column, {
                key: 0,
                id: "ACTIONS",
                name: "ACTIONS",
                fixed: "right",
                align: "center",
                headerText: "操作",
                width: "auto"
              }, {
                default: _withCtx(({row}) => [
                  _createElementVNode("a", {
                    class: "operation-item",
                    onClick: (e) => _ctx.$vm.detail(row)
                  }, "查看", 8, _hoisted_3),
                  _createVNode(_component_k_divider, { type: _ctx.vertical }, null, 8, ["type"]),
                  _createElementVNode("a", {
                    onClick: (e) => _ctx.$vm.editObjectClassLayout(row),
                    class: _normalizeClass(['operation-item', row.lifecycleState == 50000 ? 'disabled-link' : ''])
                  }, " 编辑 ", 10, _hoisted_4),
                  _createVNode(_component_k_divider, { type: _ctx.vertical }, null, 8, ["type"]),
                  _createElementVNode("a", {
                    class: _normalizeClass(['operation-item', row.lifecycleState == 50000 ? 'disabled-link' : '']),
                    onClick: (e) => _ctx.$vm.singleDelete(row)
                  }, " 删除 ", 10, _hoisted_5)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["loadData", "rowModelType"])
    ]),
    _: 1
  }))
}
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { FileDataInter } from './doc-select-files/interface'
import { KDialogResult } from '@kmsoft/upf-core'
import { AddFilePropertyReadOption } from '../doc-object-create-panel'

export const steps = [
  {
    title: '选择文件'
  },
  {
    title: '提取文件'
  },
  {
    title: '批量入库'
  }
]

export const components = [
  { step: 0, component: 'KDocSelectFiles' },
  { step: 1, component: 'KDocExtractFiles' },
  { step: 2, component: 'KDocBatchImport' }
]

/** 子组件通用props **/
export const KDocBatchCreateBasePropOptions = {
  fileList: VuePropTypes.array<FileDataInter>().def([]),
  documentType: VuePropTypes.string().def(),
  filePropertyOption: VuePropTypes.createType<AddFilePropertyReadOption>().def(),
  /** 文件夹id **/
  folderId: VuePropTypes.string().def(),
  /** 代理程序是否已开启 **/
  agentOpened: VuePropTypes.bool().def(false)
}

/** 子组件通用emit **/
export const KDocBatchCreateBaseEmitsOptions = {
  nextStep: () => true,
  previousStep: () => true,
  closeDialog: () => true,
  'update:documentType': (value: string) => true,
  'update:filePropertyOption': (value: AddFilePropertyReadOption) => true,
  addFile: (file: FileDataInter) => true,
  removeFile: (file: FileDataInter) => true
}

/** 参数 **/
export const KDocBatchCreatePropOptions = {
  ...BaseViewPropOptions,
  /** 文件夹id **/
  folderId: VuePropTypes.string().def(),
  /** dialog对象 **/
  dialog: VuePropTypes.func<() => KDialogResult>().def()
}

/** 参数类型 **/
export type KDocBatchCreatePropType = ViewPropsTypeExtract<typeof KDocBatchCreatePropOptions>

/** 事件 */
export const KDocBatchCreateEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDocBatchCreateEmitsType = ViewEmitsTypeExtract<typeof KDocBatchCreateEventEmits>

// /* eslint-disable no-async-promise-executor */
// import {
//   commonSrv,
//   EnumCreateDocPageOpenMode,
//   EnumObjCopyMode,
//   EnumQueryObjEnvironment,
//   metaObjClsType,
//   metaSrvConfigSrv,
//   ObjBusinessParam,
//   objBusinessSrv,
//   projectSrv,
//   objRelationSrv,
//   ServiceAttachParams,
//   EnumObjGridRule
// } from '@kmplm/ecol-data-srv'

// import { GlobalException } from '@kmsoft/toolkits'
// import lodash from 'lodash'
// import {
//   commonClientSrv,
//   EnumDialogResult,
//   EnumDialogSize,
//   EnumMainObjPassPropType,
//   KDialog,
//   KObjectCreatePanelLoadDataParams,
//   KObjectCreatePanelSaveEvent,
//   objectClientSrv,
//   ObjectCreateDialogProps,
//   ObjectQueryDialogProps,
//   SuccessCallBack
// } from '@kmplm/ecol-base'

// import * as DataSrv from '@kmplm/ecol-data-srv'
import {
  CommonClientSrv,
  EnumObjGridRule,
  ObjBusinessParam,
  ObjectClientSrv,
  KObjectDynamicCreateDialogParams,
  ObjectSelectDialogParam,
  ServiceAttachParams,
  SuccessCallBack
} from '@kmsoft/ebf-common'
import { PMSCreateObjectResult } from '../components'
import lodash from 'lodash'
import { EnumDialogResult, EnumDialogSize } from '@kmsoft/upf-core'

/**
 * 对象关联客户端服务
 */
class ProjectProposalDocClientSrv {
  /**服务标识 */
  Id = 'Project'
  /**主对象参数 */
  ExtendParam_Proposal = 'Proposal'
  /**生成服务端扩展参数集。
   * @param params 服务端扩展参数。
   * @returns 服务端扩展参数集。*/
  generateParams(param: ObjBusinessParam): ServiceAttachParams {
    return {
      serviceIdentity: this.Id,
      attachParam: { [this.Id + this.ExtendParam_Proposal]: param }
    }
  }

  /**
   * 移除对象关联关系
   */
  removeObjRelation(mainObjClsId: string, mainObjId: string, objParam: Array<ObjBusinessParam>, callBack: SuccessCallBack) {
    // CommonClientSrv.confirmRemoveObj(async () => {
    //   const result = await objRelationSrv.removeRelationObjs({
    //     mainObjClsId: mainObjClsId,
    //     mainObjId: mainObjId!,
    //     relObjs: objParam
    //   })
    //   if (result) callBack()
    // })
  }

  /**
   * 打开创建立项文件对象对话框
   * @param mainObjParam 主对象对象参数
   */
  onOpenCreateProjectProposalDocDialog = async (
    mainObjParam: ObjBusinessParam,
    folderId: string
  ): Promise<PMSCreateObjectResult | undefined> => {
    const dialogProps: KObjectDynamicCreateDialogParams = {
      showObjClsCodes: ['Document'],
      defaultSelectClsCode: 'Document',
      folderId: folderId,
      showObjClsSelector: false /** 文档的可以不用显示对象类选择器 */
    }
    const result = (await ObjectClientSrv.openObjectDynamicCreateDialog(dialogProps)) as any
    return { objBusiness: [{ objClsID: result.data.className, objID: result.data.id }] }
  }

  /**
   * 添加项目立项文档
   * @param objClsID
   * @param objID
   * @param showObjClsId 显示的树节点
   * @param callBack
   */
  addProjectProposalDoc(mainObjParam: ObjBusinessParam, showObjClsId: string): Promise<PMSCreateObjectResult> {
    //1.定义弹出查找对象对话框属性
    //对象类树属性
    return new Promise<PMSCreateObjectResult>((reslove, reject) => {
      const objectClassTreeProps = {
        defaultSelectClsId: showObjClsId,
        // objClassTypes: [
        //   DataSrv.metaObjClsType.DOC,
        //   DataSrv.metaObjClsType.PART,
        //   DataSrv.metaObjClsType.COMMONOBJ,
        //   DataSrv.metaObjClsType.COMMONOBJCUSTOMTABLE
        // ],
        showObjClsIds: showObjClsId ? [showObjClsId] : []
      }
      const objGridRule = { objGridRuleType: EnumObjGridRule.AddDescObj, objGridRuleArgs: { mainObjID: mainObjParam.id } }
      const porps = {
        size: EnumDialogSize.Fat,
        onClosing: async (e): Promise<void> => {
          //3.参数校验
          if (e.dialogResult == EnumDialogResult.Cancel || e.dialogResult == EnumDialogResult.Close) return

          if (e.dialogResult == EnumDialogResult.Apply) {
            e.cancel = true
          }

          const selectedObjParam = (e.viewModel as any).getSelectedObjParams()!
          if (selectedObjParam.length == 0) {
            // CommonClientSrv.alertSelectARow()
            e.cancel = true
            return
          }
          const param = selectedObjParam?.map((m: { modelCode: any; id: any }) => ({ objClsID: m.modelCode, objID: m.id }))
          reslove({ objBusiness: param })
        },
        objectClassManageQueryParam: { ...objectClassTreeProps, ...objGridRule },
        objectSearchQueryParam: { ...objectClassTreeProps, ...objGridRule },
        objectDirectoryManageProps: { ...objGridRule },
        objectSearchProps: { ...objectClassTreeProps, ...objGridRule },
        recentOpenObjectGridProps: { objClsID: showObjClsId, ...objGridRule }
      } as ObjectSelectDialogParam
      //2.弹出查找对象对话框
      ObjectClientSrv.openObjectSelectDialog(porps)
    })
  }

  /**
   * 移除立项文档关联关系
   */
  removeProjectProposalDoc(mainObjParam: ObjBusinessParam, objParam: Array<ObjBusinessParam>, callBack: SuccessCallBack) {
    // CommonClientSrv.confirmRemoveObj(async () => {
    //   const result = await projectSrv.removeProposalObjs({
    //     projectObjParam: mainObjParam,
    //     relObjs: objParam
    //   })
    //   if (result) callBack()
    // })
  }

  /**
   * 删除立项文档
   */
  async deleteProjectProposalDoc(mainObjParam: ObjBusinessParam, objParams: Array<ObjBusinessParam>, callBack: SuccessCallBack) {
    // 获取选中的对象参数
    // 批量删除对象
    let i = 0
    const rowIdList = objParams.map(o => i++)

    // await ObjectClientSrv.deleteBusinessObjBatch(objParams, rowIdList)
    callBack()
  }
}

export const projectProposalDocClientSrv = new ProjectProposalDocClientSrv()

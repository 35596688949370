import { BaseViewModel, KDialog, KFormViewModel, KNotification } from '@kmsoft/upf-core'
import {
  IConfigGridComboboxDefinition,
  KConfigGridComboboxEventEmitsType,
  KConfigGridComboboxViewModelPropType
} from './interface'
import { EnumEditType, EnumTabType, IValueDesc } from '../../../interface'
import { isReqSuccess, showError } from '../../../common/common'
import { ref } from 'vue'
import { Api } from '@kmsoft/ebf-common'

export default class KConfigGridComboboxViewModel extends BaseViewModel<
  KConfigGridComboboxEventEmitsType,
  KConfigGridComboboxViewModelPropType
> {
  refConfigGridComboboxFrom = ref<KFormViewModel<IConfigGridComboboxDefinition>>()

  /** 下拉菜单可选项 */
  selectEditTypeOptions = ref<Array<IValueDesc>>()

  formState = ref<IConfigGridComboboxDefinition>({
    code: '',
    name: '',
    values: [] as Array<string>,
    dataType: '',
    editType: '',
    editParam: '',
    remark: ''
  } as IConfigGridComboboxDefinition)

  private defId: string
  private treeId: string
  /** 初始值, 用于对比是否发生变更 */
  private sourceValue: Array<string>
  /** 初始对象 */
  private row: IConfigGridComboboxDefinition
  private mode: string

  /** 加载完成函数 */
  viewDidMount() {
    this.mode = EnumTabType.DEV
  }

  /**
   * 初始化表单内容
   * @param defId 配置项编码
   * @param treeId 树节点ID
   */
  public init(defId: string, treeId: string) {
    this.defId = defId
    this.treeId = treeId
    this.loadData()
  }

  /**
   * 加载数据
   */
  private loadData(): void {
    const param = {
      data: [
        {
          defId: this.defId as unknown,
          treeId: this.treeId as unknown
        }
      ]
    }
    Api.post('sys', 'ConfigDevService', 'loadConfigDevValue', param).then(response => {
      if (!isReqSuccess(response)) {
        showError(response)
        return
      }
      const value = response.data ?? {}

      const formState = {
        defId: value?.defId as unknown,
        id: value?.id as unknown,
        code: value?.code,
        name: value?.name,
        values: [],
        remark: value?.remark,
        dataType: value?.dataType,
        editType: value?.editType,
        editParam: value?.editParam
      } as IConfigGridComboboxDefinition
      this.row = formState
      this.initSelect()

      this.loadGridData()
    })
  }

  /**
   * 加载值列表
   */
  private loadGridData(): void {
    const param = {
      data: [
        {
          defId: this.defId as unknown,
          treeId: this.treeId as unknown
        }
      ]
    }
    Api.post('sys', 'ConfigDevService', 'loadConfigDevGrid', param).then(response => {
      if (!isReqSuccess(response)) {
        showError(response)
        return
      }
      const values = response.data
      const dList = [] as Array<string>
      if (values != null && values.length > 0) {
        for (const value of values) {
          dList.push(value.value1 as string)
        }
      }

      this.row.values = dList
      this.sourceValue = this.row.values
      this.initSelect()
      this.formState.value = { ...this.row }
    })
  }

  /**
   * 保存
   */
  public save(): void {
    const formState = this.formState.value
    const values = formState?.values
    const saveList = [] as Array<IConfigGridComboboxDefinition>
    if (values != null && values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        const data = {} as any
        ;(data.code = formState?.code), (data.value1 = values[i])
        data.orderId = i + 1
        data.id = null
        saveList.push(data)
      }
    }
    const param = {
      data: [
        {
          treeId: this.treeId as unknown,
          defId: this.defId as unknown,
          code: formState?.code,
          valueList: saveList
        }
      ]
    }
    Api.post('sys', 'ConfigDevService', 'updateConfigDevGrid', param).then(response => {
      if (!isReqSuccess(response)) {
        showError(response)
        return
      }
      // 保存成功后, 刷新初始值
      this.sourceValue = formState?.values ?? []
      KNotification.success({ title: '提示', content: '保存成功' })
    })
  }

  /**
   * 刷新
   */
  public refresh(): void {
    const formState = this.formState.value ?? ({} as IConfigGridComboboxDefinition)

    let changed = false
    if (formState.values?.length !== this.sourceValue.length) {
      changed = true
    }
    if (!changed) {
      for (let i = 0; i < formState.values.length; i++) {
        if (formState.values[i] !== this.sourceValue[i]) {
          changed = true
          break
        }
      }
    }

    if (changed) {
      KDialog.confirm({
        title: '存在数据修改，是继续保存，还是放弃修改？',
        okText: '继续保存',
        cancelText: '放弃修改',
        onCancel: async () => {
          this.loadGridData()
        },
        onOk: async () => {
          this.save()
        }
      })
    }
  }

  /**
   * 初始化[下拉菜单]选项
   */
  private initSelect(): void {
    if (this.row.editType === EnumEditType.COMBOBOX) {
      const json = JSON.parse(this.row.editParam)
      const values = json?.candidate ?? ([] as Array<string>)

      const options = [] as Array<IValueDesc>

      if (values != null && values.length > 0) {
        for (const value of values) {
          options.push({
            label: value,
            value: value
          })
        }
      }
      this.selectEditTypeOptions.value = options
    }
  }
}


import { TreeViewSelectEventArgs, defineView } from '@kmsoft/upf-core'
import { KClassPropertySelectorPropOptions, KClassPropertySelectorEventEmits } from './interface'
import KClassPropertySelectorViewModel from './KClassPropertySelectorViewModel'
import KTreeNodeObjClass from '../object-class-tree/node/KTreeNodeObjClass'

export default defineView({
  name: 'KClassPropertySelector',
  props: KClassPropertySelectorPropOptions,
  emits: KClassPropertySelectorEventEmits,
  viewModel: KClassPropertySelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const afterSelect = (node: TreeViewSelectEventArgs) => {
      if (node.node instanceof KTreeNodeObjClass) {
        vm.setObjClsCode(node.node.id)
      }
    }
    return {
      afterSelect
    }
  }
})

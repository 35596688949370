import { connect, withInstall } from '@kmsoft/upf-core'
import KPartViewFormView from './KPartViewForm.vue'
import KPartViewFormViewModel from './KPartViewFormViewModel'

const KPartViewForm = connect(KPartViewFormView, KPartViewFormViewModel)

export default withInstall(KPartViewForm)
export { KPartViewForm, KPartViewFormView, KPartViewFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KDocBomCompareResultPanelView from './KDocBomCompareResultPanel.vue'
import KDocBomCompareResultPanelViewModel from './KDocBomCompareResultPanelViewModel'

const KDocBomCompareResultPanel = connect(KDocBomCompareResultPanelView, KDocBomCompareResultPanelViewModel)

export default withInstall(KDocBomCompareResultPanel)
export { KDocBomCompareResultPanel, KDocBomCompareResultPanelView, KDocBomCompareResultPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c3ab2fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border1 h100p" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_collapse_panel = _resolveComponent("k-collapse-panel")!
  const _component_k_collapse = _resolveComponent("k-collapse")!

  return (_openBlock(), _createBlock(_component_k_collapse, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
    ghost: "",
    class: _normalizeClass([_ctx.activeKey.length ? 'h100p' : ''])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_collapse_panel, {
        key: "1",
        header: "历史签审",
        class: "h100p"
      }, {
        default: _withCtx(() => [
          _createElementVNode("section", _hoisted_1, [
            _createVNode(_component_k_data_grid, {
              dataSource: _ctx.signingHistoryList,
              "row-key": "processInstanceId",
              isMultipleSelection: false,
              onRowChanged: _ctx.$vm.rowChanged,
              selectFirstRow: _ctx.selectFirstRow
            }, {
              columns: _withCtx(() => [
                _createVNode(_component_k_data_grid_text_box_column, {
                  headerText: "流程名称",
                  dataPropertyName: "processName",
                  align: "center",
                  width: "150"
                }),
                _createVNode(_component_k_data_grid_text_box_column, {
                  headerText: "责任人",
                  dataPropertyName: "assigner",
                  align: "center",
                  width: "auto"
                }),
                _createVNode(_component_k_data_grid_text_box_column, {
                  headerText: "创建时间",
                  dataPropertyName: "createTime",
                  align: "center",
                  width: "120"
                }),
                _createVNode(_component_k_data_grid_text_box_column, {
                  headerText: "开始时间",
                  dataPropertyName: "startTime",
                  align: "center",
                  width: "120"
                }),
                _createVNode(_component_k_data_grid_text_box_column, {
                  headerText: "结束时间",
                  dataPropertyName: "endTime",
                  align: "center",
                  width: "120"
                })
              ]),
              _: 1
            }, 8, ["dataSource", "onRowChanged", "selectFirstRow"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["activeKey", "class"]))
}
import { BaseViewModel, ViewModelOptions, KNotification, AppContext, IIdentity } from '@kmsoft/upf-core'
import { KPartReportBuyEmitsType, KPartReportBuyPropType } from './interface'
import { ref, watch } from 'vue'
import { Api, EnumRequestCode, QueryCondition, ObjBusinessParam } from '@kmsoft/ebf-common'
import dayjs from 'dayjs'
import _ from 'lodash'
import { KPartSelectorViewModel } from '../../../../controls/part-selector'

/** KPartReportBuy */
export default class KPartReportBuyViewModel extends BaseViewModel<KPartReportBuyEmitsType, KPartReportBuyPropType> {
  refPartSelector = ref<KPartSelectorViewModel>()
  objParam = ref<ObjBusinessParam>()

  constructor(options: ViewModelOptions<KPartReportBuyPropType>) {
    super(options)
    this.objParam.value = options.props.objParam

    watch(
      () => this.props.objParam,
      newValue => newValue && this.init(newValue),
      {
        immediate: true
      }
    )
  }

  /**根节点*/
  root = ref<any>()
  /**报表数据*/
  data = ref<any>([])
  /**报表表头信息*/
  info = ref<any>({})

  viewDidMount() {}

  async init(newValue: ObjBusinessParam) {
    const result = await Api.post('part', 'Part', 'get', {
      data: [newValue.id]
    })
    this.root.value = result.data
    this.refPartSelector.value?.setValue(result.data)

    this.loadData()
  }

  changeSelect(value: any) {
    this.root.value = value
  }

  async loadData() {
    this.root.value = this.refPartSelector.value?.getValue()

    if (!this.root.value) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择产品'
      })
      return
    }

    const currentUser = AppContext.current.getIdentity()?.name.split(' ')[0]
    this.info.value = { currentUserName: currentUser, currentDate: dayjs().format('YYYY-MM-DD') }

    const result = (await Api.post('part', 'Part', 'reportPurchasedPartSummary', {
      data: [
        {
          root: { id: this.root.value.id, clazz: this.root.value.rdmExtensionType }
        }
      ]
    })) as any
    if (!(result && result.code == EnumRequestCode.SUCCESS)) {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件详情失败',
        details: result.detail
      })
      return
    }
    this.data.value = result.data as any
  }

  getExtAttrValue(attrs: Array<any>, name: string) {
    const attr = _.find(attrs, attr => attr.name == name)
    return attr ? attr.value : ''
  }
}

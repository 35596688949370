import { Ref, ref, watch } from 'vue'
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KQuickSearchBarEventEmitsType, KQuickSearchBarPropType, QuickSearchForm } from './interface'
import {
  CommonClientSrv,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  QUERY_CONDITION_OPERATOR_COMPARISONS,
  QueryCondition
} from '../../client-srv'

/** KQuickSearchBar 视图模型 */
export default class KQuickSearchBarViewModel extends BaseViewModel<KQuickSearchBarEventEmitsType, KQuickSearchBarPropType> {
  formLabelColStyle = { style: { width: '6em' } }
  /** 列名选项 */
  colNameOptions = ref([
    {
      label: '代号',
      value: CommonClientSrv.SYS_DEF_PROP.SYMBOL
    },
    {
      label: '名称',
      value: CommonClientSrv.SYS_DEF_PROP.NAME
    }
  ])
  /** 操作符选项列表 */
  operatorOptions = ref(
    [EnumQueryConditionOperator.EQUAL, EnumQueryConditionOperator.LIKE, EnumQueryConditionOperator.STARTWITH].map(op => ({
      label: QUERY_CONDITION_OPERATOR_COMPARISONS[op],
      value: op
    }))
  )
  form: Ref<QuickSearchForm>
  constructor(options: ViewModelOptions<KQuickSearchBarPropType>) {
    super(options)

    this.form = ref(this.__getDefaultFormValue())
    watch(
      () => this.props.searchFilter,
      () => {
        this.__clear()
        this.__initSearchOption()
      },
      {
        immediate: true,
        deep: true
      }
    )
  }
  /** 加载完成函数 */
  viewDidMount() {
    this.__clear()
    this.__initSearchOption()
  }

  getAdvancedSearchFilter(): QueryCondition {
    let filterItems: Array<QueryCondition>

    if (!this.form.value || !this.form.value.value || !this.form.value.operator) {
      filterItems = []
    } else {
      const formValueString = CommonClientSrv.objectToString(this.form.value)
      if (formValueString.length !== 0) {
        filterItems = this.form.value.colnameList.map(c => ({
          conditionName: c,
          operator: this.form.value!.operator,
          conditionValues: [CommonClientSrv.objectToString(this.form.value!.value)]
        }))
      } else {
        filterItems = []
      }
    }

    return {
      joiner: EnumQueryConditionJoiner.And,
      conditions: filterItems
    }
  }

  private __getDefaultFormValue(): QuickSearchForm {
    return {
      colnameList: [CommonClientSrv.SYS_DEF_PROP.SYMBOL, CommonClientSrv.SYS_DEF_PROP.NAME],
      operator: EnumQueryConditionOperator.STARTWITH,
      value: ''
    }
  }
  private __clear(): void {
    this.form.value = this.__getDefaultFormValue()
  }

  /** 填充缓存数据 */
  private __initSearchOption(): void {
    if (!this.form.value || !this.props.searchFilter) return

    // this.form.value.colnameList = this.props.searchFilter.rules.map(_ => _.key)
    // const filter = this.props.searchFilter.rules[0]
    // this.form.value.value = ObjectHelper.objectToString(filter.value)
    // this.form.value.operator = filter.operator ?? EnumQueryConditionOperator.STARTWITH
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartMaterialFormView from './KPartMaterialForm.vue'
import KPartMaterialFormViewModel from './KPartMaterialFormViewModel'

const KPartMaterialForm = connect(KPartMaterialFormView, KPartMaterialFormViewModel)

export default withInstall(KPartMaterialForm)
export { KPartMaterialForm, KPartMaterialFormView, KPartMaterialFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartCreateViewVersionFormView from './KPartCreateViewVersionForm.vue'
import KPartCreateViewVersionFormViewModel from './KPartCreateViewVersionFormViewModel'

const KPartCreateViewVersionForm = connect(KPartCreateViewVersionFormView, KPartCreateViewVersionFormViewModel)

export default withInstall(KPartCreateViewVersionForm)
export { KPartCreateViewVersionForm, KPartCreateViewVersionFormView, KPartCreateViewVersionFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

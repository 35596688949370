import {
  KTreeViewPropOptions,
  KTreeViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KClassUiDefinePropOptions = {
  ...KTreeViewPropOptions
}

/** 参数类型 **/
export type KClassUiDefinePropType = ViewPropsTypeExtract<typeof KClassUiDefinePropOptions>

/** 事件 */
export const KClassUiDefineEventEmits = {
  ...KTreeViewEventEmits
}

/** 事件类型 **/
export type KClassUiDefineEmitsType = ViewEmitsTypeExtract<typeof KClassUiDefineEventEmits>


import _ from 'lodash'
import {
  KDialog,
  KDialogClosingEvent,
  KGridColumn,
  KGridColumnGroup,
  KNotification,
  EnumDialogResult,
  KViewSchemaWithId,
  defineView
} from '@kmsoft/upf-core'
import KFilterGridViewConfigForm from './form'
import { KGridColumnDesigner } from '../../../../grid/column-designer/controls'
import { KFilterGridViewConfigEventEmits, KFilterGridViewConfigPropOptions } from './interface'
import { KFilterGridViewConfigViewModel } from './KFilterGridViewConfigViewModel'
import { KFilterGridViewConfigFormViewModel } from './form'

export default defineView({
  inheritAttrs: false,
  name: 'KFilterGridViewGrid',
  emits: KFilterGridViewConfigEventEmits,
  props: KFilterGridViewConfigPropOptions,
  viewModel: KFilterGridViewConfigViewModel,
  setup(props, { vm }) {
    /**
     * 新建视图
     */
    const addView = () => {
      KDialog.show({
        title: '新建视图',
        props: {},
        size: { width: 1000, height: 500 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KFilterGridViewConfigForm,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
          const formViewModel = event.viewModel as KFilterGridViewConfigFormViewModel
          const result = await formViewModel.validate()
          if (!result) {
            event.cancel = true
            return
          }
          const formValue = formViewModel.getValue() as KViewSchemaWithId
          if (__createdValidate2Name(formValue)) {
            KNotification.warning({
              title: '提示',
              content: '已存在相同名称'
            })
            event.cancel = true
            return
          }
          formValue.elements = [] as Array<KGridColumnGroup | KGridColumn>
          vm.refGrid.value?.insertRow(formValue)
        }
      })
    }
    /**
     * 编辑视图
     */
    const editView = (row: KViewSchemaWithId) => {
      KDialog.show({
        title: '编辑视图',
        props: {
          formValue: row
        },
        size: { width: 1000, height: 500 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KFilterGridViewConfigForm,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
          const formViewModel = event.viewModel as KFilterGridViewConfigFormViewModel
          const result = await formViewModel.validate()
          if (!result) {
            event.cancel = true
            return
          }
          const formValue = formViewModel.getValue() as KViewSchemaWithId
          if (__editValidate2Name(formValue)) {
            KNotification.warning({
              title: '提示',
              content: '已存在相同名称'
            })
            event.cancel = true
            return
          }
          vm.refGrid.value?.refreshRow(formValue)
        }
      })
    }
    /**
     * 删除视图
     */
    const deleteView = () => {
      const getSelectedRows = vm.refGrid.value?.getSelectedRows().map(x => x.id)
      vm.refGrid.value?.removeRow(getSelectedRows!)
    }
    /**
     * 打开视图设计弹框
     */
    const showDesignModal = (row: KViewSchemaWithId) => {
      KDialog.show({
        title: '视图设计',
        props: {
          designerMetaControl: props.designerMetaControl,
          dataSource: props.dataSource,
          elements: row.elements || []
        },
        size: { width: 1000, height: 600 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KGridColumnDesigner,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
          // todo: 待KGridColumnConfig改造成viewModel写法后，将以下viewInstance换成viewModel
          const formViewModel = event.viewInstance
          const result = await formViewModel.validate()
          if (!result) {
            event.cancel = true
            return
          }
          const formValue = {
            ...row,
            elements: formViewModel.getValue()
          }
          vm.refGrid.value?.refreshRow(formValue)
        }
      })
    }

    /**
     * 校验是否存在相同名称
     */
    const __createdValidate2Name = (model: KViewSchemaWithId) => {
      const getRows = vm.refGrid.value?.getRows()
      let result = getRows!.find(x => x.name === model.name)
      if (result) return true
    }
    /**
     * 校验是否存在相同名称
     */
    const __editValidate2Name = (model: KViewSchemaWithId) => {
      const getRows = vm.refGrid.value?.getRows()
      let result = getRows!.filter(item => item.id !== model.id).find(x => x.name === model.name)
      if (result) return true
    }
    return {
      addView,
      editView,
      deleteView,
      showDesignModal
    }
  }
})

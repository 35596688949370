import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumObjCopyMode } from '../../../../client-srv/object'
import {
  ObjectCreatePanelAttachParams,
  ObjectCreatePanelLoadDataCallback,
  ObjectCreatePanelSaveCallback,
  ObjectCreateedEvent
} from '../../interface'

/** 参数 **/
export const KObjectCreatePanelFormBasePropOptions = {
  ...BaseViewPropOptions,
  /**
   * 对象类 Code
   * @description 如果不传入对象类Code 则会显示对象类选择
   */
  modelCode: VuePropTypes.string()
    .setRequired()
    .def(),

  /**对象类分组 */
  modelGroup: VuePropTypes.string()
    .setRequired()
    .def(),
  /**
   * 对象Id
   * @description 仅在在另存为，创建大版本，创建小版本场景下才会使用该属性
   */
  objId: VuePropTypes.string().def(),
  /**
   * 主对象id
   * @description 多版本请传入
   */
  masterId: VuePropTypes.string().def(),
  /**
   * 迭代id
   * @description 多版本传入
   */
  iteratorId: VuePropTypes.string().def(),
  /**
   * 创建对象类型
   * @description 创建类型 如新建对象，创建大小版本
   */
  objCopyMode: VuePropTypes.createType<EnumObjCopyMode>().def(EnumObjCopyMode.NewObj),
  /** 对象面板业务对象获取器 */
  loadData: VuePropTypes.func<ObjectCreatePanelLoadDataCallback>().def(),
  /** 自定义保存回调 */
  onSave: VuePropTypes.func<ObjectCreatePanelSaveCallback>().def(),
  /** 新建对象保存后 */
  onSaved: VuePropTypes.func<ObjectCreateedEvent>().def(),
  /** 创建附属参数 */
  attachParams: VuePropTypes.createType<ObjectCreatePanelAttachParams>().def()
}

/** 参数类型 **/
export type KObjectCreatePanelFormBasePropType = ViewPropsTypeExtract<typeof KObjectCreatePanelFormBasePropOptions>

/** 事件 */
export const KObjectCreatePanelFormBaseEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KObjectCreatePanelFormBaseEmitsType = ViewEmitsTypeExtract<typeof KObjectCreatePanelFormBaseEventEmits>

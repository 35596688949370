import { FileDialog } from './FileDialog'

/**显示一个标准对话框，提示用户打开文件。 此类不能被继承。*/
export class OpenFileDialog extends FileDialog {
  protected get CreateParams(): Record<string, any> {
    return { ...super.CreateParams, multiselect: this.Multiselect }
  }

  /**获取或设置一个值，该值指示对话框是否允许选择多个文件。*/
  public Multiselect: boolean = false
}

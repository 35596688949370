import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KProductNeedsCreateEmitsType, KProductNeedsCreatePropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api, EnumWorkingState } from '@kmsoft/ebf-common'
import lodash from 'lodash'

/** KProductNeedsCreate */
export default class KProductNeedsCreateViewModel extends BaseViewModel<
  KProductNeedsCreateEmitsType,
  KProductNeedsCreatePropType
> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  formData = ref<any>({
    number: '',
    name: '',
    description: '',
    category: '',
    secondaryIds: null,
    folder: {
      id: ''
    }
  })

  constructor(options: ViewModelOptions<KProductNeedsCreatePropType>) {
    super(options)
  }

  viewDidMount() {
    // 文件夹赋值
    if (this.props.customerNeeds?.folder?.id) {
      this.formData.value.folder.id = this.props.customerNeeds.folder?.id
    }
  }

  getValue() {
    const createObject = lodash.cloneDeep(this.refObjectCreatePanel.value?.getValue())
    return createObject
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  async save() {
    const createObject = this.getValue()

    // 如果是从原始需求创建而来，添加关联关系
    if (this.props.customerNeeds) {
      createObject!.customerNeeds = 
        {
          id: this.props.customerNeeds.id,
          rdmExtensionType: this.props.customerNeeds.rdmExtensionType,
          clazz: this.props.customerNeeds.rdmExtensionType
        }
    }
    const params = {
      data: [
        {
          ...createObject
        }
      ]
    }
    return await Api.post('requirement', 'ProductNeeds', 'create', params)
  }
}

import {
  BaseViewModel,
  EnumFilterOperator,
  KCondition,
  KDataGridViewModel,
  KFilter,
  KSort,
  KSortModalViewModel,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KFilterGridFilterItemConfigEventEmitsType, KFilterGridFilterItemConfigPropType } from './interface'
import { ref, watch } from 'vue'
import { KFilterWithId } from '../interface'
import { cloneDeep } from 'lodash'

/** KFilterGridFilterItemConfig 视图模型 */
export class KFilterGridFilterItemConfigViewModel extends BaseViewModel<
  KFilterGridFilterItemConfigEventEmitsType,
  KFilterGridFilterItemConfigPropType
> {
  /** 网格引用 */
  refGrid = ref<KDataGridViewModel | null>(null)
  /**已有的筛选条件 */
  filters = ref<Array<KFilterWithId>>([])

  constructor(options: ViewModelOptions<KFilterGridFilterItemConfigPropType>) {
    super(options)

    watch(
      () => options.props.filters,
      newVal => {
        this.filters.value = cloneDeep(newVal)
      },
      {
        deep: true,
        immediate: true
      }
    )

    watch(
      () => this.filters.value,
      newVal => {
        const gridDataSource = this.convert2GridDataSource(this.filters.value)
        this.refGrid.value?.setDataSource(gridDataSource)
      },
      {
        deep: true,
        immediate: true
      }
    )
  }
  /**
   * 加载完成函数
   */
  viewDidMount() {
    const gridDataSource = this.convert2GridDataSource(this.filters.value)
    this.refGrid.value?.setDataSource(gridDataSource)
  }

  /**
   * 获取筛选条件
   */
  getValue() {
    return this.convert2KFilter()
  }

  /**
   * 校验有效性
   * @returns 是否有效
   */
  validate() {
    return new Promise(resolve => {
      resolve(true)
    })
  }

  /**
   * 将筛选器转换为网格列数据
   * @param filters 筛选条件
   */
  private convert2GridDataSource(filters: Array<KFilterWithId>): Array<Record<string, any>> {
    let tempFilters = [] as Array<Record<string, any>>
    filters.forEach(item => {
      let condition = this.convert2Condition(item.conditions)
      condition += this.convert2Sort(item.sorts)
      let params = {
        id: item.id,
        name: item.name,
        condition: condition
      }
      tempFilters.push(params)
    })
    return tempFilters
  }

  /**
   * condition转换为网格dataSource
   * @param conditions 筛选条件
   */
  private convert2Condition(conditions: Array<KCondition>) {
    let condition = ''
    conditions.forEach((item, index) => {
      let and = index + 1 === conditions.length ? '' : ' and'
      /**如果操作符为between */
      if (item.operator === EnumFilterOperator.BETWEEN) {
        condition +=
          ' ' +
          item.key +
          ' between ' +
          (item.value[0] === undefined ? ' ' : item.value[0]) +
          ' and ' +
          (item.value[1] === undefined ? ' ' : item.value[1]) +
          and
      } else {
        condition += ' ' + item.key + ' ' + item.operator + ' ' + item.value + and
      }
    })
    return condition
  }

  /**
   * 转换排序条件格式
   * @param sorts 源排序
   */
  private convert2Sort(sorts: Array<KSort>) {
    let sort = ''
    sorts.forEach((item, index) => {
      let and = index + 1 === sorts.length ? '' : ' ,'
      sort += item.name + ' ' + item.direction + and
    })
    let orderby = ' order by '
    return orderby + sort
  }

  /**
   * 转换KFilter
   */
  private convert2KFilter(): Array<KFilter> {
    return this.filters.value.map(item => {
      return { name: item.name, conditions: item.conditions, sorts: item.sorts }
    })
  }
}

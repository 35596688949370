import { connect, withInstall } from '@kmsoft/upf-core'
import KFolderSelectorView from './KFolderSelector.vue'
import KFolderSelectorViewModel from './KFolderSelectorViewModel'

const KFolderSelector = connect(KFolderSelectorView, KFolderSelectorViewModel)

export default withInstall(KFolderSelector)
export { KFolderSelector, KFolderSelectorView, KFolderSelectorViewModel }

export * from './interface'

// 模板生成文件
// export * from './{{folderName}}'

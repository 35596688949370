
import { defineView } from '@kmsoft/upf-core'
import { KModelBrowserEventEmits, KModelBrowserPropOptions } from './interface'
import KModelBrowserViewModel from './KModelBrowserViewModel'

export default defineView({
  name: 'KModelBrowser',
  props: KModelBrowserPropOptions,
  emits: KModelBrowserEventEmits,
  viewModel: KModelBrowserViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

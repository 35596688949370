
import { defineView } from '@kmsoft/upf-core'
import { KConfigFormItemPropOptions, KConfigFormItemEventEmits } from './interface'
import KConfigFormItemViewModel from './KConfigFormItemViewModel'
import { omit } from 'lodash'

export default defineView({
  name: 'KConfigFormItem',
  props: KConfigFormItemPropOptions,
  emits: KConfigFormItemEventEmits,
  viewModel: KConfigFormItemViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { omit }
  }
})

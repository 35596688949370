import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%","padding":"5px","display":"flex","flex-direction":"column"} }
const _hoisted_2 = { style: {"height":"100%"} }
const _hoisted_3 = { style: {"height":"100%","min-height":"300px"} }
const _hoisted_4 = { style: {"height":"100%"} }
const _hoisted_5 = { style: {"height":"100%"} }
const _hoisted_6 = { style: {"height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_config_grid_table = _resolveComponent("config-grid-table")!
  const _component_config_grid_table_tile = _resolveComponent("config-grid-table-tile")!
  const _component_config_grid_list = _resolveComponent("config-grid-list")!
  const _component_config_grid_combobox = _resolveComponent("config-grid-combobox")!
  const _component_config_grid_combobox_tile = _resolveComponent("config-grid-combobox-tile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_config_grid_table, {
        ref: _ctx.$vm.refSysConfigGridTableView,
        mode: _ctx.mode
      }, null, 8, ["mode"])
    ], 512), [
      [_vShow, _ctx.$vm.tagTable && _ctx.$vm.editable]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_config_grid_table_tile, {
        ref: _ctx.$vm.refSysConfigGridTableTileView,
        mode: _ctx.mode
      }, null, 8, ["mode"])
    ], 512), [
      [_vShow, _ctx.$vm.tagTable && !_ctx.$vm.editable]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createVNode(_component_config_grid_list, {
        ref: _ctx.$vm.refSysConfigGridListView,
        mode: _ctx.mode
      }, null, 8, ["mode"])
    ], 512), [
      [_vShow, _ctx.$vm.tagList && false]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createVNode(_component_config_grid_combobox, {
        ref: _ctx.$vm.refSysConfigGridComboboxView,
        mode: _ctx.mode
      }, null, 8, ["mode"])
    ], 512), [
      [_vShow, _ctx.$vm.tagCombobox && _ctx.$vm.editable]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createVNode(_component_config_grid_combobox_tile, {
        ref: _ctx.$vm.refSysConfigGridComboboxTileView,
        mode: _ctx.mode
      }, null, 8, ["mode"])
    ], 512), [
      [_vShow, _ctx.$vm.tagCombobox && !_ctx.$vm.editable]
    ])
  ]))
}
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KViewRenderElementWrapEmitsType, KViewRenderElementWrapPropType } from './interface'

/** KViewRenderElementWrap */
export default class KViewRenderElementWrapViewModel extends BaseViewModel<KViewRenderElementWrapEmitsType, KViewRenderElementWrapPropType> {
  constructor(options: ViewModelOptions<KViewRenderElementWrapPropType>) {
    super(options)
  }

  viewDidMount() {}
}

import { BaseViewModel } from '@kmsoft/upf-core'
import { KDevTabEventEmitsType } from './interface'
import KDevConfigTreeViewModel from '../dev-config-tree/KDevConfigTreeViewModel'
import KConfigValueViewModel from '../dev-config-value/KConfigValueViewModel'
import { EnumNodeType, EnumConfigTabType, EnumTabType, EnumDataType } from '../../interface'
import KConfigGridViewModel from '../dev-config-grid/KConfigGridViewModel'
import KConfigCustomViewModel from '../../common/config-custom/KConfigCustomViewModel'
import KConfigTileViewModel from '../dev-config-tile/KConfigTileViewModel'
import { RefreshConfigValuePanelEventArgs } from '../../sys/sys-config-tree/interface'
import { ref } from 'vue'

export default class KDevTabViewModel extends BaseViewModel<KDevTabEventEmitsType, KDevTabEventEmitsType> {
  // 系统配置树
  refDevConfigTreeView = ref<KDevConfigTreeViewModel>()
  // 配置项单值维护
  refDevConfigValueView = ref<KConfigValueViewModel>()
  // 配置项集合维护
  refDevConfigGridView = ref<KConfigGridViewModel>()
  // 配置项自定义维护
  refDevConfigCustomView = ref<KConfigCustomViewModel>()
  // 配置项平铺查看
  refDevConfigTileView = ref<KConfigTileViewModel>()

  /** 明细显示标记(1表示单值配置项, 2表示集合配置项) */
  detailTag = ref('0')
  mode = ref('')
  tagValue = ref<boolean>(false)
  tagGrid = ref<boolean>(false)
  tagCustom = ref<boolean>(false)
  tagTile = ref<boolean>(false)

  /**
   * 加载完成函数
   */
  viewDidMount() {
    this.mode.value = EnumTabType.DEV
  }

  /**
   * 树节点点击事件
   * @param args 参数(树节点对象)
   */
  public refreshConfigValuePanel(args: RefreshConfigValuePanelEventArgs) {
    const node = args.node
    // 配置项
    if (node.type === EnumNodeType.DEV_CONFIG) {
      // 单值
      if (node.dataType === EnumDataType.VALUE) {
        this.detailTag.value = EnumConfigTabType.VALUE
        this.tagValue.value = true
        this.tagGrid.value = false
        this.tagCustom.value = false
        this.tagTile.value = false

        this.refDevConfigValueView.value?.init(node.defId, node.id)
      }
      // 集合
      if (node.dataType === EnumDataType.GRID) {
        this.detailTag.value = EnumConfigTabType.GRID
        this.tagValue.value = false
        this.tagGrid.value = true
        this.tagCustom.value = false
        this.tagTile.value = false
        this.refDevConfigGridView.value?.init(node.defId, node.id, node.code, node.editType, node.editParam, 'edit')
      }
      // 自定义
      if (node.dataType === EnumDataType.CUSTOM) {
        this.detailTag.value = EnumConfigTabType.CUSTOM
        this.tagValue.value = false
        this.tagGrid.value = false
        this.tagCustom.value = true
        this.tagTile.value = false
        this.refDevConfigCustomView.value?.init(node.defId, node.id, node.code, node.editType, node.editParam)
      }
    } else if (node.type === EnumNodeType.DEV_FOLDER) {
      // 模块/目录
      this.detailTag.value = EnumConfigTabType.TILE
      this.tagValue.value = false
      this.tagGrid.value = false
      this.tagCustom.value = false
      this.tagTile.value = true
      this.refDevConfigTileView.value?.init(node.id, node.code)
    } else {
      // 其它(如: 根节点)
      this.detailTag.value = ''
      this.tagValue.value = false
      this.tagGrid.value = false
      this.tagCustom.value = false
      this.tagTile.value = false
    }
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%","padding":"5px","display":"flex","flex-direction":"column"} }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = { style: {"flex":"1"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_checkbox_group = _resolveComponent("k-checkbox-group")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_tool_strip, { name: "objClsToolStrip" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_tool_strip_button_item, {
            name: "btnSave",
            title: "保存",
            onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.save())
          }),
          _createVNode(_component_k_tool_strip_button_item, {
            name: "btnRefresh",
            title: "刷新",
            onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.refresh())
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_k_form, {
        ref: _ctx.$vm.refConfigGridComboboxFrom,
        "label-col": { span: 5 },
        "wrapper-col": { span: 16 },
        model: _ctx.$vm.formState
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_form_item, {
            label: "编码",
            name: "code",
            required: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_input, {
                value: _ctx.$vm.formState.code,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.formState.code) = $event)),
                maxlength: 40,
                disabled: true
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_k_form_item, {
            label: "名称",
            name: "name",
            required: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_input, {
                value: _ctx.$vm.formState.name,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.formState.name) = $event)),
                maxlength: 40,
                disabled: true
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_k_form_item, {
            label: "配置值",
            name: "value",
            required: false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_checkbox_group, {
                value: _ctx.$vm.formState.values,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.$vm.formState.values) = $event)),
                options: _ctx.$vm.selectEditTypeOptions
              }, null, 8, ["value", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_k_form_item, {
            label: "备注",
            name: "remark",
            required: false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_textarea, {
                value: _ctx.$vm.formState.remark,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$vm.formState.remark) = $event)),
                maxlength: 170,
                rows: 10,
                disabled: true
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ]))
}
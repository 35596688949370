import { FileAccess } from './FileAccess'
import { FileMode } from './FileMode'
import { FileOptions } from './FileOptions'
import { FileShare } from './FileShare'
import { Stream } from './Stream'

/**为文件提供 {@link Stream}，既支持同步读写操作，也支持异步读写操作。*/
export class FileStream extends Stream {
  protected static type: string = 'System.IO.FileStream,System.Runtime'

  /**用指定的路径和创建模式初始化 {@link FileStream} 类的新实例。
   * @param path 当前 {@link FileStream} 对象将封装的文件的相对路径或绝对路径。
   * @param mode 用于确定文件的打开或创建方式的枚举值之一。
   * @returns 一个 {@link FileStream} 对象。*/
  public static async create(path: string, mode: FileMode): Promise<FileStream>
  /**用指定的路径、创建模式和读/写权限初始化 {@link FileStream} 类的新实例。
   * @param path 当前 {@link FileStream} 对象将封装的文件的相对路径或绝对路径。
   * @param mode 用于确定文件的打开或创建方式的枚举值之一。
   * @param access 枚举值的按位组合，这些枚举值确定 {@link FileStream} 对象访问文件的方式。 该常数还可以确定由 {@link FileStream} 对象的 {@link CanRead} 和 {@link CanWrite} 属性返回的值。 如果 {@link path} 指定磁盘文件，则 {@link CanSeek} 为 true。
   * @returns 一个 {@link FileStream} 对象。*/
  public static async create(path: string, mode: FileMode, access: FileAccess): Promise<FileStream>
  /**用指定的路径、创建模式、读/写权限和共享权限初始化 {@link FileStream} 类的新实例。
   * @param path 当前 {@link FileStream} 对象将封装的文件的相对路径或绝对路径。
   * @param mode 用于确定文件的打开或创建方式的枚举值之一。
   * @param access 枚举值的按位组合，这些枚举值确定 {@link FileStream} 对象访问文件的方式。 该常数还可以确定由 {@link FileStream} 对象的 {@link CanRead} 和 {@link CanWrite} 属性返回的值。 如果 {@link path} 指定磁盘文件，则 {@link CanSeek} 为 true。
   * @param share 枚举值的按位组合，这些枚举值确定进程共享文件的方式。
   * @returns 一个 {@link FileStream} 对象。*/
  public static async create(path: string, mode: FileMode, access: FileAccess, share: FileShare): Promise<FileStream>
  /**用指定的路径、创建模式、读/写及共享权限和缓冲区大小初始化 {@link FileStream} 类的新实例。
   * @param path 当前 {@link FileStream} 对象将封装的文件的相对路径或绝对路径。
   * @param mode 用于确定文件的打开或创建方式的枚举值之一。
   * @param access 枚举值的按位组合，这些枚举值确定 {@link FileStream} 对象访问文件的方式。 该常数还可以确定由 {@link FileStream} 对象的 {@link CanRead} 和 {@link CanWrite} 属性返回的值。 如果 {@link path} 指定磁盘文件，则 {@link CanSeek} 为 true。
   * @param share 枚举值的按位组合，这些枚举值确定进程共享文件的方式。
   * @param bufferSize 一个大于零的正 Int32 值，表示缓冲区大小。 默认缓冲区大小为 4096。
   * @returns 一个 {@link FileStream} 对象。*/
  public static async create(
    path: string,
    mode: FileMode,
    access: FileAccess,
    share: FileShare,
    bufferSize: number
  ): Promise<FileStream>
  /**使用指定的路径、创建模式、读/写和共享权限、其他 {@link FileStream} 可以具有的对此文件的访问权限、缓冲区大小和附加文件选项初始化 {@link FileStream} 类的新实例。
   * @param path 当前 {@link FileStream} 对象将封装的文件的相对路径或绝对路径。
   * @param mode 用于确定文件的打开或创建方式的枚举值之一。
   * @param access 枚举值的按位组合，这些枚举值确定 {@link FileStream} 对象访问文件的方式。 该常数还可以确定由 {@link FileStream} 对象的 {@link CanRead} 和 {@link CanWrite} 属性返回的值。 如果 {@link path} 指定磁盘文件，则 {@link CanSeek} 为 true。
   * @param share 枚举值的按位组合，这些枚举值确定进程共享文件的方式。
   * @param bufferSize 一个大于零的正 Int32 值，表示缓冲区大小。 默认缓冲区大小为 4096。
   * @param options 枚举值的按位组合，它用于指定其他文件选项。
   * @returns 一个 {@link FileStream} 对象。*/
  public static async create(
    path: string,
    mode: FileMode,
    access: FileAccess,
    share: FileShare,
    bufferSize: number,
    options: FileOptions
  ): Promise<FileStream>
  public static async create(
    path: string,
    mode: FileMode,
    access?: FileAccess,
    share?: FileShare,
    bufferSize?: number,
    options?: FileOptions
  ): Promise<FileStream> {
    if (!access) return this.createInstanceAndWrap(path, mode)
    else if (!share) return this.createInstanceAndWrap(path, mode, access)
    else if (!bufferSize) return this.createInstanceAndWrap(path, mode, access, share)
    else if (!options) return this.createInstanceAndWrap(path, mode, access, share, bufferSize)
    else return this.createInstanceAndWrap(path, mode, access, share, bufferSize, options)
  }
}

import { ConfigClientSrv } from '../base'
import { LoginClientSrv } from '../user'

export class RequestHelper {
  /**
   * post请求
   * @param api
   * @param params
   * @returns
   */
  static post<TData = Record<string, any>>(api: string, params?: any): TData {
    /** 请求服务器URL */
    const baseUrl = ConfigClientSrv.baseApiUrl + (ConfigClientSrv.baseApiUrl.endsWith('/') ? '' : '/')
    /** 接口路径 */
    const apiUrl = baseUrl + api
    /** 创建请求 */
    const xhr = new XMLHttpRequest()
    /** 用户token */
    const userToken = LoginClientSrv.getUserIdentity()?.token

    // 设置URL
    xhr.open('post', apiUrl, false)
    // 设置请求内容为json
    xhr.setRequestHeader('Content-Type', 'application/json')

    // 如果当前用户已登录 则设置用户token
    if (userToken) {
      xhr.setRequestHeader('Authorization', `Bearer ${userToken}`)
    }

    // 开始请求
    xhr.send(JSON.stringify(params))

    /** 结果 */
    const result = JSON.parse(xhr.responseText).data
    return result
  }

  /**
   * get 请求
   * @param api
   * @param params
   * @returns
   */
  static get<TData = Record<string, any>>(api: string, params?: any): TData {
    /** 请求服务器URL */
    const baseUrl = ConfigClientSrv.baseApiUrl + (ConfigClientSrv.baseApiUrl.endsWith('/') ? '' : '/')
    /** 接口路径 */
    const apiUrl = baseUrl + api
    /** 创建请求 */
    const xhr = new XMLHttpRequest()
    /** 用户token */
    const userToken = LoginClientSrv.getUserIdentity()?.token
    /** 参数 */
    let paramStr = ''

    if (params) {
      /** 参数 */
      const paramArrays = []
      for (const key in params) {
        paramArrays.push(`${key}=${params[key]}`)
      }
      if (paramArrays.length > 0) {
        paramStr = `?${paramArrays.join('&')}`
      }
    }

    // 设置URL
    xhr.open('post', `${apiUrl}${paramStr}`, false)
    // 设置请求内容为json
    xhr.setRequestHeader('Content-Type', 'application/json')

    // 如果当前用户已登录 则设置用户token
    if (userToken) {
      xhr.setRequestHeader('Authorization', `Bearer ${userToken}`)
    }

    // 开始请求
    xhr.send()

    /** 结果 */
    const result = JSON.parse(xhr.responseText).data
    return result
  }
}

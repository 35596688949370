import { allDataTypeConfig, allEditorTypeConfig, EnumClsDataType, EnumClsEditorType } from '../enums'

export class EditorTypeBuilder {
  /** 当前的数据类型 */
  private dateType: EnumClsDataType
  /** 可选的编辑类型 */
  private optionalEditorTypeList: Array<{ label: string; value: EnumClsEditorType }> = []

  constructor(dataType: EnumClsDataType) {
    this.dateType = dataType
  }

  /**
   * 获取数据类型对应的编辑类型列表
   * @returns
   */
  getEditorTypeList() {
    switch (this.dateType) {
      case EnumClsDataType.INTEGER:
        this.optionalEditorTypeList = [
          allEditorTypeConfig[EnumClsEditorType.SINGLE_LINE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_EDITABLE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_VALUED_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.LIST_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.LIST_SINGLE_EDITOR],
          // allEditorTypeConfig[EnumClsEditorType.RESOURCE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.CHECKBOX_EDITOR],
          // allEditorTypeConfig[EnumClsEditorType.DIALOG_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.CUSTOM_EDITOR]
          // allEditorTypeConfig[EnumClsEditorType.COMPUTED_EDITOR],
          // allEditorTypeConfig[EnumClsEditorType.FORMULA_EDITOR]
        ]
        break
      case EnumClsDataType.FLOAT:
        this.optionalEditorTypeList = [
          allEditorTypeConfig[EnumClsEditorType.SINGLE_LINE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_EDITABLE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_VALUED_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.LIST_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.LIST_SINGLE_EDITOR],
          // allEditorTypeConfig[EnumClsEditorType.RESOURCE_EDITOR],
          // allEditorTypeConfig[EnumClsEditorType.PROGRESS_EDITOR],
          // allEditorTypeConfig[EnumClsEditorType.DIALOG_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.CUSTOM_EDITOR]
          // allEditorTypeConfig[EnumClsEditorType.COMPUTED_EDITOR],
          // allEditorTypeConfig[EnumClsEditorType.FORMULA_EDITOR]
        ]
        break
      case EnumClsDataType.LONG_INTEGER:
        this.optionalEditorTypeList = [
          allEditorTypeConfig[EnumClsEditorType.SINGLE_LINE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_EDITABLE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_VALUED_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.LIST_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.LIST_SINGLE_EDITOR],
          // allEditorTypeConfig[EnumClsEditorType.RESOURCE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.CHECKBOX_EDITOR],
          // allEditorTypeConfig[EnumClsEditorType.DIALOG_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.CUSTOM_EDITOR]
          // allEditorTypeConfig[EnumClsEditorType.COMPUTED_EDITOR],
          // allEditorTypeConfig[EnumClsEditorType.FORMULA_EDITOR]
        ]
        break
      case EnumClsDataType.DATE:
        this.optionalEditorTypeList = [
          allEditorTypeConfig[EnumClsEditorType.DATE_EDITOR]
          // allEditorTypeConfig[EnumClsEditorType.RICH_TEXT_EDITOR]
        ]
        break
      case EnumClsDataType.CHARACTER:
        this.optionalEditorTypeList = [
          allEditorTypeConfig[EnumClsEditorType.SINGLE_LINE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.MULTI_LINE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_EDITABLE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_VALUED_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.LIST_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.LIST_SINGLE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.LIFECYCLE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.FILED_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.CUSTOM_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.FILED_UPLOAD_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.ENCODERS_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.FILED_UPLOAD_AGENT_EDITOR]
        ]
        break
      case EnumClsDataType.OBJ:
        this.optionalEditorTypeList = [
          allEditorTypeConfig[EnumClsEditorType.SELECT_VALUED_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.FILED_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.OBJ_SELECTOR],
          allEditorTypeConfig[EnumClsEditorType.LIFECYCLE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.FILED_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.CUSTOM_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.FILED_UPLOAD_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.ENCODERS_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.VIEW_SELECTOR]
        ]
        break
      case EnumClsDataType.ENUMS:
        this.optionalEditorTypeList = [
          allEditorTypeConfig[EnumClsEditorType.SELECT_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_EDITABLE_EDITOR],
          allEditorTypeConfig[EnumClsEditorType.SELECT_VALUED_EDITOR]
        ]
        break
      case EnumClsDataType.USER:
        this.optionalEditorTypeList = [allEditorTypeConfig[EnumClsEditorType.USER_EDITOR]]
        break
      case EnumClsDataType.FILE:
        this.optionalEditorTypeList = [allEditorTypeConfig[EnumClsEditorType.FILED_EDITOR]]
        break
      default:
        break
    }

    return this.optionalEditorTypeList
  }

  /**
   * 获取编辑类型对应的自定义参数组件
   */
}

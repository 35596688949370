import { BaseViewModel, KDataGridViewModel, KNotification, SelectOption, ViewModelOptions } from '@kmsoft/upf-core'
import { KPartCollectFormEmitsType, KPartCollectFormPropType, WorkingStateEnum } from './interface'
import { ref } from 'vue'
import { EnumLifecycleState } from '@kmsoft/ebf-common'

/** KpartCollectForm */
export default class KPartCollectFormViewModel extends BaseViewModel<KPartCollectFormEmitsType, KPartCollectFormPropType> {
  /**生命周期候选值 */
  lifecycleStateOptions = ref<Array<SelectOption>>(EnumLifecycleState._list)
  /**工作状态候选值 */
  workingStateOptions = ref<Array<SelectOption>>(WorkingStateEnum)

  /**网格 */
  refPartCollectGrid = ref<KDataGridViewModel>()

  constructor(options: ViewModelOptions<KPartCollectFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 网格数据加载
   */
  loadData() {
    const parts = this.props.parts
    return new Promise((resolve, reject) => {
      if (parts && parts.length != 0) {
        resolve({
          rows: parts,
          total: parts.length
        })
      } else {
        reject([])
      }
    })
  }

  /**
   * 刷新网格
   */
  refresh() {
    this.refPartCollectGrid.value?.refresh()
  }

  /**
   * 导出excel
   */
  exportAsExcel() {
    const params = {
      columnKeys: this.refPartCollectGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id)
    }
    this.refPartCollectGrid.value?.exportDataAsExcel(params)
  }

    /**
   * 导出选中
   */
    exportSelectAsExcel() {
      const data = this.refPartCollectGrid.value?.getSelectedRows()
      if (data && data.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请先选择要导出的数据'
        })
        return
      }
      const params = {
        onlySelected: true,
        columnKeys: this.refPartCollectGrid.value
          ?.getColumnDefs()
          .filter(x => x.id !== 'action')
          .map((x: any) => x.id)
      }
      this.refPartCollectGrid.value?.exportDataAsExcel(params)
    }
}

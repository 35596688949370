import { connect, withInstall } from '@kmsoft/upf-core'
import KDocDemoView from './KDocDemo.vue'
import KDocDemoViewModel from './KDocDemoViewModel'

const KDocDemo = connect(KDocDemoView, KDocDemoViewModel)

export default withInstall(KDocDemo)
export { KDocDemo, KDocDemoView, KDocDemoViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewModel,
  EnumItemChangeCancelType,
  KNotification,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import {
  Api,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPropertyEditPanelViewModel,
  ObjBusinessParam
} from '@kmsoft/ebf-common'
import { KMetricsEditPanelEmitsType, KMetricsEditPanelPropType } from './interface'
import { get } from 'lodash'
import { ref, watch } from 'vue'

/** KMetricsEditPanel */
export default class KMetricsEditPanelViewModel extends BaseViewModel<KMetricsEditPanelEmitsType, KMetricsEditPanelPropType> {
  /** 属性 */
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  originFormData = ref<any>()
  taskParam = ref<ObjBusinessParam>()

  categorySelectorViewModel = ref<any>(null)
  categoryRenderViewModel = ref<any>(null)
  attrsCategoryRenderViewModel = ref<any>(null)
  refPartPanel = ref<HTMLElement>()
  constructor(options: ViewModelOptions<KMetricsEditPanelPropType>) {
    super(options)
    // this.toolStripItems.value.push(ToolStripHelper.getObjectLifeToolStrip())

    watch(
      () => this.originFormData.value,
      newValue => {
        if (newValue) {
          const workState = newValue['workingState']
          const latest = newValue['latest']
        }
      },
      {
        immediate: true,
        deep: true
      }
    )

    watch(
      () => this.props.objParam,
      () => {
        this.taskParam.value = this.props.objParam
      },
      {
        immediate: true,
        deep: true
      }
    )
  }

  viewDidMount() {}

  async loadData() {
    const result = (await Api.post('requirement', 'ValidationMetrics', 'get', { data: [this.props.objParam.id] })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.originFormData.value = { ...result.data }

      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取指标失败',
        details: result.detail
      })
      return
    }
  }

  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    if (!this.refObjectProperty.value?.props.objParam.id) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    console.log('onToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.update(event)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refObjectProperty.value?.refresh()
        break
      default:
        break
    }
  }

  async onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    console.log('toolStripItemChange', event)

    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY:
        if (event.value) {
          this.refObjectProperty.value?.setReadOnly(false)
        } else {
          if (await this.doEdit(event)) {
            this.refObjectProperty.value?.setReadOnly(true)
            event.itemCancelType = EnumItemChangeCancelType.CancelAll
          }
        }
        break
      default:
        break
    }
  }

  async doEdit(event: ToolStripItemChangeEventArgs) {
    if (await this.validate()) {
      const value = this.refObjectProperty.value?.getModifiedValue()! as any
      if (Object.keys(value).length) {
        const objParam = this.refObjectProperty.value?.props.objParam
        const reqParam = { ...value, id: objParam?.id }

        if (reqParam.secondary) {
          reqParam.secondary = reqParam.secondary.map((element: string) => {
            return { id: element }
          })
        }

        const partResult = (await Api.post('requirement', 'ValidationMetrics', 'get', { data: [objParam?.id] })) as any
        if (partResult && partResult.code == EnumRequestCode.SUCCESS) {
          // 工作副本才能更新
          console.log('doEdit', reqParam)
          const result = await Api.post('requirement', 'ValidationMetrics', 'batchUpdate', { data: [[reqParam]] })
          if (result && result.code == EnumRequestCode.SUCCESS) {
            this.refObjectProperty.value?.refresh()
            this.refObjectProperty.value?.setReadOnly(true)
            KNotification.success('操作成功！')
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message,
              details: result.detail
            })
            return false
          }
        } else {
          KNotification.error({
            title: '指标属性获取失败',
            content: partResult.message,
            details: partResult.detail
          })
          return false
        }
      } else {
        KNotification.warn({
          message: '系统提示',
          description: '没有修改数据'
        })
        return true
      }
    } else {
      return false
    }
  }

  async update(event: ToolStripItemClickedEventArgs) {
    if (await this.validate()) {
      const value = this.refObjectProperty.value?.getModifiedValue()! as any
      if (Object.keys(value).length) {
        const objParam = this.refObjectProperty.value?.props.objParam
        const reqParam = { ...value, id: objParam?.id }
        if (reqParam.secondary) {
          reqParam.secondary = reqParam.secondary.map((element: string) => {
            return { id: element }
          })
        }

        const result = await Api.post('requirement', 'ValidationMetrics', 'batchUpdate', { data: [[reqParam]] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refObjectProperty.value?.refresh()
          KNotification.success('操作成功！')
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message,
            details: result.detail
          })
        }
      } else {
        KNotification.warn({
          message: '系统提示',
          description: '没有修改数据'
        })
      }
    }
  }

  /** 校验 */
  async validate() {
    try {
      return await this.refObjectProperty.value?.validate()
    } catch (error) {
      console.log('验证错误', error)
      return false
    }
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KProductNeedsEditPanelView from './KProductNeedsEditPanel.vue'
import KProductNeedsEditPanelViewModel from './KProductNeedsEditPanelViewModel'

const KProductNeedsEditPanel = connect(KProductNeedsEditPanelView, KProductNeedsEditPanelViewModel)

export default withInstall(KProductNeedsEditPanel)
export { KProductNeedsEditPanel, KProductNeedsEditPanelView, KProductNeedsEditPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect } from '@kmsoft/upf-core'
import KGridColumnConfigView from './KGridColumnConfig.vue'
import KGridColumnConfigViewModel from './KGridColumnConfigViewModel'
import { App } from 'vue'
import './style/index.less'

const KGridColumnConfig = connect(KGridColumnConfigView, KGridColumnConfigViewModel)

KGridColumnConfig.install = function(app: App) {
  if (app.config.globalProperties.__KGridColumnConfig__) return
  app.config.globalProperties.__KGridColumnConfig__ = true
  app.component('KGridColumnConfig', KGridColumnConfig)
}

export default KGridColumnConfig as typeof KGridColumnConfig & Plugin
export { KGridColumnConfig }

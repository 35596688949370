import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeRequestCreateView from './KChangeRequestCreate.vue'
import KChangeRequestCreateViewModel from './KChangeRequestCreateViewModel'

const KChangeRequestCreate = connect(KChangeRequestCreateView, KChangeRequestCreateViewModel)

export default withInstall(KChangeRequestCreate)
export { KChangeRequestCreate, KChangeRequestCreateView, KChangeRequestCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { Component } from 'vue'
import ExtendLayout from './layout'
import { AbstractSkeleton, AppContext } from '@kmsoft/upf-core'

/**
 * 常规的骨架
 */
export class ExtendSkeleton extends AbstractSkeleton {
  private applicationContext: AppContext
  /**
   * 构造函数
   * @param applicationContext 应用程序上下文
   * @param navigationMenus 导航菜单
   * @param applets 挂件
   */
  constructor() {
    // 添加用户菜单
    super()
    this.applicationContext = AppContext.current
  }

  /**
   * 获取骨架组件
   * @returns 组件定义
   */
  getOriginalComponent(): Component {
    return ExtendLayout
  }
}

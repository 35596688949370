import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeRankingView from './KChangeRanking.vue'
import KChangeRankingViewModel from './KChangeRankingViewModel'

const KChangeRanking = connect(KChangeRankingView, KChangeRankingViewModel)

export default withInstall(KChangeRanking)
export { KChangeRanking, KChangeRankingView, KChangeRankingViewModel }

// 模板生成文件
// export * from './{{folderName}}'

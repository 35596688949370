
import { defineView } from '@kmsoft/upf-core'
import { KFlowFormPropOptions, KFlowFormEventEmits } from './interface'
import KFlowFormViewModel from './KFlowFormViewModel'

export default defineView({
  name: 'KFlowForm',
  props: KFlowFormPropOptions,
  emits: KFlowFormEventEmits,
  viewModel: KFlowFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_property_panel = _resolveComponent("k-object-property-panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_object_property_panel, {
      ref: "refObjectCreatePanel",
      modelCode: "PromoteActivity",
      modelGroup: "change",
      schemaType: "new",
      extendedAttributes: "",
      extendedPosition: 2,
      loadData: () => _ctx.$vm.formData,
      onLoaded: _cache[0] || (_cache[0] = () => _ctx.$vm.loaded())
    }, null, 8, ["loadData"])
  ]))
}
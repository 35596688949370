
import { defineView } from '@kmsoft/upf-core'
import { KConfigGridListViewModelPropOptions } from './interface'
import KConfigGridListViewModel from './KConfigGridListViewModel'

export default defineView({
  name: 'ConfigGridList',
  props: KConfigGridListViewModelPropOptions,
  viewModel: KConfigGridListViewModel,
  setup() {
    return {}
  }
})

import { getCandidates, EnumPropsConfigItemName } from '../../../../config'
import { KDesignerControl } from '../../../../types'
import { EnumAttributeType, EnumControlElementType, EnumDataType, EnumGridElementType, KControlAttribute } from '@kmsoft/upf-core'
import { COLUMN_BASE_ATTRS } from '../config'

/**将工具箱控件转换成网格所需要的基础列 */
export const toKDataGridColumns = (item: KDesignerControl) => {
  /** 基本参数 */
  const control: KDesignerControl = {
    name: item.name,
    title: item.title,
    control: 'KDataGridTextBoxColumn',
    type: EnumGridElementType.COLUMN as any,
    dataType: '',
    attrs: [...COLUMN_BASE_ATTRS],
    events: [],
    icon: item.icon,
    controlType: item.controlType || '基础属性'
  }
  switch (item.control) {
    case EnumControlElementType.INPUT: {
      control.control = 'KDataGridTextBoxColumn'
      break
    }
    case EnumControlElementType.TEXT_AREA: {
      control.control = 'KDataGridTextAreaColumn'
      break
    }
    case EnumControlElementType.CHECKBOX: {
      control.control = 'KDataGridCheckBoxColumn'
      break
    }
    case EnumControlElementType.COMBOBOX: {
      control.control = 'KDataGridComboBoxColumn'
      const option = item.attrs.find(x => x.name === 'options')
      if (option) {
        control.attrs.push(option)
      }
      break
    }
    case EnumControlElementType.DATE_TIME: {
      const format = {
        name: 'format',
        title: '日期格式',
        control: 'KSelect',
        options: getCandidates(['YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss']),
        defaultValue: 'YYYY-MM-DD HH:mm:ss',
        dataType: EnumDataType.STRING,
        attributeType: EnumAttributeType.PROPS,
        disabled: true
      } as KControlAttribute
      control.control = 'KDataGridDateTimeColumn'
      control.attrs.push(format)
      break
    }
    case EnumControlElementType.NUMBER: {
      control.control = 'KDataGridNumberColumn'
      break
    }
    case EnumControlElementType.GROUP_COLUMN: {
      control.control = 'KDataGridColumnGroup'
      control.type = EnumGridElementType.GROUP
      break
    }
    case EnumControlElementType.EXPRESSION: {
      control.control = 'KDataGridComputeColumn'
      control.type = EnumGridElementType.COMPUTE
      break
    }
    default: {
      control.control = 'custom'
      // const propsAttrs = item.attrs.filter(attr => attr.attributeType == EnumAttributeType.PROPS)
      // control.attrs.push(...propsAttrs)
      break
    }
  }

  //#region 补充属性
  /** 字段属性 */
  // const field = item.attrs.find(x => x.name === 'field')

  // if (field) {
  //   control.attrs.push(field)
  // }
  //#endregion

  return control
}

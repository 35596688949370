
import { EnumDataGridRowModelType, EnumDividerType, KDataGridRowSelectedEvent, defineView } from '@kmsoft/upf-core'
import KObjectClassLayoutDefinitionViewModel from './KObjectClassLayoutDefinitionViewModel'
import { KObjectClassLayoutDefinitionEventEmits, KObjectClassLayoutDefinitionPropOptions } from './interface'

export default defineView({
  name: 'KObjectClassLayoutDefinition',
  props: KObjectClassLayoutDefinitionPropOptions,
  emits: KObjectClassLayoutDefinitionEventEmits,
  viewModel: KObjectClassLayoutDefinitionViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      /** 属性布局类型候选值 */
      objectClassLayoutTypeOptions: [
        { label: '新建', value: 'new' },
        { label: '编辑', value: 'edit' },
        { label: '网格', value: 'grid' },
        { label: '列表', value: 'list' }
      ],
      vertical: EnumDividerType.VERTICAL
    }
  }
})

import { ValueChangeEventArgs, VuePropTypes } from '@kmsoft/upf-core'
import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'
import { ControlCollection, KDataElementTree, KDesignerControl } from '../../../../types'

/** KFilterGridSearchBarDesigner 事件 */
export const KFilterGridSearchBarDesignerEventEmits = {
  ...BaseViewEventEmits,
  /** 值改变前事件 */
  beforeChange: (event: ValueChangeEventArgs) => true,
  /** 值改变后事件 */
  change: (event: ValueChangeEventArgs) => true
}

/** KFilterGridSearchBarDesigner 事件类型 **/
export type KFilterGridSearchBarDesignerEventEmitsType = ViewEmitsTypeExtract<typeof KFilterGridSearchBarDesignerEventEmits>

/** KFilterGridSearchBarDesigner 参数 **/
export const KFilterGridSearchBarDesignerPropOptions = {
  ...BaseViewPropOptions,
  /**控件元数据 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def([]),
  /**数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def({} as KDataElementTree)
}

/** KFilterGridSearchBarDesigner 参数类型 **/
export type KFilterGridSearchBarDesignerPropType = ViewPropsTypeExtract<typeof KFilterGridSearchBarDesignerPropOptions>

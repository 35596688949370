import { ControlDescriptionBase } from '@kmsoft/upf-core'
import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { KControlAttribute } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'

/** 文本框列 */
export class KDataGridTextBoxColumnControlDescription extends ControlDescriptionBase {
  static readonly controlName: string = 'KDataGridTextBoxColumn'
  baseAttributes: Array<KControlAttribute> = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE]
  layoutAttributes: Array<KControlAttribute> = [
    LAYOUT_CONFIG_ITEM_COLLECTION.WIDTH,
    LAYOUT_CONFIG_ITEM_COLLECTION.ALIGN,
    LAYOUT_CONFIG_ITEM_COLLECTION.FROZEN,
    LAYOUT_CONFIG_ITEM_COLLECTION.HIDE
  ]
}

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "extendSkeleton" }
const _hoisted_2 = { class: "global-content-main" }
const _hoisted_3 = { class: "global-content-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_Extend_layout_header = _resolveComponent("k-Extend-layout-header")!
  const _component_k_Extend_layout_sidebar = _resolveComponent("k-Extend-layout-sidebar")!
  const _component_k_tabs = _resolveComponent("k-tabs")!
  const _component_k_layout_content = _resolveComponent("k-layout-content")!
  const _component_k_layout = _resolveComponent("k-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_layout, { style: {"height":"100%"} }, {
      default: _withCtx(() => [
        _createVNode(_component_k_Extend_layout_header, {
          ref: _ctx.$vm.refHeader,
          isMenusVisible: "",
          menus: _ctx.menus,
          collapsed: _ctx.$vm.collapsed,
          menuActiveKey: _ctx.$vm.activeMenuKey
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "header")
          ]),
          _: 3
        }, 8, ["menus", "collapsed", "menuActiveKey"]),
        _createVNode(_component_k_layout, { class: "layout desktop" }, {
          default: _withCtx(() => [
            _createVNode(_component_k_Extend_layout_sidebar, {
              ref: _ctx.$vm.refSideBar,
              isMenusVisible: false,
              menuActiveKey: _ctx.$vm.activeMenuKey,
              theme: "dark",
              menus: _ctx.menus
            }, {
              title: _withCtx(() => [
                _renderSlot(_ctx.$slots, "sideBar")
              ]),
              _: 3
            }, 8, ["menuActiveKey", "menus"]),
            _createVNode(_component_k_layout_content, { class: "global-content" }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_k_tabs, {
                    ref: _ctx.$vm.refGlobalTab,
                    type: "editable-card",
                    onChange: _ctx.$vm.onTabChange,
                    onAllTabClosed: _ctx.$vm.onAllTabClosed
                  }, null, 8, ["onChange", "onAllTabClosed"])
                ], 512), [
                  [_vShow, _ctx.$vm.showTabs]
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "main")
                ], 512), [
                  [_vShow, !_ctx.$vm.showTabs]
                ])
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ]))
}
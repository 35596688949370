import { ref } from 'vue'
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KObjectVersionGridEmitsType, KObjectVersionGridPropType } from './interface'
import { KObjectClassGridViewModel, ObjectClassGirdBaseQueryParams } from '../object-class-grid'

/** 版本网格 */
export default class KObjectVersionGridViewModel extends BaseViewModel<KObjectVersionGridEmitsType, KObjectVersionGridPropType> {
  /** 对象类网格 */
  refObjectClassGrid = ref<KObjectClassGridViewModel>()

  constructor(options: ViewModelOptions<KObjectVersionGridPropType>) {
    super(options)
  }

  viewDidMount() {}

  async onLoadData(params: ObjectClassGirdBaseQueryParams) {
    return []
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartMaterialBomFormView from './KPartMaterialBomForm.vue'
import KPartMaterialBomFormViewModel from './KPartMaterialBomFormViewModel'

const KPartMaterialBomForm = connect(KPartMaterialBomFormView, KPartMaterialBomFormViewModel)

export default withInstall(KPartMaterialBomForm)
export { KPartMaterialBomForm, KPartMaterialBomFormView, KPartMaterialBomFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

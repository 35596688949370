import { FileStream } from './FileStream'

/**表示用于创建 {@link FileStream} 对象的高级选项。*/
export enum FileOptions {
  /**指示在生成 {@link FileStream} 对象时，不应使用其他选项。*/
  None = 0,
  /**指示文件是加密的，只能通过用于加密的同一用户帐户来解密。*/
  Encrypted = 16384,
  /**指示当不再使用某个文件时，自动删除该文件。*/
  DeleteOnClose = 67108864,
  /**指示按从头到尾的顺序访问文件。 系统可将此选项用作优化文件缓存的提示。 如果应用程序移动用于随机访问的文件指针，可能不发生优化缓存，但仍然保证操作的正确性。 如果指定此标志，可提升某些案例中的性能。*/
  SequentialScan = 134217728,
  /**指示随机访问文件。 系统可将此选项用作优化文件缓存的提示。*/
  RandomAccess = 268435456,
  /**指示文件可用于异步读取和写入。*/
  Asynchronous = 1073741824,
  /**指示系统应通过任何中间缓存、直接写入磁盘。*/
  WriteThrough = -2147483648
}

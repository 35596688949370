import { connect, withInstall } from '@kmsoft/upf-core'
import KPartImportErrorMsgView from './KPartImportErrorMsg.vue'
import KPartImportErrorMsgViewModel from './KPartImportErrorMsgViewModel'

const KPartImportErrorMsg = connect(KPartImportErrorMsgView, KPartImportErrorMsgViewModel)

export default withInstall(KPartImportErrorMsg)
export { KPartImportErrorMsg, KPartImportErrorMsgView, KPartImportErrorMsgViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%","display":"flex","justify-content":"center","align-items":"center","gap":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_checkbox = _resolveComponent("k-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_k_checkbox, {
      checked: _ctx.$vm.model.isProperties,
      "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.model.isProperties) = $event)),
      disabled: _ctx.$vm.propertiesDisabled
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 对象属性 ")
      ]),
      _: 1
    }, 8, ["checked", "disabled"]), [
      [_vShow, _ctx.$vm.propertiesVisible]
    ]),
    _withDirectives(_createVNode(_component_k_checkbox, {
      checked: _ctx.$vm.model.isStruct,
      "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.model.isStruct) = $event)),
      disabled: _ctx.$vm.structDisabled
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 零部件结构 ")
      ]),
      _: 1
    }, 8, ["checked", "disabled"]), [
      [_vShow, _ctx.$vm.structVisible]
    ]),
    _withDirectives(_createVNode(_component_k_checkbox, {
      checked: _ctx.$vm.model.isCascade,
      "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.model.isCascade) = $event)),
      disabled: _ctx.$vm.cascadeDisabled
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 级联 ")
      ]),
      _: 1
    }, 8, ["checked", "disabled"]), [
      [_vShow, _ctx.$vm.cascadeVisible]
    ])
  ]))
}
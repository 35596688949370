import { connect, withInstall } from '@kmsoft/upf-core'
import KConfigFormView from './KConfigForm.vue'
import KConfigFormViewModel from './KConfigFormViewModel'

const KConfigForm = connect(KConfigFormView, KConfigFormViewModel)

export default withInstall(KConfigForm)
export { KConfigForm, KConfigFormView, KConfigFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KEditPanelDemoView from './KEditPanelDemo.vue'
import KEditPanelDemoViewModel from './KEditPanelDemoViewModel'

const KEditPanelDemo = connect(KEditPanelDemoView, KEditPanelDemoViewModel)

export default withInstall(KEditPanelDemo)
export { KEditPanelDemo, KEditPanelDemoView, KEditPanelDemoViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _directive_focus = _resolveDirective("focus")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    style: {"padding":"8px 0px","width":"100%","height":"100%"},
    ref: _ctx.$vm.refFolderTreeEl
  }, [
    _createVNode(_component_k_panel, null, {
      default: _withCtx(() => [
        _createVNode(_component_k_tree_view, {
          ref: _ctx.$vm.refFolderTree,
          nodeKey: "id",
          onAfterSelect: _cache[0] || (_cache[0] = (event) => _ctx.afterSelect(event)),
          defaultExpandedLevel: 0,
          autoSelectFirstNode: "",
          onAfterMenuClicked: _cache[1] || (_cache[1] = (event)=>_ctx.$vm.toolClick(event)),
          loadData: (node) => _ctx.loadTreeData(node)
        }, null, 8, ["loadData"])
      ]),
      _: 1
    })
  ])), [
    [_directive_focus]
  ])
}
import { SpecialFolder } from '../../../../Environment.SpecialFolder'
import { ImageFormat } from '../../../../drawing/imaging/ImageFormat'
import { DriveInfo } from '../../../../io/DriveInfo'
import { FileAttributes } from '../../../../io/FileAttributes'
import { NativeMethods } from '../../../../../kmsoft'
import { KTreeNodeFileSystemInfo } from './KTreeNodeFileSystemInfo'
import { KTreeNodeDrive } from './KTreeNodeDrive'

export class KTreeNodeComputer extends KTreeNodeFileSystemInfo {
  constructor() {
    super('此电脑')

    this.pathType = FileAttributes.Device

    this.needInitializeName = true
    this.needInitializeShellIcon = true
  }

  protected async initializeName(): Promise<string> {
    return NativeMethods.GetFileDisplayName(SpecialFolder.MyComputer)
  }

  protected async initializeShellIcon(): Promise<string> {
    let image = await NativeMethods.GetFileTypeBitmapBytes(SpecialFolder.MyComputer, true, true, await ImageFormat.Png())
    if (image) image = 'data:image/png;base64,' + image
    return image
  }

  protected async initialize(): Promise<void> {
    await super.initialize()
    this.pathTypeName = this.name
  }

  protected async generateChildren(populateType: number, filter: string): Promise<KTreeNodeFileSystemInfo[]> {
    return (await DriveInfo.GetDrives()).map(_ => new KTreeNodeDrive(_))
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KDocStructureListEventEmits, KDocStructureListPropOptions } from './interface'
import KDocStructureListViewModel from './KDocStructureListViewModel'
import { clsCodeMap, EnumLifecycleState, EnumLifecycleStateColor } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KDocStructureList',
  props: KDocStructureListPropOptions,
  emits: KDocStructureListEventEmits,
  viewModel: KDocStructureListViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor }
  }
})

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KBusinessVersionRenderEmitsType, KBusinessVersionRenderPropType } from './interface'
import { ref, watch } from 'vue'
import { getValues } from '@kmsoft/upf-core/src/toolkits/utils/enum'

/** KBusinessVersionRender */
export default class KBusinessVersionRenderViewModel extends BaseViewModel<
  KBusinessVersionRenderEmitsType,
  KBusinessVersionRenderPropType
> {
  stateValue = ref<string>()
  originValue = ref<string>()
  constructor(options: ViewModelOptions<KBusinessVersionRenderPropType>) {
    super(options)
    watch(
      () => options.props.value,
      () => {
        this.stateValue.value = options.props.value
      },
      {
        deep: true,
        immediate: true
      }
    )
  }

  viewDidMount() {}
  setValue(value: string, setChanged?: boolean) {
    this.stateValue.value = value
    if (setChanged) {
      this.originValue.value = value
    }
  }
  getValue() {
    return this.stateValue.value
  }
  getModifiedValue() {
    return this.stateValue.value
  }
  isModified() {
    return this.originValue.value !== this.stateValue.value
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartBomCompareView from './KPartBomCompare.vue'
import KPartBomCompareViewModel from './KPartBomCompareViewModel'

const KPartBomCompare = connect(KPartBomCompareView, KPartBomCompareViewModel)

export default withInstall(KPartBomCompare)
export { KPartBomCompare, KPartBomCompareView, KPartBomCompareViewModel }

// 模板生成文件
// export * from './{{folderName}}'

export type ActualParameter = {
  FormalParameterID: string
  IOType: string
  DataType: string
  IsArray: string
  Value: string
}

export type ReturnParameter = {
  FormalParameterID: string
  DataType: string
  Value: string
}

export type Application = {
  IntefaceName: string
  InterfaceFunName: string
  ActualParameters: {
    ActualParameter: ActualParameter[]
  }
  ReturnParameter: ReturnParameter
}

export enum IOTypeEnum {
  IN = 'In',
  OUT = 'Out'
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KChangeActivityCreatePropOptions = {
  ...BaseViewPropOptions,
  changeOrderId: VuePropTypes.string().def(),
  formValue: VuePropTypes.createType<any>().def(),
  changeBeforeData: VuePropTypes.createType<any[]>().def(),
  changeAfterData: VuePropTypes.createType<any[]>().def()
}

/** 参数类型 **/
export type KChangeActivityCreatePropType = ViewPropsTypeExtract<typeof KChangeActivityCreatePropOptions>

/** 事件 */
export const KChangeActivityCreateEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangeActivityCreateEmitsType = ViewEmitsTypeExtract<typeof KChangeActivityCreateEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangePromoteActivityEditView from './KChangePromoteActivityEdit.vue'
import KChangePromoteActivityEditViewModel from './KChangePromoteActivityEditViewModel'

const KChangePromoteActivityEdit = connect(KChangePromoteActivityEditView, KChangePromoteActivityEditViewModel)

export default withInstall(KChangePromoteActivityEdit)
export { KChangePromoteActivityEdit, KChangePromoteActivityEditView, KChangePromoteActivityEditViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { TreeViewSelectEventArgs, defineView } from '@kmsoft/upf-core'
import { KWorkflowTemplateSelectorPropOptions, KWorkflowTemplateSelectorEventEmits } from './interface'
import KWorkflowTemplateSelectorViewModel from './KWorkflowTemplateSelectorViewModel'

export default defineView({
  name: 'KWorkflowTemplateSelector',
  props: KWorkflowTemplateSelectorPropOptions,
  emits: KWorkflowTemplateSelectorEventEmits,
  viewModel: KWorkflowTemplateSelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { MetaProperty } from '../../client-srv'
import {
  VuePropTypes,
  ViewPropsTypeExtract,
  ViewEmitsTypeExtract,
  BaseViewPropOptions,
  BaseViewEventEmits
} from '@kmsoft/upf-core'

export const KObjectPropertyEditorPropOptions = {
  ...BaseViewPropOptions,
  /** 对象类的字段 */
  field: VuePropTypes.createType<MetaProperty>()
    .setRequired()
    .def(),
  /** 编辑值 */
  value: VuePropTypes.createType<string>().def(),
  /** 占位符 */
  placeholder: VuePropTypes.string().def('属性值'),
  /** 如果可以是null，是否显示null候选项 */
  allowNull: VuePropTypes.bool().def(false),
  /** 是否显示current user候选项 */
  allowCurrentUser: VuePropTypes.bool().def(false)
}

export const KObjectPropertyEditorEventEmits = {
  ...BaseViewEventEmits,
  /** 值更新事件 */
  'update:value': (value: any) => true,
  /** 数据更新 */
  update: (value: any) => true
}

export type KObjectPropertyEditorPropType = ViewPropsTypeExtract<typeof KObjectPropertyEditorPropOptions>

export type KObjectPropertyEditorEventEmitsType = ViewEmitsTypeExtract<typeof KObjectPropertyEditorEventEmits>

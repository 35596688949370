import { BaseViewModel, EnumDialogResult, KDialog, KDialogClosingEvent, ViewModelOptions } from '@kmsoft/upf-core'
import { KRefObjEditorCustomPropType, KRefObjEditorCustomPropsEventEmitsType, IRefObjEditorFormDefinition } from './interface'
import ObjSelectPanel, { KObjSelectPanelViewModel } from './obj-select-panel'
import { ref, watch } from 'vue'

export class KRefObjEditorFormViewModel extends BaseViewModel<
  KRefObjEditorCustomPropsEventEmitsType,
  KRefObjEditorCustomPropType
> {
  formState = ref<IRefObjEditorFormDefinition>()

  constructor(options: ViewModelOptions<KRefObjEditorCustomPropType>) {
    super(options)

    watch(
      () => options.props.propertyId,
      () => {
        // 判断editArgs是否为空
        const data = options.props.editArgs ? JSON.parse(options.props.editArgs) : {}
        this.formState.value = data
      },
      { immediate: true }
    )
  }

  viewDidMount() {}

  // 设置表单的值
  setValue(formState: IRefObjEditorFormDefinition) {
    this.formState.value = formState
  }

  // 获取表单的值
  getValue() {
    return this.formState.value
  }

  // 选择显示列
  toSelectRefObj() {
    // 显示对象属性选择modal
    KDialog.show({
      title: '选择显示列',
      size: { width: 950, height: 500 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      props: {
        propertyArgs: this.props.propertyArgs
      },
      content: ObjSelectPanel,
      onClosing: async (event: KDialogClosingEvent) => {
        const vm = event.viewModel as KObjSelectPanelViewModel

        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const selectedProperties = vm.getValue()

        const refObjCodes: Array<string> = []
        const refObjNameList: Array<string> = []
        const propertyArgs = JSON.parse(this.props.propertyArgs) || ''
        const refClsCode = propertyArgs.refClsCode || ''
        selectedProperties.forEach((item: any) => {
          refObjCodes.push(item.code)
          refObjNameList.push(item.displayName)
        })

        this.formState.value = {
          refClsCode,
          refObjCodes,
          refObjNames: refObjNameList.join(', '),
          refObjGroup: this.formState.value?.refObjGroup!
        }
      }
    })
  }
}

import {
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  KTreeViewEventEmits,
  KTreeViewPropOptions,
  VuePropTypes
} from '@kmsoft/upf-core'

//#region 定义

/** 属性目录定义 */
export interface IDirectoryTreeNodeData {
  /**
   * 目录Id
   */
  id: string
  /**
   * 父目录Id
   */
  parentId: string
  /**
   * 目录名称
   */
  name: string
  /**
   * 目录模块类型
   */
  moduleType: number
  /**
   * 类型
   */
  type: number
  /**
   * 序号
   */
  orderId: number
  /**
   * 是否预定义
   */
  predefined: number
  /**
   * 备注
   */
  remark: string
  /**
   * 是否叶子节点（0-否，1-是）
   */
  leaf: number
}
//#endregion

/** KDirectoryTree 事件 */
export const KDirectoryTreeEventEmits = {
  ...KTreeViewEventEmits
}

/** KDirectoryTree 事件类型 **/
export type KDirectoryTreeEventEmitsType = ViewEmitsTypeExtract<typeof KDirectoryTreeEventEmits>

/** KDirectoryTree 参数 **/
export const KDirectoryTreePropOptions = {
  ...KTreeViewPropOptions,
  /** 要显示的目录模块 */
  moduleType: VuePropTypes.number().def(),
  /** 要现实的目录类型 */
  directoryTypes: VuePropTypes.array<number>().def(),
  /** 要显示的根目录名称 */
  showRootDirectoryCodes: VuePropTypes.array<string>().def()
}

/** KDirectoryTree 参数类型 **/
export type KDirectoryTreePropType = ViewPropsTypeExtract<typeof KDirectoryTreePropOptions>

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartAssociationFormView from './KPartAssociationForm.vue'
import KPartAssociationFormViewModel from './KPartAssociationFormViewModel'

const KPartAssociationForm = connect(KPartAssociationFormView, KPartAssociationFormViewModel)

export default withInstall(KPartAssociationForm)
export { KPartAssociationForm, KPartAssociationFormView, KPartAssociationFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

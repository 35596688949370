import {
  BaseViewPropOptions,
  VuePropTypes,
  VueNode,
  BaseViewEventEmits,
  ViewPropsTypeExtract,
  ViewEmitsTypeExtract
} from '@kmsoft/upf-core'
import { Ref } from 'vue'
import { FnFormAction, EnumDataType, MetaProperty } from '../../client-srv'
import { KObjectClassGridFindViewModel } from './KObjectClassGridFindViewModel'
import { SearchFilterTreeLeaf } from '../rule-definer'

export type ObjectClassGridFindForm = {
  condition: SearchFilterTreeLeaf
  dataType: EnumDataType
}

/**
 * 对象查找对话框属性
 */
export type FindDialogProps = {
  /** 列定义 */
  fields: Ref<MetaProperty[]>
  /** 标题 */
  title?: string
  /** 控件宿主 */
  getContainer?: string | (() => VueNode) | VueNode
  /** 在点击查找下一个时调用 */
  onFindNext?: FnFormAction<ObjectClassGridFindForm>
  /** 查找上一个 */
  onFindPrev?: FnFormAction<ObjectClassGridFindForm>
  /** 关闭后回调 */
  onClosed?: () => any
}

export type KObjectClassGridFindDialog = {
  /** 关闭窗口 */
  close: () => void
  /** 组件引用 */
  refComponent?: KObjectClassGridFindViewModel
}

/**
 * 对象网格查找器属性选项
 */
export const KObjectClassGridFindPropOptions = {
  ...BaseViewPropOptions,
  /**
   * 可查找的域
   */
  fields: VuePropTypes.array<MetaProperty>().def([])
}

/**
 *  对象网格查找器属性类型
 */
export type KObjectClassGridFindPropType = ViewPropsTypeExtract<typeof KObjectClassGridFindPropOptions>

/**
 * 对象网格查找器事件
 */
export const KObjectClassGridFindEventEmits = {
  ...BaseViewEventEmits,
  findNext: (_form: ObjectClassGridFindForm, _update: boolean) => true,
  findPrev: (_form: ObjectClassGridFindForm, _update: boolean) => true
}

/**
 * 对象网格查找器事件类型
 */
export type KObjectClassGridFindEventEmitsType = ViewEmitsTypeExtract<typeof KObjectClassGridFindEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KIssueReportView from './KIssueReport.vue'
import KIssueReportViewModel from './KIssueReportViewModel'

const KIssueReport = connect(KIssueReportView, KIssueReportViewModel)

export default withInstall(KIssueReport)
export { KIssueReport, KIssueReportView, KIssueReportViewModel }

// 模板生成文件
// export * from './{{folderName}}'

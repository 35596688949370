import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!

  return (_openBlock(), _createBlock(_component_k_select, {
    value: _ctx.$vm.formState.refEnumId,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formState.refEnumId) = $event)),
    "show-search": "",
    placeholder: "Please select",
    options: _ctx.$vm.enumTreeData
  }, null, 8, ["value", "options"]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_config_form_item = _resolveComponent("config-form-item")!
  const _component_k_collapse_panel = _resolveComponent("k-collapse-panel")!
  const _component_k_collapse = _resolveComponent("k-collapse")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, _mergeProps({
    ref: "refConfigForm",
    model: _ctx.$vm.formValue,
    class: [{ 'is-view': _ctx.$vm.isView, 'config-form-notCol': !_ctx.$vm.isCol }, "config-form"]
  }, _ctx.omit(_ctx.$props, ['formValue', 'formList', 'labelCol', 'isView', 'groupList', 'isExpand', 'colSpan'])), {
    default: _withCtx(() => [
      (_ctx.$vm.isCol)
        ? (_openBlock(), _createBlock(_component_k_collapse, {
            key: 0,
            activeKey: _ctx.$vm.activeKey,
            "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.activeKey) = $event)),
            ghost: "",
            class: "form-collapse"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$vm.groupList, (col) => {
                return (_openBlock(), _createBlock(_component_k_collapse_panel, {
                  key: col?.props,
                  header: col?.label,
                  style: _normalizeStyle(col.style)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_config_form_item, {
                      "form-value": _ctx.$vm.formValue,
                      "form-list": col?.children,
                      "label-col": _ctx.$vm.labelCol,
                      "is-view": _ctx.$vm.isView,
                      "col-span": _ctx.$vm.colSpan
                    }, null, 8, ["form-value", "form-list", "label-col", "is-view", "col-span"])
                  ]),
                  _: 2
                }, 1032, ["header", "style"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["activeKey"]))
        : (_openBlock(), _createBlock(_component_config_form_item, {
            key: 1,
            "form-value": _ctx.$vm.formValue,
            "form-list": _ctx.$vm.formList,
            "label-col": _ctx.$vm.labelCol,
            "is-view": _ctx.$vm.isView
          }, null, 8, ["form-value", "form-list", "label-col", "is-view"]))
    ]),
    _: 1
  }, 16, ["model", "class"]))
}
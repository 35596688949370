import * as UpfCore from '@kmsoft/upf-core'

import { webSocketClientSrv } from '..'
import { AgentInstanceObject } from './AgentInstanceObject'
import { AgentEventHandler } from './kmsoft/agent/AgentEventHandler'
import { AgentRequestModel } from './kmsoft/agent/AgentRequestModel'
import { BindingFlags } from './system/reflection/BindingFlags'

/**代理管理器。*/
export class AgentManager {
  /**类型。*/
  protected static type: string = 'KMSoft.Agent.AgentManager'

  /**测试 Id。*/
  private static readonly testId: string = '4e8a1a1a-e652-45c9-8ccd-b303e3c2f84b'
  /**请求头键。*/
  private static readonly headerKey: string = 'Client-Id'

  private static _config: UpfCore.RequestConfig
  /**配置。*/
  private static get config(): UpfCore.RequestConfig {
    const url = webSocketClientSrv.getAgentApiBaseUrl()
    return this._config ?? (this._config = { baseURL: url + '/', headers: { [this.headerKey]: '' }, timeout: 7200000 })
  }

  /**Id。*/
  private static get id(): string {
    return this.config.headers![this.headerKey]
  }
  private static set id(value) {
    this.config.headers![this.headerKey] = value
  }

  //#region 对象操作
  /**发送请求。
   * @param url 请求相对地址。
   * @param params 请求查询参数。
   * @param data 请求数据。
   * @returns 请求结果。*/
  private static async postRequest<T>(url: string, params: Record<string, any>, data?: any): Promise<T> {
    await this.initialize()
    return (UpfCore.request.post(url, data, { ...AgentManager.config, params }) as unknown) as Promise<T>
  }

  /**创建对象。
   * @param type 对象类型。
   * @param parameters 参数。
   * @returns 对象。*/
  public static async createInstance(type: string, parameters: any[]): Promise<any> {
    return this.postRequest('CreateInstance', { type }, parameters)
  }

  /**创建对象并包装。
   * @param type 对象类型。
   * @param parameters 参数。
   * @returns 对象 Id。*/
  public static async createInstanceAndWrap(type: string, parameters: any[]): Promise<string> {
    return this.postRequest('CreateInstanceAndWrap', { type }, parameters)
  }

  /**销毁对象。
   * @param objectId 对象 Id。
   * @returns 若成功销毁对象，为 <see langword="true"/>，否则，为 <see langword="false"/>。*/
  public static async disposeInstance(objectId: string): Promise<boolean> {
    return this.postRequest('DisposeInstance', { objectId })
  }

  /**调用指定成员。
   * @param type 指定的对象 Id。
   * @param name 成员名称。
   * @param invokeAttr 成员类型。
   * @param parameters 参数。
   * @returns 调用结果。*/
  public static async invokeMember<T>(objectId: string, name: string, invokeAttr: BindingFlags, parameters: any[]): Promise<T> {
    return this.postRequest('InvokeMember', { objectId, name, invokeAttr }, parameters)
  }

  /**调用指定成员并包装结果。。
   * @param type 指定的对象 Id。
   * @param name 成员名称。
   * @param invokeAttr 成员类型。
   * @param parameters 参数。
   * @returns 调用结果 Id。*/
  public static async invokeMemberAndWrap(
    objectId: string,
    name: string,
    invokeAttr: BindingFlags,
    parameters: any[]
  ): Promise<string> {
    return this.postRequest('InvokeMemberAndWrap', { objectId, name, invokeAttr }, parameters)
  }

  /**调用指定静态成员。
   * @param type 指定的对象类型。
   * @param name 成员名称。
   * @param invokeAttr 成员类型。
   * @param parameters 参数。
   * @returns 调用结果。*/
  public static async invokeStaticMember<T>(type: string, name: string, invokeAttr: BindingFlags, parameters: any[]): Promise<T> {
    return this.postRequest('InvokeStaticMember', { type, name, invokeAttr }, parameters)
  }
  /**调用指定静态成员并包装结果。。
   * @param type 指定的对象类型。
   * @param name 成员名称。
   * @param invokeAttr 成员类型。
   * @param parameters 参数。
   * @returns 调用结果 Id。*/
  public static async invokeStaticMemberAndWrap(
    type: string,
    name: string,
    invokeAttr: BindingFlags,
    parameters: any[]
  ): Promise<string> {
    return this.postRequest('InvokeStaticMemberAndWrap', { type, name, invokeAttr }, parameters)
  }
  //#endregion

  //#region 对象事件
  /**附加事件处理程序。
   * @param objectId 指定的对象 Id。
   * @param name 事件名称。
   * @param handler 事件处理程序。
   * @returns */
  public static async addEventHandler(objectId: string, name: string, handler: AgentEventHandler): Promise<string> {
    const handlerId = UpfCore.utils.uuid().toLowerCase()
    this.modelHandlerMaps.set(handlerId, handler)
    await this.postRequest('AddEventHandler', { objectId, name, handlerId })
    return handlerId
  }

  /**移除事件处理程序。
   * @param objectId 指定的对象 Id。
   * @param name 事件名称。
   * @param handler 事件处理程序 Id。
   * @returns */
  public static async removeEventHandler(objectId: string, name: string, handlerId: string): Promise<void> {
    this.modelHandlerMaps.delete(handlerId)
    return this.postRequest('RemoveEventHandler', { objectId, name, handlerId })
  }

  /**附加静态事件处理程序。
   * @param type 指定的对象类型。
   * @param name 事件名称。
   * @param handler 事件处理程序。
   * @returns */
  public static async addStaticEventHandler(type: string, name: string, handler: AgentEventHandler): Promise<string> {
    const handlerId = UpfCore.utils.uuid().toLowerCase()
    this.modelHandlerMaps.set(handlerId, handler)
    await this.postRequest('AddStaticEventHandler', { type, name, handlerId })
    return handlerId
  }

  /**移除静态事件处理程序。
   * @param type 指定的对象类型。
   * @param name 事件名称。
   * @param handlerId 事件处理程序 Id。
   * @returns */
  public static async removeStaticEventHandler(type: string, name: string, handlerId: string): Promise<void> {
    this.modelHandlerMaps.delete(handlerId)
    return this.postRequest('RemoveStaticEventHandler', { type, name, handlerId })
  }
  //#endregion

  //#region WebSocket 操作
  /**{@link WebSocket} 对象。*/
  private static webSocket?: WebSocket
  /**对象缓存。*/
  public static readonly objectMaps = new Map<string, AgentInstanceObject>()
  /**请求处理器缓存。*/
  public static readonly modelHandlerMaps = new Map<string, (model: AgentRequestModel) => Promise<void> | void>()

  /**初始化代理。
   * @returns 初始化操作是否成功。*/
  public static async initialize(id?: string): Promise<boolean> {
    if (
      this.webSocket instanceof WebSocket &&
      (this.webSocket.readyState === WebSocket.CONNECTING || this.webSocket.readyState === WebSocket.OPEN)
    )
      return true

    this.id = ''
    this.webSocket = undefined

    return new Promise(resolve => {
      id = id || UpfCore.utils.uuid().toLowerCase()
      const webSocket = new WebSocket(UpfCore.utils.addQueryToUrl(this.config.baseURL?.replace('http', 'ws') + 'ws', { id }))
      webSocket.onopen = (ev: Event) => {
        this.id = id!
        this.webSocket = webSocket
        resolve(true)
      }

      webSocket.onerror = (ev: Event) => {
        this.id = ''
        this.webSocket = undefined
        this.objectMaps.clear()
        resolve(false)
      }

      webSocket.onclose = (ev: CloseEvent) => {
        this.id = ''
        this.webSocket = undefined
        this.objectMaps.clear()
      }

      webSocket.onmessage = async (ev: MessageEvent<string>) => {
        const data: AgentRequestModel = JSON.parse(ev.data)
        const handler = this.modelHandlerMaps.get(data.Request)
        if (handler) await handler(data)
      }
    })
  }

  /**将响应数据放入请求并将请求从缓存中移除。
   * @param requestId 请求 Id。
   * @param response 响应数据。*/
  public static async pushResponse<R = any>(requestId: string, response: R): Promise<void> {
    return this.postRequest('PushResponse', { requestId, responese: JSON.stringify(response) })
  }
  //#endregion
}

import {
  Agent,
  Api,
  CommonClientSrv,
  EBF_IOC_KEY,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  KObjectPanelPropType,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import { AgentManager, AgentRequestModel } from '@kmsoft/ebf-common/src/client-agent'
import {
  AppContext,
  EnumDialogResult,
  EnumDialogState,
  IIdentityStorage,
  KDialog,
  KDialogClosingEvent,
  UPF_IOC_KEYS,
  VNodeProps
} from '@kmsoft/upf-core'
import { resolveComponent } from 'vue'
import { KCADWorkspace, KPersonalWorkspaceViewModel } from '../../../../../../../ebf-folder'
import { DocClientSrv } from '../../../../../client-srv'
import { KDocEditPanel, KDocStructureManage } from '../../../../../controls'
import { DpMsgClientSrv } from '../DpMsgClientSrv'
import { DpMsgXml } from '../DpMsgXml'
import { EnumExApp } from '../EnumExApp'
import { Application, IOTypeEnum } from '../Parameter'

export class AutoCADHandler {
  public static readonly srcAppID: EnumExApp = EnumExApp.AUTOCAD

  private static agentLoginRequest: AgentRequestModel = (null as unknown) as AgentRequestModel

  /**登录操作处理 */
  public static async login(request: AgentRequestModel) {
    console.log('login', request)
    const srcAppID = request.Request.split('.')[0]

    AutoCADHandler.agentLoginRequest = request

    AppContext.current.getIocContainer().registerSingleton(EBF_IOC_KEY.AGENT_LOGIN, { agentAfterLoad: AutoCADHandler.afterLogin })

    //登录成功或失败
    // await DpMsgClientSrv.Token(AppContext.current.getIdentity()?.token!)
    // /** 发送登录结果消息*/
    // await AutoCADHandler.SendMsgLoginResult(parseInt(srcAppID), 'true')

    // /** 同步返回结果*/
    // const result = DpMsgXml.createXmlResult(request.Parameters, true, { cDocSymbol: '1', ErrInfo: '' })
    // await AgentManager.pushResponse<any>(request.Id, result)
  }

  public static async afterLogin(token: string) {
    const request = AutoCADHandler.agentLoginRequest
    const srcAppID = request.Request.split('.')[0]
    //登录成功或失败
    await DpMsgClientSrv.Token(token)
    /** 发送登录结果消息*/
    await AutoCADHandler.SendMsgLoginResult(parseInt(srcAppID), 'true')

    /** 同步返回结果*/
    const result = DpMsgXml.createXmlResult(request.Parameters, true, { cDocSymbol: '1', ErrInfo: '' })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**退出处理 */
  public static async exit(request: AgentRequestModel) {
    console.log('exit', request)
    /** 同步返回结果*/
    AppContext.current
      .getIocContainer()
      .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
      .clear()
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   *
   * 获取文档信息，返回文档id
   */
  public static async getDocInfo(request: AgentRequestModel) {
    console.log('getDocInfo', request)
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    let result = DpMsgXml.createXmlResult(request.Parameters, '', { cVolId: 1, ErrInfo: '' })
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN)
    //根据检出路径获取文档id
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', { data: [{ fileFullPath: fileFullPath }] })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const data = res.data
      result = DpMsgXml.createXmlResult(request.Parameters, '', { cVolId: data.id, ErrInfo: '' })
    }
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   *判断是否已经检出，用于控制插件按钮
   */
  public static async docChecked(request: AgentRequestModel) {
    console.log('docChecked', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    let result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 0,
      ErrInfo: '找不到对应的文档'
    })
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN)
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', { data: [{ fileFullPath: fileFullPath }] })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const data = res.data
      result = DpMsgXml.createXmlResult(request.Parameters, '', {
        cCheckOut: data.workingState == 'CHECKED_IN' ? '0' : '1',
        cDocSymbol: 1,
        ErrInfo: ''
      })
    }
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 工作区
   */
  public static async workspace(request: AgentRequestModel) {
    const param = request.Parameters
    //根据检出路径查询文档
    KDialog.show({
      title: '工作区',
      state: EnumDialogState.Maximum,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      props: {},
      showApply: false,
      content: KCADWorkspace,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPersonalWorkspaceViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        //TODO do something

        const result = DpMsgXml.createXmlResult(request.Parameters, '', {
          cDocSymbol: 1,
          ErrInfo: ''
        })
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 编辑
   */
  public static async edit(request: AgentRequestModel) {
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'As_Path', IOTypeEnum.IN) as string
    let currentDocId = undefined
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', { data: [{ fileFullPath: fileFullPath }] })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      currentDocId = res.data.id
    }
    const conditions = [
      {
        conditionName: 'master.documentType',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: ['AUTOCAD_2D']
      }
    ]
    if (currentDocId) {
      conditions.push({
        conditionName: 'id',
        operator: EnumQueryConditionOperator.NOT_EQUAL,
        conditionValues: [currentDocId]
      })
    }
    //1）打开对象选择
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: [
            {
              conditionName: 'master.documentType',
              operator: EnumQueryConditionOperator.EQUAL,
              conditionValues: ['AUTOCAD_2D']
            }
          ]
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document']
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]
        //检出并下载
        const doc = await DocClientSrv.getDoc(targetParam?.id)
        doc.checkoutPath = `C:\\KMSOFT\\temp\\Documents`
        const docRes = await DocClientSrv.checkOutDoc(doc)
        const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
        await AgentManager.pushResponse<any>(request.Id, result)
        //3）给插件发消息打开目录下的文件
        await AutoCADHandler.openFile(AutoCADHandler.srcAppID, docRes.data[0].checkoutPath)
        return
      }
    })
  }

  /**
   * 浏览
   */
  public static async browser(request: AgentRequestModel) {
    const param = request.Parameters
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'As_Path', IOTypeEnum.IN) as string
    let currentDocId = undefined
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', { data: [{ fileFullPath: fileFullPath }] })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      currentDocId = res.data.id
    }
    //1）打开对象选择
    const conditions = [
      {
        conditionName: 'master.documentType',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: ['SOLIDWORKS']
      }
    ]
    if (currentDocId) {
      conditions.push({
        conditionName: 'id',
        operator: EnumQueryConditionOperator.NOT_EQUAL,
        conditionValues: [currentDocId]
      })
    }
    //1）打开对象选择
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: [
            {
              conditionName: 'master.documentType',
              operator: EnumQueryConditionOperator.EQUAL,
              conditionValues: ['AUTOCAD_2D']
            }
          ]
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document']
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]

        //2）下载文档
        const fileFullPath = (await DocClientSrv.docDownload(targetParam!.id)) as string
        if (fileFullPath) {
          //3）给插件发消息打开目录下的文件
          await AutoCADHandler.openFile(AutoCADHandler.srcAppID, fileFullPath)
        }
        const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
        await AgentManager.pushResponse<any>(request.Id, result)
        return
      }
    })
  }

  /**
   * 下载
   */
  public static async download(request: AgentRequestModel) {
    console.log('download', request)
    //1）打开对象选择
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: [
            {
              conditionName: 'master.documentType',
              operator: EnumQueryConditionOperator.EQUAL,
              conditionValues: ['AUTOCAD_2D']
            }
          ]
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document']
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]

        //2）下载文档
        const fullFilePath = await DocClientSrv.docDownload(targetParam!.id)
        const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 检入
   */
  public static async checkin(request: AgentRequestModel) {
    console.log('checkin', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN)
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', { data: [{ fileFullPath: fileFullPath }] })
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const data = res.data
      //必须关闭cad对文件的占用才能上传文件
      await AutoCADHandler.closeFile(AutoCADHandler.srcAppID, fileFullPath!)
      await DocClientSrv.docCheckIn(data!.id, EnumDialogState.Maximum)
      //TODO 执行检入的操作
      const result = DpMsgXml.createXmlResult(request.Parameters, '', {
        cDocSymbol: 1,
        ErrInfo: ''
      })
      await AgentManager.pushResponse<any>(request.Id, result)
    }
  }

  /**
   * 查看批注
   */
  public static async viewRemark(request: AgentRequestModel) {
    console.log('checkin', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN)
    //TODO 执行检入的操作
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', { data: [{ fileFullPath: fileFullPath }] })
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const data = res.data
      await DocClientSrv.doViewRemarkHistory(data!.id, () => {
        AgentManager.pushResponse<any>(request.Id, result)
      })
      await AgentManager.pushResponse<any>(request.Id, result)
    } else {
      await new Promise(() => {
        KDialog.info({
          content: '请先编辑文档',
          onOk: () => {
            AgentManager.pushResponse<any>(request.Id, result)
          }
        })
      })
    }
  }

  /**
   * 取消检出
   */
  public static async undoCheckout(request: AgentRequestModel) {
    console.log('undoCheckout', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN)
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', { data: [{ fileFullPath: fileFullPath }] })
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const data = res.data
      await DocClientSrv.docUnCheckOut(data!.id)
      await AutoCADHandler.closeFile(AutoCADHandler.srcAppID, fileFullPath!)
      //TODO 执行取消检出的操作
      const result = DpMsgXml.createXmlResult(request.Parameters, '', {
        cDocSymbol: 1,
        ErrInfo: ''
      })
      await AgentManager.pushResponse<any>(request.Id, result)
    }
  }

  /**
   * 属性
   */
  public static async objectAttributes(request: AgentRequestModel) {
    console.log('objectAttributes', request)
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN)
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', { data: [{ fileFullPath: fileFullPath }] })
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const data = res.data
      KDialog.show({
        state: EnumDialogState.Maximum,
        closable: false,
        maximizeBox: false,
        minimizeBox: false,
        rootClassName: 'agent',
        title: '文档属性',
        props: {
          objParam: { modelCode: 'Document', modelGroup: 'doc', id: data!.id },
          showApply: false,
          maximizeBox: false,
          minimizeBox: false
        },
        content: KDocEditPanel,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          //TODO do something
          const param = { id: data?.id, modelCode: 'Document', modelGroup: 'doc' }
          /** 标签页标识 */
          const tabKey = `${param.id}#${param.modelCode}`
          /** 标题 */
          /** 面板参数 */
          const panelProps: VNodeProps<KObjectPanelPropType> = { objParam: param }
          // 打开页面
          CommonClientSrv.openPage('文档属性', () => resolveComponent('KObjectPanel'), panelProps, tabKey)
          await AgentManager.pushResponse<any>(request.Id, result)
        }
      })
    } else {
      await new Promise(() => {
        KDialog.info({
          content: '请先编辑文档',
          onOk: () => {
            AgentManager.pushResponse<any>(request.Id, result)
          }
        })
      })
    }
  }

  /**
   * 结构
   */
  public static async structure(request: AgentRequestModel) {
    console.log('structure', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN)
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', { data: [{ fileFullPath: fileFullPath }] })
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const data = res.data
      KDialog.show({
        title: '文档结构',
        state: EnumDialogState.Maximum,
        closable: false,
        maximizeBox: false,
        minimizeBox: false,
        rootClassName: 'agent',
        props: {
          objParam: { modelCode: 'Document', modelGroup: 'doc', id: data!.id },
          showApply: false,
          maximizeBox: false,
          minimizeBox: false
        },
        content: KDocStructureManage,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          //TODO do something
          await AgentManager.pushResponse<any>(request.Id, result)
        }
      })
    } else {
      await new Promise(() => {
        KDialog.info({
          content: '请先编辑文档',
          onOk: () => {
            AgentManager.pushResponse<any>(request.Id, result)
          }
        })
      })
    }
  }

  /**
   * 入库
   */
  public static async batchImport(request: AgentRequestModel) {
    /** 同步返回结果*/
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { cVolId: '1', ErrInfo: '' })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /** 发送消息,登录结果*/
  public static async SendMsgLoginResult(appID: EnumExApp, isLogined: string) {
    const res = isLogined == 'true' ? 'OK' : 'CANCEL'
    // const paramters: ActualParameter[] = [
    //   { FormalParameterID: 'Res', IOType: 'In', DataType: 'string', IsArray: '0', Value: res },
    //   { FormalParameterID: 'SoftCaption', IOType: 'In', DataType: 'string', IsArray: '0', Value: 'saas' },
    //   { FormalParameterID: 'UserName', IOType: 'In', DataType: 'string', IsArray: '0', Value: 'One的账号' }
    // ]
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_ISLOGIN',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'Res',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: 'OK'
          },
          {
            FormalParameterID: 'SoftCaption',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: 'saas'
          },
          {
            FormalParameterID: 'UserName',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: '系统管理员'
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const result = DpMsgXml.objectToXML(application, 'Application')
    await DpMsgClientSrv.Send(appID, 'ID_PDM_APP_ISLOGIN', result, 3)
  }

  /**
   * 结构
   */
  public static async openFile(enumExApp: EnumExApp, fileFulPath: string) {
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_OPENFILE',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'cDocList',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: fileFulPath
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const res = DpMsgXml.objectToXML(application, 'Application')
    DpMsgClientSrv.Send(enumExApp, 'ID_PDM_APP_OPENFILE', res, 3)
  }

  /**
   * 关闭文件
   */
  public static async closeFile(enumExApp: EnumExApp, fileFulPath: string) {
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_CLOSEFILEDEL',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'cDocList',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: fileFulPath
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const res = DpMsgXml.objectToXML(application, 'Application')
    DpMsgClientSrv.Send(enumExApp, 'ID_PDM_APP_CLOSEFILEDEL', res, 3)
  }
}

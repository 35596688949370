import { EnumAttributeType, EnumControlElementType, EnumDataType } from '@kmsoft/upf-core'
import { DEFAULT_CURRENCY_LAYOUT_PROPS, DEFAULT_LABEL_POSITION_CANDIDATE } from '../../type'

/* 组件的基本属性 */
const comConfig = [
  {
    name: 'checkType',
    title: '显示方式',
    control: 'KSelect',
    defaultValue: '1',
    options: [
      { displayValue: '两态', actualValue: '1' },
      { displayValue: '三态', actualValue: '2' }
    ],
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  }
]

/**控件属性配置项集合 */
const PROPS = [] as Array<any>
const EVENT = [
  { name: 'beforeValueChange', title: '值改变前', describe: '值改变前 的说明信息...' },
  { name: 'valueChanged', title: '值改变后', describe: '值改变后 的说明信息...' }
]

export default { PROPS, EVENT }


import { defineView } from '@kmsoft/upf-core'
import { KPartCreatePromotePropOptions, KPartCreatePromoteEventEmits } from './interface'
import KPartCreatePromoteViewModel from './KPartCreatePromoteViewModel'

export default defineView({
  name: 'KPartCreatePromote',
  props: KPartCreatePromotePropOptions,
  emits: KPartCreatePromoteEventEmits,
  viewModel: KPartCreatePromoteViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { BaseViewModel, KToolStripViewModel } from '@kmsoft/upf-core'
import { KConfigGridListEventEmitsType, KConfigGridListViewModelPropType } from './interface'
import { ref } from 'vue'

export default class KConfigGridListViewModel extends BaseViewModel<
  KConfigGridListEventEmitsType,
  KConfigGridListViewModelPropType
> {
  refObjClsToolStrip = ref<KToolStripViewModel>()
  private defId: string
  private treeId: string

  private oper: string

  /**
   * 加载完成函数
   */
  viewDidMount() {}

  /**
   * 初始化表单内容
   * @param defId 配置项编码
   * @param treeId 树节点ID
   * @param oper 操作类型
   */
  public init(defId: string, treeId: string, oper: string) {
    this.defId = defId
    this.treeId = treeId
    this.oper = oper
    this.loadData()
  }

  /**
   * 加载数据
   */
  private loadData(): void {
    console.info('待定')
  }

  /**
   * 新建
   */
  public doInsert(): void {
    console.info('doInsert')
  }

  /**
   * 删除
   */
  public doDelete(): void {
    console.info('doDelete')
  }

  /**
   * 上移
   */
  public doUp(): void {
    console.info('doUp')
  }

  /**
   * 下移
   */
  public doDown(): void {
    console.info('doDown')
  }

  public check() {
    console.info('check')
  }

  /**
   * 保存
   */
  public save(): void {
    // let datasource = this.getState('datasource')
    // const param = {
    //   object: {
    //     defId: this.defId as unknown,
    //     id: '' as unknown,
    //     code: formState?.code,
    //     value: formState?.value
    //   }
    // } as SysValueUpdateRequest
    // sysValueUpdate(param).then((response: SysValueUpdateResponse) => {
    //   if (response.state !== 'success') {
    //     KNotification.error({
    //       title: '提示',
    //       content: response.message
    //     })
    //     return
    //   }
    //   KNotification.success({ title: '提示', content: '保存成功' })
    // })
  }

  /**
   * 刷新
   */
  public refresh(): void {
    // const formState = this.getState('formState')
    // if (formState?.value !== this.sourceValue) {
    //   KDialog.confirm({
    //     title: '存在数据修改，是继续保存，还是放弃修改？',
    //     okText: '继续保存',
    //     cancelText: '放弃修改',
    //     onCancel: async () => {
    //       this.loadData()
    //     },
    //     onOk: async () => {
    //       this.save()
    //     }
    //   })
    // }
  }
}

import {
  Api,
  EnumLifecycleState,
  EnumRequestCode,
  IObjectSelectorComponent,
  ObjBusinessParam,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import { EnumDialogResult, KDialog, KDialogClosingEvent, KNotification, KOrderAdjustPanel } from '@kmsoft/upf-core'
import _ from 'lodash'
import { KPartForm } from '../../pages/part-manage/part-form'
import KPartFormViewModel from '../../pages/part-manage/part-form/KPartFormViewModel'
import {
  AddChildPartParams,
  CreateAddChildPart,
  CreateAddReplacePart,
  PastePartParams,
  SortChildPartItem,
  SortChildPartsParams
} from './types'

/** 零部件结构客户端服务 */
export class PartStructureClientSrv {
  /**
   * 移除子零部件
   * @param objParam
   * @returns
   */
  static async removeChildPart(objParam: ObjBusinessParam) {
    const confirmResult = await KDialog.confirmAsync({
      title: '是否移除该零部件？'
    })
    if (!confirmResult) {
      return false
    }
    const result = await Api.post('part', 'BomLink', 'removeChild', {
      data: [
        {
          id: objParam.id,
          clazz: objParam.modelCode
        }
      ]
    })
    if (!result.success) {
      KNotification.error({ title: '操作失败', content: result.message, details: result.detail })
      return false
    }
    return true
  }

  /**
   * 移除子零部件副本
   * @param objParam
   * @param part
   * @returns
   */
  static async removeWorkingCopyChild(objParam: ObjBusinessParam, part: any) {
    const confirmResult = await KDialog.confirmAsync({
      title: '是否移除该零部件？'
    })
    if (!confirmResult) {
      return false
    }
    const result = await Api.post('part', 'BomLink', 'removeWorkingCopyChild', {
      data: [
        {
          id: objParam.id,
          clazz: objParam.modelCode
        },
        {
          id: part.id,
          clazz: part.className
        }
      ]
    })
    if (!result.success) {
      KNotification.error({ title: '操作失败', content: result.message, details: result.detail })
      return false
    }
    return true
  }

  /**
   * 删除子零部件
   * @param objParam
   * @returns
   */
  static async deleteChildPart(objParam: ObjBusinessParam) {
    const confirmResult = await KDialog.confirmAsync({
      title: '是否删除该零部件？'
    })
    if (!confirmResult) {
      return false
    }
    const result = await Api.post('part', 'BomLink', 'deleteChild', {
      data: [
        {
          id: objParam.id,
          clazz: objParam.modelCode
        }
      ]
    })
    if (!result.success) {
      KNotification.error({ title: '操作失败', content: result.message, details: result.detail })
      return false
    }
    return true
  }

  /**
   * 删除子零部件副本
   * @param objParam
   * @param part
   * @returns
   */
  static async deleteWorkingCopyChild(objParam: ObjBusinessParam, part: any) {
    const confirmResult = await KDialog.confirmAsync({
      title: '是否移除该零部件？'
    })
    if (!confirmResult) {
      return false
    }
    const result = await Api.post('part', 'BomLink', 'deleteWorkingCopyChild', {
      data: [
        {
          id: objParam.id,
          clazz: objParam.modelCode
        },
        {
          id: part.id,
          clazz: part.className
        }
      ]
    })
    if (!result.success) {
      KNotification.error({ title: '操作失败', content: result.message, details: result.detail })
      return false
    }
    return true
  }

  /**
   * 添加子零件
   * @param params
   */
  static async selectAddChildPart(params: AddChildPartParams, container: any, onSuccess?: () => void) {
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      isMultipleSelection: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Part']
      },
      objectSearchQueryParam: {
        isMultipleSelection: false,
        showObjClsCodes: ['Part', '']
      },
      getContainer: container,
      onClosing: async event => {
        if (!event.viewInstance) {
          onSuccess?.()
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          onSuccess?.()
          return
        }

        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          onSuccess?.()
          return
        }
        const objParam = objParams[0]

        if (objParam.partView.name != params.partView) {
          KNotification.error({ title: '添加失败', content: '不可添加不同视图的零部件。请选择其他对象。' })
          event.cancel = true
          return
        }

        // 如果选中了自己
        if (objParam.master.id == params.objParam.masterId) {
          KNotification.error({ title: '添加失败', content: '添加零部件不可作为自身的子类。请选择其他对象。' })
          event.cancel = true
          return
        }

        if (objParam.lifecycleStateCode == 'OBSOLETED') {
          const result = await this.getAddChildPartResult(objParam, params, onSuccess, event)
          if (!result) {
            event.cancel = true
          }
        } else {
          await PartStructureClientSrv.addChildPart(objParam, params, onSuccess, event)
        }
      }
    })
  }

  static async getAddChildPartResult(
    objParam: any,
    params: any,
    onSuccess: (() => void) | undefined,
    event: KDialogClosingEvent<IObjectSelectorComponent>
  ) {
    return new Promise(resolve => {
      KDialog.confirm({
        title: `确认添加零部件`,
        content: `添加零部件已废弃，是否确认添加？`,
        onOk: async () => {
          await PartStructureClientSrv.addChildPart(objParam, params, onSuccess, event)
          resolve(true)
        },
        onCancel: () => {
          resolve(false)
        }
      })
    })
  }

  static async addChildPart(
    objParam: any,
    params: any,
    onSuccess: (() => void) | undefined,
    event: KDialogClosingEvent<IObjectSelectorComponent>
  ) {
    const result = await Api.post('part', 'BomLink', 'addChild', {
      data: [
        {
          source: {
            id: params.objParam.id,
            clazz: params.objParam.modelCode
          },
          target: {
            id: objParam.master.id,
            clazz: objParam.master.rdmExtensionType
          },
          quantity: 1,
          occurrence: null,
          insertPosition: params.orderId
        }
      ]
    })
    onSuccess?.()
    if (!result.success) {
      KNotification.error({ title: '添加失败', content: result.message, details: result.detail })
      event.cancel = true
      return
    }
  }

  // /**
  //  * 添加子零件
  //  * @param params
  //  */
  // static async selectAddChildPart__(params: AddChildPartParams, onSuccess?: () => void) {
  //   ObjectClientSrv.openObjectSelectDialog({
  //     showApply: false,
  //     objectClassManageQueryParam: { classTemplates: [EnumClassTemplate.PART], defaultSelectClsCode: params.objParam.modelCode },
  //     onClosing: async event => {
  //       if (!event.viewInstance) {
  //         return
  //       }

  //       if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
  //         return
  //       }

  //       const objParams = event.viewInstance.getSelectedObjParams()

  //       if (objParams.length <= 0) {
  //         event.cancel = true
  //         return
  //       }

  //       const objParam = objParams[0]

  //       // 如果选中了自己
  //       if (objParam.id == params.objParam.id) {
  //         KNotification.error({ title: '添加失败', content: '更换零部件时选择了自己。请选择其他对象。' })
  //         event.cancel = true
  //         return
  //       }
  //       const result = await part.addChildPart({
  //         object: {
  //           bomLink: {
  //             parentId: params.objParam,
  //             childId: { id: objParam.masterId, clsCode: 'SYS_PART_MASTER' }
  //           },
  //           orderId: params.orderId
  //         } //TODO: 网格需要提供 master Obj API
  //       })

  //       if (!result.isSuccess) {
  //         result.showMessage()
  //         event.cancel = true
  //         return
  //       }
  //       await onSuccess?.()
  //     }
  //   })
  // }

  /**
   * 创建添加子零件
   * @param params
   */
  static async createAddChildPart(nodeParams: CreateAddChildPart, onSuccess?: () => void) {
    KDialog.show({
      title: '新建零部件',
      size: { width: 800, height: 600 },
      props: {
        classTreeSelectDisplay: true,
        modelCode: 'Part'
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPartForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPartFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) {
          onSuccess?.()
          return
        }
        if (event.dialogResult == EnumDialogResult.Close) {
          onSuccess?.()
          return
        }
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          onSuccess?.()
          return
        }

        const formData = formViewModel.getValue()
        if (formData.partView.name != nodeParams.partView) {
          KNotification.error({ title: '添加失败', content: '不可创建添加不同视图的零部件。请更改视图类型。' })
          event.cancel = true
          return
        }
        const saveResult = await Api.post('part', 'BomLink', 'addCreateChild', {
          data: [
            {
              source: {
                id: nodeParams.parentObjParam.id,
                clazz: nodeParams.parentObjParam.modelCode
              },
              target: null,
              quantity: 1,
              occurrence: null,
              createChild: formData,
              insertPosition: nodeParams.orderId
            }
          ]
        })
        onSuccess?.()
        if (!(saveResult && saveResult.code == EnumRequestCode.SUCCESS)) {
          event.cancel = true
          KNotification.error({
            title: '获取失败',
            content: saveResult.message,
            details: saveResult.detail
          })
          return {
            id: '',
            modelCode: '',
            group: '',
            objBusiness: undefined,
            flowObjParam: undefined,
            cancel: false,
            success: false,
            errMsg: saveResult.message
          }
        }
        return {
          id: saveResult.data?.target.id!,
          modelCode: 'Part',
          group: 'part',
          objBusiness: {
            id: saveResult.data?.target.id!,
            modelCode: 'Part',
            group: 'part'
          },
          flowObjParam: undefined,
          cancel: false,
          success: true,
          errMsg: ''
        }
      }
    })
  }

  // /**
  //  * 创建添加子零件
  //  * @param params
  //  */
  // static async createAddChildPart__(nodeParams: CreateAddChildPart, onSuccess?: () => void) {
  //   ObjectClientSrv.openCreateDialog({
  //     objCopyMode: EnumObjCopyMode.NewObj,
  //     modelCode: 'SYS_PART',
  //     forceShowObjClsSelector: false,
  //     // objId: param?.id,
  //     onSave: async (params: CreateObjBusinessParams) => {
  //       /** 保存结果 */
  //       const saveResult = await part.createChildPart({
  //         object: {
  //           bomLink: {
  //             parentId: nodeParams.parentObjParam,
  //             childId: { id: params.masterId, clsCode: 'SYS_PART_MASTER' },
  //             orderId: nodeParams.orderId
  //           },
  //           part: params
  //         } //TODO: 网格需要提供 master Obj API
  //       })
  //       if (saveResult) {
  //         await onSuccess?.()
  //         return {
  //           id: saveResult.data?.id!,
  //           modelCode: 'SYS_PART',
  //           group: 'part',
  //           objBusiness: {
  //             id: params.id!,
  //             modelCode: params.modelCode!,
  //             group: params.group!
  //           },
  //           flowObjParam: undefined,
  //           cancel: false,
  //           success: true,
  //           errMsg: ''
  //         }
  //       }
  //       return {
  //         id: '',
  //         modelCode: '',
  //         group: '',
  //         objBusiness: undefined,
  //         flowObjParam: undefined,
  //         cancel: false,
  //         success: false,
  //         errMsg: ''
  //       }
  //     }
  //   })
  // }

  /**
   * 排序子零件
   * @param params
   * @returns
   */
  static async sortChildParts(params: SortChildPartsParams) {
    /** 获取排序结果 */
    const result = await KOrderAdjustPanel.show<SortChildPartItem>({
      rowKey: 'id',
      list: params.childParts,
      fields: [{ title: '名称', id: 'name' }]
    })

    // 如果取消排序
    if (result.dialogResult == EnumDialogResult.Cancel || result.dialogResult == EnumDialogResult.Close) {
      return false
    }

    /** 排序 */
    const reqParams = {
      data: [
        {
          partId: params.objParam.id,
          bomLinkIds: result.result.sortedItems.map(a => a.id)
        }
      ]
    }
    const sortResult = await Api.post('part', 'BomLink', 'reorderBoMLink', reqParams)
    if (sortResult && sortResult.code == EnumRequestCode.SUCCESS) {
      return true
    } else {
      KNotification.warn({
        message: '系统提示',
        description: sortResult.message
      })
      return false
    }
  }

  /**
   * 粘贴零部件
   * @param params
   */
  static async pastePart(params: PastePartParams) {
    const result = await Api.post('part', 'BomLink', 'pasteChild', {
      data: [
        {
          sources: _.map(params.sourceObj, param => {
            return { id: param.id, clazz: param.modelCode }
          }),
          target: { id: params.targetObj.id, clazz: params.targetObj.modelCode },
          action: params.isCut ? 'CUT' : 'COPY'
        }
      ]
    })
    if (!result.success) {
      KNotification.error({ title: '操作失败', content: result.message, details: result.detail })
      return false
    }
    return true
  }

  /**
   * 粘贴零部件副本
   * @param params
   */
  static async pasteWorkingCopyPart(params: PastePartParams) {
    const result = await Api.post('part', 'BomLink', 'pasteWorkingCopyChild', {
      data: [
        {
          sources: _.map(params.sourceObj, param => {
            return { id: param.id, clazz: param.modelCode }
          }),
          target: { id: params.targetObj.id, clazz: params.targetObj.modelCode },
          sourceParentId: params.sourceParentId,
          action: params.isCut ? 'CUT' : 'COPY'
        }
      ]
    })
    if (!result.success) {
      KNotification.error({ title: '操作失败', content: result.message, details: result.detail })
      return false
    }
    return true
  }

  /**
   * 新建并替换零件
   */
  static async createReplacePart(nodeParams: CreateAddReplacePart, onSuccess?: () => void) {
    KDialog.show({
      title: '新建零部件',
      size: { width: 800, height: 600 },
      props: {
        classTreeSelectDisplay: true,
        modelCode: 'Part'
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPartForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPartFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }

        const formData = formViewModel.getValue()
        const result = await Api.post('part', 'BomLink', 'replaceCreateChild', {
          data: [
            {
              bomLink: {
                id: nodeParams.bomLinkParam.id,
                clazz: nodeParams.bomLinkParam.modelCode
              },
              replaceChild: {
                id: nodeParams.objParam.id,
                clazz: nodeParams.objParam.modelCode
              },
              createChild: formData
            }
          ]
        })
        await onSuccess?.()

        if (!(result && result.success)) {
          KNotification.error({ title: '操作失败', content: result.message, details: result.detail })
          return false
        } else {
          KNotification.success('操作成功')
          return true
        }
      }
    })
  }

  /**
   * 新建并替换零件
   */
  static async replaceCreateWorkingCopyChild(nodeParams: CreateAddReplacePart, onSuccess?: () => void) {
    KDialog.show({
      title: '新建零部件',
      size: { width: 800, height: 600 },
      props: {
        classTreeSelectDisplay: true,
        modelCode: 'Part'
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPartForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPartFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) {
          onSuccess?.()
          return
        }
        if (event.dialogResult == EnumDialogResult.Close) {
          onSuccess?.()
          return
        }
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          onSuccess?.()
          return
        }

        const formData = formViewModel.getValue()
        if (formData.partView.name != nodeParams.partView) {
          KNotification.error({ title: '更换失败', content: '不可创建更换不同视图的零部件。请更改视图类型。' })
          event.cancel = true
          return
        }
        const result = await Api.post('part', 'BomLink', 'replaceCreateWorkingCopyChild', {
          data: [
            {
              bomLink: {
                id: nodeParams.bomLinkParam.id,
                clazz: nodeParams.bomLinkParam.modelCode
              },
              replaceChild: {
                id: nodeParams.objParam.id,
                clazz: nodeParams.objParam.modelCode
              },
              createChild: formData,
              parent: {
                id: nodeParams.parentObjParam.id,
                clazz: nodeParams.parentObjParam.modelCode
              }
            }
          ]
        })
        onSuccess?.()

        if (!(result && result.success)) {
          KNotification.error({ title: '操作失败', content: result.message, details: result.detail })
          return false
        } else {
          KNotification.success('操作成功')
          return true
        }
      }
    })
  }

  // /**
  //  * 新建并替换零件
  //  */
  // static async createReplacePart__(nodeParams: CreateAddReplacePart, onSuccess?: () => void) {
  //   ObjectClientSrv.openCreateDialog({
  //     objCopyMode: EnumObjCopyMode.NewObj,
  //     modelCode: 'SYS_PART',
  //     forceShowObjClsSelector: false,
  //     // objId: param?.id,
  //     onSave: async (params: CreateObjBusinessParams) => {
  //       /** 保存结果 */
  //       const saveResult = await part.createAndReplacePart({
  //         object: {
  //           bomLinkId: nodeParams.bomLinkParam,
  //           part: params
  //         } //TODO: 网格需要提供 master Obj API
  //       })
  //       if (saveResult) {
  //         await onSuccess?.()
  //         return {
  //           id: saveResult.data?.id?.id!,
  //           modelCode: 'SYS_PART',
  //           group: 'part',
  //           objBusiness: {
  //             id: params.id!,
  //             modelCode: params.modelCode!,
  //             group: params.group!
  //           },
  //           flowObjParam: undefined,
  //           cancel: false,
  //           success: true,
  //           errMsg: ''
  //         }
  //       }
  //       return {
  //         id: '',
  //         modelCode: '',
  //         group: '',
  //         objBusiness: undefined,
  //         flowObjParam: undefined,
  //         cancel: false,
  //         success: false,
  //         errMsg: ''
  //       }
  //     }
  //   })
  // }

  /**
   * 查找并替换零件
   * @param params 节点参数
   * @param onSuccess 成功回调
   */
  static findReplacePart(params: CreateAddReplacePart, onSuccess: () => void) {
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Part']
      },
      objectSearchQueryParam: {
        isMultipleSelection: false,
        showObjClsCodes: ['Part', '']
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const objParam = objParams[0]
        // 如果选中了自己
        if (objParam.master.id == params.objParam.masterId) {
          KNotification.error({ title: '操作失败', content: '更换零部件时选择了自己。请选择其他对象。' })
          event.cancel = true
          return
        }
        const result = await Api.post('part', 'BomLink', 'replaceChild', {
          data: [
            {
              bomLink: {
                id: params.bomLinkParam.id,
                clazz: params.bomLinkParam.modelCode
              },
              child: {
                id: objParam.master.id,
                clazz: objParam.master.rdmExtensionType
              }
            }
          ]
        })

        if (!(result && result.success)) {
          KNotification.error({ title: '操作失败', content: result.message, details: result.detail })
          event.cancel = true
          return
        }

        await onSuccess?.()
      }
    })
  }

  /**
   * 查找并替换零件
   * @param params 节点参数
   * @param onSuccess 成功回调
   */
  static replaceWorkingCopyChild(params: CreateAddReplacePart, onSuccess: () => void) {
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Part']
      },
      objectSearchQueryParam: {
        isMultipleSelection: false,
        showObjClsCodes: ['Part', '']
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          onSuccess?.()
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          onSuccess?.()
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          onSuccess?.()
          return
        }
        const objParam = objParams[0]
        if (objParam.partView.name != params.partView) {
          KNotification.error({ title: '更换失败', content: '不可添加更换不同视图的零部件。请选择其他对象。' })
          event.cancel = true
          return
        }
        // 如果选中了自己
        if (objParam.master.id == params.objParam.masterId) {
          KNotification.error({ title: '操作失败', content: '更换零部件时选择了自己。请选择其他对象。' })
          event.cancel = true
          return
        }
        const result = await Api.post('part', 'BomLink', 'replaceWorkingCopyChild', {
          data: [
            {
              bomLink: {
                id: params.bomLinkParam.id,
                clazz: params.bomLinkParam.modelCode
              },
              child: {
                id: objParam.master.id,
                clazz: objParam.master.rdmExtensionType
              },
              parent: {
                id: params.parentObjParam.id,
                clazz: params.parentObjParam.modelCode
              }
            }
          ]
        })
        onSuccess?.()

        if (!(result && result.success)) {
          KNotification.error({ title: '操作失败', content: result.message, details: result.detail })
          event.cancel = true
          return
        }
      }
    })
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KDistributionCreateView from './KDistributionCreate.vue'
import KDistributionCreateViewModel from './KDistributionCreateViewModel'

const KDistributionCreate = connect(KDistributionCreateView, KDistributionCreateViewModel)

export default withInstall(KDistributionCreate)
export { KDistributionCreate, KDistributionCreateView, KDistributionCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

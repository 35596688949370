import { connect, withInstall } from '@kmsoft/upf-core'
import KFileUploadView from './KFileUpload.vue'
import KFileUploadViewModel from './KFileUploadViewModel'

const KFileUpload = connect(KFileUploadView, KFileUploadViewModel)

export default withInstall(KFileUpload)
export { KFileUpload, KFileUploadView, KFileUploadViewModel }

// 模板生成文件
// export * from './{{folderName}}'

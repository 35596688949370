import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectPanelView from './KObjectPanel'
import KObjectPanelViewModel from './KObjectPanelViewModel'

const KObjectPanel = connect(KObjectPanelView, KObjectPanelViewModel)

export default withInstall(KObjectPanel)
export { KObjectPanel, KObjectPanelView, KObjectPanelViewModel }
export * from './interface'

import { BaseViewModel, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KBaselineCompareGridDialogEmitsType, KBaselineCompareGridDialogPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** KBaselineCompareGridDialog */
export default class KBaselineCompareGridDialogViewModel extends BaseViewModel<
  KBaselineCompareGridDialogEmitsType,
  KBaselineCompareGridDialogPropType
> {
  refDataGrid = ref<KDataGridViewModel>()

  constructor(options: ViewModelOptions<KBaselineCompareGridDialogPropType>) {
    super(options)
  }

  viewDidMount() {
    // this.loadData()
    // console.log(11111111)
  }

  async loadData() {
    const params = {
      data: [
        {
          sort: 'DESC',
          orderBy: 'lastUpdateTime',
          filter: {},
          isNeedTotal: true
        },
        {
          curPage: 1,
          pageSize: 50
        }
      ]
    }
    const result = (await Api.post('baseline', 'ManagedBaseline', 'page', params)) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      return {
        rows: result.data.data,
        total: result.data.page.totalRows
      }
    }
  }

  getSelectedRows() {
    return this.refDataGrid.value?.getSelectedRows() || []
  }
}

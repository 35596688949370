import { BaseViewModel, GlobalException, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KObjectClassGridFilterEventEmitsType, KObjectClassGridFilterPropType, ObjectClassGridFilterResult } from './interface'
import { KRuleDefinerViewModel, PropertyConverter } from '../rule-definer'
/**
 * 对象网格过滤器
 */
export class KObjectClassGridFilterViewModel extends BaseViewModel<
  KObjectClassGridFilterEventEmitsType,
  KObjectClassGridFilterPropType
> {
  /** 属性定义引用 */
  refSearcher = ref<KRuleDefinerViewModel>()
  /** 忽略大小写 */
  isIgnoreCase = ref<boolean>(true)

  /**
   * 构造函数
   * @param options 选项
   */
  constructor(options: ViewModelOptions<KObjectClassGridFilterPropType>) {
    super(options)
  }

  /**
   * 加载完成函数
   */
  viewDidMount() {}

  /**
   * 获取筛选结果
   * @returns
   */
  getFilterResult(): ObjectClassGridFilterResult | undefined {
    /** 获取条件 */
    const condition = this.refSearcher.value?.getCondition()

    // 如果没有条件
    if (!condition) {
      return
    }

    /** 将条件转换为过滤信息 */
    const filter = PropertyConverter.convertConditionToRule(condition)

    if (filter) {
      if (filter.rules.length <= 0) {
        return
      }

      const filterTree = PropertyConverter.advancedSearchFilterToSearchFilterTree(filter)

      return {
        condition: condition,
        filter: filterTree
      }
    }

    GlobalException.getSuggestionException('过滤器表单无有效项，请检查是否有未填写项')
  }
}

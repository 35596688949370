import { AppContext, BaseViewModel, DateTime, KFormViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { IKFolderFormDef, KFolderFormEmitsType, KFolderFormPropType } from './interface'
/** KFolderForm */
export default class KFolderFormViewModel extends BaseViewModel<KFolderFormEmitsType, KFolderFormPropType> {
  /**表单 */
  refFolderForm = ref<KFormViewModel>()

  //允许的对象类选项
  allowedObjectClassesOptions = ref<Array<Record<string, any>>>([])
  //表单绑定数据
  formDataState = ref<IKFolderFormDef>({
    id: '',
    name: '',
    nameEn: '',
    description: '',
    creator: '',
    createTime: new DateTime().toString(),
    parentId: ''
  })

  constructor(options: ViewModelOptions<KFolderFormPropType>) {
    super(options)
  }

  viewDidMount() {
    this.loadData()
  }

  /**
   * 获取表单数据
   * @returns 表单数据
   */
  getFormData(): IKFolderFormDef {
    return this.refFolderForm.value?.getValues() as IKFolderFormDef
  }

  loadData() {
    const formData = this.formDataState.value
    const formProp = this.props.formProp
    if (formProp) {
      formData.id = formProp.id!
      formData.name = formProp.name!
      formData.nameEn = formProp.nameEn
      formData.description = formProp.description
      formData.creator = formProp.creator!
      formData.createTime = formProp.createTime!
      formData.parentId = formProp.parentId!
    }
  }

  /**
   * getNormalData
   */
  public getNormalData() {
    return this.formDataState.value
  }
}

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_input_group = _resolveComponent("k-input-group")!

  return (_ctx.$vm.readonly)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$vm.displayVal), 1))
    : (_openBlock(), _createBlock(_component_k_input_group, {
        key: 1,
        class: "k-reference-editor",
        onMouseover: _cache[3] || (_cache[3] = () => _ctx.$vm.inputMouseOver()),
        onMouseout: _cache[4] || (_cache[4] = () => _ctx.$vm.inputMouseOut()),
        compact: "",
        ref: "refInputGroup"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            class: "k-reference-editor-input",
            value: _ctx.$vm.displayVal,
            disabled: _ctx.$vm.disabled,
            onChange: _cache[1] || (_cache[1] = (args) => _ctx.$vm.processMeta(args.newValue))
          }, {
            suffix: _withCtx(() => [
              _withDirectives(_createElementVNode("span", {
                class: "clear-icon",
                onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.clear())
              }, [
                _createVNode(_component_k_icon, { type: "clear" })
              ], 512), [
                [_vShow, _ctx.$vm.isShowClear]
              ])
            ]),
            _: 1
          }, 8, ["value", "disabled"]),
          _createVNode(_component_k_button, {
            class: "k-reference-editor-selector",
            onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.openObjectSelector()),
            disabled: _ctx.$vm.disabled
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_icon, { type: "select-arrow" })
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }, 512))
}
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

export interface IModuleSearchDefinition {
  code: string
  name: string
  remark: string
  pattern: string
  folder: boolean
  config: boolean
  branch: string
}

// 页面事件
export const KModuleSearchEventEmits = {
  ...BaseViewEventEmits
}

export const KModuleSearchViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def(''),
  treeId: VuePropTypes.string().def('')
}

export type KModuleSearchViewModelPropType = ViewPropsTypeExtract<typeof KModuleSearchViewModelPropOptions>

export type KModuleSearchEventEmitsType = ViewEmitsTypeExtract<typeof KModuleSearchEventEmits>

import {
  VuePropTypes,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  BaseViewEventEmits,
  BaseViewPropOptions
} from '@kmsoft/upf-core'
import { MetaProperty, QueryCondition, RuleBusiness } from '../../client-srv'
import { ISearchFilterTree } from '../rule-definer'

export type ObjectClassGridFilterResult = {
  condition: QueryCondition
  filter: ISearchFilterTree
}

/**
 * 对象网格过滤器属性选项
 */
export const KObjectClassGridFilterPropOptions = {
  ...BaseViewPropOptions,
  /** 规则业务对象 */
  filter: VuePropTypes.createType<QueryCondition>().def(),
  /** 列定义 */
  fields: VuePropTypes.array<MetaProperty>()
    .setRequired()
    .def()
}

/**
 *  对象网格过滤器属性类型
 */
export type KObjectClassGridFilterPropType = ViewPropsTypeExtract<typeof KObjectClassGridFilterPropOptions>

/**
 * 对象网格过滤器事件
 */
export const KObjectClassGridFilterEventEmits = {
  ...BaseViewEventEmits,
  /** 提交事件  */
  confirm: (form: { condition: ISearchFilterTree; isIgnoreCase: boolean }) => true
}

/** 对象网格过滤器事件类型  **/
export type KObjectClassGridFilterEventEmitsType = ViewEmitsTypeExtract<typeof KObjectClassGridFilterEventEmits>

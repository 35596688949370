import { connect, withInstall } from '@kmsoft/upf-core'
import KPublicWorkspaceView from './KPublicWorkspace.vue'
import KPublicWorkspaceViewModel from './KPublicWorkspaceViewModel'

const KPublicWorkspace = connect(KPublicWorkspaceView, KPublicWorkspaceViewModel)

export default withInstall(KPublicWorkspace)
export { KPublicWorkspace, KPublicWorkspaceView, KPublicWorkspaceViewModel }

// 模板生成文件
// export * from './{{folderName}}'

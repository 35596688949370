import {
  BaseViewPropOptions,
  ViewPropsTypeExtract,
  VuePropTypes,
  BaseViewEventEmits,
  ViewEmitsTypeExtract
} from '@kmsoft/upf-core'
import { UpdateConfigValidStatusEventArgs } from '../../sys-config-value/interface'

// 配置项定义
export interface IConfigGridComboboxDefinition {
  defId: string
  id: string
  code: string
  name: string
  values: Array<string>
  remark: string
  dataType: string
  editType: string
  editParam: string
}

// 页面事件
export const KConfigGridComboboxEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 更新配置项(模块)有效性状态
   * @param validStatus 有效性
   */
  updateConfigValidStatus: (e: UpdateConfigValidStatusEventArgs) => true
}

export const KConfigGridComboboxViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('')
}

export type KConfigGridComboboxViewModelPropType = ViewPropsTypeExtract<typeof KConfigGridComboboxViewModelPropOptions>

export type KConfigGridComboboxEventEmitsType = ViewEmitsTypeExtract<typeof KConfigGridComboboxEventEmits>

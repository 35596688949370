import { BaseViewModel, KNotification, ViewModelOptions, md5, utils } from '@kmsoft/upf-core'
import { KFileUploaderEmitsType, KFileUploaderPropType } from './interface'
import { ref, watch } from 'vue'
import {
  CheckFileExistsParam,
  ChunkInfo,
  EnumFileExistStatus,
  EnumFileUploadingStatus,
  FileClientSrv,
  FileUploadConfig,
  UploadFileInfo
} from '../../client-srv'

/** KFileUploader */
export default class KFileUploaderViewModel extends BaseViewModel<KFileUploaderEmitsType, KFileUploaderPropType> {
  /** 容器引用 */
  refUploadBody = ref<HTMLDivElement>()
  /** 上传配置 */
  uploadConfig: FileUploadConfig = FileClientSrv.getUploadConfig()
  /** 上传的文件列表 */
  localFileList = ref<Array<UploadFileInfo>>([])
  /** 所有文件未上传的分片信息列表 */
  totalChunkInfoList = ref<Array<ChunkInfo>>([])
  /** 当前正在上传的分片数量，多线程上传 */
  runningUploadThreadList = ref<Array<string>>([])

  constructor(options: ViewModelOptions<KFileUploaderPropType>) {
    super(options)

    // 监听当前运行线程的数量，上传文件
    watch(
      this.runningUploadThreadList,
      newValue => {
        // 为0时也触发了一个
        if (newValue.length < this.uploadConfig.threadCount) {
          this.uploadFile()
        }
      },
      { deep: true }
    )

    watch(
      () => options.props.fileList,
      () => {
        this.localFileList.value = utils.deepClone(this.props.fileList)
      }
    )
  }

  viewDidMount() {}

  /**
   * 获取文件列表
   * @returns
   */
  getFileList() {
    return this.localFileList.value
  }

  /**
   * 设置文件列表
   * @param fileList
   */
  setFileList(fileList: Array<UploadFileInfo>) {
    this.localFileList.value = fileList
  }

  /**
   * 文件上传
   * @param chunkInfo 分片信息
   * @returns
   */
  async uploadFile() {
    // 从totalChunkInfoList中取一个分片出来
    const chunkInfo = this.totalChunkInfoList.value.shift()
    if (!chunkInfo) return

    const fileInfo = this.getFileInfoByUid(chunkInfo.fileUid)!
    // 根据fileUId和分片index获取当前分片的数据
    chunkInfo.blob = this.getChunkBlob(fileInfo.file!, chunkInfo.chunkIndex)

    // 开启线程
    this.runningUploadThreadList.value.push(chunkInfo.fileUid)

    // 上传文件分片
    const uploadResult = await FileClientSrv.uploadFile(chunkInfo)

    if (uploadResult.state === 'success') {
      // 移除一个当前uid的线程
      const theadIndex = this.runningUploadThreadList.value.findIndex(uid => uid === fileInfo.uid)
      theadIndex !== -1 && this.runningUploadThreadList.value.splice(theadIndex, 1)

      // 将上传成功的index进行保存
      fileInfo.uploadedChunkIndexList!.push(String(chunkInfo.chunkIndex))

      // 最后一个分片会返回保存返回的文件id
      if (uploadResult.data?.tag == 1) {
        fileInfo.id = uploadResult.data.id
        fileInfo.displayLocation = uploadResult.data.displayLocation
        fileInfo.originalFileName = uploadResult.data.originalFileName
      }

      // 计算文件的上传进度
      this.handleFileUploadProgress(fileInfo)
      return true
    } else {
      // 处理失败
      this.handleUploadLoadFail(fileInfo)
      return false
    }
  }

  /**
   * 显示上传窗口
   */
  openUploadDialog() {
    const uploadButton = this.refUploadBody.value?.querySelector('input')
    uploadButton?.click()
  }

  /**
   * 移除、删除
   * @param fileInfo
   */
  async removeFile(fileInfo: UploadFileInfo): Promise<void> {
    // 将文档状态设置成removed
    this.getFileInfoByUid(fileInfo.uid!).status = EnumFileUploadingStatus.REMOVED

    // 从所有分片列表中清除当前文件的分片
    this.totalChunkInfoList.value = this.totalChunkInfoList.value.filter(chunkInfo => chunkInfo.fileUid !== fileInfo.uid)

    // 释放线程
    this.runningUploadThreadList.value = this.runningUploadThreadList.value.filter(uid => uid !== fileInfo.uid)

    // 请求后台，进行删除操作
    if (fileInfo.id) {
      const res = await FileClientSrv.removeFile({ fileId: fileInfo.id, fileLocation: fileInfo.location })

      if (res.state === 'success') {
        // 删除成功
        this.localFileList.value = this.localFileList.value.filter(item => item.uid !== fileInfo.uid)
        this.emit('remove', fileInfo)
        this.onChangeFileList(this.localFileList.value)
      }
    }
  }

  /**
   * 文件下载
   * @param fileInfo
   * @returns
   */
  async downloadFile(fileInfo: UploadFileInfo) {
    if (!fileInfo.id) {
      return KNotification.error({
        title: '文件下载失败',
        content: '文件id不存在。'
      })
    }

    // 下载整个文件
    await FileClientSrv.downloadFile({
      id: fileInfo.uid!,
      location: fileInfo.location,
      startIndex: 0,
      endIndex: fileInfo.totalChunkCount! - 1,
      fileName: fileInfo.name
    })
  }

  //#region 私有方法

  /** 检测文件是否已经上传过 */
  private async getFileExistStatus(fileInfo: UploadFileInfo) {
    const checkFileExistParam: CheckFileExistsParam = {
      fileName: fileInfo.name,
      size: String(fileInfo.size!),
      md5: fileInfo.md5!
    }

    return FileClientSrv.getFileExistStatus(checkFileExistParam)
  }

  /**
   * 获取文件的分片信息
   * @param fileInfo 文件信息
   * @param uploadedChunkIndexList 已经上传的分片索引
   * @returns
   */
  private getFileChunkInfoList(fileInfo: UploadFileInfo, uploadedChunkIndexList: Array<String> = []): Array<ChunkInfo> {
    const totalChunkCount = fileInfo.totalChunkCount!
    const chunks = [] as Array<ChunkInfo>
    for (let index = 0; index < totalChunkCount; index++) {
      // 添加历史未上传的chunk index
      if (!uploadedChunkIndexList.includes(String(index))) {
        chunks.push({
          fileUid: fileInfo.uid!,
          fileName: fileInfo.name,
          fileSize: fileInfo.size!,
          fileMd5: fileInfo.md5!,
          fileLocation: fileInfo.location,
          totalChunkCount,
          chunkIndex: index,
          blob: undefined
        })
      }
    }
    return chunks
  }

  /**
   * 获取文件的md5
   * @param file
   */
  private getFileMd5(fileInfo: UploadFileInfo): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(fileInfo.file)
      reader.onload = e => {
        const fileMd5 = md5((e.target?.result as string) || '')
        resolve(fileMd5)
      }
    })
  }

  /**
   * 通过uid获取到当前的文件
   * @param uid
   * @returns
   */
  private getFileInfoByUid(uid: string): UploadFileInfo {
    return this.localFileList.value.find(item => item.uid === uid)!
  }

  /**
   * 获取文件索引所在的文件流
   * @file 当前文件
   * @index 当前分片索引
   */
  private getChunkBlob(file: File, index: number) {
    const start = index * this.uploadConfig.chunkSize * 1024 * 1024
    const end = Math.min((index + 1) * this.uploadConfig.chunkSize * 1024 * 1024, file.size)
    return file.slice(start, end)
  }

  /**
   * 处理上传进度
   * @param fileInfo
   */
  private async handleFileUploadProgress(fileInfo: UploadFileInfo) {
    const currentFile = this.getFileInfoByUid(fileInfo.uid!)
    const uploadedChunkCount = currentFile.uploadedChunkIndexList!.length
    const fileChunkCount = currentFile.totalChunkCount!
    currentFile.percent = parseFloat(((100 / fileChunkCount) * uploadedChunkCount).toFixed(2))

    if (uploadedChunkCount === fileChunkCount) {
      currentFile.percent = 100

      // 如果是原来已经存在的文件，调用复制接口
      if (currentFile.historyStatus === EnumFileExistStatus.EXISTED) {
        const copyFileResult = await FileClientSrv.copyFile({ fileId: currentFile.id!, fileLocation: currentFile.location })

        if (copyFileResult?.state === 'success') {
          currentFile.id = copyFileResult?.data.id
          // 历史已经存在的和新上传的
          currentFile.status = EnumFileUploadingStatus.COPIED

          // 文件上传成功emit
          this.emit('uploaded', currentFile)
        } else {
          this.handleUploadLoadFail(fileInfo)
        }
      } else {
        currentFile.status = EnumFileUploadingStatus.DONE

        // 文件上传成功emit
        this.emit('uploaded', currentFile)
      }
    }
    // 分片上传成功emit
    this.emit('chunkUploaded', currentFile)
    this.onChangeFileList(this.localFileList.value)
  }

  /**
   * 处理上传失败
   * @param fileInfo
   */
  private handleUploadLoadFail(fileInfo: UploadFileInfo) {
    // 只在uploading状态进行改变状态等操作
    if (fileInfo.status === EnumFileUploadingStatus.UPLOADING) {
      KNotification.error({
        title: '文件上传失败',
        content: `文件上传失败 - ${fileInfo.name}`
      })

      // 置为失败状态
      this.getFileInfoByUid(fileInfo.uid!).status = EnumFileUploadingStatus.ERROR

      // 删除所有未上传的分片
      this.totalChunkInfoList.value = this.totalChunkInfoList.value.filter(chunkInfo => chunkInfo.fileUid !== fileInfo.uid)

      // 释放线程
      this.runningUploadThreadList.value = this.runningUploadThreadList.value.filter(uid => uid !== fileInfo.uid)

      this.emit('uploadFail', fileInfo)
      this.onChangeFileList(this.localFileList.value)
    }
  }

  /**
   * 文件类型限制
   * @param file
   */
  private fileTypeCheck(file: File) {
    /** 过滤类型 */
    const accepts = this.props.accept?.filter(a => !a.includes('.*') && !a.includes('/*'))

    if (accepts && accepts.length > 0) {
      /** 是否允许上传 */
      const isAllowed = accepts.some((fileType: string) => file.name.toLowerCase().endsWith(fileType))

      if (!isAllowed) {
        const errMsg = `文件类型错误：只能上传${this.props.accept!.join(',')}类型的文件`
        KNotification.error({ title: '文件类型错误', content: errMsg })
        throw new Error(errMsg)
      }
    }
  }

  /**
   * 计算文件的分片数量
   * @param file
   * @returns
   */
  private getFileChunkCount(file: File) {
    return Math.ceil(file.size / (this.uploadConfig.chunkSize * 1024 * 1024))
  }

  /**
   * 已经上传的分片模拟上传动作
   * @param fileInfo
   * @param uploadedChunkIndexList
   */
  private async virtualUploadProgress(fileInfo: UploadFileInfo, uploadedChunkIndexList: Array<String>) {
    for (let index = 0; index < uploadedChunkIndexList.length; index++) {
      // 保存上传的index
      fileInfo.uploadedChunkIndexList?.push(String(index))
      // 进度条操作
      this.handleFileUploadProgress(fileInfo)
      await new Promise(resolve => setTimeout(resolve, 500))
    }
  }
  //#endregion

  //#region 事件
  /**
   * 上传前操作，获取文件的基本信息，如md5等
   * @param file
   */
  async onBeforeUpload(file: File & { uid: string }) {
    // 文件类型限制
    this.fileTypeCheck(file)
    const totalChunkCount = this.getFileChunkCount(file)
    const fileInfo: UploadFileInfo = {
      id: '',
      location: '',
      uid: file.uid,
      name: file.name,
      originalFileName: file.name,
      size: file.size.toString(),
      md5: '',
      status: EnumFileUploadingStatus.UPLOADING,
      percent: 0,
      file: file,
      uploadedChunkIndexList: [],
      totalChunkCount,
      historyStatus: EnumFileExistStatus.NEW
    }

    // 获取文件的md5
    const fileMd5 = await this.getFileMd5(fileInfo)

    fileInfo.md5 = fileMd5

    // 判断文件是否已经存在
    const fileExistResult = await this.getFileExistStatus(fileInfo)

    // 保存数据
    this.localFileList.value.push(fileInfo)

    if (fileExistResult?.isSuccess) {
      const fileExistResponseData = fileExistResult.data
      // 如果已经存在，则不再往下进行，模拟秒传的动画效果
      let uploadedChunkIndexList = [] as Array<string>
      if (fileExistResponseData?.tag == EnumFileExistStatus.EXISTED) {
        uploadedChunkIndexList = [...Array(totalChunkCount)].map((_, i) => String(i))
        fileInfo.historyStatus = EnumFileExistStatus.EXISTED
      }

      if (fileExistResponseData?.tag == EnumFileExistStatus.HALF_EXISTED) {
        uploadedChunkIndexList = fileExistResponseData.index?.split(',') || []
      }

      fileInfo.id = fileExistResponseData?.fileId !== undefined ? fileExistResponseData?.fileId!.toString() : undefined
      fileInfo.location = fileExistResponseData?.location !== undefined ? fileExistResponseData?.location! : '1'

      // 模拟上传动作
      this.virtualUploadProgress(fileInfo, uploadedChunkIndexList)

      // 保存文件未上传的分片数据到 totalChunkInfoList
      if (fileInfo.status === EnumFileUploadingStatus.UPLOADING) {
        const chunkList = this.getFileChunkInfoList(fileInfo, uploadedChunkIndexList)
        this.totalChunkInfoList.value.push(...chunkList)
      }

      // 文件上传
      if (this.runningUploadThreadList.value.length < this.uploadConfig.threadCount) {
        this.uploadFile()
      }
    } else {
      this.handleUploadLoadFail(fileInfo)
    }
  }

  /**
   * 文件更改事件
   * @param value
   */
  onChangeFileList(value: Array<UploadFileInfo>) {
    this.emit('change', value)
    this.emit('update:fileList', value)
  }
  //#endregion
}

import { EnumAlignType, EnumGridElementType, KGridColumn, IKDataGridColumnSortItem } from '@kmsoft/upf-core'
import { createVNode, ref, resolveComponent } from 'vue'
import { MetaProperty, DOC_FILE_FLAG_COLUMN_NAME, EnumDataType } from '../../client-srv'

export function getFnLocalSort(
  sortForm: IKDataGridColumnSortItem[]
): (lhs: Record<string, any>, rhs: Record<string, any>) => number {
  return (lhs, rhs) => {
    for (const sortItem of sortForm) {
      const key = sortItem.property
      const method = sortItem.direction
      const [lhsVal, rhsVal] = [lhs[key], rhs[key]]
      let cmpResult
      if (typeof lhsVal === 'number' && typeof rhsVal === 'number') {
        cmpResult = lhsVal - rhsVal
      } else if (typeof lhsVal === 'string' && typeof rhsVal === 'string') {
        cmpResult = lhsVal.localeCompare(rhsVal)
      } else if (typeof lhsVal === 'boolean' && typeof rhsVal === 'boolean') {
        cmpResult = Number(lhsVal) - Number(rhsVal)
      } else {
        cmpResult = 0
      }
      if (cmpResult === 0) {
        continue
      } else {
        if (method === 'desc') cmpResult = -cmpResult
        return cmpResult
      }
    }
    return 0
  }
}

export const createDocFileFlagColumnField = (): MetaProperty => {
  return {
    columnName: DOC_FILE_FLAG_COLUMN_NAME,
    id: DOC_FILE_FLAG_COLUMN_NAME,
    name: '',
    code: '',
    index: -1000,
    length: 0,
    nullable: true,
    readonly: false,
    visible: true,
    type: EnumDataType.STRING,
    defaultValue: '',
    changeable: false,
    precision: 0,
    scale: 0,
    classCode: '',
    propArgs: '',
    classId: '',
    displayName: '',
    referenceClassCode: '',
    enumInfo: null,
    editor: {
      /** 属性id */
      propertyId: '',
      /** 属性编码 */
      propertyCode: '',
      /** 所属对象类编码 */
      belongClassCode: '',
      /** 是否可变 */
      changeable: true,
      /** 是否只读 */
      readable: true,
      /** 是否显示 */
      visible: true,
      /** 默认值 */
      defaultValue: '',
      /** 编辑类型 */
      editType: 0,
      /** 编辑参数 */
      editArgs: '',
      /**可为空 */
      nullable: false
    }
  }
}

export const createDocFileFlagColumnSchema = (): KGridColumn => {
  return {
    control: 'KGridColumn',
    type: EnumGridElementType.COLUMN,
    title: '',
    name: DOC_FILE_FLAG_COLUMN_NAME,
    visible: true,
    disabled: true,
    readonly: true,
    props: {
      required: true,
      minWidth: 50,
      width: 50,
      align: EnumAlignType.CENTER,
      frozen: false,
      rowDrag: false
    }
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KPartMaterialFormPropOptions, KPartMaterialFormEventEmits, MaterialProps, EnumSource } from './interface'
import KPartMaterialFormViewModel from './KPartMaterialFormViewModel'

export default defineView({
  name: 'KPartMaterialForm',
  props: KPartMaterialFormPropOptions,
  emits: KPartMaterialFormEventEmits,
  viewModel: KPartMaterialFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const materialPropOptions = MaterialProps._list
    return { materialPropOptions }
  }
})

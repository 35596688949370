import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    name: "objectClassLayoutDefinitionForm",
    model: _ctx.$vm.formData,
    layout: _ctx.formStyle.layout,
    "label-col": _ctx.formStyle.labelCol,
    "wrapper-col": _ctx.formStyle.wrapperCol
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, {
        label: "布局编码",
        name: "code",
        labelAlign: "right",
        required: true,
        rules: [{ required: true, message: '布局编码不能为空', trigger: 'blur' }]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formData.code,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formData.code) = $event)),
            style: {"width":"100%"},
            maxlength: 64,
            disabled: _ctx.operationType === 'detail'
          }, null, 8, ["value", "disabled"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, {
        label: "布局名称",
        name: "name",
        labelAlign: "right",
        required: true,
        rules: [{ required: true, message: '布局名称不能为空', trigger: 'blur' }]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formData.name,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formData.name) = $event)),
            style: {"width":"100%"},
            maxlength: 64,
            disabled: _ctx.operationType === 'detail'
          }, null, 8, ["value", "disabled"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, {
        label: "布局类型",
        name: "type",
        required: true,
        rules: [{ required: true, message: '布局类型不能为空', trigger: 'blur' }]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_select, {
            value: _ctx.$vm.formData.type,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.formData.type) = $event)),
            options: _ctx.typeOptions,
            disabled: _ctx.operationType === 'detail'
          }, null, 8, ["value", "options", "disabled"])
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(_component_k_form_item, {
        label: "复制布局",
        name: "sourceLayoutId",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formData.sourceLayoutName,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.formData.sourceLayoutName) = $event)),
            placeholder: "请选中需要复制的布局",
            style: {"width":"80%"},
            disabled: true
          }, null, 8, ["value"]),
          _createVNode(_component_k_button, {
            onClick: _cache[4] || (_cache[4] = () => _ctx.$vm.getSourceLayout()),
            style: {"width":"20%"}
          }, {
            default: _withCtx(() => [
              _createTextVNode("...")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.operationType === 'create']
      ]),
      _createVNode(_component_k_form_item, {
        label: "描述",
        name: "remark"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_textarea, {
            rows: 4,
            value: _ctx.$vm.formData.remark,
            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$vm.formData.remark) = $event)),
            multiline: true,
            visible: true,
            maxlength: 512,
            disabled: _ctx.operationType === 'detail'
          }, null, 8, ["value", "disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "layout", "label-col", "wrapper-col"]))
}
import { connect, withInstall } from '@kmsoft/upf-core'
import KLoginView from './KLogin.vue'
import KLoginViewModel from './KLoginViewModel'

const KLogin = connect(KLoginView, KLoginViewModel)

export default withInstall(KLogin)
export { KLogin, KLoginView, KLoginViewModel }

// 模板生成文件
// export * from './{{folderName}}'

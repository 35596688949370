import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'

// 配置项定义
export interface IConfigGridListDefinition {
  defId: string
  id: string
  code: string
  remark: string
  orderId: number
  value1: string
  value2: string
  value3: string
  value4: string
  value5: string
  value6: string
  value7: string
  value8: string
  value9: string
  value10: string
  value11: string
  value12: string
  value13: string
  value14: string
  value15: string
  value16: string
  value17: string
  value18: string
  value19: string
  value20: string
}

// 页面事件
export const KConfigGridListEventEmits = {
  ...BaseViewEventEmits
}

export const KConfigGridListViewModelPropOptions = {
  ...BaseViewPropOptions
}

export type KConfigGridListViewModelPropType = ViewPropsTypeExtract<typeof KConfigGridListViewModelPropOptions>

export type KConfigGridListEventEmitsType = ViewEmitsTypeExtract<typeof KConfigGridListEventEmits>

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

// 页面事件
export const KConfigGridEventEmits = {
  ...BaseViewEventEmits
}

export const KConfigGridViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def(''),
  /**
   * 以下六个参数取值: 当配置树点击目录节点时, 通过标签属性的方式传递参数
   */
  defId: VuePropTypes.string().def(''),
  treeId: VuePropTypes.string().def(''),
  code: VuePropTypes.string().def(''),
  editType: VuePropTypes.string().def(''),
  editParam: VuePropTypes.string().def(''),
  oper: VuePropTypes.string().def('')
}

export type KConfigGridViewModelPropType = ViewPropsTypeExtract<typeof KConfigGridViewModelPropOptions>
export type KConfigGridEventEmitsType = ViewEmitsTypeExtract<typeof KConfigGridEventEmits>

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewPropsTypeExtract,
  VuePropTypes,
  ViewEmitsTypeExtract
} from '@kmsoft/upf-core'
import { EnumClsDataType, EnumClsEditorType } from '../../enums'
import { IClassTreeNode } from '../../../../tree/interface'

// 对象界面定义定义事件
export const KCustomPropFormEventEmits = {
  ...BaseViewEventEmits
}

// 对象界面定义定义属性选项
export const KCustomPropFormPropOptions = {
  ...BaseViewPropOptions,
  /** 当前属性id */
  propertyId: VuePropTypes.string().def(),
  /** 当前编辑对象类的数据类型 */
  dataType: VuePropTypes.createType<EnumClsDataType>().def(),
  /** 当前编辑对象类的数据的编辑类型 */
  editType: VuePropTypes.createType<EnumClsEditorType>().def(),
  /** 当前编辑类型的自定义参数 */
  editArgs: VuePropTypes.string().def(),
  /** 属性参数，如对象类属性的关联属性信息等 */
  propertyArgs: VuePropTypes.string().def(),
  /** 是否只读 */
  readonly: VuePropTypes.bool().def(false),
  /** 选中节点 */
  selectedNode: VuePropTypes.createType<IClassTreeNode>()
    .setRequired()
    .def()
}

// emit type
export type KCustomPropFormEventEmitsType = ViewEmitsTypeExtract<typeof KCustomPropFormEventEmits>

// 对象界面定义定义属性类型
export type KCustomPropFormPropType = ViewPropsTypeExtract<typeof KCustomPropFormPropOptions>

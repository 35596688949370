import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumToolStripItemKeys } from './types'
import {
  toolStripItemFind,
  toolStripItemDivider,
  toolStripItemRefresh,
  toolStripItemExportExcel,
  toolStripItemSort,
  toolStripItemFilter,
  toolStripItemCancelFilter
} from './base'
import { ObjectToolStripItem } from '../object-tool-strip'

/** 操作工具栏定义 */
export const getGridOperationToolStrip = (): ObjectToolStripItem => {
  return {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_GRID_OPERATION,
    title: '列表',
    icon: 'DropGridOp',
    visible: true,
    compType: EnumToolStripCompType.CONTEXT_MENU,
    items: [
      toolStripItemFind,
      // toolStripItemSort,
      // toolStripItemFilter,
      // toolStripItemCancelFilter,
      toolStripItemDivider(),
      toolStripItemRefresh,
      toolStripItemDivider(),
      toolStripItemDivider(),
      toolStripItemExportExcel
    ]
  }
}


import { EnumDataGridRowModelType, EnumDataGridRowSpanType, defineView } from '@kmsoft/upf-core'
import { KFlowApprovalProcessPropOptions, KFlowApprovalProcessEventEmits } from './interface'
import KFlowApprovalProcessViewModel from './KFlowApprovalProcessViewModel'
import { computed, ref } from 'vue'

export default defineView({
  name: 'KFlowApprovalProcess',
  props: KFlowApprovalProcessPropOptions,
  emits: KFlowApprovalProcessEventEmits,
  viewModel: KFlowApprovalProcessViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const activeKey = ref(['1'])
    const tabActiveKey = ref('1')

    return {
      activeKey,
      tabActiveKey,
      executorRowModelType: EnumDataGridRowModelType.CLIENT_SIDE,
      rowSpanMode: EnumDataGridRowSpanType.SAME_ROW
    }
  }
})

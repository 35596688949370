import { App, Plugin } from 'vue'
import KFlowApprovalContent from './flow-approval-content'
import KFlowApprovalProcess from './flow-approval-process'
import KFlowDetail from './flow-detail'
import KFlowHistorySigning from './flow-history-signing'
import KTaskEvaluate from "./task-evaluate";

const components: Record<string, Plugin> = {
  KFlowApprovalContent,
  KFlowApprovalProcess,
  KFlowDetail,
  KFlowHistorySigning,
  KTaskEvaluate
}

const install = function(app: App) {
  Object.keys(components).forEach(key => {
    app.use(components[key])
  })
}

export default {
  install: install
}

/** 登录状态 */
export enum EnumLoginState {
  /** 错误 */
  Error = -1,
  /** 加载中 */
  Loading = 0,
  /** 登录中 */
  LoggingIn = 1,
  /** 登录成功 */
  Success = 2
}

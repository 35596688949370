import { BaseViewModel, EnumType, KControl, LayoutRenderElement, ViewModelOptions, utils } from '@kmsoft/upf-core'
import { EnumViewConfigTabKey, KViewConfigEventEmitType, KViewConfigPropType } from './interface'
import { computed, ref, watch } from 'vue'
import { KAttributeValueItem, KLayoutAttrsItem } from '../../../types'
import lodash from 'lodash'
import { KViewPropertyConfigPanelViewModel } from './panel'

/** 导航管理 */
export default class KViewConfigViewModel extends BaseViewModel<KViewConfigEventEmitType, KViewConfigPropType> {
  /* tab栏activeKey */
  activeTabKey = ref<string>(EnumViewConfigTabKey.PROPERTY)
  /*  属性 | 布局 组件 */
  refViewPropertyConfig = ref<KViewPropertyConfigPanelViewModel>()

  attributeValueItem = computed(() => {
    return this.__buildPropertyValue(this.props.element)
  })

  constructor(options: ViewModelOptions<KViewConfigPropType>) {
    super(options)

    watch(
      () => this.props.element,
      () => {
        // 切换到配置tab
        this.activeTabKey.value = EnumViewConfigTabKey.PROPERTY
      }
    )
  }

  /**去重 */
  __setNewArr(oldArr: Array<any>, newArr: Array<any>) {
    const combinedArray: Array<any> = [...oldArr, ...newArr].reduce((acc, current) => {
      const duplicate = acc.find((item: any) => item.name === current.name)
      if (!duplicate) {
        return [...acc, current]
      }
      return acc
    }, [])
    return combinedArray
  }

  /**
   * 构建选中属性值集合
   * @param element 当前选中控件
   **/
  __buildPropertyValue(element: LayoutRenderElement) {
    const attributeValue = {} as KAttributeValueItem
    /**选中控件属性值集合 */
    const baseAttrs = lodash.cloneDeep(element) as any

    delete baseAttrs.props
    delete baseAttrs.layout
    delete baseAttrs.events

    if (element.type === EnumType.CONTROL) {
      const controlElement = element as KControl
      baseAttrs.dataSource = controlElement.dataSource
      baseAttrs.field = controlElement.field
    }

    attributeValue.base = baseAttrs

    if ('layout' in element) {
      attributeValue.layout = element.layout
    } else {
      attributeValue.layout = {} as KLayoutAttrsItem
    }

    attributeValue.props = element.props

    return attributeValue
  }
}

import { UPF_IOC_KEYS, IAppConfigurer, IocContainer, IMenuPagePathMapping } from '@kmsoft/upf-core'
import { BaselineCreateMenuPathMapping } from './beans'
import { App } from 'vue'
import Control from './controls'
import Pages from './pages'
import KIssueReportEdit from './pages/issue-report/issue-report-edit'
import KIssueReportRelationship from './pages/issue-report/issue-report-relationship'

import KChangeRequestCreate from './pages/change-request/change-request-create'
import KChangeRequestEdit from './pages/change-request/change-request-edit'
import KChangeRequestRelationship from './pages/change-request/change-request-relationship'
import KChangeOrderCreate from './pages/change-order/change-order-create'

import KChangeActivityCreate from './pages/change-activity/change-activity-create'
import KIssueReportRelationPart from './controls/issue-report-affected-views/issue-report-relation-part'
// import { useExtension } from './extension'

export default {
  configVue: (app: App) => {
    app.use(KIssueReportEdit)
    app.use(KIssueReportRelationship)
    app.use(KChangeRequestCreate)
    app.use(KChangeRequestEdit)
    app.use(KChangeRequestRelationship)
    app.use(Control)
    app.use(Pages)
  },
  configBean: (iocContainer: IocContainer) => {
    iocContainer.registerSingleton<IMenuPagePathMapping>(UPF_IOC_KEYS.MENU_PAGE_PATH_MAPPING, new BaselineCreateMenuPathMapping())
    // useExtension(iocContainer)
  }
} as IAppConfigurer

export { KChangeActivityCreate, KChangeOrderCreate, KIssueReportRelationPart  }

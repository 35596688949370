import { connect, withInstall } from '@kmsoft/upf-core'
import KPartCreatePromoteView from './KPartCreatePromote.vue'
import KPartCreatePromoteViewModel from './KPartCreatePromoteViewModel'

const KPartCreatePromote = connect(KPartCreatePromoteView, KPartCreatePromoteViewModel)

export default withInstall(KPartCreatePromote)
export { KPartCreatePromote, KPartCreatePromoteView, KPartCreatePromoteViewModel }

// 模板生成文件
// export * from './{{folderName}}'

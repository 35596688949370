
import { defineView, IKTreeNode, IQuery } from '@kmsoft/upf-core'
import { KFolderTree } from '../folder-manage/folder-tree'
import { KCommonFolderManagePropOptions, KCommonFolderManageEventEmits } from './interface'
import KCommonFolderManageViewModel from './KCommonFolderManageViewModel'
import { isFunction } from 'lodash'

export default defineView({
  name: 'KCommonFolderManage',
  props: KCommonFolderManagePropOptions,
  emits: KCommonFolderManageEventEmits,
  components: { KFolderTree },
  viewModel: KCommonFolderManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { BaseViewModel, EnumDialogResult, EnumDialogState, KDialogClosingEvent, ViewModelOptions } from '@kmsoft/upf-core'
import { KObjectManageEmitsType, KObjectManagePropType } from './interface'
import {
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  KObjectSelectorViewModel,
  ObjectClientSrv
} from '@kmsoft/ebf-common'

/** KObjectManage */
export default class KObjectManageViewModel extends BaseViewModel<KObjectManageEmitsType, KObjectManagePropType> {
  constructor(options: ViewModelOptions<KObjectManagePropType>) {
    super(options)
  }

  viewDidMount() {}

  openCreateDialog() {
    // 打开对象查找选择框，但仅限于文档跟零部件
    const objectClassTreeProps = {
      showObjClsCodes: ['Part', 'Document', 'ManagedBaseline', 'ChangeItem'],
      defaultSelectClsCode: 'Part',
      filter: {
        joiner: EnumQueryConditionJoiner.And,
        conditions: [
          {
            conditionName: 'lifecycleState.internalName',
            operator: EnumQueryConditionOperator.EQUAL,
            conditionValues: ['RELEASED']
          }
        ]
      }
    }
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      isMultipleSelection: true,
      state: EnumDialogState.Maximum,
      objectClassManageQueryParam: objectClassTreeProps,
      objectSearchQueryParam: objectClassTreeProps,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
      }
    })
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","width":"100%","height":"100%"} }
const _hoisted_2 = { style: {"width":"45%"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"width":"10%","height":"100%","display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_5 = { style: {"width":"45%","height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_button = _resolveComponent("k-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_data_grid, {
        ref: _ctx.$vm.refExecutorDataGrid,
        rowKey: "id",
        isMultipleSelection: false,
        rowModelType: _ctx.executorRowModelType,
        onRowClick: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.refreshUserGrid(event))
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "name",
            name: "name",
            dataPropertyName: "name",
            headerText: "流程活动",
            rowSpanMode: _ctx.rowSpanMode,
            align: "center",
            width: "160"
          }, null, 8, ["rowSpanMode"]),
          _createVNode(_component_k_data_grid_template_column, {
            id: "executorName",
            name: "executorName",
            dataPropertyName: "executorName",
            headerText: "执行人名称",
            align: "center",
            width: "160"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "action",
            name: "action",
            fixed: "right",
            align: "left",
            headerText: "操作",
            width: "80"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("a", {
                class: "row-operation-item",
                onClick: (e) => _ctx.$vm.delete(row.id)
              }, "移除", 8, _hoisted_3)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["rowModelType"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_k_button, {
        type: "primary",
        onClick: _ctx.$vm.addExecutor
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_icon, { type: "backward" })
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_k_data_grid, {
        ref: _ctx.$vm.refUserDataGrid,
        rowKey: "name",
        isMultipleSelection: true,
        loadData: _ctx.$vm.loadDataUser,
        rowModelType: _ctx.userRowModelType
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_template_column, {
            id: "name",
            name: "name",
            dataPropertyName: "name",
            headerText: "姓名",
            align: "center",
            width: "160"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "email",
            name: "email",
            dataPropertyName: "email",
            headerText: "邮箱",
            align: "center",
            width: "160"
          })
        ]),
        _: 1
      }, 8, ["loadData", "rowModelType"])
    ])
  ]))
}
import { computed, ref, watch } from 'vue'
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { CommonSearchForm, KCommonSearcherEmitsType, KCommonSearcherPropType } from './interface'
import {
  EnumClassTemplate,
  EnumDataType,
  EnumObjLifeState,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  LIFECYCLE_PROP,
  MetaProperty,
  MetaPropertyClientSrv,
  ObjectHelper,
  QueryCondition
} from '../../../../client-srv'

/** 通用搜索 */
export default class KCommonSearcherViewModel extends BaseViewModel<KCommonSearcherEmitsType, KCommonSearcherPropType> {
  /** 支持的字段 */
  supportedFields = computed(() => {
    return this.props.fields.filter(f => MetaPropertyClientSrv.isSupportSimpleSearch(f))
  })
  /** 未使用字段 */
  unusedFields = computed(() => {
    return this.supportedFields.value.filter(f => this.form.value.recordForm[f.code] === undefined)
  })
  /** 由未使用字段得到的候选列表 */
  unusedOptions = computed(() => {
    return this.unusedFields.value.map(property => ({ label: property.displayName, value: property.columnName }))
  })
  /** 字段 */
  searchProperties = ref<MetaProperty[]>([])
  /** 表单 */
  form = ref<CommonSearchForm>(this.getDefaultFormValue())
  /** 是否显示检出时间过滤字段：仅对于文档对象类才支持该过滤字段 */
  isShowCheckoutTime = ref<boolean>(false)
  /** 当前tab */
  activeKey = ref(['1'])
  /** 生命周期选项 */
  lifeStateOptions = ref<Array<{ label: string; value: EnumObjLifeState }>>([
    { label: '创建', value: EnumObjLifeState.Created },
    { label: '活动', value: EnumObjLifeState.Actived },
    { label: '稳定', value: EnumObjLifeState.Stable },
    { label: '预发布', value: EnumObjLifeState.PrePublished },
    { label: '发布', value: EnumObjLifeState.Published },
    { label: '冻结', value: EnumObjLifeState.Freezen }
  ])
  /** 即将添加的字段 */
  newProperty = ref<string | null>(null)

  constructor(options: ViewModelOptions<KCommonSearcherPropType>) {
    super(options)

    // 是否显示检出时间
    this.isShowCheckoutTime.value = options.props.modelGroup === EnumClassTemplate.DOC || options.props.modelGroup === 'doc'

    watch(
      () => [options.props.modelGroup, options.props.conditions],
      async () => {
        this.initForm()
      },
      {
        immediate: true
      }
    )

    watch(this.form, newValue => {
      if (!this.props.enableCommonOptionsForm) {
        newValue.lifeStateForm = undefined
      }
    })
  }

  viewDidMount() {}

  //#region 初始化
  /** 初始化表单 */
  private initForm() {
    /** 获取默认搜索字段 */
    this.searchProperties.value = this.getDefaultSearchFields(this.props.modelGroup, this.supportedFields.value)
    /** 初始化表单 */
    this.form.value = this.getDefaultFormValue()

    // 缓存填充
    this.searchProperties.value.forEach(property => {
      let value: string | number | null = null
      let values: QueryCondition | undefined

      if (this.props.conditions && this.props.conditions.condition && this.props.conditions.condition.conditions) {
        values = this.props.conditions.condition.conditions.find(_ => _.conditionName == property.code)
      }

      if (!values) {
        value = null
      } else if (property.type == EnumDataType.FLOAT || property.type == EnumDataType.INT || property.type == EnumDataType.LONG) {
        value = Number(values.conditionValues![0])
      } else {
        value = values.conditionValues![0]
      }

      this.form.value.recordForm[property.code] = { property: property, value: value }
    })

    //通用属性赋值
    if (this.props.conditions && this.props.conditions.lifeState) {
      this.form.value.lifeStateForm = this.props.conditions.lifeState
    }
  }

  /** 获取more表单 */
  private getDefaultFormValue(): CommonSearchForm {
    return {
      recordForm: {},
      lifeStateForm: {
        creator: '',
        creatorId: '',
        createTime: ['', ''],
        lastModifyTime: ['', ''],
        publishTime: ['', ''],
        checkoutTime: ['', ''],
        lifeCycleState: []
      }
    }
  }

  /**
   * 获取默认搜索字段
   * @param classTemplate
   * @param fields
   * @returns
   */
  private getDefaultSearchFields(classTemplate: string, fields: MetaProperty[]) {
    return fields.filter(a => a.code == 'name')
  }
  //#endregion

  /** 添加新的字段 */
  addField() {
    if (!this.newProperty.value) {
      return
    }

    const property = this.unusedFields.value.find(f => f.columnName === this.newProperty.value)

    if (!property) {
      return
    }

    this.searchProperties.value.push(property)
    this.form.value.recordForm[property.columnName] = {
      property,
      value: null
    }

    this.newProperty.value = ''
  }

  /**
   * 移除字段
   * @param field
   */
  removeField(field: MetaProperty) {
    const index = this.searchProperties.value.indexOf(field)

    if (index !== -1) {
      this.searchProperties.value.splice(index, 1)
      delete this.form.value.recordForm[field.code]
    }
  }

  /**
   * 获取条件
   * @returns
   */
  getCondition() {
    /** 表单数据 */
    const recordForm = this.form.value.recordForm
    /** 生命周期 */
    const lifeStateForm = this.form.value.lifeStateForm
    /** 条件 */
    const conditions: Array<QueryCondition> = []
    /** 条件 */
    const mainCondition: QueryCondition = { joiner: EnumQueryConditionJoiner.And, conditions: conditions }

    // 属性
    if (recordForm) {
      for (const key of Object.keys(recordForm)) {
        const formItem = recordForm[key]
        const value = formItem.value

        if (value === undefined || value === '' || value === null) {
          continue
        }

        conditions.push({
          conditionName: formItem.property.columnName,
          operator: this.resolveCommonSearchOperator(formItem.property),
          conditionValues: [ObjectHelper.objectToString(value || '')]
        })
      }
    }

    // 生命周期
    if (lifeStateForm) {
      // 创建者
      if (lifeStateForm.creatorId) {
        conditions.push({
          conditionName: LIFECYCLE_PROP.CREATOR,
          operator: EnumQueryConditionOperator.EQUAL,
          conditionValues: [lifeStateForm.creatorId]
        })
      }

      // 时间
      if (lifeStateForm.createTime) {
        this.withTimeRange(LIFECYCLE_PROP.CREATE_TIME, lifeStateForm.createTime, conditions)
      }

      if (lifeStateForm.publishTime) {
        this.withTimeRange(LIFECYCLE_PROP.PUBLISH_TIME, lifeStateForm.publishTime, conditions)
      }

      if (lifeStateForm.lastModifyTime) {
        this.withTimeRange(LIFECYCLE_PROP.LAST_MODIFY_TIME, lifeStateForm.lastModifyTime, conditions)
      }

      if (lifeStateForm.checkoutTime) {
        this.withTimeRange(LIFECYCLE_PROP.CHECKOUT_TIME, lifeStateForm.checkoutTime, conditions)
      }

      // 生命周期
      if (lifeStateForm.lifeCycleState.length !== 0) {
        const lifeCycleFilters = []

        for (const state of lifeStateForm.lifeCycleState) {
          lifeCycleFilters.push({
            key: LIFECYCLE_PROP.STATE,
            value: state.toString(),
            operator: EnumQueryConditionOperator.EQUAL
          })
        }

        conditions.push(...lifeCycleFilters)
      }
    }

    return mainCondition
  }

  /** 清空表单 */
  clear(): void {
    this.form.value = this.getDefaultFormValue()
  }

  /**
   * 通用属性
   * @returns
   */
  getLifeStateValue() {
    return this.form.value.lifeStateForm
  }

  /**
   * 返回操作符
   * @param field
   * @returns
   */
  private resolveCommonSearchOperator(property: MetaProperty): EnumQueryConditionOperator {
    // 获取一般查询的模糊匹配方式
    if (property.type === EnumDataType.STRING || property.type === EnumDataType.CHAR) {
      return EnumQueryConditionOperator.LIKE
    } else {
      return EnumQueryConditionOperator.EQUAL
    }
  }

  /**
   * 填充日期条件
   * @param conditionName
   * @param timeRange
   * @param filters
   */
  private withTimeRange(conditionName: string, timeRange: string[], filters: Array<QueryCondition>) {
    /** 开始 */
    const from = timeRange[0]
    /** 结束 */
    const to = timeRange[1]

    if (!from || !to) {
      return
    }

    filters.push({
      conditionName: conditionName,
      operator: EnumQueryConditionOperator.IN,
      conditionValues: [from, to]
    })
  }
}

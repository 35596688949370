
import { defineView } from '@kmsoft/upf-core'
import { KPartReportBuyPropOptions, KPartReportBuyEventEmits } from './interface'
import KPartReportBuyViewModel from './KPartReportBuyViewModel'
import _ from 'lodash'

export default defineView({
  name: 'KPartReportBuy',
  props: KPartReportBuyPropOptions,
  emits: KPartReportBuyEventEmits,
  viewModel: KPartReportBuyViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KWorkflowTemplateView from './KWorkflowTemplate.vue'
import KWorkflowTemplateViewModel from './KWorkflowTemplateViewModel'

const KWorkflowTemplate = connect(KWorkflowTemplateView, KWorkflowTemplateViewModel)

export default withInstall(KWorkflowTemplate)
export { KWorkflowTemplate, KWorkflowTemplateView, KWorkflowTemplateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { App, Plugin } from 'vue'
export * from './baseline-member-grid'
import KBaselineMemberGrid from './baseline-member-grid'
import KBaselinePrimaryObject from './baseline-primary-object'

const components: Record<string, Plugin> = { KBaselineMemberGrid, KBaselinePrimaryObject }

const install = function(app: App) {
  Object.keys(components).forEach(key => {
    app.use(components[key])
  })
}

export default {
  install: install
}

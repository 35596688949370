import { BaseViewModel } from '@kmsoft/upf-core'
import { KConfigGridEventEmitsType, KConfigGridViewModelPropType } from './interface'
import KConfigGridTableViewModel from './sys-config-grid-table/KConfigGridTableViewModel'
import KConfigGridListViewModel from './sys-config-grid-list/KConfigGridListViewModel'
import KConfigGridComboboxViewModel from './sys-config-grid-combobox/KConfigGridComboboxViewModel'
import { EnumOper, EnumTabType, EnumEditType } from '../../interface'
import KConfigGridTableTileViewModel from './sys-config-grid-table-tile/KConfigGridTableTileViewModel'
import KConfigGridComboboxTileViewModel from './sys-config-grid-combobox-tile/KConfigGridComboboxTileViewModel'
import { UpdateConfigValidStatusEventArgs } from '../sys-config-value/interface'
import { ref } from 'vue'

export default class KConfigGridViewModel extends BaseViewModel<KConfigGridEventEmitsType, KConfigGridViewModelPropType> {
  refSysConfigGridTableView = ref<KConfigGridTableViewModel>()
  refSysConfigGridListView = ref<KConfigGridListViewModel>()
  refSysConfigGridComboboxView = ref<KConfigGridComboboxViewModel>()
  refSysConfigGridTableTileView = ref<KConfigGridTableTileViewModel>()
  refSysConfigGridComboboxTileView = ref<KConfigGridComboboxTileViewModel>()

  tagTable = ref<boolean>(false)
  tagList = ref<boolean>(false)
  tagCombobox = ref<boolean>(false)
  editable = ref<boolean>(true)

  private defId: string
  private treeId: string
  private code: string
  private editType: string
  private editParam: string

  private oper: string
  private mode: string

  /**
   * 加载完成函数
   */
  viewDidMount() {
    const defId = this.props.defId
    const treeId = this.props.treeId
    const code = this.props.code
    const editType = this.props.editType
    const editParam = this.props.editParam
    this.oper = this.props.oper
    this.mode = EnumTabType.SYS
    this.editable.value = this.isEditable()

    if (this.oper === EnumOper.VIEW) {
      this.init(defId, treeId, code, editType, editParam, this.oper)
    }
  }

  private isEditable(): boolean {
    return this.oper === EnumOper.EDIT
  }

  /**
   * 初始化表单内容
   * @param defId 配置项编码
   * @param treeId 树节点ID
   * @param code 配置项编码
   * @param editType 编辑类型
   * @param editParam 编辑参数
   * @param oper 操作类型
   */
  public init(defId: string, treeId: string, code: string, editType: string, editParam: string, oper: string) {
    this.defId = defId
    this.treeId = treeId
    this.code = code
    this.editType = editType
    this.editParam = editParam
    this.oper = oper
    this.editable.value = this.isEditable()

    if (this.editType === EnumEditType.TABLE) {
      this.tagTable.value = true
      this.tagList.value = false
      this.tagCombobox.value = false

      if (this.oper == EnumOper.EDIT) {
        let showInsertDocTemplate = false
        if (code == 'SYS_DOC_TEMPLATE_STORE') {
          showInsertDocTemplate = true
        }
        this.refSysConfigGridTableView.value?.init(this.defId, this.treeId, this.code, this.editParam, showInsertDocTemplate)
      } else {
        this.refSysConfigGridTableTileView.value?.init(this.defId, this.treeId, this.code, this.editParam, this.oper)
      }
    }
    if (this.editType === EnumEditType.LIST) {
      this.tagTable.value = false
      this.tagList.value = true
      this.tagCombobox.value = false

      this.refSysConfigGridListView.value?.init(this.defId, this.treeId, this.oper)
    }
    if (this.editType === EnumEditType.COMBOBOX) {
      this.tagTable.value = false
      this.tagList.value = false
      this.tagCombobox.value = true

      if (this.oper == EnumOper.EDIT) {
        this.refSysConfigGridComboboxView.value?.init(this.defId, this.treeId)
      } else {
        this.refSysConfigGridComboboxTileView.value?.init(this.defId, this.treeId, this.oper)
      }
    }
  }

  /**
   * 更新配置项(模块)有效性状态
   * @param validStatus 有效性状态
   */
  public updateConfigValidStatus(validStatus: UpdateConfigValidStatusEventArgs): void {
    this.emit('updateConfigValidStatus', validStatus)
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KPersonalHomePagePropOptions, KPersonalHomePageEventEmits } from './interface'
import KPersonalHomePageViewModel from './KPersonalHomePageViewModel'
import { UseVirtualList } from '@vueuse/components'

export default defineView({
  name: 'KPersonalHomePage',
  props: KPersonalHomePagePropOptions,
  emits: KPersonalHomePageEventEmits,
  viewModel: KPersonalHomePageViewModel,
  components: { UseVirtualList },
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

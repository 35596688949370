import { KTreeNode } from '@kmsoft/upf-core'
import { MetaClass, ClassMetaClientSrv } from '../../../client-srv'
import KObjectClassTreeViewModel from '../KObjectClassTreeViewModel'
import { ObjClassProperty } from '../interface'
import KTreeNodeObjClass from './KTreeNodeObjClass'
import lodash from 'lodash'

/** 对象类树根节点 */
export default class KObjectClassTreeRootNode extends KTreeNode {
  iconType: string
  constructor() {
    super()
    this.id = '__root__'
    this.name = '全局'
    this.leaf = false
    this.iconType = 'home'
  }

  /**
   * 对象类节点的模板类型
   * @returns
   */
  objClsType(): string {
    if (this.objClassMetaProperty == null) return ''
    else return this.objClassMetaProperty.classTemplate
  }

  /**对象类元数据定义属性 */
  objClassMetaProperty?: ObjClassProperty

  async populateChildren(populateType: number): Promise<Array<KTreeNode>> {
    const newNode = [] as Array<KTreeNodeObjClass>
    /** 判断是否需要显示在节点中 */
    const host = this.host as KObjectClassTreeViewModel
    let arr = [] as Array<MetaClass>
    arr = await ClassMetaClientSrv.getBusinessObjectClassByCode(!host.props.showAllOrNot ? classCode : [])
    //是否显示指定对象(包含子)
    if (host.props.showObjClsCodes.length > 0) {
      arr = await ClassMetaClientSrv.getBusinessObjectClassByCode(host.props.showObjClsCodes)
    }
    //TODO 对属性树进行过滤: 过滤掉扩展类(SOFTENTITY)，保留实体类(ENTITY)、抽象类(SUPERCLASS)
    if (host.props.showAllOrNot) {
      arr = arr.filter((x: any) => x.entityClass === 'SUPERCLASS' || x.entityClass === 'ENTITY')
      // 实体中的code带有"EXAValue"过滤掉
      arr = arr.filter(x => x.code.indexOf('EXAValue') === -1)
    }

    //TODO:排序临时使用
    const newArray = lodash.sortBy(arr, x => x.code)

    for (let i = 0; i < newArray.length; i++) {
      const element = newArray[i]

      if (newArray[i].parentClassCode && !host.props.showBusinessObject) {
        continue
      }

      if (host.__isNodeHide(element.code)) {
        continue
      }

      newNode.push(new KTreeNodeObjClass(element, host.props.hideObjClsCodes))
    }

    return await host.__getCanAddNodes(newNode)
  }
}

//TODO:暂时关闭需求管理相关的入口
// export const classCode = ['Part', 'Document', 'ManagedBaseline', 'ChangeItem', 'Distribution', 'Requirement']
export const classCode = ['Part', 'Document', 'ManagedBaseline', 'ChangeItem', 'Distribution']


import { defineView } from '@kmsoft/upf-core'
import { KDevTabPropOptions } from './interface'
import { EnumConfigTabType } from '../../interface'
import KDevTabViewModel from './KDevTabViewModel'
import DevConfigTree from '../dev-config-tree'
import ConfigValue from '../dev-config-value'
import ConfigGrid from '../dev-config-grid'
import ConfigCustom from '../../common/config-custom'
import ConfigTile from '../dev-config-tile'

export default defineView({
  components: { DevConfigTree, ConfigValue, ConfigGrid, ConfigCustom, ConfigTile },
  name: 'DevTab',
  props: KDevTabPropOptions,
  viewModel: KDevTabViewModel,
  setup() {
    return {
      EnumConfigTabType
    }
  }
})

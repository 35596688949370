
import {
  EnumDataGridRowModelType,
  IKTreeNode,
  ToolStripItemClickedEventArgs,
  TreeViewSelectEventArgs,
  defineView
} from '@kmsoft/upf-core'
import { KPersonalChangeItemsPropOptions, KPersonalChangeItemsEventEmits } from './interface'
import KPersonalChangeItemsViewModel from './KPersonalChangeItemsViewModel'

import { KPersonalCreateChangeOrderGrid } from './form/personal-create-change-order'
import { KPersonalCreateChangeRequestGrid } from './form/personal-create-change-request'
import { KPersonalCreateChangeActivityGrid } from './form/personal-create-change-activity'
import { KPersonalChargeChangeActivityGrid } from './form/personal-charge-change-activity'

import { onActivated, onMounted } from 'vue'

export default defineView({
  name: 'KPersonalChangeItems',
  props: KPersonalChangeItemsPropOptions,
  emits: KPersonalChangeItemsEventEmits,
  viewModel: KPersonalChangeItemsViewModel,
  components: {
    KPersonalCreateChangeOrderGrid,
    KPersonalCreateChangeRequestGrid,
    KPersonalCreateChangeActivityGrid,
    KPersonalChargeChangeActivityGrid
  },
  setup() {
    onActivated(() => {
      console.log('onActivated')
    })

    onMounted(() => {
      console.log('onMounted')
    })

    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      pagination: {
        pageIndex: 1,
        pageSize: 50
      }
    }
  }
})

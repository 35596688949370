import { connect, withInstall } from '@kmsoft/upf-core'
import KPlanEditPanelView from './KPlanEditPanel.vue'
import KPlanEditPanelViewModel from './KPlanEditPanelViewModel'

const KPlanEditPanel = connect(KPlanEditPanelView, KPlanEditPanelViewModel)

export default withInstall(KPlanEditPanel)
export { KPlanEditPanel, KPlanEditPanelView, KPlanEditPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  KContainer,
  KControl,
  KGridLayoutConfig,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { DynamicViewGetControlOptionsCallback } from '../interface'

//#region 类型定义
export type ElementPure = {
  control: string
  refName: string
}
//#endregion

/** 事件 */
export const KWrapperControlEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KWrapperControlEventEmitsType = ViewEmitsTypeExtract<typeof KWrapperControlEventEmits>

/** 属性选项 **/
export const KWrapperControlPropOptions = {
  ...BaseViewPropOptions,
  /** 元数据 */
  meta: VuePropTypes.createType<KContainer | KControl>().def({} as KContainer | KControl),
  /** 父容器 */
  parentLayout: VuePropTypes.createType<KGridLayoutConfig>()
    .setRequired()
    .def(),
  /** 实例 */
  instance: VuePropTypes.createType<any>().def(),
  /**是否只读 */
  readonly: VuePropTypes.bool().def(false),
  /** 获取组件参数 */
  getControlOptions: VuePropTypes.func<DynamicViewGetControlOptionsCallback>().def()
}

/** 属性类型 **/
export type KWrapperControlPropType = ViewPropsTypeExtract<typeof KWrapperControlPropOptions>

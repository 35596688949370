
import { TreeViewCheckedEventArgs, TreeViewSelectEventArgs, defineView } from '@kmsoft/upf-core'
import { KObjectClassTreeSelectorPropOptions, KObjectClassTreeSelectorEventEmits } from './interface'
import KObjectClassTreeSelectorViewModel from './KObjectClassTreeSelectorViewModel'
import { computed } from 'vue'
import { KObjectClassTreePropOptions } from '../../../object-class-tree'
import lodash from 'lodash'

export default defineView({
  name: 'KObjectClassTreeSelector',
  props: KObjectClassTreeSelectorPropOptions,
  emits: KObjectClassTreeSelectorEventEmits,
  viewModel: KObjectClassTreeSelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /** 对象类树属性 */
    const objectClassTreeProps = computed(() => {
      const propsKeys = Object.keys(KObjectClassTreePropOptions)
      const pickKeys = lodash.pick(props, propsKeys) as Record<string, any>

      return {
        ...pickKeys,
        autoSelectFirstNodeAfterDefaultExpand: false,
        defaultSelectClsCode: props.value instanceof Array ? lodash.first(props.value) : props.value
      }
    })
    return { objectClassTreeProps }
  }
})

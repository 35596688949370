import { connect, withInstall } from '@kmsoft/upf-core'
import KRuleDefineTestView from './KRuleDefineTest.vue'
import KRuleDefineTestViewModel from './KRuleDefineTestViewModel'

const KRuleDefineTest = connect(KRuleDefineTestView, KRuleDefineTestViewModel)

export default withInstall(KRuleDefineTest)
export { KRuleDefineTest, KRuleDefineTestView, KRuleDefineTestViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView } from '@kmsoft/upf-core'
import { KWorkflowViewPropOptions, KWorkflowViewEventEmits } from './interface'
import KWorkflowViewViewModel from './KWorkflowViewViewModel'
import { ref } from 'vue'

export default defineView({
  name: 'KWorkflowView',
  props: KWorkflowViewPropOptions,
  emits: KWorkflowViewEventEmits,
  viewModel: KWorkflowViewViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    let showFlowDetailPanel = ref<boolean>(true)

    return { showFlowDetailPanel }
  }
})

import {
  AppContext,
  BaseViewModel,
  ViewModelOptions,
  KNotification,
  KDataGridRowSelectedEvent,
  ToolStripItemClickedEventArgs,
  EnumDialogResult,
  request
} from '@kmsoft/upf-core'
import {
  Api,
  EnumRequestCode,
  EBF_IOC_KEY,
  KObjectToolStripViewModel,
  ToolStripHelper,
  ObjectToolStripItem,
  KObjectClassGridViewModel,
  EnumToolStripItemKeys,
  ObjectClientSrv,
  EnumQueryConditionOperator
} from '@kmsoft/ebf-common'
import { FolderClientSrv } from '../../../../ebf-folder/src/client-srv'
import { KObjectSearchGridEmitsType, KObjectSearchGridPropType } from './interface'
import { watch, ref } from 'vue'
import { ClassMetaClientSrv } from '@kmsoft/ebf-common/src/client-srv/meta/ClassMetaClientSrv'

/** KObjectSearchGrid */
export default class KObjectSearchGridViewModel extends BaseViewModel<KObjectSearchGridEmitsType, KObjectSearchGridPropType> {
  refObjGrid = ref<KObjectClassGridViewModel>()
  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>(ToolStripHelper.getSearchGridObjectOperationToolStripItems())
  params = ref<string>(this.props.params)
  constructor(options: ViewModelOptions<KObjectSearchGridPropType>) {
    super(options)
    watch(
      () => this.params.value,
      newVal => {
        this.refObjGrid.value?.refresh()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {
    AppContext.current
      .getIocContainer()
      .registerSingleton(EBF_IOC_KEY.SET_SEARCH_PARAMS, { changeParams: this.changeParams.bind(this) })
  }

  changeToolItem(event: KDataGridRowSelectedEvent<any>) {
    const selectRows = this.refObjGrid.value?.getSelectedRows()
    if (!selectRows || selectRows.length == 0) {
      this.toolStripItems.value = [...this.toolStripItems.value]
      return
    }
    const param = { modelCode: selectRows[0].rdmExtensionType, environment: 100, selectedDatas: selectRows }
    this.refObjGrid.value?.updateToolStripState(param)
  }

  /**
   * 工具栏点击事件
   */
  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const rows = this.refObjGrid.value!.getSelectedRows()
    if (rows && rows.length > 0) {
      rows.forEach(item => {
        item.targetId = item.id
        item.targetBranchId = item.branch.id
        item.targetClass = item.className
        item.targetExtensionType = item.rdmExtensionType
      })
    } else {
      return
    }
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.add()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_CAD:
        FolderClientSrv.addToCADWorkspace(rows, this.refObjGrid.value?.getContainer())
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS:
        FolderClientSrv.saveAs(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH:
        FolderClientSrv.publish(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE:
        FolderClientSrv.structManage(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT:
        FolderClientSrv.checkOut(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT:
        FolderClientSrv.unCheckOut(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN:
        FolderClientSrv.checkIn(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE:
        FolderClientSrv.revise(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD:
        this.downloadTemplate()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC:
        FolderClientSrv.browse(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC:
        FolderClientSrv.downloadDoc(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_FILE:
        FolderClientSrv.editFile(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_FROM_LOCAL_FILE:
        FolderClientSrv.fileReplaceFromLocalFile(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_FILE_REPLACE_NEW:
        FolderClientSrv.fileReplaceNew(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_QUERY:
        FolderClientSrv.fileReplaceQuery(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_HISTORY_VERSION:
        FolderClientSrv.historyVersion(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_REMARK:
        FolderClientSrv.remark(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK:
        FolderClientSrv.viewRemark(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK_HISTORY:
        FolderClientSrv.viewRemarkHistory(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW:
        FolderClientSrv.addToWorkflow(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW:
        FolderClientSrv.viewWorkFlow(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_USING_STATISTICS:
        FolderClientSrv.usingStatistics(rows)
        break
      default:
        break
    }
  }

  /**
   * 添加对象
   */
  add() {
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      isMultipleSelection: true,
      objectClassManageQueryParam: { showObjClsCodes: ['Part', 'Document'] },
      objectSearchQueryParam: { showObjClsCodes: ['Part', 'Document'] },
      getContainer: this.refObjGrid.value?.getContainer(),
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }

        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }

        const objParams = event.viewInstance.getSelectedObjParams()

        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const modelGroup = await ObjectClientSrv.getModelGroupByCode(objParams[0]!.modelCode)
        const objIds = objParams.map(obj => obj.id)
        // 获取对象信息
        const query = {
          modelCode: objParams[0]!.modelCode,
          modelGroup: modelGroup,
          filter: {
            conditions: [
              {
                conditionName: 'rdmExtensionType',
                operator: EnumQueryConditionOperator.EQUAL,
                conditionValues: [objParams[0]!.modelCode]
              },
              {
                conditionName: 'id',
                operator: EnumQueryConditionOperator.IN,
                conditionValues: objIds
              }
            ],
            joiner: 'and'
          },
          page: {
            pageIndex: 1,
            pageSize: 1000
          }
        }
        const objs = await ObjectClientSrv.listObjects(query)
        if (objs.rows.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '未查询到对象信息'
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 下载Excel模板
   */
  async downloadTemplate() {
    // 获取模板配置
    const fetchByCode = await Api.post('sys', 'ConfigSysQueryService', 'fetchByCode', {
      data: ['SYS_PART_IMPORT_TEMPLATE']
    })
    if (!(fetchByCode && fetchByCode.success)) {
      KNotification.warn({
        message: '系统提示',
        description: '无法获取零部件导入模板配置'
      })
      return
    }
    // 下载模板
    const param = {
      modelName: 'ZmsAllPropertyTest',
      fileIds: fetchByCode.data
    }
    const result = await request.post('/kmsaasFileApi/download', param, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    })
    if (result?.status == EnumRequestCode.SUCCESS && result.data) {
      const bolb = new Blob([result.data as any], { type: 'application/octet-stream,charset=UTF-8' })
      const url = URL.createObjectURL(bolb)
      const link = document.createElement('a')
      link.href = url
      link.download = '零部件导入模板.xlsx'
      link.click()
    } else {
      KNotification.error({
        title: '操作失败',
        content: '下载零部件导入模板失败'
      })
    }
  }

  changeParams(val: any) {
    this.params.value = val.data
  }

  async getParams(type: string, qry: any) {
    const getChildClasses = await ClassMetaClientSrv.getClassAndChild(type)
    const codeList = getChildClasses?.map(item => item.code)
    const params = {
      data: [
        {
          characterSet: 'UTF8',
          isNeedTotal: true,
          orderBy: 'lastUpdateTime',
          sort: 'DESC',
          filter: {
            joiner: 'and',
            conditions: [
              {
                joiner: 'or',
                conditions: [
                  {
                    conditionName: 'master.name',
                    operator: 'like',
                    conditionValues: [this.params.value]
                  },
                  {
                    conditionName: 'master.number',
                    operator: 'like',
                    conditionValues: [this.params.value]
                  }
                ]
              },
              {
                conditionName: 'rdmExtensionType',
                operator: 'in',
                conditionValues: [type, ...codeList]
              }
            ]
          }
        },
        {
          maxPageSize: 1000,
          curPage: qry.page.pageIndex,
          pageSize: 200,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    return params
  }

  async loadData(qry: any) {
    const docParam = await this.getParams('Document', qry)
    const partParam = await this.getParams('Part', qry)
    const docResult = Api.post('doc', 'Document', 'page', docParam)
    const partResult = Api.post('part', 'Part', 'page', partParam)
    return new Promise((resolve, reject) => {
      Promise.all([docResult, partResult]).then(result => {
        if (result[0].code == EnumRequestCode.SUCCESS && result[1].code == EnumRequestCode.SUCCESS) {
          const data = [...result[0].data.data, ...result[1].data.data]
          resolve(data)
        } else {
          resolve([])
          KNotification.warn({
            message: '系统提示',
            description: '查询超时，请输入更详细的查询条件'
          })
        }
      })
    })
  }

  refresh() {
    this.refObjGrid.value?.refresh()
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"padding-left":"40px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_radio_group = _resolveComponent("k-radio-group")!
  const _component_k_input_number = _resolveComponent("k-input-number")!
  const _component_k_checkbox = _resolveComponent("k-checkbox")!
  const _component_k_col = _resolveComponent("k-col")!
  const _component_k_row = _resolveComponent("k-row")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_custom_props_form = _resolveComponent("custom-props-form")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_k_form, {
      ref: "refPropForm",
      model: _ctx.$vm.formState,
      "label-col": { span: 6 },
      "wrapper-col": { span: 16 }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_k_row, {
          gutter: 24,
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_k_form_item, {
                  name: "dataType",
                  label: "数据类型"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_select, {
                      value: _ctx.$vm.formState.dataType,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formState.dataType) = $event)),
                      options: _ctx.$vm.dataTypeOptions,
                      disabled: true,
                      readonly: _ctx.operationType === 'detail'
                    }, null, 8, ["value", "options", "readonly"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_form_item, {
                  name: "name",
                  label: "属性名称"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_input, {
                      value: _ctx.$vm.formState.propName,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formState.propName) = $event)),
                      disabled: true,
                      readonly: _ctx.operationType === 'detail'
                    }, null, 8, ["value", "readonly"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_form_item, {
                  name: "code",
                  label: "属性编码"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_input, {
                      value: _ctx.$vm.formState.propCode,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.formState.propCode) = $event)),
                      disabled: true,
                      readonly: _ctx.operationType === 'detail'
                    }, null, 8, ["value", "readonly"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_form_item, {
                  name: "displayName",
                  label: "显示名"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_input, {
                      value: _ctx.$vm.formState.displayName,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.formState.displayName) = $event)),
                      readonly: _ctx.operationType === 'detail'
                    }, null, 8, ["value", "readonly"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_form_item, {
                  name: "defaultValue",
                  label: "默认值"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_input, {
                      value: _ctx.$vm.formState.defaultValue,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.$vm.formState.defaultValue) = $event)),
                      readonly: _ctx.operationType === 'detail'
                    }, null, 8, ["value", "readonly"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_form_item, {
                  name: "alignType",
                  label: "对齐方式"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_radio_group, {
                      options: [
                { label: '左', value: 1 },
                { label: '中', value: 2 },
                { label: '右', value: 3 }
              ],
                      value: _ctx.$vm.formState.alignType,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$vm.formState.alignType) = $event)),
                      readonly: _ctx.operationType === 'detail'
                    }, null, 8, ["value", "readonly"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_form_item, {
                  name: "dateLength",
                  label: "字段长度"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_input, {
                      value: _ctx.$vm.formState.dataLength,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.$vm.formState.dataLength) = $event)),
                      disabled: true,
                      readonly: _ctx.operationType === 'detail'
                    }, null, 8, ["value", "readonly"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_form_item, {
                  name: "columnWidth",
                  label: "列宽"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_input_number, {
                      type: "number",
                      min: 0,
                      value: _ctx.$vm.formState.columnWidth,
                      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.$vm.formState.columnWidth) = $event)),
                      readonly: _ctx.operationType === 'detail'
                    }, null, 8, ["value", "readonly"])
                  ]),
                  _: 1
                }),
                (_ctx.showDisplayPattern)
                  ? (_openBlock(), _createBlock(_component_k_form_item, {
                      key: 0,
                      name: "displayPattern",
                      label: "显示格式"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_k_select, {
                          readonly: _ctx.operationType === 'detail',
                          value: _ctx.$vm.formState.displayPattern,
                          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.$vm.formState.displayPattern) = $event)),
                          options: _ctx.displayPatternOptions,
                          allowClear: false
                        }, null, 8, ["readonly", "value", "options"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.showPropertyScale)
                  ? (_openBlock(), _createBlock(_component_k_form_item, {
                      key: 1,
                      name: "propertyScale",
                      label: "小数位数"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_k_input_number, {
                          value: _ctx.$vm.formState.propertyScale,
                          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.$vm.formState.propertyScale) = $event)),
                          min: 0,
                          readonly: _ctx.operationType === 'detail'
                        }, null, 8, ["value", "readonly"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_k_row, { style: {"width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_k_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_k_checkbox, {
                            checked: _ctx.$vm.formState.nullable,
                            "onUpdate:checked": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.$vm.formState.nullable) = $event)),
                            readonly: _ctx.operationType === 'detail'
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("可为空")
                            ]),
                            _: 1
                          }, 8, ["checked", "readonly"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_k_checkbox, {
                            checked: _ctx.$vm.formState.changeable,
                            "onUpdate:checked": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.$vm.formState.changeable) = $event)),
                            readonly: _ctx.operationType === 'detail'
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("可变")
                            ]),
                            _: 1
                          }, 8, ["checked", "readonly"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_k_checkbox, {
                            checked: _ctx.$vm.formState.readonly,
                            "onUpdate:checked": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.$vm.formState.readonly) = $event)),
                            readonly: _ctx.operationType === 'detail',
                            disabled: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" 只读 ")
                            ]),
                            _: 1
                          }, 8, ["checked", "readonly"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_k_checkbox, {
                            checked: _ctx.$vm.formState.visible,
                            "onUpdate:checked": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.$vm.formState.visible) = $event)),
                            readonly: _ctx.operationType === 'detail',
                            disabled: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" 显示 ")
                            ]),
                            _: 1
                          }, 8, ["checked", "readonly"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_k_checkbox, {
                            checked: _ctx.$vm.formState.predefined,
                            "onUpdate:checked": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.$vm.formState.predefined) = $event)),
                            disabled: true,
                            readonly: _ctx.operationType === 'detail'
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" 预置 ")
                            ]),
                            _: 1
                          }, 8, ["checked", "readonly"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_k_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_k_form_item, {
                  name: "remark",
                  label: "备注"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_textarea, {
                      rows: 2,
                      value: _ctx.$vm.formState.remark,
                      "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.$vm.formState.remark) = $event)),
                      readonly: _ctx.operationType === 'detail'
                    }, null, 8, ["value", "readonly"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_form_item, {
                  name: "editType",
                  label: "选择编辑类型"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_select, {
                      value: _ctx.$vm.formState.editType,
                      "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.$vm.formState.editType) = $event)),
                      readonly: _ctx.operationType === 'detail',
                      allowClear: false,
                      options: _ctx.$vm.optionalEditorTypeList,
                      onChange: _cache[17] || (_cache[17] = () => (_ctx.$vm.formState.editArgs = ''))
                    }, null, 8, ["value", "readonly", "options"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_custom_props_form, {
                  ref: "refCustomPropsForm",
                  readonly: _ctx.operationType === 'detail',
                  propertyId: _ctx.$vm.formState.propertyId,
                  dataType: _ctx.$vm.formState.dataType,
                  editType: _ctx.$vm.formState.editType,
                  propertyArgs: _ctx.$vm.formState.propertyArgs,
                  editArgs: _ctx.$vm.formState.editArgs,
                  selectedNode: _ctx.$vm.props.selectedNode
                }, null, 8, ["readonly", "propertyId", "dataType", "editType", "propertyArgs", "editArgs", "selectedNode"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
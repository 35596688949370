import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { KPartImportPropType, IPageDefinition, KPartImportEmitsType } from './interface'
import {
  AppContext,
  BaseViewModel,
  EnumDialogResult,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ViewModelOptions,
  request
} from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KPartImportErrorMsg } from './part-import-error-msg'

export default class KPartImportViewModel extends BaseViewModel<KPartImportEmitsType, KPartImportPropType> {
  constructor(options: ViewModelOptions<KPartImportPropType>) {
    super(options)
  }

  viewDidMount() {}
  modelName = ref('Part')
  attributeName = ref('attachment')
  applicationId = ref('rdm_081f14d2f8c64e1fb3c4af64fdc74f68_app')
  instanceId = ref('630413504649637888')
  isImported = ref(false)

  primaryFile = ref([])
  secondaryFile = ref([])
  // 表单响应式数据
  formState = ref<IPageDefinition>({ id: '', name: '', symbol: '', suffix: '' })
  /**
   * 设置数据源
   * @param value 表单的值
   * @returns
   */
  public setValue(value: IPageDefinition) {
    this.formState.value = value
  }

  /**
   * 获取表单的值
   * @returns 表单的值
   */
  public getValue() {
    return this.formState.value
  }

  /**
   * 下载Excel模板
   */
  async downloadTemplate() {
    // 获取模板配置
    const fetchByCode = await Api.post('sys', 'ConfigSysQueryService', 'fetchByCode', {
      data: ['SYS_PART_IMPORT_TEMPLATE']
    })
    if (!(fetchByCode && fetchByCode.success)) {
      KNotification.warn({
        message: '系统提示',
        description: '无法获取零部件导入模板配置'
      })
      return
    }

    // 下载模板
    const param = {
      modelName: this.modelName.value,
      fileIds: fetchByCode.data
    }
    const result = await request.post('/kmsaasFileApi/download', param, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    })

    if (result) {
      const bolb = new Blob([result as any], { type: 'application/octet-stream,charset=UTF-8' })
      const url = URL.createObjectURL(bolb)
      const link = document.createElement('a')
      link.href = url
      link.download = '零部件导入模板.xlsx'
      link.click()
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 零部件导入
   */
  async partImport({ file }: { file: File }) {
    this.isImported.value = true
    return this.customRequest({ file }, this.attributeName.value)
  }

  async customRequest({ file }: { file: File }, attributeName: string) {
    const storageType = '0'
    const req = new FormData()
    req.append('modelName', this.modelName.value)
    req.append('attributeName', attributeName)
    req.append('files', file)
    const result = (await request.post('/kmsaasFileApi/upload', req, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })) as any

    if (result && result.result == 'SUCCESS') {
      const data = result.data as any
      const folderId = this.props.folderId
      const param = {
        data: [
          {
            fileIds: data[0],
            modelName: this.modelName.value,
            attributeName: attributeName,
            applicationId: this.applicationId.value,
            //instanceId: this.instanceId.value,
            masterAttr: true,
            folder: {
              id: folderId,
              clazz: 'Folder'
            }
          }
        ]
      }

      const importResult = await Api.post('part', 'Part', 'importPart', param)
      this.isImported.value = false
      if (importResult && importResult.success) {
        const resultData = importResult.data as any
        if (resultData && resultData.success) {
          KNotification.success('导入成功')
          return
        } else {
          const errorMsg = resultData?.errors
          // 导入校验失败，显示错误信息
          KDialog.show({
            title: '零部件导入部分数据错误，请检查：',
            size: { width: 800, height: 600 },
            props: { errorMsg: errorMsg },
            showApply: false,
            showCancel: false,
            maximizeBox: false,
            minimizeBox: false,
            content: KPartImportErrorMsg,
            onClosing: async (event: KDialogClosingEvent) => {
              if (event.dialogResult == EnumDialogResult.Cancel) return
              if (event.dialogResult == EnumDialogResult.Close) return
            }
          })
        }
      } else {
        KNotification.error({
          title: '导入失败',
          content: importResult.message || '导入失败',
          details: importResult.detail
        })
        return
      }
    } else {
      KNotification.error({
        title: '导入失败',
        content: result.message || '导入上传文件失败',
        details: result.detail
      })
      return
    }
  }
}

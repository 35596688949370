import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"text-align":"left"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_k_tool_strip, {
      name: "enumValueToolStrip",
      class: "grid-toolstrip"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_k_tool_strip_button_item, {
          title: "撤销",
          icon: "undo",
          onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.cancel())
        })
      ]),
      _: 1
    }),
    _createVNode(_component_k_data_grid, {
      rowModelType: "serverSide",
      rowKey: "findnumberPath",
      name: "materialGrid",
      ref: _ctx.$vm.refMaterialGrid,
      loadData: _ctx.$vm.loadData,
      undoRedoCellEditing: "true",
      getCellEditable: _ctx.$vm.getCellEditable,
      isMultipleSelection: false,
      style: {"height":"94%"}
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_k_data_grid_template_column, {
          headerText: "层次号",
          dataPropertyName: "findnumberPath",
          align: "center",
          width: "150",
          readonly: "false",
          fixed: "left"
        }, {
          default: _withCtx(({ cellValue}) => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(cellValue), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "编码",
          dataPropertyName: "number",
          align: "center",
          width: "150",
          readonly: "true",
          fixed: "left"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "名称",
          dataPropertyName: "name",
          align: "center",
          width: "150",
          readonly: "false",
          fixed: "left"
        }),
        _createVNode(_component_k_data_grid_combo_box_column, {
          headerText: "来源",
          dataPropertyName: "source",
          options: _ctx.sourceOptions,
          align: "center",
          width: "150",
          allowClear: false
        }, null, 8, ["options"]),
        _createVNode(_component_k_data_grid_combo_box_column, {
          headerText: "装配模式",
          dataPropertyName: "assemblyMode",
          options: _ctx.assemblyModeOptions,
          align: "center",
          width: "150",
          allowClear: false
        }, null, 8, ["options"]),
        _createVNode(_component_k_data_grid_combo_box_column, {
          headerText: "阶段",
          dataPropertyName: "phase",
          options: _ctx.phaseOptions,
          align: "center",
          width: "150",
          allowClear: false
        }, null, 8, ["options"]),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "数量",
          dataPropertyName: "quantity",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "位号",
          dataPropertyName: "occurrence",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          headerText: "类型",
          dataPropertyName: "clazz",
          align: "center",
          width: "150",
          readonly: "false"
        }, {
          default: _withCtx(({ cellValue}) => [
            _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "版本",
          dataPropertyName: "displayVersion",
          align: "center",
          width: "150",
          readonly: "false"
        }),
        _createVNode(_component_k_data_grid_combo_box_column, {
          headerText: "状态",
          dataPropertyName: "lifecycleStateCode",
          align: "center",
          width: "150",
          options: _ctx.lifecycleStateOptions,
          readonly: "false"
        }, null, 8, ["options"]),
        _createVNode(_component_k_data_grid_combo_box_column, {
          headerText: "检出标记",
          dataPropertyName: "workingState",
          align: "center",
          width: "150",
          options: _ctx.workingStateOptions,
          readonly: "false"
        }, null, 8, ["options"]),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "创建时间",
          dataPropertyName: "createTime",
          align: "center",
          width: "150",
          readonly: "false"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "修改时间",
          dataPropertyName: "lastUpdateTime",
          align: "center",
          width: "150",
          readonly: "false"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "修改者",
          dataPropertyName: "modifier",
          align: "center",
          width: "150",
          readonly: "false"
        })
      ]),
      _: 1
    }, 8, ["loadData", "getCellEditable"])
  ], 64))
}
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartSearchFormPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KPartSearchFormPropType = ViewPropsTypeExtract<typeof KPartSearchFormPropOptions>

/** 事件 */
export const KPartSearchFormEventEmits = {
  ...BaseViewEventEmits,
  close: () => true,
  findNext: () => true,
  findPrevious: () => true,
  selectAll: () => true,
  cancelSelectAll: () => true,
  refershData: () => true,
  findTop: () => true,
  findBottom: () => true
}

/** 事件类型 **/
export type KPartSearchFormEmitsType = ViewEmitsTypeExtract<typeof KPartSearchFormEventEmits>

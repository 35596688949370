import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { KBaseEditorEventEmits, KBaseEditorPropOptions } from '../../base'

/** 参数 **/
export const KImageEditorPropOptions = {
  ...KBaseEditorPropOptions,
  /** 接受的文件类型，后缀 */
  accept: VuePropTypes.array().def(['image/*']),
  /** 是否允许清除 */
  allowClear: VuePropTypes.bool().def(),
  /** 是否显示上传按钮 */
  showUploadButton: VuePropTypes.bool().def(true),
  /** 是否显示下载按钮 */
  showDownloadButton: VuePropTypes.bool().def(true)
}

/** 参数类型 **/
export type KImageEditorPropType = ViewPropsTypeExtract<typeof KImageEditorPropOptions>

/** 事件 */
export const KImageEditorEventEmits = {
  ...KBaseEditorEventEmits
}

/** 事件类型 **/
export type KImageEditorEmitsType = ViewEmitsTypeExtract<typeof KImageEditorEventEmits>

import {
  Api,
  CommonClientSrv,
  EnumObjClassManageToolStripOptions,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPropertyEditPanelViewModel,
  EnumLifecycleState,
  ObjBusinessBase,
  ObjBusinessParam,
  ObjectClientSrv,
  ObjectToolStripItem,
  ToolStripHelper,
  EnumWorkingState,
  KObjectPropertyEditPanelBase,
  KObjectPropertyPanelViewModel,
  ToolStripFilterParams,
  EnumToolStripEnvironment
} from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  EnumItemChangeCancelType,
  EnumItemClickedCancelType,
  KNotification,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions,
  SimpleViewModel,
  ValueChangeEventArgs,
  KDialog,
  KDialogClosingEvent,
  EnumDialogResult,
  KSegmentViewModel
} from '@kmsoft/upf-core'
import { WorkflowClientSrv } from '../../../../ebf-workflow'
import { nextTick, ref, watch } from 'vue'
import { KPartStructureManage } from '..'
import lodash, { get, omit, uniqueId } from 'lodash'
import { PartClientSrv } from '../../client-srv'
import { EnumWorkState, KPartEditPanelEmitsType, KPartEditPanelPropType } from './interface'
import { KCategorySelectorViewModel } from '../category-selector'
import { ChangeManageClientSrv } from '../../../../ebf-change-manage/src/client-srv'
import { KPartCreateViewVersionForm } from '../part-create-view-version-form'
import KPartCreateViewVersionFormViewModel from '../part-create-view-version-form/KPartCreateViewVersionFormViewModel'

/** KPartEditPanel */
export default class KPartEditPanelViewModel extends BaseViewModel<KPartEditPanelEmitsType, KPartEditPanelPropType> {
  /** 属性 */
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  /** 流程任务对象参数 */
  taskParam = ref<ObjBusinessParam>()
  /** 属性面板工具栏 */
  toolStripOptions = ref<EnumObjClassManageToolStripOptions>(EnumObjClassManageToolStripOptions.ShowEdit)
  toolStripItems = ref<Array<ObjectToolStripItem>>(ToolStripHelper.getPartGeneralObjectOperationToolStripItems())
  originFormData = ref<any>()

  categorySelectorViewModel = ref<any>(null)
  categoryRenderViewModel = ref<any>(null)
  attrsCategoryRenderViewModel = ref<any>(null)
  refPartPanel = ref<HTMLElement>()

  constructor(options: ViewModelOptions<KPartEditPanelPropType>) {
    super(options)
    this.taskParam.value = options.props.objParam
    // this.toolStripItems.value.push(ToolStripHelper.getObjectLifeToolStrip())

    watch(
      () => this.originFormData.value,
      newValue => {
        if (newValue) {
          const workState = newValue['workingState']
          const latest = newValue['latest']
          this.updateToolStrip(workState, latest)
        }
      },
      {
        immediate: true,
        deep: true
      }
    )

    watch(
      () => this.props.objParam,
      () => {
        this.taskParam.value = this.props.objParam
      },
      {
        immediate: true,
        deep: true
      }
    )
  }

  viewDidMount() {}

  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    if (!this.refObjectProperty.value?.props.objParam.id) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    console.log('onToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.update(event)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART:
        PartClientSrv.checkoutPart(this.refObjectProperty.value?.props.objParam.id).then((res: any) => {
          if (res) {
            this.props.objParam.id = res.id
            this.taskParam.value!.id = res.id
            this.refObjectProperty.value?.refresh()
            this.emit('retrieve', { command: 'partNodeObjectUpdate', data: { id: res.id } })
          }
        })
        // this.checkout(event)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART:
        PartClientSrv.undoCheckoutPart(this.refObjectProperty.value?.props.objParam.id).then((res: any) => {
          if (res) {
            this.props.objParam.id = res.id
            this.taskParam.value!.id = res.id
            this.refObjectProperty.value?.refresh()
            this.emit('retrieve', { command: 'partNodeObjectUpdate', data: { id: res.id } })
          }
        })
        // this.undoCheckout(event)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART:
        PartClientSrv.checkInPart(this.refObjectProperty.value?.props.objParam.id).then((res: any) => {
          if (res) {
            this.props.objParam.id = res.id
            this.taskParam.value!.id = res.id
            this.refObjectProperty.value?.refresh()
            this.emit('retrieve', { command: 'partNodeObjectUpdate', data: { id: res.id } })
          }
        })
        // this.checkin(event)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE:
        PartClientSrv.structManage(this.refObjectProperty.value?.props.objParam.id)
        // this.structManage(event)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refObjectProperty.value?.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW:
        this.addWorkflow()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW:
        this.viewWorkflow()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC:
        this.doBrowse()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_CREATE:
        this.openCreateChangeActivityPanel(false)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_CREATE:
        this.openCreateChangeActivityPanel(true)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_ADD_ACTIVITY:
        this.addToChangeActivity(false)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_ADD_ACTIVITY:
        this.addToChangeActivity(true)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PART_VIEW_VERSION:
        this.createPartViewVersion()
        break
      default:
        break
    }
  }

  async onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    console.log('toolStripItemChange', event)

    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY:
        if (event.value) {
          const objParam = this.refObjectProperty.value?.props.objParam
          console.log('checkout', objParam?.id)
          const result = await Api.post('part', 'Part', 'tryCheckout', { data: [objParam?.id] })
          if (result && result.code == EnumRequestCode.SUCCESS && result.data !== null) {
            this.refObjectProperty.value?.refresh()
            this.refObjectProperty.value?.setReadOnly(false)
            this.props.objParam.id = result.data.id
            this.taskParam.value!.id = result.data.id
            this.emit('retrieve', { command: 'partNodeObjectUpdate', data: { id: result.data.id } })
          } else {
            KNotification.error({
              title: '检出失败',
              content: result.message || '尝试检出失败，不能编辑！',
              details: result.detail
            })
          }
        } else {
          if (await this.doEdit(event)) {
            this.refObjectProperty.value?.setReadOnly(true)
            event.itemCancelType = EnumItemChangeCancelType.CancelAll
          }
        }
        break
      default:
        break
    }
  }

  async loadData() {
    const result = (await Api.post('part', 'Part', 'get', { data: [this.props.objParam.id] })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.originFormData.value = { ...result.data }
      const clsAttrs = get(result.data, 'extAttrs', []).filter((arrFil: any) => {
        return arrFil?.type === 'CATEGORY'
      })
      const extId = get(clsAttrs, '[0].value.id', [])

      // 打开分类属性
      await this.refObjectProperty.value?.openCategoryAttributes(extId)

      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件失败',
        details: result.detail
      })
      return
    }
  }

  async doEdit(event: ToolStripItemChangeEventArgs) {
    if (await this.validate()) {
      const value = this.refObjectProperty.value?.getModifiedValue()! as any
      if (Object.keys(value).length) {
        const objParam = this.refObjectProperty.value?.props.objParam
        const reqParam = { ...value, id: objParam?.id }

        if (reqParam.attachment) {
          reqParam.attachment = reqParam.attachment.map((element: string) => {
            return { id: element }
          })
        }

        const partResult = (await Api.post('part', 'Part', 'get', { data: [objParam?.id] })) as any
        if (partResult && partResult.code == EnumRequestCode.SUCCESS) {
          const workingState = partResult.data?.workingState
          if (workingState == EnumWorkState.INWORK) {
            // 工作副本才能更新
            console.log('doEdit', reqParam)
            const result = await Api.post('part', 'Part', 'update', { data: [reqParam] })
            if (result && result.code == EnumRequestCode.SUCCESS) {
              this.refObjectProperty.value?.refresh()
              this.refObjectProperty.value?.setReadOnly(true)
              KNotification.success('操作成功！')
            } else {
              KNotification.error({
                title: '操作失败',
                content: result.message,
                details: result.detail
              })
              return false
            }
          } else {
            this.refObjectProperty.value?.setReadOnly(true)
            KNotification.success('操作成功！')
            return true
          }
        } else {
          KNotification.error({
            title: '零部件属性获取失败',
            content: partResult.message,
            details: partResult.detail
          })
          return false
        }
      } else {
        // KNotification.warn({
        //   message: '系统提示',
        //   description: '没有修改数据'
        // })
        return true
      }
    } else {
      return false
    }
  }

  async update(event: ToolStripItemClickedEventArgs) {
    if (await this.validate()) {
      const value = this.refObjectProperty.value?.getModifiedValue()! as any
      if (Object.keys(value).length) {
        const objParam = this.refObjectProperty.value?.props.objParam
        const reqParam = { ...value, id: objParam?.id }

        if (reqParam.attachment) {
          reqParam.attachment = reqParam.attachment.map((element: string) => {
            return { id: element }
          })
        }

        const result = await Api.post('part', 'Part', 'update', { data: [reqParam] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refObjectProperty.value?.refresh()
          KNotification.success('操作成功！')
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message,
            details: result.detail
          })
        }
      } else {
        KNotification.warn({
          message: '系统提示',
          description: '没有修改数据'
        })
      }
    }
  }

  /** 校验 */
  async validate() {
    try {
      return await this.refObjectProperty.value?.validate()
    } catch (error) {
      console.log('验证错误', error)
      return false
    }
  }

  // async checkout(event: ToolStripItemClickedEventArgs) {
  //   const objParam = this.refObjectProperty.value?.props.objParam
  //   console.log('checkout', objParam?.id)
  //   const result = await Api.post('part', 'Part', 'checkout', { data: [objParam?.id] })
  //   if (result && result.code == EnumRequestCode.SUCCESS) {
  //     console.info(111111111111, result.data.id)
  //     this.refObjectProperty.value?.refresh()
  //     this.props.objParam.id = result.data.id
  //     this.taskParam.value!.id = result.data.id
  //     KNotification.success('操作成功！')
  //   } else {
  //     KNotification.error({
  //       title: '操作失败',
  //       content: result.message,
  //       details: result.detail
  //     })
  //   }
  // }

  // async undoCheckout(event: ToolStripItemClickedEventArgs) {
  //   const objParam = this.refObjectProperty.value?.props.objParam
  //   console.log('undoCheckout', objParam?.id)
  //   const result = await Api.post('part', 'Part', 'undoCheckout', { data: [objParam?.id] })
  //   if (result && result.code == EnumRequestCode.SUCCESS) {
  //     console.info(111111111111, result.data.id)
  //     this.refObjectProperty.value?.refresh()
  //     this.props.objParam.id = result.data.id
  //     this.taskParam.value!.id = result.data.id
  //     KNotification.success('操作成功！')
  //   } else {
  //     KNotification.error({
  //       title: '操作失败',
  //       content: result.message,
  //       details: result.detail
  //     })
  //   }
  // }

  // async checkin(event: ToolStripItemClickedEventArgs) {
  //   const objParam = this.refObjectProperty.value?.props.objParam
  //   console.log('checkin', objParam?.id)
  //   const result = await Api.post('part', 'Part', 'checkin', { data: [objParam?.id] })
  //   if (result && result.code == EnumRequestCode.SUCCESS) {
  //     this.refObjectProperty.value?.refresh()
  //     this.props.objParam.id = result.data.id
  //     this.taskParam.value!.id = result.data.id
  //     KNotification.success('操作成功！')
  //   } else {
  //     KNotification.error({
  //       title: '操作失败',
  //       content: result.message,
  //       details: result.detail
  //     })
  //   }
  // }

  /**
   * 结构管理
   */
  async structManage(event: ToolStripItemClickedEventArgs) {
    const objParam = this.refObjectProperty.value?.props.objParam
    PartClientSrv.structManage(objParam!.id)
  }

  /**
   * 加入流程
   */
  async addWorkflow() {
    const result = (await Api.post('part', 'Part', 'get', { data: [this.props.objParam.id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      WorkflowClientSrv.addWorkflow(
        result.data.branch.id,
        result.data.rdmExtensionType,
        result.data.lifecycleStateCode,
        result.data.name,
        result.data.number,
        result.data.version
      )
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件失败',
        details: result.detail
      })
      return
    }
  }

  /**
   *
   * 查看流程
   */
  async viewWorkflow() {
    const result = (await Api.post('part', 'Part', 'get', { data: [this.props.objParam.id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      WorkflowClientSrv.viewWorkflow(result.data.branch.id, result.data.rdmExtensionType)
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件失败',
        details: result.detail
      })
      return
    }
  }

  /**
   * 浏览
   */
  async doBrowse() {
    PartClientSrv.doBrowse(this.props.objParam.id)
  }

  updateToolStrip(value: string, latest: boolean) {
    switch (value) {
      case EnumWorkState.CHECKED_IN:
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART, false)

        /*         if (!latest) {
          this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART, true)
          this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
        } */
        break
      case EnumWorkState.CHECKED_OUT:
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART, false)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART, false)
        //this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
        break
      case EnumWorkState.INWORK:
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART, true)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART, false)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART, false)

        /*         if (!latest) {
          this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART, true)
          this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART, true)
          this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
        } */
        break
      default:
        break
    }
  }

  /**
   * 创建变更活动
   */
  async openCreateChangeActivityPanel(isUpdateBefore: boolean) {
    const data = lodash.cloneDeep(this.refObjectProperty.value?.getValue()) as ObjBusinessBase
    if (!data.className) {
      data.className = data?.targetClass
    }
    data.state = data.lifecycleStateCode ? EnumLifecycleState[data.lifecycleStateCode] : '--'
    data.checkInState = data.workingState ? EnumWorkingState[data.workingState as keyof typeof EnumWorkingState] : '--'
    const container = this.props.container || this.refPartPanel.value
    if (isUpdateBefore) {
      ChangeManageClientSrv.createChangeActivity('基于修改前创建变更活动', [data], null, container, data.folder.id)
    } else {
      ChangeManageClientSrv.createChangeActivity('基于修改后创建变更活动', null, [data], container, data.folder.id)
    }
  }

  /**
   * 添加到变更活动
   */
  addToChangeActivity(isUpdateBefore: boolean) {
    const container = this.props.container || this.refPartPanel.value
    const partData = lodash.cloneDeep(this.refObjectProperty.value?.getValue()) as any

    ChangeManageClientSrv.addToChangeActivity(isUpdateBefore, [partData], container)
  }
  /**面板加载完成 */
  loaded() {
    // 分类组件
    this.categorySelectorViewModel.value = this.getByRecursion('KCategorySelector#edit', this) as KCategorySelectorViewModel | any
    // 分类select点击事件
    this.categorySelectorViewModel?.value?.on('select' as any, async (changedEventArgs: ValueChangeEventArgs | any) => {
      await this.refObjectProperty.value?.openCategoryAttributes(changedEventArgs.getValue()?.value)
    })
    // 分类清除事件
    this.categorySelectorViewModel?.value?.on('clear' as any, () => {
      this.refObjectProperty.value?.openCategoryAttributes(null)
    })
  }

  /**
   * 创建视图版本
   */
  async createPartViewVersion() {
    const versionId = this.props.objParam.id
    const partResult = (await Api.post('part', 'Part', 'get', { data: [versionId] })) as any
    if (partResult && partResult.code == EnumRequestCode.SUCCESS) {
      const part = partResult.data
      const viewId = part.partView.id
      const listResult = await Api.post('part', 'PartView', 'listChild', { data: [viewId] })
      if (listResult && listResult.code == EnumRequestCode.SUCCESS) {
        const listData = listResult.data
        if (listData.length == 0) {
          KNotification.warn({
            message: '该视图版本无子类，不可再创建视图版本'
          })
          return
        }
        const viewList = listData.map((elem: any) => {
          return {
            value: { id: elem.id, clazz: elem.rdmExtensionType },
            label: elem.description
          }
        })
        KDialog.show({
          title: '创建视图版本',
          size: { width: 400, height: 200 },
          props: { viewProps: viewList },
          showApply: false,
          maximizeBox: false,
          minimizeBox: false,
          content: KPartCreateViewVersionForm,
          onClosing: async (event: KDialogClosingEvent) => {
            if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
              return
            }
            const formViewModel = event.viewModel as KPartCreateViewVersionFormViewModel
            const formData = formViewModel.getValue() as any
            // 创建
            const result = (await Api.post('part', 'Part', 'createPartVersionView', {
              data: [
                {
                  versionId: versionId,
                  partView: formData
                }
              ]
            })) as any
            if (result && result.code == EnumRequestCode.SUCCESS) {
              KNotification.success('创建成功！')
            } else {
              KNotification.error({
                title: '创建失败',
                content: result.message || '创建零部件视图版本失败',
                details: result.detail
              })
              return
            }
          }
        })
      } else {
        KNotification.error({
          title: '获取失败',
          content: listResult.message || '获取视图列表失败',
          details: listResult.detail
        })
        return
      }
    } else {
      KNotification.error({
        title: '获取失败',
        content: partResult.message || '获取零部件失败',
        details: partResult.detail
      })
      return
    }
  }
}

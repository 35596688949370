import { KControlEvent, KControlAttribute } from '@kmsoft/upf-core'
import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../../propsConfigItemCollection'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../../layoutConfigItemCollection'

/* 属性配置项集合 */
const BASE = [PROPERTY_CONFIG_ITEM_COLLECTION.TITLE] as Array<KControlAttribute>

/**控件属性 */
const PROPS = [] as Array<KControlAttribute>
/* 布局配置项集合 */
const LAYOUT: Array<KControlAttribute> = [
  LAYOUT_CONFIG_ITEM_COLLECTION.WIDTH,
  LAYOUT_CONFIG_ITEM_COLLECTION.ALIGN,
  LAYOUT_CONFIG_ITEM_COLLECTION.FROZEN,
  LAYOUT_CONFIG_ITEM_COLLECTION.HIDE
]

const EVENT = [] as Array<KControlEvent>

export default { BASE, PROPS, LAYOUT, EVENT }

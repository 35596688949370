
import { defineView, EnumDataGridRowModelType } from '@kmsoft/upf-core'
import { EnumLifecycleState, EnumChangeType, EnumPriority, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KPersonalCreateChangeRequestGridPropOptions, KPersonalCreateChangeRequestGridEventEmits } from './interface'
import KPersonalCreateChangeRequestGridViewModel from './KPersonalCreateChangeRequestGridViewModel'
import { readonly } from 'vue'

export default defineView({
  name: 'KPersonalCreateChangeRequestGrid',
  props: KPersonalCreateChangeRequestGridPropOptions,
  emits: KPersonalCreateChangeRequestGridEventEmits,
  viewModel: KPersonalCreateChangeRequestGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      EnumLifecycleState,
      EnumLifecycleStateColor,
      EnumChangeType,
      EnumPriority,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      pagination: {
        pageIndex: 1,
        pageSize: 50
      },
      selectOptions: EnumLifecycleState._list,
      selectValuePlaceholder: '生命周期状态'
    }
  }
})

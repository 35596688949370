import { KObjectClassGridViewModel, ObjBusinessParam, ObjectClientSrv } from '@kmsoft/ebf-common'
import { BaseViewModel, KToolStripViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import KDialog from '@kmsoft/upf-core/src/web-controls/dialog/KDialog'
import { ref } from 'vue'
import { KConfigObjectGridEmitsType, KConfigObjectGridPropType } from './interface'

/** KObjectGrid */
export default class KConfigObjectGridViewModel extends BaseViewModel<KConfigObjectGridEmitsType, KConfigObjectGridPropType> {
  constructor(options: ViewModelOptions<KConfigObjectGridPropType>) {
    super(options)
  }

  refObjClsToolStrip = ref<KToolStripViewModel>()
  refObjClsGrid = ref<KObjectClassGridViewModel>()
  clsCode = ref<string>('')

  /** 配置项编码 */
  private defId: string
  /** 树节点ID */
  private treeId: string
  /** 配置项编码 */
  private code: string
  /** 编辑参数 */
  private editParam: string

  /**网格是否可编辑 */
  disabled = ref<boolean>(false)

  viewDidMount() {}

  /**
   * 初始化表单内容
   * @param defId 配置项编码
   * @param treeId 树节点ID
   * @param code 配置项参数
   * @param editParam 配置参数,当编辑方式为对象类时，editParam为对象类编码
   */
  public init(defId: string, treeId: string, code: string, editParam: string) {
    this.defId = defId
    this.treeId = treeId
    this.code = code
    this.clsCode.value = editParam
  }

  /**打开编辑编码 */
  public openEditPanel() {
    const row = this.refObjClsGrid.value?.getSelectedRow()!
    const param = {
      id: row.id,
      modelCode: this.clsCode.value
    } as ObjBusinessParam
    ObjectClientSrv.openObj(param)
  }

  /**新建对象类 */
  async addObjClass() {
    /**打开对象创建面板 */
    const createResult = await ObjectClientSrv.openCreateDialog({
      showObjClsCodes: [this.clsCode.value]
    })
    if (createResult.success) {
      const row = createResult.objBusiness!
      this.refObjClsGrid.value?.insertRow(row)
    }
  }

  /**删除对象类 */
  deleteObjClass() {
    KDialog.confirm({
      title: '是否确认删除？删除后不可恢复!',
      onOk: async () => {
        //TODO：自行调用删除接口后再删除网格行
        this.refObjClsGrid.value?.removeSelectRow()
      }
    })
  }

  /**刷新 */
  refresh() {
    this.refObjClsGrid.value?.refresh()
  }
}

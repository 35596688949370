import { UPF_IOC_KEYS, IAppConfigurer, IocContainer, IMenuPagePathMapping } from '@kmsoft/upf-core'
import { BaselineCreateMenuPathMapping } from './beans'
import { App } from 'vue'
import globalControls from './controls'
import KBaselineCreateForm from './pages/baseline-create/baseline-create-form'
import KBaselineCreate from './pages/baseline-create'
import KBaselineEdit from './pages/baseline-edit'
import  KBaselineGrid  from './pages/baseline-grid'
// import { useExtension } from './extension'

export default {
  configVue: (app: App) => {
    app.use(globalControls)
    app.use(KBaselineCreateForm)
    app.use(KBaselineEdit)
    app.use(KBaselineGrid)
  },
  configBean: (iocContainer: IocContainer) => {
    iocContainer.registerSingleton<IMenuPagePathMapping>(UPF_IOC_KEYS.MENU_PAGE_PATH_MAPPING, new BaselineCreateMenuPathMapping())
    // useExtension(iocContainer)
  }
} as IAppConfigurer

export { KBaselineCreate,KBaselineGrid }
export * from './client-srv'

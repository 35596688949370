import { IKEditorAPI } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  SimpleViewEventEmits,
  SimpleViewPropOptions,
  ValueChangeEventArgs
} from '@kmsoft/upf-core'

/** 参数 **/
export const KCategorySelectorPropOptions = {
  ...SimpleViewPropOptions,
  /**
   * 编辑器API
   * @description 编辑器对外操作 API
   */
  api: VuePropTypes.createType<IKEditorAPI>()
    .setRequired()
    .def(),
  modelValue: VuePropTypes.record().def(),
  code: VuePropTypes.string().def('Part'),
  textField: VuePropTypes.string().def('name'),
  valueField: VuePropTypes.string().def('id'),
  refreshLable: VuePropTypes.func<any>().def((value: any, arr: any) => {
    return `${value}`
  })
}

/** 参数类型 **/
export type KCategorySelectorPropType = ViewPropsTypeExtract<typeof KCategorySelectorPropOptions>

/** 事件 */
export const KCategorySelectorEventEmits = {
  ...SimpleViewEventEmits,
  select: (args: ValueChangeEventArgs) => true,
  clear: () => true
}

/** 事件类型 **/
export type KCategorySelectorEmitsType = ViewEmitsTypeExtract<typeof KCategorySelectorEventEmits>

export class refTreeData {
  /**
   * 只读：logicflow实例挂载的容器。
   */
  label: string
  value: string
  children: Array<any>
}

export type selectChangeEventArgs = {}

import { ref, watch } from 'vue'
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import {
  KCheckboxEditorCustomPropType,
  KCheckboxEditorCustomPropsEventEmitsType,
  ICheckboxEditorFormDefinition
} from './interface'

export class KCheckboxEditorFormViewModel extends BaseViewModel<
  KCheckboxEditorCustomPropsEventEmitsType,
  KCheckboxEditorCustomPropType
> {
  formState = ref<ICheckboxEditorFormDefinition>()

  constructor(options: ViewModelOptions<KCheckboxEditorCustomPropType>) {
    super(options)

    watch(
      () => options.props.propertyId,
      () => {
        // 判断editArgs是否为空
        const data = options.props.editArgs ? JSON.parse(options.props.editArgs) : {}
        this.formState.value = data as ICheckboxEditorFormDefinition
      },
      { immediate: true }
    )
  }

  // 设置表单的值
  setValue(formState: ICheckboxEditorFormDefinition) {
    this.formState.value = formState
  }

  // 获取表单的值
  getValue() {
    return this.formState.value
  }
}

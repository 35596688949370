
import { defineView } from '@kmsoft/upf-core'
import { KConfigGridTableTileViewModelPropOptions } from './interface'
import { EnumTabType } from '../../../interface'
import KConfigGridTableTileViewModel from './KConfigGridTableTileViewModel'

export default defineView({
  name: 'ConfigGridTableTile',
  props: KConfigGridTableTileViewModelPropOptions,
  viewModel: KConfigGridTableTileViewModel,
  setup() {
    return {
      EnumTabType
    }
  }
})

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** KSegmentContainer 事件 **/
export const KSegmentContainerEventEmits = {
  ...BaseViewEventEmits,
  /** 折叠状态改变事件 */
  collapseStateChange: (isCollapseState: boolean) => true
}

/** KSegmentContainer 参数 **/
export const KSegmentContainerPropOptions = {
  ...BaseViewPropOptions,
  title: VuePropTypes.string().def()
}

/** KSegmentContainer 参数类型 **/
export type KSegmentContainerPropType = ViewPropsTypeExtract<typeof KSegmentContainerPropOptions>

/** KSegmentContainer 事件协议*/
export type KSegmentContainerEventEmitsType = ViewEmitsTypeExtract<typeof KSegmentContainerEventEmits>

import lodash from 'lodash'

import { MemoryCacheBase } from '@kmsoft/upf-core'
import { LifecycleItem } from '../../../../meta'

export class ClassLifecycleMemoryCache extends MemoryCacheBase<Array<LifecycleItem>> {
  public static cacheKey = 'ClassLifecycleMemoryCache'

  constructor() {
    super()
    this.cacheKey = ClassLifecycleMemoryCache.cacheKey
  }

  public async reload() {
    this.cacheData = []
    // const res = await metaModel.listLifecycleItem()
    // const res = Api.post()
    // if (res && res.state == 'success') {
    //   this.cacheData = res.data! as Array<LifecycleItem>
    // } else {
    //   KNotification.error({
    //     title: '系统提示',
    //     content: '对象类生命周期枚举项获取失败',
    //     details: res.message
    //   })
    //   this.cacheData = []
    // }
  }

  /** 获取所有数据 */
  public getAll() {
    return lodash.cloneDeep(this.cacheData)
  }
}

import { EnumAttributeType, EnumDataType } from '@kmsoft/upf-core'
import { getCandidates } from '../interface'

/* 组件的基本属性 */
const comConfig = [
  {
    name: 'size',
    title: '大小型号',
    control: 'KSelect',
    options: getCandidates(['small', 'middle', 'large']),
    defaultValue: 'middle',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'min',
    title: '最小值',
    control: 'KInputNumber',
    defaultValue: null,
    dataType: EnumDataType.INT,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'max',
    title: '最大值',
    control: 'KInputNumber',
    defaultValue: null,
    dataType: EnumDataType.INT,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'precision',
    title: '数值精度，小数位数',
    control: 'KInputNumber',
    defaultValue: 0,
    dataType: EnumDataType.DECIMAL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'prefix',
    title: '前缀图标',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'suffix',
    title: '后缀图标',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'allowClear',
    title: '可清空',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'required',
    title: '是否必填',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  }
]

/**控件属性 */
const PROPS = [] as Array<any>

const EVENT = [
  { name: 'beforeValueChange', title: '值改变前', describe: '值改变前 的说明信息...' },
  { name: 'valueChanged', title: '值改变后', describe: '值改变后 的说明信息...' }
]

export default { PROPS, EVENT }

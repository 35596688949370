import { KDesignerControl, KDesignerToolBox } from '../../../../types/metadata'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 状态 **/
export interface IKDesignerControlPanelState {}

/** 事件 **/
export const KDesignerControlPanelEventEmits = {
  ...BaseViewEventEmits
}

/** 参数 **/
export const KDesignerControlPanelPropOptions = {
  ...BaseViewPropOptions,
  /** 是否为列配置 */
  columnConfig: VuePropTypes.bool().def(false),
  /** 转换元素格式 */
  convertElement: VuePropTypes.func<(element: KDesignerControl) => any>().def(),
  /** 不可拖拽的元素  */
  disabledDragElementMap: VuePropTypes.createType<Map<string, string>>().def(),
  /**工具箱控件元数据 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KDesignerControlPanelPropType = ViewPropsTypeExtract<typeof KDesignerControlPanelPropOptions>

/** 事件协议*/
export type KDesignerControlPanelEventEmitsType = ViewEmitsTypeExtract<typeof KDesignerControlPanelEventEmits>

import { defineView } from '@kmsoft/upf-core'
import { KFileUploaderPropOptions, KFileUploaderEventEmits } from './interface'
import KFileUploaderViewModel from './KFileUploaderViewModel'
import { createVNode, resolveComponent, watch } from 'vue'
import { KFileUploaderProgress } from './file-uploader-progress'

export default defineView({
  name: 'KFileUploader',
  props: KFileUploaderPropOptions,
  emits: KFileUploaderEventEmits,
  viewModel: KFileUploaderViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      /** 渲染上传组件 */
      const renderUpload = () => {
        /** 上传组件参数 */
        const uploadProps = {
          disabled: props.disabled || props.readonly,
          accept: props.accept,
          fileList: vm.localFileList.value,
          multiple: props.multiple,
          maxCount: props.maxCount,
          showUploadList: false,
          beforeUpload: vm.onBeforeUpload
        }

        /** 插槽 */
        const uploadSlots: Record<string, any> = {
          ...slots
        }

        // 如果显示上传按钮
        if (props.showUploadButton) {
          uploadSlots['default'] = () => {
            if (vm.localFileList.value.length <= 0) {
              return (
                <div class="upload-cover">
                  <p class="upload-icon">
                    <k-icon type="inbox" />
                  </p>
                  <p class="upload-text">点击{props.mode == 'dragger' ? '或拖动' : ''}当前区域上传文件</p>
                  <p class="upload-hint">{props.accept}</p>
                </div>
              )
            }

            return <k-button>上传</k-button>
          }
        }

        const component = props.mode === 'default' ? 'KUpload' : 'KUploadDragger'
        const uploadNode = createVNode(resolveComponent(component), { ...uploadProps }, uploadSlots)

        return uploadNode
      }

      /** 渲染进度条 */
      const renderProgress = () => {
        if (!props.showUploadList) {
          return
        }
        const progressProps = {
          disabled: props.disabled,
          readonly: props.readonly,
          fileList: vm.localFileList.value,
          onFileRemove: vm.removeFile,
          onFileDownload: vm.downloadFile
        }

        return <div class="k-file-upload-progress">{createVNode(KFileUploaderProgress, { ...progressProps })}</div>
      }

      return (
        <div
          class={['k-file-uploader', 'k-file-uploader-container', { disabled: props.disabled, 'fill-space': props.fillSpace }]}
        >
          <div class={['k-file-upload-wrapper', { 'fill-wrapper': props.fillSpace && vm.localFileList.value.length <= 0 }]}>
            <div ref={vm.refUploadBody} class="k-file-upload-body">
              {renderUpload()}
            </div>
            {renderProgress()}
          </div>
        </div>
      )
    }
  }
})

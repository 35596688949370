/**指定所请求的文件访问权限的类型。*/
export enum FileIOPermissionAccess {
  /**没有对文件和目录的访问权限。 {@link NoAccess} 不表示有效的 {@link FileIOPermissionAccess} 值，如果将它用作 GetPathList(FileIOPermissionAccess) 的参数（它需要单一值），则引发 {@link ArgumentException}。*/
  NoAccess = 0,
  /**从文件或目录中读取的访问权限。*/
  Read = 1,
  /**写入或删除文件或目录的访问权限。 {@link Write} 访问权限包括对文件和目录的删除和覆盖。*/
  Write = 2,
  /**向文件或目录追加内容的访问权限。 {@link Append} 访问权限包括创建新的文件和目录的能力。 要创建文件，还必须同时向代码授予 {@link Append} 权限和 {@link Write} 或 {@link Read} 权限。*/
  Append = 4,
  /**对路径本身中的信息的访问权限。 这可以保护路径中的敏感信息（如用户名）以及有关路径中显示的目录结构的信息。 此值不授予对路径所指代的文件或文件夹的访问权限。*/
  PathDiscovery = 8,
  /**对一个文件或目录的 {@link Append}、{@link Read}、{@link Write} 和 {@link PathDiscovery} 访问权限。 {@link AllAccess} 表示多个 {@link FileIOPermissionAccess} 值，如果将它用作 GetPathList(FileIOPermissionAccess) 方法的 access 参数（它需要单一值），则引发 {@link ArgumentException}。*/
  AllAccess = Read | Write | Append | PathDiscovery
}

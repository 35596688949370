
import { defineView } from '@kmsoft/upf-core'
import { KDocFormEventEmits, KDocFormPropOptions } from './interface'
import KDocFormViewModel from './KDocFormViewModel'

export default defineView({
  name: 'KDocForm',
  props: KDocFormPropOptions,
  emits: KDocFormEventEmits,
  viewModel: KDocFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDocBatchCreateEmitsType, KDocBatchCreatePropType, steps, components } from './interface'
import { computed, ref } from 'vue'
import { FileDataInter } from './doc-select-files/interface'
import { AddFilePropertyReadOption } from '../doc-object-create-panel'
import { Agent } from '@kmsoft/ebf-common'

/** KDocBatchCreate */
export default class KDocBatchCreateViewModel extends BaseViewModel<KDocBatchCreateEmitsType, KDocBatchCreatePropType> {
  /** 当前页步骤 **/
  currentStep = ref(0)
  /** 所有步骤 **/
  steps = ref(steps)
  /** 当前页组件 **/
  componentName = computed(() => {
    return components.find(item => item.step === this.currentStep.value)?.component
  })

  /** 文件列表 **/
  fileList = ref<FileDataInter[]>([])
  /** 文档类型 **/
  documentType = ref('')
  /** 文档属性参数 **/
  filePropertyOption = ref<AddFilePropertyReadOption>()
  /** 代理程序是否已打开 **/
  agentOpened = ref<boolean>(false)
  constructor(options: ViewModelOptions<KDocBatchCreatePropType>) {
    super(options)
  }

  viewDidMount() {
    this.checkAgentOpened()
  }

  async checkAgentOpened() {
    //检测代理程序是否已打开，这里只检测一次，防止后面每次都检测
    if (await Agent.AgentManager.initialize()) {
      this.agentOpened.value = true
    } else {
      this.agentOpened.value = false
    }
  }

  addFile(file: FileDataInter) {
    // 文件名相当不添加
    if (this.fileList.value.find(item => item.fileName === file.fileName)) {
      return
    }
    this.fileList.value.push(file)
  }

  removeFile(file: FileDataInter) {
    this.fileList.value = this.fileList.value.filter(item => item.tempId !== file.tempId)
  }

  previousStep() {
    const previousStep = this.currentStep.value - 1
    if (previousStep >= 0) {
      this.currentStep.value = previousStep
    }
  }

  nextStep() {
    const nextStep = this.currentStep.value + 1
    if (nextStep < this.steps.value.length) {
      this.currentStep.value = nextStep
    }
  }

  closeDialog() {
    this.props.dialog &&
      this.props.dialog().close({
        dialogResult: 'complete'
      })
  }
}

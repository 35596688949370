import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { KBaseEditorEventEmits, KBaseEditorPropOptions } from '../../base'

/** 参数 **/
export const KFileEditorPropOptions = {
  ...KBaseEditorPropOptions,
  /** 接受的文件类型，后缀 */
  accept: VuePropTypes.array().def(),
  /** 是否允许清除 */
  allowClear: VuePropTypes.bool().def(),
  /** 是否显示上传按钮 */
  showUploadButton: VuePropTypes.bool().def(true),
  /** 是否显示下载按钮 */
  showDownloadButton: VuePropTypes.bool().def(true)
}

/** 参数类型 **/
export type KFileEditorPropType = ViewPropsTypeExtract<typeof KFileEditorPropOptions>

/** 事件 */
export const KFileEditorEventEmits = {
  ...KBaseEditorEventEmits
}

/** 事件类型 **/
export type KFileEditorEmitsType = ViewEmitsTypeExtract<typeof KFileEditorEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KCommonPartWorkspaceView from './KCommonPartWorkspace.vue'
import KCommonPartWorkspaceViewModel from './KCommonPartWorkspaceViewModel'

const KCommonPartWorkspace = connect(KCommonPartWorkspaceView, KCommonPartWorkspaceViewModel)

export default withInstall(KCommonPartWorkspace)
export { KCommonPartWorkspace, KCommonPartWorkspaceView, KCommonPartWorkspaceViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KWorkflowTemplateEmitsType, KWorkflowTemplatePropType } from './interface'

/** KWorkflowTemplate */
export default class KWorkflowTemplateViewModel extends BaseViewModel<KWorkflowTemplateEmitsType, KWorkflowTemplatePropType> {
  constructor(options: ViewModelOptions<KWorkflowTemplatePropType>) {
    super(options)
  }

  viewDidMount() {}
}

import { Api, EnumRequestCode, KObjectPropertyEditPanelViewModel } from '@kmsoft/ebf-common'
import { BaseViewModel, EnumDialogResult, KDialog, KDialogClosingEvent, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import lodash from 'lodash'
import { ref, watch } from 'vue'
import { WorkflowClientSrv } from '../../client-srv'
import { KFlowDetailUpdateExecutorViewModel } from '../../controls/flow-detail/flow-detail-update-executor'
import { KFlowForm, KFlowFormViewModel } from '../../pages/personal-workflow-tasks/form/flow-form'
import { ReviewCommentsEnum } from '../../pages/personal-workflow-tasks/form/flow-form/interface'
import KFlowDetailUpdateExecutor from './flow-detail-update-executor'
import { KFlowDetailEmitsType, KFlowDetailPropType } from './interface'

/** KFlowDetail */
export default class KFlowDetailViewModel extends BaseViewModel<KFlowDetailEmitsType, KFlowDetailPropType> {
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  originFormData = ref<any>()
  isShow = ref<boolean>(false)

  constructor(options: ViewModelOptions<KFlowDetailPropType>) {
    super(options)

    watch(
      () => options.props.dataForm,
      (newVal: any) => {
        this.originFormData.value = lodash.cloneDeep(options.props.dataForm)
        this.refObjectProperty.value?.refresh()
        this.showMenu()
      },
      {
        immediate: true,
        deep: true
      }
    )
  }

  /**
   * 按钮显示
   */
  showMenu() {
    if (
      this.originFormData.value.taskId == undefined ||
      this.originFormData.value.taskId == '' ||
      (this.originFormData.value.endTime != undefined &&
        this.originFormData.value.endTime != null &&
        this.originFormData.value.endTime != '')
    ) {
      this.isShow.value = false
    } else {
      this.isShow.value = true
    }
  }

  viewDidMount() {}

  /** 提交 **/
  submit() {
    KDialog.show({
      title: '提交任务',
      content: KFlowForm,
      props: { taskId: this.originFormData.value.taskId },
      size: { width: 677, height: 280 },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const vm = event.viewModel as KFlowFormViewModel
        const formValue = vm.getValue()!
        const validataResult = await vm.validate()
        if (!validataResult) {
          event.cancel = true
          return
        }
        if (formValue.radioValue != ReviewCommentsEnum.REJECT) {
          const param = {
            data: [
              {
                processInstanceId: this.originFormData.value.processInstanceId,
                taskId: this.originFormData.value.taskId,
                comment: formValue.describe,
                rollback: formValue.rollback,
                variables: { route: formValue.radioValue }
              }
            ]
          }
          Api.post('official', 'ProcessInstanceService', 'complete', param).then(res => {
            if (res && res.code == EnumRequestCode.SUCCESS) {
              this.submitComplete()
              KNotification.success({
                title: '系统提示',
                content: '流程提交成功'
              })
              WorkflowClientSrv.taskEvaluate(this.originFormData.value.processInstanceId, this.originFormData.value.businessId)
              // this.refWorkflowGrid.value?.refresh()
              return
            } else {
              KNotification.error({
                title: '系统提示',
                content: res.message!
              })
              return
            }
          })
        } else if (formValue.radioValue == ReviewCommentsEnum.REJECT) {
          const param = {
            data: [
              {
                processInstanceId: this.originFormData.value.processInstanceId,
                taskId: this.originFormData.value.taskId,
                comment: formValue.describe,
                variables: {}
              }
            ]
          }
          Api.post('official', 'ProcessInstanceService', 'fallback', param).then(res => {
            if (res && res.code == EnumRequestCode.SUCCESS) {
              this.submitComplete()
              KNotification.success({
                title: '系统提示',
                content: '流程提交成功'
              })
              //this.refWorkflowGrid.value?.refresh()
              return
            } else {
              KNotification.error({
                title: '系统提示',
                content: res.message!
              })
              return
            }
          })
        }
      }
    })
  }

  submitComplete() {
    this.emit('submitComplete')
  }

  /** 暂停 **/
  pause() {}
  /** 重启 **/
  restart() {}
  /** 终止 **/
  stop() {
    KDialog.show({
      title: '终止任务',
      content: KFlowForm,
      props: {
        type: 'end'
      },
      size: { width: 677, height: 280 },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const vm = event.viewModel as KFlowFormViewModel
        const formValue = vm.getValue()!
        const validataResult = await vm.validate()
        if (!validataResult) {
          event.cancel = true
          return
        }
        const param = {
          data: [
            {
              processInstanceId: this.originFormData.value.processInstanceId,
              taskId: this.originFormData.value.taskId,
              comment: formValue.describe,
              variables: {}
            }
          ]
        }
        Api.post('official', 'ProcessInstanceService', 'stop', param).then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS) {
            this.submitComplete()
            KNotification.success({
              title: '系统提示',
              content: '流程终止成功'
            })
            //this.refWorkflowGrid.value?.refresh()
            return
          } else {
            KNotification.error({
              title: '系统提示',
              content: res.message!
            })
            return
          }
        })
      }
    })
  }
  /** 修改执行人 **/
  changeExecutor() {
    KDialog.show({
      title: '修改执行人',
      size: { width: 1000, height: 600 },
      props: {
        processInstanceId: this.originFormData.value.processInstanceId,
        executorGridData: this.originFormData.value?.processNodes || []
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KFlowDetailUpdateExecutor,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KFlowDetailUpdateExecutorViewModel

        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          return
        }

        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.emit('updateExecutorComplete')
          KNotification.success({
            title: '系统提示',
            content: '保存成功'
          })
        } else {
          event.cancel = true
          KNotification.error({
            title: '保存失败',
            content: result.message!
          })
        }
      }
    })
  }

  async save() {}
}

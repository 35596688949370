import { BaseViewModel, EnumFilterOperator, KCondition, ViewModelOptions } from '@kmsoft/upf-core'
import { KFilterGirdFilterItemConfigFormEventEmitsType, KFilterGirdFilterItemConfigFormPropType } from './interface'
import { cloneDeep, isObject } from 'lodash'
import { nextTick, ref, watch } from 'vue'
import { DefaultFilterValue, KFilterWithId } from '../../interface'

/** KFilterGirdFilterItemConfigForm 视图模型 */
export class KFilterGirdFilterItemConfigFormViewModel extends BaseViewModel<
  KFilterGirdFilterItemConfigFormEventEmitsType,
  KFilterGirdFilterItemConfigFormPropType
> {
  /** 表单控件引用 */
  refForm = ref()
  /** 表单数据 */
  model = ref<KFilterWithId | null>()

  constructor(options: ViewModelOptions<KFilterGirdFilterItemConfigFormPropType>) {
    super(options)
    watch(
      () => options.props.filter,
      newVal => {
        if (newVal && isObject(newVal)) {
          this.setValue(newVal)
        } else {
          this.setValue(DefaultFilterValue)
        }
      },
      {
        deep: true,
        immediate: true
      }
    )
  }
  /**
   * 加载完成函数
   */
  viewDidMount() {}

  /**
   * 获取值
   */
  getValue() {
    this.model.value!.conditions = this.getActualModel()
    return this.model.value
  }
  /**
   * 设置值
   */
  setValue(model: Record<string, any>) {
    this.model.value = cloneDeep(model as KFilterWithId)
    nextTick(() => {
      this.setModelValue(this.model.value!.conditions)
    })
  }
  /**
   * 表单校验
   */
  validate() {
    this.model.value!.conditions = this.getActualModel()
    return new Promise((resolve, reject) => {
      this.refForm.value
        ?.validate()
        .then(() => {
          const result = this.validateConditions()
          if (result) {
            let params = {
              errorFields: [{ errors: ['值不能为空!'] }]
            }
            reject(params)
          } else {
            resolve(this.model)
          }
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  /**
   * 获取model实际值
   */
  private getActualModel() {
    return this.model.value!.conditions.map((x, index) => {
      const ref0 = `refEditor_${x.key}_0_${index}`
      const ref1 = `refEditor_${x.key}_1_${index}`
      const refInstance0 = (this.getControlInstance().$refs[ref0] as any).$vm
      const refInstance1 = (this.getControlInstance().$refs[ref1] as any).$vm
      if (x.operator !== EnumFilterOperator.BETWEEN || !refInstance1) {
        return {
          ...x,
          value: Array.isArray(refInstance0.getValue()) ? refInstance0.getValue() : [refInstance0.getValue()]
        }
      } else {
        return {
          ...x,
          value: Array.isArray(refInstance0.getValue())
            ? refInstance0.getValue()
            : [refInstance0.getValue(), refInstance1.getValue()]
        }
      }
    })
  }

  /**
   * 编辑器赋值
   * @param conditions 筛选条件
   */
  private setModelValue(conditions: Array<KCondition>) {
    conditions.forEach((x, index) => {
      const ref0 = `refEditor_${x.key}_0_${index}`
      const ref1 = `refEditor_${x.key}_1_${index}`
      const refInstance0 = (this.getControlInstance().$refs[ref0] as any).$vm
      const refInstance1 = (this.getControlInstance().$refs[ref1] as any).$vm
      if (x.operator === EnumFilterOperator.BETWEEN) {
        refInstance0.setValue(x.value[0])
        refInstance1.setValue(x.value[1])
      } else {
        refInstance0.setValue(x.value[0])
      }
    })
  }

  /**
   * 校验查询条件的值
   */
  private validateConditions(): boolean {
    return this.model.value!.conditions.some(x => {
      if (x.operator === EnumFilterOperator.BETWEEN) {
        return x.value[0] === '' || x.value[1] === '' || x.value[0] === null || x.value[1] === null
      } else {
        return x.value[0] === '' || x.value[0] === null
      }
    })
  }
}

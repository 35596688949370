
import { defineView, EnumDividerType } from '@kmsoft/upf-core'
import { KClassPropertyListViewModel } from './KClassPropertyListViewModel'
import { KClassPropertyListPropOptions } from './interface'
// import ClassPropertyForm from './class-property-form'

/**
 * 对象属性集
 */
export default defineView({
  name: 'ClassPropertyList',
  props: KClassPropertyListPropOptions,
  viewModel: KClassPropertyListViewModel,
  // components: { ClassPropertyForm },
  setup() {
    const yesNoOptions = [
      { label: '是', value: 1 },
      { label: '否', value: 0 }
    ]

    const columns = [
      { width: '96', editorType: 'text', name: 'propName', title: '属性名称' },
      { width: '100', editorType: 'text', name: 'propCode', title: '属性编码' },
      { width: '125', editorType: 'text', name: 'displayName', title: '显示名' },
      { width: '90', editorType: 'text', name: 'dataLength', title: '数据长度' },
      { width: '90', editorType: 'text', name: 'columnWidth', title: '列宽' },
      { width: '90', editorType: 'text', name: 'defaultValue', title: '默认值' }
    ]
    return { yesNoOptions, columns, EnumDividerType }
  }
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_k_form, {
      autocomplete: "off",
      "label-col": { span: 4 },
      "wrapper-col": { span: 20 },
      style: {"margin":"5% 15%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_k_form_item, {
          label: "属性名",
          name: "name",
          "has-feedback": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_select, {
              value: _ctx.$vm.refMaterialProp,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.refMaterialProp) = $event)),
              options: _ctx.materialPropOptions,
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$vm.materialPropChange())),
              allowClear: false,
              showSearch: false
            }, null, 8, ["value", "options"])
          ]),
          _: 1
        }),
        _createVNode(_component_k_form_item, {
          label: "取值",
          name: "value",
          "has-feedback": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_select, {
              value: _ctx.$vm.refMaterialPropValue,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.refMaterialPropValue) = $event)),
              options: _ctx.$vm.refMaterialPropValueOptions,
              allowClear: false,
              showSearch: false
            }, null, 8, ["value", "options"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
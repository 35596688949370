import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  SimpleViewPropOptions,
  SimpleViewEventEmits
} from '@kmsoft/upf-core'

export interface KChangeOrderActivityPlanGridDataInter {
  id?: string
  number?: string
  name?: string
  activityType?: string
  inventoryDisposition?: string
  description?: string
  supplyingOrganizations?: string
  needDate?: string | null
  className?: string
  affectedObjectList?: any[] // 受影响对象列表
}

/** 参数 **/
export const KChangeOrderActivityPlanGridPropOptions = {
  ...SimpleViewPropOptions,
  /** 是否是设计模式 */
  isDesigner: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KChangeOrderActivityPlanGridPropType = ViewPropsTypeExtract<typeof KChangeOrderActivityPlanGridPropOptions>

/** 事件 */
export const KChangeOrderActivityPlanGridEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KChangeOrderActivityPlanGridEmitsType = ViewEmitsTypeExtract<typeof KChangeOrderActivityPlanGridEventEmits>

export enum EnumPropertyType {
  /**文件系统属性 */
  文件系统属性 = 'FileSystem',

  /**文档属性 */
  文档属性 = 'DocProperty',

  /**标题栏属性 */
  标题栏属性 = 'TitleProperty',

  /**明细栏对象属性 */
  明细栏对象属性 = 'DetailObjProperty',

  /**明细栏结构属性 */
  明细栏结构属性 = 'DetailStructProperty',

  /**文档表格属性 */
  文档表格属性 = 'DocTableProperty',

  /**模型属性 */
  模型属性 = 'ModelProperty',

  /**结构属性 */
  结构属性 = 'StructProperty',

  /**列 */
  列 = 'Column'
}

import { BaseViewModel, KFormViewModel } from '@kmsoft/upf-core'
import { IModuleDefinition, KModuleFormEventEmitsType, KModuleFormPropType } from './interface'
import { EnumTabType } from '../../interface'
import { ref } from 'vue'
import { Api } from '@kmsoft/ebf-common'

export default class KModuleFormViewModel extends BaseViewModel<KModuleFormEventEmitsType, KModuleFormPropType> {
  refModuleForm = ref<KFormViewModel<IModuleDefinition>>()
  /** 是否可编辑 */
  changeAvailable = ref<boolean>(true)
  /** 是否可见勾选框在系统配置模式下可用, 其它模式下不可用 */
  visibleAvailable = ref<boolean>(false)

  formState = ref<IModuleDefinition>({
    visible: true,
    code: '',
    name: '',
    remark: ''
  } as IModuleDefinition)

  private row: IModuleDefinition
  private oper: string
  private mode: string

  /**
   * 加载完成函数
   */
  viewDidMount() {
    this.row = this.props.row!
    this.oper = this.props.oper
    this.mode = this.props.mode

    if (this.oper !== 'insert') {
      this.row.visible = this.row.visible === true || this.row.visible === '1'
      this.changeAvailable.value = false
    } else {
      // 新增时默认勾选可见状态
      this.row.visible = true
    }
    // 自定义模式下, 部分属性不可用
    this.visibleAvailable.value = this.mode === EnumTabType.SYS
    this.formState.value = this.row
  }

  /**
   * 获取表单的值
   * @returns 表单的值
   */
  public getValue() {
    return this.formState.value
  }

  public async save(): Promise<any> {
    const formState = this.getValue()!
    /** 添加模块：编码 值默认加上前缀系统配置 */
    let code = this.row.id ? this.row.code : formState?.code
    if (this.mode == EnumTabType.SYS) {
      code = code.startsWith('SYS_') ? code : 'SYS_' + code
    } else if (this.mode == EnumTabType.DEV) {
      code = code.startsWith('DEV_') ? code : 'DEV_' + code
    }
    if (this.row.id) {
      const value = {
        id: this.row.id,
        code: code,
        parentCode: this.row.parentCode,
        moduleCode: this.row.moduleCode,
        name: formState?.name,
        type: this.row.type,
        remark: formState?.remark,
        orderId: this.row.orderId,
        visible: formState?.visible ?? false ? '1' : ('0' as unknown)
      } as IModuleDefinition
      const param = {
        data: [value]
      }
      const modelCode = this.mode === EnumTabType.SYS ? 'ConfigSysService' : 'ConfigDevService'
      const behavior = this.mode === EnumTabType.SYS ? 'updateConfigSysTree' : 'updateConfigDevTree'
      return Api.post('sys', modelCode, behavior, param)
    } else {
      const value = {
        code: code,
        parentCode: this.row.parentCode,
        moduleCode: this.row.moduleCode,
        name: formState?.name,
        type: this.row.type,
        remark: formState?.remark,
        orderId: this.row.orderId,
        visible: formState?.visible ?? false ? '1' : ('0' as unknown)
      } as IModuleDefinition
      const param = {
        data: [value]
      }
      const modelCode = this.mode === EnumTabType.SYS ? 'ConfigSysService' : 'ConfigDevService'
      const behavior = this.mode === EnumTabType.SYS ? 'insertConfigSysTree' : 'insertConfigDevTree'
      return Api.post('sys', modelCode, behavior, param)
    }
  }

  public async validate() {
    try {
      const validResult = await this.refModuleForm.value?.validate()
      return true
    } catch (error) {
      return false
    }
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeOrderActivityPlanGridView from './KChangeOrderActivityPlanGrid.vue'
import KChangeOrderActivityPlanGridViewModel from './KChangeOrderActivityPlanGridViewModel'

const KChangeOrderActivityPlanGrid = connect(KChangeOrderActivityPlanGridView, KChangeOrderActivityPlanGridViewModel)

export default withInstall(KChangeOrderActivityPlanGrid)
export { KChangeOrderActivityPlanGrid, KChangeOrderActivityPlanGridView, KChangeOrderActivityPlanGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { KPageFormPropType, KPageFormEventEmitsType, IPageDefinition } from './interface'
import { BaseViewModel } from '@kmsoft/upf-core'
import { ref } from 'vue'

export class KPageFormViewModel extends BaseViewModel<KPageFormEventEmitsType, KPageFormPropType> {
  // 表单响应式数据
  formState = ref<IPageDefinition>({ id: '', name: '', symbol: '', suffix: '' })

  /**
   * 设置数据源
   * @param value 表单的值
   * @returns
   */
  public setValue(value: IPageDefinition) {
    this.formState.value = value
  }

  /**
   * 获取表单的值
   * @returns 表单的值
   */
  public getValue() {
    return this.formState.value
  }
}

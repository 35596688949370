import { BaseViewModel, ViewModelOptions, KNotification, KDataGridRowData } from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { clsList } from './clsEnum'
import { KObjectDynamicCreatePanelEmitsType, KObjectDynamicCreatePanelPropType } from './interface'
import { ClassMetaClientSrv, ObjectClientSrv } from '../../index'

/**
 * 动态对象创建面板，包含如下功能：
 * 1、可切换不同的对象类，调用不同的对象类的创建面板，例如切换零部件对象类，下方显示零部件的KPartForm
 * 2、对象类创建面板的切换依赖clsEnum的映射配置，对象类-创建面板
 */
export default class KObjectDynamicCreatePanelViewModel extends BaseViewModel<
  KObjectDynamicCreatePanelEmitsType,
  KObjectDynamicCreatePanelPropType
> {
  clsOptions = ref<Array<{ label: string; value: any }>>([{ label: '1', value: '1' }])
  currentClsCode = ref()
  currentComponent = ref()
  createPageName = ref()
  refCreatePanelViewModel = ref()
  constructor(options: ViewModelOptions<KObjectDynamicCreatePanelPropType>) {
    super(options)
    watch(
      () => this.currentClsCode.value,
      async val => {
        //通过当前编码的获取设计态实例,当前扩展实体逻辑基于设计实体,共用同一界面
        const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(val)
        clsList.forEach(cls => {
          if (Array.isArray(cls.clsCode)) {
            if (cls.clsCode.includes(this.currentClsCode.value)) {
              this.createPageName.value = cls.createPage
            } else if (cls.clsCode.includes(designerCode!)) {
              this.createPageName.value = cls.createPage
            }
          } else {
            if (cls.clsCode == this.currentClsCode.value) {
              this.createPageName.value = cls.createPage
            } else if (cls.clsCode == designerCode!) {
              this.createPageName.value = cls.createPage
            }
          }
        })
      }
    )
    // 如果不显示
    if (options.props.defaultSelectClsCode) {
      this.currentClsCode.value = options.props.defaultSelectClsCode
    }
  }

  viewDidMount() {
    if (this.props.defaultSelectClsCode) {
      this.currentClsCode.value = this.props.defaultSelectClsCode
    }
  }

  validate() {
    if (!this.createPageName.value) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择对象类'
      })
      return false
    }
    return this.refCreatePanelViewModel.value.validate()
  }

  save(row: KDataGridRowData, folderId: string) {
    return this.refCreatePanelViewModel.value.save(row, folderId)
  }
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2faaa810"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { class: "split-div" }
const _hoisted_3 = ["href", "onClick"]
const _hoisted_4 = { class: "layout-content-wrapper" }
const _hoisted_5 = { class: "full chang-obj-manage" }
const _hoisted_6 = { class: "full chang-obj-manage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_list_item = _resolveComponent("k-list-item")!
  const _component_k_list = _resolveComponent("k-list")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!
  const _component_k_collapse_panel = _resolveComponent("k-collapse-panel")!
  const _component_k_collapse = _resolveComponent("k-collapse")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_split_container, { class: "default-theme" }, {
      default: _withCtx(() => [
        _createVNode(_component_k_split_pane, { size: 15 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_k_list, {
                bordered: "",
                "data-source": _ctx.data
              }, {
                renderItem: _withCtx(({ item }) => [
                  _createVNode(_component_k_list_item, null, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        href: item.id,
                        onClick: ($event: any) => (_ctx.$vm.titleClick(item.key))
                      }, _toDisplayString(item.title), 9, _hoisted_3)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["data-source"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_k_split_pane, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_k_collapse, {
                activeKey: _ctx.$vm.activeKey,
                "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.activeKey) = $event)),
                class: "base-content right-content",
                ghost: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_collapse_panel, {
                    id: "change-request",
                    key: "1",
                    header: "变更单-变更请求",
                    forceRender: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_k_object_class_grid, {
                          style: {"height":"300px"},
                          ref: "refChangeRequestGrid",
                          modelCode: "ChangeIssue",
                          modelGroup: "change",
                          loadData: _ctx.$vm.loadChangeRequestData,
                          schemaType: "change-relation"
                        }, null, 8, ["loadData"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_k_collapse_panel, {
                    id: "change-activity",
                    key: "2",
                    header: "变更单-变更活动",
                    forceRender: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_k_object_class_grid, {
                          style: {"height":"300px"},
                          ref: "refChangeActivityGrid",
                          modelCode: "ChangeIssue",
                          modelGroup: "change",
                          loadData: _ctx.$vm.loadChangeActivityData,
                          schemaType: "change-relation"
                        }, null, 8, ["loadData"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["activeKey"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
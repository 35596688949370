import { connect, withInstall } from '@kmsoft/upf-core'
import KDocExtractFilesView from './KDocExtractFiles.vue'
import KDocExtractFilesViewModel from './KDocExtractFilesViewModel'

const KDocExtractFiles = connect(KDocExtractFilesView, KDocExtractFilesViewModel)

export default withInstall(KDocExtractFiles)
export { KDocExtractFiles, KDocExtractFilesView, KDocExtractFilesViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, ViewModelOptions, KDataGridViewModel } from '@kmsoft/upf-core'
import { KImportItemEmitsType, KImportItemPropType } from './interface'
import { ref } from 'vue'

/** KImportItem */
export default class KImportItemViewModel extends BaseViewModel<KImportItemEmitsType, KImportItemPropType> {
  /** 网格引用 */
  refDataGrid = ref<KDataGridViewModel>()
  dataSource = ref<Array<Record<string, any>>>([])

  constructor(options: ViewModelOptions<KImportItemPropType>) {
    super(options)

    this.dataSource.value = options.props.dataSource
  }

  viewDidMount() {}

  getValue() {
    return this.refDataGrid.value?.getSelectedRows()
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  IKToolStripItem,
  EnumToolStripCompType
} from '@kmsoft/upf-core'
import { UpdateConfigValidStatusEventArgs } from '../../sys/sys-config-value/interface'

export enum EnumToolStripItemKeys {
  ADD = 'TOOL_STRIP_ADD',
  EDIT = 'TOOL_STRIP_EDIT',
  DELETE = 'TOOL_STRIP_DELETE',
  CHECK = 'TOOL_STRIP_CHECK',
  REFRESH = 'TOOL_STRIP_REFRESH'
}
export const toolStripItems = [
  {
    name: EnumToolStripItemKeys.ADD,
    compType: EnumToolStripCompType.BUTTON,
    title: '新建',
    visible: true,
    type: 'primary'
  },
  {
    name: EnumToolStripItemKeys.EDIT,
    compType: EnumToolStripCompType.BUTTON,
    title: '编辑',
    icon: 'edit',
    visible: true
  },
  {
    name: EnumToolStripItemKeys.DELETE,
    compType: EnumToolStripCompType.BUTTON,
    title: '删除',
    icon: 'delete',
    visible: true
  },
  {
    name: EnumToolStripItemKeys.REFRESH,
    compType: EnumToolStripCompType.BUTTON,
    title: '刷新',
    icon: 'sync',
    visible: true
  }
] as Array<IKToolStripItem>

/**工具栏项 */
export enum EnumToolStripItem {
  ADD = 'add',
  DELETE = 'delete',
  SAVE = 'save',
  CHECK = 'check',
  REFRESH = 'refresh'
}
/**
 * 选项卡
 */
export enum EnumTabType {
  // 系统配置
  SYS = '1',
  // 自定义配置
  DEV = '2'
}

/** 参数 **/
export const KConfigObjectGridPropOptions = {
  ...BaseViewPropOptions,
  /**模式 */
  mode: VuePropTypes.string().def('')
}

/** 参数类型 **/
export type KConfigObjectGridPropType = ViewPropsTypeExtract<typeof KConfigObjectGridPropOptions>

/** 事件 */
export const KConfigObjectGridEventEmits = {
  ...BaseViewEventEmits,
  /**
   * 更新配置项(模块)有效性状态
   * @param validStatus 有效性
   */
  updateConfigValidStatus: (e: UpdateConfigValidStatusEventArgs) => true
}

/** 事件类型 **/
export type KConfigObjectGridEmitsType = ViewEmitsTypeExtract<typeof KConfigObjectGridEventEmits>

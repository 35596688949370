
import { defineView, exposeComponentEvents } from '@kmsoft/upf-core'
import { KObjectViewSelectorPropOptions, KObjectViewSelectorEventEmits } from './interface'
import KObjectViewSelectorViewModel from './KObjectViewSelectorViewModel'

export default defineView({
  name: 'KObjectViewSelector',
  props: KObjectViewSelectorPropOptions,
  emits: KObjectViewSelectorEventEmits,
  viewModel: KObjectViewSelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /** 继承事件 */
    const exposedEvents = exposeComponentEvents(vm.refSelect, Object.keys(KObjectViewSelectorEventEmits), emit)

    return { exposedEvents }
  }
})

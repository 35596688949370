
import {
  EnumAttributeType,
  EnumControlElementType,
  defineView,
  utils,
  KControlAttribute,
  EnumLabelPosition
} from '@kmsoft/upf-core'
import { KViewPropertyConfigPanelEventEmits, KViewPropertyConfigPanelPropOptions } from './interface'
import KViewPropertyConfigPanelViewModel from './KViewPropertyConfigPanelViewModel'
import { createVNode, resolveComponent } from 'vue'
import { PageDesignerDataSourceProperty, PageDesignerDataSourcePropClass } from '../../../../../types'
import './style/index.less'

export default defineView({
  name: 'KViewPropertyConfigPanel',
  props: KViewPropertyConfigPanelPropOptions,
  emits: KViewPropertyConfigPanelEventEmits,
  viewModel: KViewPropertyConfigPanelViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    /** 渲染基础属性配置项 */
    const renderCommonConfig = () => {
      const baseProp = vm.baseConfig.map((item: KControlAttribute) => __renderElementSingleConfigItem(item))
      return <div class="designer-config-group">{baseProp}</div>
    }

    /** 渲染组件基础属性配置项 */
    const renderComponentBaseConfig = () => {
      /** 基础属性 */
      const configs = props.control.attrs.filter(a => a.attributeType === EnumAttributeType.BASE && a.visible != false)

      if (configs.length <= 0) {
        return
      }

      /** 基础属性控件 */
      const baseProp = configs.map(a => __renderElementSingleConfigItem(a))

      return (
        <k-collapse-panel
          class="view-property-config-group"
          key="base"
          header={createVNode('span', null, [<span class="props-item-group-title">基础属性</span>])}
        >
          <div class="designer-config-group">{baseProp}</div>
        </k-collapse-panel>
      )
    }

    /** 渲染布局属性 */
    const renderLayoutConfig = () => {
      //布局属性
      const layoutConfigs = props.control.attrs.filter(a => a.attributeType === EnumAttributeType.LAYOUT)

      if (layoutConfigs.length <= 0) {
        return
      }

      const layoutItems = layoutConfigs.map(a => __renderElementSingleConfigItem(a))

      return (
        <k-collapse-panel
          class="view-property-config-group"
          key="layout"
          header={createVNode('span', null, [<span class="props-item-group-title">布局属性</span>])}
        >
          <div class="designer-config-group">{layoutItems}</div>
        </k-collapse-panel>
      )
    }

    /** 渲染自定义属性框 */
    const renderEmpty = () => {
      let elementProps = {
        ref: `ref_custom`,
        onBlur: (value: any) => onConfigItemValueChange('custom', 'custom', value.newValue)
      }

      let vNode = createVNode(resolveComponent('KTextarea'), { ...elementProps })

      return (
        <div class={['designer-config-item', `${EnumLabelPosition.TOP}_label`]}>
          <div class="designer-config-item-title">
            属性配置
            <k-tooltip title="消费方自行保证json格式的正确性">
              <span class="notification">
                <k-icon type="question-circle" />
              </span>
            </k-tooltip>
          </div>
          <div class="designer-config-item-content">{vNode}</div>
        </div>
      )
    }

    /** 渲染控件属性 */
    const renderPropsConfig = () => {
      if (props.control.attrs.length <= 0) {
        return
      }

      //控件属性
      const propConfig = props.control.attrs.filter(a => a.attributeType === EnumAttributeType.PROPS && a.visible != false)
      /** 控件配置 */
      let configItems

      //若控件不提供任何属性,内部提供输入框以json的形式编写
      if (propConfig.length > 0) {
        configItems = propConfig.map(a => __renderElementSingleConfigItem(a))
      } else {
        configItems = renderEmpty()
      }

      return (
        <k-collapse-panel
          class="view-property-config-group"
          key="prop"
          header={createVNode('span', null, [<span class="props-item-group-title">控件属性</span>])}
        >
          <div class="designer-config-group">{configItems}</div>
        </k-collapse-panel>
      )
    }

    /**渲染属性 */
    const renderProps = () => {
      return (
        <k-collapse size="small" vModel={[vm.activeKeys.value, 'activeKey']} ghost expandIconPosition="end">
          {renderComponentBaseConfig()}
          {renderLayoutConfig()}
          {renderPropsConfig()}
        </k-collapse>
      )
    }

    /**属性修改事件
     * @param name 属性名称
     * @param type 属性类型
     * @param value 属性值
     */
    const change = utils.debounce(function(name: string, type: string, value: any) {
      vm.emit('elementPropertyChange', name, type, value)
    }, 200)

    /**
     * 配置项的值改变时触发
     * @param elementPropertyConfigItem 发生改变的配置项
     * @param value 最新值
     */
    const onConfigItemValueChange = (name: string, type: string, value: any) => {
      change(name, type, value)
    }

    /**
     * 若当前修改的为组件类型,则返回全部信息
     * @param item 控件属性
     * @param value 控件值
     * @param options 候选项集合
     */
    const __controlTypeChange = (item: KControlAttribute, value: string, options: Array<Record<string, any>>) => {
      const result = (options && options.find(x => x.name === value)) || value
      onConfigItemValueChange(item.name, item.attributeType, result)
    }

    /**
     *
     * 渲染选中元素的配置项
     * @param item 控件属性
     * @param type 属性类型
     */
    const __renderElementSingleConfigItem = (item: KControlAttribute) => {
      /** 属性 */
      const attr = Reflect.get(props.attributeValueItem, item.attributeType)
      /** 属性 */
      const itemValue = attr ? Reflect.get(attr, item.name) : undefined
      /** 生成默认值 */
      const value = itemValue === undefined || itemValue === '' ? null : itemValue
      /** 组件Props */
      const commonProps: Record<string, any> = {
        ref: `ref_${item.attributeType}_property_${item.name}`
      }
      /** 组件Props */
      let elementProps: Record<string, any> = {}

      let labelPosition: EnumLabelPosition = EnumLabelPosition.TOP

      switch (item.control) {
        case EnumControlElementType.INPUT:
        case EnumControlElementType.NUMBER:
        case EnumControlElementType.TEXT_AREA: {
          elementProps = {
            ...commonProps,
            value: value,
            disabled: item.name == 'control' ? false : item.disabled,
            onChange: (value: any) => {
              if (!value.newValue && !value.oldValue) return
              onConfigItemValueChange(item.name, item.attributeType, value.newValue)
            }
          }
          break
        }
        case EnumControlElementType.COMBOBOX: {
          /** 下拉选项 */
          let options: Array<Record<string, any>> = []
          let disabled = item.disabled

          if (item.options) {
            options = item.options.map(c => ({ label: c.displayValue, value: c.actualValue }))
          }

          elementProps = {
            ...commonProps,
            value: value,
            options: options,
            allowClear: false,
            showSearch: false,
            disabled: disabled,
            onChange: (value: any) => onConfigItemValueChange(item.name, item.attributeType, value.newValue)
          }
          break
        }
        case EnumControlElementType.GRID_CONFIG:
        case EnumControlElementType.FILTER_TOOL_STRIP:
        case EnumControlElementType.FILTER_GRID_VIEW:
        case EnumControlElementType.FILTER_SEARCH_BAR: {
          /** 数据源名称 */
          const dataSourceName = props.control.attrs.find(c => c.name == 'dataSource')?.defaultValue
          /** 数据源 */
          let dataSource: any = undefined

          // 如果设置了数据源
          if (props.dataSource) {
            // 如果设置了数据源名称
            if (dataSourceName !== '') {
              const gridProperty = __getGirdProperty(dataSourceName, props.dataSource.children) as PageDesignerDataSourceProperty
              if (gridProperty) {
                const columnDataSource = props.control.attrs.find(a => a.name == 'columns')?.options as Array<
                  PageDesignerDataSourcePropClass | PageDesignerDataSourceProperty
                >
                dataSource = {
                  id: gridProperty.id,
                  name: gridProperty.name,
                  nodeType: 0,
                  title: gridProperty.title,
                  parentId: gridProperty.parentId,
                  children: [
                    {
                      id: 'baseProp',
                      name: '基础属性',
                      nodeType: 0,
                      title: '基础属性',
                      parentId: gridProperty.id,
                      children: columnDataSource
                    }
                  ]
                }
              }
            } else {
              dataSource = props.dataSource.children && props.dataSource.children.find(x => x.id == 'base')!
            }
          }

          elementProps = {
            ...commonProps,
            value: value,
            dataSource: dataSource,
            designerMetaControl: props.designerMetaControl,
            onChange: (value: any) => {
              onConfigItemValueChange(item.name, item.attributeType, value.newValue)
            }
          }
          break
        }
        case EnumControlElementType.CHECKBOX: {
          elementProps = {
            ...commonProps,
            checked: value,
            disabled: item.disabled,
            onChange: (value: any) => {
              onConfigItemValueChange(item.name, item.attributeType, value.newValue)
            }
          }
          labelPosition = EnumLabelPosition.LEFT
          break
        }
        default: {
          elementProps = {
            ...commonProps,
            value: value,
            onChange: (value: any) => onConfigItemValueChange(item.name, item.attributeType, value.newValue),
            disabled: item.disabled
          }
          break
        }
      }
      const vNode = createVNode(resolveComponent(item.control), elementProps)

      return (
        <div class={['designer-config-item', `${labelPosition}_label`]}>
          <div class="designer-config-item-title">{item.title}</div>
          <div class="designer-config-item-content">{vNode}</div>
        </div>
      )
    }

    /**
     * 获取网格属性
     * @param name 属性名称
     * @param dataSource 数据元素集合
     */
    const __getGirdProperty = (
      name: string,
      dataSource: Array<PageDesignerDataSourcePropClass | PageDesignerDataSourceProperty>
    ): PageDesignerDataSourcePropClass | PageDesignerDataSourceProperty | undefined => {
      const gridProperty = dataSource.find(d => d.id == name)
      if (gridProperty) return gridProperty
      for (let i = 0; i < dataSource.length; i++) {
        const element = dataSource[i]
        let gridProperty = __getGirdProperty(name, element.children || [])
        if (gridProperty) return gridProperty
      }
    }

    return () => {
      return (
        <div class="k-view-property-config-panel">
          <div class="config-items">
            {renderCommonConfig()}
            {renderProps()}
          </div>
        </div>
      )
    }
  }
})

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb24053e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "upload-list-item-info" }
const _hoisted_3 = { class: "uploading-icon" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "status-icon"
}
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "upload-list-item-name" }
const _hoisted_9 = { class: "upload-percent-count" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "upload-list-item-actions" }
const _hoisted_12 = {
  key: 0,
  class: "upload-progress-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_tooltip = _resolveComponent("k-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "upload-list-wrapper",
    disabled: _ctx.disabled
  }, [
    _createVNode(_TransitionGroup, {
      name: "k-motion-list",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (file) => {
          return (_openBlock(), _createElementBlock("div", {
            key: file.uid,
            class: _normalizeClass([
          'upload-list-item',
          {
            error: file.status === _ctx.EnumFileUploadingStatus.ERROR,
            removed: file.status === _ctx.EnumFileUploadingStatus.REMOVED,
            done: [_ctx.EnumFileUploadingStatus.DONE, _ctx.EnumFileUploadingStatus.COPIED].includes(file.status)
          }
        ])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (file.status === _ctx.EnumFileUploadingStatus.UPLOADING)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      _createVNode(_component_k_icon, { type: "loading" })
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                      _createVNode(_component_k_icon, { type: "file" })
                    ])),
                ([_ctx.EnumFileUploadingStatus.DONE, _ctx.EnumFileUploadingStatus.COPIED].includes(file.status))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _createVNode(_component_k_icon, { type: "check-circle" })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", {
                class: "upload-list-item-detail",
                title: file.name
              }, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(file.name), 1),
                _createElementVNode("div", _hoisted_9, [
                  ([_ctx.EnumFileUploadingStatus.UPLOADING, _ctx.EnumFileUploadingStatus.ERROR].includes(file.status))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(file.percent) + "% ", 1))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_7),
              _createElementVNode("div", _hoisted_11, [
                ([_ctx.EnumFileUploadingStatus.DONE, _ctx.EnumFileUploadingStatus.COPIED].includes(file.status))
                  ? (_openBlock(), _createBlock(_component_k_tooltip, {
                      key: 0,
                      title: "下载"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_k_button, {
                          class: "actions",
                          type: "text",
                          onClick: ($event: any) => (_ctx.handleDownloadFile(file))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_k_icon, { type: "download" })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (!_ctx.readonly)
                  ? (_openBlock(), _createBlock(_component_k_tooltip, {
                      key: 1,
                      title: "删除"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_k_button, {
                          class: "actions",
                          type: "text",
                          onClick: ($event: any) => (_ctx.handleRemove(file))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_k_icon, { type: "delete" })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ])
            ]),
            (file.status === _ctx.EnumFileUploadingStatus.UPLOADING)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", {
                    class: "upload-progress",
                    style: _normalizeStyle(`width:${file.percent}%`)
                  }, null, 4)
                ]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ]),
      _: 1
    })
  ], 8, _hoisted_1))
}
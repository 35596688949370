import BaselineConfigurer from '@kmsoft/ebf-baseline'
import ChangeManageConfigurer from '@kmsoft/ebf-change-manage'
import CommonConfigurer from '@kmsoft/ebf-common'
import DocAppConfigurer from '@kmsoft/ebf-doc'
import FolderAppConfigurer from '@kmsoft/ebf-folder'
import PartAppConfigurer from '@kmsoft/ebf-part'
import ProjectManageConfigurer from '@kmsoft/ebf-project-manage'
import WorkflowConfigurer from '@kmsoft/ebf-workflow'
import KMVueConfigurer from '@kmsoft/km-vue'
import RequirementConfigurer from '@kmsoft/ebf-requirement'
import upfComponent, { AppBuilder, IMenuPagePathMapping, IocContainer, UPF_IOC_KEYS } from '@kmsoft/upf-core'
import webControlsComponents from '@kmsoft/ebf-view-engine'
import { App, Directive } from 'vue'
import '../../../assets/icons'
import './assets/style/index.less'
import KLoginPage from './controls/login-page'

import {
  AgentMonitorApplet,
  CooperativePluginPrepareRunner,
  EbfStartRunner,
  IdentityProvider,
  MenuPagePathMapping,
  MenuProvider,
  RequestInterceptor,
  RouteHandlerInterceptor,
  AgentClientDownLoad
} from './beans'
import { ExtendSkeleton } from './controls/extend-skeleton/ExtendSkeleton'
import permission from '@kmsoft/ebf-common/src/controls/directives/Permission'

const directives = {
  permission
}

/**
 * 创建一个应用程序启动器，做出相应配置后，启动应用
 */
AppBuilder
  // 配置导航菜单加载器
  .useMenuProvider(new MenuProvider())
  //定义身份提供器
  .useIdentityProvider(new IdentityProvider())
  //配置全局请求拦截器
  .useRequestInterceptor(new RequestInterceptor())
  /**配置路由拦截器 */
  .useRouteHandlerInterceptor([new RouteHandlerInterceptor()])
  .useExternalSkeleton(ExtendSkeleton)
  // 使用应用配置
  .useAppConfigurer([
    CommonConfigurer,
    PartAppConfigurer,
    DocAppConfigurer,
    FolderAppConfigurer,
    ProjectManageConfigurer,
    BaselineConfigurer,
    KMVueConfigurer,
    ChangeManageConfigurer,
    WorkflowConfigurer,
    RequirementConfigurer
  ])
  .useLoginPage(KLoginPage)
  .useWidgets([new AgentClientDownLoad(), new AgentMonitorApplet()])
  // 配置Vue
  .configVue((app: App) => {
    app.use(webControlsComponents)
    app.use(upfComponent)
    app.directive('permission', (directives as { [key: string]: Directive })['permission'])
  })
  .configBeans((iocContainer: IocContainer) => {
    iocContainer.registerSingleton<IMenuPagePathMapping>(UPF_IOC_KEYS.MENU_PAGE_PATH_MAPPING, new MenuPagePathMapping())
    iocContainer.registerSingleton(UPF_IOC_KEYS.APP_RUNNER, new CooperativePluginPrepareRunner())
    iocContainer.registerSingleton(UPF_IOC_KEYS.APP_RUNNER, new EbfStartRunner())
  })
  //构建应用
  .build()
  // 启动
  .run()

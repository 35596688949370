
import { defineView } from '@kmsoft/upf-core'
import { KIssueReportCreatePropOptions, KIssueReportCreateEventEmits } from './interface'
import KIssueReportCreateViewModel from './KIssueReportCreateViewModel'

export default defineView({
  name: 'KIssueReportCreate',
  props: KIssueReportCreatePropOptions,
  emits: KIssueReportCreateEventEmits,
  viewModel: KIssueReportCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46a728dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "k-agent-monitor-applet"
}
const _hoisted_2 = { class: "applet-icon link-status" }
const _hoisted_3 = {
  key: 0,
  class: "message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_badge = _resolveComponent("k-badge")!
  const _component_KAgentMonitor = _resolveComponent("KAgentMonitor")!
  const _component_k_popover = _resolveComponent("k-popover")!

  return (_ctx.showIcon)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_k_popover, { placement: "bottomLeft" }, {
          content: _withCtx(() => [
            _createVNode(_component_KAgentMonitor)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_k_badge, {
                dot: true,
                status: _ctx.linkStatus,
                class: _normalizeClass(['message-badge', { 'message-active': !!_ctx.$vm.message }])
              }, {
                default: _withCtx(() => [
                  _createVNode(_Transition, _normalizeProps(_guardReactiveProps(_ctx.transitionProps)), {
                    default: _withCtx(() => [
                      (_ctx.$vm.message)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$vm.message), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 16),
                  (_ctx.$vm.status == _ctx.EnumWebSocketStatus.Connecting)
                    ? (_openBlock(), _createBlock(_component_k_icon, {
                        key: 0,
                        class: "loading-icon",
                        type: "loading",
                        width: "16",
                        height: "16"
                      }))
                    : (_openBlock(), _createBlock(_component_k_icon, {
                        key: 1,
                        type: "api",
                        width: "16",
                        height: "16"
                      }))
                ]),
                _: 1
              }, 8, ["status", "class"])
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_k_form, {
      autocomplete: "off",
      "label-col": { span: 4 },
      "wrapper-col": { span: 19 },
      style: {"margin":"5% 0"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_k_form_item, {
          label: "视图类型",
          name: "viewType",
          "has-feedback": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_select, {
              options: _ctx.$vm.options,
              allowClear: false,
              showSearch: false,
              value: _ctx.$vm.viewValue,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.viewValue) = $event))
            }, null, 8, ["options", "value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
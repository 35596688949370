import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "class-ui-designer" }
const _hoisted_2 = { class: "layout-designer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_layout_designer = _resolveComponent("k-layout-designer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_tool_strip, null, {
      default: _withCtx(() => [
        _createVNode(_component_k_tool_strip_button_item, {
          type: "text",
          icon: "save",
          onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.saveSchema()),
          title: "保存"
        }),
        _createVNode(_component_k_tool_strip_button_item, {
          type: "text",
          icon: "delete",
          onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.resetSchema()),
          title: "重置"
        }),
        _createVNode(_component_k_tool_strip_button_item, {
          type: "text",
          icon: "export",
          onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.exportSchema()),
          title: "导出"
        }),
        _createVNode(_component_k_tool_strip_button_item, {
          type: "text",
          icon: "picture",
          onClick: _cache[3] || (_cache[3] = () => _ctx.$vm.preview()),
          title: "预览"
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_layout_designer, {
        ref: _ctx.$vm.refDesigner,
        name: _ctx.$vm.pageName,
        title: _ctx.$vm.pageTitle,
        schema: _ctx.$vm.schema,
        dataSource: _ctx.$vm.metaDataSource,
        designerMetaControl: _ctx.$vm.designerMetaControl,
        controlOptionResolver: () => _ctx.$vm.controlCollection
      }, null, 8, ["name", "title", "schema", "dataSource", "designerMetaControl", "controlOptionResolver"])
    ])
  ]))
}
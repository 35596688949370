
import { ToolStripItemChangeEventArgs, ToolStripItemClickedEventArgs, defineView } from '@kmsoft/upf-core'
import { KCustomerNeedsSectionEditPanelPropOptions, KCustomerNeedsSectionEditPanelEventEmits } from './interface'
import KCustomerNeedsSectionEditPanelViewModel from './KCustomerNeedsSectionEditPanelViewModel'

export default defineView({
  name: 'KCustomerNeedsSectionEditPanel',
  props: KCustomerNeedsSectionEditPanelPropOptions,
  emits: KCustomerNeedsSectionEditPanelEventEmits,
  viewModel: KCustomerNeedsSectionEditPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { object } from 'vue-types'

/** 参数 **/
export const KPartBomComparePropOptions = {
  ...BaseViewPropOptions,
  /**
   * 左侧零部件
   */
  leftPart: VuePropTypes.createType<Record<string, any>>().def({}),
  /**
   * 右侧零部件
   */
  rightPart: VuePropTypes.createType<Record<string, any>>().def({}),
  /**
   * 比较规则是否应用到根
   */
  applyToRoot: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KPartBomComparePropType = ViewPropsTypeExtract<typeof KPartBomComparePropOptions>

/** 事件 */
export const KPartBomCompareEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartBomCompareEmitsType = ViewEmitsTypeExtract<typeof KPartBomCompareEventEmits>


import { ToolStripItemChangeEventArgs, ToolStripItemClickedEventArgs, defineView } from '@kmsoft/upf-core'
import { KPartStructPropOptions, KPartStructEventEmits } from './interface'
import KPartStructViewModel from './KPartStructViewModel'
import { EnumObjClassManageToolStripOptions } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KPartStruct',
  props: KPartStructPropOptions,
  emits: KPartStructEventEmits,
  viewModel: KPartStructViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KChangeRequestPropOptions, KChangeRequestEventEmits } from './interface'
import KChangeRequestViewModel from './KChangeRequestViewModel'
import { ref } from 'vue'

export default defineView({
  name: 'KChangeRequest',
  props: KChangeRequestPropOptions,
  emits: KChangeRequestEventEmits,
  viewModel: KChangeRequestViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

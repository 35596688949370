import { IViewConfigItem } from '../../types'

/**
 *  获取下拉框的options
 * @param optionList 候选项的key列表
 * @returns displayValue: item, actualValue: item
 */
export function getCandidates(optionList: Array<string | boolean>) {
  return optionList.map(item => {
    let value = item
    if (typeof item == 'boolean') {
      value = item ? '是' : '否'
    }
    return { displayValue: value, actualValue: item }
  })
}

/**
 * 更换对象的字段的值
 * @param configItem 配置对象
 * @param customValue 自定义值 const customValue = { LAYOUT: { rowSpan: 3, entireRow: true } }
 * @returns IViewConfigItem
 */
export function setConfigCustomValue(configItem: any, customValue: Record<string, Record<string, any>>) {
  // propType, LAYOUT | PROP
  for (const propType in customValue) {
    // 当前属性类型的自定义值对象
    const customPropTypeValue = customValue[propType]
    for (const key in customPropTypeValue) {
      configItem[propType].forEach((item: IViewConfigItem) => {
        if (item.name == key) {
          item.defaultValue = customPropTypeValue[key]
        }
      }) //!.defaultValue = customPropTypeValue[key]
    }
  }
  return configItem
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KAgentMonitorAppletView from './KAgentMonitorApplet.vue'
import KAgentMonitorAppletViewModel from './KAgentMonitorAppletViewModel'

const KAgentMonitorApplet = connect(KAgentMonitorAppletView, KAgentMonitorAppletViewModel)

export default withInstall(KAgentMonitorApplet)
export { KAgentMonitorApplet, KAgentMonitorAppletView, KAgentMonitorAppletViewModel }

// 模板生成文件
// export * from './{{folderName}}'

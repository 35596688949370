import { connect, withInstall } from '@kmsoft/upf-core'
import KPartSaveAsFormView from './KPartSaveAsForm.vue'
import KPartSaveAsFormViewModel from './KPartSaveAsFormViewModel'

const KPartSaveAsForm = connect(KPartSaveAsFormView, KPartSaveAsFormViewModel)

export default withInstall(KPartSaveAsForm)
export { KPartSaveAsForm, KPartSaveAsFormView, KPartSaveAsFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewModel,
  IKTreeNode,
  KDataGridViewModel,
  KTabsViewModel,
  KTreeViewViewModel,
  TreeViewSelectEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KPersonalChangeItemsEmitsType, KPersonalChangeItemsPropType } from './interface'
import { ref, watch } from 'vue'
import { ObjectToolStripItem } from '@kmsoft/ebf-common'

/** KPersonalChangeItems */
export default class KPersonalChangeItemsViewModel extends BaseViewModel<
  KPersonalChangeItemsEmitsType,
  KPersonalChangeItemsPropType
> {
  /**树组件定义 */
  refTree = ref<KTreeViewViewModel>()
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>()
  /** 选中的节点 */
  selectNodeType = ref<string>('2')

  constructor(options: ViewModelOptions<KPersonalChangeItemsPropType>) {
    super(options)
  }

  viewDidMount() {
    /**
     * 切回tab页，自动刷新当前列表
     */
    const parentVm = this.getParent() as KTabsViewModel
    setTimeout(() => {
      parentVm.on('update:activeKey', (args: any) => {
        if (args === '100-6') {
          console.log('1', args)
        }
      })
    }, 200)
  }

  /**
   * 加载树
   */
  loadTreeData(): Promise<Array<IKTreeNode>> {
    return new Promise((resolve, reject) => {
      resolve([
        {
          id: 'root',
          name: '我的变更',
          leaf: false,
          iconType: 'Node_MyProjectTask',
          children: [
            {
              id: '1',
              name: '我的变更请求',
              leaf: true,
              type: '1',
              iconType: 'Task',
              children: []
            },
            {
              id: '2',
              name: '我的变更单',
              leaf: true,
              type: '2',
              iconType: 'Task',
              children: []
            },
            {
              id: '3',
              name: '我的变更活动',
              leaf: false,
              type: '3',
              iconType: 'Task',
              children: [
                {
                  id: '3.1',
                  name: '我发起的',
                  leaf: true,
                  type: '3.1',
                  iconType: 'PurviewDefine',
                  children: []
                },
                {
                  id: '3.2',
                  name: '我负责的',
                  leaf: true,
                  type: '3.2',
                  iconType: 'MSProjectInput',
                  children: []
                }
              ]
            }
          ] as Array<IKTreeNode>
        }
      ])
    })
  }

  /**
   * 刷新网格
   */
  setSelectNode(event: TreeViewSelectEventArgs<any>) {
    this.selectNodeType.value = event.node.type
  }
}

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "k-object-file-preview"
}
const _hoisted_2 = { class: "file-preview-item" }
const _hoisted_3 = {
  key: 0,
  class: "file-icon file-img"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "file-icon"
}
const _hoisted_6 = { class: "file-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!

  return (_ctx.$vm.fileRecord)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "file-thumb",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.$vm.onClickFile && _ctx.$vm.onClickFile(...args)))
          }, [
            (_ctx.$vm.fileIconUrl)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: _ctx.$vm.fileIconUrl
                  }, null, 8, _hoisted_4)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_k_icon, { type: "file" })
                ]))
          ]),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$vm.fileRecord.CFILENAME), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}
import { connect, withInstall } from '@kmsoft/upf-core'
import KPlanCreateFormView from './KPlanCreateForm.vue'
import KPlanCreateFormViewModel from './KPlanCreateFormViewModel'

const KPlanCreateForm = connect(KPlanCreateFormView, KPlanCreateFormViewModel)

export default withInstall(KPlanCreateForm)
export { KPlanCreateForm, KPlanCreateFormView, KPlanCreateFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { IWorkspace } from '../../workspace-list/interface'

/** 参数 **/
export const KProductWorkspaceFormPropOptions = {
  ...BaseViewPropOptions,
  workspace: VuePropTypes.createType<IWorkspace>().def()
}

/** 参数类型 **/
export type KProductWorkspaceFormPropType = ViewPropsTypeExtract<typeof KProductWorkspaceFormPropOptions>

/** 事件 */
export const KProductWorkspaceFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KProductWorkspaceFormEmitsType = ViewEmitsTypeExtract<typeof KProductWorkspaceFormEventEmits>

export interface IProductWorkspaceBaseProp {
  /**工作区id */
  id?: string
  /**工作区名称 */
  name?: string
  /**描述 */
  description?: string
}

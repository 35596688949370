
import { defineView } from '@kmsoft/upf-core'
import { clsCodeMap } from '@kmsoft/ebf-common'
import { KActivityPlanDetailGridPropOptions, KActivityPlanDetailGridEventEmits } from './interface'
import KActivityPlanDetailGridViewModel from './KActivityPlanDetailGridViewModel'

export default defineView({
  name: 'KActivityPlanDetailGrid',
  props: KActivityPlanDetailGridPropOptions,
  emits: KActivityPlanDetailGridEventEmits,
  viewModel: KActivityPlanDetailGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { clsCodeMap }
  }
})

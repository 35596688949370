import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { EnumAttributeType, EnumDataType } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'
import { getCandidates } from '../utils'
import { KControlAttribute } from '@kmsoft/upf-core'

/* 组件的基本属性 */
const comConfig = [
  {
    name: 'dataSource',
    title: '数据源',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.BASE
  },
  {
    name: 'field',
    title: '字段',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.BASE
  },
  {
    name: 'type',
    title: '按钮类型',
    control: 'KSelect',
    options: getCandidates(['primary', 'danger', 'link', 'dashed', 'ghost', 'text', 'default']),
    defaultValue: 'default',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'loading',
    title: 'loading状态',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'icon',
    title: '图标',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'size',
    title: '大小型号',
    control: 'KSelect',
    options: getCandidates(['small', 'middle', 'large']),
    defaultValue: 'middle',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'trigger',
    title: '下拉菜单触发模式',
    control: 'KSelect',
    options: getCandidates(['hover', 'click']),
    defaultValue: 'hover',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'placement',
    title: '下拉菜单弹出位置',
    control: 'KSelect',
    options: getCandidates(['bottomLeft', 'bottomCenter', 'bottomRight', 'topLeft', 'topCenter', 'topRight']),
    defaultValue: 'bottomLeft',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  }
]

/* 属性配置项集合 */
const BASE = [
  PROPERTY_CONFIG_ITEM_COLLECTION.TITLE,
  PROPERTY_CONFIG_ITEM_COLLECTION.DISABLED
  // ...comConfig,
  // PROPERTY_CONFIG_ITEM_COLLECTION.CUSTOM({
  //   type: 'component',
  //   name: 'menuList',
  //   title: '下拉按钮候选项',
  //   editType: EnumEditType.CUSTOM,
  //   defaultValue: []
  // })
] as Array<KControlAttribute>

/**控件属性 */
const PROPS = comConfig
/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
] as Array<KControlAttribute>

const EVENT = [{ name: 'click', title: '点击', describe: '点击 的说明信息...' }]

export default { BASE, PROPS, LAYOUT, EVENT }

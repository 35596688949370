import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectToolStripView from './KObjectToolStrip'
import KObjectToolStripViewModel from './KObjectToolStripViewModel'

const KObjectToolStrip = connect(KObjectToolStripView, KObjectToolStripViewModel)

export default withInstall(KObjectToolStrip)
export { KObjectToolStrip, KObjectToolStripView, KObjectToolStripViewModel }
export * from './types'
export * from './interface'
export { defineToolStripData } from './utils'

import { AgentStaticObject } from '../../AgentStaticObject'
import { AgentInstanceObject } from '../../AgentInstanceObject'

/**指定启动进程时使用的一组值。*/
export class ProcessStartInfo extends AgentInstanceObject {
  protected static type: string = 'System.Diagnostics.ProcessStartInfo,System.Diagnostics.Process'

  /**获取或设置要启动的应用程序或文档。*/
  public async FileName(value: string = AgentStaticObject.None): Promise<string> {
    return this.property('FileName', value)
  }

  /**获取或设置指示是否使用操作系统 shell 启动进程的值。*/
  public async UseShellExecute(value: boolean = true): Promise<boolean> {
    return this.property('UseShellExecute', value)
  }

  /**获取或设置启动应用程序时要使用的一组命令行参数。*/
  public async Arguments(value: string = AgentStaticObject.None): Promise<string> {
    return this.property('Arguments', value)
  }
}

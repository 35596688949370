
import { defineView } from '@kmsoft/upf-core'
import { KFilterGridViewConfigFormEventEmits, KFilterGridViewConfigFormPropOptions } from './interface'
import { KFilterGridViewConfigFormViewModel } from './KFilterGridViewConfigFormViewModel'

export default defineView({
  inheritAttrs: false,
  name: 'KFilterGridForm',
  viewModel: KFilterGridViewConfigFormViewModel,
  components: {},
  props: KFilterGridViewConfigFormPropOptions,
  emits: KFilterGridViewConfigFormEventEmits,
  setup(props, { vm }) {
    const formLayout = {
      formLabelCol: { span: 4 },
      formWrapperCol: { span: 18 }
    }
    const rules = {
      name: [{ required: true, message: '名称不能为空', trigger: 'blur' }]
    }
    return {
      formLayout,
      rules
    }
  }
})

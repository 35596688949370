import { AppContext, BaseViewModel, KNotification, utils } from '@kmsoft/upf-core'
import { KConfigCustomEventEmitsType, KConfigCustomViewModelPropType } from './interface'
import { nextTick, ref } from 'vue'
import { EnumEditType } from '../../interface'
import { KConfigObjectGridViewModel } from '../config-object-grid'

export default class KConfigCustomViewModel extends BaseViewModel<KConfigCustomEventEmitsType, KConfigCustomViewModelPropType> {
  refConfigObjectGrid = ref<KConfigObjectGridViewModel>()
  dyComponent = ref<any>()
  private defId: string
  private treeId: string
  private code: string
  editType = ref<EnumEditType>()
  private mode: string
  clsCode = ref<string>('')

  /**
   * 加载完成函数
   */
  viewDidMount() {
    this.mode = this.props.mode
  }

  /**
   * 初始化表单内容
   * @param defId 配置项编码
   * @param treeId 树节点ID
   * @param code 配置项编码
   * @param editType 编辑类型
   * @param editParam 编辑参数
   * @param clsCode 对象类编码,该字段只会在编辑方式为对象类时生效
   */
  public init(defId: string, treeId: string, code: string, editType: string, editParam: string) {
    this.defId = defId
    this.treeId = treeId
    this.code = code
    this.editType.value = editType as EnumEditType

    //若编辑方式为对象类，则获取对象类编码
    if (this.editType.value == EnumEditType.OBJ) {
      nextTick(() => {
        this.refConfigObjectGrid.value?.init(defId, treeId, code, editParam)
      })
    } else {
      // 动态构造组件, 1、有可能未配置, 2、有可能组件不存在
      let dyComponent = null
      if (utils.isEmpty(editParam)) {
        this.dyComponent.value = dyComponent
        KNotification.error({
          title: '提示',
          content: '未配置组件'
        })
      } else {
        const component = AppContext.current.getApplication().app.component(editParam)
        if (component === undefined) {
          this.dyComponent.value = dyComponent
          KNotification.error({
            title: '提示',
            content: '组件不存在'
          })
        } else {
          dyComponent = component
          this.dyComponent.value = dyComponent
        }
      }
    }
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectDynamicCreatePanelView from './KObjectDynamicCreatePanel.vue'
import KObjectDynamicCreatePanelViewModel from './KObjectDynamicCreatePanelViewModel'

const KObjectDynamicCreatePanel = connect(KObjectDynamicCreatePanelView, KObjectDynamicCreatePanelViewModel)

export default withInstall(KObjectDynamicCreatePanel)
export { KObjectDynamicCreatePanel, KObjectDynamicCreatePanelView, KObjectDynamicCreatePanelViewModel }

export * from './interface'


import { defineView } from '@kmsoft/upf-core'
import { KFileEditorPropOptions, KFileEditorEventEmits } from './interface'
import KFileEditorViewModel from './KFileEditorViewModel'

export default defineView({
  name: 'KFileEditor',
  props: KFileEditorPropOptions,
  emits: KFileEditorEventEmits,
  viewModel: KFileEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})


import { defineView } from '@kmsoft/upf-core'
import KViewEventEditPanelViewModel from './KViewEventEditPanelViewModel'
import { KViewEventEditPanelEventEmits, KViewEventEditPanelPropOptions } from './interface'
import { BizContextItem } from '../../../../../../types'

export default defineView({
  name: 'KViewEventEditPanel',
  props: KViewEventEditPanelPropOptions,
  emits: KViewEventEditPanelEventEmits,
  viewModel: KViewEventEditPanelViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    /**
     * 插入脚本
     * @param context
     */
    const onInsertScript = (context: BizContextItem) => {
      vm.refMonacoEditor.value?.insertScript(context.code)
    }
    return {
      onInsertScript
    }
  }
})


import { defineView, EnumDataGridRowModelType } from '@kmsoft/upf-core'
import { EnumLifecycleState, EnumComplexity, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KPersonalCreateChangeOrderGridPropOptions, KPersonalCreateChangeOrderGridEventEmits } from './interface'
import KPersonalCreateChangeOrderGridViewModel from './KPersonalCreateChangeOrderGridViewModel'
import { readonly } from 'vue'

export default defineView({
  name: 'KPersonalCreateChangeOrderGrid',
  props: KPersonalCreateChangeOrderGridPropOptions,
  emits: KPersonalCreateChangeOrderGridEventEmits,
  viewModel: KPersonalCreateChangeOrderGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      EnumLifecycleState,
      EnumLifecycleStateColor,
      EnumComplexity,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      pagination: {
        pageIndex: 1,
        pageSize: 50
      },
      selectOptions: EnumLifecycleState._list,
      selectValuePlaceholder: '生命周期状态'
    }
  }
})

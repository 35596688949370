import { EnumObjCopyMode, KObjectClassGridViewModel, KUserSelectorViewModel, ObjectClientSrv } from '@kmsoft/ebf-common'
import { BaseViewModel, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { nextTick, ref, watch } from 'vue'
import { UserSelectorTestEmitsType, UserSelectorTestPropType } from './interface'

/** UserSelectorTest */
export class UserSelectorTestViewModel extends BaseViewModel<UserSelectorTestEmitsType, UserSelectorTestPropType> {
  disabled = ref(true)
  refUserSelector = ref<KUserSelectorViewModel>()
  selectedValue = ref()
  constructor(options: ViewModelOptions<UserSelectorTestPropType>) {
    super(options)
    watch(
      () => this.selectedValue.value,
      val => {
        console.log(' v-model selectedValue', val)
      }
    )
  }

  viewDidMount() {}

  setValue() {
    this.refUserSelector.value?.setValue('1762828725864501248')
  }
  getValue() {
    console.log('')

    alert(JSON.stringify(this.refUserSelector.value?.getValue()))
  }

  setDisabled() {
    this.disabled.value = !this.disabled.value
  }

  change(value: string | Array<string>) {
    console.log('user-selector change event', value)
  }
}


import { TreeViewSelectEventArgs, defineView } from '@kmsoft/upf-core'
import KFolderSelectorFormViewModel from './KFolderSelectorFormViewModel'
import { KFolderSelectorFormEventEmits, KFolderSelectorFormPropOptions } from './interface'
import { computed } from 'vue'

export default defineView({
  name: 'KFolderSelectorForm',
  props: KFolderSelectorFormPropOptions,
  emits: KFolderSelectorFormEventEmits,
  viewModel: KFolderSelectorFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})


import { EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import { KPartAssociationFormPropOptions, KPartAssociationFormEventEmits } from './interface'
import KPartAssociationFormViewModel from './KPartAssociationFormViewModel'

export default defineView({
  name: 'KPartAssociationForm',
  props: KPartAssociationFormPropOptions,
  emits: KPartAssociationFormEventEmits,
  viewModel: KPartAssociationFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      pagination: { pageIndex: 1, pageSize: 100 },
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})

import { GridCellEditorParams, GridCellRendererParams, KObjectClassGridBaseColumn } from '../../base'
import KObjectDisplay from '.'

/** 对象显示器 */
export class KDataGridObjectDisplayColumn extends KObjectClassGridBaseColumn {
  name = 'KDataGridObjectDisplayColumn'
  getCellRenderer(params: GridCellRendererParams) {
    const col = (params.cellParams as any).col
    const newParam = { ...col, ...params }
    return <KObjectDisplay {...newParam} />
  }

  getCellEditor(params: GridCellEditorParams) {
    const col = (params.cellParams as any).col
    return <KObjectDisplay {...params} />
  }
}

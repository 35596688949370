import { AgentInstanceObject } from '../../AgentInstanceObject'

import { DriveType } from './DriveType'

/**提供对有关驱动器的信息的访问。*/
export class DriveInfo extends AgentInstanceObject {
  protected static type: string = 'System.IO.DriveInfo,System.IO.FileSystem.DriveInfo'

  /**确定给定路径是否引用磁盘上的现有目录。
   * @param path 要测试的路径。
   * @returns 如果 {@link path} 指向现有目录，则为 true；如果该目录不存在或者在尝试确定指定目录是否存在时出错，则为 false。*/
  public static async GetDrives(): Promise<DriveInfo[]> {
    return this.invokeStaticMethod('GetDrives')
  }

  /**指示驱动器上的可用空闲空间总量（以字节为单位）。*/
  public AvailableFreeSpace: number

  /**获取文件系统的名称，例如 NTFS 或 FAT32。*/
  public DriveFormat: string

  /**获取驱动器类型，如 CD-ROM、可移动、网络或固定。*/
  public DriveType: DriveType

  /**获取一个指示驱动器是否已准备好的值。*/
  public IsReady: boolean

  /**获取驱动器的名称，如 C:\。*/
  public Name: string

  /**获取驱动器上的可用空闲空间总量（以字节为单位）。*/
  public TotalFreeSpace: number

  /**获取驱动器上存储空间的总大小（以字节为单位）。*/
  public TotalSize: number

  /**获取或设置驱动器的卷标。*/
  public VolumeLabel: string
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeActivityUpdateCompareGridDiffView from './KChangeActivityUpdateCompareGridDiff.vue'
import KChangeActivityUpdateCompareGridDiffViewModel from './KChangeActivityUpdateCompareGridDiffViewModel'

const KChangeActivityUpdateCompareGridDiff = connect(KChangeActivityUpdateCompareGridDiffView, KChangeActivityUpdateCompareGridDiffViewModel)

export default withInstall(KChangeActivityUpdateCompareGridDiff)
export { KChangeActivityUpdateCompareGridDiff, KChangeActivityUpdateCompareGridDiffView, KChangeActivityUpdateCompareGridDiffViewModel }

// 模板生成文件
// export * from './{{folderName}}'

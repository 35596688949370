import { connect, withInstall } from '@kmsoft/upf-core'
import KDocTemplateView from './KDocTemplate.vue'
import KDocTemplateViewModel from './KDocTemplateViewModel'

const KDocTemplate = connect(KDocTemplateView, KDocTemplateViewModel)

export default withInstall(KDocTemplate)
export { KDocTemplate, KDocTemplateView, KDocTemplateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

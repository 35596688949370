import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectWorkSpaceSearchView from './KObjectWorkSpaceSearch.vue'
import KObjectWorkSpaceSearchViewModel from './KObjectWorkSpaceSearchViewModel'

const KObjectWorkSpaceSearch = connect(KObjectWorkSpaceSearchView, KObjectWorkSpaceSearchViewModel)

export default withInstall(KObjectWorkSpaceSearch)
export { KObjectWorkSpaceSearch, KObjectWorkSpaceSearchView, KObjectWorkSpaceSearchViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewModel,
  DataGridLoadResult,
  EnumDialogResult,
  EnumToolStripCompType,
  IKTreeNode,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  KTabsViewModel,
  KTreeViewViewModel,
  ToolStripItemClickedEventArgs,
  TreeViewSelectEventArgs,
  ViewModelOptions,
  utils,
  IQuery,
  MemoryCacheFactory
} from '@kmsoft/upf-core'
import { KPersonalWorkflowTasksEmitsType, KPersonalWorkflowTasksPropType } from './interface'
import { ref, watch } from 'vue'
import {
  Api,
  CommonClientSrv,
  EnumRequestCode,
  EnumToolStripItemKeys,
  LoginClientSrv,
  ObjectToolStripItem,
  PartViewMemoryCache,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import { KFlowForm, KFlowFormViewModel } from './form/flow-form'
import { ReviewCommentsEnum } from './form/flow-form/interface'
import { KWorkflowView } from '../workflow-view'
import { WorkflowClientSrv } from '../../client-srv'

/** KPersonalWorkflowTasks */
export default class KPersonalWorkflowTasksViewModel extends BaseViewModel<
  KPersonalWorkflowTasksEmitsType,
  KPersonalWorkflowTasksPropType
> {
  /**树组件定义 */
  refTree = ref<KTreeViewViewModel>()
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>()
  /**流程网格组件 */
  refWorkflowGrid = ref<KDataGridViewModel>()
  /** 选中的节点 */
  selectNode = ref<IKTreeNode>()

  constructor(options: ViewModelOptions<KPersonalWorkflowTasksPropType>) {
    super(options)
    watch(
      () => this.selectNode.value,
      newVal => {
        this.refWorkflowGrid.value?.refresh()
        this.refreshToolStripItems()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {
    /**
     * 切回tab页，自动刷新当前列表
     */
    const parentVm = this.getParent() as KTabsViewModel
    setTimeout(() => {
      parentVm.on('update:activeKey', (args: any) => {
        if (args === '100-5') {
          console.log('1', args)
          this.refWorkflowGrid.value?.refresh()
        }
      })
    }, 200)
  }

  /**
   * 刷新工具栏
   */
  refreshToolStripItems() {
    if (!this.selectNode.value) {
      this.toolStripItems.value = [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
          title: '刷新',
          icon: 'sync',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'f5'
        }
      ] as Array<ObjectToolStripItem>
      return
    }
    if (this.selectNode.value.type != '1') {
      this.toolStripItems.value = [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
          title: '刷新',
          icon: 'sync',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          shortcutKey: 'f5'
        }
      ] as Array<ObjectToolStripItem>
      return
    }
    this.toolStripItems.value = ToolStripHelper.getWorkFlowTaskToolStripItems()
  }
  /**
   * 加载树
   */
  loadTreeData(): Promise<Array<IKTreeNode>> {
    return new Promise((resolve, reject) => {
      resolve([
        {
          id: 'root',
          name: '我的流程任务',
          leaf: false,
          iconType: 'Node_MyProjectTask',
          children: [
            {
              id: '1',
              name: '待办任务',
              leaf: true,
              type: '1',
              iconType: 'Task',
              children: []
            },
            {
              id: '2',
              name: '已提交',
              leaf: true,
              type: '2',
              iconType: 'PurviewDefine',
              children: []
            },
            {
              id: '3',
              name: '我创建的',
              leaf: true,
              type: '3',
              iconType: 'MSProjectInput',
              children: []
            }
          ] as Array<IKTreeNode>
        }
      ])
    })
  }
  /**
   * 流程网格数据加载
   */
  loadData(query: IQuery): Promise<DataGridLoadResult> {
    this.refWorkflowGrid.value?.clearSelectedRows()
    const user = LoginClientSrv.getUserIdentity()
    const userId = user?.id
    return new Promise((resolve, reject) => {
      if (!this.selectNode.value) {
        resolve([])
        return
      }
      switch (this.selectNode.value.type) {
        case '1': {
          const param = {
            data: [
              {
                userId: userId,
                currentPage: query.page?.pageIndex,
                pageSize: query.page?.pageSize
              }
            ]
          }
          Api.post('official', 'ProcessInstanceService', 'listPendingTask', param).then(res => {
            if (res && res.code == EnumRequestCode.SUCCESS && res.data.dataList) {
              const data = {
                rows: res.data.dataList,
                total: res.data.pager.recordCount
              }
              resolve(data)
            } else {
              resolve({
                rows: [],
                total: 0
              })
            }
          })
          break
        }
        case '2': {
          const param = {
            data: [
              {
                userId: userId,
                currentPage: query.page?.pageIndex,
                pageSize: query.page?.pageSize
              }
            ]
          }
          Api.post('official', 'ProcessInstanceService', 'listCompletedTask', param).then(res => {
            if (res && res.code == EnumRequestCode.SUCCESS && res.data.dataList) {
              const data = {
                rows: res.data.dataList,
                total: res.data.pager.recordCount
              }
              resolve(data)
            } else {
              resolve({
                rows: [],
                total: 0
              })
            }
          })
          break
        }
        case '3': {
          const param = {
            data: [
              {
                userId: userId,
                currentPage: query.page?.pageIndex,
                pageSize: query.page?.pageSize
              }
            ]
          }
          Api.post('official', 'ProcessInstanceService', 'listInitiatedProcess', param).then(res => {
            if (res && res.code == EnumRequestCode.SUCCESS && res.data.dataList) {
              const data = {
                rows: res.data.dataList.map((res: any) => {
                  return {
                    ...res,
                    taskId: utils.uuid()
                  }
                }),
                total: res.data.pager.recordCount
              }
              resolve(data)
            } else {
              resolve({
                rows: [],
                total: 0
              })
            }
          })
          break
        }
        default:
          resolve([])
          break
      }
    })
  }
  /**
   * 刷新网格
   */
  setSelectNode(event: TreeViewSelectEventArgs<any>) {
    this.selectNode.value = event.node
  }
  /**
   * 工具栏点击事件
   * @param event 工具栏点击事件
   */
  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT:
        this.edit()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_SUBMIT:
        this.submit()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_START:
        this.startUp()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_PAUSE:
        this.pause()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_RESTART:
        this.restart()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_STOP:
        this.endWorkflow()
        break
      default:
        break
    }
  }
  /**
   * 打开流程
   * @param row 选中行
   */
  openWorkflowPage(row: any) {
    CommonClientSrv.openPage(
      '流程查看：' + row.processName,
      KWorkflowView,
      {
        taskId: row.taskId,
        processInstanceId: row.processInstanceId
      },
      row.id
    )
  }

  /**
   * 流程编辑
   */
  edit() {
    const rows = this.refWorkflowGrid.value?.getSelectedRows()
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个流程任务'
      })
      return
    }
    CommonClientSrv.openPage(
      '流程编辑：' + rows[0].processName,
      KWorkflowView,
      {
        taskId: rows[0].taskId,
        processInstanceId: rows[0].processInstanceId
      },
      rows[0].id
    )
  }
  /**
   * 刷新网格
   */
  refresh() {
    this.refWorkflowGrid.value?.refresh()
  }
  /**
   * 流程提交
   */
  async submit() {
    const rows = this.refWorkflowGrid.value?.getSelectedRows()
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个流程任务'
      })
      return
    }
    // 提交之前获取流程实例信息，判断当前步骤是否正确
    let flag = false
    const reqParam = {
      data: [rows[0].processInstanceId]
    }
    const result = await Api.post('official', 'ProcessInstanceService', 'getProcessInstance', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data.tasks.length > 0) {
        const newTask = result.data.tasks.filter((task: any) => task.taskId != null)[0]
        if (newTask && newTask.taskNodeKey == rows[0].taskNodeKey) {
          flag = true
        }
      }
    } else {
      KNotification.error({
        title: '系统提示',
        content: result.message!
      })
      return
    }
    if (!flag) {
      KNotification.warning({
        title: '系统提示',
        content: '当前流程任务已更新，请刷新页面'
      })
      return
    }
    KDialog.show({
      title: '提交任务',
      content: KFlowForm,
      props: { taskId: rows[0].taskId },
      size: { width: 677, height: 280 },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const vm = event.viewModel as KFlowFormViewModel
        const formValue = vm.getValue()!
        const validataResult = await vm.validate()
        if (!validataResult) {
          event.cancel = true
          return
        }
        if (formValue.radioValue != ReviewCommentsEnum.REJECT) {
          const param = {
            data: [
              {
                processInstanceId: rows[0].processInstanceId,
                taskId: rows[0].taskId,
                comment: formValue.describe,
                rollback: formValue.rollback,
                variables: { route: formValue.radioValue }
              }
            ]
          }
          Api.post('official', 'ProcessInstanceService', 'complete', param).then(res => {
            if (res && res.code == EnumRequestCode.SUCCESS) {
              KNotification.success({
                title: '系统提示',
                content: '流程提交成功'
              })
              this.refWorkflowGrid.value?.refresh()
              WorkflowClientSrv.taskEvaluate(rows[0].processInstanceId, rows[0].businessId)
              return
            } else {
              KNotification.error({
                title: '系统提示',
                content: res.message!
              })
              return
            }
          })
        }
        if (formValue.radioValue == ReviewCommentsEnum.REJECT) {
          const param = {
            data: [
              {
                processInstanceId: rows[0].processInstanceId,
                taskId: rows[0].taskId,
                comment: formValue.describe,
                variables: {}
              }
            ]
          }
          Api.post('official', 'ProcessInstanceService', 'fallback', param).then(res => {
            if (res && res.code == EnumRequestCode.SUCCESS) {
              KNotification.success({
                title: '系统提示',
                content: '流程提交成功'
              })
              this.refWorkflowGrid.value?.refresh()
              return
            } else {
              KNotification.error({
                title: '系统提示',
                content: res.message!
              })
              return
            }
          })
        }
      }
    })
  }
  /**
   * 流程启动
   */
  startUp() {}
  /**
   * 流程暂停
   */
  pause() {}
  /**
   * 流程重启
   */
  restart() {}
  /**
   * 终止流程
   */
  endWorkflow() {
    const rows = this.refWorkflowGrid.value?.getSelectedRows()
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个任务'
      })
      return
    }
    KDialog.show({
      title: '终止任务',
      content: KFlowForm,
      props: {
        type: 'end'
      },
      size: { width: 677, height: 280 },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const vm = event.viewModel as KFlowFormViewModel
        const formValue = vm.getValue()!
        const validataResult = await vm.validate()
        if (!validataResult) {
          event.cancel = true
          return
        }
        const param = {
          data: [
            {
              processInstanceId: rows[0].processInstanceId,
              taskId: rows[0].taskId,
              comment: formValue.describe,
              variables: {}
            }
          ]
        }
        Api.post('official', 'ProcessInstanceService', 'stop', param).then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '流程终止成功'
            })
            this.refWorkflowGrid.value?.refresh()
            return
          } else {
            KNotification.error({
              title: '系统提示',
              content: res.message!
            })
            return
          }
        })
      }
    })
  }

  getView(row: any) {
    const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
    const partView = cacheInstance.getAll()
    if (!row) {
      return ''
    }
    if (row.targetViewId) {
      const view = partView.filter(item => item.id == row.targetViewId)[0].description
      return '(' + view + ')'
    }
    return ''
  }
}

import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KProductNeedsEditPanelPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KProductNeedsEditPanelPropType = ViewPropsTypeExtract<typeof KProductNeedsEditPanelPropOptions>

/** 事件 */
export const KProductNeedsEditPanelEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KProductNeedsEditPanelEmitsType = ViewEmitsTypeExtract<typeof KProductNeedsEditPanelEventEmits>

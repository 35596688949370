import './style/style.less'
import KObjectPropertyPanelViewModel from './KObjectPropertyPanelViewModel'
import KObjectPropertyPanelView from './KObjectPropertyPanel'
import { withInstall, connect } from '@kmsoft/upf-core'

const KObjectPropertyPanel = connect(KObjectPropertyPanelView, KObjectPropertyPanelViewModel)

export default withInstall(KObjectPropertyPanel)
export { KObjectPropertyPanel, KObjectPropertyPanelView, KObjectPropertyPanelViewModel }
export * from './interface'

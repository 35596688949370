import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"200px"} }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = { style: {"width":"40px","height":"40px","border-radius":"20px","background-color":"#ccc","line-height":"40px","text-align":"center"} }
const _hoisted_4 = { style: {"height":"500px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_rule_editor = _resolveComponent("k-rule-editor")!
  const _component_k_file_editor = _resolveComponent("k-file-editor")!
  const _component_k_image_editor = _resolveComponent("k-image-editor")!
  const _component_k_object_class_tree_selector = _resolveComponent("k-object-class-tree-selector")!
  const _component_KBizScriptEditor = _resolveComponent("KBizScriptEditor")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_k_button, { onClick: _ctx.onSelectObj }, {
      default: _withCtx(() => [
        _createTextVNode("选择对象")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_k_button, { onClick: _ctx.onUpload }, {
      default: _withCtx(() => [
        _createTextVNode("上传文件")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_k_rule_editor, {
      disabledObjClsTree: false,
      showObjClsTree: "",
      showText: ""
    }),
    _createVNode(_component_k_file_editor, { value: "1778347041131466752" }),
    _createVNode(_component_k_image_editor, { value: "1778353040529690624" }),
    _createVNode(_component_k_object_class_tree_selector, {
      value: "SYS_ORG",
      readonly: ""
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_KBizScriptEditor)
    ]),
    _createVNode(_component_k_select, {
      options: _ctx.options,
      modelValue: _ctx.receipientId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.receipientId) = $event)),
      placeholder: "全部",
      allowClear: "",
      onPopupScroll: _ctx.handlePopupScroll
    }, {
      option: _withCtx(({ label}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_k_icon, { type: "user" })
            ])
          ]),
          _createElementVNode("div", null, _toDisplayString(label), 1)
        ])
      ]),
      _: 1
    }, 8, ["options", "modelValue", "onPopupScroll"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_k_object_class_grid, {
        modelCode: "SYS_PART",
        loadData: _ctx.onLoadData
      }, null, 8, ["loadData"])
    ])
  ], 64))
}
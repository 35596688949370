import { connect, withInstall } from '@kmsoft/upf-core'
import KDistributionEditView from './KDistributionEdit.vue'
import KDistributionEditViewModel from './KDistributionEditViewModel'

const KDistributionEdit = connect(KDistributionEditView, KDistributionEditViewModel)

export default withInstall(KDistributionEdit)
export { KDistributionEdit, KDistributionEditView, KDistributionEditViewModel }

// 模板生成文件
// export * from './{{folderName}}'

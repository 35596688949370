import { AppContext, BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KAgentMonitorAppletEmitsType, KAgentMonitorAppletPropType } from './interface'
import { EBF_IOC_KEY, EnumWebSocketStatus } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KAgentMonitorApplet */
export default class KAgentMonitorAppletViewModel extends BaseViewModel<
  KAgentMonitorAppletEmitsType,
  KAgentMonitorAppletPropType
> {
  /** 状态消息 */
  message = ref('')
  /** 状态 */
  status = ref<EnumWebSocketStatus>(EnumWebSocketStatus.Disconnected)
  constructor(options: ViewModelOptions<KAgentMonitorAppletPropType>) {
    super(options)
  }

  viewDidMount() {
    AppContext.current.getIocContainer().registerSingleton(EBF_IOC_KEY.AGENT_LINK, { updateAgentStatus: this.updateAgentStatus })
  }
  /** 代理连接事件 */
  updateAgentStatus = (event: EnumWebSocketStatus): void => {
    this.status.value = event
    switch (event) {
      case EnumWebSocketStatus.Connected:
        this.message.value = '已连接'
        break
      case EnumWebSocketStatus.Connecting:
        this.message.value = '连接中'
        break
      case EnumWebSocketStatus.Disconnected:
        this.message.value = '未连接'
        break
      default:
        this.message.value = '未连接'
    }
  }
}


import { defineView } from '@kmsoft/upf-core'
import { KBizScriptEditorPropOptions, KBizScriptEditorEventEmits } from './interface'
import KBizScriptEditorViewModel from './KBizScriptEditorViewModel'

export default defineView({
  name: 'KBizScriptEditor',
  props: KBizScriptEditorPropOptions,
  emits: KBizScriptEditorEventEmits,
  viewModel: KBizScriptEditorViewModel,
  setup(props, { vm }) {
    return {}
  }
})


import { defineView } from '@kmsoft/upf-core'
import KRequireManage from './manage'
import { KRequireWorkspacePropOptions, KRequireWorkspaceEventEmits } from './interface'
import KRequireWorkspaceViewModel from './KRequireWorkspaceViewModel'

export default defineView({
  name: 'KRequireWorkspace',
  props: KRequireWorkspacePropOptions,
  emits: KRequireWorkspaceEventEmits,
  viewModel: KRequireWorkspaceViewModel,
  components: { KRequireManage }, // 这里定义局部组件
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

// import { EnumScriptEventType, ObjClassProp, ObjectHelper } from '@kmplm/ecol-data-srv'
import { KDataGridCellValueChangedEvent } from '@kmsoft/upf-core'
// import { ScriptContext, EventScript } from '../../../../client-srv'

export class ObjectClassGridBaseEventScript {
  /** 脚本事件 */
  // private eventScript: EventScript
  /** 方法 */
  // private methods: IKObjectClassGridBase
  /** 列定义 */
  // private fields: Array<ObjClassProp>

  constructor() {
    // this.eventScript = new EventScript()
  }

  // async init(params: { methods: IKObjectClassGridBase; fields: Array<ObjClassProp> }) {
  //   this.methods = params.methods
  //   this.fields = params.fields
  //   await this.eventScript.init(params.fields)
  // }

  /** 触发事件 */
  invokeEvent(event: KDataGridCellValueChangedEvent) {
    // const field = this.fields.find(a => a.ccolname == event.column.dataPropertyName)
    // const context = ScriptContext.GridContext.getGridContext({ currentRow: event.row, methods: this.methods })
    // this.eventScript.invokeEvent({
    //   context: context,
    //   field: field!,
    //   event: EnumScriptEventType.GridPropertyChanged
    // })
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import lodash from 'lodash'
import { KObjectClassTreePropOptions } from '../object-class-tree'

//#region
/** 高级查询参数 */
export type ObjectSearchQueryParam = Partial<KObjectSearchPropType> & {}
//#endregion

/** 参数 **/
export const KObjectSearchPropOptions = {
  ...KObjectClassTreePropOptions
}

/** 参数类型 **/
export type KObjectSearchPropType = ViewPropsTypeExtract<typeof KObjectSearchPropOptions>

/** 事件 */
export const KObjectSearchEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KObjectSearchEmitsType = ViewEmitsTypeExtract<typeof KObjectSearchEventEmits>

import {
  BaseViewModel,
  EnumDialogResult,
  EnumTreeViewNodeRefreshType,
  IKTreeNode,
  KDialog,
  KDialogClosingEvent,
  KIcon,
  KModal,
  KNotification,
  KTreeNode,
  KTreeViewViewModel,
  TreeViewMenuClickedEventArgs,
  ViewModelOptions,
  utils
} from '@kmsoft/upf-core'
import { EnumToolStripItemKeys, FolderTreeNode, IQueryData, KFolderTreeEmitsType, KFolderTreePropType } from './interface'

import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { createVNode, nextTick, ref, watch } from 'vue'
import KFolderForm, { KFolderFormViewModel } from '../folder-form'
import { ActionTypeEnum } from '../folder-form/interface'
import { CopyOrCutEnum } from '../folder-tree/interface'

/** KFolderTree */
export default class KFolderTreeViewModel extends BaseViewModel<KFolderTreeEmitsType, KFolderTreePropType> {
  refFolderTreeEl = ref<HTMLElement>()
  /**树组件 */
  refFolderTree = ref<KTreeViewViewModel>()
  /**复制还是粘贴中 */
  inCopyOrCut: CopyOrCutEnum = CopyOrCutEnum.NOTHING
  /** 查询条件 */
  queryCriteria = ref<string>('')
  /** 查询索引 */
  queryIndex = ref<number>(0)
  /** 查询结果 */
  queryData = ref<Array<IQueryData>>([])

  constructor(options: ViewModelOptions<KFolderTreePropType>) {
    super(options)
    watch(
      () => this.props.folderId,
      () => {
        this.refFolderTree.value?.refresh()
      }
    )
  }
  viewDidMount() {}

  refresh() {
    this.refFolderTree.value?.refresh()
  }

  toolClick(event: TreeViewMenuClickedEventArgs<any>) {
    if (event.name == undefined) {
      return
    }
    switch (event.name) {
      case EnumToolStripItemKeys.NEW:
        this.addDir(event.node)
        break
      case EnumToolStripItemKeys.EDIT:
        this.modifyDir(event.node)
        break
      case EnumToolStripItemKeys.DELETE:
        this.removeDir(event.node)
        break
      case EnumToolStripItemKeys.COPY:
        this.copyDir(event.node)
        break
      case EnumToolStripItemKeys.CUT:
        this.cutDir(event.node)
        break
      case EnumToolStripItemKeys.PASTE:
        this.pasteDir(event.node)
        break
      case EnumToolStripItemKeys.REFRESH:
        this.refreshNode(event.node)
        break
      default:
        break
    }
  }

  /**
   * 新建文件夹
   * @param node 文件夹节点
   *
   */
  addDir(node: IKTreeNode) {
    const parentNode = this.refFolderTree.value?.getParentNode(node.id)
    KDialog.show({
      title: '创建文件夹',
      size: { width: 510, height: 350 },
      props: {
        actionType: ActionTypeEnum.NEW
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KFolderForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KFolderFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        //获取表单的数据
        const newFolder = formViewModel.getFormData()
        if (utils.isEmpty(newFolder?.name)) {
          KNotification.warn({
            message: '系统提示',
            description: '文件夹名称不能为空'
          })
          event.cancel = true
          return
        }
        const flag = this.ifExistUnderParentNode(node?.id, newFolder.name)
        if (flag) {
          KNotification.warn({
            message: '系统提示',
            description: '同级目录不能重名'
          })
          event.cancel = true
          return
        }
        const param = {
          data: [
            {
              name: newFolder.name,
              description: newFolder.description,
              parentId: node.id
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'create', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          // 刷新树节点
          // this.refFolderTree.value?.refresh(parentNode?.key)
          const newFolder = new FolderTreeNode(result.data)
          this.refFolderTree.value?.insertNode(node.id, newFolder)
          this.refFolderTree.value?.expandTo(node.id)
          KNotification.success({
            title: '系统提示',
            content: '添加目录成功'
          })
        } else {
          KNotification.warn({
            message: '添加目录失败',
            description: result.message
          })
          event.cancel = true
          return
        }
      }
    })
  }
  /**
   * 更新文件夹
   * @param node 文件夹节点
   */
  async modifyDir(node: IKTreeNode) {
    //常规标签页传递的参数
    const normalProp = {
      id: node?.id,
      name: node?.name!,
      nameEn: node.nameEn,
      type: node.type,
      description: node.description,
      parentId: node?.parentId,
      creator: node?.creator,
      createTime: node?.createTime
    }
    const actionType = node.type == null || node.type == '' ? ActionTypeEnum.EDIT : ActionTypeEnum.READONLY
    KDialog.show({
      title: '编辑目录',
      size: { width: 718, height: 519 },
      props: {
        formProp: normalProp,
        actionType: actionType
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KFolderForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KFolderFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        if (node.type != null && node.type != '') return
        //获取常规页面数据
        const folderNormalData = formViewModel.getFormData()
        if (utils.isEmpty(folderNormalData.name)) {
          KNotification.warn({
            message: '系统提示',
            description: '目录名称不能为空'
          })
          event.cancel = true
          return
        }
        const parentNode = this.refFolderTree.value?.getParentNode(node.id) as KTreeNode
        //判断统计目录有无重名
        const flag = this.ifExistUnderParentNode(parentNode?.id, folderNormalData.name, node.id)
        if (flag) {
          KNotification.warn({
            message: '系统提示',
            description: '同级目录不能重名'
          })
          event.cancel = true
          return
        }

        const param = {
          data: [
            {
              id: folderNormalData.id,
              name: folderNormalData.name,
              description: folderNormalData.description,
              type: node.type,
              parentId: node.parentId
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'update', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          const newNode = {
            key: node.id,
            name: folderNormalData.name,
            nameEn: folderNormalData.nameEn,
            description: folderNormalData.description,
            type: node.type,
            parentId: node.id
          }
          this.refFolderTree.value?.modifyNode(newNode)
          KNotification.success({
            title: '系统提示',
            content: '修改成功'
          })
          return
        } else {
          KNotification.warn({
            message: '修改失败',
            description: result.message
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 删除文件夹
   * @param node 文件夹节点
   */
  removeDir(node: IKTreeNode) {
    KModal.delete({
      title: '目录删除提示',
      icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
      content: '您正在进行删除操作，请确认是否删除',
      onOk: () => {
        const childNodes = this.refFolderTree.value?.getChildNodes(node.id)
        if (childNodes && childNodes.length != 0) {
          KNotification.warn({
            message: '删除失败',
            description: '请先删除子文件夹'
          })
          return
        }
        const param = {
          data: [[node.id]]
        }
        Api.post('folder', 'Folder', 'batchDelete', param).then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS) {
            this.refFolderTree.value?.removeNode(node.id)
            KNotification.success({
              title: '系统提示',
              content: '目录删除成功'
            })
          } else {
            KNotification.warn({
              message: '删除失败',
              description: res.message
            })
            return
          }
        })
      }
    })
  }

  /**
   * 复制文件夹
   * @param node 文件夹节点
   */
  copyDir(node: IKTreeNode) {
    this.inCopyOrCut = CopyOrCutEnum.IN_COPY
    this.setCache('copyFolderId', node.id)
  }

  /**
   * 剪切文件夹
   * @param node 文件夹节点
   */
  cutDir(node: IKTreeNode) {
    this.inCopyOrCut = CopyOrCutEnum.IN_CUT
    this.setCache('copyFolderId', node.id)
  }

  /**
   * 粘贴文件夹
   * @param node 文件夹节点
   */
  async pasteDir(node: IKTreeNode) {
    const fromNodeId = this.getCache('copyFolderId') as string
    if (!fromNodeId) {
      KNotification.warn({
        message: '系统提示',
        description: '请先复制或剪切一个目录'
      })
      return
    }
    const currentNodeId = node.id
    if (fromNodeId == currentNodeId) {
      KNotification.warn({
        message: '系统提示',
        description: '请勿粘贴到相同目录'
      })
      return
    }

    let children = node.children
    //子无数据，尝试去加载子节点数据
    if (!children) {
      await this.refFolderTree.value?.expand(node.id)
      children = this.refFolderTree.value?.getChildNodes(node.id) as any
    }
    const fromNode = this.refFolderTree.value?.getNode(fromNodeId)!
    if (children) {
      const findNode = children.find(childNode => childNode.name == fromNode?.name)
      if (findNode) {
        KNotification.warn({
          message: '系统提示',
          description: '目标目录下存在重名文件夹'
        })
        return
      }
    }

    //如果是剪切操作，需要删除fromNode的数据
    if (this.inCopyOrCut == CopyOrCutEnum.IN_CUT) {
      const param = {
        data: [fromNodeId, node.id]
      }
      Api.post('folder', 'Folder', 'cutFolder', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          this.refFolderTree.value?.removeNode(fromNodeId)
          this.refFolderTree.value?.insertNode(node.id, fromNode, 0)
          KNotification.success('剪切成功')
        } else {
          KNotification.warn({
            message: '剪切失败',
            description: res.message
          })
          return
        }
      })
    } else if (this.inCopyOrCut == CopyOrCutEnum.IN_COPY) {
      const param = {
        data: [fromNodeId, node.id]
      }
      Api.post('folder', 'Folder', 'copyFolder', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          this.refFolderTree.value?.refreshNode(node as KTreeNode, EnumTreeViewNodeRefreshType.NODE_PARENT)
          KNotification.success('粘贴成功')
        } else {
          KNotification.warn({
            message: '复制失败',
            description: res.message
          })
          return
        }
      })
    }
    this.clearCache('copyFolderId')
    this.inCopyOrCut = CopyOrCutEnum.NOTHING
  }
  /**
   * 在同一个父节点下是否存在重名
   * @param parentId 父节点id
   * @param currentNodeId 当前节点id
   * @param newName 新名字
   */
  ifExistUnderParentNode(parentId: string, newName: string, currentNodeId?: string): boolean {
    const childNodes = this.refFolderTree.value?.getChildNodes(parentId)
    if (!childNodes) {
      return false
    }
    const duplicateNameNode = childNodes?.find(node => node.name == newName)
    //不存在重名
    if (!duplicateNameNode) {
      return false
      //存在重名，但是新名称与当前节点名称相同
    } else if (currentNodeId && duplicateNameNode.id == currentNodeId) {
      return false
    }
    return true
  }

  isDynamicSubfolder(node: IKTreeNode) {
    return node?.enableDynamicSubfolder == true
  }

  /**
   * 刷新子节点
   */
  refreshNode(node: IKTreeNode) {
    this.refFolderTree.value?.refresh(node.key)
  }

  /**
   * 清空查询数据
   */
  refreshQueryCriteria() {
    this.queryIndex.value = 0
    this.queryData.value = []
  }
  /**
   * 搜索文件夹
   */
  async searchProperty() {
    const qry = this.queryCriteria.value
    const index = this.queryIndex.value
    const queryData = this.queryData.value as Array<IQueryData>
    const rootNode = this.refFolderTree.value?.getRootNodes()[0] as KTreeNode
    if (qry == '') {
      this.refreshQueryCriteria()
    }

    // /** 判断queryData是否为空 */
    if (queryData.length == 0 || queryData[index] == undefined) {
      const param = {
        data: [qry, rootNode.id]
      }
      const result = await Api.post('folder', 'Folder', 'listByName', param)
      if (result && result.code == EnumRequestCode.SUCCESS) {
        // 接口成功
        // 判断是否查询到属性
        if (result.data.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '查询属性不存在'
          })
          this.queryIndex.value = 0
          return
        }

        if (result.data.length > 0) {
          this.queryData.value = result.data.map((res: Record<string, object>) => {
            return {
              directoryId: res.id,
              directoryPath: res.rawFullPath
                .toString()
                .split(rootNode.id + '/')[1]
                .slice(0, -1)
                .replaceAll('/', ',')
            }
          }) as Array<IQueryData>

          // 左侧树勾选当前选中的节点，会触发右侧网格数据的更新
          this.refFolderTree.value?.expandByPath(
            rootNode!,
            rootNode?.id +
              ',' +
              result
                .data![0].rawFullPath.toString()
                .split(rootNode.id + '/')[1]
                .slice(0, -1)
                .replaceAll('/', ','),
            true
          )
          this.queryIndex.value = 1
          return
        }
      }
    } else {
      this.refFolderTree.value?.expandByPath(rootNode!, rootNode?.id + ',' + queryData[index].directoryPath, true)
      this.queryIndex.value = index + 1
    }
  }

  /**
   * 获取选中的树节点
   * @returns 选中的树节点
   */
  getSelectNode() {
    return this.refFolderTree.value?.getSelectedNode()
  }
  getContainer() {
    return this.refFolderTreeEl.value
  }
}

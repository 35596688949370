import { BaseViewModel, KDataGridRowData, ViewModelOptions } from '@kmsoft/upf-core'
import { KRequirementChangeCreateFormEmitsType, KRequirementChangeCreateFormPropType } from './interface'
import { Api, KObjectPropertyPanelViewModel } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KRequirementChangeCreateForm */
export default class KRequirementChangeCreateFormViewModel extends BaseViewModel<
  KRequirementChangeCreateFormEmitsType,
  KRequirementChangeCreateFormPropType
> {
  /** 创建面板 */
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()

  constructor(options: ViewModelOptions<KRequirementChangeCreateFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  validate() {
    return true
  }

  /**
   * 创建
   */
  save(row: KDataGridRowData, folderId: string) {
    const createObject = {
      ...this.refObjectCreatePanel.value?.getValue(),
      ...{ folder: { id: folderId, clazz: 'Folder' } },
      productNeedsId: row.targetId
    } as any
    if (createObject.secondary) {
      createObject.secondary = createObject.secondary.map((element: string) => {
        return { id: element }
      })
    }
    return Api.post('requirement', 'RequirementChangeRequest', 'create', { data: [createObject] })
  }
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KScriptEditorEmitsType, KScriptEditorPropType } from './interface'
import { KBaseEditorViewModel } from '../../base'

/** KScriptEditor */
export default class KScriptEditorViewModel extends KBaseEditorViewModel<KScriptEditorEmitsType, KScriptEditorPropType> {
  constructor(options: ViewModelOptions<KScriptEditorPropType>) {
    super(options)
  }

  viewDidMount() {}

  onEdit() {}
}

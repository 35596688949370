
import { defineView } from '@kmsoft/upf-core'
import { KFileUploadPropOptions, KFileUploadEventEmits } from './interface'
import KFileUploadViewModel from './KFileUploadViewModel'

export default defineView({
  name: 'KFileUpload',
  props: KFileUploadPropOptions,
  emits: KFileUploadEventEmits,
  viewModel: KFileUploadViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { EnumObjGridRule, ObjBusinessParam, ObjectClientSrv, ObjectCreateDynamicPanelParams, ObjectSelectDialogParam } from "@kmsoft/ebf-common"
import { PMSCreateObjectResult } from "../components"
import { EnumDialogSize,EnumDialogResult } from "@kmsoft/upf-core"

class ManaReviewClientSrv{
  /**
   * 打开创建证明资料对话框
   * @param planObjParam 主对象对象参数
   */
  onOpenCreateManaReviewDialog = async (
    mainObjParam: ObjBusinessParam,
    folderId: string,
    showObjClsId?: string
  ): Promise<PMSCreateObjectResult | undefined> => {
    const dialogProps: ObjectCreateDynamicPanelParams = {
      showObjClsCodes: showObjClsId == '' ? [] : [showObjClsId!],
      folderId: folderId
    }
    const result = await ObjectClientSrv.openCreateDialog(dialogProps)

    if (!result.success) {
      return
    }
    const params = {
      objClsID: result.objBusiness!.rdmExtensionType,
      objID: result.objBusiness!.id
    }
    return { objBusiness: [(params as unknown) as ObjBusinessParam] }
  }

  /**
   * 添证明资料
   * @param objClsID
   * @param objID
   * @param showObjClsId 显示的树节点
   * @param callBack
   */
  addManaReview(mainObjParam: ObjBusinessParam, showObjClsId: string): Promise<PMSCreateObjectResult> {
    //1.定义弹出查找对象对话框属性
    //对象类树属性
    return new Promise<PMSCreateObjectResult>((resolve, reject) => {
      const objectClassTreeProps = {
        defaultSelectClsCode: showObjClsId,
        // objClassTypes: [
        //   DataSrv.metaObjClsType.DOC,
        //   DataSrv.metaObjClsType.PART,
        //   DataSrv.metaObjClsType.COMMONOBJ,
        //   DataSrv.metaObjClsType.COMMONOBJCUSTOMTABLE
        // ],
        showObjClsCodes: showObjClsId ? [showObjClsId] : []
      }
      const objGridRule = {
        objGridRuleType: EnumObjGridRule.AddDescObj,
        objGridRuleArgs: { mainObjID: mainObjParam.modelCode },
        isMultipleSelection: showObjClsId ? false : true
      }
      const porps = {
        size: EnumDialogSize.Fat,
        onClosing: async (e:any): Promise<void> => {
          //3.参数校验
          if (e.dialogResult == EnumDialogResult.Cancel || e.dialogResult == EnumDialogResult.Close) return

          if (e.dialogResult == EnumDialogResult.Apply) {
            e.cancel = true
          }

          const selectedObjParam = (e.viewModel as any).getSelectedObjParams()!
          if (selectedObjParam.length == 0) {
            // commonClientSrv.alertSelectARow()
            e.cancel = true
            return
          }
          const param = selectedObjParam?.map((m: { modelCode: any; id: any }) => ({ objClsID: m.modelCode, objID: m.id }))

          resolve({ objBusiness: param })
        },
        objectClassManageQueryParam: { ...objectClassTreeProps, ...objGridRule },
        objectSearchQueryParam: { ...objectClassTreeProps, ...objGridRule },
        objectDirectoryManageProps: { ...objGridRule },
        objectSearchProps: { ...objectClassTreeProps, ...objGridRule },
        recentOpenObjectGridProps: { objClsID: showObjClsId, ...objGridRule }
      } as ObjectSelectDialogParam
      //2.弹出查找对象对话框
      ObjectClientSrv.openObjectSelectDialog(porps)
    })
  }
}
export const manaReviewClientSrv = new ManaReviewClientSrv()
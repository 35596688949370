import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KMyManagedProductionWorkspacePropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KMyManagedProductionWorkspacePropType = ViewPropsTypeExtract<typeof KMyManagedProductionWorkspacePropOptions>

/** 事件 */
export const KMyManagedProductionWorkspaceEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KMyManagedProductionWorkspaceEmitsType = ViewEmitsTypeExtract<typeof KMyManagedProductionWorkspaceEventEmits>

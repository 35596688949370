
import { KDataGridRowData, TreeViewExpandEventArgs, ValueChangeEventArgs, defineView } from '@kmsoft/upf-core'
import { KPartBomComparePropOptions, KPartBomCompareEventEmits } from './interface'
import { KModelBrowser } from '@kmsoft/km-vue'
import KPartBomCompareViewModel from './KPartBomCompareViewModel'
import KBomCompareResultPanel from './panel/bom-compare-result-panel'

export default defineView({
  name: 'KPartBomCompare',
  props: KPartBomComparePropOptions,
  emits: KPartBomCompareEventEmits,
  viewModel: KPartBomCompareViewModel,
  components: { KBomCompareResultPanel, KModelBrowser },
  setup(props, { emit, slots, attrs, vm }) {
    return {
      expandTypeOptions: [
        {
          label: '最新版本',
          value: 'LATEST_WITHOUT_WORKING_COPY'
        },
        {
          label: '最新发布版本',
          value: 'LATEST_RELEASED'
        },
        {
          label: '基线',
          value: 'BASELINE'
        }
      ]
    }
  }
})

import { EnumObjCopyMode, KObjectClassGridViewModel, ObjectClientSrv } from '@kmsoft/ebf-common'
import { BaseViewModel, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { nextTick, ref } from 'vue'
import { ObjectGridTestEmitsType, ObjectGridTestPropType } from './interface'

/** ObjectGridTest */
export default class ObjectGridTestViewModel extends BaseViewModel<ObjectGridTestEmitsType, ObjectGridTestPropType> {
  disabled = ref(true)
  refObjectGrid = ref<KObjectClassGridViewModel>()
  selectedClsCode = ref('')
  constructor(options: ViewModelOptions<ObjectGridTestPropType>) {
    super(options)
  }

  viewDidMount() {}

  async createClass() {
    // const createResult = await ObjectClientSrv.openCreateDialog({
    //   objCopyMode: EnumObjCopyMode.NewObj,
    //   modelCode: this.selectedClsCode.value,
    //   forceShowObjClsSelector: false
    // })
    // if (createResult.success) {
    //   const properties = createResult.objBusiness
    //   this.refObjectGrid.value?.insertRow({ ...properties })
    // }
  }

  switchEdit() {
    this.disabled.value = !this.disabled.value
  }

  filter() {
    this.refObjectGrid.value?.openFilterDialog()
  }

  loadData() {
    return new Promise(resolve => {
      resolve({
        total: 4,
        rows: [
          {
            id: '1',
            modelCode: 'user',
            name: '张三',
            nativePlace: '1',
            sex: 1,
            telphone: 123456,
            email: 'www.xxx.com',
            latestUpdateTime: '2024-01-01: 09:00:00',
            createTime: '2024-01-01: 09:00:00',
            onLine: 1,
            remark: '备注备注',
            creatorId$name: '系统管理员',
            creatorId$code: 'admin'
          },
          {
            id: '2',
            modelCode: 'user',
            name: '李四',
            nativePlace: '1',
            sex: 1,
            telphone: 123456,
            email: 'www.xxx.com',
            latestUpdateTime: '2024-01-01: 09:00:00',
            createTime: '2024-01-01: 09:00:00',
            onLine: 1,
            remark: '备注备注',
            creatorId$name: '系统管理员',
            creatorId$code: 'admin'
          },
          {
            id: '3',
            modelCode: 'user',
            name: '张五',
            nativePlace: '1',
            sex: 1,
            telphone: 123456,
            email: 'www.xxx.com',
            latestUpdateTime: '2024-01-01: 09:00:00',
            createTime: '2024-01-01: 09:00:00',
            onLine: 1,
            remark: '备注备注',
            creatorId$name: '系统管理员',
            creatorId$code: 'admin'
          },
          {
            id: '4',
            modelCode: 'user',
            name: '张六',
            nativePlace: '1',
            sex: 1,
            telphone: 123456,
            email: 'www.xxx.com',
            latestUpdateTime: '2024-01-01: 09:00:00',
            createTime: '2024-01-01: 09:00:00',
            onLine: 1,
            remark: '备注备注',
            creatorId$name: '系统管理员',
            creatorId$code: 'admin'
          }
        ]
      })
    })
  }

  save() {
    // this.refObjectGrid.value?.save().then(res => {
    //   alert(JSON.stringify(res))
    // })
  }

  find() {
    this.refObjectGrid.value?.openFindDialog()
  }

  openSelectedObj() {
    // this.refObjectGrid.value?.openSelectedObj()
  }

  delete() {}

  treeSelected({ node }: { node: any }) {
    this.selectedClsCode.value = node.id
  }
}

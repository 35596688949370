import { AgentInstanceObject } from '../../AgentInstanceObject'

import { Stream } from '../io/Stream'

/**表示 Windows 图标，它是用于表示对象的小位图图像。 尽管图标的大小由系统决定，但仍可将其视为透明的位图。*/
export class Icon extends AgentInstanceObject {
  protected static type: string = 'System.Drawing.Icon,System.Drawing.Common'

  /**将此 {@link Icon} 保存到指定的输出 {@link Stream}。*/
  public async Save(outputStream: Stream): Promise<void> {
    return this.invokeMethod('Save', outputStream)
  }
}

import { CommonClientSrv } from '@kmsoft/ebf-common'
import { Doc } from '../beans/Doc'
import { DocTool } from './DocTool'
import { KPdfBrowser } from '../controls'
import { DocClientSrv } from './DocClientSrv'
import { EnumDocToolType, EnumOperatorType } from './enums'

export class DocToolPdfBrowser extends DocTool {
  protected static typeSupported: EnumDocToolType = EnumDocToolType.Browser | EnumDocToolType.Editor
  public async execute(doc: Doc): Promise<void> {
    switch (this.type) {
      case EnumDocToolType.Browser:
        return this.browse(doc)
      case EnumDocToolType.Downloader:
      case EnumDocToolType.Editor:
      case EnumDocToolType.Markup:
      case EnumDocToolType.Printer:
      case EnumDocToolType.Reference:
      default:
        break
    }
  }
  private async browse(doc: Doc) {
    if (!doc) return

    const docs = await DocClientSrv.selectDocs(doc, EnumOperatorType.Browser)
    if (!docs) return
    const urls = (await DocClientSrv.downloadToBlobs(docs.downLoadList)).map(a => {
      if (a) {
        return window.URL.createObjectURL(new Blob([a], { type: 'application/pdf' }))
      }
    })
    CommonClientSrv.openPage('pdf', KPdfBrowser, { urls: urls }, doc.id + 'browser')
  }
}

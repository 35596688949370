import { BaseViewModel } from '@kmsoft/upf-core'
import { KConfigTileEventEmitsType, KConfigTileViewModelPropType } from './interface'
import { EnumTabType } from '../../interface'
import { isReqSuccess, showError } from '../../common/common'
import { UpdateConfigValidStatusEventArgs } from '../sys-config-value/interface'
import { ref } from 'vue'
import { Api } from '@kmsoft/ebf-common'

export default class KConfigTileViewModel extends BaseViewModel<KConfigTileEventEmitsType, KConfigTileViewModelPropType> {
  // 页面表单状态
  configList = ref<Array<Record<string, any>>>()
  mode = ref<string>()

  private treeId: string
  private code: string

  /**
   * 加载完成函数
   */
  viewDidMount() {
    //
    this.mode.value = EnumTabType.SYS
  }

  public init(treeId: string, code: string): void {
    this.treeId = treeId
    this.code = code
    this.loadData()
  }

  public loadData() {
    this.configList.value = []
    const param = {
      data: [
        {
          id: this.treeId as unknown
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'loadConfigSysList', param).then(response => {
      if (!isReqSuccess(response)) {
        showError(response)
        return
      }
      const retData = response.data

      const configList = [] as Array<Record<string, any>>

      if (retData != null) {
        const defCodeList = retData.defCodeList
        const defMap = retData.defMap || ({} as any)

        defCodeList?.forEach((code: string) => {
          const def = defMap[code]
          configList.push(def)
        })
      }

      this.configList.value = configList
    })
  }

  /**
   * 更新配置项(模块)有效性状态
   * @param validStatus 有效性状态
   */
  public updateConfigValidStatus(validStatus: UpdateConfigValidStatusEventArgs): void {
    this.emit('updateConfigValidStatus', validStatus)
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KStructuredDocManageView from './KStructuredDocManage.vue'
import KStructuredDocManageViewModel from './KStructuredDocManageViewModel'

const KStructuredDocManage = connect(KStructuredDocManageView, KStructuredDocManageViewModel)

export default withInstall(KStructuredDocManage)
export { KStructuredDocManage, KStructuredDocManageView, KStructuredDocManageViewModel }
export * from './interface'
// 模板生成文件
// export * from './{{folderName}}'

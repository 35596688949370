import { connect, withInstall } from '@kmsoft/upf-core'
import KDocStructureTreeView from './KDocStructureTree.vue'
import KDocStructureTreeViewModel from './KDocStructureTreeViewModel'

const KDocStructureTree = connect(KDocStructureTreeView, KDocStructureTreeViewModel)

export default withInstall(KDocStructureTree)
export { KDocStructureTree, KDocStructureTreeView, KDocStructureTreeViewModel }
export * from './interface'
export * from './node'
// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartCreateBaselineView from './KPartCreateBaseline.vue'
import KPartCreateBaselineViewModel from './KPartCreateBaselineViewModel'

const KPartCreateBaseline = connect(KPartCreateBaselineView, KPartCreateBaselineViewModel)

export default withInstall(KPartCreateBaseline)
export { KPartCreateBaseline, KPartCreateBaselineView, KPartCreateBaselineViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { KBaselineCompare } from '../../pages/baseline-compare'
import { KDialog, KDialogClosingEvent } from '@kmsoft/upf-core'

export class BaselineClientSrv {
  static async baselineCompare(sourceId: string, targetId: string) {
    KDialog.show({
      title: '基线对比',
      size: { width: document.documentElement.clientWidth - 100, height: document.documentElement.clientHeight - 100 },
      props: {
        sourceId: sourceId,
        targetId: targetId
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      showConfirm: false,
      content: KBaselineCompare,
      onClosing: async (event: KDialogClosingEvent) => {
        // if (event.dialogResult == EnumDialogResult.Cancel) return
        // if (event.dialogResult == EnumDialogResult.Close) return
        // if (await formViewModel.save()) {
        //   this.refObjGrid.value?.refresh()
        // } else {
        //   event.cancel = true
        // }
      }
    })
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartImportErrorMsgPropOptions = {
  ...BaseViewPropOptions,
  errorMsg: VuePropTypes.createType<Array<any>>().def()
}

/** 参数类型 **/
export type KPartImportErrorMsgPropType = ViewPropsTypeExtract<typeof KPartImportErrorMsgPropOptions>

/** 事件 */
export const KPartImportErrorMsgEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartImportErrorMsgEmitsType = ViewEmitsTypeExtract<typeof KPartImportErrorMsgEventEmits>

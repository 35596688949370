import { KObjectClassGridPropOptions, KObjectClassGridEventEmits } from './interface'
import { defineExtendView } from '@kmsoft/upf-core'
import { KObjectClassGridBase } from './controls'

export default defineExtendView({
  name: 'KObjectClassGrid',
  parent: KObjectClassGridBase,
  props: KObjectClassGridPropOptions,
  emits: KObjectClassGridEventEmits
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeTracingView from './KChangeTracing.vue'
import KChangeTracingViewModel from './KChangeTracingViewModel'

const KChangeTracing = connect(KChangeTracingView, KChangeTracingViewModel)

export default withInstall(KChangeTracing)
export { KChangeTracing, KChangeTracingView, KChangeTracingViewModel }

// 模板生成文件
// export * from './{{folderName}}'

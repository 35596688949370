import { AgentGenericObject } from '../../../AgentGenericObject'

/**表示键和值的集合。*/
export abstract class Dictionary<TKey, TValue> extends AgentGenericObject {
  protected static genericTypeDefinition: string = 'System.Collections.Generic.Dictionary'

  /**数据。*/
  public data: Record<string | number | symbol, TValue>

  public toJSON(): any {
    return { __type: (this.constructor as typeof Dictionary).type, __object: this.data }
  }

  /**创建本地实例。
   * @param dictionary {@link Record}，它的元素被复制到新 {@link Dictionary}。*/
  protected static createLocalProtected<TKey, TValue, T extends Dictionary<TKey, TValue>>(
    dictionary?: Record<string | number | symbol, TValue>
  ): T {
    const instance = this.createProtected<T>('')
    if (dictionary) instance.data = dictionary
    return instance
  }
}

/**表示字符串键和字符串值的集合。*/
export class Dictionary_String_String extends Dictionary<string, string> {
  protected static genericArguments: string[] = ['System.String', 'System.String']

  /**创建本地实例。*/
  public static createLocal(dictionary?: Record<string, string>): Dictionary_String_String {
    return super.createLocalProtected(dictionary)
  }
}

/**表示字符串键和字符串值的集合。*/
export class Dictionary_String_Array extends Dictionary<string, Array<string>> {
  protected static genericArguments: string[] = ['System.String', 'System.String[]']

  /**创建本地实例。*/
  public static createLocal(dictionary?: Record<string, Array<string>>): Dictionary_String_Array {
    return super.createLocalProtected(dictionary)
  }
}

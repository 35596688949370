import { ViewPropsTypeExtract, BaseViewPropOptions, BaseViewEventEmits, ViewEmitsTypeExtract } from '@kmsoft/upf-core'

// 页面事件
export const KDevTabEventEmits = {
  ...BaseViewEventEmits
}

// 属性选项
export const KDevTabPropOptions = {
  ...BaseViewPropOptions
}

// 属性类型
export type KDevTabPropType = ViewPropsTypeExtract<typeof KDevTabPropOptions>

export type KDevTabEventEmitsType = ViewEmitsTypeExtract<typeof KDevTabEventEmits>

import lodash from 'lodash'
import { KDesignerControl } from '../types'
import { ControlDescriptionBase, ControlDescriptionConstructor } from '@kmsoft/upf-core'
import { ConcreteComponent } from 'vue'

/**
 * 组件描述转设计器组件描述
 * @param element
 * @returns
 */
export const controlDescriptionToDesignerControl = (component: ConcreteComponent): KDesignerControl | undefined => {
  if (!('description' in component)) {
    return
  }

  /** 组件描述 */
  const description = component.description

  // 如果不包含组件名称
  if (!('controlName' in description) || !('CONTROL_DESCRIPTION' in description)) {
    return
  }

  /** 狗仔 */
  const constructor = description as typeof ControlDescriptionBase
  /** 构造 */
  const constructorFunc = description as ControlDescriptionConstructor
  /** 创建实例 */
  const instance = new constructorFunc()

  const controlInfo: KDesignerControl = {
    name: instance.name,
    title: instance.title,
    icon: instance.icon,
    attrs: instance.attrs,
    events: instance.events,
    control: constructor.controlName,
    type: instance.type,
    controlType: instance.controlType,
    dataType: instance.dataType,
    visible: instance.visible
  }

  return lodash.cloneDeep(controlInfo)
}

/** 生产控件名称 */
export const generateControlName = (elementName: string) => {
  let timestamp = new Date().getTime()
  return `${elementName}#${timestamp}`
}

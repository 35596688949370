
import { defineView } from '@kmsoft/upf-core'
import { EnumLifecycleState, EnumPhaseState, clsCodeMap, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KPromoteActivityAfterGridPropOptions, KPromoteActivityAfterGridEventEmits } from './interface'
import KPromoteActivityAfterGridViewModel from './KPromoteActivityAfterGridViewModel'
import { readonly } from 'vue'

export default defineView({
  name: 'KPromoteActivityAfterGrid',
  props: KPromoteActivityAfterGridPropOptions,
  emits: KPromoteActivityAfterGridEventEmits,
  viewModel: KPromoteActivityAfterGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumLifecycleState, EnumPhaseState, clsCodeMap, EnumLifecycleStateColor }
  }
})

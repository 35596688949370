import { IKTreeNode } from '@kmsoft/upf-core'

export interface ConfigTreeNodeCO extends IKTreeNode {
  code: string
  parentCode: string
  type: string
  orderId: number
}

/**
 * 选项卡
 */
export enum EnumTabType {
  // 系统配置
  SYS = '1',
  // 自定义配置
  DEV = '2'
}

/**
 * 配置类型
 */
export enum EnumNodeType {
  // 系统配置根目录
  SYS_ROOT = '1',
  // 系统配置模块节点
  SYS_MODULE = '0',
  // 系统配置子目录
  SYS_FOLDER = '2',
  // 系统配置项
  SYS_CONFIG = '3',
  // 自定义配置根目录
  DEV_ROOT = '11',
  // 自定义配置子目录
  DEV_FOLDER = '12',
  // 自定义配置项
  DEV_CONFIG = '13'
}

/**
 * 节点类型标识
 */
export enum EnumNodeLevel {
  ROOT = 'root',
  MODULE = 'module',
  FOLDER = 'folder',
  CONFIG = 'config',
  DRVIDER = 'divider'
}

/**
 * 配置项页签类型
 */
export enum EnumOper {
  // 预览
  VIEW = 'view',
  // 编辑
  EDIT = 'edit'
}

/**
 * 配置项页签类型
 */
export enum EnumConfigTabType {
  // 单值配置项
  VALUE = '1',
  // 集合配置项
  GRID = '2',
  // 自定义配置项
  CUSTOM = '3',
  // 平铺配置项
  TILE = '4'
}

/**
 * 数据类型
 */
export enum EnumDataType {
  // 单值
  VALUE = '0',
  // 集合
  GRID = '1',
  // 自定义
  CUSTOM = '2'
}

/**
 * 编辑方式
 */
export enum EnumEditType {
  // 文本框
  TEXT = '101',
  // 多行文本框
  TEXTAREA = '102',
  // 下拉菜单
  SELECT = '103',
  // 可编辑下拉菜单(不支持)
  SELECT_EDITABLE = '104',
  // 带显示值下拉菜单
  SELECT_DISPLAY = '105',
  // 开关
  SWITCH = '106',
  // 单选框
  RADIO = '107',
  // 多选框
  COMBOBOX = '201',
  // 列表
  LIST = '202',
  // 表格
  TABLE = '203',
  // 对象类
  OBJ = '204',
  // 自定义编辑方式
  CUSTOM = '301'
}

/**
 * 枚举
 */
export interface IValueDesc {
  label: string
  value: string
}

/**
 * 自定义列配置
 */
export interface IColumnDefinition {
  code: string
  name: string
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  SimpleViewPropOptions,
  SimpleViewEventEmits
} from '@kmsoft/upf-core'

/** 参数 **/
export const KActivityPlanDetailGridPropOptions = {
  ...SimpleViewPropOptions,
  dataSource: VuePropTypes.array().def([])
}

/** 参数类型 **/
export type KActivityPlanDetailGridPropType = ViewPropsTypeExtract<typeof KActivityPlanDetailGridPropOptions>

/** 事件 */
export const KActivityPlanDetailGridEventEmits = {
  ...SimpleViewEventEmits,
  /** 删除受影响对象 **/
  removeAffectedObject: (row: any) => true
}

/** 事件类型 **/
export type KActivityPlanDetailGridEmitsType = ViewEmitsTypeExtract<typeof KActivityPlanDetailGridEventEmits>

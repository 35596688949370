/** 运行环境帮助类 */
export class RuntimeHelper {
  /** 是否是生成环境 */
  static get isProduction() {
    return process.env.NODE_ENV === 'production'
  }

  /** 是否是开发环境 */
  static get isDevelopment() {
    return process.env.NODE_ENV === 'development'
  }

  /** 是否是自动化测试环境 */
  static get isAutomatedTesting() {
    const global = window as any
    return global.automatedTesting === true
  }
}


import { defineView, request } from '@kmsoft/upf-core'
import { KAgentDownloadPropOptions, KAgentDownloadEventEmits } from './interface'
import KAgentDownloadViewModel from './KAgentDownloadViewModel'
import { computed } from 'vue'
import { sysSrv } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KAgentDownload',
  props: KAgentDownloadPropOptions,
  emits: KAgentDownloadEventEmits,
  viewModel: KAgentDownloadViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const showIcon = computed(() => {
      return sysSrv.isSSOLogin()
    })
    const downloadFile = async () => {
      const url = '/kmsaasFileApi/download'
      const params = {
        modelName: 'DocumentMarkRecord',
        fileIds: '654779511799287808',
        attributeName: 'MarkFile'
      }

      try {
        const response = await request.post(url, params, {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        })

        if (response!.status != 200) {
          throw new Error('Network response was not ok')
        }
        const blob = new Blob([response!.data], { type: response!.headers['content-type'] })
        const downloadUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = downloadUrl
        a.download = 'KmUpgradeClient.sfx.exe' // 你想要下载的文件名
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(downloadUrl) // 释放 URL 对象
      } catch (error) {
        console.error('Download failed:', error)
      }
    }
    return {
      showIcon,
      downloadFile
    }
  }
})

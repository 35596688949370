import { connect, withInstall } from '@kmsoft/upf-core'
import KDocFilePropertyView from './KDocFileProperty.vue'
import KDocFilePropertyViewModel from './KDocFilePropertyViewModel'

const KDocFileProperty = connect(KDocFilePropertyView, KDocFilePropertyViewModel)

export default withInstall(KDocFileProperty)
export { KDocFileProperty, KDocFilePropertyView, KDocFilePropertyViewModel }

// 模板生成文件
// export * from './{{folderName}}'

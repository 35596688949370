import { defineView, VNodeProps } from '@kmsoft/upf-core'
import { KRuleDefinerPropOptions, KRuleDefinerEventEmits } from './interface'
import KRuleDefinerViewModel from './KRuleDefinerViewModel'
import { KRuleProperty, KRulePropertyEmitsType, KRulePropertyPropType } from './controls'
import { createVNode } from 'vue'
import { KObjectClassTreeSelectorEmitsType, KObjectClassTreeSelectorPropType } from '../editors/form/object-class-tree-selector'

/** 规则定义器 */
export default defineView({
  name: 'KRuleDefiner',
  props: KRuleDefinerPropOptions,
  emits: KRuleDefinerEventEmits,
  viewModel: KRuleDefinerViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      /**
       * 渲染树选择器
       * @returns
       */
      const renderTreeSelector = () => {
        if (!props.showObjClsTree) {
          return
        }

        /** 对象类树参数 */
        const objectClassTreeSelectorProps: VNodeProps<KObjectClassTreeSelectorPropType, KObjectClassTreeSelectorEmitsType> = {
          class: 'tree-selector',
          showDefaultSelect: false,
          showObjClsCodes: props.showObjClsCodes,
          showClear: true,
          disabled: props.disabledObjClsTree,
          'onUpdate:value': vm.onUpdateObjClsCode
        }

        return (
          <k-object-class-tree-selector
            {...objectClassTreeSelectorProps}
            v-models={[
              [vm.modelCode.value, 'value'],
              [vm.objClsName.value, 'objClsName']
            ]}
          />
        )
      }

      /**
       * 渲染规则定义
       * @returns
       */
      const renderRuleProperty = () => {
        if (!vm.fields.value) {
          return <k-empty description="无属性可设置" />
        }

        /** 规则属性参数 */
        const rulePropertyProps: VNodeProps<KRulePropertyPropType, KRulePropertyEmitsType> = {
          ...props,
          ref: vm.refRuleProperty,
          class: 'property',
          fields: vm.fields.value,
          condition: vm.condition.value,
          includeChild: vm.includeChild.value,
          showIncludeChildren: props.showIncludeChildren,
          'onUpdate:condition': vm.onUpdateCondition
        }
        return createVNode(KRuleProperty, rulePropertyProps)
      }

      return (
        <k-spin spinning={vm.isLoading.value}>
          <div class="k-rule-definer">
            {renderTreeSelector()}
            {renderRuleProperty()}
          </div>
        </k-spin>
      )
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import KFolderManage from '../../folder-manage'
import KCommonPartWorkspaceViewModel from './KCommonPartWorkspaceViewModel'
import { KCommonPartWorkspaceEventEmits, KCommonPartWorkspacePropOptions } from './interface'

export default defineView({
  name: 'KCommonPartWorkspace',
  props: KCommonPartWorkspacePropOptions,
  emits: KCommonPartWorkspaceEventEmits,
  components: { KFolderManage }, // 这里定义局部组件
  viewModel: KCommonPartWorkspaceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import {
  BaseViewModel,
  EnumDialogResult,
  EnumTreeViewNodeRefreshType,
  IKTreeNode,
  KDialog,
  KDialogClosingEvent,
  KIcon,
  KModal,
  KNotification,
  KTreeNode,
  KTreeViewViewModel,
  TreeViewMenuClickedEventArgs,
  utils,
  ViewModelOptions,
  PageManager
} from '@kmsoft/upf-core'
import { KRequireTreeEmitsType, KRequireTreePropType, RequireTreeNode } from './interface'
import { createVNode, ref, watch } from 'vue'
import { Api, CommonClientSrv, EnumRequestCode } from '@kmsoft/ebf-common'
import {
  CopyOrCutEnum,
  EnumToolStripItemKeys,
  IQueryData
} from '../../../../../ebf-folder/src/pages/folder-manage/folder-tree/interface'
import {
  KCustomerNeedsSectionCreate,
  KCustomerNeedsSectionCreateViewModel
} from '../../../pages/customer-needs/customer-needs-section-create'

/** KRequireTree */
export default class KRequireTreeViewModel extends BaseViewModel<KRequireTreeEmitsType, KRequireTreePropType> {
  refFolderTreeEl = ref<HTMLElement>()
  /**树组件 */
  refFolderTree = ref<KTreeViewViewModel>()
  /**复制还是粘贴中 */
  inCopyOrCut: CopyOrCutEnum = CopyOrCutEnum.NOTHING
  /** 查询条件 */
  queryCriteria = ref<string>('')
  /** 查询索引 */
  queryIndex = ref<number>(0)
  /** 查询结果 */
  queryData = ref<Array<IQueryData>>([])

  constructor(options: ViewModelOptions<KRequireTreePropType>) {
    super(options)
    watch(
      () => this.props.folderId,
      () => {
        this.refFolderTree.value?.refresh()
      }
    )
  }

  viewDidMount() {}

  refresh() {
    this.refFolderTree.value?.refresh()
  }

  toolClick(event: TreeViewMenuClickedEventArgs<any>) {
    if (event.name == undefined) {
      return
    }
    switch (event.name) {
      case EnumToolStripItemKeys.NEW:
        this.addDir(event.node)
        break
      case EnumToolStripItemKeys.DELETE:
        this.removeDir(event.node)
        break
      case EnumToolStripItemKeys.REFRESH:
        this.refreshNode(event.node)
        break
      case EnumToolStripItemKeys.SORT:
        this.sortDir(event.node)
        break
      default:
        break
    }
  }

  /**
   * 新建需求
   * @param node  节点
   *
   */
  addDir(node: IKTreeNode) {
    KDialog.show({
      title: '创建需求',
      props: {
        formValue: {
          id: node?.id,
          rdmExtensionType: node?.modelCode
        }
      },
      size: { height: 800, width: 1200 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KCustomerNeedsSectionCreate,
      onClosing: async (event: KDialogClosingEvent) => {
        const needViewModel = event.viewModel as KCustomerNeedsSectionCreateViewModel
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        if (needViewModel.validate()) {
          const res = await needViewModel.save()
          if (res && res.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '添加成功'
            })
            this.refreshNode(node)
          } else {
            KNotification.warn({
              message: '添加失败',
              description: res.message
            })
            return
          }
        }
      }
    })
  }

  /**
   * 删除需求
   * @param node 节点
   */
  removeDir(node: IKTreeNode) {
    KModal.delete({
      title: '目录删除提示',
      icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
      content: '您正在进行删除操作，请确认是否删除',
      onOk: async () => {
        const childNodes = this.refFolderTree.value?.getChildNodes(node.id)
        if (childNodes && childNodes.length != 0) {
          KNotification.warn({
            message: '删除失败',
            description: '请先删除子需求'
          })
          return
        }
        const param = {
          data: [node.id]
        }
        Api.post('requirement', `${node.modelCode}Section`, 'delete', param).then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS) {
            this.refFolderTree.value?.removeNode(node.id)
            KNotification.success({
              title: '系统提示',
              content: '目录删除成功'
            })
          } else {
            KNotification.warn({
              message: '删除失败',
              description: res.message
            })
            return
          }
        })
      }
    })
  }

  sortDir(node: IKTreeNode) {
    KNotification.info({
      message: '功能开发中'
    })
  }

  /**
   * 在同一个父节点下是否存在重名
   * @param parentId 父节点id
   * @param currentNodeId 当前节点id
   * @param newName 新名字
   */
  ifExistUnderParentNode(parentId: string, newName: string, currentNodeId?: string): boolean {
    const childNodes = this.refFolderTree.value?.getChildNodes(parentId)
    if (!childNodes) {
      return false
    }
    const duplicateNameNode = childNodes?.find(node => node.name == newName)
    //不存在重名
    if (!duplicateNameNode) {
      return false
      //存在重名，但是新名称与当前节点名称相同
    } else if (currentNodeId && duplicateNameNode.id == currentNodeId) {
      return false
    }
    return true
  }

  isDynamicSubfolder(node: IKTreeNode) {
    return node?.enableDynamicSubfolder == true
  }

  /**
   * 刷新子节点
   */
  refreshNode(node: IKTreeNode) {
    this.refFolderTree.value?.refresh(node.key)
  }

  /**
   * 清空查询数据
   */
  refreshQueryCriteria() {
    this.queryIndex.value = 0
    this.queryData.value = []
  }
  /**
   * 搜索需求
   */
  async searchProperty() {
    const qry = this.queryCriteria.value
    const index = this.queryIndex.value
    const queryData = this.queryData.value as Array<IQueryData>
    const rootNode = this.refFolderTree.value?.getRootNodes()[0] as KTreeNode
    if (qry == '') {
      this.refreshQueryCriteria()
    }

    // /** 判断queryData是否为空 */
    if (queryData.length == 0 || queryData[index] == undefined) {
      const param = {
        data: [qry, rootNode.id]
      }
      const result = await Api.post('folder', 'Folder', 'listByName', param)
      if (result && result.code == EnumRequestCode.SUCCESS) {
        // 接口成功
        // 判断是否查询到属性
        if (result.data.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '查询属性不存在'
          })
          this.queryIndex.value = 0
          return
        }

        if (result.data.length > 0) {
          this.queryData.value = result.data.map((res: Record<string, object>) => {
            return {
              directoryId: res.id,
              directoryPath: res.rawFullPath
                .toString()
                .split(rootNode.id + '/')[1]
                .slice(0, -1)
                .replaceAll('/', ',')
            }
          }) as Array<IQueryData>

          // 左侧树勾选当前选中的节点，会触发右侧网格数据的更新
          this.refFolderTree.value?.expandByPath(
            rootNode!,
            rootNode?.id +
              ',' +
              result
                .data![0].rawFullPath.toString()
                .split(rootNode.id + '/')[1]
                .slice(0, -1)
                .replaceAll('/', ','),
            true
          )
          this.queryIndex.value = 1
          return
        }
      }
    } else {
      this.refFolderTree.value?.expandByPath(rootNode!, rootNode?.id + ',' + queryData[index].directoryPath, true)
      this.queryIndex.value = index + 1
    }
  }

  /**
   * 获取选中的树节点
   * @returns 选中的树节点
   */
  getSelectNode() {
    return this.refFolderTree.value?.getSelectedNode()
  }
  getContainer() {
    return this.refFolderTreeEl.value
  }
}

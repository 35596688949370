import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "k-model-browser" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_vue_browser = _resolveComponent("k-vue-browser")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_vue_browser, {
      ref: "refBrowser",
      fileUrl: _ctx.$vm.fileUrl,
      fileType: _ctx.$vm.fileType,
      structData: _ctx.$vm.structData,
      onSaveComment: _ctx.$vm.onSaveComment,
      loadComments: _ctx.$vm.loadComments,
      environment: _ctx.environment,
      container: _ctx.container,
      showRemark: _ctx.$vm.props.showMark,
      onSetNodesHighlighted: _ctx.$vm.setNodesHighlighted
    }, null, 8, ["fileUrl", "fileType", "structData", "onSaveComment", "loadComments", "environment", "container", "showRemark", "onSetNodesHighlighted"])
  ]))
}

import { defineView } from '@kmsoft/upf-core'
import { KCommonSearcherPropOptions, KCommonSearcherEventEmits } from './interface'
import KCommonSearcherViewModel from './KCommonSearcherViewModel'
import { EnumClassTemplate } from '../../../../client-srv'

export default defineView({
  name: 'KCommonSearcher',
  props: KCommonSearcherPropOptions,
  emits: KCommonSearcherEventEmits,
  viewModel: KCommonSearcherViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumClassTemplate }
  }
})

import { ref, watch } from 'vue'
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ObjectRelationClientSrv, ObjectRelationDefination } from '../../client-srv'
import { KObjectRelationEmitsType, KObjectRelationPropType } from './interface'
import { KObjectRelationGridViewModel } from '../object-relation-grid'
import { IObjectPanelTab, ObjectPanelTabRetrieveParams } from '../object-panel'

/** KObjectRelation */
export default class KObjectRelationViewModel extends BaseViewModel<KObjectRelationEmitsType, KObjectRelationPropType>
  implements IObjectPanelTab {
  /**关系网格。*/
  public refRelationGrid = ref<KObjectRelationGridViewModel>()

  /** 当前选中的关系树定义。*/
  public currentRelation = ref<ObjectRelationDefination>()

  constructor(options: ViewModelOptions<KObjectRelationPropType>) {
    super(options)

    watch([() => this.props.objParam, () => this.props.relationCode], newValue => newValue && this.loadData(), {
      immediate: true,
      deep: true
    })
  }

  public retrieve(params: ObjectPanelTabRetrieveParams<string, Record<string, any>>): void {}

  public refresh(): Promise<void> {
    return this.loadData()
  }

  /**加载关系树。*/
  private async loadData() {
    //获取关联关系
    this.currentRelation.value = await ObjectRelationClientSrv.getDefinition(this.props.objParam.modelCode, this.props.relationCode)
  }
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectViewSelectorView from './KObjectViewSelector.vue'
import KObjectViewSelectorViewModel from './KObjectViewSelectorViewModel'

const KObjectViewSelector = connect(KObjectViewSelectorView, KObjectViewSelectorViewModel)

export default withInstall(KObjectViewSelector)
export { KObjectViewSelector, KObjectViewSelectorView, KObjectViewSelectorViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_radio_group = _resolveComponent("k-radio-group")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    ref: _ctx.$vm.refForm,
    model: _ctx.$vm.formData,
    "label-col": { span: 4 },
    "wrapper-col": { span: 16 }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, {
        label: "总体评价",
        name: "evaluate",
        rules: [{ required: true, message: '总体评价不能为空', trigger: 'blur' }]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_radio_group, {
            value: _ctx.$vm.formData.evaluate,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formData.evaluate) = $event)),
            options: _ctx.$vm.radioOptions
          }, null, 8, ["value", "options"])
        ]),
        _: 1
      }),
      _createVNode(_component_k_form_item, {
        label: "意见",
        name: "describe"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_textarea, {
            value: _ctx.$vm.formData!.describe,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formData!.describe) = $event)),
            maxlength: 170,
            rows: 3
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
import {
  BaseViewModel,
  EnumDialogResult,
  EnumDialogSize,
  IKTreeNode,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  KOrderAdjustPanel,
  KTreeViewViewModel,
  utils
} from '@kmsoft/upf-core'
import { KConfigTreeEventEmitsType, KConfigTreePropType } from './interface'
import { EnumNodeLevel, EnumNodeType, EnumTabType } from '../../interface'
import ModuleFormView from '../../common/module-form'
import ConfigFormView from '../../common/config-form'
import ModuleSearchView from '../../common/module-search'
import KModuleFormViewModel from '../../common/module-form/KModuleFormViewModel'
import KConfigFormViewModel from '../../common/config-form/KConfigFormViewModel'
import KModuleSearchViewModel from '../../common/module-search/KModuleSearchViewModel'
import { isReqSuccess, isTenantMode, showError, toTree } from '../../common/common'
import { IModuleSearchDefinition } from '../../common/module-search/interface'
import { RefreshConfigValuePanelEventArgs } from '../../sys/sys-config-tree/interface'
import { ref } from 'vue'
import { Api } from '@kmsoft/ebf-common'

export default class KDevConfigTreeViewModel extends BaseViewModel<KConfigTreeEventEmitsType, KConfigTreePropType> {
  // 目录树
  refObjClsTree = ref<KTreeViewViewModel>()
  treeSelectedKey = ref<string>()
  /** 右键菜单 */
  contextMenuMap = ref()

  // 是否显示粘贴菜单
  showPasteMenu = ref<boolean>(false)

  /** 复制树节点对象 */
  private copyNode: Array<IKTreeNode> = []
  /** 剪切树节点对象 */
  private cutNode: Array<IKTreeNode> = []
  /** 粘贴树节点对象 */
  private pasteNode: Array<IKTreeNode> = []
  /** 当前查询条件 */
  private currentCondition: IModuleSearchDefinition
  /** 查找到满足条件的节点清单 */
  private searchNodes: Array<IKTreeNode>
  /** 当前定位到的节点索引(在节点清单中的顺序号) */
  private curIndex: number

  /**
   * 加载完成函数
   */
  viewDidMount() {
    this.loadAllData()
    this.initContextMenuMap()
  }

  private loadAllData(): void {
    const param = {
      data: []
    }
    Api.post('sys', 'ConfigDevService', 'listConfigDevTreeAll', param).then(response => {
      if (!isReqSuccess(response)) {
        showError(response)
        return
      }
      const data = response?.data ?? ([] as Array<Record<string, any>>)
      let dList = [] as Array<IKTreeNode>
      for (const value of data) {
        const newNode = {
          id: value.id,
          name: value.name,
          iconType: this.calculateNodeIcon(value.type),
          leaf: true,
          orderId: value.orderId,
          type: value.type,
          code: value.code,
          parentCode: value.parentCode,
          remark: value.remark,
          defId: value.defId,
          dataType: value.dataType,
          editType: value.editType,
          editParam: value.editParam,
          visible: value.visible,
          valid: value.valid
        }
        dList.push(newNode)
      }
      dList = toTree(dList)
      this.refObjClsTree.value?.setValue(dList)
    })
  }

  /**
   * 根据节点类型分配图标
   * @param type 节点类型
   * @returns 图标名
   */
  private calculateNodeIcon(type: string): string {
    let icon = ''
    switch (type) {
      case EnumNodeType.DEV_ROOT:
        icon = 'global'
        break
      case EnumNodeType.DEV_FOLDER:
        icon = 'folder'
        break
      case EnumNodeType.DEV_CONFIG:
        icon = 'file'
        break
      default:
        icon = 'file'
        break
    }
    return icon
  }

  /**
   * 树节点点击事件
   * @param node 树节点对象
   */
  public refreshConfigValuePanel(node: IKTreeNode) {
    this.emit('refreshConfigValuePanel', new RefreshConfigValuePanelEventArgs(node))
  }

  /**
   * 计算指定节点下的子节点最大顺序号+1
   * 由于节点是按照顺序号排序的, 理论上最后一个节点的顺序号最大, 在此基础上+1即可
   * @param id 节点ID
   * @returns 顺序号
   */
  private calculateOrder(id: string): number {
    // 计算顺序号
    let orderId = 1
    const children = this.refObjClsTree.value?.getChildNodes(id)
    if (children != null && children.length > 0) {
      const lastSubNode: IKTreeNode = children[children.length - 1]
      orderId = lastSubNode.orderId + 1
    }
    return orderId
  }

  /**
   * 排序
   */
  async sortDevNode(node: IKTreeNode) {
    const { result } = await KOrderAdjustPanel.show({
      rowKey: 'id',
      list: node.children,
      fields: [
        { title: '编码', id: 'code' },
        { title: '名称', id: 'name' }
      ]
    })

    const sortedItems = result.sortedItems

    const newArray = sortedItems.map((a, index) => {
      return a.id
    })

    if (newArray.length > 0) {
      const subNodes = this.refObjClsTree.value?.getChildNodes(node.id)!

      const param = {
        data: [
          {
            parentId: node.id,
            idList: newArray
          }
        ]
      }
      const res = await Api.post('sys', 'ConfigDevService', 'sortConfigDevTree', param)
      if (!isReqSuccess(res)) {
        showError(res)
        return
      }

      const newSubNodes = [...subNodes]

      // 先删除节点
      for (let i = subNodes.length - 1; i >= 0; i--) {
        this.refObjClsTree.value?.removeNode(subNodes[i].id)
      }

      // 再重新添加节点
      const orderId = {} as any
      newArray.forEach(function(a, i) {
        orderId[a.toString()] = i
      })

      newSubNodes.sort(function(a, b) {
        return orderId[a.id] - orderId[b.id]
      })
      for (const subNode of newSubNodes) {
        this.refObjClsTree.value?.insertNode(node.id, subNode)
      }

      this.refObjClsTree.value?.expand(node.key!)
      this.refObjClsTree.value?.setSelectedNode(newArray[0])
    }
  }

  /**
   * 查找
   */
  locateDevNode(node: IKTreeNode) {
    this.currentCondition = {} as IModuleSearchDefinition
    this.searchNodes = [] as Array<IKTreeNode>
    this.curIndex = -1

    KDialog.show({
      title: '查找',
      size: { width: 718, height: 440 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      cancelText: '找上一处',
      okText: '找下一处',
      content: ModuleSearchView,
      props: {
        mode: EnumTabType.DEV
      },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KModuleSearchViewModel
        const formState = formViewModel.getValue()
        if (formState?.code === '' && formState?.name === '' && formState?.remark === '') {
          KNotification.info({
            message: '未输入任何条件'
          })
          event.cancel = true
          return
        }

        if (
          this.currentCondition?.code !== formState.code ||
          this.currentCondition?.name !== formState.name ||
          this.currentCondition?.remark !== formState.remark ||
          this.currentCondition?.pattern !== formState.pattern ||
          this.currentCondition?.folder !== formState.folder ||
          this.currentCondition?.config !== formState.config ||
          this.currentCondition?.branch !== formState.branch
        ) {
          this.searchNodes = []
          this.currentCondition = JSON.parse(JSON.stringify(formState))
          this.curIndex = -1

          let parentNode = null
          if (formState.branch === '1') {
            // 在当前分支检索
            parentNode = node
          } else {
            // 在全树检索
            parentNode = this.refObjClsTree.value?.getRootNodes() ?? []
            parentNode = parentNode[0]
          }
          const baseNodes = [] as Array<IKTreeNode>
          this.trans(parentNode, baseNodes)
          let searchNodes = [] as Array<IKTreeNode>

          if (formState.folder && formState.config) {
            // 在目录和配置项中检索
            searchNodes = baseNodes
          } else if (formState.folder) {
            // 在目录中检索
            for (const baseNode of baseNodes) {
              if (baseNode.type === EnumNodeType.DEV_FOLDER) {
                searchNodes.push(baseNode)
              }
            }
          } else if (formState.config) {
            // 在配置项中检索
            for (const baseNode of baseNodes) {
              if (baseNode.type === EnumNodeType.DEV_CONFIG) {
                searchNodes.push(baseNode)
              }
            }
          }

          const matchedNodes = [] as Array<IKTreeNode>
          for (const searchNode of searchNodes) {
            if (formState.pattern === '1') {
              // 包含
              const matched1 = formState.code === '' ? true : searchNode.code.indexOf(formState.code) >= 0
              const matched2 = formState.name === '' ? true : searchNode.name.indexOf(formState.name) >= 0
              const matched3 = formState.remark === '' ? true : searchNode.remark?.indexOf(formState.remark) >= 0
              if (matched1 && matched2 && matched3) {
                matchedNodes.push(searchNode)
              }
            } else if (formState.pattern === '2') {
              // 以开始
              const matched1 = formState.code === '' ? true : searchNode.code.indexOf(formState.code) === 0
              const matched2 = formState.name === '' ? true : searchNode.name.indexOf(formState.name) === 0
              const matched3 = formState.remark === '' ? true : searchNode.remark?.indexOf(formState.remark) === 0
              if (matched1 && matched2 && matched3) {
                matchedNodes.push(searchNode)
              }
            } else if (formState.pattern === '3') {
              // 等于
              const matched1 = formState.code === '' ? true : searchNode.code === formState.code
              const matched2 = formState.name === '' ? true : searchNode.name === formState.name
              const matched3 = formState.remark === '' ? true : searchNode.remark === formState.remark
              if (matched1 && matched2 && matched3) {
                matchedNodes.push(searchNode)
              }
            }
          }
          this.searchNodes = matchedNodes
        }
        if (this.searchNodes.length === 0) {
          KNotification.error({
            title: '提示',
            content: '没有匹配到任何节点'
          })
          event.cancel = true
        } else {
          if (event.dialogResult == EnumDialogResult.Cancel) {
            this.curIndex = this.curIndex - 1
            if (this.curIndex < 0) {
              this.curIndex = this.searchNodes.length - 1
            }
            this.refObjClsTree.value?.setSelectedNode(this.searchNodes[this.curIndex].id)
            this.refObjClsTree.value?.expandTo(this.searchNodes[this.curIndex].id)
          }
          if (event.dialogResult == EnumDialogResult.Confirm) {
            this.curIndex = this.curIndex + 1
            if (this.curIndex >= this.searchNodes.length) {
              this.curIndex = 0
            }
            this.refObjClsTree.value?.setSelectedNode(this.searchNodes[this.curIndex].id)
            this.refObjClsTree.value?.expandTo(this.searchNodes[this.curIndex].id)
          }
          event.cancel = true
        }
      }
    })
  }

  private trans(node: IKTreeNode, nodes: Array<IKTreeNode>) {
    nodes.push(node)
    const children = node.children ?? ([] as Array<IKTreeNode>)
    for (const child of children) {
      this.trans(child, nodes)
    }
  }

  /**
   * 添加目录
   */
  addDevFolder(node: IKTreeNode) {
    KDialog.show({
      title: '添加目录',
      size: EnumDialogSize.Normal,
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: ModuleFormView,
      props: {
        mode: EnumTabType.DEV,
        oper: 'insert',
        row: {
          parentCode: node.code,
          type: EnumNodeType.DEV_FOLDER,
          orderId: this.calculateOrder(node.id)
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KModuleFormViewModel

        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }

        const response = await formViewModel.save()
        if (!isReqSuccess(response)) {
          showError(response)
          event.cancel = true
          return
        }
        const newData = response.data

        const newNode = {
          id: newData?.id as unknown,
          name: newData?.name,
          iconType: this.calculateNodeIcon(newData?.type),
          leaf: true,
          orderId: newData?.orderId,
          type: newData?.type,
          code: newData?.code,
          parentCode: newData?.parentCode,
          remark: newData?.remark
        } as IKTreeNode
        this.refObjClsTree.value?.insertNode(node.id, newNode)
        this.refObjClsTree.value?.setSelectedNode(newNode.id)

        KNotification.success({ title: '提示', content: '添加成功' })
      }
    })
  }

  /**
   * 删除目录
   */
  delDevFolder(node: IKTreeNode) {
    const subNodes = this.refObjClsTree.value?.getChildNodes(node.id)
    if (subNodes != null && subNodes.length > 0) {
      KNotification.error({
        title: '提示',
        content: '目录下存在子目录或配置项'
      })
      return
    }

    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: async () => {
        const deleteParam = {
          data: [
            {
              id: node.id as unknown
            }
          ]
        }
        Api.post('sys', 'ConfigDevService', 'deleteConfigDevTree', deleteParam).then(deleteResponse => {
          if (!isReqSuccess(deleteResponse)) {
            showError(deleteResponse)
            return
          }
          this.refObjClsTree.value?.removeNode(node.id)
          KNotification.success({ title: '提示', content: '删除成功' })
        })
      }
    })
  }

  /**
   * 修改目录
   */
  modDevFolder(node: IKTreeNode) {
    KDialog.show({
      title: '目录属性',
      size: EnumDialogSize.Normal,
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: ModuleFormView,
      props: {
        mode: EnumTabType.DEV,
        oper: 'update',
        row: {
          defId: node.defId,
          id: node.id,
          code: node.code,
          parentCode: node.parentCode,
          name: node.name,
          type: node.type,
          remark: node.remark,
          orderId: node.orderId,
          visible: node.visible
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KModuleFormViewModel

        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }

        const response = await formViewModel.save()
        if (!isReqSuccess(response)) {
          showError(response)
          event.cancel = true
          return
        }
        const newData = response.data

        const newNode = {
          id: node.id,
          name: newData?.name,
          remark: newData.remark,
          visible: newData.visible
        }
        this.refObjClsTree.value?.modifyNode(newNode)

        KNotification.success({ title: '提示', content: '修改成功' })
      }
    })
  }

  /**
   * 添加配置项
   */
  addDevDef(node: IKTreeNode) {
    KDialog.show({
      title: '添加配置项',
      size: { width: 718, height: 520 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: ConfigFormView,
      props: {
        mode: EnumTabType.DEV,
        oper: 'insert',
        row: {
          parentCode: node.code,
          type: EnumNodeType.DEV_CONFIG,
          orderId: this.calculateOrder(node.id)
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KConfigFormViewModel

        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }

        const response = await formViewModel.save()
        if (!isReqSuccess(response)) {
          showError(response)
          event.cancel = true
          return
        }
        const newData = response.data
        const frontData = formViewModel.getValue()

        const newNode = {
          id: newData?.treeId as unknown,
          name: frontData?.name,
          iconType: this.calculateNodeIcon(frontData?.type ?? ''),
          leaf: true,
          orderId: frontData?.orderId,
          type: frontData?.type,
          code: frontData?.code,
          parentCode: frontData?.parentCode,
          remark: frontData?.remark,
          defId: newData?.id,
          dataType: frontData?.dataType,
          editType: frontData?.editType,
          editParam: frontData?.editParam
        } as IKTreeNode
        this.refObjClsTree.value?.insertNode(node.id, newNode)
        this.refObjClsTree.value?.setSelectedNode(newNode.id)

        KNotification.success({ title: '提示', content: '添加成功' })
      }
    })
  }

  /**
   * 删除配置项
   */
  delDevDef(node: IKTreeNode) {
    KDialog.confirm({
      title: '确认删除吗？删除后不可恢复!',
      onOk: async () => {
        const deleteParam = {
          data: [
            {
              treeId: node.id as unknown,
              id: node.defId
            }
          ]
        }
        Api.post('sys', 'ConfigDevService', 'deleteConfigDevDef', deleteParam).then(deleteResponse => {
          if (!isReqSuccess(deleteResponse)) {
            showError(deleteResponse)
            return
          }

          // 删除前先检查关系: 检查实际选中节点是否是当前删除配置项节点的上级, 如果是, 说明此刻右侧列表显示有待删除的配置项信息, 在配置项删除后需要刷新右侧区域
          const isParent = this.checkIfSelectedNodeIsDelNodeParent(node)

          this.refObjClsTree.value?.removeNode(node.id)
          KNotification.success({ title: '提示', content: '删除成功' })

          // 刷新右侧区域(先取消选中, 再选中同一节点, 到达触发刷新的目的)
          if (isParent) {
            const selectedNode = this.refObjClsTree.value?.getSelectedNode()!
            if (selectedNode !== null) {
              this.refObjClsTree.value?.clearSelected()
              this.refObjClsTree.value?.setSelectedNode(selectedNode.id)
            }
          }
        })
      }
    })
  }

  /**
   * 检查选择节点是否是当前操作节点的上级
   * @param node 当前操作节点
   * @returns true表示是
   */
  private checkIfSelectedNodeIsDelNodeParent(node: IKTreeNode): boolean {
    let matched = false
    const selectedNode = this.refObjClsTree.value?.getSelectedNode()!
    if (selectedNode === null || selectedNode.type === EnumNodeType.DEV_ROOT || selectedNode.type === EnumNodeType.DEV_CONFIG) {
      matched = false
    } else {
      let parentNode = this.refObjClsTree.value?.getParentNode(node.id)!
      while (parentNode !== null) {
        if (parentNode.id === selectedNode.id) {
          matched = true
          break
        }
        parentNode = this.refObjClsTree.value?.getParentNode(parentNode.id)!
      }
    }
    return matched
  }

  /**
   * 检查选择节点是否是当前操作节点
   * @param node 当前操作节点
   * @returns true表示是
   */
  private checkIfSelectedNodeIsOperNode(node: IKTreeNode): boolean {
    let matched = false
    const selectedNode = this.refObjClsTree.value?.getSelectedNode()!
    if (selectedNode !== null && selectedNode.id === node.id) {
      matched = true
    }
    return true
  }

  /**
   * 修改配置项
   */
  modDevDef(node: IKTreeNode) {
    KDialog.show({
      title: '配置项属性',
      size: { width: 718, height: 520 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: ConfigFormView,
      props: {
        mode: EnumTabType.DEV,
        oper: 'update',
        row: {
          treeId: node.id,
          id: node.defId,
          code: node.code,
          parentCode: node.parentCode,
          name: node.name,
          type: node.type,
          remark: node.remark,
          orderId: node.orderId,
          dataType: node.dataType,
          editType: node.editType,
          editParam: node.editParam,
          visible: node.visible
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const formViewModel = event.viewModel as KConfigFormViewModel

        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }

        const response = await formViewModel.save()
        if (!isReqSuccess(response)) {
          showError(response)
          event.cancel = true
          return
        }

        // 判断当前选择节点是否是当前操作节点, 如果是则右侧区域需要刷新
        const isSelf = this.checkIfSelectedNodeIsOperNode(node)

        const frontData = formViewModel.getValue()
        if (frontData) {
          // 同一个配置项可能在不同的目录下存在, 找到所有的配置项, 都需要进行修改
          const sameNodes = this.getAllSameConfigNode(node.defId)
          const nodeIds = [] as Array<string>
          for (const sameNode of sameNodes) {
            nodeIds.push(sameNode.id)
          }
          for (let i = nodeIds.length - 1; i >= 0; i--) {
            const modSameNode = {
              id: nodeIds[i],
              name: frontData.name,
              editType: frontData.editType,
              editParam: frontData.editParam,
              remark: frontData.remark,
              visible: frontData.visible
            } as IKTreeNode
            this.refObjClsTree.value?.modifyNode(modSameNode)
          }
        }

        // 刷新右侧区域(先取消选中, 再选中同一节点, 到达触发刷新的目的)
        if (isSelf) {
          this.refObjClsTree.value?.clearSelected()
          this.refObjClsTree.value?.setSelectedNode(node.id)
        }

        KNotification.success({ title: '提示', content: '修改成功' })
      }
    })
  }

  /**
   * 获取所有和指定配置项ID一致的树节点集合
   * @param defId 匹配的配置项ID
   * @returns 相同配置项树节点集合
   */
  private getAllSameConfigNode(defId: string): Array<IKTreeNode> {
    const sameNodes = [] as Array<IKTreeNode>
    let rootNode = {} as IKTreeNode
    const rootNodes = this.refObjClsTree.value?.getRootNodes()
    if (rootNodes !== undefined && rootNodes !== null && rootNodes.length > 0) {
      rootNode = rootNodes[0]
    }
    const baseNodes = [] as Array<IKTreeNode>
    this.trans(rootNode, baseNodes)
    for (const baseNode of baseNodes) {
      if (baseNode.defId === defId) {
        sameNodes.push(baseNode)
      }
    }
    return sameNodes
  }

  /**
   * 复制配置项
   * @param node 待复制树节点
   */
  copyDevNode(node: IKTreeNode) {
    this.showPasteMenu.value = true
    this.copyNode = [node]
    this.cutNode = []
    this.pasteNode = []
    KNotification.success({
      title: '提示',
      content: '复制成功'
    })
  }

  /**
   * 剪切配置项
   * @param node 待剪切树节点
   */
  cutDevNode(node: IKTreeNode) {
    this.showPasteMenu.value = true
    this.cutNode = [node]
    this.copyNode = []
    this.pasteNode = []
    KNotification.success({
      title: '提示',
      content: '剪切成功'
    })
  }

  /**
   * 粘贴配置项
   * @param node 待粘贴树节点
   */
  pasteDevNode(node: IKTreeNode) {
    this.pasteNode = [node]
    /**
     * 复制粘贴规则梳理
     * 1、必需选择复制节点、粘贴节点
     * 2、粘贴节点不能为配置项
     * -------------------------
     * 3、复制节点不能和粘贴节点相同
     * 4、复制节点不能和粘贴节点父节点相同
     * 5、复制节点不能和粘贴节点子节点相同
     */
    if (this.copyNode.length === 0 && this.cutNode.length === 0) {
      KNotification.info({
        message: '请先进行复制或者剪贴操作'
      })
      return
    }
    if (this.copyNode.length !== 0) {
      // 复制操作
      const copyParentNode = this.refObjClsTree.value?.getParentNode(this.copyNode[0].id) ?? { id: '' }
      if (copyParentNode.id === this.pasteNode[0].id) {
        KNotification.warn({
          message: '复制节点已经在粘贴节点下'
        })
        return
      }
      // 检查复制节点作为粘贴节点的上级现象
      const checked = this.checkNodeRelation(this.copyNode[0].id, this.pasteNode[0].id)
      if (!checked) {
        return
      }
      const param = {
        data: [
          {
            sourceTreeId: this.copyNode[0].id as unknown,
            sourceDefId: this.copyNode[0].defId,
            targetTreeId: this.pasteNode[0].id as unknown,
            orderId: this.calculateOrder(this.pasteNode[0].id)
          }
        ]
      }
      Api.post('sys', 'ConfigDevService', 'copyConfigDevTree', param).then(devTreeCopyResponse => {
        if (!isReqSuccess(devTreeCopyResponse)) {
          showError(devTreeCopyResponse)
          return
        }
        const newData = devTreeCopyResponse.data
        const newNode = {
          id: newData?.id as unknown,
          name: this.copyNode[0].name,
          iconType: this.calculateNodeIcon(this.copyNode[0].type),
          leaf: this.copyNode[0].leaf,
          orderId: newData?.orderId,
          type: this.copyNode[0].type,
          code: this.copyNode[0].code,
          parentCode: this.pasteNode[0].code,
          remark: this.copyNode[0].remark,
          defId: newData?.defId,
          dataType: this.copyNode[0].dataType,
          editType: this.copyNode[0].editType,
          editParam: this.copyNode[0].editParam
        } as IKTreeNode
        this.refObjClsTree.value?.insertNode(this.pasteNode[0].id, newNode)

        this.showPasteMenu.value = false
        this.copyNode = []
        this.cutNode = []
        this.pasteNode = []
      })
    }
    if (this.cutNode.length !== 0) {
      // 剪切操作
      if (this.cutNode[0].id === this.pasteNode[0].id) {
        KNotification.warn({
          message: '剪切节点和粘贴节点不能相同'
        })
        return
      }
      const cutParentNode = this.refObjClsTree.value?.getParentNode(this.cutNode[0].id) ?? { id: '' }
      if (cutParentNode.id === this.pasteNode[0].id) {
        KNotification.warn({
          message: '剪切节点已经在粘贴节点下'
        })
        return
      }
      // 检查剪切节点作为粘贴节点的上级现象
      const checked = this.checkNodeRelation(this.cutNode[0].id, this.pasteNode[0].id)
      if (!checked) {
        return
      }
      const param = {
        data: [
          {
            sourceTreeId: this.cutNode[0].id as unknown,
            sourceDefId: this.cutNode[0].defId,
            targetTreeId: this.pasteNode[0].id as unknown,
            orderId: this.calculateOrder(this.pasteNode[0].id)
          }
        ]
      }
      Api.post('sys', 'ConfigDevService', 'cutConfigDevTree', param).then(devTreeCutResponse => {
        if (!isReqSuccess(devTreeCutResponse)) {
          showError(devTreeCutResponse)
          return
        }
        const newData = devTreeCutResponse.data
        const newNode = {
          id: newData?.id as unknown,
          name: this.cutNode[0].name,
          iconType: this.calculateNodeIcon(this.cutNode[0].type),
          leaf: this.cutNode[0].leaf,
          orderId: newData?.orderId,
          type: this.cutNode[0].type,
          code: this.cutNode[0].code,
          parentCode: this.pasteNode[0].code,
          remark: this.cutNode[0].remark,
          defId: newData?.defId,
          dataType: this.cutNode[0].dataType,
          editType: this.cutNode[0].editType,
          editParam: this.cutNode[0].editParam,
          children: this.cutNode[0].children
        } as IKTreeNode
        this.refObjClsTree.value?.removeNode(this.cutNode[0].id)
        this.refObjClsTree.value?.insertNode(this.pasteNode[0].id, this.cutNode)

        this.showPasteMenu.value = false
        this.copyNode = []
        this.cutNode = []
        this.pasteNode = []
      })
    }
  }

  /**
   * 检查粘贴节点是否是复制节点的下级节点
   * @param sourceNodeId 复制节点ID
   * @param targetNodeId  粘贴节点ID
   * @returns 操作结果(false表示异常, true表示正常)
   */
  private checkNodeRelation(sourceNodeId: string, targetNodeId: string): boolean {
    const subNodes = this.refObjClsTree.value?.getChildNodes(sourceNodeId)
    if (subNodes != null && subNodes.length > 0) {
      for (const subNode of subNodes) {
        if (subNode.id === targetNodeId) {
          KNotification.warn({
            message: '复制节点作为粘贴节点的上级'
          })
          return false
        }
        this.checkNodeRelation(subNode.id, targetNodeId)
      }
    }
    return true
  }

  private initContextMenuMap(): void {
    if (!isTenantMode()) {
      const contextMenuMap = {
        /**
         * 自定义配置根节点（添加目录、排序、分隔符、粘贴、分隔符、查找）
         */
        [EnumNodeType.DEV_ROOT]: [
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_ROOT,
            title: '添加目录',
            callback: 'addDevFolder',
            icon: 'plus'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_ROOT,
            title: '排序',
            callback: 'sortDevNode',
            icon: 'ordered-list'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_ROOT,
            title: '粘贴',
            callback: 'pasteDevNode',
            icon: 'snippets',
            pasteTag: '1'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符', pasteTag: '1' },
          { id: utils.uuid(32), type: EnumNodeType.DEV_ROOT, title: '查找', callback: 'locateDevNode', icon: 'search' }
        ],
        /**
         * 自定义配置目录节点（添加目录、添加配置项、删除、分隔符、属性、排序、分隔符、剪切、粘贴、分隔符、查找）
         */
        [EnumNodeType.DEV_FOLDER]: [
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '添加目录',
            callback: 'addDevFolder',
            icon: 'plus'
          },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '添加配置项',
            callback: 'addDevDef',
            icon: 'plus'
          },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '删除',
            callback: 'delDevFolder',
            icon: 'delete'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '属性',
            callback: 'modDevFolder',
            icon: 'value-prop'
          },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '排序',
            callback: 'sortDevNode',
            icon: 'ordered-list'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          { id: utils.uuid(32), type: EnumNodeType.DEV_FOLDER, title: '剪切', callback: 'cutDevNode', icon: 'scissor' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '粘贴',
            callback: 'pasteDevNode',
            icon: 'snippets',
            pasteTag: '1'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '查找',
            callback: 'locateDevNode',
            icon: 'search'
          }
        ],
        /**
         * 自定义配置项（删除、分隔符、属性、分隔符、复制、剪切、分隔符、查找）
         */
        [EnumNodeType.DEV_CONFIG]: [
          { id: utils.uuid(32), type: EnumNodeType.DEV_CONFIG, title: '删除', callback: 'delDevDef', icon: 'delete' },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_CONFIG,
            title: '属性',
            callback: 'modDevDef',
            icon: 'value-prop'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          { id: utils.uuid(32), type: EnumNodeType.DEV_CONFIG, title: '复制', callback: 'copyDevNode', icon: 'copy' },
          { id: utils.uuid(32), type: EnumNodeType.DEV_CONFIG, title: '剪切', callback: 'cutDevNode', icon: 'scissor' },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_CONFIG,
            title: '查找',
            callback: 'locateDevNode',
            icon: 'search'
          }
        ]
      }
      this.contextMenuMap.value = contextMenuMap
    } else {
      const contextMenuMap = {
        /**
         * 自定义配置根节点（添加目录、排序、分隔符、粘贴、分隔符、查找）
         */
        [EnumNodeType.DEV_ROOT]: [
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_ROOT,
            title: '添加目录',
            callback: 'addDevFolder',
            icon: 'plus'
          },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_ROOT,
            title: '排序',
            callback: 'sortDevNode',
            icon: 'ordered-list'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_ROOT,
            title: '粘贴',
            callback: 'pasteDevNode',
            icon: 'snippets',
            pasteTag: '1'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          { id: utils.uuid(32), type: EnumNodeType.DEV_ROOT, title: '查找', callback: 'locateDevNode', icon: 'search' }
        ],
        /**
         * 自定义配置目录节点（添加目录、添加配置项、删除、分隔符、属性、排序、分隔符、剪切、粘贴、分隔符、查找）
         */
        [EnumNodeType.DEV_FOLDER]: [
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '添加目录',
            callback: 'addDevFolder',
            icon: 'plus'
          },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '添加配置项',
            callback: 'addDevDef',
            icon: 'plus'
          },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '删除',
            callback: 'delDevFolder',
            icon: 'delete'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '属性',
            callback: 'modDevFolder',
            icon: 'value-prop'
          },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '排序',
            callback: 'sortDevNode',
            icon: 'ordered-list'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          { id: utils.uuid(32), type: EnumNodeType.DEV_FOLDER, title: '剪切', callback: 'cutDevNode', icon: 'scissor' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '粘贴',
            callback: 'pasteDevNode',
            icon: 'snippets',
            pasteTag: '1'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_FOLDER,
            title: '查找',
            callback: 'locateDevNode',
            icon: 'search'
          }
        ],
        /**
         * 自定义配置项（删除、分隔符、属性、分隔符、复制、剪切、分隔符、查找）
         */
        [EnumNodeType.DEV_CONFIG]: [
          { id: utils.uuid(32), type: EnumNodeType.DEV_CONFIG, title: '删除', callback: 'delDevDef', icon: 'delete' },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_CONFIG,
            title: '属性',
            callback: 'modDevDef',
            icon: 'value-prop'
          },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          { id: utils.uuid(32), type: EnumNodeType.DEV_CONFIG, title: '复制', callback: 'copyDevNode', icon: 'copy' },
          { id: utils.uuid(32), type: EnumNodeType.DEV_CONFIG, title: '剪切', callback: 'cutDevNode', icon: 'scissor' },
          { id: utils.uuid(32), type: EnumNodeLevel.DRVIDER, title: '分隔符' },
          {
            id: utils.uuid(32),
            type: EnumNodeType.DEV_CONFIG,
            title: '查找',
            callback: 'locateDevNode',
            icon: 'search'
          }
        ]
      }
      this.contextMenuMap.value = contextMenuMap
    }
  }
}

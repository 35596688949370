
import { IKTreeNode, defineView } from '@kmsoft/upf-core'
import { KDesignerToolboxPropOptions, KDesignerToolboxEventEmits } from './interface'
import KDesignerToolboxViewModel from './KDesignerToolboxViewModel'
import { KDesignerControlPanel } from './control'
import { KDesignerHierarchy } from './hierarchy'
import { KDesignerMetadata } from './metadata'

export default defineView({
  name: 'KDesignerToolbox',
  props: KDesignerToolboxPropOptions,
  emits: KDesignerToolboxEventEmits,
  components: { KDesignerControlPanel, KDesignerHierarchy, KDesignerMetadata },
  viewModel: KDesignerToolboxViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    /**
     * 层级树节点选中事件
     * @param node 选中的节点
     */
    const onHierarchyTreeNodeSelected = (node: IKTreeNode) => {
      emit('hierarchyTreeNodeSelected', node)
    }

    return {
      onHierarchyTreeNodeSelected
    }
  }
})

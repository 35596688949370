
import { defineView } from '@kmsoft/upf-core'
import KFolderManage from '../../folder-manage'
import KStandardWorkspaceViewModel from './KStandardWorkspaceViewModel'
import { KStandardWorkspaceEventEmits, KStandardWorkspacePropOptions } from './interface'

export default defineView({
  name: 'KStandardWorkspace',
  props: KStandardWorkspacePropOptions,
  emits: KStandardWorkspaceEventEmits,
  components: { KFolderManage }, // 这里定义局部组件
  viewModel: KStandardWorkspaceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KBaseEditorPropOptions, KBaseEditorEventEmits } from './interface'
import KBaseEditorViewModel from './KBaseEditorViewModel'

export default defineView({
  name: 'KBaseEditor',
  props: KBaseEditorPropOptions,
  emits: KBaseEditorEventEmits,
  viewModel: KBaseEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { IKTreeNode, KTreeViewViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDirectoryTreeEventEmitsType, KDirectoryTreePropType } from './interface'
import { KRootDirectoryTreeNode } from './nodes'

/** KDirectoryTree 视图模型 */
export default class KDirectoryTreeViewModel extends KTreeViewViewModel<KDirectoryTreeEventEmitsType, KDirectoryTreePropType> {
  get rootId() {
    return '__root__'
  }

  /** 目录模块 */
  get moduleType() {
    return this.props.moduleType
  }

  /** 处理叶子节点时，考虑目录关联对象，为true时：如果目录下没有目录，但是有目录对象，当前目录不会被标记为叶子节点，默认值：false */
  get directoryTypes() {
    return this.props.directoryTypes
  }

  /** 要显示的根目录 */
  get showRootDirectoryCodes() {
    return this.props.showRootDirectoryCodes
  }

  constructor(options: ViewModelOptions<KDirectoryTreePropType>) {
    super(options)
  }

  /** 加载完成函数 */
  viewDidMount() {}

  /**
   * 数据加载
   * @param node
   * @override
   * @returns
   */
  onLoadData(node: null | IKTreeNode) {
    return [new KRootDirectoryTreeNode()]
  }
}

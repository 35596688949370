import { AgentStaticObject } from '../../../AgentStaticObject'

import { Encoding } from '../../../system/text/Encoding'

/**提供一系列静态服务。*/
export class ConstantValue extends AgentStaticObject {
  protected static type: string = 'KMSoft.Base.WindowsAPIExtensions.ConstantValue,KMSoft.Base.WindowsAPIExtensions'

  /**获取本地编码（非Unicode程序中使用的当前语言）。*/
  public static async LocalEncoding(): Promise<Encoding> {
    return this.getOrAddToMap('a2294786-29ab-49ea-aa98-0907ceaf15b2', () => this.staticPropertyAndWrap(Encoding, 'LocalEncoding'))
  }
}

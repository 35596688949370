
import { defineView } from '@kmsoft/upf-core'
import { KObjectClassGridFilterPropOptions, KObjectClassGridFilterEventEmits } from './interface'
import { KObjectClassGridFilterViewModel } from './KObjectClassGridFilterViewModel'

/** 网格过滤器 */
export default defineView({
  inheritAttrs: false,
  props: KObjectClassGridFilterPropOptions,
  emits: KObjectClassGridFilterEventEmits,
  viewModel: KObjectClassGridFilterViewModel,
  setup(props, { attrs, emit, expose, slots }) {
    return {}
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KBottomBarPropOptions, KBottomBarEventEmits } from './interface'
import KBottomBarViewModel from './KBottomBarViewModel'
import './style/index.less'

export default defineView({
  name: 'KBottomBar',
  props: KBottomBarPropOptions,
  emits: KBottomBarEventEmits,
  viewModel: KBottomBarViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { enumFactory } from '../model'

export const EnumWorkingState = enumFactory({
  INWORK: '工作中',
  CHECKED_IN: '已检入',
  CHECKED_OUT: '已检出'
})

export const EnumActivityType = enumFactory({
  CHECK: '检查',
  REVIEW: '评审',
  PROOFREAD: '校对'
})

export const EnumDocumentType = enumFactory({
  UG_SOLIDEDGE: 'UG数模',
  SOLIDWORKS: 'SW数模',
  AUTOCAD_2D: 'AUTOCAD图纸',
  EXCEL: 'Excel文件',
  WORD: 'Word文件'
})
export const EnumLifecycleState = enumFactory({
  Start: '开始',
  End: '结束',
  RELEASED: '已发布',
  UNDERREVIEW: '审核中',
  INWORK: '工作中',
  TERMINATED: '评审终止',
  OPENNED: '已创建',
  PROCESSED: '已处理',
  PROCESSING: '处理中',
  ACCEPTED: '已采纳',
  EVALUATED: '评估中',
  RECOGNIZED: '已识别',
  VALIDATING: '验证中',
  CONFIRMED: '已确认',
  CONFIRMING: '确认中',
  RAISED: '已提出',
  CLOSED: '已关闭',
  OBSOLETED: '已废弃'
})
export const EnumLifecycleStateColor = enumFactory({
  Start: '#28a745',
  End: '#6c757d',
  RELEASED: '#007bff',
  UNDERREVIEW: '#fd7e14',
  INWORK: '#52c41a',
  TERMINATED: '#dc3545',
  OPENNED: '#4abddb',
  PROCESSED: '#218838',
  PROCESSING: '#ffc107',
  ACCEPTED: '#6f42c1',
  EVALUATED: '#d63384',
  RECOGNIZED: '#20c997',
  VALIDATING: '#ffc107',
  CONFIRMED: '#0056b3',
  CONFIRMING: '#007bff',
  RAISED: '#e83e8c',
  CLOSED: '#343a40',
  OBSOLETED: '#495057'
})
export const EnumComplexity = enumFactory({
  LOW: '低复杂度',
  MEDIUM: '中等复杂度',
  HIGH: '高复杂度'
})

export const EnumChangeType = enumFactory({
  DOCUMENTATION_CORRECTION: '资料修正',
  DESIGN_CHANGE: '设计变更',
  COMPONENT_CHANGE: '零部件变更',
  OTHER: '其它'
})

export const EnumPriority = enumFactory({
  LOW: '低',
  MEDIUM: '中',
  HIGH: '高',
  URGENT: '紧急'
})

export const EnumLifecycleStateSimple = enumFactory({
  RELEASED: '已发布',
  UNDERREVIEW: '审核中',
  INWORK: '工作中'
})
export const EnumPhaseState = enumFactory({
  D: '设计',
  T: '试制',
  S: '量产',
  X: '废弃'
})

export const EnumHandingMethod = enumFactory({
  1: '报废',
  2: '继续使用'
})

export const EnumValidationMetrics = enumFactory({
  faultTolerant: '容错',
  EnvProtection: '环保',
  Performance: '性能',
  Safe: '安全',
  Function: '功能',
  Reliability: '可靠性'
})

export const EnumVerification = enumFactory({
  Development: '研制测试',
  Simulation: '仿真测试'
})

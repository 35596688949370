import { EnumAttributeType, EnumControlElementType, EnumDataType, EnumGridElementType, KControlAttribute } from '@kmsoft/upf-core'
import { KDesignerControl } from '../../../../types'
import { EnumPropsConfigItemName, getCandidates } from '../../../../config'

export const COLUMN_BASE_ATTRS: Array<KControlAttribute> = [
  {
    name: EnumPropsConfigItemName.TITLE,
    title: '标题',
    control: 'KInput',
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    dataType: EnumDataType.STRING
  },
  {
    name: 'field',
    title: '字段',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.BASE
  },
  {
    name: 'width',
    title: '宽度',
    control: 'KInput',
    defaultValue: 'auto',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'align',
    title: '对齐方式',
    control: 'KSelect',
    options: getCandidates(['left', 'right', 'center']),
    defaultValue: 'left',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'frozen',
    title: '冻结',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'hide',
    title: '隐藏',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'rowDrag',
    title: '可拖拽',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  }
]

export const DEFAULT_COLUMN: Array<KDesignerControl> = [
  {
    name: 'column_group',
    title: '分组列',
    control: EnumControlElementType.GROUP_COLUMN,
    type: EnumGridElementType.GROUP as any,
    attrs: [...COLUMN_BASE_ATTRS],
    events: [],
    dataType: '',
    icon: 'font-colors',
    controlType: '容器列'
  },
  {
    name: 'column_compute',
    title: '计算列',
    control: EnumControlElementType.EXPRESSION,
    type: EnumGridElementType.COMPUTE as any,
    icon: 'font-colors',
    attrs: [
      ...COLUMN_BASE_ATTRS,
      {
        name: 'express',
        title: '公式',
        control: 'KInput',
        defaultValue: '',
        dataType: EnumDataType.STRING,
        attributeType: EnumAttributeType.BASE
      }
    ],
    events: [],
    dataType: '',
    controlType: '容器列'
  }
]

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { PartParamInter } from './baseline-create-form/interface'

/** 参数 **/
export const KBaselineCreatePropOptions = {
  ...BaseViewPropOptions,
  /** 是否显示添加按钮 **/
  showAddButton: VuePropTypes.bool().def(true),
  /** part创建基线时查询参数 **/
  partParam: VuePropTypes.createType<Partial<PartParamInter>>().def({}),
  collectedParts: VuePropTypes.createType<Array<any>>().def()
}

/** 参数类型 **/
export type KBaselineCreatePropType = ViewPropsTypeExtract<typeof KBaselineCreatePropOptions>

/** 事件 */
export const KBaselineCreateEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KBaselineCreateEmitsType = ViewEmitsTypeExtract<typeof KBaselineCreateEventEmits>

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeOrderEditView from './KChangeOrderEdit.vue'
import KChangeOrderEditViewModel from './KChangeOrderEditViewModel'

const KChangeOrderEdit = connect(KChangeOrderEditView, KChangeOrderEditViewModel)

export default withInstall(KChangeOrderEdit)
export { KChangeOrderEdit, KChangeOrderEditView, KChangeOrderEditViewModel }

// 模板生成文件
// export * from './{{folderName}}'

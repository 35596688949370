
import { defineView } from '@kmsoft/upf-core'
import { KPartUsingStatisticsPropOptions, KPartUsingStatisticsEventEmits } from './interface'
import KPartUsingStatisticsViewModel from './KPartUsingStatisticsViewModel'
import { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KPartUsingStatistics',
  props: KPartUsingStatisticsPropOptions,
  emits: KPartUsingStatisticsEventEmits,
  viewModel: KPartUsingStatisticsViewModel,
  setup() {
    return {
      clsCodeMap,
      EnumLifecycleState,
      EnumLifecycleStateColor
    }
  }
})

import { AgentStaticObject } from '../../AgentStaticObject'

import { DirectoryInfo } from './DirectoryInfo'
import { EnumerationOptions } from './EnumerationOptions'
import { SearchOption } from './SearchOption'

/**公开用于通过目录和子目录进行创建、移动和枚举的静态方法。 此类不能被继承。*/
export class Directory extends AgentStaticObject {
  protected static type: string = 'System.IO.Directory,System.Runtime'

  /**确定给定路径是否引用磁盘上的现有目录。
   * @param path 要测试的路径。
   * @returns 如果 {@link path} 指向现有目录，则为 true；如果该目录不存在或者在尝试确定指定目录是否存在时出错，则为 false。*/
  public static async Exists(path?: string): Promise<boolean> {
    return this.invokeStaticMethod('Exists', path)
  }

  /**返回与在指定目录中的指定搜索模式匹配的子目录的名称（包括其路径），还可以选择地搜索子目录。
   * @param path 要搜索的目录的相对或绝对路径。 此字符串不区分大小写。
   * @param searchPattern 要与 {@link path} 中的子目录的名称匹配的搜索字符串。 此参数可以包含有效文本和通配符的组合，但不支持正则表达式。
   * @param searchOption 用于指定搜索操作是应包含所有子目录还是仅包含当前目录的枚举值之一。
   * @returns 与指定条件匹配的子目录的完整名称（包含路径）的数组；如果未找到任何目录，则为空数组。*/
  public static async GetDirectories(path: string, searchPattern: string, searchOption: SearchOption): Promise<string[]>
  /**返回指定目录中与指定的搜索模式和枚举选项匹配的子目录的名称（包括其路径）。
   * @param path 要搜索的目录的相对或绝对路径。 此字符串不区分大小写。
   * @param searchPattern 要与 {@link path} 中的子目录的名称匹配的搜索字符串。 此参数可以包含有效文本和通配符的组合，但不支持正则表达式。
   * @param enumerationOptions 描述要使用的搜索和枚举配置的对象。*/
  public static async GetDirectories(
    path: string,
    searchPattern: string,
    enumerationOptions: EnumerationOptions
  ): Promise<string[]>
  public static async GetDirectories(
    path: string,
    searchPattern: string,
    option: SearchOption | EnumerationOptions
  ): Promise<string[]> {
    return this.invokeStaticMethod('GetDirectories', path, searchPattern, option)
  }

  /**返回指定目录中与指定的搜索模式匹配的文件的名称（包含其路径），使用某个值确定是否要搜索子目录。
   * @param path 要搜索的目录的相对或绝对路径。 此字符串不区分大小写。
   * @param searchPattern 要与 {@link path} 中的文件名匹配的搜索字符串。 此参数可以包含有效文本路径和通配符（* 和 ?）的组合，但不支持正则表达式。
   * @param searchOption 用于指定搜索操作是应包含所有子目录还是仅包含当前目录的枚举值之一。*/
  public static async GetFiles(path: string, searchPattern: string, searchOption: SearchOption): Promise<string[]>
  /**返回指定目录中与指定的搜索模式和枚举选项匹配的文件的名称（包括其路径）。
   * @param path 要搜索的目录的相对或绝对路径。 此字符串不区分大小写。
   * @param searchPattern 要与 {@link path} 中的子目录的名称匹配的搜索字符串。 此参数可以包含有效文本和通配符的组合，但不支持正则表达式。
   * @param enumerationOptions 描述要使用的搜索和枚举配置的对象。*/
  public static async GetFiles(path: string, searchPattern: string, enumerationOptions: EnumerationOptions): Promise<string[]>
  public static async GetFiles(
    path: string,
    searchPattern: string,
    option: SearchOption | EnumerationOptions
  ): Promise<string[]> {
    return this.invokeStaticMethod('GetFiles', path, searchPattern, option)
  }

  /**在指定路径中创建所有目录和子目录，除非它们已经存在。
   * @param path 要创建的目录。
   * @returns 一个表示在指定路径的目录的对象。 无论指定路径的目录是否已经存在，都将返回此对象。*/
  public static async CreateDirectory(path: string): Promise<DirectoryInfo> {
    return this.invokeStaticMethod('CreateDirectory', path)
  }
}

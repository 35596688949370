
import { ref } from 'vue'
import { defineView } from '@kmsoft/upf-core'
import { KCategorySelectorPropOptions, KCategorySelectorEventEmits, selectChangeEventArgs } from './interface'
import KCategorySelectorViewModel from './categorySelectorViewModel'

export default defineView({
  name: 'KCategorySelector',
  props: KCategorySelectorPropOptions,
  emits: KCategorySelectorEventEmits,
  viewModel: KCategorySelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import { enumFactory } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartMaterialFormPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KPartMaterialFormPropType = ViewPropsTypeExtract<typeof KPartMaterialFormPropOptions>

/** 事件 */
export const KPartMaterialFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartMaterialFormEmitsType = ViewEmitsTypeExtract<typeof KPartMaterialFormEventEmits>

export const MaterialProps = enumFactory({
  source: '来源',
  assemblyMode: '装配类型',
  phase: '阶段'
  /*   occurrence: '位号',
  quantity: '数量' */
})

/** 零部件来源 */
export enum EnumSource {
  /**
   * 自制
   */
  Make = '自制',
  /**
   * 采购
   */
  Buy = '采购'
}

/** 装配模式 */
export enum EnumAssemblyMode {
  Separable = '可分离',
  Inseparable = '不可分离',
  Component = '组件'
}

/** 阶段 */
export enum EnumPhase {
  D = '设计',
  T = '试制',
  S = '量产'
}

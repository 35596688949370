import { BaseViewModel, KFormViewModel } from '@kmsoft/upf-core'
import { KModuleSearchEventEmitsType, IModuleSearchDefinition, KModuleSearchViewModelPropType } from './interface'
import { ref } from 'vue'

export default class KModuleSearchViewModel extends BaseViewModel<KModuleSearchEventEmitsType, KModuleSearchViewModelPropType> {
  refConfigForm = ref<KFormViewModel<IModuleSearchDefinition>>()
  formState = ref<IModuleSearchDefinition>({
    code: '',
    name: '',
    remark: '',
    pattern: '1',
    folder: false,
    config: true,
    branch: '1'
  } as IModuleSearchDefinition)

  /** 树节点ID */
  private treeId: string
  /** 模式 */
  private mode: string

  /**
   * 加载完成函数
   */
  viewDidMount() {}

  public getValue(): IModuleSearchDefinition {
    return this.formState.value
  }
}

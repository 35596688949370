import { BaseViewModel, IKForm, KViewSchema, KViewSchemaWithId, ValueChangeEventArgs, ViewModelOptions } from '@kmsoft/upf-core'
import { DefaultFormValue, KFilterGridViewConfigFormEventEmitsType, KFilterGridViewConfigFormPropType } from './interface'
import { ref, watch } from 'vue'
import { cloneDeep } from 'lodash'

/** KFilterGridViewConfigForm 视图模型 */
export class KFilterGridViewConfigFormViewModel extends BaseViewModel<
  KFilterGridViewConfigFormEventEmitsType,
  KFilterGridViewConfigFormPropType
> {
  /** 表单引用 */
  refForm = ref<IKForm<Record<string, any>> | null>(null)
  /** 表单值 */
  formValue = ref<KViewSchema>(DefaultFormValue)

  constructor(options: ViewModelOptions<KFilterGridViewConfigFormPropType>) {
    super(options)
    watch(
      () => options.props.formValue,
      newValue => {
        this.formValue.value = cloneDeep(newValue)
      },
      {
        immediate: true,
        deep: true
      }
    )
  }

  /**
   * 加载完成函数
   */
  viewDidMount() {}

  /**
   * 获取视图信息
   * @returns
   */
  getValue() {
    return this.formValue.value
  }
  /**
   * 定义视图信息
   * @param formValue
   */
  setValue(formValue: KViewSchema) {
    this.formValue.value = cloneDeep(formValue)
  }
  /**
   * 校验
   * @returns
   */
  validate() {
    return new Promise((resolve, reject) => {
      this.refForm.value
        ?.validate()
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

import {
  BaseViewModel,
  EnumDataGridRowModelType,
  EnumToolStripMergeType,
  IKDataGridColumnSortItem,
  IPageResult,
  IQuery,
  KDataGridCellContextMenuEvent,
  KDataGridCellValueChangedEvent,
  KDataGridColumn,
  KDataGridModelUpdatedEvent,
  KDataGridPaginationChangedEvent,
  KDataGridRefreshParams,
  KDataGridRowData,
  KDataGridRowDoubleClickEvent,
  KDataGridSelectionChangedEvent,
  KDataGridTextBoxColumn,
  KDataGridViewModel,
  KDialog,
  KGridColumn,
  KGridPageInfo,
  KPopupContextMenu,
  KSchema,
  OrderAdjustConfig,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ToolStripItemInputEventArgs,
  ViewModelOptions,
  KOrderAdjustPanel,
  EnumDialogResult,
  KDataGridTemplateColumn,
  IKDataGridCellParams,
  KNotification
} from '@kmsoft/upf-core'
import { KObjectClassGridBaseEventEmitsType, KObjectClassGridBasePropType, ObjectClassGirdBaseQueryParams } from './interface'
import { createTextVNode, createVNode, nextTick, Ref, ref, shallowRef, VNode, watch } from 'vue'
import {
  CommonClientSrv,
  MetaProperty,
  ObjBusinessBase,
  ObjBusinessParam,
  UpdateObjBusinessParam,
  messages,
  MetaPropertyClientSrv,
  ObjectClientSrv,
  ComponentClientSrv,
  QueryCondition,
  EnumQueryConditionOperator,
  EnumQueryConditionJoiner,
  IObjectPageResult,
  EditorFactory
} from '../../../client-srv'
import * as utils from './utils'
import lodash, { template } from 'lodash'
import { KObjectClassGridFindDialog } from '../../object-class-grid-find'
import { ObjectToolStripItem, KObjectToolStripViewModel, ToolStripFilterParams } from '../../object-tool-strip'
import { KDynamicViewViewModel } from '@kmsoft/upf-view-engine'
import { KObjectClassGridBaseColumn, processCellCallback } from '../../editors'
import _ from 'lodash'

/** 对象基础网格视图模型 */
export class KObjectClassGridBaseViewModel<
  E extends KObjectClassGridBaseEventEmitsType = KObjectClassGridBaseEventEmitsType,
  P extends KObjectClassGridBasePropType = KObjectClassGridBasePropType
> extends BaseViewModel<E, P> {
  //#region 引用
  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()
  /** 网格容器引用 */
  refContainer = ref<HTMLElement>()
  /** 过滤网格引用 */
  refFilterGridRender = ref<KDataGridViewModel>()
  /** 渲染器引用 */
  refDynamicView = ref<KDynamicViewViewModel>()
  /** 查找框引用 */
  refFindDialog = ref<KObjectClassGridFindDialog>()
  //#endregion

  /**
   * class 属性
   * @description 用于子类设置
   * @override
   */
  classAttr: string = ''

  /** 本地搜索过滤器相关 */
  localSearchFilter = ref<any>()
  /** 服务端模式 本地过滤数据缓存 */
  localSearchData = ref<Array<Record<string, any>>>()
  /** 本地过滤分页 */
  localSearchPageInfo = ref<KGridPageInfo>()

  /** 事件脚本 */
  eventScript = ref<utils.ObjectClassGridBaseEventScript | undefined>()
  /** 排序 */
  localSort = ref<((lhs: Record<string, any>, rhs: Record<string, any>) => number) | undefined>(undefined)
  /** 排序配置 */
  localSortForm = ref<Array<IKDataGridColumnSortItem>>([])
  /** 是否刷新 */
  isRefreshing = ref(false)
  /** 导出的列 */
  exportConfig: OrderAdjustConfig<MetaProperty> | undefined
  /** 元数据字段 */
  fieldList = ref<Array<MetaProperty>>([])
  /** 布局方案 */
  gridSchema = ref<null | KSchema>(null)
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>([])
  /** 是否显示渲染器 */
  showDynamicView = ref(false)

  /**
   * 构造函数
   * @param options 选项
   */
  constructor(options: ViewModelOptions<P>) {
    super(options)

    watch(
      () => options.props.fields,
      newValue => {
        this.setFields(newValue)
      },
      {
        immediate: true,
        deep: true
      }
    )

    // 监听过滤器
    watch(
      () => this.props.filter,
      (newValue, oldValue) => {
        this.onFilterChange(newValue)
      },
      {
        deep: true
      }
    )
  }

  /**
   * 网格引用
   * @returns 网格引用
   */
  get refDataGrid(): Ref<KDataGridViewModel | undefined> {
    if (!this.refFilterGridRender.value) {
      return ref()
    }
    return this.refFilterGridRender
  }

  /**
   * 行Id
   * @returns 行Id
   */
  get rowKey() {
    return this.refDataGrid.value?.getRowKey() || 'id'
  }

  viewDidMount() {}

  //#region 初始化
  /** 初始化 */
  private async init() {
    // 初始化自动计算
    await this.initPlugin()
    // 初始化过滤和排序
    this.initLocalFilterAndSort()
  }

  /** 初始化公式 */
  private async initPlugin(): Promise<void> {
    // 属性脚本
    this.eventScript.value = new utils.ObjectClassGridBaseEventScript()
  }

  /** 初始化本地排序和过滤 */
  private initLocalFilterAndSort() {
    this.localSort.value = undefined
    this.localSortForm.value = []
  }

  /** 初始化筛选网格引用 */
  private initFilterGrid() {
    nextTick(() => {
      if (this.gridSchema.value && this.gridSchema.value.controls) {
        this.refFilterGridRender.value = this.getByRecursion('refDataGird', this.refDynamicView.value)
      }
    })
  }
  //#endregion

  //#region 工具栏
  /**
   * 设置工具栏是否启用
   * @param disabled
   */
  setToolStripDisabled(disabled: boolean) {
    this.refToolStrip.value?.setDisabled(disabled)
  }

  /**
   * 隐藏工具栏
   */
  setToolStripVisible(visible: boolean) {
    this.refToolStrip.value?.setVisible(visible)
  }

  /**
   * 设置菜单项目是否可见
   * @param id
   * @param isVisible
   */
  setToolStripItemVisible(id: string, isVisible: boolean, params?: any) {
    this.refToolStrip.value?.setItemVisible(id, isVisible, params)
  }

  /**
   * 移除菜单项
   * @param id
   */
  removeToolStripItem = (id: string) => {
    const toolStripItem = this.findToolStripItem(id)
    if (!toolStripItem) {
      return
    }
    this.refToolStrip.value?.removeItem(id)
  }

  /**
   * 设置菜单项目是否可用
   * @param id
   * @param disabled
   */
  setToolStripItemDisabled(id: string, disabled: boolean) {
    this.refToolStrip.value?.setItemDisabled(id, disabled)
  }

  /**
   * 设置工具栏条目值
   * @param id
   * @param value
   */
  setToolStripItemValue = (id: string, value: string | number | boolean) => {
    this.refToolStrip.value?.setItemValue(id, value)
  }

  /**
   * 查找工具栏条目
   * @param id
   * @returns
   */
  findToolStripItem = (id: string): Record<string, any> | null => {
    return this.refToolStrip.value?.findItem(id) || null
  }

  /**
   * 同时设置菜单是否隐藏和是否可用
   */
  setToolStripItemEnableAndVisible = (id: string, isEnable: boolean) => {
    this.refToolStrip.value?.setItemVisible(id, isEnable)
    this.refToolStrip.value?.setItemDisabled(id, !isEnable)
  }

  /**
   * 添加工具栏
   * @param items
   * @param id
   * @param mergeType
   */
  addToolStripItemById(items: Array<ObjectToolStripItem>, id: string, mergeType: EnumToolStripMergeType) {
    this.refToolStrip.value?.addItemById(items, id, mergeType)
  }

  /**
   * 更新工具栏状态
   * @param params
   */
  updateToolStripState(params: ToolStripFilterParams) {
    return this.refToolStrip.value?.updateState(params)
  }
  //#endregion

  //#region 查找
  /**
   * 查找
   */
  openFindDialog() {
    //this.refFindDialog.value = ComponentClientSrv.openGridFindDialog(this.refDataGrid, this.fieldList)
  }
  //#endregion

  //#region 过滤
  /** 打开过滤窗口 */
  public async openFilterDialog() {
    /** 支持过滤的列 */
    const filterFields = this.fieldList.value.filter(MetaPropertyClientSrv.isSupportSimpleSearch)

    /** 打开过滤窗口 */
    const filterResult = await ObjectClientSrv.openFilterDialog({
      title: '过滤',
      props: {
        fields: filterFields
      }
    })

    // 如果关闭窗口
    if (filterResult === undefined || filterResult.filter === undefined || filterResult.condition === undefined) {
      return
    }

    // 如果是客户端过滤
    if (this.props.rowModelType == EnumDataGridRowModelType.CLIENT_SIDE) {
      // 调用网格过滤方法
      this.refDataGrid.value?.filterInGrid((row: Record<string, any>) => filterResult!.filter.test(row))
    } else {
      this.localSearchFilter.value = filterResult!.filter
      this.localSearchData.value = this.refDataGrid.value?.getRows()
      this.localSearchPageInfo.value = this.refDataGrid.value?.getPageInfo()
      this.refDataGrid.value?.refresh()
    }
  }

  /**
   * 清除本地过滤
   * @param refreshGrid 是否刷新网格 仅服务端模式可用
   */
  clearLocalFilter(refreshGrid?: boolean) {
    if (this.props.rowModelType == EnumDataGridRowModelType.CLIENT_SIDE) {
      this.refDataGrid.value?.clearFilterInGrid()
    } else {
      if (this.localSearchFilter.value) {
        this.localSearchFilter.value = undefined
        this.localSearchData.value = undefined
        if (refreshGrid || refreshGrid == undefined) {
          this.refDataGrid.value?.refresh()
        }
      }
    }
  }
  //#endregion

  //#region 业务对象参数
  /**
   * 将行转换为业务对象
   * @param row
   */
  protected convertRowToObjBusinessParam(row: Record<string, any>): ObjBusinessParam {
    if (this.props.rowObjBussinessParamPredicate) {
      return this.props.rowObjBussinessParamPredicate(row)
    }
    return CommonClientSrv.getObjBusinessParamByRow(row)
  }

  /**
   * 获取选中业务对象参数
   * @param showSelectMessage
   * @returns
   */
  getSelectedObjParam(showSelectMessage: boolean = false): ObjBusinessParam | undefined {
    const objParams = this.getSelectedObjParams(showSelectMessage)
    if (objParams.length > 0) return objParams[0]
  }

  /** 获取选中业务对象参数列表 */
  getSelectedObjParams(showSelectMessage: boolean = false): Array<ObjBusinessParam> {
    const selectedRows = this.getSelectedRows(showSelectMessage)
    if (selectedRows && lodash.isArray(selectedRows)) {
      return selectedRows.map(a => this.convertRowToObjBusinessParam(a)).filter(a => a.id && a.modelCode)
    }
    return []
  }
  //#endregion

  //#region 业务对象
  /** 获取选中的业务对象 */
  getSelectedObjBussiness(): Array<ObjBusinessBase> {
    /** 获取选中行 */
    const selectedRows = this.refDataGrid.value?.getSelectedRows()
    /** 业务对象列表 */
    const objBussinessArray: Array<ObjBusinessBase> = []

    if (selectedRows && selectedRows.length > 0) {
      // 循环行
      for (const row of selectedRows) {
        /** 行Id */
        const cid = row[this.rowKey]
        /** 业务对象 */
        const objBussiness = this.getObjBussiness(cid)

        objBussiness && objBussinessArray.push(objBussiness)
      }
    }

    return objBussinessArray
  }

  /** 获取网格行所有的业务对象 */
  getSortedObjBussiness(): Array<ObjBusinessBase> {
    /** 获取选中行 */
    const allRows = this.refDataGrid.value?.getSortedRows()
    /** 业务对象列表 */
    const objBussinessArray: Array<ObjBusinessBase> = []

    if (allRows && allRows.length > 0) {
      // 循环行
      for (const row of allRows) {
        /** 行Id */
        const cid = row[this.rowKey]
        /** 业务对象 */
        const objBussiness = this.getObjBussiness(cid)

        objBussiness && objBussinessArray.push(objBussiness)
      }
    }
    return objBussinessArray
  }

  /**
   * 获取更改的业务对象记录
   * @returns
   */
  getChangedObjBussiness(): Array<UpdateObjBusinessParam> | undefined {
    /** 网格更改行记录 */
    const dataGridChangedRows = this.refDataGrid.value?.getChangedRows()
    /** 业务对象更改参数列表 */
    const objBussinessArray: Array<UpdateObjBusinessParam> = []

    // 循环更改行
    if (!dataGridChangedRows?.modifiedRows || dataGridChangedRows.modifiedRows.length <= 0) {
      return
    }

    // 循环行
    for (const modifiedRow of dataGridChangedRows.modifiedRows) {
      let tempModifiedRow = modifiedRow
      /** 行Id */
      const cid = tempModifiedRow[this.rowKey]
      /** 业务对象 */
      const objBussiness = this.getObjBussiness(cid)
      let rowData = this.refDataGrid.value?.getRow(cid)

      if (objBussiness && rowData) {
        //#region
        //在装配工艺场景下，行业务对象是一个内存富对象，包含父子信息，这里需要特殊处理。
        //后面可以在request.ts文件中做统一处理。
        if (rowData.toObjBusinessProperties) {
          tempModifiedRow = rowData!.toObjBusinessProperties.bind(tempModifiedRow)()
          rowData = rowData.toObjBusinessProperties()
        }
        //#endregion

        const param: UpdateObjBusinessParam = {
          objParam: {
            /** 对象类Code */
            modelCode: objBussiness.modelCode,
            modelGroup: objBussiness.modelGroup,
            /** 对象Id */
            id: objBussiness.id
          },
          allProperties: rowData!,
          properties: tempModifiedRow
        }
        objBussinessArray.push(param)
      }
    }

    return objBussinessArray
  }

  /**
   * 获取选中的业务对象
   * @param cid
   * @returns
   */
  getObjBussiness(cid: string): ObjBusinessBase | undefined {
    const row = this.refDataGrid.value?.getRow(cid)
    if (!row) {
      return
    }
    const objBusinesses = utils.convertRecordToObjBusinesses(row)

    // 如果对象没有对象类code和对象id 则数据不合法
    if (!objBusinesses.modelCode || !objBusinesses.id) {
      return
    }

    return objBusinesses
  }

  /** 获取主业务对象参数 */
  getObjBussinessParam(cid: string): ObjBusinessParam | undefined {
    const row = this.refDataGrid.value?.getRow(cid)
    if (!row) {
      return
    }
    return this.convertRowToObjBusinessParam(row)
  }

  //#endregion

  //#region Excel 导出

  /**
   * 导出Excel
   * @param autoSelectFields
   */
  async exportExcel(autoSelectFields: Array<MetaProperty>) {
    let checkedItems

    if (this.exportConfig) {
      checkedItems = this.exportConfig.checkedItems
    } else {
      checkedItems = lodash.cloneDeep(this.fieldList.value)
    }

    const otherFields = checkedItems.filter(a => !autoSelectFields.some(b => b.id == a.id))

    const fields = [...autoSelectFields, ...otherFields]
    this.refDataGrid.value?.exportDataAsExcel({
      columnKeys: fields.map(a => a.columnName)
    })
  }

  /**
   * 导出选中行为excel表格
   */
  exportSelectAsExcel(params?: Record<string, any>) {
    const data = this.refDataGrid.value?.getSelectedRows()
    if (data && data.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择要导出的数据'
      })
      return
    }
    let param: Record<string, any> = { ...params, onlySelected: true }
    if (!params) {
      param = {
        onlySelected: true,
        sheetName: 'sheet1',
        columnKeys: this.refDataGrid.value
          ?.getColumnDefs()
          .filter((x: any) => x.id !== 'action')
          .map((x: any) => x.id),
        processCellCallback: (params: any) => {
          const self = this
          return processCellCallback(params, self.refDataGrid.value)
        }
      }
    }
    this.refDataGrid.value?.exportDataAsExcel(param!)
  }

  /**
   * 导出为excel表格
   */
  exportAsExcel(params?: Record<string, any>): void {
    let param = params
    if (!params) {
      param = {
        sheetName: 'sheet1',
        processCellCallback: (params: any) => {
          const self = this
          return processCellCallback(params, self.refDataGrid.value)
        }
      }
    }
    this.refDataGrid.value?.exportDataAsExcel(param!)
  }
  //#endregion

  //#region 元数据及布局

  /** 获取列定义 */
  getFields() {
    return this.fieldList.value
  }

  /**
   * 设置网格布局方案
   * @param schema
   */
  setSchema(schema: KSchema) {
    this.refFindDialog.value?.close()
    this.showDynamicView.value = false
    this.gridSchema.value = lodash.cloneDeep(schema)
    this.showDynamicView.value = true
    this.initFilterGrid()
  }

  /**
   * 设置属性元数据
   * @param fields
   */
  setFields(fields: Array<MetaProperty>) {
    this.refFindDialog.value?.close()
    this.fieldList.value = lodash.cloneDeep(fields)
    this.init()
  }
  //#endregion

  //#region 其他
  /**
   * 获取容器对象
   * @returns
   */
  getContainer() {
    return this.refContainer.value
  }
  //#endregion

  //#region 编辑
  /**
   * 网格数据是否发生了变化
   * @returns
   */
  async isDataChanged(): Promise<boolean> {
    // 结束单元格编辑
    await this.endCellEdit()

    const changeData = this.refDataGrid.value?.getChangedRows()

    if (!changeData) {
      return false
    }

    return (
      (changeData.modifiedRows != undefined && changeData.modifiedRows.length > 0) ||
      (changeData.deletedRows != undefined && changeData.deletedRows.length > 0) ||
      (changeData.insertedRows != undefined && changeData.insertedRows.length > 0)
    )
  }

  /** 结束编辑 */
  async endCellEdit() {
    if (!this.refDataGrid.value?.getIsEditing()) {
      return
    }
    this.refDataGrid.value?.endEdit(false)
    this.refDataGrid.value?.acceptChanges()
    await CommonClientSrv.sleep(200)
    this.refDataGrid.value?.endEdit(true)
  }
  //#endregion

  //#region 单元格及行
  /**
   * 获取单元格的是否只读
   * @param row
   * @param column
   * @returns
   */
  getCellReadOnly(row: KDataGridRowData, column: KDataGridColumn) {
    const rowKey = this.refDataGrid.value?.getRowKey()
    if (!rowKey) return
    const node = this.refDataGrid.value?.getRowNode(row[rowKey])
    if (this.props.getCellEditable) {
      const editable = this.props.getCellEditable({
        row: row,
        column: column,
        api: this.refDataGrid.value!,
        node: node!
      })
      if (editable === undefined) return
      return !editable
    }
  }

  /**
   * 插入行
   * @param row 要插入的行
   * @param objId 当前行的Id（网格的主键列的值）
   */
  insertRow(
    row: Record<string, any>,
    position?: number,
    selectRow: boolean = true,
    scrollToRow: boolean = true,
    objId: string = row['id']
  ): void {
    if (!this.refDataGrid.value) {
      return
    }

    // 当分页启用，插入行为第一行
    if (position == undefined && this.props.pagination) {
      position = 0
    }

    this.refDataGrid.value.clearSelectedRows()
    this.refDataGrid.value.insertRow(row, position)

    if (objId) {
      if (selectRow === true) this.refDataGrid.value.setSelectedRow(objId, true)
      if (scrollToRow === true) this.refDataGrid.value.scrollToRow(objId)
    }
  }

  /**
   * 获取选中行
   * @param showSelectMessage 是否显示提示信息
   * @returns
   */
  getSelectedRows(showSelectMessage: boolean = false) {
    const rows = this.refDataGrid.value?.getSelectedRows()
    if (rows && rows.length <= 0 && showSelectMessage) {
      KDialog.info({
        title: '系统提示',
        content: messages.MSG_PLS_SELECT_A_ROW
      })
    }
    return rows
  }

  /**
   * 获取选中行
   * @param showSelectMessage 是否显示提示信息
   */
  getSelectedRow(showSelectMessage: boolean = false) {
    /** 获取选中行 */
    const rows = this.getSelectedRows(showSelectMessage)
    if (!rows || rows.length <= 0) {
      return undefined
    }
    return rows[0]
  }
  //#endregion

  //#region 脚本
  /** 获取脚本上下文 */
  getContext() {
    // const gridContext = ScriptContext.GridContext.getGridContext({
    //   methods: methods as any
    // })
    // return gridContext
    return {}
  }
  //#endregion

  //#region KDataGrid 方法继承
  //#region 单元格
  /**
   * 设置单元格只读
   * @param rowKey
   * @param column
   * @param readonly
   */
  setCellReadonly(rowKey: string | number, column: string, readonly: boolean) {
    this.refDataGrid.value?.setCellReadonly(rowKey, column, readonly)
  }
  //#endregion

  //#region 数据
  /**
   * 刷新
   * @param page
   * @override
   */
  refresh(params?: KDataGridRefreshParams) {
    return new Promise((reslove, reject) => {
      // 记录开始刷新
      this.isRefreshing.value = true

      /** 监听刷新完成标记 */
      const watchStopHandle = watch(this.isRefreshing, () => {
        // 如果刷新完成
        if (this.isRefreshing.value == false) {
          // 如果是静默刷新会导致自定义单元格不重绘，导致数据更新但是界面不更新
          // 所以在此手动重绘所有单元格
          if (params?.purge == false) {
            this.refDataGrid.value?.redrawRows()
          }

          // 返回刷新完成
          reslove(void 0)

          // 停止监听
          watchStopHandle()
        }
      })
      this.refDataGrid.value?.clearSelectedRows()
      // 刷新
      this.refDataGrid.value?.refresh(params)
    })
  }
  //#endregion

  //#region 行操作
  /**
   * 获取改变之后的行数据
   * @returns
   */
  getChangedRows() {
    return this.refDataGrid.value?.getChangedRows()
  }

  /**
   * 获取所有行数据, 网格行数据
   * @returns
   */
  getRows() {
    return this.refDataGrid.value?.getRows()
  }

  /**
   * 移除行
   * @param keys 移除行的关键字数组
   */
  removeRow(key: string | Array<string>) {
    return this.refDataGrid.value?.removeRow(key)
  }

  /**
   * 移除网格所有数据
   */
  clear() {
    return this.refDataGrid.value?.clear()
  }
  //#endregion

  //#region 编辑
  /**
   * 将当前网格置于编辑模式，所有非只读的可见的单元格都可以编辑
   * @returns
   */
  /**
   * 获取当前编辑状态
   * @returns 是否正在编辑
   */
  getIsEditing() {
    return this.refDataGrid.value?.getIsEditing()
  }

  beginEdit() {
    return this.refDataGrid.value?.beginEdit()
  }

  /**
   * 结束编辑
   * @param complete
   */
  endEdit(complete?: boolean) {
    this.refDataGrid.value?.endEdit(complete)
  }
  //#endregion

  /**
   * 隐藏列
   * @param columnId 列唯一键
   * @param hide 是否隐藏
   */
  hideColumn(columnId: string, hide: boolean) {
    this.refDataGrid.value?.hideColumn(columnId, hide)
  }

  //#endregion

  //#region 事件

  //#region 工具栏
  /**
   * 工具栏点击事件
   * @param event
   */
  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    /** 发布事件 */
    this.emit('toolStripItemClicked', event)
  }

  /**
   * 工具栏change事件
   * @param event
   */
  onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    this.emit('toolStripItemChange', event)
  }

  /**
   * 工具栏输入事件
   * @param event
   */
  onToolStripItemInput(event: ToolStripItemInputEventArgs) {
    this.emit('toolStripItemInput', event)
  }

  /**
   * 工具栏按钮点击前事件
   * @param event 事件类型
   */
  onItemClickedBefore(event: ToolStripItemClickedEventArgs) {
    this.emit('itemClickedBefore', event)
  }
  //#endregion

  //#region 自定义过滤器
  /**
   * 自定义过滤器
   * @param id
   * @param argument
   * @returns
   */
  onFilterResolver(name: string, argument?: Record<string, any>) {
    /** 列定义 */
    const field = this.fieldList.value.find((a: MetaProperty) => a.columnName == name)

    if (!field) {
      return createVNode('p', { innerHtml: '列不存在' })
    }

    // 通过工场返回过滤器
    return utils.resolveCustomFilter({
      field: field,
      methods: this as any
    })
  }
  //#endregion

  //#region 自定义列
  /**
   * 构建自定义列节点
   * @param column 渲染器当前遍历的列
   * @override
   * @private
   */
  onGirdResolveCustomColumn(column: KGridColumn): VNode | KDataGridColumn {
    /** 列定义 */
    // const properties: Array<MetaProperty> = this.fieldList.value
    /** 当前列 */
    // const field = column.field?.split('#')[1] || column.field?.split('#')[0]
    // const property = properties.find(property => property.columnName == field!.split('$')[0])
    /** 网格的基础属性,默认属性渲染器会自动处理 */
    const commonColumnInstanceValues: Partial<KObjectClassGridBaseColumn> = {
      code: column.title,
      refComponent: () => {
        return this.refDataGrid.value!
      }
    }

    /** 网格列 */
    let gridColumn: KDataGridColumn = new KDataGridTextBoxColumn()
    /** 自定义列定义 */
    const customField = this.onResolveCustomColumn(column)

    // 如果设置了自定义列
    if (customField) {
      gridColumn = customField
    } else {
      /** 通过工厂获取列 */
      const resolveColumn = EditorFactory.getColumn(column!.control)

      // 如果获取到列定义
      if (resolveColumn) {
        gridColumn = lodash.cloneDeep(resolveColumn)
      }
    }

    // 批量应用参数
    Object.assign(gridColumn, commonColumnInstanceValues)

    // 从全局工厂返回
    return gridColumn
  }

  /**
   * 自定义列返回
   * @param column
   * @param field
   * @returns
   */
  onResolveCustomColumn(column: KGridColumn): KDataGridColumn | undefined {
    if (this.props.columnResolver) {
      return this.props.columnResolver(column)
    }
    return undefined
  }
  //#endregion

  //#region 网格数据加载
  /**
   * 加载数据
   * @params 网格数据加载模型
   * @private
   */
  async onGridLoadData(params: IQuery): Promise<Array<KDataGridRowData> | IPageResult> {
    if (this.localSearchFilter.value && this.localSearchData.value) {
      return this.localSearchData.value!.filter(r => this.localSearchFilter.value!.test(r))
    }

    /** 过滤条件 */
    let filter: QueryCondition | undefined

    /** 如果设置了过滤条件 */
    if (params.filters && params.filters.length > 0) {
      filter = {
        joiner: EnumQueryConditionJoiner.And,
        conditions:
          params.filters.map(c => ({
            conditionName: c.key,
            operator: c.operator as EnumQueryConditionOperator,
            conditionValues: [c.value!]
          })) || []
      }
    }

    // 如果有分页要加上分页
    const pageInfo = params.page

    // 如果当前页没有传则默认是1，否则就用传递来的当前页
    const pageIndex = pageInfo ? pageInfo.pageIndex : 1

    let result = await this.onLoadData({
      filter: this.props.filter || filter,
      sort: params.sort,
      page: {
        pageSize: CommonClientSrv.getDefaultPageInfo().pageSize,
        pageIndex
      }
    })

    //WebAPI请求异常了
    if (!result) {
      return []
    }

    let rows
    if (result instanceof Array) {
      rows = result
    } else {
      rows = result.rows
    }

    // 本地排序
    if (this.localSort.value) {
      rows = rows.sort(this.localSort.value)
    }

    if (result instanceof Array) {
      result = rows
    } else {
      result.rows = rows
    }

    if (this.localSearchPageInfo.value) {
      //【临时方案】由于当期有效数据只有1页,所以需要二次刷新数据
      //TODO：后面将AGGrid的企业版列头排序、过滤功能引入后再行完善
      setTimeout(() => {
        const page = this.localSearchPageInfo.value!
        page.pageIndex -= 1
        this.localSearchPageInfo.value = undefined
        this.refDataGrid.value?.refresh(page)
      }, 100)
    }

    return result
  }

  /**
   * 加载数据回调
   * @param params
   */
  protected async onLoadData(params: ObjectClassGirdBaseQueryParams): Promise<IObjectPageResult | Array<KDataGridRowData>> {
    throw new Error('onLoadData 未实现')
  }

  /**
   * 分页切换事件
   * @param event 事件类型
   */
  onPaginationChanged(event: KDataGridPaginationChangedEvent) {
    // 如果是新页
    if (event.newPage) {
      /** 获取所有行 */
      const rows = this.refDataGrid.value?.getRows()
      // 清除选中行
      if (rows && rows.length > 0) {
        this.refDataGrid.value?.clearSelectedRows()
      }
    }
    this.emit('paginationChanged', event)
  }
  //#endregion

  //#region 单元格
  /**
   * 右键菜单
   * @param event
   * @returns
   */
  onCellContextMenu(event: KDataGridCellContextMenuEvent) {
    if (this.toolStripItems.value.length <= 0 || !this.props.showContextMenu) {
      return
    }
    /** 获取选中的行 */
    const selectedRows = this.refDataGrid.value?.getSelectedRows() as Record<string, any>
    const row = event.row
    if (!selectedRows || selectedRows.length <= 0 || selectedRows.indexOf(row) < 0) {
      this.refDataGrid.value?.clearSelectedRows()
      this.refDataGrid.value?.setSelectedRow(row![this.rowKey], true)
    }

    KPopupContextMenu.show({
      preciseEvent: false,
      items: this.toolStripItems.value
    })
  }

  /**
   * 单元格值变化事件
   * @param event
   */
  async onCellValueChanged(event: KDataGridCellValueChangedEvent) {
    this.eventScript.value?.invokeEvent(event)
    this.emit('cellValueChanged', event)
  }
  //#endregion

  //#region 行
  /**
   * 网格行双击事件
   * @param event 事件类型
   */
  onRowDoubleClick(event: KDataGridRowDoubleClickEvent) {
    this.emit('rowDoubleClick', event)
  }

  /**
   * 行选中事件
   * @param event
   */
  onSelectionChanged(event: KDataGridSelectionChangedEvent) {
    this.emit('selectionChanged', event)
  }

  /**
   * 模型更新事件
   * @param event
   */
  async onModelUpdated(event: KDataGridModelUpdatedEvent) {
    // 等待200毫秒
    await CommonClientSrv.sleep(200)

    this.isRefreshing.value = false

    if (this.refDataGrid.value && event.keepRenderedRows && this.props.selectFirstRow) {
      /** 获取选中的行 */
      const selectedRows = this.refDataGrid.value.getSelectedRows()
      /** 所有行 */
      const rows = this.refDataGrid.value.getRows()

      // 如果没有选中首行
      // 如果网格加载了新数据
      // 选择首行
      if (selectedRows.length <= 0) {
        if (rows && rows.length > 0) {
          this.refDataGrid.value?.setSelectedRow(rows[0][this.rowKey], true, true)
        } else {
          this.refDataGrid.value?.clearSelectedRows()
        }
      }
    }

    this.emit('modelUpdated', event)
  }
  //#endregion

  //#region 过滤器
  /**
   * 过滤更新事件
   * @param filter
   */
  onFilterChange(filter: QueryCondition | undefined) {
    // 刷新数据
    this.refresh()
  }
  /**
   * 构建操作列
   */
  buildOptionsColumn() {
    if (!this.props.showOperatorColumn || this.props.operatorColumn.length == 0) return []
    const operation = this.props.operatorColumn
    const templateColumn = new KDataGridTemplateColumn()
    let operator = [] as Array<VNode>
    templateColumn.headerText = '操作'
    templateColumn.fixed = 'right'
    templateColumn.align = 'center'
    templateColumn.readonly = true
    templateColumn.getCellTemplate = (params: IKDataGridCellParams) => {
      operator = operation.map(item => {
        return createVNode(
          'a',
          {
            title: item.title,
            onClick: () => {
              this.emit('operationClick', { key: item.key, params: params })
            },
            style: 'padding:0 5px'
          },
          { default: () => item.title }
        )
      })
      return createVNode('div', {}, { default: () => operator })
    }

    return [templateColumn]
  }
  //#endregion

  //#region 其他
  /** 网格就绪事件 */
  onReady() {
    this.emit('ready')
  }
  //#endregion
  //#endregion
}

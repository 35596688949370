
import {
  EnumControlElementType,
  EnumLayoutFlexDirection,
  KContainer,
  KControl,
  KFlexLayoutConfig,
  LayoutRenderElement,
  VNodeProps,
  defineView
} from '@kmsoft/upf-core'
import { KToolStripContainerEventEmits, KToolStripContainerPropOptions } from './interface'
import KToolStripContainerViewModel from './KToolStripContainerViewModel'
import _ from 'lodash'
import { DragElement } from './interface'
import { createCommentVNode, createVNode, resolveComponent } from 'vue'
import {
  EnumRenderLayoutType,
  KRenderLayout,
  KRenderLayoutEmitsType,
  KRenderLayoutPropType,
  RenderLayoutBaseEvent
} from '@kmsoft/upf-view-engine'

export default defineView({
  name: 'KToolStripContainer',
  props: KToolStripContainerPropOptions,
  emits: KToolStripContainerEventEmits,
  viewModel: KToolStripContainerViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    /**
     * 根据类型渲染指定元素
     * @param element 元素
     */
    const renderElement = (element: KContainer | KControl) => {
      //布局元素为按钮时，渲染按钮
      return createVNode(resolveComponent(element.control), {
        ...element.props,
        title: element.title
      })
    }

    const renderLayout = () => {
      /** 容器配置 */
      const containerConfig: KFlexLayoutConfig = {
        direction: EnumLayoutFlexDirection.ROW
      }

      /** 网格布局参数 */
      const renderLayoutProps: VNodeProps<KRenderLayoutPropType, KRenderLayoutEmitsType> = {
        class: 'k-tool-strip-designer',
        designMode: true,
        layoutType: EnumRenderLayoutType.FLEX,
        elements: props.elements,
        containerConfig: containerConfig,
        selectedElement: props.selectedElement,
        onAdd: ({ item }: RenderLayoutBaseEvent<DragElement>) => vm.onElementDragIn(item),
        'onUpdate:selectedElement': element => vm.onElementSelected(element as LayoutRenderElement)
      }

      const renderLayoutSlots = {
        item: ({ element }: { element: KContainer | KControl }) => {
          return renderElement(element)
        }
      }

      return createVNode(KRenderLayout, renderLayoutProps, renderLayoutSlots)
    }

    const renderActions = () => {
      const popverSlots = {
        default: () => {
          return (
            <span class="toolStrip-action-button">
              <k-icon type="plus" class="addBtn"></k-icon>
            </span>
          )
        },
        content: () => {
          return (
            <div>
              <p>
                <a onClick={() => vm.onPopoverClick(EnumControlElementType.TOOL_STRIP_BUTTON)}>按钮</a>
              </p>
              <p>
                <a onClick={() => vm.onPopoverClick(EnumControlElementType.TOOL_STRIP_CHECKBOX)}>复选框</a>
              </p>
              <p>
                <a onClick={() => vm.onPopoverClick(EnumControlElementType.TOOL_STRIP_DATETIME)}>日期框</a>
              </p>
              <p>
                <a onClick={() => vm.onPopoverClick(EnumControlElementType.TOOL_STRIP_INPUT)}>输入框</a>
              </p>
              <p>
                <a onClick={() => vm.onPopoverClick(EnumControlElementType.TOOL_STRIP_RADIO)}>单选按钮</a>
              </p>
              <p>
                <a onClick={() => vm.onPopoverClick(EnumControlElementType.TOOL_STRIP_SELECT)}>下拉框</a>
              </p>
            </div>
          )
        }
      }

      return (
        <div class="toolStrip-action">
          <k-popover title="工具栏项" placement="bottom" v-slots={popverSlots} />
        </div>
      )
    }

    return () => {
      if (!Array.isArray(props.elements)) {
        return createCommentVNode('k-layout-render')
      }

      return (
        <div class="k-tool-strip-container">
          {renderLayout()}
          {renderActions()}
        </div>
      )
    }
  }
})

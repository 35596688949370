import {
  Api,
  CommonClientSrv,
  ConfigClientSrv,
  EnumObjTabPageId,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPanelViewModel,
  KObjectToolStripViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  ObjectPanelDataUpdatedEvent,
  TREE_VIEW_ROOT_NODE_KEY
} from '@kmsoft/ebf-common'
import { KModelBrowser } from '@kmsoft/km-vue'
import { EnumKmVueFileType } from '@kmsoft/km-vue/src/components'
import {
  AppContext,
  BaseViewModel,
  EnumToolStripCompType,
  EnumTreeViewNodeRefreshType,
  KCkeditorViewModel,
  KDialog,
  KNotification,
  request,
  ToolStripItemClickedEventArgs,
  TreeViewCheckedEventArgs,
  TreeViewSelectEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { computed, ref, watch } from 'vue'
import { DocClientSrv, DocFilterResult, EnumFilterType, EnumPartNodeChildExpandMode, StructureView } from '../../client-srv'
import { KStructuredDocManageEmitsType, KStructuredDocManagePropType } from './interface'
import { KStructuredDocTreeViewModel } from './structured-doc-tree'
import { StructuredDocTreeNode } from './structured-doc-tree/node/StructuredDocTreeNode'
import { EnumWorkState } from '../doc-edit-panel/interface'
import { Doc } from "../../beans";

/** KStructuredDocManage */
export default class KStructuredDocManageViewModel extends BaseViewModel<
  KStructuredDocManageEmitsType,
  KStructuredDocManagePropType
> {
  /** 文档结构树引用 */
  refStructuredDocTree = ref<KStructuredDocTreeViewModel>()
  /** 对象面板 */
  refObjectPanel = ref<KObjectPanelViewModel>()

  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()

  /** 过滤条件 */
  // structureFilterOptions = ref<Array<StructureFilterOption>>([])
  /** 过滤名称 */
  structureFilterName = ref<string>()
  /** 文档过滤规则 */
  filterResult: DocFilterResult = {
    searchCondition: '',
    filterType: EnumFilterType.None,
    results: []
  }
  /** 视图名称 */
  structureViewName = ref<string>('最新版本')
  /** 视图模式 */
  structuredDocViewMode = computed(() => {
    return this.structureViews.value.find(a => a.viewName == this.structureViewName.value)
  })
  /** 视图列表 */
  structureViews = ref<Array<StructureView>>([])
  /** 视图下拉列表 */
  structureViewOptions = computed(() => {
    return this.structureViews.value.map(a => {
      return { label: a.viewName, value: a.viewName }
    })
  })
  toolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT,
      title: '编辑',
      icon: 'edit',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE,
      title: '保存',
      icon: 'save',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN,
      title: '检入',
      icon: 'DocCheckIn',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]

  refCkEditor = ref<KCkeditorViewModel>()
  editorText = ref<string>('')
  /** 节点展开模式 */
  nodeChildExpandMode = ref<EnumPartNodeChildExpandMode>()

  //#region 控制属性
  /** 是否正在变更选择项 */
  private selectionChanging = false
  /** 是否允许选择 */
  private allowSelectionChange = false
  //#endregion

  //#region 对象参数
  /** 结构树根对象参数 */
  rootObjParam = ref<ObjBusinessParam>()
  /** 当前选择的对象 */
  selectObjParam = ref<ObjBusinessParam>()
  /** 选中的树节点 */
  selectedNode = ref<StructuredDocTreeNode>()

  workingState = ref<string>()
  //#endregion

  //#region 对外组件API
  /** 文档结构树 */
  get structuredDocTree() {
    return this.refStructuredDocTree.value
  }

  /** 对象面板 */
  get objectPanel() {
    return this.refObjectPanel.value
  }

  //#endregion

  //#region 界面控制
  /** 是否初始化完成 */
  isInitialized = ref<boolean>(false)

  //#endregion
  constructor(options: ViewModelOptions<KStructuredDocManagePropType>) {
    super(options)
    this.rootObjParam.value = options.props.objParam
    this.selectObjParam.value = options.props.objParam
    // 监听对象参数
    watch(
      () => options.props.objParam,
      async (newValue, oldValue) => {
        this.rootObjParam.value = newValue
        if (oldValue) {
          await this.refresh()
        } else {
          await this.init()
        }
      },
      {
        immediate: true
      }
    )

    // // 节点展开模式
    // watch(this.nodeChildExpandMode, newValue => {
    //   this.emit('update:nodeChildExpandMode', newValue!)
    // })
    // 监听版本规则选择
    watch(
      this.structureViewName,
      async newValue => {
        this.onStructureViewChange(newValue)
      },
      {
        immediate: true
      }
    )
    // 监听过滤条件
    watch(this.structureFilterName, async newValue => {
      // 获取过滤结果
      await this.getFilterResult()

      // 刷新树
      await this.refStructuredDocTree.value?.treeView?.refresh()

      // 选中默认节点
      this.selectRootNode()
    })

    watch(
      () => this.workingState.value,
      newValue => {
        console.log('@@@@@', newValue)
        this.updateToolStrip(this.workingState.value!)
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  /**
   * 初始化
   */
  async init() {
    if (!this.props.objParam) {
      return
    }

    // 加载完成
    this.isInitialized.value = false

    // 加载过滤器
    await this.initFilter()

    // 加载完成
    this.isInitialized.value = true
  }

  /**
   * 初始化过滤器
   */
  async initFilter() {
    // API
    this.structureViews.value = [{ viewName: '最新版本' }, { viewName: '最新发布版本' }]

    /** 默认视图 */
    let defaultViewName = ''
    if (this.props.viewName) {
      defaultViewName = this.props.viewName
    } else {
      const expandMode = EnumPartNodeChildExpandMode.LatestVersion
      defaultViewName = DocClientSrv.getPartViewModeViewName(expandMode)
    }

    // API
    // this.structureFilterOptions.value = [{ label: '??', value: '' }]

    this.structureViewName.value = defaultViewName
  }

  /**
   * 重载
   */
  async refresh() {
    await this.init()
  }

  /** 选中根节点 */
  selectRootNode() {
    this.refStructuredDocTree.value?.treeView?.setSelectedNode(TREE_VIEW_ROOT_NODE_KEY)
  }

  /** 节点选中后事件 */
  onTreeAfterCheck(event: TreeViewCheckedEventArgs) {
    this.emit('afterTreeCheck', event)
  }

  /** 节点刷新 */
  onNodeRefresh() {
    this.refObjectPanel.value?.refresh()
  }

  /**
   * 获取过滤结果
   * @returns
   */
  async getFilterResult() {
    // 如果没有选中过滤条件
    if (!this.structureFilterName.value) {
      this.filterResult.filterType = EnumFilterType.None
      return
    }

    // 获取根节点
    const rootNode = this.refStructuredDocTree.value?.treeView?.getRootNodes() as Array<StructuredDocTreeNode>

    // const parentId = rootNode[0].partId
    // const parentClsId = rootNode[0].partObjClsId

    // filterResult = await DataSrv.partStructureSrv.getFilterResult({
    //   objClsID: parentClsId,
    //   objID: parentId,
    //   filterName: structureFilterName.value,
    //   viewName: structureViewName.value
    // })
  }

  /**
   * 节点选择前事件
   * @param event
   */
  async onBeforeNodeSelect(event: TreeViewSelectEventArgs) {
    if (!event.node) {
      return
    }

    // 取消选择
    event.cancel = !this.allowSelectionChange

    // 如果正在选择
    if (this.selectionChanging) {
      return
    }

    this.selectionChanging = true

    try {
      /** 保存更改 */
      const saveResult = await this.refObjectPanel.value?.saveChanges()

      // 如果取消
      if (saveResult && saveResult.cancel) {
        return
      }

      this.allowSelectionChange = true

      try {
        this.refStructuredDocTree.value?.treeView?.setSelectedNode(event.node.key!)
      } finally {
        this.allowSelectionChange = false
      }
    } finally {
      this.selectionChanging = false
    }
  }

  /**
   * 版本规则改变
   * @param newValue
   * @returns
   */
  async onStructureViewChange(newValue: string) {
    if (!this.structuredDocViewMode.value) {
      return
    }

    // 获取展开模式
    this.nodeChildExpandMode.value = DocClientSrv.getPartNodeChildExpandMode(this.structuredDocViewMode.value.viewName)

    // 如果是Bom视图
    if (this.structuredDocViewMode.value.baselineContents) {
      if (this.structuredDocViewMode.value.baselineContents!.length <= 0) {
        return
      }
      this.rootObjParam.value = this.structuredDocViewMode.value.baselineContents.find(
        _ => _.masterId == this.props.objParam.masterId
      )!
      this.selectObjParam.value = {
        modelCode: this.selectObjParam.value!.modelCode,
        id: this.structuredDocViewMode.value.baselineContents[0].id,
        modelGroup: this.selectObjParam.value!.modelGroup
      }
    } else {
      this.rootObjParam.value = this.props.objParam
      this.selectObjParam.value = this.props.objParam
    }

    // 获取过滤结果
    await this.getFilterResult()

    // 刷新树
    await this.refStructuredDocTree.value?.treeView?.refresh(TREE_VIEW_ROOT_NODE_KEY, true)

    // 选中默认节点
    this.selectRootNode()

    this.emit('update:viewName', newValue)
  }

  /** 树加载完成以后 */
  async onTreeReady() {
    // 选择根节点
    this.selectRootNode()

    // 使用灵活队列的方式显示数据
    setTimeout(() => {
      // 不使用树默认展开功能，频繁加载大量数据会导致控件卡顿
      const rootNodes = this.refStructuredDocTree.value?.treeView?.getRootNodes()
      if (rootNodes) {
        this.refStructuredDocTree.value?.treeView?.expandLevel(rootNodes, 0)
      }
    }, 0)

    // 抛出树就绪事件
    this.emit('treeReady', this.refStructuredDocTree.value!)
  }

  /** 节点选择事件 */
  async onAfterSelect(event: TreeViewSelectEventArgs<StructuredDocTreeNode>) {
    const node = event.node

    // 保存选中的树节点
    this.selectedNode.value = node
    //获取设计态编码
    node.parentDocObjParam.modelGroup = await ObjectClientSrv.getModelGroupByCode(node.parentDocObjParam.modelCode)

    this.selectObjParam.value = {
      modelCode: node.docObjParam.modelCode,
      id: node.docObjParam.id,
      modelGroup: node.docObjParam.modelGroup
    }

    this.workingState.value = node.docObjParam.workingState

    //下载html文件
    const fileId = node.docObjParam.file.id
    if (fileId) {
      const params = {
        fileIds: fileId,
        modelName: node.docObjParam.modelCode,
        attributeName: 'primary'
      }
      const downLoadResult = await request.post('/kmsaasFileApi/download', params, {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'blob'
      })
      if (downLoadResult?.status == EnumRequestCode.SUCCESS) {
        const data = downLoadResult.data
        const htmlText = await data.text()
        this.editorText.value = htmlText
      }
    } else {
      this.editorText.value = ''
    }

    this.emit('afterTreeSelect', event)
  }

  /**
   * 对象面板更新事件
   * @param event
   */
  async onObjectPanelDataUpdated(event: ObjectPanelDataUpdatedEvent) {
    if (event.tabId == EnumObjTabPageId.StructureList || event.tabId == EnumObjTabPageId.Property) {
      /** 获取选中的节点 */
      const selectedNode = this.refStructuredDocTree.value?.treeView?.getSelectedNode() as StructuredDocTreeNode
      /** 刷新选择的节点 */
      if (selectedNode) {
        await this.refStructuredDocTree.value?.refreshNode(
          selectedNode,
          event.tabId == EnumObjTabPageId.StructureList
            ? EnumTreeViewNodeRefreshType.NODE_CHILD
            : EnumTreeViewNodeRefreshType.SELF
        )
      }
    }
  }

  async previewWord() {
    const objParam = this.props.objParam
    const docId = objParam.id
    const resDoc = await DocClientSrv.getDoc(this.props.objParam.id)
    const tabKey = `${resDoc.name}##预览`
    const identity = AppContext.current.getIdentity()
    const base64Token = btoa(identity!.token)
    CommonClientSrv.openPage(
      '对象浏览',
      KModelBrowser,
      {
        objParam: this.props.objParam,
        url: `${ConfigClientSrv.getFileApiBaseUrl}/doc/struct/export/${base64Token}/${docId}.docx`,
        fileType: EnumKmVueFileType.Office
      },
      tabKey
    )
  }

  async exportToWord() {
    const objParam = this.props.objParam
    const docId = objParam.id
    const identity = AppContext.current.getIdentity()
    const base64Token = btoa(identity!.token)
    // 浏览器下载
    const url = `${ConfigClientSrv.getFileApiBaseUrl}/doc/struct/export/${base64Token}/${docId}`
    // const link = document.createElement('a')
    // link.href = url
    // document.body.appendChild(link)
    // link.click()
    // document.body.removeChild(link)
    window.open(url, '_blank')
  }

  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT:
        //TODO 检出文档，刷新文档树
        this.editDoc()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN:
        this.checkIn()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.saveDoc()
        break
      default:
        break
    }
  }

  async editDoc() {
    const docData = (await DocClientSrv.getDoc(this.selectObjParam.value!.id)) as any
    //判断状态
    const workingState = docData?.workingState
    if (workingState == EnumWorkState.CHECKED_OUT) {
      KNotification.warn({
        message: '系统提示',
        description: '文档已被其他人检出，不能编辑'
      })
      return
    }
    if (workingState == EnumWorkState.INWORK) {
      // 修改编辑状态
      this.refCkEditor.value?.setDisabled(false)
      return
    }
    // 检出
    const doc = Object.assign(new Doc(), docData)
    const result = (await DocClientSrv.checkOutDocWithoutFile(doc)) as any
    if (result) {
      // 刷新节点
      await this.refStructuredDocTree.value?.refreshNode(this.selectedNode.value!, EnumTreeViewNodeRefreshType.NODE_PARENT)
      this.selectObjParam.value!.id = result.data[0].id
      this.workingState.value = result.data[0].workingState
      // 修改编辑状态
      this.refCkEditor.value?.setDisabled(false)
    }
  }

  async saveDoc() {
    const isModified = this.refCkEditor.value?.isModified()
    if (isModified) {
      KNotification.warning({
        title: '未保存',
        content: '没有修改数据'
      })
    }
    const docData = (await DocClientSrv.getDoc(this.selectObjParam.value!.id)) as any
    const fileName = docData.name + '.html'
    const htmlText = this.refCkEditor.value?.getValue()
    const req = new FormData()
    req.append('modelName', 'Document')
    req.append('attributeName', 'primary')
    req.append('files', new File([htmlText], fileName))
    const result = (await request.post('/kmsaasFileApi/upload', req, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })) as any
    if (result && result.result == 'SUCCESS') {
      const fileId = result.data[0]
      // 更新doc fileId
      const reqParam = { primary: [{ id: fileId }], id: this.selectObjParam.value!.id }
      const updateResult = await Api.post('doc', 'Document', 'update', { data: [reqParam] })
      if (updateResult && updateResult.code == EnumRequestCode.SUCCESS) {
        KNotification.success('操作成功！')
        // 修改编辑状态
        this.refCkEditor.value?.setDisabled(true)
        this.refCkEditor.value?.setValue(htmlText, true)
        const current = this.selectedNode.value
        current!.docObjParam!.file.id = fileId
        this.refStructuredDocTree.value?.refreshNode(current!, EnumTreeViewNodeRefreshType.SELF)
        return
      }
      KNotification.error({
        title: '系统错误',
        content: updateResult.message
      })
      return
    }
  }

  async checkIn() {
    const docData = (await DocClientSrv.getDoc(this.selectObjParam.value!.id)) as any
    //判断状态
    const workingState = docData?.workingState
    if (workingState == EnumWorkState.CHECKED_OUT) {
      KNotification.warn({
        message: '系统提示',
        description: '文档已被其他人检出，不能编辑'
      })
      return
    }
    if (workingState == EnumWorkState.INWORK) {
      // 修改编辑状态
      const doc = Object.assign(new Doc(), docData)
      doc.masterId = docData.master.id
      const result = await DocClientSrv.checkInDocWithoutFile([doc])
      this.workingState.value = result.data[0].workingState
      this.refCkEditor.value?.setDisabled(true)
    }
  }

  updateToolStrip(value: string) {
    switch (value) {
      case EnumWorkState.CHECKED_IN:
        this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
        this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
        this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN, true)
        break
      case EnumWorkState.CHECKED_OUT:
        this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, true)
        this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
        this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN, true)
        break
      case EnumWorkState.INWORK:
        this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT, false)
        this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN, false)
        break
      default:
        break
    }
  }

  async importFromWord() {
    const objParam = this.props.objParam
    const docId = objParam.id
    return new Promise((resolve, reject) => {
      // 动态创建文件输入框
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      let dialog: any = null

      // 监听文件选择事件
      fileInput.addEventListener('change', async () => {
        const files = fileInput.files
        if (files && files.length > 0) {
          // 显示导入提示对话框
          dialog = KDialog.info({ content: '正在导入文档数据，请稍后...', title: '提示', showOk: false })

          // 普通上传文件
          DocClientSrv.uploadPrimaryFile(files[0])
            .then(async fileId => {
              if (!fileId) {
                KDialog.error('上传文件失败')
                reject('上传文件失败')
                dialog.destroy() // 销毁对话框
                return
              }
              const res = await Api.post('doc', 'Document', 'docImportFromWord', { data: [docId, fileId] })
              if (res && res.code == EnumRequestCode.SUCCESS) {
                const id = res.data.id
                const current = this.selectedNode.value
                current!.docObjParam!.id = id
                this.rootObjParam.value!.id = id
                this.refStructuredDocTree.value?.refreshTree()
                dialog.destroy() // 成功后销毁对话框
                resolve(id)
              } else {
                KNotification.error({
                  title: '系统错误',
                  content: res.message
                })
                dialog.destroy() // 错误后销毁对话框
                reject(res.message)
              }
            })
            .catch(error => {
              KDialog.error('上传文件失败')
              dialog.destroy() // 异常时销毁对话框
              reject(error)
            })
        } else {
          reject('未选择文件')
        }
      })

      // 触发文件选择对话框
      fileInput.click()
    })
  }
}

import './style/style.less'
import { KToolStrip, defineExtendView } from '@kmsoft/upf-core'
import { KObjectToolStripEventEmits, KObjectToolStripPropOptions } from './interface'

/** 对象工具栏 */
export default defineExtendView({
  name: 'KObjectToolStrip',
  props: KObjectToolStripPropOptions,
  emits: KObjectToolStripEventEmits,
  parent: KToolStrip
})


import { defineView } from '@kmsoft/upf-core'
import { KClassUiConfigPropOptions, KClassUiConfigEventEmits } from './interface'
import KClassUiConfigViewModel from './KClassUiConfigViewModel'

export default defineView({
  name: 'KClassUiConfig',
  props: KClassUiConfigPropOptions,
  emits: KClassUiConfigEventEmits,
  viewModel: KClassUiConfigViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

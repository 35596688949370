import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KObjectDisplayPropOptions = {
  ...BaseViewPropOptions,
  readonly: VuePropTypes.bool().def(false),
  value: VuePropTypes.createType<any>().def(),
  cellParams: VuePropTypes.createType<Record<string, any>>().def()
}

/** 参数类型 **/
export type KObjectDisplayPropType = ViewPropsTypeExtract<typeof KObjectDisplayPropOptions>

/** 事件 */
export const KObjectDisplayEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KObjectDisplayEmitsType = ViewEmitsTypeExtract<typeof KObjectDisplayEventEmits>

import _ from 'lodash'
import { KControlAttribute, KControlEvent, ControlDescriptionBase, ControlDescriptionConstructor } from '@kmsoft/upf-core'

//#region 列
import * as columns from './columns'
//#endregion

import buttonElementConfig from './buttonElementConfig'
import pageElementConfig from './pageElementConfig'
import segmentElementConfig from './segmentElementConfig'
import formElementConfig from './formElementConfig'
import tabsElementConfig from './tabsElementConfig'
import customPanelElementConfig from './customPanelElementConfig'
import { setConfigCustomValue } from './utils'
import gridElementConfig from './gridElementConfig'

import groupElementConfig from './groupElementConfig'
import searchBarElementConfig from './searchBarElementConfig'
import inputElementConfig from './inputElementConfig'
import textAreaElementConfig from './textAreaElementConfig'
import numberElementConfig from './numberElementConfig'
import checkboxElementConfig from './checkboxElementConfig'
import dateTimeElementConfig from './dateTimeElementConfig'
import comboboxElementConfig from './comboboxElementConfig'
import filterToolButtonElementConfig from './filterToolButtonElementConfig'
import toolStripElementConfig from './toolStripElementConfig'
import filterToolScriptElementConfig from './filterToolScriptElementConfig'
import dropdownButtonElementConfig from './dropdownButtonElementConfig'
import filterGirdViewElementConfig from './filterGirdViewElementConfig'
import filterGridViewButtonElementConfig from './filterGridViewButtonElementConfig'
import filterGridElementConfig from './filterGridElementConfig'
import lodash from 'lodash'
import { EnumControlElementType } from '@kmsoft/upf-core'
import gridHeaderElementConfig from './gridHeaderElementConfig'
import toolStripButtonConfig from './toolStripButtonConfig'
import toolStripCheckboxConfig from './toolStripCheckboxConfig'
import toolStripDateTimeConfig from './toolStripDateTimeConfig'
import toolStripInputConfig from './toolStripInputConfig'
import toolStripRadioConfig from './toolStripRadioConfig'
import toolStripSelectConfig from './toolStripSelectConfig'

// 一些组件需要对特定属性的值进行自定义
const customValue1 = { LAYOUT: { rowSpan: 3, entireRow: true } }
const customValue2 = { LAYOUT: { rowSpan: 1, entireRow: true } }
const customValue3 = { PROPS: { colCount: 2 } }
const customValue4 = { PROPS: { sorted: 'left' }, LAYOUT: { rowSpan: 1, entireRow: true } }
const customValue6 = { LAYOUT: { rowSpan: 2, entireRow: true } }

export type DesignerElementConfig = {
  BASE: Array<KControlAttribute>
  PROPS: Array<KControlAttribute>
  LAYOUT: Array<KControlAttribute>
  EVENT: Array<KControlEvent>
}

/* 深拷贝，避免赋自定义值时出现引用 */
const ElementConfigCollection = new Map<string, DesignerElementConfig>()

ElementConfigCollection.set(EnumControlElementType.INPUT, lodash.cloneDeep(inputElementConfig))
ElementConfigCollection.set(EnumControlElementType.FORM, setConfigCustomValue(lodash.cloneDeep(formElementConfig), customValue1))
ElementConfigCollection.set(
  EnumControlElementType.SEGMENT,
  setConfigCustomValue(lodash.cloneDeep(segmentElementConfig), customValue1)
)
ElementConfigCollection.set(EnumControlElementType.PAGE, setConfigCustomValue(lodash.cloneDeep(pageElementConfig), customValue1))
ElementConfigCollection.set(
  EnumControlElementType.TAB_PAGE,
  setConfigCustomValue(lodash.cloneDeep(tabsElementConfig), customValue1)
)
ElementConfigCollection.set(EnumControlElementType.GRID, setConfigCustomValue(lodash.cloneDeep(gridElementConfig), customValue1))
ElementConfigCollection.set(
  EnumControlElementType.FILTER_GRID,
  setConfigCustomValue(lodash.cloneDeep(filterGridElementConfig), { LAYOUT: { fill: true } })
)
ElementConfigCollection.set(EnumControlElementType.BUTTON, lodash.cloneDeep(buttonElementConfig))
ElementConfigCollection.set(EnumControlElementType.CUSTOM_PANEL, lodash.cloneDeep(customPanelElementConfig))
ElementConfigCollection.set(
  EnumControlElementType.GROUP,
  setConfigCustomValue(lodash.cloneDeep(groupElementConfig), customValue3)
)
ElementConfigCollection.set(EnumControlElementType.TEXT_AREA, lodash.cloneDeep(textAreaElementConfig))
ElementConfigCollection.set(EnumControlElementType.NUMBER, lodash.cloneDeep(numberElementConfig))
ElementConfigCollection.set(EnumControlElementType.CHECKBOX, lodash.cloneDeep(checkboxElementConfig))
ElementConfigCollection.set(EnumControlElementType.DATE_TIME, lodash.cloneDeep(dateTimeElementConfig))
ElementConfigCollection.set(EnumControlElementType.COMBOBOX, lodash.cloneDeep(comboboxElementConfig))

ElementConfigCollection.set(
  EnumControlElementType.FILTER_GRID_VIEW,
  setConfigCustomValue(lodash.cloneDeep(filterGirdViewElementConfig), customValue4)
)
ElementConfigCollection.set(EnumControlElementType.DROPDOWN_BUTTON, lodash.cloneDeep(dropdownButtonElementConfig))
ElementConfigCollection.set(
  EnumControlElementType.GRID_HEAD_TOOL,
  setConfigCustomValue(lodash.cloneDeep(gridHeaderElementConfig), customValue1)
)

ElementConfigCollection.set(
  EnumControlElementType.TOOL_STRIP,
  setConfigCustomValue(lodash.cloneDeep(toolStripElementConfig), customValue2)
)
ElementConfigCollection.set(EnumControlElementType.TOOL_STRIP_BUTTON, lodash.cloneDeep(toolStripButtonConfig))
ElementConfigCollection.set(EnumControlElementType.TOOL_STRIP_CHECKBOX, lodash.cloneDeep(toolStripCheckboxConfig))
ElementConfigCollection.set(EnumControlElementType.TOOL_STRIP_DATETIME, lodash.cloneDeep(toolStripDateTimeConfig))
ElementConfigCollection.set(EnumControlElementType.TOOL_STRIP_INPUT, lodash.cloneDeep(toolStripInputConfig))
ElementConfigCollection.set(EnumControlElementType.TOOL_STRIP_RADIO, lodash.cloneDeep(toolStripRadioConfig))
ElementConfigCollection.set(EnumControlElementType.TOOL_STRIP_SELECT, lodash.cloneDeep(toolStripSelectConfig))

ElementConfigCollection.set('column', lodash.cloneDeep(columns.columnElementConfig))
ElementConfigCollection.set('column_group', lodash.cloneDeep(columns.columnGroupElementConfig))
ElementConfigCollection.set('column_expression', lodash.cloneDeep(columns.columnComputeElementConfig))

for (const key in columns) {
  /** 当前循环对象 */
  const element = Reflect.get(columns, key)

  // 如果不包含组件名称
  if (!('controlName' in element)) {
    continue
  }

  /** 狗仔 */
  const constructor = element as typeof ControlDescriptionBase
  /** 构造 */
  const constructorFunc = element as ControlDescriptionConstructor
  /** 创建实例 */
  const instance = new constructorFunc()
  /** 转换格式 （兼容性过渡） */
  const config = {
    BASE: instance.baseAttributes,
    PROPS: instance.propsAttributes,
    LAYOUT: instance.layoutAttributes,
    EVENT: instance.events
  }
  // 添加进对照表
  ElementConfigCollection.set(constructor.controlName, lodash.cloneDeep(config))
}

export { ElementConfigCollection }

export * from './layoutConfigItemCollection'
export * from './propsConfigItemCollection'
export * from './utils'
export * from './interface'

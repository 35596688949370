import { KViewSchema, ValueChangeEventArgs, VuePropTypes } from '@kmsoft/upf-core'
import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'
import { KDataElementTree, KDesignerControl } from '../../../../types'

/** KFilterGridViewDesigner 事件 */
export const KFilterGridViewDesignerEventEmits = {
  ...BaseViewEventEmits,
  /** 值改变前事件 */
  beforeChange: (event: ValueChangeEventArgs) => true,
  /** 值改变后事件 */
  change: (event: ValueChangeEventArgs) => true
}

/** KFilterGridViewDesigner 事件类型 **/
export type KFilterGridViewDesignerEventEmitsType = ViewEmitsTypeExtract<typeof KFilterGridViewDesignerEventEmits>

/** KFilterGridViewDesigner 参数 **/
export const KFilterGridViewDesignerPropOptions = {
  ...BaseViewPropOptions,
  value: VuePropTypes.createType<Array<KViewSchema>>().def([]),
  /**控件元数据 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def([]),
  /**数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def({} as KDataElementTree)
}

/** KFilterGridViewDesigner 参数类型 **/
export type KFilterGridViewDesignerPropType = ViewPropsTypeExtract<typeof KFilterGridViewDesignerPropOptions>

import {
  VuePropTypes,
  NavigationMenuGroup,
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract
} from '@kmsoft/upf-core'

/** KExtendLayout 参数 **/
export const KExtendLayoutPropOptions = {
  ...BaseViewPropOptions,
  defaultMenuKey: VuePropTypes.string().def(''),
  menus: VuePropTypes.array<NavigationMenuGroup>().def()
}

/** KExtendLayout 参数类型 **/
export type KExtendLayoutPropType = ViewPropsTypeExtract<typeof KExtendLayoutPropOptions>

/** KExtendLayout 事件 */
export const KExtendLayoutEventEmits = {
  ...BaseViewEventEmits
}

/** KExtendLayout 事件类型 **/
export type KExtendLayoutEventEmitsType = ViewEmitsTypeExtract<typeof KExtendLayoutEventEmits>

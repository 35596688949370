import { connect, withInstall } from '@kmsoft/upf-core'
import KStandardWorkspaceView from './KStandardWorkspace.vue'
import KStandardWorkspaceViewModel from './KStandardWorkspaceViewModel'

const KStandardWorkspace = connect(KStandardWorkspaceView, KStandardWorkspaceViewModel)

export default withInstall(KStandardWorkspace)
export { KStandardWorkspace, KStandardWorkspaceView, KStandardWorkspaceViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewPropOptions, ViewPropsTypeExtract, BaseViewEventEmits, ViewEmitsTypeExtract } from '@kmsoft/upf-core'
import { RefreshConfigValuePanelEventArgs } from '../../sys/sys-config-tree/interface'

// 页面事件
export const KConfigTreeEventEmits = {
  ...BaseViewEventEmits,
  refreshConfigValuePanel: (e: RefreshConfigValuePanelEventArgs) => true
}

// 属性选项
export const KConfigTreePropOptions = {
  ...BaseViewPropOptions
}

// 属性类型
export type KConfigTreePropType = ViewPropsTypeExtract<typeof KConfigTreePropOptions>

export type KConfigTreeEventEmitsType = ViewEmitsTypeExtract<typeof KConfigTreeEventEmits>

import { GridCellEditorParams, GridCellRendererParams, KObjectClassGridBaseColumn } from '../../base'
import KHyperLink from '.'

/** 对象显示器 */
export class KDataGridHyperLinkColumn extends KObjectClassGridBaseColumn {
  name = 'KDataGridHyperLinkColumn'
  getCellRenderer(params: GridCellRendererParams) {
    const col = (params.cellParams as any).col
    const colField = col.field?.split('.')
    const rows = colField.length > 1 ? (params.cellParams as any).row[colField[0]] : (params.cellParams as any).row
    const objParam = { id: rows.id, modelCode: rows.rdmExtensionType ? rows.rdmExtensionType : rows.className }
    const newParam = { ...col, ...params, objParam: objParam }
    return <KHyperLink {...newParam} />
  }

  getCellEditor(params: GridCellEditorParams) {
    const col = (params.cellParams as any).col
    const colField = col.field?.split('.')
    const rows = colField.length > 1 ? (params.cellParams as any).row[colField[0]] : (params.cellParams as any).row
    const objParam = { id: rows.id, modelCode: rows.rdmExtensionType ? rows.rdmExtensionType : rows.className }
    const newParam = { ...col, ...params, objParam: objParam }
    return <KHyperLink {...newParam} />
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_data_grid, {
      ref: _ctx.$vm.refObjClsGrid,
      rowKey: "id",
      autoIndex: false,
      isMultipleSelection: true,
      rowModelType: 'clientSide'
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "id",
          name: "id",
          dataPropertyName: "id",
          headerText: "编码",
          align: "center"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          id: "name",
          name: "name",
          dataPropertyName: "name",
          headerText: "名称",
          align: "center"
        })
      ]),
      _: 1
    }, 512)
  ]))
}
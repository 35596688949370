import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartExcelExportPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KPartExcelExportPropType = ViewPropsTypeExtract<typeof KPartExcelExportPropOptions>

/** 事件 */
export const KPartExcelExportEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartExcelExportEmitsType = ViewEmitsTypeExtract<typeof KPartExcelExportEventEmits>

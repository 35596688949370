
import { defineView } from '@kmsoft/upf-core'
import { KBaselineEditPropOptions, KBaselineEditEventEmits } from './interface'
import KBaselineEditViewModel from './KBaselineEditViewModel'

export default defineView({
  name: 'KBaselineEdit',
  props: KBaselineEditPropOptions,
  emits: KBaselineEditEventEmits,
  viewModel: KBaselineEditViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

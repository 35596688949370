
import { defineView } from '@kmsoft/upf-core'
import { KPartBomCompareModeFormPropOptions, KPartBomCompareModeFormEventEmits } from './interface'
import KPartBomCompareModeFormViewModel from './KPartBomCompareModeFormViewModel'

export default defineView({
  name: 'KPartBomCompareModeForm',
  props: KPartBomCompareModeFormPropOptions,
  emits: KPartBomCompareModeFormEventEmits,
  viewModel: KPartBomCompareModeFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

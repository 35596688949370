import { connect, withInstall } from '@kmsoft/upf-core'
import KPersonalWorkspaceView from './KPersonalWorkspace.vue'
import KPersonalWorkspaceViewModel from './KPersonalWorkspaceViewModel'

const KPersonalWorkspace = connect(KPersonalWorkspaceView, KPersonalWorkspaceViewModel)

export default withInstall(KPersonalWorkspace)
export { KPersonalWorkspace, KPersonalWorkspaceView, KPersonalWorkspaceViewModel }
export * from './KPersonalWorkspaceViewModel'

// 模板生成文件
// export * from './{{folderName}}'

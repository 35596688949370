
import { defineView } from '@kmsoft/upf-core'
import { KConfigValueTileViewModelPropOptions } from './interface'
import KConfigValueTileViewModel from './KConfigValueTileViewModel'
import { EnumTabType, EnumEditType } from '../../interface'

export default defineView({
  name: 'ConfigValueTile',
  props: KConfigValueTileViewModelPropOptions,
  viewModel: KConfigValueTileViewModel,
  setup() {
    return {
      EnumEditType,
      EnumTabType
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KProductWorkspaceFormPropOptions, KProductWorkspaceFormEventEmits } from './interface'
import KProductWorkspaceFormViewModel from './KProductWorkspaceFormViewModel'

export default defineView({
  name: 'KProductWorkspaceForm',
  props: KProductWorkspaceFormPropOptions,
  emits: KProductWorkspaceFormEventEmits,
  viewModel: KProductWorkspaceFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

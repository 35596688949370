import { connect, withInstall } from '@kmsoft/upf-core'
import KPartExcelExportView from './KPartExcelExport.vue'
import KPartExcelExportViewModel from './KPartExcelExportViewModel'

const KPartExcelExport = connect(KPartExcelExportView, KPartExcelExportViewModel)

export default withInstall(KPartExcelExport)
export { KPartExcelExport, KPartExcelExportView, KPartExcelExportViewModel }

// 模板生成文件
// export * from './{{folderName}}'

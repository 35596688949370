import { BaseViewModel, KDialog, KFormViewModel, KNotification, KToolStripViewModel } from '@kmsoft/upf-core'
import { IConfigValueDefinition, KConfigValueEventEmitsType, KConfigValueViewModelPropType } from './interface'
import { EnumEditType, EnumTabType, IValueDesc } from '../../interface'
import { equals, isReqSuccess, showError } from '../../common/common'
import { ref } from 'vue'
import { Api } from '@kmsoft/ebf-common'


export default class KConfigValueViewModel extends BaseViewModel<KConfigValueEventEmitsType, KConfigValueViewModelPropType> {
  refObjClsToolStrip = ref<KToolStripViewModel>()
  refConfigValueFrom = ref<KFormViewModel<IConfigValueDefinition>>()
  /** 下拉菜单可选项 */
  selectEditTypeOptions = ref<Array<IValueDesc>>()
  /** 可编辑的下拉菜单可选项
   */
  selectEditEditTypeOptions = ref<Array<IValueDesc>>()
  /** 带显示值的下拉菜单可选项 */
  selectDisplayEditTypeOptions = ref<Array<IValueDesc>>()
  /** 单选框可选项 */
  radioEditTypeOptions = ref<Array<IValueDesc>>()
  formState = ref<IConfigValueDefinition>({
    code: '',
    name: '',
    value: '',
    values: [] as Array<string>,
    value_switch: false,
    dataType: '',
    editType: '',
    editParam: '',
    remark: ''
  } as IConfigValueDefinition)

  private defId: string
  private treeId: string
  /** 初始值, 用于对比是否发生变更*/
  private sourceValue: string
  private sourceValue_switch: boolean
  /** 初始对象*/
  private row: IConfigValueDefinition
  private mode: string

  /** 加载完成函数 */
  viewDidMount() {
    this.mode = EnumTabType.DEV
  }

  /**
   * 初始化表单内容
   * @param defId 配置项编码
   * @param treeId 树节点ID
   */
  public init(defId: string, treeId: string) {
    this.defId = defId
    this.treeId = treeId
    this.loadData()
  }

  /**
   * 加载数据
   */
  private loadData(): void {
    const param = {
      data: [
        {
          defId: this.defId as unknown,
          treeId: this.treeId as unknown
        }
      ]
    }
    Api.post('sys', 'ConfigDevService', 'loadConfigDevValue', param).then(response => {
      if (!isReqSuccess(response)) {
        showError(response)
        return
      }
      const value = response.data
      const formState = {
        defId: value?.defId as unknown,
        id: value?.id as unknown,
        code: value?.code,
        name: value?.name,
        value: value?.value,
        values: [] as Array<string>,
        remark: value?.remark,
        dataType: value?.dataType,
        editType: value?.editType,
        editParam: value?.editParam
      } as IConfigValueDefinition
      if (formState.value) {
        formState.values = [formState.value]
      }
      this.row = formState
      this.initSelect()
      this.initSelectEdit()
      this.initSelectDisplay()
      this.initSwitch()
      this.initRadio()

      this.sourceValue = formState.value
      if (formState.editType === EnumEditType.SWITCH) {
        this.sourceValue_switch = formState.value_switch
      }
      this.formState.value = { ...this.row }
    })
  }

  /**
   * 保存
   */
  public save(): void {
    let formState = this.formState.value
    formState = this.convertValue(formState)!
    const param = {
      data: [
        {
          treeId: this.treeId as unknown,
          defId: this.defId as unknown,
          // id: '' as unknown,
          code: formState?.code,
          value: formState?.value
        }
      ]
    }
    Api.post('sys', 'ConfigDevService', 'updateConfigDevValue', param).then(response => {
      if (!isReqSuccess(response)) {
        showError(response)
        return
      }
      // 保存成功后, 刷新初始值
      this.sourceValue = formState?.value || ''
      this.sourceValue_switch = formState?.value_switch || false
      KNotification.success({ title: '提示', content: '保存成功' })
    })
  }

  /**
   * 刷新
   */
  public refresh(): void {
    const formState = this.formState.value
    let actualValue = formState?.value as any
    let expectedValue = this.sourceValue as any
    if (formState?.editType === EnumEditType.SWITCH) {
      actualValue = formState.value_switch
      expectedValue = this.sourceValue_switch
    }
    if (!equals(actualValue, expectedValue)) {
      KDialog.confirm({
        title: '存在数据修改，是继续保存，还是放弃修改？',
        okText: '继续保存',
        cancelText: '放弃修改',
        onCancel: async () => {
          this.loadData()
        },
        onOk: async () => {
          this.save()
        }
      })
    }
  }

  /**
   * 表单数据保存前转换
   * @param formState 表单数据
   * @returns 表单数据
   */
  private convertValue(formState: IConfigValueDefinition | undefined): IConfigValueDefinition | undefined {
    if (formState === undefined) {
      return formState
    }
    if (this.row.editType === EnumEditType.SWITCH) {
      formState.value = formState.value_switch.toString()
    }
    return formState
  }

  /**
   * 初始化[下拉菜单]选项
   */
  private initSelect(): void {
    if (this.row.editType === EnumEditType.SELECT) {
      const json = JSON.parse(this.row.editParam)
      const values = json?.candidate ?? ([] as Array<string>)

      const options = [] as Array<IValueDesc>

      if (values != null && values.length > 0) {
        for (const value of values) {
          options.push({
            label: value,
            value: value
          })
        }
      }
      this.selectEditTypeOptions.value = options
    }
  }

  /**
   * 初始化[可编辑的下拉菜单]选项
   */
  private initSelectEdit(): void {
    if (this.row.editType === EnumEditType.SELECT_EDITABLE) {
      const json = JSON.parse(this.row.editParam)
      const values = json?.candidate ?? ([] as Array<string>)

      const options = [] as Array<IValueDesc>

      if (values != null && values.length > 0) {
        for (const value of values) {
          options.push({
            value: value,
            label: value
          })
        }
      }
      this.selectEditEditTypeOptions.value = options
    }
  }

  /**
   * 初始化[带显示值的下拉菜单]选项
   */
  private initSelectDisplay(): void {
    if (this.row.editType === EnumEditType.SELECT_DISPLAY) {
      const json = JSON.parse(this.row.editParam)
      const values = json?.candidate ?? ([] as Array<Record<string, any>>)

      const options = [] as Array<IValueDesc>

      if (values != null && values.length > 0) {
        for (const value of values) {
          options.push({
            label: value.displayedValue,
            value: value.actualValue
          })
        }
      }
      this.selectDisplayEditTypeOptions.value = options
    }
  }

  /**
   * 初始化[开关]
   */
  private initSwitch(): void {
    this.row.value_switch = false
    if (this.row.value === 'true') {
      this.row.value_switch = true
    }
  }

  /**
   * 初始化[单选框]
   */
  private initRadio(): void {
    if (this.row.editType === EnumEditType.RADIO) {
      const json = JSON.parse(this.row.editParam)
      const values = json?.candidate ?? ([] as Array<string>)

      const options = [] as Array<IValueDesc>

      if (values != null && values.length > 0) {
        for (const value of values) {
          options.push({
            label: value,
            value: value
          })
        }
      }
      this.radioEditTypeOptions.value = options
    }
  }

  /**
   * 可编辑下拉菜单变更事件
   * @param selectedArr 选择事件
   */
  public selectEditableChange(selectedArr: any): void {
    const formState = this.formState.value
    if (formState !== undefined) {
      formState.value = selectedArr.newValue[selectedArr.newValue.length - 1]
      if (formState.value) {
        formState.values = [formState.value]
      } else {
        formState.values = []
      }
      this.formState.value = formState
    }
  }
}

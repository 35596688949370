import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-start-start h100p"
}
const _hoisted_2 = { class: "flex1 h100p" }
const _hoisted_3 = { class: "flex1 h100p overflowYAuto" }
const _hoisted_4 = {
  class: "flex1",
  style: {"max-height":"450px"}
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_flow_history_signing = _resolveComponent("k-flow-history-signing")!
  const _component_k_flow_detail = _resolveComponent("k-flow-detail")!
  const _component_k_flow_approval_content = _resolveComponent("k-flow-approval-content")!
  const _component_k_flow_approval_process = _resolveComponent("k-flow-approval-process")!
  const _component_k_spin = _resolveComponent("k-spin")!
  const _component_k_empty = _resolveComponent("k-empty")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      (_ctx.$vm.signingHistoryList.length != 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("section", _hoisted_2, [
              _createVNode(_component_k_flow_history_signing, {
                selectFirstRow: "",
                signingHistoryList: _ctx.$vm.signingHistoryList,
                onRowChanged: _ctx.$vm.rowChanged
              }, null, 8, ["signingHistoryList", "onRowChanged"])
            ]),
            _createElementVNode("section", _hoisted_3, [
              _createVNode(_component_k_spin, {
                spinning: _ctx.$vm.loadingData
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_k_flow_detail, {
                      dataForm: _ctx.$vm.flowDetailData
                    }, null, 8, ["dataForm"])
                  ]),
                  _createElementVNode("div", {
                    class: "flex1",
                    style: _normalizeStyle({ height: _ctx.showApprovalContentPanel ? '450px' : '32px' })
                  }, [
                    _createVNode(_component_k_flow_approval_content, {
                      onOpen: _cache[0] || (_cache[0] = isOpen => (_ctx.showApprovalContentPanel = isOpen)),
                      businessId: _ctx.$vm.flowDetailData?.businessId,
                      showToolStrip: false
                    }, null, 8, ["businessId"])
                  ], 4),
                  _createVNode(_component_k_flow_approval_process, {
                    processInstanceId: _ctx.$vm.flowDetailData?.processInstanceId,
                    executorGridData: _ctx.$vm.flowDetailData?.processNodes
                  }, null, 8, ["processInstanceId", "executorGridData"])
                ]),
                _: 1
              }, 8, ["spinning"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_k_empty, {
              description: "暂无签审历史",
              style: {"position":"absolute","top":"50%","transform":"translateY(-50%)"}
            })
          ]))
    ]),
    _: 1
  }))
}
import {
  BaseViewModel,
  EnumItemChangeCancelType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KChangeOrderEditEmitsType, KChangeOrderEditPropType } from './interface'
import { ref } from 'vue'
import {
  Api,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPropertyEditPanelViewModel,
  ObjBusinessParam,
  ObjectToolStripItem,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import lodash from 'lodash'
import { WorkflowClientSrv } from '../../../../../ebf-workflow'
import { ChangeManageClientSrv } from '../../../client-srv'

/** KChangeOrderEdit */
export default class KChangeOrderEditViewModel extends BaseViewModel<KChangeOrderEditEmitsType, KChangeOrderEditPropType> {
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  taskParam = ref<ObjBusinessParam>()
  originFormData = ref<any>()
  originFormDataCopy = ref<any>()
  toolStripItems = ref<ObjectToolStripItem[]>(ToolStripHelper.getChangeOrderToolEditStripItems())

  constructor(options: ViewModelOptions<KChangeOrderEditPropType>) {
    super(options)
    this.taskParam.value = options.props.objParam
  }

  viewDidMount() {}
  async loadData() {
    const result = (await Api.post('change', 'ChangeOrder', 'get', { data: [this.props.objParam.id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const data = result.data
      //转换变更活动计划中的受影响对象
      data.plmChangeActivityViews.forEach((item: any) => {
        item.affectedObjectList = lodash.cloneDeep(item.plmChangeBeforeTargetViews)
      })

      if (data.lifecycleStateCode === 'RELEASED') {
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ACTIVITY, false)
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW, true)
        this.disabledEdit()
      }

      if (data.lifecycleStateCode === 'UNDERREVIEW') {
        this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW, true)
        this.disabledEdit()
      }
      if (data.needDate) {
        data.needDate = data.needDate.slice(0, 10)
      }
      this.originFormData.value = data
      this.originFormDataCopy.value = lodash.cloneDeep(data)
      return data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message,
        details: result.detail
      })
      return
    }
  }

  /** 禁用编辑**/
  disabledEdit() {
    this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
    this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
  }

  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const buttonName = event.name as EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE
    const method = this[buttonName]
    if (typeof method === 'function') {
      method.call(this, event)
    }
  }

  /** 创建变更活动 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ACTIVITY](event: ToolStripItemClickedEventArgs) {
    const data = lodash.cloneDeep(this.originFormData.value)
    ChangeManageClientSrv.createChangeActivity('创建变更活动', data.plmAffectedTargetViews, null, data.folder.id, data.id)
  }

  /** 保存 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE](event: ToolStripItemClickedEventArgs) {
    this.save()
    // this.refObjectProperty.value?.setReadOnly(true)
  }

  /** 刷新 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH](event: ToolStripItemClickedEventArgs) {
    this.refObjectProperty.value?.refresh()
  }

  /** 加入流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.addWorkflow(
      this.taskParam.value!.id,
      'ChangeOrder',
      this.originFormData.value.lifecycleStateCode == 'RELEASED' ? 'Released' : '',
      this.originFormData.value.name,
      this.originFormData.value.number
    )
  }

  /** 查看流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.viewWorkflow(this.taskParam.value!.id, 'ChangeOrder')
  }

  /** 修改状态 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_UPDATE_STATE](event: ToolStripItemClickedEventArgs) {
    KNotification.info({
      message: '功能正在开发中，敬请期待'
    })
  }
  /** 废弃 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_DISCARD](event: ToolStripItemClickedEventArgs) {
    KNotification.info({
      message: '功能正在开发中，敬请期待'
    })
  }
  /** 删除 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE](event: ToolStripItemClickedEventArgs) {
    KNotification.info({
      message: '功能正在开发中，敬请期待'
    })
  }

  async onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    if (event.name == EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY) {
      if (event.value) {
        this.refObjectProperty.value?.setReadOnly(false)
      } else {
        if (await this.save(false)) {
          this.refObjectProperty.value?.setReadOnly(true)
          event.itemCancelType = EnumItemChangeCancelType.CancelAll
        }
      }
    }
  }

  async validate() {
    return this.refObjectProperty.value?.validate()
  }

  async save(isSave: boolean = true) {
    if (await this.validate()) {
      const modifiedValue = this.refObjectProperty.value?.getModifiedValue() as any
      const value = this.refObjectProperty.value?.getValue() as any
      //如果附件
      if (Object.keys(modifiedValue).length) {
        if (Object.keys(modifiedValue).includes('secondary')) {
          modifiedValue.secondaryIds = [...modifiedValue.secondary]
          Reflect.deleteProperty(modifiedValue, 'secondary')
        }

        //获取受影响对象数据
        if (Object.keys(value).includes('plmAffectedTargetViews')) {
          const invalidItem = (value!.plmAffectedTargetViews as any[]).find((m: { handlingMethod: any }) => !m.handlingMethod)
          if (invalidItem) {
            KNotification.warning({
              title: '属性空',
              content: `处理方式不能为空`
            })
            return false
          }

          modifiedValue!.plmChangeOrderAffectedViews = (value!.plmAffectedTargetViews as any[]).map(item => {
            return {
              target: {
                id: item.id,
                rdmExtensionType: item.rdmExtensionType
              },
              handlingMethod: item.handlingMethod
            }
          })

          Reflect.deleteProperty(modifiedValue, 'plmAffectedTargetViews')
        }
        if (Object.keys(value).includes('plmChangeActivityViews')) {
          modifiedValue.plmChangeActivityViews = value.plmChangeActivityViews
        }

        // 获取活动变更数据
        this.getActivityModifiedValue(modifiedValue)
        const params = {
          data: [
            {
              id: this?.props.objParam.id,
              ...modifiedValue
            }
          ]
        }

        const result = await Api.post('change', 'ChangeOrder', 'update', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refObjectProperty.value?.refresh()
          KNotification.success('操作成功！')
          return true
        } else {
          KNotification.error({
            title: '系统错误',
            content: result.message
          })
          return false
        }
      } else {
        if (isSave)
          KNotification.warn({
            message: '系统提示',
            description: '没有修改数据'
          })
        return true
      }
    } else {
      return false
    }
  }

  /** 获取活动变更数据 **/
  private getActivityModifiedValue(modifiedValue: any) {
    //原始变更活动计划id
    const originActivityIds = this.originFormDataCopy.value?.plmChangeActivityViews.map((item: any) => item.id)
    if (Object.keys(modifiedValue).includes('plmChangeActivityViews')) {
      // 1、找出新添加
      const addIds: string[] = []
      modifiedValue.newPlmChangeActivityCreateDTOS = (modifiedValue!.plmChangeActivityViews as any[])
        .filter((item: any) => !originActivityIds.includes(item.id))
        .map((item: any) => {
          addIds.push(item.id)
          return {
            number: item.number,
            name: item.name,
            activityType: item.activityType,
            inventoryDisposition: item.inventoryDisposition,
            description: item.description,
            supplyingOrganizations: item.supplyingOrganizations,
            needDate: item.needDate,
            changeBeforeLinkViews: item.affectedObjectList.map((affectedObj: any) => {
              return {
                branchId: affectedObj?.branch?.id,
                masterId: affectedObj?.master?.id,
                target: {
                  id: affectedObj.id,
                  className: affectedObj.className,
                  rdmExtensionType: affectedObj.rdmExtensionType
                }
              }
            })
          }
        })
      // 2、找出删除的
      modifiedValue.deletePlmChangeActivityIds = originActivityIds.filter(
        (item: any) => !(modifiedValue!.plmChangeActivityViews as any[]).map((item: any) => item.id).includes(item)
      )

      // 3、其它都为修改
      const addOrDeleteIds = [...addIds, ...modifiedValue.deletePlmChangeActivityIds]
      modifiedValue.updatePlmChangeActivityUpdateDTOS = (modifiedValue!.plmChangeActivityViews as any[])
        .filter((item: any) => !addOrDeleteIds.includes(item.id))
        .map((item: any) => {
          return {
            id: item.id,
            changeBeforeLinkViews: item.affectedObjectList.map((affectedObj: any) => {
              return {
                branchId: affectedObj?.branch?.id,
                masterId: affectedObj?.master?.id,
                target: {
                  id: affectedObj.id,
                  className: affectedObj.className,
                  rdmExtensionType: affectedObj.rdmExtensionType
                }
              }
            })
          }
        })

      Reflect.deleteProperty(modifiedValue, 'plmChangeActivityViews')
    }
  }

  /** 检查受影响对象是否关联变更活动 */
  associateChangeActivity(row: any) {
    const activityPlanDataList = row.plmChangeActivityViews
    let allActivityPlanIds = activityPlanDataList.reduce((arr: string[], cur: any) => {
      const ids = cur.affectedObjectList.map((r: any) => r.id) || []
      arr.push(...ids)
      return arr
    }, [])
    allActivityPlanIds = [...new Set(allActivityPlanIds)]
    return row.plmAffectedTargetViews.every((item: Record<string, any>) => allActivityPlanIds.includes(item.id))
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KWorkflowViewPropOptions = {
  ...BaseViewPropOptions,
  /** 流程id **/
  processInstanceId: VuePropTypes.string().def('00e07ad1-2a01-11ef-8ac3-46ba9817b75e'),
  taskId: VuePropTypes.string().def('00e07ad1-2a01-11ef-8ac3-46ba9817b75e')
}

/** 参数类型 **/
export type KWorkflowViewPropType = ViewPropsTypeExtract<typeof KWorkflowViewPropOptions>

/** 事件 */
export const KWorkflowViewEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KWorkflowViewEmitsType = ViewEmitsTypeExtract<typeof KWorkflowViewEventEmits>

import { AgentStaticObject } from '../../AgentStaticObject'

/**哈希工具集。*/
export class HashUtil extends AgentStaticObject {
  protected static type: string = 'KMSoft.Agent.HashUtil'

  /**
   * 计算文件 Hash 值。
   * @param argFile 文件。
   * @returns Hash 值。*/
  public static async GetFileHash(argFile: string): Promise<string> {
    return this.invokeStaticMethod('GetFileHash', argFile)
  }

  public static ConvertToLittleEndian(guid: string): string {
    if (!guid) return guid

    const reg = new RegExp(
      /([({]?)([0-9a-fA-F]{8})(-?)([0-9a-fA-F]{4})(-?)([0-9a-fA-F]{4})(-?)([0-9a-fA-F]{4})(-?)([0-9a-fA-F]{12})([)}]?)/
    )

    const matches = guid.match(reg)
    if (
      !matches ||
      matches.length !== 12 ||
      !matches[2] ||
      matches[2].length !== 8 ||
      !matches[4] ||
      matches[4].length !== 4 ||
      !matches[6] ||
      matches[6].length !== 4
    )
      return guid

    const convert = (s: string): string => {
      const buffer = []
      for (let i = 0; i < s.length; i += 2) buffer.push(s.substring(i, i + 2))
      return buffer.reverse().join('')
    }

    return matches.map((v, i) => (i === 0 ? '' : i === 2 || i === 4 || i === 6 ? convert(v) : v)).join('')
  }
}

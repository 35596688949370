import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../../object-tool-strip'
import { EnumToolStripItemKeys } from '../../types'
import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumClassTemplate } from '../../../../client-srv'

export const getIssueReportEditToolStripItems = (): Array<ObjectToolStripItem> => {
  return [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUEST_CHANGE,
      title: '创建请求变更',
      icon: 'file-protect',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjClsTempletID: [EnumClassTemplate.CHANGE_ISSUE],
      shortcutKey: 'alt+o',
      supportedObjNumMode: EnumSupportObjectNumMode.Single,
      disabled: true
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION,
      title: '操作',
      icon: 'DropObjOp',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      items: [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
          title: '加入流程',
          icon: 'AddToFlow',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW,
          title: '查看流程',
          icon: 'ViewFlowObj',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
        // {
        //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UPDATE_STATE,
        //   title: '修改状态',
        //   icon: 'file-text',
        //   visible: true,
        //   compType: EnumToolStripCompType.BUTTON
        // },
        // {
        //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DISCARD,
        //   title: '废弃',
        //   icon: 'file-text',
        //   visible: true,
        //   compType: EnumToolStripCompType.BUTTON
        // },
        // {
        //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
        //   title: '删除',
        //   icon: 'file-text',
        //   visible: true,
        //   compType: EnumToolStripCompType.BUTTON
        // }
      ]
    }
  ]
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_k_form, {
      model: _ctx.$vm.formState,
      "label-col": { span: 8 },
      "wrapper-col": { span: 16 }
    }, null, 8, ["model"])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%","padding":"5px","display":"flex","flex-direction":"column"} }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = { style: {"flex":"1"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip_checkbox_item = _resolveComponent("k-tool-strip-checkbox-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createBlock(_component_k_split_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_pane, {
        size: 15,
        minSize: 15,
        maxSize: 50
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_tree_view, {
            ref: _ctx.refObjClsTree,
            autoWrapRoot: "",
            defaultExpandAll: ""
          }, null, 512)
        ]),
        _: 1
      }),
      _createVNode(_component_k_split_pane, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_k_tool_strip, { name: "objClsToolStrip" }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_tool_strip_button_item, {
                    name: "btnNew",
                    title: "新建",
                    onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.addPage())
                  }),
                  _createVNode(_component_k_tool_strip_button_item, {
                    name: "btnNewChild",
                    title: "新建子页面",
                    onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.newChildPage())
                  }),
                  _createVNode(_component_k_tool_strip_button_item, {
                    name: "btnNewVersion",
                    title: "创建新版本"
                  }),
                  _createVNode(_component_k_tool_strip_button_item, {
                    name: "btnDelete",
                    title: "删除",
                    onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.deletePage())
                  }),
                  _createVNode(_component_k_tool_strip_checkbox_item, {
                    name: "chkShowLatestVersion",
                    title: "是否显示最新版本"
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_k_data_grid, { name: "objClsGrid" }, {
                columns: _withCtx(() => [
                  _createVNode(_component_k_data_grid_text_box_column, {
                    id: "name",
                    name: "name",
                    dataPropertyName: "name",
                    headerText: "名称",
                    align: "center"
                  }),
                  _createVNode(_component_k_data_grid_text_box_column, {
                    id: "symbol",
                    name: "symbol",
                    dataPropertyName: "symbol",
                    headerText: "代号",
                    align: "center"
                  }),
                  _createVNode(_component_k_data_grid_text_box_column, {
                    id: "suffix",
                    name: "suffix",
                    dataPropertyName: "suffix",
                    headerText: "代号后缀",
                    align: "center"
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
import { AppContext } from '@kmsoft/upf-core'
import { VerifyObjClsPurviewParams, VerifyObjectPurviewParams, PurviewVerifyResult, VerifyFuncPurviewParams } from './types'

/** 数据适配器 */
export abstract class PurviewAdapterBase {
  /** 用户Id */
  get userId() {
    const identity = AppContext.current.getIdentity()
    return identity?.id
  }

  abstract verifyObjClsPurview(params: VerifyObjClsPurviewParams): PurviewVerifyResult

  abstract verifyObjectPurview(params: VerifyObjectPurviewParams): Promise<PurviewVerifyResult> | PurviewVerifyResult

  abstract verifyFuncPurview(params: VerifyFuncPurviewParams): PurviewVerifyResult
}

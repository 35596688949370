
import { defineView } from '@kmsoft/upf-core'
import { KCustomerNeedsSectionCreatePropOptions, KCustomerNeedsSectionCreateEventEmits } from './interface'
import KCustomerNeedsSectionCreateViewModel from './KCustomerNeedsSectionCreateViewModel'

export default defineView({
  name: 'KCustomerNeedsSectionCreate',
  props: KCustomerNeedsSectionCreatePropOptions,
  emits: KCustomerNeedsSectionCreateEventEmits,
  viewModel: KCustomerNeedsSectionCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

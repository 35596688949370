
import { defineView, EnumDataGridRowModelType } from '@kmsoft/upf-core'
import { EnumLifecycleState, EnumActivityType, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KPersonalChargeChangeActivityGridPropOptions, KPersonalChargeChangeActivityGridEventEmits } from './interface'
import KPersonalChargeChangeActivityGridViewModel from './KPersonalChargeChangeActivityGridViewModel'
import { readonly } from 'vue'

export default defineView({
  name: 'KPersonalChargeChangeActivityGrid',
  props: KPersonalChargeChangeActivityGridPropOptions,
  emits: KPersonalChargeChangeActivityGridEventEmits,
  viewModel: KPersonalChargeChangeActivityGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      EnumLifecycleState,
      EnumLifecycleStateColor,
      EnumActivityType,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      pagination: {
        pageIndex: 1,
        pageSize: 50
      },
      selectOptions: EnumLifecycleState._list,
      selectValuePlaceholder: '生命周期状态'
    }
  }
})

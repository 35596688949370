import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { CADWorkspaceRootTreeNode, KCADWorkspaceEmitsType, KCADWorkspacePropType } from './interface'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { IFolder } from '../../folder-manage/folder-tree/interface'

/** KCADWorkspace */
export default class KCADWorkspaceViewModel extends BaseViewModel<KCADWorkspaceEmitsType, KCADWorkspacePropType> {
  constructor(options: ViewModelOptions<KCADWorkspacePropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 加载树节点
   */
  async loadTreeData() {
    const param = {
      data: []
    }
    const res = await Api.post('folder', 'Folder', 'getCADWorkspace', param)
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const node = {
        id: res.data?.id,
        name: res.data?.name,
        leafFlag: res.data.leafFlag,
        nameEn: res.data.nameEn,
        type: res.data.type
      } as IFolder
      return [new CADWorkspaceRootTreeNode(node)]
    } else {
      KNotification.error({
        title: '获取CAD工作区数据异常',
        content: res.message!
      })
      return Promise.resolve([])
    }
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "full part-obj-manage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_folder_tree = _resolveComponent("k-folder-tree")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createBlock(_component_k_split_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_pane, {
        size: 15,
        minSize: 15,
        maxSize: 50
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_panel, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_folder_tree, {
                ref: "refFolderTree",
                loadData: _ctx.$vm.loadTreeData,
                onAfterSelect: _ctx.$vm.afterSelect
              }, null, 8, ["loadData", "onAfterSelect"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_k_split_pane, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_k_object_class_grid, {
              ref: "refFolderObjGrid",
              modelCode: _ctx.modelCode,
              modelGroup: _ctx.modelGroup,
              schemaType: "folderGrid",
              selectFirstRow: false,
              loadData: _ctx.$vm.loadGridData,
              toolStripItems: _ctx.$vm.refToolStripItems,
              onToolStripItemClicked: _ctx.toolStripItemClicked,
              operatorColumn: _ctx.operatorColumn,
              showOperatorColumn: true,
              onOperationClick: _ctx.operationClick
            }, null, 8, ["modelCode", "modelGroup", "loadData", "toolStripItems", "onToolStripItemClicked", "operatorColumn", "onOperationClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
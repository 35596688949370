import { App, AppContext, DirectiveBinding, VNode, isRef } from 'vue'
import lodash from 'lodash'
import { ObjectToolStripItem } from '../../controls'
import { ContextMenuMenuClickedEvent } from '@kmsoft/upf-core'

class FocusDirective {
  /** 工具栏条目 */
  private shortcutKeyItems: Array<{
    shortcutKey: Array<string>
    element: ObjectToolStripItem
    parent?: ObjectToolStripItem
  }> = []
  /** 工具栏条目 */
  private items: Array<ObjectToolStripItem> = []
  /** 聚焦的控件Id */
  private focusComponentId = ''
  /** 上一个激活的控件Id */
  private lastFocusComponentId = ''
  /** 控件索引 */
  private componentIndex = 1
  /** 工具栏实例 */
  private toolStripInstance?: VNode

  async init(app: App): Promise<any> {
    const that = this

    /** 生成唯一id */
    const tabIndex = 1

    // 添加键盘监听事件
    this.addKeyboardListener()

    // 增加钩子函数
    app.directive('focus', {
      beforeMount(el: HTMLElement, binding, vnode) {
        // 添加焦点属性
        el.setAttribute('tabIndex', tabIndex.toString())
        // 增加组件
        el.classList.add('k-focus')
      },
      // 挂载后
      mounted(el: HTMLElement, binding, vnode) {
        /** 获取控件唯一id */
        const componentId = `k-focus-${that.componentIndex}`

        // 添加控件id
        el.setAttribute('data-focus-id', componentId)

        // 监听获取焦点事件
        el.addEventListener('focusin', event => {
          that.updataMenu(componentId, el, binding, vnode, 'focus')
          event.stopPropagation()
        })

        // 添加离开焦点事件
        el.addEventListener('focusout', event => {
          that.lastFocusComponentId = componentId

          const target = event.target as HTMLElement
          const componentType = target ? target.attributes.getNamedItem('type')?.value : ''

          // 例外：当组件为checkbox时，focusin会先执行，所以这个直接判断，不使用定时器
          if (componentType == 'checkbox') {
            if (that.focusComponentId != that.lastFocusComponentId) {
              el.classList.remove('component-focus')
              that.focusComponentId = ''
              that.shortcutKeyItems = []
            }
          } else {
            // 如果当前容器子节点可为激活状态，且没有绑定全局快捷键
            // 重复点击会造成重复切换component-focus样式类
            // 使用延时来解决重复切换的问题
            setTimeout(() => {
              if (that.focusComponentId != that.lastFocusComponentId) {
                el.classList.remove('component-focus')
              }
            }, 200)
            that.focusComponentId = ''
            that.shortcutKeyItems = []
          }

          event.stopPropagation()
        })

        //增加索引数
        that.componentIndex++

        // 挂载元素之后默认激活当前元素
        if (binding.arg == 'auto-focus') {
          el.focus()
        }
      },
      updated(el: HTMLElement, binding, vnode) {
        const componentId = el.getAttribute('data-focus-id')
        if (componentId == that.focusComponentId) {
          that.updataMenu(componentId, el, binding, vnode, 'updated')
        }
      },
      unmounted() {}
    })
  }

  /** 更新菜单配置 */
  private updataMenu = (
    componentId: string,
    el: HTMLElement,
    binding: DirectiveBinding<Array<ObjectToolStripItem> | undefined>,
    vnode: VNode,
    type: string
  ) => {
    /** 控件绑定的值 */
    const bindingValue = binding.value

    /** 绑定的组件 */
    const instance = binding.instance as any

    // 只有在组件聚焦切换时才设置点击效果
    if (this.focusComponentId != componentId) {
      /** 移除所有聚焦的样式 */
      const nodeList = document.querySelectorAll(`.component-focus`)
      nodeList.forEach(e => {
        // 当前组件不移除，否则将会重复闪烁效果
        if (e != el) {
          e.classList.remove('component-focus')
        }
      })
      // 增加聚焦样式
      el.classList.add('component-focus')
    }

    // 设置激活的控件
    this.focusComponentId = componentId

    // 如果控件直接绑定了菜单数据
    if (bindingValue) {
      this.initData(bindingValue)
      return
    }

    /** 组件字段 */
    this.toolStripInstance = instance ? instance.refToolStrip : null

    // 如果存在工具栏配置
    if (this.toolStripInstance) {
      // 组件引用
      let refToolStrip

      // 如果是方法返回
      if (lodash.isFunction(this.toolStripInstance)) {
        refToolStrip = this.toolStripInstance()
      } else if (isRef(this.toolStripInstance)) {
        // 如果是ref返回
        refToolStrip = this.toolStripInstance.value
      } else {
        // 如果是直接返回
        refToolStrip = this.toolStripInstance
      }

      // 如果有实例且包含菜单
      if (refToolStrip && refToolStrip.items) {
        this.initData(refToolStrip.items)
        return
      }
    }

    // 否则将快捷键设置为空
    this.shortcutKeyItems = []
  }

  /**
   * 初始化数据
   * @param arrays
   */
  private initData(arrays: Array<ObjectToolStripItem>) {
    //清空数组
    this.shortcutKeyItems = []

    // 保存工具栏原数组
    this.items = arrays

    // 循环初始化数据
    const findItems = (arrays: Array<ObjectToolStripItem>, parent?: ObjectToolStripItem) => {
      if (!arrays) {
        return null
      }
      arrays.forEach(element => {
        if ('shortcutKey' in element && element.shortcutKey) {
          this.shortcutKeyItems.push({
            shortcutKey: element.shortcutKey.replace('ctrl', 'control').split('+'),
            element: element,
            parent: parent
          })
        }
        if ('items' in element && element.items && element.items.length) {
          findItems(element.items, element)
        }
      })
    }
    findItems(arrays, undefined)
  }

  /**
   * 添加键盘监听事件
   */
  private addKeyboardListener() {
    // 按键按下
    document.addEventListener('keydown', this.onKeyDown.bind(this))
    // 按键抬起
    document.addEventListener('keyup', this.onKeyUp.bind(this))
    // 按键抬起
    window.addEventListener('blur', this.onBlur.bind(this))
  }

  /** 按下的按键数组 */
  private pressKeyArray: Array<any> = []

  /**
   * 按下事件
   * @param e 事件
   * @returns
   */
  private onKeyDown(e: KeyboardEvent) {
    if (!e.key) {
      return
    }

    // 如果没有工具栏引用 不执行快捷键
    if (!this.shortcutKeyItems || this.shortcutKeyItems.length <= 0) {
      return
    }

    // 如果选中的是输入框且不是单选框 不执行快捷键
    const target = e.target as HTMLElement
    if (target && target.localName) {
      const componentType = target.attributes.getNamedItem('type')?.value
      if (target.localName == 'input' && componentType != 'checkbox') {
        return
      }
    }

    // 如果选中了文字 不执行快捷键
    const elementWindow = e.view
    if (elementWindow) {
      const selection = elementWindow.getSelection()?.toString()
      if (selection) {
        return
      }
    }

    // 全局禁用刷新快捷键
    if (e.key.toLowerCase() == 'f5') {
      e.preventDefault()
    }

    // 如果已经有按键列表
    if (this.pressKeyArray.length > 0) {
      // a-z按键长按去重
      if (this.pressKeyArray.indexOf(e.key.toLowerCase()) >= 0) {
        return
      }
    }

    // 将按下的按键加入按键数组
    this.pressKeyArray.push(e.key.toLowerCase())

    // 通过快捷键从列表里面获取菜单配置
    const item = this.findItemByShortcutKey(this.pressKeyArray)

    // 如果找到了对应的菜单
    if (item) {
      // 阻止默认事件
      e.preventDefault()

      // 组装点击参数
      const event: ContextMenuMenuClickedEvent = {
        name: item.name,
        hostType: 'ShortCut',
        attachParams: {},
        cancel: false
      }

      // 调用点击事件
      const toolStripItem: any = item
      toolStripItem.__triggerClick(event)

      // 按下的键置空
      this.pressKeyArray = []
    }
  }

  /**
   * 抬起事件
   * @param e 键盘事件
   */
  private onKeyUp(e: KeyboardEvent) {
    if (!e.key) {
      return
    }
    lodash.remove(this.pressKeyArray, a => a == e.key.toLowerCase())
  }

  /**
   * 窗口失去焦点
   * @param e
   */
  private onBlur(e: FocusEvent) {
    // 当使用打印快捷键时，当前窗口会失去焦点，造成按下按键记录异常
    // 无法获取按键抬起事件
    this.pressKeyArray = []
  }

  /**
   * 查找快捷键
   * @param shortcutKey 查找的快捷键
   * @returns
   */
  private findItemByShortcutKey(shortcutKey: Array<string>): ObjectToolStripItem | undefined {
    const result = this.shortcutKeyItems.find(a => {
      /** 从按键数组中取交集 */
      const intersection = lodash.intersection(a.shortcutKey, shortcutKey)
      /** 获取是否满足快捷键 */
      const matchShortCuts = intersection.length == a.shortcutKey.length
      /** 父节点是否可见 */
      const parentMtach = a.parent ? a.parent.visible == true : true
      /** 当前节点是否可见 */
      const currentMatch = a.element.visible == true || a.element.shortcutKeyIgnoreVisible == true
      // 只有在启用且可见时才返回对象
      return matchShortCuts && currentMatch && parentMtach
    })
    return result?.element
  }
}

export const useFocusDirective = (app: App) => {
  const instance = new FocusDirective()
  instance.init(app)
}


import { defineView } from '@kmsoft/upf-core'
import { KModuleListViewModelPropOptions } from './interface'
import KModuleListViewModel from './KModuleListViewModel'

export default defineView({
  name: 'ModuleList',
  props: KModuleListViewModelPropOptions,
  viewModel: KModuleListViewModel,
  setup() {
    return {}
  }
})


import { IKTreeNode, defineView } from '@kmsoft/upf-core'

import { KSelectDocEventEmits, KSelectDocPropOptions } from './interface'
import KSelectDocViewModel from './KSelectDocViewModel'
import { DocStructureTreeNode } from '..'

export default defineView({
  name: 'KSelectDoc',
  props: KSelectDocPropOptions,
  emits: KSelectDocEventEmits,
  viewModel: KSelectDocViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

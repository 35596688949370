import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "full",
  ref: "refChangeRequest"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_property_edit_panel = _resolveComponent("k-object-property-edit-panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_object_property_edit_panel, {
      ref: _ctx.$vm.refObjectProperty,
      readonly: true,
      objParam: _ctx.$vm.taskParam,
      load: ()=>_ctx.$vm.loadData(),
      toolStripItems: _ctx.$vm.toolStripItems,
      toolStripOptions: _ctx.$vm.toolStripOptions,
      onToolStripItemClicked: _ctx.$vm.onToolStripItemClicked,
      onToolStripItemChange: _ctx.$vm.onToolStripItemChange
    }, null, 8, ["objParam", "load", "toolStripItems", "toolStripOptions", "onToolStripItemClicked", "onToolStripItemChange"])
  ], 512))
}
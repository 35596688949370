
import { defineView } from '@kmsoft/upf-core'
import { KRequirementChangeEditPanelPropOptions, KRequirementChangeEditPanelEventEmits } from './interface'
import KRequirementChangeEditPanelViewModel from './KRequirementChangeEditPanelViewModel'
import { ToolStripItemClickedEventArgs, ToolStripItemChangeEventArgs } from '@kmsoft/upf-core/src'

export default defineView({
  name: 'KRequirementChangeEditPanel',
  props: KRequirementChangeEditPanelPropOptions,
  emits: KRequirementChangeEditPanelEventEmits,
  viewModel: KRequirementChangeEditPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import {
  BaseViewPropOptions,
  ViewPropsTypeExtract,
  VuePropTypes,
  ViewEmitsTypeExtract,
  BaseViewEventEmits
} from '@kmsoft/upf-core'
import { IClassTreeNode } from '../../tree/interface'

export interface IClassProperty {
  /**
   * id
   */
  id: string
  /**
   * 对象类id
   */
  classId: string
  /**
   * 对象类名称
   */
  className: string
  /**
   * 编码
   */
  code: string
  /**
   * 属性名称
   */
  name: string
  /**
   * 属性别名
   */
  displayName: string
  /**
   * 数据类型
   */
  dataType: any
  /**
   * 数据长度
   */
  length: number
  /**
   * 列宽
   */
  columnWidth: number
  /**
   * 编辑类型
   */
  editType: number
  /**
   * 可为空
   */
  nullable: boolean
  /**
   * 使用
   */
  valid: boolean
  /**
   * 只读
   */
  readonly: boolean
  /**
   * 可变
   */
  changeable: number
  /**
   * 可见
   */
  visible: number
  /**
   * 预置
   */
  predefined: number
  /**
   * 默认值
   */
  defaultValue: string
  /**
   * 数值位数
   */
  propertyPrecision: number
  /**
   * 数值小数位
   */
  propertyScale: number
  /**
   * 属性参数
   */
  propertyArgs: string
  /** todo ? */
  // /**
  //  * 对象属性参数
  //  */
  // objectPropertyArgs: string
  // /**
  //  * 枚举属性参数
  //  */
  // enumPropertyArgs: string
  // /**
  //  * 分类属性参数
  //  */
  // classificationPropertyArgs: string
}

// 属性集属性选项
export const KClassPropertyListPropOptions = {
  ...BaseViewPropOptions,
  /** 对象类定义 */
  classId: VuePropTypes.string().def(),
  /**文件访问 */
  fileAccess: VuePropTypes.bool().def(false),
  /** 选中节点 */
  selectedNode: VuePropTypes.createType<IClassTreeNode>()
    .setRequired()
    .def()
}

// 属性集事件
export const KClassPropertyListEventEmits = {
  ...BaseViewEventEmits
}

// emit type
export type KClassPropertyListEventEmitsType = ViewEmitsTypeExtract<typeof KClassPropertyListEventEmits>

// 属性集属性类型
export type KClassPropertyListPropType = ViewPropsTypeExtract<typeof KClassPropertyListPropOptions>

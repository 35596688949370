import { Ref } from 'vue'
import * as IframeCommunication from '@kmsoft/iframe-communication'

/** 选项 */
export type FrameEventManagerOptions = {
  /** 目标窗口 */
  targetWindow: Window
  /** 应用Id */
  appId: string
  /** 附加选项 */
  options?: {
    /** 附加消息 每次发消息时会调用此方法，返回当前方法返回的数据 */
    extraData?: () => Record<string, any>
  }
}

/** iFrame通信机制 */
export class IFrameEventManager {
  /** 注册 */
  static register(options: FrameEventManagerOptions) {
    return new IFrameEventManager(options)
  }

  /** 注册消息发送 */
  private msgDispatcher: IframeCommunication.IframeMessageDispatcher
  /** 注册消息接收 */
  private msgProcessor: IframeCommunication.IframeMessageProcessor
  /** 配置 */
  private options: FrameEventManagerOptions
  /** 事件列表 弱引用，防止内存溢出 */
  private events: WeakSet<Function> = new WeakSet<Function>()

  /**
   * 注册事件
   * @param options
   */
  constructor(options: FrameEventManagerOptions) {
    // 保存配置
    this.options = options
    // 注册消息发送
    this.msgDispatcher = IframeCommunication.IframeMessageDispatcher.getInstance().register(this.options.appId)
    // 注册消息接收
    this.msgProcessor = IframeCommunication.IframeMessageProcessor.getInstance().register(this.options.appId)
  }

  /**
   * 添加监听
   * @param name
   * @param callback
   */
  public addEventListener<TData = Record<string, any>>(name: string, callback: (args: TData) => void): void {
    /** 检查回调是否存在 */
    const existFunc = this.events.has(callback)
    // 如果存在 则不添加监听
    if (existFunc) {
      return
    }

    this.msgProcessor
      .receiveIfMatched((message: { type: string }) => message.type === name)
      .process(async message => {
        callback(message.payload as any)
        return undefined
      })

    this.events.add(callback)
  }

  /**
   * 发布事件
   * @param name 事件名称
   * @param data 携带数据
   * @param callBack 执行完成回调
   */
  public dispatchEvent(name: string, data: Record<string, any>, callBack?: IframeCommunication.IFrameMessageSuccessCallback) {
    this.msgDispatcher.sendUnicast(this.options.targetWindow, name, { ...this.options.options?.extraData?.(), ...data }, callBack)
  }

  /**
   * 广播事件
   * @param name 事件名称
   * @param data 携带数据
   * @param callBack 执行完成回调
   */
  public broadcastEvent(name: string, data: Record<string, any>, callBack?: IframeCommunication.IFrameMessageSuccessCallback) {
    this.msgDispatcher.sendBroadcast(name, { ...this.options.options?.extraData?.(), ...data }, callBack)
  }
}

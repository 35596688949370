
import { defineView } from '@kmsoft/upf-core'
import { KFileEditPropOptions, KFileEditEventEmits } from './interface'
import KFileEditViewModel from './KFileEditViewModel'

export default defineView({
  name: 'KFileEdit',
  props: KFileEditPropOptions,
  emits: KFileEditEventEmits,
  viewModel: KFileEditViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

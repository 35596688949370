import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b56a7074"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border1 h100p" }
const _hoisted_2 = { style: {"height":"calc(100% - 48px)"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"margin-left":"5px"} }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid_check_box_column = _resolveComponent("k-data-grid-check-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_collapse_panel = _resolveComponent("k-collapse-panel")!
  const _component_k_collapse = _resolveComponent("k-collapse")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createBlock(_component_k_collapse, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeKey) = $event)),
    ghost: "",
    class: _normalizeClass([_ctx.activeKey.length ? 'h100p' : ''])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_collapse_panel, {
        key: "1",
        header: "审批内容",
        class: "h100p"
      }, {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("section", _hoisted_1, [
            _withDirectives(_createVNode(_component_k_object_tool_strip, {
              ref: _ctx.$vm.refToolStrip,
              items: _ctx.$vm.toolStripItems,
              onItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onToolStripItemClicked(event))
            }, null, 8, ["items"]), [
              [_vShow, _ctx.showToolStrip]
            ]),
            _createElementVNode("section", _hoisted_2, [
              _createVNode(_component_k_data_grid, {
                dataSource: _ctx.$vm.gridData,
                ref: "refDataGrid",
                onSelectionChanged: _cache[1] || (_cache[1] = event => _ctx.$vm.changeToolItem(event))
              }, {
                columns: _withCtx(() => [
                  _createVNode(_component_k_data_grid_template_column, {
                    headerText: "名称",
                    dataPropertyName: "name",
                    align: "center",
                    width: "150"
                  }, {
                    default: _withCtx(({cellValue,row}) => [
                      _createElementVNode("a", {
                        href: "javascript:void(0)",
                        onClick: () => _ctx.$vm.onDoubleClick(row)
                      }, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(cellValue), 1)
                      ], 8, _hoisted_3)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_k_data_grid_text_box_column, {
                    headerText: "编码",
                    dataPropertyName: "code",
                    align: "center",
                    width: "150"
                  }),
                  _createVNode(_component_k_data_grid_template_column, {
                    headerText: "实体类型",
                    dataPropertyName: "rdmExtensionType",
                    align: "center",
                    width: "150"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(row.rdmExtensionType) + _ctx.$vm.getView(row)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_k_data_grid_template_column, {
                    headerText: "生命周期状态",
                    dataPropertyName: "lifecycleStateCode",
                    align: "center",
                    width: "150"
                  }, {
                    default: _withCtx(({ cellValue }) => [
                      cellValue
                        ? (_openBlock(), _createBlock(_component_k_tag, {
                            key: 0,
                            color: _ctx.EnumLifecycleStateColor[cellValue]
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.EnumLifecycleState[cellValue]), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_5, "--"))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_k_data_grid_check_box_column, {
                    headerText: "主对象",
                    dataPropertyName: "masterObjectMark",
                    align: "center",
                    width: "150"
                  }),
                  _createVNode(_component_k_data_grid_text_box_column, {
                    headerText: "版本号",
                    dataPropertyName: "version",
                    align: "center",
                    width: "150"
                  })
                ]),
                _: 1
              }, 8, ["dataSource"])
            ])
          ])), [
            [_directive_focus]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["activeKey", "class"]))
}

import { defineView } from '@kmsoft/upf-core'
import { KDocSelectFilesPropOptions, KDocSelectFilesEventEmits } from './interface'
import KDocSelectFilesViewModel from './KDocSelectFilesViewModel'
import { EnumDocumentType, SelectItem } from '@kmsoft/ebf-common'
import { computed } from 'vue'

export default defineView({
  name: 'KDocSelectFiles',
  props: KDocSelectFilesPropOptions,
  emits: KDocSelectFilesEventEmits,
  viewModel: KDocSelectFilesViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

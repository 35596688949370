import { ILocale } from './types'
import zh_CN from './zh_CN'

/** 语言列表 */
const languages: Record<string, ILocale> = { zh_CN }
/** 当前系统语言 */
const currentLanguage = navigator.language.replaceAll('-', '_')
/** 当前语言包 */
let currentLanguagePackage: ILocale = zh_CN

// 如果存在当前语言
if (Reflect.has(languages, currentLanguage)) {
  currentLanguagePackage = languages[currentLanguage]
}

export { currentLanguagePackage as locale }
export { default as messages } from './zh_CN'

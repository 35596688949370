import { EnumDialogResult, ViewModelOptions } from '@kmsoft/upf-core'
import { computed, ref, watch } from 'vue'
import { ClassMetaClientSrv, MetaProperty, QueryCondition } from '../../../../client-srv'
import { KRuleDefiner, PropertyConverter } from '../../../rule-definer'
import { KBaseEditorViewModel } from '../../base'
import { KRuleEditorEmitsType, KRuleEditorPropType } from './interface'

/** KRuleEditor */
export default class KRuleEditorViewModel extends KBaseEditorViewModel<
  KRuleEditorEmitsType,
  KRuleEditorPropType,
  QueryCondition
> {
  displayValue = computed(() => {
    /** 当前值 */
    const currentValue = this.stateValue.value

    if (!currentValue) {
      return ''
    }

    /** 显示值 */
    const displayValue = PropertyConverter.convertConditionToString(currentValue, this.fields.value)

    return displayValue
  })
  /** 包含子类 */
  includeChild = ref<boolean>(false)
  /** 对象类 Code */
  modelCode = ref<string>('')
  /** 列定义 */
  fields = ref<Array<MetaProperty>>([])

  constructor(options: ViewModelOptions<KRuleEditorPropType>) {
    super(options)
    this.stateValue.value = options.props.value
    this.includeChild.value = options.props.includeChild
    this.modelCode.value = options.props.modelCode

    watch(
      () => [options.props.modelCode, options.props.fields],
      () => {
        this.initProperties()
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  /** 初始化属性 */
  async initProperties() {
    // 如果设置了对象类
    if (this.modelCode.value) {
      const classProperty = await ClassMetaClientSrv.getClassProperties(this.modelCode.value)
      if (classProperty) {
        this.fields.value = classProperty
      }
    }
    // 如果设置了列定义
    else if (this.props.fields) {
      this.fields.value = this.props.fields
    }
  }

  /**
   * 编辑规则
   */
  onEditRule() {
    // 显示规则定义框
    KRuleDefiner.show({
      title: '规则定义',
      props: {
        ...this.props,
        condition: this.stateValue.value,
        modelCode: this.modelCode.value,
        includeChild: this.includeChild.value
      },
      onClosing: async event => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }

        /** 获取规则业务对象 */
        const definition = await event.viewInstance?.getDefinition()

        if (!definition) {
          event.cancel = true
          return
        }

        this.fields.value = definition.fields
        this.stateValue.value = definition.condition
        this.modelCode.value = definition.modelCode
        this.includeChild.value = definition.includeChild

        this.onChangeValue(definition.condition)
        this.onChangeIncludeChild(definition.includeChild)
        this.onUpdateObjClsCode(definition.modelCode)
      }
    })
  }

  /**
   * 更新值
   * @param value
   */
  onChangeValue(value: QueryCondition) {
    this.emit('update:value', value)
  }

  /**
   * 更新包含子类
   * @param value
   */
  onChangeIncludeChild(value: boolean) {
    this.emit('update:includeChild', value)
  }

  /**
   * 更新对象类 Code
   * @param value
   */
  onUpdateObjClsCode(value: string) {
    this.emit('update:modelCode', value)
  }
}

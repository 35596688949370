import { connect, withInstall } from '@kmsoft/upf-core'
import KAttrsCategoryRenderView from './KAttrsCategoryRender.vue'
import KAttrsCategoryRenderViewModel from './KAttrsCategoryRenderViewModel'

const KAttrsCategoryRender = connect(KAttrsCategoryRenderView, KAttrsCategoryRenderViewModel)

export default withInstall(KAttrsCategoryRender)
export { KAttrsCategoryRender, KAttrsCategoryRenderView, KAttrsCategoryRenderViewModel }

// 模板生成文件
// export * from './{{folderName}}'

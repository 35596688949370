import { AppContext, BaseViewModel, KSelectViewModel, SelectOption, ViewModelOptions, request } from '@kmsoft/upf-core'
import { EnumOptionsType, KSelectWrapperEmitsType, KSelectWrapperPropType } from './interface'
import { ref, watch } from 'vue'

/** KSelectWrapper */
export default class KSelectWrapperViewModel extends BaseViewModel<KSelectWrapperEmitsType, KSelectWrapperPropType> {
  private apiKey = 'server.baseApiUrl'
  refSelect = ref<KSelectViewModel>()
  options = ref<Array<SelectOption>>([])
  stateValue = ref<string>()
  stateReadonly = ref<boolean>()
  constructor(options: ViewModelOptions<KSelectWrapperPropType>) {
    super(options)

    watch(
      () => options.props.optionType,
      () => {
        this.initData()
      },
      {
        immediate: true
      }
    )
    watch(
      () => options.props.readonly,
      newVal => {
        this.stateReadonly.value = newVal
        this.refSelect.value?.setReadonly(newVal!)
      }
    )
  }

  viewDidMount() {}

  async initData() {
    const optionType = this.props.optionType
    switch (optionType) {
      case EnumOptionsType.OPTIONS:
        this.options.value = this.props.options
        break
      case EnumOptionsType.Enum: {
        // eslint-disable-next-line no-case-declarations
        const refEnumCode = this.props.refEnumCode!
        // eslint-disable-next-line no-case-declarations
        // TODO 生命周期查询枚举
        const url = '/request/common/Enumerator/get'
        const res = (await request.post(url, { object: { data: [refEnumCode] } })) as any
        if (res && res.isSuccess) {
          const enums = res.data && res.data.enumValues && res.data.enumValues?.filter((x: { valid: number }) => x.valid == 1)
          const options = enums?.map((x: { displayValue: any; actualValue: any }) => {
            return {
              label: x.displayValue,
              value: x.actualValue
            }
          })
          this.options.value = options as Array<SelectOption>
        }
        break
      }
      case EnumOptionsType.DATA_SOURCE_LINK:
        console.info('数据源,待完善')
        break
      default:
        break
    }
  }

  getValue() {
    return this.refSelect.value?.getValue()
  }
  setValue(value: any, setChanged?: boolean) {
    this.stateValue.value = value
    this.refSelect.value?.setValue(value, setChanged)
  }
  getModifiedValue() {
    return this.refSelect.value?.getModifiedValue()
  }
  isModified() {
    return this.refSelect.value?.isModified()
  }
  displayValue() {
    if (this.options.value.length > 0) {
      return this.options.value.find((item: { value: any }) => item.value === this.stateValue.value)?.label || ''
    } else {
      return ''
    }
  }
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KClassTreeDemoEmitsType, KClassTreeDemoPropType } from './interface'

/** KClassTreeDemo */
export default class KClassTreeDemoViewModel extends BaseViewModel<KClassTreeDemoEmitsType, KClassTreeDemoPropType> {
  constructor(options: ViewModelOptions<KClassTreeDemoPropType>) {
    super(options)
  }

  viewDidMount() {}
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65b6c032"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"100%","height":"100%","padding":"5px"} }
const _hoisted_2 = { class: "title-wrapper" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { style: {"font-style":"italic","margin-top":"5px","margin-bottom":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_checkbox_group = _resolveComponent("k-checkbox-group")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_form, {
      ref: _ctx.$vm.refConfigGridComboboxTileFrom,
      "label-col": { span: 5 },
      "wrapper-col": { span: 16 },
      model: _ctx.formState
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$vm.formState.name), 1),
          _createVNode(_component_k_tool_strip, { name: "objClsToolStrip" }, {
            default: _withCtx(() => [
              (_ctx.$vm.editable && _ctx.$vm.dataLoaded)
                ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                    key: 0,
                    name: "btnSave",
                    title: "保存",
                    onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.save())
                  }))
                : _createCommentVNode("", true),
              (_ctx.$vm.editable && _ctx.$vm.dataLoaded)
                ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                    key: 1,
                    name: "btnRefresh",
                    title: "刷新",
                    onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.refresh())
                  }))
                : _createCommentVNode("", true),
              (_ctx.$vm.editable && _ctx.$vm.dataLoaded)
                ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                    key: 2,
                    name: "btnEditOut",
                    title: "完成编辑",
                    onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.editOut())
                  }))
                : _createCommentVNode("", true),
              (!_ctx.$vm.editable && _ctx.$vm.dataLoaded)
                ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                    key: 3,
                    name: "btnEditIn",
                    title: "编辑",
                    onClick: _cache[3] || (_cache[3] = () => _ctx.$vm.editIn())
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$vm.formState.remark), 1),
        _createElementVNode("div", null, [
          _createVNode(_component_k_checkbox_group, {
            value: _ctx.$vm.formState.values,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.$vm.formState.values) = $event)),
            options: _ctx.$vm.selectEditTypeOptions,
            disabled: !_ctx.$vm.editable
          }, null, 8, ["value", "options", "disabled"])
        ])
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
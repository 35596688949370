import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KChangePromoteActivityEditPropOptions = {
  ...BaseViewPropOptions,
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def({
      id: '',
      modelCode: '',
      modelGroup: ''
    })
}

/** 参数类型 **/
export type KChangePromoteActivityEditPropType = ViewPropsTypeExtract<typeof KChangePromoteActivityEditPropOptions>

/** 事件 */
export const KChangePromoteActivityEditEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangePromoteActivityEditEmitsType = ViewEmitsTypeExtract<typeof KChangePromoteActivityEditEventEmits>

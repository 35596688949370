import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_check_box_column = _resolveComponent("k-data-grid-check-box-column")!
  const _component_k_divider = _resolveComponent("k-divider")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createVNode(_component_k_tool_strip, { name: "objClsToolStrip" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_tool_strip_button_item, {
            name: "btnSaveProps",
            icon: "save",
            title: "引入属性",
            onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.importProperty())
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        isMultipleSelection: false,
        selectFirstRow: true,
        rowKey: "id",
        ref: "refClassPropertyGrid"
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "dataType",
            dataPropertyName: "dataType",
            name: "vm-dataType",
            headerText: "数据类型",
            align: "center",
            width: "120",
            options: _ctx.$vm.dataTypeOptions,
            hidden: ""
          }, null, 8, ["options"]),
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "editType",
            dataPropertyName: "editType",
            name: "vm-editType",
            headerText: "编辑类型",
            align: "center",
            width: "130",
            options: _ctx.$vm.editTypeOptions
          }, null, 8, ["options"]),
          _createVNode(_component_k_data_grid_combo_box_column, {
            id: "propSource",
            dataPropertyName: "propSource",
            name: "vm-propSource",
            headerText: "属性类型",
            align: "center",
            width: "130",
            options: _ctx.$vm.propertyTypeOptions
          }, null, 8, ["options"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
            return (_openBlock(), _createBlock(_component_k_data_grid_text_box_column, {
              key: column.name,
              id: column.name,
              name: `vm-${column.name}`,
              dataPropertyName: column.name,
              headerText: column.title,
              width: column.width,
              align: "center"
            }, null, 8, ["id", "name", "dataPropertyName", "headerText", "width"]))
          }), 128)),
          _createVNode(_component_k_data_grid_check_box_column, {
            id: "nullable",
            dataPropertyName: "nullable",
            name: "vm-nullable",
            headerText: "可为空",
            align: "center",
            width: "90"
          }),
          _createVNode(_component_k_data_grid_check_box_column, {
            id: "changeable",
            dataPropertyName: "changeable",
            name: "vm-changeable",
            headerText: "可变",
            align: "center",
            width: "90"
          }),
          _createVNode(_component_k_data_grid_check_box_column, {
            id: "readonly",
            dataPropertyName: "readonly",
            name: "vm-readonly",
            headerText: "只读",
            align: "center",
            width: "90"
          }),
          _createVNode(_component_k_data_grid_check_box_column, {
            id: "visible",
            dataPropertyName: "visible",
            name: "vm-visible",
            headerText: "显示",
            align: "center",
            width: "90"
          }),
          _createVNode(_component_k_data_grid_check_box_column, {
            id: "predefined",
            dataPropertyName: "predefined",
            name: "vm-predefined",
            headerText: "预定义",
            align: "center",
            width: "90"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "ACTIONS",
            name: "ACTIONS",
            fixed: "right",
            align: "center",
            headerText: "操作",
            width: "180"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("a", {
                class: "operation-item",
                onClick: (e) => _ctx.$vm.detail(row)
              }, "查看", 8, _hoisted_1),
              _createVNode(_component_k_divider, {
                type: _ctx.EnumDividerType.VERTICAL
              }, null, 8, ["type"]),
              _createElementVNode("a", {
                class: "operation-item",
                onClick: (e) => _ctx.$vm.updateProp(row)
              }, "编辑", 8, _hoisted_2),
              _createVNode(_component_k_divider, {
                type: _ctx.EnumDividerType.VERTICAL
              }, null, 8, ["type"]),
              _createElementVNode("a", {
                class: "operation-item",
                onClick: (e) => _ctx.$vm.removeProp(row)
              }, "移除", 8, _hoisted_3)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}
import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeOrderCreateView from './KChangeOrderCreate.vue'
import KChangeOrderCreateViewModel from './KChangeOrderCreateViewModel'

const KChangeOrderCreate = connect(KChangeOrderCreateView, KChangeOrderCreateViewModel)

export default withInstall(KChangeOrderCreate)
export { KChangeOrderCreate, KChangeOrderCreateView, KChangeOrderCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KDocStructureManageView from './KDocStructureManage.vue'
import KDocStructureManageViewModel from './KDocStructureManageViewModel'

const KDocStructureManage = connect(KDocStructureManageView, KDocStructureManageViewModel)

export default withInstall(KDocStructureManage)
export { KDocStructureManage, KDocStructureManageView, KDocStructureManageViewModel }
export * from './interface'
// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KMyManagedProductionWorkspaceView from './KMyManagedProductionWorkspace.vue'
import KMyManagedProductionWorkspaceViewModel from './KMyManagedProductionWorkspaceViewModel'

const KMyManagedProductionWorkspace = connect(KMyManagedProductionWorkspaceView, KMyManagedProductionWorkspaceViewModel)

export default withInstall(KMyManagedProductionWorkspace)
export { KMyManagedProductionWorkspace, KMyManagedProductionWorkspaceView, KMyManagedProductionWorkspaceViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KBomCompareResultPanelView from './KBomCompareResultPanel.vue'
import KBomCompareResultPanelViewModel from './KBomCompareResultPanelViewModel'

const KBomCompareResultPanel = connect(KBomCompareResultPanelView, KBomCompareResultPanelViewModel)

export default withInstall(KBomCompareResultPanel)
export { KBomCompareResultPanel, KBomCompareResultPanelView, KBomCompareResultPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KPdfBrowserEmitsType, KPdfBrowserPropType } from './interface'
import { computed, ref } from 'vue'

/** KPdfBrowser */
export default class KPdfBrowserViewModel extends BaseViewModel<KPdfBrowserEmitsType, KPdfBrowserPropType> {
  urls = ref<string[]>()
  constructor(options: ViewModelOptions<KPdfBrowserPropType>) {
    super(options)
    this.urls.value = options.props.urls
  }

  url = computed(() => {
    if (this.urls.value && this.urls.value.length > 0) {
      return this.urls.value[0]
    }
    return undefined
  })

  viewDidMount() {}
}

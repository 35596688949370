import { EnumLabelPosition, KGridLayoutConfig } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from './element-config'
import { KControlAttribute } from '@kmsoft/upf-core'
import { KBizContext } from '../types'
import { PROPERTY_CONFIG_ITEM_COLLECTION } from './element-config'

/** 默认页面容器配置 */
export const DEFAULT_PAGE_CONFIG: KGridLayoutConfig = {
  minColWidth: 260,
  rowGap: 5,
  colGap: 10,
  colCount: 4,
  labelWidth: 130,
  labelPosition: EnumLabelPosition.LEFT
}

export const DEFAULT_LAYOUT: Array<KControlAttribute> = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
]

/** 默认业务上下文 */
export const DEFAULT_BIZ_CONTEXT: KBizContext = {
  name: 'DEFAULT',
  remark: 'DEFAULT',
  contextItemInfos: []
}

/* 基础配置项集合 */
export const BASE_CONTROL_ATTRIBUTE: Array<KControlAttribute> = [
  PROPERTY_CONFIG_ITEM_COLLECTION.NAME,
  PROPERTY_CONFIG_ITEM_COLLECTION.CONTROL,
  PROPERTY_CONFIG_ITEM_COLLECTION.VISIBLE
]


import { defineView } from '@kmsoft/upf-core'
import { KDocBatchImportPropOptions, KDocBatchImportEventEmits } from './interface'
import KDocBatchImportViewModel from './KDocBatchImportViewModel'

export default defineView({
  name: 'KDocBatchImport',
  props: KDocBatchImportPropOptions,
  emits: KDocBatchImportEventEmits,
  viewModel: KDocBatchImportViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { emit }
  }
})

import {
  BaseViewModel,
  ViewModelOptions,
  EnumToolStripCompType,
  ToolStripItemClickedEventArgs,
  KDialog,
  KDialogClosingEvent,
  EnumDialogResult,
  KNotification,
  EnumItemClickedCancelType,
  KDialogResult
} from '@kmsoft/upf-core'
import { KChangeRequestEmitsType, KChangeRequestPropType } from './interface'
import {
  Api,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectClassGridViewModel,
  ObjectClientSrv,
  ObjectToolStripItem
} from '@kmsoft/ebf-common'
import KChangeRequestCreate from './change-request-create'
import KChangeRequestEdit from './change-request-edit'
import { KChangeRequestCreateViewModel } from './change-request-create'
import { KChangeRequestEditViewModel } from './change-request-edit'
import { ref } from 'vue'

/** KChangeRequest */
export default class KChangeRequestViewModel extends BaseViewModel<KChangeRequestEmitsType, KChangeRequestPropType> {
  refObjGrid = ref<KObjectClassGridViewModel>()
  toolItem = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWOBJ,
      title: '创建',
      icon: 'common_add_cover',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'alt+n'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY,
      title: '编辑',
      icon: 'common_edit_cover',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  createDialog = ref<KDialogResult>()

  constructor(options: ViewModelOptions<KChangeRequestPropType>) {
    super(options)
  }

  // [key: string]: any; // 添加索引签名

  viewDidMount() {}

  toolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    // event.itemCancelType = EnumItemClickedCancelType.CancelAll
    const buttonName = event.name as EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWOBJ

    this[buttonName].call(this, event)
  }

  /**
   * 创建
   */
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWOBJ](event: ToolStripItemClickedEventArgs) {
    this.showCreate(1000, 600, {})
  }

  public showCreate(width: number, height: number, formValue: any) {
    let curParams: Record<string, any> = {}
    const that = KDialog.show({
      title: '新建变更请求',
      size: { width: width, height: height },
      showFooter: false,
      floatFooter: true,
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KChangeRequestCreate,
      props: {
        showExtButton: true,
        formValue: formValue,
        onCancel: function() {
          that.close({ dialogResult: EnumDialogResult.Cancel })
        },
        onConfirm: function(params: Record<string, any>) {
          curParams = { ...params }
          that.close({ dialogResult: EnumDialogResult.Confirm })
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KChangeRequestCreateViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS && result.success) {
          if (curParams.openObj) {
            setTimeout(() => {
              this.showEdit(result.data.id)
            }, 20)
          } else if (curParams.createNext) {
            // 不关闭窗口, 清空表单状态
            formViewModel.cleanAll()
            event.cancel = true
          }
          this.refObjGrid?.value?.refresh()
        } else {
          event.cancel = true
        }
      }
    })
  }

  /**
   * 从[变更问题报告编辑]页面打开[创建请求变更]窗口
   * @param formValue 表单属性
   */
  public showCreateFromChangeIssueEdit(formValue: any) {
    KDialog.show({
      title: '新建变更请求',
      size: { width: 1000, height: 600 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KChangeRequestCreate,
      props: {
        showExtButton: false,
        formValue: formValue
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KChangeRequestCreateViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS && result.success) {
          // 无需处理
          KNotification.success({
            title: '系统提示',
            content: '新建成功'
          })
        } else {
          event.cancel = true
        }
      }
    })
  }

  /**
   * 编辑
   */
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY](event: ToolStripItemClickedEventArgs) {
    const rows = this.refObjGrid.value?.getSelectedRows() || []
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    this.showEdit(rows[0].id)
  }

  showEdit(id: any) {
    KDialog.show({
      title: '编辑变更请求',
      size: { width: 1000, height: 600 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KChangeRequestEdit,
      props: {
        objParam: {
          id: id,
          modelCode: 'ChangeRequest',
          modelGroup: 'change'
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KChangeRequestEditViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // if (await formViewModel.save()) {
        //   this.refObjGrid?.value?.refresh()
        // } else {
        //   event.cancel = true
        // }
      }
    })
  }

  onDoubleClick() {
    const selectedRow = this.refObjGrid.value?.getSelectedRow()
    const param = { id: selectedRow?.id, modelCode: 'ChangeRequest', modelGroup: 'change' }
    ObjectClientSrv.openObj(param)
  }

  async loadData(qry: any) {
    const param = {
      group: 'change',
      behavior: 'list',
      data: [
        {
          sort: 'DESC',
          orderBy: 'lastUpdateTime',
          filter: {},
          isNeedTotal: true
        },
        {
          curPage: qry.page.pageIndex,
          pageSize: 20
        }
      ],
      clsCode: 'ChangeRequest'
    }

    const result = await Api.post('change', 'ChangeRequest', 'page', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return {
        rows: result.data.data,

        total: result.data.page.totalRows
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }
}

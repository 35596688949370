import { EnumToolStripItemKeys } from '../../types'
import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../../object-tool-strip'

export const toolStripItemEditPart: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PART,
  title: '编辑',
  icon: 'edit',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}
export const toolStripItemEndEditPart: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE,
  title: '保存',
  icon: 'save',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemCheckOutPart: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART,
  title: '检出(O)',
  icon: 'file-protect',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemUnCheckOutPart: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART,
  title: '取消检出',
  icon: 'file-text',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const toolStripItemCheckInPart: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART,
  title: '检入(I)',
  icon: 'file-sync',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}
export const toolStripItemOpenPart: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_OPEN_OBJ,
  title: '打开',
  icon: 'select',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}
export const toolStripItemChangePartPosition: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT,
  title: '调整位置顺序…',
  icon: 'sort-descending',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}
export const toolStripItemRefresh: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
  title: '刷新',
  icon: 'sync',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

export const getPartStructureOperationToolStripItems = (): Array<ObjectToolStripItem> => {
  return [
    toolStripItemEditPart,
    toolStripItemEndEditPart,
    toolStripItemCheckOutPart,
    toolStripItemUnCheckOutPart,
    toolStripItemCheckInPart,
    toolStripItemOpenPart,
    toolStripItemChangePartPosition,
    toolStripItemRefresh
  ]
}

export const clsList = [
  {
    clsCode: ['Part'],
    clsName: '零部件',
    createPage: 'KPartForm'
  },
  {
    clsCode: ['Document'],
    clsName: '文档',
    createPage: 'KDocForm'
  },
  {
    clsCode: 'Other',
    clsName: '其他',
    page: ''
  },
  {
    clsCode: 'ManagedBaseline',
    clsName: '基线',
    createPage: 'KBaselineCreateForm'
  },
  {
    clsCode: 'ChangeActivity',
    clsName: '变更活动',
    createPage: 'KChangeActivityCreate'
  },
  {
    clsCode: 'ChangeIssue',
    clsName: '变更问题报告',
    createPage: 'KIssueReportCreate'
  },
  {
    clsCode: 'ChangeRequest',
    clsName: '变更请求',
    createPage: 'KChangeRequestCreate'
  },
  {
    clsCode: 'ChangeOrder',
    clsName: '变更单',
    createPage: 'KChangeOrderCreate'
  },
  {
    clsCode: 'Distribution',
    clsName: '发放单',
    createPage: 'KDistributionCreate'
  },
  {
    clsCode: 'PromoteActivity',
    clsName: '转阶段活动',
    createPage: 'KChangePromoteActivity'
  },
  {
    clsCode: 'CustomerNeeds',
    clsName: '原始需求',
    createPage: 'KCustomerNeedsCreate'
  },
  {
    clsCode: 'ProductNeeds',
    clsName: '产品需求',
    createPage: 'KProductNeedsCreate'
  },
  {
    clsCode: 'RequirementChangeRequest',
    clsName: '需求变更请求',
    createPage: 'KRequirementChangeCreateForm'
  }
]

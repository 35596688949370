
import { EnumDataGridAutoSizeColumnsMode, EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import { KPropertyGridPropOptions, KPropertyGridEventEmits } from './interface'
import KPropertyGridViewModel from './KPropertyGridViewModel'
import { VNode } from 'vue'
import './style/index.less'

export default defineView({
  name: 'KPropertyGrid',
  props: KPropertyGridPropOptions,
  emits: KPropertyGridEventEmits,
  viewModel: KPropertyGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /**
     * 网格列
     * @returns
     */
    let __gridColumns = (): VNode[] => {
      const columns = [] as VNode[]
      columns.push(<k-data-grid-text-box-column id="id" headerText="id" dataPropertyName="id" align="center" hide={true} />)
      // columns.push(
      //   <k-data-grid-text-box-column id="CPROPERTYTYPE" headerText="属性类型" dataPropertyName="CPROPERTYTYPE" align="center" />
      // )
      columns.push(
        <k-data-grid-text-box-column id="propertyName" headerText="属性名称" dataPropertyName="propertyName" align="center" />
      )
      columns.push(
        <k-data-grid-text-box-column id="columnName" headerText="数据库列名" dataPropertyName="columnName" align="center" />
      )

      columns.push(
        <k-data-grid-combo-box-column
          id="dataType"
          headerText="数据类型"
          dataPropertyName="dataType"
          align="center"
          options={vm.dataType.value}
        />
      )
      columns.push(<k-data-grid-text-box-column id="length" headerText="长度" dataPropertyName="length" align="center" />)
      return columns
    }
    return () => {
      if (!vm.dataType.value) return
      let gridProp = {
        ref: vm.refDataGrid,
        class: 'property-grid',
        loadData: vm.loadData,
        rowKey: 'id',
        isMultipleSelection: true,
        rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
        autoSizeColumnsMode: EnumDataGridAutoSizeColumnsMode.ALL_CELLS
      }
      const metaDataGridSlots = {
        columns: () => {
          return __gridColumns()
        }
      }
      return <k-data-grid {...gridProp} v-slots={metaDataGridSlots}></k-data-grid>
    }
  }
})

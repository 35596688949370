import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPartDocBomComparePropOptions = {
  ...BaseViewPropOptions,
  /**
   * 左侧零部件
   */
  leftPart: VuePropTypes.createType<Record<string, any>>().def({}),
  /**
   * 右侧零部件
   */
  rightPart: VuePropTypes.createType<Record<string, any>>().def({}),
  /**
   * 比较规则是否应用到根
   */
  applyToRoot: VuePropTypes.createType<boolean>().def(false)
}

/** 参数类型 **/
export type KPartDocBomComparePropType = ViewPropsTypeExtract<typeof KPartDocBomComparePropOptions>

/** 事件 */
export const KPartDocBomCompareEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartDocBomCompareEmitsType = ViewEmitsTypeExtract<typeof KPartDocBomCompareEventEmits>

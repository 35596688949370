import { connect, withInstall } from '@kmsoft/upf-core'
import KClassTreeDemoView from './KClassTreeDemo.vue'
import KClassTreeDemoViewModel from './KClassTreeDemoViewModel'

const KClassTreeDemo = connect(KClassTreeDemoView, KClassTreeDemoViewModel)

export default withInstall(KClassTreeDemo)
export { KClassTreeDemo, KClassTreeDemoView, KClassTreeDemoViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "k-view-config" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KViewPropertyConfigPanel = _resolveComponent("KViewPropertyConfigPanel")!
  const _component_k_tab_pane = _resolveComponent("k-tab-pane")!
  const _component_KViewEventConfig = _resolveComponent("KViewEventConfig")!
  const _component_k_tabs = _resolveComponent("k-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_tabs, {
      activeKey: _ctx.$vm.activeTabKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.activeTabKey) = $event)),
      onChange: _ctx.onTabChange
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_component_k_tab_pane, {
          key: _ctx.EnumViewConfigTabKey.PROPERTY,
          tab: "属性"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _withDirectives(_createVNode(_component_KViewPropertyConfigPanel, {
                ref: _ctx.$vm.refViewPropertyConfig,
                control: _ctx.control,
                element: _ctx.element,
                attributeValueItem: _ctx.$vm.attributeValueItem,
                designerMetaControl: _ctx.designerMetaControl,
                dataSource: _ctx.dataSource,
                onElementPropertyChange: _ctx.onElementPropertyChange
              }, null, 8, ["control", "element", "attributeValueItem", "designerMetaControl", "dataSource", "onElementPropertyChange"]), [
                [_vShow, _ctx.$vm.activeTabKey != _ctx.EnumViewConfigTabKey.EVENT]
              ])
            ])
          ]),
          _: 1
        })),
        (_ctx.control.events)
          ? (_openBlock(), _createBlock(_component_k_tab_pane, {
              key: _ctx.EnumViewConfigTabKey.EVENT,
              tab: "事件"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _withDirectives(_createVNode(_component_KViewEventConfig, {
                    controlEvents: _ctx.control.events,
                    eventValueItem: _ctx.element.events,
                    control: _ctx.element.control,
                    title: _ctx.element.title,
                    bizContext: _ctx.bizContext,
                    onElementPropertyChange: _ctx.onEventPropertyChange
                  }, null, 8, ["controlEvents", "eventValueItem", "control", "title", "bizContext", "onElementPropertyChange"]), [
                    [_vShow, _ctx.$vm.activeTabKey == _ctx.EnumViewConfigTabKey.EVENT]
                  ])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["activeKey", "onChange"])
  ]))
}
import { connect, withInstall } from '@kmsoft/upf-core'
import KIssueReportPartStruView from './KIssueReportPartStru.vue'
import KIssueReportPartStruViewModel from './KIssueReportPartStruViewModel'

const KIssueReportPartStru = connect(KIssueReportPartStruView, KIssueReportPartStruViewModel)

export default withInstall(KIssueReportPartStru)
export { KIssueReportPartStru, KIssueReportPartStruView, KIssueReportPartStruViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KnavigationStyleTestEmitsType, KnavigationStyleTestPropType } from './interface'

/** KnavigationStyleTest */
export default class KnavigationStyleTestViewModel extends BaseViewModel<
  KnavigationStyleTestEmitsType,
  KnavigationStyleTestPropType
> {
  constructor(options: ViewModelOptions<KnavigationStyleTestPropType>) {
    super(options)
  }

  viewDidMount() {}
}

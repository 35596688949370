import { createVNode, resolveComponent } from 'vue'
import './style/index.less'
import {
  EnumConditionValueType,
  EnumControlElementType,
  EnumFilterOperator,
  EnumSortDirection,
  KCondition,
  KDateTime,
  KInput,
  KInputNumber,
  KSelect,
  KSort,
  defineView
} from '@kmsoft/upf-core'
import { KDesignerControl } from '../../../../../../types'
import { KFilterGirdFilterItemConfigFormViewModel } from './KFilterGirdFilterItemConfigFormViewModel'
import { KFilterGirdFilterItemConfigFormEventEmits, KFilterGirdFilterItemConfigFormPropOptions } from './interface'

export default defineView({
  inheritAttrs: false,
  name: 'KFilterGirdFilterItemConfigForm',
  viewModel: KFilterGirdFilterItemConfigFormViewModel,
  components: {},
  props: KFilterGirdFilterItemConfigFormPropOptions,
  emits: KFilterGirdFilterItemConfigFormEventEmits,
  setup(props, { vm }) {
    const formLayout = {
      formLabelCol: { span: 4 },
      formWrapperCol: { span: 18 }
    }
    const operationOptions = [
      { label: '等于', value: EnumFilterOperator.EQUAL },
      { label: '不等于', value: EnumFilterOperator.NOT_EQUAL },
      { label: '大于', value: EnumFilterOperator.GREAT_THEN },
      { label: '大于等于', value: EnumFilterOperator.GREAT_THEN_OR_EQUAL },
      { label: '小于', value: EnumFilterOperator.LESS_THAN },
      { label: '小于等于', value: EnumFilterOperator.LESS_THAN_OR_EQUAL },
      { label: '包含', value: EnumFilterOperator.INCLUDE },
      { label: '不包含', value: EnumFilterOperator.NOT_INCLUDE },
      { label: '介于', value: EnumFilterOperator.BETWEEN }
    ]
    const sortOptions = [
      { label: '升序', value: 'ASC' },
      { label: '降序', value: 'DESC' }
    ]
    const rules = {
      name: [{ required: true, message: '名称不能为空', trigger: 'blur' }]
    }

    /**
     * 新增筛选条件
     */
    const addConditionItem = () => {
      vm.model.value!.conditions.push({
        key: '',
        title: '',
        operator: EnumFilterOperator.EQUAL,
        value: [],
        valueType: EnumConditionValueType.RAW
      })
    }

    /**
     * 新增排序条件
     */
    const addSortItem = () => {
      vm.model.value!.sorts.push({ name: '', direction: EnumSortDirection.ASC })
    }

    /**
     * 删除查询条件
     */
    const deleteConditionItem = (index: number) => {
      if (vm.model.value!.conditions.length == 1) return false
      vm.model.value!.conditions.splice(index, 1)
    }

    /**
     * 删除排序条件
     */
    const deleteSortItem = (index: number) => {
      if (vm.model.value!.sorts.length == 1) return false
      vm.model.value!.sorts.splice(index, 1)
    }

    /**
     * 切换表达式编辑框
     */
    const showExpress = (index: number) => {
      let valueType = vm.model.value!.conditions[index].valueType
      switch (valueType) {
        case EnumConditionValueType.RAW:
          vm.model.value!.conditions[index].valueType = EnumConditionValueType.Expression
          break
        case EnumConditionValueType.Expression:
          vm.model.value!.conditions[index].valueType = EnumConditionValueType.RAW
          break
        default:
          vm.model.value!.conditions[index].valueType = EnumConditionValueType.RAW
          break
      }
    }
    /**
     * 操作符Change事件
     */
    const operationChange = (item: KCondition, index: number) => {
      if (item.operator === EnumFilterOperator.BETWEEN) {
        vm.model.value!.conditions[index].value = ['', '']
      } else {
        vm.model.value!.conditions[index].value.splice(1, 1)
      }
    }
    /**
     * 属性选中事件
     * @param name 名称
     * @param index 索引
     */
    const onSelectChange = (name: string, index: number) => {
      let column = props.columns?.find(item => item.name === name)
      vm.model.value!.conditions[index].title = column!.title
      vm.model.value!.conditions[index].operator = __getOperationOptions(name)[0].value as EnumFilterOperator
    }

    /**
     * 根据编辑方式获取值编辑器
     */
    const __renderValueEditor = (item: KCondition, index: number, valueIndex: any) => {
      let refValue = valueIndex === 0 ? `refEditor_${item.key}_0_${index}` : `refEditor_${item.key}_1_${index}`

      if (item && item.key !== '') {
        /**元数据 */
        const column = props.columns?.find(x => x.name === item.key) as KDesignerControl
        /**编辑方式 */
        const control = column.control
        /**操作符 */
        const operation = item.operator
        /**值类型 */
        const valueType = item.valueType
        /**当值类型为表达式时，渲染输入框 */
        if (valueType === EnumConditionValueType.Expression) {
          return createVNode(KInput, { ref: refValue })
        } else {
          switch (control) {
            case EnumControlElementType.CUSTOM: {
              const columnVNode = props.filterEditorResolver!(column)
              return createVNode(columnVNode, { ...{ ref: refValue } })
            }
            case EnumControlElementType.INPUT:
            case EnumControlElementType.TEXT_AREA:
              return createVNode(KInput, { ref: refValue })
            case EnumControlElementType.DATE_TIME:
              if (operation !== EnumFilterOperator.BETWEEN) {
                return createVNode(KDateTime, {
                  ref: refValue,
                  format: 'YYYY-MM-DD',
                  isShowTime: false,
                  style: 'width:184px'
                })
              } else {
                return createVNode(KDateTime, {
                  ref: refValue,
                  format: 'YYYY-MM-DD',
                  isShowTime: false,
                  style: 'width:184px'
                })
              }
            case EnumControlElementType.COMBOBOX: {
              let maxTagRang = { maxTagCount: 1, maxTagTextLength: 3 }
              const options = column.attrs.find(a => a.name == 'options')?.options || []
              return createVNode(KSelect, {
                options: options,
                ref: refValue,
                range: maxTagRang,
                style: 'width:184px;height: 23px;position: relative;bottom: 4px;',
                isMultiSelect: true
              })
            }
            case EnumControlElementType.CHECKBOX: {
              const options = [
                { id: '1', label: '是', value: '1', orderId: 1 },
                { id: '2', label: '否', value: '0', orderId: 2 }
              ]
              return createVNode(KSelect, {
                options: options,
                ref: refValue,
                style: 'width:184px;height: 23px;position: relative;bottom: 4px;'
              })
            }
            case EnumControlElementType.NUMBER: {
              return createVNode(KInputNumber, { ref: refValue })
            }
            default:
              return createVNode(KInput, { ref: refValue })
          }
        }
      } else {
        return createVNode(resolveComponent('KInput'), { ref: refValue })
      }
    }
    /**
     * 根据编辑方式获取操作符
     * @param key 属性唯一标识
     */
    const __getOperationOptions = (key: string) => {
      if (key && key !== '') {
        const column = props.columns?.find(x => x.name === key) as KDesignerControl
        const control = column.control
        switch (control) {
          case EnumControlElementType.INPUT:
          case EnumControlElementType.TEXT_AREA: {
            const operation = [EnumFilterOperator.EQUAL, EnumFilterOperator.INCLUDE]
            return __setOperationOptions(operation)
          }
          case EnumControlElementType.DATE_TIME: {
            const operation = [
              EnumFilterOperator.LESS_THAN,
              EnumFilterOperator.LESS_THAN_OR_EQUAL,
              EnumFilterOperator.EQUAL,
              EnumFilterOperator.GREAT_THEN_OR_EQUAL,
              EnumFilterOperator.GREAT_THEN,
              EnumFilterOperator.BETWEEN
            ]
            return __setOperationOptions(operation)
          }
          case EnumControlElementType.COMBOBOX: {
            const operation = [EnumFilterOperator.INCLUDE]
            return __setOperationOptions(operation)
          }
          case EnumControlElementType.CHECKBOX: {
            const operation = [EnumFilterOperator.EQUAL]
            return __setOperationOptions(operation)
          }
          case EnumControlElementType.NUMBER: {
            const operation = [
              EnumFilterOperator.LESS_THAN,
              EnumFilterOperator.LESS_THAN_OR_EQUAL,
              EnumFilterOperator.EQUAL,
              EnumFilterOperator.GREAT_THEN_OR_EQUAL,
              EnumFilterOperator.GREAT_THEN,
              EnumFilterOperator.BETWEEN
            ]
            return __setOperationOptions(operation)
          }
          case EnumControlElementType.CUSTOM:
            return __setOperationOptions(props.operationResolver!(column))
          default:
            return operationOptions
        }
      } else {
        return operationOptions
      }
    }
    /**
     * 构建操作符候选项
     * @param operation 操作符
     */
    const __setOperationOptions = (operation: Array<EnumFilterOperator>) => {
      return operationOptions.filter(x => operation.indexOf(x.value) !== -1)
    }
    /**
     * 渲染排序条件
     */
    const __renderSort = (item: KSort, index: number) => {
      const options = props.columns.map(c => ({ label: c.title, value: c.name }))
      return (
        <div key={index} style="display:flex">
          <k-form-item name={['sorts', index, 'name']} rules={{ required: true, message: '排序条件不能为空', trigger: 'change' }}>
            <k-select
              style="width:300px;margin-right:5px"
              {...{
                value: item.name,
                'onUpdate:value': (value: string) => (item.name = value)
              }}
              options={options}
            ></k-select>
          </k-form-item>
          <k-form-item>
            <k-select
              style="width:200px;margin-right:5px"
              {...{
                value: item.direction,
                'onUpdate:value': (value: EnumSortDirection) => (item.direction = value)
              }}
              options={sortOptions}
            ></k-select>
          </k-form-item>
          <k-form-item>
            <k-button
              style="margin-left:5px"
              type="default"
              {...{
                onClick: () => addSortItem()
              }}
            >
              +
            </k-button>
          </k-form-item>
          <k-form-item>
            <k-button
              type="default"
              {...{
                onClick: () => deleteSortItem(index)
              }}
              style="margin-right:0px"
            >
              -
            </k-button>
          </k-form-item>
        </div>
      )
    }

    /**
     * 渲染筛选条件
     * @param item 筛选条件
     * @param index 索引
     */
    const __renderFilter = (item: KCondition, index: number) => {
      let secondEditorVisible = false
      if (
        item.operator === 'between'
        // &&
        // (__getMetadataEditType(item) !== EnumControl.DATETIME || item.valueType === EnumConditionValueType.Expression)
      ) {
        secondEditorVisible = true
      }
      return (
        <div key={index} style="display:flex;">
          <k-form-item
            name={['conditions', index, 'key']}
            rules={{ required: true, message: '查询条件不能为空', trigger: 'change' }}
          >
            {__renderMetadataOption(item, index)}
          </k-form-item>
          <k-form-item>{__renderOperators(item, index)}</k-form-item>
          <k-form-item>
            <div style="min-width:233px;display:flex">
              <k-form-item key="a" class="form-item">
                {__renderValueEditor(item, index, 0)}
              </k-form-item>
              <k-form-item class="form-item-date" v-show={secondEditorVisible}>
                {__renderValueEditor(item, index, 1)}
              </k-form-item>
            </div>
          </k-form-item>
          <k-form-item>
            <k-button
              type="default"
              {...{ onClick: () => showExpress(index) }}
              style={`margin-left:5px;background:${
                item.valueType === EnumConditionValueType.Expression ? 'rgb(190, 200, 200)' : ''
              }`}
            >
              <k-icon type="express"></k-icon>
            </k-button>
          </k-form-item>
          <k-form-item>
            <k-button type="default" {...{ onClick: () => addConditionItem() }}>
              +
            </k-button>
          </k-form-item>
          <k-form-item>
            <k-button
              type="default"
              {...{ onClick: () => deleteConditionItem(index) }}
              style="padding-right:12px!important;margin-right:0px"
            >
              -
            </k-button>
          </k-form-item>
        </div>
      )
    }

    /**
     * 渲染元数据下拉框
     */
    const __renderMetadataOption = (item: KCondition, index: number) => {
      const options = props.columns.map(c => ({ label: c.title, value: c.name }))
      return (
        <k-select
          {...{
            value: item.key,
            'onUpdate:value': (value: string) => (item.key = value),
            onChange: () => onSelectChange(item.key, index)
          }}
          style="width:160px"
          options={options}
        ></k-select>
      )
    }

    /**
     * 渲染操作符下拉框
     */
    const __renderOperators = (item: KCondition, index: number) => {
      return (
        <k-select
          {...{
            value: item.operator,
            onChange: () => operationChange(item, index)
          }}
          options={__getOperationOptions(item.key)}
          style="width:120px;margin:0 5px"
        ></k-select>
      )
    }

    return () => {
      return (
        <k-form
          ref="refForm"
          rules={rules}
          model={vm.model.value}
          label-col={formLayout.formLabelCol}
          wrapper-col={formLayout.formWrapperCol}
        >
          <k-form-item label="名称" name="name" required={true}>
            <k-input
              {...{ value: vm.model.value!.name, 'onUpdate:value': (value: string) => (vm.model.value!.name = value) }}
              placeholder="请填写名称"
              style="margin-right:12px"
            />
          </k-form-item>
          <k-form-item label="查询条件" required={true}>
            {vm.model.value!.conditions.map((x, index) => __renderFilter(x, index))}
          </k-form-item>
          <k-form-item label="排序条件" name="sorts" required={true}>
            {vm.model.value!.sorts.map((x, index) => __renderSort(x, index))}
          </k-form-item>
        </k-form>
      )
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KModuleFormPropOptions } from './interface'
import KModuleFormViewModel from './KModuleFormViewModel'

export default defineView({
  name: 'ModuleForm',
  props: KModuleFormPropOptions,
  viewModel: KModuleFormViewModel,
  setup() {
    return {
      rules: {
        code: { required: true, message: '编码不能为空', trigger: 'blur' },
        name: { required: true, message: '名称不能为空', trigger: 'blur' }
      }
    }
  },
  mounted() {}
})

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KFlowHistorySigningPropOptions = {
  ...BaseViewPropOptions,
  /** 默认选中第一条 **/
  selectFirstRow: VuePropTypes.bool().def(false),
  signingHistoryList: VuePropTypes.array<any[]>().def([])
}

/** 参数类型 **/
export type KFlowHistorySigningPropType = ViewPropsTypeExtract<typeof KFlowHistorySigningPropOptions>

/** 事件 */
export const KFlowHistorySigningEventEmits = {
  ...BaseViewEventEmits,
  rowChanged: (row: any) => true
}

/** 事件类型 **/
export type KFlowHistorySigningEmitsType = ViewEmitsTypeExtract<typeof KFlowHistorySigningEventEmits>

import { VNodeProps, defineView, exposeComponentEvents } from '@kmsoft/upf-core'
import { KDocObjectPropertyEditPanelPropOptions, KDocObjectPropertyEditPanelEventEmits } from './interface'
import KDocObjectPropertyEditPanelViewModel from './KDocObjectPropertyEditPanelViewModel'
import {
  KObjectPropertyEditPanelBase,
  KObjectPropertyEditPanelBaseEmitsType,
  KObjectPropertyEditPanelBaseEventEmits,
  KObjectPropertyEditPanelBasePropType
} from '@kmsoft/ebf-common'
import { Transition, createVNode } from 'vue'

export default defineView({
  name: 'KDocObjectPropertyEditPanel',
  props: KDocObjectPropertyEditPanelPropOptions,
  emits: KDocObjectPropertyEditPanelEventEmits,
  viewModel: KDocObjectPropertyEditPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /** 继承事件 */
    const exposedEvents = exposeComponentEvents(
      vm.refObjectPropertyEditPanelBase,
      Object.keys(KObjectPropertyEditPanelBaseEventEmits),
      emit
    )

    return () => {
      /** 渲染属性面板 */
      const renderObjectPropertyEditPanel = () => {
        /** 面板参数 */
        const panelProps: VNodeProps<KObjectPropertyEditPanelBasePropType, KObjectPropertyEditPanelBaseEmitsType> = {
          ref: vm.refObjectPropertyEditPanelBase,
          ...props,
          ...attrs,
          ...exposedEvents
        }

        return createVNode(KObjectPropertyEditPanelBase, panelProps)
      }

      /** 渲染预览信息 */
      const renderPreview = () => {
        return <k-doc-object-preview objParam={props.objParam} />
      }

      /** 渲染文件列表 */
      const renderFileList = () => {
        return (
          <k-split-pane size={30}>
            <k-doc-file-list objParam={props.objParam}></k-doc-file-list>
          </k-split-pane>
        )
      }

      return (
        <k-split-container class="k-doc-object-property-edit-panel" horizontal>
          <k-split-pane>
            <div class="k-object-property-edit-panel">
              {renderObjectPropertyEditPanel()}
              {renderPreview()}
            </div>
          </k-split-pane>
          {renderFileList()}
        </k-split-container>
      )
    }
  }
})

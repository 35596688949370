import { connect, withInstall } from '@kmsoft/upf-core'
import KCustomerNeedsEditPanelView from './KCustomerNeedsEditPanel.vue'
import KCustomerNeedsEditPanelViewModel from './KCustomerNeedsEditPanelViewModel'

const KCustomerNeedsEditPanel = connect(KCustomerNeedsEditPanelView, KCustomerNeedsEditPanelViewModel)

export default withInstall(KCustomerNeedsEditPanel)
export { KCustomerNeedsEditPanel, KCustomerNeedsEditPanelView, KCustomerNeedsEditPanelViewModel }

// 模板生成文件
// export * from './{{folderName}}'

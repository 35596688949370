import { BaseViewModel, KSelectViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KLifecycleStateEditorEmitsType, KLifecycleStateEditorPropType } from './interface'
import { ref, watch } from 'vue'
import { EnumLifecycleState } from '../../../../index'

/** KLifecycleStateEditor */
export default class KLifecycleStateEditorViewModel extends BaseViewModel<
  KLifecycleStateEditorEmitsType,
  KLifecycleStateEditorPropType
> {
  refSelect = ref<KSelectViewModel>()
  stateValue = ref<string>()
  originValue = ref<string>()
  stateReadonly = ref<boolean>(false)
  constructor(options: ViewModelOptions<KLifecycleStateEditorPropType>) {
    super(options)
    watch(
      () => options.props.value,
      () => {
        this.stateValue.value = options.props.value
      },
      {
        deep: true,
        immediate: true
      }
    )
    watch(
      () => options.props.readonly,
      newValue => {
        this.stateReadonly.value = newValue
      },
      {
        deep: true,
        immediate: true
      }
    )
  }

  viewDidMount() {}
  getModifiedValue() {
    return this.stateValue.value
  }
  getValue() {
    return this.stateValue.value
  }
  setValue(value: string, setChange?: boolean) {
    this.stateValue.value = value
    if (setChange) {
      this.originValue.value = value
    }
  }
  isModified() {
    return this.originValue.value !== this.stateValue.value
  }
  public displayValue() {
    const options = EnumLifecycleState._list
    const value = this.stateValue.value || this.props.value
    const displayValue = options.find((item: { value: any }) => item.value === value)?.label
    return displayValue
  }
}

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDistributionCreateEmitsType, KDistributionCreatePropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api, EnumWorkingState } from '@kmsoft/ebf-common'
import lodash from 'lodash'

/** KDistributionCreate */
export default class KDistributionCreateViewModel extends BaseViewModel<
  KDistributionCreateEmitsType,
  KDistributionCreatePropType
> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  formData = ref<any>({
    number: '',
    name: '',
    description: '',
    startTime: null,
    endTime: null,
    secondaryIds: null,
    folder: {
      id: ''
    },
    distributionContentLinkViews: [],
    distributionRecordViews: []
  })

  constructor(options: ViewModelOptions<KDistributionCreatePropType>) {
    super(options)
  }

  viewDidMount() {
    // 文件夹赋值
    if (this.props.formValue?.folder?.id) {
      this.formData.value.folder.id = this.props.formValue.folder?.id
    }

    //发放单内容赋值
    if (this.props.changeBeforeData) {
      this.formData.value.distributionContentLinkViews = lodash.cloneDeep(this.props.changeBeforeData)
    }

    //发放单记录赋值
    if (this.props.changeAfterData) {
      this.formData.value.distributionRecordViews = lodash.cloneDeep(this.props.changeAfterData)
    }
  }

  getValue() {
    const createObject = lodash.cloneDeep(this.refObjectCreatePanel.value?.getValue())
    return createObject
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  async save() {
    const createObject = this.getValue()
    //发放单内容对象
    createObject!.distributionContentLinkViews = (createObject!.distributionContentLinkViews as any[]).map(item => {
      return {
        target: {
          id: item.id,
          rdmExtensionType: item.rdmExtensionType
        }
      }
    })
    //发放单记录对象
    createObject!.distributionRecordViews = (createObject!.distributionRecordViews as any[]).map(item => lodash.omit(item, 'id'))

    const params = {
      data: [
        {
          ...createObject
        }
      ]
    }

    return await Api.post('doc', 'Distribution', 'create', params)
  }
}

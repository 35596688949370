
import { defineView } from '@kmsoft/upf-core'
import { KDocBatchCreatePropOptions, KDocBatchCreateEventEmits } from './interface'
import KDocBatchCreateViewModel from './KDocBatchCreateViewModel'

export default defineView({
  name: 'KDocBatchCreate',
  props: KDocBatchCreatePropOptions,
  emits: KDocBatchCreateEventEmits,
  viewModel: KDocBatchCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

/**
 * 数据类型
 * @description 数据类型
 */
export enum EnumDataType {
  /**未知 */
  UNKNOWN = -1,
  /** 整型 */
  INT = 10,
  /** 数值 */
  FLOAT = 20,
  /** 长整型 */
  LONG = 30,
  /** 日期 */
  DATE = 40,
  /** 字符 */
  CHAR = 50,
  /** 图片 */
  IMAGE = 60,
  /** 大文本 */
  STRING = 70,
  /** 对象 */
  OBJECT = 80,
  /** 分类 */
  TYPE = 90,
  /** 枚举 */
  ENUM = 100,
  /** 人员 */
  USER = 110
}

/** 编辑类型 */
export enum EnumPropertyEditorType {
  /** 未知 */
  UNKNOWN = 0,
  /** 单行编辑器 */
  SINGLE = 1,
  /** 多行编辑器 */
  MULTI = 2,
  /** 日期 */
  DATETIME = 3,
  /** 图片 */
  PICTURE = 4,
  /** 下拉列表 */
  DROPDOWN_LIST = 5,
  /** 可编辑下拉列表 */
  DROPDOWN_LIST_EDITABLE = 6,
  /** 带显示下拉列表 */
  DROPDOWN_LIST_WITH_DISPLAY = 7,
  /** 列表视图 */
  LIST_VIEW = 8,
  /** 单选列表视图 */
  LIST_VIEW_SINGLE_CHECK = 9,
  /** 资源管理器 */
  RESOURCE_MANAGER = 10,
  /** 编码器 */
  ENCODER = 11,
  /** 选择填写 */
  SELECT_WRITE = 12,
  /** 复选框 */
  CHECKBOX = 13,
  /** 刻度条 */
  SCALE_BAR = 14,
  /** 超链接 */
  HYPERLINK = 15,
  /** 自定义弹出对话框 */
  CUSTOM_MODAL = 16,
  /** 自定义控件 */
  CUSTOM_CONTROL = 17,
  /** 自动计算 */
  AUTO_COMPUTE = 18,
  /** 公式计算 */
  FORMULA_COMPUTE = 19,
  /** 富文本 */
  RICH_TEXT = 20,
  /** 对象链接 */
  OBJECT_LINK = 21,
  /** 分类链接 */
  CATEGORY_LINK = 22,
  /** 下拉对象选择器 */
  OBJECT_DROPDOWN = 23,
  /** 弹窗对象选择器 */
  OBJECT_MODAL = 24,
  /**人员选择器 */
  USER_EDITOR = 25
}

/** 布局方案类型 */
export enum EnumLayoutSchemaType {
  FormNew = 'new',
  FormEdit = 'edit',
  Grid = 'grid',
  FormSaveAs = 'saveAs',
  FormRevise = 'revise'
}

/**
 * 对象类的节点类型 分为两大类：目录和对象类
 */
export enum EnumObjClassTreeNodeType {
  //#region 对象类
  /**对象大类 */
  RootObjClass = 3,
  /**对象子类 */
  SubObjClass = 4
  //#endregion
}

/** 文件模式 */
export enum EnumDocFileMode {
  /** 单文件 */
  SINGLE_FILE = 10,
  /** 多文件 */
  MULTI_FILES = 20
}

/** 对象类模板 */
export enum EnumClassTemplate {
  /** 零部件对象类 */
  PART = 'Part',
  /** 文档对象类 */
  DOC = 'Document',
  /** 组织对象 */
  ORG = 'ORG',
  /** 人员对象类 */
  USER = 'USER',
  /** 普通数据对象类 */
  COMMONOBJ = 'COMMONOBJ',
  /** 工作流对象类*/
  FLOWOBJ = 'FLOWOBJ',
  /** 文档步骤对象类 */
  FLOWSTEPOBJ = 'FLOWSTEPOBJ',
  /** 项目对象类 */
  PROJECT = 'PROJECT',
  /** BOM视图 对象模板 */
  BOMVIEW = 'BOMVIEW',
  /** 基线 */
  BASELINE = 'BASELINE',
  /** BOM节点的虚拟模板模板 */
  BOMNODE = 'BOMNODE',
  /** 工作流步骤任务附件的对象类 */
  FLOWDOCSUGGESTION = 'FLOWDOCSUGGESTION',
  /** 公共基础数据模板（对应唯一的虚拟对象模板为COMMONDATA） */
  COMMONDATA = 'COMMONDATA',
  /** 虚拟所有数据模板，勇于所有数据对象要求在一起显示的场合 */
  ALLOBJTYPE = 'ALLOBJTYPE',
  /** PARTSTRUCTURE 零部件结构的虚拟对象模板和对象类 */
  PARTSTRUCTURE = 'PARTSTRUCTURE',
  /** 问题报告详情对象类 **/
  CHANGE_ISSUE = 'ChangeIssue',
  /** 变更请求详情对象类 **/
  CHANGE_REQUEST = 'ChangeRequest',
  /** 原始需求 */
  CUSTOMER_NEEDS = 'CustomerNeeds',
  /** 产品需求 */
  PRODUCT_NEEDS = 'ProductNeeds'
}

/** 默认对象类 */
export enum EnumDefaultClassCode {
  /** 混合对象类 */
  SYS_COMMON_PROP_LIST = 'SYS_COMMON_PROP_LIST'
}

/**模型分类枚举 */
export enum EnumModelCategoryType {
  /** 系统数据模型 */
  SYSTEM_MODEL = 10,
  /** 业务数据模型 */
  BUSINESS_MODEL = 20
}

/**实体类型 */
export enum EnumEntityClass {
  ENTITY = 'ENTITY',
  SOFT_ENTITY = 'SOFTENTITY'
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_user_selector = _resolveComponent("k-user-selector")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    ref: "refFolderForm",
    model: _ctx.$vm.formDataState,
    "label-col": { span: 8 },
    "wrapper-col": { span: 16 },
    autocomplete: "off"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, {
        label: "所属人",
        name: "owner",
        "has-feedback": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_user_selector, {
            isMultipleSelection: false,
            value: _ctx.$vm.formDataState.userId,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formDataState.userId) = $event)),
            disabled: false
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import {
  EnumPartSource,
  IObjectReferenceDefinition,
  IPartDefinition,
  IPartTypeDefinition,
  KPartSaveAsFormEmitsType,
  KPartSaveAsFormPropType
} from './interface'
import { ref } from 'vue'
import { KObjectCreatePanelViewModel } from '@kmsoft/ebf-common'
import { KAttrsCategoryRenderViewModel } from '../../../controls/attrs-category-render'
import { KCategoryRenderViewModel } from '../../../controls/category-render'
import { KCategorySelectorViewModel } from '../../../controls/category-selector'

/** KPartForm */
export default class KPartFormViewModel extends BaseViewModel<KPartSaveAsFormEmitsType, KPartSaveAsFormPropType> {
  classCode = ref<string>()
  constructor(options: ViewModelOptions<KPartSaveAsFormPropType>) {
    super(options)
  }

  viewDidMount() {
    // 获取外部传值
    this.formData.value = this.props.formValue as IPartDefinition
  }

  // 表单响应式数据
  formData = ref<IPartDefinition>({
    id: '',
    rdmExtensionType: '',
    number: '',
    name: '',
    defaultUnit: '',
    source: EnumPartSource.Make,
    partView: '',
    master: { id: '', clazz: '' } as IObjectReferenceDefinition,
    branch: { id: '', clazz: '' } as IObjectReferenceDefinition,
    folder: { id: '', clazz: '' } as IObjectReferenceDefinition
  })

  /** 校验规则 */
  rules = {
    number: [{ required: true, message: '编码不能为空', trigger: 'change' }],
    name: [{ required: true, message: '名称不能为空', trigger: 'change' }]
  }

  // 通用布局
  refObjectCreatePanel = ref<KObjectCreatePanelViewModel>()

  // 零部件扩展类型下拉选择框
  partTypeDefinitionOption = ref<Array<IPartTypeDefinition>>()

  public save(value: IPartDefinition) {
    return this.formData.value
  }

  /**
   * 设置数据源
   * @param value 表单的值
   * @returns
   */
  public setValue(value: any) {
    this.formData.value = { ...value, extAttrsList: value?.extAttrs }
  }

  /**
   * 获取表单的值
   * @returns 表单的值
   */
  public getValue() {
    let plmPartCreateObject = this.refObjectCreatePanel.value?.getValue() as any
    if (plmPartCreateObject?.extAttrsList?.length) {
      plmPartCreateObject.extAttrs = plmPartCreateObject.extAttrs
        ? plmPartCreateObject.extAttrs.concat(plmPartCreateObject?.extAttrsList)
        : plmPartCreateObject?.extAttrsList
    }
    delete plmPartCreateObject.extAttrsList
    // 零部件类型
    plmPartCreateObject = { ...plmPartCreateObject, rdmExtensionType: plmPartCreateObject.classCode } as any

    return plmPartCreateObject
  }

  /**
   * 获取表单的修改值
   * @returns 表单的值
   */
  public getModifiedValue() {
    return this.refObjectCreatePanel.value?.getModifiedValue()
  }

  /** 校验 */
  async validate() {
    try {
      return true
    } catch (error) {
      return false
    }
  }

  panelLoaded() {
    // 扩展属性显示
    const attrsCatetoryRenderViewModel = this.getByRecursion('KAttrsCategoryRender#create') as KAttrsCategoryRenderViewModel
    attrsCatetoryRenderViewModel?.initDraw('Part')

    // 分类属性显示
    const categorySelectVM = this.getByRecursion('KCategorySelector#create') as KCategorySelectorViewModel
    const catetoryRenderViewModel = this.getByRecursion('KCategoryRender#create') as KCategoryRenderViewModel
    categorySelectVM?.on('update:value', (args: any) => {
      catetoryRenderViewModel?.initDraw(args)
    })

    // 分类select点击事件
    categorySelectVM?.on('select' as any, (changedEventArgs: any) => {
      const currentCatetoryId = changedEventArgs.getValue()
      catetoryRenderViewModel?.initDraw(currentCatetoryId?.value)
    })
    // 分类清除事件
    categorySelectVM?.on('clear' as any, () => {
      catetoryRenderViewModel?.clear()
    })
  }
}

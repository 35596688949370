import { connect, withInstall } from '@kmsoft/upf-core'
import KFolderFormView from './KFolderForm.vue'
import KFolderFormViewModel from './KFolderFormViewModel'

const KFolderForm = connect(KFolderFormView, KFolderFormViewModel)

export default withInstall(KFolderForm)
export { KFolderForm, KFolderFormView, KFolderFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, GlobalException, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import {
  KObjectSearcherEmitsType,
  KObjectSearcherPropType,
  EnumObjectSearcherTab,
  EnumSearchRegion,
  ParttreeSearchForm
} from './interface'
import { computed, nextTick, ref, watch } from 'vue'
import { ClassMetaClientSrv, MetaProperty, ObjectClientSrv, QueryCondition } from '../../client-srv'
import { CommonSearcherLifeStateForm, KCommonSearcherViewModel, KQuickSearcherViewModel } from './tabs'
import { KRuleDefinerViewModel } from '../rule-definer'
import { Api } from '../../../../ebf-common'
import { get } from 'lodash'

/** 对象搜索 */
export default class KObjectSearcherViewModel extends BaseViewModel<KObjectSearcherEmitsType, KObjectSearcherPropType> {
  /** 高级查询引用 */
  refKRuleDefine = ref<KRuleDefinerViewModel>()
  /** 通用查询引用 */
  refKCommonSearcher = ref<KCommonSearcherViewModel>()
  /** 快速查询引用 */
  refKQuickSearcher = ref<KQuickSearcherViewModel>()
  /** 分类ref */
  refCategory = ref<any>()
  /** 一般查询的表单 */
  refConfigForm = ref<any>()

  /** 固定对象类Id */
  freezeClassCode = computed(() => this.props.showObjClsCodes.length == 1)
  /** 类ID */
  modelCode = ref<string>()
  /** 分类下拉 */
  clsAttrs = ref<Array<Record<string, any>>>([])
  /** 类名 */
  className = ref<string | undefined>()
  /** 加载状态 */
  isLoading = ref<boolean>(false)
  /** 对象属性 */
  modelGroup = ref<string>()
  /** 是否显示通用查询页签 */
  isAllowCommonSearch = ref(false)
  /** 是否显示快速查询页 */
  isAllowQuickSearch = ref(false)
  /** 拓展对象属性列表 */
  fields = ref<Array<MetaProperty>>([])
  /** 标签页 */
  tabKey = ref<EnumObjectSearcherTab>(EnumObjectSearcherTab.COMMON_SEARCH)
  /** 包含子类 */
  isIncludeChildren = ref<boolean>(false)

  /** 通用搜索配置 */
  commonCondition = ref<{ condition: QueryCondition; lifeState: CommonSearcherLifeStateForm }>()
  /** 高级搜索配置 */
  advancedCondition = ref<QueryCondition>()
  /** 快速搜索配置 */
  quickSearchCondition = ref<QueryCondition>()
  /** 分类组件置灰 */
  isCategoryDisabled = ref<Boolean>(true)
  /** 分类绑定值 */
  configFormValue = ref<any>({})

  /** 表单配置 */
  groupList = ref<Array<Record<string, any>>>([
    {
      label: '基本属性',
      props: 'basic',
      children: [
        {
          label: '名称',
          props: 'name',
          name: 'commonSearchName',
          attrs: {
            placeholder: '请输入名称'
          }
        },
        {
          label: '编码',
          props: 'id',
          name: 'commonSearchId',
          attrs: {
            placeholder: '请输入编码'
          }
        }
      ]
    },
    {
      label: '通用属性',
      props: 'common',
      children: [
        {
          label: '创建时间',
          props: 'createTime',
          type: 'k-date-time',
          name: 'commonSearchCreateTimes',
          attrs: {
            type: 'range'
          }
        },
        {
          label: '修改时间',
          props: 'lastUpdateTime',
          type: 'k-date-time',
          name: 'commonSearchLastUpdateTimes',
          attrs: {
            type: 'range'
          }
        },
        {
          label: '生命周期状态',
          props: 'lifecycleState.nameEn',
          type: 'k-select',
          name: 'commonSearchPhase',
          attrs: {
            placeholder: '请选择生命周期状态',
            options: [
              {
                id: 'gEgz8yapVY',
                label: '工作中',
                value: 'InWork'
              },
              {
                id: 'k6vfbM4G0q',
                label: '审核中',
                value: 'UnderReview'
              },
              {
                id: '9eddexAjaD',
                label: '已发布',
                value: 'Released'
              },
              {
                id: '9eddexAjaa',
                label: '已废弃',
                value: 'OBSOLETED'
              }
            ]
          }
        },
        {
          label: '分类',
          props: 'extAttrs',
          type: 'k-category-selector',
          name: 'KCategorySelector#searchCommon'
        }
      ]
    }
  ])

  categoryAttrField: string

  constructor(options: ViewModelOptions<KObjectSearcherPropType>) {
    super(options)
    this.modelCode.value = options.props.modelCode

    watch(
      () => this.props.modelCode,
      newValue => {
        this.categoryAttrField = newValue == 'Part' ? 'Classification' : 'catAttr'
        this.modelCode.value = newValue
      }
    )

    watch(
      this.modelCode,
      (newValue, oldValue) => {
        this.emit('update:modelCode', newValue ?? '')
        if (newValue) {
          this.refKRuleDefine.value?.clear()
          this.refKCommonSearcher.value?.clear()
          this.refKQuickSearcher.value?.clear()
          this.fetchMetaData()
          // await setOption()
        }
      },
      {
        immediate: true
      }
    )

    this.isCategoryDisabled = computed(() => {
      return !this.modelCode.value
    })
  }

  async viewDidMount() {
    this.fetchMetaData()
    // await this.setOption()
  }

  /** 异步加载对象属性，对象拓展属性列表 */
  async fetchMetaData() {
    try {
      this.isLoading.value = true
      if (this.modelCode.value) {
        const metaClass = await ClassMetaClientSrv.getClassByClsCode(this.modelCode.value)
        this.className.value = metaClass?.name
        this.modelGroup.value = await ObjectClientSrv.getModelGroupByCode(this.modelCode.value!)
        this.fields.value = (await ClassMetaClientSrv.getClassProperties(this.modelCode.value)!) as Array<MetaProperty> //metaClass?.properties!
      }
    } catch (exception) {
      GlobalException.getException('获取属性列表时异常')
      this.modelCode.value = undefined
    } finally {
      this.isLoading.value = false
    }
  }

  /**
   * 生成高级查询表单
   */
  getCondition(): QueryCondition | undefined {
    switch (this.tabKey.value) {
      case EnumObjectSearcherTab.COMMON_SEARCH:
        return this.refConfigForm.value?.getValue()
      case EnumObjectSearcherTab.ADVANCED_SEARCH:
        return this.refKRuleDefine.value?.getCondition()
      case EnumObjectSearcherTab.QUICK_SEARCH:
        return this.refKQuickSearcher.value?.getCondition()
    }
  }

  //#region 事件
  /** 当搜索按下时，提交搜索事件 */
  async onSearch() {
    if (!this.modelCode.value) {
      KNotification.info({
        message: '请选择对象类！'
      })
    }
    const condition = (await this.getCondition()) as any
    const extAttrs = this.refCategory.value.getValue()
    // 分类属性单独数据处理
    if (extAttrs?.length && condition?.conditions?.length && get(extAttrs, '[0].value', '')) {
      condition.conditions.push({
        conditionName: `extAttrs.${this.categoryAttrField}`,
        operator: '=',
        conditionValues: [get(extAttrs, '[0].value', '')]
      })
    }
    // 类型单独处理
    if (this.modelCode.value) {
      condition.conditions.push({
        conditionName: 'rdmExtensionType',
        operator: '=',
        conditionValues: [this.modelCode.value]
      })
    }
    if (!condition || !condition.conditions || condition.conditions.length <= 0) {
      return
    }
    this.emit('search', condition)
  }

  /** 取消 */
  onCancel() {
    this.emit('cancel')
  }
  //#endregion

  /** 分类下拉选择 */
  async selectCategory(value: any) {
    const commonArr = {
      changeable: null,
      class: 'com.kmsoft.common.core.dme.dto.DataModelAttributeDTO',
      classCode: null,
      classId: null,
      defaultValue: null,
      enumInfo: null,
      id: null,
      index: null,
      length: 50,
      name: null,
      nullable: true,
      precision: 0,
      propArgs: null,
      readonly: true,
      referenceClassCode: null,
      scale: null,
      tenantId: '-1',
      type: 50,
      visible: null
    }
    const category = value.getValue()
    const res = (await Api.post('common', 'ClassificationNode', 'getCategoryNodeInfo', {
      data: [get(category, 'value', '')]
    })) as any
    const result = (get(res, 'data[0].attrs') || []).map((arr: any) => {
      return {
        code: get(arr, 'nameEn', ''),
        columnName: `clsAttrs.${this.categoryAttrField}.` + get(arr, 'nameEn', ''),
        displayName: get(arr, 'name', ''),
        ...commonArr
      }
    })
    this.clsAttrs.value = result
  }

  /** 清除下拉 */
  async clearCategory() {
    this.clsAttrs.value = []
  }

  /** 表单数据格式化 */
  configFormGetValue(value: any) {
    const result = [] as any
    Object.keys(value).forEach(async (key: any) => {
      if (value[key]) {
        // 创建时间、最后更新时间单独处理
        if (['createTime', 'lastUpdateTime'].includes(key)) {
          result.push(
            {
              conditionName: key,
              operator: '>=',
              conditionValues: [value[key][0]]
            },
            {
              conditionName: key,
              operator: '<=',
              conditionValues: [value[key][1]]
            }
          )
          return
        }
        let displayName = key
        const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(this.modelCode.value as any)
        if (['Document', 'Part'].includes(designerCode!) && key === 'name') {
          displayName = 'master.name'
        }
        result.push({
          conditionName: displayName,
          operator: '=',
          conditionValues: [value[key]]
        })
      }
    })
    return { conditions: result, joiner: 'and' }
  }
}

import { KNotification, MemoryCacheBase } from '@kmsoft/upf-core'
import lodash from 'lodash'
import { Api, EnumRequestCode } from '../../../..'
/** 对象类缓存 */
export class PartViewMemoryCache extends MemoryCacheBase<Array<Record<string, any>>> {
  public static cacheKey = 'PartViewMemoryCache'
  constructor() {
    super()
    this.cacheKey = PartViewMemoryCache.cacheKey
  }

  public async reload() {
    const res = await Api.post('part', 'PartView', 'listAll', {})
    if (res && res.code == EnumRequestCode.SUCCESS) {
      this.cacheData = res.data! as Array<Record<string, any>>
    } else {
      KNotification.error({
        title: '系统提示',
        content: '零部件视图获取失败',
        details: res.message
      })
      this.cacheData = []
    }
  }

  /** 获取所有数据 */
  public getAll() {
    return lodash.cloneDeep(this.cacheData)
  }
}

import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumClsDataType, EnumClsEditorType } from '../enums'
import { IClassTreeNode } from '../../../tree/interface'

/** 显示类型options */
export const displayPatternOptions = [
  { label: '常规', value: 0 },
  { label: '数值', value: 1 },
  { label: '百分比', value: 2 },
  { label: '科学', value: 3 }
]

export interface IClsPropsFormDefine {
  propertyCode?: string
  propertyName?: string
  displayName?: string
  defaultValue?: string
  propertyEvent?: string
  nullable?: number | boolean
  readonly?: number | boolean
  changeable?: number | boolean
  visible?: number | boolean
  predefined?: number | boolean
  dataType?: number
  editType?: EnumClsEditorType
  columnWidth?: string | number
  alignType?: number
  dataLength?: string | number
  displayPattern?: number
  propertyScale?: string | number
  remark?: string
  editArgs?: string
}

// 对象界面定义定义事件
export const KClassPropertyFormEventEmits = {
  ...BaseViewEventEmits
}

// 对象界面定义定义属性选项
export const KClassPropertyFormPropOptions = {
  ...BaseViewPropOptions,
  /** 当前编辑对象类的数据类型 */
  dataType: VuePropTypes.createType<EnumClsDataType>().def(),
  /** 当前form value */
  formState: VuePropTypes.createType<IClsPropsFormDefine>().def({}),
  /** 操作类型，更新 | 查看详情 */
  operationType: VuePropTypes.createType<'update' | 'detail'>().def('update'),
  /**文件访问 */
  fileAccess: VuePropTypes.bool().def(false),
  /** 属性编码*/
  propertyCode: VuePropTypes.string().def(),
  /** 选中节点 */
  selectedNode: VuePropTypes.createType<IClassTreeNode>()
    .setRequired()
    .def()
}

// emit type
export type KClassPropertyFormEventEmitsType = ViewEmitsTypeExtract<typeof KClassPropertyFormEventEmits>

// 对象界面定义定义属性类型
export type KClassPropertyFormPropType = ViewPropsTypeExtract<typeof KClassPropertyFormPropOptions>

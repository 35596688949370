
import { EnumDataGridRowModelType, defineView, KDataGridRowSelectedEvent } from '@kmsoft/upf-core'
import { KValidationMetricsManagePropOptions, KValidationMetricsManageEventEmits } from './interface'
import KValidationMetricsManageViewModel from './KValidationMetricsManageViewModel'
import { clsCodeMap, EnumValidationMetrics, EnumVerification } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KValidationMetricsManage',
  props: KValidationMetricsManagePropOptions,
  emits: KValidationMetricsManageEventEmits,
  viewModel: KValidationMetricsManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      clsCodeMap,
      EnumValidationMetrics,
      EnumVerification,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})

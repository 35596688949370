/**提供文件和目录的属性。*/
export enum FileAttributes {
  /**文件为只读文件。 ReadOnly 在 Windows、Linux 和 macOS 上受支持。 在 Linux 和 macOS 上，更改 ReadOnly 标记是权限操作。*/
  ReadOnly = 1,

  /**文件是隐藏的，因此没有包括在普通的目录列表中。 {@link Hidden} 在 Windows、Linux 和 macOS 上受支持。*/
  Hidden = 2,

  /**此文件是系统文件。 即，该文件是操作系统的一部分或者由操作系统以独占方式使用。*/
  System = 4,

  /**此文件是一个目录。 {@link Directory} 在 Windows、Linux 和 macOS 上受支持。*/
  Directory = 16,

  /**此文件标记为包含在增量备份操作中。 每当修改文件时，Windows 会设置该属性，并且在增量备份期间处理文件时，备份软件应进行清理该属性。*/
  Archive = 32,

  /**留待将来使用。*/
  Device = 64,

  /**该文件是没有特殊属性的标准文件。 仅当其单独使用时，此特性才有效。 {@link Normal} 在 Windows、Linux 和 macOS 上受支持。*/
  Normal = 128,

  /**文件是临时文件。 临时文件包含当执行应用程序时需要的，但当应用程序完成后不需要的数据。 文件系统尝试将所有数据保存在内存中，而不是将数据刷新回大容量存储，以便可以快速访问。 当临时文件不再需要时，应用程序应立即删除它。*/
  Temporary = 256,

  /**此文件是稀疏文件。 稀疏文件一般是数据通常为零的大文件。*/
  SparseFile = 512,

  /**文件包含一个重新分析点，它是一个与文件或目录关联的用户定义的数据块。 {@link ReparsePoint} 在 Windows、Linux 和 macOS 上受支持。*/
  ReparsePoint = 1024,

  /**此文件是压缩文件。*/
  Compressed = 2048,

  /**此文件处于脱机状态， 文件数据不能立即供使用。*/
  Offline = 4096,

  /**将不会通过操作系统的内容索引服务来索引此文件。*/
  NotContentIndexed = 8192,

  /**此文件或目录已加密。 对于文件来说，表示文件中的所有数据都是加密的。 对于目录来说，表示新创建的文件和目录在默认情况下是加密的。*/
  Encrypted = 16384,

  /**文件或目录包括完整性支持数据。 在此值适用于文件时，文件中的所有数据流具有完整性支持。 此值将应用于一个目录时，所有新文件和子目录在该目录中和默认情况下应包括完整性支持。*/
  IntegrityStream = 32768,

  /**文件或目录从完整性扫描数据中排除。 此值将应用于一个目录时，所有新文件和子目录在该目录中和默认情况下应不包括数据完整性。*/
  NoScrubData = 131072
}

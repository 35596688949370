import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

// 配置项定义
export interface IConfigGridComboboxDefinition {
  defId: string
  id: string
  code: string
  name: string
  values: Array<string>
  remark: string
  dataType: string
  editType: string
  editParam: string
}

// 页面事件
export const KConfigGridComboboxEventEmits = {
  ...BaseViewEventEmits
}

export const KConfigGridComboboxViewModelPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('')
}

export type KConfigGridComboboxViewModelPropType = ViewPropsTypeExtract<typeof KConfigGridComboboxViewModelPropOptions>

export type KConfigGridComboboxEventEmitsType = ViewEmitsTypeExtract<typeof KConfigGridComboboxEventEmits>

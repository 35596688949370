import { PurviewAdapterBase, PurviewServiceBase, VerifyObjClsPurviewParams } from '../../common'
import { PurviewAdapter } from './PurviewAdapter'

/** 权限服务实现类 */
export class PurviewService extends PurviewServiceBase {
  constructor() {
    super()
  }

  getAdapter<T extends PurviewAdapterBase>(): T {
    return new PurviewAdapter() as T
  }

  verifyObjClsPurview(params: VerifyObjClsPurviewParams) {
    // 重载后做一些事情
    if (this.userId == 'ADM') {
      return { isMatch: true, hasPurview: true }
    }
    return super.verifyObjClsPurview(params)
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KBaseEditorEventEmits, KBaseEditorPropOptions } from '../../base'

/** 参数 **/
export const KScriptEditorPropOptions = {
  ...KBaseEditorPropOptions
}

/** 参数类型 **/
export type KScriptEditorPropType = ViewPropsTypeExtract<typeof KScriptEditorPropOptions>

/** 事件 */
export const KScriptEditorEventEmits = {
  ...KBaseEditorEventEmits
}

/** 事件类型 **/
export type KScriptEditorEmitsType = ViewEmitsTypeExtract<typeof KScriptEditorEventEmits>

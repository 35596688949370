
import { defineView, ToolStripItemClickedEventArgs } from '@kmsoft/upf-core'
import { KPartDemoEventEmits, KPartDemoPropOptions } from './interface'
import KPartDemoViewModel from './KPartDemoViewModel'

export default defineView({
  name: 'KPartDemo',
  props: KPartDemoPropOptions,
  emits: KPartDemoEventEmits,
  viewModel: KPartDemoViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

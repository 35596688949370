import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { KObjectPropertyEditPanelBaseEventEmits, KObjectPropertyEditPanelBasePropOptions } from './controls'
import { ObjectLoadDataProvider } from '../object-property-panel'

/** 参数 **/
export const KObjectPropertyEditPanelPropOptions = {
  ...KObjectPropertyEditPanelBasePropOptions,
  /** 面板值加载 */
  load: VuePropTypes.createType<ObjectLoadDataProvider>().def()
}

/** 参数类型 **/
export type KObjectPropertyEditPanelPropType = ViewPropsTypeExtract<typeof KObjectPropertyEditPanelPropOptions>

/** 事件 */
export const KObjectPropertyEditPanelEventEmits = {
  ...KObjectPropertyEditPanelBaseEventEmits
}

/** 事件类型 **/
export type KObjectPropertyEditPanelEmitsType = ViewEmitsTypeExtract<typeof KObjectPropertyEditPanelEventEmits>

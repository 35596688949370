import { HtmlNode, HtmlNodeModel } from '@logicflow/core'
import { createApp, Slot } from 'vue'

class RectNode extends HtmlNode {
  static defaultSlot: Slot<any> | undefined
  setHtml(rootEl: HTMLElement) {
    const { properties } = this.props.model
    const node = document.createElement('div')
    node.className = 'html-node'
    const vueNode = createApp({
      render: () => RectNode.defaultSlot!({ data: properties })
    })
    vueNode.mount(node)
    rootEl.innerHTML = ''
    rootEl.appendChild(node)
  }
}

class RectNodeModel extends HtmlNodeModel {
  static width: number
  static height: number
  setAttributes() {
    this.width = RectNodeModel.width
    this.height = RectNodeModel.height
    this.text.editable = false
  }
}
export default {
  type: 'rect-node',
  view: RectNode,
  model: RectNodeModel
}


import { defineView } from '@kmsoft/upf-core'
import { KTempUserSelectorEventEmits, KTempUserSelectorPropOptions } from './interface'
import KTempUserSelectorViewModel from './KTempUserSelectorViewModel'

export default defineView({
  name: 'KTempUserSelector',
  props: KTempUserSelectorPropOptions,
  emits: KTempUserSelectorEventEmits,
  viewModel: KTempUserSelectorViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

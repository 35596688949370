import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KFileUploaderProgressEmitsType, KFileUploaderProgressPropType } from './interface'

/** KFileUploaderProgress */
export default class KFileUploaderProgressViewModel extends BaseViewModel<KFileUploaderProgressEmitsType, KFileUploaderProgressPropType> {
  constructor(options: ViewModelOptions<KFileUploaderProgressPropType>) {
    super(options)
  }

  viewDidMount() {}
}

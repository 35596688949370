import {
  KLayoutElementEvent,
  KSchema,
  SimpleViewModel,
  ContainerViewModel,
  EventScriptEngine,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { IControlWithPath, KDynamicViewEventEmitsType, KDynamicViewPropType } from './interface'
import { nextTick, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'

/** 动态渲染器视图模型 */
export class KDynamicViewViewModel extends ContainerViewModel<KDynamicViewEventEmitsType, KDynamicViewPropType> {
  /** 布局方案 */
  schema = ref<Nullable<KSchema>>(null)
  /**
   * 控件的数据源字段与名称映射
   * key: control.dataSource#control.field
   * value: control.name
   */
  controlDataSourceFieldNameMap = new Map<string, Array<string>>()

  constructor(options: ViewModelOptions<KDynamicViewPropType>) {
    super(options)

    watch(
      () => options.props.schema!,
      (newVal: KSchema) => {
        this.schema.value = cloneDeep(newVal)
      },
      {
        deep: true,
        immediate: true
      }
    )

    watch(
      () => options.props.pageValue!,
      (newVal: Record<string, any>) => {
        if (newVal) {
          nextTick(() => {
            this.$vm.setValue(newVal)
          })
        }
      },
      {
        deep: true,
        immediate: true
      }
    )
  }

  /**
   * 加载完成事件
   */
  viewDidMount() {
    if (this.props.schema) {
      const controls = this.convertToFlatArray(this.props.schema)
      this.initEventScript(controls)
      this.setControlDataSourceFieldNameMap(controls)
    } else {
      console.error('KDynamicView: 未找到schema,初始化失败')
    }
  }

  /**
   * 设置布局方案
   * @param schema 方案
   */
  public setSchema(schema: KSchema) {
    this.emit('beforeSetDataSource')
    this.schema.value = schema
    this.emit('afterSetDataSource')
  }

  /**
   * 设置页面值
   * @param value 值
   */
  public setValue(value: Record<string, any>) {
    const flattenDataSource = this.getFlattenDataSource(value)
    Object.keys(flattenDataSource).forEach(key => {
      this.setControlValue(key, flattenDataSource[key])
    })
  }

  /**
   * 获取页面值
   * @returns 页面值
   */
  public getValue(): Record<string, any> {
    const flattenData = {} as Record<string, any>
    this.controlDataSourceFieldNameMap.forEach((controlNames: Array<string>, fieldName: string) => {
      const controlName = controlNames[0]
      const childControl = this.getByRecursion<SimpleViewModel>(controlName, this)
      if (childControl && this.hasMethod(childControl, 'getValue')) {
        const controlValue = childControl.getValue()
        flattenData[fieldName] = controlValue
      }
    })
    return this.getRestoreDataSource(flattenData)
  }

  /**
   * 获取页面修改的值
   * @returns 修改值
   */
  public getModifiedValue(): Record<string, any> {
    const flattenData = {} as Record<string, any>
    this.controlDataSourceFieldNameMap.forEach((controlNames: Array<string>, fieldName: string) => {
      const controlName = controlNames[0]
      const childControl = this.getByRecursion<SimpleViewModel>(controlName, this)
      if (childControl && this.hasMethod(childControl, 'isModified') && childControl.isModified()) {
        flattenData[fieldName] = childControl.getModifiedValue()
      }
    })
    return flattenData
  }

  /**
   * 设置指定控件值
   * @param dataSourceField 数据源#字段
   * @param value 控件值
   * @returns 是否存在指定控件
   */
  public setControlValue(dataSourceField: string, value: any): boolean {
    const controlNames = this.controlDataSourceFieldNameMap.get(dataSourceField)
    controlNames?.forEach(controlName => {
      const childControl = this.getByRecursion<SimpleViewModel>(controlName, this)
      if (childControl && this.hasMethod(childControl, 'setValue')) {
        childControl.setValue(value, true)
      }
    })
    return controlNames && controlNames.length > 0 ? true : false
  }

  /**
   * 获取指定控件值
   * @param dataSourceField 数据源#字段
   */
  public getControlValue(dataSourceField: string): any | undefined {
    const controlNames = this.controlDataSourceFieldNameMap.get(dataSourceField)
    if (controlNames && controlNames.length > 0) {
      const controlName = controlNames[0]
      const childControl = this.getByRecursion<SimpleViewModel>(controlName, this)
      if (childControl && this.hasMethod(childControl, 'getValue')) {
        return childControl.getValue()
      }
    }
  }

  /**
   * 设置页面是否只读
   */
  public setReadonly(readonly: boolean) {
    this.setDisabledOrReadonly('readonly', readonly)
  }

  /**
   * 设置页面是否启用
   */
  public setDisabled(disabled: boolean) {
    this.setDisabledOrReadonly('disabled', disabled)
  }

  /**
   * 禁用或只读
   * @param methodName
   * @param value
   */
  private setDisabledOrReadonly(methodName: string, value: boolean) {
    const childList = this.getChildControlCode(this)
    for (let i = 0; i < childList.length; i++) {
      const name = childList[i]
      const viewModel = this.getByRecursion<SimpleViewModel>(name, this)
      if (methodName === 'readonly') {
        viewModel?.setReadonly?.(value)
      } else {
        viewModel?.setDisabled?.(value)
      }
    }
  }

  /**
   * 初始化脚本
   * @param controls
   */
  private initEventScript(controls: Array<IControlWithPath>) {
    const bizCtx = this.props.bizCtx
    const pageViewModel = this
    controls.forEach(c => {
      const parentViewModel = this.getByPath(c.parentPath)
      const viewModel = c.currentPath == this.props.schema!.name ? pageViewModel : this.getByPath(c.currentPath)
      const code = c.control.events ? c.control.events.map((event: KLayoutElementEvent) => event.code).join(';') : ''
      const scriptEngine = new EventScriptEngine(code, {
        viewModel,
        parentViewModel,
        pageViewModel,
        ...bizCtx
      })
      try {
        scriptEngine.execute()
      } catch (error) {
        console.error('KDynamicView: 脚本异常', error)
      }
    })
  }

  /**
   * 记录控件数据源字段与名称映射
   * @param controls 控件集合
   */
  private setControlDataSourceFieldNameMap(controls: Array<IControlWithPath>) {
    controls.forEach(c => {
      if ('field' in c.control && c.control.field) {
        const dataSource = c.control.dataSource ? c.control.dataSource + '#' : ''
        const dataSourceField = `${dataSource}${c.control.field}`
        if (this.controlDataSourceFieldNameMap.has(dataSourceField)) {
          this.controlDataSourceFieldNameMap.get(dataSourceField)?.push(c.control.name)
        } else {
          this.controlDataSourceFieldNameMap.set(dataSourceField, [c.control.name])
        }
      }
    })
  }

  /**
   * 将所有层级的组件拉平放入同一个数组（递归）
   * @param node
   * @param currentPath
   * @param parentPath
   * @returns
   */
  private convertToFlatArray(node: any, currentPath = '', parentPath = ''): Array<IControlWithPath> {
    const result = [] as Array<IControlWithPath>
    /** 当前控件路径 */
    const currentNodePath = currentPath ? `${currentPath}.${node.name}` : node.name
    result.push({
      currentPath: currentNodePath,
      parentPath,
      control: node
    })
    /** 递归查找子组件 */
    if (node.controls && node.controls.length > 0) {
      for (const childControl of node.controls) {
        const childResult = this.convertToFlatArray(childControl, currentNodePath, currentNodePath)
        result.push(...childResult)
      }
    }

    return result
  }

  /**
   * 获取拉平后的数据源
   * {a: {b: 1, c:2}}  => { 'a#b': 1, 'a#c': 2}
   * @param originalData 原始的数据源
   * @param parentKey 父对象key
   * @param separator 分隔符
   */
  private getFlattenDataSource(originalData: Record<string, any> | null, parentKey = '', separator = '#'): Record<string, any> {
    const flattenedData = {} as Record<string, any>
    const isObject = this.isObject
    if (isObject(originalData)) {
      for (const key in originalData) {
        // eslint-disable-next-line no-prototype-builtins
        if (originalData.hasOwnProperty(key)) {
          const newKey = parentKey ? parentKey + separator + key : key
          if (isObject(originalData[key])) {
            Object.assign(flattenedData, this.getFlattenDataSource(originalData[key], newKey, separator))
          } else {
            flattenedData[newKey] = originalData[key]
          }
        }
      }
    }
    return flattenedData
  }

  /**
   * 判断数据格式是否是对象
   * @param data 数据
   * @returns
   */
  private isObject(data: any) {
    return Object.prototype.toString.call(data) == '[object Object]'
  }

  /**
   * 获取还原后的数据源格式
   * { 'a#b': 1, 'a#c': 2} => {a: {b: 1, c:2}}
   * @param flattenData 拉平的数据源
   * @param separator 分隔符
   * @returns
   */
  getRestoreDataSource(flattenData: Record<string, any>, separator = '#'): Record<string, any> {
    const resultObject = {} as Record<string, any>
    for (const key in flattenData) {
      const keys = key.split(separator)
      let currentLevel = resultObject

      for (let i = 0; i < keys.length; i++) {
        const currentKey = keys[i]

        if (!currentLevel[currentKey]) {
          if (i === keys.length - 1) {
            currentLevel[currentKey] = flattenData[key]
          } else {
            currentLevel[currentKey] = {}
          }
        }

        currentLevel = currentLevel[currentKey]
      }
    }

    return resultObject
  }
}

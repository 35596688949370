
import { EnumDataGridRowModelType, EnumDividerType, defineView } from '@kmsoft/upf-core'
import { KMyManagedProductionWorkspacePropOptions, KMyManagedProductionWorkspaceEventEmits } from './interface'
import KMyManagedProductionWorkspaceViewModel from './KMyManagedProductionWorkspaceViewModel'

export default defineView({
  name: 'KMyManagedProductionWorkspace',
  props: KMyManagedProductionWorkspacePropOptions,
  emits: KMyManagedProductionWorkspaceEventEmits,
  viewModel: KMyManagedProductionWorkspaceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KSchemaExportEventEmits, KSchemaExportPropOptions } from './interface'
import KSchemaExportViewModel from './KSchemaExportViewModel'

export default defineView({
  name: 'KSchemaExport',
  props: KSchemaExportPropOptions,
  emits: KSchemaExportEventEmits,
  viewModel: KSchemaExportViewModel,
  data() {
    return {}
  }
})

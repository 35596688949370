import { ref } from 'vue'
import { BaseViewModel, ViewModelOptions, KNotification } from '@kmsoft/upf-core'
import { KChangeRequestRelationshipEmitsType, KChangeRequestRelationshipPropType } from './interface'
import { Api, EnumRequestCode, KObjectClassGridViewModel, ObjBusinessParam } from '@kmsoft/ebf-common'

/** KChangeRequestRelationship */
export default class KChangeRequestRelationshipViewModel extends BaseViewModel<
  KChangeRequestRelationshipEmitsType,
  KChangeRequestRelationshipPropType
> {
  refChangeIssueGrid = ref<KObjectClassGridViewModel>()
  refChangeOrderGrid = ref<KObjectClassGridViewModel>()
  objParam = ref<ObjBusinessParam>()
  activeKey = ref<Array<string>>(['1', '2'])

  /**
   * 变更问题报告
   */
  plmLinkSources = ref<Array<Record<string, any>>>([])

  /**
   * 变更单
   */
  plmLinkTargets = ref<Array<Record<string, any>>>([])

  constructor(options: ViewModelOptions<KChangeRequestRelationshipPropType>) {
    super(options)
    this.objParam.value = options.props.objParam
    console.info(this.objParam)
  }

  viewDidMount() {
    this.listChangeIssue()
  }

  titleClick(key: string) {
    if (!this.activeKey.value.includes(key)) {
      this.activeKey.value.push(key)
    }
  }

  loadChangeIssueData() {
    return Promise.resolve({
      rows: [],
      total: 0
    })
  }

  /**
   * 查询变更请求-问题报告清单
   *
   * @returns
   */
  async listChangeIssue() {
    const reqParam = {
      data: [this.objParam.value?.id]
    }
    const result = await Api.post('change', 'ChangeRequest', 'getLinks', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.plmLinkTargets.value = result.data?.plmLinkTargets || []
      this.plmLinkSources.value = result.data?.plmLinkSources || []

      this.plmLinkTargets.value.forEach((item: any) => {
        this.refChangeOrderGrid.value?.insertRow(item)
      })
      this.plmLinkSources.value.forEach((item: any) => {
        this.refChangeIssueGrid.value?.insertRow(item)
      })
      return
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }

  loadChangeOrderData() {
    return Promise.resolve({
      rows: [],
      total: 0
    })
  }
}

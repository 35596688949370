import { BaseViewModel, ViewModelOptions, KMonacoEditorViewModel, TreeViewSelectEventArgs, request } from '@kmsoft/upf-core'
import { KBizScriptEditorEventEmitsType, KBizScriptEditorPropType } from './interface'
import { ref, watch } from 'vue'
import { KDirectoryTreeNodeBase, KDirectoryTreeViewModel } from '../directory-tree'
import { ConfigClientSrv } from '../../client-srv'

/** KBizScriptEditor 视图模型 */
export default class KBizScriptEditorViewModel extends BaseViewModel<KBizScriptEditorEventEmitsType, KBizScriptEditorPropType> {
  /** 目录树 */
  refModuleTree = ref<KDirectoryTreeViewModel>()
  /** 脚本编辑器 */
  refScriptEditor = ref<KMonacoEditorViewModel>()
  /** 系统脚本目录 */
  moduleType: number = 70
  /** 选择的节点 */
  selectedNode = ref<KDirectoryTreeNodeBase>()
  /** 当前脚本 */
  value = ref('console.log(123);')
  /** 是否正在加载脚本信息 */
  isLoadingScriptContent = ref<boolean>(true)
  /** 当前选中的脚本 */
  currentScript = ref<string>('')

  constructor(options: ViewModelOptions<KBizScriptEditorPropType>) {
    super(options)

    watch(this.value, newValue => {
      this.emit('update:value', newValue)
    })
  }

  /** 加载完成函数 */
  viewDidMount() {}

  async onTreeNodeAfterSelect(event: TreeViewSelectEventArgs<KDirectoryTreeNodeBase>) {
    this.selectedNode.value = event.node
    this.isLoadingScriptContent.value = true
    const baseApiUrl = ConfigClientSrv.getApiBaseUrl
    const url = `${baseApiUrl}/script/getScriptDefinition`
    const scriptResult = (await request.post(url, { name: this.selectedNode.value?.name })) as any
    if (scriptResult.isSuccess) {
      this.currentScript.value = scriptResult.data!.script
    }
    this.isLoadingScriptContent.value = false
  }
}

import { KBizContext } from '../../../../../types'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  KLayoutElementEvent,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  KControlEvent
} from '@kmsoft/upf-core'

/** KViewEventConfig 状态 **/
export interface IKViewEventConfigState {}

/** KViewEventConfig 事件 **/
export const KViewEventConfigEventEmits = {
  ...BaseViewEventEmits,
  elementPropertyChange: (configItemName: string, value: any) => true
}

/** KViewEventConfig 参数 **/
export const KViewEventConfigPropOptions = {
  ...BaseViewPropOptions,
  /**控件标题 */
  title: VuePropTypes.string().def(),
  /* 当前选中的空间类型 */
  control: VuePropTypes.string().def(),
  /**控件事件集合 */
  controlEvents: VuePropTypes.createType<Array<KControlEvent>>().def([]),
  /**事件值集合 */
  eventValueItem: VuePropTypes.createType<Array<KLayoutElementEvent>>().def(),
  /**业务上下文 */
  bizContext: VuePropTypes.func<() => KBizContext>().def()
}

/** KViewEventConfig 参数类型 **/
export type KViewEventConfigPropType = ViewPropsTypeExtract<typeof KViewEventConfigPropOptions>

/** KViewEventConfig 事件协议*/
export type KViewEventConfigEventEmitsType = ViewEmitsTypeExtract<typeof KViewEventConfigEventEmits>

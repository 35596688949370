import { BaseViewModel, KTreeViewViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KRequireWorkspaceEmitsType, KRequireWorkspacePropType, RequireWorkspaceRootTreeNode } from './interface'

import { ref } from 'vue'
import { Api, EnumRequestCode, ObjectToolStripItem } from '@kmsoft/ebf-common'
import { CopyOrCutEnum, FolderTreeNode } from '../../../ebf-folder/src/pages/folder-manage/folder-tree/interface'
import { folderObjOperationToolStripItems } from '../../../ebf-folder/src/pages/folder-manage/toolStripItems'

/** KRequireWorkspace */
export default class KRequireWorkspaceViewModel extends BaseViewModel<KRequireWorkspaceEmitsType, KRequireWorkspacePropType> {
  selectNode = ref<FolderTreeNode>()
  refFolderTree = ref<KTreeViewViewModel>()
  /**复制还是粘贴中 */
  inCopyOrCut: CopyOrCutEnum = CopyOrCutEnum.NOTHING
  /**工具栏item */
  toolStripItems = ref<Array<ObjectToolStripItem>>([])
  /** 运行的对象类 */
  allowedClsCodes = ref<Array<string>>([])
  /** 文件夹类型 */
  folderType = ref<number>()
  /** 父节点Id */
  parentFolderId = ref<string>()

  constructor(options: ViewModelOptions<KRequireWorkspacePropType>) {
    super(options)
    this.toolStripItems.value = folderObjOperationToolStripItems
  }

  viewDidMount() {}

  /**
   * 加载树节点
   */
  async loadTreeData() {
    return [new RequireWorkspaceRootTreeNode(this.props.objParam)]
  }
}

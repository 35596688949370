import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19bdacd5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "login",
  class: "login-wrapper"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "container-bg" }
const _hoisted_4 = { class: "km-logo" }
const _hoisted_5 = { href: "/" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "km-remind" }
const _hoisted_8 = { class: "login-area" }
const _hoisted_9 = { class: "main-area" }
const _hoisted_10 = { class: "login-top" }
const _hoisted_11 = { class: "err-message" }
const _hoisted_12 = { class: "login-main" }
const _hoisted_13 = { class: "login-footer" }
const _hoisted_14 = {
  href: "/",
  style: {"margin-left":"20px"}
}
const _hoisted_15 = {
  href: "/",
  style: {"margin-left":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_form_group = _resolveComponent("k-form-group")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.logoImg,
              alt: "logo"
            }, null, 8, _hoisted_6)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.remind), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.sysTitle), 1),
          _withDirectives(_createElementVNode("div", _hoisted_11, [
            _createVNode(_component_k_icon, {
              type: "info-circle-red",
              width: 16,
              height: 16,
              "margin-left": 10
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.errMessage), 1)
          ], 512), [
            [_vShow, _ctx.errMessage != '']
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_k_form, {
              class: "login-from form-row-block",
              ref: "refForm",
              model: _ctx.form.model,
              rules: _ctx.form.rules
            }, {
              default: _withCtx(() => [
                _createVNode(_component_k_form_group, { title: "用户登录" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_k_form_item, {
                      class: "k-login-form-item",
                      name: "userName",
                      onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput('0'))),
                      onMouseout: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInputOut('0')))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_k_input, {
                          type: "text",
                          value: _ctx.form.model.userName,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.model.userName) = $event)),
                          placeholder: "用户名",
                          onKeyup: _withKeys(_withModifiers(_ctx.onLogin, ["prevent"]), ["enter"]),
                          onChange: _ctx.useNameChange
                        }, {
                          prefix: _withCtx(() => [
                            _createVNode(_component_k_icon, {
                              type: "user",
                              width: 16,
                              height: 16,
                              style: {
                          color: '#8e8e8e'
                        }
                            })
                          ]),
                          suffix: _withCtx(() => [
                            _withDirectives(_createElementVNode("span", {
                              class: "clear-icon",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearValue('0')))
                            }, [
                              _createVNode(_component_k_icon, {
                                type: "login-delete",
                                width: 16,
                                height: 16
                              })
                            ], 512), [
                              [_vShow, _ctx.isShowClearUserName]
                            ])
                          ]),
                          _: 1
                        }, 8, ["value", "onKeyup", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_k_form_item, {
                      class: "k-login-form-item",
                      name: "password",
                      onMouseover: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onInput('1'))),
                      onMouseout: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onInputOut('1')))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_k_input, {
                          type: "password",
                          autocomplete: "false",
                          placeholder: "密码",
                          value: _ctx.form.model.password,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.model.password) = $event)),
                          onKeyup: _withKeys(_withModifiers(_ctx.onLogin, ["prevent"]), ["enter"]),
                          onChange: _ctx.passwordChange
                        }, {
                          prefix: _withCtx(() => [
                            _createVNode(_component_k_icon, {
                              type: "lock",
                              width: 16,
                              height: 16,
                              style: {
                          color: '#8e8e8e'
                        }
                            })
                          ]),
                          suffix: _withCtx(() => [
                            _withDirectives(_createElementVNode("span", {
                              class: "clear-icon",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearValue('1')))
                            }, [
                              _createVNode(_component_k_icon, {
                                type: "login-delete",
                                width: 16,
                                height: 16
                              })
                            ], 512), [
                              [_vShow, _ctx.isShowClearPassWord]
                            ])
                          ]),
                          _: 1
                        }, 8, ["value", "onKeyup", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_k_form_item, { class: "k-login-form-item" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_k_button, {
                          size: "large",
                          type: "primary",
                          onClick: _ctx.onLogin,
                          class: "login-button",
                          loading: _ctx.isLogging,
                          disabled: _ctx.isLogging
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" 登录 ")
                          ]),
                          _: 1
                        }, 8, ["onClick", "loading", "disabled"]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("a", {
                            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.downloadFile && _ctx.downloadFile(...args)))
                          }, _toDisplayString(_ctx.footerMsg_left), 1),
                          _createElementVNode("a", _hoisted_14, _toDisplayString(_ctx.footerMsg_center), 1),
                          _createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.footerMsg_right), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "rules"])
          ])
        ])
      ])
    ])
  ]))
}
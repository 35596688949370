import { FileAttributes } from '../../io/FileAttributes'
import { CommonDialog } from './CommonDialog'
import { DialogResult } from './DialogResult'

import * as UpfCore from '@kmsoft/upf-core'

import KFileDialog from './file-dialog'
import KFileDialogViewModel from './file-dialog/KFileDialogViewModel'

/**显示一个用户可从中选择文件的对话框窗口。*/
export abstract class FileDialog extends CommonDialog {
  /**获取或设置文件对话框标题。*/
  public Title: string

  /**获取或设置当前文件名筛选器字符串，该字符串决定对话框的“另存为文件类型”或“文件类型”框中出现的选择内容。*/
  public Filter: string

  /**获取或设置文件对话框显示的初始目录。*/
  public InitialDirectory: string

  /**获取或设置一个包含在文件对话框中选定的文件名的字符串。*/
  public FileName: string

  /**获取对话框中所有选定文件的文件名。*/
  public FileNames: string[]

  /**将所有属性重新设置为其默认值。*/
  public Reset(): void {}

  /**获取创建控件时所需要的创建参数。*/
  protected get CreateParams(): Record<string, any> {
    return { ...super.CreateParams, filter: this.Filter }
  }

  public async ShowDialog(): Promise<DialogResult> {
    return new Promise(resolve => {
      UpfCore.KDialog.show({
        title: this.Title,
        size: UpfCore.EnumDialogSize.Fat,
        okText: '打开',
        content: KFileDialog,
        props: this.CreateParams,
        onClosing: (event: UpfCore.KDialogClosingEvent) => {
          if (event.dialogResult !== UpfCore.EnumDialogResult.Confirm) {
            resolve(DialogResult.Cancel)
            return
          }

          const result = (event.viewModel as KFileDialogViewModel).collect()
          event.cancel = result.cancel
          if (!event.cancel) {
            this.FileNames = result.fileNames
            this.FileName = result.fileName
            resolve(DialogResult.OK)
          }
        }
      })
    })
  }
}

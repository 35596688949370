
import { defineView } from '@kmsoft/upf-core'
import { KConfigTileViewModelPropOptions } from './interface'
import KConfigTileViewModel from './KConfigTileViewModel'
import ConfigValueTile from '../sys-config-value-tile'
import ConfigGrid from '../sys-config-grid'
import { EnumDataType } from '../../interface'

export default defineView({
  components: { ConfigValueTile, ConfigGrid },
  name: 'ConfigTile',
  props: KConfigTileViewModelPropOptions,
  viewModel: KConfigTileViewModel,
  setup() {
    return {
      EnumDataType
    }
  }
})

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPropertyGridPropOptions = {
  ...BaseViewPropOptions,
  /**
   * 对象类code
   */
  modelCode: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KPropertyGridPropType = ViewPropsTypeExtract<typeof KPropertyGridPropOptions>

/** 事件 */
export const KPropertyGridEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPropertyGridEmitsType = ViewEmitsTypeExtract<typeof KPropertyGridEventEmits>

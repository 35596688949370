import {
  KObjectPanelTabEventEmits,
  KObjectPanelTabPropOptions,
  ObjBusinessParam,
  ObjectPanelRetrieveEvent,
  ObjectPanelTabChangeEvent
} from '@kmsoft/ebf-common'
import {
  TreeViewCheckedEventArgs,
  TreeViewMenuEventArgs,
  TreeViewSelectEventArgs,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { EnumPartNodeChildExpandMode } from '../../client-srv'
import { KPartStructureTreeViewModel, PartStructureTreeNode } from '../part-structure-tree'
import { PartStructureTreeMenuItemClickedEvent } from '../part-structure-tree/interface'

export type StructureViewOption = {
  /** 标签 */
  label: string
  /** 值 */
  value: string
}

export type StructureFilterOption = {
  /** 标签 */
  label: string
  /** 值 */
  value: string
}

/** 参数 **/
export const KPartStructureManagePropOptions = {
  ...KObjectPanelTabPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  /** 是否停用对象面板 */
  suppressObjectPanel: VuePropTypes.bool().def(false),
  /** 是否默认显示对象面板 */
  showObjectPanel: VuePropTypes.bool().def(true),
  /** 是否显示替换件网格 */
  showReplaceGrid: VuePropTypes.bool().def(true),
  /** 是否支持多选 */
  isMultipleSelection: VuePropTypes.bool().def(true),
  /** 选中严格受控，父子节点选中状态不再关联 */
  checkStrictly: VuePropTypes.bool().def(true),
  /** 节点展开模式 */
  nodeChildExpandMode: VuePropTypes.createType<EnumPartNodeChildExpandMode>().def(),
  /** 停用过滤视图 */
  suppressView: VuePropTypes.bool().def(false),
  /** 禁用视图 */
  disabledView: VuePropTypes.bool().def(false),
  /** 禁用过滤 */
  disabledFilter: VuePropTypes.bool().def(false),
  /** 是否禁用右键菜单 */
  disabledContextMenu: VuePropTypes.bool().def(false),
  /** 禁用替换网格 默认undefined，设置默认值会导致工具栏状态异常 */
  disabledSwapGrid: VuePropTypes.bool().def(),
  /** 禁止的标签页 */
  suppressedTabs: VuePropTypes.array<string>().def([]),
  /** 默认视图（筛选器：最新、最新发布、基线、时间有效性） */
  viewName: VuePropTypes.string().def(),
  /** 默认视图（视图：设计、制造） */
  partViewName: VuePropTypes.string().def(),
  /** 显示环境 */
  env: VuePropTypes.createType<'part' | 'structure'>().def('part')
}

/** 参数类型 **/
export type KPartStructureManagePropType = ViewPropsTypeExtract<typeof KPartStructureManagePropOptions>

/** 事件 */
export const KPartStructureManageEventEmits = {
  ...KObjectPanelTabEventEmits,
  /** 切换视图事件 */
  'update:viewName': (value: string) => true,
  /** 版本规则 */
  'update:nodeChildExpandMode': (value: EnumPartNodeChildExpandMode) => true,
  /** 节点选中后事件 */
  afterTreeCheck: (event: TreeViewCheckedEventArgs) => true,
  /** 初始化完成后提交此事件，传递显示的零部件树，用于要对树进行初始化配置的场景 */
  treeReady: (tree: KPartStructureTreeViewModel) => true,
  /** 对象面板标签页更改事件 */
  objectPanelTabChange: (event: ObjectPanelTabChangeEvent) => true,
  /** 零部件树菜单点击事件 */
  treeContextMenuItemClicked: (event: PartStructureTreeMenuItemClickedEvent) => true,
  /** 零部件树菜单弹出前事件 */
  beforeTreeContextMenuPopup: (event: TreeViewMenuEventArgs<PartStructureTreeNode>) => true,
  /** 对象面板自定义指令 */
  objectPanelRetrieve: (event: ObjectPanelRetrieveEvent<any>) => true,
  /** 树选择后事件 */
  afterTreeSelect: (event: TreeViewSelectEventArgs<PartStructureTreeNode>) => true
}

/** 事件类型 **/
export type KPartStructureManageEmitsType = ViewEmitsTypeExtract<typeof KPartStructureManageEventEmits>

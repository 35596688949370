import { ComponentHelper, VNodeProps, defineView } from '@kmsoft/upf-core'
import { computed, createVNode } from 'vue'
import { KObjectClassTreeSelector, KObjectClassTreeSelectorEmitsType, KObjectClassTreeSelectorPropType } from '../editors'
import KObjectCreatePanelViewModel from './KObjectCreatePanelViewModel'
import { KObjectCreatePanelFormBasePropType } from './controls'
import { KObjectCreatePanelEventEmits, KObjectCreatePanelPropOptions } from './interface'

export default defineView({
  name: 'KObjectCreatePanel',
  props: KObjectCreatePanelPropOptions,
  emits: KObjectCreatePanelEventEmits,
  viewModel: KObjectCreatePanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /** 是否显示对象类选择 */
    const showObjectTreeSelector = computed(() => {
      return props.forceShowObjClsSelector || !props.modelCode
    })

    /** 渲染对象类选择 */
    const renderSelector = () => {
      if (!showObjectTreeSelector.value) {
        return
      }

      /** 选择器参数 */
      const selectorProps: VNodeProps<KObjectClassTreeSelectorPropType, KObjectClassTreeSelectorEmitsType> = {
        classTemplates: props.classTemplates,
        showObjClsCodes: props.showObjClsCodes,
        hideObjClsCodes: props.hideObjClsCodes,
        showAllOrNot: props.showAllOrNot,
        defaultOpen: !vm.modelCode.value,
        value: vm.modelCode.value,
        'onUpdate:value': (value: string) => {
          vm.modelCode.value = value
        }
      }

      return <div class="header">{createVNode(KObjectClassTreeSelector, selectorProps)}</div>
    }

    /** 渲染表单 */
    const renderForm = () => {
      // 如果正在加载
      if (vm.isLoading.value) {
        return <k-loading />
      }

      // 如果没有表单组件
      if (!vm.formComponent.value) {
        return
      }

      /** 组件 */
      const component = ComponentHelper.renderSomeContent(vm.formComponent.value)

      /** 表单属性 */
      const formProps: VNodeProps<KObjectCreatePanelFormBasePropType> = {
        ref: vm.refForm,
        ref_key: 'refForm',
        ...props,
        ...attrs,
        modelCode: vm.modelCode.value,
        modelGroup: vm.modelGroup.value,
        objCopyMode: vm.objCopyMode.value,
        masterId: props.masterId,
        iteratorId: props.iteratorId,
        attachParams: props.attachParams
      }
      /** 表单 VNode */
      const formVNode = createVNode(component, formProps)

      return <div class="content">{formVNode}</div>
    }

    return () => {
      return (
        <div class="k-object-create-panel">
          {renderSelector()}
          {renderForm()}
        </div>
      )
    }
  }
})

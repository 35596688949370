import { ViewPropsTypeExtract, ViewEmitsTypeExtract } from '@kmsoft/upf-core'
import { VuePropTypes, KDataGridRowData } from '@kmsoft/upf-core'
import {
  KObjectClassGridBaseEventEmits,
  KObjectClassGridBasePropOptions,
  ObjectClassGirdBaseDataResult,
  ObjectClassGirdBaseQueryParams
} from './controls'
import { IObjectPageResult, EnumVersionShowType, QueryCondition, EnumLayoutSchemaType } from '../../client-srv'
import { ObjectToolStripItem } from '../object-tool-strip'

//#region 枚举
/** 对象分类管理工具条选项 */
export enum EnumObjClassManageToolStripOptions {
  /** 隐藏全部 */
  None = 0,
  /** 显示对象生命周期分组 */
  ShowObjLifeTime = 1,
  /** 显示对象操作分组 */
  ShowOperation = 2,
  /** 显示列表操作分组 */
  ShowGridOperation = 4,
  /** 显示“编辑属性”按钮 */
  ShowEdit = 8,
  /** 显示“是否显示子类”复选框 */
  ShowSubClass = 16,
  /** 显示“全局搜索”按钮 */
  ShowGlobalSearch = 32,
  /** 显示“版本类型”按钮 */
  ShowVersionType = 64,
  /** 显示所有 */
  All = 127
}

/** 布局数据来源 */
export enum EnumLayoutDataSourceType {
  /** 列定义 */
  Fields,
  /** 对象类Code */
  ObjClsCode
}
//#endregion

//#region 类型
/**查询参数 */
export interface ObjectClassGridQueryParams extends ObjectClassGirdBaseQueryParams {
  /** 对象类Code */
  modelCode?: string
  /**对象类分组 */
  modelGroup?: string
  /** 版本 */
  versionShowType?: EnumVersionShowType
}

/** 网格数据 */
export type ObjectClassGirdDataResult = ObjectClassGirdBaseDataResult

/** 对象网格加载函数 */
export type ObjectClassGridDataLoader = (
  query: ObjectClassGridQueryParams
) => Promise<ObjectClassGirdDataResult> | ObjectClassGirdDataResult

//#endregion

/** 对象网格事件 */
export const KObjectClassGridEventEmits = {
  ...KObjectClassGridBaseEventEmits,
  /** 对象类编码改变事件 */
  objClsCodeChange: (classCode: string) => true,
  /** 更新工具栏事件 */
  updateToolStripState: () => true
}

/** 对象网格事件类型  **/
export type KObjectClassGridEventEmitsType = ViewEmitsTypeExtract<typeof KObjectClassGridEventEmits>

/** 对象网格属性选项 */
export const KObjectClassGridPropOptions = {
  ...KObjectClassGridBasePropOptions,
  /** 布局数据来源 */
  layoutDataSource: VuePropTypes.createType<EnumLayoutDataSourceType>().def(EnumLayoutDataSourceType.ObjClsCode),
  /** 对象类Code */
  modelCode: VuePropTypes.string().def(''),
  /**对象类分组 */
  modelGroup: VuePropTypes.string().def(''),

  /** 网格数据加载器 */
  loadData: VuePropTypes.func<ObjectClassGridDataLoader>().def(),
  /** 是否启用网格多选 */
  isMultipleSelection: VuePropTypes.bool().def(true),
  /** 是否启用双击对象打开 默认值: true */
  enableDbClickOpenObj: VuePropTypes.bool().def(true),
  /** 编辑网格行是否弹出 */
  editorIsPopup: VuePropTypes.bool().def(false),

  //#region 布局
  /** 布局Id */
  schemaId: VuePropTypes.string().def(''),
  /** 链接列 */
  linkField: VuePropTypes.string().def('CNAME'),
  //#endregion

  //#region 工具栏
  /** 在后面插入工具栏 */
  toolStripItems: VuePropTypes.createType<ObjectToolStripItem[]>().def([]),
  /** 在最前端插入工具栏 */
  toolStripItemsInsert: VuePropTypes.createType<ObjectToolStripItem[]>().def([]),
  /** 工具条按钮选项 */
  toolStripOptions: VuePropTypes.createType<EnumObjClassManageToolStripOptions>().def(EnumObjClassManageToolStripOptions.All),
  /** 禁止默认工具栏条目 */
  suppressDefaultToolStripItems: VuePropTypes.bool().def(false),
  /** 是否启用工具栏选项过滤 */
  enableToolStripFilter: VuePropTypes.bool().def(true),
  /** 是否启用权限判断 */
  enablePermission: VuePropTypes.bool().def(true),
  /**布局类型 */
  schemaType: VuePropTypes.createType<EnumLayoutSchemaType | string>().def(EnumLayoutSchemaType.Grid)
  //#endregion
}

/** 对象网格属性类型 */
export type KObjectClassGridPropType = ViewPropsTypeExtract<typeof KObjectClassGridPropOptions>

import {
  EnumConditionValueType,
  EnumFilterOperator,
  EnumSortDirection,
  KFilter,
  ValueChangeEventArgs,
  VuePropTypes
} from '@kmsoft/upf-core'
import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'
import { KDataElementTree, KDesignerControl } from '../../../../types'
import { VNode } from 'vue'

/** KFilterGridFilterItemDesigner 事件 */
export const KFilterGridFilterItemDesignerEventEmits = {
  ...BaseViewEventEmits,
  /** 值改变前事件 */
  beforeChange: (event: ValueChangeEventArgs) => true,
  /** 值改变后事件 */
  change: (event: ValueChangeEventArgs) => true
}

/** KFilterGridFilterItemDesigner 事件类型 **/
export type KFilterGridFilterItemDesignerEventEmitsType = ViewEmitsTypeExtract<typeof KFilterGridFilterItemDesignerEventEmits>

/** KFilterGridFilterItemDesigner 参数 **/
export const KFilterGridFilterItemDesignerPropOptions = {
  ...BaseViewPropOptions,
  /**控件元数据 */
  designerMetaControl: VuePropTypes.createType<Array<KDesignerControl>>().def([]),
  /**数据元素 */
  dataSource: VuePropTypes.createType<KDataElementTree>().def({} as KDataElementTree)
}

/** KFilterGridFilterItemDesigner 参数类型 **/
export type KFilterGridFilterItemDesignerPropType = ViewPropsTypeExtract<typeof KFilterGridFilterItemDesignerPropOptions>

/** 自定义编操作符号 */
export type KFilterOperationResolver = (column: KDesignerControl) => Array<EnumFilterOperator>

/**自定义编辑方式 */

export type KFilterEditorResolver = (column: KDesignerControl) => VNode

export type KFilterWithId = KFilter & {
  id: string
}

/**
 * 默认筛选值
 */
export const DefaultFilterValue = {
  name: '',
  conditions: [{ key: '', title: '', operator: EnumFilterOperator.EQUAL, value: [], valueType: EnumConditionValueType.RAW }],
  sorts: [{ name: '', direction: EnumSortDirection.ASC }]
}

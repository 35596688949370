import './style/style.less'
import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectClassTreeView from './KObjectClassTree'
import KObjectClassTreeViewModel from './KObjectClassTreeViewModel'

const KObjectClassTree = connect(KObjectClassTreeView, KObjectClassTreeViewModel)

export default withInstall(KObjectClassTree)
export { KObjectClassTree, KObjectClassTreeViewModel }
export * from './interface'

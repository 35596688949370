import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KPartCreateBaselinePropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KPartCreateBaselinePropType = ViewPropsTypeExtract<typeof KPartCreateBaselinePropOptions>

/** 事件 */
export const KPartCreateBaselineEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartCreateBaselineEmitsType = ViewEmitsTypeExtract<typeof KPartCreateBaselineEventEmits>

import {
  BaseViewModel,
  EnumDialogResult,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  SimpleViewModel,
  ViewModelOptions,
  KNotification
} from '@kmsoft/upf-core'
import {
  KChangeOrderActivityPlanGridDataInter,
  KChangeOrderActivityPlanGridEmitsType,
  KChangeOrderActivityPlanGridPropType
} from './interface'
import { ref } from 'vue'
import { CommonClientSrv, KObjectSelectorViewModel, ObjectClientSrv, EnumWorkingState } from '@kmsoft/ebf-common'
import { KActivityPlanDetailGrid, KActivityPlanDetailGridViewModel } from './activity-plan-detail-grid'
import lodash from 'lodash'
import { KPartStructureManage } from '../../../../ebf-part/src/controls'
import { KChangeActivityEdit } from '../../pages/change-activity/change-activity-edit'

/** KChangeOrderActivityPlanGrid */
export default class KChangeOrderActivityPlanGridViewModel extends SimpleViewModel<
  KChangeOrderActivityPlanGridEmitsType,
  KChangeOrderActivityPlanGridPropType
> {
  gridData = ref<KChangeOrderActivityPlanGridDataInter[]>([])
  refDataGrid = ref<KDataGridViewModel>()
  modified = ref<boolean>(false)
  constructor(options: ViewModelOptions<KChangeOrderActivityPlanGridPropType>) {
    super(options)
  }

  viewDidMount() {}

  public setValue(newValue: any, setChanged?: boolean) {
    this.gridData.value = lodash.cloneDeep(newValue)
  }

  /** 添加活动项 **/
  addActivity(item: KChangeOrderActivityPlanGridDataInter) {
    this.modified.value = true
    this.gridData.value.push(item)
  }

  addActivityItem(id: string, items: any[]) {
    const find = this.gridData.value.find(v => v.id === id)
    if (find) {
      const affectedObjectList = find.affectedObjectList
      // 如果有重复的不添加
      const addItem = items.filter(item => !affectedObjectList?.some(v => v.id === item.id)) || []
      if (addItem.length > 0) {
        this.modified.value = true
      }
      find.affectedObjectList?.push(...addItem)
    }
  }

  /** 移除 **/
  removeData() {
    const selectedRows = this.refDataGrid.value?.getSelectedRows() || []
    if (selectedRows.length > 0) {
      this.modified.value = true
      this.gridData.value = this.gridData.value.filter(item => !selectedRows.some(row => row.id === item.id))
    }
  }

  /** 查看受影响对象详情 **/
  openAffectedObjectDetail(row: KChangeOrderActivityPlanGridDataInter) {
    KDialog.show({
      title: '关联的对象类',
      size: { width: 800, height: 600 },
      props: {
        ...this.props,
        dataSource: row.affectedObjectList,
        onRemoveAffectedObject: (item: any) => this.removeAffectedObject(row, item)
      },
      showCancel: false,
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KActivityPlanDetailGrid,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
      }
    })
  }
  /** 打开变更活动 **/
  openChangeActivity(row: any) {
    if (!row.createTime) {
      KNotification.warn({
        message: '系统提示',
        description: '当前对象未创建，无法查看详情'
      })
      return
    }
    CommonClientSrv.openPage(`变更活动:${row.number}:${row.name}`, KChangeActivityEdit, {
      objParam: { id: row.id, modelCode: 'ChangeActivity', modelGroup: 'change' }
    })
  }

  /** 删除受影响对象 **/
  removeAffectedObject(row: KChangeOrderActivityPlanGridDataInter, item: any) {
    const findIndex = row.affectedObjectList!.findIndex((affectObject: any) => affectObject?.id === item?.id)

    if (findIndex >= 0) {
      row.affectedObjectList!.splice(findIndex, 1)
    }
  }

  /** 根据ID删除受影响对象 **/
  removeAffectedObjectByIds(ids: string[]) {
    this.gridData.value.forEach(row => {
      row.affectedObjectList = row.affectedObjectList?.filter(item => !ids.includes(item.id))
    })
  }

  /** 刷新 **/
  refresh() {}

  isModified(): boolean {
    // 编辑时默认认为修改
    return this.modified.value
  }

  getModifiedValue() {
    return this.getValue()
  }

  public getValue() {
    return this.gridData.value || []
  }
}

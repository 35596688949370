import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KWorkflowTemplatePropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KWorkflowTemplatePropType = ViewPropsTypeExtract<typeof KWorkflowTemplatePropOptions>

/** 事件 */
export const KWorkflowTemplateEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KWorkflowTemplateEmitsType = ViewEmitsTypeExtract<typeof KWorkflowTemplateEventEmits>

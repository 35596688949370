import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineCompareView from './KBaselineCompare.vue'
import KBaselineCompareViewModel from './KBaselineCompareViewModel'

const KBaselineCompare = connect(KBaselineCompareView, KBaselineCompareViewModel)

export default withInstall(KBaselineCompare)
export { KBaselineCompare, KBaselineCompareView, KBaselineCompareViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KProductWorkspaceView from './KProductWorkspace.vue'
import KProductWorkspaceViewModel from './KProductWorkspaceViewModel'

const KProductWorkspace = connect(KProductWorkspaceView, KProductWorkspaceViewModel)

export default withInstall(KProductWorkspace)
export { KProductWorkspace, KProductWorkspaceView, KProductWorkspaceViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_folder_selector = _resolveComponent("k-folder-selector")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_form = _resolveComponent("k-form")!
  const _component_k_button = _resolveComponent("k-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_k_form, {
      ref: "refFolderForm",
      model: _ctx.$vm.formDataState,
      "label-col": { span: 8 },
      "wrapper-col": { span: 16 },
      autocomplete: "off"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_k_form_item, {
          label: "文件夹",
          name: "folderId",
          "has-feedback": "",
          rules: [{ required: true, message: '请输入' }]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_folder_selector, {
              value: _ctx.$vm.folderId,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.folderId) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_k_button, {
      onClick: _ctx.$vm.changeFolderId
    }, {
      default: _withCtx(() => [
        _createTextVNode("查询当前文件夹")
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}

import { defineView } from '@kmsoft/upf-core'
import { KDocFilePropertyPropOptions, KDocFilePropertyEventEmits } from './interface'
import KDocFilePropertyViewModel from './KDocFilePropertyViewModel'

export default defineView({
  name: 'KDocFileProperty',
  props: KDocFilePropertyPropOptions,
  emits: KDocFilePropertyEventEmits,
  viewModel: KDocFilePropertyViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

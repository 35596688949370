import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KPlanCreateFormEmitsType, KPlanCreateFormPropType } from './interface'
import { ref } from 'vue'
import { Api, KObjectPropertyPanelViewModel } from '@kmsoft/ebf-common'

/** KPlanCreateForm */
export default class KPlanCreateFormViewModel extends BaseViewModel<KPlanCreateFormEmitsType, KPlanCreateFormPropType> {
  /** 创建面板 */
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  /** 默认加载面板值 */
  formData = ref<any>()

  constructor(options: ViewModelOptions<KPlanCreateFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  loaded() {}

  /**
   * 校验
   */
  validate() {
    return true
  }

  /**
   * 创建验证计划
   */
  save(req: any, reqAndMetricsLinks: any) {
    const createObject = {
      ...this.refObjectCreatePanel.value?.getValue(),
      req: req,
      reqAndMetricsLinks: reqAndMetricsLinks
    } as any
    if (createObject.secondary) {
      createObject.secondary = createObject.secondary.map((element: string) => {
        return { id: element }
      })
    }
    return Api.post('requirement', 'VerificationPlan', 'create', { data: [createObject] })
  }
}

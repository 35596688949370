import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDateEditorCustomPropType, KDateEditorCustomPropsEventEmitsType, IDateEditorFormDefinition } from './interface'
import { ref, watch } from 'vue'

export class KDateEditorFormViewModel extends BaseViewModel<KDateEditorCustomPropsEventEmitsType, KDateEditorCustomPropType> {
  formState = ref<IDateEditorFormDefinition>()

  constructor(options: ViewModelOptions<KDateEditorCustomPropType>) {
    super(options)

    watch(
      () => options.props.propertyId,
      () => {
        // 判断editArgs是否为空
        const data = options.props.editArgs ? JSON.parse(options.props.editArgs) : {}
        this.formState.value = data
      },
      { immediate: true }
    )
  }

  // 设置表单的值
  setValue(formState: IDateEditorFormDefinition) {
    this.formState.value = formState
  }

  // 获取表单的值
  getValue() {
    return this.formState.value
  }
}

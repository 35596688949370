import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KCustomerNeedsSectionCreateEmitsType, KCustomerNeedsSectionCreatePropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api, EnumWorkingState } from '@kmsoft/ebf-common'
import lodash from 'lodash'

/** KCustomerNeedsSectionCreate */
export default class KCustomerNeedsSectionCreateViewModel extends BaseViewModel<
  KCustomerNeedsSectionCreateEmitsType,
  KCustomerNeedsSectionCreatePropType
> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  formData = ref<any>({
    number: '',
    name: '',
    description: '',
    category: '',
    additionalComments: '',
    secondaryIds: null
  })

  constructor(options: ViewModelOptions<KCustomerNeedsSectionCreatePropType>) {
    super(options)
  }

  viewDidMount() {}

  getValue() {
    const createObject = lodash.cloneDeep(this.refObjectCreatePanel.value?.getValue())
    return createObject
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  async save() {
    const createObject = this.getValue()

    if (this.props.formValue.id) {
      createObject!.parent = {
        id: this.props.formValue.id,
        clazz: this.props.formValue.rdmExtensionType
      }
    }
    const params = {
      data: [
        {
          ...createObject
        }
      ]
    }
    return await Api.post('requirement', `${this.props.formValue.rdmExtensionType}Section`, 'create', params)
  }
}

import { App, Plugin } from 'vue'
import KChangeActivityObjGrid from './change-activity-obj-grid'
import KIssueReportAffectedViewsGrid from './issue-report-affected-views'
import KChangeOrderAffectedObjectGrid from './change-order-affected-object-grid'
import KChangeOrderActivityPlanGrid from './change-order-activity-plan-grid'
import KPromoteActivityBeforeGrid from './promote-activity-before-grid'
import KPromoteActivityAfterGridfrom from './promote-activity-after-grid'
import KChangeRequestAffectedViewsGrid from './change-request-affected-views'

const components: Record<string, Plugin> = {
  KChangeActivityObjGrid,
  KIssueReportAffectedViewsGrid,
  KChangeRequestAffectedViewsGrid,
  KChangeOrderAffectedObjectGrid,
  KChangeOrderActivityPlanGrid,
  KPromoteActivityBeforeGrid,
  KPromoteActivityAfterGridfrom
}

const install = function(app: App) {
  Object.keys(components).forEach(key => {
    app.use(components[key])
  })
}

export default {
  install: install
}

import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectManageView from './KObjectManage.vue'
import KObjectManageViewModel from './KObjectManageViewModel'

const KObjectManage = connect(KObjectManageView, KObjectManageViewModel)

export default withInstall(KObjectManage)
export { KObjectManage, KObjectManageView, KObjectManageViewModel }

// 模板生成文件
// export * from './{{folderName}}'


import { defineView } from '@kmsoft/upf-core'
import { KImageEditorPropOptions, KImageEditorEventEmits } from './interface'
import KImageEditorViewModel from './KImageEditorViewModel'

export default defineView({
  name: 'KImageEditor',
  props: KImageEditorPropOptions,
  emits: KImageEditorEventEmits,
  viewModel: KImageEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})

import lodash from 'lodash'
import { createVNode, resolveComponent, Component, ConcreteComponent, VNodeProps } from 'vue'
import { defineView, KTabs, ToolStripItemClickedEventArgs } from '@kmsoft/upf-core'
import {
  KObjectPanelTabPropOptions,
  KObjectPanelPropOptions,
  KObjectPanelEventEmits,
  ObjectPanelTabDataUpdatedEvent,
  ObjectPanelTabRetrieveEvent
} from './interface'
import KObjectPanelViewModel from './KObjectPanelViewModel'

export default defineView({
  name: 'KObjectPanel',
  inheritAttrs: false,
  props: KObjectPanelPropOptions,
  emits: KObjectPanelEventEmits,
  viewModel: KObjectPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      if (!vm.hasPreview.value) {
        return <k-empty description="暂无当前对象权限" />
      }

      if (!vm.isObjExisted.value) {
        return <k-empty description="对象不存在" />
      }

      /**
       * 渲染页签
       * @returns
       */
      const renderTabs = () => {
        const tabPanels: Array<JSX.Element> = []

        for (const config of vm.tabConfigs.value.filter(a => a.visible == true)) {
          /** 标签页参数 */
          let params = config.params

          // 如果参数是回调返回
          if (lodash.isFunction(config.params)) {
            params = config.params()
          }

          // 组件参数
          const tabPanelProps = {
            ref: (ref: any) => {
              config.ref = ref
            },
            ...params,
            ...lodash.pick(props, Object.keys(KObjectPanelTabPropOptions)),
            objParam: vm.mainObjParam.value,
            tabId: config.tabId,
            container: props.container,
            onDataUpdated: (event: ObjectPanelTabDataUpdatedEvent) => vm.onDataUpdated(event, config),
            onRetrieve: (event: ObjectPanelTabRetrieveEvent) => vm.onRetrieve(event, config),
            onItemClicked: (event: ToolStripItemClickedEventArgs) => {
              emit('itemClicked', event)
            },
            onVnodeMounted: () => vm.onTabMounted(config),
            onReady: () => vm.onTabReady(config),
            attachParams: props.attachParams
          }

          // 获取组件
          let component: Component

          if (lodash.isString(config.component)) {
            component = resolveComponent(config.component) as ConcreteComponent
          } else {
            component = config.component
          }

          // 组合标签页
          const tabPanel = (
            <k-tab-pane tab={config.title} key={config.tabId}>
              {createVNode(component, tabPanelProps)}
            </k-tab-pane>
          )

          tabPanels.push(tabPanel)
        }

        return tabPanels
      }

      /**
       * 渲染 Tabs
       * @returns
       */
      const renderTab = () => {
        if (vm.tabConfigs.value.length <= 0) {
          return <k-empty description="未配置任何标签页" />
        }

        /** 标签参数 */
        const tabProps = {
          ref: vm.refTabs,
          class: 'k-object-panel-tabs',
          type: 'line',
          activeKey: vm.activeKey.value,
          onChange: (tabId: string) => vm.onTabChange(tabId)
        }

        const tabs = renderTabs()

        return createVNode(KTabs, tabProps, tabs)
      }

      /** 渲染骨架屏 */
      const renderSkeleton = () => {
        // 只有加载时才显示骨架屏
        if (!vm.isLoading.value) {
          return
        }

        return (
          <div class="skeleton">
            <div class="skeleton-row">
              <k-space>
                <k-skeleton-button active />
                <k-skeleton-button active />
                <k-skeleton-button active />
              </k-space>
            </div>
            <div class="skeleton-row">
              <k-skeleton active />
            </div>
            <div class="skeleton-row">
              <k-skeleton active />
            </div>
            <div class="skeleton-row">
              <k-skeleton active />
            </div>
            <div class="skeleton-row">
              <k-skeleton active />
            </div>
          </div>
        )
      }

      return (
        <div class="k-object-panel">
          {renderSkeleton()}
          {renderTab()}
        </div>
      )
    }
  }
})

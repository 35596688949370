
import { defineView } from '@kmsoft/upf-core'
import { KChangeOrderActivityPlanGridPropOptions, KChangeOrderActivityPlanGridEventEmits } from './interface'
import KChangeOrderActivityPlanGridViewModel from './KChangeOrderActivityPlanGridViewModel'
import { EnumActivityType, clsCodeMap, EnumLifecycleState, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import KActivityPlanDetailGrid from './activity-plan-detail-grid'

export default defineView({
  name: 'KChangeOrderActivityPlanGrid',
  components: { KActivityPlanDetailGrid },
  props: KChangeOrderActivityPlanGridPropOptions,
  emits: KChangeOrderActivityPlanGridEventEmits,
  viewModel: KChangeOrderActivityPlanGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      EnumActivityType: EnumActivityType,
      clsCodeMap,
      EnumLifecycleState,
      EnumLifecycleStateColor
    }
  }
})

import { EnumSizeType, defineView, VNodeProps } from '@kmsoft/upf-core'
import { KObjectSearchPropOptions, KObjectSearchEventEmits } from './interface'
import KObjectSearchViewModel from './KObjectSearchViewModel'
import { createVNode } from 'vue'
import { KObjectSearcher, KObjectSearcherEmitsType, KObjectSearcherPropType } from '../object-searcher'
import {
  EnumObjClassManageToolStripOptions,
  KObjectClassGrid,
  KObjectClassGridEventEmitsType,
  KObjectClassGridPropType
} from '../object-class-grid'
import { ClassMetaClientSrv, ObjectClientSrv } from '../../client-srv'

export default defineView({
  name: 'KObjectSearch',
  props: KObjectSearchPropOptions,
  emits: KObjectSearchEventEmits,
  viewModel: KObjectSearchViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      /** 渲染搜索组件 */
      const renderSearcher = () => {
        const searcherProps: VNodeProps<KObjectSearcherPropType, KObjectSearcherEmitsType> = {
          ref: vm.refIKObjectSearcher,
          ...vm.objectClassTreeProps.value,
          includeChildrenCheck: true,
          showSearch: true,
          showCancel: true,
          onSearch: vm.onSearch,
          onCancel: vm.onCancel,
          'onUpdate:modelCode': async (value: string) => {
            vm.modelCode.value = value
            vm.modelGroup.value = await ObjectClientSrv.getModelGroupByCode(value)
          }
        }

        return createVNode(KObjectSearcher, searcherProps)
      }

      /** 渲染网格 */
      const renderGrid = () => {
        if (!vm.modelCode.value) {
          return <k-empty description="请选择对象类" />
        }

        const objectClassGridProps: VNodeProps<KObjectClassGridPropType, KObjectClassGridEventEmitsType> = {
          ref: vm.refObjectClassGrid,
          modelCode: vm.modelCode.value,
          modelGroup: vm.modelGroup.value,
          filter: vm.filter.value,
          pagination: { pageIndex: 1, pageSize: 50 },
          onRowDoubleClick: vm.onRowDoubleClick,
          toolStripOptions:
            EnumObjClassManageToolStripOptions.ShowObjLifeTime |
            EnumObjClassManageToolStripOptions.ShowOperation |
            EnumObjClassManageToolStripOptions.ShowGridOperation,
          class: 'class-content'
        }
        return createVNode(KObjectClassGrid, objectClassGridProps)
      }

      return (
        <k-split-container sizeUnit={EnumSizeType.Pixel}>
          <k-split-pane size="420" minSize="420">
            {renderSearcher()}
          </k-split-pane>
          <k-split-pane>{renderGrid()}</k-split-pane>
        </k-split-container>
      )
    }
  }
})

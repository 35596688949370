import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectPropertyEditPanelView from './KObjectPropertyEditPanel'
import KObjectPropertyEditPanelViewModel from './KObjectPropertyEditPanelViewModel'
import { KObjectPropertyEditPanelBase } from './controls'

const KObjectPropertyEditPanel = connect(KObjectPropertyEditPanelView, KObjectPropertyEditPanelViewModel)

export default withInstall(KObjectPropertyEditPanel, { KObjectPropertyEditPanelBase: KObjectPropertyEditPanelBase })
export { KObjectPropertyEditPanel, KObjectPropertyEditPanelView, KObjectPropertyEditPanelViewModel }
export * from './controls'
export * from './interface'

import { IAppConfigurer, IMenuPagePathMapping, IocContainer, UPF_IOC_KEYS } from '@kmsoft/upf-core'
import { App } from 'vue'
import { KVueBrowser,KModelBrowser } from './components'

export default {
  configVue: (app: App) => {
    app.use(KVueBrowser)
  },
  configBean: (iocContainer: IocContainer) => {}
} as IAppConfigurer

export { KVueBrowser,KModelBrowser }

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KProductNeedsCreatePropOptions = {
  ...BaseViewPropOptions,
  customerNeeds: VuePropTypes.createType<any>().def(),
  formValue: VuePropTypes.createType<any>().def()
}

/** 参数类型 **/
export type KProductNeedsCreatePropType = ViewPropsTypeExtract<typeof KProductNeedsCreatePropOptions>

/** 事件 */
export const KProductNeedsCreateEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KProductNeedsCreateEmitsType = ViewEmitsTypeExtract<typeof KProductNeedsCreateEventEmits>

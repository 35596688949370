import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_radio_group = _resolveComponent("k-radio-group")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    ref: _ctx.$vm.refForm,
    model: _ctx.$vm.formData,
    "label-col": { span: 4 },
    "wrapper-col": { span: 16 }
  }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_k_form_item, {
        label: "结论",
        name: "describe"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_radio_group, {
            value: _ctx.$vm.formData.radioValue,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formData.radioValue) = $event)),
            options: _ctx.$vm.radioOptions
          }, null, 8, ["value", "options"])
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.type == 'submit']
      ]),
      _createVNode(_component_k_form_item, {
        label: "意见",
        name: "describe",
        rules: [{ required: true, message: '意见不能为空', trigger: 'blur' }]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_textarea, {
            value: _ctx.$vm.formData!.describe,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formData!.describe) = $event)),
            maxlength: 170,
            rows: 3
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"width":"100%","height":"100%"},
  name: "baseline-member"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_object_class_grid, {
      ref: "refObjGrid",
      modelCode: "BaselineMember",
      modelGroup: "baseline",
      onToolStripItemClicked: _ctx.$vm.toolStripItemClicked,
      toolStripItems: _ctx.$vm.toolItem,
      loadData: _ctx.$vm.loadData,
      showToolStrip: !_ctx.readonly,
      selectFirstRow: false
    }, null, 8, ["onToolStripItemClicked", "toolStripItems", "loadData", "showToolStrip"])
  ]))
}
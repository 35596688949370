export enum EnumDdbOperation {
  /**提取信息到XML文件 */
  OutXml = 0,

  /**更新文件（含签字） */
  Update = 1,

  /**读取线缆信息到结构（暂支持proe）需添加线缆结构头文件 */
  Cable = 2,

  /**读取标注信息到结构（暂支持catia） */
  Annot = 3,

  /**生成PDF文件 */
  Topdf = 4,

  /**EPlan根据模板创建项目 */
  EPlanTemplateCreate = 5
}

import { VNode } from 'vue'
import { KCommonObjectCreateFormEmitsType, KCommonObjectCreateFormPropType } from './interface'
import { KObjectCreatePanelFormBaseViewModel } from '../base'
import { ObjectCreateLoadDataParams } from '../../interface'
import { ObjBusinessResult, ObjectClientSrv } from '../../../../client-srv'

/** KCommonObjectCreateForm */
export default class KCommonObjectCreateFormViewModel extends KObjectCreatePanelFormBaseViewModel<
  KCommonObjectCreateFormEmitsType,
  KCommonObjectCreateFormPropType
> {
  /** 加载完成函数 */
  viewDidMount() {
    super.viewDidMount()
  }
}

import { EnumControlElementType, EnumDataType, EnumType } from '@kmsoft/upf-core'
import { KDesignerControl } from '../types'

/** 工具栏 */
export const TOOL_STRIP_CONTROLS: Array<KDesignerControl> = [
  {
    name: 'toolStrip',
    title: '工具栏',
    control: EnumControlElementType.TOOL_STRIP,
    icon: 'tool-strip',
    type: EnumType.CONTAINER,
    controlType: '工具栏',
    dataType: '',
    events: [],
    attrs: []
  },
  {
    name: 'toolStripButtonItem',
    title: '工具栏按钮',
    control: EnumControlElementType.TOOL_STRIP_BUTTON,
    icon: 'tool-strip',
    type: EnumType.CONTROL,
    controlType: '工具栏',
    dataType: '',
    events: [],
    attrs: [],
    visible: false
  },
  {
    name: 'toolStripCheckboxItem',
    title: '工具栏多选按钮',
    control: EnumControlElementType.TOOL_STRIP_CHECKBOX,
    icon: 'tool-strip',
    type: EnumType.CONTROL,
    controlType: '工具栏',
    dataType: '',
    events: [],
    attrs: [],
    visible: false
  },
  {
    name: 'toolStripDateInputItem',
    title: '工具栏日期输入框',
    control: EnumControlElementType.TOOL_STRIP_DATETIME,
    icon: 'tool-strip',
    type: EnumType.CONTROL,
    controlType: '工具栏',
    dataType: '',
    events: [],
    attrs: [],
    visible: false
  },
  {
    name: 'toolStripInputItem',
    title: '工具栏输入框',
    control: EnumControlElementType.TOOL_STRIP_INPUT,
    icon: 'tool-strip',
    type: EnumType.CONTROL,
    controlType: '工具栏',
    dataType: '',
    events: [],
    attrs: [],
    visible: false
  },
  {
    name: 'toolStripRadioItem',
    title: '工具栏单选框',
    control: EnumControlElementType.TOOL_STRIP_RADIO,
    icon: 'tool-strip',
    type: EnumType.CONTROL,
    controlType: '工具栏',
    dataType: '',
    events: [],
    attrs: [],
    visible: false
  },
  {
    name: 'toolStripSelectItem',
    title: '工具栏下拉框',
    control: EnumControlElementType.TOOL_STRIP_SELECT,
    icon: 'tool-strip',
    type: EnumType.CONTROL,
    controlType: '工具栏',
    dataType: '',
    events: [],
    attrs: [],
    visible: false
  }
]

/** 默认控件 */
export const DEFAULT_CONTROL: Array<KDesignerControl> = [
  {
    name: 'segment',
    title: '页段',
    control: 'KSegment',
    icon: 'segment',
    type: EnumType.CONTAINER,
    events: [],
    attrs: [],
    controlType: '容器',
    dataType: ''
  },
  {
    name: 'form',
    title: '表单',
    control: 'KFormContainer',
    icon: 'form',
    type: EnumType.CONTAINER,
    events: [],
    attrs: [],
    controlType: '数据容器',
    dataType: ''
  },
  {
    name: 'grid-head',
    title: '表格头部栏',
    control: 'KGridHeader',
    icon: 'form',
    type: EnumType.CONTAINER,
    events: [],
    attrs: [],
    controlType: '数据容器',
    dataType: ''
  },
  {
    name: 'grid',
    title: '表格',
    control: 'KDataGrid',
    icon: 'table',
    type: EnumType.CONTROL,
    events: [],
    attrs: [],
    controlType: '数据容器',
    dataType: ''
  },
  {
    name: 'filterGrid',
    title: '筛选网格',
    control: 'KFilterGrid',
    icon: 'filter-grid',
    type: EnumType.CONTROL,
    events: [],
    attrs: [],
    controlType: '数据容器',
    dataType: ''
  },
  {
    name: 'button',
    title: '按钮',
    control: 'KButton',
    icon: 'button',
    type: EnumType.CONTROL,
    events: [],
    attrs: [],
    controlType: '按钮控件',
    dataType: ''
  },
  {
    name: 'dynamicView',
    title: '动态视图',
    control: 'KDynamicView',
    icon: 'tool',
    type: EnumType.CONTAINER,
    events: [],
    attrs: [],
    controlType: '容器',
    dataType: '',
    visible: false
  },
  {
    name: 'label',
    title: '文字',
    control: 'KLabel',
    icon: 'button',
    type: EnumType.CONTROL,
    events: [],
    attrs: [],
    controlType: '基础',
    dataType: ''
  },
  ...TOOL_STRIP_CONTROLS
  // {
  //   name: 'dropdownButton',
  //   title: '下拉按钮',
  //   control: 'KDropDown',
  //   icon: 'dropdown-btn',
  //   type: EnumType.CONTROL,
  //   events: [],
  //   attrs: [],
  //   controlType: '按钮控件'
  // }
]

/** 基础控件 */
export const BASE_CONTROL: Array<KDesignerControl> = [
  {
    name: 'input',
    title: '文本框',
    control: 'KInput',
    icon: 'input',
    type: EnumType.CONTROL,
    events: [],
    attrs: [],
    controlType: '基础控件',
    dataType: EnumDataType.STRING
  },
  {
    name: 'textArea',
    title: '多行文本',
    control: 'KTextarea',
    icon: 'textarea',
    type: EnumType.CONTROL,
    events: [],
    attrs: [],
    controlType: '基础控件',
    dataType: EnumDataType.STRING
  },
  {
    name: 'datetime',
    title: '日期框',
    control: 'KDateTime',
    icon: 'date-time',
    type: EnumType.CONTROL,
    events: [],
    attrs: [],
    controlType: '基础控件',
    dataType: EnumDataType.DATETIME
  },
  {
    name: 'number',
    title: '数字框',
    control: 'KInputNumber',
    icon: 'number-input',
    type: EnumType.CONTROL,
    events: [],
    attrs: [],
    controlType: '基础控件',
    dataType: EnumDataType.INT
  },
  {
    name: 'checkBox',
    title: '复选框',
    control: 'KCheckbox',
    icon: 'checkbox',
    type: EnumType.CONTROL,
    events: [],
    attrs: [],
    controlType: '基础控件',
    dataType: EnumDataType.INT
  },
  {
    name: 'combobox',
    title: '下拉框',
    control: 'KSelect',
    icon: 'dropdown',
    type: EnumType.CONTROL,
    events: [],
    attrs: [],
    controlType: '基础控件',
    dataType: EnumDataType.INT
  }
]

import { BaseViewModel, EnumDialogResult, EnumDialogSize, KDialog, KDialogClosingEvent, ViewModelOptions } from '@kmsoft/upf-core'
import { ObjectCreatePanelTestEmitsType, ObjectCreatePanelTestPropType } from './interface'
import { EnumObjCopyMode, KClassPropertySelector, KClassPropertySelectorViewModel, ObjectClientSrv } from '@kmsoft/ebf-common'

/** ObjectCreatePanelTest */
export default class ObjectCreatePanelTestViewModel extends BaseViewModel<
  ObjectCreatePanelTestEmitsType,
  ObjectCreatePanelTestPropType
> {
  constructor(options: ViewModelOptions<ObjectCreatePanelTestPropType>) {
    super(options)
  }

  viewDidMount() {}
  async createClass() {
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Part']
      },
      onClosing: async event => {}
    })
    // const createResult = await ObjectClientSrv.openCreateDialog({
    //   objCopyMode: EnumObjCopyMode.NewObj,
    //   forceShowObjClsSelector: true
    // })
    // if (createResult) {
    //   console.log(createResult.objBusiness)
    // }
    // //显示弹窗
    // KDialog.show({
    //   title: '添加属性',
    //   size: EnumDialogSize.Fat,
    //   movable: true, //可移动
    //   floatFooter: true,
    //   content: KClassPropertySelector,
    //   onClosing: async (event: KDialogClosingEvent) => {
    //     if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
    //       return
    //     }
    //     const vm = event.viewModel as KClassPropertySelectorViewModel
    //     const rows = vm.getSelectedRows()
    //     console.log('rows', rows)
    //   }
    // })
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KFlowDetailUpdateExecutorPropOptions = {
  ...BaseViewPropOptions,
  processInstanceId: VuePropTypes.string().def(''),
  executorGridData: VuePropTypes.array<any[]>().def([])
}

/** 参数类型 **/
export type KFlowDetailUpdateExecutorPropType = ViewPropsTypeExtract<typeof KFlowDetailUpdateExecutorPropOptions>

/** 事件 */
export const KFlowDetailUpdateExecutorEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KFlowDetailUpdateExecutorEmitsType = ViewEmitsTypeExtract<typeof KFlowDetailUpdateExecutorEventEmits>

import { IocContainer, useMemoryCache } from '@kmsoft/upf-core'

export * from './memory-cache'
import { MemoryCacheRepository } from './memory-cache'
export * from './local-storage-cache'

/** 使用缓存 */
export const useCache = (iocContainer: IocContainer) => {
  useMemoryCache(iocContainer, MemoryCacheRepository)
}

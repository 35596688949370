import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { IKFileFormDefinition, KFileUploadEmitsType, KFileUploadPropType } from './interface'
import { ref, watch } from 'vue'

/** KFileUpload */
export default class KFileUploadViewModel extends BaseViewModel<KFileUploadEmitsType, KFileUploadPropType> {
  formState = ref<IKFileFormDefinition>()
  constructor(options: ViewModelOptions<KFileUploadPropType>) {
    super(options)
    watch(
      () => options.props.propertyId,
      () => {
        // 判断editArgs是否为空
        const data = options.props.editArgs ? JSON.parse(options.props.editArgs) : {}
        this.formState.value = data as IKFileFormDefinition
      },
      { immediate: true }
    )
  }

  setValue(value: IKFileFormDefinition) {
    this.formState.value = value
  }
  getValue() {
    return this.formState.value
  }
}


import { defineView, utils } from '@kmsoft/upf-core'
import { KDateEditorFormViewModel } from './KDateEditorFormViewModel'
import { KDateEditorCustomPropOptions } from './interface'
import { EnumDateFormat } from '../../../enums'
import dayjs from 'dayjs'
import { computed } from 'vue'

export default defineView({
  name: 'DateEditorForm', // 组件名称
  components: {}, // 这里定义局部组件
  viewModel: KDateEditorFormViewModel,
  props: KDateEditorCustomPropOptions,
  setup(props, { vm }) {
    const onDateFormatChange = (value: any) => {
      if (value.newValue) {
        vm.formState.value!.format = value.newValue
        vm.formState.value!.sample = dayjs().format(value.newValue)
      } else {
        vm.formState.value!.format = ''
        vm.formState.value!.sample = ''
      }
    }

    const dateFormatOptions = computed(() => {
      const dateFormatList = utils.getNames(EnumDateFormat)
      return dateFormatList.map((item, index) => {
        return { label: item, value: item }
      })
    })
    return {
      // 日期格式说明
      dateDescribe: '提示: Y-年 M-月 D-日 H-时 m-分 s-秒',
      onDateFormatChange,
      dateFormatOptions
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KSelectFillEditorPropOptions, KSelectFillEditorEventEmits } from './interface'
import KSelectFillEditorViewModel from './KSelectFillEditorViewModel'

/** 选择填写 */
export default defineView({
  name: 'KSelectFillEditor',
  props: KSelectFillEditorPropOptions,
  emits: KSelectFillEditorEventEmits,
  viewModel: KSelectFillEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createBlock(_component_k_data_grid, {
    isMultipleSelection: true,
    rowKey: "id",
    ref: "refRefPropertyGrid",
    onSelectionChanged: _cache[0] || (_cache[0] = (rowData) => _ctx.$vm.rowSelectionChanged(rowData))
  }, {
    columns: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
        return (_openBlock(), _createBlock(_component_k_data_grid_text_box_column, {
          key: column.name,
          id: column.name,
          name: `vm-${column.name}`,
          dataPropertyName: column.name,
          headerText: column.title,
          width: column.width,
          align: "center"
        }, null, 8, ["id", "name", "dataPropertyName", "headerText", "width"]))
      }), 128))
    ]),
    _: 1
  }, 512))
}
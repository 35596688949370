import { connect, withInstall } from '@kmsoft/upf-core'
import KDocSelectFilesView from './KDocSelectFiles.vue'
import KDocSelectFilesViewModel from './KDocSelectFilesViewModel'

const KDocSelectFiles = connect(KDocSelectFilesView, KDocSelectFilesViewModel)

export default withInstall(KDocSelectFiles)
export { KDocSelectFiles, KDocSelectFilesView, KDocSelectFilesViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KChangeActivityRelationshipView from './KChangeActivityRelationship.vue'
import KChangeActivityRelationshipViewModel from './KChangeActivityRelationshipViewModel'

const KChangeActivityRelationship = connect(KChangeActivityRelationshipView, KChangeActivityRelationshipViewModel)

export default withInstall(KChangeActivityRelationship)
export { KChangeActivityRelationship, KChangeActivityRelationshipView, KChangeActivityRelationshipViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { BaseViewModel, KViewSchema, KViewSchemaWithId, ValueChangeEventArgs, ViewModelOptions } from '@kmsoft/upf-core'
import { KFilterGridViewDesignerEventEmitsType, KFilterGridViewDesignerPropType } from './interface'
import { ref } from 'vue'
import lodash from 'lodash'

/** KFilterGridViewDesigner 视图模型 */
export class KFilterGridViewDesignerViewModel extends BaseViewModel<
  KFilterGridViewDesignerEventEmitsType,
  KFilterGridViewDesignerPropType
> {
  stateValue = ref<Array<KViewSchema>>([])
  originalValue = ref<Array<KViewSchema>>([])

  constructor(options: ViewModelOptions<KFilterGridViewDesignerPropType>) {
    super(options)
    this.stateValue.value = lodash.cloneDeep(options.props.value)
  }

  /**
   * 加载完成函数
   */
  viewDidMount() {}

  /**
   * 设置模型托管的值
   * 根据 setChanged 判断是否更新originalValue的值
   * @param value 值
   */
  public setValue(value: any, setChanged?: boolean) {
    const oldValue = this.getValue(),
      eventArgs = new ValueChangeEventArgs(oldValue, value)
    if (setChanged) {
      this.originalValue.value = value
    }
    this.emit('beforeChange', eventArgs)
    this.stateValue.value = value
    this.emit('change', eventArgs)
  }

  /**
   * 获取模型托管的值
   */
  public getValue() {
    return this.stateValue.value
  }
}

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_radio = _resolveComponent("k-radio")!
  const _component_k_radio_group = _resolveComponent("k-radio-group")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_k_form, {
      model: _ctx.$vm.formState,
      "label-col": { span: 8 },
      "wrapper-col": { span: 16 }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_k_form_item, { name: "checkType" }, {
          default: _withCtx(() => [
            _createVNode(_component_k_radio_group, {
              value: _ctx.$vm.formState!.indeterminate,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formState!.indeterminate) = $event)),
              readonly: _ctx.readonly
            }, {
              default: _withCtx(() => [
                _createVNode(_component_k_radio, { value: "0" }, {
                  default: _withCtx(() => [
                    _createTextVNode("两态")
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_radio, { value: "1" }, {
                  default: _withCtx(() => [
                    _createTextVNode("三态")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "readonly"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_menu_item = _resolveComponent("k-menu-item")!
  const _component_k_menu = _resolveComponent("k-menu")!
  const _component_k_dropdown = _resolveComponent("k-dropdown")!
  const _component_k_tooltip = _resolveComponent("k-tooltip")!
  const _component_k_input_group = _resolveComponent("k-input-group")!

  return (_openBlock(), _createBlock(_component_k_input_group, {
    class: "k-document-selector",
    compact: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_input, {
        value: _ctx.$vm.selectedDocValue?.name,
        disabled: ""
      }, {
        addonBefore: _withCtx(() => [
          _createVNode(_component_k_icon, { type: "link" }),
          _createTextVNode(" 文件 ")
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_k_dropdown, null, {
        overlay: _withCtx(() => [
          _createVNode(_component_k_menu, {
            onClick: _ctx.$vm.onMenuClick
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_component_k_menu_item, {
                key: _ctx.EnumAddMainFileType.LocalFile
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" 引用本地文件 ")
                ]),
                _: 1
              })),
              (_ctx.mainFileAddOptions.showTemplate)
                ? (_openBlock(), _createBlock(_component_k_menu_item, {
                    key: _ctx.EnumAddMainFileType.Template
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("引用文档模板")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_k_button, null, {
            default: _withCtx(() => [
              _createTextVNode(" 引用 "),
              _createVNode(_component_k_icon, { type: "down" })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_k_tooltip, { title: "查看" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_k_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vm.onShowDocEchoList(undefined)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_icon, { type: "eye" })
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.$vm.isShowDocEchoList]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_k_tooltip, { title: "清除文件" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_button, {
            onClick: _ctx.$vm.onClear
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_icon, { type: "clear" })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
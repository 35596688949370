import { AgentInstanceObject } from './AgentInstanceObject'

export abstract class AgentGenericObject extends AgentInstanceObject {
  /**返回一个表示可用于构造当前泛型类型的泛型类型定义的 Type 对象。*/
  protected static genericTypeDefinition: string = 'KMSoft.Agent.AgentGenericObject'
  /**返回表示封闭式泛型类型的类型参数或泛型类型定义的类型参数的 Type 对象的数组。*/
  protected static genericArguments: string[] = []
  protected static get type(): string {
    return this.genericTypeDefinition + '`' + this.genericArguments.length + '[[' + this.genericArguments.join('],[') + ']]'
  }
}

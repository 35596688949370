import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "file",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vm.download()))
  }, [
    _createElementVNode("div", null, [
      (_ctx.$vm.fileInfo)
        ? (_openBlock(), _createBlock(_component_k_icon, {
            key: 0,
            type: _ctx.$vm.getIcon(),
            width: "60",
            height: "60"
          }, null, 8, ["type"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$vm.displayValue), 1)
      ])
    ])
  ]))
}
import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'

/** 参数 **/
export const ObjectCreatePanelTestPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type ObjectCreatePanelTestPropType = ViewPropsTypeExtract<typeof ObjectCreatePanelTestPropOptions>

/** 事件 */
export const ObjectCreatePanelTestEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type ObjectCreatePanelTestEmitsType = ViewEmitsTypeExtract<typeof ObjectCreatePanelTestEventEmits>

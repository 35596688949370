
import { defineView } from '@kmsoft/upf-core'
import { KWorkflowSigningHistoryPropOptions, KWorkflowSigningHistoryEventEmits } from './interface'
import KWorkflowSigningHistoryViewModel from './KWorkflowSigningHistoryViewModel'
import { ref } from 'vue'

export default defineView({
  name: 'KWorkflowSigningHistory',
  props: KWorkflowSigningHistoryPropOptions,
  emits: KWorkflowSigningHistoryEventEmits,
  viewModel: KWorkflowSigningHistoryViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    let showApprovalContentPanel = ref<boolean>(true)

    return { showApprovalContentPanel }
  }
})

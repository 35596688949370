import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KObjectClassLayoutDefinitionPropOptions = {
  ...BaseViewPropOptions,
  /** 对象类id */
  clsId: VuePropTypes.string().def(),
  /** 对象类编码 */
  clsCode: VuePropTypes.string().def(),
  /** 组件环境 */
  environment: VuePropTypes.createType<'show' | 'detail'>().def('detail')
}

/** 参数类型 **/
export type KObjectClassLayoutDefinitionPropType = ViewPropsTypeExtract<typeof KObjectClassLayoutDefinitionPropOptions>

/** 事件 */
export const KObjectClassLayoutDefinitionEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KObjectClassLayoutDefinitionEmitsType = ViewEmitsTypeExtract<typeof KObjectClassLayoutDefinitionEventEmits>

/**
 * 属性布局
 */
export interface IObjectClassLayout {
  /**
   * id
   */
  id: string
  /**
   * 名称
   */
  name: string
  /**
   * 编码
   */
  code: string
  /**
   * 类别
   */
  type: string
  /**
   * 备注
   */
  remark?: string
  /**
   * 布局定义数据
   */
  schema?: string
  clsCode?: string
  belongClsCode?: string
}

import { Api, EnumRequestCode, QueryCondition, EnumPurviewCode } from '@kmsoft/ebf-common'
import { BaseViewModel, IQuery, KDataGridViewModel, PageManager, ViewModelOptions, utils, KNotification } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KProductWorkspace } from '../product-workspace'
import { IWorkspace } from '../product-workspace/workspace-list/interface'
import { UserClientSrv } from '../../../client-srv'
import { KMyManagedProductionWorkspaceEmitsType, KMyManagedProductionWorkspacePropType } from './interface'

/** KMyManagedProductionWorkspace */
export default class KMyManagedProductionWorkspaceViewModel extends BaseViewModel<
  KMyManagedProductionWorkspaceEmitsType,
  KMyManagedProductionWorkspacePropType
> {
  /**工作区列表网格 */
  refWorkspaceGrid = ref<KDataGridViewModel>()
  /**工作区名称 */
  workspaceName = ref<string>()

  constructor(options: ViewModelOptions<KMyManagedProductionWorkspacePropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 初始化工作区网格
   */
  async loadData(qry: IQuery) {
    const param = {
      data: [
        {
          filter: {},
          isNeedTotal: true,
          sort: 'DESC',
          orderBy: 'createTime'
        },
        {
          maxPageSize: 1000,
          curPage: qry.page?.pageIndex,
          pageSize: 50,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    if (this.workspaceName.value && this.workspaceName.value != '') {
      param.data[0].filter = {
        conditions: [
          {
            conditionName: 'name',
            operator: 'like',
            conditionValues: [this.workspaceName.value]
          }
        ],
        joiner: 'and',
        ignoreStr: true
      } as QueryCondition
    }
    const result = await Api.post('folder', 'Folder', 'listMyManagedProductFolder', param)
    if (result && result.code == EnumRequestCode.SUCCESS && result.data.data) {
      return {
        rows: result.data.data,
        total: result.data.page.totalRows
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 刷新网格数据
   */
  refresh() {
    this.refWorkspaceGrid.value?.refresh()
  }

  openProductWorkspace(row: IWorkspace) {
    if (UserClientSrv.verifyPurview(EnumPurviewCode.OpenProductLibrary)) {
      PageManager.openPage(utils.uuid(), `产品库（` + row.name + `)`, KProductWorkspace, {
        workspaceId: row.id,
        workspaceName: row.name
      })
    } else {
      KNotification.warn({
        message: '权限不足',
        description: '您没有打开产品库的权限'
      })
    }
    return
  }
  search() {
    this.refresh()
  }
}

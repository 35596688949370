import { KFlexLayoutConfig, KGridLayoutConfig, VNodeProps, defineView, RenderLayoutElement } from '@kmsoft/upf-core'
import {
  KRenderLayoutPropOptions,
  KRenderLayoutEventEmits,
  DragElement,
  RenderLayoutItemSlotProps,
  EnumRenderLayoutType
} from './interface'
import KRenderLayoutViewModel from './KRenderLayoutViewModel'
import { renderSlot } from 'vue'
import { KViewRenderElement, KViewRenderElementEventEmitsType, KViewRenderElementPropType } from '../element'
import { getFillConfig, getLayoutStyle } from './utils'
import lodash from 'lodash'

export default defineView({
  inheritAttrs: false,
  name: 'KRenderLayout',
  props: KRenderLayoutPropOptions,
  emits: KRenderLayoutEventEmits,
  viewModel: KRenderLayoutViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const onElementDragIn = (event: { item: DragElement }) => {
      emit('add', event)
    }

    /**
     * 渲染元素
     * @param element
     * @returns
     */
    const renderElement = (element: RenderLayoutElement, index: number, style: Record<string, any>) => {
      /** 插槽属性 */
      const slotProps: RenderLayoutItemSlotProps = {
        element: element,
        index: index
      }
      /** 插槽内容 */
      const childNode = renderSlot(slots, 'item', slotProps)
      /** 网格布局条目参数 */
      const gridLayoutItemProps: VNodeProps<KViewRenderElementPropType, KViewRenderElementEventEmitsType> = {
        ref_key: `refViewRenderElement_${element.name}`,
        style: style,
        meta: element,
        gridRows: vm.gridRows.value,
        layoutType: props.layoutType,
        designMode: props.designMode,
        selected: props.selectedElement?.name == element.name,
        onDelete: element => vm.onElementDelete(element),
        onSelect: () => vm.onElementSelected(element),
        onTop: () => vm.elementToTop(element),
        onBottom: () => vm.elementToBottom(element),
        onUp: () => vm.elementUp(index),
        onDown: () => vm.elementDown(index)
      }

      return <KViewRenderElement {...gridLayoutItemProps}>{childNode}</KViewRenderElement>
    }

    /**
     * 渲染布局
     * @returns
     */
    const renderLayout = () => {
      /** 布局内容Class */
      const contentClass = ['k-render-layout-content']

      /** 布局内容样式 */
      let contentStyle: Record<string, any> = {}
      let elementStyle: Record<string, any> = {}

      // 如果是 grid 布局
      if (props.layoutType == EnumRenderLayoutType.GRID) {
        const config = props.containerConfig! as KGridLayoutConfig
        contentStyle = getLayoutStyle(config, vm.gridRows.value)
      } else {
        // 如果是 flex 布局
        const config = props.containerConfig! as KFlexLayoutConfig
        contentStyle = { 'flex-direction': config.direction }
      }

      /** 填充配置 */
      elementStyle = getFillConfig(props.elements, vm.gridRows.value)
      // 如果是设计模式
      if (props.designMode) {
        // 插槽
        const draggableSlots = {
          item: (slotsProps: RenderLayoutItemSlotProps) => {
            const element = slotsProps.element
            const style = elementStyle[element.name] || {}
            const childNode = renderElement(element, slotsProps.index, style)

            return childNode
          }
        }

        return (
          <k-draggable
            class={contentClass}
            style={contentStyle}
            list={props.elements}
            group={{ name: `${props.layoutType}-layout-group`, put: true }}
            handle=".render-element-mover"
            itemKey="name"
            animation={300}
            dragClass="drag-element"
            onAdd={(event: { item: DragElement }) => onElementDragIn(event)}
            v-slots={draggableSlots}
          />
        )
      }

      /** 子节点 */
      const childNodes = []

      // 循环元素
      for (let index = 0; index < props.elements.length; index++) {
        const element = props.elements[index]
        const style = elementStyle[element.name] || {}
        const childNode = renderElement(element, index, style)

        childNodes.push(childNode)
      }

      return (
        <div ref={vm.refPreviewContainer} class={contentClass} style={contentStyle}>
          {childNodes}
        </div>
      )
    }

    return () => {
      /** 布局节点 */
      const layoutVNode = renderLayout()

      const otherClass = []

      // 如果是 grid 布局
      if (props.layoutType == EnumRenderLayoutType.FLEX) {
        const config = props.containerConfig! as KFlexLayoutConfig
        otherClass.push(`${config.direction}-direction`)
      }

      /** 容器参数 */
      const containerProps = {
        ref: vm.refPreviewContainer,
        class: [`k-render-layout`, `${props.layoutType}-layout`, { 'design-mode': props.designMode }, attrs.class],
        style: attrs.style as any
      }

      return <div {...containerProps}>{layoutVNode}</div>
    }
  }
})


import { defineView } from '@kmsoft/upf-core'
import { KCustomEditorFormViewModel } from './KCustomEditorFormViewModel'
import { KCustomEditorCustomPropOptions } from './interface'
/**
 *
 */
export default defineView({
  name: 'CustomEditorForm', // 组件名称
  components: {}, // 这里定义局部组件
  viewModel: KCustomEditorFormViewModel,
  props: KCustomEditorCustomPropOptions,
  setup() {
    return {}
  }
})

import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KLoginEmitsType, KLoginPropType } from './interface'

/** KLogin */
export default class KLoginViewModel extends BaseViewModel<KLoginEmitsType, KLoginPropType> {
  constructor(options: ViewModelOptions<KLoginPropType>) {
    super(options)
  }

  viewDidMount() {}
}


import { defineView } from '@kmsoft/upf-core'
import { KChangeActivityUpdateComparePropOptions, KChangeActivityUpdateCompareEventEmits } from './interface'
import KChangeActivityUpdateCompareViewModel from './KChangeActivityUpdateCompareViewModel'
import KChangeActivityUpdateCompareGrid from './change-activity-update-compare-grid'
import KChangeActivityUpdateCompareGridDiff from './change-activity-update-compare-grid-diff'
import { computed, ref } from 'vue'
import { SelectItem } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KChangeActivityUpdateCompare',
  components: { KChangeActivityUpdateCompareGrid, KChangeActivityUpdateCompareGridDiff },
  props: KChangeActivityUpdateComparePropOptions,
  emits: KChangeActivityUpdateCompareEventEmits,
  viewModel: KChangeActivityUpdateCompareViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /**  过滤显示方示  **/
    const filterTypeOptions = ref([new SelectItem(1, '显示全部'), new SelectItem(2, '仅显示差异')])

    /** 展示的对象类 **/
    const objClsTypeOptions = computed(() => {
      let allData = [...vm.sourceDataSource.value, ...vm.targetDataSource.value]
      let classNameList = Array.from(new Set([...allData.map((item: any) => item.className)]))

      return classNameList.map(item => new SelectItem(item, item))
    })

    return { objClsTypeOptions, filterTypeOptions }
  }
})

import { connect, withInstall } from '@kmsoft/upf-core'
import KPartManageView from './KPartManage.vue'
import KPartManageViewModel from './KPartManageViewModel'

const KPartManage = connect(KPartManageView, KPartManageViewModel)

export default withInstall(KPartManage)
export { KPartManage, KPartManageView, KPartManageViewModel }

// 模板生成文件
// export * from './{{folderName}}'

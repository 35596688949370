import { connect, withInstall } from '@kmsoft/upf-core'
import KPartStructView from './KPartStruct.vue'
import KPartStructViewModel from './KPartStructViewModel'

const KPartStruct = connect(KPartStructView, KPartStructViewModel)

export default withInstall(KPartStruct)
export { KPartStruct, KPartStructView, KPartStructViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { connect, withInstall } from '@kmsoft/upf-core'
import KFlowDetailUpdateExecutorView from './KFlowDetailUpdateExecutor.vue'
import KFlowDetailUpdateExecutorViewModel from './KFlowDetailUpdateExecutorViewModel'

const KFlowDetailUpdateExecutor = connect(KFlowDetailUpdateExecutorView, KFlowDetailUpdateExecutorViewModel)

export default withInstall(KFlowDetailUpdateExecutor)
export { KFlowDetailUpdateExecutor, KFlowDetailUpdateExecutorView, KFlowDetailUpdateExecutorViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import { ErrorDetails, IExceptionViewer } from '@kmsoft/upf-core'
import { isString } from 'lodash'

export default class ExceptionViewer implements IExceptionViewer {
  display(ex: ErrorDetails) {
    const msg = isString(ex) ? ex : JSON.stringify(ex)
    console.error(msg)
    // message.error(msg)
  }
}

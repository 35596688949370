import { connect, withInstall } from '@kmsoft/upf-core'
import KFileEditView from './KFileEdit.vue'
import KFileEditViewModel from './KFileEditViewModel'

const KFileEdit = connect(KFileEditView, KFileEditViewModel)

export default withInstall(KFileEdit)
export { KFileEdit, KFileEditView, KFileEditViewModel }

// 模板生成文件
// export * from './{{folderName}}'

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KBaselineComparePropOptions = {
  ...BaseViewPropOptions,
  sourceId: VuePropTypes.string()
    .setRequired()
    .def(),
  targetId: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KBaselineComparePropType = ViewPropsTypeExtract<typeof KBaselineComparePropOptions>

/** 事件 */
export const KBaselineCompareEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KBaselineCompareEmitsType = ViewEmitsTypeExtract<typeof KBaselineCompareEventEmits>

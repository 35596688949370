import { EnumToolStripCompType } from '@kmsoft/upf-core'
import {
  toolStripItemCopy,
  toolStripItemDivider,
  toolStripItemMainDescRel,
  toolStripItemOpen,
  toolStripItemPaste,
  toolStripItemViewMainObj
} from './base'
import { EnumToolStripItemKeys, EnumToolStripUseEnv } from './types/enums'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../object-tool-strip'
import { CommonClientSrv, EnumClassTemplate } from '../../client-srv'
import * as doc from './doc'
import * as part from './part'

/** 操作 */
export const toolStripItemGroupOperation: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION,
  title: '操作',
  icon: 'DropObjOp',
  visible: true,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  items: []
}

export const toolStripItemSendTo: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SEND_TO,
  title: '发送到',
  icon: 'SendObjsToMyRecDir',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER],
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
}

export const toolStripItemSendToDirectory: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SEND_TO_DIRECTORY,
  title: '发送到我的目录',
  icon: 'SendObjsToMyRecDir',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER],
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
}

export const toolStripItemChangeObjcls: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHANGE_OBJCLS,
  title: '修改对象类',
  icon: 'ChangeObjCls',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Edit
}

export const getObjectOperationToolStrip = (env: EnumToolStripUseEnv = EnumToolStripUseEnv.ObjGrid): ObjectToolStripItem => {
  return {
    ...toolStripItemGroupOperation,
    items: [
      toolStripItemOpen,
      // 查看主对象
      //toolStripItemViewMainObj,
      toolStripItemDivider(),
      ...doc.getDocObjectOperationToolStripItems(env),
      toolStripItemDivider(),
      ...part.partObjectOperationToolStripItems,
      toolStripItemDivider(),
      ...part.bomViewObjectOperationToolStripItems,
      toolStripItemDivider(),
      toolStripItemMainDescRel,
      toolStripItemDivider(),
      toolStripItemSendTo,
      toolStripItemSendToDirectory,
      toolStripItemChangeObjcls,
      toolStripItemDivider(),
      toolStripItemCopy,
      toolStripItemPaste
    ]
  }
}

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KChangeRequestPropOptions = {
  ...BaseViewPropOptions,
  aa: VuePropTypes.string().def('1')
}

/** 参数类型 **/
export type KChangeRequestPropType = ViewPropsTypeExtract<typeof KChangeRequestPropOptions>

/** 事件 */
export const KChangeRequestEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangeRequestEmitsType = ViewEmitsTypeExtract<typeof KChangeRequestEventEmits>


import { defineView } from '@kmsoft/upf-core'
import { KDistributionCreatePropOptions, KDistributionCreateEventEmits } from './interface'
import KDistributionCreateViewModel from './KDistributionCreateViewModel'

export default defineView({
  name: 'KDistributionCreate',
  props: KDistributionCreatePropOptions,
  emits: KDistributionCreateEventEmits,
  viewModel: KDistributionCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {}
})

import { ViewEmitsTypeExtract, ViewPropsTypeExtract, VuePropTypes } from '@kmsoft/upf-core'
import { KObjectCreatePanelFormBaseEventEmits, KObjectCreatePanelFormBasePropOptions } from '../base'

/** 参数 **/
export const KCommonObjectCreateFormPropOptions = {
  ...KObjectCreatePanelFormBasePropOptions
}

/** 参数类型 **/
export type KCommonObjectCreateFormPropType = ViewPropsTypeExtract<typeof KCommonObjectCreateFormPropOptions>

/** 事件 */
export const KCommonObjectCreateFormEventEmits = {
  ...KObjectCreatePanelFormBaseEventEmits
}

/** 事件类型 **/
export type KCommonObjectCreateFormEmitsType = ViewEmitsTypeExtract<typeof KCommonObjectCreateFormEventEmits>

import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPersonalWorkflowTasksPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KPersonalWorkflowTasksPropType = ViewPropsTypeExtract<typeof KPersonalWorkflowTasksPropOptions>

/** 事件 */
export const KPersonalWorkflowTasksEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPersonalWorkflowTasksEmitsType = ViewEmitsTypeExtract<typeof KPersonalWorkflowTasksEventEmits>

import * as Vue from 'vue'
import KVueBrowser from './KVueBrowser'

export * from './enums'
export * from './inherits'
export * from './types'

KVueBrowser.install = function(app: Vue.App) {
  if (app.config.globalProperties.__k_vue_browser__registry__) return
  app.config.globalProperties.__k_vue_browser__registry__ = true
  app.component(KVueBrowser.name!, KVueBrowser)
}

export default KVueBrowser as typeof KVueBrowser & Vue.Plugin

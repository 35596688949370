
import { defineView } from '@kmsoft/upf-core'
import { createVNode } from 'vue'
import { EnumClsEditorType } from '../../enums'
import { KCustomPropsFormViewModel } from './KCustomPropsFormViewModel'
import CheckboxEditor from './checkbox-editor'
import CustomEditor from './custom-editor'
import DateEditor from './date-editor'
import FileEditor from './file-edit'
import { KCustomPropFormPropOptions } from './interface'
import RefObjEditor from './obj-link-editor'
import Selector from './select-editor'
import SingleLineEditor from './single-line-editor'
import FileUpload from './file-upload'

export default defineView({
  name: 'EditorTypeForm', // 组件名称
  viewModel: KCustomPropsFormViewModel,
  props: KCustomPropFormPropOptions,

  setup(props, { vm }) {
    /**
     * 获取编辑类型对应的组件
     */
    const getEditorComponent = () => {
      // 当前选择的编辑器
      let editorComponent: any
      switch (props.editType) {
        case EnumClsEditorType.SINGLE_LINE_EDITOR:
        case EnumClsEditorType.MULTI_LINE_EDITOR:
          editorComponent = SingleLineEditor
          break
        case EnumClsEditorType.DATE_EDITOR:
          editorComponent = DateEditor
          break
        case EnumClsEditorType.SELECT_EDITOR:
        case EnumClsEditorType.SELECT_EDITABLE_EDITOR:
        case EnumClsEditorType.SELECT_VALUED_EDITOR:
        case EnumClsEditorType.LIST_EDITOR:
        case EnumClsEditorType.LIST_SINGLE_EDITOR:
          editorComponent = Selector
          break
        case EnumClsEditorType.CHECKBOX_EDITOR:
          editorComponent = CheckboxEditor
          break
        case EnumClsEditorType.CUSTOM_EDITOR:
          editorComponent = CustomEditor
          break
        case EnumClsEditorType.OBJ_SELECTOR:
        case EnumClsEditorType.DIALOG_OBJ_SELECTOR:
          editorComponent = RefObjEditor
          break
        case EnumClsEditorType.FILED_EDITOR:
          editorComponent = FileEditor
          break
        case EnumClsEditorType.FILED_UPLOAD_EDITOR:
        case EnumClsEditorType.FILED_UPLOAD_AGENT_EDITOR:
          editorComponent = FileUpload
          break
        default:
          editorComponent = SingleLineEditor
          break
      }

      return editorComponent
    }

    return () => {
      const commonProps = {
        ref: vm.refName,
        propertyId: props.propertyId,
        dataType: props.dataType,
        editType: props.editType,
        editArgs: props.editArgs,
        readonly: props.readonly,
        selectedNode: props.selectedNode
      } as any
      const editorComponent = getEditorComponent()

      if ([EnumClsEditorType.OBJ_SELECTOR, EnumClsEditorType.DIALOG_OBJ_SELECTOR].includes(props.editType!)) {
        commonProps.propertyArgs = props.propertyArgs
      }

      return createVNode(editorComponent, { ...commonProps })
    }
  }
})

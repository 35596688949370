import { connect, withInstall } from '@kmsoft/upf-core'
import KStructuredDocTreeView from './KStructuredDocTree.vue'
import KStructuredDocTreeViewModel from './KStructuredDocTreeViewModel'

const KStructuredDocTree = connect(KStructuredDocTreeView, KStructuredDocTreeViewModel)

export default withInstall(KStructuredDocTree)
export { KStructuredDocTree, KStructuredDocTreeView, KStructuredDocTreeViewModel }
export * from './interface'
export * from './node'
// 模板生成文件
// export * from './{{folderName}}'

import { Directory } from '../../../../io/Directory'
import { EnumerationOptions } from '../../../../io/EnumerationOptions'
import { FileAttributes } from '../../../../io/FileAttributes'
import { KTreeNodeFileSystemInfo } from './KTreeNodeFileSystemInfo'
import { KTreeNodeFile } from './KTreeNodeFile'

/**目录树节点。*/
export class KTreeNodeDirectory extends KTreeNodeFileSystemInfo {
  constructor(path: string) {
    super(path)

    this.pathType = FileAttributes.Directory
    this.pathTypeName = '文件夹'

    this.needInitializeName = true
    this.needInitializeShellIcon = true
  }

  protected async generateChildren(populateType: number, filter: string): Promise<KTreeNodeFileSystemInfo[]> {
    const children: KTreeNodeFileSystemInfo[] = (
      await Directory.GetDirectories(this.id, '*', await EnumerationOptions.Default())
    ).map(_ => new KTreeNodeDirectory(_))

    if (populateType === FileAttributes.Normal) {
      const filters = filter.split(';')
      let files = []
      for (const fill of filters) {
        files.push(...(await Directory.GetFiles(this.id, fill, await EnumerationOptions.Default())))
      }
      if (filters.length > 1) {
        files = files.sort()
      }
      children.push(...files.map(_ => new KTreeNodeFile(_)))
    }

    return children
  }
}

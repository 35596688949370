import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { toolStripItemEdit, toolStripItemRefresh } from '../base'
import { EnumToolStripItemKeys } from '../types'
import { ObjectToolStripItem } from '../../object-tool-strip'

//编辑
export const toolStripItemEditWorkflow: ObjectToolStripItem = {
  ...toolStripItemEdit,
  visible: true
}

//刷新
export const toolStripItemRefreshFolderObj: ObjectToolStripItem = {
  ...toolStripItemRefresh,
  visible: true
}

//刷新
export const toolStripItemSubmit: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_SUBMIT,
  title: '提交',
  icon: 'common_up_cover',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

//启动
export const toolStripItemStartUp: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_START,
  title: '启动',
  icon: 'delete',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

//暂停
export const toolStripItemPause: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_PAUSE,
  title: '暂停',
  icon: 'pause',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

//重启
export const toolStripItemRestart: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_RESTART,
  title: '重启',
  icon: 'delete',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

//终止
export const toolStripItemEnd: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WORKFLOW_STOP,
  title: '终止',
  icon: 'stop',
  visible: true,
  compType: EnumToolStripCompType.BUTTON
}

//更多操作
export const toolStripItemMoreOperations: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION,
  title: '更多操作',
  icon: 'more',
  visible: true,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  items: [toolStripItemPause, toolStripItemRestart, toolStripItemEnd]
}

export const getWorkFlowTaskToolStrip = (): Array<ObjectToolStripItem> => [
  toolStripItemEditWorkflow,
  toolStripItemRefreshFolderObj,
  toolStripItemSubmit,
  // toolStripItemStartUp,
  toolStripItemEnd
  // toolStripItemMoreOperations
]

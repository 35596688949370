import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KIssueReportRelationshipPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
  .setRequired()
  .def()
}

/** 参数类型 **/
export type KIssueReportRelationshipPropType = ViewPropsTypeExtract<typeof KIssueReportRelationshipPropOptions>

/** 事件 */
export const KIssueReportRelationshipEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KIssueReportRelationshipEmitsType = ViewEmitsTypeExtract<typeof KIssueReportRelationshipEventEmits>


import { defineView } from '@kmsoft/upf-core'
import { KCheckboxEditorFormViewModel } from './KCheckboxEditorFormViewModel'
import { KCheckboxEditorCustomPropOptions } from './interface'
/**
 *
 */
export default defineView({
  name: 'CheckboxEditorForm', // 组件名称
  components: {}, // 这里定义局部组件
  viewModel: KCheckboxEditorFormViewModel,
  props: KCheckboxEditorCustomPropOptions,
  setup(props, { vm }) {
    return {}
  }
})
